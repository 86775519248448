<template>
    <div class="system-settings-container">
        <div class="col no-wrap parent-row">
            <div class="outer-container under-line">
                <div class="title-container">
                    <span class="outside-title">{{ toLocal('settings.assign_media') }}</span>
                </div>
                <div class="row top-row no-wrap logo-row">
                    <div class="row-block ">
                        <span class="title">{{toLocal('settings.setting_1')}} (250 x 250 px):</span>
                        <div class="logo-box"><img
                            :src="main_logo_preview.length > 0 ? main_logo_preview : $store.state.api_uploads_link + $store.state.system_settings['main_logo']"
                            >
                            <label class="svg-container">
                                <input
                                    ref="main_logo"
                                    type="file"
                                    name="main_logo"
                                    accept=".png,.svg,.jpg,.jpeg"
                                    @change="mainLogoChange"
                                > 
                                <SettingsPencilSvg/>
                            </label>
                            <div class="progress" v-if="isSaving">
                                <div class="progress-bar" role="progressbar" :style="{height: uploadProgress + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row-block">
                        <span class="title">{{toLocal('settings.setting_2')}} (1920 x 1080 px):</span>
                        <div class="logo-box cover">
                            <img
                            :src="login_bg_preview.length > 0 ? login_bg_preview : $store.state.api_uploads_link + $store.state.system_settings['login_bg']"
                            >
                            <label class="svg-container">
                                <input
                                    ref="login_bg"
                                    type="file"
                                    name="login_bg"
                                    accept=".png,.svg,.jpg,.jpeg"
                                    @change="loginBgChange"
                                > 
                                <SettingsPencilSvg/>
                            </label>
                            <div class="progress" v-if="isSaving">
                                <div class="progress-bar" role="progressbar" :style="{height: uploadProgress + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row-block">
                        <span class="title">{{toLocal('settings.setting_3')}} (50 x 50 px):</span>
                        <div class="logo-box">
                            <img
                            :src="logo_preview.length > 0 ? logo_preview : $store.state.api_uploads_link + $store.state.system_settings['logo']"
                            >
                            <label class="svg-container">
                                <input
                                    ref="logo"
                                    type="file"
                                    name="logo"
                                    accept=".png,.svg"
                                    @change="logoChange"
                                >
                                <SettingsPencilSvg/>
                            </label>
                            <div class="progress" v-if="isSaving">
                                <div class="progress-bar" role="progressbar" :style="{height: uploadProgress + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="outer-container under-line">
                <div class="title-container">
                        <span class="outside-title">{{toLocal('settings.assign_colors')}}</span>
                        <span class="desc">{{toLocal('settings.color_explanation')}}</span>
                </div>
                <div class="row top-row">
                    <div class="row color-row">
                        <div class="color-block">
                            <div class="color-container">
                                <span class="color-title">
                                    {{ toLocal('settings.main_colour') }}:
                                </span>
                                <div class="color-box">
                                    <div class="color-circle allowedToClick" :style="{'background-color':primary_color.hex}" @click="primary_color_selector = true; isSavingEnabled = true">
                                        <chrome-picker style="z-index:1;position:relative" ref="color-picker1" v-click-outside="clickOff" class="picker allowedToClick" v-if="primary_color_selector" v-model="primary_color" />
                                    </div>
                                    <SettingsPencilBigSvg class="allowedToClick" @click="primary_color_selector = !primary_color_selector; isSavingEnabled = true"/>
                                </div>
                            </div>
                        </div>
                        <div class="color-block">
                            <div class="color-container">
                                <span class="color-title">
                                    {{ toLocal('settings.secondary_colour') }}:
                                </span>
                                <div class="color-box">
                                    <div class="color-circle allowedToClick" :style="{'background-color':secondary_color.hex}" @click="secondary_color_selector = true; isSavingEnabled = true">
                                        <chrome-picker style="z-index:1;position:relative" ref="color-picker2" v-click-outside="clickOff" class="picker allowedToClick" v-if="secondary_color_selector" v-model="secondary_color" />
                                    </div>
                                    <SettingsPencilBigSvg class="allowedToClick" @click="secondary_color_selector = !secondary_color_selector; isSavingEnabled = true"/>
                                </div>
                            </div>
                        </div>
                        <div class="color-block">
                            <div class="color-container">
                                <span class="color-title">
                                    {{ toLocal('settings.tetriary_colour') }}:
                                </span>
                                <div class="color-box">
                                    <div class="color-circle allowedToClick" :style="{'background-color':third_color.hex}" @click="third_color_selector = true; isSavingEnabled = true">
                                        <chrome-picker style="z-index:1;position:relative" ref="color-picker3" v-click-outside="clickOff" class="picker allowedToClick" v-if="third_color_selector" v-model="third_color" />
                                    </div>
                                    <SettingsPencilBigSvg class="allowedToClick" @click="third_color_selector = !third_color_selector; isSavingEnabled = true"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="outer-container under-line">
                <div class="title-container">
                        <span class="outside-title">{{toLocal('settings.select_palette_label')}}</span>
                        <span class="desc">{{toLocal('settings.color_palette_explanation')}}</span>
                </div>
                <div class="row top-row">
                    <div class="row color-row swatches">
                        <div class="color-block clickable" v-for="(palette, index) in color_paletts" :key="index">
                            <div class="color-container">
                                <span class="color-title">
                                    {{ palette.label }}:
                                </span>
                                <div class="color-box" :class="{selected: selectedPalette==index}">
                                    <div class="color-rect" @click="selectedPalette=index; isSavingEnabled = true" :class="[palette.class]">
                                        <span :class="[palette.text_color]">{{toLocal('settings.text_example')}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="outer-container under-line">
                <div class="column-container">
                    <span class="outer-title">{{toLocal('settings.shop_enabled')}}</span>
                    <div class="wrap-box">
                        <div 
                        class="checkmark-container" 
                        v-for="(role, index) in allRoles"
                        :key="'role-'+index"
                        @click="toggleRoleToShop(role)"
                        >
                            <div class="checkmark">
                                <div class="checkmark-icon"  v-if="arrAllowShop.includes(role.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                                        <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                                            </svg>
                                </div>
                            </div>
                            <span>
                                {{ role.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <portal to="global-modal-settings">
            <div class="row saving-row" v-if="isSavingEnabled">
                <button @click="saveChanges()" class="save-btn">
                    <span v-if="!isSaving">
                    <svg class="save-checkmark" v-if="showSaveCheckmark" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                        <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                    </svg>
                    {{ toLocal('settings.save') }}
                </span>
                <span v-else>
                    <font-awesome-icon icon="spinner" />
                </span>
            </button>
        </div>
        </portal>
    </div>
</template>

<script>
import axios from "axios";
import SettingsPencilBigSvg from "@/assets/images/icons/settings-pencil-big.svg?inline";
import SettingsPencilSvg from "@/assets/images/icons/settings-pencil.svg?inline";
import { Chrome } from 'vue-color'  
export default {
name: 'SystemSettings',
components: {
    SettingsPencilSvg,
    SettingsPencilBigSvg,
    "chrome-picker": Chrome,
},
data(){
  return{
    isSavingEnabled: false,
    isSaving: false,
    showSaveCheckmark: false,
    selectables: [
                {
                    name: 'settings.show_shop_admins',
                    selected: this.$store.state.system_settings.shop_to_admins == 1 ? true : false ,
                    isSelectable: true,
                },
                {
                    name: 'settings.show_shop_users',
                    selected: this.$store.state.system_settings.shop_to_users == 1 ? true : false,
                    isSelectable: true, 
                },
                ],
      primary_color: {hex: '#fff'},
      secondary_color: {hex: '#fff'},
      third_color: {hex: '#fff'},
      color_paletts: [
        {opacity: 1, label:this.toLocal('settings.color_palette_rich'), class: 'primary', text_color: 'white'},
        {opacity: 0.6, label:this.toLocal('settings.color_palette_bright_dark'), class: 'secondary', text_color: 'primary-color'},
        {opacity: 0.3, label:this.toLocal('settings.color_palette_light_dark'), class: 'third', text_color: 'primary-color'},
      ],
      selectedPalette: 0,
      primary_color_selector: false,
      secondary_color_selector: false,
      third_color_selector: false,
      main_logo_label: this.static_main_logo_label,
      login_bg_label: this.static_login_bg_label,
      logo_label: this.static_logo_label,
      main_logo_preview: '',
        login_bg_preview: '',
        logo_preview: '',
      static_main_logo_label: "", // 1. Prisijungimo lango logotipas (250x250px)
      static_login_bg_label: "", // 2. Prisijungimo lango fonas (1920x1080px)
      static_logo_label: "", // 3. Logotipas puslapio višuje (50x50px)

      uploadProgress: 0,
      uploadedData: null,
      isSaving: false,
      allRoles: [],
      arrAllowShop: [],
      
  }
},
mounted(){
    this.primary_color = {hex: this.$store.state.system_settings.primary_color};
    this.secondary_color = {hex:  this.$store.state.system_settings.secondary_color};
    this.third_color= {hex:  this.$store.state.system_settings.third_color};
    this.selectedPalette = this.$store.state.system_settings.color_palette;
    this.getAllRoles();
},
methods:{
    toggleRoleToShop(role){
        if(this.arrAllowShop.includes(role.id)){
            this.arrAllowShop = this.arrAllowShop.filter((item)=>{return item != role.id});
        }else{
            this.arrAllowShop.push(role.id);
        }
        this.isSavingEnabled = true;
        
    },
    getAllRoles(){
        axios.get(
            `${this.$store.state.api_link}/user/roles/getAll`,
            this.$store.getters.axiosConfig()
        ).then((response) => {
            this.allRoles = response.data.roles;
            this.allRoles.forEach((role)=>{
                let hasShopRole = role.permissions.filter((perm)=>{return perm.permission_key == 'users.view_shop'});
                if(hasShopRole.length > 0){
                    this.arrAllowShop.push(role.id);
                }
            })
        })
        .catch((e) => {
            console.log(e.error);
        });
    },
    clickOff(e){
      if(!e.target.classList.contains('allowedToClick') && !e.target.classList.contains('color-container')){
        this.primary_color_selector = false;
        this.secondary_color_selector = false;
        this.third_color_selector = false;
      }
    },
    saveChanges(){
        this.isSaving = true;
        this.saveSettings();
        this.saveImages();
    },
    saveSettings(){
      axios.post(
          `${this.$store.state.api_link}/change-settings/theme`,
          {'primary_color': this.primary_color.hex, 'secondary_color': this.secondary_color.hex, 'third_color': this.third_color.hex, 'color_palette': this.selectedPalette,
            shopsAllowed: this.arrAllowShop},
          this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){
          this.$store.state.infoMessage.setMessage('success',this.toLocal('settings.settings_update_success'));
          this.$store.commit('changeThemeColor', [this.primary_color.hex, this.secondary_color.hex, this.third_color.hex]);
          this.showSaveCheckmark = true;
            setTimeout(() => {
                this.showSaveCheckmark = false;
            }, 1000);
          this.$store.state.system_settings.color_palette = this.selectedPalette;
          this.isSavingEnabled = false;
          this.isSaving = false;
        }
      })
      .catch(() => {
      });
    },
    saveImages(){
        let main_logo_files = this.$refs['main_logo'].files;
      let login_bg_files = this.$refs['login_bg'].files;
      let logo_files = this.$refs['logo'].files;

      if (!main_logo_files.length && !login_bg_files.length && !logo_files.length)
        return;
        
      let formData = new FormData();
      if(main_logo_files.length){
          formData.append('main_logo', main_logo_files[0]);
        }
        if(login_bg_files.length){
            formData.append('login_bg', login_bg_files[0]);

        }
        if(logo_files.length){
            formData.append('logo', logo_files[0]);
        }
      this.system_settings_message = this.toLocal('settings.settings_updating');
      this.isSaving = true;
      axios.post(
          `${this.$store.state.api_link}/change-settings`,
          formData,
          {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.uploadedData = progressEvent.loaded
                this.uploadProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        }
      ).then((response) => {
        if(response.data.status == "success"){
            if(response.data.updated){
                for (const [key, value] of Object.entries(response.data.updated)) {
                    this.$store.state.system_settings[key] = value;
                }
            }
          
            this.$store.state.infoMessage.setMessage('success',this.toLocal('settings.settings_update_success'));
        }else{
            this.$store.state.infoMessage.setMessage('error',this.toLocal('settings.settings_update_error_1'));
        }
        this.isSaving = false;

      })
      .catch(() => {
        this.$store.state.infoMessage.setMessage('error',this.toLocal('settings.settings_update_error_2'));
        this.isSaving = false;
      });
    },
    mainLogoChange(e) {
        let main_logo_files = this.$refs['main_logo'].files;
        if(main_logo_files.length){
            this.main_logo_label = main_logo_files[0].name;
        }else{
            this.main_logo_label = this.static_main_logo_label;
        }
        const [file] = main_logo_files
        if (file) {
            this.main_logo_preview= URL.createObjectURL(file)
        }
        this.isSavingEnabled = true;
    },
    loginBgChange(e) {
        let login_bg_files = this.$refs['login_bg'].files;
        if(login_bg_files.length){
            this.login_bg_label = login_bg_files[0].name;
        }else{
            this.login_bg_label = this.static_login_bg_label;
        }
        const [file] = login_bg_files
        if (file) {
            this.login_bg_preview= URL.createObjectURL(file)
        }
        this.isSavingEnabled = true;
    },
    logoChange(e) {
        let logo_files = this.$refs['logo'].files;
        if(logo_files.length){
            this.logo_label = logo_files[0].name;
        }else{
            this.logo_label = this.static_logo_label;
        }
        const [file] = logo_files
        if (file) {
            this.logo_preview= URL.createObjectURL(file)
        }
        this.isSavingEnabled = true;
    },
}
}
</script>

<style lang="scss" scoped>
.system-settings-container{
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    .outside-title{
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 16px;
        color: var(--primary-color);
        @media(max-width: 768px){
            text-align: center;
        }
    }
    .outer-container{
        display: flex;
        width: 100%;
        padding: 0px 24px;
        .top-row{
            width: 100%;
            .row{
                width: 60%;
            }
        }
    }
    .row{
        display: flex;
        flex-direction: row;
        margin-bottom: 32px;
        @media(max-width: 768px){
            flex-direction: column;
            align-items: center;
        }
    }
    .under-line{
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid hsla(0, 0%, 49%, 0.5);
    }
    .color-row{
        display: flex;
        gap: 52px;
        color: var(--primary-color);
        margin-bottom: 16px;
    }
    .no-wrap{
        flex-wrap: nowrap;
    }
    .parent-row{
        column-gap: 28px;
    }
}
.logo-row{
    display: flex;
    gap: 36px;
}
.wrap-box{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.checkmark-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 200px;
        }
    .checkmark{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: white;
        padding: 0.25rem;
        height: 28px;
        width: 28px;
        min-width: 28px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        user-select: none;
        cursor: pointer;
        .checkmark-icon{
            display: flex;
            width: 100%;
        }
        svg{
            height: 14px;
            width: 12px;
            margin: auto;
            path{
                fill: var(--primary-color);
            }
        }
        &.selected{
            background: var(--secondary-color);
        }
        &.round{
            border-radius: 50%;
        }
    }
    span{
        a{
            text-decoration: underline;
        }
        // font-weight: 700;
        margin-left: 12px;
        font-size: clamp(14px, 1.5vw, 16px);
        cursor: pointer;
    }
    &.non-selectable{
        .checkmark{
            opacity: 0.5;
        }
    }
}
.row-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    width: 180px;
    color: var(--primary-color);
    @media(max-width:768px){
        min-width: 0px;
        align-items: center;
    }
    .title{
        color: var(--primary-color);
        font-size: clamp(12px, 1.5vw, 14px); 
    }
    .logo-box{
        width: 180px;
        height: 180px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #FFFFFF;
        img{
            width: 93%;
            height: 93%;
            object-fit: contain;
            border-radius: 4px;
            text-align: center;
        }
        &.cover{
            img{
                object-fit: cover;
            }
        }
    }
}
.svg-container{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    padding: 22px;
    border-radius: 50%;
    cursor: pointer;
    svg{
        position: absolute;
        margin: 0 auto;
        top: 11px;
        right: 11px;
    }
}
.column-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .outer-title{
        font-weight: 600;
        line-height: 24px; 
        color: #000; 
        font-size: 16px;
    }
}
.title-container{
    display: flex;
    flex-direction: column;
    min-width: 30%;
    max-width: 30%;
    .outside-title{
        font-weight: 600;
        line-height: 24px; 
        color: #000; 
        font-size: 16px; 
    }
    .desc{
        width: 80%;
        font-size: clamp(12px, 1.5vw, 14px);
    }
}
.color-block{
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.color-container{
    display: flex;
    flex-direction: column;
    .color-title{
        margin-bottom: 12px;
    }
    .color-box{
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
        padding: 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background-color: #FFFFFF;
        .color-circle{
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border-radius: 50%;
            cursor: pointer;
        }
        svg{
            height: 28px;
            width: 28px;
            cursor: pointer;
        }
        &.selected{
            border:1px solid rgba(125, 125, 125, 1);
        }
    }
    .color-rect{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 36px;
        border-radius: 4px;
        &.primary{
            background: linear-gradient(90deg, #E64545 0%, #E57300 10.33%, #BF8F00 19.44%, #64992E 28.3%, #2E992E 37.29%, #2E9964 45.88%, #2E9999 54.61%, #4596E5 63.72%, #4545E5 72.45%, #9645E6 81.95%, #992E99 91.2%, #E54596 100%);
        }
        &.secondary{
            background:  linear-gradient(90deg, #E57373 0%, #E5A667 10.33%, #E5E567 19.44%, #B8E58A 28.3%, #8AE58A 37.29%, #73E5AC 45.88%, #73E5E5 54.61%, #72ACE5 63.72%, #8A8AE5 72.45%, #B88AE6 81.95%, #E58AE5 91.2%, #E58AB8 100%);
        }
        &.third{
            background: linear-gradient(90deg, #E5B8B8 0%, #E5CFB8 10.33%, #E5E5B8 19.44%, #CFE5B8 28.3%, #B8E5B8 37.29%, #B8E5CF 45.88%, #B8E5E5 54.61%, #B8CFE5 63.72%, #B8B8E5 72.45%, #CFB8E5 81.95%, #E5B8E5 91.2%, #E5B8CF 100%);
        }
        span{
            font-size: 14px;
            &.primary-color{
                color: var(--primary-color)
            }
            &.white{
                color: white;
            }
        }
    }
}
.description{
    span{
        color: #4444446b;
        font-size: 14px;
    }
}
.top-16{
    margin-top: 16px;
}
.col{
    display: flex;
    flex-direction: column;
}
.justify-center{
    justify-content: center;
}
.save-btn{
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    border-radius: 38px;
    padding: 9px 44px;
    border: none;
    cursor: pointer;
    background-color: var(--secondary-color);
    font-family: var(--main-site-font);
    font-size: 16px;
    &:hover{
        text-shadow: 1px 0 0 var(--primary-color);
    }
    &:active{
        background-color: var(--primary-color);
        color: white;
    }
}
.clickable{
    cursor: pointer;
}
input[type=file] {
    display: none;
  }
.progress{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .progress-bar{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background-color: var(--secondary-color);
        opacity: 0.5;
    }
}
@media (max-width: 1120px){
    .system-settings-container{
        .parent-row{
            flex-direction: column;
        }
        .col{
            padding-left: 20px;
            border: none;
            &:first-child{
                border: none;
            }
        }
        .top-row{
            margin-bottom: 30px;
            margin-top: 15px;
            justify-content: center;
            .row{
                width: 100%;
            }
        }
        .outer-container{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title-container{
                width: 80%;
                max-width: 80%;
                justify-content: center;
                align-items: center;
                .desc{
                    text-align: center;
                }
            }
            .top-row{
                .row{
                    width: 90%;
                }
            }
        }
        .color-row{
            justify-content: center;
        }
        .palette-row{
            flex-direction: column;
        }
    }
}
@media (max-width: 768px){
    .system-settings-container{
        .color-container{
            margin-bottom: 10px;
            .color-title{
                margin-bottom: 6px;
            }
        }
        .under-line{
        margin-bottom: 16px;
        padding-bottom: 16px;
        }
        .color-row{
            flex-direction: column;
            gap: 8px;
        }
    }
    .system-settings-container .col:first-child{
        border: none;
    }
}
.saving-row{
    position: fixed;
    bottom: 0;
    left: 0;
    background:#F9F9F9;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.16); 
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    .save-btn{
        display: flex;
    }
}
</style>