
<template>
  <div class="table-container">
        <table 
            
        >
            <thead>
                <tr>
                    <th v-for="(header, index) in headers.filter(h => h.show)" :key="index">
                        <div class="header-cell">
                            <span>
                                {{toLocal(header.label)}}
                            </span>
                            <svg v-if="header.sortable" @click="setOrderBy(header.key)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path :class="{active: orderByElement == header.key && orderByDirection == 'desc'}" d="M6.60243 19.7168C6.28496 20.0774 5.75871 20.0955 5.42453 19.7529C5.41617 19.7439 5.39943 19.7258 5.39108 19.7168L0.228004 13.8212C-0.089464 13.4606 -0.0727225 12.8926 0.261454 12.5501C0.420188 12.3968 0.620747 12.3066 0.837962 12.3066H11.164C11.6235 12.3066 11.9995 12.7123 11.9995 13.2081C11.9995 13.4425 11.9159 13.6589 11.7739 13.8302L6.60243 19.7168Z" fill="#7D7D7D"/>
                                <path :class="{active: orderByElement == header.key && orderByDirection == 'asc'}" d="M5.39757 0.281221C5.71504 -0.0793729 6.2413 -0.0974378 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549251 7.28574 0.000549293 6.78992C0.000549313 6.55553 0.0841098 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="#7D7D7D"/>
                            </svg>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(user, user_id) in searchCopy"
                    :key="user_id"
                    :class="[user.belongsToGroup || group.user_selection_mode==1 ? `bc-${group.color_id}` : 'inactive']"
                >
                    <td v-for="(header, index) in headers.filter(h => h.show)" :key="index" >
                        <span class="user-name" v-if="header.key == 'name'">{{ user.name }} {{user.last_name}}</span>
                        <div class="border-left" :class="header.class" v-else-if="header.percent">
                            {{ user[header.key] ? user[header.key].toFixed(1) : '0' }} %
                        </div>
                        <div class="border-left" :class="header.class" v-else-if="header.key =='role'">
                            <img 
                                v-if="user.role == 'user'" 
                                src="@/assets/images/icons/user.svg"
                            >
                            <img 
                                v-if="user.role == 'admin'" 
                                src="@/assets/images/icons/crown.svg"
                            >
                        </div>
                        <div class="border-left action-btn" :class="header.class" v-else-if="header.key=='action' " >
                            <svg v-if="user.belongsToGroup" @click="removeUser(user)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 34">                            <g id="Group_1052" data-name="Group 1052" transform="translate(-1769 -356)">                                <g id="Group_656" data-name="Group 656" transform="translate(1785.898 359.239) rotate(45)">                                <g id="Path_749" data-name="Path 749" transform="translate(0 0)">                                    <path id="Path_751" data-name="Path 751" d="M19.461,9.667a1.066,1.066,0,0,1-1.054,1.054H10.792v7.56a1.066,1.066,0,0,1-1.062,1.046,1.053,1.053,0,0,1-.745-.307,1.015,1.015,0,0,1-.317-.739v-7.56H1.054A1.054,1.054,0,0,1,.3,8.928a1.11,1.11,0,0,1,.752-.315H8.669V1.061A1.024,1.024,0,0,1,8.986.307,1.053,1.053,0,0,1,9.731,0a1.069,1.069,0,0,1,1.062,1.061V8.613h7.615A1.071,1.071,0,0,1,19.461,9.667Z" transform="translate(0 0)" fill="#464646"/>                                </g>                                </g>                                <g id="Ellipse_135" data-name="Ellipse 135" transform="translate(1769 356)" fill="none" stroke="#464646" stroke-width="2">                                <circle cx="17" cy="17" r="17" stroke="none"/>                                <circle cx="17" cy="17" r="16" fill="none"/>                                </g>                            </g>                            </svg>
                            <svg v-else @click="addUser(user)" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">                            <g clip-path="url(#clip0)">                                <path d="M24.5445 16.0661C24.5414 16.3119 24.4421 16.5467 24.268 16.7202C24.094 16.8938 23.8589 16.9923 23.6131 16.9946L16.8939 16.9841L16.8835 23.6547C16.8785 23.9005 16.7775 24.1345 16.6021 24.3067C16.4267 24.479 16.1908 24.5757 15.945 24.5762C15.6986 24.5762 15.4623 24.4784 15.2881 24.3043C15.1996 24.2202 15.1293 24.119 15.0814 24.0068C15.0334 23.8946 15.0089 23.7738 15.0094 23.6518L15.0198 16.9812L8.3016 16.9707C8.11814 16.9708 7.93874 16.9167 7.78597 16.8151C7.6332 16.7136 7.51388 16.5691 7.44303 16.3998C7.37217 16.2306 7.35296 16.0442 7.38779 15.8641C7.42262 15.684 7.50995 15.5182 7.63878 15.3876C7.81696 15.2144 8.05431 15.1154 8.30275 15.1107L15.0236 15.1212L15.034 8.45767C15.0326 8.3335 15.0568 8.21038 15.1052 8.09599C15.1535 7.9816 15.2248 7.87839 15.3148 7.79281C15.4896 7.61921 15.7262 7.52214 15.9726 7.52296C16.2204 7.52496 16.4576 7.62446 16.6326 7.79992C16.8077 7.97539 16.9067 8.21273 16.9082 8.4606L16.8977 15.1241L23.6168 15.1346C23.8621 15.1391 24.0961 15.2388 24.2692 15.4126C24.4423 15.5864 24.541 15.8208 24.5445 16.0661Z" fill="#464646"/>                                <path d="M30.117 15.8714C30.0461 8.07481 23.6681 1.81194 15.8714 1.88293C8.0748 1.95393 1.81193 8.33191 1.88293 16.1285C1.95392 23.9252 8.3319 30.1881 16.1285 30.1171C23.9252 30.0461 30.188 23.6681 30.117 15.8714Z" stroke="#464646" stroke-width="1.76471"/>                            </g>                            <defs>                                <clipPath id="clip0">                                <rect width="30" height="30" fill="white" transform="translate(1.13721 31.136) rotate(-90.5217)"/>                                </clipPath>                            </defs>                            </svg>
                        </div>
                        <div v-else class="border-left" :class="header.class">
                            {{ user[header.key] ? user[header.key] : '' }}
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    props:{
        group: Object,
        users: Array,
        searchInput: String,
        selectedFilter: Number | Array
    },
    data(){
        return{
            orderByDirection: '',
            orderByElement: '',
            usersCopy: [],
            searchCopy: [],
            headers:[
                {'label': 'payments.placeholder_name_surname', key: 'name', sortable:true, show:true},
                {'label': 'group.users_training_count', class:'centered', key: 'training_count', sortable:true, show:true},
                {'label': 'group.users_started_percent', class:'centered', key: 'started_percent', sortable:true, percent: true, show:true},
                {'label': 'group.users_ended_percent', class:'centered', key: 'ended_percent', sortable:true, percent: true, show:true},
                {'label': 'group.users_role', class:'centered', key: 'role', sortable:true, show:true},
                {'label': '', key: 'action', sortable:false, show:this.group.user_selection_mode==0},
            ]
        }
    },
    mounted(){
        if(this.group.user_selection_mode == 0){
            this.usersCopy = this._.cloneDeep(this.users);
            this.searchCopy = this._.cloneDeep(this.usersCopy);
        }
        this.$emit('UserCountFiltered', 0)
    },
    watch:{
        searchInput(newVal, previousInput){
            this.searchUsers(newVal, previousInput);
        },
        users(newVal){
            if(this.usersCopy.length == 0 && this.group.user_selection_mode == 0){
                this.usersCopy = newVal;
                this.searchCopy = this._.cloneDeep(this.usersCopy);
                this.filterUsers()
            }
        },
        'searchCopy.length'(newVal) {
            if (this.group.user_selection_mode == 1) {
                
                this.$emit('UserCountFiltered', newVal)
                this.$root.$emit('group_user_count_modified', this.group.id, newVal);
            }
        },
        selectedFilter:{
            deep:true,
            immediate:true,
            handler(newVal){
                this.filterUsers()
            }
        }
    },
    methods:{
        setOrderBy(element) {
            if(element == "")
                return;
            if(element == this.orderByElement){
                this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
            }else{
                this.orderByElement = element;
                this.orderByDirection = "asc";
            }
            let users = this.usersCopy;
            if((users.length && isNaN(users[0][element]))){
                if(this.orderByDirection == "desc"){
                    if(users.length) users.sort((a,b) => b[element].localeCompare(a[element])); 
                }else{
                    if(users.length) users.sort((a,b) => a[element].localeCompare(b[element])); 
                }
            }else{
                if(this.orderByDirection == "desc"){
                    if(users.length) users.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
                }else{
                    if(users.length) users.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
                }
                if(this.searchingUsers){
                    this.searchUsers = users;
                }
                else{
                    this.users = users;
                }
            }
        },
        searchUsers(input, previous_input) {
            if((!input || input.length == 0) && previous_input.length > 0){
                if(!Array.isArray(this.selectedFilter)){
                    this.usersCopy = this._.cloneDeep(this.users);
                    this.searchCopy = this._.cloneDeep(this.usersCopy);
                     let filter_id = this.selectedFilter;
                    if(filter_id == 1){
                        this.searchCopy = this.searchCopy.filter(el => el.belongsToGroup == false);
                    }
                    else if(filter_id == 2){
                        this.searchCopy = this.searchCopy.filter(el => el.belongsToGroup == true);
                    }
                    this.filterUsers();
                }
                else{
                    this.usersCopy = this._.cloneDeep(this.searchCopy);
                    
                }
                return;
            }
            let search = input.toLowerCase();
            this.usersCopy = this._.cloneDeep(this.users);
            let allUsers = this.usersCopy;
             this.searchCopy = this._.cloneDeep(this.usersCopy);
           
            this.usersCopy = allUsers.filter(user => {
                let name = user.name.toLowerCase();
                let last_name = user.last_name ? user.last_name.toLowerCase() : null;
                if(last_name && last_name != null)
                    return name.includes(search) || last_name.includes(search);
                else
                    return name.includes(search);
            });
            if(!Array.isArray(this.selectedFilter)){
                this.searchCopy = this._.cloneDeep(this.usersCopy);
                // this.filterUsers();
                let filter_id = this.selectedFilter;
                if(filter_id == 1){
                    this.searchCopy = this.searchCopy.filter(el => el.belongsToGroup == false);
                }
                else if(filter_id == 2){
                    this.searchCopy = this.searchCopy.filter(el => el.belongsToGroup == true);
                }
            }

        },
        filterUsers(){
            if(Array.isArray(this.selectedFilter)){
                if(!this.selectedFilter[0])  {
                    this.usersCopy = [];
                    return
                }
                let cols = this.selectedFilter[0].cols;
                if(cols.length == 0) {
                    this.usersCopy = [];

                    return
                }
                if(cols.length < 3) {
                    this.usersCopy = [];
                    return
                }
                this.usersCopy = this._.cloneDeep(this.searchCopy);
                this.advancedFilter(this.selectedFilter, this.usersCopy)
            }
            else{
                let filter_id = this.selectedFilter;
                this.usersCopy = this._.cloneDeep(this.users);
                
                if(filter_id == 1){
                     this.usersCopy = this.usersCopy.filter(el => el.belongsToGroup == false);
                     this.searchCopy = this.usersCopy.filter(el => el.belongsToGroup == false);
                }
                else if(filter_id == 2){
                     this.usersCopy = this.usersCopy.filter(el => el.belongsToGroup == true);
                     this.searchCopy = this.usersCopy.filter(el => el.belongsToGroup == true);
                }
                if (this.searchInput && this.searchInput.length > 0) {
                    let search = this.searchInput.toLowerCase();
                    this.searchCopy = this.usersCopy.filter(user => {
                        let name = user.name.toLowerCase();
                        let last_name = user.last_name ? user.last_name.toLowerCase() : null;
                        if(last_name && last_name != null)
                            return name.includes(search) || last_name.includes(search);
                        else
                            return name.includes(search);
                    });
                }
            }
        },
        advancedFilter(filter, users){
            axios
                .post(
                    `${this.$store.state.api_link}/group/${this.group.id}/users/filter`,
                    { filter: filter },
                    this.$store.getters.axiosConfig()
                )
                .then((response) => {
                    let user_ids = response.data.user_ids;
                    this.usersCopy = this.users.filter(el => user_ids.includes(el.id));
                    this.searchCopy = this._.cloneDeep(this.usersCopy);
                    if(this.searchInput.length > 0){
                        this.searchUsers(this.searchInput);
                    }
                })
                .catch((error) => {
                });
        },
        getConditionReturn(item, condition, value){
            if(condition == 'is'){
                return item == value;
            }
            else if(condition == 'is not'){
                return item != value;
            }
            else if(condition == 'includes'){
                return item.toLowerCase().includes(value.toLowerCase());
            }
            else if(condition == 'excludes'){
                return !item.toLowerCase().includes(value.toLowerCase());
            }
        },
        addUser(user) {
            var user_id = user.id;
            var index = this.usersCopy.findIndex(el => el.id == user.id);
            if(index != -1)
                this.$set(this.usersCopy[index], 'belongsToGroup', true);
            var index_user = this.users.findIndex(el => el.id == user.id);
            if(index_user != -1)
                this.$set(this.users[index_user], 'belongsToGroup', true);
            this.$root.$emit('group_user_count_modified', this.group.id, this.users.filter(el => el.belongsToGroup == true).length);
            // this.$emit('UserCountModified', this.id, this.allUsers.filter(el => el.belongsToGroup == true).length);
            axios.post(
                `${this.$store.state.api_link}/group/user/add`,
                {group: this.group.id, user: user_id},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == "success"){
                    this.group.users.push(user_id)
                }else{
                console.log("Error while adding user");
                }
            })
            .catch(() => {
                console.log("Server error while adding user");
            });
        },
        removeUser(user) {
            var user_id = user.id;
            var index = this.usersCopy.findIndex(el => el.id == user.id);
            if(index != -1)
                this.$set(this.usersCopy[index], 'belongsToGroup', false);
            var index_user = this.users.findIndex(el => el.id == user.id);
            if(index_user != -1)
                this.$set(this.users[index_user], 'belongsToGroup', false);
            this.$root.$emit('group_user_count_modified', this.group.id, this.users.filter(el => el.belongsToGroup == true).length);
            // this.$emit('UserCountModified', this.id, this.allUsers.filter(el => el.belongsToGroup == true).length);
            axios.post(
                `${this.$store.state.api_link}/group/user/remove`,
                {group: this.group.id, user: user_id},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == "success"){
                    let index = this.group.users.findIndex(el => el == user_id);
                    if(index != -1){
                        this.group.users.splice(index, 1);
                    }
                // this.$store.state.side_info['loaded'] = false;
                }else{
                console.log("Error while removing user");
                }
            })
            .catch(() => {
                console.log("Server error while removing user");
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.table-container{
    overflow: auto;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}
table {
    width: 100%;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0 12px;
    color: #333;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    tr {
        border-radius: 35px;
        &.inactive{
            td{
                background: none;
                border-top: 1px solid #B2B2B2;
                border-bottom: 1px solid #B2B2B2;
                &:first-child{
                    border-left: 1px solid #B2B2B2;
                }
                &:last-child{
                    border-right: 1px solid #B2B2B2;
                }
            }

        }
    }
    th{
        background: white;
        // padding: 8px 16px;
    }
    td{
    
        background: #E9E9E9;
        &:first-child{
            border-radius: 30px 0 0 30px ;
        }
        &:last-child{
            border-radius: 0px 30px 30px 0px ;
        }
    }
    thead{
        tr{
            position: sticky;
            left: 0;
            top: 0;
        }
        svg{
            margin-left: 8px;
        }
    }

    svg {
        margin-top: -2px;
    }
    .border-left{
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-left: 1px solid #CACACA;
    }
    .centered{
        text-align: center;
        justify-content: center;
    }
    .header-cell{
        padding: 0px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-left: 1px solid #B2B2B2;
        svg{
            cursor: pointer;
            path{
                &.active{
                    fill: #333333;
                }
            }
        }
    }
    .action-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg{
            height: 100%;
            margin: 0;
        }
    }
    .user-name{
        padding: 8px 16px;
    }
    th{
        &:first-child{
            .header-cell{
                border-left: none;
            }
        }
    }

}
</style>