var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"parentRef",staticClass:"image-editor-container",on:{"mouseenter":function($event){return _vm.$emit('disableDrag')},"mouseleave":function($event){return _vm.$emit('enableDrag')},"click":function($event){return _vm.openPreview(_vm.item, _vm.src)}}},[(_vm.type=='cover' && !_vm.readOnly)?_c('div',{staticClass:"image-select",class:{active: _vm.src == ''}},[_c('div',{staticClass:"upload-progress-container"},[_c('span',{style:({width: _vm.uploadProgress + '%'})})]),(_vm.src == '' && !_vm.readOnly)?_c('img',{staticClass:"choose-file-big",attrs:{"src":require("@/assets/images/icons/training-item-upload.svg")},on:{"click":function($event){return _vm.$refs.imageSelect.click()}}}):_vm._e()]):_vm._e(),(!_vm.readOnly && _vm.src != '')?_c('Toolbar',{attrs:{"isEditing":_vm.isEditing,"readOnly":_vm.readOnly,"type":_vm.type,"sliderVal":_vm.sliderVal,"sliderHeightVal":_vm.sliderHeightVal},on:{"sliderChange":_vm.onSliderChange,"sliderHeightChange":_vm.onSliderHeightChange,"rotate":_vm.rotate,"chooseFile":function($event){return _vm.$refs.imageSelect.click()},"deleteImage":_vm.deleteImage,"mouseenter":function($event){return _vm.$emit('disableDrag')},"mouseleave":function($event){return _vm.$emit('enableDrag')}}}):_vm._e(),(!_vm.readOnly)?_c('input',{ref:"imageSelect",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.uploadImage}}):_vm._e(),_c('div',{ref:"container",staticClass:"img-container",style:({height: _vm.type=='cover' ? _vm.height*_vm.windowScale+'px' : _vm.sliderHeightVal+'px', width: _vm.width ? _vm.width*_vm.windowScale+'px' : ''})},[(_vm.containerLoaded)?_c('img',{ref:"gifImage",staticClass:"main-image",class:{'read-only': _vm.readOnly ? _vm.readOnly : false},style:({
                borderRadius: _vm.readOnly && _vm.isImageRounded ? '15px': '0',
                width: _vm.getImageWidth + 'px', 
                height: _vm.getImageHeight + 'px',
                top: (_vm.readOnly ? _vm.imageY : _vm.item[_vm.metadataKey] ? _vm.top : _vm.imageY) + 'px',
                left: (_vm.readOnly ? _vm.imageX : _vm.item[_vm.metadataKey] ? _vm.left : _vm.imageX) + 'px',
                transform: _vm.readOnly && _vm.item[_vm.metadataKey] ?  'rotate('+_vm.item[_vm.metadataKey].rotation*90+'deg)' : 'rotate('+_vm.rotation*90+'deg)'
            }),attrs:{"src":_vm.src,"alt":"","draggable":"false"},on:{"mousedown":_vm.onMouseDown,"mousemove":_vm.onMouseMove,"mouseup":_vm.onMouseUp,"mouseleave":_vm.onMouseUp}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }