<template>
  <div class="modal-window">
    <div class="modal-box">
      <div class="modal-close" @click="closeModal()"><CloseCrossSvg /></div>
        <slot name="modalContent">

        </slot>
    </div>
  </div>
</template>

<script>
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";

export default {
  name: "SimpleModal",
  data: function () {
    return {
    };
  },
  components: {
   CloseCrossSvg
  },
  props: {
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },
  mounted() {
   
  },
};
</script>

<style lang="scss" scoped>
.modal-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background: #1a19196b;
}
.danger {
  .modal-confirmation {
    background: #d45454;
  }
}
.modal-box {
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-align: center;
  position: relative;
  user-select: none;
}
.modal-text {
  width: 62%;
  font-size: 24px;
  margin-top: 24px;
}
.modal-undertext {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

</style>

