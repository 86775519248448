<template>
  <div class="skeleton-loader">
      <div class="test" v-if="layout == 'testOptionsTab'">
          <div class="col">
              <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="260"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="260"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="240"
                color="#fff"
                :rounded="true"
                />
          </div>
          
          <div class="col">
              <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="260"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="250"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="240"
                color="#fff"
                :rounded="true"
                />
          </div>
          <div class="col">
              <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="200"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="250"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="wave"
                :height="20"
                :width="260"
                color="#fff"
                :rounded="true"
                />
          </div>
      </div>
      <div class="user-side-trainings" v-else-if="layout == 'userSideTrainings'">
          <div class="header">
           <vue-skeleton-loader
            type="rect"
            animation="fade"
            :height="40"
            :width="260"
            color="#fff"
            :rounded="true"
            />
            </div>
            <div class="col-container">
                <vue-skeleton-loader
                type="rect"
                animation="fade"
                :height="132"
                :width="132"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="fade"
                :height="132"
                :width="132"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="fade"
                :height="132"
                :width="132"
                color="#fff"
                :rounded="true"
                />
                <vue-skeleton-loader
                type="rect"
                animation="fade"
                :height="132"
                :width="132"
                color="#fff"
                :rounded="true"
                />
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'SkeletonLoader',
    props:{
        layout: String
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
.skeleton-loader{
    width: 100%;
}
.col{
    display: flex;
    flex-direction: column;
    div{
        margin: 0.55rem 0;
    }
    
}
.test{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.user-side-trainings{
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    background: #efefef;
    padding: 1rem;
    border-radius: 1rem;
}
.col-container{
    display: flex;
    justify-content: space-between;

}
.header{
    margin-bottom: 1rem;
}
</style>