import { render, staticRenderFns } from "./ReportTab.vue?vue&type=template&id=55398684&scoped=true&"
import script from "./ReportTab.vue?vue&type=script&lang=js&"
export * from "./ReportTab.vue?vue&type=script&lang=js&"
import style0 from "./ReportTab.vue?vue&type=style&index=0&id=55398684&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55398684",
  null
  
)

export default component.exports