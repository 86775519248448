var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-window"},[(_vm.savingUsersModalOpen)?_c('GlobalModal',{attrs:{"modalInfo":{
      type: 'confirmation',
      text: 'modal.are_you_sure',
      undertext: 'modal.cant_revoke',
      confirmBtnText: 'save_modal.save',
    }},on:{"onConfirm":_vm.completeSave,"onClose":function($event){_vm.savingUsersModalOpen = false}}}):_vm._e(),(_vm.revokeLicenceFromUser!=null)?_c('GlobalModal',{attrs:{"modalInfo":{
      type: 'confirmation',
      text: 'training.licence_revoke_text',
      undertext: 'training.licence_revoke_undertext',
      confirmBtnText: 'training.confirm_licence_revoke_btn',
    }},on:{"onConfirm":_vm.fullyRevokeLicence,"onClose":function($event){_vm.revokeLicenceFromUser = null}}}):_vm._e(),_c('div',{staticClass:"inner-modal"},[_c('div',{staticClass:"overlay"}),_c('span',{staticClass:"closing-icon",on:{"click":_vm.closeModal}},[_c('CloseCrossSvg')],1),_c('div',{staticClass:"item-header underline"},[_c('div',{staticClass:"flex-row"},[_c('span',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.item.name))])])]),_c('UserTrainingList',{key:_vm.cloneGroups.length,ref:"groupList",staticClass:"user-training-list",style:({'margin-top': _vm.sendInvitationsModalActive ? '0px' : -_vm.groupListMarginTop+'px'}),attrs:{"groupHeader":_vm.groupHeader,"loadingGroups":_vm.loadingGroups,"userHeader":_vm.userHeader,"cloneGroups":_vm.cloneGroups,"cloneUsers":_vm.cloneUsers,"usersData":_vm.users,"groupsData":_vm.groups,"item":_vm.item,"mode":"folders"},on:{"revokeLicence":function($event){return _vm.revokeLicence($event)},"addGroupToIndex":_vm.addGroupToIndex,"toggleUser":_vm.toggleUserToRentedTraining,"addUserToList":_vm.addUserToList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }