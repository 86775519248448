<template>
  <div class="title-container" >
        <input
         class="title-input" 
         v-if="group.name_set!=undefined && !group.name_set" 
         type="text" 
         :placeholder="group.newly_added ? toLocal('group.new_input'): toLocal('groups.input_group_name')" 
         v-model="group.name" 
         ref="titleInput"
         v-click-outside-2="editName"
         
        >
        <span 
            @click="startEditingTitle" 
            class="title"
            @mouseover="!(group.name_set!=undefined && !group.name_set) ? showEditIcon=true : showEditIcon=false"
            @mouseleave="showEditIcon=false"
            v-else
        >
            {{group.name}}
        </span>
        <svg v-if="showEditIcon && !(group.name_set!=undefined && !group.name_set)" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.630608 13.9861L3.88713 13.2565H3.91442C4.0673 13.2563 4.21864 13.2259 4.35976 13.1672C4.50088 13.1084 4.62901 13.0224 4.7368 12.914L13.6559 3.99858C13.8763 3.7773 14 3.47778 14 3.16554C14 2.85331 13.8763 2.55378 13.6559 2.3325L11.6718 0.34396C11.4505 0.123673 11.1508 0 10.8385 0C10.5261 0 10.2265 0.123673 10.0051 0.34396L1.0807 9.26472C0.971378 9.37432 0.885531 9.50503 0.828388 9.64888C0.771246 9.79273 0.744004 9.9467 0.748324 10.1014L0.0151472 13.3713C0.00015831 13.4446 0.00015831 13.5202 0.0151472 13.5936C0.0295181 13.6602 0.0570145 13.7234 0.096036 13.7793C0.135056 13.8352 0.184823 13.8828 0.242432 13.9193C0.325458 13.9718 0.421556 14.0001 0.519817 14.0007C0.557682 14.0015 0.595481 13.9972 0.632237 13.9881M10.522 0.861047C10.6059 0.777507 10.7196 0.730607 10.8381 0.730607C10.9565 0.730607 11.0702 0.777507 11.1541 0.861047L13.1386 2.84918C13.1807 2.89004 13.2141 2.93898 13.2369 2.99306C13.2596 3.04714 13.2712 3.10525 13.271 3.16391C13.2709 3.22289 13.2592 3.28127 13.2364 3.3357C13.2137 3.39013 13.1805 3.43953 13.1386 3.48109L12.2787 4.34059L9.65968 1.72259L10.522 0.861047ZM1.8375 9.54199L9.14238 2.23845L11.7615 4.85645L4.45657 12.16L1.8375 9.54199ZM1.12958 11.7553L1.47458 10.2138L3.79141 12.5297L2.13444 12.8994L1.12958 11.7553Z" fill="#333333"/>
            <path d="M4.00813 10.3577C3.96021 10.358 3.91271 10.3486 3.86849 10.3301C3.82426 10.3116 3.78421 10.2845 3.75071 10.2502C3.68231 10.1815 3.6439 10.0886 3.6439 9.99166C3.6439 9.89475 3.68231 9.80179 3.75071 9.73311L9.31145 4.17505C9.34516 4.14065 9.3854 4.11332 9.42982 4.09467C9.47423 4.07602 9.52192 4.06641 9.57009 4.06641C9.61827 4.06641 9.66596 4.07602 9.71037 4.09467C9.75479 4.11332 9.79503 4.14065 9.82874 4.17505C9.89714 4.24372 9.93555 4.33668 9.93555 4.43359C9.93555 4.5305 9.89714 4.62346 9.82874 4.69213L4.268 10.2502C4.23402 10.2845 4.19353 10.3117 4.14891 10.3301C4.10429 10.3486 4.05643 10.358 4.00813 10.3577Z" fill="#333333"/>
        </svg>

  </div>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        group: Object
    },
    data(){
        return{
            showEditIcon: false,
        }
    },
    mounted(){
        if(this.group.name_set!=undefined && !this.group.name_set){
            this.focusTitleInput();
        }
    },
    methods:{
        editName(e){
            let input = this.group.name;
            axios.put( 
                `${this.$store.state.api_link}/groups/${this.group.id}`,
                {
                    name: input
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.$set(this.group, 'name_set', true);
                
            }).catch((error) => {
                console.log(error)
            });
        },
        startEditingTitle(){
            this.$set(this.group, 'name_set',false);
            this.showEditIcon=false
            this.focusTitleInput();
        },
        focusTitleInput(){
            this.$nextTick(()=>{
                let ref = this.$refs.titleInput;
                if(Array.isArray(ref)) ref = ref[0];
                ref.focus();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.title-container{
    display: flex;
    align-items: center;
    width: 100%;
    span{
        width: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #333;
        text-overflow: ellipsis;
        font-family: var(--main-site-font);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }
}
.title-input{
    border-radius: 6px;
    border: 1px solid #E2E2E2;
    background: #FFF;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: var(--main-site-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    overflow: hidden;
    color: #333;
    line-height: 26px; /* 144.444% */
    width: 100%;
    &:focus{
        outline: none;
    }
}
</style>