var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.topic.test && _vm.showTest)?_c('div',{class:[
    'container main--container training',
    { 'side--open': _vm.$store.getters.isSideOpen() } ],style:({
    'max-width': _vm.customSysStyle && _vm.customSysStyle.general ? _vm.customSysStyle.general['width']+'%' : '' 
  })},[(_vm.showTestAnswers)?_c('AnsweredQuestions',{attrs:{"answeredQuestions":_vm.topic.user_answers,"topic":_vm.topic}}):_vm._e(),(!_vm.topic.test_done && _vm.testHasStarted)?_c('div',{staticClass:"training--block test--training--block"},[(
        _vm.topic.result == undefined &&
        _vm.topic.currentquestion > 0 &&
        !_vm.topic.test_done &&
        _vm.testHasStarted
      )?_c('div',{staticClass:"test--arrow arrow--back",on:{"click":function($event){_vm.topic.currentquestion--}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"37.754","height":"95.393","viewBox":"0 0 37.754 95.393"}},[_c('path',{attrs:{"id":"Path_5869","data-name":"Path 5869","d":"M413,322.538l40.8-25.854,40.8,25.854","transform":"translate(-291.684 501.494) rotate(-90)","fill":"none","stroke":"#28345c","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"10","stroke-width":"10"}})])]):_vm._e(),(
        _vm.training.trainingType != 'attest' &&
        _vm.topic.result == undefined &&
        _vm.topic.currentquestion < _vm.topic.items.length - 1 &&
        !_vm.topic.test_done &&
        _vm.testHasStarted &&
        (_vm.topic.items[_vm.topic.currentquestion].type == 5
          ? _vm.topic.items[_vm.topic.currentquestion].correct
          : true)
      )?_c('div',{staticClass:"test--arrow arrow--next",on:{"click":function($event){_vm.topic.currentquestion++}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"37.754","height":"95.393","viewBox":"0 0 37.754 95.393"}},[_c('path',{attrs:{"id":"Path_5870","data-name":"Path 5870","d":"M413,322.538l40.8-25.854,40.8,25.854","transform":"translate(329.438 -406.1) rotate(90)","fill":"none","stroke":"#28345c","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"10","stroke-width":"10"}})])]):_vm._e(),_vm._l((_vm.topic.items),function(question,questionIndex){return _c('div',{key:("question-" + _vm.topicIndex + "-" + questionIndex),class:['content--item--wrapper']},[(
          !(_vm.topic.result != undefined && _vm.topic.testAnswerShowType == 0) &&
          !_vm.topic.test_done &&
          _vm.testHasStarted
        )?_c('div',{class:['content--item', 'content--item--test', _vm.training.trainingType =='attest' ? 'attesting-task' : '']},[_c('div',{key:_vm.topic.result == undefined && _vm.topic.currentquestion != questionIndex,class:[
            'task',
            {
              hidden: _vm.training.trainingType != 'attest' &&
                (_vm.topic.result == undefined &&
                _vm.topic.currentquestion != questionIndex),
            },
            { 'task-5': question.type == 5 } ]},[(_vm.training.trainingType=='attest')?_c('div',{staticClass:"task--number"},[_vm._v(" "+_vm._s(questionIndex+1)+". ")]):_vm._e(),_c('div',{staticClass:"task--text"},[_c('editor',{attrs:{"isEditing":false,"readOnly":true,"value":question.text}})],1),(question.type == 1)?_c('div',[_c('div',{staticClass:"task--answers"},_vm._l((question.answers),function(answer,answerIndex){return _c('div',{key:answerIndex,class:[
                  'task--answer',
                  { selected: answer.selected },
                  {
                    correct:
                      _vm.topic.result != undefined &&
                      answer.correct &&
                      (_vm.topic.testAnswerShowType == 1 ||
                        _vm.topic.testAnswerShowType == 2),
                  },
                  {
                    incorrect:
                      _vm.topic.result &&
                      !answer.correct &&
                      (_vm.topic.testAnswerShowType == 2 ||
                        _vm.topic.testAnswerShowType == 3),
                  } ],on:{"click":function($event){return _vm.selectTaskAnswer(question, answerIndex)}}},[_c('div',{class:['task--answer--letter']},[_vm._v(" "+_vm._s(String.fromCharCode(65 + answerIndex))+" ")]),_c('div',{staticClass:"task--answer--text"},[_vm._v(" "+_vm._s(answer.text)+" ")])])}),0)]):(question.type == 2)?_c('div',[_c('div',{staticClass:"task--images"},_vm._l((question.answers),function(answer,answerIndex){return _c('div',{key:((answer.image) + "-" + answerIndex),class:[
                  'task--image',
                  { big: question.size == 1 },
                  { selected: answer.selected },
                  {
                    correct:
                      _vm.topic.result != undefined &&
                      answer.correct &&
                      (_vm.topic.testAnswerShowType == 1 ||
                        _vm.topic.testAnswerShowType == 2),
                  },
                  {
                    incorrect:
                      _vm.topic.result != undefined &&
                      !answer.correct &&
                      (_vm.topic.testAnswerShowType == 2 ||
                        _vm.topic.testAnswerShowType == 3),
                  } ],on:{"click":function($event){return _vm.selectTaskAnswer(question, answerIndex)}}},[_c('div',{class:['task--answer--letter']},[_vm._v(" "+_vm._s(String.fromCharCode(65 + answerIndex))+" ")]),_c('div',{staticClass:"img-wrapper",staticStyle:{"z-index":"-1"}},[(answer.metadata ? answer.metadata.data : false)?_c('ImageViewer',{attrs:{"src":answer.image != null
                        ? _vm.$store.state.api_uploads_link + answer.image
                        : '',"height":question != undefined
                        ? question.size == 1
                          ? 416.25
                          : 200
                        : undefined,"width":question != undefined
                        ? question.size == 1
                          ? 740
                          : 200
                        : undefined,"metadata":answer.metadata,"size":question.size},on:{"clickCanvas":function($event){return _vm.selectTaskAnswer(question, answerIndex)}}}):_vm._e(),(answer.metadata ? !answer.metadata.data : true)?_c('NewImageEditor',{attrs:{"readOnly":true,"item":answer,"type":"cover","height":question.size == 1 ? 416.25 : 200,"width":question.size == 1 ? 740 : 200,"isEditing":false,"src":_vm.getTrainingFileURL(answer.image)}}):_vm._e()],1)])}),0)]):(question.type == 3)?_c('div',[_c('div',{staticClass:"task--connects task--connects--part1"},[_c('draggable',{staticClass:"list-group",attrs:{"options":{
                  disabled: question.result,
                  animation: 200,
                  group: 'description',
                  swap: true,
                },"move":function (event) { return _vm.handleMove(event, _vm.topicIndex, questionIndex); }},on:{"start":function($event){_vm.answerDrag = true},"end":function($event){return _vm.handleDragEnd(_vm.topicIndex, questionIndex)}},model:{value:(question.selected),callback:function ($$v) {_vm.$set(question, "selected", $$v)},expression:"question.selected"}},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.answerDrag ? 'flip-list' : null}},_vm._l((question.selected),function(answer){return _c('div',{key:answer.id,class:['task--connect']},[_c('div',{class:[
                        'task--connect--part1',
                        {
                          correct:
                            _vm.topic.result &&
                            !answer.incorrect &&
                            (_vm.topic.testAnswerShowType == 1 ||
                              _vm.topic.testAnswerShowType == 2),
                        },
                        {
                          incorrect:
                            _vm.topic.result != undefined &&
                            answer.incorrect &&
                            (_vm.topic.testAnswerShowType == 2 ||
                              _vm.topic.testAnswerShowType == 3),
                        } ]},[_c('div',{class:[
                          'task--connect--temp',
                          { 'task--connect--temp--active': answer.temptext } ]},[_c('div',{staticClass:"text-wrapper"},[_c('div',[_vm._v(" "+_vm._s(answer.temptext)+" ")])])]),_c('div',{staticClass:"text-wrapper"},[_c('div',[_vm._v(" "+_vm._s(answer.part1)+" ")])])])])}),0)],1)],1),_c('div',{staticClass:"task--connects task--connects--part2"},_vm._l((question.answers),function(answer,answer_key){return _c('div',{key:((answer.part2) + "-" + answer_key),class:['task--connect']},[_c('div',{class:[
                    'task--connect--part2',
                    {
                      correct:
                        _vm.topic.result &&
                        !answer.incorrect &&
                        (_vm.topic.testAnswerShowType == 1 ||
                          _vm.topic.testAnswerShowType == 2),
                    },
                    {
                      incorrect:
                        _vm.topic.result != undefined &&
                        answer.incorrect &&
                        (_vm.topic.testAnswerShowType == 2 ||
                          _vm.topic.testAnswerShowType == 3),
                    } ]},[_c('div',{staticClass:"text-wrapper"},[_c('div',[_vm._v(" "+_vm._s(answer.part2)+" ")])])])])}),0)]):(question.type == 4)?_c('div',{class:{'attesting-4':_vm.training.trainingType=='attest'}},[(question.answers && question.answers[0])?_c('editor',{class:['test-task-editor', 'lg'],attrs:{"isEditing":true,"readOnly":false,"showCharInfo":true,"charLimit":1000,"placeholder":_vm.toLocal('test.enter_answer')},model:{value:(question.answers[0].answer_text),callback:function ($$v) {_vm.$set(question.answers[0], "answer_text", $$v)},expression:"question.answers[0].answer_text"}}):_vm._e(),(_vm.training.trainingType=='attest')?_c('TestScoringComponent',{staticClass:"test-attest-4",attrs:{"question":question}}):_vm._e()],1):(question.type == 5)?_c('div',[(question.answers && question.answers[0])?_c('ClickTask',{attrs:{"question":question,"currentQuestion":_vm.topic.currentquestion,"answer":question.answers[0],"readOnly":true}}):_vm._e()],1):_vm._e()])]):_vm._e()])})],2):_vm._e(),(!_vm.testHasStarted)?_c('div',{staticClass:"start-training"},[(_vm.training.trainingType =='attest')?_c('TestAttestingModal',{attrs:{"training":_vm.training,"test":_vm.topic},on:{"closeModal":_vm.goBack}}):_c('TestInfoModal',{attrs:{"displayType":"in-line","test":_vm.topic,"isOpen":true}}),_c('div',{staticClass:"btn retake",class:{'attesting-btn': _vm.training.trainingType =='attest', inactive: !_vm.topic.selectedUser},on:{"click":function($event){return _vm.startTest()}}},[_vm._v(" "+_vm._s(_vm.training.trainingType =='attest' ? _vm.toLocal("tests.start_attesting") : _vm.toLocal("tests.begin"))+" ")])],1):_vm._e(),_c('TestResultModal',{attrs:{"training":_vm.training,"showTestResult":_vm.showTestResult,"topic":_vm.topic,"testHasStarted":_vm.testHasStarted,"showTestAnswers":_vm.showTestAnswers}}),(
      _vm.showEndButton && _vm.currentTopic == _vm.topicIndex && _vm.topic.result != undefined
    )?_c('div',{staticClass:"end--button",on:{"click":function($event){return _vm.$emit('endTraining')}}},[_vm._v(" "+_vm._s(_vm.toLocal("training.end"))+" ")]):_vm._e(),(
      !_vm.showEndButton &&
      _vm.topic.test &&
      _vm.topic.result != undefined &&
      _vm.showTestResult
    )?_c('div',{staticClass:"test-ended-button-container",class:{ row: _vm.topic.resultstatus }},[_c('div',{staticClass:"btn top",class:{ deactivated: _vm.topic.options.testAnswerShowType == 0 },on:{"click":function($event){_vm.topic.options.testAnswerShowType !== 0
          ? (_vm.showTestAnswers = true)
          : '';
        _vm.topic.options.testAnswerShowType !== 0
          ? (_vm.showTestResult = false)
          : '';}}},[_vm._v(" "+_vm._s(_vm.toLocal("training.see_questions"))+" ")]),(
        !_vm.showEndButton &&
        _vm.topic.test &&
        _vm.topic.test_done &&
        _vm.topic.canSolveAgain &&
        !_vm.showTestAnswers
      )?_c('div',{staticClass:"btn restart",class:{ deactivated: _vm.training.trainingType =='attest' ? false : !_vm.topic.testIsChecked },on:{"click":function($event){return _vm.retakeTest()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.training.trainingType=='attest' ? _vm.toLocal('tests.attest_again') : _vm.toLocal("test.test_again"))+" ")])]):_vm._e()]):_vm._e(),(
      !_vm.showEndButton &&
      _vm.topic.test &&
      _vm.topic.test_done &&
      !_vm.showTestResult &&
      !_vm.showTestAnswers
    )?_c('div',{staticClass:"test-ended-button-container"},[_c('div',{staticClass:"btn icon",on:{"click":_vm.goBack}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.602","height":"21.199","viewBox":"0 0 11.602 21.199"}},[_c('path',{attrs:{"id":"Path_6221","data-name":"Path 6221","d":"M20230.473-15605.5l-9.187,9.186,9.188,9.186","transform":"translate(-20220.285 15606.912)","fill":"none","stroke":"#28345c","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.toLocal("training.go_to_start"))+" ")])]),_c('div',{staticClass:"btn top",class:{ deactivated: _vm.topic.options.testAnswerShowType == 0 },on:{"click":function($event){_vm.topic.options.testAnswerShowType !== 0
          ? (_vm.showTestAnswers = true)
          : '';
        _vm.topic.options.testAnswerShowType !== 0
          ? (_vm.showTestResult = false)
          : '';}}},[_vm._v(" "+_vm._s(_vm.toLocal("training.see_questions"))+" ")]),(_vm.topic.testIsChecked)?_c('div',{staticClass:"btn top",class:{ deactivated: !_vm.topic.testIsChecked },on:{"click":function($event){_vm.topic.testIsChecked ? (_vm.showTestResult = true) : ''}}},[_vm._v(" "+_vm._s(_vm.toLocal("training.see_score"))+" ")]):_vm._e()]):_vm._e(),(
      !_vm.showEndButton &&
      _vm.topic.test &&
      _vm.topic.result != undefined &&
      !_vm.showTestResult &&
      _vm.showTestAnswers
    )?_c('div',{staticClass:"end--button",on:{"click":function($event){_vm.showTestAnswers = false;
      _vm.showTestResult = true;}}},[_vm._v(" "+_vm._s(_vm.toLocal("training.go_back"))+" ")]):_vm._e(),(_vm.testHasStarted && !_vm.topic.test_done && (_vm.training.trainingType == 'attest' ? true : (_vm.topic.currentquestion == _vm.topic.items.length - 1)))?_c('div',{staticClass:"end--button",on:{"click":function($event){!_vm.isCheckingTest ? _vm.checkTest(_vm.topic, _vm.topicIndex) : ''}}},[_c('span',[_vm._v(_vm._s(_vm.isCheckingTest ? _vm.toLocal("generic.checking") : _vm.toLocal("training.task_check")))]),(_vm.isCheckingTest)?_c('sync-loader',{attrs:{"loading":true,"size":"5px"}}):_vm._e()],1):_vm._e(),(
      !_vm.showEndButton &&
      _vm.currentTopic == _vm.topicIndex &&
      _vm.topic.result != undefined &&
      !_vm.showTestAnswers
    )?_c('div',{staticClass:"end--button",on:{"click":function($event){return _vm.$emit('nextTopic')}}},[_vm._v(" "+_vm._s(_vm.training && _vm.lastShownTopic == _vm.topic.id ? 'Grįžti į pirkimo langą' : _vm.toLocal("generic.next"))+" ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }