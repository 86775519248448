<template>
  <div class="view-container">
    <div class="tools-container">
      <div class="header-container">
      <SearchHeader v-model="searchTerm" @input="searchList" class="search-box"/>
      <div class="user-count">
        <span>{{ 'Pasirinkta vartotojų: ' }}</span>
        <span class="bold">{{ items ? items.length : 0 }}</span>
      </div>
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th v-for="(header, index) in header" :key="index">
                <div class="header-cell">
                  {{header.label}}
                    <svg @click="setSort(header)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :class="{active:sortBy == header.value && sortDirection == 'desc'}" d="M6.60243 19.7188C6.28496 20.0794 5.75871 20.0974 5.42453 19.7549C5.41617 19.7459 5.39943 19.7278 5.39108 19.7188L0.228004 13.8231C-0.089464 13.4625 -0.0727225 12.8946 0.261454 12.552C0.420188 12.3988 0.620747 12.3086 0.837962 12.3086H11.164C11.6235 12.3086 11.9995 12.7143 11.9995 13.2101C11.9995 13.4445 11.9159 13.6609 11.7739 13.8322L6.60243 19.7188Z" fill="#B2B2B2"/>
                        <path :class="{active:sortBy == header.value && sortDirection == 'asc'}" d="M5.39757 0.281221C5.71504 -0.0793729 6.2413 -0.0974378 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549251 7.28574 0.000549293 6.78992C0.000549313 6.55553 0.0841098 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="#B2B2B2"/>
                    </svg>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <div class="user-list">
          <tr v-for="(item, index) in items" :key="index" class="user" :class="`cu${index+1}`">
            <td v-for="(header, h_index) in header" :key="h_index" class="border-right">
              {{item[header.value]}}
            </td>
          </tr>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SearchHeader from '@/components/layout/users/trainingList/SearchHeader'
export default {
  components:{
    SearchHeader
  },
  props:{
    users: Array,
    item: Object,
  },
  data(){
    return{
      searchTerm: '',
      activeGroup: -1,
      header:[
        {label: 'Vardas', value:'name'},
        {label: 'Pavardė', value:'last_name'},
        {label: 'El. paštas', value:'email'},
      ],
      items: [],
      sortBy: null,
      sortDirection: 'asc'
    }
  },
  mounted(){
    this.getUsers();
  },
  watch:{
    users(){
      this.getUsers();
    }
  },
  methods:{
    searchList(input){
      if(input == ''){
        this.getUsers();
      }else{
        let header = this.header

        this.items = this.items.filter((s_item)=>{
          return s_item[header[0].value] && s_item[header[0].value].toLowerCase().includes(input.toLowerCase()) || 
          s_item[header[1].value] && s_item[header[1].value].toLowerCase().includes(input.toLowerCase()) || 
          s_item[header[2].value] && s_item[header[2].value].toLowerCase().includes(input.toLowerCase());
        })
      }
    },
    setSort(header){
        this.sortBy = header.value;
        this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
        this.sortItems();
    },
    getUsers(){
      //unsure kokioj formoj cia dar gausim userius
      if(this.users.length > 0){
        this.items = this._.cloneDeep(this.users);
      }else{
        this.items = this._.cloneDeep(this.item.condition);
      }
    },
    sortItems(){
      this.getUsers();
      this.searchTerm = ''
      this.items = this.items.sort((a,b)=>{
          return this.sortDirection == 'asc' ?
              a[this.sortBy].localeCompare(b[this.sortBy]) 
              : b[this.sortBy].localeCompare(a[this.sortBy]) 
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-container{
  height: 100%;
  overflow: auto;
  z-index: 3;
  padding-bottom: 50px;
}
.table-container{

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 12px;
}
table{
  width: 100%;
  border-collapse: collapse;
  font-family: var(--main-site-font);
  font-size: 14px;
  th{
    text-align: left;
    padding: 12px;
    font-weight: 400;
    &:first-child{
      border-top-left-radius: 8px;
    }
    &:last-child{
      border-top-right-radius: 8px;
    }
  }
  td{
    border-bottom: 1px solid #7D7D7D;
    padding: 12px;
    font-size:14px;
  }
  tr{
    &:last-child{
      td{
        border-bottom: none
      }
    }
  }
}
.header-cell{
  display: flex;
  align-items: center;
  svg{
    margin-left: 8px;
    cursor: pointer;
    path{
      &.active{
        fill: #7D7D7D;
      }
    }
  }
}
.header-container{
  display: flex;
  align-items: center;
  .user-count{
    font-size: clamp(12px, 1.25vw, 14px);
  }
}
.search-box{
  padding: 0 12px;
}

.list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding-right: 20px;
}
.user-sticky-header{
display: flex;
flex-direction: column;
width: 100%;
&.expanded{
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.user-header{
  width: calc(100% - 40px);
  margin: auto;
  background: #F4F4F4;
  box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  .icon{
    display: flex;
  }
}
}
.user-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    font-size: 14px;
    .user{
      width: 100%;
      display: flex;
      border-radius: 30px;
      // background: #D2C7FD;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12); 
      background-color: hsla(93, 56%, 85%, 1);
      padding: 12px 20px;
      td{
        width: 31%;
        padding: 2px 8px;
        &.border-right{
          &:not(:last-child){
            border-right: 1px solid hsla(0, 0%, 20%, 1)
          }
        }
      }
    }
  }
.bold{
  font-weight: 600;
}
// @for $i from 1 through 15 {
//         .cu#{$i} {
//           background-color: hsla(5 *$i, 10% * $i , 89%, 1);
//         }
//       }
</style>