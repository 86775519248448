<template>
  <div class="toggle-btn" 
    :style="{height: height+'px', width: width+'px'}"
    :class="[
        { 'active': toggledState },
        theme
    ]
    " 
    @click="$emit('changeState', !toggledState)"
  >
      <div 
      class="inner--circle" 
      :style="{
          height: innerHeight+'px', 
          width: innerWidth+'px', 
          left: toggleDirection == 'left' ? 
                    toggledState || toggledState == 1 ? 
                        (height - innerHeight)/2 + 'px' 
                        : 'calc(100% - '+((height - innerHeight)/2+innerWidth) + 'px)' 
                : '',
            right: toggleDirection == 'right' ? 
                    toggledState || toggledState == 1 ? 
                        (height - innerHeight)/2 + 'px' 
                        : 'calc(100% - '+((height - innerHeight)/2+innerWidth) + 'px)' 
            : '',

        }"
      >
          
      </div>
  </div>
</template>

<script>
export default {
    props:{
        toggledState:{
            type: [Boolean, Number],
            default: false
        },
        height:{
            type: Number,
            default: 36
        },
        width:{
            type: Number,
            default: 72,
        },
        innerHeight:{
            type: Number,
            default: 30
        },
        innerWidth:{
            type: Number,
            default: 30
        },
        toggleDirection:{
            type: String,
            default: 'left'
        },
        theme:{
            type: String,
            default: 'white'
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
.toggle-btn{
    position: relative;
    display: flex;
    border-radius: 3rem;
    background: white;
    cursor: pointer;
    overflow: hidden;
    &.gray{
        background: #efefef;
        .inner--circle{
            background: white;
        }
    }
    &.active{
        background: var(--secondary-color);
        .inner--circle{
            background: white;
        }
    }
    &.bidirectional{
        background: #fff;
        .inner--circle{
            background: var(--secondary-color);
        }
        &.active{
            background: #fff;
            .inner--circle{
                background: var(--secondary-color);
            }
        }
    }
    &.mobile-active{
        background: #efefef;
        .inner--circle{
            background: var(--secondary-color);
        }
        &.active{
            background: #CACACA;
            .inner--circle{
                background: var(--secondary-color);
            }
        }
    }

}
.inner--circle{

    position: absolute;
    background: #efefef;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
     transition: left 0.3s, right 0.3s;

}
</style>