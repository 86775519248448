<template>
  <div class="invitations-container">
    <AddNewInvitationsButtons :invitation_types="invitation_types" @inviteUsers="$emit('inviteUsers', $event)"/>
    <InvitationsTable
      :invitations="invitations"
      @viewItem="$emit('viewItem', $event)"
      @toggleItem="$emit('toggleItem', $event)"
      @removeItem="$emit('removeItem', $event)"
      @editItem="$emit('editItem', $event)"
    />
  </div>
</template>

<script>
import AddNewInvitationsButtons from '@/components/layout/trainings/training_users/invitation_list/AddNewInvitationsButtons'
import InvitationsTable from '@/components/layout/trainings/training_users/invitation_list/InvitationsTable'
export default {
    props:{
        invitations:Array,
        invitation_types:Array,
    },
    components:{
        AddNewInvitationsButtons,
        InvitationsTable
    }

}
</script>

<style lang="scss" scoped>
.invitations-container{
    display: flex;
    flex-direction: column;
    z-index: 2;
    max-height: 100%;
    overflow: hidden;
}
</style>