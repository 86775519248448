<template>
  <div class="overlay">
    <div class="modal">
        <div class="loading-overlay" v-if="isLoading">
            <font-awesome-icon icon="spinner" />
        </div>
        <div class="close-btn" @click="$emit('close')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.00391L16.9957 16.9986" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round"/>
                <path d="M17 1L1.00424 17" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <div class="content">
            <div class="header">
                <h3>
                    {{toLocal('groups.new_invitation_link_label')}}
                </h3>
                <p>
                    {{toLocal('groups.new_invitation_link_info_label')}}
                </p>
            </div>
            <div class="options-container">
                <div class="row">
                    <div class="left-col">
                        <span>
                            {{toLocal('groups.invitation_link_duration')}}
                        </span>
                    </div>
                    <div class="right-col">
                        <button :class="[getContrast('secondary_color'), {active: selected_validity_length== 0}]" @click="selected_validity_length=0">
                            {{toLocal('groups.unlimited')}}
                        </button>
                        <span>
                            {{toLocal('training_edit.test_questions_or')}}
                        </span>
                        <CustomDropdown
                            :list="validity_length_options"
                            key-label="value"
                            value-label="label"
                            :class="{'inactive-dropdown': selected_validity_length==0}"
                            :compareChosen="selected_validity_length==0 ? {} : selected_validity_length"
                            :options="{
                                closeOnClick: true,
                                checkbox: false,
                                customDDWidth: 'initial',
                                customDDMaxWidth: '250px',
                                customDDMinWidth: '100%',
                                customMaxHeight: '300px',
                                initial: getInitialText(selected_validity_length),
                                containerClass: 'condition-filter-dropdown',
                                searchBar: false,
                                left:true,
                                showCreateNewBtn: false,
                                valueWidthType: 'dynamic'
                            }"
                            @elClicked="setValue('validity_length', $event)"
                        >
                            <template v-slot:arrow >
                                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z" fill="#7D7D7D"/>
                                </svg>
                            </template>
                        </CustomDropdown>
                        <div class="input-container" v-if="selected_validity_length.value &&selected_validity_length.value==1 ">
                            <input 
                            type="text" 
                            v-model="selectedTimeFrame.input"
                            @input="selectedTimeFrame.input = validateInput(selectedTimeFrame.input)"
                            />
                        </div>
                        <CustomDropdown
                            v-if="selected_validity_length.value &&selected_validity_length.value==1 "
                            :list="timeFrames"
                            key-label="value"
                            value-label="label"
                            :compareChosen="selectedTimeFrame"
                            :options="{
                                closeOnClick: true,
                                checkbox: false,
                                customDDWidth: 'initial',
                                customDDMaxWidth: '250px',
                                customDDMinWidth: '100%',
                                customMaxHeight: '300px',
                                initial: getInitialText(selectedTimeFrame),
                                containerClass: 'condition-filter-dropdown',
                                searchBar: false,
                                left:true,
                                
                                showCreateNewBtn: false,
                                valueWidthType: 'dynamic'
                            }"
                            @elClicked="setValue('time_frame', $event)"
                        >
                            <template v-slot:arrow >
                                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z" fill="#7D7D7D"/>
                                </svg>
                            </template>
                        </CustomDropdown>
                        <date-picker
                            v-if="selected_validity_length.value &&selected_validity_length.value==3 "
                            required
                            v-model="validity_date"
                            :disabledDate="disabledDates"
                            label="date"
                            :placeholder="toLocal('users_side.select_date')"
                            class="group-expiration-date-picker"
                            :class="{picked: validity_date != null && validity_date.length > 0}"
                            type="date"
                            valueType="format"
                        >
                            <template v-slot:icon-calendar>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.630608 13.9861L3.88713 13.2565H3.91442C4.0673 13.2563 4.21864 13.2259 4.35976 13.1672C4.50088 13.1084 4.62901 13.0224 4.7368 12.914L13.6559 3.99858C13.8763 3.7773 14 3.47778 14 3.16554C14 2.85331 13.8763 2.55378 13.6559 2.3325L11.6718 0.34396C11.4505 0.123673 11.1508 0 10.8385 0C10.5261 0 10.2265 0.123673 10.0051 0.34396L1.0807 9.26472C0.971378 9.37432 0.885531 9.50503 0.828388 9.64888C0.771246 9.79273 0.744004 9.9467 0.748324 10.1014L0.0151472 13.3713C0.00015831 13.4446 0.00015831 13.5202 0.0151472 13.5936C0.0295181 13.6602 0.0570145 13.7234 0.096036 13.7793C0.135056 13.8352 0.184823 13.8828 0.242432 13.9193C0.325458 13.9718 0.421556 14.0001 0.519817 14.0007C0.557682 14.0015 0.595481 13.9972 0.632237 13.9881M10.522 0.861047C10.6059 0.777507 10.7196 0.730607 10.8381 0.730607C10.9565 0.730607 11.0702 0.777507 11.1541 0.861047L13.1386 2.84918C13.1807 2.89004 13.2141 2.93898 13.2369 2.99306C13.2596 3.04714 13.2712 3.10525 13.271 3.16391C13.2709 3.22289 13.2592 3.28127 13.2364 3.3357C13.2137 3.39013 13.1805 3.43953 13.1386 3.48109L12.2787 4.34059L9.65968 1.72259L10.522 0.861047ZM1.8375 9.54199L9.14238 2.23845L11.7615 4.85645L4.45657 12.16L1.8375 9.54199ZM1.12958 11.7553L1.47458 10.2138L3.79141 12.5297L2.13444 12.8994L1.12958 11.7553Z" fill="#333333"/>
                                    <path d="M4.00813 10.3577C3.96021 10.358 3.91271 10.3486 3.86849 10.3301C3.82426 10.3116 3.78421 10.2845 3.75071 10.2502C3.68231 10.1815 3.6439 10.0886 3.6439 9.99166C3.6439 9.89475 3.68231 9.80179 3.75071 9.73311L9.31145 4.17505C9.34516 4.14065 9.3854 4.11332 9.42982 4.09467C9.47423 4.07602 9.52192 4.06641 9.57009 4.06641C9.61827 4.06641 9.66596 4.07602 9.71037 4.09467C9.75479 4.11332 9.79503 4.14065 9.82874 4.17505C9.89714 4.24372 9.93555 4.33668 9.93555 4.43359C9.93555 4.5305 9.89714 4.62346 9.82874 4.69213L4.268 10.2502C4.23402 10.2845 4.19353 10.3117 4.14891 10.3301C4.10429 10.3486 4.05643 10.358 4.00813 10.3577Z" fill="#333333"/>
                                </svg>

                            </template>
                        </date-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="left-col">
                        <span>
                            {{toLocal('groups.invited_user_count')}}
                        </span>
                    </div>
                    <div class="right-col">
                        <button :class="[getContrast('secondary_color'),{active:userCount==0}]" @click="userCount=0">
                            {{toLocal('groups.unlimited')}}
                        </button>
                        <span>
                            {{toLocal('training_edit.test_questions_or')}}
                        </span>
                        <div class="input-container">
                            <input 
                                type="text" 
                                v-model="userCount"
                                :class="{inactive: userCount==0}"
                                @input="userCount= validateInput(userCount)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="qr-code-container">
                <div class="left-qr">
                    <div class="input-container link-input">
                        <div class="label">{{toLocal('groups.invitation_link_copy_label')}}</div>
                        <input type="text" readonly="readonly" v-model="invitationLink">
                        <div class="copy-btn" @click="copyToClipboard">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="box" d="M0 6.80078C0 5.69621 0.895431 4.80078 2 4.80078H17.2012C18.3058 4.80078 19.2012 5.69621 19.2012 6.80078V22.002C19.2012 23.1065 18.3058 24.002 17.2012 24.002H2C0.895433 24.002 0 23.1065 0 22.002V6.80078Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2012 6.30078H2C1.72386 6.30078 1.5 6.52464 1.5 6.80078V22.002C1.5 22.2781 1.72386 22.502 2 22.502H17.2012C17.4773 22.502 17.7012 22.2781 17.7012 22.002V6.80078C17.7012 6.52464 17.4773 6.30078 17.2012 6.30078ZM2 4.80078C0.895431 4.80078 0 5.69621 0 6.80078V22.002C0 23.1065 0.895433 24.002 2 24.002H17.2012C18.3058 24.002 19.2012 23.1065 19.2012 22.002V6.80078C19.2012 5.69621 18.3058 4.80078 17.2012 4.80078H2Z" fill="#4D4D4D"/>
                                <path class="box" d="M4.80078 2C4.80078 0.895433 5.69621 0 6.80078 0H22.002C23.1065 0 24.002 0.895431 24.002 2V17.2012C24.002 18.3058 23.1065 19.2012 22.002 19.2012H6.80078C5.69621 19.2012 4.80078 18.3058 4.80078 17.2012V2Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.002 1.5H6.80078C6.52464 1.5 6.30078 1.72386 6.30078 2V17.2012C6.30078 17.4773 6.52464 17.7012 6.80078 17.7012H22.002C22.2781 17.7012 22.502 17.4773 22.502 17.2012V2C22.502 1.72386 22.2781 1.5 22.002 1.5ZM6.80078 0C5.69621 0 4.80078 0.895433 4.80078 2V17.2012C4.80078 18.3058 5.69621 19.2012 6.80078 19.2012H22.002C23.1065 19.2012 24.002 18.3058 24.002 17.2012V2C24.002 0.895431 23.1065 0 22.002 0H6.80078Z" fill="#4D4D4D"/>
                            </svg>
                            <svg v-if="showCopySuccessCheckmark" class="success" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5153 0.216262C16.0313 0.592966 16.1548 1.33175 15.7913 1.86638L10.2214 10.0574L10.2214 10.0574L7.20223 14.4978C6.99391 14.8042 6.65792 14.9903 6.29634 14.9996C5.93475 15.0089 5.59026 14.8403 5.36752 14.545L0.24245 7.75042C-0.146232 7.23512 -0.0581768 6.49089 0.439127 6.08815C0.93643 5.6854 1.65466 5.77664 2.04334 6.29194L6.22039 11.8297L8.35292 8.69331L8.35295 8.69327L13.9228 0.502218C14.2864 -0.0324148 14.9994 -0.160441 15.5153 0.216262Z" fill="#49B357"/>
                            </svg>

                        </div>
                    </div>
                </div>
                <div class="right-qr">
                    <div class="qr-wrapper">

                        <div class="label">
                            {{toLocal('groups.invitation_qr_code_download_label')}}
                        </div>
                        <div class="qr-container">
                            <div class="qr">

                                <qrcode-vue
                                    class="qr-view"
                                    ref="qr"
                                    :key="invitationLink" 
                                    :value="invitationLink" 
                                    :size="116" 
                                    level="H" 
                                />
                                <div class="qr-overlay">
                                    <div class="download-btn" @click="downloadQR" :class="getContrast('secondary_color')">
                                        <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.37014 10.8726C2.12684 10.8726 2.74028 11.5042 2.74028 12.2833V21.9274C2.74028 23.7147 4.15269 25.1786 5.89805 25.1786H28.1019C29.8379 25.1786 31.2597 23.7244 31.2597 21.9274V12.4435C31.2597 11.6644 31.8732 11.0328 32.6299 11.0328C33.3866 11.0328 34 11.6644 34 12.4435V21.9274C34 25.2889 31.3452 28 28.1019 28H5.89805C2.63315 28 0 25.2666 0 21.9274V12.2833C0 11.5042 0.613432 10.8726 1.37014 10.8726Z" fill="#333333"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7797 0C17.5364 0 18.1498 0.631582 18.1498 1.41068V18.4081C18.1498 19.1872 17.5364 19.8188 16.7797 19.8188C16.023 19.8188 15.4096 19.1872 15.4096 18.4081V1.41068C15.4096 0.631582 16.023 0 16.7797 0Z" fill="#333333"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5533 12.9714C23.0884 13.5223 23.0884 14.4155 22.5533 14.9664L18.5544 19.0836C17.5837 20.083 16.0004 20.083 15.0297 19.0836L11.202 15.1426C10.6669 14.5917 10.6669 13.6985 11.202 13.1476C11.737 12.5967 12.6046 12.5967 13.1396 13.1476L16.7921 16.9081L20.6157 12.9714C21.1507 12.4205 22.0183 12.4205 22.5533 12.9714Z" fill="#333333"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import QrcodeVue from 'qrcode.vue'
import moment from "moment";
import axios from 'axios';
export default {
    components:{
        DatePicker,
        CustomDropdown,
        QrcodeVue
    },
    props:{
        item: Object
    },
    data(){
        return{
            QRLink: '',
            validity_length_options:[
                {label: this.toLocal('groups.this_period'), value:1, type: 'dropdown'},
                // {label: 'Kol vartotojas prisijungs:', value:2},
                {label: this.toLocal('groups.until_this_date'), value:3, type: 'datepicker'},
            ],
            selected_validity_length: 0,
            timeFrames: [
                {label: this.toLocal('generic.days_short'), 'value': 1},
                {label: this.toLocal('generic.weeks_short'), 'value': 7},
                {label: this.toLocal('generic.months_short'), 'value': 30},
                {label: this.toLocal('generic.years_short'), 'value': 365},
            ],
            selectedTimeFrame: {label: this.toLocal('generic.months_short'), 'value': 30},
            validity_date: '',
            userCount: 0,
            invitationLink: '',
            isLoading: true,
            qrToken: '',
            showCopySuccessCheckmark:false,
            qrComponent: null
        }
    },
    mounted(){
        this.getQRLink();
    },
    
    watch:{
        selected_validity_length(){
            this.getQRLink();
        },
        selectedTimeFrame(){
            this.getQRLink();
        },
        userCount(){
            this.getQRLink();
        }
    },
    methods:{
        setValue(type, value){
            if(type == 'validity_length'){
                this.selected_validity_length = this.validity_length_options.find(el=>el.value == value)
            }
            if(type == 'time_frame'){
                let input = this.selectedTimeFrame.input;
                this.selectedTimeFrame = this.timeFrames.find(el=>el.value == value)
                if(input){
                    this.$set(this.selectedTimeFrame, 'input', input)
                }
            }
        },
        disabledDates(time){
            var date = moment(String(time)).format('YYYY-MM-DD');
            var d1 = moment().format('YYYY-MM-DD');
            if (date>=d1) {
                return false;
            }
            else return true;
        },
        getInitialText(item){
            if(item.label){
                return item.label
            }
            else{
                return this.toLocal('generic.choose')
            }
        },
        getQRLink(){
            axios.post(`${this.$store.state.api_link}/user/register/link`, {
                item_type: 'groups',
                item_id: this.item.id,
                active_until: this.selectedTimeFrame.value * this.selectedTimeFrame.input,
                count: this.userCount,
                token: this.qrToken
            }, this.$store.getters.axiosConfig())
            .then((response)=>{
                this.isLoading=false;
                this.qrToken = response.data.token
                this.invitationLink = response.data.link;
                this.$nextTick(()=>{
                    if(this.$refs.qr)
                        this.qrComponent = this.$refs.qr.$refs['qrcode-vue'];
                })
            })
            .catch((error)=>{
                this.isLoading=false;
            })
        },
        copyToClipboard(){
             navigator.clipboard.writeText(this.invitationLink);
             this.showCopySuccessCheckmark=true;

            setTimeout(()=>{
                this.showCopySuccessCheckmark=false;
            },2000)
        },
        downloadQR(){
            var link = document.createElement('a');
            link.download = this.toLocal('groups.invitation_file_name')+'.png';
            link.href = this.qrComponent.toDataURL()
            link.click();
        },
        validateInput(input){
            input = input.replace(/\D/g, "");
            let intValue = parseInt(input);
            if(isNaN(intValue)){
                input = 0
            }
            else{
                input = intValue
            }
           return input;
        }

    }
}
</script>

<style lang="scss" scoped>
.overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.534);
    .modal{
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 800px;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        padding: 48px 32px 44px;
    }
}
.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E2E2E2;
}
.link-input{
    max-width: 385px;
    input{
        width: 100%;
        padding: 8px 12px;
        color: #333;
        font-family: var(--main-site-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        min-height: 40px;
        line-height: 24px; /* 150% */
        border-radius: 6px;
        border-bottom: 2px solid #E2E2E2;
        background: #FFF;
        text-align: left;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16) inset;
    }
}
.options-container{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
    border-bottom: 1px solid #E2E2E2;
}
.close-btn{
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    user-select: none;
    z-index: 3;
}
.left-col{
    span{
        font-weight: 600;
    }
}
h3,p{
    color: #333;
    text-align: center;
    font-family: var(--main-site-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    margin: 0;
}
p{
    font-size: 16px;
    
}
.download-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #F4F4F4;
    cursor: pointer;
    &:hover{
        background: #4D4D4D;
        svg{
            path{
                fill: white;
            }
        }
    }
    &:active{
        background: var(--secondary-color);
        &.blackContrast{
            svg{
                fill: #000;
            }
        }
        &.whiteContrast{
            svg{
                fill: #FFF;
            }
        }
        
    }
}
.qr-container{
    position: relative;
    display: flex;
    .qr{
        position: relative;
    }
}
.row{
    display: flex;
    align-items: center;
    gap: 12px;
}
.right-col{
    display: flex;
    align-items: center;
    gap: 12px;

}
.inactive-dropdown{
    border-radius: 6px;
    border: 1px solid #CACACA;
    background: #FFF;
    color: #B2B2B2;
}
button{
    display: flex;
    width: 113px;
    height: 28px;
    padding: 4px 20px;
    justify-content: center;
    align-items: center;
    background: #E2E2E2;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    cursor: pointer;
    border: none;
    user-select: none;
    &:hover{
        background: #4D4D4D;
        color:white;
    }
    &.active,&:active{
        background: var(--secondary-color);
        &.whiteContrast{
            color: white;
        }
        &.blackContrast{
            color: black;
        }
    }
   
}
input{
    width: 56px;
    height: 28px;
    border-radius: 6px;
    border:none;
    border-bottom: 1px solid #7D7D7D;
    background: #FFF;
    text-align: center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16) inset;
    &.inactive{
        border-radius: 6px;
        border-bottom: 1px solid #E2E2E2;
        color: #B2B2B2;
    }
}
.qr-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.85);
}
.loading-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    border-radius: 12px;
    z-index: 2;
    svg{
        width: 50px;
        height: 50px;
    }
}
.input-container{
    position: relative;
    .copy-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        position: absolute;
        right: -48px;
        cursor: pointer;
        display: flex;
        bottom: 0;
        &:hover{
            background: #E2E2E2;
            border-radius: 50%;
            .box{
                fill: #E2E2E2;
            }
        }
        .success{
            position: absolute;
            right: -20px;
        }
    }
    
}
.qr-wrapper{
    margin: auto;
}
.qr-code-container{
    display: flex;
    margin-top: 24px;
    width: 100%;
    .qr{
        border: 2px solid #B2B2B2;
        border-radius: 6px;
        overflow: hidden;

    }
    .qr-container{
    }
    .label{
        margin-bottom: 14px;
    }
    .left-qr{
        display: flex;
        flex-direction: column;
        flex: 5.5
    }
    .right-qr{
        display: flex;
        flex-direction: column;
        flex: 4.5
    }
}
.qr-view{
    display: flex;
}
</style>