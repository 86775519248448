<template>
    <div class="tab--container">
        <div
          class="tab"
          :style="{
            'z-index':
              selectedTabIndex == tab_index ? 5 : tabs.length - tab_index,
          }"
          :class="{ selected: selectedTabIndex == tab_index }"
          v-for="(tab, tab_index) in tabs"
          :key="tab_index"
          v-show="!tab.hidden"
          @click="$emit('selectTab', tab_index)"
        >
          {{ tab.name }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: Array,
        selectedTabIndex: Number
    },
    data(){
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
.tab--container{
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      z-index: 1;
      gap: 12px;
      .tab{
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 5px 0 7px 0;
          width: clamp(175px, 16.25vw, 250px);
          background: white;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          // box-shadow: 3px -4px 7px -2px #cbcbcb;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          color: var(--primary-color);
          &:last-child{
              margin-right: 0;
          }
          &.selected{
              border-bottom: none;
              background-color: #4D4D4D;
              color: white;
            
          }
  
      }
  }
  .tab--content{
    padding: 1rem;
    border: 4px solid white;
    border-top: none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: #efefef;
  }
</style>