<template>
    <div class="modal-window">
  
      <GlobalModal
        v-if="savingUsersModalOpen"
        :modalInfo="{
          type: 'confirmation',
          text: 'modal.are_you_sure',
          undertext: 'modal.cant_revoke',
          confirmBtnText: 'save_modal.save',
        }"
        @onConfirm="completeSave"
        @onClose="savingUsersModalOpen = false"
      />
      <GlobalModal
        v-if="revokeLicenceFromUser!=null"
        :modalInfo="{
          type: 'confirmation',
          text: 'training.licence_revoke_text',
          undertext: 'training.licence_revoke_undertext',
          confirmBtnText: 'training.confirm_licence_revoke_btn',
        }"
        @onConfirm="fullyRevokeLicence"
        @onClose="revokeLicenceFromUser = null"
      />
      <div class="inner-modal" >
        <div class="overlay" >
  
        </div>
        <span @click="closeModal" class="closing-icon"><CloseCrossSvg /></span>
        <div class="item-header underline">
          <div class="flex-row">
            <span class="item-title">{{ item.name }}</span>
            <!-- <div class="flex-column" v-if="item.rented"> -->
              <!-- <span
                class=" small-font flex-column left-aligned"
                v-if="item.rented != undefined && item.rented == 1"
                >
                
                <span class="label">{{ toLocal("modal.owned_licenses") }} </span>
                <span class="big-bold-text"
                  ><span
                    :class="{
                      red: item.license_count == 0 || usableLicenses <= 0,
                    }"
                    >{{ usableLicenses }}</span
                  >
                  / {{ item.license_count }} {{toLocal('general.unit_short')}}</span
                ></span
              > -->
              <!-- <span v-if="item.rented != undefined && item.rented == 1" class="save-btn" @click="buyMore(item.id)"
                >{{ toLocal("trainings.buy_more") }}
                <ArrowRightSvg class="arrow-right"
              /></span> -->
            <!-- </div> -->
          </div>
        </div>
        <UserTrainingList
          :key="cloneGroups.length"
          class="user-training-list"
          ref="groupList"
          :groupHeader="groupHeader"
          :loadingGroups="loadingGroups"
          :userHeader="userHeader"
          :cloneGroups="cloneGroups"
          :cloneUsers="cloneUsers"
          :usersData="users"
          :groupsData="groups"
          :item="item"
          mode="folders"
          :style="{'margin-top': sendInvitationsModalActive ? '0px' : -groupListMarginTop+'px'}"
          @revokeLicence="revokeLicence($event)"
          @addGroupToIndex="addGroupToIndex"
          @toggleUser="toggleUserToRentedTraining"
          @addUserToList="addUserToList"
        />
      </div>
        <!-- <div class="row button-container"  :class="{'drop-shadow': showListShadow}"> -->
          <!-- <div class="send-invitations-btn" @click="sendInvitationsModalActive=true">
            <span>
              {{toLocal('training.send_invitation_btn_label')}}
            </span>
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0212 0.806785C21.1441 1.04493 21.1198 1.33246 20.9586 1.54656L9.1014 17.2927C8.92932 17.5212 8.63519 17.6215 8.35934 17.5458C8.08349 17.47 7.88183 17.2336 7.85057 16.9492L6.879 8.10906L0.234016 2.19835C0.0202773 2.00823 -0.055748 1.7069 0.0422159 1.43814C0.14018 1.16938 0.392281 0.987646 0.678229 0.979662L20.3818 0.429472C20.6497 0.421992 20.8982 0.568643 21.0212 0.806785ZM8.28903 8.16618L9.04532 15.0475L18.0049 3.14944L8.28903 8.16618ZM17.3647 1.90961L2.47626 2.32535L7.64885 6.92636L17.3647 1.90961Z" fill="black"/>
            </svg>
          </div> -->
          <!-- <div class="save-btn-with-undertext" v-if="item.rented">
            <div
              class="save-btn"
              :class="{
                faded: usableLicenses < 0,
              }"
              @click="saveChanges"
            >
              {{ toLocal("settings.save") }}
            </div>
            <span class="save-undertext" v-if="usableLicenses < 0">{{
              toLocal("modal.too_many_users")
            }}</span>
          </div> -->
        <!-- </div>   -->
        <!-- <transition name="expand-invite">
          <send-training-invitations 
            v-if="sendInvitationsModalActive" 
            :key="sendInvitationsModalActive" 
            :training_id="item.id"
            :training="item"
            :users="cloneUsers"
            :groups="cloneGroups"
            @close="sendInvitationsModalActive=false" 
          />
        </transition> -->
      <!-- </div> -->
      <!-- <portal-target name="training-invitation-status">
      </portal-target> -->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import GlobalModal from "./GlobalModal.vue";
  import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
  import CloseCrossFadedSvg from "@/assets/images/icons/close-cross-faded.svg?inline";
  import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
  import ArrowDownSvg from "@/assets/images/icons/arrow-down.svg?inline";
  import OrderBySvg from "@/assets/images/icons/order-by.svg?inline";
  import OrderByAscSvg from "@/assets/images/icons/order-by-asc.svg?inline";
  import OrderByDescSvg from "@/assets/images/icons/order-by-desc.svg?inline";
  import ArrowRightSvg from "@/assets/images/icons/arrow-right.svg?inline";
  import AssignPlusSvg from "@/assets/images/icons/assign-plus.svg?inline";
  import AssignCrossSvg from "@/assets/images/icons/assign-cross.svg?inline";
  import SendTrainingInvitations from "@/components/layout/SendTrainingInvitations.vue";
  import GroupBubbleRow from "@/components/layout/groups/GroupBubbleRow.vue";
  import ToggleButton from "@/components/layout/emails/ToggleButton";
  import UserTrainingList from "@/components/layout/users/UserTrainingList.vue";
  
  export default {
    name: "GroupAssignModal",
    data: function () {
      return {
        groups: [],
        users: [],
        cloneUsers: [],
        cloneGroups: [],
        activatedIndex: -1,
        addedUsersIndex: [],
        fullyAddedGroups: [],
        usableLicenses: 0,
        cloneIndexes: [],
        savingUsersModalOpen: false,
        removedGroups: [],
        searchTerm: "",
        userHeader:[
          {'key':'name', 'label':this.toLocal('generic.name'), class:'name', sortable:true, sortOptions:{type:'text'}},
          {'key':'last_name', 'label':this.toLocal('generic.surname'), class:'last-name', sortable:true, sortOptions:{type:'text'}},
          {'key':'email', 'label':this.toLocal('generic.email'), class:'email', sortable:true, sortOptions:{type:'text'}},
          {'key':'added', 'label':this.toLocal('training.added_to_trainings'), class:'user-groups', sortable:true, sortOptions:{type:'relation'}},
        ],
        groupHeader:[
          {'key':'name', 'label':this.toLocal('generic.group_name'), class:'title-header', sortable:true, sortOptions:{type:'text'}},
          // {'key':'added_user_count', 'label':this.toLocal('training.added_user_count_to_training'), class:'user-count-header', sortable:true, sortOptions:{type:'number'}},
          {'key':'users_count', 'label':this.toLocal('groups.user_count_in_group'), class:'user-count-header', sortable:true, sortOptions:{type:'number'}},
          {'key':'group_full', 'label':this.toLocal('training.added_to_trainings'), class:'actions-header', sortable:true, sortOptions:{type:'relation'}},
        ],
        
        searchingForGroup:true,
        ungroupedUsers: [],
        cloneUngroupedUsers: [],
        listRef: null,
        listHeight: 0,
        loadingGroups:false,
        changingUserStatus: false,
        sendInvitationsModalActive: false,
        revokeLicenceFromUser: null,
        groupListMarginTop: 0
      };
    },
    components: {
      GlobalModal,
      GroupBubbleRow,
      SendTrainingInvitations,
      ToggleButton,
      UserTrainingList,
  
      CloseCrossSvg,
      CloseCrossFadedSvg,
      GroupSearchSvg,
      ArrowDownSvg,
      OrderBySvg,
      OrderByAscSvg,
      OrderByDescSvg,
      ArrowRightSvg,
      AssignPlusSvg,
      AssignCrossSvg,
    },
    props: {
      item: Object,
      type: String,
    },
    mounted() {
      this.usableLicenses = this.item.license_count;
      this.loadGroups();
    },
    computed:{
      showListShadow(){
        if( this.listRef){
            return this.listHeight > this.listRef.clientHeight;
        }
        return null;
      },
    },
    beforeDestroy(){
      this.listRef.removeEventListener('scroll', this.scrollGroupList);
    },
    watch: {
      addedUsersIndex: {
        handler(newVal) {
          if (this.item.rented) {
            this.usableLicenses = this.item.license_count - newVal.length;
            // this.groups.forEach((group) => {
            //   if (
            //     group.users.every((user) => {
            //       return this.addedUsersIndex.includes(user.id);
            //     })
            //   ) {
            //     if (!this.fullyAddedGroups.includes(group.id)) {
            //       this.fullyAddedGroups.push(group.id);
            //     }
            //   } else {
            //     this.fullyAddedGroups.forEach((groupInside, index) => {
            //       if (groupInside == group.id) {
            //         this.fullyAddedGroups.splice(index, 1);
            //       }
            //     });
            //   }
            // });
          }
        },
      },
      searchTerm: {
        handler(newVal, oldVal) {
          if (newVal == "") {
            if(this.searchingForGroup){
              this.groups = _.cloneDeep(this.cloneGroups);
            }
            else{
              this.users = _.cloneDeep(this.cloneUsers);
            }
          } else {
             if(this.searchingForGroup){
              this.groups = this.cloneGroups.filter((group) =>
                group.name.toLowerCase().includes(this.searchTerm.toLowerCase())
              );
             }
             else{
               this.users = this.cloneUsers.filter((user) =>{
                 return user.name.toLowerCase().includes(newVal) 
                 || user.last_name.toLowerCase().includes(newVal)
                 || user.email.toLowerCase().includes(newVal)
               });
             }
          }
        },
      },
      item(newVal, oldVal){
        if(newVal.id != undefined && oldVal.id == undefined){
          this.loadGroups();
          this.usableLicenses = this.item.license_count;
        }
      },
      listRef(){
        if(this.listRef)
          this.listHeight = this.listRef.scrollHeight;
      },
      
    },
    methods: {
      buyMore(id) {
        this.$router.push(`/training-shop?training_id=${id}&rented=true`);
      },
      
      completeSave() {
        if (this.item.rented) {
          axios
            .post(
              `${this.$store.state.api_link}/trainings/assign-rented`,
              { id: this.item.connection_id, users: this.addedUsersIndex },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              this.item.license_count -= this.addedUsersIndex.length;
              this.addedUsersIndex = [];
              this.savingUsersModalOpen = false;
            })
            .catch((error) => {
              this.$store.commit("handleError", { error: error });
            });
        } 
      },
      saveChanges() {
        if (
          this.addedUsersIndex.length != 0 &&
          this.item.license_count >= this.addedUsersIndex.length &&
          this.item.rented
        ) {
          this.savingUsersModalOpen = true;
        } 
      },
      fullyRevokeLicence(){
         axios
            .post(
              `${this.$store.state.api_link}/training/users/${this.item.rented ? this.item.rented : 0}/${this.item.id}/revoke`,
              {
                user_id: this.revokeLicenceFromUser.id,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              let index = this.users.findIndex(el=>el.id == this.revokeLicenceFromUser.id);
              if(index != -1){
                this.$set(this.users[index],'added', false);
              }
              index = this.cloneUsers.findIndex(el=>el.id == this.revokeLicenceFromUser.id);
              if(index != -1){
                this.$set(this.cloneUsers[index], 'added', false);
              }
              this.revokeLicenceFromUser=null;
  
            })
            .catch((error) => {
              this.$store.commit("handleError", { error: error });
            });
      },
      loadGroups() {
        if(!this.item.id) return;
        if(this.loadingGroups) return;
        this.loadingGroups = true;
        axios
          .get(
            `${this.$store.state.api_link}/training/users/${this.item.id}/groups`,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            console.log(response);
            if (response.data.groups != undefined) {
                console.log(response.data);
              this.groups = response.data.groups;
              this.cloneGroups = _.cloneDeep(this.groups);
              this.users = response.data.users;
              console.log(response.data.users);
              this.cloneUsers = _.cloneDeep(this.users);
              
              this.$nextTick(()=>{
                this.listRef = this.$refs.groupList;
                if(this.listRef.$refs.groupList){
                  this.listRef = this.listRef.$refs.groupList;
                }
                console.log(this.listRef);
                this.listRef.addEventListener('scroll', this.scrollGroupList);
              })
              this.loadingGroups = false;
            }
          })
          .catch((error) => {
            this.$store.commit("handleError", { error: error });
            this.loadingGroups = false;
          });
      },
      
      revokeLicence(user){
        this.revokeLicenceFromUser = user;
      },
      closeModal() {
        this.$emit("modalClose");
      },
      scrollGroupList(e){
        if(this.listRef.scrollTop > 350)
          this.groupListMarginTop = 175;
        else this.groupListMarginTop = this.listRef.scrollTop/2;
      },
      toggleUserToRentedTraining(user){
        console.log(user);
              let userWillBeAdded = false;
              let index = this.users.findIndex(el=>el.id == user.id);
              if(index != -1){
                  userWillBeAdded = !user.added;
                  if(userWillBeAdded){
                      if(this.usableLicenses == 0 ) return;
                      this.addedUsersIndex.push(user.id);
                      this.$set(this.users[index],'added',!user.added);
                  }
                  else if(this.addedUsersIndex.includes(user.id)){
                      let u_index = this.addedUsersIndex.findIndex(el=>el == user.id);
                      this.addedUsersIndex.splice(u_index, 1);
                      this.$set(this.users[index],'added',!user.added);
                  }
                  else{
                      this.$set(this.users[index],'added',!user.added);
                  }
              }
              index = this.cloneUsers.findIndex(el=>el.id == user.id);
              if(index != -1){
                  if(userWillBeAdded){
                  if(this.usableLicenses == 0 ) return;
                      this.$set(this.cloneUsers[index],'added',true);
                  }
                  else if(this.addedUsersIndex.includes(user.id)){
                      this.$set(this.cloneUsers[index],'added',false);
                  }
                  else{
                      this.$set(this.cloneUsers[index],'added',false);
                  }
              }
          },
          addGroupToIndex(group) {
            //   if (this.item.rented) {
            //       let filtered_users = this.cloneUsers.filter((user) => {
            //         if(group.id ==0) return true;
            //         return group.users.includes(user.id);
            //       });
            //       let count = group.users_count > this.usableLicenses ? this.usableLicenses : group.users_count;
            //       for(let i = 0; i< count; i++){
            //         if(this.usableLicenses == 0) break;
            //         this.toggleUserToRentedTraining(filtered_users[i]);
            //       }
                  
            //   } 
            let action = group.added ? 'remove' : 'add';
            let user_ids = group.id == 0 ? this.cloneUsers.map(el=>el.id) : group.users;
            let temp_users = this._.cloneDeep(this.cloneUsers);
            let addableGroups = [];
            let removableGroups = [];

            user_ids.forEach(id => {
                let index = temp_users.findIndex(el=>el.id == id);
                if(index != -1){
                if(this.type == 'groups' && temp_users[index].editable)
                    this.$set(temp_users[index], 'added', action=='remove' ? false : true);
                }
            });
            let temp_added_user_count = [];
            for(let i = 0; i< this.groups.length; i++){
                if(this.groups[i].id == 0 ){
                    let added = temp_users.filter(el=>el.added).length;
                    temp_added_user_count[i] = added;
                }else{
                let added = temp_users.filter(el=>el.added && (this.groups[i].users && this.groups[i].users.includes(el.id))).length;
                temp_added_user_count[i] = added;
            }
            if(temp_added_user_count[i] == this.groups[i].users_count){
                addableGroups.push(this.groups[i].id);
            }
            else if(action=='remove'){
                removableGroups.push(this.groups[i].id);
            }
        }
        if(!group.added && group.users_count == 0) action = 'add';
        let updatable_groups = action=='remove' ? removableGroups : addableGroups;
        axios
            .post(
            `${this.$store.state.api_link}/training/group/folders/${action}`,
        {
            group: group.id,
            folder: this.item.id,
            updatable_groups: [group.id]
        },
        this.$store.getters.axiosConfig()
        ).then((response) => {
            let action = group.added ? 'remove' : 'add';
            if(!group.added && group.users_count == 0) action = 'add';
            let user_ids = response.data.user_ids;
            user_ids.forEach(id => {
                let index = this.users.findIndex(el=>el.id == id);
                if(index != -1){
                    if(this.type == 'training' && temp_users[index].editable)
                    this.$set(this.users[index], 'added', action=='remove' ? false : true);
                }
                index = this.cloneUsers.findIndex(el=>el.id == id);
                if(index != -1){
                    if(this.type == 'training' && temp_users[index].editable)
                    this.$set(this.cloneUsers[index],'added', action=='remove' ? false : true);
                }
            });
            if(group.users_count == 0){
                let group_index = this.groups.findIndex(el=>el.id == group.id);
                if(group_index != -1)
                    this.$set(this.groups[group_index],'added', action=='remove' ? false : true);
                group_index = this.cloneGroups.findIndex(el=>el.id == group.id);
                if(group_index != -1)
                    this.$set(this.cloneGroups[group_index],'added', action=='remove' ? false : true);
            }
            else{
                if(updatable_groups.length > 0){
                let index = this.groups.findIndex(el=>el.id == group.id);
                if(index != -1){
                    this.$set(this.groups[index],'added', action=='remove' ? false : true);
                }
                index = this.cloneGroups.findIndex(el=>el.id == group.id);
                if(index != -1){
                    this.$set(this.cloneGroups[index],'added', action=='remove' ? false : true);
                }
                }
            }
            })
            // }
            // if(!group.added && group.users_count == 0) action = 'add';
            // axios
            // .post(
            // `${this.$store.state.api_link}/training/group/${action}`,
            // {
            //     group: group.id,
            //     training: this.item.id,
            //     updatable_groups: [group.id]
            // },
            // this.$store.getters.axiosConfig()
            // )
            // .then((response) => {
            // let action = group.added ? 'remove' : 'add';
            // if(!group.added && group.users_count == 0) action = 'add';
            // let user_ids = response.data.user_ids;
            // user_ids.forEach(id => {
            //     let index = this.users.findIndex(el=>el.id == id);
            //     if(index != -1){
            //         if(this.type == 'training' && temp_users[index].editable)
            //         this.$set(this.users[index], 'added', action=='remove' ? false : true);
            //     }
            //     index = this.cloneUsers.findIndex(el=>el.id == id);
            //     if(index != -1){
            //         if(this.type == 'training' && temp_users[index].editable)
            //         this.$set(this.cloneUsers[index],'added', action=='remove' ? false : true);
            //     }
            // });
            // if(group.users_count == 0){
            //     let group_index = this.groups.findIndex(el=>el.id == group.id);
            //     if(group_index != -1)
            //         this.$set(this.groups[group_index],'added', action=='remove' ? false : true);
            //     group_index = this.cloneGroups.findIndex(el=>el.id == group.id);
            //     if(group_index != -1)
            //         this.$set(this.cloneGroups[group_index],'added', action=='remove' ? false : true);
            // }
            // else{
            //     if(updatable_groups.length > 0){
            //     let index = this.groups.findIndex(el=>el.id == group.id);
            //     if(index != -1){
            //         this.$set(this.groups[index],'added', action=='remove' ? false : true);
            //     }
            //     index = this.cloneGroups.findIndex(el=>el.id == group.id);
            //     if(index != -1){
            //         this.$set(this.cloneGroups[index],'added', action=='remove' ? false : true);
            //     }
            //     }
            // }
            // })
            // .catch((error) => {
            // this.$store.commit("handleError", { error: error });
            // });
          
          },
        addUserToList(group, user){
            let update_to_state = !user.added;
            console.log(update_to_state);
            if(this.changingUserStatus) return;
            this.changingUserStatus = true;
            axios
              .post(
                `${this.$store.state.api_link}/training/folder/${this.item.id}/toggle`,
                {
                  user_id: user.id,
                  state: update_to_state,
                },
                this.$store.getters.axiosConfig()
              )
              .then((response) => {
                console.log(response);
                let index = this.users.findIndex(el=>el.id == user.id);
                if(index !== -1){
                  this.users[index].added = update_to_state;
                  this.selectedGroupUsers;
                }
                index = this.cloneUsers.findIndex(el=>el.id == user.id);
                if(index !== -1){
                  this.$set(this.cloneUsers[index], 'added', update_to_state);
                }
                this.$nextTick(()=>{
                  let g_index = this.groups.findIndex(el=>el.id == group.id);
                  if(g_index !== -1){
                    this.groups.forEach((group)=>{
                      if(group.users && group.users.includes(user.id)){
                        if(update_to_state){
                          group.added_user_count += 1;
                        }else{
                          group.added_user_count -=1;
                        }
                      };
                    })
                    if(this.groups[g_index].added_user_count != this.groups[g_index].users_count){
                      this.$set(this.groups[g_index], 'added', false);
                    }
                    g_index = this.cloneGroups.findIndex(el=>el.id == group.id);
                    if(g_index !== -1){
                      if(this.cloneGroups[g_index].added_user_count != this.cloneGroups[g_index].users_count){
                        this.$set(this.cloneGroups[g_index], 'added', false);
                      }
                    }
                  }
                  let all_group_index = this.groups.findIndex(el=>el.id == 0);
                  if(all_group_index !== -1 && !update_to_state){
                    this.$set(this.groups[all_group_index], 'added', false);
                  }
                  all_group_index = this.cloneGroups.findIndex(el=>el.id == 0);
                  if(all_group_index !== -1 && !update_to_state){
                    this.$set(this.cloneGroups[all_group_index], 'added', false);
                  }
                })
                this.changingUserStatus = false;
              })
              .catch((error) => {
                this.changingUserStatus = false;
                this.$store.commit("handleError", { error: error });
              });
        },
    },
  
  };
  </script>
  
  <style lang="scss" scoped>
  .item-header {
    .save-btn {
      position: relative;
      padding: 10px 43px 10px 27px;
      background-color: rgba(244, 244, 244, 1);
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
      font-weight: 400;
      font-size: 14px;
      border-radius: 38px;
      transition: 0.25s;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .arrow-right {
        position: absolute;
        top: 35%;
        right: 23px;
      }
      &:not(.faded):hover {
        cursor: pointer;
        background: #333333;
        color: #ffffff;
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
    .flex-row{
      width: 100%;
    }
  }
  .modal-window {
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
    margin: 0 auto;
    color: #333333;
    user-select: none;
    padding-top: 11vh;
    &.rent{
      padding-top: 103px;
      height: 100%;
    }
  }
  .icon{
    svg{
      path{
        &.active{
          fill: #333333;
        }
      }
    }
  }
  .inner-modal {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    width: 60vw;
    max-width: 1096px;
    min-width: 920px;
    height: 75vh;
    position: relative;
    .closing-icon {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 16px;
      line-height: 16px;
      z-index: 2;
      cursor: pointer;
    }
    .item-img {
      width: 110px;
      max-width: 110px;
      max-height: 110px;
      min-width: 110px;
      height: 110px;
      border-radius: 8px;
      .item-cover {
        height: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
      }
    }
    .item-title {
      margin-left: 20px;
      font-size: 32px;
      width: 100%;
      line-height: 32px;
  
      font-family: var(--main-site-font);
    }
    &.overlay-active{
      .overlay{
        opacity: 1;
      }
      .main-list{
        opacity: 0;
      }
      .item-upper-header{
        opacity: 0;
      }
    }
  }
  
  
  
  .save-btn {
    padding: 8px 28px;
    background-color: var(--third-color);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    font-weight: 400;
    font-size: 16px;
    border-radius: 38px;
    transition: 0.25s;
    &:not(.faded):hover {
      cursor: pointer;
      background: #333333;
      color: #ffffff;
    }
  }
  .save-undertext {
    font-size: 14px;
    line-height: 20px;
  
    /* identical to box height, or 143% */
    text-align: center;
  
    /* LK fail raudona */
    color: #d45454;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: #00000062;
    transition: opacity 0.3s ease-in-out;
    z-index: 0;
    border-radius: 16px;
    &.active{
      opacity: 1;
    }
  }
  .button-container{
    display: flex;
    justify-content: space-between;
    z-index: 2;
    &.drop-shadow{
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);
    }
  }
  .send-invitations-btn{
    display: flex;
    align-items: center;
    background: #F4F4F4;
    border: 1px solid #333333;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    padding: 8px 20px;
    user-select: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--main-site-font);
    cursor: pointer;
    svg{
      margin-left: 8px;
    }
  }
  
  
  .flex-row {
    display: flex;
    flex-direction: row;
    padding: 40px 0 24px 0;
    margin: 0 40px;
    border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  }
  .button-container{
    padding: 30px 40px;
  }
  .arrow{
    &.active{
      transform: rotate(180deg);
    }
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 27px;
  }
  .label{
    white-space: nowrap;
    margin-bottom: 8px;
  }
  .left-aligned {
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;
  }
  .underline {
  }
  .end {
    justify-content: flex-end;
  }
  .bottom-8 {
    margin-bottom: 8px;
  }
  .action-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 12%;
  }
  .big-bold-text {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .small-font {
    font-size: 14px;
  }
  .flipped {
    transform: rotate(180deg);
  }
  .slide-enter-active {
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }
  
  .slide-leave-active {
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }
  
  .slide-enter-to,
  .slide-leave {
    max-height: 100vh;
    overflow: hidden;
  }
  
  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  .faded {
    opacity: 0.5;
    cursor: not-allowed;
    cursor: no-drop;
  }
  .red {
    color: #d45454;
  }
  .user-training-list{
    display: flex;
    flex: 1;
    height: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;
  }
  .expand-invite-enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  
  .expand-invite-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  
  .expand-invite-enter-to,
  .expand-invite-leave {
    max-height: 100%;
    max-width: 100%;
  
    overflow: hidden;
  }
  
  .expand-invite-enter,
  .expand-invite-leave-to {
    overflow: hidden;
    max-height: 0;
    max-width: 0;
    padding: 0;
    
  }
  .expand-invite-leave-to {
    left: 5px;
    bottom: 5px;
    
  }
  
  //Viso modalo 'sumazinimas', daug kur perpuse maziau or less
  @media (max-width: 1024px){
    .modal-window{
      padding-left: 4vw;
      padding-top: 60px;
    }
    .inner-modal{
        height: 90%;
        .item-header{
          .flex-row{
            padding: 20px 0 12px 0;
            margin: 0 30px;
              .item-img{
              max-width: 55px;
              max-height: 55px;
              min-width: 55px;
              width: 55px;
              height: 55px;
            }
            .item-title{
              display: flex;
              align-items: center;
            }
          }
        }
      }
    .item-main{
      .item-upper-header{
        padding: 0 30px;
        margin: 12px 0;
      }
      .main-list{
        padding: 0 30px 0 30px;
      }
    }
    .button-container{
      padding: 15px 20px;
      .send-invitations-btn{
        padding: 4px 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          width: 16px;
          height: 16px;
        }
      }
    }
    .item-upper-header .search-bar .search-inner{
      padding: 8px 12px;
      width: 200px;
      font-size: 14px;
    }
    .main-list .list{
      gap: 8px;
      margin: 8px 0;
    }
    .list-item-with-users{
      margin: 0;
      .list-item{
        padding: 4px 10px;
      }
    }
  }
  @media (max-width: 960px){
    .inner-modal{
      min-width: 768px;
      width: 92vw;
    }
    .main-list .sort-options .option-with-icon .option{
      min-width: 80px;
    }
  }
  @media (max-width: 768px){
    .modal-window{
      padding-left: 0vw;
    }
    .inner-modal{
     min-width: 95vw;
     width: 95vw;
     .item-img{
      height: 8vh;
      min-height: 64px;
      min-width: 64px;
      width: 8vh;
     }
    }
    .title-header{
      width: 35%;
    }
    .list-item-with-users .list-item .title.bought{
      width: 32%;
    }
    .list-item-with-users .list-item .user-count{
      width: 25%;
    }
  
    .main-list .sort-options .option-with-icon .option{
      min-width: 85px;
    }
    .flex-row{
      margin: 0 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .main-list{
      padding: 0 10px 0 20px;
    }
    .item-upper-header{
      padding: 0 20px;
    }
    .button-container{
      padding: 15px 20px;
    }
  }
  @media (max-width: 635px){
    .item-upper-header{
      flex-direction: column;
      align-items: flex-start;
      .search-type{
        margin-top: 10px;
      }
    }
    .inner-modal .item-title{
      font-size: 20px;
      line-height: 28px;
    }
  }
  </style>
  <style lang="scss">
  .expand-invite-enter-active, expand-invite-leave-active {
    .content, .header{
      opacity: 1;
      transition: all 0.5s;
    }
  }
  .expand-invite-enter,
  .expand-invite-leave-to {
    .content, .header{
      opacity: 0;
    }
    
  }
  .item-upper-header{
    .custom-search-toggle{
      background: #F4F4F4;
      width: 30px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      &.toggle-btn.active {
         background:#F4F4F4;
        .inner--circle{
          background: var(--secondary-color);
        }
      }
       &.toggle-btn{
         .inner--circle{
           background: var(--secondary-color);
         }
       }
    }
  }
  </style>