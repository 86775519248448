<template>
  <div>
        <div class="user-answer-container">
            <div class="user-answer-wrapper">
                <div class="filtered-container" v-if="selectedAnswerFilter==1">

                    <div 
                        class="group"
                        v-for="(group, index) in filteredGroups"
                        :key="index"
                    >
                     <h2>{{group.name}}</h2>
                        <TestCheckAnswersUsers :topic="topic" :test="test" :groups="$store.state.testStats.groups" :users="getUsersByAttr('groups', group.id)" :type="selectedAnswerFilter" />
                    </div>
                </div>
                <div class="filtered-container" v-else-if="selectedAnswerFilter==2">

                    <div 
                        class="group"
                        v-for="(question, index) in filteredQuestions"
                        :key="index"
                    >
                     <h2>{{question.question ? stripHTML(question.question.text) : toLocal('test.answer_doesnt_exist')}}</h2>
                        <TestCheckAnswersUsers :topic="topic" :test="test" :groups="$store.state.testStats.groups" :users="getUsersByAttr('questions', question.id)" :type="selectedAnswerFilter" />
                    </div>
                </div>
                <div class="filtered-container" v-else-if="selectedAnswerFilter==3">

                    <div 
                        class="group"
                        v-for="(date, index) in filteredDates"
                        :key="index"
                    >
                     <h2>{{stripHTML(date.created_at.split(' ')[0])}}</h2>
                        <TestCheckAnswersUsers :topic="topic" :test="test" :groups="$store.state.testStats.groups" :users="getUsersByAttr('date', date.id)" :type="selectedAnswerFilter" />
                    </div>
                </div>
                <TestCheckAnswersUsers v-if="selectedAnswerFilter==0" :topic="topic" :test="test" :groups="$store.state.testStats.groups" :users="$store.state.testStats.users" :type="selectedAnswerFilter" />
            </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
import TestCheckAnswersUsers from "@/components/layout/trainings/training_components/test/TestCheckAnswersUsers.vue";
export default {
    name:'TestCheckAnswersTabContent',
    components:{
        TestCheckAnswersUsers
    },
    data(){
        return{
            expandedIndex: -1,
            stats: [],
            filteredGroups: [],
            filteredUsers: [],
            filteredDates: [],
            filteredQuestions: [],
            groups:[],
            dates:[],
            questions:[],
        }
    },
    props:{
        topic: {
            type: Object,
            required: true
        },
        test:{
            type: Object,
            required: true
        },
        availableTestQuestions: {
            type: Array,
            required: true
        },
        selectedAnswerFilter:{
            type: Number,
            required: true
        },
        selectedItemID:{
            type: Number,
            required: true
        }
    },
    mounted(){
        this.getUserStats();
        this.filterItems(this.$store.state.testCheckAnswersFilterItemID);
    },
    watch:{
        groups:{
            immediate: true,
            handler(){
                this.filteredGroups = this._.cloneDeep(this.$store.state.testStats.groups);
                this.filteredUsers = this._.cloneDeep(this.$store.state.testStats.users);
                this.filteredDates = this._.cloneDeep(this.$store.state.testStats.dates);
                this.filteredQuestions = this._.cloneDeep(this.$store.state.testStats.questions);
            }
        },
        users:{
            immediate: true,
            handler(newData){

            }
        },
        topic:{
            immediate: true,
            handler(){
                //this.questions = this.availableTestQuestions[this.topic.questionsindex].questions;
            }
        },
        selectedAnswerFilter:{
            immediate: true,
            handler(newVal){
                if(this.$store.state.testCheckAnswersFilterItemID == 0){
                    this.filterItems(this.$store.state.testCheckAnswersFilterItemID);
                }
                else this.$store.state.testCheckAnswersFilterItemID = 0;
                // switch(newVal){
                //     case 0:
                //         if(this.$store.state.testStats.groups[0])
                //             this.$store.state.testCheckAnswersFilterItemID = this.$store.state.testStats.groups[0].id;
                //         break;
                //     case 1:
                //         if(this.$store.state.testStats.users[0])
                //              this.$store.state.testCheckAnswersFilterItemID = this.$store.state.testStats.users[0].id;
                //         break;
                //     case 2:
                //          if(this.$store.state.testStats.questions[0])
                //             this.$store.state.testCheckAnswersFilterItemID = this.$store.state.testStats.questions[0].id;
                //         break;
                //     case 3:
                //         if(this.$store.state.testStats.dates[0])
                //             this.$store.state.testCheckAnswersFilterItemID = this.$store.state.testStats.dates[0].id;
                //         break;
                //     default:
                //         break;
                // }
            }
        },
        '$store.state.testCheckAnswersFilterItemID':{
            handler(newVal){
                this.filterItems(newVal);
            }
        },
        '$store.state.testStats':{
            deep:true,
            handler(newData){
               if(this.filteredGroups.length == 0){
                    this.filterItems(this.$store.state.testCheckAnswersFilterItemID);
                }
            }
        },
        selectedItemID:{
            immediate: true,
            handler(newVal){
                if(this.selectedAnswerFilter == 0){
                    if(newVal !=0)
                     this.filteredGroups = this.groups.filter(el=>el.id == newVal);
                    else  this.filteredGroups = this._.cloneDeep(this.groups);
                }
                if(this.selectedAnswerFilter == 1){
                    if(newVal !=0){
                        this.users= this.users.filter(el=>el.id == newVal);
                    }
                    else this.users = this._.cloneDeep(this.users);
                }
            }
        }
    },
    methods:{
        getDetailedStats(user){
            if(user.answered == undefined){
                axios.post(
                    `${this.$store.state.api_link}/training/stats/users/detailed`,
                    {user_id: user.id, test_id: this.test.id, 'user_stats': user.stats},

                    this.$store.getters.axiosConfig()
                    ).then((response) => {
                        this.stats= response.data.test_results;
                        this.stats = Array.from(new Set(this.stats.map(a => a.id)))
                            .map(id => {
                                return this.stats.find(a => a.id === id)
                            });
                        this.stats = this.stats[0].filter(el=>el.type == 4);
                        this.$set(user, 'answered', this.stats);
                    })
                    .catch(() => {
                        console.log("Server error while getting user statistics");
                    });
            }
        },
        filterItems(newVal){
            switch(this.selectedAnswerFilter){
                case 0:
                    this.filteredGroups = this._.cloneDeep(this.$store.state.testStats.groups);
                    if(newVal != 0){
                        this.filteredGroups = this.filteredGroups.filter(el=>el.id == newVal);
                    }
                    break;
                case 1:
                    this.filteredUsers = this._.cloneDeep(this.$store.state.testStats.users);
                    if(newVal != 0){
                        this.filteredUsers = this.filteredUsers.filter(el=>el.id == newVal);
                    }
                    break;
                case 2:
                    this.filteredQuestions = this._.cloneDeep(this.$store.state.testStats.questions);
                    if(newVal != 0){
                        this.filteredQuestions = this.filteredQuestions.filter(el=>el.id == newVal);
                    }
                    break;
                case 3:
                    this.filteredDates = this._.cloneDeep(this.$store.state.testStats.dates);
                    if(newVal != 0){
                        this.filteredDates = this.filteredDates.filter(el=>el.created_at.includes(newVal));
                    }
                    break;
                default:
                    break;
            }
        },
        getUserStats(){
            let groups = this.groups.map(group=>group.id);
            axios.post(
                `${this.$store.state.api_link}/training/stats/users/minimal`,
                {test_id: this.test.id, groups: groups},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.dates = response.data.dates;
                this.questions = response.data.questions;
                this.users = response.data.users;
            })
            .catch((err) => {
                console.log(err);
                console.log("Server error while getting user statistics");
            });
        },
        countWords(e) {
            let text = e.target.value;
            let words = 0;
            if ((text.match(/\S+/g)) != null) {
                words = text.match(/\S+/g).length;
            }
        },
        confirmMark(user){
            axios.post(
                `${this.$store.state.api_link}/training/stats/confirm/answer`,
                {user_id: user.id, answered: user.answered, test_id: this.test.id, user_key: user.training_code},

                this.$store.getters.axiosConfig()
                ).then((response) => {
                   this.$set(user, 'checked', true);
                   this.expandedIndex = -1;
                })
                .catch(() => {
                    console.log("Server error while getting user statistics");
                });
        },
        stripHTML(html) {
            return html.replace(/<(?:.|\n)*?>/gm, '');
        },
        getUsersByAttr(attribute, id){
            if(attribute == 'date') attribute = 'questions'
            return this.$store.state.testStats.users.filter(el=>el[attribute].map(elem=>elem.id).flat().includes(id));
        },
    }
}
</script>

<style lang="scss" scoped>
 
 .filtered-container{
     .group{
         position: relative;
        border: 0.5rem solid white;
        border-radius: 2rem;
        padding: 1rem;
        margin: 1rem 0;
        width: calc(100% + 2rem);
        color: var(--primary-color);
        left: -1rem;
     }
     h2{
         margin: 0 1rem;
         font-size: clamp(20px, 1.7vw, 26px);
         font-weight: 700;
         color: var(--primary-color);
     }
 }
</style>