<template>
  <div class="sms-form">
      <div v-if="!resetPasswordForm" class="sms-verification">
            <div class="back-btn" @click="$emit('goBack')">
                <svg width="43" height="25" viewBox="0 0 43 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.92871 10.2212C0.0183424 11.3953 0.0243172 14.1738 1.93971 15.3397L16.3678 24.122C18.3279 25.315 20.8472 23.9555 20.9258 21.6622L21 19.5V19C21 18.4477 21.4477 18 22 18H41C42.1046 18 43 17.1046 43 16V10C43 8.89543 42.1046 8 41 8H22C21.4477 8 21 7.55228 21 7V3.86514C21 1.51895 18.428 0.0807764 16.4292 1.30927L1.92871 10.2212Z" fill="black"/>
                </svg>

            </div>
            <h3>{{toLocal('sms.enter_code')}}</h3>
            <div class="input-container">
                    <CodeInput type="text" :loading="false" className="sms-verification-input" class="input" v-on:change="onChange" :fieldWidth="40" :fieldHeight="40" v-on:complete="onComplete" />
            </div>
            <span v-if="tokenError != ''">
                {{tokenError}}
            </span>
            <button
                    type="submit"
                    class="btn btn-primary"
                    @click="checkToken"
                    >
                        <span v-if="!tokenCheckLoading">{{toLocal('login.confirm_button')}}</span> 
                        <span v-else> <font-awesome-icon icon="spinner" /></span>
                </button>
        </div>
        <div v-else class="password-reset-modal">
            <label
                for="forgot_password"
                class="col-md-4 col-form-label text-md-right"
              >{{toLocal('generic.password')}}:</label>
            <input id="password" class="form-control" type="password" v-model="password">
            <label
                for="confirm_password"
                class="col-md-4 col-form-label text-md-right"
              >{{toLocal('first_login.repeated')}}:</label>
            <input id="confirm_password" type="password" class="form-control" v-model="passwordConfirm">
            <span v-if="passwordValidationErrors != ''">
                {{passwordValidationErrors}}
            </span>
            <button
                type="submit"
                class="btn btn-primary reset"
                @click="resetPassword"
                >
                        <span v-if="!resetPassLoading">{{toLocal('settings.save')}}</span> 
                        <span v-else> <font-awesome-icon icon="spinner" /></span>
            </button>
        </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import axios from 'axios';
export default {
    name: 'SMSForm',
    props:{
        resetPasswordForm:{
            type: Boolean,
            default: false
        },
        token: {
            type: String,
            default: ''
        }
    },
    components: {
        CodeInput
    },
    data(){
        return{
            password: '',
            tokenError: '',
            passwordConfirm: '',
            passwordValidationErrors: '',
            resetPassLoading: false,
            tokenCheckLoading: false
        }
    },
    methods: {
        onChange(v) {
        },
        onComplete(v) {
            this.$emit('setToken', v);
        },
        checkToken(){
            this.tokenError = '';
            this.tokenCheckLoading= true;
            axios.post(`${this.$store.state.api_link}/user/check-token`, 
            {
    
                token: this.token
            }
            )
            .then((res) => {
                if(res.data.status == 'error'){
                    this.tokenError = res.data.error.message;
                }
                this.tokenCheckLoading= false;
                this.$emit('resetPasswordFormState', true);
                
            })
            .catch((err) => {
                this.tokenCheckLoading= false;
                
              console.log(err);
            });
        },
        resetPassword(){
            this.passwordValidationErrors= '';
            this.resetPassLoading = true;
            axios.post(`${this.$store.state.api_link}/user/reset-password`, 
            {
                password: this.password,
                password_confirmation: this.passwordConfirm,
                token: this.token
            }
            )
            .then((res) => {
                if(res.data.status == 'error'){
                    this.passwordValidationErrors = res.data.message;
                }
                else{
                    this.passwordValidationErrors = this.toLocal('sms.password_save_success')
                    setTimeout(() => {
                        this.$emit('resetPasswordFormState', false);
                        this.$emit('goToStart');
                        this.$emit('setToken', '');
                    }, 2000);
                }
                this.resetPassLoading = false;
            })
            .catch((err) => {
                this.resetPassLoading = false;
              console.log(err);
            });
        }
    }
}
</script>
<style lang="scss">
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_forms.scss";
.sms-verification-input{

    .react-code-input > input{
            color: var(--primary-color);
         &:focus{
            caret-color: var(--primary-color);
            border-color: var(--primary-color);
            color: var(--primary-color);
        }
    }
}
</style>
<style lang="scss" scoped>

.sms-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

}
.sms-verification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.btn{
    margin: 1rem 0;
    color: var(--primary-color);
    &.reset{
        margin-top: 0.5rem;
    }
}
h3{
    text-align: center;
    font-size: clamp(16px, 2vw, 22px);
    margin-top: 2rem;
     color: var(--primary-color)
}
label{
    margin-bottom: 0.125rem;
}
.back-btn{
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    svg{
        height: 100%;
        width: 100%;
        path, rect{
             fill: var(--primary-color)
        }
    }
}
.password-reset-modal{
    display: flex;
    flex-direction: column;

}
.form-control{
    margin-bottom: 0.5rem;

}

</style>
