<template>
  <div class="modal-container">
      <div class="modal">
          <div class="close-btn" @click="$store.state.testDeleteModal.modalOpened = false">
              <span></span><span></span>
          </div>
            <h2>
                {{toLocal('delete_modal.are_you_sure')}}
            </h2>
            <div class="btn-container">
                <div class="btn" @click="acceptPrompt">
                    {{toLocal('delete_modal.yes_delete')}}
                </div>
            </div>
      </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
        }
    },
    methods:{
        acceptPrompt(){
            this.$emit('delete');
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: #1a19196b;
    .modal{
        position: relative;
        border-radius: 1rem;
        padding: 2rem;
        border: 0.25rem solid white;
        background: #efefef;
        .close-btn{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            background: white;
            cursor: pointer;
            span{
                position: absolute;
                height: 80%;
                width: 3px;
                border-radius: 2rem;
                background: var(--primary-color);
                transform: rotate(45deg);
            }
            span:nth-child(2){
                transform: rotate(-45deg);
            }
        }
    }
    
}
.btn-container{
    display: flex;
    justify-content: center;
}
h2{
    color: var(--primary-color);
}
.btn{
    display: block;
    background: var(--secondary-color);
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 2rem;
    cursor: pointer;
    user-select: none;
    &:hover{
        font-weight: 700;
    }
}
</style>