var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training--side--container"},[(_vm.training.content)?_c('div',{staticClass:"training-side-nav"},_vm._l((_vm.training.content),function(trainingTopic,trainingTopicIndex){return _c('div',{key:trainingTopicIndex,class:[
          'training--nav--block',
          trainingTopicIndex == _vm.scrolledTopic ? 'active' : '' ],style:({
            height: (((100 / _vm.training.content.length)*3) + "%"),
            'background-color': ("hsl(" + (41 - (trainingTopicIndex- 1) * 3) + ", 100%, 76%)"),
           'z-index': (5 + trainingTopicIndex),
        }),on:{"click":function($event){return _vm.$emit('gotoTopic',trainingTopicIndex)}}},[(!_vm.isMobileWindow())?_c('span',{staticClass:"training--nav--title"},[_vm._v(" "+_vm._s(trainingTopic.title)+" ")]):_vm._e(),_c('span',{class:[
            'training--nav--indicator',
            trainingTopicIndex == _vm.scrolledTopic ? 'active' : '' ]})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }