import { render, staticRenderFns } from "./TrainingStatsTestView.vue?vue&type=template&id=cd0af954&scoped=true&"
import script from "./TrainingStatsTestView.vue?vue&type=script&lang=js&"
export * from "./TrainingStatsTestView.vue?vue&type=script&lang=js&"
import style0 from "./TrainingStatsTestView.vue?vue&type=style&index=0&id=cd0af954&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd0af954",
  null
  
)

export default component.exports