<template>
   <thead>
        <tr>
            <th 
                v-for="(column, index) in headers" 
                :key="column.show+'-'+index"
                :class="column.class"
                :ref="column.ref"
                v-show="showCol(column)"
                :style="getColumnStyle(column, index)"
            >
                <div class="header-cell" @click="setOrderBy(column)">
                    <input :ref="`edit-input-${column.id}`" v-if="column.isEditingTitle" type="text" v-model="editInputModel" @blur="saveCustomColumnName(column)">
                    <span v-else>
                        {{ column.altName && column.altName.length > 0 ? column.altName : toLocal(column.label) }}
                    </span>
                    <div class="tools-container">
                        <svg v-if="column.sortable" class="sort" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{'active': orderByElement == column.key && orderByDirection == 'desc'}" d="M6.60243 19.7168C6.28496 20.0774 5.75871 20.0955 5.42453 19.7529C5.41617 19.7439 5.39943 19.7258 5.39108 19.7168L0.228004 13.8212C-0.089464 13.4606 -0.0727225 12.8926 0.261454 12.5501C0.420188 12.3968 0.620747 12.3066 0.837962 12.3066H11.164C11.6235 12.3066 11.9995 12.7123 11.9995 13.2081C11.9995 13.4425 11.9159 13.6589 11.7739 13.8302L6.60243 19.7168Z" fill="#E2E2E2"/>
                            <path :class="{'active': orderByElement == column.key && orderByDirection == 'asc'}" d="M5.39757 0.281221C5.71504 -0.0793729 6.2413 -0.0974378 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549251 7.28574 0.000549293 6.78992C0.000549313 6.55553 0.0841098 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="#E2E2E2"/>
                        </svg>
                        <div 
                            v-if="column.filter"
                            class="filter hoverable" 
                            :class="getContrast()" 
                        >
                            <CustomDropdown
                                :list="getAllColValues(column)"
                                :compare-list="valueFilters[column.id]"
                                key-label="name"
                                value-label="name"
                                :options="getFilterOptions(column)"
                                @elAll="toggleAllColumnValueFilters"
                                @elClicked="toggleColumnFilterByValue"
                            />
                            
                        </div>
                        <div class="edit-container" v-if="column.editable">
                             <CustomDropdown
                                :list="editOptions"
                                :compare-list="[]"
                                key-label="key"
                                value-label="name"
                                :options="{
                                    containerClass: 'no-padding-no-bg edit-col-dropdown',
                                    dropdownStyle: {right: '20px', top: '0px'},
                                    left: false,
                                    checkbox: false,
                                    showCreateNewBtn: false,
                                    singleIcon: true,
                                    closeOnClick: true,
                                    compareByValue: true,
                                    customMaxHeight: '300px',
                                    customDDWidth: '500%',
                                    searchBar: false,
                                    searchLocally: false,
                                }"
                                @elClicked="(option) => clickedEditOption(column, option)"
                            >
                                <template v-slot:icon>
                                    <div class="edit-wrapper"  >
                                        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="white"/>
                                            <circle cx="2" cy="8" r="2" transform="rotate(90 2 8)" fill="white"/>
                                            <circle cx="2" cy="14" r="2" transform="rotate(90 2 14)" fill="white"/>
                                        </svg>
                                    </div>
                                </template>
                                <template v-slot:edit >
                                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.07953 0.294498L6.13194 0.400965L9.86362 10.0207L8.89752 10.9979L8.05656 8.83033H3.05459L1.75203 12.2093C1.64071 12.4978 1.34182 12.6569 1.05056 12.6L0.942084 12.5684C0.655791 12.4563 0.497901 12.1551 0.554309 11.8616L0.585675 11.7523L4.96598 0.401978C5.15787 -0.0952488 5.81977 -0.131328 6.07953 0.294498ZM5.54946 2.36521L3.5414 7.5694H7.5685L5.54946 2.36521Z" fill="#333333"/>
                                        <path d="M5.41424 19.9842L8.72347 19.2488H8.7512C8.90655 19.2486 9.06034 19.218 9.20374 19.1588C9.34715 19.0996 9.47735 19.0129 9.58689 18.9036L18.6503 9.91715C18.8743 9.69411 19 9.3922 19 9.07748C19 8.76276 18.8743 8.46085 18.6503 8.2378L16.6342 6.23342C16.4092 6.01138 16.1047 5.88672 15.7873 5.88672C15.4699 5.88672 15.1654 6.01138 14.9404 6.23342L5.87161 15.2252C5.76052 15.3357 5.67329 15.4675 5.61522 15.6125C5.55715 15.7575 5.52947 15.9127 5.53386 16.0686L4.78882 19.3645C4.77359 19.4384 4.77359 19.5147 4.78882 19.5886C4.80342 19.6558 4.83136 19.7194 4.87102 19.7758C4.91067 19.8321 4.96124 19.8801 5.01978 19.9169C5.10415 19.9699 5.20181 19.9983 5.30165 19.999C5.34013 19.9997 5.37854 19.9955 5.4159 19.9863M15.4657 6.75463C15.551 6.67042 15.6665 6.62315 15.7869 6.62315C15.9073 6.62315 16.0228 6.67042 16.1081 6.75463L18.1247 8.7586C18.1675 8.79979 18.2014 8.84911 18.2245 8.90362C18.2477 8.95813 18.2594 9.01671 18.2592 9.07584C18.2591 9.13528 18.2472 9.19413 18.2241 9.24899C18.201 9.30385 18.1672 9.35366 18.1247 9.39554L17.2509 10.2619L14.5894 7.62303L15.4657 6.75463ZM6.64066 15.5047L14.0638 8.143L16.7252 10.7819L9.30212 18.1436L6.64066 15.5047ZM5.92128 17.7357L6.27187 16.1819L8.6262 18.5162L6.9424 18.8889L5.92128 17.7357Z" fill="#333333"/>
                                    </svg>
                                </template>
                                <template v-slot:remove >
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.2207 3.92969H12.6726V13.8717C12.6726 15.8128 10.9978 17.3893 8.93565 17.3992H4.96828C2.90611 17.3992 1.23117 15.8227 1.2207 13.8717V3.92969ZM11.8351 4.71794H2.06866V13.8717C2.06866 15.3793 3.3667 16.5912 4.96828 16.6011H8.93565C10.5372 16.6011 11.8247 15.3793 11.8351 13.8717V4.71794Z" fill="#D45454"/>
                                        <path d="M12.5405 1.94922C12.5719 1.94922 12.6033 1.94922 12.6451 1.94922C13.4512 2.00834 14.0583 2.67838 13.9955 3.43708C13.9327 4.17608 13.2524 4.7377 12.4673 4.70814H1.46553C0.659501 4.70814 0 4.08739 0 3.32868C0 2.56997 0.659501 1.94922 1.46553 1.94922H12.4776H12.5405ZM12.5405 3.91989C12.8859 3.91989 13.1685 3.64398 13.1581 3.31882C13.1581 3.01336 12.9069 2.76703 12.5824 2.73747C12.551 2.73747 12.53 2.73747 12.4986 2.73747H1.44457C1.09913 2.73747 0.816524 2.99366 0.816524 3.31882C0.816524 3.64398 1.08865 3.91002 1.43409 3.91002C1.43409 3.91002 1.4341 3.91002 1.44457 3.91002L12.5405 3.91989Z" fill="#D45454"/>
                                        <path d="M9.52566 2.74763H4.37542L4.27077 2.5013C3.9672 1.79186 4.33361 0.983895 5.0873 0.698149C5.25478 0.629175 5.44322 0.599609 5.63165 0.599609H8.26957C9.08606 0.599609 9.73497 1.22037 9.73497 1.98893C9.73497 2.16629 9.70359 2.33379 9.63031 2.5013L9.52566 2.74763ZM5.01408 1.95937H8.89762C8.88715 1.64407 8.60454 1.39773 8.26957 1.39773H5.63165C5.29667 1.39773 5.02455 1.64407 5.01408 1.95937Z" fill="#D45454"/>
                                        <path d="M4.37968 14.7045C4.14938 14.7045 3.96094 14.5271 3.96094 14.3104V6.3883C3.96094 6.17152 4.14938 5.99414 4.37968 5.99414C4.60997 5.99414 4.79842 6.17152 4.79842 6.3883V14.3104C4.79842 14.5271 4.60997 14.7045 4.37968 14.7045Z" fill="#D45454"/>
                                        <path d="M6.95963 14.7045C6.72934 14.7045 6.54102 14.5271 6.54102 14.3104V6.3883C6.54102 6.17152 6.72934 5.99414 6.95963 5.99414C7.18992 5.99414 7.37837 6.17152 7.37837 6.3883V14.3104C7.37837 14.5271 7.18992 14.7045 6.95963 14.7045Z" fill="#D45454"/>
                                        <path d="M9.53007 14.7045C9.29978 14.7045 9.11133 14.5271 9.11133 14.3104V6.3883C9.11133 6.17152 9.29978 5.99414 9.53007 5.99414C9.76036 5.99414 9.94868 6.17152 9.94868 6.3883V14.3104C9.94868 14.5271 9.76036 14.7045 9.53007 14.7045Z" fill="#D45454"/>
                                    </svg>
                                </template>
                             </CustomDropdown>
                        </div>
                    </div>
                </div>
            </th>
        </tr>
        <portal to="user-column-delete-modal">
            <GlobalModal
                :modalInfo="{
                        text: 'Ar tikrai norite ištrinti šią skiltį?',
                        undertext: 'Ištrintos skilties atkurti nebus galima.',
                        type: 'multiChoice',
                        multiChoiceYes: 'generic.yes',
                        multiChoiceNo: 'generic.no'
                  }"
                @onChoiceOne="deleteCustomColumn"
                @onChoiceTwo="deletingColumn=null"
                @onClose="deletingColumn=null"
                v-if="deletingColumn!=null"
            />
        </portal>
    </thead>
</template>

<script>
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import GlobalModal from '@/components/layout/modals/GlobalModal.vue';

import axios from "axios";
export default {
    components:{
        CustomDropdown,
        GlobalModal
    },
    props:{
        users:{
            type: Array,
        },
        extraColumns: {
            type: Array,
        },
        valueFilters:{
            type: Array,
        }
    },
    data(){
        return{
            orderByElement: null,
            orderByDirection: 'asc',
            colorContrast: this.getContrast(),
            headers: [
                {id: 1, show:true, ref: 'table-col-1', class: 'borderless sticky-cell first-empty-col', style: this.getStickyColStyle },
                {id: 2, show:true, ref: 'table-col-2', class: [this.getContrast(), 'sticky-cell', 'max-130-px'], style: this.getStickyColStyle },
                {id: 3, show:'users.viewable_columns', ref: 'table-col-3', class: [this.getContrast(), 'sticky-cell'], style: this.getStickyColStyle,   type: 'text', key: 'name', label: 'generic.name', sortable: true},
                {id: 4, show:'users.viewable_columns', ref: 'table-col-4', class: [this.getContrast(), 'sticky-cell'], style: this.getStickyColStyle, type: 'text', key: 'last_name', label: 'generic.surname', sortable: true},
                {id: 5, show:'users.viewable_columns', class: this.getContrast(), type: 'text', key: 'email', label: 'generic.email', sortable: true},
                {id: 6, show:'users.viewable_columns', class: this.getContrast(), type: 'text', key: 'phone', label: 'generic.phone', sortable: true},
                {id: 7, show:true, class: this.getContrast(), type: 'text', key: 'hierarchy_node', label: 'generic.department', sortable: true},
                {id: 8, show:true, class: this.getContrast(), type: 'text', key: 'training_count', label: 'group.users_training_count', sortable: true},
                {id: 9, show:true, class: this.getContrast(), type: 'text', key: 'started_percent', label: 'group.users_started_percent', sortable: true},
                {id: 10, show:true, class: this.getContrast(), type: 'text', key: 'ended_percent', label: 'group.users_ended_percent', sortable: true},
                {id: 13, show:true, class: this.getContrast(), type: 'date', key: 'last_appeared_at', label: 'generic.last_appeared_at', sortable: true},
                {id: 11, show: 'users.change_roles', class: this.getContrast(), type: 'text', key: 'role', label: 'group.users_role', sortable: true},
                {id: 12, show:true, class: this.getContrast(), type: 'text', key: 'groups', label: 'users.in_groups', sortable: false},
            ],
            editOptions:[
                {id: 1, name: this.toLocal('generic.rename'), key: 'rename', icon:'edit'},
                {id: 2, name: this.toLocal('generic.delete'), key: 'delete', icon:'remove', class:'danger'},
            ],
            deletingColumn: null,
            editInputModel: '',
            stickyColWidths: [],
            rerenderTable:false
        }
    },
    computed:{
        shownTableColumns(){
            return this.$store.state.system_settings.shownUserTableKeys;
        },
    },
    watch:{
        users(){
            this.setStickyCols();
        },
        extraColumns() {
            this.setExtraColumns();
        },
        stickyColWidths(){
            this.$emit('setStickyColWidths', this.stickyColWidths)
        },
        '$store.state.enabledUserColumnsArray': {
            immediate: true,
            handler() {
                if (this.rerenderTable) {
                    this.$emit('rerenderTable')
                    this.rerenderTable = false;
                }
                this.headers.forEach(header => {
                    if (this.$store.state.enabledUserColumnsArray.includes(header.key)) {
                        this.$set(header,'show',true);
                    }
                    else if(this.$store.state.disabledUserColumnsArray.includes(header.key) ){
                        this.$set(header,'show',false);
                    }
                })
                this.rerenderTable = true;
                // this.$emit('rerenderTable')
            }

        },
      
    },
    mounted(){
        this.setStickyCols();
        this.setHeaders();
        this.setExtraColumns();
    },
    methods:{
        setHeaders(){
            let keys = Object.keys(this.$store.state.system_settings.shownUserTableKeys);
            keys.forEach(key => {
                if(!this.$store.state.system_settings.shownUserTableKeys[key]){
                    let index = this.headers.findIndex(col => col.key == key);
                    if(index != -1){
                        this.headers.splice(index, 1);
                    }
                }
                
            });
        },
      
        setStickyCols(){
             this.$nextTick(()=>{
                let widths = {};
                for (let [key, value] of Object.entries(this.$refs)) {
                    if (/^table-col/.test(key)) {
                        let col = value;
                        if(col){
                            if(Array.isArray(col)){
                                col.forEach(element => {
                                    widths[key] = element.offsetWidth;
                                });
                            }
                            else{
                                widths[key] = col.offsetWidth;
                            }
                        }
                    }
                }
                this.stickyColWidths = widths;
            });
        },
        setExtraColumns(){
            this.extraColumns.forEach(element => {
                let key = element.value;
                let index = this.headers.findIndex(col => col.key ? col.key == key : col.id == element.id);
                if(index == -1){
                    let header_obj = {
                        id: element.id,
                        class: this.getContrast(), 
                        type: element.type, 
                        key: element.value, 
                        label: element.name, 
                        filter: element.filter_by_value,
                        editable: element.editable,
                        show: true
                    };
                    if(element.altName){
                        header_obj.altName = element.altName;
                    }
                    this.headers.push(header_obj);
                }
            })
        },
        setOrderBy(column){
            if(!column.sortable) return
            let key = column.key;
            if(this.orderByElement == key)
                this.orderByDirection = this.orderByDirection == 'desc' ? 'asc' : 'desc';
            else this.orderByDirection = 'asc';
            this.orderByElement = key;
            this.$emit('setOrderBy', this.orderByElement);
        },
        getStickyColStyle(index, refName){
            let total_width = 0;
            if(this.stickyColWidths[refName]){
                let sliced_array = Object.keys(this.stickyColWidths).splice(0, index);
                for(let i = 0; i < sliced_array.length; i++){
                    let width = this.stickyColWidths[sliced_array[i]];
                    total_width += width;
                }
                return {
                    position: 'sticky',
                    left: 'calc(' + total_width+ 'px + 4vw)'
                };
            }
            return '';
        },
        getColumnStyle(column, index){
            if(typeof column.style === 'function'){
                return column.style(index, 'table-col-'+column.id);
            }
            else{
                return column.style;
            }
        },
        getFilterOptions(column){
            return {
                containerClass: 'no-padding-no-bg',
                left: true,
                singleIcon: true,
                checkbox: true,
                showCreateNewBtn: false,
                closeOnClick: false,
                compareByValue: true,
                selectAllChoice: this.toLocal('users.all_columns'),
                customMaxHeight: '300px',
                customDDWidth: '900%',
                searchBar: true,
                searchLocally: true,
                attached: {
                    parentId: column.id,
                    column: column,
                }
            }
        },
        toggleAllColumnValueFilters(value, attached){
            if(value == false){
                this.valueFilters[attached.parentId] = [];
                this.getAllColValues(attached.column).forEach((col)=>{
                    this.valueFilters[attached.parentId].push({
                        id: attached.parentId,
                        name: col.name,
                    });
                });
            }else{
                this.valueFilters[attached.parentId] = [];
            }
            this.filterUsersByValue(attached.parentId);
        },
        toggleColumnFilterByValue(value, attached){
            let parentId = attached.parentId;
            let index = this.valueFilters[parentId].findIndex((filterVal)=> filterVal.name == value);
            if(index != -1){
                this.valueFilters[parentId].splice(index,1);
            }else{
                this.valueFilters[parentId].push({
                    id: parentId,
                    name: value
                });
            }
            this.filterUsersByValue(parentId);
        },
        getAllColValues(column){
            let values = [];
            this.users.forEach((user)=>{
                if(user.info_columns){
                    let user_col = user.info_columns.filter((col)=>{
                        return column.id == col.id;
                    })
                    if(user_col.length > 0){
                        user_col.forEach((col, idx)=>{
                            let isExisting = values.filter((insideCol)=>{
                            return insideCol.name == col.pivot.value
                            })
                            if(isExisting.length == 0){
                                values.push({
                                    id: col.id,
                                    name: col.pivot.value
                                }); 
                            }
                        })
                    }
                }
            })
            return values;
        },
        filterUsersByValue(parent){
            this.$emit('filterUsersByValue', parent);
        },
        clickedEditOption(column, option){
            if(option == 'rename'){
                this.$set(column, 'isEditingTitle', true);
                this.editInputModel = this.toLocal(column.label);
                this.$nextTick(()=>{
                    let input = this.$refs[`edit-input-${column.id}`];
                    if(Array.isArray(input)) input = input[0];
                    input.focus();
                });
            }
            else if(option == 'delete'){
                this.deletingColumn = {
                    column: column,
                    isOpen: true,
                }
            }
        },
        saveCustomColumnName(column){
            let new_name = this.editInputModel;
            axios.put(
                `${this.$store.state.api_link}/user/columns/${column.id}`,
                {
                    value: new_name,
                    key: 'name'
                },
                this.$store.getters.axiosConfig()
            ).then((res)=>{
                this.editInputModel = '';
                this.$set(column,'label',new_name)
                this.$set(column,'key',res.data.column.value)
                this.$set(column,'isEditingTitle',false)
                let index = this.$store.state.userColumns.findIndex((col) => col.id == column.id);
                if(index != -1){
                    this.$set(this.$store.state.userColumns[index], 'name', res.data.column.name);
                    this.$set(this.$store.state.userColumns[index], 'value', res.data.column.value);
                }
            })
            .catch((err)=>{

            })
        },
        deleteCustomColumn(){
            let column = this.deletingColumn.column
            axios.delete(
                `${this.$store.state.api_link}/user/columns/${column.id}`,
                this.$store.getters.axiosConfig()
            ).then((res)=>{
                let index = this.$store.state.userColumns.findIndex((col) => col.id == column.id);
                this.$store.state.userColumns.splice(index, 1);
                this.headers.splice(this.headers.findIndex(col => col.id == column.id), 1);
                this.deletingColumn = null;
                this.$emit('deleteColumn', column.id);
            })
            .catch((err)=>{

            })
        },
        showCol(column) {
            if (typeof column.show == 'string') {
                if (column.show == 'users.viewable_columns') {
                    if (this.$store.state.user_permissions['users.viewable_columns']) {
                        let cols = this.$store.state.user_permissions['users.viewable_columns'].items;
                        let index = this.$store.state.userColumns.findIndex((col) => col.value == column.key);
                        if (index != -1) {
                            return cols.includes(this.$store.state.userColumns[index].id);
                        }
                        else {
                            return false
                        }
                    }
                }
                return this.hasPermission(column.show);
            }
            if (column.show) return true;
            return false;
        }

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_usersViewScoped.scss";
.header-cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border-left: 1px solid white;
}
.filter {
    border-radius: 50%
}
.edit-container{
    display: flex;
    align-items: center;
    .edit-wrapper{
        padding: 8px;
        display: flex;
        cursor: pointer;
        margin-left: 4px;
        &.active{
            background: rgba(255, 255, 255, 0.466);
            border-radius: 5px
        }
    }
}
input{
    width: 100%;
    background: none;
    border: none;
    border-bottom-width: medium;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-bottom: 1px solid white;
    color: white;
    font-size: 16px;
    &:focus{
        outline:none;
        box-shadow: 0px 4px 4px -4px #f2f2f2;
    }
}
.tools-container{
    display: flex;
    align-items: center;
    margin-left: 8px;
}
.user--list thead tr th{
    padding-left: 0;
    padding-right: 0;
}
</style>
<style lang="scss">
@import "@/assets/scss/views/_usersView.scss";
.edit-col-dropdown{
    .dropdown-extended{
        overflow: hidden;
    }
    .dropdown-extended .dropdown-option.with-icon{
        &:hover{
            background: #E2E2E2;
            margin: 0;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
</style>
