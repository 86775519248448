var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-container"},[(_vm.selectedTraining)?_c('AssignModal',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.selectedTraining),expression:"selectedTraining"}],attrs:{"type":"training","item":_vm.selectedTraining},on:{"modalClose":function($event){_vm.selectedTraining=null}}}):_vm._e(),_c('section',{staticClass:"home-section",attrs:{"name":"home"}},[_c('HeaderButtons',{attrs:{"options":{
        showCreateBtn: false, 
        showBackBtn:true,
        mode: 'edit'
      }},on:{"goBack":_vm.goBack}}),_c('div',{class:{
        'container wide--container main--container': true,
        'side--open': _vm.$store.getters.isSideOpen(),
        loaded: _vm.loaded,
      }},[_c('div',{staticClass:"folder-title"},[_c('span',[_vm._v(_vm._s(_vm.toLocal(_vm.trainingGroup.name)))])]),(_vm.trainingGroup != '')?_c('div',{staticClass:"card-container",class:{ flexStart: _vm.trainingGroup.trainings.length < 5 }},[_c('draggable',{staticClass:"inside-container",attrs:{"animation":"200"},on:{"end":function($event){return _vm.onTrainingListChange(_vm.trainingGroup)}},model:{value:(_vm.trainingGroup.trainings),callback:function ($$v) {_vm.$set(_vm.trainingGroup, "trainings", $$v)},expression:"trainingGroup.trainings"}},[_c('transition-group',{staticClass:"centering",attrs:{"type":"transition"}},_vm._l((_vm.trainingGroup.trainings),function(training,trainingIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.trainingGroup != undefined),expression:"trainingGroup != undefined"}],key:("training-" + (_vm.trainingGroup.id) + "-" + trainingIndex),class:[
                'card',
                _vm.$store.getters.hasPermission('training.*')
                  ? 'with--actions'
                  : '' ]},[(training.enabled == 0 || training.expired == 1)?_c('div',{staticClass:"over-cover",class:{ fadedCover: training.expired }},[_c('TrainingEyeSvg')],1):_vm._e(),_c('div',{staticClass:"tag-container"},[(training.enabled == 0 || training.expired == 1)?_c('span',{staticClass:"disabled-tag"},[_vm._v(_vm._s(_vm.toLocal("generic.disabled")))]):_vm._e(),(training.rented == 1 && training.purchase_type == 2)?_c('span',{staticClass:"disabled-tag"},[_vm._v(_vm._s(_vm.toLocal("generic.rented")))]):_vm._e(),(training.purchase_type == 0)?_c('span',{staticClass:"disabled-tag"},[_vm._v(_vm._s(_vm.toLocal('training_shop.free')))]):_vm._e(),(training.valid_until != undefined)?_c('span',{staticClass:"time-tag"},[(training.expired != 1)?_c('span',[_vm._v(_vm._s(_vm.toLocal("generic.remaining"))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.showExpiryDays(training.valid_until))),(training.expired != 1)?_c('span',[_vm._v(" d.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"cover",style:([
                  training.data.cover != ''
                    ? {
                        'background-image': ("url('" + (_vm.getFileUrl(
                          training.data.cover
                        )) + "')"),
                      }
                    : {} ]),on:{"click":function($event){return _vm.openTraining(training.id, training)}}},[(training.enabled != 0)?_c('div',{staticClass:"play-button"},[_c('TrainingEyeSvg')],1):_vm._e(),_c('div',{staticClass:"title"},[_c('span',{staticClass:"card-title"},[_vm._v(_vm._s(training.data.title))]),_c('div',{staticClass:"slider"},[(training.data.time != 0)?_c('span',{staticClass:"slider--time"},[_vm._v(" "+_vm._s(_vm.getTrainingTime(training.data.time))+" ")]):_vm._e(),_c('div',{staticClass:"slider--bar"},[_c('div',{staticClass:"slider--fill",staticStyle:{"width":"0%"}}),_c('div',{staticClass:"slider--blob"})])])])]),(!_vm.$store.getters.hasPermission('training.*'))?_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(training.data.description)+" ")]):_vm._e(),(training.id != -1)?_c('div',{staticClass:"option-dots",on:{"click":function($event){$event.stopPropagation();_vm.activeOptionMenu == training.id
                    ? _vm.closeMenus()
                    : _vm.activeOptionMenu == -50
                    ? (_vm.activeOptionMenu = training.id)
                    : (_vm.activeOptionMenu = -50)}}},[_c('OptionDotsSvg',{staticClass:"themable"})],1):_vm._e(),(
                  _vm.$store.state.user_role == 'admin' &&
                  _vm.activeOptionMenu == training.id)?_c('div',{directives:[{name:"click-outside-2",rawName:"v-click-outside-2",value:(_vm.closeMenus),expression:"closeMenus"}],staticClass:"option-menu-bought"},[(!training.rented == 1)?_c('div',{staticClass:"color option-block",on:{"click":function($event){$event.stopPropagation();return _vm.editTraining(training.id, training)}}},[_c('HomeEditEditTrainingSvg',{staticClass:"stroke"})],1):_vm._e(),(training.expired != 1)?_c('div',{staticClass:"pencil option-block",on:{"click":function($event){$event.stopPropagation();return _vm.openAssignModal(training)}}},[_c('HomeEditOpenGroupSvg',{staticClass:"stroke"})],1):_vm._e(),_c('div',{staticClass:"trash option-block",on:{"click":function($event){$event.stopPropagation();return _vm.trainingStats(training.id, training.rented)}}},[_c('HomeEditStatsSvg',{staticClass:"stroke fill-gray"})],1)]):_vm._e()])}),0)],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }