<template>
    <div class="info-modal-container" v-if="displayType == 'in-line' ? true : isOpen && test.options" :class="{'in-line': displayType == 'in-line'}">
        <div class="info-modal">
            <div class="close-btn" @click="$emit('closeModal')">
                <div>
                    <span></span><span></span>
                </div>
            </div>
            <div class="row">
                <h2>{{this.toLocal('test.info')}}</h2>
            </div>
            <div class="grid-row">
                <div class="grid" v-if="test.options.testLength != 0">
                    <span class="label">{{toLocal('test.length')}}</span>
                    <span class="value"><span>{{test.options.testLength}}</span> {{test.options.testLength != 0 ? test.options.testTimeFormat == 1 ? 'val.' : 'min.' : ''}}</span>
                </div>
                <div class="grid">
                    <span class="label">{{toLocal('test.max_points')}}</span>
                    <span class="value"><span>{{test.options.testMaxScore}}</span></span>
                </div>
                <div class="grid">
                    <span class="label">{{toLocal('test.passable_from')}}</span>
                    <span class="value"><span>{{test.options.testPassedFrom}}</span> %</span>
                </div>
                <div class="grid" v-if="test.options.testSolveCount != 0">
                    <span class="label">{{toLocal('test.solve_count')}}</span>
                    <span class="value"><span>{{test.options.testSolveCount}}</span> {{test.options.testSolveCount != 0 ? textEnd(test.options.testSolveCount, 'times') : ''}}</span>
                </div>
                <!-- <div class="grid">
                    <span class="label">Teisingi atsakymai bus rodomi:</span>
                    <span class="value">{{}}</span>
                </div> -->
                <div class="grid">
                    <span class="label">{{toLocal('test.question_count')}}</span>
                    <span class="value"><span>{{test.options.testShownQuestionCount}}</span></span>
                </div>
            </div>
            <div class="row justify-center"  v-if="displayType != 'in-line'">
                <div class="btn"  @click="$emit('closeModal')">{{toLocal('task.understood')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestInfoModal',
    data(){
        return{
        }
    },
    props:{
        isOpen: {
            type: Boolean,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
        displayType:{
            type: String,
            required: false,
        }
    },
    methods:{
         textEnd(number, type){
            if(number == '' || number == undefined){
                return '';
            }
            let text_array = [];
            if(type == 'score') text_array = [this.toLocal('generic.score_who'), this.toLocal('generic.score_one'), this.toLocal('generic.score_multiple')];
            if(type == 'times') text_array = [this.toLocal('generic.times_who'), this.toLocal('generic.times_one'), this.toLocal('generic.times_multiple')];
            if(type == 'question') text_array = [this.toLocal('generic.questions_who'), this.toLocal('generic.question'), this.toLocal('generic.questions_multiple')];
            let number_array = number.toString().split('');
            let last_number = number_array[number_array.length - 1];
            if(last_number == 0) return text_array[0];
            else if(parseInt(number)> 10 && parseInt(number)<= 19) return text_array[0];
            else if(last_number == 1) return text_array[1];
            else if(last_number> 1 && last_number<= 9) return text_array[2];
        },
    }
}
</script>

<style lang="scss" scoped>
 .info-modal-container{
  position: fixed;
  width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff85;
    z-index: 999;
     &.in-line{
        position: relative;
        z-index: 1;
        background: transparent;
        .close-btn{
            display: none;
        }
        .info-modal{
            background: transparent;
        }
    }
}
h2{
    margin: 0;
    text-align: center;
    font-size: clamp(1em, 2vw, 2.5em);
}
.row{
    display: flex;
    width: 100%;
}
.grid-row{
    display: grid;
    grid-template-rows: 1fr;
    gap: 0.75rem;
    margin-top: 2rem;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    border-bottom: 1px solid;
    padding-left: 1rem;
    color: var(--primary-color);
    .value{
        span{
            font-weight: 700;
        }
    }
}
.info-modal{
    display: flex;
    flex-direction: column;
    position: relative;
    background: #efefef;
    border: 0.25rem solid white;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    max-width: 36rem;
}
.justify-center{
    justify-content: center;
}
.close-btn{
    display: flex;
    position: absolute;
    top:0.25rem;
    right: 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    padding: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }
    span{
        display: flex;
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--primary-color);
        transform: rotate(45deg);
        &:last-child{
            transform: rotate(-45deg);
        }
    }
}
.btn{
    background: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 2rem;
    text-align: center;
    padding: 0.25rem 2rem; 
    margin-top: 1rem;
    cursor: pointer;
    max-width: 36rem;
}
</style>