<template>
    <div class="answer-row">
        <div class="answer-col">
            <div class="answer-col center mb-1">
                <div class="answer-row center">{{toLocal('trainingEdit.test_rate')}}:</div>
                <div class="answer-row score-input" :class="{disabled: question.type == 5}">
                    <input 
                    v-if="question.type != 5"
                    type="text" 
                    v-model="question.set_score" 
                    v-mask="numberMask"
                    @keydown="inputNumbersOnlyInput($event, question)" 
                    @input="inputScore($event, question)"  
                    @blur="$event.target.value == '' ? question.set_score = 0 : ''">
                    <input 
                    v-else-if="question.answers[0] && question.answers[0].clicks"
                    type="text"
                    :value="question.score == 0 ? 0 : question.answers[0].clicks.length/question.score"
                    :readonly="true"
                    >
                </div>
            </div>
            <div class="answer-row">
                <div 
                    :class="{active: question.type != 5 ? question.set_score == question.score : ''}"
                    class="answer-col answer-state center"
                    @click="question.type != 5 ? $set(question, 'set_score', question.score) : ''"
                >
                    {{toLocal('test_user.answered_correct')}}
                    <svg id="Group_7025" data-name="Group 7025" xmlns="http://www.w3.org/2000/svg" width="17.85" height="17.85" viewBox="0 0 17.85 17.85">
                    <ellipse id="Ellipse_463" data-name="Ellipse 463" cx="8.925" cy="8.925" rx="8.925" ry="8.925" transform="translate(0 0)" fill="#45c34a"/>
                    <path id="Path_5921" data-name="Path 5921" d="M-379.365-14993.943l2.662,3.654,4.462-6.793" transform="translate(385.195 15002.29)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </svg>
                </div>
                <div 
                    :class="{active: question.type != 5 ? question.set_score >0 && question.set_score < question.score : false }" 
                    class="answer-col answer-state center"
                    @click="question.type != 5 ? $set(question, 'set_score', question.score - 1) : ''"
                >
                    {{toLocal('test_user.answered_partially')}}
                    <svg id="Group_7293" data-name="Group 7293" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.85" height="17.85" viewBox="0 0 17.85 17.85">
                    <defs>
                        <clipPath id="clip-path">
                        <rect id="Rectangle_2253" data-name="Rectangle 2253" width="12.257" height="12.252" fill="#28345c"/>
                        </clipPath>
                    </defs>
                    <ellipse id="Ellipse_463" data-name="Ellipse 463" cx="8.925" cy="8.925" rx="8.925" ry="8.925" transform="translate(0 0)" fill="#f2db7d"/>
                    <g id="Group_7292" data-name="Group 7292" transform="translate(15.234 2.615) rotate(90)" clip-path="url(#clip-path)">
                        <path id="Path_6020" data-name="Path 6020" d="M12.246,11.7l-.639-2.85V8.828a1.017,1.017,0,0,0-.3-.72L3.5.3A1.032,1.032,0,0,0,2.043.3L.3,2.038A1.032,1.032,0,0,0,.3,3.5l7.811,7.811a1,1,0,0,0,.733.291l2.863.643a.486.486,0,0,0,.195,0,.45.45,0,0,0,.284-.2.46.46,0,0,0,.07-.243.378.378,0,0,0-.011-.1M.755,3.045a.391.391,0,0,1,0-.553L2.5.755A.382.382,0,0,1,2.771.639a.391.391,0,0,1,.278.116l.752.752L1.509,3.8Zm7.6,7.6L1.962,4.252,4.254,1.96l6.393,6.393Zm1.938.621-1.35-.3,2.028-2.028.324,1.45Z" transform="translate(0)" fill="#28345c"/>
                        <path id="Path_6021" data-name="Path 6021" d="M5.508,5.188a.313.313,0,0,1-.094.225.321.321,0,0,1-.453,0L.1.546A.317.317,0,0,1,.1.094a.321.321,0,0,1,.453,0L5.414,4.96a.318.318,0,0,1,.094.227" transform="translate(3.562 3.559)" fill="#28345c"/>
                    </g>
                    </svg>
                </div>
                <div 
                    :class="{active: question.type != 5 ? question.set_score == 0 : false}" 
                    class="answer-col answer-state center"
                    @click="question.type != 5 ? $set(question, 'set_score', 0) : ''"
                >
                    {{toLocal('test_user.answered_incorrect')}}
                    <svg id="Group_7021" data-name="Group 7021" xmlns="http://www.w3.org/2000/svg" width="17.85" height="17.85" viewBox="0 0 17.85 17.85">
                    <ellipse id="Ellipse_463" data-name="Ellipse 463" cx="8.925" cy="8.925" rx="8.925" ry="8.925" transform="translate(0 0)" fill="#ff6969"/>
                    <g id="Group_7020" data-name="Group 7020" transform="translate(8.816 0.277) rotate(45)">
                        <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                        <path id="Path_751" data-name="Path 751" d="M11.775,5.892a.635.635,0,0,1-.187.45.621.621,0,0,1-.45.192H6.53v4.607a.647.647,0,0,1-.642.638.635.635,0,0,1-.45-.187.621.621,0,0,1-.192-.45V6.534H.638A.643.643,0,0,1,.183,5.441.669.669,0,0,1,.638,5.25H5.245V.647A.626.626,0,0,1,5.437.187.634.634,0,0,1,5.887,0,.649.649,0,0,1,6.53.647v4.6h4.607A.65.65,0,0,1,11.775,5.892Z" transform="translate(0 0)" fill="#28345c"/>
                        </g>
                    </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="answer-col width-80" >
            <div class="answer-row label">{{toLocal('training_stats.comment')}}:</div>
            <div class="answer-row">
                <textarea :value="question.comment"  @input="setComment($event, question)" cols="30" rows="10" :placeholder="toLocal('test.enter_comment')"></textarea>
            </div>
            <div class="answer-row flex-end">
                <span class="word-count">{{question.comment ? question.comment.length : '0'}} / {{maxCommentLength}} {{toLocal('generic.symbols_multiple')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  integerLimit: 2

});
export default {
    props: {
        question: Object,
    },
    data() {
        return {
            numberMask,
            maxCommentLength: 150,
        }
    },
    methods: {
        inputNumbersOnlyInput(e, question){
            let start = e.target.selectionStart;
           
            if(e.key == ',') this.$set(question, 'set_score',  [question.set_score.slice(0, start) + '.' + question.set_score.slice(start)].join(''));

        },
        inputScore(e, question){
            let input = e.target.value;
           
            if(parseFloat(input) > question.score){
                input = question.score;
            }
            this.$set(question, 'set_score', input);
        },
        setComment(e, question){
            if(e.target.value.length > this.maxCommentLength){
                e.target.value = e.target.value.substring(0, this.maxCommentLength);
                this.$set(question, 'comment', e.target.value);
            }
            else{
               this.$set(question, 'comment', e.target.value);
            }
           
        },
    }
}
</script>

<style lang="scss" scoped>
 .answer-row{
     display: flex;
     flex-direction: row;
     width: 100%;
     font-size: clamp(12px, 1.1vw, 20px);
     &.center{
        justify-content: center;
        align-items: center;
    }
    background-color: #efefef;
    border-radius: 20px;
    padding: 8px;
 }
 .mb-1{
    margin-bottom: 1rem;
 }
 .answer-col{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.sm{
        width: 5%;
    }
    &.center{
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &.disabled{
        position: relative;
        svg{
            ellipse{
                fill: #aeaeae;
            }
        }
        &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(239, 239, 239, 0.48);
            cursor: not-allowed;
        }
    }
 }
  .score-input{
     display: flex;
     padding: 0.125rem;
     background: none;
     border: 1px solid var(--primary-color);
     border-radius: 1rem;
     width: 3rem;
     input{
         display: flex;
         width: 100%;
         background: none;
         border: none;
         font-weight: 900;
         color: var(--primary-color-opacity-50);
        text-align: center;
        justify-content: center;
        &:focus{
            outline: none;
        }
     }
     &.disabled{
        background: #efefef;
        border-color: #d7d7d7;
        input{
            color: #d7d7d7;
        cursor: not-allowed;

        }
        cursor: not-allowed;

     }
 }
 .word-count{
    font-size: clamp(12px, 1vw, 16px);
    color: var(--primary-color-opacity-50);
}
 textarea{
     border-radius: 0.5rem;
     border:1px solid var(--primary-color);
     background: none;
     width: 100%;
     padding: 0.5rem;
      resize: none;
 }
 .answer-state{
    position: relative;
    background: rgba(255, 255, 255, 0.377);
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 30%;
    margin: 0 auto;
    font-size: clamp(12px, 0.8vw, 20px);
    cursor: pointer;
    &.active{
        background: white;
        &::after{
            display: none;
        }
    }
    &::after{
        display: flex;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.541);
        border-radius: 1rem;
    }
    &:first-child{
        margin-left: 0.3rem;
    }
    &:last-child{
        margin-right: 0.3rem;
    }
}
</style>