<template>
    <div class="modal-overlay" :class="[type]">
        <div class="modal">
            <div class="modal-header">
                <h2>
                    {{ ['confirmations', 'certificate'].includes(type) ? user.name + ' ' + user.last_name : type == 'test' ? training.title : ''}}
                </h2>
                <div class="close-btn" @click="$emit('close')">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.00195L12.9968 12.998" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round"/>
                        <path d="M13 1L1.00318 13" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </div>
            </div>
            <div class="modal-content" v-if="type=='confirmations'">
                <h3 class="training-title">
                    {{ training.title}}
                </h3>
                <span class="confirmations">
                    {{toLocal('training.confirmations_done')}}
                    <strong>
                        {{ buttons.filter((item) => item.stats).length}}/{{buttons.length}}
                    </strong>
                </span>
                <div class="confirm-btn-wrapper">
                    <div class="confirm-btn-container" v-for="(button, index) in buttons" :key="index" :class="{'incomplete': !button.stats}">
                        <span class="btn-example">
                            {{ button ? button.content : '-' }}
                        </span>
                        <div class="right-side">
                          
                            <svg  v-if="button.stats" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="12" fill="url(#paint0_linear_2765_3)"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6373 7.21319C18.0489 7.55402 18.1209 8.18261 17.798 8.61718L11.8538 16.6171C11.6845 16.845 11.4299 16.9842 11.1558 16.9987C10.8818 17.0133 10.6153 16.9017 10.4252 16.6928L6.26422 12.1214C5.90172 11.7232 5.91371 11.0901 6.29101 10.7075C6.6683 10.3249 7.26802 10.3375 7.63052 10.7358L11.0359 14.4771L16.3072 7.3829C16.6301 6.94834 17.2256 6.87235 17.6373 7.21319Z" fill="white"/>
                                <defs>
                                <linearGradient id="paint0_linear_2765_3" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#16EDA4"/>
                                <stop offset="1" stop-color="#33C493"/>
                                </linearGradient>
                                </defs>
                            </svg>
                            <span class="date" v-if="button.stats">
                                {{ button.stats.created_at_formatted }}
                            </span>
                            <span v-if="!button.stats">
                                {{toLocal('generic.waiting')}}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-content" v-else-if="type == 'test'">
                <div
                    class="background--image"
                    :style="{ 'background-image': `url(${getFileUrl(training.cover)})` }"
                    />
                <div class="background--cover" />
                <div class="test-answers-container">
                    <AnsweredQuestions
                        :answeredQuestions="test_results"
                        :topic="null"
                        type="showAll"
                    />
                </div>
            </div>
            <div class="modal-content" v-else-if="type == 'certificate'">
                <CertificatePreview :certificate="training.user_training_stats.certificate"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import AnsweredQuestions from "@/components/layout/trainings/training_components/test/TestUser/AnsweredQuestions.vue";
import CertificatePreview from './CertificatePreview.vue';
export default {
    props: {
        user: Object,
        training: Object,
        type: String,
        selectedItem: Object
    },
    components: {
        AnsweredQuestions,
        CertificatePreview
    },
    data() {
        return{
            buttons: [],
            test_results: [],
        }
    },
    mounted() {
        if (this.type == 'confirmations') { 
            this.getButtons()
        }
        else if(this.type == 'test'){
            this.getTestAnswers()
        }
    },
    methods: {
        getButtons() {
            axios.get(
                `${this.$store.state.api_link}/user/confirmed/tasks?user_id=${this.user.id}&training_id=${this.training.id}`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.buttons = response.data.buttons
            })
            .catch((error) => {
                console.log(error)
            })
        },
        getTestAnswers() {
            let id = this.selectedItem.id
            axios.get(
                `${this.$store.state.api_link}/user/test/answers?user_id=${this.user.id}&training_id=${this.training.id}&stat_id=${id}`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.test_results = response.data.results
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.modal-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    &.test{
        .modal-content{
            position: relative;
            max-height: 78vh;
            max-width: 85vw;
            width: 85vw;
            align-items: center;
            background-color: #efefef;
            padding-top: 1rem;
            padding-bottom: 3rem;
            overflow-y: auto;
        }
    }
}
.modal{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
}
.modal-header{
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 15px 8px 20px;
    background-color: #F4F4F4;
    padding-right: 2rem;
    z-index: 2;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
}
.close-btn{
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
}
h2{
    margin: 0;
    font-weight: 600;
    font-size: clamp(16px, 1.25vw, 18px);
}
.confirm-btn-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 35vh;
    max-width: 665px;
    min-width: 400px;
    width: 60vw;
    overflow-y: auto;
}
.confirm-btn-container{
    display: flex;
    align-items: center;
    gap: 8px;
    .right-side{
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 4;
    }
    &.incomplete{
        .btn-example{
            background-color: white;
        }
        .right-side{
            color: #B2B2B2
        }
    }
}
.modal-content{
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 12px 20px;
}
h3{
    margin: 0;
    font-weight: 500;
    font-size: clamp(16px, 1.25vw, 18px);
}
.btn-example{
    display: flex;
    flex:6;
    padding: 10px;
    background-color: #F4F4F4;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    max-width: 60%;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(14px, 0.8vw, 16px);
}
.test-answers-container{
    max-width: 900px;
    z-index: 2;
    width: 100%;
}
.background--image {
  width: 100%;
  height: 45vh;
  top: -5vh;
  position: absolute;
  background-position: center;
  background-size: cover;
  filter: blur(8px);

  @media (max-width: 768px) {
    filter: none;
    height: 26vh;
  }
}
.background--cover {
  width: 100%;
  height: 80vh;
  top: -37vh;
  position: absolute;
  background: transparent linear-gradient(180deg, #EFEFEF88 0%, #EFEFEF88 54%, #EFEFEFF4 96%, #EFEFEF 100%) 0% 0% no-repeat padding-box;

  @media (max-width: 768px) {
    height: 59vh;
    background: transparent linear-gradient(180deg, #FFF0 0%, #FFF0 54%, #EFEFEF 96%, #EFEFEF 100%) 0% 0% no-repeat padding-box;
  }
}

</style>