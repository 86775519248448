<template>
  <div class="email-template">
       <div v-if="selectedEmail == -1">
            <h2>{{ toLocal('email.select_template') }}</h2>
            <p>{{ toLocal('email.select_template_description') }}</p>
          </div>
          <div 
            v-if="selectedEmail != -1"
            class="email--select--wrapper"
          >
            {{ toLocal('email.template_sen_1') }}
            <select-input
              v-model="selectedReceiverType" 
              :options="static_recipient_types"
              :selectedReceiverType="selectedReceiverType"
              :selectedUsers="selectedUsers"
               width="8rem"
            />
            <select-input
              v-if="selectedReceiverType == toLocal('mail.user') "
              :key="selectedReceiverType"
              v-model="selectedUsers"
              label="fullName"
              multichoice
              :selectedReceiverType="selectedReceiverType"
              :canSelectAll="true"
              :options="allUsers" 
              :selectAllLabel="toLocal('chat.select_all_users')"
              
            />

            <!-- <v-select
              v-if="selectedReceiverType == 'vartotojai iš'"
              v-model="selectedGroupType"
              :options="static_group_types" 
            /> -->
            <!-- <v-select
              v-if="selectedReceiverType == 'vartotojai iš' && selectedGroupType == 'iš grupės'"
              v-model="selectedGroup"
              label="name"
              :options="allGroups" 
            /> -->
            <!-- <v-select
              v-if="selectedReceiverType == 'vartotojai iš' "
              v-model="selectedGroups"
              label="name"
              multiple 
              :options="allGroups" 
            /> -->
            <select-input
              v-if="selectedReceiverType == toLocal('chat.users_from') "
              :key="selectedReceiverType"
              v-model="selectedGroups"
              label="name"
              width="10rem"
              multichoice
              :canSelectAll="true"
              :selectAllLabel="toLocal('tests.all_groups')"
              :options="allGroups" 
            />
            {{getGroupLabel()}}
            <select-input
               v-model="selectedTimeType" 
              :options="static_time_types"
              width="5.2rem"
              @input="disabledTimeInputFunction(selectedTimeUnit)"
             
            />
    
            <select-input
              v-if="selectedReceiverType && selectedTimeType != toLocal('email.template_static_time_type_2')" 
              v-model="selectedTime"
              :options="static_times" 
              width="3rem"
            />
            <select-input
              v-if="selectedReceiverType && selectedTimeType != toLocal('email.template_static_time_type_2')" 
              v-model="selectedTimeUnit"
              :options="static_time_units"
              :selectable="(option) => selectedTimeType == toLocal('mail.every') && option == toLocal('generic.hours_short') ? false : true"
              :disabled="disabledTimeInputFunction"
              width="3rem"
            />
            
            {{ toLocal('email.template_sen_2') }}
            <select-input
              v-model="selectedEvent"
              :options="static_events"
              width="15rem"
              @input="disabledInputFunction(selectedIfTimeType); disabledTimeIfInputFunction(selectedIfTimeType)"
            />
             <select-input
             v-if="(selectedTrainings == 'all' || selectedTrainings.length > 1) && [4,5,6,7,8,9,10,11,12,13,15].map(el=>static_events[el]).includes(selectedEvent)"
              v-model="selectedAtleastOne"
              :options="[4, 7, 10].map(el=>static_events[el]).includes(selectedEvent) ? static_atleast_one : static_atleast_one_version_2"
              width="7rem"
             
            />
           <select-input
              v-if="[4,5,6,7,8,9,10,11,12,13,15].map(el=>static_events[el]).includes(selectedEvent)"
              v-model="selectedTrainings"
              label="title"
              multichoice
              :canSelectAll="true"
              :selectAllLabel="toLocal('mail.all_trainings')"
              :options="allTrainings"
            
            />
            {{[4,5,6,7,8,9,10,11,12,13,15].map(el=>static_events[el]).includes(selectedEvent) ? getTrainingLabel([9, 10].map(el=>static_events[el]).includes(selectedEvent) ? 'test' : 'training') : ''}}
            <select-input
              v-if="[0, 3, 5, 6, 8, 9, 12].map(el=>static_events[el]).includes(selectedEvent)"
              v-model="selectedIfTimeType" 
              :options="static_if_time_types"
              width="5.2rem"
              :selectable="(option) => {return [0, 3, 5, 6, 8, 9].map(el=>static_events[el]).includes(selectedEvent) && option == toLocal('mail.each') || static_events[12] == selectedEvent && option == toLocal('mail.after')}"
              :disabled="disabledInputFunction"
            />
            <select-input

              v-if="[0, 3, 5, 6, 8, 9, 12].map(el=>static_events[el]).includes(selectedEvent)" 
              v-model="selectedIfTime"
              width="3rem"
              :options="static_times" 
            />
            <select-input
               v-if="[0, 3, 5, 6, 8, 9, 12].map(el=>static_events[el]).includes(selectedEvent)" 
              v-model="selectedIfTimeUnit"
              width="3rem"
              :options="static_time_units"
              :disabled="disabledTimeIfInputFunction"
            />
          </div>
          <div class="selected--users"  v-if="selectedEmail != -1">
            <div class="btn--multi"  @click="sideOpen = true">
              <!-- <div v-if="selectedReceiverType == 'vartotojai iš'">Gavėjai</div>
              <div v-else>{{selectedUsers == 'all' ? allUsers.length : selectedUsers.length}} {{getUserReceiverLabel(selectedUsers == 'all' ? allUsers.length : selectedUsers.length)}}</div> -->
              <div>{{toLocal('chat.view_recipients')}}</div>
            </div>
          </div>
          <div 
            v-if="selectedEmail != -1"
            class="email--input--wrapper"
          >

             <editor 
                class="editor-parent-container"
                :isEditing="true"
                :readOnly="false"
                :signature="{text: $store.state.emailForming.signature, show: true, editing: selectedEmail.editing, enabled: selectedEmail.signature_enabled}"
                v-model="selectedEmail.text"
                @editSignature="$set(selectedEmail, 'editing', $event); $event == false ? saveSignature($store.state.emailForming.signature) : ''"
                @enableSignature="$set(selectedEmail, 'signature_enabled', $event)"
            />
            <div class="button-container">
               <div class="toggle-container">
                <span class="label">{{ toLocal('mail.auto_send') }}</span>
                <div class="toggle" @click="enableTemplate">
                  <div class="label-container">
                    <span :class="{active: selectedEmail.enabled}">{{toLocal('mail.on')}}</span>
                  </div>
                  <ToggleButton  :toggledState="selectedEmail.enabled" @changeState="$set(selectedEmail, 'enabled', !selectedEmail.enabled)"/>
                  <div class="label-container">
                    <span :class="{active: !selectedEmail.enabled}">{{toLocal('mail.off')}}</span>
                  </div>

                </div>
              </div>
                <!-- <div
                class="save--button"
                @click="enableTemplate" 
              >
                <span v-if="!loadingTemplateActivation">
                  {{selectedEmail.enabled ? 'Išjungti' : 'Įjungti'}}
               
                </span>
                <span v-if="loadingTemplateActivation">
                  <font-awesome-icon icon="spinner" />
                </span>
              </div> -->

              <div
                class="save--button"
                @click="saveEmailTemplate" 
              >
                <span v-if="!savingEmail">
                 {{toLocal('mail.save_draft')}}
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.848" height="26.627" viewBox="0 0 26.848 26.627">
                    <g id="Group_1097" data-name="Group 1097" transform="translate(-449.311 -967)">
                      <path id="Path_1005" data-name="Path 1005" d="M475.159,973.311v17.841a1.475,1.475,0,0,1-1.475,1.475h-21.9a1.475,1.475,0,0,1-1.475-1.475V969.475A1.475,1.475,0,0,1,451.786,968h18.05a.737.737,0,0,1,.521.215l4.586,4.573A.738.738,0,0,1,475.159,973.311Z" transform="translate(0 0)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="2"/>
                      <path id="Path_1006" data-name="Path 1006" d="M472.8,968v4.977a2.212,2.212,0,0,1-2.212,2.212h-4.423a2.212,2.212,0,0,1-2.212-2.212V968Z" transform="translate(-5.644 0)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="2"/>
                      <path id="Path_1007" data-name="Path 1007" d="M473.845,997.311v6.526H457.919v-6.526a2.212,2.212,0,0,1,2.212-2.212h11.5A2.212,2.212,0,0,1,473.845,997.311Z" transform="translate(-3.147 -11.209)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="2"/>
                    </g>
                  </svg>
                </span>
                <span v-if="savingEmail">
                  <font-awesome-icon icon="spinner" />
                </span>
              </div>
             
            </div>
        </div>
        <UserDrawer :users="selectedReceiverType == toLocal('chat.users_from') ? selectedGroups : selectedUsers" :usersType="selectedReceiverType == toLocal('chat.users_from') ? 'groups' : 'users'"  :sideOpen="sideOpen" @closeSide="sideOpen = false"/>
  </div>
</template>

<script>
import Editor from '@/components/layout/trainings/training_components/Editor.vue';
import axios from 'axios';
import moment from "moment";
import vSelect from "vue-select";
import SelectInput from './SelectInput.vue'
import ToggleButton from './ToggleButton.vue'
import UserDrawer from './UserDrawer.vue'
export default {
    components:{
        Editor,
        ToggleButton,
        SelectInput,
        UserDrawer
    },
    data(){
        return{
            static_events: [],
            static_recipient_types: [],
            static_group_types: [],
            static_time_types: [],
            static_times: [],
            static_time_units: [],
            static_atleast_one: [],
            static_atleast_one_version_2: [],
            static_if_time_types: [],
            tab: 0,
            selectedChat: -1,
            chatLoading: false,
            chats: [],
            chatMessages: [],
            chatUpdateInterval: null,
            selectedEmail: -1,
            emailTemplates: [],
            lastEmailTemplateJson: "",
            savingEmail: false,
            emailTemplatesLoading: false,
            lastChatMessages: "",
            allUsers: [],
            allGroups: [],
            allTrainings: [],
            selectedReceiverType: null,
            selectedTimeType: null,
            selectedIfTimeType: null,
            selectedGroupType: null,
            selectedGroup: null,
            selectedGroups: null,
            selectedUsers: [],
            selectedEvent: null,
            selectedTime: null,
            selectedIfTime: null,
            selectedTimeUnit: null,
            selectedIfTimeUnit: null,
            selectedAtleastOne: null,
            selectedText: "",
            selectedTraining: null,
            selectedTrainings: [],
            positiveEvents:[],
            loadingTemplateActivation: false,
            input: '',
            sideOpen: false,
        }
    },
    watch:{
        '$store.state.emailForming.selectedItem'(newItem, oldItem){
          if(newItem == -1){
            this.selectedEmail = -1;
          }
          else{
             this.setEmail(newItem);
          }
        },
        selectedUsers:{
          immediate: true,
          handler(newVal){
            if(newVal == null || newVal == undefined || newVal.length == 0){
              this.selectedUsers = 'all';
            }
          }
        },
        selectedGroups:{
          immediate: true,
          handler(newVal){
            if(newVal == null || newVal == undefined || newVal.length == 0){
              this.selectedGroups = 'all';
            }
          }
        },
        selectedReceiverType(newVal){
          this.selectedUsers = [];
          this.selectedGroups = [];
        }
    },
    mounted(){
        if(this.selectedEmail == -1 || this.selectedEmail.id != this.$store.state.emailForming.selectedItem)
            this.setEmail(this.$store.state.emailForming.selectedItem);
        this.loadStatic();
        this.loadAllUsers();
        this.loadAllGroups();
        this.loadAllTrainings();
    },
    methods:{
        loadStatic(){
            this.static_events = [
              this.toLocal('mail.didnt_log'),
                this.toLocal('email.template_static_event_1'), //	prisijungs prie sistemos
                // this.toLocal('email.template_static_event_2'), // neprisijungs prie sistemos
                this.toLocal('email.template_static_event_3'), // viską peržiūrės
                this.toLocal('email.template_static_event_5'), // nieko neperžiūrės
                // this.toLocal('email.template_static_event_4'), // peržiūrės konkrečią atmintinę
                this.toLocal('mail.views'),
                // this.toLocal('email.template_static_event_6'), // neperžiūrės konkrečios atmintinės
                this.toLocal('mail.doesnt_view'),
                // this.toLocal('email.template_static_event_8'), // nepabaigs žiūrėti atmintinės
                this.toLocal('mail.doesnt_finish'),
                this.toLocal('mail.rates'),
                this.toLocal('email.template_static_event_7'), // neįvertins atmintinės
                // this.toLocal('email.template_static_event_9'), // bus atnaujinta atmintinė
                // this.toLocal('email.template_static_event_10'), // atsiras nauja atmintinė
                this.toLocal('mail.doesnt_complete'),
                this.toLocal('mail.completes'),
                this.toLocal('mail.gets_certificate'),
                this.toLocal('mail.certificate_expires_from'),
                this.toLocal('mail.certificate_expired'),
                this.toLocal('mail.new_training'),
            ],
            this.positiveEvents = [
                this.toLocal('email.template_static_event_1'), //	prisijungs prie sistemos
                this.toLocal('email.template_static_event_3'), // viską peržiūrės
                this.toLocal('email.template_static_event_4'), // peržiūrės konkrečią atmintinę
                this.toLocal('email.template_static_event_9'), // bus atnaujinta atmintinė
                this.toLocal('email.template_static_event_10'), // atsiras nauja atmintinė
            ]
            this.static_recipient_types = [
                // this.toLocal('email.template_static_recipient_type_1'), // vartotojai
                // this.toLocal('email.template_static_recipient_type_2'), // vartotojas
                this.toLocal('chat.users_from'),
                this.toLocal('mail.user')
            ],
            this.static_group_types = [
                this.toLocal('email.template_static_group_type_1'), // iš grupės
                this.toLocal('email.template_static_group_type_2'), // iš grupių
                this.toLocal('mail.from_all_groups'), // iš grupių
            ],
            this.static_time_types = [
                this.toLocal('mail.after'), // per
                this.toLocal('email.template_static_time_type_2'), // iš karto
                this.toLocal('mail.every')
            ],
            this.static_if_time_types = [
                this.toLocal('mail.each'),
                this.toLocal('mail.after')
            ],
            this.static_times = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            this.static_time_units = [
                this.toLocal('generic.hours_short'),
                this.toLocal('email.template_static_time_unit_1'), // d.
                this.toLocal('mail.week_short'),
                this.toLocal('email.template_static_time_unit_2'), // mėn.
            ];
            this.static_atleast_one = [
              this.toLocal('mail.all_from'),
              this.toLocal('mail.atleast_one_from')
            ];
             this.static_atleast_one_version_2 = [
              this.toLocal('mail.all_from'),
              this.toLocal('mail.atleast_one_from')
            ]
        },
        setEmail(newItem){
             if(this.$store.state.emailForming.selectedFolder){
                let folder_index = this.$store.state.emailForming.emailFolders.findIndex(el=>el.id == this.$store.state.emailForming.selectedFolder);
                if(folder_index != -1){
                    let item_index =  this.$store.state.emailForming.emailFolders[folder_index].reportList.findIndex(el => el.id == newItem);
                    if(item_index != -1){
                   
                        this.selectedEmail = this.$store.state.emailForming.emailFolders[folder_index].reportList[item_index];
                        this.selectEmail();
                    }
                }
            }
        },
        selectEmail() {
            let email = this.selectedEmail;
            this.selectedReceiverType = email.recipient_type != null ? this.static_recipient_types[email.recipient_type] : null;
              
            this.selectedEvent = email.event != null ? this.static_events[email.event] : null;
            this.selectedTimeType = email.time_type != null ? this.static_time_types[email.time_type] : null;
            this.selectedTime = email.time != null ? this.static_times[email.time] : null;
            this.selectedTimeUnit = email.time_unit != null ? this.static_time_units[email.time_unit] : null;
            this.selectedIfTimeType = email.time_type != null ? this.static_if_time_types[email.time_if_type] : null;
            this.selectedIfTime = email.time != null ? this.static_times[email.time_if] : null;
            this.selectedIfTimeUnit = email.time_unit != null ? this.static_time_units[email.time_if_unit] : null;
            this.selectedAtleastOne = email.trigger_after_type != null ? [4, 7, 10].map(el=>this.static_events[el]).includes(this.selectedEvent) ? this.static_atleast_one[email.trigger_after_type] : this.static_atleast_one_version_2[email.trigger_after_type] : null;
            this.selectedTrainings =  email.training_ids;
            
            setTimeout(() => {
                if(email.groups.length){
                  this.selectedGroups = email.groups;
                  }else{
                  this.selectedGroup = null;
                  this.selectedGroups = null
                }
                this.selectedUsers = email.user_ids != null ? email.user_ids : null;
                this.selectedText = email.text;
                
            });
        },
            loadAllUsers() {
                axios.get( 
                    `${this.$store.state.api_link}/user/users`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                  this.allUsers = response.data.data;
                    this.allallUsersGroups = this.allUsers.filter(
                    (user) => user.email && user.email.length > 0
                  );
                  this.allUsers = this.allUsers.map(el => {
                    return {...el, fullName: el.name + ' ' + el.last_name}
                  })
                    this.allUsers.sort((a, b) => {
                      if(a.fullName != '' && b.fullName == '') return -1;
                      else if(a.fullName == '' && b.fullName != '') return 1;
                      else if(a.fullName && b.fullName){
                        if (a.fullName.localeCompare(b.fullName) < 0 ) return -1;
                        else return 1;
                      }
                      else if(a.fullName && !b.fullName){
                        return -1;
                      }
                      else return 1;
                    });
                }).catch((error) => {
                    console.log(error)
                });
            },
            loadAllGroups() {
                axios.get( 
                    `${this.$store.state.api_link}/group/groups`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                  this.allGroups = response.data.data;
                    this.allGroups = this.allGroups.filter(
                    (group) => group.name && group.name.length > 0
                  );
                    this.allGroups.sort((a, b) => {
                      if (a.name.localeCompare(b.name) < 0 ) return -1;
                      else return 1;
                    });
                }).catch((error) => {
                    console.log(error)
                });
            },
            loadAllTrainings() {
                axios.get( 
                    `${this.$store.state.api_link}/training/trainings`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    this.allTrainings = response.data.data;
                    this.allTrainings.forEach(el => {
                      el.data = JSON.parse(el.data);
                      el['title'] = el.data.title
                    });
                    this.allTrainings.sort((a, b) => {
                      if (a.title.localeCompare(b.title) < 0 ) return -1;
                      else return 1;
                    });
                }).catch((error) => {
                    this.$store.commit("handleError", {"error": error});
                });
            },
             prepareEmailTemplateForSaving() {
                // let currentlySelectedGroups = [];
                // if(this.static_group_types.findIndex(el => el === this.selectedGroupType) == 0 && this.selectedGroup){ // single
                //     currentlySelectedGroups.push(this.selectedGroup.id);
                // }else if(this.static_group_types.findIndex(el => el === this.selectedGroupType) == 1 && this.selectedGroups && this.selectedGroups.length){ // multi
                //     this.selectedGroups.forEach(el => {
                //     currentlySelectedGroups.push(el.id);
                //     });
                // }
                let user = null;
                if(this.static_recipient_types.findIndex(el => el === this.selectedReceiverType) == 1 && this.selectedUser) {
                    user = this.selectedUser.id;
                }
                let emailTemplate = {
                    id: this.selectedEmail.id,
                    event: this.static_events.findIndex(el => el === this.selectedEvent),
                    group_type: this.static_group_types.findIndex(el => el === this.selectedGroupType),
                    recipient_type: this.static_recipient_types.findIndex(el => el === this.selectedReceiverType),
                    time: this.selectedTime,
                    time_type: this.static_time_types.findIndex(el => el === this.selectedTimeType),
                    time_unit: this.static_time_units.findIndex(el => el === this.selectedTimeUnit),
                    time_if:  this.selectedIfTime,
                    time_if_unit: this.static_time_units.findIndex(el => el === this.selectedIfTimeUnit),
                    time_if_type: this.static_if_time_types.findIndex(el => el === this.selectedIfTimeType),
                    trigger_after_type: [4, 7, 10].map(el=>this.static_events[el]).includes(this.selectedEvent) ? this.static_atleast_one.findIndex(el => el === this.selectedAtleastOne) : this.static_atleast_one_version_2.findIndex(el => el === this.selectedAtleastOne),
                    text: this.selectedEmail.text,
                    signature_enabled: this.selectedEmail.signature_enabled,
                }
                if(this.selectedUsers){
                  this.$set(emailTemplate, 'user_ids',  Array.isArray(this.selectedUsers) ? this.selectedUsers.map(el => el.id) :  this.selectedUsers.id ?  this.selectedUsers.id : this.selectedUsers)
                }
                if(this.selectedGroups){
                  this.$set(emailTemplate, 'groups',  Array.isArray(this.selectedGroups) ? this.selectedGroups.map(el => el.id) : this.selectedGroups)
                }
                if(this.selectedTrainings){
                  this.$set(emailTemplate, 'training_ids',  Array.isArray(this.selectedTrainings) ? this.selectedTrainings.map(el => el.id) :  this.selectedTrainings.id ?  this.selectedTrainings.id : this.selectedTrainings)
                }
                return emailTemplate;
            },
            saveEmailTemplate(){
                let item = this.selectedEmail;
                this.savingEmail = true;
                let template = this.prepareEmailTemplateForSaving();

                axios.put(
                    `${this.$store.state.api_link}/email-templates/${item.id}`,
                    {
                        item:template,
                    },
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                  let folder_index = this.$store.state.emailForming.emailFolders.findIndex(el=>el.id == this.$store.state.emailForming.selectedFolder);
                    if(folder_index != -1){
                        let old_index = this.$store.state.emailForming.emailFolders[folder_index].reportList.findIndex(el=>this.selectedEmail.id == el.id);
                        if(old_index != -1){
                          this.$set(this.$store.state.emailForming.emailFolders[folder_index].reportList, old_index, template);
                        }
                    }
                    this.savingEmail = false;
                })
                .catch(()=>{
                    this.savingEmail = false;
                })
            },
            enableTemplate(){
              this.loadingTemplateActivation = true;
              // this.$set(this.selectedEmail, 'enabled', !this.selectedEmail.enabled);
              axios.post(
                `${this.$store.state.api_link}/email-template/toggle`,
                {id: this.selectedEmail.id},
                this.$store.getters.axiosConfig()
              ).then((response) => {
                 this.loadingTemplateActivation = false;
              })
              .catch(() => {
                this.$set(this.selectedEmail, 'enabled', !this.selectedEmail.enabled);
                this.loadingTemplateActivation = false;
              });
            },
            getGroupLabel(){
              if(this.selectedReceiverType != this.toLocal('chat.users_from')) return
              if(this.selectedGroups){
                if(this.selectedGroups.length == 1){
                  return this.toLocal('chat.groups');
                }
                else{
                  return this.toLocal('chat.groups_who');
                }
              }
              return '';
            },
            getTrainingLabel(type){
              if(type =='test'){
                if (this.selectedTrainings.length == 1) {
                  return this.toLocal('chat.test_one');
                }
                else {
                  return this.toLocal('chat.test_one');
                }
              }
              else{
                if([5, 6, 8, 9].map(el=>this.static_events[el]).includes(this.selectedEvent)){
                  if (this.selectedTrainings.length == 1) {
                    return this.toLocal('chat.training_single');
                  }
                  else {
                    return this.toLocal('chat.training_multiple_who');
                  }
                }
                else{
                  if (this.selectedTrainings.length == 1) {
                    return this.toLocal('chat.training_single_what');
                  }
                  else {
                    return this.toLocal('chat.training_multiple_who');
                  }
                }
              }

            },
            disabledInputFunction(option){
              let after = [0, 3, 5, 6, 8, 9].map(el=>this.static_events[el]).includes(this.selectedEvent) && option == this.toLocal('mail.after');
              if(after) this.selectedIfTimeType = this.toLocal('mail.each');
              let during =this.static_events[12] == this.selectedEvent && option == this.toLocal('mail.each');
              if(during) this.selectedIfTimeType = this.toLocal('mail.after');
              return after || during
                
            },
            disabledTimeInputFunction(option){
              let every_hour = this.selectedTimeType == this.toLocal('mail.every') && option == this.toLocal('generic.hours_short');
              if(every_hour) this.selectedTimeUnit = this.toLocal('generic.days_short');
              return every_hour;
            },
            disabledTimeIfInputFunction(option){
           
              return option == this.toLocal('generic.hours_short');
            },
            saveSignature(signature){
              axios.post(
                `${this.$store.state.api_link}/signature/save`,
                {text: signature},
                this.$store.getters.axiosConfig()
              ).then((response) => {
              })
              .catch(() => {
              });
            },
            getUserReceiverLabel(users_count){
              let number_array = users_count.toString().split('');
              let last_number = number_array[number_array.length - 1];
              if(last_number == 0) return this.toLocal('mail.recipients_who');
              else if(parseInt(users_count)> 10 && parseInt(users_count)<= 19) return this.toLocal('mail.recipients_who');
              else if(last_number == 1) return this.toLocal('mail.recipient');
              else if(last_number> 1 && last_number<= 9) return this.toLocal('mail.recipients_multiple');
            }

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.email-template{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main--container {
  transition: .3s width, .3s transform;
  transform: translate(0);
  padding-top: 100px;
  max-width: 1630px;
  &.side--open {
    width: calc(100vw - 950px);
    transform: translateX(-400px);
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
h2 {
  font-size: 35px;
  font-weight: 900;
  color: #464646;
  margin: 0;
}
h3 {
  font-size: 25px;
  font-weight: 500;
  color: #464646;
  margin: 0;
  margin-bottom: 20px;
}
p {
  font-size: 20px;
}
.user--list {
  width: calc(40% - 8px);
  float: left;
  position: relative;
  padding-right: 80px;
}
.message--wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 60%;
  float: left;
  position: relative;
  padding-left: 100px;
  .message--wrapper--child{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.vertical--border {
  width: 8px;
  border-radius: 4px;
  background-color: #fff;
  height: calc(100vh - 300px);
  float: left;
  // position: absolute;
  // display: inline-block;
}
.tab--select {
  float: right;
  .tab--button {
    height: 53px;
    background-color: #fff;
    border-radius: 30px;
    margin: 0 10px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 0 20px;
    transition: .3s all;
    img {
      margin-right: 15px;
    }
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 3px 6px rgba(0,0,0,.2);
    }
  }
  
  .tab--selection {
    width: 53px;
    height: 53px;
    background-color: #fff;
    border-radius: 100%;
    margin: 0 10px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    transition: .3s all;
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 3px 6px rgba(0,0,0,.2);
    }
    &.active {
      background-color: var(--primary-color);
    }
    &.tab--automate {
      background-image: url("../../../assets/images/icons/messages-automate.svg");
      &.active {
        background-image: url("../../../assets/images/icons/messages-automate-active.svg");
      }
    }
    &.tab--write {
      background-image: url("../../../assets/images/icons/messages-write.svg");
      background-position: 60% 40%;
      &.active {
        background-image: url("../../../assets/images/icons/messages-write-active.svg");
      }
    }
  }
}
.user--table {
  margin-top: 30px;
  .user {
    background-color: #fff;
    border-radius: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: .3s all;
    user-select: none;
    position: relative;
    
    .user--name {
      padding: 16px 30px;
      white-space: nowrap;
      
      img {
        margin-right: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 3px 6px rgba(0,0,0,.2);
    }
    &.active { 
      background-color: var(--primary-color);
      color: #fff;
    }
    .unread--messages {
      background-color: #FF5858;
      width: 22px;
      height: 22px;
      top: -5px;
      left: -5px;
      border-radius: 100%;
      position: absolute;
    }
    .user--groups {
      float: right;
      width: 240px;
      height: 60px;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      overflow: hidden;
      position: relative;
      &:hover .group {
        @for $i from 2 through 15 {
          &.g#{$i} {
            width: 10% - ($i - 1) * 4;
          }
        }
      }
      .group {
        position: absolute;
        width: 100%;
        height: 100%;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s width;
        user-select: none;
        overflow: hidden;
        color: #464646;
        @for $i from 0 through 15 {
          &.c#{$i} {
            background-color: hsl((3 + ($i - 1) * 24), 100%, 76%);
          }
          &.g#{$i} {
            width: 100% - ($i - 1) * 10;
          }
        }
        &:hover {
          @for $i from 0 through 15 {
            &.g#{$i} {
              width: 100% - ($i - 1) * 2;
            }
          }
        }
      }
    }
  }
}
.email--table {

  margin-top: 30px;
  .email--template {


    height: 100%;
    background-color: #fff;
    border-radius: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    min-height: 65px;
    transition: .3s all;
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 3px 6px rgba(0,0,0,.2);
    }
    .email--name {
      padding: 16px 30px;
    }
    .email--actions {
      float: right;
      padding: 16px 20px;
      .email--action {
        width: 30px;
        height: 30px;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 20px;
        cursor: pointer;
        display: inline-block;
        transition: .3s transform;
        &.email--action--dublicate {
          background-image: url("../../../assets/images/icons/item-dublicate.svg");
        }
        &.email--action--switch {
          background-image: url("../../../assets/images/icons/item-switch.svg");
        }
        &.email--action--delete {
          background-image: url("../../../assets/images/icons/item-delete.svg");
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    &.disabled {
      background-color: transparent;
      border: 1px solid #707070;
      .email--action.email--action--switch {
        background-image: url("../../../assets/images/icons/item-switch-red.svg") !important;
      }
    }
    &.active {
      background-color: var(--primary-color);
      color: #fff;
      
      .email--action {
        &.email--action--dublicate {
          background-image: url("../../../assets/images/icons/item-dublicate-active.svg");
        }
        &.email--action--switch {
          background-image: url("../../../assets/images/icons/item-switch-active.svg");
        }
        &.email--action--delete {
          background-image: url("../../../assets/images/icons/item-delete-active.svg");
        }
      }
    }
  }
}
.timestamp {
  font-size: 15px;
  border-bottom: 1px solid #707070;
  margin: 0 120px;
  padding: 6px 0;
}
.message--wrapper {
  .chat--messages--wrapper {
    height: calc(100vh - 240px);
    margin: 0 -100px;
    overflow-y: auto;
  }
  .message {
    margin: 8px 100px;
    position: relative;
    .message--text {
      background-color: #fff;
      padding: 15px 25px;
      border-radius: 20px;
      display: inline-block;
    }
    .message--loading {
      position: absolute;
    }
    .avatar {
      position: absolute;
      margin-left: -70px;
      background-color: #fff;
      font-size: 30px;
      font-weight: 900;
      width: 58px;
      height: 58px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
 
    &.message--right {
      text-align: right;
      .avatar {
        right: 0;
        margin-left: 0px;
        margin-right: -70px;
      }
    }
  }
  .chat--input--wrapper {
    padding: 10px 0;
    position: relative;
    .chat--input {
      width: 100%;
      border: none;
      background-color: #fff;
      padding: 15px 27px;
      font-family: var(--main-site-font);
      font-size: 20px;
      color: #707070;
      border-radius: 20px;
      outline: none;
    }
    svg {
      position: absolute;
      cursor: pointer;
      top: 27px;
      right: 25px;
      user-select: none;
      overflow: hidden;
    }
  }
  .email--select--wrapper {
    // height: calc(100vh - 240px);
    margin-bottom: 30px;
    font-size: clamp(24px, 1.1vw, 30px);
    color: var(--primary-color);
    line-height: 55px;
    margin-bottom: 10px;
    span {
      color: var(--primary-color);
      border-bottom: 4px solid #FFD93E;
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-left: 10px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 8px;
        background-image: url("../../../assets/images/icons/dropdown-arrow.svg");
        background-repeat: no-repeat;
        margin-left: -15px;
        margin-top: 28px;
      }
    }
  }
  .email--input--wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    .email--input {
      width: 100%;
      // min-height: 145px;
      border: none;
      background-color: #fff;
      padding: 15px 27px;
      font-family: var(--main-site-font);
      font-size: 20px;
      color: #707070;
      border-radius: 20px;
      outline: none;
      float: left;
    }
    .save--button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid var(--primary-color);
      padding: 15px 27px;
      border-radius: 10px;
      cursor: pointer;
      user-select: none;
      float: left;
      width: 100%;
      margin: 15px 0;
      height: 50px;
      max-width: 430px;
      text-align: center;
      color: var(--primary-color);
      margin-bottom: 0;
      span{
        font-size: 22px;
        display: flex;
      }
      svg{
        margin:0 15px;
        path{
          stroke: var(--primary-color);
        }
      }
    }
  }
}
 .button-container{
    justify-content: space-between;
    display: flex;
    margin-bottom: 1rem;

  }
  .toggle-container{
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
    margin-top: 0.75rem;
    margin-left: 1rem;
    min-width: 12rem;
    margin-right: 2rem;
    .label{
      display: flex;
      justify-content: center;
      margin-bottom: 0.25rem;
    }
    .toggle{
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      span{
        &.active{
          text-shadow: 1px 0 0 var(--primary-color);
        }
      }
    }
  }
  .selected--users{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn--multi{
      display: flex;
      justify-content: center;
      color: var(--primary-color);
      background: white;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.15rem 0.25rem;
      font-size: clamp(14px, 0.9vw, 16px);
      width: 137px;
      border: 1px solid transparent;
      min-height: 1.75rem;
      cursor:pointer;
      &:hover{
        border: 1px solid var(--primary-color);
        div:last-child{
          text-shadow: 1px 0 0 var(--primary-color);
        }
      }
      &:active{
        background: var(--secondary-color);
      }
      div{
        flex:2;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        &:first-child{
          // margin-right: 0.25rem;
        }
      }
      div:last-child{
        flex:3;
        // border-left: 1px solid var(--primary-color);
      }
    }
  }
</style>

<style lang="scss">
  .select-input{
    display: inline-block;
    min-width: 100px;
    margin: 0 3px;
    vertical-align: middle;
    margin-top: -3px;
    ul {
      font-size: 16px;
    }
    .vs__dropdown-toggle {
      border: none;
      border-bottom: 2px solid #707070;
      border-radius: 0;
      padding: 0;
      height: 45px;
    }
    .vs__selected {
      color: var(--primary-color);
    }
    .vs__actions {
      display: none;
    }
    &.vs--single.vs--open .vs__selected {
      position: relative;
    }
    .vs__search {
      font-family: var(--main-site-font);
      color: var(--primary-color);
    }
    .vs__selected-options {
      flex-wrap: nowrap;
      -ms-flex-preferred-size: auto;
    }
    // input {
    //   background-color: transparent;
    //   border: none;
    //   color: var(--primary-color);
    //   border-bottom: 4px solid #FFD93E;
    // }
  } 
  .email-menu-bar{
    display: flex;
    justify-content: space-evenly;
    background: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px 10px 0px;
    button{
      background: #EFEFEF;
      width: 100%;
      border: none;
      padding: 5px 7px;
      cursor:pointer;
      transition: 0.3s all;
      &:hover{
        background: var(--secondary-color);
        opacity: 0.5;
      }
    }
    button:first-child{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    button:last-child{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .email-editor{
    margin: 0;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 200px;
    padding: 10px;
    overflow: auto;
    .ProseMirror{
      & > *{
        margin: 0;
      }
    }
  }
  .editor-parent-container{
    flex: 1;
    display: flex;
    flex-direction: column;
      position: relative;
    height: 100%;
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    .toolbar-container{
      align-items: center;
      display: flex;
    }
    // max-height: 23rem;
    .editor-content{
      width: 100%;
      height: 100%;
    }
    .ProseMirror{
        width: 100%;

    }
    .signature{
      position: relative;
    flex: auto;
    display: flex;
    flex-direction: column;
    .ProseMirror{
         p{
           transition: margin 0.3s;
           margin: 0rem 0;
          }
      }
    &.not-editing{
      
      .ProseMirror{
         width: 100%;
         p{
            transition: margin 0.5s;
           margin: 0.25rem 0;
         }
      }
    }
    }
  }
 
</style>

<style lang="scss">
.email--input--wrapper{
    .editor-content{
      .ProseMirror{
        height: 100%;
      }
    }
  }
</style>