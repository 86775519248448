<template>
  <div v-scroll-lock="sideOpen">
    <div :class="['side--background', sideOpen ? 'side--open' : '']">
      <section
        :class="{
          'user--modal': true,
          'side--open': sideOpen,
          'header--disabled': !$store.state.header_enabled,
        }"
      >
        <div
          class="close-cross"
          @click="
            $emit('closeSide');
            currentTab = -1;
          "
        >
          <CrossSVG />
        </div>
        <div class="tabs-row" :class="[getContrast('secondary_color')]">
          <div
            class="single-tab"
            @click="currentTab = 0"
            :class="{ active: currentTab == 0 }"
          >
            {{ toLocal("settings.personal_title") }}
          </div>
          <div
            class="single-tab"
            @click="currentTab = 1"
            :class="{ active: currentTab == 1 }"
          >{{ toLocal('group_side.assigned_trainings') }}
          </div>
          <div
            class="single-tab"
            @click="currentTab = 2"
            :class="{ active: currentTab == 2 }"
          >
            {{ toLocal("user_side.certificates") }}
          </div>
        </div>
        <div class="main-modal-container">
          <div class="user-name">
            <span>{{ user.name }} {{ user.last_name }}</span>
          </div>
          <UserSettings v-if="currentTab == 0" :user="user" />
          <UserTrainings v-if="currentTab == 1" :user="user" />
          <UserDocs v-if="currentTab == 2" :user="user" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import UserSettings from "./userside/UserSettings.vue";
import UserTrainings from "./userside/UserTrainings.vue";
import UserDocs from "./userside/UserDocs.vue";
import CrossSVG from "@/assets/images/icons/users/cross-gray.svg?inline";
export default {
  name: "UserSide",
  data: function () {
    return {
      currentTab: -1,
      addDocumentModal: false,
      trainings: [],
      trainingsCopy: [],
      filteredTrainings: [],
      filteredTrainingsCopy: [],
      groups: [],
      all_groups: [],
      all_filtered_groups: [],
      loadingTrainings: false,
      isExpired: false,
      shownExpiryDate: "",
      editingDocument: "",
      today: Date.now(),
      selectedFilter: 0,
      activeDropdownType: -1,
      activeDropdown: -1,
      showGroupDrowDown: false,
      searchInput: "",
      training_groups: [],
      filteredGroupIds: [],
      // changedIDs: [],
    };
  },
  components: {
    UserSettings,
    UserTrainings,
    UserSettings,
    UserDocs,
    CrossSVG,
  },
  props: {
    user: {
      type: Object,
    },
    allDocuments: {
      type: Array,
    },
    sideOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  watch: {
    sideOpen() {
      if (this.sideOpen) {
        this.currentTab = 0;
        this.userDeactivationDate = this.user.deactivation_date;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";
@import "@/assets/scss/components/layout/users/_userSideScoped.scss";
</style>
