<template>
  <div
    class="input-container"
    :class="[
      customClass ? customClass : '',
      { 'dropdown-active': showDropdown },
      { searchable: searchable },
    ]"
    v-click-outside="closeDropdown"
  >
    <div
      class="input-wrapper"
      @click="isDropdown ? (showDropdown = !showDropdown) : ''"
    >
      <input
        type="text"
        :placeholder="inputPlaceholder"
        v-model="searchValue"
        @input="filterItems($event)"
        :readonly="searchable ? false : true"
      />
      <div class="icon right" v-if="showIcon">
        <svg
          v-if="
            !hasClear
              ? icon == 'pencil'
              : value == '' || value == null
              ? icon == 'pencil'
              : false
          "
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="15.182"
          height="15.189"
          viewBox="0 0 15.182 15.189"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_2454"
                data-name="Rectangle 2454"
                width="15.189"
                height="15.182"
                fill="#28345c"
              />
            </clipPath>
          </defs>
          <g
            id="Group_8034"
            data-name="Group 8034"
            transform="translate(15.182) rotate(90)"
            opacity="0.5"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_6192"
              data-name="Path 6192"
              d="M15.175,14.5l-.791-3.532v-.03a1.26,1.26,0,0,0-.371-.892L4.339.374a1.279,1.279,0,0,0-1.808,0L.374,2.526a1.279,1.279,0,0,0,0,1.808l9.679,9.679a1.236,1.236,0,0,0,.908.36l3.548.8a.6.6,0,0,0,.241,0,.558.558,0,0,0,.352-.247.57.57,0,0,0,.087-.3.468.468,0,0,0-.014-.122M.935,3.773a.484.484,0,0,1,0-.686L3.092.935A.473.473,0,0,1,3.434.791a.484.484,0,0,1,.344.144l.932.932L1.87,4.708Zm9.418,9.418L2.431,5.269l2.84-2.84,7.922,7.922Zm2.4.77-1.672-.374,2.512-2.512.4,1.8Z"
              transform="translate(0)"
              fill="#28345c"
            />
            <path
              id="Path_6193"
              data-name="Path 6193"
              d="M23.111,22.7a.388.388,0,0,1-.117.279.4.4,0,0,1-.561,0l-6.03-6.03a.393.393,0,0,1,0-.561.4.4,0,0,1,.561,0l6.03,6.03a.394.394,0,0,1,.117.282"
              transform="translate(-11.871 -11.862)"
              fill="#28345c"
            />
          </g>
        </svg>
        <svg
          v-if="
            !hasClear
              ? icon == 'arrow'
              : value == '' || value == null
              ? icon == 'arrow'
              : false
          "
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L7 7L13 1"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="hasClear && value != '' && value != null"
          @click="$emit('clear')"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="1.06065"
            width="1.5"
            height="12"
            rx="0.75"
            transform="rotate(-45 0 1.06065)"
            fill="black"
          />
          <rect
            x="8.48438"
            width="1.5"
            height="12"
            rx="0.75"
            transform="rotate(45 8.48438 0)"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <div class="dropdown-list white" v-show="showDropdown">
      <div
        class="list-item"
        v-for="(item, index) in filteredData"
        :key="index"
        @click="selectItem(item, index)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String | Object,
      default: "",
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "pencil",
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    valueKey: {
      type: String,
      default: "title",
    },
    hasClear: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filteredData: [],
      showDropdown: false,
      searchValue: "",
    };
  },
  watch: {
    data() {
      this.filteredData = this.data;
    },
  },
  mounted() {
    this.filteredData = this.data;
    this.$nextTick(() => {
      if (this.value != "" && typeof this.value == "string") {
        this.searchValue = this.value;
      }
    });
  },
  computed: {
    inputPlaceholder() {
      if (this.value == "" || this.value == null) {
        return this.placeholder;
      } else {
        return this.valueKey == "object"
          ? this.value["title"]
          : this.value[this.valueKey];
      }
    },
  },
  methods: {
    closeDropdown() {
      this.showDropdown = false;
    },
    selectItem(item, index) {
      if (this.valueKey == "object") {
        this.$emit("input", item);
      } else {
        this.$emit("input", item[this.valueKey]);
      }
      this.searchValue = "";
      this.showDropdown = false;
    },
    filterItems(e) {
      this.$emit("searchValue", e.target.value);
      if (e.target.value === "") {
        this.filteredData = this._.cloneDeep(this.data);
        return;
      }
      this.showDropdown = true;
      this.filteredData = this._.cloneDeep(this.data);
      this.filteredData = this.filteredData.filter((item) => {
        return item.title.toLowerCase().includes(e.target.value.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 2;
  &.dropdown-active {
    outline: 1px solid #7c7b7b;
    border-bottom: none;
    border-radius: 8px;
  }
  &.searchable {
    input {
      cursor: text;
    }
  }
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 30px;
  cursor: pointer;
  svg {
    width: 70%;
    height: 70%;
  }
  &.left {
    left: 5px;
    right: unset;
  }
  &.right {
    right: 5px;
    left: unset;
  }
}
.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
input {
  background: #ffffff;
  border: 1px solid #efefef;
  -webkit-box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px 30px 10px 16px;
  font-style: normal;
  font-size: 16px;
  width: 100%;
  font-family: var(--main-site-font);
  cursor: pointer;
}
.dropdown {
  display: flex;
  position: relative;
  background: #efefef;
  border-radius: 2rem;
  // padding: 0.25rem 0.35rem 0.25rem 0.5rem;
  justify-content: space-between;
  cursor: pointer;
  // border: 1px solid var(--primary-color-opacity-50);
  // margin-left: 1rem;
  height: 35px;
  width: 85%;
  z-index: 2;
  span {
    width: 100%;
    font-size: clamp(0.9em, 1.2vw, 1em);
  }
  .dropdown-icon {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.25rem;
    width: 25px;
    height: 25px;
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 0.25rem 0.35rem 0.25rem 0.5rem;
    font-size: clamp(16px, 0.8vw, 18px);
    border-radius: 2rem;

    border: 1px solid var(--primary-color-opacity-50);
    .long {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      min-width: 93%;
      width: 8px;
    }
  }
}
.languages {
  background: #f4f4f4;
  box-shadow: none;
  border-radius: 8px;
  input {
    background: #f4f4f4;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
  }
  svg {
    width: 12px;
  }
  .dropdown-list {
    background: #f4f4f4;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: none;
    outline: none;
    &.white {
      background: #f4f4f4;
    }
  }
  &.dropdown-active {
    outline: none;
  }
}
.dropdown-list {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0.25rem 0;
  top: 1rem;
  padding-top: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: #efefef;
  // border: 2px solid white;
  border-top: none;
  padding-top: 1rem;
  z-index: -1;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  outline: 1px solid #7c7b7b;
  max-height: 300px;
  overflow: auto;
  border-top: none;
  .list-item {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 15px;
    font-size: clamp(0.7em, 1.1vw, 18px);
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
    &.active {
      font-weight: 700;
    }
  }
  &.white {
    background: white;
    .dropdown-icon {
      background: #efefef;
    }
    .dropdown-list {
      background: white;
    }
  }
  &.small {
    padding: 0.2rem 0.25rem;
    width: 4.5rem;
    span {
      text-align: center;
      font-size: clamp(0.7em, 1.1vw, 1em);
    }
  }
  &.top {
    z-index: 3;
  }
  &.bottom {
    z-index: 2;
  }
  &.active {
    padding: 0;
    border-bottom: none;
    background: none;
    border: none;
    .btn-container {
      background: #efefef;
      padding: 0.25rem 0.35rem 0.25rem 0.5rem;
      border: 1px solid var(--primary-color-opacity-50);
      border-radius: 1rem;
    }
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
