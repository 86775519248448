var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-leader-page"},[_c('div',{staticClass:"information-container"},[_c('div',{staticClass:"tabs"},[(_vm.$store.state.managedGroups.length ==1)?_c('div',{staticClass:"group-name"},[_vm._v(" "+_vm._s(_vm.group.name)+" ")]):(_vm.$store.state.managedGroups.length > 1 && _vm.group)?_c('div',{staticClass:"group-name dropdown"},[_c('CustomDropdown',{attrs:{"list":_vm.$store.state.managedGroups,"key-label":"id","value-label":"name","compareChosen":_vm.group ? _vm.group : _vm.$store.state.managedGroups[0],"options":{
                        closeOnClick: true,
                        checkbox: false,
                        customDDWidth: 'initial',
                        customDDMaxWidth: '250px',
                        customDDMinWidth: '100%',
                        customMaxHeight: '300px',
                        initial: _vm.group ? _vm.group.name : _vm.$store.state.managedGroups[0].name,
                        containerClass: 'leader-page-dropdown',
                        searchBar: false,
                        left:true,
                        showCreateNewBtn: false,
                        valueWidthType: 'dynamic'
                    }},on:{"elClicked":_vm.selectDropdownItem},scopedSlots:_vm._u([{key:"arrow",fn:function(){return [_c('svg',{attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z","fill":"#7D7D7D"}})])]},proxy:true}])})],1):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"users"},[_c('UserList',{attrs:{"users":_vm.users,"selectedUser":_vm.selectedUser,"isLoadingUsers":_vm.isLoadingUsers},on:{"selectUser":function($event){_vm.selectedUser=$event}}})],1),_c('div',{staticClass:"tab-content",class:{'no-background': _vm.selectedTabIndex == 1 && Object.keys(_vm.selectedUser).length == 0}},[(_vm.selectedTabIndex == 0)?_c('LeaderNotifications'):_vm._e(),(_vm.selectedTabIndex == 1 && _vm.selectedUser && Object.keys(_vm.selectedUser).length  > 0)?_c('GroupMembers',{attrs:{"user":_vm.selectedUser,"userRatings":_vm.userRatings,"isLoadingUsers":_vm.isLoadingUsers}}):_vm._e(),(_vm.selectedTabIndex == 2)?_c('GroupTrainings'):_vm._e()],1)])]),_c('portal-target',{attrs:{"name":"training-confirm-modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }