<template>
  <div class="report-container">
      <ReportTab  />
      <div class="content-container"   >
          <ReportPeriodicContent v-if="$store.state.reportForming.selectedTab == 0"/>
          <ReportMomentContent v-else/>
      </div>
  </div>
</template>

<script>
import ReportTab from "./ReportTab.vue";
import ReportPeriodicContent from "./ReportPeriodicContent.vue";
import ReportMomentContent from "./ReportMomentContent.vue";
export default {
    name: "ReportForm",
    components: { ReportTab, ReportPeriodicContent, ReportMomentContent }
}
</script>

<style lang="scss" scoped>
.report-container{
    display: flex;
    flex-direction: column;
    width: 55vw;
    max-width: 70rem;
    min-width: 30rem;
}
.content-container{
    display: flex;
    flex-direction: column;
    background-color: #E2E2E2;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.20);
    border-top: none;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    // height: 100%;
}
</style>