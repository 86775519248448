<template>
  <div class="modal-container" v-if="loaded"  v-scroll-lock="$store.state.window_width > 768 && $store.state.user_is_new == 1">
        <div class="modal-wrapper">
            <div class="modal-header">
                <h2>
                    {{toLocal('first_login.description')}}
                </h2>
                <select v-model="selectedLanguage" @change="changeLanguage(selectedLanguage)">
                    <option v-for="(language, index) in languages" :key="index" :value="language.toLowerCase()">{{ language }}</option>
                </select>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div class="input-wrapper" >
                        <span v-if="firstLogin.username != undefined">{{toLocal('first_login.new_username_desc')}}</span>
                        <div class="input-container" v-if="firstLogin.username != undefined">
                            
                            <input type="text-field"  :placeholder="toLocal('first_login.enter')" v-model="$v.firstLogin.username.$model">
                            <div class="error-mobile" v-if="isMobileWindow() && $v.firstLogin.username.$anyDirty && $v.firstLogin.username.$invalid">
                                <p>{{toLocal('first_login.username_incorrect')}}</p>
                            </div>
                            <div class="error-mobile" v-if="isMobileWindow() && errorCode == 0">
                                <p>{{errorMessage}}</p>
                            </div>
                            <v-popover
                                v-if="$v.firstLogin.username.$anyDirty && $v.firstLogin.username.$invalid && !isMobileWindow()"
                                offset="26"
                                :open="$v.firstLogin.username.$invalid && $v.firstLogin.username.$anyDirty"
                                placement="left"
                                class="pop-left"
                                popoverClass="login-form-popover-container"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <h3>{{toLocal('generic.username')}}</h3>
                                    <span>{{toLocal('generic.field_must')}}</span>
                                    <ul>
                                    <li>
                                        {{toLocal('first_login.username_requirement')}}
                                    </li>
                                    </ul>
                                </div>
                            </v-popover>
                            <v-popover
                                v-if="errorCode == 0 && !isMobileWindow()"
                                offset="26"
                                :open="errorCode == 0"
                                placement="left"
                                class="pop-left server-error"
                                popoverClass="login-form-popover-containerr"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <span>{{errorMessage}}</span>
                                </div>
                            </v-popover>
                        </div>
                        <span v-if="firstLogin.password != undefined" class="password-label">
                            {{toLocal('settings.new_password')}}:
                            <span>{{ toLocal('password.validation_label_n_symbols') }}</span>
                        </span>
        
                        <div class="input-container" v-if="firstLogin.password != undefined">
                            <input type="password-field" name="not-password" autocomplete="new-password" v-model="$v.firstLogin.password.$model">
                            <div class="error-mobile" v-if="isMobileWindow() && $v.firstLogin.password.$anyDirty && $v.firstLogin.password.$invalid">
                                <p>{{toLocal('first_login.password_invalid')}}</p>
                            </div>
                            <div class="error-mobile" v-if="isMobileWindow() && errorCode == 2">
                                <p>{{errorMessage}}</p>
                            </div>
                            <v-popover
                                v-if="$v.firstLogin.password.$anyDirty && $v.firstLogin.password.$invalid && !isMobileWindow()"
                                offset="26"
                                :open="$v.firstLogin.password.$invalid && $v.firstLogin.password.$anyDirty"
                                placement="left"
                                class="pop-left"
                                popoverClass="login-form-popover-container"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <h3>{{toLocal('generic.password')}}</h3>
                                    <span>{{toLocal('generic.field_must')}}</span>
                                    <ul>
                                    <li>
                                        {{toLocal('first_login.password_requirement')}}
                                    </li>
                                    </ul>
                                </div>
                            </v-popover>
                            <v-popover
                                v-if="errorCode == 2 && !isMobileWindow()"
                                offset="26"
                                :open="errorCode == 2"
                                placement="left"
                                class="pop-left server-error pass-error"
                                popoverClass="login-form-popover-container"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <span>{{errorMessage}}</span>
                                </div>
                            </v-popover>
                        </div>
                        <span v-if="firstLogin.confirmPassword != undefined">{{toLocal('first_login.repeat_password')}}:</span>
                        <div class="input-container" v-if="firstLogin.confirmPassword != undefined">
                            <input type="password-field" name="not-password" autocomplete="no-auto" v-model="$v.firstLogin.confirmPassword.$model">
                            <div class="error-mobile" v-if="isMobileWindow() && $v.firstLogin.confirmPassword.$anyDirty && $v.firstLogin.confirmPassword.$invalid">
                                <p>{{toLocal('first_login.repeat_wrong')}}</p>
                            </div>
                            <v-popover
                                v-if="$v.firstLogin.confirmPassword.$anyDirty && $v.firstLogin.confirmPassword.$invalid && !isMobileWindow()"
                                offset="26"
                                :open="$v.firstLogin.confirmPassword.$invalid && $v.firstLogin.confirmPassword.$anyDirty"
                                placement="left"
                                class="pop-left"
                                popoverClass="login-form-popover-container"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <h3>{{toLocal('first_login.repeated')}}</h3>
                                    <span>{{toLocal('generic.field_must')}}</span>
                                    <ul>
                                    <li>
                                        {{toLocal('first_login.match_requirement')}}
                                    </li>
                                    </ul>
                                </div>
                            </v-popover>
                        </div>
                        <span v-if="firstLogin.emailPhone != undefined">{{$store.state.system_settings.usesPhones ? toLocal('generic.phone') : toLocal('first_login.email')}}</span>
                        <div class="input-container" v-if="firstLogin.emailPhone != undefined">
                            <input type="text-field" autocomplete="no-auto" name="not-email" v-if="$store.state.system_settings.usesPhones" placeholder="+370 XXX XXXXX" v-mask="$store.state.system_settings.phoneMask" v-model="$v.firstLogin.emailPhone.$model">
                            <input type="text-field" autocomplete="no-auto" name="not-email" v-else :placeholder="toLocal('first_login.enter')" v-model="$v.firstLogin.emailPhone.$model">
                            <div class="error-mobile" v-if="isMobileWindow() && $v.firstLogin.emailPhone.$anyDirty && $v.firstLogin.emailPhone.$invalid">
                                <p>{{$store.state.system_settings.usesPhones ? toLocal('first_login.phone_who') : toLocal('first_login.email_who') + ' / ' + toLocal('first_login.phone_who')}} {{toLocal('first_login.phone_requirements')}}</p>
                            </div>
                            <div class="error-mobile" v-if="isMobileWindow() && errorCode == 1">
                                <p>{{errorMessage}}</p>
                            </div>
                            <v-popover
                                v-if="$v.firstLogin.emailPhone.$anyDirty && $v.firstLogin.emailPhone.$invalid && !isMobileWindow()"
                                offset="26"
                                :open="$v.firstLogin.emailPhone.$invalid && $v.firstLogin.emailPhone.$anyDirty"
                                placement="left"
                                class="pop-left"
                                popoverClass="login-form-popover-container"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <h3>{{$store.state.system_settings.usesPhones ? toLocal('first_login.phone_who') : toLocal('first_login.email_who') + ' / ' + toLocal('first_login.phone_who')}}</h3>
                                    <span>{{toLocal('generic.field_must')}}</span>
                                    <ul>
                                    <li>
                                        {{toLocal('first_login.phone_must')}}
                                    </li>
                                    </ul>
                                </div>
                            </v-popover>
                            <v-popover
                                v-if="errorCode == 1 && !isMobileWindow()"
                                offset="26"
                                :open="errorCode == 1"
                                placement="left"
                                class="pop-left server-error"
                                popoverClass="login-form-popover-containerr"
                                trigger='manual'
                                :autoHide='false'
                            >
                                <div class="tooltip-container">
                                    <span>{{errorMessage}}</span>
                                </div>
                            </v-popover>
                        </div>
                    </div>
                <div class="selectables-container">
                    <span class="accept-label">{{toLocal('first_login.agree')}}:</span>
                    <div class="checkmark-parent-container">
                        <div 
                            class="checkmark-container" 
                            v-for="(selectable, s_id) in selectables"
                            :key="s_id"
                            :class="{'non-selectable': !selectable.isSelectable}"
                            @click="selectable.isSelectable ? selectable.selected = !selectable.selected : ''"
                        >
                            <div class="checkmark" :class="[{selected: selectable.selected}, {error: errorCode == 4 && selectable.code == errorCode && isMobileWindow()}]"  >
                                <div class="checkmark-icon">
                                        <svg v-if="selectable.selected" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                                        <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                                        </svg>
                                </div>
                            </div>
                            <span v-html="toLocal(selectable.name)"> </span>
                            <v-popover
                                    v-if="errorCode == 4 && selectable.code == errorCode && !isMobileWindow()"
                                    offset="26"
                                    :open="errorCode == 4"
                                    placement="right"
                                    class="pop-right server-error pass-error"
                                    popoverClass="login-form-popover-container"
                                    trigger='manual'
                                    :autoHide='false'
                                >
                                    <div class="tooltip-container">
                                        <span>{{errorMessage}}</span>
                                    </div>
                                </v-popover>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accept-container">
                <p>
                    {{toLocal('first_login.bottom_desc')}}
                </p>
                <div class="btn secondary" @click="saveUserDetails">
                    <span v-if="!isSaving">
                    {{toLocal('generic.save')}}
                    </span>
                    <span v-else>
                        <font-awesome-icon icon="spinner" />
                    </span>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import { required, requiredIf, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
    name: 'FirstLoginModal',
    data(){
        return{
            firstLogin:{
                username: '',
                password: '',
                confirmPassword: '',
                emailPhone: '',

            },
            selectables: [
                {
                    name: 'first_login.contact_forgot_password_2',
                    selected: this.$store.state.user_can_contact == 1 ? true : false ,
                    isSelectable: true,
                },
                {
                    name: 'first_login.contact_updates_2',
                    selected: this.$store.state.user_can_notify == 1 ? true : false,
                    isSelectable: true, 
                },
                {
                    name: 'first_login.contact_identification_2',
                    selected:  true ,
                    isSelectable: false,
                },
                {
                    name: 'settings.privacy_policy_agree',
                    isSelectable: true,
                    selected:  false,
                    required:true,
                    code: 4
                }
            ],
            selectedLanguage: this.$store.state.user_selected_language,
            errorMessage: '',
            errorCode: -1,
            isSaving: false,
            loaded: false,
            languages: [
                'LT', 'EN', 'RU', 'LV', "ET"
            ]
        }
    },
    mounted(){
        let setSettings = new Promise((resolve) =>{

            for (let key in this.$store.state.system_settings.firstloginmodal){
                let val = this.$store.state.system_settings.firstloginmodal[key];
                if(val){
                    this.$set(this.firstLogin, key,  '');
                }
                else{
                    delete this.firstLogin[key];
                }
            }
            resolve()
        });
         setSettings.then(()=>{
            this.loaded = true;
        })
      
    },
    beforeDestroy(){
        this.loaded = false;
        delete this.firstLogin;
    },
    validations: {
        firstLogin: {
            username:{
                required: requiredIf(function(el) {
                    return el.username != undefined && this.$store.state.system_settings.firstloginmodalrequired.username;
                }),
                minLength: minLength(2),
                maxLength: maxLength(32),
            },
            password:{
                required: requiredIf(function(el) {
                    return el.password != undefined && this.$store.state.system_settings.firstloginmodalrequired.password;
                }),
                minLength: minLength(8),
                maxLength: maxLength(1024),
            },
            confirmPassword:{
                 required: requiredIf(function(el) {
                    return el.confirmPassword != undefined && this.$store.state.system_settings.firstloginmodalrequired.confirmPassword;
                }),
                minLength: minLength(8),
                maxLength: maxLength(1024),
                sameAsPassword: sameAs('password')
            },
            emailPhone:{
                required: requiredIf(function(el){
                    
                    return el.emailPhone != undefined && this.$store.state.system_settings.firstloginmodalrequired.emailPhone;
                }),
                minLength: minLength(6),
                maxLength: maxLength(64),
            },
        },
    },
    methods:{
        changeLanguage(lang){
            this.$store.state.user_selected_language = lang;
            this.$store.state.language = this.$store.state.user_selected_language;
        },
        saveUserDetails(){
            this.$v.firstLogin.$touch();
            if(this.$v.firstLogin.$invalid){
                return;
            }
            this.errorMessage = '';
            this.errorCode = -1;
            if(!this.selectables[3].selected){
                this.errorMessage = this.toLocal('first_login.required_clause');
                this.errorCode = 4;
                return;
            }
            this.isSaving = true;
            let emailPhone = this.firstLogin.emailPhone ? this.firstLogin.emailPhone : '';
            if(this.$store.state.system_settings.usesPhones){
                emailPhone = emailPhone.replaceAll(' ', '');
            }
            axios.post( 
                `${this.$store.state.api_link}/user/first-login/save`,
                {
                    username: this.firstLogin.username,
                    password: this.firstLogin.password,
                    emailPhone: emailPhone,
                    can_contact: this.selectables[0].selected,
                    can_notify: this.selectables[1].selected,
                    can_identify: this.selectables[2].selected,
                    language: this.$store.state.user_selected_language,
                    terms: this.selectables[3].selected,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == 'success'){
                    this.$store.state.user_email = response.data.user.email;
                    this.$store.state.user_username = response.data.user.username;
                    this.$store.state.user_is_new = false;
                }
                else{
                    this.errorMessage = response.data.message;
                    this.errorCode = response.data.code;
                }
                this.isSaving = false;

            }).catch((error) => {
                console.log(error)
                this.isSaving = false;
            });
        },
        isFieldRequired(key){
            return this.firstLogin[key] != undefined;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/layout/_firstLoginModalScoped.scss';
.modal-container{
    .modal-wrapper{
        width: 47vw;
        max-width: 900px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        border: none;
        padding: 0 0 48px;
        min-width: 768px;
    }
}
.input-wrapper{
    flex: 5;
    margin: 0;
    span{
        font-family: var(--main-site-font);
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        margin-bottom: 8px;
        margin-left: 0;
    }
}
.password-label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: #7D7D7D;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }
}
.modal-header{
    background: var(--secondary-color);
    border-radius: 16px 16px 0px 0px;
    padding: 16px;
    padding-left: 32px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    h2{
        font-family: var(--main-site-font);
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: start;
        padding: 0;
        color: #333333;
        border: none;
    }
}
.selectables-container{
    flex: 4;
    margin-left: 40px;
    padding-left: 40px;
    border-left: 1px solid #7d7d7d79;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: var(--main-site-font);
    .accept-label{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        font-family: var(--main-site-font);
    }
    
    .checkmark-container{
        margin: 12px 0;
        position: relative;
        width: fit-content;
        .checkmark{
            background: #F9F9F9;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            border: none;
            height: 28px;
            width: 28px;
            min-width: 28px;
            &.error{
                border: 1px solid red;
            }
            &.selected{
                background: var(--secondary-color);
                border: none;
              
            }
        }
        span{
            
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            margin-left: 12px;
            font-family: var(--main-site-font);
        }
        &.non-selectable{
            .checkmark{
                opacity: 0.5;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:first-of-type{
            margin-top: 8px;
        }
    }
}
.modal-content{
    .row{
        padding: 40px 32px;
    }
}
.checkmark-parent-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.input-container{
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    input{
        background: #F9F9F9;
        border-bottom: 1px solid #EFEFEF;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        height: 44px;
        padding: 11px 16px;
        -webkit-user-select: initial;
        -khtml-user-select: initial;
        -moz-user-select: initial;
        -ms-user-select: initial;
        user-select: initial;
    }
    input[type="password-field"]{
        -webkit-text-security: disc;
    }
    
    &:last-child{
        margin-bottom: 0;
    }
}
.accept-container{
    margin-top: 20px;
    p{
        text-align: center;
        margin: 0 0 24px;
    }
    .btn{
        background: var(--secondary-color);
        color: var(--primary-color);
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
        border-radius: 40px;
        border: none;
        max-width: 272px;
    }
}
.row{
    display: flex;
}
.tooltip-container{
    border: 2px solid #D45454;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #D45454;
    span{
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: #D45454;
    }
    &::after{
        border-color: #D45454;
    }
}
@media (max-width: 768px){
    .modal-container{
        .modal-wrapper{
            width: 100%;
            min-height: 100%;
            overflow: auto;
            padding: 0px;
            min-width: 100%;
            border-radius: 0;
            height: 100%;
            .modal-content{
                width: 100%;
                margin-bottom: 40px;

            }
            .modal-header{
                border-radius: 0;
            }
        }
    }
    .modal-content{
        .row{
            flex-direction: column;
            padding: 20px;
        }
    }
    .error-mobile{
        top: calc(100% + 5px);
        bottom: initial;
        p{
            line-height: 14px;
        }
    }
    h2{
        font-size: clamp(16px, 5vw, 28px);
        padding: 0 0 1rem 0;
    }
    .input-wrapper{
        span{
            margin: 0;
        }
    }
    .input-container{
        margin-bottom: 3vh;
    }
    .accept-container{
        padding: 0 20px;
        .btn{
            width: 100%;
        }
    }
    .selectables-container{
        margin-top: 2rem;
        margin-left: 0;
        padding-left: 0;
        border: none;
    }
    .checkmark-container{
        margin: 0.5rem 0;
    }
}

</style>
<style lang="scss">

.checkmark-container{
    
    .v-popover{
        width: initial;
        top: initial;
        height: initial;
        left: initial;
        right: initial;
    }
   
    .pop-right{
        left: calc(100% + 22px);
        .tooltip-container{
            span{
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;

                color: #D45454;
            }
            &::after{
                transform: rotate(0deg);
                left: -1.3rem;
                right: initial;
                background: transparent;
                border-top: 10px solid transparent;
                border-right: 20px solid white;
                border-bottom: 10px solid transparent;
                border-left: 10px solid transparent;
            }
            &::before{
                position: absolute;
                content: '';
                top: 0;
                left: -1.5rem;
                right: initial;
                bottom: 0;
                margin: auto;
                background: transparent;
                transform: rotate(0deg);
                display: flex;
                width: 1px;
                height: 1px;
                border-top: 10px solid transparent;
                border-right: 18px solid #D45454;
                border-bottom: 10px solid transparent;
                border-left: 10px solid transparent;
            }
        }
    }
}
</style>