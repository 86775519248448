<template>
  <div>
        
        <div class="grid-table">
          <div class="table-row header">
            <div class="table-cell">
              <span class="name-cell header">
                {{toLocal('generic.name')}}
                <img
                  v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('name')"
                >
                <img
                  v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('name')"
                >
                <img
                  v-if="orderByElement != 'name'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('name')"
                >
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{this.toLocal('generic.surname')}}
                <img
                  v-if="orderByElement == 'last_name' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('last_name')"
                >
                <img
                  v-if="orderByElement == 'last_name' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('last_name')"
                >
                <img
                  v-if="orderByElement != 'last_name'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('last_name')"
                >
              </span>
            </div>
    
            <div class="table-cell">
              <span class="header">
                {{toLocal('generic.approved')}}
                <img
                  v-if="orderByElement == 'confirmed' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('confirmed')"
                >
                <img
                  v-if="orderByElement == 'confirmed' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('confirmed')"
                >
                <img
                  v-if="orderByElement != 'confirmed'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('confirmed')"
                >
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{toLocal('training_stats.last_approval_date')}}
                <img
                  v-if="orderByElement == 'latest_date' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('latest_date')"
                >
                <img
                  v-if="orderByElement == 'latest_date' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('latest_date')"
                >
                <img
                  v-if="orderByElement != 'latest_date'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('latest_date')"
                >
              </span>
            </div>
           
  
          </div>
          <div class="table-row parent" v-for="(user, user_id) in users" :key="user_id" >
            <div class="table-row">
              <div class="table-cell">
      
                <span  
                  @mouseover="scrollNameHorizontal($event,true)" 
                  @mouseleave="scrollNameHorizontal($event,false)"
                >
                   {{user.user ? user.user.name : '-'}}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event,true)" 
                  @mouseleave="scrollNameHorizontal($event,false)"
                >
                  {{user.user ? user.user.last_name : '-'}}
                </span>
              </div>
              <div class="table-cell">
                <span
              
                >
                  {{user.stats.length}} / {{totalCount}}
                </span>
              </div>
              <div class="table-cell">
                <span
              
                >
                  {{user.latest_date}}
                </span>
              </div>
              <div class="table-cell actions"><span
        
              >
                  <svg @click="$emit('setTestData', {'user': user.user, 'stats':user.stats, 'openedConfirmationSide': user_id})" xmlns="http://www.w3.org/2000/svg" width="27.084" height="35.342" viewBox="0 0 27.084 35.342">
                    <g id="Group_11327" data-name="Group 11327" transform="translate(-1497.768 -340.25)">
                      <g id="Group_11282" data-name="Group 11282" transform="translate(1498.518 341)">
                        <path id="Path_280_1_" d="M36.348,11.689A5.669,5.669,0,1,1,30.679,6.02h0a5.661,5.661,0,0,1,5.669,5.635C36.348,11.668,36.348,11.682,36.348,11.689Z" transform="translate(-20.603 -6.02)" fill="none" stroke="#464646" stroke-miterlimit="10" stroke-width="1.5"/>
                        <path id="Path_529" data-name="Path 529" d="M33.34,42.185H23.771a5.291,5.291,0,0,1-5.291-5.291V33.176A10.076,10.076,0,0,1,28.556,23.1h0A10.076,10.076,0,0,1,38.631,33.176v3.718A5.291,5.291,0,0,1,33.34,42.185Z" transform="translate(-18.48 -11.574)" fill="none" stroke="#464646" stroke-miterlimit="10" stroke-width="1.5"/>
                      </g>
                      <g id="Group_11288" data-name="Group 11288" transform="translate(-0.592 -1)">
                        <circle id="Ellipse_614" data-name="Ellipse 614" cx="9.796" cy="9.796" r="9.796" transform="translate(1505.853 357)" fill="#464646"/>
                        <g id="Group_11285" data-name="Group 11285" transform="translate(1513.989 360.608)">
                          <path id="Path_8346" data-name="Path 8346" d="M-10067.338,5753.5a1.152,1.152,0,0,1-1.152-1.152v-6.255a1.152,1.152,0,0,1,1.152-1.152,1.152,1.152,0,0,1,1.152,1.152v6.255A1.152,1.152,0,0,1-10067.338,5753.5Z" transform="translate(10068.888 -5741.018)" fill="#fff"/>
                          <circle id="Ellipse_612" data-name="Ellipse 612" cx="1.66" cy="1.66" r="1.66" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>
  
              </span>
              </div>
          
            </div>
            </div>
                
        </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:{
    cover:{
      type: String,
    }
  },
  data(){
    return{
      buttons: [
        {
          name: 'Testas',
          last_name: 'Testauskas',
          done: 1,
          total: 4,
          
        }
      ],
      users: [],
      orderByElement: '',
      totalCount: 0,
    }
  },
  mounted(){
    this.getConfirmData();
  },
  methods:{
    getConfirmData(){
        axios.get(
            `${this.$store.state.api_link}/training/confirmed/${this.$store.state.currentTrainingRented ? 1: 0}/${this.$store.state.currentTraining}`,
            this.$store.getters.axiosConfig()
        ).then((response) => {
          this.users = response.data.users;
          this.totalCount = response.data.confirm_btn_count;
          this.$emit('setConfirmationData', this.users, this.totalCount);
        }).catch((error) => {
            console.log(error)
        });
    },
    setOrderBy(element) {
        if(element == this.orderByElement){
            this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
        }else{
            this.orderByElement = element;
            this.orderByDirection = "asc";
        }
        if(['name', 'last_name'].includes(element)){
            if(this.orderByDirection == "desc"){
              this.users.sort((a,b) => b[element].localeCompare(a[element])); 
            }else{
              this.users.sort((a,b) =>  a[element].localeCompare(b[element])); 
            }
        }
        else if(element == 'confirmed'){
            if(this.orderByDirection == "desc"){
              this.users.sort((a,b) => b.stats.length - a.stats.length); 
            }else{
              this.users.sort((a,b) =>  a.stats.length - b.stats.length); 
            }
        }
        else if(element == 'latest_date'){
            if(this.orderByDirection == "desc"){
              this.users.sort((a,b) => Date.parse(b[element])-Date.parse(a[element])); 
            }else{
              this.users.sort((a,b) => Date.parse(a[element])-Date.parse(b[element])); 
            }
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.training--summary {
  background-color: #fff;
  border-radius:  20px;
  margin-bottom: 1rem;

  .training--cover {
    float: left;
    width: 287px;
    height: 287px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    background-image: url("../../../../../assets/images/placeholders/pia21974.0.jpg");
  }
  .training--info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
    padding: 15px;
    padding-left: 35px;
    height: 287px;
    h2 {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.summary--button {
  border-radius: 14px;
  border: 2px solid #464646;
  cursor: pointer;
  margin-right: 25px;
  padding: 4px 10px;
  padding-right: 16px;
  margin-top: 5px;
  display: inline-block;
  user-select: none;
  max-width: 10rem;
  img {
    max-width: 27px;
    max-height: 25px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.group--list th img {
  cursor: pointer;
  user-select: none;
}

.group--list tbody tr td:nth-child(1) {
  cursor: pointer;
  user-select: none;
}
.grid-table{
    display: grid;
    grid-template-rows: auto;
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    .table-row{
        display: grid;
        grid-template-columns: minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(30px, 0.75fr) ;
        // margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        background: #efefef;
        &.header{
            background: white;
            font-weight: 700;
            color: var(--primary-color);
        }
        &.parent{
            grid-template-columns:1fr;
            margin: 0.25rem 0;
            padding:0;
        }
        .table-cell{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            padding: 0.25rem 0;
            border-left: 1px solid var(--primary-color);
            &:first-child{
                border-left: none;
            }
            span{
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                margin:0 auto;
                justify-content: center;
                &.name-cell{
                    margin-left: 1.7rem;
                }
                img{
                    margin: 0 0.25rem;
                }
                &.header{
                    display: flex;
                   white-space: normal;
                   font-size: 18px;
                }
            }

            svg{
                height: 100%;
                // width: 1.7rem;
                
            }
        }
    }
}
.actions{
    svg{
        cursor: pointer;
    }
}
.search-container{
    grid-area: search;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    .search-icon{
        display: flex;
        margin-right: 0.25rem;
        height: 100%;
        align-items: center;
        .remove{
            display: flex;
            position: relative;
            width: 1rem;
            height: 100%;
            cursor: pointer;
            transition: transform 0.3s;
            span{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 80%;
                height: 2px;
                background: var(--primary-color);
                border-radius: 1rem;
                transform: rotate(45deg);
            }
            span:last-child{
                transform: rotate(-45deg);
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    svg{
        path{
            fill: var(--primary-color);
        }
    }
    input{
        background: none;
        width: 100%;
        height: 100%;
        border: none;
        &:focus{
            outline: none;
        }
    }
}
.filter-container{
  display: flex;
  flex-direction: column;
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 2rem;
  margin-top: 1rem;
  .filter{
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px
  };
  .search-container{
    background: #efefef;
    display: flex;
    width: 100%;
    max-width:20rem;
    height: 100%;
    input{
      font-size: clamp(16px, 1.1vw, 20px);
    }
  }
  .group-filter{
    background: #efefef;
    border-radius: 1rem;
    margin: 0 1rem;
    height: 100%;

  }
  .label{
    margin-right: 1rem;
  }
  &.empty{
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
}
</style>