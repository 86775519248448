var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-modal-container"},[_c('div',{staticClass:"info-modal"},[_c('div',{staticClass:"close-btn",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._m(0)]),_c('div',{staticClass:"row header"},[_c('h2',[_vm._v(_vm._s(_vm.toLocal('test.attest_info')))])]),_c('table',[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.toLocal('test.attest_user'))+" ")]),_c('td',{staticClass:"second-cell info"},[_c('div',{staticClass:"info-container"},[_c('CustomDropdown',{attrs:{"list":_vm.training.training_users ? _vm.training.training_users : [],"compareChosen":_vm.user,"key-label":"id","value-label":"label","options":{
                                closeOnClick: true,
                                checkbox: false,
                                customDDWidth: '100%',
                                customMaxHeight: '300px',
                                initial: _vm.getInitialDropdownText(),
                                showCreateNewBtn: false,
                                containerClass: 'no-padding-no-bg test-users-dropdown',
                                searchBar: true,
                                searchLocally: true
                            }},on:{"elClicked":_vm.addUser}})],1)])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.toLocal('test.max_points'))+" ")]),_c('td',{staticClass:"second-cell"},[_vm._v(_vm._s(_vm.test.options.testMaxScore))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.toLocal('test.passable_from'))+" ")]),_c('td',{staticClass:"second-cell"},[_c('span',[_vm._v(_vm._s(_vm.test.options.testPassedFrom)+" %")])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.toLocal('test.question_count'))+" ")]),_c('td',{staticClass:"second-cell"},[_c('span',[_vm._v(_vm._s(_vm.test.options.testShownQuestionCount))])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span'),_c('span')])}]

export { render, staticRenderFns }