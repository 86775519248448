<template>
  <div v-if="isLoading == false">
    <GlobalModal
      v-if="isUploadModalOpen == true"
      :modalInfo="{
        class: 'doc-upload-modal',
        type: 'new-doc',
        user: user,
        editedDocument: editingDocument ? editingDocument : '',
        data: docCopy,
      }"
      @onClose="closeDocModal"
    />
    <GlobalModal
      :modalInfo="{
        text: 'delete_modal.are_you_sure',
        undertext: 'delete_modal.docs_deletion_undertext',
        type: 'multiChoice',
        multiChoiceYes: 'generic.yes',
        multiChoiceNo: 'generic.no',
      }"
      @onChoiceOne="continueDeleteDoc()"
      @onChoiceTwo="closeDeletionModal()"
      @onClose="closeDeletionModal()"
      v-if="isDocBeingDeleted"
    />

    <div class="header-container">
      <div class="left-box">
        <div class="search-container">
          <span class="count"
            >{{ toLocal("user_docs.doc_count") }} {{ docCopy.length }}</span
          >
          <div class="search-container">
            <SearchSVG class="search-icon" v-if="searchParam == ''" />
            <input
              :class="{ gapLeft: searchParam == '' }"
              type="text"
              v-model="searchParam"
              class="search-input"
              :placeholder="toLocal('generic.search')"
            />
          </div>
        </div>
        <div class="upload-btn" @click="openUpload" v-if="hasPermission('users.upload_documents')">
          <span><DocSVG /></span>
          <span>{{ toLocal("user_docs.upload_new") }}</span>
          <span><DocDownSVG /></span>
        </div>
      </div>
      <div class="right-box">
        <div class="dropdown-with-label">
          <span class="d-label">{{
            toLocal("user_docs.show_these_tags")
          }}</span>
          <CustomDropdown
            class="c-dropdown docs-dropdown-container"
            :list="tagsList"
            :compare-list="chosenTags"
            :options="{
              closeOnClick: false,
              checkbox: true,
              initial: 'user_docs.select_tag',
            }"
            @elClicked="filterByTag"
          />
        </div>
        <div class="dropdown-with-label sort">
          <span class="d-label">{{ toLocal("user_side.sort_by") }}</span>
          <CustomDropdown
            class="c-dropdown docs-dropdown-container"
            :list="sortList"
            :compare-chosen="chosenSort"
            :options="{
              checkbox: false,
              initial: chosenSort.name
                ? chosenSort.name
                : toLocal('user_side.sort_by'),
            }"
            @elClicked="sortBy"
          />
        </div>
      </div>
    </div>
    <div class="doc-container">
      <div
        class="document"
        v-for="(doc, index) in documents"
        :key="`doc-${index}`"
      >
        <div class="image-container">
          <img
            class="image"
            :src="
              doc.file
                ? getFileUrl(doc.file)
                : require(`@/assets/images/placeholder_image_${$store.state.language}.png`)
            "
            alt="document"
          />
        </div>
        <div class="text-container">
          <div
            class="info-top"
            :class="{
              renaming: editingDocumentName && documentEditIndex == index,
            }"
          >
            <span
              class="title"
              v-if="!editingDocumentName || documentEditIndex != index"
              >{{ doc.title }}</span
            >
            <input
              class="title-editing"
              :id="`input-${index}`"
              v-if="editingDocumentName && documentEditIndex == index"
              v-model="editingName"
              v-click-outside-3="closeRenamingMode"
            />
            <div class="dots-container" v-if="hasPermission('users.edit_documents')">
              <span
                class="bolder dots"
                :class="{ active: dotsMenuIndex == index }"
                @click="openDotsMenu(index)"
                ><DotsSVG
              /></span>
              <div
                class="dots-menu"
                v-click-outside-3="closeDotsMenu"
                v-if="isDotsMenuOpen && dotsMenuIndex == index"
              >
                <span
                  class="dots-option"
                  @click="editDocumentName(index, doc.title)"
                  >{{ toLocal("user_docs.rename") }}</span
                >
                <span class="dots-option" @click="editCertificate(doc)">{{
                  toLocal("user_docs.update")
                }}</span>
                <span class="dots-option red" @click="deleteCert(doc)">{{
                  toLocal("user_docs.delete")
                }}</span>
              </div>
            </div>
          </div>
          <div class="info-bottom">
            <div class="doc-number column">
              <span>{{ toLocal("modal.doc_number") }}:</span>
              <span class="bolder">{{ doc.number ? doc.number : "-" }}</span>
            </div>
            <div class="passed-date column">
              <span>{{ toLocal("modal.passing_date") }}:</span>
              <span class="bolder">{{
                getCreatedDate(doc)
              }}</span>
            </div>
            <div class="cert-valid-till column">
              <span>{{ toLocal("users.certificate_valid") }}:</span>
              <span class="bolder">{{
                doc.expires_at && doc.expires_at != ""
                  ? doc.expires_at.split(" ")[0]
                  : "-"
              }}</span>
              <span class="label" :class="getCertificateStatus(doc)[1]">
                <span>
                  <CheckSVG
                    v-if="
                      getCertificateStatus(doc)[1] == 'yellow' ||
                      getCertificateStatus(doc)[1] == 'green'
                    "
                  />
                  <CrossSVG
                    v-if="getCertificateStatus(doc)[1] == 'red'"
                  /> </span
                ><span>
                  {{ getCertificateStatus(doc)[0] }}
                </span></span
              >
            </div>
            <div class="tags column">
              <span class="tags-with-plus"
                >{{ toLocal("user_docs.assigned_tags") }}
                <div class="tag-container" @click="openTagList(index)">
                  <TagAddSVG class="tag-add" /></div
              ></span>
              <span
                class="tag"
                v-for="(doc_tag, index) in doc.tags"
                :key="`doc_tag-${index}`"
              >
                <TagSVG />
                <span> {{ doc_tag.name }}</span>
              </span>
            </div>
            <div
              class="dd column"
              v-if="isTagsAddOpened && tagsOpenIndex == index"
            >
              <CustomDropdown
                class="c-dropdown on-card"
                :list="tagsList"
                :compare-list="doc.tags"
                :options="{
                  checkbox: true,
                  searchBar: true,
                  closeBtn: true,
                  createNewAtEmpty: true,
                  closeOnClick: false,
                  customMaxHeight: '100px',
                  customDDWidth: '100%',
                  attached: {
                    id: doc.id,
                  },
                  open: true,
                  initial: 'user_docs.select_tag',
                  createNewText: 'user_docs.create_tag',
                }"
                @elClicked="addTagToDoc"
                @search="searchTags"
                @close="closeTagList"
                @createNew="createNewTag"
                v-click-outside-3="closeTagList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import GlobalModal from "../../modals/GlobalModal.vue";
import CustomDropdown from "../../elements/CustomDropdown.vue";
import TagAddSVG from "@/assets/images/icons/users/users-tag-add.svg?inline";
import TagSVG from "@/assets/images/icons/users/users-tag.svg?inline";
import CheckSVG from "@/assets/images/icons/users/check.svg?inline";
import CrossSVG from "@/assets/images/icons/users/cross.svg?inline";
import DocSVG from "@/assets/images/icons/users/users-docs-up.svg?inline";
import DocDownSVG from "@/assets/images/icons/users/users-docs-down.svg?inline";
import SearchSVG from "@/assets/images/icons/users/search.svg?inline";
import DotsSVG from "@/assets/images/icons/users/users_doc_dots.svg?inline";
export default {
  name: "UserDocs",
  components: {
    GlobalModal,
    CustomDropdown,
    TagAddSVG,
    TagSVG,
    CheckSVG,
    CrossSVG,
    DocSVG,
    DocDownSVG,
    SearchSVG,
    DotsSVG,
  },
  data() {
    return {
      isTagsAddOpened: false,
      tagsOpenIndex: -1,
      documents: [],
      docCopy: [],
      searchParam: "",
      isLoading: false,
      isUploadModalOpen: false,
      editingDocument: {},
      isDotsMenuOpen: false,
      dotsMenuIndex: -1,
      editingDocumentName: false,
      documentEditIndex: -1,
      editingName: "",
      isDocBeingDeleted: false,
      docDeletionId: -1,
      tagsList: [],
      tagsCopy: [],
      chosenTags: [],
      sortList: [
        { id: 1, name: this.toLocal("users.sort_name_first") },
        { id: 2, name: this.toLocal("users.sort_name_last") },
        { id: 3, name: this.toLocal("user_docs.created_at_asc") + " ↑" },
        { id: 4, name: this.toLocal("user_docs.created_at_asc") + " ↓" },
        { id: 5, name: this.toLocal("user_docs.expiry_date") + " ↑" },
        { id: 6, name: this.toLocal("user_docs.expiry_date") + " ↓" },
      ],
      chosenSort: { id: 1, name: this.toLocal("users.sort_name_first") },
    };
  },
  mounted() {
    this.getDocs();
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    searchParam: {
      handler() {
        this.filterDocsByTag();
      },
    },
  },
  methods: {
    filterByTag(tag) {
      let singleTag = this.tagsList.find((t) => t.id == tag);
      if (this.chosenTags.filter((t) => t.id == tag).length > 0) {
        this.chosenTags.splice(this.chosenTags.indexOf(singleTag), 1);
      } else {
        this.chosenTags.push(singleTag);
      }

      this.filterDocsByTag();
    },

    filterDocsByTag() {
      if (this.chosenTags.length <= 0) {
        this.documents = _.cloneDeep(this.docCopy);
        this.searchDocs(this.documents);
      } else {
        let chosenTagIDs = this.chosenTags.map((s_tag) => {
          return s_tag.id;
        });

        this.documents = this.docCopy.filter((dCopy) => {
          let docTagIDs = dCopy.tags.map((doc_tag) => {
            return doc_tag.id;
          });

          return docTagIDs.filter((el) => chosenTagIDs.includes(el)).length > 0;
        });
        this.searchDocs(this.documents);
      }
    },
    getCreatedDate(doc) {
      if (doc.created_at) {
        let date = doc.created_at.split(" ")[0];
        date = date.split("T")[0];
        return date;
      }
      else return '-'
    },
    addTagToDoc(tag, attachment) {
      if (attachment.id != "") {
        let doc = this.documents.filter((el) => el.id == attachment.id)[0];
        let docCopy = this.docCopy.filter((el) => el.id == attachment.id)[0];
        let singleTag = this.tagsList.filter((el) => el.id == tag)[0];
        let doesTagExist = doc.tags.filter((el) => el.id == tag);
        if (doesTagExist.length > 0) {
          let item = doesTagExist[0];
          doc.tags.splice(
            doc.tags.findIndex((el) => el.id == item.id),
            1
          );
          docCopy.tags.splice(
            docCopy.tags.findIndex((el) => el.id == item.id),
            1
          );
        } else {
          doc.tags.push(singleTag);
          docCopy.tags.push(singleTag);
        }
        axios
          .post(
            `${this.$store.state.api_link}/tag/attach`,
            { tags: doc.tags, doc: attachment.id },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {})
          .catch((error) => {
            console.log("error getting info");
          });
      }
    },
    createNewTag(name) {
      axios
        .post(
          `${this.$store.state.api_link}/tag/new`,
          { name: name },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status != "error") {
            this.tagsList = response.data.tags;
          }
        })
        .catch((error) => {
          console.log("error getting info");
        });
    },
    closeTagList() {
      this.isTagsAddOpened = false;
      this.tagsOpenIndex = -1;
      this.tagsList = _.cloneDeep(this.tagsCopy);
    },
    searchTags(search) {
      if (search == null) {
        this.tagsList = this.tagsCopy;
      } else {
        this.tagsList = this.tagsCopy.filter((t) => {
          return t.name.toLowerCase().includes(search.toLowerCase());
        });
      }
    },
    openTagList(index) {
      this.isTagsAddOpened = true;
      this.tagsOpenIndex = index;
    },
    sortBy(id) {
      this.chosenSort =
        this.sortList[this.sortList.findIndex((x) => x.id == id)];
      this.sortDocs();
    },
    closeRenamingMode() {
      this.editingDocumentName = false;
      this.documents[this.documentEditIndex].title = this.editingName;
      this.docCopy[this.documentEditIndex].title = this.editingName;
      this.saveRenamedDoc(
        this.documents[this.documentEditIndex].id,
        this.editingName
      );
    },
    sortDocs() {
      let sortID = this.chosenSort.id;
      switch (sortID) {
        case 1:
          this.documents = _.orderBy(this.documents, ["title"], ["asc"]);
          break;
        case 2:
          this.documents = _.orderBy(this.documents, ["title"], ["desc"]);
          break;
        case 3:
          this.documents = _.orderBy(this.documents, ["created_at"], ["asc"]);
          break;
        case 4:
          this.documents = _.orderBy(this.documents, ["created_at"], ["desc"]);
          break;
        case 5:
          this.documents = _.orderBy(this.documents, ["expires_at"], ["asc"]);
          break;
        case 6:
          this.documents = _.orderBy(this.documents, ["expires_at"], ["desc"]);
          break;
        default:
          break;
      }
    },
    saveRenamedDoc(id, name) {
      axios
        .post(
          `${this.$store.state.api_link}/document/rename`,
          { id: id, name: name },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status != "error") {
            this.documentEditIndex = -1;
          }
        })
        .catch((error) => {
          console.log("error getting info");
        });
    },
    deleteCert(doc) {
      this.isDocBeingDeleted = true;
      this.docDeletionId = doc.id;
    },
    continueDeleteDoc() {
      axios
        .post(
          `${this.$store.state.api_link}/document/delete`,
          { id: this.docDeletionId },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status != "error") {
            this.getDocs();
            this.isDocBeingDeleted = false;
            this.docDeletionId = -1;
          }
        })
        .catch((error) => {
          console.log("error getting info");
        });
    },
    closeDeletionModal() {
      this.isDocBeingDeleted = false;
      this.docDeletionId = -1;
    },
    editDocumentName(index, title) {
      this.documentEditIndex = index;
      this.editingDocumentName = true;
      this.editingName = title;
      this.isDotsMenuOpen = false;
      this.dotsMenuIndex = -1;
      this.$nextTick(() => {
        document.getElementById(`input-${index}`).focus();
      });
    },
    closeDocModal() {
      this.isUploadModalOpen = false;
      this.getDocs(true);
      this.editingDocument = {};
      if (this.searchParam != "") {
        this.searchDocs();
      }
    },
    openUpload() {
      this.isUploadModalOpen = true;
    },
    openDotsMenu(index) {
      if (this.isDotsMenuOpen == true) {
        this.isDotsMenuOpen = false;
        this.dotsMenuIndex = -1;
      } else {
        this.isDotsMenuOpen = true;
        this.dotsMenuIndex = index;
      }
    },
    closeDotsMenu() {
      this.isDotsMenuOpen = false;
      this.dotsMenuIndex = -1;
    },
    editCertificate(doc) {
      this.editingDocument = doc;
      this.isUploadModalOpen = true;
    },
    getDocs(noLoad = false) {
      if (noLoad == false) {
        this.isLoading = true;
      }
      axios
        .post(
          `${this.$store.state.api_link}/user/docs/detailed`,
          { user_id: this.user.id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status != "error") {
            this.documents = response.data.data.documents;
            this.docCopy = _.cloneDeep(this.documents);
            this.tagsList = response.data.data.tags;
            this.tagsCopy = _.cloneDeep(this.tagsList);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error getting info");
        });
    },
    getCertificateStatus(doc) {
      let now = new Date();
      let difference =
        parseInt(new Date(doc.expires_at) - now) / (1000 * 60 * 60 * 24);
      let certStatus = [];
      if (doc.expires_at == "") {
        certStatus = [this.toLocal("user_docs.doc_valid"), "green"];
      } else if (difference > 0 && difference > 30) {
        certStatus = [this.toLocal("user_docs.doc_valid"), "green"];
      } else if (difference > 0 && difference <= 30) {
        certStatus = [this.toLocal("user_docs.doc_valid"), "yellow"];
      } else if (difference <= 0) {
        certStatus = [this.toLocal("user_docs.doc_invalid"), "red"];
      }
      return certStatus;
    },
    searchDocs(docArray) {
      if (this.searchParam != "") {
        this.documents = docArray.filter((el) =>
          el.title.includes(this.searchParam)
        );
      } else {
        this.documents = _.cloneDeep(docArray);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
}
.left-box {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  .search-container {
    gap: 8px;
    display: flex;
    flex-direction: column;
    font-size: clamp(12px, 1.5vw, 14px);
    position: relative;
    justify-content: center;
    .search-icon {
      position: absolute;
      left: 8px;
    }
    .gapLeft {
      padding-left: 28px;
    }
    input {
      border-radius: 44px;
      border: 1px solid #b2b2b2;
      padding: 4px 8px;
    }
  }
  .upload-btn {
    border-radius: 44px;
    background: #f3de18;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    font-size: clamp(12px, 1.5vw, 14px);
    padding: 4px 10px;
    cursor: pointer;
    transition: 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span {
      display: flex;
      align-items: center;
      svg path {
        transition: 0.25s;
      }
    }
    &:hover {
      background: #4d4d4d;
      color: white;
      svg path {
        fill: white;
      }
    }
  }
}
.right-box {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  min-width: 50%;
}
.doc-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 16px 0;
  .document {
    display: flex;
    padding: 20px;
    border-radius: 12px;
    background: #f4f4f4;
    gap: 20px;
    .image-container {
      width: 180px;
      height: 126px;
      border-radius: 8px;
      border: 1.5px solid #e2e2e2;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}
.text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: hsla(0, 0%, 20%, 1);
  .info-top {
    &:not(.renaming) {
      padding: 4px 0;
    }
    border-bottom: 1px solid #4d4d4d;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: clamp(16px, 1.5vw, 18px);
    }
  }
  .title-editing {
    border: none;
    background: #f4f4f4;
    border-bottom: 1px solid black;
    padding: 8px 0;
    font-size: clamp(16px, 1.5vw, 18px);
    width: 80%;
    overflow-x: hidden;
  }
  .info-bottom {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}
.dots-container {
  position: relative;
  .dots-menu {
    border-radius: 4px;
    background: #f9f9f9;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 85%;
    right: 0;
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 120px;
    pointer-events: all;
    z-index: 99;
    .dots-option {
      padding: 6px 8px;
      cursor: pointer;
      transition: 0.25s;
      font-size: clamp(12px, 1.5vw, 14px);
      &.red {
        color: #d45454;
      }
      &:hover {
        background: #e2e2e2;
        font-weight: 600;
      }
    }
  }
  .dots {
    border-radius: 4px;
    transition: 0.25s;
    cursor: pointer;
    user-select: none;
    padding: 8px 8px;
    display: flex;
    &.active {
      color: white;
      background: #4d4d4d;
      svg g {
        fill: white;
      }
    }
    svg {
      transition: 0.25s;
    }
    &:hover {
      color: white;
      background: #4d4d4d;
      svg g {
        fill: white;
      }
    }
  }
}
.info-bottom {
  padding-top: 8px;
  .column {
    display: flex;
    flex-direction: column;
    font-size: clamp(12px, 1.5vw, 14px);
    gap: 8px;
    .bolder {
      font-weight: 600;
    }
  }
}

.tags {
  min-width: 15%;
  width: 20%;
  .tag {
    border-radius: 4px;
    background: #cacaca;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    max-width: 100%;
    justify-content: flex-start;
    padding-left: 4px;
    span {
      max-width: 85%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .tag-container {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      background-color: #4d4d4d;
      svg,
      path {
        stroke: white;
      }
    }
  }
}
.tags-with-plus {
  display: flex;
  justify-content: space-between;
}
.label {
  padding: 2px 8px;
  border-radius: 4px;
  &.green {
    background-color: #85e091;
  }
  &.red {
    background-color: #ff7f7f;
  }
  &.yellow {
    background-color: #f3de18;
  }
}
.dropdown-with-label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.sort {
    min-width: 30%;
  }
}
.d-label {
  font-size: 14px;
}

.c-dropdown {
  background-color: var(--secondary-color);
  border-radius: 6px;
  color: white;
  padding: 4px 10px;
  border: none;
}

.dd {
  min-width: 25%;
  position: absolute;
  top: 8px;
  right: 0;
  .dropdown-extended {
    width: 100%;
  }
}
.count {
  font-size: 14px;
}
</style>
<style lang="scss">
.docs-dropdown-container{
  color: white;
  &.dropdown-container {

    .dropdown-initial{
        color: white;
        span{
          max-width: 85%;
        }
  
    }
    .dropdown-extended .dropdown-option span{
      max-width: 85%;
    }
  }
}
</style>