<template>
 <div class="row">
    <span class="row-label">{{toLocal('report.shown_colums_label')}}:</span>
    <CustomDropdown
        :list="shownColumns"
        key-label="value"
        value-label="label"
        :compare-list="computedSelectedColumns"
        :options="{
            closeOnClick: false,
            customDDWidth: 'initial',
            customDDMaxWidth: '300px',
            customDDMinWidth: '100%',
            customMaxHeight: '250px',
            initial: 'generic.select_columns',
            containerClass: 'report-dropdown '+getContrast(),
            searchBar: false,
            selectAllChoice:toLocal('generic.all_data'),
            checkbox: true,
            left:true,
            showCreateNewBtn: false,
            valueWidthType: 'dynamic'
        }"
        @elClicked="selectShownColumn"
        @elAll="selectAllShownColumn"
    >
        <template v-slot:arrow >
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.5L6 6.5L11 1.5" stroke="#333333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </template>
    </CustomDropdown>
</div>
</template>

<script>
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

export default {
    props: {
        selectedShownColumns: Array
    },
    components: {
        CustomDropdown,
    },
    data() {
        return {
             shownColumns: [
                {id: 1, label: 'payments.placeholder_name_surname', value: 'name'},
                {id: 23, label: 'columns.last_appearance', value: 'last_appeared_at'},
                {id: 2, label: 'columns.selected_groups', value: 'groups'},
                {id: 3, label: 'columns.selected_trainings', value: 'trainings'},
                {id: 4, label: 'columns.training_status', value: 'training_status'},
                {id: 5, label: 'columns.training_start', value: 'training_start'},
                {id: 6, label: 'columns.training_end', value: 'training_end'},
                {id: 7, label: 'columns.training_duration', value: 'training_duration'},
                {id: 8, label: 'columns.assigned_tests', value: 'tests'},
                {id: 9, label: 'generic.test_status', value: 'test_status'},
                {id: 10, label: 'columns.test_start', value: 'test_start'},
                {id: 11, label: 'columns.test_end', value: 'test_end'},
                {id: 12, label: 'columns.test_duration' , value: 'test_duration'},
                {id: 13, label: 'columns.test_rating', value: 'test_result'},
                {id: 14, label: 'columns.test_solve_times', value: 'test_solve_times'},
                {id: 24, label: 'columns.attest_cols', value: 'attest_cols'},
                {id: 15, label: 'columns.confirmation_button', value: 'confirmation_button'},
                {id: 16, label: 'columns.confirmation_date', value: 'confirmation_date'},
                {id: 17, label: 'columns.selected_certificates', value: 'certificates'},
                {id: 18, label: 'columns.certificate_expires', value: 'certificate_duration'},
                {id: 19, label: 'columns.assigned_documents', value: 'documents'},
                {id: 20, label: 'columns.document_number', value: 'document_number'},
                {id: 21, label: 'columns.document_expiration_start', value: 'document_start'},
                {id: 22, label: 'columns.document_expiration_end', value: 'document_end'},
            ],
            selectedShownColumnsCopy: [],
        }
    },
    mounted() {
        if(this.selectedShownColumns)
            this.selectedShownColumnsCopy = this._.cloneDeep(this.selectedShownColumns);
    },
    watch: {
        selectedShownColumnsCopy() {
            this.$emit('selectShownColumns', this.selectedShownColumnsCopy);
        }
    },
    computed: {
        computedSelectedColumns() {
            if(this.selectedShownColumnsCopy)
                return this.shownColumns.filter(el => this.selectedShownColumnsCopy.includes(el.value));
            else return []
        }
    },
    methods: {
        selectShownColumn(item) {
            if (!this.selectedShownColumnsCopy.includes(item)) {
                this.selectedShownColumnsCopy.push(item);
            }
            else {
                let i_index = this.selectedShownColumnsCopy.findIndex(el => el == item);
                if(i_index != -1)
                    this.selectedShownColumnsCopy.splice(i_index, 1);
            }
        },
        selectAllShownColumn() {
             if(this.selectedShownColumnsCopy.length == this.shownColumns.length){
                this.selectedShownColumnsCopy = [];
            }
            else {
                this.selectedShownColumnsCopy = this._.cloneDeep(this.shownColumns.map(el => el.value));
            }
        }
    }
    
}
</script>

<style lang="scss" scoped>
 .report-dropdown{
    background-color: white;
    border-radius: 6px;
    border: 1px solid #B2B2B2;
    background: #FFF;
    .white{
        path{
            stroke: var(--primary-color);
        }
    }
    &:not(.no-padding-no-bg){
        background-color: white;
    }
}
.row-label{
    color: #333;
    font-family: var(--main-site-font);
    font-size: clamp(14px, 2vw, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}
</style>