import store from '@/store'

export function getPalettes(){


    return [
        [
            "rgba(230, 69, 69, 1)",
            "rgba(229, 115, 0, 1)",
            "rgba(191, 143, 0, 1)",
            "rgba(100, 153, 46, 1)",
            "rgba(46, 153, 46, 1)",
            "rgba(46, 153, 100, 1)",
            "rgba(46, 153, 153, 1)",
            "rgba(69, 150, 229, 1)",
            "rgba(69, 69, 229, 1)",
            "rgba(150, 69, 230, 1)",
            "rgba(153, 46, 153, 1)",
            "rgba(229, 69, 150, 1)",
        ],
        [
            "rgba(229, 115, 115, 1)",
            "rgba(229, 166, 103, 1)",
            "rgba(229, 229, 103, 1)",
            "rgba(184, 229, 138, 1)",
            "rgba(138, 229, 138, 1)",
            "rgba(115, 229, 172, 1)",
            "rgba(115, 229, 229, 1)",
            "rgba(115, 172, 229, 1)",
            "rgba(138, 138, 229, 1)",
            "rgba(184, 138, 230, 1)",
            "rgba(229, 138, 229, 1)",
            "rgba(229, 138, 184, 1)",
        ],
        [
            "rgba(229, 184, 184, 1)",
            "rgba(229, 207, 184, 1)",
            "rgba(229, 229, 184, 1)",
            "rgba(207, 229, 184, 1)",
            "rgba(184, 229, 184, 1)",
            "rgba(184, 229, 207, 1)",
            "rgba(184, 229, 229, 1)",
            "rgba(184, 207, 229, 1)",
            "rgba(184, 184, 229, 1)",
            "rgba(207, 184, 229, 1)",
            "rgba(229, 184, 229, 1)",
            "rgba(229, 184, 207, 1)",
        ],
    ];
}
export function getGroupHue(colorPalettes, optionalColor) {
    let settings_palette = store.state.system_settings.color_palette;
    let colorHue = optionalColor
    let colorGroup = Math.floor(
      colorHue % colorPalettes[settings_palette].length
    );
    let color1 =
      colorPalettes[settings_palette][colorGroup];
    if (
      colorPalettes[settings_palette][colorGroup + 1] == undefined
    ) {
      var color2 =
        colorPalettes[settings_palette][colorPalettes[settings_palette].length - 1];
    } else {
      var color2 =
        colorPalettes[settings_palette][colorGroup + 1];
    }
    // this.colorHue /= 100;
    let color1Values = color1
      .replace("rgba(", "")
      .replace(")", "")
      .split(",");
    let color2Values = color2
      .replace("rgba(", "")
      .replace(")", "")
      .split(",");
    let copiedColor = colorHue;
    for (let i = 1; i <= copiedColor; ) {
      copiedColor -= 1;
    }
    let midwayValues = color1Values.map(
      (val, index) =>
        (1 - copiedColor) * parseInt(val) +
        copiedColor * parseInt(color2Values[index])
    );
    midwayValues[midwayValues.length - 1] = 1;
    let color = `rgba(${midwayValues
      .map((val) => Math.floor(val))
      .join(",")})`;
    return color
  }
