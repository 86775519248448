<template>
    <transition name="fade" mode="in-out">
        <div class="overlay-container" v-scroll-lock="true" :class="{'dark': data.item ? data.item.type != 'scorm' : true}">
            <div class="content">
                <div class="close-btn" @click="closeOverlay">
                    <div>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div 
                    class="frame-container"
                    :class="{'is-item': data.item && data.item.type != 'scorm'}"
                    v-if="data" :key="data.item ? data.item.id : 0"
                >
                    <ScormFramePlayer ref="scormFrame" v-if="data.item && data.item.type == 'scorm'" :item="data.item"/>
                    <TrainingItem 
                        v-else
                        class="training-item"
                        :key="data.training.id"
                        :training="data.training"
                        :trainingItem="data.item"
                        :topic_index="0"
                        :item_index="0"
                        :readOnly="true"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ScormFramePlayer from "@/components/layout/scorm/ScormFramePlayer"
import TrainingItem from "@/components/layout/TrainingItem";
import axios from "axios";
export default {
    components: {
        ScormFramePlayer,
        TrainingItem
    },
    props: {
        data: Object
    },
    mounted() {
        if (this.data.training && this.data.training.trainingType == 'singleDoc') {
            this.startTrainingRequest();
        }
    },
    methods: {
        closeOverlay() {
            if (this.data.training && this.data.training.trainingType == 'singleDoc') {
                this.endTrainingRequest();
            }
            // this.$refs.scormFrame.endSession();
            this.$emit('close');
        },
        startTrainingRequest(){
        axios
            .post(
                `${this.$store.state.api_link}/training/stats/start`,
                {
                training: this.$store.state.currentTraining,
                },
                this.$store.getters.axiosConfig()
            )
            .then((response) => {
                this.handleScroll();
                if (response.data.status == "success") {
                this.hasStartedTraining = true;
                } else {
                console.log("Error while sending statistics");
                }
            })
            .catch(() => {
                console.log("Error while sending statistics");
            });
        },
        endTrainingRequest(){
            axios
          .post(
            `${this.$store.state.api_link}/training/stats/end`,
            {
              training: this.$store.state.currentTraining,
            },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.hasEnded = true;
            } else {
              console.log("Error while sending statistics");
            }
          })
          .catch(() => {
            this.hasEnded = false;
            console.log("Error while sending statistics");
          });
        },
   
    }
}
</script>

<style lang="scss" scoped>
.overlay-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1001;
    padding-left: 0;
    padding-top: 0;
    animation: 0.3s fadeIn;
    &.dark{
        background: #6a6a6a;
    }
}
.content{
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
}
.close-btn{
 position: absolute;
 top: 12px;
 right: 12px;
 cursor: pointer;
 z-index: 4;
 div{
    position: relative;
    width: 30px;
    height: 30px;
    background-color: white;
    box-shadow: 0 2px 3px 0 #0000002f;
    border-radius: 50%;
    span{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: black;
        border-radius: 4px;
        width: 2px;
        height: 80%;
        transform: rotate(45deg);
        &:last-child{
            transform: rotate(-45deg);
        }
    }
 }
}
.training-item{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.frame-container{

    &.is-item{
        height: 100%;
        width: calc(100% - 114px);
        margin: auto;
        border-radius: 20px;
        overflow: hidden;
    }
}
@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
</style>