<template>
  <div class="shop-page-container">
    <!-- <TrainingFolders v-if="selectedGroup==-1" :showCreateFolder="false" :showSettings="false" :openGroupFunction="openGroup" /> -->
    <Home v-if="selectedTraining == -1" type="paid" :openTrainingFunction="openTraining"/>
    <PaidTraining v-else :training_id="selectedTraining" :training_obj="selectedTrainingObj" @goBack="selectedTraining=-1; selectedTrainingObj=null" :isOpenedExternally="isOpenedExternally" />

  </div>
</template>

<script>
import TrainingFolders from '@/views/TrainingFolders.vue';
import Home from '@/views/Home.vue';
import PaidTraining from '@/components/layout/payments/PaidTraining.vue';
export default {
  components:{
    TrainingFolders,
    Home,
    PaidTraining
  },
  data() {
    return {
      selectedTraining: -1,
      selectedTrainingObj: null,
      isOpenedExternally: false,
    }
  },
  mounted(){
    if(!this.$store.state.system_settings.showTrainingShop){
      this.$router.push({name: 'home'});
    }
    if(this.$route.query.training_id){
      this.selectedTraining = this.$route.query.training_id;
      if(this.$route.query.rented == true){
        this.isOpenedExternally = false;
      }else{
        this.isOpenedExternally = true;
      }
    }
  },
  methods:{
 
    openTraining(training){
      this.selectedTrainingObj = training;
      this.selectedTraining = training.id;
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-page-container{
  width: 100%;
}
</style>