<template>
  <div>
    <div 
      :class="['side--background', isOpen ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': isOpen }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
            <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
                <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
                <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
                <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                    <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
                </g>
                </g>
            </g>
            </svg>
      </div>
      <div class="side--content">
        <div class="stat--header">
          <div class="stat-row">
              <div class="stat-col left-col">
                  <div class="stat-row">
                      <div class="user-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <clipPath id="clip-path">
                                <circle id="Ellipse_467" data-name="Ellipse 467" cx="14" cy="14" r="14" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1"/>
                                </clipPath>
                            </defs>
                            <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-1212 -2055.711)" clip-path="url(#clip-path)">
                                <g id="Ellipse_464" data-name="Ellipse 464" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1">
                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                </g>
                                <g id="Ellipse_465" data-name="Ellipse 465" transform="translate(1221 2062.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                                <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                                </g>
                                <g id="Ellipse_466" data-name="Ellipse 466" transform="translate(1215 2070.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
                                <circle cx="11.5" cy="11.5" r="11" fill="none"/>
                                </g>
                            </g>
                        </svg>
                      </div>
                      <div class="user-name"> {{user.name}} {{user.last_name}}</div>
                  </div>
                  <div class="stat-row">
                      <div class="user-groups">
                            <div class="user-groups--list">
                                <div>{{toLocal('columns.selected_groups')}}:</div>
                                <div v-for="group in groups" :key="group.id">
                                    {{group.name}}
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
              <div class="stat-col right-col parent">
                  <div class="stat-row">
                      <div class="stat-col left-col">{{toLocal('tests.solved_times')}}:</div>
                      <div class="stat-col right-col">
                          <div class="score">{{user.stats ? user.stats.length : '0'}}</div>
                      </div>
                  </div>
                  <div class="stat-row">
                      <div class="stat-col left-col">{{ toLocal('tests.average_score')}}:</div>
                      <div class="stat-col right-col">
                          <div class="score">{{user.stats ? getResultAverage(user.stats) : '0'}}</div>
                      </div>
                  </div>

              </div>
          </div>
        </div>
        <div class="seperator"></div>
        <div class="grid-table">
            <div class="heading-row">
                <div></div>
                <div>
                    <span>
                        {{toLocal('test_stats.date_of_attempt')}}
                        <img
                            v-if="orderByElement == 'end_date' && orderByDirection == 'desc'"
                            src="@/assets/images/icons/order-by-desc.svg"
                            @click="setOrderBy('end_date')"
                        >
                        <img
                            v-if="orderByElement == 'end_date' && orderByDirection == 'asc'"
                            src="@/assets/images/icons/order-by-asc.svg"
                            @click="setOrderBy('end_date')"
                        >
                        <img
                            v-if="orderByElement != 'end_date'"
                            src="@/assets/images/icons/order-by.svg"
                            @click="setOrderBy('end_date')"
                        >
                    </span>
                </div>
                <div>
                    <span>
                        {{toLocal('test_stats.attempt_duration')}}
                        <img
                            v-if="orderByElement == 'done_time_in_seconds' && orderByDirection == 'desc'"
                            src="@/assets/images/icons/order-by-desc.svg"
                            @click="setOrderBy('done_time_in_seconds')"
                        >
                        <img
                            v-if="orderByElement == 'done_time_in_seconds' && orderByDirection == 'asc'"
                            src="@/assets/images/icons/order-by-asc.svg"
                            @click="setOrderBy('done_time_in_seconds')"
                        >
                        <img
                            v-if="orderByElement != 'done_time_in_seconds'"
                            src="@/assets/images/icons/order-by.svg"
                            @click="setOrderBy('done_time_in_seconds')"
                        >
                    </span>
                </div>
                <div>
                    <span>
                        {{toLocal('generic.result')}}
                        <img
                            v-if="orderByElement == 'result' && orderByDirection == 'desc'"
                            src="@/assets/images/icons/order-by-desc.svg"
                            @click="setOrderBy('result')"
                        >
                        <img
                            v-if="orderByElement == 'result' && orderByDirection == 'asc'"
                            src="@/assets/images/icons/order-by-asc.svg"
                            @click="setOrderBy('result')"
                        >
                        <img
                            v-if="orderByElement != 'result'"
                            src="@/assets/images/icons/order-by.svg"
                            @click="setOrderBy('result')"
                        >
                    </span>
                </div>
            </div>
            <div 
                class="row" 
                v-for="(stat, stat_id) in user.stats ? user.stats : []" 
                :key="stat_id"
                @click="$set(stat, 'expanded', stat.expanded == undefined ? true : !stat.expanded)"
                :class="{expanded: stat.expanded}"
            >
                <div class="user-stats">
                    <span class="cell-number">{{stat_id+1}}</span>
                    <div class="cell"><span>{{stat.end_date.split(' ')[0]}}</span></div>
                    <div class="cell"><span>{{getTestSolvingTime(stat.done_time_in_seconds)}}</span></div>
                    <div class="cell"><span>{{getUserStatResult(stat.result)}}</span></div>
                </div>
                <div class="detail-container" v-if="stat.expanded">
                    <div class="label-container">
                        <div class="label left"> {{toLocal('generic.question')}}:</div>
                        <div class="label right"> Atsakyta</div>
                    </div>
                    <div class="answer-container" v-for="(question, question_index) in stats[stat_id]" :key="question_index">
                        <div class="answer-left">
                            {{question_index+1}}
                        </div>
                        <div class="answer-center">
                            <div class="answer-text">
                                <editor
                                    class="question-text"
                                    :readOnly="true"
                                    v-model="question.text"
                                />
                            </div>
                            <div class="answer-content">
                                <div class="answer-type" v-if="question.type == 1">
                                    <div 
                                        class="answer type-1" 
                                        v-for="(answer, answer_index) in question.answers" 
                                        :key="answer_index"
                                        :class="{correct: answer.correct, incorrect: !answer.correct}"
                                    >
                                        <div class="answer-letter" :class="{active: answer.selected}">{{String.fromCharCode(65+answer_index)}}</div>
                                        <div class="answer-text">
                                            {{answer.text}}
                                        </div>
                                    </div>
                                </div>
                                <div class="answer-type type-img" v-if="question.type == 2">
                                    <div 
                                        class="answer type-2" 
                                        v-for="(answer, answer_index) in question.answers" 
                                        :key="answer_index"
                                        :class="{correct: answer.correct, incorrect: !answer.correct, big: question.size == 1 }"
                                    >
                                        <div class="answer-letter" :class="{active: answer.selected}">{{String.fromCharCode(65+answer_index)}}</div>
                                        <div class="answer-image">
                                            <ImageViewer
                                                v-if="(answer.metadata ? answer.metadata.data : false)"
                                                :src="`${$store.state.api_uploads_link}${answer.image}`"
                                                :height="question != undefined ? question.size == 1 ? 416.25 : 200 : undefined"
                                                :width="question != undefined ? question.size == 1 ? 740 : 200 : undefined"
                                                :metadata="answer.text"
                                                :size="question.size"
                                            />
                                            <NewImageEditor 
                                                v-if="(answer.metadata ? !answer.metadata.data : true)"
                                                :readOnly="true"
                                                :item="answer"
                                                type="cover"
                                                :height="question.size == 1 ? 243 : 200"
                                                :width="question.size == 1 ? 420 : 200"
                                                :isEditing="false"
                                                :src="getFileUrl(answer.image)"
                                            
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="answer-type " v-if="question.type == 3">
                                    <div 
                                        class="answer type-3" 
                                        v-for="(answer, answer_index) in question.answers" 
                                        :key="answer_index"
                                        :class="{correct: answer.correct && answer.selected, incorrect: !answer.selected}"
                                        >
                                        <div class="part-1">{{answer.part1}}</div>
                                        <div class="part-1">{{answer.part2}}</div>
                                    </div>
                                </div>
                                <div class="answer-type" v-if="question.type == 4">
                                    <div class="answer type-4" v-for="(answer, answer_index) in question.answers" :key="answer_index">
                                        <div class="text-answer-container" v-html="$sanitize(answer.answer_text)"></div>
                                        <!-- <div class="part-1">{{answer.part2}}</div> -->
                                    </div>
                                </div>
                                <div class="answer-type" v-if="question.type == 5">
                                    <div class="answer type-5"  v-if="question.answers && question.answers[0]">
                                        <div class="tries-container">
                                            <div class="tries" v-if="question.answers[0].clicks">
                                                <span>Bandymai:</span>
                                                <span>{{question.answers[0].clicks.length}}</span>
                                            </div>
                                        </div>
                                        <ClickTask
                                        :question="question"
                                        :answer="question.answers[0]"
                                        :readOnly="true"
                                        :statsMode="true"
                                        :clicks="question.answers[0].clicks"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="answer-right">
                            <div class="answer-state" :class="{'correct': question.isCorrect}"> {{getQuestionStatusLabel(question)}}</div>
                            <div class="state-icon" :class="{'correct': question.isCorrect || question.type == 5, 'partial':!question.isCorrect && question.set_score > 0}"> 
                                <svg v-if="question.isCorrect || question.type == 5" xmlns="http://www.w3.org/2000/svg" width="9.961" height="9.296" viewBox="0 0 9.961 9.296">
                                    <path id="Path_5921" data-name="Path 5921" d="M-379.365-14993.614l2.942,4.038,4.932-7.506" transform="translate(380.413 14998.121)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </svg>
                                <svg v-else-if="test.options.testPartiallySolvable==1 && !question.isCorrect && question.set_score > 0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.252" height="12.257" viewBox="0 0 12.252 12.257">
                                    <defs>
                                        <clipPath id="clip-path">
                                        <rect id="Rectangle_2253" data-name="Rectangle 2253" width="12.257" height="12.252" fill="#28345c"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Group_7292" data-name="Group 7292" transform="translate(12.252) rotate(90)" >
                                        <path id="Path_6020" data-name="Path 6020" d="M12.246,11.7l-.639-2.85V8.828a1.017,1.017,0,0,0-.3-.72L3.5.3A1.032,1.032,0,0,0,2.043.3L.3,2.038A1.032,1.032,0,0,0,.3,3.5l7.811,7.811a1,1,0,0,0,.733.291l2.863.643a.486.486,0,0,0,.195,0,.45.45,0,0,0,.284-.2.46.46,0,0,0,.07-.243.378.378,0,0,0-.011-.1M.755,3.045a.391.391,0,0,1,0-.553L2.5.755A.382.382,0,0,1,2.771.639a.391.391,0,0,1,.278.116l.752.752L1.509,3.8Zm7.6,7.6L1.962,4.252,4.254,1.96l6.393,6.393Zm1.938.621-1.35-.3,2.028-2.028.324,1.45Z" transform="translate(0)" fill="#28345c"/>
                                        <path id="Path_6021" data-name="Path 6021" d="M5.508,5.188a.313.313,0,0,1-.094.225.321.321,0,0,1-.453,0L.1.546A.317.317,0,0,1,.1.094a.321.321,0,0,1,.453,0L5.414,4.96a.318.318,0,0,1,.094.227" transform="translate(3.562 3.559)" fill="#28345c"/>
                                    </g>
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="18.408" height="18.408" viewBox="0 0 18.408 18.408">
                                    <g id="Group_7020" data-name="Group 7020" transform="translate(9.206) rotate(45)">
                                        <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                                        <path id="Path_751" data-name="Path 751" d="M13.014,6.512a.7.7,0,0,1-.207.5.686.686,0,0,1-.5.212H7.217v5.092a.715.715,0,0,1-.71.7.7.7,0,0,1-.5-.207.686.686,0,0,1-.212-.5V7.222H.7A.711.711,0,0,1,.2,6.014.739.739,0,0,1,.7,5.8H5.8V.715A.692.692,0,0,1,6.009.207.7.7,0,0,1,6.507,0a.717.717,0,0,1,.71.715V5.8h5.092A.719.719,0,0,1,13.014,6.512Z" transform="translate(0)" fill="#28345c"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import ClickTask from "@/components/layout/trainings/training_components/ClickTask.vue";
import ImageViewer from '@/components/layout/trainings/training_components/ImageViewer.vue';
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";

export default {
  name: "TestUserSideDrawer",
  data: function(){
    return {
        isOpen: false,
        user:{},
        test: {},
        groups: [], 
        stats:[],
        orderByElement: "",
        orderByDirection: "",
    }
  },
  components:{
      Editor,
      ClickTask,
      ImageViewer,
      NewImageEditor
  },
  props:{
    
    ClickTasktraining: Object,
  },
  watch:{

  },
  mounted(){
    this.$store.state.userTrainingStatSide = this;
  },

  methods: {
    getStats(){
      axios.post(
          `${this.$store.state.api_link}/training/stats/user/detailed`,
          {user_id: this.user.id, test_id: this.test.id, 'user_stats': this.user.stats},

          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.stats= response.data.test_results;
        //   this.stats.forEach((stat, index) => {
        //     stat = Array.from(new Set(stat.map(a => a.id)))
        //     .map(id => {
        //         return stat.find(a => a.id === id)
        //     });
        //     if(this.test.options.testResultShowType == 0){
        //         this.user.stats[index].result = stat.filter(elem=>elem.isCorrect).map(el=>el.score).reduce((a,b)=>a+b,0);
        //     }
        //     else this.user.stats[index].result = Math.round((stat.filter(a => a.isCorrect).length/stat.length)*100, 2);
        //     console.log(this.user.stats[index].result);
        //   });
         
            this.groups = response.data.groups;
        })
        .catch((err) => {
            console.log(err);
          console.log("Server error while getting user statistics");
        });
    },
    setUser(user, test){
      this.user = user;
      this.test = test;
      this.getStats();
    },
    getTestSolvingTime(time_in_seconds){
        let hours = parseInt(time_in_seconds/3600);
        if(hours<10){
            hours = '0'+hours;
        }
        let minutes = parseInt(time_in_seconds/60);
        if(minutes<10){
            minutes = '0'+minutes;
        }
        let seconds = parseInt(time_in_seconds%60);
        if(seconds<10){
            seconds = '0'+seconds;
        }
        return `${hours}:${minutes}:${seconds}`;
    },
  
    closeSide() {
        this.isOpen = false;

    },
    openSide(){
        this.isOpen = true;
    },
    getResultAverage(stats){
        return Math.round(stats.map((el)=>this.getUserStatResult(el.result)).reduce((a,b)=>a+b)/stats.length, 2);
    },
    getUserStatResult(result){
        if(this.test.options.testResultShowType ==1)
            return parseInt(Math.round(result * this.test.options.testMaxScore));
        else return Math.round(result*100,1);
    },
   setOrderBy(element) {
        if(element == this.orderByElement){
            this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
        }else{
            this.orderByElement = element;
            this.orderByDirection = "asc";
        }
        if(['end_date', 'done_time_in_seconds'].includes(element)){

            if(this.orderByDirection == "desc"){
                this.user.stats.sort((a,b) => b[element].localeCompare(a[element])); 
            }else{
                this.user.stats.sort((a,b) => a[element].localeCompare(b[element])); 
            }
        }
        else{
            if(isNaN(this.user.stats[0][element])){
                if(this.orderByDirection == "desc"){
                this.user.stats.sort((a,b) => b[element].localeCompare(a[element])); 
                }else{
                this.user.stats.sort((a,b) => a[element].localeCompare(b[element])); 
                }
            }else{
                if(this.orderByDirection == "desc"){
                this.user.stats.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
                }else{
                this.user.stats.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
                }
            }
        }
    },
    getQuestionStatusLabel(question){
        if(question.isCorrect || question.type == 5){
            return this.toLocal('training.task_correct');
        }
        else if(this.test.options.testPartiallySolvable == 1){
            if(question.set_score != 0){
                return this.toLocal('generic.partially');
            }
            else{
                return this.toLocal('training.task_incorrect');
            }
        }
        else return this.toLocal('training.task_incorrect');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";


    .side--background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .group--side {
        position: fixed;
        right: -900px;
        background-color: #fff;
        height: 100vh;
        top: 0;
        width: 800px;
        z-index: 11;
        font-family: var(--main-site-font);
        transition: .3s right;
        z-index: 1006;

        &.side--open {
          right: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }
    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 47px;
            height: 47px;
            path{
                fill: var(--primary-color);
            }
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      width: 40px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -4px;
      }
    }

    .group--section--select {
      margin-top: -60px;

      .group--section--select--button {
        width: 50%;
        float: left;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        user-select: none;
        background-color: $background_color;
        box-shadow: 0 0 5px rgba(0,0,0,0);
        transition: .3s box-shadow, .3s background-color;

        &.active, &:hover {
          box-shadow: 0 0 5px rgba(0,0,0,.3);
          background-color: $group_color;
        }
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }


    .lesson--list {
      position: relative;
    }

    .side--table--control {
      margin-top: 50px;
      width: 100%;
      margin: 0;
      position: absolute;
      left: 0;
      transition: .3s left;

      &.hidden {
        left: calc(-100% - 150px);

        &.search--table {
          left: calc(200% + 150px);
        }
      }

      svg {
        margin-top: -2px;
      }

      // tbody tr {
        // background-color: #efefef;
      // }

      tbody td {
        background-color: #efefef;
      }

    }

.stat--header{
    display: flex;
    width: 100%;
    font-size: clamp(20px, 2vw, 3vw);
    .user-icon{
        display: flex;
        align-items: center;
        margin-right: 1rem;
        height: 100%;
        svg{
            height: 100%;
            width: 3rem;
        }
    }
}
.stat-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}
.stat-col{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.right-col{
        width: 30%;
        font-size: clamp(14px, 1vw, 1.5vw);
        &.parent{
            padding-left: 1rem;
        }
    }
    &.left-col{
        width: 70%;
    }
}
.score{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #efefef;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.2rem 0;
}
.user-groups--list{
    display: flex;
    flex-wrap: wrap;
    font-size: clamp(12px, 1vw, 1.5vw);
    margin-left: 2.7rem;
    div{
        margin: 0.3rem;
    }
}
.seperator{
    display: flex;
    height: 1px;
    background: var(--primary-color);
    width: 70%;
}
.grid-table{
    display: grid;
    width: 70%;
    .heading-row{
        display: grid;
        grid-template-areas: 
                ". a a a";
        grid-template-columns: 2rem 8rem 9rem 8rem;
        color: var(--primary-color);
        text-align: center;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid var(--primary-color);
            &:last-child{
                border-right: none;
            }
            &:first-child{
                border-right: none;
            }
        }
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            img{
                margin: 0 0.5rem;
            }
        }
        margin: 1rem 0;
    }

    .row{
        display: grid;
        color: var(--primary-color);
        text-align: center;
        background: #efefef;
        border-radius: 1rem;
        padding: 0.5rem 0;
        margin: 0.25rem  0;
        // display: flex;
        cursor: pointer;
        grid-template-areas: 
                "a a a a";
        width: fit-content;
        .user-stats{
            display: grid;
            grid-template-columns: 2rem 8rem 9rem 8rem;
            // grid-template-areas: 
            //     "a a a "
        }
        .cell{
            padding: 0.25rem 0;
            
            span{
                display: flex;
                justify-content: center;
                width: 100%;
                border-right: 1px solid var(--primary-color);
            }
            &:last-child{
                span{
                    border-right: none;
                }
            }
        }
        .cell-number{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 700;
        }
        &.expanded{
            grid-template-areas: 
                "a"
                "b";
            grid-template-rows: auto;
            .user-stats{
                // padding-left: 1rem;
                grid-template-columns: 2rem minmax(8rem, 1fr) minmax(9rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr);
                grid-template-areas: 
                 "a a a a .";
                 .cell{
                     border-bottom: 2px solid var(--primary-color);
                 }
            }
            .cell-number{
                 align-items: flex-start;
                margin-left: 0.75rem;    
                border-bottom: 2px solid;
            }
            // grid-template-columns: minmax(8rem, 1fr) minmax(9rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr);
            
        }
        .detail-container{
            padding: 1rem;
            display: grid;
            .label-container, .answer-container{
                display: grid;
                max-width: 34rem;
                grid-template-columns: minmax(2rem, 1fr) 8fr 2fr;
                .left{
                    text-align: left;
                }
            }
            .label-container{
                grid-area: 
                    ". label label";
                padding: 0 0.5rem;
                //grid-column-start: 2;
                .label.left{
                  grid-column-start: 2;
                }
            }
       
            .answer-container{
                background: white;
                border-radius: 1rem;
                padding: 0.5rem;
                .answer-center{
                    display: grid;
                    grid-area: 
                        "text"
                        "content";
                    text-align: left;
                    font-size: clamp(14px, 1.3vw, 1.8vw);
                    grid-template-rows: minmax(3rem, 1fr) auto;
                    padding-right: 1rem;
                    border-right: 2px solid var(--primary-color);
                    .answer-type{
                        // display: flex;

                        .answer{

                        }
                        .answer-letter{
                            background: white;
                            border-radius: 50%;
                            padding: 0.25rem;
                            width: 1.5rem;
                            height: 1.5rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            &.active{
                                background: var(--secondary-color);
                            }  
                        }
                        .type-1{
                            display: grid;
                            align-items: center;
                            grid-template-columns: 1fr 9fr;
                            background: #efefef;
                            margin: 0.5rem 0;
                            padding: 0.5rem 0.5rem;
                            border-radius: 1rem;
                            .answer-text{
                                display: flex;
                                align-items: center;
                                margin-left: 1rem;
                                max-width: 21rem;
                                width: 100%;
                            }
                             &.correct{
                                border:2px solid #6ACD77;
                            }
                            &.incorrect{
                                border:2px solid #F47979
                            }
                        }
                        .type-2{
                            position: relative;
                            max-width: 45%;
                            width: 45%;
                            margin: 1rem 0.25rem;
                            border-radius: 1rem;
                            .answer-letter{
                                position: absolute;
                                left: 0.5rem;
                                top:0.5rem;
                                z-index: 2;
                            }
                            .answer-image{
                                width: 100%;
                                height: 100%;
                                max-width: 10rem;
                                max-height: 10rem;
                                background: #efefef;
                                border-radius: 1rem;
                                overflow: hidden;
                                object-fit: contain;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                }
                            }
                            &.correct{
                                border:2px solid #6ACD77;
                            }
                            &.incorrect{
                                border:2px solid #F47979
                            }
                            &.big{
                                max-width: 416px;
                                width: 100%;
                                .answer-image{
                                    max-width: 416px;
                                    max-height: 234px;
                                }
                            }
                        }
                        &.type-img{
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .type-3{
                            display: flex;
                            margin: 1rem 0 ;
                            background: #efefef;
                            border-radius: 0.5rem;
                            .part-1, .part-2{
                                display: flex;
                                padding: 1rem 0.75rem;
                                width: 100%;
                                
                            }
                            &.correct{
                                border:2px solid #6ACD77;
                            }
                            &.incorrect{
                                border:2px solid #F47979
                            }
                        }
                        
                        .type-4{
                              margin-bottom: 1rem;
                        }
                        .type-5{
                            .tries-container{
                                display: flex;
                                justify-content: flex-end;
                                margin-bottom: 5px;
                                .tries{
                                    display: flex;
                                    background: #efefef;
                                    padding: 2px 10px;
                                    border-radius: 10px;
                                    width: 6rem;
                                    justify-content: center;
                                    align-items: center;
                                    text-align: right;
                                    font-size: 16px;
                                    span:nth-child(2){
                                        margin: 0 11px;
                                        font-weight: 700;
                                        margin-bottom: -2px;
                                    }
                                }
                            }
                        }
                    }
                }
                .answer-right{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .answer-state{
                        font-weight: 700;
                    }
                    .state-icon{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: #FF6969;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        &.correct{
                            background: #45C34A;
                            padding: 0.4rem;
                        }
                        &.partial{
                            background: #F2DB7D;
                            padding: 0.4rem;
                        }
                        svg{
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            gap: 1rem;
            //background: red;
            //height: 1000px;
        }
    }
}
table{
    width: 100%;
    border-collapse: collapse;
    width: 70%;
    tr{
        td{
            padding:0.5rem 0;
            span{
                display: flex;
                border-right: 1px solid var(--primary-color);    
                justify-content: center;
                color: var(--primary-color);
            }   
            text-align: center;
            &:first-child{
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
            }
            &:last-child{
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                span{
                    border-right: none;
                }
            }

        }
        th{
            padding: 1rem 0rem;
            span{
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                //width: 100%;
                border-right: 1px solid var(--primary-color); 
                img{
                    margin-left: 0.25rem;
                }
                &.name-cell{
                    margin-left: 2.3rem;
                }
            }
            &:last-child{
  
                span{
                    border-right: none;
                }
            }

        }
    }
    .user-row{
        border-radius: 30px;
        border-bottom-left-radius: 30px;
        background: #efefef;
        border-bottom: 9px solid white;
        svg{
        }
        .double-cell{
            justify-content: flex-start;
            svg{
                margin: 0 0.5rem;
            }
            div{
                margin: 0 auto;
            }
        }
    }
}
 .question-text{
    width: 100%;
    max-width: 21rem;
    div{
        max-width: 100%;
    }
}

</style>
<style lang="scss">
.text-answer-container{
    background: #efefef;
    border-radius: 0.5rem;
    padding: 0.5rem;
    p{
        margin: 0;
    }
}
</style>