<template>
<div class="container">
    <portal to="global-modal-settings">
        <GlobalModal v-if="isDeleteModalOpen" :modalInfo="{
            type:'multiChoice',
            text: 'Ar tikrai norite ištrinti paskyrą visam laikui?',
            undertext: 'Ištrynę paskyrą jos susigrąžinti nebegalėsite.',
            multiChoiceYes: 'Taip',
            multiChoiceNo: 'Ne',
        }"
        @onClose="isDeleteModalOpen = false"
        @onChoiceOne="deleteAccount()"
        @onChoiceTwo="isDeleteModalOpen = false"/>
        <div class="row saving-row" v-if="isSavingEnabled">
            <button @click="saveUserDetails()" class="save-btn">
                <span v-if="!isSaving">
                    <svg class="save-checkmark" v-if="showSaveCheckmark" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                        <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                    </svg>
                    {{ toLocal('settings.save') }}
                </span>
                <span v-else>
                    <font-awesome-icon icon="spinner" />
                </span>
            </button>
        </div>
    </portal>
    <div class="col">
        <div class="row user-details line-under">
            <span class="section-label">{{toLocal('settings.your_login')}}</span>
            <div class="input-wrapper" v-if="loaded">
                <div class="input-outer">
                    <span v-if="firstLogin.username != undefined">{{toLocal('settings.change_username')}}</span>
                    <div class="input-container" v-if="firstLogin.username != undefined">
                        <input type="text" autocomplete="no-auto" :placeholder="toLocal('settings.current_username')" v-model="$v.firstLogin.username.$model" @keypress="enableSaving(); isUsernameEdited=true;" @change="enableSaving(); isUsernameEdited = true">
                        <v-popover
                            v-if="$v.firstLogin.username.$anyDirty && $v.firstLogin.username.$invalid && !isMobileWindow()"
                            offset="26"
                            :open="$v.firstLogin.username.$invalid && $v.firstLogin.username.$anyDirty && !isMobileWindow()"
                            placement="right"
                            class="pop-right"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <h3>{{toLocal('generic.username')}}</h3>
                                <span>{{toLocal('generic.field_must')}}</span>
                                <ul>
                                <li>
                                   {{toLocal('first_login.username_requirement')}}
                                </li>
                                </ul>
                            </div>
                        </v-popover>
                         <v-popover
                            v-if="errorCode == 0 && !isMobileWindow()"
                            offset="26"
                            :open="errorCode == 0"
                            placement="right"
                            class="pop-right server-error"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <span>{{errorMessage}}</span>
                            </div>
                        </v-popover>
                    </div>
                    <span class="error"  v-if="$v.firstLogin.username.$anyDirty && $v.firstLogin.username.$invalid && isMobileWindow()">
                        <span>{{toLocal('generic.field_must')}}</span>
                        <ul>
                        <li>
                            {{toLocal('first_login.username_requirement')}}
                        </li>
                        </ul>
                    </span>
                </div>
                <div class="input-outer">
                    <span v-if="firstLogin.emailPhone != undefined">{{$store.state.system_settings.usesPhones ? this.toLocal('user_info.change_phone') : ''}} {{$store.state.system_settings.usesPhones ? '' : toLocal('user_info.change_email')}}</span>
                    <div class="input-container" v-if="firstLogin.emailPhone != undefined">
                        <input type="text" autocomplete="no-auto" v-if="$store.state.system_settings.usesPhones" placeholder="+370 XXX XXXXX" v-mask="$store.state.system_settings.phoneMask" @keypress="enableSaving(); isEmailEdited=true;" @change="enableSaving(); isEmailEdited = true" v-model="$v.firstLogin.emailPhone.$model">
                        <input type="text" v-else :placeholder="toLocal('first_login.enter')" v-model="$v.firstLogin.emailPhone.$model" @keypress="enableSaving(); isEmailEdited=true;" @change="enableSaving();isEmailEdited = true">
                         <v-popover
                            v-if="$v.firstLogin.emailPhone.$anyDirty && $v.firstLogin.emailPhone.$invalid && !isMobileWindow()"
                            offset="26"
                            :open="$v.firstLogin.emailPhone.$invalid && $v.firstLogin.emailPhone.$anyDirty"
                            :placement="isMobileWindow() ? 'top' : 'left'"
                            class="pop-right"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <h3>{{$store.state.system_settings.usesPhones ? toLocal('first_login.phone_who') : toLocal('first_login.email_who')}} </h3>
                                <span>{{toLocal('generic.field_must')}}</span>
                                <ul>
                                <li>
                                    {{toLocal('first_login.phone_must')}}
                                </li>
                                </ul>
                            </div>
                        </v-popover>
                        <v-popover
                            v-if="errorCode == 1 && !isMobileWindow()"
                            offset="26"
                            :open="errorCode == 1"
                            :placement="isMobileWindow() ? 'top' : 'left'"
                            class="pop-right server-error"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <span>{{errorMessage}}</span>
                            </div>
                        </v-popover>
                    </div>
                    <span class="error"  v-if="$v.firstLogin.emailPhone.$anyDirty && $v.firstLogin.emailPhone.$invalid && isMobileWindow()">
                        <span>{{toLocal('generic.field_must')}}</span>
                        <ul>
                        <li>
                            {{toLocal('first_login.phone_must')}}
                        </li>
                        </ul>
                    </span>
                </div>
                <div class="input-outer">
                    <span class="error"  v-if="$v.firstLogin.emailPhone.$anyDirty && $v.firstLogin.emailPhone.$invalid && isMobileWindow()">
                        {{toLocal('first_login.email_incorrect')}}
                    </span>
                    <span v-if="firstLogin.password != undefined" class="password-label">
                        {{toLocal('settings.change_password')}}
                        <span>{{ toLocal('password.validation_label_n_symbols') }}</span>
                    </span>
                    <div class="input-container password-container" v-if="firstLogin.password != undefined">
                        <input :type="showInputText.password ? 'text' : 'password-field'" autocomplete="new-password" v-model="$v.firstLogin.password.$model" @keypress="enableSaving(); isPasswordEdited=true;" @change="enableSaving(); isPasswordEdited = true">
                        <svg v-if="!showInputText.password" @click="showInputText.password = !showInputText.password" class="eye" width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 6.5C3.64222 9.64091 6.68351 11.5556 10 11.5556C13.3159 11.5556 16.3504 9.64152 18.4621 6.49937C16.3574 3.35884 13.3163 1.44444 10 1.44444C6.68351 1.44444 3.64222 3.35909 1.53746 6.5ZM0.112049 6.10746C2.41702 2.42249 5.97035 0 10 0C14.0296 0 17.583 2.42249 19.888 6.10746C20.0376 6.34665 20.0373 6.65456 19.8873 6.89351C17.5753 10.5769 14.0302 13 10 13C5.97035 13 2.41702 10.5775 0.112049 6.89254C-0.0373498 6.6537 -0.0373498 6.3463 0.112049 6.10746Z" fill="#B2B2B2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 4.33333C8.84509 4.33333 7.90801 5.30338 7.90801 6.5C7.90801 7.69662 8.84509 8.66667 10.001 8.66667C11.157 8.66667 12.0941 7.69662 12.0941 6.5C12.0941 5.30338 11.157 4.33333 10.001 4.33333ZM6.51267 6.5C6.51267 4.50564 8.07446 2.88889 10.001 2.88889C11.9276 2.88889 13.4894 4.50564 13.4894 6.5C13.4894 8.49436 11.9276 10.1111 10.001 10.1111C8.07446 10.1111 6.51267 8.49436 6.51267 6.5Z" fill="#B2B2B2"/>
                        </svg>
                        <svg v-else @click="showInputText.password = !showInputText.password" class="eye" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 7.50005C3.64222 10.641 6.68351 12.5556 10 12.5556C13.3159 12.5556 16.3504 10.6416 18.4621 7.49942C16.3574 4.35888 13.3163 2.44449 10 2.44449C6.68351 2.44449 3.64222 4.35914 1.53746 7.50005ZM0.112049 7.1075C2.41702 3.42253 5.97035 1.00005 10 1.00005C14.0296 1.00005 17.583 3.42253 19.888 7.1075C20.0376 7.3467 20.0373 7.65461 19.8873 7.89356C17.5753 11.577 14.0302 14 10 14C5.97035 14 2.41702 11.5776 0.112049 7.89259C-0.0373498 7.65374 -0.0373498 7.34635 0.112049 7.1075Z" fill="#B2B2B2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 5.33338C8.84509 5.33338 7.90801 6.30343 7.90801 7.50005C7.90801 8.69666 8.84509 9.66671 10.001 9.66671C11.157 9.66671 12.0941 8.69666 12.0941 7.50005C12.0941 6.30343 11.157 5.33338 10.001 5.33338ZM6.51267 7.50005C6.51267 5.50569 8.07446 3.88893 10.001 3.88893C11.9276 3.88893 13.4894 5.50568 13.4894 7.50005C13.4894 9.49441 11.9276 11.1112 10.001 11.1112C8.07446 11.1112 6.51267 9.49441 6.51267 7.50005Z" fill="#B2B2B2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.391998 0.560937C0.634516 0.225143 1.10333 0.149527 1.43913 0.392046L19.4391 13.392C19.7749 13.6346 19.8505 14.1034 19.608 14.4392C19.3655 14.775 18.8967 14.8506 18.5609 14.6081L0.560889 1.60806C0.225095 1.36555 0.14948 0.896731 0.391998 0.560937Z" fill="#B2B2B2"/>
                        </svg>

                        
                        <v-popover
                            v-if="$v.firstLogin.password.$anyDirty && $v.firstLogin.password.$invalid && !isMobileWindow() "
                            offset="26"
                            :open="$v.firstLogin.password.$invalid && $v.firstLogin.password.$anyDirty"
                            placement="right"
                            class="pop-right"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <h3>{{toLocal('generic.password')}}</h3>
                                <span>{{toLocal('generic.field_must')}}</span>
                                <ul>
                                <li>
                                    {{toLocal('first_login.password_requirement')}}
                                </li>
                                </ul>
                            </div>
                        </v-popover>
                    </div>
                    <span class="error"  v-if="$v.firstLogin.password.$anyDirty && $v.firstLogin.password.$invalid && isMobileWindow()">
                        <span>{{toLocal('generic.field_must')}}</span>
                        <ul>
                        <li>
                            {{toLocal('first_login.password_requirement')}}
                        </li>
                        </ul>
                    </span>
                </div>
                <div class="input-outer">
                    <span v-if="firstLogin.confirmPassword != undefined">{{toLocal('settings.repeat_new_password')}}</span>
                    <div class="input-container password-container" v-if="firstLogin.confirmPassword != undefined">
                        <input :type="showInputText.confirmPassword ? 'text' : 'password-field'" v-model="$v.firstLogin.confirmPassword.$model" @keypress="enableSaving(); isPasswordEdited=true;" @change="enableSaving(); isPasswordEdited=true;">
                        <svg v-if="!showInputText.confirmPassword" @click="showInputText.confirmPassword=!showInputText.confirmPassword" class="eye" width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 6.5C3.64222 9.64091 6.68351 11.5556 10 11.5556C13.3159 11.5556 16.3504 9.64152 18.4621 6.49937C16.3574 3.35884 13.3163 1.44444 10 1.44444C6.68351 1.44444 3.64222 3.35909 1.53746 6.5ZM0.112049 6.10746C2.41702 2.42249 5.97035 0 10 0C14.0296 0 17.583 2.42249 19.888 6.10746C20.0376 6.34665 20.0373 6.65456 19.8873 6.89351C17.5753 10.5769 14.0302 13 10 13C5.97035 13 2.41702 10.5775 0.112049 6.89254C-0.0373498 6.6537 -0.0373498 6.3463 0.112049 6.10746Z" fill="#B2B2B2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 4.33333C8.84509 4.33333 7.90801 5.30338 7.90801 6.5C7.90801 7.69662 8.84509 8.66667 10.001 8.66667C11.157 8.66667 12.0941 7.69662 12.0941 6.5C12.0941 5.30338 11.157 4.33333 10.001 4.33333ZM6.51267 6.5C6.51267 4.50564 8.07446 2.88889 10.001 2.88889C11.9276 2.88889 13.4894 4.50564 13.4894 6.5C13.4894 8.49436 11.9276 10.1111 10.001 10.1111C8.07446 10.1111 6.51267 8.49436 6.51267 6.5Z" fill="#B2B2B2"/>
                        </svg>
                        <svg v-else class="eye" @click="showInputText.confirmPassword=!showInputText.confirmPassword" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 7.50005C3.64222 10.641 6.68351 12.5556 10 12.5556C13.3159 12.5556 16.3504 10.6416 18.4621 7.49942C16.3574 4.35888 13.3163 2.44449 10 2.44449C6.68351 2.44449 3.64222 4.35914 1.53746 7.50005ZM0.112049 7.1075C2.41702 3.42253 5.97035 1.00005 10 1.00005C14.0296 1.00005 17.583 3.42253 19.888 7.1075C20.0376 7.3467 20.0373 7.65461 19.8873 7.89356C17.5753 11.577 14.0302 14 10 14C5.97035 14 2.41702 11.5776 0.112049 7.89259C-0.0373498 7.65374 -0.0373498 7.34635 0.112049 7.1075Z" fill="#B2B2B2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 5.33338C8.84509 5.33338 7.90801 6.30343 7.90801 7.50005C7.90801 8.69666 8.84509 9.66671 10.001 9.66671C11.157 9.66671 12.0941 8.69666 12.0941 7.50005C12.0941 6.30343 11.157 5.33338 10.001 5.33338ZM6.51267 7.50005C6.51267 5.50569 8.07446 3.88893 10.001 3.88893C11.9276 3.88893 13.4894 5.50568 13.4894 7.50005C13.4894 9.49441 11.9276 11.1112 10.001 11.1112C8.07446 11.1112 6.51267 9.49441 6.51267 7.50005Z" fill="#B2B2B2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.391998 0.560937C0.634516 0.225143 1.10333 0.149527 1.43913 0.392046L19.4391 13.392C19.7749 13.6346 19.8505 14.1034 19.608 14.4392C19.3655 14.775 18.8967 14.8506 18.5609 14.6081L0.560889 1.60806C0.225095 1.36555 0.14948 0.896731 0.391998 0.560937Z" fill="#B2B2B2"/>
                        </svg>
                        <v-popover
                            v-if="$v.firstLogin.confirmPassword.$anyDirty && $v.firstLogin.confirmPassword.$invalid && !isMobileWindow()"
                            offset="26"
                            :open="$v.firstLogin.confirmPassword.$invalid && $v.firstLogin.confirmPassword.$anyDirty"
                            placement="right"
                            class="pop-right"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <h3>{{toLocal('first_login.repeated')}}</h3>
                                <span>{{toLocal('generic.field_must')}}</span>
                                <ul>
                                <li>
                                    {{toLocal('first_login.match_requirement')}}
                                </li>
                                </ul>
                            </div>
                        </v-popover>
                        <v-popover
                            v-if="errorCode == 2 && !isMobileWindow()"
                            offset="26"
                            :open="errorCode == 2"
                            :placement="isMobileWindow() ? 'top' : 'left'"
                            class="pop-right server-error"
                            popoverClass="popover-container"
                            trigger='manual'
                            :autoHide='false'
                        >
                            <div class="tooltip-container">
                                <span>{{errorMessage}}</span>
                            </div>
                        </v-popover>
                    </div>
                    <span class="error"  v-if="$v.firstLogin.confirmPassword.$anyDirty && $v.firstLogin.confirmPassword.$invalid && isMobileWindow()">
                        <span>{{toLocal('generic.field_must')}}</span>
                        <ul>
                        <li>
                            {{toLocal('first_login.match_requirement')}}
                        </li>
                        </ul>
                    </span>
                </div>
              
                    <span class="error" v-if="[1,2].includes(errorCode) && isMobileWindow()" >
                        {{errorMessage}}
                    </span>
              </div>
        </div>
        <div class="row line-under">
            <span class="section-label">{{toLocal('settings.your_permissions')}}:</span>
            <div class="selectables-container">
                <div 
                    class="checkmark-container" 
                    v-for="(selectable, s_id) in selectables"
                    :key="s_id"
                    :class="{'non-selectable': !selectable.isSelectable}"
                    @click="selectable.isSelectable ? selectable.selected = !selectable.selected : ''; enableSaving()"
                >
                  <div class="checkmark" :class="{selected: selectable.selected}" >
                      <div class="checkmark-icon">
                            <svg v-if="selectable.selected" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                             <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                            </svg>
                      </div>
                  </div>
                  <span v-html="toLocal(selectable.name)"> </span>
              </div>
            </div>
        </div>
        <div class="row space-between line-under">
            <span class="section-label">{{toLocal('settings.language_select')}}:</span>
            <div class="language-container">
                 <div 
                    class="checkmark-container" 
                    v-for="(language, index) in languages"
                    :key="index"
                    @click="$store.state.user_selected_language = language.value; enableSaving()"
                >
                  <div class="checkmark round" :class="{selected: $store.state.user_selected_language == language.value}" >
                      <div class="checkmark-icon">
                            <svg v-if="$store.state.user_selected_language == language.value" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                             <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                            </svg>
                      </div>
                  </div>
                  <span >{{language.label}} </span>
              </div>
            </div>
            
        </div>
        <div class="row" v-if="isNative" ref="updateContainer">
            <span class="section-label">{{toLocal('settings.auto_update_label')}}</span>
            <div class="update-container" :class="{'new-update': $store.state.showUpdateIndicators}">
                <div class="toggle">
                    <span :class="{'active': !$store.state.user_cols['cols.autoUpdate']}">
                        {{ toLocal('generic.state_off') }}
                    </span>
                    <ToggleButton
                    :toggledState="$store.state.user_cols['cols.autoUpdate']"
                    theme="mobile-active"
                    :height="24"
                    :width="48"
                    :innerHeight="24"
                    :innerWidth="24"
                    toggleDirection="right"
                    class="custom-search-toggle"
                    @changeState="changeToggleState"
                  />
                   <span :class="{'active': $store.state.user_cols['cols.autoUpdate']}">
                        {{ toLocal('generic.state_on') }}
                    </span>
                </div>
                <span class="disclaimer">
                    {{ toLocal('settings.updates_disclaimer') }}
                </span>
                <div class="updates-available" v-if="$store.state.showUpdateIndicators && $store.state.filesToDownload && $store.state.filesToDownload.length > 0 && $store.state.user_cols['cols.autoUpdate']">
                    <UpdateModal/>
                </div>
            </div>
        </div>
        <div class="row line-under" v-if="$store.state.system_settings.deleteAccountBtn">
            <span class="section-label">Paskyros ištrynimas</span>
            <div class="row-container">
                <span class="small-text">Visam laikui ištrinti paskyrą:</span>
                <button class="delete-acc-btn" @click="isDeleteModalOpen = true">Ištrinti</button>
            </div>
        </div>
    </div>
</div>
 
</template>

<script>
import { required, requiredIf, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
import GlobalModal from '../modals/GlobalModal.vue'
import ToggleButton from "@/components/layout/emails/ToggleButton";
import UpdateModal from "@/components/layout/modals/UpdateModal";

export default {
    name: 'UserInfo',
    components:{
        GlobalModal,
        ToggleButton,
        UpdateModal
    },
    data(){
        return{
            isDeleteModalOpen: false,
            isSavingEnabled: false,
            isPasswordEdited: false,
            isEmailEdited: false,
            isUsernameEdited: false,
            firstLogin:{
                username: this.$store.state.user_username != null ? this.$store.state.user_username : '',
                password: '',
                confirmPassword: '',
                emailPhone: this.$store.state.system_settings.usesPhones ? this.$store.state.user_phone != null ? this.$store.state.user_phone : '' :  this.$store.state.user_email != null ? this.$store.state.user_email : '',
            },
            selectables: [
                {
                    name: 'settings.forgot_password_agree',
                    selected: this.$store.state.user_can_contact == 1 ? true : false ,
                    isSelectable: true,
                },
                {
                    name: 'settings.trainings_update_agree',
                    selected: this.$store.state.user_can_notify == 1 ? true : false,
                    isSelectable: true, 
                },
                {
                    name: 'settings.auth_agree',
                    selected:  true ,
                    isSelectable: false,
                },
                {
                    name: 'settings.privacy_policy_agree',
                    selected:  true ,
                    isSelectable: false,
                }
            ],
            errorMessage: '',
            errorCode: -1,
            isSaving: false,
            showSaveCheckmark: false,
            loaded: false,
            languages:[
                {'label':'Lietuvių', 'value':'lt'},
                {'label':'English', 'value':'en'},
                {'label':'По-русски', 'value':'ru'},
                {'label':'Latviešu', 'value':'lv'},
                {'label':'Eesti', 'value':'et'},
            ],
            showInputText:{
                password:false,
                confirmPassword:false
            },
            data: [],
        }
    },
    computed: {
        isNative(){
            return  window.isNative
        }
    },
    mounted(){
        let setSettings = new Promise((resolve) =>{
            for (const key in this.$store.state.system_settings.firstloginmodal){
                let val = this.$store.state.system_settings.firstloginmodal[key];
                if(val){
                    if(this.firstLogin[key] == undefined)
                        this.$set(this.firstLogin, key,  '');
                    else if(val == 'password') this.$set(this.firstLogin, key, '');
                    else this.$set(this.firstLogin, key, this.firstLogin[key]);
                }
                else{
                    delete this.firstLogin[key];
                }
            }
            resolve();
        });
        setSettings.then(()=>{
            this.loaded = true;
            this.isSavingEnabled = false;
        })
       
       
        this.loadUserInfo();
    },
    validations: {
        firstLogin: {
            username:{
                required: requiredIf((el) => {
                    return el.username != undefined;
                }),
                minLength: minLength(2),
                maxLength: maxLength(32),
            },
            password:{
                required: requiredIf((el) => {
                    return el.password != undefined;
                }),
                minLength: minLength(8),
                maxLength: maxLength(1024),
            },
            confirmPassword:{
                required: requiredIf((el) => {
                    return el.confirmPassword != undefined;
                }),
                minLength: minLength(8),
                maxLength: maxLength(1024),
                sameAsPassword: sameAs('password')
            },
            emailPhone:{
                required: requiredIf((el) => {
                    return el.emailPhone != undefined;
                }),
                minLength: minLength(6),
                maxLength: maxLength(64),
            },
        },
    },
    methods:{
        enableSaving(){
            if(this.loaded == true){
                this.isSavingEnabled = true;
            }
        },
        changeToggleState() {
            this.$set(this.$store.state.user_cols, 'cols.autoUpdate', this.$store.state.user_cols['cols.autoUpdate'] ? false : true);
            this.isSavingEnabled = true;
            if (!this.$store.state.user_cols['cols.autoUpdate']) {
                this.$store.state.showUpdateIndicators = false
                this.$store.state.checkAutoUpdate = false;
            }
            if (window.ReactNativeWebView && this.$store.state.user_cols['cols.autoUpdate']) {
                this.$store.state.checkAutoUpdate = true;
            }
        },
        deleteAccount() {
        axios
            .delete(
            `${this.$store.state.api_link}/user/delete/account`,
            this.$store.getters.axiosConfig()
            )
            .then((res) => {
            if (res.data.status == "success") {
                this.logout();
            }
            })
            .catch((error) => {});
        },
        saveUserDetails(){
            let changedFields = [
                this.isUsernameEdited && 'username',
                this.isPasswordEdited && 'password',
                this.isPasswordEdited && 'confirmPassword',
                this.isEmailEdited && 'emailPhone',
            ]
            let isValid = true
            changedFields.forEach((field)=>{
                if(field != false){
                    if(this.$v.firstLogin[field].$invalid){
                        this.$v.firstLogin[field].$touch;
                        isValid = false;
                        return;
                    }
                }
            })
            if(isValid == false){
                return;
            }
            this.errorMessage = '';
            this.errorCode = -1;
            this.isSaving = true;
            let emailPhone = this.firstLogin.emailPhone ? this.firstLogin.emailPhone : '';
            if(this.$store.state.system_settings.usesPhones){
                emailPhone = emailPhone.replaceAll(' ', '');
            }
            this.$store.state.language = this.$store.state.user_selected_language;
            let autoUpdate = this.$store.state.user_cols['cols.autoUpdate'] ? 1 : 0;
            axios.post( 
                `${this.$store.state.api_link}/user/first-login/save`,
                {
                    username: this.isUsernameEdited ? this.firstLogin.username : '',
                    password: this.isPasswordEdited ? this.firstLogin.password : '',
                    emailPhone: this.isEmailEdited ? emailPhone : '',
                    can_contact: this.selectables[0].selected,
                    can_notify: this.selectables[1].selected,
                    can_identify: this.selectables[2].selected,
                    language: this.$store.state.user_selected_language,
                    autoUpdate: autoUpdate,
                    type: 'update'
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == 'success'){
                    this.$store.state.user_email = response.data.user.email;
                    this.$store.state.user_username = response.data.user.username;
                    this.$store.state.user_is_new = false;
                    this.$store.state.language = this.$store.state.user_selected_language;
                    this.$cookies.set('selected_language', this.$store.state.user_selected_language, '1Y');
                    this.showSaveCheckmark = true;
                    setTimeout(() => {
                        this.showSaveCheckmark = false;
                    }, 1000);
                    this.isSavingEnabled = false;
                    this.isUsernameEdited = false;
                    this.isEmailEdited = false;
                    this.isPasswordEdited = false;
                }
                else{
                    this.errorMessage = response.data.message;
                    this.errorCode = response.data.code;
                    if(this.errorCode == 2){
                        setTimeout(() => {
                            this.errorCode = 0;
                            this.errorMessage = '';
                        }, 2000);
                    }
                }
                this.isSaving = false;

            }).catch((error) => {
                console.log(error)
                 this.isSaving = false;
            });
        },
         saveLanguage(){
            this.$store.state.language = this.$store.state.user_selected_language;
            axios.post( 
                `${this.$store.state.api_link}/user/first-login/save`,
                {
                    can_contact: this.selectables[0].selected,
                    can_notify: this.selectables[1].selected,
                    can_identify: this.selectables[2].selected,
                    language: this.$store.state.user_selected_language,
                    type: 'update'
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == 'success'){
                    this.$cookies.set('selected_language', this.$store.state.user_selected_language, '1Y');
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        loadUserInfo() {
            if (Object.keys(this.$store.state.user_cols).length > 0) return
            axios
                .get(
                `${this.$store.state.api_link}/user/get-self-info`,
                this.$store.getters.axiosConfig()
                )
            .then((response) => {
                this.data = response.data.data;
                let cols = {};
                this.data.forEach((el, index) => {
                    if (el.colValue != null) {
                    try {
                        el.colValue.value = JSON.parse(el.colValue.value);
                        cols[el.col.name] = el.colValue.value;
                    } catch (e) {}
                    }
                
                });
                this.$store.state.user_cols = cols;
                 let route = this.$route;
                let hash = route.hash;
                 if (hash == '#updates') {
                    this.$nextTick(()=>{
                        this.$refs.updateContainer.scrollIntoView();
                    })
                }
            });
        },
        
    }
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    max-width: 100%;
    @media (max-width: 1532px){
        .row{
            grid-template-columns: 2fr 3fr;
        }
        .col:nth-child(2){
            border-right: none;
        }
    }
    @media (max-width: 1200px){
        margin-left: 20px;
        padding-bottom: 100px;
        .row{
            grid-template-columns: 1fr;
        }
        .language-container, .selectables-container{
            margin-top: 20px;
            padding-left: 0px;
        }
        .col{
            border-right: none;
            padding-left: 23px;

        }
      
    }
    @media (max-width: 768px){
        max-width: 100%;
        margin-bottom: 100px;
        margin-left:0;
        padding-bottom: 0px;
        .row{
            display: flex;
            flex-direction: column;

        }
        .col{
            border: none;
            padding: 0;
            margin: 20px 0;
        }
        .selectables-container{
            margin-top: 0;
        }
        .delete-btn{
            width: 100%;
            max-width: 100%;
            white-space: nowrap;
            margin: 20px auto;
        }
        .justify-center{
            margin-top: 20px;
        }
    }
}   
.row{
    display: grid;
    grid-template-columns: 2fr 3fr 1.5fr;
    width: 100%;
    padding: 0px 23px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.col{
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    margin-bottom: 60px;

    &:last-child{
        padding-right: 0;
        border-right: none;
    }
}
.user-details{
    padding-left: 23px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .input-wrapper{
        width: 100%;
        display: flex;     
        gap: 24px;
    }
}
.section-label{
    line-height: 24px;
    font-weight: 600;
}
.line-under{
    padding-bottom: 32px;
    border-bottom: 1px solid hsla(0, 0%, 49%, 0.5);
    margin-bottom: 32px;
}
.input-outer{
    display: flex;
    flex-direction: column;
    span{
        // height: 50%;
    }
    .input-container{
        height: 50%;
    }
}
.modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--primary-color);
      background: #dbdbdbc2;
    // background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    .modal-wrapper{
        position: relative;
        width: 45vw;
        max-width: 40rem;
        background: #efefef;
        border: 5px solid white;
        border-radius: 1rem;
        padding: 1.5rem 2rem;
        //overflow: hidden;
    }
}
.selectables-container{
    display: flex;
    flex-direction: column;
}
.label{
    margin-bottom: 16px;
}
.language-container{
    display: flex;
    flex-direction: column;
}
.checkmark-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 20px;
    .checkmark{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: white;
        padding: 0.25rem;
        height: 28px;
        width: 28px;
        min-width: 28px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        user-select: none;
        cursor: pointer;
        .checkmark-icon{
            display: flex;
            width: 100%;
        }
        svg{
            height: 14px;
            width: 12px;
            margin: auto;
            path{
                fill: var(--primary-color);
            }
        }
        &.selected{
            background: var(--secondary-color);
        }
        &.round{
            border-radius: 50%;
        }
    }
    span{
        a{
            text-decoration: underline;
        }
        // font-weight: 700;
        margin-left: 12px;
        font-size: clamp(14px, 1.5vw, 16px);
        cursor: pointer;
    }
    &.non-selectable{
        .checkmark{
            opacity: 0.5;
        }
    }
}
.button-container{
    display: flex;
    justify-content: space-around;
}
.accept-container{
    margin-top: 1.5rem;
    p{
        font-size: clamp(12px, 1vw, 16px);
    }
    .btn{
        margin: 0;
        width: 58%;
        min-width: 12rem;
        margin: auto;
    }
}
.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1rem;
    border: 2px solid var(--primary-color);
    background: white;
    color: var(--primary-color);
    border-radius: 2rem;
    user-select: none;
    cursor: pointer;
    width: 100%;
    margin: 0 1rem;
    &:hover{
        font-weight: 700;
    }
    &.secondary{
        background: var(--primary-color);
        color: white;
    }
    &:active{
        background: var(--secondary-color);
    }
}
.input-wrapper{
    span{
        font-size: clamp(12px, 1.5vw, 16px);
        font-family: var(--main-site-font);
        font-weight: 600;
        color: #333333;
        // margin-left: 0.75rem;
    }
    input{
        font-size: 16px;
        font-family: var(--main-site-font);
    }
}
.row-container{
    display: flex;
    gap: 12px;
    align-items: center;
    .small-text{
        font-weight: 600;
        line-height: 20px; 
        font-size: 14px;
    }
    .delete-acc-btn{
        border: none;
        border-radius: 20px;
        background: #D45454;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12); 
        color: white;
        padding: 4px 28px; 
        transition: 0.25s;
        cursor: pointer;
        &:hover{
            background-color: #333;
        }
    }
}
.input-container{
    position: relative;
    display: flex;
    background: white;
    margin-bottom: 24px;
    margin-top: 0.25rem;
    width: 100%;
    padding: 0;
    border-radius: 8px;
    &:last-child{
        margin-bottom: 0;
    }
    .eye{
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        height: 100%;
        margin: auto;
        cursor: pointer;
        z-index: 2;
    }
    &.password-container{
        input{
            padding-right: 32px;
        }
    }
}
input{
    width: 100%;
    color: var(--primary-color);
    background: white;
    border-radius: 8px;
    border: none;
    padding: 11px 19px;
    font-size: clamp(16px, 1vw, 20px);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
    -webkit-appearance: none;
    &:focus{
        outline: none;
    }
    &::placeholder{
        color: var(--primary-color-opacity-50);
    }
 
}
input[type="password-field"]{
    -webkit-text-security: disc;
    padding-right: 32px;
}
h2{
    margin: 0;
    border-bottom: 4px solid;
    text-align: center;
    padding: 0 2rem 0.5rem;
    font-weight: 700;
}
.tooltip-container{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    background: white;
    border: 2px solid var(--primary-color);
    border-radius: 0.5rem;
    font-size: clamp(12px, 1vw, 16px);
    min-width: 9rem;
    right: 0%;
    h3{
        margin: 0;
        font-size: clamp(12px, 1vw, 16px);
    }
    span{
        margin-left: 0;
    }
    ul{
        margin: 0;
        padding-left: 1rem;
    }
    &::after{
        position: absolute;
        content: '';
        top: 0;
        left: -0.58rem;
        bottom: 0;
        margin: auto;
        background: white;
        transform: rotate(45deg);
        display: flex;
        width: 1rem;
        height: 1rem;
        border-bottom: 2px solid var(--primary-color);
        border-left: 2px solid var(--primary-color);
    }
}
.space-between{
    justify-content: space-between;
}
.pop-right{
    top: 0;
    right: auto;
    left: 105%;
    z-index: 999;
    width: 10rem;
    bottom: 0;
    margin: auto;
    height: fit-content;
    &.server-error{
        top: -0.45rem;
    }
}
.pop-right{
    top: 0;
    right: auto;
    left: 105%;
    z-index: 999;
    width: 10rem;
    bottom: 0;
    margin: auto;
    height: fit-content;
    &.server-error{
        top: -0.45rem;
    }
}
.pop-top{
    top: 0;
    right: 0;
    z-index: 999;
    width: 10rem;
    bottom: 0;
    // margin: auto;
    height: fit-content;
    &.server-error{
        top: -0.45rem;
    }
}
.save-checkmark{
    height: 15px;
    path{
        fill: var(--primary-color);
    }
}
.password-label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: #7D7D7D;
        font-weight: 400;
        font-size: clamp(12px, 1.5vw, 14px);
        line-height: 20px;
    }
}
.saving-row{
    position: fixed;
    bottom: 0;
    background:#F9F9F9;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.16); 
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .save-btn{
        display: flex;
    }
}
@media(max-width:1080px){
    .input-wrapper{
        flex-direction: column;
    }
}
@media(max-width: 1080px) and (min-width:769px){
    .input-outer{
        width: 80%;
    }
}
@media (max-width: 768px){
    .modal-container{
        .modal-wrapper{
            width: 100%;
            min-height: 100%;
            overflow: auto;
            .modal-content{
                width: 100%;
            }
        }
    }
    h2{
        font-size: clamp(16px, 6vw, 28px);
        padding: 0 0 1rem 0;
    }
    .input-wrapper{
        span{
            margin: 0;
        }
    }
    .accept-container{
        .btn{
            width: 100%;
        }
    }
    .selectables-container{
        margin-top: 2rem;
    }
    .checkmark-container{
        margin: 0.5rem 0;
    }
}
.justify-center{
    display: flex;
    justify-content: center;
    margin-top: 68px;
}
.save-btn{
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    padding: 9px 44px;
    border: none;
    cursor: pointer;
    background-color: var(--secondary-color);
    font-family: var(--main-site-font);
    font-size: 16px;
    span{
        display: flex;
        align-items: center;
        svg{
            margin-right: 5px;
        }
    }
     &:hover{
        text-shadow: 1px 0 0 var(--primary-color);
    }
    &:active{
        background-color: var(--primary-color);
        color: white;
    }
}
.delete-btn{
    padding: 9px 44px;
    background: #E2E2E2;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    border: none;
    cursor: pointer;
    color: var(--primary-color);
    font-family: var(--main-site-font);
    width: 244px;
    margin-right: 40px;
    font-size: 16px;
    &:hover{
        text-shadow: 1px 0 0 var(--primary-color);
    }
    &:active{
        background-color: rgba(125, 125, 125, 1);
        color: white;
        text-shadow: none;
    }
}
.updates-available{
    display: flex;
    align-items: center;
    background-color: #D45454;
    color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 13px;
    border-radius: 0 0 8px 8px;
    gap: 8px;
    button{
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        border: none;
        padding: 2px 8px;
        font-size: 10px;
        height: 18px;
        background-color: white;
        box-shadow: 0 1px 2px 0px #0000003a ;
    }
}
.toggle{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #7D7D7D;
    span{
        &.active{
            color: #000;
            font-weight: 600;
        }
    }
}
.error{
    color: rgb(208, 63, 63);
    font-size: clamp(14px, 3vw, 16px);
}
.update-container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: clamp(14px, 1.5vw, 16px);
    .disclaimer{
        color: #7D7D7D;
        font-size: clamp(10px, 1.3vw, 12px);
    }
    &.new-update{
        padding-bottom: 20px;
    }
}
</style>
<style lang="scss">
.checkmark-container{
    a{
        text-decoration: underline;
    }
}
</style>