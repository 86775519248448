var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-container",class:_vm.options.containerClass ? _vm.options.containerClass : ''},[_c('div',{staticClass:"dropdown-initial",on:{"click":function($event){return _vm.toggleDropdown()}}},[_vm._t("leftIcon"),(_vm.options.singleIcon)?_c('div',{staticClass:"dropdown-btn-icon",class:{active: _vm.isDropdownOpen}},[_vm._t("icon",function(){return [_c('FilterOnColumnsSVG')]})],2):_vm._e(),(_vm.options.singleIcon != true)?_c('span',[_vm._v(_vm._s(_vm.options.initial ? _vm.options.selectAllChoice && _vm.isAllChecked() ? _vm.toLocal(_vm.options.selectAllChoice) : _vm.toLocal(_vm.options.initial) : _vm.toLocal("generic.choose")))]):_vm._e(),(_vm.options.open != true && _vm.options.singleIcon != true)?_c('span',{class:[{ reverse: _vm.isDropdownOpen }, { initial: !_vm.isDropdownOpen }]},[_vm._t("arrow",function(){return [_c('ArrowDownSVG',{staticClass:"white"})]})],2):_vm._e(),(_vm.options.closeBtn)?_c('span',{staticClass:"closeBtn",on:{"click":_vm.closeDropdown}},[_c('CrossSVG')],1):_vm._e()],2),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.isDropdownOpen)?_c('div',{directives:[{name:"click-outside-3",rawName:"v-click-outside-3",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"dropdown-extended",class:{left: _vm.options.left, dynamic: _vm.options.valueWidthType && _vm.options.valueWidthType =='dynamic'},style:([{
        width: _vm.options.customDDWidth ? _vm.options.customDDWidth : '120%',
        'max-width': _vm.options.customDDMaxWidth ? _vm.options.customDDMaxWidth : '',
        'min-width': _vm.options.customDDMinWidth ? _vm.options.customDDMinWidth : '',
        
      }, _vm.options.dropdownStyle ? _vm.options.dropdownStyle : {}])},[(_vm.options.searchBar)?_c('div',{staticClass:"search-box"},[(_vm.searchTerm == '')?_c('SearchSVG'):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],class:{ gapLeft: _vm.searchTerm == '' },attrs:{"placeholder":_vm.toLocal('dropdown.write')},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}})],1):_vm._e(),_c('div',{key:_vm.updateKey,staticClass:"options-container",style:({
          maxHeight: _vm.options.customMaxHeight
            ? _vm.options.customMaxHeight
            : 'fit-content',
        })},[(_vm.options.selectAllChoice && _vm.options.checkbox)?_c('div',{staticClass:"dropdown-option all-option",on:{"click":function($event){return _vm.selectAll()}}},[(_vm.options.checkbox)?_c('div',{staticClass:"checkbox",class:{ checked: _vm.isAllChecked() }},[(_vm.isAllChecked())?_c('CheckmarkSVG',{staticClass:"checkmark"}):_vm._e()],1):_vm._e(),_c('span',{class:{ bold: _vm.isAllChecked() }},[_vm._v(_vm._s(_vm.options.selectAllChoice))])]):_vm._e(),(_vm.options.filterType && ['date','number','percent'].includes(_vm.options.filterType))?_c('div',{staticClass:"options-wrapper"},[(_vm.options.filterType=='date')?_c('DateRangePicker',{attrs:{"from":_vm.compareList[0] ? _vm.compareList[0] : '',"to":_vm.compareList[1] ? _vm.compareList[1] : ''},on:{"changeFrom":function($event){return _vm.$emit('selectedElement', 'date', 'from', $event)},"changeTo":function($event){return _vm.$emit('selectedElement', 'date', 'to', $event)},"innerPopupState":function($event){_vm.innerPopupOpen=$event}}}):(['number','percent'].includes(_vm.options.filterType))?_c('NumberRangePicker',{attrs:{"from":_vm.compareList[0] ? _vm.compareList[0] : '',"to":_vm.compareList[1] ? _vm.compareList[1] : '',"type":_vm.options.filterType,"rangeMin":_vm.options.rangeMin,"rangeMax":_vm.options.rangeMax},on:{"changeFrom":function($event){return _vm.$emit('selectedElement', 'number', 'from', $event)},"changeTo":function($event){return _vm.$emit('selectedElement', 'number', 'to', $event)},"innerPopupState":function($event){_vm.innerPopupOpen=$event}}}):_vm._e()],1):_c('div',{staticClass:"options-wrapper"},[_vm._l((_vm.totalList),function(element){return _c('div',{key:("el-" + (element[_vm.keyLabel])),staticClass:"dropdown-option",class:[{ 
              selected: _vm.isChecked(element[_vm.keyLabel]), 
              'with-icon': element.icon 
            },element.class ? element.class : '',
              element.additional ? 'additional' : ''
            ],on:{"click":function($event){return _vm.clickedOnElement(element[_vm.keyLabel])}}},[(_vm.options.checkbox)?_c('div',{staticClass:"checkbox",class:{ checked: _vm.isChecked(element[_vm.keyLabel]) }},[(_vm.isChecked(element[_vm.keyLabel]))?_c('CheckmarkSVG',{staticClass:"checkmark"}):_vm._e()],1):_vm._e(),_c('span',{class:{ bold: _vm.isChecked(element[_vm.keyLabel]) }},[_vm._v(_vm._s(_vm.toLocal(_vm.getDropdownValue(element))))]),(element.icon)?_c('div',{staticClass:"right-icon"},[_vm._t(element.icon)],2):_vm._e()])}),_c('div',{staticClass:"hidden-option"})],2)]),(
          _vm.totalList.length == 0 &&
          (_vm.options.showCreateNewBtn == undefined || _vm.options.showCreateNewBtn) && _vm.options.showCreateNewBtn != false
        )?_c('div',{staticClass:"create-new",on:{"click":_vm.createNewElement}},[_c('span',[_c('DropAddSVG')],1),_c('span',[_vm._v(_vm._s(_vm.toLocal(_vm.options.createNewText)))])]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }