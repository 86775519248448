<template>
  <section class="stats-section" name="group-list" v-scroll-lock="selectedTraining">
    <TrainingUserSide
      :sideOpen="testOpenedSide"
      :test="selectedTest"
      :training_id="selectedTraining"
      @close="testOpenedSide = -1"
    />
    <TrainingConfirmationSide
      :sideOpen="openedConfirmationSide"
      :user="selectedUser"
      :stats="selectedConfirmStats"
      :training_id="selectedTraining"
      @close="openedConfirmationSide = -1"
    />

    <div :class="{ 'container main--container': true }">
      <div  class="back-button-container" >
            <div class="back-btn" @click="$router.back()">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M9 1L1 9L9 17" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{toLocal('generic.back')}}
            </div>
      </div>
      <div class="tab--container">
        <div
          class="tab"
          :style="{
            'z-index':
              selectedTabIndex == tab_index ? 5 : tabs.length - tab_index,
          }"
          :class="{ selected: selectedTabIndex == tab_index }"
          v-for="(tab, tab_index) in tabs"
          :key="tab_index"
          v-show="!tab.hidden"
          @click="selectedTabIndex = tab_index"
        >
          {{ tab.name }}
        </div>
      </div>
      <div class="tab--content">
        <div class="training--summary">
          <div
            class="training--cover"
            :style="[
              cover != ''
                ? { 'background-image': `url('${getFileUrl(cover)}')` }
                : {},
            ]"
          />
          <div class="training--info">
            <h3>{{ title }}</h3>
            <div class="stats-text" v-if="selectedTabIndex == 0">
              {{ toLocal("training_stats.started_1") }}
              <b>{{ users_started }}</b>
              {{ toLocal("training_stats.started_2") }}<br />
              {{ toLocal("training_stats.ended_1") }}<b> {{ user_count }} </b
              >{{ toLocal("training_stats.started_2") }}<br />
              {{ toLocal("training_stats.rated") }}
              <b
                >{{
                  isNaN(parseFloat(avg_rating))
                    ? "0"
                    : parseFloat(avg_rating).toFixed(1)
                }}/5</b
              ><br />
            </div>
            <div class="test-text" v-else-if="selectedTabIndex == 1">
              <span
                >{{ toLocal("training_stats.test_versions") }}
                <b>{{ tests.length }}</b>
              </span>
              <span
                >{{ toLocal("training_stats.average_overall_result") }}
                <b>{{ tests.length > 0 ? allTestsResult : 0 }} %</b></span
              >
              <span
                >{{ toLocal("training_stats.average_overall_time") }}
                <b>{{ getTestSolvingTime(allSolveTime) }} </b><br
              /></span>
            </div>
            <div class="button--container">
              <span
                class="summary--button"
                v-if="selectedTabIndex == 0"
                @click="openComments"
              >
                <img src="@/assets/images/icons/item-comment.svg" />
                {{ toLocal("training.comments") }}
              </span>
              <span
                class="summary--button"
                @click="exportStats(selectedTabIndex)"
              >
                <svg class="download-icon" width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1V12M11 12L7 8.82692M11 12L15 8.82692" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 8V15C1 16.1046 1.89543 17 3 17H19C20.1046 17 21 16.1046 21 15V8" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ toLocal("training_stats.download_stats") }}
              </span>
            </div>
          </div>
        </div>
        <TrainingStatsView
          v-if="selectedTabIndex == 0"
          @statsLoaded="setData"
        />
        <TrainingStatsTestView
          v-if="selectedTabIndex == 1"
          :stats="stats"
          :cover="cover"
          :title="title"
          @setTests="setTests"
          @setTestData="setTestData"
        />
        <TrainingStatsConfirmationView
          v-if="selectedTabIndex == 2"
          :cover="cover"
          :title="title"
          @setTestData="setConfirmationData"
          @setConfirmationData="setConfirmData"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TrainingStatsView from "@/components/layout/trainings/training_components/training_stats/TrainingStatsView.vue";
import TrainingStatsTestView from "@/components/layout/trainings/training_components/training_stats/TrainingStatsTestView.vue";
import TrainingUserSide from "@/components/layout/trainings/training_components/training_stats/TrainingUserSide.vue";
import TrainingStatsConfirmationView from "@/components/layout/trainings/training_components/training_stats/TrainingStatsConfirmationView.vue";
import TrainingConfirmationSide from "@/components/layout/trainings/training_components/training_stats/TrainingConfirmationSide.vue";
import axios from "axios";

export default {
  name: "TrainingStats",
  components: {
    TrainingStatsView,
    TrainingStatsTestView,
    TrainingUserSide,
    TrainingStatsConfirmationView,
    TrainingConfirmationSide,
  },
  data: function () {
    return {
      tabs: [
        { name: this.toLocal("generic.training") },
        { name: this.toLocal("generic.tests") },
        { name: this.toLocal("generic.confirmations") },
      ],
      selectedTabIndex: 0,
      stats: [],
      userStats: [],
      cover: "",
      title: "",
      tests: [],
      testOpenedSide: -1,
      selectedTest: {},
      openedConfirmationSide: -1,
      selectedUser: {},
      avg_result: 0,
      avg_rating: 0,
      user_count: 0,
      users_started: 0,
      selectedConfirmStats: [],
      exporting: false,
      confirmationUsers: [],
      totalConfirmations: 0,
    };
  },
  props:{
    selectedTraining:{
      type: Number,
    },
    item: {
      type: Object,
    }
  },
  computed: {
    allTestsResult() {
      let mapped = this.tests.map((el) => el.result);
      let reduced = mapped.reduce((a, b) => parseFloat(a) + parseFloat(b));
      return Math.round((reduced / this.tests.length) * 100, 2);
    },
    allSolveTime() {
      let mapped = this.tests.map((el) => el.time);
      if (mapped.length > 0) {
        let reduced = mapped.reduce((a, b) => parseInt(a) + parseInt(b));
        return reduced / this.tests.length;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    if (this.item && this.item.data && this.item.data.trainingType == 'attest') {
      // this.tabs.shift()
      this.selectedTabIndex = 1;
      this.$set(this.tabs[0], 'hidden', true);
      this.$set(this.tabs[2], 'hidden', true);
    }
  },
 

  methods: {
    setData(data) {
      this.stats = data.stats;
      this.cover = data.cover;
      this.title = data.title;
      this.user_count = data.user_count;
      this.users_started = data.users_started;
      this.avg_result = data.avg_result;
      this.avg_rating = data.avg_rating;
    },
    setTestData(data) {
      this.testOpenedSide = data.testOpenedSide;
      this.selectedTest = data.test;
    },
    setTests(tests) {
      this.tests = tests;
    },
    setConfirmationData(data) {
      this.selectedUser = data.user;
      this.selectedConfirmStats = data.stats;
      this.openedConfirmationSide = data.openedConfirmationSide;
    },
    setConfirmData(users, total) {
      this.confirmationUsers = users;
      this.totalConfirmations = total;
    },
    openComments() {
      this.$store.commit("openSide", {
        side: "training-comments-side",
        sideInfo: {},
      });
    },
    getTestSolvingTime(time_in_seconds) {
      let hours = parseInt(time_in_seconds / 3600);
      if (hours < 10) {
        hours = "0" + hours;
      }
      let minutes = parseInt(time_in_seconds / 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds = parseInt(time_in_seconds % 60);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return `${hours}:${minutes}:${seconds}`;
    },
    exportStats(tabIndex) {
      this.exporting = true;
      let type =
        tabIndex == 0 ? "training" : tabIndex == 1 ? "tests" : "confirmations";
      let stats =
        tabIndex == 0
          ? this.stats.filter((el) => el.user != undefined)
          : tabIndex == 1
          ? this.tests
          : this.confirmationUsers;
      let additional =
        tabIndex == 0
          ? { users_started: this.users_started, user_count: this.user_count }
          : tabIndex == 1
          ? {
              test_versions: this.tests.length,
              avg_result: this.tests.length > 0 ? this.allTestsResult : 0,
              avg_time: this.getTestSolvingTime(this.allSolveTime),
            }
          : { total_confirmations: this.totalConfirmations };
      let file_name =
        tabIndex == 0
          ? this.toLocal('export.training_statistics_file')
          : tabIndex == 1
          ? this.toLocal('export.test_statistics_file')
          : this.toLocal('export.confirmation_statistics_file');
      axios
        .post(
          `${this.$store.state.api_link}/training/stats/export`,
          {
            training: this.selectedTraining,
            type: type,
            stats: stats,
            additional: additional,
          },
          this.$store.getters.axiosConfigDownload()
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name + ".xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();
          this.exporting = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  deactivated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_trainingStatsViewScoped.scss";
.back-button-container{
    display: flex;
    width: 100%;
    max-width: 1330px;
    margin: auto;
    &.buying-page{
        .back-btn{
            margin-bottom: 27px;
        }
    }
}
.back-btn{
    display:flex;
    align-items:center;
    background: rgb(255, 255, 255);
    opacity: 0.5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    padding: 4px 16px;
    margin-bottom: 20px;
    width: 100px;
    height: 40px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    svg{
        margin-right: 10px;
    }
    &:hover, &:active{
        opacity: 1;
    }
   
}
</style>
<style lang="scss">
@import "@/assets/scss/views/_trainingStatsView.scss";
.stats-section{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 105px;
  background: #F5F5F5;
  z-index: 25;
  overflow: auto;
}
</style>
