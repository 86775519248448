<template>
<div class="click-task">
    <div class="tries-container" v-if="readOnly && !statsMode">
        <span>{{toLocal('task.tries')}} </span>
        <span class="tries">{{tries}}</span>
    </div>
    <div class="divider" v-if="!readOnly && !statsMode">

    </div>
  <div class="click-task-container">
    <div class="settings-container" v-show="isEditing && answer && answer.taskImage && answer.taskImage != ''">
        <div class="img-slider-container">
                
            <div class="slider">
                <label for="width">{{toLocal('generic.zoom')}}</label>
                <input id="width" type="range" novalidate @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal">
            </div>
            <div class="slider">
                <label for="height">{{toLocal('generic.expand')}}</label>
                <input id="height"  class="height-slider" type="range" @input="onSliderHeightChange" :min="sliderHeightMin" :max="sliderHeightMax" step="5" v-model="sliderHeightVal">
            </div>
                <div class="slider">
                <label for="brightness">{{toLocal('generic.brightness')}}</label>
                <input id="brightness" class="brightness-slider" type="range" @input="onSliderBrightnessChange" :min="sliderBrightnessMin" :max="sliderBrightnessMax" step="5" v-model="sliderBrightnessVal">
            </div>
            <div class="btn-container">
                <button @click="isEditing = false">{{toLocal('task.finish_editing')}}</button>

            </div>
        </div>
    </div>
    <span class="explanation-text" v-if="!isEditing && !readOnly">
        {{toLocal('task.explanation_text')}}
    </span>
   
        <div class="canvas-container" v-if="answer && answer.taskImage">
            <canvas 
                ref="canvas"
                id="canvas" 
                height="600"
            ></canvas>
            <div class="delete-spots" v-if="!isEditing && answer.hiddenSpots && !readOnly">
                <div 
                    class="delete-spot" 
                    v-for="(spot, index) in answer.hiddenSpots" 
                    :key="index" 
                    :style="{'left' : spot.x+spot.width-10 + 'px', 'top' : spot.y-10 + 'px'}"
                    @click="deleteHiddenSpot(spot, index)"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
                        <g id="Path_749" data-name="Path 749" transform="translate(7.071) rotate(45)">
                            <path id="Path_751" data-name="Path 751" d="M10,5a.539.539,0,0,1-.159.382.527.527,0,0,1-.383.163H5.545V9.459A.55.55,0,0,1,5,10a.539.539,0,0,1-.383-.159.527.527,0,0,1-.163-.382V5.547H.542a.546.546,0,0,1-.386-.928.568.568,0,0,1,.386-.163H4.455V.549a.531.531,0,0,1,.163-.39A.539.539,0,0,1,5,0a.551.551,0,0,1,.545.549V4.457H9.458A.552.552,0,0,1,10,5Z" transform="translate(0 0)" fill="#28345c"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="file-upload-container"  v-if="!readOnly">
            <input type="file" accept=".jpeg,.jpg,.png,.bmp" ref="imageInput" @change="fileInput">
            <div v-if="(answer && !answer.taskImage || answer && answer.taskImage == '') && !isUploadingFile" class="upload-btn" @click="$refs.imageInput.click()">
                
                <img 
                  src="@/assets/images/icons/importQuestions.svg"
                >
                <span>{{toLocal('task.add_image')}}</span>
                
            </div>
            <div v-else-if="isUploadingFile" class="upload-progress-bar-container">
                <div class="upload-progress">
                    <span class="progress-bar"><h3>{{toLocal('task.information_preparing')}}</h3> <span :style="{width: fileUploadedProgress+'%'}"></span> </span>
                </div>
                <div class="cancel-btn" @click="cancelUpload">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <g id="Group_10311" data-name="Group 10311" transform="translate(-1123 -1496)">
                        <g id="Ellipse_533" data-name="Ellipse 533" transform="translate(1123 1496)" fill="#fff" stroke="#28345c" stroke-width="1">
                        <circle cx="15" cy="15" r="15" stroke="none"/>
                        <circle cx="15" cy="15" r="14.5" fill="none"/>
                        </g>
                        <g id="Group_10308" data-name="Group 10308" transform="translate(1138 1497.957) rotate(45)">
                        <path id="Path_7786" data-name="Path 7786" d="M0,0V18.446" transform="translate(9.224)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_7787" data-name="Path 7787" d="M0,0V18.447" transform="translate(18.447 9.224) rotate(90)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </g>
                    </svg>

                </div>  
            </div>
        </div>
        <div class="comment-container" v-if="!isEditing && !readOnly">
            <span class="label">
                {{toLocal('task.correct_comment')}}
            </span>
            <textarea-autosize
                :placeholder="toLocal('task.correct_comment_placeholder')"
                class="textarea"
                ref="myTextarea"
                v-model="answer.comment"
                :min-height="50"
                :max-height="350"
                :readOnly="isLocked"
                @blur.native="$emit('saveAnswer')"
            />
            
        </div>
        <div class="modal" v-if="showModal" :class="{incorrect: !isClickedCorrectly}">
            <div class="close-btn" @click="showModal = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
                    <g id="Path_749" data-name="Path 749" transform="translate(7.071) rotate(45)">
                        <path id="Path_751" data-name="Path 751" d="M10,5a.539.539,0,0,1-.159.382.527.527,0,0,1-.383.163H5.545V9.459A.55.55,0,0,1,5,10a.539.539,0,0,1-.383-.159.527.527,0,0,1-.163-.382V5.547H.542a.546.546,0,0,1-.386-.928.568.568,0,0,1,.386-.163H4.455V.549a.531.531,0,0,1,.163-.39A.539.539,0,0,1,5,0a.551.551,0,0,1,.545.549V4.457H9.458A.552.552,0,0,1,10,5Z" transform="translate(0 0)" fill="#28345c"/>
                    </g>
                </svg>
            </div>
            <h2>
                {{isClickedCorrectly ? answer.comment && Object.isO ? answer.comment : toLocal('task.correct_comment_placeholder') : toLocal('task.incorrect_comment_placeholder')}}
            </h2>
            <h2 v-if="!isClickedCorrectly"> {{toLocal('task.try_again')}}</h2>
            <button @click="showModal = false">{{toLocal('task.understood')}} <span>({{buttonTimer}} s.)</span></button>
        </div>

  </div>
</div>
</template>

<script>
import { fabric } from 'fabric-with-gestures';
import axios from 'axios';
export default {
    props:{
        question:{
            type: Object,
            required: true
        },
        answer:{
            type: Object,
            required: true
        },
        readOnly:{
            type: Boolean,
            default: false
        },
        statsMode:{
            type: Boolean,
            default: false
        },
        clicks:{
            type: Array,
        },
        isLocked:{
            type: Boolean,
            default: false
        },
        currentQuestion:{
            type: Number,
        },

    },
    data(){
        return{
            canvas: null,
            image: null,
            brightness: null,
            options: {},
            fileUploadedData: 0,
            fileUploadedProgress: 0,
            isDown: false,
            ctx: null,

            startY: null,
            startX: null,
            isDown:false,
            prevHeight: null,
            prevWidth: null,
            prevStartY: null,
            prevStartX: null,
            scrollY:0,
            scrollX:0,
            origX: 0,
            origY: 0,
            isEditing: true,
            loadedImage: null,

            sliderVal: 900,
            sliderHeightVal: window.innerHeight*0.88/2,
            sliderBrightnessVal: 0,
            sliderMin: 50,
            sliderMax: 1800,
            sliderHeightMin: 50,
            sliderHeightMax: window.innerHeight*0.88,
            sliderBrightnessMin: -100,
            sliderBrightnessMax: 100,
            tries: 0,
            buttonDefaultTime: 5,
            buttonTimer: 5,
            buttonTimerInterval: null,
            showModal: false,
            isClickedCorrectly: null,
            isDrawingLocked: false,
            isUploadingFile: false,
            axiosUploadController: null
            
        }
    },
    watch:{
        'answer.taskImage'(newVal, oldVal){
            this.$nextTick(()=>{
                if(this.answer && this.answer.taskImage && this.answer.taskImage != ''){
                    this.loadCanvas();
                    this.loadInitialImage();
                }
            });
        },
        isEditing(newVal, oldVal){
            if(!newVal && this.canvas != undefined && this.loadedImage != undefined){
                this.canvas.hoverCursor = 'crosshair';
                this.canvas.selection = true;
                this.loadedImage.set({selectable:false});
                this.canvas.discardActiveObject().renderAll();
                if(!this.answer.options || this.answer.options == '' || Object.keys(this.options).length == 0){
                    let options={
                        left: this.loadedImage.left,
                        top: this.loadedImage.top,
                        width: this.loadedImage.width,
                        height: this.loadedImage.height,
                        scaleX: this.loadedImage.scaleX,
                        scaleY: this.loadedImage.scaleY,
                        canvasHeight: this.canvas.height,
                        canvasWidth: this.canvas.width,
                        brightness: this.loadedImage.filters[0].brightness,
                    }
                    this.options = options;
                    this.$set(this.answer, 'options', options)
                    this.$emit('saveAnswer');
                }
            }
        },
       
       
        showModal(newVal, oldVal){
            if(newVal){
                this.buttonTimerInterval = setInterval(()=>{
                    this.buttonTimer--;
                    if(this.buttonTimer == 0){
                        this.buttonTimer = this.buttonDefaultTime;
                        this.showModal = false;
                        clearInterval(this.buttonTimerInterval);
                    }
                },1000);
            }
            else{
                this.buttonTimer = this.buttonDefaultTime;
                clearInterval(this.buttonTimerInterval);
            }
         
        },
        'answer.hiddenSpots.length':{
            immediate: true,
            handler(newVal, oldVal){
                if(this.canvas){
                    if(oldVal == 0 && newVal == 1){
                        this.isDrawingLocked = true;
                        this.canvas.hoverCursor = 'pointer';
                        this.canvas.selection = false;
                    }
                    else{
                        this.isDrawingLocked = false;
                        this.canvas.hoverCursor = 'crosshair';
                        this.canvas.selection = true;
                    }
                }
            }
        },
    
    },
    mounted(){
        if(!this.answer.comment){
            this.$set(this.answer, 'comment', '')
        }

        if(this.answer.options) this.isEditing = false;
        this.$nextTick(()=>{
            if(this.answer && this.answer.taskImage && this.answer.taskImage != ''){
                this.loadCanvas();
                this.loadInitialImage();
               
               
            }
        });
    },
    methods:{
        uploadFile(formData){
            this.fileUploadedProgress = 0;
            this.isUploadingFile=true;
            this.axiosUploadController = new AbortController();
            return axios.post(
                `${this.$store.state.api_link}/upload-file`,
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: progressEvent => {
                    this.fileUploadedData = progressEvent.loaded
                    this.fileUploadedProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                },
                signal: this.axiosUploadController.signal
                }
            );
        },
        getFileType (file) {
            if (file.type.match('image.*'))
                return 'image';
            if (file.type.match('video.*'))
                return 'video';
            if (file.type.match('audio.*'))
                return 'audio';
            return 'other';
        },
        loadInitialImage(){
            let vm = this;
            if(this.answer.options) this.options = this.answer.options;
            fabric.Image.fromURL(this.answer.taskImage, function(myImg) {
                let parentWidth = vm.$el.offsetWidth
                vm.canvas.setDimensions({width: parentWidth});
                vm.loadedImage = myImg;
                myImg.set({ 
                    left: vm.options && vm.options.left ? vm.options.left : 0 , 
                    top: vm.options && vm.options.top ? vm.options.top : 0,
                    scaleX: vm.options && vm.options.scaleX ? vm.options.scaleX : 1,
                    scaleY: vm.options && vm.options.scaleY ? vm.options.scaleY : 1, 
                    selectable: vm.isEditing});
                vm.brightness =new fabric.Image.filters.Brightness()
                vm.brightness.brightness = vm.options.brightness;
                myImg.filters.push(vm.brightness);
                vm.canvas.add(myImg);
                if(vm.answer.hiddenSpots){
                    for(let i = 0; i < vm.answer.hiddenSpots.length; i++){
                        let element = vm.answer.hiddenSpots[i];
                        var rect = new fabric.Rect({
                            left: element.x,
                            top: element.y,
                            width: element.width,
                            height: element.height,
                            fill: 'rgba(255,0,0,0)',
                            stroke: vm.readOnly ?  vm.statsMode ? vm.isLocked ? vm.$store.state.system_settings.primary_color : '#6ACD77' : 'rgba(255,0,0,0)' : vm.$store.state.system_settings.primary_color,
                            strokeWidth: 2,
                            custom_id: element.custom_id,
                            rx:2,
                            ry:2,
                            selectable: false
                        });
                        vm.canvas.add(rect);
                    }
            
                }
                if(vm.options == undefined || Object.keys(vm.options).length === 0){
                    myImg.scaleToWidth(parentWidth);
                    myImg.scaleToHeight(vm.$refs.canvas.offsetHeight);
                }
                let ratio = vm.$el.offsetWidth / vm.options.canvasWidth;
                if(vm.answer.clicks != undefined && vm.statsMode){
                    for(let i = 0; i < vm.answer.clicks.length; i++){
                        if(!vm.answer.clicks[i].correct){
                            let circle = new fabric.Circle({
                                left: vm.answer.clicks[i].x,
                                top: vm.answer.clicks[i].y,
                                radius: 15,
                                fill: 'rgba(244,121,121,0.5)',
                                selectable: false
                                
                            });
                            vm.canvas.add(circle);
                        }
                    }
                }
                if(vm.options && Object.keys(vm.options).length != 0){
                    vm.canvas.setDimensions({ width: vm.options.canvasWidth * ratio, height: vm.options.canvasHeight * ratio });
                    vm.canvas.setZoom(ratio)
                }
                vm.canvas.renderAll();
                if(vm.answer.options && Object.keys(vm.answer.options).length > 0) vm.isEditing = false;
                if(vm.readOnly){
                    vm.canvas.hoverCursor = 'pointer';
                    vm.canvas.selection = false;
                }
               
            }, { crossOrigin: 'anonymous'});
        },
        loadCanvas(){
            let vm = this;
            if(this.canvas != null){
                this.canvas.dispose();
                 this.canvas = null;
            }
            let ref = this.$refs.canvas;
            let canvasHeight = vm.options && vm.options.canvasHeight ? vm.options.canvasHeight : 600;
            this.canvas = new fabric.Canvas(ref, {
                selectionBorderColor: "#707070",
                selectionLineWidth: 2,
                selectionColor : 'rgba(255,255,255,0.5)',
                height: canvasHeight,
                selection: false,
            });
            this.canvas.on('mouse:down', vm.clickedOnCanvas);
            if(!this.isEditing && !this.readOnly){
                this.canvas.hoverCursor = 'crosshair';
                this.canvas.selection = true;
            }
            if(!this.readOnly){
                this.canvas.on('mouse:down', function(o){
                    vm.handleMouseDown(o.e);
                    vm.$emit('focusing')
                })
  
                this.canvas.on('mouse:up', function(o){
                    vm.handleMouseUp(o.e);
                })
            }
            
        },
        fileInput(e){
            let file;
            if(e.size){
                file = e;
            }
            else{
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                file = files[0];
            }
            if(this.getFileType(file) != 'image'){
                this.$store.state.infoMessage.setMessage('error', this.toLocal('error.not_a_photo'));
                return;
            }
            let formData = new FormData();
            formData.append('file', file);
            let vm = this;
            vm.uploadFile(formData).then((response)=>{
                vm.image = vm.$store.state.api_uploads_link+response.data.file
                vm.$set(vm.answer, 'taskImage', vm.image);
                vm.$emit('saveAnswer');
                vm.isUploadingFile = false;
            }).catch((err)=>{
                vm.isUploadingFile = false;
            })
        },
        handleMouseDown(e){
            if(!this.isDrawingLocked && !this.isLocked){
                this.isDown = true;
                let pointer = this.canvas.getPointer(e);
                this.origX = pointer.x;
                this.origY = pointer.y;
                pointer = this.canvas.getPointer(e);
            }
            
        },

        handleMouseUp(e){
            this.isDown= false;
            let pointer = this.canvas.getPointer(e);
            if(!this.isEditing && !this.isDrawingLocked && !this.isLocked){
                let item = {
                    x: pointer.x-this.origX < 0 ? pointer.x : this.origX,
                    y: pointer.y-this.origY < 0 ? pointer.y : this.origY,
                    width: Math.abs(pointer.x-this.origX),
                    height: Math.abs(pointer.y-this.origY),
                    value: '',
                    hide: true
                }
                if(item.width != 0 && item.height != 0){
                    item.height = Math.max(10,item.height);
                    item.width = Math.max(10,item.width);
                    let id = Math.random().toString(36).substr(2, 9);
                    var rect = new fabric.Rect({
                        left: item.x,
                        top: item.y,
                        width: item.width,
                        height: item.height,
                        fill: 'rgba(255,0,0,0)',
                        stroke: this.$store.state.system_settings.primary_color,
                        strokeWidth: 2,
                        custom_id: id,
                        selectable: false,
                        rx:2,
                        ry:2
                    });
                    item.custom_id = id;
                    if(this.answer.hiddenSpots && this.answer.hiddenSpots.length > 0){
                        this.isDrawingLocked = true;
                        this.canvas.hoverCursor = 'pointer';
                        this.canvas.selection = false;
                        return
                    }
                    this.$set(this.answer, 'hiddenSpots', [item]);
                    this.canvas.add(rect);
                    this.$emit('saveAnswer');
                }
            }
        },
        onSliderChange(e){
            let value = e.target.value;
            let canvasItem = this.$refs.canvas;
            this.loadedImage.scaleToWidth(value);
            this.canvas.centerObject(this.loadedImage);
            this.canvas.renderAll(); 
            this.setImageOptions(canvasItem);

        },
        onSliderHeightChange(e){
            let value = e.target.value;
            let canvasItem = this.$refs.canvas;
            this.canvas.setDimensions({height: value});
            this.loadedImage.scaleToHeight(value);
            this.canvas.centerObject(this.loadedImage);
            this.canvas.renderAll(); 
            this.setImageOptions(canvasItem);

        },
        onSliderBrightnessChange(e){
            let value = e.target.value;
            let canvasItem = this.$refs.canvas;
            value = parseFloat(value);
            this.loadedImage.filters[0].brightness = value/100;
            this.loadedImage.applyFilters();
            this.canvas.renderAll(); 
            this.setImageOptions(canvasItem);

        },
        setImageOptions(canvasItem){
            if(this.answer.options == undefined) this.$set(this.answer, 'options', {});
            let options={
                left: this.loadedImage.left,
                top: this.loadedImage.top,
                width: this.loadedImage.width,
                height: this.loadedImage.height,
                scaleX: this.loadedImage.scaleX,
                scaleY: this.loadedImage.scaleY,
                canvasHeight: this.canvas.height,
                canvasWidth: this.canvas.width,
                brightness: this.loadedImage.filters[0].brightness,
                
            }
            this.$set(this.answer, 'options', options);
        },
        deleteHiddenSpot(spot, index){
            if(this.isLocked) return;
            this.answer.hiddenSpots.splice(index, 1);
            let obj = this.canvas.getObjects().find(obj => obj.custom_id === spot.custom_id);
            if(obj){
                this.canvas.remove(obj);
            }
            this.$emit('saveAnswer');
        },
        clickedOnCanvas(e){
            if(this.readOnly && !this.isClickedCorrectly && !this.statsMode){
                this.tries++;
                if(e.target && e.target.custom_id != undefined){
                    this.showModal = true;
                    this.isClickedCorrectly = true;
                    this.$set(this.question, 'correct', true);
                    e.target.set({stroke: '#6ACD77'})
                }
                else{
                    this.showModal = true;
                    this.isClickedCorrectly = false;
                    this.$set(this.question, 'correct', false);

                }
                this.$set(this.answer, 'tries', this.tries);
                if(!this.answer.clicks) this.$set(this.answer, 'clicks', []);
                let radius=15;
                let x = e.absolutePointer.x-radius;
                let y = e.absolutePointer.y-radius;
                this.answer.clicks.push({
                    x: x,
                    y: y,
                    correct: e.target && e.target.custom_id != undefined ? true : false,
                })
            }
        },
        cancelUpload(){
            if(this.axiosUploadController){
                this.axiosUploadController.abort();
                this.isUploadingFile = false;
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.click-task{
    width: 100%;
    max-width: 846px;
}
.click-task-container{
    position: relative;
}
.file-upload-container{
    display: flex;
    justify-content: center;
    input{
        display: none;
    }
}
.upload-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border-radius: 15px;
    border: 2px solid #707070;
    width: 120px;
    height: 120px;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s box-shadow;
    span{
        text-align: center;
        color:#707070;
    }
    &:hover{
        box-shadow: 0px 0px 7px 2px #70707070;
    }
}
.btn-container{
    width: 46%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 0;
    button{
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        padding: 5px 51px;
        border-radius: 10px;
        background: none;
        font-size: 18px;
        cursor: pointer;
        user-select: none;
    }
}
#canvas{
    border-radius: 5px;
}
.img-slider-container{
    opacity: 1;
    width: 95%;
    min-width: 95%;
    max-width: 100%;
    gap: 15px;
    margin: 0;
    top: 0;
    margin-bottom: 1rem;
    .slider{
        width:100%;
        max-width: 46%;
        label{
            font-size: 12+px;
            padding-left: 6px;
            color: var(--primary-color);
        }
        input{
            margin-top: 7px;
        }
    }
    .rotate{
        min-width: 20px;
        display: flex;
        min-height: 100%;
        align-self: flex-end;
    }
}
.height-slider, .brightness-slider{
    position: initial;
    transform: none;
    opacity: 1;
}

.img-slider-container, .height-slider-container{
  display:flex;
   width: 100%;

  bottom: 0.5rem;
  transition: opacity 0.3s ease;
  cursor:pointer;
  input{
     -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: var(--secondary-color);
    border-radius: 10px;
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
  }
}
.img-slider-container{
    margin: 23px 20px 20px 20px;
    flex-wrap: wrap;
    position: relative;
}
.height-slider-container{
    bottom: 0.5rem;
    right: 0.5rem;
    input{
        bottom: 0.5rem;
        right: 0.5rem;
    }
}
.height-slider, .brightness-slider{
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    // opacity: 0;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: var(--secondary-color);
    border-radius: 10px;
    outline: none; /* Remove outline */
    right: -10.5rem;
    top: 0.5rem;
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); 
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); 
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
}
.comment-container{
    display: flex;
    flex-direction: column;
    .label{
        margin: 1rem 0 0.5rem;
        font-size: 18px;
        color: var(--primary-color)
    }
}
.textarea{
    border-radius: 15px;
    background: #f4f4f4;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    color: var(--primary-color)
}
.canvas-container{
    position: relative;
}
.delete-spots{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events:none;
    .delete-spot{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid var(--primary-color);
        height: 20px;
        width: 20px;
        cursor: pointer;
        z-index: 2;
        background: white;
        pointer-events:auto;
    }
}
.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 630px;
    height: 280px;
    border: 5px solid #6ACD77;
    background: #EFEFEF;
    border-radius: 25px;
    color: var(--primary-color);
    .close-btn{
        position: absolute;
        top: 7px;
        right: 7px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
    }
    h2{
        margin: 0;
        text-align: center;
    }
    button{
        border: 1px solid var(--primary-color);
        background: white;
        color: var(--primary-color);
        border-radius: 25px;
        padding: 11px 40px;
        font-size: 20px;
        margin: 2rem 0 0;
        cursor: pointer;
    }
    &.incorrect{
        border: 5px solid #F47979;
    }
}
.tries-container{
    position: absolute;
    right: 27px;
    top: 27px;
    display: flex;
    align-items: center;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 16px;
    color: var(--primary-color);
    .tries{
        margin: 0 1rem -3px;
        font-weight: 700;
    }
}
.upload-progress-bar-container{
    display: flex;
    align-items: center;
    width: 70%;
    margin:2rem auto;
    .cancel-btn{
        cursor: pointer;
        display: flex;
        margin-left: 0.25rem;
        svg{
            opacity: 0.5;
            transition: opacity 0.3s;
            &:hover{
                opacity: 1;
            }
        }
    }
}
.upload-progress{
    display: flex;
    width: 100%;
    .progress-bar{
        display: flex;
        position: relative;
        width: 100%;
        height: 2.5rem;
        border-radius: 2rem;
        border:2px solid var(--primary-color);
        overflow: hidden;
        text-align: center;
        align-items: center;
        justify-content: center;
        h3{
            z-index: 2;
            margin: 0;
            color: var(--primary-color);
        }
        span{
            background-color: var(--secondary-color);
            position: absolute;
            left: 0;
            top: 0;
            max-width: 100%;
            height: 100%;
            transition: width 0.3s ease-in-out;
        }
    }
    &.small{
        .progress-bar{
            height: 1.5rem;
        }
    }
}
.divider{
    display: flex;
    background: #747474;
    height: 2px;
    width: 100%;
    margin-bottom: 1rem;
}
.explanation-text{
    display: flex;
    margin-bottom: 1rem;
     font-size: 18px;
    color: var(--primary-color-opacity-50);
}
</style>
<style lang="scss">
.task{
    &.task-5{
      .task--text{
        p{
            padding-top: 0;
            margin-top: 0;
        }
      }
    }
}
</style>