<template>
    <div class="filter-container">
        <ConditionFilter
            v-if="additional_loaded"
            class="condition-report-container"
            :groupUserFilter="[]"
            :initial_cols="cols"
            :options="{timeframeOptions: timeframeOptions}"
            filterStyle="report"
            :selectedFilter="selectedFilter"
            @filterEvent="$emit('saveFilter', $event)"
        />
    </div>
</template>

<script>
import axios from 'axios'
import ConditionFilter from "@/components/layout/groups/ConditionFilter.vue";

export default {
    props: {
        selectedFilter: Array
    },
    components: {
        ConditionFilter
    },
    data() {
        return {
            cols: [
                {id: 1, type:'text', label: 'group.users', multiselect:true, conditions: ['is', 'is not', 'includes', 'excludes'], value: 'users', api_route: 'user/users-min', data: async (item) => this.getItemData(item)},
                { id: 1, type:'text', label: 'generic.user_activity', multiselect:true, conditions: ['is', 'is not'], value: 'user_activity', data: async (item) => this.getItemData(item) },
                { id: 1, type:'text', label: 'users.users_who_finished_trainings', multiselect:true, conditions: ['is', 'is not'], value: 'training_ended',  api_route: 'training/titles', data: async (item) => this.getItemData(item) },
                { id: 1, type:'text', label: 'users.who_rated_trainings', multiselect:false, conditions: ['>', '<'], value: 'training_rated', data: async (item) => this.getItemData(item) },

                { id: 1, type: 'text', label: 'menu.groups', value: 'groups', multiselect:true,  conditions: ['is', 'is not', 'includes', 'excludes'], api_route: 'group/groups-min', data: async (item) => this.getItemData(item) },
                {id: 1, type: 'text', label: 'menu.trainings', value: 'trainings', multiselect:true, conditions: ['is', 'is not', 'includes', 'excludes'], api_route: 'training/titles', data: async (item) => this.getItemData(item)},
                { id: 1, label: 'generic.training_status', value: 'training_status', multiselect:false, conditions: ['is', 'is not'], data: async (item) => this.getItemData(item) },
                {id: 1, type:'text', label: 'generic.tests', value: 'tests', multiselect:true, conditions: ['is', 'is not'], api_route: 'test-question-groups/tests', data: async (item) => this.getItemData(item)},
                {id: 1, type:'text', label: 'generic.test_by_training', multiselect:true, conditions: ['is', 'is not'], value: 'test_training', api_route: 'training/titles', data: async (item) => this.getItemData(item)},
                { id: 1, type:'text', label: 'generic.test_status', value: 'test_status', multiselect:false, conditions: ['is', 'is not'], data: async (item) => this.getItemData(item) },
                { id: 1, type:'number', label: 'test.final_result', value: 'test_result', multiselect:false,  conditions: ['>', '<'], data: async (item) => this.getItemData(item) },
                { id: 1, type:'text', label: 'user_side.certificates', value: 'certificates', multiselect:true, conditions: ['is', 'is not', 'includes', 'excludes'],  api_route: 'certificates/all', data: async (item) => this.getItemData(item) },
                { id: 1, type:'timeframe', label: 'certificates.expires_after',  multiselect:false, conditions: ['>', '<'], value: 'certificate_validity', data: async (item) => this.getItemData(item) },
                { id: 1, type:'text', label: 'generic.documents', value: 'documents', multiselect:true, conditions: ['is', 'is not', 'includes', 'excludes'], api_route: 'documents', data: async (item) => this.getItemData(item) },
                { id: 1, type:'timeframe', label: 'documents.expires_after', multiselect:false, conditions: ['>', '<'], value: 'documents_validity', api_route: 'documents', data: async (item) => this.getItemData(item) },
            ],
            user_activity: [
                { id: 1, label:'generic.logged_in', value: 'last_appeared_at'},
                { id: 2, label:'generic.first_login', value: 'first_login'},
                { id: 3, label:'generic.viewed_all', value: 'viewed_all_trainings'},
                { id: 4, label:'generic.viewed_nothing', value: 'none_trainings_viewed'},
            ],
            test_status: [
                {
                    id: 1, label: 'tests.test_done', value: 'done',
                },
                {
                    id: 2, label: 'tests.test_not_done', value: 'not_finished',
                },
                {
                    id: 3, label: 'tests.test_not_started', value: 'not_started',
                }
            ],
            training_status: [
                {
                    id: 1, label: 'trainings.training_done', value: 'done',
                },
                {
                    id: 2, label: 'trainings.training_not_done', value: 'not_finished',
                },
                {
                    id: 3, label: 'trainings.training_not_started', value: 'not_started',
                }
            ],
            training_rating: [
                { id: 1, label:'1 '+this.toLocal('generic.star'), value: 1},
                { id: 2, label:'2 '+this.toLocal('generic.stars'), value: 2},
                { id: 3, label:'3 '+this.toLocal('generic.stars'), value: 3},
                { id: 4, label:'4 '+this.toLocal('generic.stars'), value: 4},
                { id: 5, label:'5 '+this.toLocal('generic.stars'), value: 5},
            ],
            timeframeOptions: [
                 {
                    id: 0,
                    label: this.toLocal("generic.days_multiple"),
                    label_short: this.toLocal("generic.days_short"),
                    value: 1
                },
                {
                    id: 1,
                    label: this.toLocal("generic.weeks_multiple"),
                    label_short: this.toLocal("generic.weeks_short"),
                    value: 7
                },
                {
                    id: 2,
                    label: this.toLocal("generic.months_multiple"),
                    label_short: this.toLocal("generic.months_short"),
                    value: 30
                },
                {
                    id: 3,
                    label: this.toLocal("generic.years_multiple"),
                    label_short: this.toLocal("generic.years_short"),
                    value: 365
                },
            ],
            includable_columns: [],
            additional_user_data: [],
            additional_loaded:false,
        }
    },
    mounted(){
        this.getAdditionalCols();
        this.getAdditionalUserData();
    },
    methods: {
        async getItemData(item) {
            if (item.api_route) {
               try {
                    let res = await this.getDataListRequest(item.api_route);
                   let temp = item.value == 'groups' ? res.data : res.data.data;
                   if (['users', 'groups', 'trainings', 'training_ended', 'test_training', 'tests', 'certificates', 'documents'].includes(item.value)) {
                       temp = temp.map(element => {
                           if (item.value == 'users')
                               return { id: element.id, label: (element.name ? element.name : '') + ' ' + (element.last_name ? element.last_name : ''), value: element.id };
                            else if (['groups','tests'].includes(item.value))
                                return { id: element.id, label: element.name ? element.name : '', value: element.id };
                            else if (['trainings', 'test_training', 'training_ended','certificates', 'documents'].includes(item.value))
                                return { id: element.id, label: element.title ? element.title : '', value: element.id };
                       });
                       temp = temp.filter(element => {
                            return element.label ? element.label.length > 0 : false
                       });
                    }
                    return temp;
                } catch (err) {
                    console.error(err);
                    return [];
                }
            }
            else {
                if (item.value == 'user_activity') {
                    return this.user_activity;
                }
                else if (item.value == 'test_status') {
                    return this.test_status
                }
                  else if (item.value == 'training_status') {
                    return this.training_status
                }
                else if (item.value == 'training_rated') {
                    return this.training_rating
                }
                else if (item.type == 'text') {
                    return this.mapUserCols(item.value)
                }
                return [];
            }
        },
        mapUserCols(col_value) {
            let seen = [];
            return this.additional_user_data.filter(el => {
                if (seen.indexOf(el[col_value]) < 0) {
                    seen.push(el[col_value]);
                    return el[col_value] && el[col_value].length > 0
                }
                else return false;
            }).map(element => {
                return { id: element.id, label: element[col_value], value: element[col_value] };
            })
        },
        getDataListRequest(link){
            return axios.get(`${this.$store.state.api_link}/${link}`,
                this.$store.getters.axiosConfig()
            )
        },
        getAdditionalCols() {
            this.includable_columns = [];
            axios.get(
                `${this.$store.state.api_link}/user/users/user-cols`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if (response.data && response.data.cols && response.data.cols.length > 0) {
                    this.includable_columns = response.data.cols.map((col, index) => {
                        return { id: col.id, additional:true, label: col.name, value: col.value, type: col.type, data: async (item) => this.getItemData(item)};
                    })
                    this.cols = [ ...this.cols, ...this.includable_columns ];
                }
            }).catch((error) => {
                
            })
        },
        getAdditionalUserData() {
            this.additional_loaded = false;
            axios.get( 
                `${this.$store.state.api_link}/user/additional-data`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                
                this.additional_user_data = response.data.users
                this.additional_loaded = true;
                // let additional_cols = response.data.includable_columns;
                // let cols = [];
                // additional_cols.forEach((col, index) => {
                //     if (!cols.includes(col)) {
                //         cols.push(col);
                //         this.includable_columns.push({
                //             id: index + 1,
                //             label: 'cols.'+col,
                //             value: col
                //         })
                //     }
                // });
            }).catch((error) => {
                this.additional_loaded = true;

            });
        },
    }
}
</script>

<style lang="scss" scoped>

</style>