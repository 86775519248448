<template>
  <div class="chat-container">
      <div class="new-message-btn" @click="$emit('setSendMessageMode')">
          <svg xmlns="http://www.w3.org/2000/svg" width="32.207" height="31.3" viewBox="0 0 32.207 31.3">
            <g id="Group_11260" data-name="Group 11260" transform="translate(-385 -227.7)">
                <g id="Rectangle_3323" data-name="Rectangle 3323" transform="translate(385 235)" fill="none" stroke="#111412" stroke-width="1">
                <rect width="24" height="24" rx="5" stroke="none"/>
                <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" fill="none"/>
                </g>
                <g id="Group_11152" data-name="Group 11152" transform="translate(395 227.7)">
                <path id="Path_8221" data-name="Path 8221" d="M22.2,21.21l-1.158-5.166V16a1.843,1.843,0,0,0-.543-1.3L6.347.547A1.871,1.871,0,0,0,3.7.547L.547,3.695a1.871,1.871,0,0,0,0,2.644L14.7,20.5a1.808,1.808,0,0,0,1.328.527l5.189,1.166a.881.881,0,0,0,.353,0,.816.816,0,0,0,.515-.361.834.834,0,0,0,.127-.44.684.684,0,0,0-.02-.178M1.368,5.519a.708.708,0,0,1,0-1L4.523,1.368a.692.692,0,0,1,.5-.21.708.708,0,0,1,.5.21L6.89,2.732,2.735,6.886ZM15.144,19.3,3.556,7.707,7.711,3.552,19.3,15.14Zm3.512,1.126-2.446-.547L19.885,16.2l.587,2.628Z" transform="translate(22.207 0) rotate(90)" fill="#111412"/>
                <path id="Path_8225" data-name="Path 8225" d="M9.984,9.4a.567.567,0,0,1-.17.408.581.581,0,0,1-.821,0L.172.99a.574.574,0,0,1,0-.821.581.581,0,0,1,.821,0L9.814,8.99a.576.576,0,0,1,.17.412" transform="translate(15.756 6.456) rotate(90)" fill="#111412"/>
                </g>
            </g>
            </svg>
        <span>{{toLocal('chat_list.new')}}</span>
      </div>
      <div class="filters-container">
          <div class="search-container">
                 <div class="icon">
                   <svg v-if="searchInput == ''" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22.882" height="22.883" viewBox="0 0 22.882 22.883">
                      <defs>
                        <clipPath >
                          <rect  data-name="Rectangle 2331" width="22.882" height="22.883" fill="#28345c"/>
                        </clipPath>
                      </defs>
                      <g id="Group_7557" data-name="Group 7557" >
                        <path id="Path_6095" data-name="Path 6095" d="M.606,22.279a2.069,2.069,0,0,1,0-2.927L6.779,13.18a8.509,8.509,0,0,1-.621-1.237,8.679,8.679,0,1,1,4.781,4.781A8.248,8.248,0,0,1,9.7,16.1L3.53,22.279a2.072,2.072,0,0,1-2.923,0M9.321,3.79a6.912,6.912,0,1,0,4.886-2.024A6.911,6.911,0,0,0,9.321,3.79Z" transform="translate(0 0)" fill="#28345c"/>
                      </g>
                    </svg>
                    <svg v-else @click="searchInput='';searchUsers({target:{value: ''}})" xmlns="http://www.w3.org/2000/svg" width="17.191" height="17.191" viewBox="0 0 17.191 17.191">
                      <g id="Group_8028" data-name="Group 8028" transform="translate(-1377.29 -528.908)">
                        <g id="Group_656" data-name="Group 656" transform="translate(1385.887 530.693) rotate(45)">
                          <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                            <path id="Path_751" data-name="Path 751" d="M10.357,5.183a.558.558,0,0,1-.165.4.546.546,0,0,1-.4.169H5.743V9.8a.569.569,0,0,1-.565.561.558.558,0,0,1-.4-.165.546.546,0,0,1-.169-.4V5.748H.561a.566.566,0,0,1-.4-.961.588.588,0,0,1,.4-.169H4.613V.569a.551.551,0,0,1,.169-.4A.558.558,0,0,1,5.178,0a.571.571,0,0,1,.565.569V4.618H9.8A.572.572,0,0,1,10.357,5.183Z" transform="translate(0 0)" fill="#28345c"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                 </div>
                 <input type="text" class="search" :placeholder="toLocal('filter_modal.search_message')" @input="searchUsers" v-model="searchInput">
               </div>
          <div class="filter">
              <span>
                  {{toLocal('chat_list.displayed')}}
              </span>
              <div class="dropdown" :class="{active: activeDropdown}">
                    <div class="btn-container" @click="activeDropdown = true">
                    <span>{{selectedFilter == -1 ? toLocal('user_side.sort_by') : filter_list[selectedFilter].name}}</span>
                    <div class="dropdown-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.427" height="10.146" viewBox="0 0 18.427 10.146">
                            <path id="Path_5930" data-name="Path 5930" d="M-3723.431-17219.354a.923.923,0,0,1-.66-.271l-8.279-8.275a.94.94,0,0,1,0-1.326.932.932,0,0,1,1.32,0l7.619,7.621,7.62-7.621a.932.932,0,0,1,1.32,0,.94.94,0,0,1,0,1.326l-8.278,8.275A.924.924,0,0,1-3723.431-17219.354Z" transform="translate(3732.645 17229.5)" fill="#28345c"/>
                        </svg>
                    </div>
                    </div>
                    <div class="dropdown-list" v-if="activeDropdown" v-click-outside-3="clickOutsideSort">
                    <div 
                        class="list-item" 
                        v-for="(filter, filter_index) in filter_list" 
                        :key="filter_index"
                        @click="selectedFilter = filter_index; sortUsers()"
                        :class="{active: selectedFilter == filter_index}"
                    >
                        {{filter.name}}
                    </div>
                    </div>
                </div>
          </div>
      </div>
      <div class="user-list">
         <div
            v-for="(chat, chatIndex) in chats"
            :key="chatIndex"
            :class="['user', chat.id == selectedChat ? 'active' : '', chat.is_important ? 'important' : '', $store.state.unread_chats.includes(chat.id) ? 'unread' : '']"
            v-show="chat.user"
            @click="$emit('openChat', chat.id, chats, chats[chatIndex])"
          >
            <div 
              v-if="$store.state.unread_chats.includes(chat.id)"
              class="unread--messages" 
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24.019" height="24.419" viewBox="0 0 24.019 24.419">
                    <g id="Group_11179" data-name="Group 11179" transform="translate(0.5 0.5)">
                        <path id="Path_8307" data-name="Path 8307" d="M11.512,0c6.347,0,11.507,4.537,11.507,10.111a9.606,9.606,0,0,1-4.106,7.74l.621,4.959a.557.557,0,0,1-.221.534.426.426,0,0,1-.235.075.432.432,0,0,1-.276-.107l-4.046-3.5a13.036,13.036,0,0,1-3.25.411C5.159,20.221,0,15.684,0,10.111S5.164,0,11.512,0Z" fill="#fe8383" stroke="#111412" stroke-width="1"/>
                        <g id="Group_11178" data-name="Group 11178" transform="translate(5 8.419)">
                        <circle id="Ellipse_607" data-name="Ellipse 607" cx="1.5" cy="1.5" r="1.5" fill="#111412"/>
                        <circle id="Ellipse_608" data-name="Ellipse 608" cx="1.5" cy="1.5" r="1.5" transform="translate(5)" fill="#111412"/>
                        <circle id="Ellipse_609" data-name="Ellipse 609" cx="1.5" cy="1.5" r="1.5" transform="translate(10)" fill="#111412"/>
                        </g>
                    </g>
                </svg>
            </div> 
            <div class="user--name" v-if="chat.user">
              <img 
                src="../../../assets/images/icons/user.svg"
              >
              
              {{ chat.user.name + ' ' + (chat.user.last_name ? chat.user.last_name : '') }}
              <div class="important-status" @click="toggleImportant(chat)">
                <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0H18.5V23.5L9.5 17.5L0.5 23.5V0Z" fill="white"/>
                <path d="M0.500305 -0.5V23.281L9.48175 17.4431L18.4896 23.281V-0.5" stroke="#28345C"/>
                </svg>

              </div>

            </div>
          </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    props:{
        selectedChat:{
            type: Number,
            default: -1
        },
    },
    data(){
        return{
            chatLoading: false,
            chats: [],
            chatMessages: [],
            lastChatMessages: [],
            searchInput: '',
            activeDropdown: false,
            filter_list: [
                { id: 0, name: this.toLocal('chat_list.all_new'), key: "weight", value: "asc" },
                { id: 1, name: this.toLocal('chat_list.all_old'), key: "weight", value: "desc" },
                { id: 2, name: this.toLocal('chat_list.unread'), key: "not_read", value: "desc" },
                { id: 3, name: this.toLocal('chat_list.marked'), key: "is_important", value: "desc" },
                { id: 4, name: this.toLocal('chat_list.unanswered'), key: "certificate", value: "desc" },
            ],
            selectedFilter: -1,
            searchChats: [],
        }
    },
    mounted(){
        this.loadChats();

    },
   
    methods:{
        
        
        clickOutsideSort(){
            this.activeDropdown = false;
        },
        loadChats() {
            axios.get( 
                `${this.$store.state.api_link}/chat/chats`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.chats = response.data.data;
                this.searchChats = response.data.data;
            }).catch((error) => {
                console.log(error)
            });
        },
        searchUsers(){
            this.chats = this._.cloneDeep(this.searchChats);
            this.chats = this.chats.filter(chat=>{
                let name_last_name = chat.user.name + ' ' + (chat.user.last_name ? chat.user.last_name : '');
                return name_last_name.toLowerCase().includes(this.searchInput.toLowerCase());
            })
            this.sortUsers();
        },
        sortUsers(){
             this.chats.sort((a, b)=>{
                 if(this.filter_list[this.selectedFilter].key == 'not_read'){
                    if(this.$store.state.unread_chats.includes(a.id) && !this.$store.state.unread_chats.includes(b.id)){
                        return -1;
                    }
                    else if(!this.$store.state.unread_chats.includes(a.id) && this.$store.state.unread_chats.includes(b.id)){
                        return 1;
                    }
                    else{
                        return 0;
                    }
                 }
                 if(this.filter_list[this.selectedFilter].key == 'weight'){
                    if(a[this.filter_list[this.selectedFilter].key] > b[this.filter_list[this.selectedFilter].key]){
                        if(this.filter_list[this.selectedFilter].value == 'asc'){
                            return 1;
                        }
                        else{
                            return -1;
                        }
                    }
                    else if(a[this.filter_list[this.selectedFilter].key] < b[this.filter_list[this.selectedFilter].key]){
                        if(this.filter_list[this.selectedFilter].value == 'asc'){
                            return -1;
                        }
                        else{
                            return 1;
                        }
                    }
                    else{
                        return 0;
                    }
                 }
                if(this.filter_list[this.selectedFilter].key == 'is_important'){
                    if(a.is_important && !b.is_important){
                        return -1;
                    }
                    else if(!a.is_important && b.is_important){
                        return 1;
                    }
                    else{
                        return 0;
                    }
                }
                 if(this.filter_list[this.selectedFilter].key == 'is_answered'){
                    if(!a.is_answered && b.is_answered){
                        return -1;
                    }
                    else if(a.is_answered && !b.is_answered){
                        return 1;
                    }
                    else{
                        return 0;
                    }
                }
               
             })
        },
        toggleImportant(chat){
            this.$set(chat, 'is_important', !chat.is_important);
            axios.post( 
                `${this.$store.state.api_link}/chat/important`,
                {
                    chat: chat.id,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
              
            }).catch((error) => {
                console.log(error)
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.chat-container{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem;
    min-height: 100%;
}
.filters-container{
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin-bottom: 1rem;
    align-items: center;
    border-bottom: 1px solid var(--primary-color);
    padding-bottom: 1rem;
    @media (max-width: 1668px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .search-container{
            margin-bottom: 0.5rem;
            width: 100%;
        }

    }
}
.search-container{
      display: flex;
      background: white;
      border-radius: 1vw;
      padding: 0.25rem 0.5rem;
      height: 35px;
      input{
        background: none;
        border: none;
        padding-left: 0.5rem;
          width: 100%;
        &:focus{
          outline: none;
        }
        &::placeholder{
            color: var(--primary-color-opacity-50);
        }
      }
      .icon{
        display: flex;
        height: 100%;
        min-width: 20px;
        cursor: pointer;
        svg{
          height: 100%;
          width: 100%;
          path{
            fill: var(--primary-color);
          }
        }
      }
    }
.new-message-btn{
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 0.75rem;
    background: white;
    margin-bottom: 1rem;
    width: fit-content;
    cursor: pointer;
    svg{
        height: 100%;
    }
    span{
        color: var(-primary-color);
        margin-left: 0.5rem;
        &:hover{
            text-shadow: 0px 0 0 var(--primary-color), 0 1px 0 var(--primary-color);
        }
    }
    &:active{
        background: var(--secondary-color);
    }
}
 .user {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: .3s all;
    user-select: none;
    position: relative;
    min-height: 3rem;
    max-height: 3rem;
    color: var(--primary-color);
    
    .user--name {
        padding: 0px 20px;
        white-space: nowrap;
        height: 100%;
        display: flex;
        align-items: center;
      
      img {
        margin-right: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 3px 6px rgba(0,0,0,.2);
    }
    &.important{
         border-bottom: 4px solid var(--secondary-color);
        .important-status{
            svg{
                path{
                    fill: var(--secondary-color);
                }
            }
        }
    }
    &.active { 
        border-bottom: 4px solid var(--primary-color);
        font-weight: 700;
    }
    &.unread{
        border-bottom: 4px solid #FF5858;
    }
    .unread--messages {
         width: 22px;
        height: 22px;
        top: -7px;
        left: 1px;
        border-radius: 100%;
        position: absolute;
    }
    .user--groups {
        float: right;
      width: 240px;
      height: 60px;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      overflow: hidden;
      position: relative;
      &:hover .group {
        @for $i from 2 through 15 {
          &.g#{$i} {
            width: 10% - ($i - 1) * 4;
          }
        }
      }
      .group {
          position: absolute;
        width: 100%;
        height: 100%;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s width;
        user-select: none;
        overflow: hidden;
        color: #464646;
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        @for $i from 0 through 14 {
          &.c#{$i} {
            background-color: hsl(($i * 40 % 360), (20 + 2.5 * $i) % 70 * 1%, var(--theme-lightness));
          }
          &.g#{$i} {
            width: 100% - ($i - 1) * 10;
          }
        }
        &:hover {
          @for $i from 0 through 14 {
            &.g#{$i} {
              width: 100% - ($i - 1) * 2;
            }
          }
        }
      }
    }
    .important-status{
        position: absolute;
        right: 1.5rem;
        top: 0;
        svg{
            
        }
       
    }
  }
.filter{
    display: flex;
    align-items: center;
    span{
        font-size: clamp(12px, 1.2vw, 18px);
        margin: 0 0.5rem;
        white-space: nowrap;

    }

}
.dropdown{
    display: flex;
    position: relative;
    background: #efefef;
    border-radius: 2rem;
    justify-content: space-between;
    cursor: pointer;
    height: 35px;
    width: 100%;
    z-index: 2;
    min-width: 8rem;
    text-align: center;
    span{
        width: 100%;
        font-size: clamp(0.9em, 1.2vw, 1em);
    }
    .dropdown-icon{
        background: #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0.25rem;
        width: 25px;
        height: 25px;
        svg{
            transition: all 0.3s;
            path{
            fill: var(--primary-color);
            }
        }
    }
    .btn-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;  
        cursor: pointer;
        background: white;
        
        padding: 0.25rem 0.35rem 0.25rem 0.5rem;
        font-size: clamp(16px, 0.8vw, 18px);
        border-radius: 2rem;
        span{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            
            // min-width: 81%;
            // width: 8px;
        }
    }
    .dropdown-list{
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0.25rem;
        top: 1rem;
        padding-top: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background: white;
    // border: 2px solid white;
        border-top: none;
        z-index: -1;
        .list-item{
            font-size: clamp(0.7em, 1.1vw, 18px);
            margin: 0.5rem 0;
            &.active{
                font-weight: 700;
            }
            &:hover{
                text-shadow: 1px 0px 0px var(--primary-color);
            }
        }
    }
    &.white{
        background: white;
        .dropdown-icon{
            background: #efefef;
        }
        .dropdown-list{
            background: white;
        }
    }
    &.small{
        padding: 0.2rem 0.25rem;
        width: 4.5rem;
        span{
            text-align: center;
            font-size: clamp(0.7em, 1.1vw, 1em);
        }
    }
    &.top{
        z-index: 3;
    }
    &.bottom{
        z-index: 2;
    }
    &.active{
        padding: 0;
        border-bottom: none;
        background: none;
        border: none;
        .btn-container{
        background: white;  
        padding: 0.25rem 0.35rem 0.25rem 0.5rem;
        
        border-radius: 1rem;

        }
        svg{
        transform: rotate(180deg);
        }
    }
}
.user-list{
    display: flex;
    flex-direction: column;
    overflow: auto;
}
</style>