<template>
  <div>
    <div
      :class="['side--background', sideOpen != -1 ? 'side--open' : '']"
      v-scroll-lock="sideOpen != -1"
      @click="$emit('close')"
    />
    <section :class="{ 'group--side': true, 'side--open': sideOpen != -1 }">
      <div class="side--padding">
        <img
          class="side--padding--corner"
          src="@/assets/images/side-corner-narrow.svg"
        />
        <div class="side--padding--bottom" />
      </div>
      <div class="side--close" @click="$emit('close')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.521"
          height="36.521"
          viewBox="0 0 36.521 36.521"
        >
          <g
            id="Group_1147"
            data-name="Group 1147"
            transform="translate(-995 -94.204)"
          >
            <circle
              id="Ellipse_62"
              data-name="Ellipse 62"
              cx="18.26"
              cy="18.26"
              r="18.26"
              transform="translate(995 94.204)"
              fill="#fff"
            />
            <g
              id="Group_656"
              data-name="Group 656"
              transform="translate(1012.934 96.686) rotate(45)"
            >
              <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                <path
                  id="Path_751"
                  data-name="Path 751"
                  d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z"
                  transform="translate(0)"
                  fill="#6d66a2"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="side--content" v-if="test">
        <div class="test--title">
          <span>{{ test.name }}</span>
        </div>
        <div class="stats">
          <span>
            {{ toLocal("training_side.average_result")
            }}<strong>{{ Math.round(test.result * 100) + " %" }}</strong></span
          >
          <span
            >{{ toLocal("training_side.average_attempt_time") }}
            <strong>{{ getTestSolvingTime(test.time) }}</strong></span
          >
        </div>
        <div class="filter-container" :class="{ empty: totalPageCount == 0 }">
          <div class="filter">
            <div class="search-container">
              <div class="search-icon">
                <svg
                  v-if="searchInput == ''"
                  id="Group_10091"
                  data-name="Group 10091"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20.933"
                  height="20.934"
                  viewBox="0 0 20.933 20.934"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_2331"
                        data-name="Rectangle 2331"
                        width="20.933"
                        height="20.934"
                        fill="#28345c"
                      />
                    </clipPath>
                  </defs>
                  <g id="Group_7557" data-name="Group 7557">
                    <path
                      id="Path_6095"
                      data-name="Path 6095"
                      d="M.555,20.382a1.893,1.893,0,0,1,0-2.677L6.2,12.057a7.785,7.785,0,0,1-.568-1.131A7.94,7.94,0,1,1,10.008,15.3a7.545,7.545,0,0,1-1.13-.567L3.229,20.382a1.9,1.9,0,0,1-2.674,0M8.527,3.467A6.324,6.324,0,1,0,13,1.616a6.323,6.323,0,0,0-4.47,1.851Z"
                      transform="translate(0 0)"
                      fill="#28345c"
                    />
                  </g>
                </svg>
                <div v-else class="remove" @click="searchInput = ''">
                  <span></span><span></span>
                </div>
              </div>
              <input
                type="text"
                :placeholder="toLocal('training_stats.enter_search_text')"
                v-model="searchInput"
                @keydown.enter="getUsers"
              />
            </div>
            <div class="group-filter">
              <SelectInput
                v-model="groupFilter"
                width="10rem"
                :placeholder="toLocal('training_stats.group_filter')"
                :options="groups"
                multichoice
                :canSelectAll="false"
                label="name"
              />
            </div>
            <span class="label">{{
              toLocal("training_side.show_all_attempts")
            }}</span>
            <ToggleButton
              :toggledState="showAllStats"
              theme="gray"
              @changeState="
                showAllStats = $event;
                expandedStat = -1;
              "
            />
          </div>
        </div>
        <paginate
          v-if="searchingStats ? searchStats.length > 0 : stats.length > 0"
          v-model="page"
          :page-count="searchingStats ? totalSearchPageCount : totalPageCount"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Atgal'"
          :next-text="'Tolyn'"
          :container-class="'pagination stats m-0'"
          :page-class="'page-item'"
        >
        </paginate>
        <div
          class="grid-table"
          :class="{'with-rating': haveRating}"
          v-if="searchingStats ? searchStats.length > 0 : stats.length > 0"
        >
          <div class="table-row header">
            <div class="table-cell">
              <span class="name-cell header">
                {{ toLocal("generic.name") }}
                <img
                  v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('name')"
                />
                <img
                  v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('name')"
                />
                <img
                  v-if="orderByElement != 'name'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('name')"
                />
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{ toLocal("generic.surname") }}
                <img
                  v-if="
                    orderByElement == 'last_name' && orderByDirection == 'desc'
                  "
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('last_name')"
                />
                <img
                  v-if="
                    orderByElement == 'last_name' && orderByDirection == 'asc'
                  "
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('last_name')"
                />
                <img
                  v-if="orderByElement != 'last_name'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('last_name')"
                />
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{ toLocal("test_stats.date_of_attempt") }}
                <img
                  v-if="
                    orderByElement == 'created_at' && orderByDirection == 'desc'
                  "
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('created_at')"
                />
                <img
                  v-if="
                    orderByElement == 'created_at' && orderByDirection == 'asc'
                  "
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('created_at')"
                />
                <img
                  v-if="orderByElement != 'created_at'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('created_at')"
                />
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{ toLocal("training_stats.length_of_attempt") }}
                <img
                  v-if="orderByElement == 'time' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('time')"
                />
                <img
                  v-if="orderByElement == 'time' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('time')"
                />
                <img
                  v-if="orderByElement != 'time'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('time')"
                />
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{ toLocal("generic.result") }}
                <img
                  v-if="
                    orderByElement == 'result' && orderByDirection == 'desc'
                  "
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('result')"
                />
                <img
                  v-if="orderByElement == 'result' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('result')"
                />
                <img
                  v-if="orderByElement != 'result'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('result')"
                />
              </span>
            </div>
            <div class="table-cell" v-if="haveRating">
              <span class="header">
                {{ toLocal("training_stats.rating") }}
                <img
                  v-if="
                    orderByElement == 'rating' && orderByDirection == 'rating'
                  "
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('rating')"
                />
                <img
                  v-if="orderByElement == 'rating' && orderByDirection == 'rating'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('rating')"
                />
                <img
                  v-if="orderByElement != 'rating'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('rating')"
                />
              </span>
            </div>
          </div>
          <div
            class="table-row parent"
            v-for="(user, user_id) in stats"
            :key="user_id"
            v-show="user.user && user.test.test_end.length > 0"
          >
            <div
              v-if="!showAllStats && user.user && user.test.test_end.length > 0"
              class="table-row"
            >
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ user.user.name }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ user.user.last_name }}
                </span>
              </div>
              <div 
                class="table-cell"
              >
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ user.test.test_end[0].end.created_at_formatted ? user.test.test_end[0].end.created_at_formatted : user.test.test_end[0].end.created_at.split(" ")[0].split("T")[0] }}
                </span>
              </div>
              <div class="table-cell">
                <span>
                  <!-- {{user.training.training_end[0].end.created_at}} -->
                  {{ getTestSolvingTime(user.test.test_end[0].time) }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  >{{
                    user.test.test_end[0].result == null
                      ? ""
                      : Math.round(user.test.test_end[0].result * 100, 1) + " %"
                  }}
                </span>
              </div>
              <div class="table-cell" v-if="haveRating">
                <span
                  >{{
                     user.rating && user.rating[0] ? Math.round(user.rating[0].stat_value,2)
                      : 0
                  }}
                </span>
              </div>
              <div
                class="table-cell action"
                :class="{ active: expandedStat == user_id }"
                @click="
                  expandedStat == user_id
                    ? (expandedStat = -1)
                    : (expandedStat = user_id)
                "
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                  >
                    <g
                      id="Group_1310"
                      data-name="Group 1310"
                      transform="translate(-1802 -1226)"
                    >
                      <g
                        id="Group_1308"
                        data-name="Group 1308"
                        transform="translate(-5087.722 -162.499)"
                      >
                        <path
                          id="Path_561"
                          data-name="Path 561"
                          d="M6949.911,1424.981a29.934,29.934,0,0,1-48.021,0,29.924,29.924,0,0,1,48.021,0Z"
                          transform="translate(-0.001 0)"
                          fill="none"
                          stroke="#6d66a2"
                          stroke-miterlimit="10"
                          stroke-width="4"
                        />
                        <circle
                          id="Ellipse_33"
                          data-name="Ellipse 33"
                          cx="7.891"
                          cy="7.891"
                          r="7.891"
                          transform="translate(6918.008 1417.086)"
                          fill="none"
                          stroke="#6d66a2"
                          stroke-miterlimit="10"
                          stroke-width="4"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
              <UserTestDetails
                v-if="expandedStat == user_id && !showAllStats"
                :user_start_stat="user.test.test_end[0].start"
                :user_end_stat="user.test.test_end[0].end"
                :comment="user.comment && user.comment[0] ? user.comment[0].stat_value_text : ''"
              />
            </div>
            <div
              v-else
              class="table-row"
              :class="{ expanded: expandedStat == stat_id }"
              v-for="(stat, stat_id) in user.test.test_end"
              v-show="user.user && user.test.test_end.length > 0"
              :key="stat_id"
            >
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ user.user ? user.user.name : "" }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ user.user ? user.user.last_name : "" }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                  >{{ stat.end.created_at_formatted ? stat.end.created_at_formatted : stat.end.created_at.split(" ")[0].split("T")[0] }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                  >{{ getTestSolvingTime(stat.time) }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                  >{{
                    stat.result == null
                      ? ""
                      : Math.round(stat.result * 100, 1) + " %"
                  }}
                </span>
              </div>
              <div class="table-cell" v-if="haveRating">
                <span
                  >{{
                     user.rating && user.rating[0] ? Math.round(user.rating[0].stat_value,2)
                      : 0
                  }}
                </span>
              </div>
              <div
                class="table-cell action"
                :class="{ active: expandedStat == stat.end.id }"
                @click="
                  expandedStat == stat.end.id
                    ? (expandedStat = -1)
                    : (expandedStat = stat.end.id)
                "
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                  >
                    <g
                      id="Group_1310"
                      data-name="Group 1310"
                      transform="translate(-1802 -1226)"
                    >
                      <g
                        id="Group_1308"
                        data-name="Group 1308"
                        transform="translate(-5087.722 -162.499)"
                      >
                        <path
                          id="Path_561"
                          data-name="Path 561"
                          d="M6949.911,1424.981a29.934,29.934,0,0,1-48.021,0,29.924,29.924,0,0,1,48.021,0Z"
                          transform="translate(-0.001 0)"
                          fill="none"
                          stroke="#6d66a2"
                          stroke-miterlimit="10"
                          stroke-width="4"
                        />
                        <circle
                          id="Ellipse_33"
                          data-name="Ellipse 33"
                          cx="7.891"
                          cy="7.891"
                          r="7.891"
                          transform="translate(6918.008 1417.086)"
                          fill="none"
                          stroke="#6d66a2"
                          stroke-miterlimit="10"
                          stroke-width="4"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </div>

              <UserTestDetails
                v-if="expandedStat == stat.end.id && showAllStats"
                :user_start_stat="stat.start"
                :user_end_stat="stat.end"
                :comment="user.comment && user.comment[0] ? user.comment[0].stat_value_text : ''"
              />
            </div>
          </div>
        </div>
        <paginate
          v-if="searchingStats ? searchStats.length > 0 : stats.length > 0"
          v-model="page"
          :page-count="searchingStats ? totalSearchPageCount : totalPageCount"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="toLocal('generic.back')"
          :next-text="toLocal('generic.next')"
          :container-class="'pagination stats m-0'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<script>
import ToggleButton from "@/components/layout/emails/ToggleButton.vue";
import SelectInput from "@/components/layout/SelectInput.vue";
import UserTestDetails from "./UserTestDetails.vue";
import axios from "axios";

export default {
  name: "TrainingUserSide",
  components: {
    ToggleButton,
    SelectInput,
    UserTestDetails,
  },
  props: {
    sideOpen: {
      type: Number,
      default: -1,
    },
    test: {
      type: Object,
    },
    training_id: {
      type: Number,
    },
  },
  data: function () {
    return {
      id: "-1",
      name: "...",
      description: "...",
      cover: "",
      groups: [],
      search_groups: [],
      searching: false,
      lastSearchValue: "",
      searchLoading: false,
      loadInterval: null,
      searchInterval: null,
      orderByElement: "",
      orderByDirection: "",
      users: [],
      stats: [],
      searchStats: [],
      allStats: [],
      showAllStats: false,
      orderByDirection: "",
      exporting: false,
      searchingStats: false,
      totalSearchPageCount: 0,
      totalPageCount: 0,
      page: 1,
      searchPage: 1,
      skip: 0,
      limit: 100,
      searchSkip: 0,
      searchLimit: 100,
      user_count: 0,
      users_started: 0,
      table_user_count: 0,
      table_users_started: 0,
      allUsersIterator: 0,
      avg_result: 0,
      avg_rating: 0,
      pagesViewed: [],
      searchInput: "",
      groupFilter: [],
      groups: [],
      firstStatsLoaded: false,
      firstSearchStatsLoaded: false,
      searchMode: false,
      lastSearch: "",
      cover: "",
      expandedStat: -1,
    };
  },
  watch: {
    sideOpen() {
      if (this.sideOpen != -1) {
        this.resetData();
        this.expandedStat = -1;
        this.users = [];
        this.stats = [];
        this.getUsers();
      }
    },
    searchInput() {
      this.searchDebounce();
    },
    "groupFilter.length"() {
      this.filterByGroups();
    },
  },
  computed: {
    haveRating() {
      return this.stats.filter((u) => u.rating).length > 0
    }
  },

  methods: {
    resetData() {
      this.page = 1;
      this.skip = 0;
      this.searchSkip = 0;
      this.allStats = [];
      this.totalSearchPageCount = 0;
      this.totalPageCount = 0;
      this.searchPage = 1;
      this.limit = 100;
      this.searchSkip = 0;
      this.searchLimit = 100;
      this.user_count = 0;
      this.users_started = 0;
      this.allUsersIterator = 0;
      this.pagesViewed = [];
      this.firstStatsLoaded = false;
      this.firstSearchStatsLoaded = false;
      this.searchMode = false;
      this.lastSearch = "";
    },
    getTestSolvingTime(time_in_seconds) {
      let hours = parseInt(time_in_seconds / 3600);
      if (hours < 10) {
        hours = "0" + hours;
      }
      let minutes = parseInt(time_in_seconds / 60);
      minutes = parseInt(minutes % 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds = parseInt(time_in_seconds % 60);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return `${hours}:${minutes}:${seconds}`;
    },
    getUsers() {
      let search = this.searchInput != "" ? "&q=" + this.searchInput : "";
      if (search != "") {
        if (this.lastSearch != this.searchInput) {
          this.skip = 0;
          this.page = 1;
          this.allStats = [];
          this.allUsersIterator = 0;
          this.searchMode = true;
          this.pagesViewed = [];
          this.lastSearch = this.searchInput;
        }
      } else {
        if (this.lastSearch != this.searchInput) {
          this.resetData();
        }
        this.searchMode = false;
      }
      axios
        .get(
          `${this.$store.state.api_link}/training/stats/test/${
            this.$store.state.currentTraining
          }/${this.sideOpen}?skip=${this.skip}&limit=${
            this.limit
          }&firstLoad=${!this.firstStatsLoaded}${search}`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.stats = response.data.stats;
          this.searchStats = response.data.stats;
          this.$store.state.loading.loadingDone();
          // this.skip+=this.limit;
          if (
            (!this.firstStatsLoaded && !this.searchMode) ||
            (this.lastSearch != this.searchInput && !this.searchMode)
          ) {
            this.table_user_count = response.data.user_count;
            this.table_users_started = response.data.users_started;
            this.totalPageCount = Math.ceil(
              this.table_user_count / (this.limit / 2)
            );
            this.totalSearchPageCount = Math.ceil(
              this.table_user_count / (this.limit / 2)
            );
            if (!this.firstStatsLoaded && !this.searchMode) {
              this.user_count = response.data.user_count;
              this.users_started = response.data.users_started;
              this.groups = response.data.groups;
              this.cover = response.data.cover;
              this.avg_result = response.data.result;
              this.avg_rating = response.data.avg_rating;
            }
          }
          if (this.searchMode) {
            this.table_user_count = response.data.user_count;
            this.table_users_started = response.data.users_started;
            this.totalPageCount = Math.ceil(
              this.table_user_count / (this.limit / 2)
            );
            this.totalSearchPageCount = Math.ceil(
              this.table_user_count / (this.limit / 2)
            );
            // this.groups =response.data.groups;
          }
          if (this.allStats.length != this.table_user_count)
            this.allStats[this.table_user_count - 1] = {};
          if (this.allUsersIterator < this.table_user_count) {
            this.allStats.splice(
              this.allUsersIterator,
              response.data.stats.length,
              ...response.data.stats
            );
            this.allUsersIterator =
              this.allUsersIterator > this.table_user_count
                ? this.table_user_count
                : Math.min(
                    this.table_user_count,
                    this.allUsersIterator + response.data.stats.length
                  );
          }
          this.firstStatsLoaded = true;
          if (this.searchMode) this.firstSearchStatsLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setOrderBy(element) {
      if (element == this.orderByElement) {
        this.orderByDirection =
          this.orderByDirection == "desc" ? "asc" : "desc";
      } else {
        this.orderByElement = element;
        this.orderByDirection = "asc";
      }
      if (["name", "last_name"].includes(element)) {
        if (this.orderByDirection == "desc") {
          this.stats.sort((a, b) =>
            a.user[element] > b.user[element]
              ? -1
              : b.user[element] > a.user[element]
              ? 1
              : 0
          );
        } else {
          this.stats.sort((a, b) =>
            a.user[element] > b.user[element]
              ? 1
              : b.user[element] > a.user[element]
              ? -1
              : 0
          );
        }
      } else if (element == "created_at") {
        if (this.orderByDirection == "desc") {
          this.stats.sort((a, b) =>
            a.test.test_end[0].end[element] > b.test.test_end[0].end[element]
              ? -1
              : b.test.test_end[0].end[element] > atest.test_end[0].end[element]
              ? 1
              : 0
          );
        } else {
          this.stats.sort((a, b) =>
            a.test.test_end[0].end[element] > b.test.test_end[0].end[element]
              ? 1
              : b.test.test_end[0].end[element] >
                a.test.test_end[0].end[element]
              ? -1
              : 0
          );
        }
      } else if (["time", "result"].includes(element)) {
        if (this.orderByDirection == "desc") {
          this.stats.sort((a, b) =>
            a.test.test_end[0][element] > b.test.test_end[0][element]
              ? -1
              : b.test.test_end[0][element] > a.test.test_end[0][element]
              ? 1
              : 0
          );
        } else {
          this.stats.sort((a, b) =>
            a.test.test_end[0][element] > b.test.test_end[0][element]
              ? 1
              : b.test.test_end[0][element] > a.test.test_end[0][element]
              ? -1
              : 0
          );
        }
      }
    },
    clickCallback(pageNum) {
      if (!this.pagesViewed.includes(pageNum)) {
        this.pagesViewed.push(pageNum);
      }
      if (!this.searchingStats) {
        if (this.table_user_count > this.allUsersIterator) {
          this.skip = (pageNum - 1) * this.limit;
          this.getUsers();
        } else {
          this.stats = this.allStats.slice(
            ((pageNum - 1) * this.limit) / 2,
            (pageNum * this.limit) / 2
          );
          this.searchStats = this.allStats.slice(
            ((pageNum - 1) * this.limit) / 2,
            (pageNum * this.limit) / 2
          );
          this.filterByGroups();
        }
      } else {
        this.searchStats = this.allStats.slice(
          (pageNum - 1) * this.limit,
          pageNum * this.limit
        );
        this.filterByGroups();
      }
    },
    searchDebounce: _.debounce(function () {
      this.getUsers();
    }, 500),
    filterByGroups() {
      this.stats = this._.cloneDeep(this.searchStats);
      if (this.groupFilter.length == 0) return;
      // console.log(this.stats);
      let group_ids = this.groupFilter.map((elem) => elem.id);
      this.stats = this.stats.filter((stat) => {
        let user_groups = stat.user.hierarchy_nodes.map((elem) => elem.id);
        let arr = group_ids.filter((el) => user_groups.includes(el));
        // console.log(arr.length > 0);
        return arr.length > 0;
      });
      // console.log(this.stats);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";

.side--background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s opacity;

  &.side--open {
    pointer-events: auto;
    opacity: 1;
  }
}
.group--side {
  position: fixed;
  right: -900px;
  background-color: #fff;
  height: calc(100vh - 85px);
  top: 85px;
  width: 800px;
  z-index: 11;
  font-family: var(--main-site-font);
  transition: 0.3s right;
  z-index: 1006;

  &.side--open {
    right: 0;
  }

  &.header--disabled {
    height: 100vh;
    top: 0px;
  }
}
.side--content {
  overflow: auto;
  height: 100%;
  padding: 60px 50px;
  padding-left: 20px;
  overflow-x: hidden;
}
.side--close {
  position: absolute;
  left: -100px;
  top: 44px;
  border: transparent;
  // border-right: 20px solid #EFEFEF;
  padding: 20px;
  border-radius: 100%;
  width: 87px;
  height: 87px;
  cursor: pointer;
  user-select: none;

  svg {
    width: 47px;
    height: 47px;
    path {
      fill: var(--primary-color);
    }
  }
}

.side--padding {
  position: absolute;
  margin-left: -35px;
  margin-top: -80px;
  width: 40px;

  .side--padding--corner {
    height: 250px;
    display: block;
  }

  .side--padding--bottom {
    height: calc(100vh - 150px);
    width: 35px;
    background-color: #fff;
    margin-top: -4px;
  }
}

.group--section--select {
  margin-top: -60px;

  .group--section--select--button {
    width: 50%;
    float: left;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    user-select: none;
    background-color: $background_color;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: 0.3s box-shadow, 0.3s background-color;

    &.active,
    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background-color: $group_color;
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.test--title {
  font-size: 35px;
  padding-top: 15px;
  font-weight: 900;
  padding-left: 30px;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  .group--cover {
    width: 105px;
    height: 105px;
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    background-color: #dbdbdb;
    // background-image: url("@/assets/images/placeholders/pia21974.0.jpg");
  }

  span {
    font-size: 35px;
    padding-left: 23px;
    // display: block;
  }
}

.lesson--list {
  position: relative;
}

.side--table--control {
  margin-top: 50px;
  width: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  transition: 0.3s left;

  &.hidden {
    left: calc(-100% - 150px);

    &.search--table {
      left: calc(200% + 150px);
    }
  }

  svg {
    margin-top: -2px;
  }

  // tbody tr {
  // background-color: #efefef;
  // }

  tbody td {
    background-color: #efefef;
  }

  &.search--table {
    // tr td {
    // border-top: 1px solid #464646;
    // border-bottom: 1px solid #464646;

    // &:first-child {
    // border-left: 1px solid #464646;
    // }
    // &:last-child {
    // border-right: 1px solid #464646;
    // }
    // }
    svg {
      margin-top: -9px;
    }
  }
}

.group--add--button {
  padding: 12px;
  border-radius: 35px;
  padding-right: 35px;
  user-select: none;
  transition: 1s background-color, 0.3s transform, 0.3s box-shadow;
  // background-color: #EFEFEF;
  // margin: 0 -35px;
  margin-top: 20px;
  border: 1px solid #707070;

  .group--add--button--icon {
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 100%;
    vertical-align: middle;

    svg {
      max-width: 28px;
      max-height: 28px;
      fill: var(--primary-color);
    }
  }

  .group--add--button--title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #707070;
    width: calc(100% - 70px);

    input {
      background: transparent;
      border: none;
      outline: none;
      color: $font_color;
      font-size: 20px;
      font-family: var(--main-site-font);
      width: calc(100% - 40px);
    }
  }

  &:hover {
    box-shadow: 0px 3px 6px #00000033;
    transform: translateY(-3px);
  }
}
.stats {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  strong {
    margin-left: 5px;
  }
}
.grid-table {
  display: grid;
  grid-template-rows: auto;
  background: white;
  padding: 1rem 0;
  border-radius: 1rem;
  .table-row {
    display: grid;
    grid-template-columns: minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(
        100px,
        1.67fr
      ) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(30px, 0.8fr);
    margin: 0.25rem 0;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    background: #efefef;
    &.header {
      background: white;
      font-weight: 700;
      color: var(--primary-color);
      font-size: 16px;
    }
    &.parent {
      grid-template-columns: 1fr;
      margin: 0.25rem 0;
      padding: 0;
    }
    &.expanded {
      box-shadow: 0px 0px 2px 2px var(--primary-color-opacity-50);
    }
    .table-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 0.25rem 0px;
      border-left: 1px solid var(--primary-color);
      &:first-child {
        border-left: none;
      }
      span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        padding: 0 5px;
        margin: 0 auto;
        justify-content: center;
        &.name-cell {
          margin-left: 1.7rem;
        }
        img {
          margin: 0 0.25rem;
        }
        &.header {
          display: flex;
          white-space: normal;
        }
      }

      svg {
        height: 100%;
        width: 1.7rem;
      }
      &.action {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          display: flex;
          padding: 0.125rem 0;
        }
        svg {
          background: white;
          border-radius: 50%;
          width: 1.75rem;
          height: 1.75rem;
          border: 1px solid var(--primary-color);
          cursor: pointer;

          circle {
            stroke: var(--primary-color);
          }
          path {
            stroke: var(--primary-color);
          }
        }
        &.active {
          svg {
            background: var(--primary-color);
            //border-color: white;
            #Ellipse_33 {
              stroke: white;
            }
            path {
              stroke: white;
            }
          }
        }
      }
    }
  }
  &.with-rating{
        .table-row{
          grid-template-columns: 
          minmax(100px, 1.67fr) 
          minmax(100px, 1.67fr) 
          minmax(100px, 1.67fr) 
          minmax(100px, 1.67fr) 
          minmax(90px, 1.11fr) 
          minmax(90px, 1.11fr) 
          minmax(30px, 0.66fr);
          &.parent{
            grid-template-columns:1fr;
          }
        }
    }
}
.search-container {
  grid-area: search;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 2rem;
  color: var(--primary-color);
  padding: 0.25rem 0.5rem;
  .search-icon {
    display: flex;
    margin-right: 0.25rem;
    height: 100%;
    align-items: center;
    .remove {
      display: flex;
      position: relative;
      width: 1rem;
      height: 100%;
      cursor: pointer;
      transition: transform 0.3s;
      span {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 80%;
        height: 2px;
        background: var(--primary-color);
        border-radius: 1rem;
        transform: rotate(45deg);
      }
      span:last-child {
        transform: rotate(-45deg);
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  svg {
    path {
      fill: var(--primary-color);
    }
  }
  input {
    background: none;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 16px;
    color: var(--primary-color);
    &:focus {
      outline: none;
    }
  }
}
.filter-container {
  display: flex;
  flex-direction: column;
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 0rem;
  margin-top: 0rem;
  color: var(--primary-color);
  .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .search-container {
    background: #efefef;
    display: flex;
    width: 100%;
    max-width: 20rem;
    height: 100%;
    margin: 1rem 0;
    height: 36px;

    input {
      font-size: 16px;
    }
  }
  .group-filter {
    display: flex;
    align-items: center;
    background: #efefef;
    border-radius: 1rem;
    margin: 0 1rem;
    height: 36px;
    min-width: 14rem;
    max-width: 14rem;
  }
  .label {
    margin-right: 1rem;
  }
  &.empty {
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  flex-direction: row;

  //   background: #efefef;
  padding: 0.5rem;
  border-radius: 1.5rem;
  margin: 0;
  list-style-type: none;
  &.stats {
    border-radius: 1rem;
    flex-direction: row;
  }
  &.top {
    // margin-top:0;
    // border-top-right-radius: 0;
    // border-top-left-radius: 0;
  }
}
.page-item {
  &.active {
    background-color: var(--primary-color);
    color: white;
  }
  margin: 0 0.25rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
}
</style>
