<template>
  <div class="toolbar-container">
    <div class="img-slider-container" v-if="isEditing && !readOnly && type != 'cover'">
        <svg class="rotate" @click="rotate()" xmlns="http://www.w3.org/2000/svg" width="19.724" height="19.222" viewBox="0 0 19.724 19.222">
            <g id="Group_6929" data-name="Group 6929" transform="translate(0.555)">
                <path id="Path_7439" data-name="Path 7439" d="M1198.9,422.1a7.832,7.832,0,1,1-2.609-15.216" transform="translate(-1188.457 -403.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.063"/>
                <rect id="Rectangle_2189" data-name="Rectangle 2189" width="7.863" height="7.863" rx="1.003" transform="translate(7.342 11.325) rotate(-45)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_7440" data-name="Path 7440" d="M1196.1,404.568l2.353,2.353a.646.646,0,0,1,0,.913l-2.353,2.353a.644.644,0,0,1-1.1-.456v-4.706a.644.644,0,0,1,1.1-.456Z" transform="translate(-1187.165 -404.38)" fill="#28345c"/>
            </g>
        </svg>
        <div class="slider">
            <label for="width">{{toLocal('generic.zoom')}}</label>
            <input 
                id="width" 
                type="range" 
                novalidate 
                @input="onSliderChange" 
                :min="sliderMin" 
                :max="sliderMax" 
                step=".001" 
                v-model="sliderValue"
            >
        </div>
        <div class="slider">
            <label for="height">{{toLocal('generic.expand')}}</label>
            <input 
                id="height"
                class="height-slider" 
                type="range" 
                @input="onSliderHeightChange" 
                :min="sliderHeightMin" 
                :max="sliderHeightMax" 
                step="5" 
                v-model="sliderHeight"
            >
        </div>
    </div>
    <div class="image-manipulation" v-if="type=='cover'">
        <div class="delete-image" @click="deleteImage">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.827" height="15.827" viewBox="0 0 15.827 15.827">
                <g id="Group_6960" data-name="Group 6960" transform="translate(-224.569 -720.611)">
                    <path id="Path_7476" data-name="Path 7476" d="M0,13.705,13.706,0" transform="translate(239.335 721.672) rotate(90)" stroke-linecap="round" stroke-width="1.5"/>
                    <path id="Path_7477" data-name="Path 7477" d="M9068.63,372.378l13.705-13.705" transform="translate(-8843 363)" stroke-linecap="round" stroke-width="1.5"/>
                </g>
            </svg>
        </div>
        <div class="tool-container">
            <svg @click="rotate()" xmlns="http://www.w3.org/2000/svg" width="19.724" height="19.222" viewBox="0 0 19.724 19.222">
            <g id="Group_6929" data-name="Group 6929" transform="translate(0.555)">
                <path id="Path_7439" data-name="Path 7439" d="M1198.9,422.1a7.832,7.832,0,1,1-2.609-15.216" transform="translate(-1188.457 -403.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.063"/>
                <rect id="Rectangle_2189" data-name="Rectangle 2189" width="7.863" height="7.863" rx="1.003" transform="translate(7.342 11.325) rotate(-45)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_7440" data-name="Path 7440" d="M1196.1,404.568l2.353,2.353a.646.646,0,0,1,0,.913l-2.353,2.353a.644.644,0,0,1-1.1-.456v-4.706a.644.644,0,0,1,1.1-.456Z" transform="translate(-1187.165 -404.38)" fill="#28345c"/>
            </g>
            </svg>
        </div>
        <div class="option-container" >
            <svg @click="chooseFile" xmlns="http://www.w3.org/2000/svg" width="18.647" height="15.196" viewBox="0 0 18.647 15.196">
            <g id="Group_6932" data-name="Group 6932" transform="translate(0.4 0.4)">
                <g id="Group_6931" data-name="Group 6931" transform="translate(0 0)">
                <g id="Group_1127" data-name="Group 1127" transform="translate(5.918 3.145)">
                    <g id="Group_509" data-name="Group 509" transform="translate(0 1.763)">
                    <g id="Group_508" data-name="Group 508">
                        <path id="Path_693" data-name="Path 693" d="M270.449,1765.184v1.892a.933.933,0,0,1-.931.932h-4.56a.933.933,0,0,1-.931-.932v-1.923" transform="translate(-264.026 -1765.153)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                    </g>
                    </g>
                    <line id="Line_48" data-name="Line 48" y1="3.394" transform="translate(3.207)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                    <path id="Path_694" data-name="Path 694" d="M283.277,1750.756l.821-.821a.231.231,0,0,1,.326,0l.786.786" transform="translate(-281.057 -1749.867)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                </g>
                <g id="Group_1128" data-name="Group 1128" transform="translate(0 0)">
                    <rect id="Rectangle_297" data-name="Rectangle 297" width="17.847" height="10.806" rx="1" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                    <rect id="Rectangle_298" data-name="Rectangle 298" width="5.612" height="2.405" transform="translate(6.239 10.806)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                    <rect id="Rectangle_299" data-name="Rectangle 299" width="8.331" height="1.15" rx="0.575" transform="translate(4.88 13.246)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                </g>
                </g>
            </g>
            </svg>
       
            <template>
                <slot name="small-icon">

                </slot>
            </template>
        </div>
    </div>
    <div v-if="type=='cover'" class="img-slider-container cover">
        <input type="range" novalidate @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".01" v-model="sliderValue">
    </div>
  </div>
</template>

<script>
export default {
    props:{
        isEditing: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
        },
        sliderVal: {
            type: Number | String,
            default: 0
        },
        sliderHeightVal: {
            type: Number | String,
            default: 0
        },

    },
    mounted(){
        this.sliderHeight = this.sliderHeightVal;
        this.sliderValue = this.sliderVal;
    },
    data(){
        return{
            sliderBrightnessMin: -100,
            sliderBrightnessMax: 100,
            sliderMin: 0.1,
            sliderMax: 5,
            sliderHeightMin: 50,
            sliderHeight: 0,
            sliderValue: 0,
            sliderHeightMax: window.innerHeight*0.88,
        }
    },
    methods:{
        onSliderChange(e){
           this.$emit('sliderChange', e)
        },
        onSliderHeightChange(e){
            this.$emit('sliderHeightChange', e)
        },
        rotate(){
            this.$emit('rotate')
        },
        deleteImage(){
            this.$emit('deleteImage');
        },
        chooseFile(){
            this.$emit('chooseFile');
        }
    }
}
</script>

<style lang="scss" scoped>
.img-slider-container{
    opacity: 1;
    width: 95%;
    min-width: 95%;
    max-width: 100%;
    gap: 15px;
    margin: 0;
    top: 0;
    margin-bottom: 1rem;
    .slider{
        width:100%;
        max-width: 44.7%;
        label{
            font-size: 12px;
            padding-left: 6px;
            color: var(--primary-color);
        }
        input{
            margin-top: 7px;
        }
    }
    .rotate{
        min-width: 20px;
        display: flex;
        min-height: 100%;
        align-self: flex-end;
        user-select: none;
    }
    &.cover{
        position: absolute;
        bottom: 0px;
        height: 20px;
        top: initial;
        width: 80%;
        margin: auto;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s;
    }
}
.img-slider-container{
    svg{
        path{
            stroke: var(--primary-color);
        }
    }
}
.height-slider, .brightness-slider{
    position: initial;
    transform: none;
    opacity: 1;
}

.img-slider-container, .height-slider-container{
  display:flex;
   width: 100%;

  bottom: 0.5rem;
  transition: opacity 0.3s ease;
  cursor:pointer;
  input{
     -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: var(--primary-color-opacity-50);
    border-radius: 10px;
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
  }
}
.img-slider-container{
    margin: 23px 20px 20px 20px;
    flex-wrap: wrap;
    position: relative;
}
.height-slider-container{
    bottom: 0.5rem;
    right: 0.5rem;
    input{
        bottom: 0.5rem;
        right: 0.5rem;
    }
}
.height-slider, .brightness-slider{
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    // opacity: 0;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: var(--primary-color-opacity-50);
    border-radius: 10px;
    outline: none; /* Remove outline */
    right: -10.5rem;
    top: 0.5rem;
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); 
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); 
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
}
  .image-manipulation{
        display: flex;
        height: 30px;
        max-width: 130px;
        flex:3;
        background: #efefef;
        padding: 5px;
        border-radius: 6px;
        position: absolute;
        bottom: 35px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s;
        svg{
            height: 20px;
            width: 20px;
            path, line, rect{
                stroke: var(--primary-color);
            }
        }
        .delete-image{
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            border-right: 2px solid var(--primary-color);
            cursor: pointer;
            svg{
                height: 18px;
                width: 18px;
                fill: none;
                stroke: var(--primary-color);
            }
        
        }
  }
  .tool-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex:1;
    padding: 0;
    border-right: 2px solid var(--primary-color);
    cursor: pointer;
  }
  .option-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex:1;
    cursor: pointer;

  }
</style>