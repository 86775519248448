<template>
    <div class="subtitles-container">
        <div class="switch" @click="toggleSubtitles" :class="{disabled: subtitleList.length == 0}">
        <span>{{ captionsToggled ? toLocal('video.subtitles_on') : toLocal('video.subtitles_off') }}</span> 
        <div class="switch-box" :class="{active: captionsToggled}">
          <span class="slider" :class="{active: !captionsToggled}"></span>
        </div>
      </div>
        <div class="languages-container" ref="languagesContainer" v-if="subtitleList.length > 0">
          <div class="list-item" :class="{active: currentLanguage == lang.languageCode}" :key="'lang-'+lang.displayName" v-for="lang in subtitleList" @click="setLanguage(lang.languageCode)">
          {{lang.displayName}}</div>
        </div>
        <div v-else class="languages-container">
          <div class="list-item disabled">
            {{ toLocal('video.no_subtitles') }}
          </div>
        </div>
    </div>
</template>

<script>
    export default {
  name: 'Subtitles',
  props: ['subtitleList', 'captionsToggled', 'player'],
  data() {
    return {
      currentLanguage: 'en',
    }
  },
  methods:{
    setLanguage(code){
      this.player.tech_.ytPlayer.setOption("captions", "track", {"languageCode": code});
      this.currentLanguage = code;
    },
    toggleSubtitles(){
      this.$emit('toggleSubtitles');
    }
  }
}
</script>

<style lang="scss" scoped>

.subtitles-container{
  width: 150px;
  max-height: 150px;
  background-color: rgba(43, 51, 63, 0.8);
  position: absolute;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  transform-origin: bottom;
  .switch{
    width: 100%;
    font-size: 14px;
    color: white;
    min-height: 25px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.25s;
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    &:not(.disabled){
      &:hover{
      background-color: rgba(43, 51, 63,1);
      }
    }
    &.disabled{
      color: rgb(108, 108, 108);
      cursor: default;
      background-color: rgba(43, 51, 63, 0.7);
    }
    .switch-box{
      width: 20%;
      height: 12px;
      border-radius: 13px;
      display: flex;
      align-items: center;
      transition: 0.25s;
      position: relative;
      background-color: #717171;
      .slider{
        width: 16px;
        height: 16px;
        display: flex;
        border-radius: 50%;
        position: absolute;
        transition: 0.25s;
        left: 50%;
        background-color: white;
        &.active{
          transform: translateX(-100%);
          background-color: rgb(194, 193, 193);
        }
      }
    }
  }
  .languages-container{
    max-height: 85%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    .list-item{
      box-sizing: border-box;
      color: white;
      cursor: pointer;
      font-size: 12px;
      transition: 0.25s;
      padding: 4px;
      &:not(.active){
        &:hover{
        background-color: rgba(43, 51, 63, 1);
        }
        &.disabled{
          cursor: default;
          color: rgb(125, 125, 125);
          background-color: rgba(43, 51, 63, 0.7);
          &:hover{
            background-color: rgba(43, 51, 63, 0.7);
          }
        }
      }
      &.active{
        background-color: rgba(43, 51, 63, 1);
      }
    }
  }
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 150px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>