<template>
  <div class="embed--container" v-html="embed_data">

  </div>
</template>

<script>
export default {
    name: 'Embed',
    data(){
        return{
            embed_data: '',
        }
    },
    props: ['data', 'script'],
    watch:{
        data(newdata, olddata){
            this.embed_data = newdata;
        },
        script(newData, oldData){
            let script= document.createElement('script')
            script.setAttribute('src', newData)
            document.head.appendChild(script)
        }
    },
    mounted(){
        this.embed_data = this.data;
        let script= document.createElement('script')
        script.setAttribute('src', this.script)
        document.head.appendChild(script)
    }

}
</script>

<style lang="scss" scoped>
.embed--container{
    display: flex;
    justify-content: center;
    word-break: break-all;
    max-height: initial !important;
}
blockquote{
    overflow: auto;
}
</style>