<template>
  <div class="tab-container">
    <div 
        v-if="!isMobileWindow()"

        class="settings--group--select"
      >
        <div 
            v-for="(tab, index) in shownTabs" 
            :key="index"
            class="settings--group--button"
            :class="{active: settings_page == tab.name, light: isLight }"
            @click="changeSettingsTab(tab.name)"
        >
            
            {{ toLocal(tab.label) }}
        </div>
      </div>
      <div v-else class="mobile-tabs">
        <div 
            class="tab" 
            v-for="(tab, index) in shownTabs"
            :class="{active: settings_page == tab.name, light: isLight }"
            :key="index"
          
        >
            <div class="tab-container">
                <div class="tab-header" @click="changeSettingsTab(tab.name)">
                    <span>
                        {{ toLocal(tab.label) }}
                    </span>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 11.4419C0.688981 11.1979 0.688981 10.8021 0.933058 10.5581L5.49112 6L0.933058 1.44194C0.68898 1.19786 0.68898 0.802136 0.933058 0.558059C1.17714 0.313981 1.57286 0.313981 1.81694 0.558059L6.81694 5.55806C7.06102 5.80214 7.06102 6.19786 6.81694 6.44194L1.81694 11.4419C1.57286 11.686 1.17714 11.686 0.933058 11.4419Z" fill="#B2B2B2"/>
                    </svg>
                </div>
            </div>
            <div class="content-container" v-if="settings_page == tab.name">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        settings_page: {
            type: [String, Number],
            default: 'user'
        },
        showBillTab:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            tabs:[
                {
                    name: 'userInfo',
                    label: 'settings.user_info',
                    permissions: 'any',
                },
                {
                    name: 'user',
                    label: 'settings.personal',
                    permissions: 'any'
                },
                {
                    name: 'bills',
                    label: 'settings.billings',
                    permissions: 'admin'
                },
                {
                    name: 'system',
                    label: 'settings.system',
                    permissions: 'admin'
                },
                {
                    name: 'stats',
                    label: 'settings.server_stats',
                    permissions: 'admin'
                },
            ]
        }
    },

    computed:{
        isLight(){
            let color = document.documentElement.style.getPropertyValue(`--secondary-color`);
            return color;
        },
        shownTabs(){
            return this.tabs.filter(tab => {
                if(tab.name == 'bills' && !this.showBillTab){
                    return false;
                }
                if(tab.permissions == 'any'){
                    return true;
                }else{
                    return this.$store.getters.hasPermission('training.*');
                }
            })
        }
    },
    methods:{
        changeSettingsTab(tab) {
            this.$emit('tabChange', tab);
        },
       
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_settingsViewScoped.scss";
.settings--group--button{
    max-width: 320px;
    border-radius: 8px 8px 0 0;
    user-select: none;
    font-size: clamp(14px, 1.5vw, 20px);
    white-space: nowrap;
    &.active, &:hover {
        &.light{
            color: var(--primary-color);
        }
    }
}
.tab-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 8px;
    background-color: #FFFFFF;
    font-size: 18px;
    color: #000;
    border-radius: 6px;
    //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    max-width: 62.5%;
    min-width: 225px;
    cursor: pointer;
    
}
.tab{
    margin: 12px 0;
    &.active{
        .tab-header{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: var(--secondary-color);
            svg{
                path{
                    fill: #000;
                }
                transform: rotate(90deg);
            }
        }
    }
}
</style>