<template>
  <section name="training" v-if="training.content" ref="trainingSection">
    <div :class="['page--up', pageUp ? 'active' : '']" @click="goUp">
      <TrainingEditArrowUpSvg id="Ellipse_158" />
    </div>
    <div
      :class="['eye--floating', pageUp ? 'active' : '']"
      @click="openTraining"
    >
      <TrainingEditEyeSvg class="training-eye-icon" />
      <!-- <TrainingSideCorner/> -->
    </div>
    <div v-if="alertMessage != ''" class="alert--inner">
      {{ alertMessage }}
    </div>
    <GallerySide
      v-if="imageGalleryOpen"
      @returnPickedGif="galleryImageClick"
      @closeGallery="imageGalleryOpen = false"
    />
    <!-- <div :class="['gallery--side', imageGalleryOpen ? 'active' : '']">
      <div v-click-outside="closeGallery" class="gallery">
        <div class="side--padding">
          <TrainingSideCornerSvg class="side--padding--corner" />
          <div class="side--padding--bottom" />
        </div>
        <div class="side--close" @click="closeGallery">
          <TrainingSideCloseSvg />
        </div>
        <h3>
          {{ toLocal("training_edit.gallery") }}
        </h3>
        <div class="images">
          <div
            v-for="img in galleryFiles"
            :key="`image-${img.id}`"
            :style="[
              {
                'background-image': `url('${$store.state.parent_link}/storage/files/${
                  img.filename
                }')`,
              },
            ]"
            class="image"
            @click="galleryImageClick(`${$store.state.parent_link}/storage/files/${
                  img.filename
                }`)"
          />
        </div>
      </div>
    </div> -->
    <div :class="['gallery--side', videoGalleryOpen ? 'active' : '']">
      <div v-click-outside="closeGallery" class="gallery">
        <div class="side--padding">
          <TrainingSideCornerSvg class="side--padding--corner" />
          <div class="side--padding--bottom" />
        </div>
        <div class="side--close" @click="closeGallery">
          <TrainingSideCloseSvg />
        </div>
        <h3>
          {{ toLocal("training_edit.gallery") }}
        </h3>
        <div class="videos">
          <!--Kodel sitas loadinasi kartu su paveiksliuku gallery? cia jauciu kazkaip kitaip reiktu, for now uzdejau ta v-if-->
          <video
            v-for="video in galleryFiles"
            :key="video"
            class="video"
            @click="galleryVideoClick(video)"
            v-if="video.url == undefined"
          >
            <source
              :src="$store.state.api_uploads_link + video"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
    <div :class="['gallery--side notes--side', notesOpen ? 'active' : '']">
      <div v-click-outside="closeNotes" class="gallery">
        <div class="side--padding">
          <TrainingSideCornerSvg class="side--padding--corner" />
          <div class="side--padding--bottom" />
        </div>
        <div class="side--close" @click="closeNotes">
          <TrainingSideCloseSvg />
        </div>
        <h3>
          {{ toLocal("training_edit.notes") }}
        </h3>
        <textarea v-model="notes" class="notes--textarea" />
      </div>
    </div>
    <div
      :class="[
        'gallery--side certificate--side',
        certificateOpen ? 'active' : '',
      ]"
      v-scroll-lock="certificateOpen"
    >
      <div v-click-outside="closeCertificate" class="gallery">
        <div class="side--padding">
          <TrainingSideCornerSvg class="side--padding--corner" />
          <div class="side--padding--bottom" />
        </div>
        <div class="side--close" @click="closeCertificate">
          <TrainingSideCloseSvg />
        </div>
        <div class="cert-container">
          <h3>
            {{ toLocal("training.certificate") }}
          </h3>
          <div
            v-if="
              certificates &&
              training.certificate &&
              training.certificate.background != undefined &&
              training.certificate.background != null &&
              selectedCert != null
            "
            :class="[
              'certificate',
              'c' + training.certificate ? training.certificate.background : '',
            ]"
            :style="{
              'background-image': `url('${$store.state.api_assets_link}${selectedCert.preview_path ? selectedCert.preview_path : selectedCert.path}')`,
            }"
          >
            <div class="name" v-if="selectedCert.show_preview">
              {{ toLocal("training_edit.certificate_name_placeholder") }}
            </div>
            <div class="finished" v-if="selectedCert.show_preview">
              {{ toLocal("training.certificate_finished") }}
            </div>
            <div class="title" v-if="selectedCert.show_preview">
              {{ training.title }}
            </div>
            <div class="date" v-if="selectedCert.show_preview">
              {{ moment() }}
            </div>
            <!-- <textarea 
           v-if="training.certificate && training.certificate.description"
            v-model="training.certificate.description"
            class="description" 
          /> -->
          </div>
          <div class="expiration--select--wrapper" v-if="training.certificate">
            <span>
              {{ toLocal("training_edit.certificate_expiration") }}
            </span>
            <div
              v-if="training.certificate != undefined"
              :class="[
                'expiration--toggle',
                {
                  active: training.certificate
                    ? training.certificate.expires
                    : '',
                },
              ]"
              @click="
                $set(
                  training.certificate,
                  'expires',
                  training.certificate.expires != undefined
                    ? !training.certificate.expires
                    : false
                )
              "
            >
              <div>
                <TrainingInfinitySvg />
              </div>
              <div>
                {{ toLocal("training_edit.certificate_expiration_month") }}.
              </div>
            </div>
            <span v-if="training.certificate && training.certificate.expires">
              {{ toLocal("training_edit.certificate_expiration_time") }}:
              <input
                v-model="training.certificate.expiration"
                type="number"
                max="96"
                min="1"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              />
              <b
                >{{ toLocal("training_edit.certificate_expiration_month") }}.</b
              >
            </span>
            <span v-if="training.certificate && !training.certificate.expires">
              {{ toLocal("training_edit.certificate_expiration_time") }}
              <b>{{
                toLocal("training_edit.certificate_expiration_infinte")
              }}</b>
            </span>
          </div>
          <div
            class="background--select--wrapper"
            v-if="training.certificate && certificates"
          >
            <div
              v-for="(cert, cert_index) in certificates"
              :key="cert_index"
              class="background--select"
              :style="{
                'background-image': `url('${$store.state.api_assets_link}${cert.path}')`,
              }"
              @click="setCertificateBackground(cert.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      ref="mainContainer"
      :class="{
        'container main--container training--briefing training': true,
        'side--open': $store.getters.isSideOpen(),
      }"
    >
      <div class="training--side" >
        <span class="go-back" @click="goBack"
          ><ArrowLeftSvg /> {{ toLocal("generic.back") }}</span
        >
        <div class="training--card">
          <input
            ref="trainingCardCover"
            accept=".jpeg,.jpg,.png"
            type="file"
            style="display: none"
            @change="onTrainingCardCoverChange($event)"
          />
          <div
            :class="[
              'training--card--cover',
              training.cover != '' ? 'has--image' : '',
            ]"
            :style="[
              training.cover != ''
                ? { 'background-image': `url('${getFileUrl(training.cover)}')` }
                : {},
            ]"
          >
            <div class="action-container">
              <div
                class="icon-container"
                @click="$refs[`trainingCardCover`].click()"
              >
                <TrainingItemUploadSvg />
              </div>
              <div class="icon-container" @click="openImageGallery(-2, -2)">
                <TrainingItemGallerySvg />
              </div>
            </div>
          </div>
          <div class="training--card--actions">
            <TrainingViewEyeSvg @click="openTraining" class="stroke" />
            <TrainingEditCopySvg @click="dublicateTraining" class="stroke" />

            <TrainingEditTrashSvg @click="deleteTraining" class="stroke" />
          </div>
          <div class="progress-bar" v-if="isUploadingCover">
            <span class="bar">
              <span
                class="progress"
                :style="{ width: $store.state.uploadProgress + '%' }"
              ></span>
            </span>
          </div>
        </div>
        <div class="publish-container" v-if="!['attest'].includes(training.trainingType)">
          <button
            @click="publishingTrainingModal = true"
            :class="{ active: training.published }"
          >
            {{
              training.published
                ? toLocal("training_edit.dont_publish")
                : toLocal("training_edit.publish")
            }}
          </button>
        </div>

        <!-- <div
          class="slider-toggle"
          :class="{ doc: training.trainingType == 'doc' }"
          @click="
            training.trainingType == undefined
              ? $set(training, 'trainingType', 'doc')
              : training.trainingType == 'doc'
              ? $set(training, 'trainingType', 'training')
              : $set(training, 'trainingType', 'doc')
          "
        >
          <div class="text">
            {{ this.toLocal("trainingEdit.training") }}
          </div>
          <div class="text">
            {{ this.toLocal("trainingEdit.document_folder") }}
          </div>
          <div class="toggle-btn"></div>
        </div> -->
        <CustomDropdown
                :list="$store.state.system_settings.trainingTypes"
                key-label="key"
                value-label="value"
                class="training-type-dropdown"
                :compareChosen="$store.state.system_settings.trainingTypes.find(item => item.key == training.trainingType) ? $store.state.system_settings.trainingTypes.find(item => item.key == training.trainingType) : $store.state.system_settings.trainingTypes[0]"
                :options="{
                    closeOnClick: true,
                    checkbox: false,
                    customDDWidth: 'initial',
                    customDDMaxWidth: '250px',
                    customDDMinWidth: '100%',
                    customMaxHeight: '300px',
                    initial: trainingTypeInitialText,
                    containerClass: 'condition-filter-dropdown '+getContrast(),
                    searchBar: false,
                    left:true,
                    showCreateNewBtn: false,
                    valueWidthType: 'dynamic'
                }"
                @elClicked="setTrainingType($event)"
            ></CustomDropdown>
        <div class="training--groups">
          <span class="text-training">{{
            toLocal("training_edit.training_group")
          }}</span>
          <div class="add--training--group">
            <v-select
              ref="addingTrainingGroupInput"
              v-model="selectedTrainingGroup"
              label="name"
              :options="allTrainingGroups"
              @input="trainingGroupChange"
            />
          </div>
        </div>
        <div class="deactivation-time-edit">
          <span class="long">{{ toLocal("user_side.deactivate_after") }}</span>
          <date-picker
            v-model="disableDate"
            label="date"
            :placeholder="toLocal('users_side.select_date')"
            class="secondary-calendar"
            type="date"
            valueType="format"
            @change="handleDisableDate"
          >
            <template v-slot:icon-calendar> </template>
          </date-picker>
        </div>
        <div class="languages-btn-container">
          <div class="languages-btn" @click="showLanguagesSection = true">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 0C4.98583 0 0.5 4.48583 0.5 10C0.5 15.5142 4.98583 20 10.5 20C16.0142 20 20.5 15.5142 20.5 10C20.5 4.48583 16.0136 0 10.5 0ZM5.8274 10C5.8274 8.75735 5.97854 7.56815 6.24251 6.48637H9.89541V13.5142H6.24251C5.97854 12.4318 5.8274 11.2432 5.8274 10.0005V10ZM11.1046 1.3641C12.4665 1.71012 13.6628 3.19514 14.3996 5.27665H11.1046V1.3641ZM9.89541 1.3641V5.27665H6.60041C7.33671 3.19514 8.53347 1.71012 9.89541 1.3641ZM9.89541 14.7228V18.6354C8.53347 18.2893 7.33725 16.8043 6.60041 14.7228H9.89541ZM11.1046 18.6354V14.7228H14.3996C13.6628 16.8043 12.4665 18.2893 11.1046 18.6354ZM11.1046 13.5136V6.48583H14.7575C15.0215 7.56815 15.1726 8.75681 15.1726 9.99946C15.1726 11.2421 15.0215 12.4313 14.7575 13.5131H11.1046V13.5136ZM16.0088 6.48583H18.5529C19.0247 7.56329 19.2908 8.75034 19.2908 9.99946C19.2908 11.2486 19.0247 12.4362 18.5529 13.5131H16.0088C16.2463 12.4238 16.3818 11.2443 16.3818 9.99946C16.3818 8.75466 16.2463 7.57463 16.0088 6.48583ZM17.9024 5.27665H15.6951C15.2741 3.9336 14.6733 2.78974 13.9467 1.91417C15.5796 2.61323 16.954 3.79541 17.9024 5.27665ZM7.05331 1.91417C6.32672 2.7892 5.72645 3.9336 5.30486 5.27665H3.09757C4.04602 3.79541 5.42038 2.61323 7.05331 1.91417ZM2.4471 6.48583H4.99123C4.75371 7.57517 4.61822 8.75466 4.61822 9.99946C4.61822 11.2443 4.75371 12.4243 4.99123 13.5131H2.4471C1.9753 12.4356 1.70918 11.2486 1.70918 9.99946C1.70918 8.7498 1.97476 7.56275 2.4471 6.48583ZM3.09757 14.7228H5.30486C5.72591 16.0659 6.32672 17.2097 7.05331 18.0853C5.42038 17.3862 4.04602 16.204 3.09757 14.7228ZM13.9467 18.0853C14.6733 17.2103 15.2735 16.0659 15.6951 14.7228H17.9024C16.954 16.204 15.5796 17.3862 13.9467 18.0853Z"
                fill="#333333"
              />
            </svg>
            <span>
              {{ toLocal("training.link_language") }}
            </span>
          </div>
        </div>
      </div>
      <div class="vertical--border" />
      <TrainingMain
        :training="training"
        :showLanguagesSection="showLanguagesSection"
        :siblingTrainings="siblingTrainings"
        @gotoTopic="gotoTopic"
        @openCertificate="openCertificate"
        @openNotes="openNotes"
        @onMediaChange="onTrainingMediaChange"
        @closeLanguageSection="showLanguagesSection = false"
        @showBanner="
          showInfoBanner = true;
          showInfoBannerText = 'training_edit.shop_info_modal';
        "
      />
    </div>
    <TrainingSideNavigation
      v-if="training.content"
      :training="training"
      :scrolledTopic="scrolledTopic"
      @gotoTopic="gotoTopic"
    />
    <div
      v-if="training.content && !['scorm', 'singleDoc'].includes(training.trainingType)"
      :class="[
        'training--side',
        lockedTrainingSide ? 'training--side--locked' : '',
        {
          'test-select-mode-selectable-absolute':
            training.content[scrolledTopic] &&
            training.content[scrolledTopic].test &&
            $store.state.selectTestsModeActive,
        },
      ]"
      :style="{
        transform:
          'translateY(' + (lockedTrainingSide ? 0 : sideTrainingScroll) + 'px)',
        left: trainingSideX + 'px',
      }"
      @dragend="draggingDroplet = false"
    >
      <div
        v-if="
          training.content && !['attest'].includes(training.trainingType) && 
          (!training.content[scrolledTopic] ||
            (training.content[scrolledTopic] &&
              !training.content[scrolledTopic].test))
        "
        class="droplet-container"
      >
        <div
          v-for="droplet in droplets"
          :key="droplet.title"
          class="droplet"
          draggable
          @dragstart="startDrag($event, droplet)"
        >
          <div class="droplet--image">
            <img :src="droplet.image" />
          </div>
          <span>{{ droplet.title }}</span>
        </div>
        <div
          v-for="droplet in dropletsSection2"
          :key="droplet.title"
          class="droplet"
          draggable
          @dragstart="startDrag($event, droplet)"
        >
          <div class="droplet--image">
            <img :src="droplet.image" />
          </div>
          <span>{{ droplet.title }}</span>
        </div>
        <div
          v-for="droplet in dropletsSection3"
          :key="droplet.title"
          class="droplet"
          draggable
          @dragstart="startDrag($event, droplet)"
        >
          <div class="droplet--image">
            <img :src="droplet.image" />
          </div>
          <span>{{ droplet.title }}</span>
        </div>
      </div>
      <TestSideList
        v-if="testsLoaded"
        :training="training"
        :lastSavedTest="lastSavedTest"
        :activeTest="
          training.content[scrolledTopic]
            ? training.content[scrolledTopic].questions
            : 0
        "
        :testQuestions="availableTestQuestions"
        :scrolledTopic="scrolledTopic"
        @setTestData="
          (test, folder_id) =>
            setSelectedTestData(scrolledTopic, test, folder_id)
        "
        @setSearchTestQuestions="testQuestions = $event"
      />
    </div>
    <!-- <div 
      :class="['vertical--border', lockedTrainingSide ? 'vertical--border--locked': '', training.content[scrolledTopic] && training.content[scrolledTopic].test ? 'test' : '']"
      :style="{ transform: 'translateY('+(lockedTrainingSide ? 0 : sideTrainingScroll)+'px)', left: training.content[scrolledTopic] && training.content[scrolledTopic].test ? trainingSideX + 330 + 'px' : (trainingSideX + 270)+'px'}"
      v-if="training.content[scrolledTopic] && !training.content[scrolledTopic].test"
    /> -->
    <div
      v-for="(trainingTopic, trainingTopicIndex) in training.content"
      :id="`trainingTopic${trainingTopicIndex}`"
      :key="trainingTopicIndex"
      v-show="!['scorm', 'singleDoc'].includes(training.trainingType) && (['attest'].includes(training.trainingType) ? trainingTopic.test : true)"
      :class="[
        'training--topic',
        trainingTopic.enabled ? '' : 'training--topic--disabled',
        draggingDroplet ? 'dragging' : '',
        trainingTopic.test &&
        trainingTopicIndex == scrolledTopic &&
        $store.state.selectTestsModeActive
          ? 'test-select-mode-selectable'
          : '',
      ]"
    >
      <div
        :ref="`thead_${trainingTopicIndex}`"
        class="training--header"
        :class="{
          'test-select-filter':
            trainingTopic.test &&
            trainingTopicIndex == scrolledTopic &&
            $store.state.selectTestsModeActive,
        }"
        :style="[{ width: headerWidth + 'px' }]"
        v-if="!['scorm', 'singleDoc'].includes(training.trainingType)"
      >
        <div class="training--header--inner">
          <div class="training--header--number">
            {{ ['attest'].includes(training.trainingType) ? 1 : trainingTopicIndex + 1 }}
          </div>
          <h2
            v-if="!trainingTopic.editingTitle"
            :style="[
              {
                fontSize:
                  trainingTopic.title.length > 30
                    ? '23px'
                    : isBiggerMonitor
                    ? '45px'
                    : '36px',
              },
            ]"
            @click="editTopicTitle(trainingTopicIndex)"
          >
            {{ ['attest'].includes(training.trainingType) ? toLocal("training.attesting") :  trainingTopic.title }}
          </h2>
          <input
            v-if="trainingTopic.editingTitle"
            :ref="`trainingTopic-${trainingTopicIndex}`"
            v-click-outside-editing-title="undefined"
            class="topic--editing"
            type="text"
            @keyup.enter="
              setTopicTitle(trainingTopicIndex, $event.srcElement.value)
            "
          />
        </div>
      </div>
      <div
        :class="{
          'container main--container training': true,
          'side--open': $store.getters.isSideOpen(),
        }"
      >
        <div
          :class="[
            'training--main',
            { transparent: training.content[trainingTopicIndex].test },
          ]"
        >
          <div
            :class="[
              'content--item',
              'content--item--add',
              showStartDragArea(trainingTopicIndex) ? 'dragging' : '',
              isDragAreaActive(trainingTopicIndex, -1, 'bottom')
                ? 'active'
                : '',
            ]"
            @drop="onDrop($event, -1, trainingTopicIndex, 'full')"
            @dragover="onDragOver($event, trainingTopicIndex, -1, 'full')"
            @dragleave="onDragLeave(trainingTopicIndex, -1, 'full')"
            @dragenter.prevent
          >
            <div class="content--plus">
              <TrainingEditPlusNarrowWhiteSvg />
            </div>
          </div>
          <training-test
            v-if="
              training.content[trainingTopicIndex].test &&
              availableTests &&
              trainingTopic.selectedTest
            "
            :key="`${trainingTopic.selectedTest}-${selectedTestID}`"
            :content="training.content"
            :topic="trainingTopic"
            :parentTest="parentTest(trainingTopic)"
            :topicIndex="trainingTopicIndex"
            :scrolledTopic="scrolledTopic"
            :folders="availableTestQuestions"
            :availableTestQuestions="availableTestQuestions"
            @disableDrag="disableItemDrag2 = $event"
            @on-training-media-change="onTrainingMediaChange"
            @save-metadata="saveMetadata"
            @saveTest="saveTestData"
            @activeParentVersion="$set(trainingTopic, 'questions', $event)"
          />
          <draggable
            v-if="!trainingTopic.test"
            v-model="trainingTopic.items"
            animation="200"
            :options="{
              disabled:
                disableItemDrag || disableItemDrag2 || $store.state.stopDrag,
            }"
            @start="onTrainingItemDragStart"
            @end="onTrainingItemDrag"
          >
            <transition-group type="transition">
              <div
                v-for="(trainingItem, trainingItemIndex) in trainingTopic.items"
                :key="`trainingItem-${trainingItemIndex}`"
                :training-item="`${trainingItemIndex}`"
                :training-topic="`${trainingTopicIndex}`"
                :class="[
                  'content--item--wrapper',
                  trainingItem.half ? 'half--item' : '',
                  isLeftHalf(trainingTopicIndex, trainingItemIndex)
                    ? ''
                    : 'disabled',
                  showDragArea(trainingItemIndex, trainingTopicIndex)
                    ? 'dragging'
                    : '',
                ]"
              >
                <div
                  v-for="i in trainingItem.half &&
                  isLeftHalf(trainingTopicIndex, trainingItemIndex)
                    ? 2
                    : 1"
                  :id="`trainingItem-${trainingTopicIndex}-${
                    !trainingItem.half ||
                    isLeftHalf(trainingTopicIndex, trainingItemIndex)
                      ? trainingItemIndex + i - 1
                      : 'disabled'
                  }`"
                  :key="trainingItemIndex + i"
                  :ref="`trainingItem-${trainingTopicIndex}-${
                    !trainingItem.half ||
                    isLeftHalf(trainingTopicIndex, trainingItemIndex)
                      ? trainingItemIndex + i - 1
                      : 'disabled'
                  }`"
                  v-click-outside-editor="undefined"
                  :class="[
                    isFullscreen(
                      trainingTopicIndex + '-' + (trainingItemIndex + i - 1)
                    ),
                    isSmaller(
                      trainingItem.half,
                      trainingTopicIndex,
                      trainingItemIndex + i - 1,
                      isLeftHalf(trainingTopicIndex, trainingItemIndex + i - 1)
                    ),
                    'content--item',
                    'content--item--' +
                      (trainingTopic.items[trainingItemIndex + i - 1]
                        ? trainingTopic.items[trainingItemIndex + i - 1].type
                        : ''),
                    trainingTopic.items[trainingItemIndex + i - 1] &&
                    trainingTopic.items[trainingItemIndex + i - 1].newItem
                      ? 'new--item'
                      : '',
                    hideTopPadding(trainingTopicIndex, trainingItemIndex)
                      ? 'top--padding--hidden'
                      : '',
                    hideBottomPadding(trainingTopicIndex, trainingItemIndex)
                      ? 'bottom--padding--hidden'
                      : '',
                  ]"
                >
                  <div v-if="trainingTopic.items[trainingItemIndex + i - 1]">
                    <div
                      v-if="
                        draggingAreaAvailable(
                          trainingTopic,
                          trainingItemIndex + i - 1
                        )
                      "
                      :class="[
                        'content--plus content--plus--left',
                        showDragArea(
                          trainingItemIndex + i - 1,
                          trainingTopicIndex
                        )
                          ? 'dragging'
                          : '',
                        isDragAreaActive(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1,
                          'left'
                        )
                          ? 'active'
                          : '',
                      ]"
                      @drop="
                        onDrop(
                          $event,
                          trainingItemIndex + i - 1,
                          trainingTopicIndex,
                          'left'
                        )
                      "
                      @dragover="
                        onDragOver(
                          $event,
                          trainingTopicIndex,
                          trainingItemIndex + i - 1,
                          'left'
                        )
                      "
                      @dragleave="
                        onDragLeave(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1,
                          'left'
                        )
                      "
                      @dragenter.prevent
                    >
                      <TrainingEditPlusNarrowWhiteSvg />
                    </div>
                    <div
                      v-if="
                        draggingAreaAvailable(
                          trainingTopic,
                          trainingItemIndex + i - 1
                        )
                      "
                      :class="[
                        'content--plus content--plus--right',
                        showDragArea(
                          trainingItemIndex + i - 1,
                          trainingTopicIndex
                        )
                          ? 'dragging'
                          : '',
                        isDragAreaActive(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1,
                          'right'
                        )
                          ? 'active'
                          : '',
                      ]"
                      @drop="
                        onDrop(
                          $event,
                          trainingItemIndex + i - 1,
                          trainingTopicIndex,
                          'right'
                        )
                      "
                      @dragover="
                        onDragOver(
                          $event,
                          trainingTopicIndex,
                          trainingItemIndex + i - 1,
                          'right'
                        )
                      "
                      @dragleave="
                        onDragLeave(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1,
                          'right'
                        )
                      "
                      @dragenter.prevent
                    >
                      <TrainingEditPlusNarrowWhiteSvg />
                    </div>
                    <!-- Text item -->
                    <text-item
                      v-if="
                        trainingTopic.items[trainingItemIndex + i - 1].type ==
                        'text'
                      "
                      :topic="trainingTopic"
                      :item="trainingTopic.items[trainingItemIndex + i - 1]"
                      :itemIndex="trainingItemIndex + i - 1"
                      :topicIndex="trainingTopicIndex"
                      @removeItem="
                        removeItem(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1
                        )
                      "
                      @mouseover="disableItemDrag2 = true"
                      @mouseout="disableItemDrag2 = false"
                      @disableItemDrag="disableItemDrag2 = $event"
                    />

                    <TrainingItem
                      v-if="
                        ['video', 'url', 'doc', 'img', 'scorm'].includes(
                          trainingTopic.items[trainingItemIndex + i - 1].type
                        ) &&
                        (trainingTopic.items[trainingItemIndex + i - 1].half
                          ? isLeftHalf(trainingTopicIndex, trainingItemIndex)
                          : true)
                      "
                      :ref="`item-${trainingTopicIndex}-${
                        !trainingItem.half ||
                        isLeftHalf(trainingTopicIndex, trainingItemIndex)
                          ? trainingItemIndex + i - 1
                          : 'disabled'
                      }`"
                      :trainingItem="
                        trainingTopic.items[trainingItemIndex + i - 1]
                      "
                      :key="trainingItem.id"
                      :training="training"
                      :topic_index="trainingTopicIndex"
                      :item_index="trainingItemIndex + i - 1"
                      :trainingType="'content'"
                      :videoTests="videoTests"
                      :videoSubtitles="videoSubtitles"
                      :videoTestSettings="videoTestSettings"
                      @editItemTitle="editWritingItem"
                      @onMediaChange="onTrainingMediaChange"
                      @videoGallery="openVideoGallery"
                      @imageGallery="openImageGallery"
                      @itemRemove="removeItem"
                      @toggleDrag="toggleItemDrag"
                      @editingModeCLosed="closeEditingMode"
                      @edit="
                        toggleEditItem(
                          $event,
                          trainingTopicIndex,
                          trainingItemIndex + i - 1
                        )
                      "
                    />
                    <!-- Confirm Item -->
                    <ConfirmTask
                      v-if="
                        trainingTopic.items[trainingItemIndex + i - 1].type ==
                        'confirm'
                      "
                      :item="trainingTopic.items[trainingItemIndex + i - 1]"
                      :readOnly="false"
                      @toggleDrag="toggleItemDrag"
                      @removeItem="
                        removeItem(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1
                        )
                      "
                    />

                    <!-- Image item -->

                    <!-- Seperator item -->
                    <div
                      v-if="
                        trainingTopic.items[trainingItemIndex + i - 1].type ==
                        'seperator'
                      "
                      class="seperator"
                    >
                      <div class="seperator--top" />
                      <div class="seperator--bottom" />
                    </div>
                    <div
                      v-if="
                        trainingTopic.items[trainingItemIndex + i - 1].type ==
                        'seperator'
                      "
                      :class="[
                        'media--editing side--editing seperator--editing',
                      ]"
                    >
                      <div
                        class="media--action"
                        @click="
                          removeItem(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          )
                        "
                      >
                        <!-- <img 
                        src="../assets/images/icons/training-item-remove.svg"
                        @click="removeItem(trainingTopicIndex, trainingItemIndex + i - 1)"
                      > -->
                        <TrainingEditTaskRemoveSvg />
                      </div>
                    </div>

                    <!-- Task item -->
                    <test-task
                      v-if="
                        trainingTopic.items[trainingItemIndex + i - 1].type ==
                          'task' &&
                        getTaskType(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1
                        ) > 0
                      "
                      :training="training"
                      :topic="trainingTopic"
                      :item="trainingTopic.items[trainingItemIndex + i - 1]"
                      :itemIndex="trainingItemIndex + i - 1"
                      :topicIndex="trainingTopicIndex"
                      :type="'content'"
                      @disableDrag2="disableItemDrag2 = $event"
                      @onTrainingMediaChange="onTrainingMediaChange"
                      @saveMetadata="saveMetadata"
                    />

                    <div
                      v-if="
                        trainingTopic.items[trainingItemIndex + i - 1].type ==
                        'task'
                      "
                      :class="[
                        'media--editing task--editing',
                        !getTaskType(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1
                        ) ||
                        getTaskType(
                          trainingTopicIndex,
                          trainingItemIndex + i - 1
                        ) == -1
                          ? 'full--editing narrow--editing'
                          : 'side--editing',
                      ]"
                    >
                      <div
                        v-if="
                          !getTaskType(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          ) ||
                          getTaskType(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          ) == -1
                        "
                        class="media--action"
                      >
                        <TrainingEditTaskTextSvg
                          @click="
                            setTaskType(
                              trainingTopicIndex,
                              trainingItemIndex + i - 1,
                              1
                            )
                          "
                          class="media--action--button"
                        />
                      </div>
                      <div
                        v-if="
                          !getTaskType(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          ) ||
                          getTaskType(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          ) == -1
                        "
                        class="media--action"
                      >
                        <TrainingEditTaskImageSvg
                          @click="
                            setTaskType(
                              trainingTopicIndex,
                              trainingItemIndex + i - 1,
                              2
                            )
                          "
                          class="media--action--button"
                        />
                      </div>
                      <div
                        v-if="
                          !getTaskType(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          ) ||
                          getTaskType(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          ) == -1
                        "
                        class="media--action"
                      >
                        <TrainingEditTaskConnectSvg
                          @click="
                            setTaskType(
                              trainingTopicIndex,
                              trainingItemIndex + i - 1,
                              3
                            )
                          "
                          class="media--action--button"
                        />
                      </div>
                      <div
                        class="media--action"
                        @click="
                          removeItem(
                            trainingTopicIndex,
                            trainingItemIndex + i - 1
                          )
                        "
                      >
                        <TrainingEditTaskRemoveSvg
                          class="media--action-remove-button"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="[
                    'content--item',
                    'content--item--add',
                    showDragArea(trainingItemIndex, trainingTopicIndex)
                      ? 'dragging'
                      : '',
                    isDragAreaActive(
                      trainingTopicIndex,
                      trainingItem.half
                        ? trainingItemIndex + 1
                        : trainingItemIndex,
                      'full'
                    )
                      ? 'active'
                      : '',
                  ]"
                  @drop="
                    onDrop(
                      $event,
                      trainingItem.half
                        ? trainingItemIndex + 1
                        : trainingItemIndex,
                      trainingTopicIndex,
                      'full'
                    )
                  "
                  @dragover="
                    onDragOver(
                      $event,
                      trainingTopicIndex,
                      trainingItem.half
                        ? trainingItemIndex + 1
                        : trainingItemIndex,
                      'full'
                    )
                  "
                  @dragleave="
                    onDragLeave(
                      trainingTopicIndex,
                      trainingItem.half
                        ? trainingItemIndex + 1
                        : trainingItemIndex,
                      'full'
                    )
                  "
                  @dragenter.prevent
                >
                  <div class="content--plus">
                    <TrainingEditPlusNarrowWhiteSvg />
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div
      :class="[
        'training--topic',
        training.endingEnabled ? '' : 'training--topic--disabled',
      ]"
    >
      <div
        :ref="`thead_${training.content ? training.content.length : 0}`"
        class="training--header ending--header"
      >
        <!-- <div class="training--header--inner"> -->
        <!-- <div class="training--header--number">
            {{ training.content ? training.content.length + 1 : 0 }}
          </div> -->
        <!-- <h2>
            {{ toLocal("training.ending") }}
          </h2> -->
        <!-- </div> -->
      </div>
      <!-- Mokymo pabaigos edit rezime nebereikia, bet just in case palieku ja cia-->
      <!-- <div
        :class="{
          'container main--container training training--end': true,
          'side--open': $store.getters.isSideOpen(),
        }"
      > -->
      <!-- <h1
          v-if="!training.editingEndingTitle"
          class="end--title"
          @click="editTrainingEndingTitle()"
        >
          {{ training.endingTitle }}
        </h1>
        <input
          v-if="training.editingEndingTitle"
          ref="trainingEndingTitle"
          v-click-outside-editing-title="undefined"
          class="ending--title--editing"
          type="text"
          @keyup.enter="setTrainingEndingTitle($event.srcElement.value)"
        />
        <div class="end--description">
          <p v-if="!training.editingEnding" @click="editTrainingEnding()">
            {{ training.endingDescription }}
          </p>
          <textarea
            v-if="training.editingEnding"
            ref="trainingEnding"
            v-click-outside-editing-title="undefined"
            class="description--editing"
            type="text"
            @keyup.enter="setTrainingEnding($event.srcElement.value)"
          />
        </div> -->
      <!-- <div class="end--rating">
          <div 
            v-for="star in 5"
            :key="star"
            :ref="`rating-star-${star}`"
            :class="['end--rating--star', (hoveredStar >= star || starRating >= star ? 'active' : '')]" 
            :style="{'background-image': hoveredStar >= star || starRating >= star ? 'url(' + require('@/assets/images/icons/star-active.svg') + ')' : 'url(' + require('@/assets/images/icons/star.svg') + ')'}"
  
          />
          <br>
          {{ toLocal('training.rating_question') }}
        </div> -->
      <!-- </div> -->
    </div>
    <div v-if="multipleInstances" class="multiple-instances">
      {{ multipleInstancesMessage }}
    </div>
    <TestUserSideDrawer ref="userTrainingStats" />
    <TaskInfoModal
      v-if="taskInfoModalOpen"
      @closeModal="
        taskInfoModalOpen = false;
        hasAcceptedModal = true;
      "
    />
    <transition name="fade">
      <DeleteModal
        v-if="$store.state.testDeleteModal.modalOpened"
        @delete="deleteTestItem"
      />
    </transition>
    <portal-target name="upload-tiptap-modal"></portal-target>
    <div
      class="test-select-mode"
      v-if="$store.state.selectTestsModeActive"
    ></div>
    <div class="info-banner" v-if="showInfoBanner">
      {{ toLocal(showInfoBannerText) }}
      <div class="close" @click="showInfoBanner = false">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.9989 8L8.00259 23.9974M23.999 24L8 8.00524"
            stroke="#7D7D7D"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
    <portal-target name="training-main-cancel-modal"></portal-target>
    <GlobalModal
      :modalInfo="publishModalData"
      @onConfirm="
        publishingTrainingModal = false;
        $set(training, 'published', training.published ? false : true);
      "
      @onClose="publishingTrainingModal = false"
      v-if="publishingTrainingModal"
    />
    <GlobalModal
      v-if="deletingTraining"
      :modalInfo="{
        type: 'confirmation',
        text: 'training_edit.delete_confirm',
        undertext: '',
        confirmBtnText: 'delete_modal.yes_delete',
        class: 'delete',
      }"
      @onConfirm="deleteTrainingRequest"
      @onClose="deletingTraining = false"
    />
    <ImportModal type="user-training-import" />
    <GlobalModal
        :modalInfo="{
            text: 'hierarchy.remove_confirm',
            confirmBtnText: 'delete_modal.yes_delete',
            type: 'multiChoice',
            multiChoiceYes: 'generic.yes',
            multiChoiceNo: 'generic.no'
        }"
        @onChoiceOne="removeItemConfirmed(deleteItemModalData.topic, deleteItemModalData.index)"
        @onChoiceTwo="isDeleteItemModalOpened=false"
        @onClose="isDeleteItemModalOpened=false"
        v-if="isDeleteItemModalOpened"
    />
    <portal-target name="paid-training-preview"></portal-target>
    <portal-target name="topic-delete-modal"></portal-target>
    <portal-target name="video-test-delete-modal"></portal-target>
    <portal-target name="training-item-gallery"></portal-target>
    <portal-target name="training-test-task"></portal-target>
  </section>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import TextItem from "@/components/layout/trainings/training_components/TextItem.vue";
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import TestTask from "@/components/layout/trainings/training_components/TestTask.vue";
import TrainingTest from "@/components/layout/trainings/training_components/TrainingTest.vue";
import TestUserSideDrawer from "@/components/layout/trainings/training_components/test/TestUserSideDrawer.vue";
import TaskInfoModal from "@/components/layout/trainings/training_components/TaskInfoModal.vue";
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";
import ImportModal from "@/components/layout/ImportModal.vue";

import VueScrollTo from "vue-scrollto";
import vSelect from "vue-select";
import moment from "moment";
import TrainingItem from "../components/layout/TrainingItem.vue";
import ConfirmTask from "../components/layout/trainings/training_components/ConfirmTask.vue";
import TestSideList from "../components/layout/trainings/training_components/TestSideList.vue";
import DeleteModal from "../components/layout/trainings/training_components/test_folder_tree/DeleteModal.vue";
import TrainingMain from "../components/layout/trainings/training_components/TrainingMain.vue";
import TrainingSideNavigation from "../components/layout/trainings/training_components/TrainingSideNavigation.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/lt";
import { getDroplets } from "@/components/layout/trainings/training_components/droplets";
import GallerySide from "../components/layout/modals/GallerySide.vue";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

//SVG
import TrainingEditArrowUpSvg from "@/assets/images/icons/training-edit/training-edit-arrow-up.svg?inline";
import TrainingEditEyeSvg from "@/assets/images/icons/training-edit/training-edit-eye.svg?inline";
import TrainingSideCornerSvg from "@/assets/images/side-corner.svg?inline";
import TrainingSideCloseSvg from "@/assets/images/icons/side-close.svg?inline";
import TrainingInfinitySvg from "@/assets/images/icons/infinity.svg?inline";
import TrainingItemGallerySvg from "@/assets/images/icons/training-item-gallery.svg?inline";
import TrainingItemUploadSvg from "@/assets/images/icons/training-item-upload.svg?inline";
import TrainingViewEyeSvg from "@/assets/images/icons/training-edit/training-edit-view-eye.svg?inline";
import TrainingEditCopySvg from "@/assets/images/icons/training-edit/training-edit-copy.svg?inline";
import TrainingEditTrashSvg from "@/assets/images/icons/training-edit/training-edit-trash.svg?inline";
import TrainingEditNotesSvg from "@/assets/images/icons/notes.svg?inline";
import TrainingEditAddSvg from "@/assets/images/icons/training-edit/training-edit-add.svg?inline";
import TrainingEditLockSvg from "@/assets/images/icons/training-edit/training-edit-lock.svg?inline";
import TrainingEditCopyTopicSvg from "@/assets/images/icons/training-edit/training-edit-copy-topic.svg?inline";
import TrainingContentMoveSvg from "@/assets/images/icons/content-move.svg?inline";
import TrainingEditToggleTopicSvg from "@/assets/images/icons/training-edit/training-edit-toggle-topic.svg?inline";
import TrainingEditTrashTopicSvg from "@/assets/images/icons/training-edit/training-edit-trash-topic.svg?inline";
import TrainingEditTaskTextSvg from "@/assets/images/icons/task-text.svg?inline";
import TrainingEditTaskImageSvg from "@/assets/images/icons/task-image.svg?inline";
import TrainingEditTaskConnectSvg from "@/assets/images/icons/task-connect.svg?inline";
import TrainingEditTaskRemoveSvg from "@/assets/images/icons/training-item-remove.svg?inline";
import TrainingEditPlusNarrowWhiteSvg from "@/assets/images/icons/plus-narrow-white.svg?inline";
import TrainingSwitch from "@/assets/images/icons/item-switch.svg?inline";
import ArrowLeftSvg from "@/assets/images/icons/homeEdit/arrow-go-back-16-8.svg?inline";

export default {
  name: "TrainingEdit",
  components: {
    draggable,
    TextItem,
    vSelect,
    TrainingItem,
    Editor,
    TestTask,
    TrainingTest,
    TestUserSideDrawer,
    TaskInfoModal,
    ConfirmTask,
    TestSideList,
    DeleteModal,
    DatePicker,
    TrainingMain,
    GlobalModal,
    TrainingSideNavigation,
    ImportModal,
    GallerySide,
    CustomDropdown,
    //SVG COMPONENTS
    TrainingEditArrowUpSvg,
    TrainingEditEyeSvg,
    TrainingSideCornerSvg,
    TrainingSideCloseSvg,
    TrainingInfinitySvg,
    TrainingItemGallerySvg,
    TrainingItemUploadSvg,
    TrainingViewEyeSvg,
    TrainingEditCopySvg,
    TrainingEditTrashSvg,
    TrainingEditNotesSvg,
    TrainingEditAddSvg,
    TrainingEditLockSvg,
    TrainingContentMoveSvg,
    TrainingEditCopyTopicSvg,
    TrainingEditToggleTopicSvg,
    TrainingEditTrashTopicSvg,
    TrainingEditTaskTextSvg,
    TrainingEditTaskImageSvg,
    TrainingEditTaskConnectSvg,
    TrainingEditTaskRemoveSvg,
    TrainingEditPlusNarrowWhiteSvg,
    TrainingSwitch,
    ArrowLeftSvg,
  },
  directives: {
    "click-outside-editor": {
      bind: function (el, binding, vnode) {
        let hasSomeParentWithClass = function (target, selector) {
          return [...document.querySelectorAll(selector)].some(
            (el) => el !== target && el.contains(target)
          );
        };
        let getDomPath = function (el) {
          var stack = [];
          while (el.parentNode != null) {
            var sibCount = 0;
            var sibIndex = 0;
            for (var i = 0; i < el.parentNode.childNodes.length; i++) {
              var sib = el.parentNode.childNodes[i];
              if (sib.nodeName == el.nodeName) {
                if (sib === el) {
                  sibIndex = sibCount;
                }
                sibCount++;
              }
            }
            if (el.hasAttribute("id") && el.id != "") {
              stack.unshift(el.nodeName.toLowerCase() + "#" + el.id);
            } else if (sibCount > 1) {
              stack.unshift(
                el.nodeName.toLowerCase() + ":eq(" + sibIndex + ")"
              );
            } else {
              stack.unshift(el.nodeName.toLowerCase());
            }
            el = el.parentNode;
          }

          return stack.slice(1);
        };
        el.clickOutsideEvent = function (event) {
          let domPath = getDomPath(event.target);
          let split = vnode.data.ref.split("-");
          if (
            !domPath.includes(
              "div#trainingItem-" + split[1] + "-" + split[2]
            ) &&
            !hasSomeParentWithClass(event.target, ".menububble")
          ) {
            vnode.context.endTaskEditingOnOutsideClick(split[1], split[2]);
          }
        };
        document.body.addEventListener("mousedown", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("mousedown", el.clickOutsideEvent);
      },
    },

    "click-outside-editing-title": {
      bind: function (el, binding, vnode) {
        let hasSomeParentWithClass = function (target, selector) {
          return [...document.querySelectorAll(selector)].some(
            (el) => el !== target && el.contains(target)
          );
        };
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            if (vnode.data.ref == "trainingTitle") {
              vnode.context.setTrainingTitle(vnode.elm.value);
              return;
            }
            if (vnode.data.ref == "trainingTime") {
              vnode.context.setTrainingTime(vnode.elm.value);
              return;
            }
            if (vnode.data.ref == "trainingEnding") {
              vnode.context.setTrainingEnding(vnode.elm.value);
              return;
            }
            if (vnode.data.ref == "trainingEndingTitle") {
              vnode.context.setTrainingEndingTitle(vnode.elm.value);
              return;
            }
            if (vnode.data.ref.startsWith("taskAnswer")) {
              let indexes = vnode.data.ref.split("-");
              if (indexes.length > 4) {
                vnode.context.setTaskAnswer(
                  indexes[1],
                  indexes[2],
                  indexes[3],
                  indexes[4],
                  vnode.elm.value
                );
              } else {
                vnode.context.setTaskAnswer(
                  indexes[1],
                  indexes[2],
                  indexes[3],
                  -1,
                  vnode.elm.value
                );
              }
              return;
            }
            let index = vnode.data.ref.split("-")[1];
            vnode.context.setTopicTitle(index, vnode.elm.value);
          }
        };
        document.body.addEventListener("mousedown", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("mousedown", el.clickOutsideEvent);
      },
    },
  },
  data: function () {
    return {
      videoTests: [],
      fullscreenPartsIndexList: [],
      videoTestSettings: [],
      disableDate: "",
      isTrainingDisabled: false,
      alertMessage: "",
      draggingDroplet: false,
      draggingDropletID: -1,
      editingTrainingTopic: -1,
      hoveredStar: 0,
      starRating: 0,
      imageGalleryOpen: false,
      videoGalleryOpen: false,
      galleryFiles: [],
      galleryUploadTo: [-1, -1],
      videoUrlUploadTo: [-1, -1],
      videoUrlOpen: false,
      notesOpen: false,
      certificateOpen: false,
      notes: "",
      pageUp: false,
      sideTrainingScroll: 0,
      lockedTrainingSide: false,
      trainingSideX: 0,
      headerWidth: 0,
      activeDragArea: [-1, -1, -1],
      selectedTrainingGroup: "",
      allTrainingGroups: [],
      scrolledTopic: -1,
      disableItemDrag: false,
      disableItemDrag2: false,
      droplets: [],
      dropletsSection2: [],
      dropletsSection3: [],
      addingNewTestQuestions: false,
      availableTestQuestions: [],
      testsLoaded: false,
      availableTestQuestionsLast: [],
      training: {
        title: "-",
        description: "-",
        cover: "",
        time: "0",
        endingEnabled: true,
        endingTitle: this.toLocal("training.congrats"),
        endingDescription: "...",
        editingTitle: false,
        editingDescription: false,
        certificate: {
          enabled: false,
        },
        content: [
          {
            title: "-",
            editingTitle: false,
            innerScrollTop: 0,
            visibleItems: [],
            enabled: true,
            items: [
              {
                type: "text",
                content: "<h2>-</h2>-",
                editing: false,
                uploading: false,
                file_size: 0,
              },
            ],
          },
        ],
      },
      training_group: -1,
      currentTextEditing: [-1, -1],
      currentUrlEditing: [-1, -1],
      currentInternetEditing: [-1, -1],
      currentDocumentEditing: [-1, -1],
      currentTaskEditing: [-1, -1],
      currentTaskAnswerEditing: [-1, -1, -1, -1],
      currentQuestionEditing: [-1, -1],
      currentQuestionAnswerEditing: [-1, -1, -1, -1],
      saveInterval: null,
      miscInterval: null,
      heartbeatInterval: null,
      lastJson: "",
      taskInfoModalOpen: false,
      hasAcceptedInfoModal: false,
      searchTestQuestions: "",
      isUploadingCover: false,
      trainingSectionHeight: 0,
      selectedTestID: -1,
      certificates: [],
      availableTests: [],
      settingTestData: -1,
      lastSavedTest: null,
      isTheOnlyEditing: true,
      connectedUserCount: 0,
      multipleInstances: false,
      multipleInstancesMessage: "",
      showInfoBanner: false,
      showInfoBannerText: "",
      publishingTrainingModal: false,
      deletingTraining: false,
      showUserImportModal: false,
      showLanguagesSection: false,
      siblingTrainings: [],
      videoSubtitles: [],
      isDeleteItemModalOpened: false,
      deleteItemModalData: {}
    };
  },

  mounted() {
    // this.trainingSectionHeight = this.$refs['training-section'] ? this.$refs['training-section'].clientHeight : 0;
    if (!this.hasPermission('trainings.edit')) {
      this.$router.push("/");
    }
    let droplets = getDroplets();
    this.droplets = droplets[0];
    this.dropletsSection2 = droplets[1];
    this.dropletsSection3 = droplets[2];

    if (
      this.$route.query.id &&
      this.$store.state.currentTraining == -1
    ) {
      this.$store.state.currentTraining = this.$route.query.id;
      this.$store.state.previewTopic = -1;
      if (!this.$store.state.header_hidden) {
        this.$store.state.header_hidden = true;
      }
      this.$store.state.loading.loadingStart();
    }
    this.starRating = 0;
    (this.editingTrainingTopic = -1),
      window.addEventListener("scroll", this.handleScroll.bind(this));
    window.addEventListener("resize", this.handleResize.bind(this));
    if (this.$store.state.currentTraining != -1) {
      if (
        this.$store.state.currentTrainingData != null &&
        this.$store.state.currentTrainingData.id ==
          this.$store.state.currentTraining
      ) {
        this.loadTraining(
          this.$store.state.currentTrainingData.data,
          this.$store.state.currentTrainingData.training_group,
          this.$store.state.currentTrainingData
        );
      } else {
        axios
          .get(
            `${this.$store.state.api_link}/training/info/${this.$store.state.currentTraining}?edit=true`,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.loadTraining(
              JSON.parse(response.data.data),
              response.data.training_group,
              response.data
            );
            this.siblingTrainings = response.data.sibling_trainings;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    this.loadForFiles();
    this.handleResize();
    let self = this;
    this.$root.$on("testInfoModal", function (e) {
      if (!self.hasAcceptedInfoModal) {
        self.taskInfoModalOpen = true;
      }
    });
    this.certificates = this.$store.state.certificates.siteCertificates;
    this.miscInterval = setInterval(() => {
      this.handleNavbar();
    }, 100);
    this.heartbeatInterval = setInterval(() => {
      this.save(true);
    }, 60000); // for training edition locking.
  },
  computed: {
    isBiggerMonitor() {
      if (window.innerWidth <= 1280) {
        return false;
      } else {
        return true;
      }
    },
    hasTest() {
      if (this.training.content.find((el) => el.test) !== undefined)
        return true;
      else return false;
    },
    selectedCert() {
      if (
        this.certificates &&
        this.training.certificate &&
        this.training.certificate.background
      )
        return this.certificates.find(
          (el) => el.id == this.training.certificate.background
        );
      else return null;
    },
    publishModalData() {
      let text = "";
      let undertext = "";
      let confirmBtnText = "";
      if (this.training.paid) {
        if (this.training.enabled) {
          text = this.toLocal("training_edit.shop_modal_dont_publish_text");
          undertext = this.toLocal(
            "training_edit.shop_modal_not_published_undertext"
          );
          confirmBtnText = this.toLocal("training_edit.publish_btn_cancel");
        } else {
          text = this.toLocal("training_edit.shop_modal_publish_text");
          undertext = this.toLocal(
            "training_edit.shop_modal_publish_undertext"
          );
          confirmBtnText = this.toLocal(
            "training_edit.shop_modal_publish_confirm"
          );
        }
      } else {
        if (this.training.published) {
          text = this.toLocal("training_edit.modal_dont_publish_text");
          undertext = this.toLocal(
            "training_edit.modal_not_published_undertext"
          );
          confirmBtnText = this.toLocal("training_edit.publish_btn_cancel");
        } else {
          text = this.toLocal("training_edit.modal_publish_text");
          undertext = this.toLocal("training_edit.modal_publish_undertext");
          confirmBtnText = this.toLocal(
            "training_edit.shop_modal_publish_confirm"
          );
        }
      }
      return {
        text: text,
        undertext: undertext,
        confirmBtnText: confirmBtnText,
        type: "confirmation",
        class:
          "publish" +
          (this.training.published || this.training.enabled
            ? " third-color"
            : ""),
      };
    },
    trainingTypeInitialText() {
      let trainingType = this.training.trainingType
      let label = this.$store.state.system_settings.trainingTypes[0].value; 
      if (trainingType) {
        let label_temp = this.$store.state.system_settings.trainingTypes.find(el => el.key == trainingType);
        if(label_temp) label = label_temp.value;  
      }
      return label
    },
  },
  watch: {
    "$store.state.selectedTest.questions.length": {
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal != undefined &&
          this.$store.state.selectedParentTest != undefined &&
          this.$store.state.selectedParentTest != {}
        ) {
          let index = this.availableTestQuestions.findIndex(
            (el) => el.id == this.$store.state.selectedParentTest.id
          );
          if (index != -1) {
            this.$set(
              this.availableTestQuestions[index],
              "question_count",
              newVal
            );
          }
        }
      },
    },

    showInfoBanner() {
      if (this.showInfoBanner) {
        setTimeout(() => {
          this.showInfoBanner = false;
        }, 5000);
      }
    },
    isTheOnlyEditing(newVal) {
      this.$store.state.isUserTheOnlyOneEditingTraining = newVal;
    },
    availableTestQuestions() {
      this.availableTests = this.availableTestQuestions
        .map((el) => {
          return el.tests;
        })
        .flat();
    },
    "$refs.trainingSection.clientHeight": {
      immediate: true,
      handler(newVal) {},
    },
  },

  beforeDestroy() {
    if (this.editorDescription) this.editorDescription.destroy();
    if (this.training) this.training = null;
    if (window.Echo)
      window.Echo.leave(
        `${this.$store.state.system_settings.lms_key}.trainingEdit.${this.$store.state.currentTraining}`
      );
    this.isTheOnlyEditing = true;
    this.$store.state.isUserTheOnlyOneEditingTraining = true;
    this.connectedUserCount = 0;
    this.isTheOnlyEditing = true;
    this.multipleInstances = false;
    window.removeEventListener("scroll", this.handleScroll.bind(this));
    window.removeEventListener("resize", this.handleResize.bind(this));
    if (this.saveInterval != null) {
      clearInterval(this.saveInterval);
      this.saveInterval = null;
    }
    if (this.heartbeatInterval != null) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    if (this.miscInterval != null) {
      clearInterval(this.miscInterval);
      this.miscInterval = null;
    }
    this.save(false);

    this.training = [];
    this.availableTestQuestions = [];
    this.availableTestQuestionsLast = [];

    this.$store.state.currentTrainingData = null;
  },

  methods: {
    closeEditingMode() {
      this.fullscreenPartsIndexList = [];
    },
    isFullscreen(index) {
      return this.fullscreenPartsIndexList.includes(index) ? "full-wide" : "";
    },
    isSmaller(half, topic, index, isLeft) {
      if (half && isLeft) {
        if (
          !this.fullscreenPartsIndexList.includes(`${topic}-${index}`) &&
          this.fullscreenPartsIndexList.includes(`${topic}-${index + 1}`)
        ) {
          return "hidden";
        }
      } else {
        if (half && !isLeft) {
          if (
            !this.fullscreenPartsIndexList.includes(`${topic}-${index}`) &&
            this.fullscreenPartsIndexList.includes(`${topic}-${index - 1}`)
          ) {
            return "hidden";
          }
        }
      }
    },
    loadForFiles() {
      axios
        .post(
          `${this.$store.state.api_link}/load/subtitle`,
          { training: this.$store.state.currentTraining },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.subtitles) {
            this.videoSubtitles = response.data.subtitles;
          }
        });
    },
    goBack() {
      let id = this.$store.state.currentGroupOpen;
      if (id == -1) {
        this.$router.push("/bought-trainings");
      } else {
        if (this.selectedTrainingGroup && this.selectedTrainingGroup.id) {
          this.$router.push("/edit-group?id=" + this.selectedTrainingGroup.id);
        } else {
          // this.$router.push("/edit");
          this.$router.back();
        }
      }
    },
    handleDisableDate() {
      if (this.disableDate != undefined && this.disableDate != null) {
        this.setDisableDate();
      } else {
        this.clearDisableDate();
      }
    },
    setDisableDate() {
      if (this.disableDate != undefined && this.disableDate != null) {
        axios
          .post(
            `${this.$store.state.api_link}/training/setDisableDate/${this.$store.state.currentTraining}`,
            { deactivation_date: this.disableDate },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.disableDate = response.data.deactivation_date;
            this.$set(this.training, "enabled", response.data.enabled);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    clearDisableDate() {
      axios
        .post(
          `${this.$store.state.api_link}/training/setDisableDate/${this.$store.state.currentTraining}`,
          { deactivation_date: null },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.disableDate = response.data.deactivation_date;
          this.$set(this.training, "enabled", response.data.enabled);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    disableTraining() {
      axios
        .get(
          `${this.$store.state.api_link}/training/disable/${this.$store.state.currentTraining}`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$set(this.training, "enabled", response.data.enabled);
          if (response.data.enabled == 0) {
            this.isTrainingDisabled = true;
          } else [(this.isTrainingDisabled = false)];
          this.disableDate = response.data.deactivation_date;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startEchoListen() {
      window.Echo.join(
        `${this.$store.state.system_settings.lms_key}.trainingEdit.${this.$store.state.currentTraining}`
      )
        .here((users) => {
          this.connectedUserCount = users.length;
          if (this.connectedUserCount == 1) this.isTheOnlyEditing = true;
          else {
            this.isTheOnlyEditing = false;
            this.multipleInstances = true;
            this.multipleInstancesMessage = this.toLocal(
              "training_edit.multipleInstances"
            );
          }
        })
        .joining((user) => {
          this.connectedUserCount++;
        })
        .leaving((user) => {
          this.connectedUserCount--;
          if (this.connectedUserCount == 1 && this.isTheOnlyEditing == false) {
            let vm = this;
            axios
              .get(
                `${this.$store.state.api_link}/training/info/${this.$store.state.currentTraining}?edit=true`,
                this.$store.getters.axiosConfig()
              )
              .then((response) => {
                this.loadTraining(
                  JSON.parse(response.data.data),
                  response.data.training_group,
                  response.data
                );
                vm.isTheOnlyEditing = true;
                vm.multipleInstances = false;
                vm.multipleInstancesMessage = "";
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .listen("MobileUpload", (e) => {
          var topic = parseInt(e.topic);
          var item = parseInt(e.item);
          var type = e.type;
          if (
            this.training[type][topic] == undefined ||
            this.training[type][topic].items[item] == undefined
          )
            console.log(
              "Failas įkeltas, tačiau įvyko klaida, bandykite perkrauti puslapį."
            );
          else {
            this.$set(
              this.training[type][topic].items[item],
              "content",
              e.data
            );
            this.$set(this.training[type][topic].items[item], "metadata", []);
          }
        });
    },

    loadTraining(training_data, training_group, full_data) {
      this.videoTests = full_data.video_tests;
      this.videoTestSettings = full_data.video_test_settings;
      if (full_data.deactivation_date != null) {
        this.disableDate = full_data.deactivation_date;
      }
      if (full_data.enabled == 0) {
        this.isTrainingDisabled = true;
      }
      let training = training_data;
      this.training_group = training_group;
      this.$set(training, "editingTitle", false);
      this.$set(training, "editingDescription", false);
      this.$set(training, "editingEnding", false);
      this.$set(training, "editingEndingTitle", false);
      
      if (!("lockTopics" in training)) this.$set(training, "lockTopics", false);
      if (!("certificate" in training))
        this.$set(training, "certificate", {
          enabled: false,
          description: "-",
          expires: false,
          expiration: 36,
        });
      if (!("endingDescription" in training))
        this.$set(training, "endingDescription", "...");
      if (!("endingTitle" in training))
        this.$set(training, "endingTitle", this.toLocal("training.congrats"));
      if (!("endingEnabled" in training))
        this.$set(training, "endingEnabled", true);
      let undefined_items = [];
      training.content.forEach((topic, topic_index) => {
        this.$set(topic, "editingTitle", false);
        this.$set(topic, "innerScrollTop", 0);
        this.$set(topic, "visibleItems", []);
        if (!("enabled" in topic)) this.$set(topic, "enabled", true);
        if (topic["test"]) {
          this.$set(topic, "addingQuestion", false);
          this.$set(topic, "selectedTest", topic["questions"]);
        }
        topic.items = topic.items.filter(function (item, pos) {
          return !(item === null);
        });
        topic.items.forEach((item, item_index) => {
          if (item == undefined) {
            undefined_items.push([topic_index, item_index]);
            return;
          }
          this.$set(item, "editing", 0);
          this.$set(item, "uploading", 0);
          this.$set(item, "file_size", 0);
        });
      });
      undefined_items.forEach((item) => {
        training.content[item[0]].items.splice(item[1], 1);
      });
      this.checkTrainingHalves(training);
      this.training = training;
      if (this.training.deactivation_date) {
        this.disableDate = this.training.deactivation_date;  
      }
      if (
        this.training.topic_iter == undefined ||
        this.training.item_iter == undefined
      ) {
        this.initIndexes();
      }
      if (training.trainingType == 'scorm' && training.content.length > 0) {
        if(training.content[0] && training.content[0].items.length == 0) {
          this.addItem(0, 0, 'scorm', false)
        }
      }
      this.scrolledTopic = -1;
      if (this.$refs["trainingTime"] != undefined) {
        if (this.$refs["trainingTime"].length > 0)
          this.$refs["trainingTime"][0].value = training.time;
        else this.$refs["trainingTime"].value = training.time;
      }

      this.$store.state.loading.loadingDone();
      setTimeout(() => {
        this.handleScroll();
        if (this.$store.state.previewTopic >= 0) {
          VueScrollTo.scrollTo(
            `#trainingTopic${this.$store.state.previewTopic}`,
            1000
          );
        } else {
          VueScrollTo.scrollTo(`body`, 100);
        }
      }, 500);
      axios
        .get(
          `${this.$store.state.api_link}/training_group/training_groups`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.allTrainingGroups = response.data.data;
          this.allTrainingGroups.forEach((training_group) => {
            if (training_group.id == this.training_group) {
              this.selectedTrainingGroup = training_group;
            }
            // this.saveQuestionsInterval = setInterval(() => {this.saveQuestions(false);}, 1000);
          });
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
      axios
        .get(
          `${this.$store.state.api_link}/test-question-groups`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.loadAvailableQuestionGroups(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.saveInterval = setInterval(() => {
        this.save(false);
      }, 1000);
      this.startEchoListen();
    },
    moment: function () {
      return moment().format("YYYY-MM-DD");
    },
    initIndexes() {
      if (this.training.topic_iter == undefined) {
        let content = this.training.content;
        let iter = 1;
        let vm = this;
        content.forEach((element) => {
          iter += 1;
          vm.$set(element, "id", iter);
        });

        this.$set(this.training, "topic_iter", iter);
      }
      if (this.training.item_iter == undefined) {
        let content = this.training.content;
        let iter = 1;
        let vm = this;
        content.forEach((element) => {
          element.items.forEach((elem) => {
            iter += 1;
            vm.$set(elem, "id", iter);
          });
        });

        this.$set(this.training, "item_iter", iter);
      }
    },
    offset: (el) => {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
    handleScroll: function () {
      if (this.training == undefined) return;
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      if (scrollY > 100) {
        this.pageUp = true;
      } else {
        this.pageUp = false;
      }
      for (let refIndex = 0; refIndex < 100; refIndex++) {
        const currentRef = this.$refs["thead_" + refIndex];
        if (currentRef == undefined || currentRef[0] == undefined) break;
        const nextRef = this.$refs["thead_" + (refIndex + 1)];
        let nextTop = 9999999;
        if (nextRef != undefined && nextRef[0] != undefined)
          nextTop = this.offset(nextRef[0]).top;
        if (nextTop == 9999999 && nextRef != undefined)
          nextTop = this.offset(nextRef).top;
        const top = this.offset(currentRef[0]).top;
        let yOffset = -80;
        this.training.content[refIndex].innerScrollTop =
          -(top - scrollY) - yOffset;
        if (refIndex == 0) {
          let lasttop = this.offset(
            this.$refs["thead_" + this.training.content.length]
          ).top;
          this.sideTrainingScroll = Math.max(
            250,
            Math.min(
              -(top - scrollY) - yOffset,
              lasttop -
                top -
                window.innerHeight +
                (window.innerWidth > 1280 ? 150 : 75)
            )
          );
          if (
            -(top - scrollY) - yOffset > 40 &&
            lasttop -
              top -
              window.innerHeight +
              (window.innerWidth > 1280 ? 150 : 75) >
              -(top - scrollY) - yOffset
          )
            this.lockedTrainingSide = true;
          else this.lockedTrainingSide = false;
        }
        this.training.content[refIndex].visibleItems = [];

        for (let refIndex2 = 0; refIndex2 < 100; refIndex2++) {
          const currentRef2 =
            this.$refs["trainingItem-" + refIndex + "-" + refIndex2];
          if (currentRef2 == undefined || currentRef2[0] == undefined) break;
          const top2 = this.offset(currentRef2[0]).top - top;
          if (
            this.training.content[refIndex].innerScrollTop -
              currentRef2[0].clientHeight <
              top2 &&
            top2 <
              this.training.content[refIndex].innerScrollTop +
                window.innerHeight
          ) {
            this.training.content[refIndex].visibleItems.push(refIndex2);
          }
        }
      }
    },
    handleResize: function () {
      if (!this.$refs["mainContainer"]) return;
      let containerWidth = this.$refs["mainContainer"].clientWidth;
      let windowWidth = window.innerWidth;
      let left = (windowWidth - containerWidth) / 2;
      this.trainingSideX = left + 40;
      this.headerWidth = left + containerWidth - 410;
      if (windowWidth <= 1280) {
        this.headerWidth = left + containerWidth - 250;
        this.trainingSideX = left;
      }
    },
    handleNavbar() {
      let topic = -1;
      for (let refIndex = 0; refIndex < 100; refIndex++) {
        const currentRef = this.$refs["thead_" + refIndex];
        if (currentRef == undefined || currentRef[0] == undefined) break;
        if (refIndex == 0) topic = -1;
        if (this.training && this.training.content)
          if (this.training.content[refIndex].innerScrollTop > -200)
            topic = refIndex;
      }
      this.scrolledTopic = topic;
    },
    parentTest(topic) {
      if (this.availableTests && this.availableTests.length > 0) {
        let test = this.availableTests.find((el) =>
          el ? el.id == topic.selectedTest : false
        );
        if (test != undefined) return test;
        else return {};
      } else return {};
    },
    loadAvailableQuestionGroups(groups) {
      let temp = groups;
      let temp_last = [];
      temp.forEach((folder, foldeIndex) => {
        folder.tests.forEach((test) => {
          if (test.questions) {
            test.questions.forEach((question) => {
              this.$set(question, "expanded", false);
              this.$set(question, "editing", false);
            });
            temp_last.push(JSON.stringify(test, this.censorJson(test)));
          }
        });
      });
      this.training.content.forEach((topic, topicIndex) => {
        if (topic.test) {
          let selectedTest = -1;
          let selectedFolder = temp.findIndex((folder, f_i) => {
            let index = folder.tests.findIndex(
              (test) => test.id == topic.questions
            );
            let found = index != -1;
            if (found) selectedTest = index;
            return found;
          });
          this.$set(
            this.training.content[topicIndex],
            "folderindex",
            selectedFolder
          );
          this.$set(
            this.training.content[topicIndex],
            "questionsindex",
            selectedTest
          );
          if (temp[selectedFolder] != undefined)
            this.loadDetailedTestData(
              temp[selectedFolder].tests,
              temp[selectedFolder].tests[selectedTest].id,
              selectedTest
            );
        }
      });
      this.availableTestQuestions = temp;
      this.testsLoaded = true;
      this.availableTestQuestionsLast = temp_last;
    },
    loadDetailedTestData(test_list, id, index) {
      axios
        .get(
          `${this.$store.state.api_link}/test-question-group/${id}`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$set(test_list, index, response.data.data);
          let t_index = this.availableTests.findIndex((test) => test.id == id);
          this.$set(this.availableTests, t_index, response.data.data);
          return test_list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goUp() {
      VueScrollTo.scrollTo(`body`, 1000);
    },
    draggingAreaAvailable(trainingTopic, itemIndex) {
      if (
        this.draggingDropletID == 3 ||
        this.draggingDropletID == 6 ||
        this.draggingDropletID == 7 ||
        this.draggingDropletID == 8
      )
        // video, document, internet, seperator or task
        return false;
      if (
        (this.draggingDropletID == 4 || this.draggingDropletID == 5) &&
        trainingTopic.items[itemIndex].type == "text"
      ) {
        // check if no text in text block, only h2
        return (
          !trainingTopic.items[itemIndex].half &&
          trainingTopic.items[itemIndex].content.endsWith("</h2><p></p>")
        );
      }
      return (
        !trainingTopic.items[itemIndex].half &&
        trainingTopic.items[itemIndex].type != "seperator" &&
        trainingTopic.items[itemIndex].type != "doc" &&
        trainingTopic.items[itemIndex].type != "task"
      );
    },
    showDragArea(itemIndex, sectionIndex) {
      return (
        this.draggingDroplet &&
        this.training.content[sectionIndex] &&
        this.training.content[sectionIndex].visibleItems.includes(itemIndex)
      );
    },
    showStartDragArea(sectionIndex) {
      return (
        this.draggingDroplet &&
        this.training.content[sectionIndex].innerScrollTop <= 300 &&
        !this.training.content[sectionIndex].test
      );
    },
    arrayInsert: (arr, index, ...newItems) => [
      ...arr.slice(0, index),
      ...newItems,
      ...arr.slice(index),
    ],
    arrayMove: (arr, old_index, new_index) => {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      this.draggingDropletID = item.id;
      this.draggingDroplet = true;
    },
    onDrop(evt, toIndex, toTopic, position) {
      const itemID = this.draggingDropletID;
      const item = [
        ...this.droplets,
        ...this.dropletsSection2,
        ...this.dropletsSection3,
      ].find((droplet) => droplet.id == itemID);

      const half = position == "left" || position == "right";
      if (position != "full") {
        if (
          item.type == "seperator" ||
          item.type == "task" ||
          item.type == "doc"
        )
          return;
        this.training.content[toTopic].items[toIndex]["half"] = true;
      }
      let index = toIndex;
      if (position == "left") {
        index--;
      }
      this.addItem(index, toTopic, item.type, half);
      this.activeDragArea = [-1, -1, -1];
      setTimeout(() => {
        this.draggingDroplet = false;
      }, 300);
    },

    openCertificate() {
      setTimeout(() => {
        this.certificateOpen = true;
        if (!("background" in this.training.certificate)) {
          this.$set(this.training.certificate, "background", 0);
        }
      }, 10);
    },
    gotoTopic(index) {
      VueScrollTo.scrollTo(`#trainingTopic${index}`, 1000);
    },

    addItem(toIndex, toTopic, type, half) {
      this.training.content[toTopic].items.forEach((item) => {
        item.newItem = false;
      });
      let newItem = {
        id: this.training.item_iter + 1,
        type: type,
        content: "",
        editing: true,
        newItem: true,
        uploading: false,
        file_size: 0,
      };
      if (type == "img") {
        newItem.content = require("@/assets/images/placeholders/image.png");
        newItem.title = this.toLocal("generic.open_img");
      }
      if (type == "media") {
        // newItem.content = require("@/assets/images/placeholders/placeholder_video.mp4");
        newItem.title = this.toLocal("generic.open_video");
      }
      if (type == "doc") {
        newItem.content = "/placeholders/PDF TEST.pdf§Document";
        newItem.downloadable = false;
        newItem.title = this.toLocal("generic.open_img");
      }
      if (type == "url") {
        newItem.content = "";
        newItem.url = "";
        newItem.title = this.toLocal("generic.open_img");
      }
      if (type == "text" || type == "task") {
        newItem.editing = false;
      }
      if (type == "confirm") {
        newItem.showContent = true;
      }
      if (type == "internet") newItem.content = "";
      if (half) newItem.half = true;

      setTimeout(() => {
        this.training.content[toTopic].items = this.arrayInsert(
          this.training.content[toTopic].items,
          toIndex + 1,
          newItem
        );
        if (half) {
          this.fullscreenPartsIndexList = [
            toTopic + "-" + parseInt(toIndex + 1),
          ];
        }
        this.$set(this.training, "item_iter", this.training.item_iter + 1);

        setTimeout(() => {
          this.handleScroll();
        }, 100);
      }, 10);
    },
    checkTrainingHalves(training) {
      training.content.forEach((topic, topicIndex) => {
        let items = topic.items;
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const item = items[itemIndex];
          if (item == undefined) continue;
          if (item.half) {
            if (itemIndex == 0 && items.length > 1) {
              if (!items[1].half) delete item.half;
              continue;
            } else if (items.length == 1) {
              delete item.half;
              continue;
            } else if (items.length == itemIndex + 1) {
              if (!items[itemIndex - 1].half) delete item.half;
              continue;
            } else {
              if (!items[itemIndex - 1].half && !items[itemIndex + 1].half)
                delete item.half;
            }
            let halves = 0;
            for (let i = 0; i < items.length; i++) {
              if (i == itemIndex) {
                if (halves % 2 == 0 && (!items[i + 1] || !items[i + 1].half)) {
                  delete item.half;
                }
              }
              const item = items[i];
              if (item && item.half) {
                halves++;
              }
            }
          }
        }
      });
    },
    getHalfPosition(topicIndex, itemIndex) {
      if (this.training.content[topicIndex].items[itemIndex].half) {
        let halves = 0;
        for (
          let i = 0;
          i < this.training.content[topicIndex].items.length;
          i++
        ) {
          if (i == itemIndex) {
            return halves % 2 == 0 ? "left" : "right";
          }
          const item = this.training.content[topicIndex].items[i];
          if (item.half) {
            halves++;
          }
        }
      }
      return false;
    },
    isLeftHalf(topicIndex, itemIndex) {
      return this.getHalfPosition(topicIndex, itemIndex) == "left";
    },
    hideTopPadding(topicIndex, itemIndex) {
      let half = this.training.content[topicIndex].items[itemIndex].half;
      if (this.training.content[topicIndex].items[itemIndex].editing)
        return false;
      if (
        half &&
        this.training.content[topicIndex].items[itemIndex + 1] &&
        this.training.content[topicIndex].items[itemIndex + 1].editing
      )
        return false;
      if (
        half &&
        this.training.content[topicIndex].items[itemIndex + 1] &&
        (this.training.content[topicIndex].items[itemIndex].type == "url" ||
          this.training.content[topicIndex].items[itemIndex + 1].type ==
            "url" ||
          this.training.content[topicIndex].items[itemIndex].type == "audio" ||
          this.training.content[topicIndex].items[itemIndex + 1].type ==
            "audio")
      )
        return false;
      if (itemIndex == 0) return false;
      if (this.training.content[topicIndex].items[itemIndex - 1].half) {
        if (
          this.training.content[topicIndex].items[itemIndex - 1].type == "text"
        )
          return true;
        if (
          itemIndex > 1 &&
          this.training.content[topicIndex].items[itemIndex - 2].type == "text"
        )
          return true;
      } else {
        if (
          this.training.content[topicIndex].items[itemIndex - 1].type == "text"
        )
          return true;
      }
      return false;
    },
    hideBottomPadding(topicIndex, itemIndex) {
      let half = this.training.content[topicIndex].items[itemIndex].half;
      if (this.training.content[topicIndex].items[itemIndex] == undefined)
        return false;
      if (this.training.content[topicIndex].items[itemIndex].editing)
        return false;
      if (
        half &&
        this.training.content[topicIndex].items[itemIndex + 1] &&
        this.training.content[topicIndex].items[itemIndex + 1].editing
      )
        return false;
      if (
        half &&
        this.training.content[topicIndex].items[itemIndex + 1] &&
        (this.training.content[topicIndex].items[itemIndex].type == "url" ||
          this.training.content[topicIndex].items[itemIndex + 1].type ==
            "url" ||
          this.training.content[topicIndex].items[itemIndex].type == "audio" ||
          this.training.content[topicIndex].items[itemIndex + 1].type ==
            "audio")
      )
        return false;
      if (itemIndex == this.training.content[topicIndex].items.length - 1)
        return false;
      let nextOffset = 1;
      if (half) nextOffset = 2;
      if (this.training.content[topicIndex].items[itemIndex + nextOffset]) {
        if (
          this.training.content[topicIndex].items[itemIndex + nextOffset].half
        ) {
          if (
            this.training.content[topicIndex].items[itemIndex + nextOffset]
              .type == "text" &&
            !this.training.content[topicIndex].items[itemIndex].half
          )
            return true;
          if (
            itemIndex < this.training.content[topicIndex].items.length - 2 &&
            this.training.content[topicIndex].items[
              itemIndex + nextOffset + 1
            ] &&
            this.training.content[topicIndex].items[itemIndex + nextOffset + 1]
              .type == "text"
          )
            return true;
        } else {
          if (
            this.training.content[topicIndex].items[itemIndex + nextOffset]
              .type == "text"
          )
            return true;
        }
      }
      return false;
    },
    removeItem(topicIndex, itemIndex) {
        if (this.systemSettings && this.systemSettings.trainingItemDeleteConfirmation) {
          this.deleteItemModalData = {
            topic: topicIndex,
            index: itemIndex
          }
          this.isDeleteItemModalOpened = true
        }
        else {
          this.removeItemConfirmed(topicIndex, itemIndex);
        }
     
    },
    removeItemConfirmed(topicIndex, itemIndex) {
       if (this.training.content[topicIndex].items[itemIndex].half) {
        if (this.isLeftHalf(topicIndex, itemIndex)) {
          delete this.training.content[topicIndex].items[itemIndex + 1].half;
        } else {
          delete this.training.content[topicIndex].items[itemIndex - 1].half;
        }
      }
      this.deleteFile(this.training.content[topicIndex].items[itemIndex]);
      this.training.content[topicIndex].items.splice(itemIndex, 1);
      this.deleteItemModalData = { }
      this.isDeleteItemModalOpened = false
      this.checkTrainingHalves(this.training);
    },
    deleteFile(item) {
      let same_file_count = 0
      this.training.content.forEach(topic => {
        let items = topic.items.filter(s_item => s_item.content == item.content)
        if(items.length > 0) same_file_count+=items.length;
      });
      if(same_file_count > 1) return
      axios
        .post(
          `${this.$store.state.api_link}/files/delete`,
          {filename: item.content},
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
        })
        .catch((err)=>{

        })
    },
    onTrainingItemDragStart() {
      // this.endUrlEditing();
    },
    onTrainingItemDrag(event) {
      let topicIndex = event.clone.getAttribute("training-topic");

      // setTimeout(() => {

      this.training.content[topicIndex].items.forEach((element) => {
        if (element.newItem) element.newItem = false;
      });

      let itemIndex = event.newIndex;
      let oldItemIndex = event.oldIndex;
      // let movedDown = 0;
      let halfBlock = this.training.content[topicIndex].items[itemIndex].half;

      if (
        itemIndex - oldItemIndex == 1 &&
        this.training.content[topicIndex].items[itemIndex].half
      ) {
        // moving by invisible element fix
        this.arrayMove(
          this.training.content[topicIndex].items,
          itemIndex,
          oldItemIndex
        );
        console.log("Moving by invisible element cancelled");
        return;
      }
      // if(itemIndex - oldItemIndex == 1 && !this.training.content[topicIndex].items[itemIndex].half) { // moving to invisible element place fix
      //   this.arrayMove(this.training.content[topicIndex].items, itemIndex, itemIndex + 1);
      //   console.log("Moving to invisible element place fixed");
      //   return;
      // }

      console.log(
        oldItemIndex +
          " > " +
          itemIndex +
          " > " +
          this.training.content[topicIndex].items[oldItemIndex].half +
          " > " +
          this.isLeftHalf(topicIndex, oldItemIndex) +
          " > " +
          this.training.content[topicIndex].items[itemIndex].content
      );

      let i = 0;
      let offset = 0;
      this.training.content[topicIndex].items.forEach((element) => {
        if (i >= itemIndex) return;
        if (
          element.half &&
          this.isLeftHalf(topicIndex, i) &&
          i != oldItemIndex
        ) {
          offset++;
        }
        i++;
      });

      console.log("Moving by offset: " + offset);

      this.arrayMove(
        this.training.content[topicIndex].items,
        itemIndex,
        itemIndex + offset
      );

      console.log(
        "Cheking if the same element (" +
          (itemIndex + offset) +
          "): " +
          this.training.content[topicIndex].items[itemIndex + offset].content
      );

      if (halfBlock) {
        console.log(
          "Moving " +
            oldItemIndex +
            " > " +
            (itemIndex + offset) +
            " > " +
            this.training.content[topicIndex].items[oldItemIndex].content
        );
        let offset2 = 0;
        if (oldItemIndex > itemIndex + offset) offset2 = 1;
        this.arrayMove(
          this.training.content[topicIndex].items,
          oldItemIndex + offset2,
          itemIndex + offset + offset2
        );
      }
    },
    editTrainingEnding() {
      setTimeout(() => {
        this.training.editingEnding = true;

        setTimeout(() => {
          this.$refs["trainingEnding"].value = this.training.endingDescription;
        }, 10);
      }, 10);
    },
    setTrainingEnding(trainingEnding) {
      if (trainingEnding.length === 0 || !trainingEnding.trim())
        trainingEnding = "...";
      this.training.endingDescription = trainingEnding;
      this.training.editingEnding = false;
    },
    editTrainingEndingTitle() {
      if (!this.$store.state.isUserTheOnlyOneEditingTraining) return;
      setTimeout(() => {
        this.training.editingEndingTitle = true;

        setTimeout(() => {
          this.$refs["trainingEndingTitle"].value = this.training.endingTitle;
        }, 10);
      }, 10);
    },
    setTrainingEndingTitle(trainingEndingTitle) {
      if (!this.$store.state.isUserTheOnlyOneEditingTraining) return;
      if (trainingEndingTitle == "") trainingEndingTitle = "-";
      this.training.endingTitle = trainingEndingTitle;
      this.training.editingEndingTitle = false;
    },
    setTrainingTime(trainingTime) {
      if (!this.$store.state.isUserTheOnlyOneEditingTraining) return;
      if (trainingTime == "") trainingTime = "0";
      var n = Math.floor(Number(trainingTime));
      if (!(n !== Infinity && String(n) === trainingTime && n >= 0)) {
        trainingTime = "0";
      }
      this.$refs["trainingTime"].value = trainingTime;
      this.training.time = trainingTime;
    },
    onTrainingMediaChange(e, toTopic, toIndex, toAnswer = null) {
      if (e.imageSet) {
        if (toAnswer != null) {
          this.training.content[toTopic].items[toIndex].metadata = [
            JSON.stringify(e.getMetadata()),
            e.height,
          ];
        }
        e.generateBlob((blob) => {
          let formData = new FormData();
          formData.append("file", blob, "image.jpg");
          this.$store.state.uploadedData = 0;
          let test = this.training.content[toTopic].test;
          if (!test) {
            if (toAnswer != null)
              this.training.content[toTopic].items[toIndex].answers[
                toAnswer
              ].uploading = true;
            else this.training.content[toTopic].items[toIndex].uploading = true;
            this.training.content[toTopic].items[toIndex].file_size = blob.size;
          } else {
            this.training.content[toTopic].uploading = true;
          }
          if (this.training.content[toTopic].items[toIndex].file_size != 0) {
            var link = this.training.content[toTopic].items[
              toIndex
            ].content.replace(this.$store.state.api_uploads_link, "");
            // axios.post(`${this.$store.state.api_link}/training/delete-item`,
            // {link: link.split('§')[0]},
            // this.$store.getters.axiosConfig()
            // ).then((res)=>{
            // // if(res.data.deleted)
            // //   this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.success_remove_item'));
            // })
            // .catch((err)=>{
            // this.$store.state.infoMessage.setMessage('error', this.toLocal('training_edit.error_remove_item'));
            // });
          }
          this.onMediaChange(formData, toTopic, toIndex, toAnswer, e);
        });
      } else {
        var file;
        if (e.size) {
          file = e;
        } else {
          let files = e.target.files || e.dataTransfer.files;
          if (!files.length) return;
          file = files[0];
        }

        this.checkIfHeic(file).then((blob) => {
          let formData = new FormData();
          if (blob == false) formData.append("file", file);
          else formData.append("file", blob);

          this.$store.state.uploadedData = 0;
          let test = this.training.content[toTopic].test;
          if (!test) {
            this.training.content[toTopic].items[toIndex].uploading = true;
            this.training.content[toTopic].items[toIndex].file_size = file.size;
            if (this.training.content[toTopic].items[toIndex].file_size != 0) {
              var link = this.training.content[toTopic].items[
                toIndex
              ].content.replace(this.$store.state.api_uploads_link, "");
            }
          } else {
            this.training.content[toTopic].uploading = true;
          }
          this.onMediaChange(formData, toTopic, toIndex, toAnswer);
        });
      }
    },
    onMediaChange(
      formData,
      toTopic,
      toIndex,
      toAnswer = null,
      imgCroppa = null
    ) {
      this.$store.state.uploadedData = 0;
      let test = this.training.content[toTopic].test;
      axios
        .post(
          `${this.$store.state.api_link}/upload-file`,
          formData,
          this.$store.getters.axiosConfigMultipart()
        )
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.aspect_ratio && response.data.aspect_ratio != 0) {
              this.training.content[toTopic].items[toIndex].embed_options = {
                aspect_ratio: response.data.aspect_ratio,
              };
            }
            if (response.data.size) {
              this.training.content[toTopic].items[toIndex].imageSize =
                response.data.size;
            }
            if (!test) {
              this.training.content[toTopic].items[toIndex].editing = false;
            }
             if(response.data.type && ['scorm', 'qti','xapi'].includes(response.data.type)){
              let scorm_data = response.data.data;
              this.$set(this.training.content[toTopic].items[toIndex],'content', response.data.scorm_id);
              this.$set(this.training.content[toTopic].items[toIndex],'type', 'scorm');
              this.$set(this.training.content[toTopic].items[toIndex],'data', {
                'title': scorm_data.title,
                format: scorm_data.format,
              });
            }
            else {
              this.training.content[toTopic].items[toIndex].content =
                response.data.file;
            }
            if (toAnswer !== null) {
              this.setUploadedTestTaskFiles(
                response,
                imgCroppa,
                toTopic,
                toIndex,
                toAnswer
              );
            } else if (
              this.training.content[toTopic].items[toIndex].type == "doc"
            ) {
              this.training.content[toTopic].items[toIndex].content =
                response.data.file;

            } else if (response.data.type && response.data.type == "scorm") {
              this.$set(
                this.training.content[toTopic].items[toIndex],
                "content",
                response.data.scorm_id
              );
              this.$set(
                this.training.content[toTopic].items[toIndex],
                "type",
                "scorm"
              );
            } else {
              this.training.content[toTopic].items[toIndex].content =
                response.data.file;
            }
          } else {
            alert("Error while uploading file: Server returned error");
          }
          if (!test) {
            if (toAnswer != null)
              this.training.content[toTopic].items[toIndex].answers[
                toAnswer
              ].uploading = false;
            else
              this.training.content[toTopic].items[toIndex].uploading = false;
          }
        })
        .catch((e) => {
          if (!test) {
            this.training.content[toTopic].items[toIndex].uploading = false;
            // this.training.content[toTopic].items[toIndex].answers[toAnswer].uploading = false;
          }
          alert("Error while uploading file" + e);
        });
    },
  
    setUploadedTestTaskFiles(response, imgCroppa, toTopic, toIndex, toAnswer) {
      if (this.training.content[toTopic].test) {
        let parentTest =
          this.availableTestQuestions[
            this.training.content[toTopic].questionsindex
          ];
        let selected_test_id = this.$store.state.selectedTest.id;
        let set_test = parentTest;
        if (parentTest.id != selected_test_id) {
          set_test = this.findRecursiveTestVersion(
            selected_test_id,
            parentTest.versions,
            "versions"
          );
        }
        let answers = set_test.questions[toIndex].answers;
        let weights =
          answers.length > 0
            ? answers.map((el) => (el.weight ? el.weight : 0))
            : 0;
        let weight = 0;
        if (weights != 0) weight = Math.max(0, ...weights) + 1;
        let answer = {
          id: null,
          image: response.data.file,
          metadata:
            imgCroppa != null
              ? [JSON.stringify(imgCroppa.getMetadata()), imgCroppa.height]
              : "",
          correct: false,
          weight: weight,
          question_id: set_test.questions[toIndex].id
            ? set_test.questions[toIndex].id
            : 1,
        };
        this.addNewAnswerRequest(answer).then((data) => {
          if (set_test.questions[toIndex].type == 2) {
            answer.id = data.answer.id;
          } else {
            Object.assign(answer, data.answer);
          }
          if (toAnswer > set_test.questions[toIndex].answers.length - 1) {
            set_test.questions[toIndex].answers.push(answer);
          } else {
            set_test.questions[toIndex].answers[toAnswer].image = answer.image;
            set_test.questions[toIndex].answers[toAnswer].metadata =
              answer.metadata;
          }
        });
      } else {
        let answer = {
          image: response.data.file,
          metadata:
            imgCroppa != null
              ? [JSON.stringify(imgCroppa.getMetadata()), imgCroppa.height]
              : "",
          correct: false,
        };
        if (
          toAnswer >
          this.training.content[toTopic].items[toIndex].answers.length - 1
        ) {
          this.training.content[toTopic].items[toIndex].answers.push(answer);
        } else {
          this.training.content[toTopic].items[toIndex].answers[
            toAnswer
          ].image = answer.image;
          this.training.content[toTopic].items[toIndex].answers[
            toAnswer
          ].metadata = answer.metadata;
        }
      }
    },
    addNewAnswerRequest(answer) {
      const promise = axios.post(
        `${this.$store.state.api_link}/test-question-groups/crud-answer`,
        {
          data: answer,
          action: "add",
          training: this.$store.state.currentTraining,
        },
        this.$store.getters.axiosConfig()
      );
      const dataPromise = promise.then((res) => res.data);
      return dataPromise;
    },
    getItemUrl(url) {
      if (url.indexOf("http") == -1 || url.indexOf("www") == -1)
        url = this.$store.state.api.uploads_link + url;
      return url;
    },
    checkIfHeic(file) {
      return new Promise((resolve, reject) => {
        if (file.name) {
          if (
            file.name.split(".").pop() == "heic" ||
            file.name.split(".").pop() == "heif"
          ) {
            this.readFile(file).then((result) => {
              const blob = this.dataURItoBlob(result);
              heic2any({
                blob,
                toType: "image/jpeg",
                quality: 0.5, // cuts the quality and size by half
              }).then((conversionResult) => {
                file = conversionResult;
                resolve(file);
              });
            });
          } else resolve(false);
        } else {
          resolve(false);
        }
      });
    },
    onTrainingCardCoverChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let file = files[0];
      let formData = new FormData();
      formData.append("file", file);
      this.isUploadingCover = true;
      axios
        .post(
          `${this.$store.state.api_link}/upload-file`,
          formData,
          this.$store.getters.axiosConfigMultipart()
        )
        .then((response) => {
          if (response.data.status == "success") {
            let link = response.data.file;
            this.training.cover = link;
            this.isUploadingCover = false;
          } else {
            alert("Error while uploading file");
            this.isUploadingCover = false;
          }
          this.$store.state.uploadProgress = 0;
        })
        .catch(() => {
          alert("Error while uploading file");
          this.isUploadingCover = false;
          this.$store.state.uploadProgress = 0;
        });
    },
    openTraining() {
      this.$store.state.loading.loadingStart();
      this.$store.state.previewTopic = this.scrolledTopic;
      this.$store.state.previewMode = true;
      this.$router.push("/training?id=" + this.$store.state.currentTraining);
    },
    censorJson(censor) {
      return function (key, value) {
        if (key == "editor") return "";
        if (key == "innerScrollTop" || key == "visibleItems") return "-";

        return value;
      };
    },
    save(force) {
      if (!this.isTheOnlyEditing) return;
      if (!this.$router.currentRoute.fullPath.startsWith("/edit-training"))
        return;
      if (
        JSON.stringify(this.training, this.censorJson(this.training)) ==
          this.lastJson &&
        !force
      )
        return;
      this.lastJson = JSON.stringify(
        this.training,
        this.censorJson(this.training)
      );
      if (
        this.$store.state.currentTraining != -1 &&
        this.training != null &&
        this.training.title != "-"
      ) {
        axios
          .post(
            `${this.$store.state.api_link}/training/edit/${this.$store.state.currentTraining}`,
            { data: this.lastJson },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.alertMessage = "";
            } else {
              this.alertMessage = this.toLocal(
                "training_edit.save_error"
              );
              console.log("Error while trying to save training");
            }
          })
          .catch(() => {
            this.alertMessage = this.toLocal(
              "training_edit.save_error"
            );
            console.log("Server error while trying to save training");
          });
      }
    },
    toggleEditItem(state, topic_index, item_index) {
      let current_item = this.training.content[topic_index].items[item_index];
      let current_item_is_half = current_item.half;
      if (state == false) {
        this.fullscreenPartsIndexList = [];
      } else if (state == true) {
        this.fullscreenPartsIndexList = [topic_index + "-" + item_index];
      }
      if (this.$refs[`item-${topic_index}-${item_index}`][0]) {
        if (this.$refs[`item-${topic_index}-${item_index}`][0].setIsEditing) {
          this.$refs[`item-${topic_index}-${item_index}`][0].setIsEditing(
            state
          );
        }
      }
      if (current_item_is_half && this.isLeftHalf(topic_index, item_index)) {
        if (
          this.training.content[topic_index] &&
          this.$refs[`item-${topic_index}-${item_index + 1}`] &&
          this.training.content[topic_index].items[item_index + 1] &&
          this.training.content[topic_index].items[item_index + 1].half
        ) {
          this.$refs[`item-${topic_index}-${item_index + 1}`][0].setIsEditing(
            state
          );
        }
      } else {
        if (
          this.training.content[topic_index] &&
          this.$refs[`item-${topic_index}-${item_index - 1}`] &&
          this.training.content[topic_index].items[item_index - 1] &&
          this.training.content[topic_index].items[item_index - 1].half
        ) {
          this.$refs[`item-${topic_index}-${item_index - 1}`][0].setIsEditing(
            state
          );
        }
      }
    },
    deleteTraining() {
      this.deletingTraining = true;
    },
    deleteTrainingRequest() {
      this.$store.state.loading.loadingStart();
      axios
        .post(
          `${this.$store.state.api_link}/training/delete`,
          { id: this.$store.state.currentTraining },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$router.push("/edit");
          this.$store.state.currentTraining = -1;
          this.$store.state.loading.loadingDone();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dublicateTraining() {
      this.$store.state.loading.loadingStart();
      axios
        .post(
          `${this.$store.state.api_link}/training/dublicate`,
          { id: this.$store.state.currentTraining },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$router.push("/edit");
          this.$store.state.currentTraining = -1;
          this.$store.state.loading.loadingDone();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openImageGallery(toTopic, toIndex, toAnswer = null) {
      this.galleryUploadTo = [toTopic, toIndex, toAnswer];
      setTimeout(() => {
        this.imageGalleryOpen = true;
        axios
          .get(
            `${this.$store.state.api_link}/image-gallery`,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            // console.log(response);
            this.galleryFiles = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 10);
    },
    openVideoGallery(toTopic, toIndex) {
      this.galleryUploadTo = [toTopic, toIndex];
      setTimeout(() => {
        this.videoGalleryOpen = true;
        axios
          .get(
            `${this.$store.state.api_link}/video-gallery`,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            // console.log(response);
            this.galleryFiles = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 10);
    },

    setTaskType(toTopic, toIndex, type) {
      this.$set(
        this.training.content[toTopic].items[toIndex],
        "taskType",
        type
      );
      this.$set(
        this.training.content[toTopic].items[toIndex],
        "content",
        "<p>" + this.toLocal("training_edit.new_question") + "</p>"
      );
      if (type == 1) {
        let answers = [
          {
            text: this.toLocal("training_edit.answer_yes"),
            correct: true,
          },
          {
            text: this.toLocal("training_edit.answer_no"),
            correct: false,
          },
        ];
        this.$set(
          this.training.content[toTopic].items[toIndex],
          "answers",
          answers
        );
        this.setTaskCommentTab(toTopic, toIndex, 1);
      }
      if (type == 2) {
        this.$set(this.training.content[toTopic].items[toIndex], "answers", []);
        this.setTaskCommentTab(toTopic, toIndex, 1);
      }
      if (type == 3) {
        let answers = [
          {
            part1: this.toLocal("generic.question"),
            part2: this.toLocal("generic.answer"),
          },
        ];
        this.$set(
          this.training.content[toTopic].items[toIndex],
          "answers",
          answers
        );
        this.setTaskCommentTab(toTopic, toIndex, 0);
      }
      // this.$forceUpdate();
    },
    getTaskType(topic, index) {
      return this.training.content[topic].items[index].taskType;
    },
    endTaskEditing() {
      if (this.currentTaskEditing[0] == -1) return;
      let arr = this.currentTaskEditing;
      let item = this.training.content[arr[0]].items[arr[1]];

      let content = item.editor.getHTML();
      content = content.split("<h2>").join("<h3>").split("</h2>").join("</h3>");
      content = content.split("<a href").join("<a target='_blank' href");

      item.editing = false;
      item.content = content;

      item.editor.destroy();

      this.currentTaskEditing = [-1, -1];

      this.disableItemDrag = false;
    },
    endTaskEditingOnOutsideClick(topicIndex, itemIndex) {
      setTimeout(() => {
        if (
          topicIndex == this.currentTaskEditing[0] &&
          itemIndex == this.currentTaskEditing[1]
        ) {
          this.endTaskEditing();
        }
      }, 50);
    },
    setTaskAnswer(topicIndex, itemIndex, answerIndex, partIndex, topicTitle) {
      if (topicTitle == "") topicTitle = "-";
      if (partIndex == 0) {
        this.training.content[topicIndex].items[itemIndex].answers[
          answerIndex
        ].part1 = topicTitle;
      } else if (partIndex == 1) {
        this.training.content[topicIndex].items[itemIndex].answers[
          answerIndex
        ].part2 = topicTitle;
      } else {
        this.training.content[topicIndex].items[itemIndex].answers[
          answerIndex
        ].text = topicTitle;
      }
      this.currentTaskAnswerEditing = [-1, -1, -1, -1];
    },

    setTaskCommentTab(topicIndex, itemIndex, tabIndex) {
      this.$set(
        this.training.content[topicIndex].items[itemIndex],
        "comment_tab",
        tabIndex
      );
    },

    closeGallery() {
      // this.imageGalleryOpen = false;
      this.videoGalleryOpen = false;
    },
    closeNotes() {
      if (this.$store.state.currentTraining == -1 || !this.notesOpen) return;
      this.notesOpen = false;

      axios
        .post(
          `${this.$store.state.api_link}/training/notes/set`,
          { id: this.$store.state.currentTraining, notes: this.notes },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
          } else {
            console.log("Error while getting notes");
          }
        })
        .catch(() => {
          console.log("Error while getting notes");
        });
    },
    closeCertificate() {
      if (this.$store.state.currentTraining == -1 || !this.certificateOpen)
        return;
      this.certificateOpen = false;
    },
    setCertificateBackground(background) {
      this.$set(this.training.certificate, "background", background);
      this.$nextTick(() => {
        if (this.selectedCert != null) {
          if (this.selectedCert.default_expiration == 0)
            this.$set(this.training.certificate, "expires", false);
          else {
            this.$set(this.training.certificate, "expires", true);
            this.$set(
              this.training.certificate,
              "expiration",
              this.selectedCert.default_expiration
            );
          }
        }
      });
    },
    galleryImageClick(img) {
      if (this.galleryUploadTo[0] == -2) {
        this.training.cover = img;
        this.imageGalleryOpen = false;
        return;
      }
      if (this.galleryUploadTo.length > 2 && this.galleryUploadTo[2] != null) {
        if (this.training.content[this.galleryUploadTo[0]].test) {
          if (
            this.galleryUploadTo[2] >
            this.searchAvailableTestQuestions[
              this.training.content[this.galleryUploadTo[0]].questionsindex
            ].questions[this.galleryUploadTo[1]].answers.length -
              1
          ) {
            let answer = {
              image: this.$store.state.api_uploads_link + img,
              correct: false,
            };
          }
        } else {
          if (
            this.galleryUploadTo[2] >
            this.training.content[this.galleryUploadTo[0]].items[
              this.galleryUploadTo[1]
            ].answers.length -
              1
          ) {
            let answer = {
              image: this.$store.state.api_uploads_link + img,
              correct: false,
            };
            this.training.content[this.galleryUploadTo[0]].items[
              this.galleryUploadTo[1]
            ].answers.push(answer);
          } else {
            this.training.content[this.galleryUploadTo[0]].items[
              this.galleryUploadTo[1]
            ].answers[this.galleryUploadTo[2]].image =
              this.$store.state.api_uploads_link + img;
          }
        }
      } else {
        this.training.content[this.galleryUploadTo[0]].items[
          this.galleryUploadTo[1]
        ].content = this.$store.state.api_uploads_link + img;
      }
      this.training.content[this.galleryUploadTo[0]].items[
        this.galleryUploadTo[1]
      ].editing = false;
      this.imageGalleryOpen = false;
    },
    galleryVideoClick(video) {
      this.training.content[this.galleryUploadTo[0]].items[
        this.galleryUploadTo[1]
      ].editing = false;
      this.training.content[this.galleryUploadTo[0]].items[
        this.galleryUploadTo[1]
      ].content = this.$store.state.api_uploads_link + video;
      this.videoGalleryOpen = false;
    },
    onDragOver(event, topicIndex, itemIndex, position) {
      event.preventDefault();
      if (!this.isDragAreaActive(topicIndex, itemIndex, position))
        this.activeDragArea = [topicIndex, itemIndex, position];
    },
    onDragLeave(topicIndex, itemIndex, position) {
      this.activeDragArea = [-1, -1, -1];
    },
    isDragAreaActive(topicIndex, itemIndex, position) {
      return (
        this.activeDragArea[0] == topicIndex &&
        this.activeDragArea[1] == itemIndex &&
        this.activeDragArea[2] == position
      );
    },
    setTrainingType(type) {
      this.$set(this.training, 'trainingType', type)
      if (type == 'scorm') {
        if (this.training.content) {
          let scorm_packages = this.training.content.filter(item => {
            return item.items.filter(s_item => {
              return s_item.type=='scorm'
            }).length > 0
          })
          if(scorm_packages.length == 0) {
            this.addItem(0, 0, 'scorm', false)
          }
        }
      }
      else if (type == 'attest') {
        if (!this.hasTest) {
          const newItem = {
                id: this.training.topic_iter + 1,
                title: this.toLocal("training.attesting"),
                test: true,
                enabled: true,
                editingTitle: false,
                innerScrollTop: -1,
                visibleItems: [],
                testValidFrom: "2020",
                testExpiresAt: "2020",
                testPassedFrom: 80,
                testShownQuestionCount: 10,
                testAnswerShowType: 0,
                testQuestionsShuffle: false,
                testAnswersShuffle: false,
                testResultShowType: 0,
                addingQuestion: false,
                items: [],
            };
            this.training.content.splice(this.training.content.length, 0, newItem);
            this.$set(this.training, "topic_iter", this.training.topic_iter + 1);
          }
        this.$set(this.training, "published", true);
      }
      else if (type == 'singleDoc') {
        if(this.training.content.length == 1) {
          if(this.training.content[0].items.length == 0) {
            this.addItem(0, 0, 'doc', false)
          }
        }
      }
    },
    trainingGroupChange(group) {
      axios
        .post(
          `${this.$store.state.api_link}/training/set-training-group`,
          {
            training: this.$store.state.currentTraining,
            training_group: group.id,
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
          } else {
            console.log("Error while setting group");
          }
        })
        .catch(() => {
          console.log("Error while setting group");
        });
    },
    toggleItemDrag(value) {
      this.disableItemDrag = value;
    },
    editWritingItem(topicIndex, itemIndex) {
      if (this.currentTaskEditing[0] != -1) {
        let arr = this.currentTaskEditing;
        let item = this.training.content[arr[0]].items[arr[1]];
        item.editor.setContent(item.title);
        let content = item.editor.getHTML();
        content = content
          .split("<h2>")
          .join("<h3>")
          .split("</h2>")
          .join("</h3>");
        content = content.split("<a href").join("<a target='_blank' href");
        item.editing = false;
        item.title = content;
      }
      setTimeout(() => {
        this.currentEtherpadEditing = [topicIndex, itemIndex];
        let item = this.training.content[topicIndex].items[itemIndex];
        item.editor.setContent(item.title);
        item.editing = true;
        let content = item.editor.getHTML();
        content = content
          .split("<h2>")
          .join("<h3>")
          .split("</h2>")
          .join("</h3>");
        content = content.split("<a href").join("<a target='_blank' href");
        item.title = content;
        this.disableItemDrag = true;
      }, 10);
    },

    saveMetadata(e, item, answer = null) {
      if (Object.keys(e[0].getMetadata()).length != 0) {
        if (answer == null)
          this.$set(item, "metadata", {
            data: JSON.stringify(e[0].getMetadata()),
            height: e[1],
          });
        else
          this.$set(item.answers[answer], "metadata", {
            data: JSON.stringify(e[0].getMetadata()),
            height: e[1],
          });
      }
    },

    saveTestData(test) {
      this.lastSavedTest = test;
      setTimeout(() => {
        this.lastSavedTest = null;
      }, 1000);
      let index = this.availableTestQuestions.findIndex(
        (el) => el.id === test.id
      );
      if (index != -1) {
        this.$set(this.availableTestQuestions, index, test);
      }
    },
    setSelectedTestData(topic_index, test, folder_id) {
      if (!this.$store.state.selectTestsModeActive) {
        let folderIndex = this.availableTestQuestions.findIndex(
          (folder) => folder.id === folder_id
        );
        if (folderIndex != -1) {
          let test_index = this.availableTestQuestions[
            folderIndex
          ].tests.findIndex((el) => test.id === el.id);
          if (test_index != -1) {
            this.availableTestQuestions[folderIndex].tests[test_index] = test;
            this.selectedTestID = test.id;
          }
        }
      } else {
        let index = this.$store.state.selectModeSelectedTests.findIndex(
          (el) => test.id === el.test_id
        );
        if (index != -1) {
          this.$store.state.selectModeSelectedTests[index].test = test;
          this.$set(
            this.$store.state.selectModeSelectedTests[index],
            "questions",
            test.questions.map((q) => (q = { id: q.id, score: q.score }))
          );
        }
      }
      this.availableTests = this.availableTestQuestions
        .map((el) => {
          return el.tests;
        })
        .flat();
      this.availableTests[
        this.availableTests.findIndex((el) => test.id == el.id)
      ] = test;
    },
    deleteTestItem() {
      let folder_id = this.$store.state.testDeleteModal.folder.id;
      let type = this.$store.state.testDeleteModal.type;
      let folderIndex = this.availableTestQuestions.findIndex(
        (folder) => folder.id === folder_id
      );
      if (type == "test") {
        let test_id = this.$store.state.testDeleteModal.test.id;
        let test_index = this.availableTestQuestions[
          folderIndex
        ].tests.findIndex((el) => test_id === el.id);
        if (test_index != -1) {
          this.availableTestQuestions[folderIndex].tests.splice(test_index, 1);
        }
        axios
          .post(
            `${this.$store.state.api_link}/test-question-groups/delete`,
            { id: test_id },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {});
      } else {
        folderIndex = this.availableTestQuestions.findIndex(
          (folder) => folder.id === folder_id
        );
        this.availableTestQuestions.splice(folderIndex, 1);
        axios
          .delete(
            `${this.$store.state.api_link}/test-question-groups/folders/${folder_id}`,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {});
      }
      this.$store.state.testDeleteModal = {
        folder: {},
        test: {},
        modalOpened: false,
        type: "test",
      };
    },
    openNotes() {
      axios
        .post(
          `${this.$store.state.api_link}/training/notes/get`,
          { id: this.$store.state.currentTraining },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.notes = response.data.notes;
          } else {
            console.log("Error while getting notes");
          }
        })
        .catch(() => {
          console.log("Error while getting notes");
        });
      setTimeout(() => {
        this.notesOpen = true;
      }, 10);
    },
    editTopicTitle(topicIndex) {
        setTimeout(() => {
            this.training.content[topicIndex].editingTitle = true;

            setTimeout(() => {
            this.$refs[`trainingTopic-${topicIndex}`][0].value =
                this.training.content[topicIndex].title;
            }, 10);
        }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
@import "@/assets/scss/views/_trainingEditViewScoped.scss";

.info-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  background: var(--secondary-color);
  .close {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }
}
.publish-container {
  width: 100%;
  margin-top: 24px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    padding: 6px 8px 6px 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

    border: none;
    width: 250px;
    cursor: pointer;
    font-size: 18px;
    line-height: 26px;
    border-radius: 12px;
    background: var(--secondary-color);
    color: var(--primary-color);
    transition: all 0.3s;
    &.active {
      background: var(--third-color);
      color: var(--primary-color);
      svg path {
        fill: white;
      }
    }
    &:hover {
      background: var(--primary-color);
      color: white;
    }
    svg {
      margin-right: 12px;
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/views/_trainingEditView.scss";
.training-type-dropdown{
  background-color: #333333;
  max-width: 250px;
  border-radius: 8px;
  margin-top: 25px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  &.condition-filter-dropdown.dropdown-container:not(.no-padding-no-bg){
    background-color: #333333;
    padding: 8px 12px;
  }
  &.dropdown-container .dropdown-initial{
    color: white;
    // font-size: 20px;
    font-weight: 600;
  }
  &.dropdown-container{
    .dropdown-extended .dropdown-option{
      margin: 0;
      padding: 8px 12px;
    }
    .dropdown-extended .dropdown-option:hover{
      background-color: #E2E2E2;
      font-weight: 500;
    }
  }
}
.languages-btn-container {
  margin-top: 24px;
  width: 250px;
  .languages-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s;
    &:hover {
      background: var(--secondary-color);
    }
    svg {
      margin-right: 8px;
    }
    span {
      font-family: var(--main-site-font);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
