<template>
  <div class="main-container">
    <div class="header">
      <GroupsTitle class="group-title" :group="group"/>
      <div class="header-bottom">
        <div class="upper-half">
          <!-- <span>Vartotojams elementus rodyti: </span>
                <div class="button-switch">
                    <div class="backdrop" :class="{right: showElements == 2}"></div>
                    <span class="button" :class="{active: showElements == 1}" @click="showElements = 1">Nustatytu laiku</span>
                    <span class="button" :class="{active: showElements == 2}" @click="showElements = 2">Tik perziurejus ankstesni</span>
                </div> -->
        </div>
        <div class="lower-half">
          <div class="search-container col-container">
            <span class="col-title"
              >{{ toLocal("groups.assigned_elements") }}:
              {{ elements.length }}</span
            >
            <div class="search-box">
              <SearchIconSVG v-if="searchTerm == ''" />
              <input
                type="text"
                class="search-input"
                :class="{ padded: searchTerm == '' }"
                :placeholder="toLocal('generic.search')"
                v-model="searchTerm"
              />
            </div>
          </div>
          <div class="filter-container">
            <div class="filter-one col-container">
              <span class="col-title"
                >{{ toLocal("groups.elements_in_folders") }}:</span
              >
              <CustomDropdown
                class="c-dropdown"
                key-label="id"
                value-label="name"
                :list="folders"
                :compare-list="chosenFolders"
                :options="{
                  closeOnClick: false,
                  checkbox: true,
                  customDDWidth: '100%',
                  customMaxHeight: '300px',
                  initial: 'Keli aplankai',
                  selectAllChoice: toLocal('users.many_folders'),
                  containerClass: 'groups-table-dropdown',
                  searchBar: true,
                  createNewAtEmpty: false,
                  showCreateNewBtn:false
                }"
                @elClicked="changeFolderFilter"
                @elAll="toggleAllFolders"
              />
            </div>
            <div class="filter-two col-container">
              <span class="col-title">{{ toLocal("group_side.sort_by") }}</span>
              <CustomDropdown
                class="c-dropdown"
                key-label="id"
                value-label="name"
                :list="sortFunctions"
                :compareChosen="chosenSort"
                :options="{
                  closeOnClick: true,
                  checkbox: false,
                  customDDWidth: '115%',
                  customMaxHeight: '300px',
                  initial:
                    chosenSort != ''
                      ? chosenSort.name
                      : 'Pasirinkite rūšiavimą',
                  containerClass: 'groups-table-dropdown',
                  searchBar: true,
                  showCreateNewBtn:false
                }"
                @elClicked="changeSort"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-container">
      <div
        class="single-item"
        v-for="(element, idx) in elementsCopy"
        :key="`element-${idx}`"
      >
        <div class="image">
          <img
            :src="
              getFileUrl(element.cover)
                ? getFileUrl(element.cover)
                : './img/pia21974.0.17df4049.jpg'
            "
            onerror="this.src='./img/pia21974.0.17df4049.jpg'"
            :alt="element.title"
          />
        </div>
        <div class="text-side">
          <div class="title">
            {{ element.title }}
          </div>
          <div class="item-bottom-half">
            <div class="row">
              <span class="text-title">{{
                toLocal("group_side.average_score")
              }}</span>
              <span class="result">{{
                element.average_result ? element.average_result + "%" : "-"
              }}</span>
            </div>
            <div class="row gapped">
              <span class="text-title"
                >{{ toLocal("groups.element_opens") }}:</span
              >
              <div class="time-select">
                <span class="front-text"> {{ toLocal("mail.after") }} </span>
                <input
                  @input="
                    (e) =>
                      changeElementInfo(e, element, elementInfoIDs[element.id])
                  "
                  placeholder="0"
                  v-model="elementInfoIDs[element.id].time"
                  type="number"
                  :class="{ grayed: element.accessible_after == 0 }"
                />
                <CustomDropdown
                  class="white-c-dropdown"
                  :key="updateKey"
                  :class="{ grayed: element.accessible_after == 0 }"
                  :list="elementEnablingDD"
                  :compareChosen="elementInfoIDs[element.id].typeObj"
                  @elClicked="changeElementType"
                  :options="{
                    closeOnClick: true,
                    checkbox: false,
                    customDDWidth: '150%',
                    customMaxHeight: '300px',
                    initial: elementInfoIDs[element.id].typeObj
                      ? elementInfoIDs[element.id].typeObj.short
                      : '',
                    containerClass: 'groups-table-dropdown',
                    searchBar: false,
                    attached: {
                      type: 'after',
                      element: element,
                      element_idx: idx,
                    },
                  }"
                />
                <span class="front-text">
                  {{ toLocal("training_edit.test_questions_or") }}
                </span>
                <div
                  class="time-select-btn"
                  :class="{ active: element.accessible_after == 0 }"
                  @click="clearElementTime('after', element)"
                >
                  {{ toLocal("groups.instant") }}
                </div>
              </div>
            </div>
            <div class="row gapped">
              <span class="text-title"
                >{{ toLocal("group.element_repeats") }}:</span
              >
              <div class="time-select">
                <span class="front-text"> {{ toLocal("mail.every") }} </span>
                <input
                  @input="
                    (e) =>
                      changeElementInfo(e, element, elementInfoIDs[element.id])
                  "
                  placeholder="0"
                  v-model="elementInfoIDs[element.id].repeatTime"
                  type="number"
                  :class="{ grayed: element.training_repeats_every == 0 }"
                />

                <CustomDropdown
                  class="white-c-dropdown"
                  :key="updateKey"
                  :class="{ grayed: element.training_repeats_every == 0 }"
                  :list="elementRepeatingDD"
                  :compareChosen="elementInfoIDs[element.id].repeatingTypeObj"
                  :options="{
                    closeOnClick: true,
                    checkbox: false,
                    customDDWidth: '150%',
                    customMaxHeight: '300px',
                    initial: elementInfoIDs[element.id].repeatingTypeObj
                      ? elementInfoIDs[element.id].repeatingTypeObj.short
                      : '',
                    containerClass: 'groups-table-dropdown',
                    searchBar: false,
                    attached: {
                      type: 'repeat',
                      element: element,
                      element_idx: idx,
                    },
                  }"
                  @elClicked="changeElementType"
                />
                <span class="front-text">
                  {{ toLocal("training_edit.test_questions_or") }}
                </span>
                <div
                  class="time-select-btn"
                  :class="{ active: element.training_repeats_every == 0 }"
                  @click="clearElementTime('repeat', element)"
                >
                  {{ toLocal("group.doesnt_repeat") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SearchIconSVG from "@/assets/images/icons/users/search.svg?inline";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import GroupsTitle from "@/components/layout/groups/GroupsTitle.vue";

export default {
  name: "GroupSelectedItems",
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  components: {
    SearchIconSVG,
    CustomDropdown,
    GroupsTitle,
  },
  data: function () {
    return {
      updateKey: 0,
      elements: [],
      elementsCopy: [],
      searchTerm: "",
      folders: [],
      sortFunctions: [
        {
          name: this.toLocal("groups.element_after_time") + " ↑",
          id: 1,
        },
        {
          name: this.toLocal("groups.element_after_time") + " ↓",
          id: 2,
        },
        {
          name: this.toLocal("groups.element_repeat_time") + " ↑",
          id: 3,
        },
        {
          name: this.toLocal("groups.element_repeat_time") + " ↓",
          id: 4,
        },
        {
          name: this.toLocal("users.sort_name_first") + " ↑",
          id: 5,
        },
        {
          name: this.toLocal("users.sort_name_last") + " ↓",
          id: 6,
        },
        {
          name: this.toLocal("groups.average_rating") + " ↑",
          id: 7,
        },
        {
          name: this.toLocal("groups.average_rating") + " ↓",
          id: 8,
        },
      ],
      chosenFolders: [],
      chosenSort: {
        name: this.toLocal("groups.element_after_time") + " ↑",
        id: 1,
      },
      showElements: 1,
      isLoading: false,
      afterTime: 0,
      repeatTime: 0,
      elementEnablingDD: [
        {
          id: 0,
          name: "generic.hours_multiple",
          short: "generic.hours_short",
        },
        {
          id: 1,
          name: "generic.days_multiple",
          short: "generic.days_short",
        },
        {
          id: 2,
          name: "generic.weeks_multiple",
          short: "generic.weeks_short",
        },
      ],
      elementInfoIDs: [],
      elementRepeatingDD: [
        {
          id: 0,
          name: "generic.days_multiple",
          short: "generic.days_short",
        },
        {
          id: 1,
          name: "generic.weeks_multiple",
          short: "generic.weeks_short",
        },
        {
          id: 2,
          name: "generic.months_multiple",
          short: "generic.months_short",
        },
        {
          id: 3,
          name: "generic.years_multiple",
          short: "generic.years_short",
        },
      ],
    };
  },
  mounted() {
    this.getGroupElements();
  },
  methods: {
    changeSort(id) {
      this.chosenSort = this.sortFunctions[id - 1];
      this.sortElements();
    },
    sortElements() {
      let id = this.chosenSort.id;
      switch (id) {
        case 1:
          this.elementsCopy.sort((a, b) =>
            a.accessible_after > b.accessible_after
              ? -1
              : b.accessible_after > a.accessible_after
              ? 1
              : 0
          );
          break;
        case 2:
          this.elementsCopy.sort((a, b) =>
            a.accessible_after > b.accessible_after
              ? 1
              : b.accessible_after > a.accessible_after
              ? -1
              : 0
          );
          break;
        case 3:
          this.elementsCopy.sort((a, b) =>
            a.training_repeats_every > b.training_repeats_every
              ? -1
              : b.training_repeats_every > a.training_repeats_every
              ? 1
              : 0
          );
          break;
        case 4:
          this.elementsCopy.sort((a, b) =>
            a.training_repeats_every > b.training_repeats_every
              ? 1
              : b.training_repeats_every > a.training_repeats_every
              ? -1
              : 0
          );
          break;
        case 5:
          this.elementsCopy.sort((a, b) =>
            a.title > b.title ? 1 : b.title > a.title ? -1 : 0
          );
          break;
        case 6:
          this.elementsCopy.sort((a, b) =>
            a.title > b.title ? -1 : b.title > a.title ? 1 : 0
          );
          break;
        case 7:
          this.elementsCopy.sort((a, b) =>
            a.average_result > b.average_result
              ? 1
              : b.average_result > a.average_result
              ? -1
              : 0
          );
          break;
        case 8:
          this.elementsCopy.sort((a, b) =>
            a.average_result > b.average_result
              ? -1
              : b.average_result > a.average_result
              ? 1
              : 0
          );
          break;
      }
    },
    saveTraining: _.debounce((training, self) => {
      axios.post(
        `${self.$store.state.api_link}/group/trainings/edit`,
        {
          trainings: [training],
          group_id: self.group.id,
        },
        self.$store.getters.axiosConfig()
      );
    }, 1000),
    changeElementType(id, attached) {
      if (attached.type == "repeat") {
        let type = this.elementRepeatingDD.filter((el) => el.id == id)[0];
        this.elementInfoIDs[attached.element.id].repeatType = type.id;
        this.elementInfoIDs[attached.element.id].repeatingTypeObj = type;
      } else {
        let type = this.elementEnablingDD.filter((el) => el.id == id)[0];
        this.elementInfoIDs[attached.element.id].type = type.id;
        this.elementInfoIDs[attached.element.id].typeObj = type;
      }
      this.changeElementInfo(
        null,
        this.elements[attached.element_idx],
        this.elementInfoIDs[attached.element.id]
      );
      this.updateKey += 1;
    },
    changeElementInfo(e, element, info) {
      element.accessible_after = parseInt(info.time);
      element.accessible_after_type = info.type;
      element.training_repeats_every = parseInt(info.repeatTime);
      element.training_repeats_every_type = info.repeatType;
      this.saveTraining(element, this);
    },
    clearElementTime(type, element) {
      if (type == "after") {
        if (element.accessible_after == 0) return;
        element.accessible_after = 0;
        element.accessible_after_type = 0;
        this.elementInfoIDs[element.id].time = 0;
        this.elementInfoIDs[element.id].type = 0;
        this.elementInfoIDs[element.id].typeObj = this.elementEnablingDD[0];
      } else if (type == "repeat") {
        if (element.training_repeats_every == 0) return;
        element.training_repeats_every = 0;
        element.training_repeats_every_type = 0;
        this.elementInfoIDs[element.id].repeatTime = 0;
        this.elementInfoIDs[element.id].repeatType = 0;
        this.elementInfoIDs[element.id].repeatingTypeObj =
          this.elementRepeatingDD[0];
      }
      this.saveTraining(element, this);
    },
    getGroupElements() {
      this.isLoading = true;
      axios
        .get(
          `${this.$store.state.api_link}/group/${this.group.id}/trainings`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.elements = response.data.trainings;
          this.folders = response.data.training_groups;
          this.chosenFolders = _.cloneDeep(this.folders);
          this.elements.forEach((el) => {
            this.elementInfoIDs[el.id] = {
              id: el.id,
              time: el.accessible_after,
              type: el.accessible_after_type,
              typeObj: this.elementEnablingDD[el.accessible_after_type],
              repeatTime: el.training_repeats_every,
              repeatType: el.training_repeats_every_type,
              repeatingTypeObj:
                this.elementRepeatingDD[el.training_repeats_every_type],
            };
          });
          this.elementsCopy = _.cloneDeep(this.elements);
          this.sortElements();
        });
    },
    toggleAllFolders() {
      let allFolders = this.folders.map((f) => {
        return f.id;
      });
      let checkedFolders = this.chosenFolders.map((f) => {
        return f.id;
      });
      if (allFolders.every((f) => checkedFolders.includes(f))) {
        this.chosenFolders = [];
      } else {
        this.chosenFolders = _.cloneDeep(this.folders);
      }
      this.filterElements();
    },
    changeFolderFilter(id) {
      let isAlreadyChecked =
        this.chosenFolders.filter((f) => {
          return f.id == id;
        }).length > 0;
      if (isAlreadyChecked) {
        let idx = this.chosenFolders.findIndex((folder) => {
          return folder.id == id;
        });
        this.chosenFolders.splice(idx, 1);
      } else {
        let fIdx = this.folders.findIndex((folder) => {
          return folder.id == id;
        });
        this.chosenFolders.push(this.folders[fIdx]);
      }
      this.filterElements();
    },
    filterElements() {
      this.elementsCopy = this.elements.filter((el) => {
        return (
          el.training_groups.length == 0 ||
          this.chosenFolders.filter((folder) => {
            return folder.id == el.training_groups[0].id;
          }).length > 0
        );
      });
    },
    searchElements(val) {
      if (val == "") {
        this.elementsCopy = _.cloneDeep(this.elements);
      } else {
        this.elementsCopy = this.elements.filter((el) =>
          el.title.toLowerCase().includes(val.toLowerCase())
        );
      }
      this.sortElements();
    },
  },
  watch: {
    searchTerm: {
      handler(newVal) {
        this.searchElements(newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  .header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0;
    .group-title {
      color: #333;
      font-weight: 600;
      font-size: clamp(16px, 1.2vw, 18px);
      padding: 0 16px;
    }
  }
}

.header {
  .header-bottom {
    .upper-half {
      font-size: clamp(12px, 1.2vw, 14px);
      display: flex;
      gap: 8px;
      align-items: center;
      /* padding: 8px 16px; */
      border-bottom: 2px solid #e9e9e9;
    }
  }
}
.button-switch {
  display: flex;
  position: relative;
  background: #e9e9e9;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  padding: 4px 0;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 30px;
  gap: 40px;
  .backdrop {
    position: absolute;
    background: #4d4d4d;
    width: 45%;
    height: 100%;
    top: 0;
    z-index: 98;
    border-radius: 30px;
    transition: transform 0.25s, width 0.25s;
    &:not(.right) {
      transform: translateX(-15%);
    }
    &.right {
      transform: translateX(60%);
      width: 60%;
    }
  }
  .button {
    z-index: 99;
    cursor: pointer;
    user-select: none;
    transition: 0.25s;
    &:not(.active) {
      color: #4d4d4d;
    }
    &.active {
      color: white;
    }
  }
}

.lower-half {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  .col-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.col-container {
  .col-title {
    font-size: clamp(12px, 1.2vw, 14px);
  }
}

.search-container {
  .search-box {
    position: relative;
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      left: 8px;
    }
    input {
      padding: 4px 8px;
      border-radius: 44px;
      border: 1px solid #b2b2b2;
      font-size: clamp(12px, 1.2vw, 14px);
      &.padded {
        padding-left: 31px;
      }
    }
  }
}
.filter-container {
  display: flex;
  gap: 16px;
}

.c-dropdown {
  border-radius: 6px;
  color: white;
}
.item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 16px;
  height: 100%;
  padding: 0 16px;
  gap: 12px;
  overflow-y: auto;
}
.single-item {
  display: flex;
  width: 100%;
  border-radius: 12px;
  background: #e9e9e9;
  padding: 16px;
  align-items: center;
  .image {
    width: 160px;
    height: 160px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}

.text-side {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title {
    font-size: clamp(16px, 1.2vw, 18px);
    padding-bottom: 8px;
    width: 100%;
    border-bottom: 1px solid #b2b2b2;
  }
}

.item-bottom-half {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
  .row {
    display: flex;
    gap: 4px;
    font-size: clamp(12px, 1.2vw, 14px);
    align-items: center;
    .result {
      font-weight: 600;
    }
    &.gapped {
      gap: 16px;
    }
  }
}

.time-select {
  display: flex;
  gap: 8px;
  align-items: center;
  input {
    padding: 4px 2px;
    border-radius: 6px;
    border: 1px solid #b2b2b2;
    text-align: center;
    max-width: 48px;
    font-size: clamp(12px, 1.2vw, 14px);
  }
  .white-c-dropdown {
    border-radius: 6px;
    border: 1px solid #b2b2b2;
    background: #fff;
    color: hsla(0, 0%, 20%, 1);
    width: 90px;
    padding: 3px 10px;
    &:not(.no-padding-no-bg){
        background: white;
        
    }
    .dropdown-initial {
      font-size: clamp(12px, 1.2vw, 14px);
      .white path {
        stroke: hsla(0, 0%, 20%, 1);
      }
    }
  }
}
.time-select-btn {
  user-select: none;
  border-radius: 20px;
  background: #f4f4f4;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  font-size: clamp(12px, 1.2vw, 14px);
  color: #7d7d7d;
  padding: 4px 16px;
  cursor: pointer;
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #4d4d4d;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    color: white;
  }
  &.active {
    background: #4d4d4d;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    color: white;
  }
}

.grayed {
  background-color: #f4f4f4;
  color: #7d7d7d;
}
.time-select {
  .grayed {
    background-color: #f4f4f4;
    color: #7d7d7d;
    &.white-c-dropdown[data-v-511d4d4c]:not(.no-padding-no-bg){
        background: #f4f4f4;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style lang="scss">
.white-c-dropdown{
  &.dropdown-container .dropdown-initial{
    .white{
      path{
        stroke: hsla(0, 0%, 20%, 1);
      }
    }
  }
}
.time-select {
  .grayed {
     &.dropdown-container .dropdown-initial{
      .white{
        path{
          stroke: rgba(51, 51, 51, 0.425);
        }
      }
    }
  }
}
</style>