<template>
    <div class="tab-container">
        <div class="outer-container">
            <div class="expanded-gif" v-if="currentGif != ''">
            <div class="expanded-gif-inner">
                <div class="close" @click="closeExpanded">
                <CloseSVG />
                </div>
                <img class="gif" alt="gif" :src="getImageSrc(tab.imageOriginalURLPath, currentGif)" />
                <div
                class="select-btn"
                @click="returnGif(getImageSrc(tab.imageOriginalURLPath, currentGif))"
                >
                <span>{{ toLocal("generic.upload") }}</span> <UploadSVG />
                </div>
            </div>
            </div>
            <div class="upper-bar">
            <div class="search-box">
                <SearchSVG v-if="searchTerm == ''" />
                <input
                :class="{ hasOffset: searchTerm == '' }"
                type="text"
                :placeholder="toLocal('generic.search')"
                @input="searchGifs"
                v-model="searchTerm"
                />
            </div>
            <div class="tags">
                <div
                    class="tag"
                    v-for="(tag, idx) in tab.tags"
                    :key="idx"
                    @click="setTag(tag.value)"
                    :class="{ active: selectedTag == tag.value }"
                >
                {{ toLocal(tag.label) }}
                </div>
            </div>
            <div class="policy-btn" @click="openPolicy"><LicenseSVG /></div>
            </div>
            <div class="item-container" id="item-container" @scroll="checkIfEnd">
            <div
                class="gif-column"
                v-for="(column, idx) in itemColumns"
                :key="`column-${idx}`"
            >
                <div
                class="gif-item"
                v-for="(item, idx) in column"
                :key="`gif-${idx}-${item.id}`"
                @click="clickOnGif(item)"
                >
                <img :src="getImageSrc(tab.imageURLPath, item)" alt="gif" />
                </div>
            </div>
            <div class="loading-div" v-if="!isLoading"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LicenseSVG from "@/assets/images/icons/training-edit/gallery/giphy_license.svg?inline";
import SearchSVG from "@/assets/images/icons/training-edit/gallery/search.svg?inline";
import CloseSVG from "@/assets/images/icons/training-edit/gallery/close.svg?inline";
import UploadSVG from "@/assets/images/icons/training-edit/gallery/upload.svg?inline";
export default {
    name: "GalleryTab",
    props:{
        tab: Object
    },
    components: {
        LicenseSVG,
        SearchSVG,
        CloseSVG,
        UploadSVG,
    },
    data(){
        return{
            currentGif: "",
            itemColumns: [],
            isLoading: true,
            allItemsCount: 0,
            searchTerm: "",
            selectedTag: 'gifs',
            page: 1,
        }
    },
    mounted() {
        this.getTrendingGifs();
    },
    methods: {
        closeExpanded() {
            this.currentGif = "";
        },
        setTag(tag) {
            this.selectedTag = tag;
            this.debounceSearch(this);
        },
        clickOnGif(gif) {
            this.currentGif = gif;
        },
        openPolicy() {
            window.open(
                this.tab.policyURL,
                "_blank",
                "noreferrer"
            );
        },
        getQueryString(params, type){
            let q = '?'
            let index = 0;
            for (const [key, value] of Object.entries(params)) {
                if(index > 0) q+='&';
                let formatted_value = value;
                if(key == 'tag'){
                    formatted_value = this.selectedTag;
                }
                else if(key == 'type'){
                    formatted_value = type;
                }
                else if(key == 'offset'){
                    formatted_value = this.allItemsCount
                }
                else if(key == 'page'){
                    formatted_value = this.page
                }
                
                q+=`${key}=${formatted_value}`
                index++;
            }
            if(type == 'search'){
                q+=`&q=${this.searchTerm}`
            }
            return q;
        },
        getTrendingGifs() {
            this.isLoading = true;
            let url = `${this.$store.state.api_link}/gallery`;
            let query = this.getQueryString(this.tab.queryParams, 'trending');

            axios.get(`${url}${query}`,
                    this.$store.getters.axiosConfig()
                )
                .then((response) => {
                    let data = response.data.data[this.tab.APIDataLabel]
                    this.itemColumns = [];
                    let sliceCount = (data.length / 5).toFixed(0);
                    this.allItemsCount = data.length;
                    this.page = 2;
                    for (let i = 0; i < 5; i++) {
                        this.itemColumns.push(
                            data.slice(
                                i * sliceCount,
                                i * sliceCount + parseInt(sliceCount)
                            )
                        );
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    console.log("Error while loading gifs");
                });
        },
        loadMoreContent() {
            this.isLoading = true;
            let url = `${this.$store.state.api_link}/gallery`;
            let query = ''
            if (this.searchTerm != "") {
                query =  this.getQueryString(this.tab.queryParams, 'search');
            }
            else{
                query = this.getQueryString(this.tab.queryParams, 'trending');
            }
            axios
                .get(`${url}${query}`, this.$store.getters.axiosConfig())
                .then((response) => {
                    let data = response.data.data[this.tab.APIDataLabel]
                    let sliceCount = (data.length / 5).toFixed(0);
                    this.allItemsCount += data.length;
                    this.page = this.page + 1;
                    for (let i = 0; i < 5; i++) {
                        this.itemColumns[i] = this.itemColumns[i].concat(
                        data.slice(
                            i * sliceCount,
                            i * sliceCount + parseInt(sliceCount)
                        )
                        );
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                console.log("Error while loading gifs");
                });
        },
        searchGifs(e) {
            this.debounceSearch(this);
        },
        debounceSearch: _.debounce((self) => {
            if (self.searchTerm == "") {
                self.getTrendingGifs();
                return;
            }
            self.isLoading = true;
            let url = `${self.$store.state.api_link}/gallery`;
            let query = self.getQueryString(self.tab.queryParams, 'search');
            axios
                .get(
                `${url}${query}`,
                self.$store.getters.axiosConfig()
                )
                .then((response) => {
                    let data = response.data.data[self.tab.APIDataLabel]
                    self.itemColumns = [];
                    let sliceCount = (data.length / 5).toFixed(0);
                    self.allItemsCount = data.length;
                    for (let i = 0; i < 5; i++) {
                        self.itemColumns.push(
                            data.slice(
                                i * sliceCount,
                                i * sliceCount + parseInt(sliceCount)
                            )
                        );
                    }
                    self.isLoading = false;
                })
                .catch(() => {
                console.log("Error while loading gifs");
                });
        }, 500),
        checkIfEnd() {
            if (this.itemColumns[0].length > 3) {
                let container = $("#item-container")[0];
                if (
                container.scrollTop + parseInt(container.clientHeight) >=
                    container.scrollHeight - parseInt(container.clientHeight) / 2 &&
                !this.isLoading
                ) {
                this.loadMoreContent();
                }
            }
        },
        returnGif(gif) {
            axios.post(
                `${this.$store.state.api_link}/gallery/usage`,
                {
                'item': gif,
                },
                this.$store.getters.axiosConfig()
            );
            this.$emit("returnGif", gif);
        },
        getImageSrc(path, item){
            let url_path = path;
            url_path = url_path.split('.');
            return this._.get(item, url_path);
        },
      
    },
}
</script>

<style lang="scss" scoped>
.tab-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}
.upper-bar {
  display: flex;
  justify-content: space-between;
  padding: 20px 28px;
  padding-bottom: 0;
  .search-box {
    position: relative;
    display: flex;
    align-items: center;
    input {
      padding: 4px 8px;
      border-radius: 44px;
      border: 1px solid #b2b2b2;
      background: #fff;
      font-size: 14px;
      line-height: 20px;
      &.hasOffset {
        padding-left: 28px;
        padding-right: 0;
      }
    }
    svg {
      position: absolute;
      left: 8px;
    }
  }
  .tags {
    display: flex;
    justify-content: center;
    gap: 12px;
    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #b2b2b2;
      background: #fff;
      transition: 0.25s;
      cursor: pointer;
      &:hover {
        background: #4d4d4d;
        color: white;
      }
      &.active {
        background: #4d4d4d;
        color: white;
      }
    }
  }
  .policy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    background: #e2e2e2;
    padding: 0px 8px;
    transition: 0.25s;
    svg {
      g {
        transition: 0.25s;
      }
      path {
        transition: 0.25s;
      }
    }
    &:hover {
      background: #4d4d4d;
      svg {
        g {
          fill: white;
        }
        path:nth-child(2) {
          fill: white;
        }
        path:nth-child(3) {
          fill: black;
        }
      }
    }
  }
}
.outer-container {
  height: 100%;
  position: relative;
}
.expanded-gif {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .expanded-gif-inner {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    max-height: 80%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 24px 40px;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    .close {
      position: absolute;
      right: 12px;
      top: 6px;
      z-index: 1001;
      cursor: pointer;
      transition: 0.25s;
      svg {
        g {
          transition: 0.25s;
        }
      }
      &:hover {
        svg {
          g {
            stroke: #333333;
          }
        }
      }
    }
    .select-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 44px;
      background: var(--secondary-color);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
      padding: 4px 68px;
      cursor: pointer;
      transition: 0.25s;
      gap: 10px;
      &:hover {
        background: var(--primary-color);
        color: white;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
        svg{
          path{
            fill: white;
          }
        }
      }
    }
    img {
      height: 100%;
      border-radius: 6px;
    }
  }
}
.item-container {
  margin: 20px 28px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 88%;
  overflow-y: auto;
  gap: 20px;
  .gif-column {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .gif-item {
      display: flex;
      justify-content: center;
      cursor: pointer;
      transition:filter 0.3s;
      img {
        border-radius: 6px;
        width: 100%;
      }
      &:hover{
        filter: brightness(70%);
      }
    }
  }
}
.loading-div {
  display: none;
}
</style>