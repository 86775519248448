<template>
    <div class="canvas-container" ref="canvasContainer">
        <canvas v-if="loaded" ref="canvas" :width="canvasSize[0]" :height="canvasSize[1]">

        </canvas>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        height: Number | String,
        width: Number | String,
        options: Object
    },
    data(){
        return {
            canvas: null,
            ctx: null,
            bars: [],
            barCount: 50,
            barGap: 0.73,
            barWidth: 2.17,
            canvasSize: [150, 100],
            loaded: false,
            observer: null,
            barHeights:[3,5,7,9,12,14,17,19,22,25,28,31,34,37,41,44,47,51,54,58,62,66,70,74,79,83,88,92,97,102,107,113,118,124,130,136,142,149,155,162,170,177,185,194,203,213,223,231,236,243]
        }
    },
    mounted() {
        if(this.height && typeof this.height == "number") {
            this.canvasSize[1] = this.$refs.canvasContainer.parentElement.clientHeight * this.height / 100
        }
        if(this.width && typeof this.width == "number") {
            this.canvasSize[0] = this.$refs.canvasContainer.parentElement.clientWidth * this.width / 100
        }
        this.loaded = true
        this.$nextTick(() => {
            this.canvas = this.$refs.canvas
            this.ctx = this.canvas.getContext('2d');
            this.drawCanvas()
            if(this.options && this.options.type == 'big') {
                
                this.observer = new ResizeObserver((entries) => this.observeResize(entries));
                this.observer.observe(this.$refs.canvasContainer.parentElement);
            }
        });
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }   
    },
    methods: {
        observeResize(entries) {
            if (this.$refs.canvasContainer) {
                const entry = entries.find((entry) => entry.target === this.$refs.canvasContainer.parentElement);
                this.canvas.width = entry.devicePixelContentBoxSize[0].inlineSize;
                this.canvas.height = entry.devicePixelContentBoxSize[0].blockSize;
                this.canvasSize[0] = entry.devicePixelContentBoxSize[0].inlineSize;
                this.canvasSize[1] = entry.devicePixelContentBoxSize[0].blockSize;
                this.$nextTick(() => {
                    this.rerender();
                });
                console.log(entry)
            }

        },
        drawCanvas() {
            // this.addCircle(26); //adding result circle
            if (this.options && this.options.type == 'big') {
                this.animateCircle(25,50)
            }
            else {
                this.animateCircle(14,26)
            }
            //this.animate()
            // for(let i = 0; i < this.barCount; i++) {
            //     this.addBar(i);
            // }
            this.animateBars();
        },
        rerender: _.debounce(function(){
            this.bars = []
            this.drawCanvas()
        }, 300),
        animateBars() {
            let index = 0;
            const animate = () => {
                if (index < this.barCount) {
                    this.addBar(index);
                    index++;
                    setTimeout(animate, 5);
                }
            };
            animate();
        },
        animateCircle(fromSize, toSize) {
            let index = fromSize
            const animate = () => {
                if (index < toSize) {
                    this.ctx.clearRect(0, 0, index, index)
                    this.addCircle(index);
                    index++;
                    setTimeout(animate, 2);
                }
            };
            animate();
        },
        addBar(index) {

            let canvas_width = this.canvasSize[0];
            this.barGap = (canvas_width / this.barCount) /3;
            let bar_width = (canvas_width / this.barCount) - this.barGap;
            let x = (bar_width * index) + (this.barGap * (index - 1));
            let y = this.canvasSize[1];
            let ratio = this.canvasSize[1]/this.barHeights[this.barHeights.length-1]
            let height = this.barHeights[index] * ratio
            let is_current_rating = (this.data.rating % 2) == 0 ? (index * 2) == this.data.rating : (index * 2) == (this.data.rating + 1)
            if (is_current_rating) {
                const grad=this.ctx.createLinearGradient(x,this.canvasSize[1], x,this.canvasSize[1]-height);
                grad.addColorStop(0, "#FD9B40");
                grad.addColorStop(1, "#FDDF40"); 
                this.ctx.fillStyle = grad;
            }
            else {
                let color_other = this.colorCurrentCol(index, x, height);
                if (!color_other) {
                    const grad = this.ctx.createLinearGradient(x,this.canvasSize[1], x,0);
                    grad.addColorStop(0.13, "#AFAFAF");
                    grad.addColorStop(0.93, "#E7E7E7");
                    this.ctx.fillStyle = grad;
                }
            }
            this.ctx.beginPath()
            this.ctx.roundRect(x, this.canvasSize[1] - height, bar_width, height, 20);
            this.ctx.fill()
            let bar = { x: x, y: this.canvasSize[1] - height, width: bar_width, height: height };
            this.bars.push(bar);
            if (is_current_rating) {
                this.canvas_arrow(this.ctx, bar.x-12, bar.y-17, bar.x-2, bar.y-2);
            }
        },
        addCircle(size) {
            this.ctx.beginPath(size);
            let positionX = this.options && this.options.type == 'big' ? 74 : 26;
            let positionY = this.options && this.options.type == 'big' ? 76 : 26;
            this.ctx.arc(positionX, positionY, size, 0, 2 * Math.PI);
            const grad=this.ctx.createLinearGradient(0,0, 0,size);
            grad.addColorStop(0, "#FDDF40");
            grad.addColorStop(1, "#FD9B40"); 
            this.ctx.fillStyle = grad;
            this.ctx.fill();
            this.addText(this.data.rating)
        },
        addText(text) {
            this.ctx.font = this.options && this.options.type == 'big' ? "bold 36px Noto sans" : "bold 20px Noto sans";
            this.ctx.fillStyle = "#ffffff";
            let positionX = this.options && this.options.type == 'big' ? 145 : 52;
            let positionY = this.options && this.options.type == 'big' ? 90 : 34;
            let metrics = this.ctx.measureText(text);
            let text_x = (positionX-(metrics.width+14.4))/2
            this.ctx.fillText(text, text_x, positionY);
            this.ctx.font = this.options && this.options.type == 'big' ? "bold 24px Noto sans" : "bold 16px Noto sans";
            this.ctx.fillText('%',text_x+metrics.width+2, positionY);
        },
        colorCurrentCol(index, x, height) {
            let color_col = false;
            if (this.data.other_ratings) {
                if (index % 2 == 0) {
                    if (this.data.other_ratings.includes(index*2)) {
                        color_col = true;
                    }
                }
                else {
                    if (this.data.other_ratings.includes(index*2) || this.data.other_ratings.includes(index*2-1)) {
                        color_col = true;
                    }
                }
            }
            if(color_col) {
                const grad=this.ctx.createLinearGradient(x,this.canvasSize[1], x,this.canvasSize[1]-height);
                grad.addColorStop(0, "#2C4DF9");
                grad.addColorStop(1, "#A5B3FE"); 
                this.ctx.fillStyle = grad;
            }
            return color_col
        },
        canvas_arrow(context, fromx, fromy, tox, toy) {
            context.beginPath();
            context.strokeStyle = "#7D7D7D";
            var headlen = 5; // length of head in pixels
            var dx = tox - fromx;
            var dy = toy - fromy;
            var angle = Math.atan2(dy, dx);
            context.moveTo(fromx, fromy);
            context.lineTo(tox, toy);
            context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
            context.moveTo(tox, toy);
            context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
            context.stroke();
        }
    },
   
}
</script>

<style lang="scss" scoped>

</style>