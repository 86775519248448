<template>
  <div
    class="dropdown-container"
    :class="options.containerClass ? options.containerClass : ''"
  >
    <div class="dropdown-initial" @click="toggleDropdown()">
      <slot name="leftIcon">
         
      </slot>
      <div class="dropdown-btn-icon" v-if="options.singleIcon" :class="{active: isDropdownOpen}">
        <slot name="icon">
         <FilterOnColumnsSVG />
        </slot>
      </div>
      <span v-if="options.singleIcon != true">{{
        options.initial
          ? options.selectAllChoice && isAllChecked()
            ? toLocal(options.selectAllChoice)
            : toLocal(options.initial)
          : toLocal("generic.choose")
      }}</span>
      <span
        :class="[{ reverse: isDropdownOpen }, { initial: !isDropdownOpen }]"
        v-if="options.open != true && options.singleIcon != true"
      >
        <slot name="arrow">
          <ArrowDownSVG class="white" />
        </slot>
      </span>
      <span class="closeBtn" v-if="options.closeBtn" @click="closeDropdown"
        ><CrossSVG
      /></span>
    </div>
    <transition name="slide-fade">
      <div
        v-click-outside-3="closeDropdown"
        class="dropdown-extended"
        :class="{left: options.left, dynamic: options.valueWidthType && options.valueWidthType =='dynamic'}"
        v-if="isDropdownOpen"
        :style="[{
          width: options.customDDWidth ? options.customDDWidth : '120%',
          'max-width': options.customDDMaxWidth ? options.customDDMaxWidth : '',
          'min-width': options.customDDMinWidth ? options.customDDMinWidth : '',
          
        }, options.dropdownStyle ? options.dropdownStyle : {}]"
      >
        <div class="search-box" v-if="options.searchBar">
          <SearchSVG v-if="searchTerm == ''" />
          <input
            :class="{ gapLeft: searchTerm == '' }"
            :placeholder="toLocal('dropdown.write')"
            v-model="searchTerm"
          />
        </div>
        <div
          class="options-container"
          :key="updateKey"
          :style="{
            maxHeight: options.customMaxHeight
              ? options.customMaxHeight
              : 'fit-content',
          }"
        >
          <div
            class="dropdown-option all-option"
            v-if="options.selectAllChoice && options.checkbox"
            @click="selectAll()"
          >
            <div
              class="checkbox"
              v-if="options.checkbox"
              :class="{ checked: isAllChecked() }"
            >
              <CheckmarkSVG class="checkmark" v-if="isAllChecked()" />
            </div>
            <span :class="{ bold: isAllChecked() }">{{
              options.selectAllChoice
            }}</span>
          </div>
          <div class="options-wrapper"  v-if="options.filterType && ['date','number','percent'].includes(options.filterType)">
            <DateRangePicker
              v-if="options.filterType=='date'"
              :from="compareList[0] ? compareList[0] : ''"
              :to="compareList[1] ? compareList[1] : ''"
              @changeFrom="$emit('selectedElement', 'date', 'from', $event)"
              @changeTo="$emit('selectedElement', 'date', 'to', $event)"
              @innerPopupState="innerPopupOpen=$event"
            />
            <NumberRangePicker
              v-else-if="['number','percent'].includes(options.filterType)"
              :from="compareList[0] ? compareList[0] : ''"
              :to="compareList[1] ? compareList[1] : ''"
              :type="options.filterType"
              :rangeMin="options.rangeMin"
              :rangeMax="options.rangeMax"
              @changeFrom="$emit('selectedElement', 'number', 'from', $event)"
              @changeTo="$emit('selectedElement', 'number', 'to', $event)"
              @innerPopupState="innerPopupOpen=$event"
            />
          </div>
          <div class="options-wrapper" v-else>
            <div
              class="dropdown-option"
              v-for="element in totalList"
              :key="`el-${element[keyLabel]}`"
              :class="[{ 
                selected: isChecked(element[keyLabel]), 
                'with-icon': element.icon 
              },element.class ? element.class : '',
                element.additional ? 'additional' : ''
              ]"
              @click="clickedOnElement(element[keyLabel])"
            >
              <div
                class="checkbox"
                v-if="options.checkbox"
                :class="{ checked: isChecked(element[keyLabel]) }"
              >
                <CheckmarkSVG
                  class="checkmark"
                  v-if="isChecked(element[keyLabel])"
                />
              </div>
              <span :class="{ bold: isChecked(element[keyLabel]) }">{{
                toLocal(getDropdownValue(element))
              }}</span>
              <div class="right-icon" v-if="element.icon">
                <slot :name="element.icon">
                </slot>
              </div>
            </div>
            <div class="hidden-option">
            </div>
          </div>
          
        </div>
        <div
          class="create-new"
          v-if="
            totalList.length == 0 &&
            (options.showCreateNewBtn == undefined || options.showCreateNewBtn) && options.showCreateNewBtn != false
          "
          @click="createNewElement"
        >
          <span><DropAddSVG /></span>
          <span>{{ toLocal(options.createNewText) }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CheckmarkSVG from "@/assets/images/icons/checkmark_users.svg?inline";
import ArrowDownSVG from "@/assets/images/icons/arrow-down.svg?inline";
import CrossSVG from "@/assets/images/icons/users/cross.svg?inline";
import DropAddSVG from "@/assets/images/icons/users/dropdown-add.svg?inline";
import SearchSVG from "@/assets/images/icons/users/search.svg?inline";
import FilterOnColumnsSVG from "@/assets/images/icons/users/filter_on_columns.svg?inline";
import DateRangePicker from "@/components/layout/elements/DropdownItems/DateRangePicker";
import NumberRangePicker from "@/components/layout/elements/DropdownItems/NumberRangePicker";
export default {
  name: "CustomDropdown",
  props: {
    list: {
      type: Array,
      default: null,
    },
    compareList: {
      type: Array,
      default: null,
    },
    compareChosen: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    keyLabel: {
      type: String,
      default: "id",
    },
    valueLabel: {
      type: String,
      default: "name",
    },
  },
  components: {
    DateRangePicker,
    NumberRangePicker,
    CheckmarkSVG,
    ArrowDownSVG,
    CrossSVG,
    DropAddSVG,
    SearchSVG,
    FilterOnColumnsSVG,
  },
  data: function () {
    return {
      isDropdownOpen: false,
      searchTerm: "",
      totalList: [],
      updateKey: false,
      innerPopupOpen: false,
    };
  },
 
  watch: {
    searchTerm: {
      handler(newVal) {
        if (!this.options.searchLocally) {
          if (newVal.length > 0) {
            this.$emit("search", newVal);
          } else {
            this.$emit("search", null);
          }
        } else {
          this.searchList(newVal);
        }
      },
    },
    list(){
      this.totalList = _.cloneDeep(this.list);
    }
  },

  methods: {
    searchList(val) {
      if (val.length > 0) {
        this.totalList = this.list.filter((listItem) => {
          return listItem[this.valueLabel].toLowerCase().includes(val.toLowerCase());
        });
      } else {
        this.totalList = _.cloneDeep(this.list);
      }
    },
    createNewElement() {
      if (this.searchTerm != "") {
        this.$emit("createNew", this.searchTerm);
        this.searchTerm == "";
      }
    },
    closeDropdown() {
      if(this.innerPopupOpen) return
      if (this.options.open) {
        this.$emit("close");
      } else {
        this.isDropdownOpen = false;
      }
    },
    toggleDropdown() {
      if (this.options.open != true) {
        this.isDropdownOpen = !this.isDropdownOpen;
      } else {
        return;
      }
    },
    isAllChecked() {
      if (this.compareList) {
        let count = this.list.length;
        let selected = this.list.filter((lElem) => {
          if (
            this.compareList.find(
              (el) => el[this.keyLabel] == lElem[this.keyLabel]
            )
          ) {
            return lElem;
          }
        }).length;
        if (count == selected) {
          return true;
        } else {
          return false;
        }
      }
    },
    isChecked(id) {
      if (this.compareList) {
        return this.compareList.find((el) => el[this.keyLabel] == id);
      } else {
        return this.compareChosen[this.keyLabel] == id;
      }
    },
    clickedOnElement(id) {
      this.$emit("elClicked", id, this.options.attached);
      if (this.options.closeOnClick != false) {
        this.isDropdownOpen = false;
      }
      this.updateKey = !this.updateKey;
    },
    selectAll() {
      let isAll = this.isAllChecked();
      this.updateKey = !this.updateKey;
      this.$emit("elAll", isAll, this.options.attached);
      if (this.options.closeOnClick != false) {
        this.isDropdownOpen = false;
      }
    },
    getDropdownValue(element) {
      return element[this.valueLabel];
    },
  },
  mounted() {
    this.totalList = _.cloneDeep(this.list);
    if (this.options.open && this.options.open == true) {
      this.isDropdownOpen = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  font-size: clamp(14px, 1.5vw, 16px);
  user-select: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  &:not(.no-padding-no-bg){
    padding: 4px 10px;
    background-color: #4d4d4d;
  
  }
  .whiteContrast{
    color: white;
    .dropdown-initial {
      color: white;
    }
  }
  .blackContrast{
    color: #333;
    .dropdown-initial {
      color: #333;
    }
  }
  .dropdown-initial {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    color: #333;
    span {
      max-width: 80%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .reverse {
      transition: 0.25s;
      transform: rotate(180deg);
    }
    .initial {
      transition: 0.25s;
      transform: rotate(0deg);
    }
    .white path {
      stroke: white;
    }
  }
  .search-box {
    padding: 10px 12px;
    display: flex;
    justify-self: center;
    align-items: center;
    position: relative;
    svg {
      position: absolute;
      left: 20px;
    }
    input {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      border: 1px solid #b2b2b2;
      padding: 4px 8px;
      &.gapLeft {
        padding-left: 28px;
      }
    }
  }
  .dropdown-extended {
    transform-origin: top;
    position: absolute;
    top: 100%;
   
    &:not(.left){
      right: 0;
    }
    .left{
      left: 0;
    }
    z-index: 10;
    color: #333;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.24);
    &.dynamic{
      width: initial;
      .dropdown-option {
        span{
          max-width: 100%;
        }
      }
      &.left{
        left: 0;
      }
    }
    .options-container {
      max-height: 200px;
      overflow-y: auto;
    }
    .dropdown-option {
      margin: 0px 8px;
      padding: 6px 0;
      display: flex;
      gap: 8px;
      align-items: center;
      &:hover {
        font-weight: 600;
      }
      &:nth-child(1 of .additional){
          position: relative;
          // border-top: 1px solid #7D7D7D;
          &::after{
            content: '';
            top: 0;
            left: -8px;
            width: calc(100% + 16px);
            height: 1px;
            position: absolute;
            background-color: #7D7D7D;

          }
       
      }
      span {
        max-width: 80%;
        text-overflow: ellipsis;
        overflow-x: hidden;
        flex-wrap: nowrap;
        white-space: nowrap;
      }
      .checkbox {
        width: 16px;
        height: 16px;
        min-width: 16px;
        border-radius: 2px;
        border: 1px solid #4d4d4d;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .checkmark {
          width: 100%;
          height: 100%;
          margin: 15%;
        }
        &.checked {
          background-color: var(--primary-color);
        }
      }
      .bold {
        font-weight: 600;
      }
      &.with-icon{
        justify-content: space-between;
      }
      
    }
    .all-option {
      border-bottom: 1px solid black;
    }
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.25s ease;
}
.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: scaleY(0%);
  overflow: visible;
}

.closeBtn {
  svg path {
    fill: white;
  }
}
.create-new {
  border-top: 1px solid #b2b2b2;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 8px;
  &:hover {
    span {
      svg {
        box-sizing: content-box;
        border-radius: 50%;
        fill: var(--secondary-color);
        circle {
          fill: var(--secondary-color);
          stroke: #4d4d4d;
        }
      }
    }
  }
  span {
    display: flex;
    align-items: center;
  }
}

.options-wrapper{
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss">
.dropdown-btn-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  &.active{
    background: #F4F4F4;
    border-radius: 50%;
    svg{
      path{
        fill: #4D4D4D;
      }
    }
  }
}
.options-container{
  .dropdown-option{
    .right-icon{
      svg{
        path{
          fill: #333;
        }
      }
    }
    &.danger{
      color:#D45454;
      .right-icon{
        svg{
          path{
            fill: #D45454;
          }
        }
      }
    }
  }
}
</style>