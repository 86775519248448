<template>
  <div class="gallery-backdrop">
    <div class="gallery-container">
      <CloseSVG @click="closeGallery()" class="close-cross" />
      <div class="tabs-container">
        <span
          class="tab"
          @click="selectedTab = 0"
          :class="{ active: selectedTab == 0 }"
          >Giphy</span
        >
        <span
          class="tab"
          @click="selectedTab = 1"
          :class="{ active: selectedTab == 1 }"
          >Pixabay</span
        >
      </div>
      <div class="gallery-main-container">
        <GalleryTab :key="selectedTab" :tab="tabs[selectedTab]" @returnGif="returnPickedGif"/>
      </div>
    </div>
  </div>
</template>

<script>
import Giphy from "../galleryElements/Giphy.vue";
import Pixabay from "../galleryElements/Pixabay.vue";
import GalleryTab from "../galleryElements/GalleryTab.vue";

import CloseSVG from "@/assets/images/icons/training-edit/gallery/close.svg?inline";

export default {
  name: "GallerySide",
  components: { 
    Giphy, 
    GalleryTab,
    Pixabay,
    CloseSVG,
  },
  data() {
    return {
      selectedTab: 0,
      tabs:[
        {
          id: 1, 
          type: 'giphy',
          policyURL: 'https://support.giphy.com/hc/en-us/articles/360020287091-GIPHY-DMCA-Copyright-Policy',
          tags: [
            {label: 'generic.gif', value: 'gifs'},
            {label: 'generic.sticker', value: 'stickers'},
          ],
          queryParams: {
            "tag": 'tag',
            "site": 'giphy',
            "type": 'type',
            "limit": '50',
            "offset": '1',
          },
          APIDataLabel: 'data',
          imageURLPath: 'images.fixed_width.url',
          imageOriginalURLPath: 'images.original.url',
        },
        {
          id: 2, 
          type: 'pixabay',
          policyURL: 'https://pixabay.com/service/license-summary/',
          tags: [
            {label: 'tags.nature', value: 'nature'},
            {label: 'tags.background', value: 'backgrounds'},
            {label: 'tags.science', value: 'science'},
            {label: 'tags.fashion', value: 'fashion'},
            {label: 'tags.animals', value: 'animals'},
          ],
          queryParams: {
            "tag": 'tag',
            "site": 'pixabay',
            "type": 'type',
            "limit": '50',
            "page": 1
          },
          APIDataLabel: 'hits',
          imageURLPath: 'webformatURL',
          imageOriginalURLPath: 'largeImageURL',
        },
      ]
    };
  },
  methods: {
    returnPickedGif(gif) {
      this.$emit("returnPickedGif", gif);
    },
    closeGallery() {
      this.$emit("closeGallery");
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  .gallery-container {
    border-radius: 12px 12px 0px 0px;
    width: 75%;
    height: 80%;
    background-color: white;
    position: relative;
    .close-cross {
      position: absolute;
      right: 16px;
      top: 19px;
      transition: 0.25s;
      &:hover {
        g {
          stroke: #333333;
        }
      }
      cursor: pointer;
    }
  }
}
.gallery-container {
  display: flex;
  flex-direction: column;
  .tabs-container {
    width: 100%;
    display: flex;
    border-bottom: 2px solid var(--secondary-color);
    padding: 0px 16px;
    padding-top: 16px;
    height: 8%;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: clamp(150px, 12vw, 205px);
      border-radius: 8px 8px 0px 0px;
      cursor: pointer;
      border: 1px solid #b2b2b2;
      background: #fff;
      border-bottom: none;
      transition: 0.25s;
      font-size: clamp(12px, 2vh, 20px);
      &:hover {
        background-color: var(--secondary-color);
        font-weight: 600;
      }
      &.active {
        background-color: var(--secondary-color);
        font-weight: 600;
      }
    }
  }
  .gallery-main-container {
    height: 92%;
  }
}
</style>
