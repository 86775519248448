<template>
    <div 
        class="scorm-container"
    >
        <div class="close-btn" @click="endSession" v-if="item.options && !item.options.popup && showPackageIframe">
            <div>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="overlay" >

        </div>
        <div class="content" >
            <!-- <div class="start-btn" @click="getScormUrl" v-if="!started && !ended && !showResults && (item.options ? item.options.allowRetry ? true : !hasResults : true)">
                {{toLocal('training.start')}}
            </div> -->
            <div class="error-message" v-if="errorMessage.length > 0">
                {{errorMessage}}
            </div>
          
           
            <div class="started" v-if="started && !ended">
                <div class="row mb-83" v-if="item.options && item.options.popup">
                    <h2>
                        {{toLocal('scorm.content_open_new_tab')}}
                    </h2>
                </div>
                <div class="row">
                    <!-- <button @click="opened_window.focus()">
                        Pereiti prie turinio
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6265 0.302801C1.97073 0.104213 2.36318 -0.00112795 2.76441 9.10886e-06C3.16564 0.00114617 3.55744 0.108708 3.90048 0.309194L3.91326 0.31666L13.4265 6.08425C13.7593 6.28854 14.0303 6.57362 14.2168 6.90888C14.4032 7.24396 14.5 7.61963 14.5 8C14.5 8.38037 14.4032 8.75604 14.2168 9.09112C14.0303 9.42638 13.7593 9.71145 13.4265 9.91574L13.4222 9.91842L3.90055 15.6909C3.5575 15.8914 3.16564 15.9989 2.76441 16C2.36318 16.0011 1.97073 15.8958 1.6265 15.6972C1.28255 15.4988 1.00023 15.2152 0.804209 14.8781C0.608306 14.5411 0.504298 14.1606 0.500102 13.7742L0.5 13.7648L0.500052 2.22577C0.504248 1.83936 0.608307 1.4589 0.80421 1.12194C1.00023 0.784791 1.28255 0.50122 1.6265 0.302801ZM2.75909 1.77778C2.69063 1.77759 2.62123 1.79541 2.55741 1.83223C2.49329 1.86921 2.43557 1.92509 2.39339 1.99764C2.35166 2.06942 2.32769 2.15335 2.32613 2.241L2.32613 13.759C2.32769 13.8466 2.35166 13.9306 2.39339 14.0024C2.43557 14.0749 2.49329 14.1308 2.5574 14.1678C2.62123 14.2046 2.69064 14.2224 2.75909 14.2222C2.82591 14.222 2.89359 14.2047 2.95602 14.1692L12.4525 8.41198C12.453 8.41166 12.4536 8.41134 12.4541 8.41102C12.516 8.37265 12.5711 8.31638 12.6111 8.24452C12.6515 8.17187 12.6739 8.08753 12.6739 8C12.6739 7.91247 12.6515 7.82813 12.6111 7.75548C12.5711 7.68362 12.516 7.62735 12.4541 7.58898C12.4536 7.58866 12.453 7.58834 12.4525 7.58802L2.95601 1.83076C2.89358 1.79532 2.82591 1.77797 2.75909 1.77778Z" fill="#333333"/>
                        </svg>
                    </button> -->
                </div>
            </div>
            <div class="results-container" v-if="(showResults && started && ended) || (showResults && latest_results.completed)">
                <div class="stats-container">
                    <div class="row justify-center">
                        <h2>
                            {{toLocal('scorm.your_results')}}
                        </h2>
                    </div>
                    <div class="row justify-center time-row">
                        {{toLocal('generic.time_spent')}}: {{formatTime(latest_results.time)}}
                    </div>
                    <div class="row justify-center gap-20">
                        <div class="answered" v-if="latest_results.completed">
                            <span>{{toLocal('scorm.answered_correctly')}} </span>
                            <span v-if="latest_results.correct_answers && latest_results.total_answers">{{latest_results.correct_answers}} {{toLocal('test_user.out_of')}} {{latest_results.total_answers}} {{toLocal('generic.questions_who')}}</span>
                            <span v-if="latest_results.score">({{latest_results.score *100}} %)</span>
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button @click="restartTask()" class="secondary w-240 justify-center">
                        {{toLocal('scorm.solve_again')}}
                       <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.86429 0.5C6.16933 0.5 3.74144 1.84524 2.35754 4.04762L2.12689 3.30952C1.99336 2.88095 1.51992 2.64286 1.0829 2.77381C0.645881 2.90476 0.403092 3.36905 0.536626 3.79762L1.41067 6.54762C1.53206 6.92857 1.90838 7.16667 2.33326 7.10714C2.38182 7.10714 2.43038 7.08333 2.4668 7.07143L5.59878 6.04762C5.81729 5.98809 5.98724 5.85714 6.10863 5.66667C6.21789 5.47619 6.25431 5.2619 6.19361 5.04762C6.13291 4.83333 5.99938 4.66667 5.80515 4.54762C5.61092 4.44048 5.39241 4.40476 5.17389 4.46429L3.74144 4.92857C4.80971 3.17857 6.70347 2.11905 8.84001 2.11905C12.1541 2.11905 14.8369 4.7619 14.8369 8C14.8369 11.2381 12.1419 13.881 8.84001 13.881C6.58207 13.881 4.45767 12.6429 3.45009 10.7381C3.36512 10.5357 3.2073 10.381 3.00093 10.2976C2.79456 10.2143 2.57605 10.2143 2.36968 10.2976C1.9448 10.4762 1.75057 10.9524 1.93266 11.3571C1.9448 11.3929 1.96908 11.4405 1.98122 11.4524C3.28014 13.9048 5.9751 15.5 8.84001 15.5C13.0645 15.5 16.5 12.131 16.5 7.9881C16.5 3.84524 13.0888 0.5 8.86429 0.5Z" fill="#333333"/>
                        </svg>
                    </button> 
                    <button @click="continueTask()" class="w-240 justify-center" v-if="!latest_results.completed">
                        {{toLocal('generic.continue')}}
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6265 0.302801C1.97073 0.104213 2.36318 -0.00112795 2.76441 9.10886e-06C3.16564 0.00114617 3.55744 0.108708 3.90048 0.309194L3.91326 0.31666L13.4265 6.08425C13.7593 6.28854 14.0303 6.57362 14.2168 6.90888C14.4032 7.24396 14.5 7.61963 14.5 8C14.5 8.38037 14.4032 8.75604 14.2168 9.09112C14.0303 9.42638 13.7593 9.71145 13.4265 9.91574L13.4222 9.91842L3.90055 15.6909C3.5575 15.8914 3.16564 15.9989 2.76441 16C2.36318 16.0011 1.97073 15.8958 1.6265 15.6972C1.28255 15.4988 1.00023 15.2152 0.804209 14.8781C0.608306 14.5411 0.504298 14.1606 0.500102 13.7742L0.5 13.7648L0.500052 2.22577C0.504248 1.83936 0.608307 1.4589 0.80421 1.12194C1.00023 0.784791 1.28255 0.50122 1.6265 0.302801ZM2.75909 1.77778C2.69063 1.77759 2.62123 1.79541 2.55741 1.83223C2.49329 1.86921 2.43557 1.92509 2.39339 1.99764C2.35166 2.06942 2.32769 2.15335 2.32613 2.241L2.32613 13.759C2.32769 13.8466 2.35166 13.9306 2.39339 14.0024C2.43557 14.0749 2.49329 14.1308 2.5574 14.1678C2.62123 14.2046 2.69064 14.2224 2.75909 14.2222C2.82591 14.222 2.89359 14.2047 2.95602 14.1692L12.4525 8.41198C12.453 8.41166 12.4536 8.41134 12.4541 8.41102C12.516 8.37265 12.5711 8.31638 12.6111 8.24452C12.6515 8.17187 12.6739 8.08753 12.6739 8C12.6739 7.91247 12.6515 7.82813 12.6111 7.75548C12.5711 7.68362 12.516 7.62735 12.4541 7.58898C12.4536 7.58866 12.453 7.58834 12.4525 7.58802L2.95601 1.83076C2.89358 1.79532 2.82591 1.77797 2.75909 1.77778Z" fill="#333333"/>
                        </svg>
                    </button> 
                </div>
            </div>
            <div class="not-finished" v-if="showResults && latest_results && !latest_results.completed && !started && !ended">
                <div class="modal">
                    <h2>
                        {{toLocal('scorm.did_not_finish_item_label')}}
                    </h2>
                    <p>{{toLocal('scorm.select_how_to_continue')}}</p>
                    <div class="continue-button-container">
                        <button @click="restartTask()" class="w-240 justify-center">
                            {{toLocal('scorm.start_from_beginning')}}
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.86429 0.5C6.16933 0.5 3.74144 1.84524 2.35754 4.04762L2.12689 3.30952C1.99336 2.88095 1.51992 2.64286 1.0829 2.77381C0.645881 2.90476 0.403092 3.36905 0.536626 3.79762L1.41067 6.54762C1.53206 6.92857 1.90838 7.16667 2.33326 7.10714C2.38182 7.10714 2.43038 7.08333 2.4668 7.07143L5.59878 6.04762C5.81729 5.98809 5.98724 5.85714 6.10863 5.66667C6.21789 5.47619 6.25431 5.2619 6.19361 5.04762C6.13291 4.83333 5.99938 4.66667 5.80515 4.54762C5.61092 4.44048 5.39241 4.40476 5.17389 4.46429L3.74144 4.92857C4.80971 3.17857 6.70347 2.11905 8.84001 2.11905C12.1541 2.11905 14.8369 4.7619 14.8369 8C14.8369 11.2381 12.1419 13.881 8.84001 13.881C6.58207 13.881 4.45767 12.6429 3.45009 10.7381C3.36512 10.5357 3.2073 10.381 3.00093 10.2976C2.79456 10.2143 2.57605 10.2143 2.36968 10.2976C1.9448 10.4762 1.75057 10.9524 1.93266 11.3571C1.9448 11.3929 1.96908 11.4405 1.98122 11.4524C3.28014 13.9048 5.9751 15.5 8.84001 15.5C13.0645 15.5 16.5 12.131 16.5 7.9881C16.5 3.84524 13.0888 0.5 8.86429 0.5Z" fill="#333333"/>
                            </svg>
                        </button> 
                        <button @click="continueTask()" class="w-240 justify-center">
                            {{toLocal('scorm.continue_where_stoped')}}
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6265 0.302801C1.97073 0.104213 2.36318 -0.00112795 2.76441 9.10886e-06C3.16564 0.00114617 3.55744 0.108708 3.90048 0.309194L3.91326 0.31666L13.4265 6.08425C13.7593 6.28854 14.0303 6.57362 14.2168 6.90888C14.4032 7.24396 14.5 7.61963 14.5 8C14.5 8.38037 14.4032 8.75604 14.2168 9.09112C14.0303 9.42638 13.7593 9.71145 13.4265 9.91574L13.4222 9.91842L3.90055 15.6909C3.5575 15.8914 3.16564 15.9989 2.76441 16C2.36318 16.0011 1.97073 15.8958 1.6265 15.6972C1.28255 15.4988 1.00023 15.2152 0.804209 14.8781C0.608306 14.5411 0.504298 14.1606 0.500102 13.7742L0.5 13.7648L0.500052 2.22577C0.504248 1.83936 0.608307 1.4589 0.80421 1.12194C1.00023 0.784791 1.28255 0.50122 1.6265 0.302801ZM2.75909 1.77778C2.69063 1.77759 2.62123 1.79541 2.55741 1.83223C2.49329 1.86921 2.43557 1.92509 2.39339 1.99764C2.35166 2.06942 2.32769 2.15335 2.32613 2.241L2.32613 13.759C2.32769 13.8466 2.35166 13.9306 2.39339 14.0024C2.43557 14.0749 2.49329 14.1308 2.5574 14.1678C2.62123 14.2046 2.69064 14.2224 2.75909 14.2222C2.82591 14.222 2.89359 14.2047 2.95602 14.1692L12.4525 8.41198C12.453 8.41166 12.4536 8.41134 12.4541 8.41102C12.516 8.37265 12.5711 8.31638 12.6111 8.24452C12.6515 8.17187 12.6739 8.08753 12.6739 8C12.6739 7.91247 12.6515 7.82813 12.6111 7.75548C12.5711 7.68362 12.516 7.62735 12.4541 7.58898C12.4536 7.58866 12.453 7.58834 12.4525 7.58802L2.95601 1.83076C2.89358 1.79532 2.82591 1.77797 2.75909 1.77778Z" fill="#333333"/>
                            </svg>
                        </button> 
                    </div>
                </div>
            </div>
            <iframe ref="iframe" v-if="item.options && !item.options.popup && showPackageIframe" :src="url" frameborder="0"></iframe>
            <div class="finish-button-container" v-if="started && showPackageIframe && hasCompletedResult">
                <div class="btn"  @click="showUserResults">
                    {{toLocal('scorm.show_results')}}
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    props:{
        item: Object,
    },
    data(){
        return{
            started: false,
            ended: false,
            scorm_url: '',
            opened_window: '',
            session_token: '',
            status: '',
            showResults: false,
            loadingResults: false,
            latest_results: {
                score: null,
                completed: false,
                passed: false,
                time: 0,
            },
            hasResults: false,
            url: '',
            showPackageIframe:false,
            showPackageResults:false,
            errorMessage: '',
            training_id: this.$store.state.currentTraining
        }
    },
    computed:{
        hasCompletedResult(){
            if((this.latest_results.score != null && this.latest_results.score != 0) || this.latest_results.completed || this.latest_results.passed){
                return true;
            }
            else return false;
        }
    },
    mounted(){
        if(this.item && this.item.results){
            this.latest_results = this.item.results
            this.hasResults= true;
            this.showResults = true;
            // this.started = true;
            // this.ended = true;
        }
        if (this.item) {
            if(!this.started && !this.ended && !this.showResults && (this.item.options ? this.item.options.allowRetry ? true : !this.hasResults : true))
                this.getScormUrl();
            if(!this.item.options){
                this.$set(this.item, 'options', {
                    popup: true,
                    allowRetry: true,
                    continueAfterTry: true
                })
            }
        }
    },
    beforeDestroy(){
        window.removeEventListener('message', this.windowClosed);
        if (window.Echo){
            window.Echo.leave(`task.${this.session_token}`);
        }
    },
    methods:{
        endSession(){
            const iframe = this.$refs.iframe;
            iframe.contentWindow.postMessage('finishCourse', '*');
            // iframe.remove();
            this.ended = true;
            this.showPackageIframe = false;
        },
        getScormUrl(){
            axios
            .post(
            `${this.$store.state.api_link}/scorm/start`,
            { scorm_key: this.item.content, training_id: this.training_id, training_scorm: true },
            this.$store.getters.axiosConfig()
            )
            .then((response) => {
                if(response.data.session_token){
                    this.session_token = response.data.session_token;
                }
                let package_type = 'scorm';
                if(response.data.type){
                    package_type = response.data.type
                }
                let url = this.$store.state.system_settings.scormURL+"/player/"+this.item.content+"?session_token="+this.session_token;
                if(package_type == 'qti'){
                    url = this.$store.state.system_settings.qtiURL+"/test/"+this.item.content+"?session_token="+this.session_token;
                }
                this.url = url;
                if(this.item.options && this.item.options.popup){
                    window.open(
                        url,
                        '_blank',
                        'noopener,noreferrer'
                    );
                }
                else if(this.item.options && !this.item.options.popup){
                    this.showPackageIframe = true;
                }
                this.startEchoListen(this.item.content);
                this.started = true;
                this.ended = false;
                window.addEventListener(
                    'message',
                    this.windowClosed,
                    false,
                );
            })

            .catch((error) => {
                this.$store.commit("handleError", { error: error });
            });
        },
        continueTask(){
            axios
            .post(
            `${this.$store.state.api_link}/scorm/continue`,
            { scorm_key: this.item.content },
            this.$store.getters.axiosConfig()
            )
            .then((response) => {
                if(response.data.session){
                    this.session_token = response.data.session.token;
                }
                let package_type = 'scorm';
                if(response.data.type){
                    package_type = response.data.session.package_type
                }
                let url = this.$store.state.system_settings.scormURL+"/player/"+this.item.content+"?session_token="+this.session_token+"&continue=1";
                if(package_type == 'qti'){
                    url = this.$store.state.system_settings.qtiURL+"/test/"+this.item.content+"?session_token="+this.session_token+"&continue=1";
                }
                this.url = url;
                if(this.item.options && this.item.options.popup){
                    window.open(
                        url,
                        '_blank',
                        'noopener,noreferrer'
                    );
                }
                else if(this.item.options && !this.item.options.popup){
                    this.showPackageIframe = true;
                }
                this.startEchoListen(this.item.content);
                this.started = true;
                this.ended = false;
                window.addEventListener(
                    'message',
                    this.windowClosed,
                    false,
                );
            })

            .catch((error) => {
                this.$store.commit("handleError", { error: error });
            });
        },
        windowClosed(e){
         
            const { data } = e;
            if (data.pageClosed) {
                this.started = false;
            }
        },
        restartTask(){
            this.getScormUrl();
        },
    
        startEchoListen() {
            if (window.Echo != null) {
                window.Echo.private(`task.${this.session_token}`)
                    .listen('TaskEvent', (e) => {
                        if( e.data && e.data.event =='end'){
                            // this.showResults();
                            this.showResults=true;
                            if(e.data.results){
                                this.$set(this.item,'results', e.data.results);
                                this.latest_results = this.item.results
                                this.ended = true;
                            }
                        }
                    })
            }
        },
        showUserResults(){
            this.showPackageIframe = false;
            this.showResults = true
            this.ended = true;
        },
        formatTime(seconds){
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            seconds = seconds % 60;
            let formatted_text = '';
            if(hours > 0){
                formatted_text += `${hours} ${this.toLocal('generic.hours_short')} `;
            }
            if(minutes > 0){
                formatted_text += `${minutes} ${this.toLocal('generic.min_short')} `;
            }
            if(seconds > 0){
                formatted_text += `${seconds} ${this.toLocal('generic.sec_short')} `;
            }
            return formatted_text;
        }
        
    }
}
</script>

<style lang="scss" scoped>
.scorm-container{
    display: flex;
    flex-direction: column;
    position: absolute;
    font-family: var(--main-site-font);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #efefef;
}
.error-message{
    position: absolute;
    bottom: 5px;
    right: 15px;
    color: rgba(252, 65, 65, 0.925);
    font-size: 16px;
}
.iframe-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
iframe{
    height: 100%;
    width: 100%;
}
.start-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    font-size: 24px;
    color: #333;
    border-bottom: 5px solid var(--secondary-color);
    background: #F4F4F4;
    padding: 10px 25px;
    width: 360px;
    max-width: 80vw;
    height: 68px;
    cursor: pointer;
    transition: opacity 0.3s;
    z-index: 3;
    &:hover, &:active{
        opacity: 0.8;
    }
}
.results-container{
    background: white;
    border-radius: 8px;
    padding: 35px;
}
.overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    
}
.row{
    display: flex;
    flex-direction: row;
    font-family: var(--main-site-font);
    &.gap-20{
        gap: 20px;
    }
}
.justify-center{
    justify-content: center;
}
.mb-83{
    margin-bottom: 83px;
}
.started{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
h2{
    color: #333;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
}
.button-container{
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 104px;
}
button{
    display: flex;
    align-items: center;
    border-radius: 40px;
    background: var(--secondary-color);
    padding: 8px 16px;
    color: #333;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
    border: none;
    cursor: pointer;
    svg{
        margin-left: 16px;
    }
    &.secondary{
        background: #E2E2E2;
    }
}
.w-240{
    width: 240px;
}
.finish-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .btn{
        min-width: 150px;
        background: var(--secondary-color);
    }
}
.not-finished{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
    font-size: 16px;
    z-index: 3;
    .modal{
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 12px;
        padding: 40px 48px;
        max-width: 70%;
        box-shadow: 0 0 8px 0 #0000002f;
        opacity: 0;
        animation: 0.3s fadeIn forwards;
        animation-delay: 0.5s;
        h2, p{
            text-align: center;
        }
        h2{
            margin-bottom: 20px;
            font-size: 24px;
        }
        p{
            margin: 0;
        }
    }
    .continue-button-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin-top: 32px;
        button{
            font-size: 16px;
        }
    }
}
iframe{
    width: calc(100% - 114px);
    border-radius: 20px;
}
.close-btn{
 position: absolute;
 top: 12px;
 right: 12px;
 cursor: pointer;
 z-index: 5;
 div{
    position: relative;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    span{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: black;
        border-radius: 4px;
        width: 2px;
        height: 80%;
        transform: rotate(45deg);
        &:last-child{
            transform: rotate(-45deg);
        }
    }
 }
}
@keyframes fadeIn{
    0%{
        opacity: 0;
        transform: scale(0.5);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
</style>