<template>
<div class="editor-parent" ref="editorParent"  @mouseenter="$emit('disableDrag')" @mouseleave="$emit('enableDrag')" v-click-outside="saveImageMetadata">
    <croppa 
        v-model="imgCroppa"
        :disable-scroll-to-zoom="true"
        :disableClickToChoose="true"
        :show-remove-button="false"
        :zoom-speed="3"
        :height="height ? height : parseInt(canvasHeight)"
        :width="width ? width : $parent.trainingItem.half ? Math.min(448, canvasWidth) : canvasWidth"
        placeholder=""
        :initial-image="initial"
         :initial-size="'cover'"
        initial-position="center"
        :disabled="readOnly"
        :auto-sizing="false"
        @zoom="onZoom"
        @file-choose="handleChoose"
        @new-image-drawn="onNewImage"
        @initial-image-loaded="initialLoaded"
        :class="{'read-only':readOnly, 'user': align == 'top-user'}"
        @click="$emit('canvasClick')"
        @draw="onImageDraw"
    >
        <img 
        v-if="!imgCroppa.imageSet && !readOnly"
        class="choose-file-big"
        src="@/assets/images/icons/training-item-upload.svg"
        @click="chooseFile"
        >
        <!-- <img 
        v-if="!imgCroppa.imageSet && !readOnly"
        class="choose-file-big"
        src="@/assets/images/icons/training-item-gallery.svg"
        @click="openGallery"
        > -->
        <div class="spinner-container" v-if="imgCroppa && imgCroppa.loading">
            <div class="spinner"></div>
        </div>

         <template v-if="!imgCroppa.imageSet && !readOnly" class="choose-file-big">
            <slot name="big-icon">

            </slot>
         </template>
     
        <div 
        v-if="imgCroppa.imageSet && !readOnly && (showSlider != undefined ? showSlider : true) || (showHalfSlider!= undefined ? showHalfSlider : true)" v-show="align == 'top' ? isEditing : true " 
        class="img-tool-container" 
        :class="{'align-top': align == 'top' || align == 'top-user', 'user': align == 'top-user', doubled: showSlider}"
        >
            <div class="file-container" v-if="align != 'top' && align != 'top-user'">
                <div class="save-image" @click="saveImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.365" height="17.174" viewBox="0 0 17.365 17.174">
                        <path id="Path_7475" data-name="Path 7475" d="M8946.158,376.429l4.848,7.149,10.434-15.383" transform="translate(-8945.116 -367.153)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </svg>
                </div>
                <div class="delete-image" @click="deleteImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.827" height="15.827" viewBox="0 0 15.827 15.827">
                        <g id="Group_6960" data-name="Group 6960" transform="translate(-224.569 -720.611)">
                            <path id="Path_7476" data-name="Path 7476" d="M0,13.705,13.706,0" transform="translate(239.335 721.672) rotate(90)" stroke-linecap="round" stroke-width="1.5"/>
                            <path id="Path_7477" data-name="Path 7477" d="M9068.63,372.378l13.705-13.705" transform="translate(-8843 363)" stroke-linecap="round" stroke-width="1.5"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="image-manipulation" v-if="align != 'top' && align != 'top-user' && !readOnly">
                <div class="tool-container">
                    <svg @click="imgCroppa.rotate()" xmlns="http://www.w3.org/2000/svg" width="19.724" height="19.222" viewBox="0 0 19.724 19.222">
                    <g id="Group_6929" data-name="Group 6929" transform="translate(0.555)">
                        <path id="Path_7439" data-name="Path 7439" d="M1198.9,422.1a7.832,7.832,0,1,1-2.609-15.216" transform="translate(-1188.457 -403.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.063"/>
                        <rect id="Rectangle_2189" data-name="Rectangle 2189" width="7.863" height="7.863" rx="1.003" transform="translate(7.342 11.325) rotate(-45)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_7440" data-name="Path 7440" d="M1196.1,404.568l2.353,2.353a.646.646,0,0,1,0,.913l-2.353,2.353a.644.644,0,0,1-1.1-.456v-4.706a.644.644,0,0,1,1.1-.456Z" transform="translate(-1187.165 -404.38)" fill="#28345c"/>
                    </g>
                    </svg>
                </div>
                <div class="option-container" >
                    <svg @click="imgCroppa.chooseFile()" xmlns="http://www.w3.org/2000/svg" width="18.647" height="15.196" viewBox="0 0 18.647 15.196">
                    <g id="Group_6932" data-name="Group 6932" transform="translate(0.4 0.4)">
                        <g id="Group_6931" data-name="Group 6931" transform="translate(0 0)">
                        <g id="Group_1127" data-name="Group 1127" transform="translate(5.918 3.145)">
                            <g id="Group_509" data-name="Group 509" transform="translate(0 1.763)">
                            <g id="Group_508" data-name="Group 508">
                                <path id="Path_693" data-name="Path 693" d="M270.449,1765.184v1.892a.933.933,0,0,1-.931.932h-4.56a.933.933,0,0,1-.931-.932v-1.923" transform="translate(-264.026 -1765.153)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            </g>
                            </g>
                            <line id="Line_48" data-name="Line 48" y1="3.394" transform="translate(3.207)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            <path id="Path_694" data-name="Path 694" d="M283.277,1750.756l.821-.821a.231.231,0,0,1,.326,0l.786.786" transform="translate(-281.057 -1749.867)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                        </g>
                        <g id="Group_1128" data-name="Group 1128" transform="translate(0 0)">
                            <rect id="Rectangle_297" data-name="Rectangle 297" width="17.847" height="10.806" rx="1" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            <rect id="Rectangle_298" data-name="Rectangle 298" width="5.612" height="2.405" transform="translate(6.239 10.806)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            <rect id="Rectangle_299" data-name="Rectangle 299" width="8.331" height="1.15" rx="0.575" transform="translate(4.88 13.246)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                        </g>
                        </g>
                    </g>
                    </svg>
                    <svg @click="openGallery" xmlns="http://www.w3.org/2000/svg" width="17.051" height="15.727" viewBox="0 0 17.051 15.727">
                    <g id="Group_6933" data-name="Group 6933" transform="translate(0.4 0.4)">
                        <g id="Group_1129" data-name="Group 1129" transform="translate(0)">
                        <g id="Group_517" data-name="Group 517" transform="translate(3.134 6.358)">
                            <path id="Path_702" data-name="Path 702" d="M275.083,782.607l1.633-2.074a.582.582,0,0,1,.915,0l1.633,2.074" transform="translate(-274.23 -775.884)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="0.8"/>
                            <path id="Path_703" data-name="Path 703" d="M290.931,773.091l1.861-2.365a.582.582,0,0,1,.915,0l3.115,3.931" transform="translate(-287.057 -767.947)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="0.8"/>
                            <ellipse id="Ellipse_46" data-name="Ellipse 46" cx="0.753" cy="0.753" rx="0.753" ry="0.753" transform="translate(1.436 1.159)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            <path id="Path_704" data-name="Path 704" d="M297.8,775.017c.287.169.588.353.588.353l.422-.286.36.263.589-.408" transform="translate(-292.62 -771.537)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            <rect id="Rectangle_300" data-name="Rectangle 300" width="10.114" height="6.723" rx="1" transform="translate(0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                        </g>
                        <g id="Group_518" data-name="Group 518">
                            <rect id="Rectangle_301" data-name="Rectangle 301" width="16.251" height="10.647" rx="2" transform="translate(0 4.28)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                            <path id="Path_705" data-name="Path 705" d="M270.412,729.479v-2.954a1.121,1.121,0,0,0-1.121-1.121h-6.405a1.121,1.121,0,0,1-.933-.5l-.454-.684a1.12,1.12,0,0,0-.933-.5h-5.284a1.121,1.121,0,0,0-1.121,1.121v4.635" transform="translate(-254.161 -723.723)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.8"/>
                        </g>
                        </g>
                    </g>
                    </svg>
                    <template>
                        <slot name="small-icon">

                        </slot>
                    </template>
                </div>
            </div>
            <div 
                v-if="imgCroppa.imageSet && align == 'top' " 
                class="img-slider-container"
               
            >
                <svg class="rotate" @click="imgCroppa.rotate();$emit('rotateImage')" xmlns="http://www.w3.org/2000/svg" width="19.724" height="19.222" viewBox="0 0 19.724 19.222">
                    <g id="Group_6929" data-name="Group 6929" transform="translate(0.555)">
                        <path id="Path_7439" data-name="Path 7439" d="M1198.9,422.1a7.832,7.832,0,1,1-2.609-15.216" transform="translate(-1188.457 -403.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.063"/>
                        <rect id="Rectangle_2189" data-name="Rectangle 2189" width="7.863" height="7.863" rx="1.003" transform="translate(7.342 11.325) rotate(-45)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_7440" data-name="Path 7440" d="M1196.1,404.568l2.353,2.353a.646.646,0,0,1,0,.913l-2.353,2.353a.644.644,0,0,1-1.1-.456v-4.706a.644.644,0,0,1,1.1-.456Z" transform="translate(-1187.165 -404.38)" fill="#28345c"/>
                    </g>
                </svg>
                <div class="slider">
                    <label for="width">{{toLocal('generic.zoom')}}</label>
                    <input id="width" type="range" novalidate @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal">
                </div>
                <div class="slider">
                    <label for="height">{{toLocal('generic.expand')}}</label>
                    <input id="height" v-if="imgCroppa.imageSet && !height && align == 'top'" class="height-slider" type="range" @input="onSliderHeightChange" :min="sliderHeightMin" :max="sliderHeightMax" step="5" v-model="sliderHeightVal">
                </div>
            </div>
            <div v-else-if="imgCroppa.imageSet && align == 'top-user' && !readOnly " class="img-slider-container">
                <svg class="rotate" @click="rotateImage" xmlns="http://www.w3.org/2000/svg" width="19.724" height="19.222" viewBox="0 0 19.724 19.222">
                    <g id="Group_6929" data-name="Group 6929" transform="translate(0.555)">
                        <path id="Path_7439" data-name="Path 7439" d="M1198.9,422.1a7.832,7.832,0,1,1-2.609-15.216" transform="translate(-1188.457 -403.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.063"/>
                        <rect id="Rectangle_2189" data-name="Rectangle 2189" width="7.863" height="7.863" rx="1.003" transform="translate(7.342 11.325) rotate(-45)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_7440" data-name="Path 7440" d="M1196.1,404.568l2.353,2.353a.646.646,0,0,1,0,.913l-2.353,2.353a.644.644,0,0,1-1.1-.456v-4.706a.644.644,0,0,1,1.1-.456Z" transform="translate(-1187.165 -404.38)" fill="#28345c"/>
                    </g>
                </svg>
                <div class="slider">
                    <label for="width">{{toLocal('generic.zoom')}}</label>
                    <input id="width" type="range" novalidate @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal">
                </div>
                <div class="slider">
                    <label for="brightness">{{toLocal('generic.brightness')}}</label>
                    <input id="brightness" v-if="imgCroppa.imageSet  && align == 'top-user'" class="brightness-slider" type="range" @input="onSliderBrightnessChange" :min="sliderBrightnessMin" :max="sliderBrightnessMax" step="5" v-model="sliderBrightnessVal">
                </div>
            </div>
            <div v-else-if="imgCroppa.imageSet " class="img-slider-container">
                <input type="range" novalidate @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal">
            </div>
        </div>

    </croppa>
    <template>
        <slot name="is-uploading">

        </slot>
    </template>
    <input v-if="imgCroppa.imageSet && !height && align != 'top' && align != 'top-user' && !readOnly" class="height-slider" type="range" @input="onSliderHeightChange" :min="sliderHeightMin" :max="sliderHeightMax" step="5" v-model="sliderHeightVal">
</div>
</template>

<script>
import _ from 'lodash'
export default {

 name:'ImageEditor',
 data(){
     return{
        imgCroppa: {},
        sliderVal: 0,
        sliderMin: 0,
        sliderMax: 0,
        sliderHeightVal: 250,
        sliderHeightMin: 50,
        sliderHeightMax: window.innerHeight*0.88,
        sliderBrightnessVal: 100,
        sliderBrightnessMin: 0,
        sliderBrightnessMax: 200,
        canvasHeight:50,
        canvasWidth: 250,
        croppaParent: '',
        autoSize: false,
        xLock: 0,
        yLock: 0,
        sliderLocked: false,
        scaleLock: 0,
        imgSize: [],
        mobileLoad:false,
        loaded: false,
        maxWindowHeightRatio: 0.88
     }
 },
  props:{
    height: Number | undefined,
    width: Number | undefined,
    initial: String,
    metadata: Array | Object,
    metadataString: String,
    align: String,
    isEditing: Boolean,
    imageSize: Array,
    readOnly: Boolean,
    preview: Boolean,
    rotation: Number,
    showSlider: Boolean | undefined,
    showHalfSlider: Boolean | undefined,
 },
 watch: { 
    height(){
        if(this.height)
            this.imgCroppa.$el.parentElement.style.height = this.height+'px'
    },
    rotation(newVal, oldVal){
        if(newVal && this.imgCroppa && this.imgCroppa.imgData && this.readOnly){
              this.setRotationMetadata(this.imgCroppa, this.rotation);
        }
    },

    
    canvasHeight (newVal, oldVal) {
        var height = newVal;
        if(this.canvasHeight > window.innerHeight * 0.88){
            height = window.innerHeight * 0.80;
        }
      
        this.resizeCanvas(height);
    },
   'metadata.data':{
        handler (newVal, oldVal){
            this.applyMetadata();
        }
   },
   loaded(){
        if(this.loaded){
            this.$emit('loaded');
        }
    }
},

created() {
  window.addEventListener("resize", this.resizeCanvas);
},
destroyed() {
  window.removeEventListener("resize", this.resizeCanvas);
    this.loaded = false;
},

 mounted(){
    this.canvasWidth = this.imgCroppa.$el.parentElement.offsetWidth;
    
    if(this.rotation && this.rotation != ''){
         this.setRotationMetadata(this.imgCroppa, this.rotation);
    
    }
    if(this.height)
        this.imgCroppa.$el.parentElement.style.height = this.height+'px';
    if(this.preview){
        setTimeout(()=>{
            var ratio_width = 900-this.imgCroppa.imgData.startX/2;
            var ratio_height = this.canvasHeight-Math.abs(this.imgCroppa.imgData.startY/2);
            this.canvasWidth = Math.min(ratio_width, 900);
            this.canvasHeight = ratio_height;
            if(this.metadata.height) this.canvasHeight = this.metadata.height;
            if(this.imgCroppa.imgData.width/2 <= this.canvasWidth){
                this.canvasWidth = this.imgCroppa.imgData.width/2;
            }
            this.$set(this.imgCroppa.imgData, 'startX', Math.min(0, this.imgCroppa.imgData.startX));
        },100)
    }
    setTimeout(()=>{
            if(this.canvasHeight <= 50){
                if(this.canvasHeight < this.imgCroppa.naturalHeight){
                    this.canvasHeight = window.innerHeight * 0.80;
                }
                else{
                    this.canvasHeight = this.imgCroppa.naturalHeight;
                }
            }
    },100)
    
 },
 methods:{
    setRotationMetadata: _.debounce((imgCroppa, rotation) => {
        var meta = imgCroppa.getMetadata();
        meta.orientation = rotation;
        imgCroppa.applyMetadata(meta);
    }, 200),
    onSliderChange(evt) {
        
        var increment = evt.target.value
        if(this.align == 'top'){

            this.sliderLocked = false;
            if(this.imgCroppa.imgData.startX < 20 && this.imgCroppa.imgData.startX > -20){
                this.sliderLocked = true;
                this.xLock = Math.abs(+increment - this.imgCroppa.scaleRatio);
                if(this.xLock > 0.2){
                    this.sliderLocked = false;
                }
                this.imgCroppa.scaleRatio = (this.imgCroppa.canvas.scrollWidth / this.imgCroppa.naturalWidth) *2;
            }
            if(!this.sliderLocked){
                this.imgCroppa.scaleRatio = +increment
            }
        }
        else{
            this.imgCroppa.scaleRatio = +increment
           
        }
        var imageY = this.imgCroppa.imgData.startY;
        var imageX = this.imgCroppa.imgData.startX;
        imageY = (this.imgCroppa.canvas.scrollHeight - this.imgCroppa.imgData.height / 2);
        imageX = (this.imgCroppa.canvas.scrollWidth - this.imgCroppa.imgData.width / 2);
        this.imgCroppa.imgData.startY = imageY;
        this.imgCroppa.imgData.startX = imageX;
        let obj = {
            scale: this.imgCroppa.scaleRatio,
            startX: this.imgCroppa.imgData.startX,
            startY: this.imgCroppa.imgData.startY,
        }
        this.$emit('scale',obj )
    },
    onSliderHeightChange(evt) {
        var increment = evt.target.value
            this.sliderLocked = false;
            if(this.imgCroppa.imgData.startY < 20 && this.imgCroppa.imgData.startY > -20){
                this.sliderLocked = true;
                this.yLock = Math.abs(+increment - this.canvasHeight);
                if(this.yLock > 20){
                    this.sliderLocked = false;
                }
                this.canvasHeight = this.imgCroppa.imgData.height / 2;
            }
            if(!this.sliderLocked){
                this.canvasHeight = +increment
            }
        var imageY = this.imgCroppa.imgData.startY;
        var imageX = this.imgCroppa.imgData.startY;
        imageY =  (this.canvasHeight -this.imgCroppa.imgData.height / 2);
        imageX =  (this.imgCroppa.canvas.scrollWidth -this.imgCroppa.imgData.width / 2);
        this.imgCroppa.imgData.startY = imageY;
        this.imgCroppa.imgData.startX = imageX;
        let obj = {
            canvasHeight: this.canvasHeight,
            startX: this.imgCroppa.imgData.startX,
            startY: this.imgCroppa.imgData.startY,
        }
      this.$emit('heightChange',obj )
     },
     initialLoaded() {
        // this.$nextTick(()=>{
        if(!this.loaded){
            this.applyMetadata();
            this.loaded = true;
        }   
        // })
    },
    onZoom() {
      this.sliderVal = this.imgCroppa.scaleRatio
    },
    handleChoose(e){
        this.imgCroppa.refresh()
        if(!this.height){
            this.canvasHeight = this.imgCroppa.naturalHeight >= 50 ? this.imgCroppa.naturalHeight : 50;
            this.imgCroppa.$el.parentElement.style.height = this.canvasHeight+'px';
        } 
    },
    onNewImage(e){
        this.sliderVal = this.imgCroppa.scaleRatio;
        this.sliderMin = this.imgCroppa.scaleRatio / 5;
        this.sliderMax = Math.max(this.imgCroppa.scaleRatio *3, 2);
        if(!this.height){
            this.canvasWidth = this.imgCroppa.$el.parentElement.offsetWidth;
            this.autoSize= true;
        }
        if(this.align=='top'){
           this.applyMetadata();
        }
    },
    uploadImage(){
        this.$emit('uploadImage');
    },
    saveImage(){
        this.$emit('saveImage', this.imgCroppa);
        this.$emit('saveMetadata', [this.imgCroppa, this.canvasHeight]);
    },
    saveImageMetadata(){
        if(!this.readOnly && this.imgCroppa && this.align=='top')
            this.$emit('saveMetadata', [this.imgCroppa, this.canvasHeight]);
    },
    saveCardMetadata(){
        this.$emit('saveMetadata', [this.imgCroppa, this.canvasHeight]);
    },
    deleteImage(){
        this.imgCroppa.remove();
        this.$emit('deleteImage', this.imgCroppa);
    },
    openGallery(){
        this.$emit('openGallery', this.imgCroppa);
    },
    setCanvasSize(){
        if(!this.height)
            this.imgCroppa.$el.parentElement.style.height = this.imgCroppa.naturalHeight+'px';
    },
    resizeCanvas(){
   
        if(!this.height){
            this.$set(this.imgCroppa.$el.parentElement.style, 'height', this.canvasHeight+'px');
            // this.canvasHeight = this.imgCroppa.$el.parentElement.offsetHeight;
        }
        if(!this.width){
            this.canvasWidth = this.imgCroppa.$el.parentElement.offsetWidth;
        }
    },
    applyMetadata(){
        this.imgSize = [];
        let objCondition = this.metadata &&  this.metadata != null && this.metadata.data!={} && this.metadata.height != null && this.imgCroppa && this.imgCroppa.imgData && this.align != 'cover';
        let arrayCondition = this.metadata && this.metadata != null && this.metadata.length == 2 && this.metadata[1] != null && this.imgCroppa && this.imgCroppa.imgData && this.align != 'cover';
        let condition = this.metadata && this.metadata != null && Array.isArray(this.metadata) ? arrayCondition : objCondition;
        let data = {};
        let height = 0;
        if(this.metadata && this.metadata != null){
            data = Array.isArray(this.metadata) ? this.metadata[0] : this.metadata.data;
            height = Array.isArray(this.metadata) ? this.metadata[1] : this.metadata.height;
        }
        if(condition){
            this.imgCroppa.applyMetadata(JSON.parse(data));
            this.imgCroppa.imgData.startX = -100;
            this.sliderVal = this.imgCroppa.scaleRatio*3;
            this.sliderHeightVal = height;
            this.canvasHeight = height;
        }
        else{
           this.setCanvasSize();
        }
        // setTimeout(()=>{
        //     this.$emit('loaded')
        // },200)
    },
    setCanvasSize(){
            if(this.imageSize && this.imageSize.length > 0 || (this.imgCroppa.imgData && (this.imgCroppa.imgData.height/2 > window.innerHeight*0.88))){
               this.setCanvasSizeWithImageData();
            }
            else{

                if(this.imgCroppa && this.imgCroppa.imgData && this.align != 'cover'){
                    let ratio = this.imgCroppa.naturalHeight / this.imgCroppa.naturalwidth;
                    if(this.imgCroppa.imgData.height < this.canvasHeight){
                         this.$set(this.imgCroppa.imgData, 'height', this.canvasHeight*2);
                    }
                    if(this.imgCroppa.imgData.width >= this.imgCroppa.imgData.height){
                        ratio =  this.imgCroppa.naturalHeight / this.imgCroppa.naturalWidth;
                        let width = this.width ? parseFloat(this.width) : 896;
                        this.canvasWidth = width;
                        this.$set(this.imgCroppa.imgData, 'width', this.canvasWidth*2);
                        if(!this.height){
                            this.imgCroppa.$el.parentElement.style.height = this.imgCroppa.$el.parentElement.clientWidth*ratio+'px';
                            this.canvasHeight = this.imgCroppa.$el.parentElement.clientWidth*ratio;
                        }
                        else{
                            let height = parseFloat(this.height);
                            height = height* ratio;
                             this.$set(this.imgCroppa.imgData, 'height', this.imgCroppa.imgData.width*ratio);
                            this.canvasHeight =this.imgCroppa.imgData.width*ratio;
                        }
                    }
                    else{
                        if(!this.height){
                            let h_temp = this.canvasHeight;
                            if(h_temp == 0){
                                h_temp =  this.imgCroppa.naturalHeight * 0.8
                            }
                            this.$set(this.imgCroppa.imgData, 'height', Math.min(window.innerHeight*0.88*2, this.canvasHeight*2));
                        }
                        else{
                            let height = parseFloat(this.height);
                            this.$set(this.imgCroppa.imgData, 'height', height*2);
                            this.canvasHeight = height;
                            if(this.width){
                                let width = parseFloat(this.width);
                                this.canvasWidth = width;
                            }
                        }
                        
                    }
                    this.sliderVal = this.imgCroppa.scaleRatio*3;
                    this.sliderHeightVal = this.imgCroppa.imgData.height/2;
                    if(!this.canvasHeight){
                        if(this.height) this.canvasHeight = parseInt(this.height);
                        else this.canvasHeight = this.imgCroppa.imgData.height/2;
                    }
                }
                else if(this.imgCroppa && this.imgCroppa.refresh){
                    this.$set(this.imgCroppa.imgData, 'startY', 0);
                }
        
            }
            setTimeout(()=>{
                this.imgCroppa.imgData.startY =1;
                let height = this.canvasHeight == this.imgCroppa.imgData.height ? this.height : this.canvasHeight;
                var imageY = (height - this.imgCroppa.imgData.height / 2);
                var imageX = (this.canvasWidth - this.imgCroppa.imgData.width/2);
                if(this.align != 'cover'){
                    this.imgCroppa.imgData.startY = imageY;
                    this.imgCroppa.imgData.startX = imageX;
                }
            },100)
    },
    setCanvasSizeWithImageData(){
        if(this.imageSize){
            this.imgSize = this.imageSize;
        }
        else{
            let width = this.imgCroppa.naturalWidth;
            let current_height = Math.min(this.imgCroppa.naturalHeight, window.innerHeight*this.maxWindowHeightRatio);
            this.imgSize = [width, current_height]
        }
        let height = this.imgSize[1];
        //check if current height is greater than max viable editor height
        if(height >= window.innerHeight*this.maxWindowHeightRatio){
            this.canvasHeight = window.innerHeight*this.maxWindowHeightRatio;
            this.sliderHeightVal = window.innerHeight*this.maxWindowHeightRatio;
            this.$nextTick(() => {
                if(this.imgCroppa.imgData.height > window.innerHeight*this.maxWindowHeightRatio *2){
                    this.imgCroppa.imgData.height = window.innerHeight*this.maxWindowHeightRatio*2;
                }
            })
        }
        else{
            
            var ratio_height = this.imgSize[1]/ this.imgSize[0];
            if(this.imgSize[0] > this.canvasWidth){
                this.canvasHeight = ratio_height*this.canvasWidth;
                this.imgCroppa.imgData.width = this.canvasWidth*2;
                this.imgCroppa.imgData.height = this.canvasHeight*2;
            }
            else {
                this.canvasHeight = this.imgSize[1];
                this.imgCroppa.imgData.height = this.imgSize[1]*2;
            }
            this.sliderHeightVal = this.canvasHeight;
        }
    },
    onSliderBrightnessChange(e){
        this.$set(this.imgCroppa.ctx, 'filter', 'brightness('+this.sliderBrightnessVal+'%)');
        this.imgCroppa.ctx.drawImage(this.imgCroppa.img, this.imgCroppa.imgData.startX, this.imgCroppa.imgData.startY, this.imgCroppa.imgData.width, this.imgCroppa.imgData.height);
    },
    rotateImage(){
        this.imgCroppa.rotate(); 
        setTimeout(()=>{
            this.$emit('imgRotate', this.imgCroppa.orientation);
        },100);
    },
    chooseFile(){
        this.imgCroppa.chooseFile()
        
    },
    onImageDraw(){
        if(!this.loaded) this.loaded = true;
    },

 },
 beforeDestroy(){
      this.imgCroppa= {};
 }
}
</script>

<style lang="scss" scoped>
.editor-parent{
    width: 100%;
    // height: 100%;
    &:hover input{
        opacity: 1;
    }
}

.croppa-container{
    border-radius: 20px;
    background-color: #DBDBDB;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    &:hover div{
        opacity: 1;
    }
    &.read-only{
        background-color: transparent;
    }
    &.user{
        border-radius: 10px;
        canvas{
            border-radius: 10px;
        }
    }
}
canvas{
    position: absolute;
    // border-radius: 20px;
    height: 100%;
    width: 100%;
}
.img-tool-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  z-index: 2;
  position: absolute;
  bottom: 0.1rem;
  width: 90%;
  min-width: 200px;
  max-width: 550px;
  justify-content: center;
  padding: 5px;
  border-radius: 6px;
  opacity:0;
  transition: opacity 0.3s ease;
  cursor:pointer;
  .file-container{
    width: 100%;
    max-width: 75px;
    justify-content: space-around;
    flex: 2;
    display: flex;
    margin-right: 5px;
      .save-image, .delete-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffffffd1;
        padding: 5px;
        border-radius: 6px;
        height: 30px;
        width: 30px;
        svg{
            fill: none;
            stroke: #28345C;
        }
        &:hover{
            background: #a9b9eec4;
        }
      }
  }
  .image-manipulation{
      display: flex;
      height: 30px;
      max-width: 185px;
      flex:3;
      background: #ffffffd1;
      padding: 5px;
      border-radius: 6px;
      svg{
        height: 20px;
        width: 20px;
      }
  }
  .tool-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex:1;
    padding: 0 5px;
    border-right: 2px solid white;
  }
  .option-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex:2;
  }
  svg{
      transition: all 0.3s ease;
      &:hover{
          transform: scale(1.1)
      }
  }
  &.align-top{
    top: -2.2rem;
    opacity: 1;
    max-height: 40px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    .img-slider-container{
        opacity: 1;
        width: 95%;
        min-width: 95%;
        max-width: 100%;
        gap: 15px;
        margin: 0;
        top: 0;
        .slider{
            width:100%;
            label{
                font-size: 12px;
                padding-left: 6px;
                color: #28345c;
            }
            input{
                margin-top: 7px;
            }
        }
        .rotate{
            min-width: 20px;
            display: flex;
            min-height: 100%;
            align-self: flex-end;
        }
    }
    .height-slider, .brightness-slider{
        position: initial;
        transform: none;
        opacity: 1;
    }
  }
  &.user{
      top:0rem;
  }
  &.doubled{
    width: 200%;
    min-width: 100%;
    max-width: 200%;
    left: 0;
    top: -2.5rem;
  }
}
.img-slider-container, .height-slider-container{
  display:flex;
  position: absolute;
   width: 90%;
  min-width: 200px;
  max-width: 280px;
  bottom: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor:pointer;
  input{
     -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: #A9B9EE;
    border-radius: 10px;
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: #28345C; /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: #28345C; /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
  }
}
.img-slider-container{
    margin: 23px 20px 0 20px;
    position: relative;
}
.height-slider-container{
    bottom: 0.5rem;
    right: 0.5rem;
    input{
        position:absolute;
        transform: rotate(270deg);
        transform-origin: left;
        bottom: 0.5rem;
        right: 0.5rem;
    }
}
.height-slider, .brightness-slider{
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    position:absolute;
    opacity: 0;
    transform: rotate(90deg);
    transform-origin: left;
    width: 225px; /* Full-width */
    height: 4px; /* Specified height */
    background: #A9B9EE;
    border-radius: 10px;
    outline: none; /* Remove outline */
    right: -10.5rem;
    top: 0.5rem;
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
}
.brightness-slider{
    background: linear-gradient(90deg, rgba(40,52,92,1) 10%, rgba(169,185,238,1) 90%) !important;
}

.choose-file-big{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s transform, .3s opacity;
    transition: .3s transform, .3s opacity;
    cursor: pointer;
    height: 70px;
    padding: 10px;
    &:hover{
        transform: scale(1.1);
    }
    img{
        height: 100%;
        width: 100%;
    }
}
  .btn{
    position: absolute;
    z-index: 2;
    display: flex;
    right: 2.4rem;
    top: 1.5rem;
    width: 20px;
    height: 20px;
    svg{
      fill: #707070;
      background: #efefef;
      border-radius: 50%;
      cursor: pointer;
      height:100%;
      width: 100%;
      transition: all 0.3s;
      &:hover{
        fill: var(--primary-color);
      }
    }
  }
.croppa-container.croppa--disabled{
    cursor: initial;
}

.content--item--wrapper {
  &.merged-background-top{
    margin-top: 0;
    .croppa-container{
    background-color: white;
    }
  }
  &.merged-background-bottom{
     margin-bottom: 0;
     .croppa-container{
       background-color: white;
     }
  }
}
.spinner-container {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background:white;
    .spinner{
        position: absolute;
        top: 50%;
        left: 50%;
        height: 70px;
        width: 70px;
        margin-left: -35px;
        margin-top: -35px;
        -webkit-animation: spin 1s linear infinite;
                animation: spin 1s linear infinite;
        border: 3px solid #ddd;
        border-top: 3px solid #42a5f5;
        border-radius: 50%;
    }
}
.img-rotate-container{
    position: absolute;
    left: 1rem;
    top: 3rem;
}

@-webkit-keyframes spin {
  to {
    border-top-color: #ec407a;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    border-top-color: #ec407a;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
</style>
<style lang="scss">
.croppa-container{
    &.user{
        canvas{
            border-radius: 0px;
        }
    }
  canvas{
    position: absolute;
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }
}
</style>