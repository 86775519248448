<template>
  <div>
      <div class="filter-container" :class="{empty: totalPageCount == 0}">
        <div class="filter">
          <div class="search-container">
              <div class="search-icon">
                  <svg  v-if="searchInput == ''" id="Group_10091" data-name="Group 10091" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.933" height="20.934" viewBox="0 0 20.933 20.934">
                    <defs>
                        <clipPath id="clip-path">
                        <rect id="Rectangle_2331" data-name="Rectangle 2331" width="20.933" height="20.934" fill="#28345c"/>
                        </clipPath>
                    </defs>
                    <g id="Group_7557" data-name="Group 7557" >
                        <path id="Path_6095" data-name="Path 6095" d="M.555,20.382a1.893,1.893,0,0,1,0-2.677L6.2,12.057a7.785,7.785,0,0,1-.568-1.131A7.94,7.94,0,1,1,10.008,15.3a7.545,7.545,0,0,1-1.13-.567L3.229,20.382a1.9,1.9,0,0,1-2.674,0M8.527,3.467A6.324,6.324,0,1,0,13,1.616a6.323,6.323,0,0,0-4.47,1.851Z" transform="translate(0 0)" fill="#28345c"/>
                    </g>
                    </svg>
                    <div v-else class="remove" @click="searchInput = ''">
                        <span></span><span></span>
                    </div>
                </div>
              <input type="text" :placeholder="toLocal('training_stats.enter_search_text')" v-model="searchInput" @keydown.enter="loadTrainingInfo">
          </div>
          <div class="group-filter">
            <SelectInput 
              v-model="groupFilter" 
              width="10rem" 
              theme="filter" 
              :placeholder="toLocal('training_stats.group_filter')" 
              :options="groups" 
              multichoice  
              :canSelectAll="false" 
              label="name"
            />
          </div>
          <span class="label">{{toLocal('training_stats.show_more_attempts')}}</span>
          <ToggleButton :toggledState="showAllStats" theme="gray"  @changeState="showAllStats = $event" />
        </div>
      </div>
      <paginate
      v-if="searchingStats ? searchUserStats.length > 0 : stats.length > 0"
      v-model="page"
      :page-count="searchingStats ? totalSearchPageCount : totalPageCount"
      :page-range="3"
      :margin-pages="2"
      :click-handler="clickCallback"
      :prev-text="toLocal('generic.back')"
      :next-text="toLocal('generic.next')"
      :container-class="'pagination stats top'"
      :page-class="'page-item'">
    </paginate>
    <div class="grid-table">
        <div class="table-row header">
          <div class="table-cell">
            <span class="name-cell header">
              {{toLocal('generic.name')}}
              <img
                v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                src="@/assets/images/icons/order-by-desc.svg"
                @click="setOrderBy('name')"
              >
              <img
                v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                src="@/assets/images/icons/order-by-asc.svg"
                @click="setOrderBy('name')"
              >
              <img
                v-if="orderByElement != 'name'"
                src="@/assets/images/icons/order-by.svg"
                @click="setOrderBy('name')"
              >
            </span>
          </div>
          <div class="table-cell">
            <span class="header">
              {{toLocal('generic.surname')}}
              <img
                v-if="orderByElement == 'last_name' && orderByDirection == 'desc'"
                src="@/assets/images/icons/order-by-desc.svg"
                @click="setOrderBy('last_name')"
              >
              <img
                v-if="orderByElement == 'last_name' && orderByDirection == 'asc'"
                src="@/assets/images/icons/order-by-asc.svg"
                @click="setOrderBy('last_name')"
              >
              <img
                v-if="orderByElement != 'last_name'"
                src="@/assets/images/icons/order-by.svg"
                @click="setOrderBy('last_name')"
              >
            </span>
          </div>
          <div class="table-cell">
            <span class="header">
              {{toLocal('test_stats.date_of_attempt')}}
              <img
                v-if="orderByElement == 'created_at' && orderByDirection == 'desc'"
                src="@/assets/images/icons/order-by-desc.svg"
                @click="setOrderBy('created_at')"
              >
              <img
                v-if="orderByElement == 'created_at' && orderByDirection == 'asc'"
                src="@/assets/images/icons/order-by-asc.svg"
                @click="setOrderBy('created_at')"
              >
              <img
                v-if="orderByElement != 'created_at'"
                src="@/assets/images/icons/order-by.svg"
                @click="setOrderBy('created_at')"
              >
            </span>
          </div>
          <div class="table-cell">
            <span class="header">
              {{toLocal('training_stats.length_of_attempt')}}
              <img
                v-if="orderByElement == 'time' && orderByDirection == 'desc'"
                src="@/assets/images/icons/order-by-desc.svg"
                @click="setOrderBy('time')"
              >
              <img
                v-if="orderByElement == 'time' && orderByDirection == 'asc'"
                src="@/assets/images/icons/order-by-asc.svg"
                @click="setOrderBy('time')"
              >
              <img
                v-if="orderByElement != 'time'"
                src="@/assets/images/icons/order-by.svg"
                @click="setOrderBy('time')"
              >
            </span>
          </div>
          <div class="table-cell">
            <span class="header">
              {{toLocal('training.users_rating')}}
              <img
                v-if="orderByElement == 'rate' && orderByDirection == 'desc'"
                src="@/assets/images/icons/order-by-desc.svg"
                @click="setOrderBy('rate')"
              >
              <img
                v-if="orderByElement == 'rate' && orderByDirection == 'asc'"
                src="@/assets/images/icons/order-by-asc.svg"
                @click="setOrderBy('rate')"
              >
              <img
                v-if="orderByElement != 'rate'"
                src="@/assets/images/icons/order-by.svg"
                @click="setOrderBy('rate')"
              >
            </span>
          </div>
           <div class="table-cell">
            <span class="header">
              {{toLocal('training.users_comment')}}
              <img
                v-if="orderByElement == 'comment' && orderByDirection == 'desc'"
                src="@/assets/images/icons/order-by-desc.svg"
                @click="setOrderBy('comment')"
              >
              <img
                v-if="orderByElement == 'comment' && orderByDirection == 'asc'"
                src="@/assets/images/icons/order-by-asc.svg"
                @click="setOrderBy('comment')"
              >
              <img
                v-if="orderByElement != 'comment'"
                src="@/assets/images/icons/order-by.svg"
                @click="setOrderBy('comment')"
              >
            </span>
          </div>
        </div>
        <div class="table-row parent" v-for="(user, user_id) in searchUserStats" :key="user_id" v-show="user.user">
          <div v-if="!showAllStats && user.user && user.training" class="table-row">
            <div class="table-cell">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                <defs>
                  <clipPath id="clip-path">
                    <circle id="Ellipse_467" data-name="Ellipse 467" cx="14" cy="14" r="14" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1"/>
                  </clipPath>
                </defs>
                <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-1212 -2055.711)" clip-path="url(#clip-path)">
                  <g id="Ellipse_464" data-name="Ellipse 464" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1">
                    <circle cx="14" cy="14" r="14" stroke="none"/>
                    <circle cx="14" cy="14" r="13.5" fill="none"/>
                  </g>
                  <g id="Ellipse_465" data-name="Ellipse 465" transform="translate(1221 2062.711)" fill="#707070" stroke="#707070" stroke-width="1">
                    <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                    <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                  </g>
                  <g id="Ellipse_466" data-name="Ellipse 466" transform="translate(1215 2070.711)" fill="#707070" stroke="#707070" stroke-width="1">
                    <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
                    <circle cx="11.5" cy="11.5" r="11" fill="none"/>
                  </g>
                </g>
              </svg>
              <span  
                @mouseover="scrollNameHorizontal($event,true)" 
                @mouseleave="scrollNameHorizontal($event,false)"
              >
                {{user.user.name}}
              </span>
            </div>
            <div class="table-cell">
              <span
                @mouseover="scrollNameHorizontal($event,true)" 
                @mouseleave="scrollNameHorizontal($event,false)"
              >
                {{user.user.last_name}}
              </span>
            </div>
            <div class="table-cell">
              <span
            
              >
                {{getStartDate(user)}}
              </span>
            </div>
            <div class="table-cell"><span
      
            >
            <!-- {{user.training.training_end[0].end.created_at}} -->
              {{user.training.training_end ? getTestSolvingTime(user.training.training_end[0], user.training.training_end[0].time) : ''}}
            </span>
            </div>
          
             <div class="table-cell"><span
        
            >{{user.training.training_end ? user.training.training_end[0].rate : ''}}
            </span>
            </div>
             <div class="table-cell"><span
               @mouseover="scrollNameHorizontal($event,true)" 
                @mouseleave="scrollNameHorizontal($event,false)"
            >{{user.training.training_end ? user.training.training_end[0].comment : ''}}
            </span>
            </div>
          </div>
           <div v-else-if="showAllStats && user.user && user.training" class="table-row" v-for="(stat, stat_id) in getAllUserStats(user)" v-show="user.user" :key="stat_id">
                <div class="table-cell">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                      <defs>
                          <clipPath id="clip-path">
                          <circle id="Ellipse_467" data-name="Ellipse 467" cx="14" cy="14" r="14" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1"/>
                          </clipPath>
                      </defs>
                      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-1212 -2055.711)" clip-path="url(#clip-path)">
                          <g id="Ellipse_464" data-name="Ellipse 464" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1">
                          <circle cx="14" cy="14" r="14" stroke="none"/>
                          <circle cx="14" cy="14" r="13.5" fill="none"/>
                          </g>
                          <g id="Ellipse_465" data-name="Ellipse 465" transform="translate(1221 2062.711)" fill="#707070" stroke="#707070" stroke-width="1">
                          <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                          <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                          </g>
                          <g id="Ellipse_466" data-name="Ellipse 466" transform="translate(1215 2070.711)" fill="#707070" stroke="#707070" stroke-width="1">
                          <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
                          <circle cx="11.5" cy="11.5" r="11" fill="none"/>
                          </g>
                      </g>
                      </svg>
                      <span
                          @mouseover="scrollNameHorizontal($event,true)" 
                          @mouseleave="scrollNameHorizontal($event,false)"
                      >
                      {{user.user ? user.user.name : ''}}
                  </span>
                  </div>
              <div class="table-cell">
                  <span
                      @mouseover="scrollNameHorizontal($event,true)" 
                      @mouseleave="scrollNameHorizontal($event,false)"
                  >
                  {{user.user ? user.user.last_name : ''}}
                  </span>
              </div>
              <div class="table-cell"><span
                      @mouseover="scrollNameHorizontal($event,true)" 
                      @mouseleave="scrollNameHorizontal($event,false)"
                      
                  >{{getStatStartDate(stat)}}
                  </span>
              </div>
              <div class="table-cell"><span
                      @mouseover="scrollNameHorizontal($event,true)" 
                      @mouseleave="scrollNameHorizontal($event,false)"
                  >{{stat.time != undefined ? getTestSolvingTime(stat, stat.time) : ''}}
                  </span>
              </div>
              
              <div class="table-cell"><span
                      @mouseover="scrollNameHorizontal($event,true)" 
                      @mouseleave="scrollNameHorizontal($event,false)"
                  >{{stat.rate ? stat.rate : ''}}
                  </span>
              </div>
              <div class="table-cell"><span
                      @mouseover="scrollNameHorizontal($event,true)" 
                      @mouseleave="scrollNameHorizontal($event,false)"
                  >{{stat.comment ? stat.comment : ''}}
                  </span>
              </div>
            </div>
        </div>
              
      </div>
      <paginate
      v-if="searchingStats ? searchUserStats.length > 0 : stats.length > 0"
      v-model="page"
      :page-count="searchingStats ? totalSearchPageCount : totalPageCount"
      :page-range="3"
      :margin-pages="2"
      :click-handler="clickCallback"
      :prev-text="toLocal('generic.back')"
      :next-text="toLocal('generic.next')"
      :container-class="'pagination stats'"
      :page-class="'page-item'">
    </paginate>
  </div>
</template>

<script>
import TrainingGroupStatsSide from "@/components/layout/trainings/TrainingGroupStatsSide";
import TrainingCommentsSide from "@/components/layout/trainings/TrainingCommentsSide";
import TrainingDownloadsSide from "@/components/layout/trainings/TrainingDownloadsSide";
import ToggleButton from "@/components/layout/emails/ToggleButton";
import SelectInput from "@/components/layout/emails/SelectInput";
import axios from "axios";
export default {
    components: {
        TrainingGroupStatsSide,
        TrainingCommentsSide,
        TrainingDownloadsSide,
        ToggleButton,
        SelectInput
    },
    data(){
        return{
            stats: [],
            searchStats: [],
            allStats: [],
            showAllStats: false,
            userStats: [],
            searchUserStats: [],
            orderByElement: "",
            orderByDirection: "",
            exporting: false,
            searchingStats: false,
            totalSearchPageCount: 0,
            totalPageCount:0,
            page: 1,
            searchPage:1,
            skip:0,
            limit: 100,
            searchSkip: 0,
            searchLimit: 100,
            user_count: 0,
            users_started: 0,
            table_user_count:0,
            table_users_started:0,
            allUsersIterator:0,
            avg_result:0,
            avg_rating:0,
            pagesViewed: [],
            searchInput: '',
            groupFilter: [],
            usersFilteredByGroup: [],
            usersFilteredBySearch: [],
            groups: [],
            firstStatsLoaded: false,
            firstSearchStatsLoaded: false,
            searchMode: false,
            lastSearch: '',
            cover: '',
            title: '',
        }
    },
    mounted() {
      if(this.stats.length == 0)
        this.loadTrainingInfo();
    },
    beforeDestroy() {
        this.resetData();
        this.groups=[];
        this.groupFilter=[];
        this.cover = '';

    },
    watch:{
      searchInput() {
          

        this.searchFilter();
        if (this.groupFilter.length > 0) {
          this.filterByGroups();
          let data = this.usersFilteredByGroup.filter(el => {
            let index = this.usersFilteredBySearch.findIndex(elem => elem.user && el.user ? elem.user.id == el.user.id : false);
            return index > -1;
          });
          this.totalPageCount = Math.ceil(data.length / (this.limit / 2));
          this.totalSearchPageCount = Math.ceil(data.length / (this.limit / 2));
          if(this.page > this.totalPageCount) this.page = this.totalPageCount == 0 ? 1 : this.totalPageCount
          this.searchUserStats = data.slice((this.page - 1) * this.limit / 2, (this.page) * this.limit / 2);
        }
        else {
          this.totalPageCount = Math.ceil(this.usersFilteredBySearch.length / (this.limit / 2));
          this.totalSearchPageCount = Math.ceil(this.usersFilteredBySearch.length / (this.limit / 2));
          if(this.page > this.totalPageCount) this.page = this.totalPageCount == 0 ? 1 : this.totalPageCount
          this.searchUserStats = this.usersFilteredBySearch.slice((this.page - 1) * this.limit / 2, (this.page) * this.limit / 2);
        }
          // this.searchDebounce();
        },
       
      'groupFilter.length'() {
          this.filterByGroups();
          if (this.searchInput.length > 0) {
            this.searchFilter()
             let data = this.usersFilteredBySearch.filter(el => {
              let index = this.usersFilteredByGroup.findIndex(elem => elem.user && el.user ? elem.user.id == el.user.id : false);
              return index > -1;
            });
            this.totalPageCount = Math.ceil(data.length / (this.limit / 2));
            this.totalSearchPageCount = Math.ceil(data.length / (this.limit / 2));
            if(this.page > this.totalPageCount) this.page = this.totalPageCount == 0 ? 1 : this.totalPageCount
            this.searchUserStats = data.slice((this.page - 1) * this.limit / 2, (this.page) * this.limit / 2);
          }
          else {
            this.totalPageCount = Math.ceil(this.usersFilteredByGroup.length / (this.limit / 2));
            this.totalSearchPageCount = Math.ceil(this.usersFilteredByGroup.length / (this.limit / 2));
            if(this.page > this.totalPageCount) this.page = this.totalPageCount == 0 ? 1 : this.totalPageCount
            this.searchUserStats = this.usersFilteredByGroup.slice((this.page - 1) * this.limit / 2, (this.page) * this.limit / 2);
          }

        }
    },
    methods:{
        resetData(){
            this.page = 1;
            this.skip = 0;
            this.searchSkip=0;
            this.allStats =[];
            this.userStats = [];
            this.searchUserStats = [];
            this.usersFilteredBySearch = [];
            this.usersFilteredByGroup = [];
            this.totalSearchPageCount= 0;
            this.totalPageCount=0;
            this.searchPage=1;
            this.limit= 100;
            this.searchSkip= 0;
            this.searchLimit= 100;
            this.user_count= 0;
            this.users_started= 0;
            this.allUsersIterator=0;
            this.pagesViewed= [];
            this.firstStatsLoaded= false;
            this.firstSearchStatsLoaded= false;
            this.searchMode= false;
            this.lastSearch = '';
            this.title = '';
        },
        loadTrainingInfo() {
            let search= this.searchInput != '' ? '&q=' + this.searchInput : '';
            if(search != ''){
                if(this.lastSearch != this.searchInput){
                this.skip = 0;
                this.page = 1;
                this.allStats = [];
                // this.userStats = [];
                this.searchUserStats = [];
                this.allUsersIterator= 0;
                this.searchMode =true;
                this.pagesViewed=[];
                this.lastSearch = this.searchInput;
                }
            }
            else{
                if(this.lastSearch != this.searchInput){
                    this.resetData();
                }
                this.searchMode =false;
            }
            axios.get(
                `${this.$store.state.api_link}/training/stats/${this.$store.state.currentTrainingRented ? 1:0}/${this.$store.state.currentTraining}?skip=${this.skip}&limit=${this.limit}&firstLoad=${!this.firstStatsLoaded}${search}`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.stats = response.data.stats;
                this.searchStats = response.data.stats;
                this.userStats = response.data.stats.filter(el=>el.user != undefined);
                this.searchUserStats = this._.cloneDeep(this.userStats.slice(0, this.limit/2));
                this.$store.state.loading.loadingDone();
                // this.skip+=this.limit;
                if((!this.firstStatsLoaded && !this.searchMode) || ((this.lastSearch != this.searchInput) && !this.searchMode)){
                this.table_user_count = response.data.user_count;
                this.table_users_started = response.data.users_started;
                if(!this.firstStatsLoaded && !this.searchMode){
                    this.user_count = response.data.user_count;
                    this.users_started = response.data.users_started;
                    this.groups =response.data.groups;
                    this.cover = response.data.cover;
                    this.title = response.data.title;
                    this.avg_result = response.data.result;
                    this.avg_rating = response.data.avg_rating;
                }
                }
                if(this.searchMode){
                this.table_user_count = response.data.user_count;
                this.table_users_started = response.data.users_started;
                this.totalSearchPageCount= Math.ceil(this.table_user_count / (this.limit/2));
                // this.groups =response.data.groups;
                }
                if(this.allStats.length != this.table_user_count)
                this.allStats[this.table_user_count-1]= {};
                if(this.allUsersIterator < this.table_user_count){
                this.allStats.splice(this.allUsersIterator, response.data.stats.length, ...response.data.stats);
                this.totalPageCount = Math.ceil(this.allStats.length / (this.limit/2));
                this.totalSearchPageCount= Math.ceil(this.allStats.length / (this.limit/2));

                this.allUsersIterator = this.allUsersIterator > this.table_user_count ? this.table_user_count : Math.min(this.table_user_count, this.allUsersIterator + response.data.stats.length);
                }
                this.firstStatsLoaded = true;
                if(this.searchMode) this.firstSearchStatsLoaded = true;
                let data = {
                    'stats': this.stats,
                    'cover': this.cover,
                    'title': this.title,
                    'user_count': this.user_count,
                    'users_started': this.users_started,
                    'avg_result': this.avg_result,
                    'avg_rating': this.avg_rating,
                }
                this.$emit('statsLoaded', data);
            }).catch((error) => {
                console.log(error)
            });
        },
        setOrderBy(element) {
            if(element == this.orderByElement){
                this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
            }else{
                this.orderByElement = element;
                this.orderByDirection = "asc";
            }
            if(['name', 'last_name'].includes(element)){
                if(this.orderByDirection == "desc"){
                this.searchUserStats.sort((a,b) => (a.user[element] > b.user[element]) ? -1 : ((b.user[element] > a.user[element]) ? 1 : 0)); 
                }else{
                this.searchUserStats.sort((a,b) => (a.user[element] > b.user[element]) ? 1 : ((b.user[element] > a.user[element]) ? -1 : 0)); 
                }
            }
            else if(element == 'created_at'){
                if(this.orderByDirection == "desc"){
                this.searchUserStats.sort((a,b) => Date.parse(b.training.training_end[0].end[element]) - Date.parse(a.training.training_end[0].end[element])) ; 
                }else{
                this.searchUserStats.sort((a,b) => Date.parse(a.training.training_end[0].end[element]) - Date.parse(b.training.training_end[0].end[element])); 
                }
            }
            else if(['time', 'rate', 'result'].includes(element)){
                if(this.orderByDirection == "desc"){
                this.searchUserStats.sort((a,b) => (a.training.training_end[0][element] > b.training.training_end[0][element]) ? -1 : ((b.training.training_end[0][element] > a.training.training_end[0][element]) ? 1 : 0)); 
                }else{
                this.searchUserStats.sort((a,b) => (a.training.training_end[0][element] > b.training.training_end[0][element]) ? 1 : ((b.training.training_end[0][element] > a.training.training_end[0][element]) ? -1 : 0)); 
                }
            }
            else if(element == 'comment'){
                if(this.orderByDirection == "desc"){
                this.searchUserStats.sort((a,b) => (a.training.training_end[0][element] != null && b.training.training_end[0][element] == null) ? -1 : 1 ); 
                }else{
                this.searchUserStats.sort((a,b) => (a.training.training_end[0][element] == null && b.training.training_end[0][element] != null) ? 1 : -1); 
                }
            }
      },
       searchFilter() {
          let data = this._.cloneDeep(this.allStats);
          if (this.searchInput != '') {
            data = data.filter(el => {
              return el.user && (el.user.name + ' ' + el.user.last_name).toLowerCase().includes(this.searchInput.toLowerCase())
            })
            this.searchingStats = true;
          }
          else {
            this.searchingStats = false;
          }
          // this.totalSearchPageCount = Math.ceil(data.length / (this.limit / 2));
          this.usersFilteredBySearch = this._.clone(data);
          // this.searchUserStats = data.slice((this.page - 1) * this.limit / 2, (this.page) * this.limit / 2);
        },
        getTestSolvingTime(stat, time_in_seconds){
            let hours = Math.floor(time_in_seconds / 3600);
            if(hours<10){
                hours = '0'+hours;
            }
          let minutes = Math.floor(time_in_seconds / 60 % 60);
            if(minutes<10){
                minutes = '0'+minutes;
            }
            let seconds =  Math.floor(time_in_seconds % 60);
            if(seconds<10){
                seconds = '0'+seconds;
            }
            return `${hours}:${minutes}:${seconds}`;
        },
        getAllUserStats(user){
          if(user.training.training_end && user.training.training_end.length > 0){
            return user.training.training_end;
          }
          else{
            return user.training.training_start;
          }
        },
        clickCallback (pageNum){
            if(!this.pagesViewed.includes(pageNum)){
            this.pagesViewed.push(pageNum);
            }
            if(!this.searchingStats){
            if(this.table_user_count > this.allUsersIterator){
                this.skip = (pageNum-1) * this.limit;
                this.loadTrainingInfo();
            }
            else{
                this.stats = this.allStats.slice((pageNum-1) * this.limit/2, (pageNum) * this.limit/2);
                this.searchStats = this.allStats.slice((pageNum - 1) * this.limit / 2, (pageNum) * this.limit / 2);
                this.searchUserStats = this.allStats.slice((pageNum - 1) * this.limit / 2, (pageNum) * this.limit / 2);
                this.filterByGroups();
            }
            }
            else{
                this.searchStats = this.allStats.slice((pageNum-1) * this.limit, (pageNum) * this.limit);
                this.filterByGroups();

            }
        },
        searchDebounce: _.debounce(function(){
            this.loadTrainingInfo();
        }, 500),
        filterByGroups(){
            // this.stats = this._.cloneDeep(this.searchStats);
          let data = this._.cloneDeep(this.allStats);
          let group_ids = this.groupFilter.map(elem=>elem.id);
          
          if (group_ids.length > 0) {
            data = data.filter(stat => {
              let user_groups = stat.user && stat.user.groups ? stat.user.groups.map(elem => elem.id) : [];
              let arr = group_ids.filter(el => user_groups.includes(el))
              return arr.length > 0;
            })
          }
          this.usersFilteredByGroup = this._.cloneDeep(data);
            // console.log(this.stats);
        },
        openGroup(id) {
            this.$store.commit("openSide", {"side": "training-group-stats-side", "sideInfo": {"id": id}});
        },
        openComments() {
            this.$store.commit("openSide", {"side": "training-comments-side", "sideInfo": {}});
          },
        openDownloads() {
            this.$store.commit("openSide", {"side": "training-downloads-side", "sideInfo": {}});
        },
        exportStats() {
            this.exporting = true;
            axios.post(
                `${this.$store.state.api_link}/training/stats/export`,
                {training: this.$store.state.currentTraining},
                this.$store.getters.axiosConfigDownload()
            ).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'stats.xlsx');
                document.body.appendChild(fileLink);

                fileLink.click();
                this.exporting = false;
            }).catch((error) => {
                (error)
            });
        },
        getStartDate(user){
          if(user.training.training_end){
            if (user.training.training_end[0].end) {
              let date = user.training.training_end[0].end.created_at.split(' ')[0]
              date = date.split('T')[0]
              return date
            }
            else {
              let date =user.training.training_end[0].start_date.split(' ')[0]
              date = date.split('T')[0]
              return date
            }
          }
          else if (user.training.training_start) {
            let date = user.training.training_start[0].created_at.split(' ')[0];
            date = date.split('T')[0]
            return date
          }
          else return '-'
      },
      getStatStartDate(stat) {
        if (stat.end) {
          let date = stat.end.created_at.split(' ')[0];
          date = date.split('T')[0]
          return date
        }
        else if(stat.start_date) {
          let date = stat.start_date.split(' ')[0];
          date = date.split('T')[0]
          return date
        }
        else {
          let date = stat.created_at.split(' ')[0];
          date = date.split('T')[0]
          return date
        }
      }
    }
}
</script>

<style lang="scss" scoped>

.group--list th img {
  cursor: pointer;
  user-select: none;
}

.group--list tbody tr td:nth-child(1) {
  cursor: pointer;
  user-select: none;
}
.grid-table{
    display: grid;
    grid-template-rows: auto;
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    .table-row{
        display: grid;
        grid-template-columns: minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) ;
        margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        background: #efefef;
        &.header{
            background: white;
            font-weight: 700;
            color: var(--primary-color);
        }
        &.parent{
            grid-template-columns:1fr;
            margin: 0.25rem 0;
            padding:0;
        }
        .table-cell{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            padding: 0.25rem 0;
            border-left: 1px solid var(--primary-color);
            &:first-child{
                border-left: none;
            }
            span{
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                margin:0 auto;
                justify-content: center;
                &.name-cell{
                    margin-left: 1.7rem;
                }
                img{
                    margin: 0 0.25rem;
                }
                &.header{
                    display: flex;
                   white-space: normal;
                }
            }

            svg{
                height: 100%;
                width: 1.7rem;
                
            }
        }
    }
}
.search-container{
    grid-area: search;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    .search-icon{
        display: flex;
        margin-right: 0.25rem;
        height: 100%;
        align-items: center;
        .remove{
            display: flex;
            position: relative;
            width: 1rem;
            height: 100%;
            cursor: pointer;
            transition: transform 0.3s;
            span{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 80%;
                height: 2px;
                background: var(--primary-color);
                border-radius: 1rem;
                transform: rotate(45deg);
            }
            span:last-child{
                transform: rotate(-45deg);
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    svg{
        path{
            fill: var(--primary-color);
        }
    }
    input{
        background: none;
        width: 100%;
        height: 100%;
        border: none;
        &:focus{
            outline: none;
        }
    }
}
.filter-container{
  display: flex;
  flex-direction: column;
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 2rem;
  .filter{
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px
  };
  .search-container{
    background: #efefef;
    display: flex;
    width: 100%;
    max-width:20rem;
    height: 100%;
    input{
      font-size: clamp(16px, 1.1vw, 20px);
    }
  }
  .group-filter{
    background: #efefef;
    border-radius: 1rem;
    margin: 0 1rem;
    height: 100%;

  }
  .label{
    margin-right: 1rem;
  }
  &.empty{
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
}
</style>