<template>
<div class="training--side--container">

  <div class="training-side-nav" v-if="training.content">
      <div
        v-for="(trainingTopic, trainingTopicIndex) in training.content"
        :key="trainingTopicIndex"
        :class="[
          'training--nav--block',
          trainingTopicIndex == scrolledTopic ? 'active' : '',
        ]"
        :style="{
            height: `${(100 / training.content.length)*3}%`,
            'background-color': `hsl(${41 - (trainingTopicIndex- 1) * 3}, 100%, 76%)`,
           'z-index': (5 + trainingTopicIndex),
        }"
        @click="$emit('gotoTopic',trainingTopicIndex)"
      >
        <span class="training--nav--title" v-if="!isMobileWindow()">
          {{ trainingTopic.title }}
        </span>
        <span
          :class="[
            'training--nav--indicator',
            trainingTopicIndex == scrolledTopic ? 'active' : '',
          ]"
        ></span>
      </div>
  </div>
</div>
</template>

<script>
export default {
    props:{
        training:{
            type: Object,
            default: () => {}
        },
        scrolledTopic:{
            type: Number,
            default: 0
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
.training--side--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    right: 0;
    height: 100%;
    top: 0%;
    z-index: 5;
    transition: .3s right;
}
.training-side-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    right: -25px;
    position: fixed;
    height: 87vh;
    max-height: 87vh;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    z-index: 5;
    transition: .3s right;

  &:hover {
    right: -15px;

    .training--nav--block {
      .training--nav--title {
        pointer-events: auto;
        opacity: .3;
        right: 30px;
      }
      
      &.active {
        .training--nav--title {
            opacity:1;
            transform: scale(1);
            right: 25px;
            padding-right: 25px;
        }
      }
    }
  
  }

  .training--nav--block {
   width: 50px;
    max-height: 12.5%;
    min-height: 70px;
    height: 100%;
    border-radius: 25px 0 0 25px;
    margin-top: -100%;
    cursor: pointer;
    transition: .3s margin-left;
    position: relative;

    .training--nav--title {
      position: absolute;
      right: 0px;
      padding-right: 0px;
      height: 40px;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;
      width: 320px;
      // text-align: right;
      top: 25%;
      display: inline-block;
      align-items: center;
      justify-content: flex-end;
      transform: scale(.7);
      transition: .3s opacity, .3s transform, .3s right, .3s padding;
      text-align: right;
      user-select: none;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training--nav--indicator{
      display: none;
    }

    &.active {
      margin-left: -15px;

      .training--nav--title {
        opacity: .3;
        right: 25px;
      }
      .training--nav--indicator{
        width: 100%;
        height: 60%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
    }

    &.active:hover, &:hover {
      margin-left: -15px;

      .training--nav--title {
        opacity: 1;
        transform: scale(1);
        right: 50px;
      }
      .training--nav--indicator{
        width: 100%;
        height: 60%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
    }

    @media (max-height: 800px) {
      margin-top: -50px;

      .training--nav--title {
        line-height: 0.9;
      }
    }
  }

  @for $i from 0 through 14 {
    .training--nav--block:nth-child(#{$i}) {
      background-color: hsl((41 - ($i - 1) * 3), 100%, 76%);
      z-index: (5 + $i);
    }
  }
  @media (max-width: 768px) {
    display: none;
  }

}
@media(max-width:1280px){
  .training--nav--block{
    .training--nav--title{
      font-size: 18px;
      height: 20px;
    }
  }
}

</style>