<template>
  <div>
        <div class="grid-table" :class="{'with-rating': tests[0] && tests[0].rating_avg!=undefined}">
          <div class="table-row header">
            <div class="table-cell">
              <span class="name-cell header">
                {{toLocal('training_stats.test_name')}}
                <img
                  v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setTestOrderBy('name')"
                >
                <img
                  v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setTestOrderBy('name')"
                >
                <img
                  v-if="orderByElement != 'name'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setTestOrderBy('name')"
                >
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{toLocal('training_stats.question_count')}}
                <img
                  v-if="orderByElement == 'question_count' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setTestOrderBy('question_count')"
                >
                <img
                  v-if="orderByElement == 'question_count' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setTestOrderBy('question_count')"
                >
                <img
                  v-if="orderByElement != 'question_count'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setTestOrderBy('question_count')"
                >
              </span>
            </div>
    
            <div class="table-cell">
              <span class="header">
               {{toLocal('training_stats.average_attempt_time')}}
                <img
                  v-if="orderByElement == 'time' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setTestOrderBy('time')"
                >
                <img
                  v-if="orderByElement == 'time' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setTestOrderBy('time')"
                >
                <img
                  v-if="orderByElement != 'time'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setTestOrderBy('time')"
                >
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{toLocal('training_stats.average_result')}}
                <img
                  v-if="orderByElement == 'result' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setTestOrderBy('result')"
                >
                <img
                  v-if="orderByElement == 'result' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setTestOrderBy('result')"
                >
                <img
                  v-if="orderByElement != 'result'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setTestOrderBy('result')"
                >
              </span>
            </div>
            <div class="table-cell" v-if="tests[0] && tests[0].rating_avg!=undefined">
              <span class="header">
                {{toLocal('training_stats.average_rating')}}
                <img
                  v-if="orderByElement == 'rating_avg' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setTestOrderBy('rating_avg')"
                >
                <img
                  v-if="orderByElement == 'rating_avg' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setTestOrderBy('rating_avg')"
                >
                <img
                  v-if="orderByElement != 'rating_avg'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setTestOrderBy('rating_avg')"
                >
              </span>
            </div>
  
          </div>
          <div class="table-row parent" v-for="(test, test_id) in tests" :key="test_id" v-show="test">
            <div v-if=" test" class="table-row">
              <div class="table-cell test-title">
                <span class="version">
                   {{test.version_prefix}} 
                </span>
                <span  
                  @mouseover="scrollNameHorizontal($event,true)" 
                  @mouseleave="scrollNameHorizontal($event,false)"
                >
                 {{test.name}}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event,true)" 
                  @mouseleave="scrollNameHorizontal($event,false)"
                >
                  {{test.question_count}}
                </span>
              </div>
              <div class="table-cell">
                <span
              
                >
                  {{getTestSolvingTime(test.time)}}
                </span>
              </div>
              <div class="table-cell"><span
        
              >
              <!-- {{user.training.training_end[0].end.created_at}} -->
                {{test.result  == null ? '' : Math.round(test.result * 100,2) +' %'}}
              </span>
              </div>
              <div class="table-cell" v-if="test.rating_avg!=undefined"><span
        
              >
              <!-- {{user.training.training_end[0].end.created_at}} -->
                {{test.rating_avg}}
              </span>
              </div>
              <div class="table-cell actions">
                  <svg @click="$emit('setTestData', {'testOpenedSide': test.id, 'test': test, 'tests': tests})" xmlns="http://www.w3.org/2000/svg" width="27.084" height="35.342" viewBox="0 0 27.084 35.342">
                    <g id="Group_11327" data-name="Group 11327" transform="translate(-1497.768 -340.25)">
                      <g id="Group_11282" data-name="Group 11282" transform="translate(1498.518 341)">
                        <path id="Path_280_1_" d="M36.348,11.689A5.669,5.669,0,1,1,30.679,6.02h0a5.661,5.661,0,0,1,5.669,5.635C36.348,11.668,36.348,11.682,36.348,11.689Z" transform="translate(-20.603 -6.02)" fill="none" stroke="#464646" stroke-miterlimit="10" stroke-width="1.5"/>
                        <path id="Path_529" data-name="Path 529" d="M33.34,42.185H23.771a5.291,5.291,0,0,1-5.291-5.291V33.176A10.076,10.076,0,0,1,28.556,23.1h0A10.076,10.076,0,0,1,38.631,33.176v3.718A5.291,5.291,0,0,1,33.34,42.185Z" transform="translate(-18.48 -11.574)" fill="none" stroke="#464646" stroke-miterlimit="10" stroke-width="1.5"/>
                      </g>
                      <g id="Group_11288" data-name="Group 11288" transform="translate(-0.592 -1)">
                        <circle id="Ellipse_614" data-name="Ellipse 614" cx="9.796" cy="9.796" r="9.796" transform="translate(1505.853 357)" fill="#464646"/>
                        <g id="Group_11285" data-name="Group 11285" transform="translate(1513.989 360.608)">
                          <path id="Path_8346" data-name="Path 8346" d="M-10067.338,5753.5a1.152,1.152,0,0,1-1.152-1.152v-6.255a1.152,1.152,0,0,1,1.152-1.152,1.152,1.152,0,0,1,1.152,1.152v6.255A1.152,1.152,0,0,1-10067.338,5753.5Z" transform="translate(10068.888 -5741.018)" fill="#fff"/>
                          <circle id="Ellipse_612" data-name="Ellipse 612" cx="1.66" cy="1.66" r="1.66" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <svg @click="downloadStats(test)" v-if="!test.downloading" class="download-icon" width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 1V12M11 12L7 8.82692M11 12L15 8.82692" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1 8V15C1 16.1046 1.89543 17 3 17H19C20.1046 17 21 16.1046 21 15V8" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <font-awesome-icon class="download-icon" v-else icon="spinner" />
              </div>
          
            </div>
            </div>
                
        </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {

    data(){
        return{
            tests: [],
            orderByElement: ''
        }
    },
    props:{
        stats:{
            type:Array,
            default:function(){
                return []
            }
        },
        cover:{
            type:String,
            default:function(){
                return ''
            }
        },

    },
    computed:{
        allTestsResult(){
            let mapped = this.tests.map(el=>el.result);
            let reduced =mapped.reduce((a, b)=> parseFloat(a)+parseFloat(b));
            return Math.round((reduced / this.tests.length)*100, 2);
        },
        allSolveTime(){
            let mapped = this.tests.map(el=>el.time);
            if(mapped.length > 0){
                let reduced =mapped.reduce((a, b)=> parseInt(a)+parseInt(b));
                return reduced / this.tests.length;
            }
            else{
                return 0;
            }
        },
    },
    mounted(){
        this.loadTestInfo();
    },
    methods:{
        getTestSolvingTime(time_in_seconds){
            let hours = parseInt(time_in_seconds/3600);
            if(hours<10){
                hours = '0'+hours;
            }
            let minutes = parseInt(time_in_seconds/60%60);
            if(minutes<10){
                minutes = '0'+minutes;
            }
            let seconds = parseInt(time_in_seconds%60);
            if(seconds<10){
                seconds = '0'+seconds;
            }
            return `${hours}:${minutes}:${seconds}`;
        },
      downloadStats(test) {
          this.$set(test, 'downloading', true);
           axios
            .post(
              `${this.$store.state.api_link}/test-question-groups/stats/export`,
              {
                training: this.$store.state.currentTraining,
                test: test.id
              },
              this.$store.getters.axiosConfigDownload()
            )
             .then((response) => {
              let file_name = 'test_stats';
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", file_name + ".xlsx");
              document.body.appendChild(fileLink);

               fileLink.click();
              this.$set(test, 'downloading', false);
              
              // this.exporting = false;
            })
            .catch((error) => {
              this.$set(test, 'downloading', false);
              console.log(error);
            });
        },
        loadTestInfo() {
            axios.get(
                `${this.$store.state.api_link}/training/test/stats/${this.$store.state.currentTrainingRented ? 1: 0}/${this.$store.state.currentTraining}`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                let tests = response.data.tests;
                tests = tests.filter(el=>el.result != undefined)
                this.tests= tests;
                this.$emit('setTests', this.tests);
                this.$nextTick(()=>{
                  this.$store.state.loading.loadingDone();

                })
            }).catch((error) => {
                console.log(error)
            });
        },
         setTestOrderBy(element) {
            if(element == this.orderByElement){
                this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
            }else{
                this.orderByElement = element;
                this.orderByDirection = "asc";
            }
            if(this.orderByDirection == "desc"){
                this.tests.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
            }else{
                this.tests.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
            }
            
        },
    }
}
</script>

<style lang="scss" scoped>


.summary--button {
  border-radius: 14px;
  border: 2px solid #464646;
  cursor: pointer;
  margin-right: 25px;
  padding: 4px 10px;
  padding-right: 16px;
  margin-top: 5px;
  display: inline-block;
  user-select: none;
  max-width: 10rem;
  img {
    max-width: 27px;
    max-height: 25px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.group--list th img {
  cursor: pointer;
  user-select: none;
}

.group--list tbody tr td:nth-child(1) {
  cursor: pointer;
  user-select: none;
}
.grid-table{
    display: grid;
    grid-template-rows: auto;
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    .table-row{
        display: grid;
        grid-template-columns: minmax(100px, 2.67fr) minmax(100px, 1fr) minmax(100px, 1.67fr) minmax(100px, 1fr) minmax(30px, 0.75fr);
        // margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        background: #efefef;
        &.header{
            background: white;
            font-weight: 700;
            color: var(--primary-color);
        }
        &.parent{
            grid-template-columns:1fr;
            margin: 0.25rem 0;
            padding:0;
        }
        .table-cell{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            padding: 0.25rem 0;
            border-left: 1px solid var(--primary-color);
            &:first-child{
                border-left: none;
            }
            span{
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                margin:0 auto;
                justify-content: center;
                &.name-cell{
                  margin-left: 1.7rem;
                  margin: auto;
                  max-width: 205px;
                }
                img{
                    margin: 0 0.25rem;
                }
                &.header{
                    display: flex;
                   white-space: normal;
                   font-size: 18px;
                }
            }

            svg{
                height: 100%;
                // width: 1.7rem;
                
            }
            &.test-title{
              span{
                width: auto;
                &.version{
                  max-width: 75px;
                  width: 75px;
                  margin: 0;
                }
                &:nth-child(2){
                  margin-left: 10px;
                }
              }
            }
        }
    }
    &.with-rating{
        .table-row{
          grid-template-columns: 
            minmax(100px, 2.67fr) 
            minmax(100px, 1fr) 
            minmax(100px, 1.67fr) 
            minmax(100px, 1fr) 
            minmax(100px, 1fr) 
            minmax(30px, 0.75fr);
          &.parent{
            grid-template-columns:1fr;
          }
        }
    }
}
.actions{
    display: flex;
    gap: 15px;
    svg{
        cursor: pointer;
    }
    .download-icon{
      path{
        stroke: #464646;
      }
    }
}
.search-container{
    grid-area: search;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    .search-icon{
        display: flex;
        margin-right: 0.25rem;
        height: 100%;
        align-items: center;
        .remove{
            display: flex;
            position: relative;
            width: 1rem;
            height: 100%;
            cursor: pointer;
            transition: transform 0.3s;
            span{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 80%;
                height: 2px;
                background: var(--primary-color);
                border-radius: 1rem;
                transform: rotate(45deg);
            }
            span:last-child{
                transform: rotate(-45deg);
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    svg{
        path{
            fill: var(--primary-color);
        }
    }
    input{
        background: none;
        width: 100%;
        height: 100%;
        border: none;
        &:focus{
            outline: none;
        }
    }
}
.filter-container{
  display: flex;
  flex-direction: column;
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 2rem;
  margin-top: 1rem;
  .filter{
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px
  };
  .search-container{
    background: #efefef;
    display: flex;
    width: 100%;
    max-width:20rem;
    height: 100%;
    input{
      font-size: clamp(16px, 1.1vw, 20px);
    }
  }
  .group-filter{
    background: #efefef;
    border-radius: 1rem;
    margin: 0 1rem;
    height: 100%;

  }
  .label{
    margin-right: 1rem;
  }
  &.empty{
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
}
</style>