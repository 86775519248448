<template>
  <div class="form-input-container">
      <div v-for="(item, item_index) in reportDataArray" :key="item_index">
        <ReportDataInput 
            inputType="level_1" 
            :style="{'z-index': reportDataArray.length-item_index +1}" 
            :index="item_index" 
            :parent="item" 
            :dataList="item.filters" 
            :selectLabel="item.selectLabel"
            @removeFilter="removeFilter($event, reportDataArray, item_index)"
        /> 
      </div>
      <ReportDataInput 
        class="primary-input"
        inputType="main" 
        :parent="dataTypeList" 
        :dataList="dataTypeList"
        :selectLabel="toLocal('generic.select_filter')" 
        @selected="reportDataArray.push($event)"
      /> 
  </div>
</template>

<script>
import ReportDataInput from "./ReportDataInput.vue"
import _ from 'lodash';
import {additional_training_filters} from './data/training'
import {additional_test_filters} from './data/test'
import {additional_certificate_filters} from './data/certificate'
import store from '@/store'
let typeLabelList = [store.getters.__('report.users'), 'grupes', 'mokymus', 'testus', 'sertifikatus'];

let tempTypeList = [
    {
        id: 1, name: 'all', label: ''
    },
    {
        id: 2, name: 'specific', label: '', 
    },
    {
        id: 3, name: 'all_except', label: '', 
    },
]
let labeledTypeList = [];
for(let i = 0; i < typeLabelList.length; i++){
    if(i != 1){
        tempTypeList[0].label = 'Visus '+typeLabelList[i];
        tempTypeList[1].label = 'Konkrečius '+typeLabelList[i];
        tempTypeList[2].label = 'Visus '+typeLabelList[i]+', išskyrus';
    }
    else{
        tempTypeList[0].label = 'Visas '+typeLabelList[i];
        tempTypeList[1].label = 'Konkrečias '+typeLabelList[i];
        tempTypeList[2].label = 'Visas '+typeLabelList[i]+', išskyrus';
    }
    labeledTypeList.push(_.cloneDeep(tempTypeList));
}

let dataTypeList= [
                {
                   id: 1, 
                   name: 'users', 
                   label: 'Vartotojai', 
                   filters: labeledTypeList[0], 
                   selectLabel: 'Pasirinkite vartotojus',
                   selectedLabel: 'Pasirinkote šiuos vartotojus',
                   selectedIds: [], 
                   additionalFilters: [],
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'user/users-min'
                },
                {
                   id: 2, 
                   name: 'groups', 
                   label: 'Grupės',  
                   filters: labeledTypeList[1], 
                   selectLabel: 'Pasirinkite grupes', 
                   selectedLabel: 'Pasirinkote šias grupes',
                   selectedIds: [], 
                   additionalFilters: [], 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'group/groups-min'
                },
                {
                   id: 3, 
                   name: 'trainings', 
                   label: 'Mokymai',  
                   filters: labeledTypeList[2], 
                   selectLabel: 'Pasirinkite mokymus', 
                   selectedLabel: 'Pasirinkote šiuos mokymus',
                   selectedIds: [], 
                   additionalFilters: additional_training_filters, 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'training/titles'
                },
                {
                   id: 4, 
                   name: 'tests', 
                   label: 'Testai',  
                   filters: labeledTypeList[3], 
                   selectLabel: 'Pasirinkite testus', 
                   selectedLabel: 'Pasirinkote šiuos testus',
                   selectedIds: [], 
                   additionalFilters: additional_test_filters, 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'test-question-groups/tests'
                },
                {
                   id: 5, 
                   name: 'certificates', 
                   label: 'Sertifikatai', 
                   filters: labeledTypeList[4], 
                   selectLabel: 'Pasirinkite sertifikatus', 
                   selectedLabel: 'Pasirinkote šiuos sertifikatus',
                   selectedIds: [], 
                   additionalFilters: additional_certificate_filters, 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'certificates/all'
                },
            ];
export default {
    props:{
        reportDataArray: {
            type: Array,
            default: function(){
                return [];
            }
        }
    },
    data(){
        return{
            dataTypeList,
           
            
        }
    },
    mounted(){
        typeLabelList = [this.toLocal('report.users'), 
                        this.toLocal('report.groups'), 
                        this.toLocal('report.trainings'), 
                        this.toLocal('report.tests'), 
                        this.toLocal('report.certificates')];
        labeledTypeList = [];
        for(let i = 0; i < typeLabelList.length; i++){
            if(i != 1){
                tempTypeList[0].label = this.toLocal('report.all_something') +typeLabelList[i];
                tempTypeList[1].label = this.toLocal('report.specific') +typeLabelList[i];
                tempTypeList[2].label = this.toLocal('report.all_something')+typeLabelList[i]+this.toLocal('report.except');
            }
            else{
                tempTypeList[0].label = this.toLocal('report.everything_fem')+typeLabelList[i];
                tempTypeList[1].label = this.toLocal('report.specific_fem')+typeLabelList[i];
                tempTypeList[2].label = this.toLocal('report.everything_fem')+typeLabelList[i]+this.toLocal('report.except');
            }
            labeledTypeList.push(_.cloneDeep(tempTypeList));
        }
        this.localizeFilters(additional_training_filters);
        this.localizeFilters(additional_certificate_filters);
        this.localizeFilters(additional_test_filters);
        this.dataTypeList = [
                {
                   id: 1, 
                   name: 'users', 
                   label: this.toLocal('generic.users'), 
                   filters: labeledTypeList[0], 
                   selectLabel: this.toLocal('report.select_users'),
                   selectedLabel: this.toLocal('report.selected_users'),
                   selectedIds: [], 
                   additionalFilters: [],
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'user/users-min'
                },
                {
                   id: 2, 
                   name: 'groups', 
                   label: this.toLocal('menu.groups'),  
                   filters: labeledTypeList[1], 
                   selectLabel: this.toLocal('report.select_groups'), 
                   selectedLabel: this.toLocal('report.selected_groups'),
                   selectedIds: [], 
                   additionalFilters: [], 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'group/groups-min'
                },
                {
                   id: 3, 
                   name: 'trainings', 
                   label: this.toLocal('menu.trainings'),  
                   filters: labeledTypeList[2], 
                   selectLabel: this.toLocal('report.select_training'), 
                   selectedLabel: this.toLocal('report.selected_training'),
                   selectedIds: [], 
                   additionalFilters: additional_training_filters, 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'training/titles'
                },
                {
                   id: 4, 
                   name: 'tests', 
                   label: this.toLocal('generic.tests'),  
                   filters: labeledTypeList[3], 
                   selectLabel: this.toLocal('report.select_tests'), 
                   selectedLabel: this.toLocal('report.selected_tests'),
                   selectedIds: [], 
                   additionalFilters: additional_test_filters, 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'test-question-groups/tests'
                },
                {
                   id: 5, 
                   name: 'certificates', 
                   label: this.toLocal('menu.certificates'), 
                   filters: labeledTypeList[4], 
                   selectLabel: this.toLocal('report.select_certificates'), 
                   selectedLabel: this.toLocal('report.selected_certificates'),
                   selectedIds: [], 
                   additionalFilters: additional_certificate_filters, 
                   selectedAdditionalFilters: [],
                   secondarySelect: [],
                   searchApi: 'certificates/all'
                },
            ];
    },
    components: { ReportDataInput },
    methods:{
         removeFilter(data, parent, index){
            parent.splice(index, 1);
        },

        localizeFilters(filterList){
            let copy = filterList
            copy.forEach((single)=>{
                if(single.label != undefined){
                    single.label = this.toLocal(single.label);
            }
            if(single.selectLabel != undefined){
                single.selectLabel = this.toLocal(single.selectLabel); 
            }
            if(single.selectedLabel != undefined){
                single.selectedLabel = this.toLocal(single.selectedLabel);
            }
            if(single.filters.length >0){
                single.filters.forEach((extra)=>{
                    extra.label = this.toLocal(extra.label);
                    if(extra.multiFilter != undefined && extra.multiFilter.length > 0){
                        extra.multiFilter.forEach((multi)=>{
                            multi.label = this.toLocal(multi.label);
                        })
                    }
                    if(extra.short_label != undefined){
                        extra.short_label = this.toLocal(extra.short_label)
                    }
                })
            }
            })
            // if(filter.filters.length >0){
            //     console.log(filter.filters);
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-input-container{
    display: flex;
    flex-direction: column;
    padding: 0 7rem;
}

</style>