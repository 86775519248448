<template>
<transition name="fade">
  <div class="modal-container" v-if="$store.state.system_settings.cookiesModal ? cookieNotAccepted : false" v-scroll-lock="cookieNotAccepted">
      <div class="modal">
          <p class="top-text">{{toLocal('cookies.desc')}}</p>
          <div class="selectables-container">
              <div 
                class="checkmark-container" 
                v-for="(selectable, s_id) in selectables"
                :key="s_id"
                @click="selectable.checkable ? selectable.selected = !selectable.selected : ''"
                >
                  <div class="checkmark" :class="{selected: selectable.selected}" >
                      <div class="checkmark-icon">
                            <svg v-if="selectable.selected" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                             <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                            </svg>
                      </div>
                  </div>
                  <span> {{toLocal(selectable.name)}}</span>
              </div>
             
          </div>
          <p>{{toLocal('cookies.more_info')}} <strong @click="$store.state.privacy_policy_modal = true">{{toLocal('cookies.privacy_policy')}}</strong></p>
          <div class="button-container">
              <div class="btn" @click="acceptCookies('selected')">{{toLocal('cookies.confirm_selected')}}</div>
              <div class="btn secondary"  @click="acceptCookies('all')">{{toLocal('cookies.confirm_all')}}</div>
          </div>
          <div class="cookie-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="191.175" height="191.677" viewBox="0 0 191.175 191.677">
                <defs>
                    <clipPath id="clip-path">
                    <rect id="Rectangle_2543" data-name="Rectangle 2543" width="191.175" height="191.677" transform="translate(0 -6.644)" fill="#28345c"/>
                    </clipPath>
                </defs>
                <g id="Group_8311" data-name="Group 8311" transform="translate(0 6.644)">
                    <g id="Group_8310" data-name="Group 8310" clip-path="url(#clip-path)">
                    <path id="Path_6255" data-name="Path 6255" d="M167.947,101.156c-12.621-3.382-20.088-10.4-23.142-24.25-5.887,1.464-13.055,1.436-22.121-.992-16.465-4.4-25.223-14.24-27.564-35a31.1,31.1,0,0,1,.4-9.191C82.45,27.577,75.407,18.915,73.461,1.612c-.048-.443-.077-.877-.106-1.32C26.7,9.618-3.856,62.6.393,100.173c5.376,47.643,25.445,70.216,63.241,80.332,52.72,14.1,77.5-7.178,97.27-29.424,11.513-12.949,18.537-29.973,20.348-48.211a45.508,45.508,0,0,1-13.3-1.715m-125.692-.79a10.209,10.209,0,0,1-10.309-3c-2.11-2.534-4.027-5.588-1.619-11.118,1.725-3.969,4.528-5.8,9.827-5.6,4.23.154,9.606,4.47,9.731,9.818.116,4.827-2.457,8.555-7.631,9.9M60.7,39.032a5.04,5.04,0,0,1-5.087-1.474,4.623,4.623,0,0,1-.8-5.492c.857-1.956,2.235-2.861,4.856-2.765a5.385,5.385,0,0,1,4.8,4.856A4.637,4.637,0,0,1,60.7,39.032m10.733,96.682a5.019,5.019,0,0,1-5.087-1.484,4.616,4.616,0,0,1-.8-5.492c.857-1.956,2.235-2.852,4.846-2.755A5.384,5.384,0,0,1,75.2,130.83a4.654,4.654,0,0,1-3.767,4.885M98.039,76.8c-1.8,7.168-6,11.022-14.982,12.188-7.178.915-17.438-4.846-19.144-13.826-1.542-8.083,1.744-15.1,10.078-18.8,6.349-2.813,13.585-1.821,18.2,2.158,4.258,3.671,8.353,8.286,5.848,18.277m43.2,56.612c-4.085,3.806-13.113,4.8-18.228-.058-4.6-4.384-5.694-10.261-2.129-16.321a13.417,13.417,0,0,1,12.39-6.831c4.316.385,8.96,1.436,11.889,8.835,2.091,5.3,1.185,9.615-3.921,14.375" transform="translate(0 -0.011)" fill="#28345c"/>
                    <path id="Path_6256" data-name="Path 6256" d="M141.579,52.368a6.916,6.916,0,0,0-2.938-8.689c-3.322-2.1-8.149-.576-9.968,1.948-2.277,3.158-2.3,5.613-.579,8.284,2.4,3.726,5.053,3.8,7.456,3.539a7.486,7.486,0,0,0,6.028-5.081" transform="translate(-4.637 -1.56)" fill="#28345c"/>
                    <path id="Path_6257" data-name="Path 6257" d="M197.746,53.629c.066-4.719-2.519-7.783-6.655-8.809-4.593-1.14-9.526,2.437-10.645,6.01-1.4,4.472-.5,7.285,2.485,9.682,4.157,3.343,7.213,2.417,9.859,1.209a9.007,9.007,0,0,0,4.956-8.092" transform="translate(-20.623 5.02)" fill="#28345c"/>
                    <path id="Path_6258" data-name="Path 6258" d="M131.572,24.653c6.51-.639,10.328-4.674,11.1-10.529.859-6.5-4.83-12.742-9.924-13.729C126.374-.841,122.64.839,119.8,5.314c-3.961,6.243-2.213,10.309-.141,13.766a12.484,12.484,0,0,0,11.912,5.573" transform="translate(-4.292)" fill="#28345c"/>
                    </g>
                </g>
                </svg>
          </div>
      </div>

  </div>
</transition>
</template>

<script>
import { bootstrap } from 'vue-gtag'
export default {
    name: 'CookieModal',
    data(){
        return{
            selectables: [
                {
                    name: this.toLocal('cookies.required'),
                    cookie_label: 'cookies_policy_accept',
                    selected: true,
                    checkable: false,
                },
                {
                    name: this.toLocal('cookies.statistics'),
                    cookie_label: 'cookies_stats_accept',
                    selected: false,
                    checkable: true,
                }
            ],
            cookieNotAccepted: false,
        }
    },
    mounted(){
        if(this.$cookies.get("cookies_stats_accept")){
            bootstrap().then((gtag)=>{

            })
            // this.$ga.enable();
        }
        setTimeout(() => {
            if(!this.checkIfIOS())
                this.cookieNotAccepted = this.$cookies.get("cookies_policy_accept") ? false : true;     
        }, 5000);
    },
    methods:{
        acceptCookies(type){
            if(type=='selected'){
                this.selectables.forEach(item => {
                    if(item.selected){
                        this.$cookies.set(item.cookie_label, true, '2y');
                        if(item.cookie_label == 'cookies_stats_accept'){
                            bootstrap().then((gtag)=>{

                            })
                        }
                    }
                    else{
                       this.$cookies.set(item.cookie_label, false, '2y');
                    }
                });
            }
            else{
                 this.selectables.forEach(item => {
                    this.$cookies.set(item.cookie_label, true, '2y');
                });
            }
            this.cookieNotAccepted = false;
        },
        checkIfIOS(){
            let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            return isIOS;
        }

    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/_transitions.scss';
@import '@/assets/scss/components/layout/_cookieModalScoped.scss';
</style>