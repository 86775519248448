<template>
  <div class="modal-window" :class="{rent:item.rented}">

    <GlobalModal
      v-if="savingUsersModalOpen"
      :modalInfo="{
        type: 'confirmation',
        text: 'modal.are_you_sure',
        undertext: 'modal.cant_revoke',
        confirmBtnText: 'save_modal.save',
      }"
      @onConfirm="completeSave"
      @onClose="savingUsersModalOpen = false"
    />
    <GlobalModal
      v-if="revokeLicenceFromUser!=null"
      :modalInfo="{
        type: 'confirmation',
        text: 'training.licence_revoke_text',
        undertext: 'training.licence_revoke_undertext',
        confirmBtnText: 'training.confirm_licence_revoke_btn',
      }"
      @onConfirm="fullyRevokeLicence"
      @onClose="revokeLicenceFromUser = null"
    />
    <GlobalModal
      v-if="isDeletingInvitation"
      :modalInfo="{
        type: 'multiChoice',
        text: 'delete_modal.are_you_sure',
        undertext: 'delete_modal.no_rollback',
        confirmBtnText: 'save_modal.save',
        multiChoiceYes: 'training_edit.answer_yes',
        multiChoiceNo: 'training_edit.answer_no',
      }"
      @onChoiceOne="removeInvitation"
      @onChoiceTwo="isDeletingInvitation = false"
      @onClose="isDeletingInvitation = false"
    />
    <div class="inner-modal" >
      <div class="overlay" >

      </div>
      <span @click="closeModal" class="closing-icon"><CloseCrossSvg /></span>
      <div class="item-header underline" :class="{minimal: userInvitations.show || preview.show}">
        <div class="flex-row">
          <div class="item-img" v-if="!userInvitations.show && !preview.show">
            <div
              class="item-cover"
              :style="
                item.data.cover != ''
                  ? `background-image:url('${getFileUrl(item.data.cover)}')`
                  : `background-image:url('/img/pia21974.0.17df4049.jpg')`
              "
              v-if="type == 'training'"
              alt="item-photo"
            />
          </div>
          <div class="assign-title" v-if="userInvitations.show || preview.show">
            {{preview.show ? toLocal('generic.list_preview') : invitation_type_labels[userInvitations.type]}}
            <svg v-if="preview.show" width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 6.4C3.64222 9.49259 6.68351 11.3778 10 11.3778C13.3159 11.3778 16.3504 9.49319 18.4621 6.39938C16.3574 3.30716 13.3163 1.42222 10 1.42222C6.68351 1.42222 3.64222 3.30741 1.53746 6.4ZM0.112049 6.0135C2.41702 2.38522 5.97035 0 10 0C14.0296 0 17.583 2.38522 19.888 6.0135C20.0376 6.24901 20.0373 6.55219 19.8873 6.78746C17.5753 10.4142 14.0302 12.8 10 12.8C5.97035 12.8 2.41702 10.4148 0.112049 6.7865C-0.0373498 6.55133 -0.0373498 6.24867 0.112049 6.0135Z" fill="#4D4D4D"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 4.26667C8.84509 4.26667 7.90801 5.22179 7.90801 6.4C7.90801 7.57821 8.84509 8.53333 10.001 8.53333C11.157 8.53333 12.0941 7.57821 12.0941 6.4C12.0941 5.22179 11.157 4.26667 10.001 4.26667ZM6.51267 6.4C6.51267 4.43632 8.07446 2.84444 10.001 2.84444C11.9276 2.84444 13.4894 4.43632 13.4894 6.4C13.4894 8.36368 11.9276 9.95555 10.001 9.95555C8.07446 9.95555 6.51267 8.36368 6.51267 6.4Z" fill="#4D4D4D"/>
            </svg>
          </div>
          <span class="item-title">{{ preview.show ? preview.label : item.data.title }}</span>
          <div class="flex-column" v-if="item.rented && item.data.price != 0 && item.data.rent_price != 0">
            <span
              class=" small-font flex-column left-aligned"
              v-if="item.rented != undefined && item.rented == 1 "
              >
              
              <span class="label">{{ toLocal("modal.owned_licenses") }} </span>
              <span class="big-bold-text"
                ><span
                  :class="{
                    red: item.license_count == 0 || usableLicenses <= 0,
                  }"
                  >{{ usableLicenses }}</span
                >
                / {{ item.license_count }} {{toLocal('general.unit_short')}}</span
              ></span
            >
            <span v-if="item.rented != undefined && item.rented == 1" class="save-btn" @click="buyMore(item.id)"
              >{{ toLocal("trainings.buy_more") }}
              <ArrowRightSvg class="arrow-right"
            /></span>
          </div>
        </div>
      </div>
      <!-- <ConditionFilter 
      :groupUserFilter="conditionalFilter" 
      :includable_columns="includable_columns" 
      :user_data="additional_user_data" 
      @filterEvent="saveFilter"
      /> -->
      <SelectUserInvitationWindow
        v-if="loadedInvitations && invitations.length == 0 && !userInvitations.show && !preview.show"
        :invitation_types="invitation_types"
        @selectedType="selectInvitationType"
      />
      <TrainingInvitationsWindow 
        v-if="!userInvitations.show && !preview.show && invitations.length > 0 " 
        :invitations="invitations" 
        :invitation_types="invitation_types"
        @inviteUsers="inviteUsers"
        @viewItem="viewInvitation"
        @editItem="editInvitation"
        @toggleItem="toggleInvitation"
        @removeItem="bufferRemoveInvitation"
       />
       <InvitedUserListModal v-if="preview.show && !userInvitations.show && (preview.item ? preview.item.type !== 'groups' : true)" :users="preview.users" :item="preview.item"/>

       <InvitedGroupsListModal v-if=" preview.show && (preview.item && preview.item.type === 'groups')"
        class="user-training-list"
        :groupHeader="groupHeader"
        :loadingGroups="loadingGroups"
        :userHeader="userHeader"
        :cloneGroups="cloneGroups"
        :cloneUsers="cloneUsers"
        :usersData="users"
        :groupsData="groups"
        :item="preview.item"
        :invitationType="userInvitations.type"
        :style="{'margin-top': sendInvitationsModalActive ? '0px' : -groupListMarginTop+'px'}"
       
       />
      <TrainingConditionWindow 
        v-if="userInvitations.show && (!preview.show || preview.temp_preview) && userInvitations.type == 'condition'"
        ref="conditionWindow"
        :additional_user_data="additional_user_data"
        :includable_columns="includable_columns"
        :preview="preview"
        :user_count="conditionFilterUserCount"
        :selectedFilter="userInvitations.selectedItems ? userInvitations.selectedItems : []"
        @previewFilter="advancedFilter"
        @filterUsers="advancedFilter($event, null, false)"
      />
      <UserTrainingList
        v-if="['groups', 'single'].includes(userInvitations.type) && userInvitations.show && !preview.show"
        :key="cloneGroups.length"
        class="user-training-list"
        ref="groupList"
        :groupHeader="groupHeader"
        :loadingGroups="loadingGroups"
        :userHeader="userHeader"
        :cloneGroups="cloneGroups"
        :cloneUsers="cloneUsers"
        :usersData="users"
        :groupsData="groups"
        :selectedItems="userInvitations.selectedItems ? userInvitations.selectedItems : []"
        :item="item"
        :invitationType="userInvitations.type"
        :style="{'margin-top': sendInvitationsModalActive ? '0px' : -groupListMarginTop+'px'}"
        @revokeLicence="revokeLicence($event)"
        @addGroupToIndex="addGroupToIndex"
        @toggleUser="toggleUserToRentedTraining"
        @addUserToList="addUserToList"
      />
      <!-- <div class="row button-container"  :class="{'drop-shadow': showListShadow}">
        <div class="send-invitations-btn" @click="sendInvitationsModalActive=true">
          <span>
            {{toLocal('training.send_invitation_btn_label')}}
          </span>
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0212 0.806785C21.1441 1.04493 21.1198 1.33246 20.9586 1.54656L9.1014 17.2927C8.92932 17.5212 8.63519 17.6215 8.35934 17.5458C8.08349 17.47 7.88183 17.2336 7.85057 16.9492L6.879 8.10906L0.234016 2.19835C0.0202773 2.00823 -0.055748 1.7069 0.0422159 1.43814C0.14018 1.16938 0.392281 0.987646 0.678229 0.979662L20.3818 0.429472C20.6497 0.421992 20.8982 0.568643 21.0212 0.806785ZM8.28903 8.16618L9.04532 15.0475L18.0049 3.14944L8.28903 8.16618ZM17.3647 1.90961L2.47626 2.32535L7.64885 6.92636L17.3647 1.90961Z" fill="black"/>
          </svg>
        </div>
        <div class="save-btn-with-undertext" v-if="item.rented && item.purchase_type == 2">
          <div
            class="save-btn"
            :class="{
              faded: usableLicenses < 0 ,
            }"
            @click="saveChanges"
          >
            {{ toLocal("settings.save") }}
          </div>
          <span class="save-undertext" v-if="usableLicenses < 0 && item.purchase_type == 2">{{
            toLocal("modal.too_many_users")
          }}</span>
        </div>
      </div>   -->
      <div class="save-bar" v-if="userInvitations.show && !['link'].includes(userInvitations.type)">
        <div class="button-container">
            <div
            class="save-btn"
            @click="saveInvitations"
          >
            <font-awesome-icon icon="spinner" v-if="isSavingInvitations"/>
            {{ !isSavingInvitations ? toLocal("settings.save") : '' }}
          </div>
        </div>
      </div>
      <transition name="expand-invite">
        <send-training-invitations 
          v-if="['link'].includes(userInvitations.type) && userInvitations.show && !preview.show" 
          :training_id="item.id"
          :training="item"
          :users="cloneUsers"
          :selectedUsers="userInvitations.selectedItems ? userInvitations.selectedItems : []"
          :groups="cloneGroups"
          @close="resetInvitation" 
          @saveItem="saveLinkInvitation"
        />
      </transition>
    </div>
    <portal-target name="training-invitation-status">
    </portal-target>
  </div>
</template>

<script>
import axios from "axios";
import GlobalModal from "./GlobalModal.vue";
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
import CloseCrossFadedSvg from "@/assets/images/icons/close-cross-faded.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
import ArrowDownSvg from "@/assets/images/icons/arrow-down.svg?inline";
import OrderBySvg from "@/assets/images/icons/order-by.svg?inline";
import OrderByAscSvg from "@/assets/images/icons/order-by-asc.svg?inline";
import OrderByDescSvg from "@/assets/images/icons/order-by-desc.svg?inline";
import ArrowRightSvg from "@/assets/images/icons/arrow-right.svg?inline";
import AssignPlusSvg from "@/assets/images/icons/assign-plus.svg?inline";
import AssignCrossSvg from "@/assets/images/icons/assign-cross.svg?inline";
import SendTrainingInvitations from "@/components/layout/SendTrainingInvitations.vue";
import GroupBubbleRow from "@/components/layout/groups/GroupBubbleRow.vue";
import ToggleButton from "@/components/layout/emails/ToggleButton";
import UserTrainingList from "@/components/layout/users/UserTrainingList.vue";
import ConditionFilter from "@/components/layout/groups/ConditionFilter.vue";
import SelectUserInvitationWindow from "@/components/layout/trainings/training_users/SelectUserInvitationWindow.vue";
import TrainingInvitationsWindow from "@/components/layout/trainings/training_users/invitation_list/TrainingInvitationsWindow.vue";
import InvitedUserListModal from "@/components/layout/trainings/training_users/invitation_list/InvitedUserListModal.vue";
import InvitedGroupsListModal from "../trainings/training_users/invitation_list/InvitedGroupsListModal.vue";
import TrainingConditionWindow from "@/components/layout/trainings/training_users/invitation_list/TrainingConditionWindow.vue";
export default {
  name: "AssignModal",
  data: function () {
    return {
      groups: [],
      users: [],
      cloneUsers: [],
      cloneGroups: [],
      activatedIndex: -1,
      addedUsersIndex: [],
      fullyAddedGroups: [],
      usableLicenses: 0,
      cloneIndexes: [],
      savingUsersModalOpen: false,
      removedGroups: [],
      searchTerm: "",
      userHeader:[
        {'key':'name', 'label':this.toLocal('generic.name'), class:'name', sortable:true, sortOptions:{type:'text'}},
        {'key':'last_name', 'label':this.toLocal('generic.surname'), class:'last-name', sortable:true, sortOptions:{type:'text'}},
        {'key':'email', 'label':this.toLocal('generic.email'), class:'email', sortable:true, sortOptions:{type:'text'}},
        {'key':'added', 'label':this.toLocal('training.added_to_training'), class:'user-groups', sortable:true, sortOptions:{type:'relation'}},
      ],
      groupHeader:[
        {'key':'name', 'label':this.toLocal('generic.group_name'), class:'title-header', sortable:true, sortOptions:{type:'text'}},
        {'key':'added_user_count', 'label':this.toLocal('training.added_user_count_to_training'), class:'user-count-header', sortable:true, sortOptions:{type:'number'}},
        {'key':'users_count', 'label':this.toLocal('groups.user_count_in_group'), class:'user-count-header', sortable:true, sortOptions:{type:'number'}},
        {'key':'group_full', 'label':this.toLocal('training.added_to_training'), class:'actions-header', sortable:true, sortOptions:{type:'relation'}},
      ],
      invitation_types: [
        {'label': this.toLocal('invitations.add_by_condition'), value: 'condition'},
        {'label': this.toLocal('invitations.add_by_one'), value: 'single'},
        {'label': this.toLocal('invitations.by_groups'), value: 'groups'},
        {'label': this.toLocal('invitations.email_link'), value: 'link'},
      ],
      invitation_type_labels:{
        condition: this.toLocal('invitations.label_add_by_condition'),
        single: this.toLocal('invitations.label_add_by_one'),
        groups: this.toLocal('invitations.label_add_by_groups'),
        link: this.toLocal('invitations.label_add_by_email'), 
      },
      searchingForGroup:true,
      ungroupedUsers: [],
      cloneUngroupedUsers: [],
      listRef: null,
      listHeight: 0,
      loadingGroups:false,
      changingUserStatus: false,
      sendInvitationsModalActive: false,
      revokeLicenceFromUser: null,
      groupListMarginTop: 0,
      conditionalFilter: [],
      includable_columns: [],
      additional_user_data: [],
      invitations: [
      ],
      loadedInvitations: false,
      userInvitations:{
        type: null,
        show: false,
        selectedItems:[]
      },
      preview:{
        show: false,
        users: [],
        label: '',
        item: null
      },
      isSavingInvitations: false,
      isDeletingInvitation: false,
      tempInvitation: '',
      preview_labels:{
        condition: this.toLocal('invitations.label_add_by_condition'),
        link: this.toLocal('invitations.label_add_by_email'),
        single: this.toLocal('invitations.label_add_by_one'),
        groups: this.toLocal('invitations.label_add_by_groups'),
      },
      conditionFilterUserCount: 0
    };
  },
  components: {
    GlobalModal,
    ConditionFilter,
    GroupBubbleRow,
    SendTrainingInvitations,
    ToggleButton,
    UserTrainingList,
    SelectUserInvitationWindow,
    TrainingInvitationsWindow,
    InvitedUserListModal,
    TrainingConditionWindow,
    CloseCrossSvg,
    CloseCrossFadedSvg,
    GroupSearchSvg,
    ArrowDownSvg,
    OrderBySvg,
    OrderByAscSvg,
    OrderByDescSvg,
    ArrowRightSvg,
    AssignPlusSvg,
    AssignCrossSvg,
    InvitedGroupsListModal
},
  props: {
    item: Object,
    type: String,
  },
  mounted() {
    this.usableLicenses = this.item.license_count;
    this.loadGroups();
    this.getAdditionalUserData();
    this.getTrainingInvitations();
  },
  computed:{
    showListShadow(){
      if( this.listRef){
          return this.listHeight > this.listRef.clientHeight;
      }
      return null;
    },
  },
  beforeDestroy(){
    if(this.listRef)
      this.listRef.removeEventListener('scroll', this.scrollGroupList);
  },
  watch: {
    addedUsersIndex: {
      handler(newVal) {
        if (this.item.rented) {
          this.usableLicenses = this.item.license_count - newVal.length;
         
        }
      },
    },
    searchTerm: {
      handler(newVal, oldVal) {
        if (newVal == "") {
          if(this.searchingForGroup){
            this.groups = _.cloneDeep(this.cloneGroups);
          }
          else{
            this.users = _.cloneDeep(this.cloneUsers);
          }
        } else {
           if(this.searchingForGroup){
            this.groups = this.cloneGroups.filter((group) =>
              group.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
           }
           else{
             this.users = this.cloneUsers.filter((user) =>{
               return user.name.toLowerCase().includes(newVal) 
               || user.last_name.toLowerCase().includes(newVal)
               || user.email.toLowerCase().includes(newVal)
             });
           }
        }
      },
    },
    item(newVal, oldVal){
      if(newVal.id != undefined && oldVal.id == undefined){
        this.loadGroups();
        this.usableLicenses = this.item.license_count;
      }
    },
    listRef(){
      if(this.listRef)
        this.listHeight = this.listRef.scrollHeight;
    },
    
  },
  methods: {
    selectInvitationType(type){
      this.$set(this.userInvitations, 'type', type);
      this.$set(this.userInvitations, 'show', true);
    },
    inviteUsers(type){
      this.$set(this.userInvitations, 'type', type);
      this.$set(this.userInvitations, 'show', true);
    },
    getTrainingInvitations(){
      axios.get( 
            `${this.$store.state.api_link}/training/invitations/${this.item.id}`,
            this.$store.getters.axiosConfig()
        ).then((response) => {
            this.invitations = response.data.data
            this.loadedInvitations = true;
        }).catch((error) => {
            this.loadedInvitations = true;
        });
    },
    getAdditionalUserData(){
        axios.get( 
            `${this.$store.state.api_link}/user/additional-data`,
            this.$store.getters.axiosConfig()
        ).then((response) => {
            this.additional_user_data = response.data.users
            this.includable_columns = response.data.includable_columns
        }).catch((error) => {
        });
    },

    
    buyMore(id) {
      this.$router.push(`/training-shop?training_id=${id}&rented=true`);
    },
    
    completeSave() {
      if (this.item.rented) {
        axios
          .post(
            `${this.$store.state.api_link}/trainings/assign-rented`,
            { id: this.item.connection_id, users: this.addedUsersIndex, type: this.item.purchase_type },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.item.license_count -= this.addedUsersIndex.length;
            this.addedUsersIndex = [];
            this.savingUsersModalOpen = false;
          })
          .catch((error) => {
            this.$store.commit("handleError", { error: error });
          });
      } 
    },
    saveChanges() {
      if(this.item.rented && this.addedUsersIndex.length){
        if(this.item.purchase_type == 2 && (this.item.license_count >= this.addedUsersIndex.length)){
          this.savingUsersModalOpen = true;
        }
        else{
          this.completeSave();
        }
      }
    },
    advancedFilter(filter,invitation_id=null,preview=true){
          axios
              .post(
                  `${this.$store.state.api_link}/training/${this.item.id}/users/filter`,
                  { 
                    filter: filter,
                    invitation_id: invitation_id
                  },
                  this.$store.getters.axiosConfig()
              )
              .then((response) => {
                  let users_ids = response.data.user_ids;
                  if(preview){
                    this.$set(this.preview, 'show', true);
                    if(invitation_id == null)
                      this.$set(this.preview, 'temp_preview', true);
                    this.$set(this.preview, 'label', this.preview_labels.condition);
                    this.$set(this.preview,'users',this.additional_user_data.filter(el => users_ids.includes(el.id)));
                  }
                  else{
                    this.conditionFilterUserCount = users_ids.length;

                  }
              })
              .catch((error) => {
              });
      },
    fullyRevokeLicence(){
       axios
          .post(
            `${this.$store.state.api_link}/training/users/${this.item.rented ? this.item.rented : 0}/${this.item.id}/revoke`,
            {
              user_id: this.revokeLicenceFromUser.id,
            },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            let index = this.users.findIndex(el=>el.id == this.revokeLicenceFromUser.id);
            if(index != -1){
              this.$set(this.users[index],'added', false);
            }
            index = this.cloneUsers.findIndex(el=>el.id == this.revokeLicenceFromUser.id);
            if(index != -1){
              this.$set(this.cloneUsers[index], 'added', false);
            }
            this.revokeLicenceFromUser=null;

          })
          .catch((error) => {
            this.$store.commit("handleError", { error: error });
          });
    },
    loadGroups() {
      if(!this.item.id) return;
      if(this.loadingGroups) return;
      this.loadingGroups = true;
      let type = this.item.purchase_type != null ? this.item.purchase_type : 3 // 3 - group type
      axios
        .get(
          `${this.$store.state.api_link}/training/users/${type}/${this.item.id}/groups`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.groups != undefined) {
            this.groups = response.data.groups;
            this.cloneGroups = _.cloneDeep(this.groups);
            this.users = response.data.users;
            this.cloneUsers = _.cloneDeep(this.users);
            
            this.$nextTick(()=>{
              this.listRef = this.$refs.groupList;
              
              if(this.listRef && this.listRef.$refs.groupList){
                this.listRef = this.listRef.$refs.groupList;
              }
              if(this.listRef)
                this.listRef.addEventListener('scroll', this.scrollGroupList);
            })
            this.loadingGroups = false;
          }
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
          this.loadingGroups = false;
        });
    },
    
    revokeLicence(user){
      this.revokeLicenceFromUser = user;
    },
    closeModal() {
      if(this.preview.show){
        this.resetPreview();
        return;
      }
      if(this.userInvitations.show){
        this.resetInvitation();
      }
      else{
        this.$emit("modalClose");
      }
    },
    scrollGroupList(e){
      if(this.listRef && this.listRef.scrollTop > 350)
        this.groupListMarginTop = 175;
      else this.groupListMarginTop = this.listRef.scrollTop/2;
    },
    toggleUserToRentedTraining(user){
            let userWillBeAdded = false;
            let index = this.users.findIndex(el=>el.id == user.id);
            if(index != -1){
                userWillBeAdded = !user.added;
                if(userWillBeAdded){
                    if(this.usableLicenses == 0 && this.item.purchase_type == 2) return;
                    this.addedUsersIndex.push(user.id);
                    this.$set(this.users[index],'added',!user.added);
                }
                else if(this.addedUsersIndex.includes(user.id)){
                    let u_index = this.addedUsersIndex.findIndex(el=>el == user.id);
                    this.addedUsersIndex.splice(u_index, 1);
                    this.$set(this.users[index],'added',!user.added);
                }
                else{
                    this.$set(this.users[index],'added',!user.added);
                }
            }
            index = this.cloneUsers.findIndex(el=>el.id == user.id);
            if(index != -1){
                if(userWillBeAdded){
                if(this.usableLicenses == 0 && this.item.purchase_type == 2) return;
                    this.$set(this.cloneUsers[index],'added',true);
                }
                else if(this.addedUsersIndex.includes(user.id)){
                    this.$set(this.cloneUsers[index],'added',false);
                }
                else{
                    this.$set(this.cloneUsers[index],'added',false);
                }
            }
        },
        addGroupToIndex(group) {
            if (this.item.rented && this.item.purchase_type == 2) {
                let filtered_users = this.cloneUsers.filter((user) => {
                  if(group.id ==0) return true;
                  return group.users.includes(user.id);
                });
                let count = group.users_count > this.usableLicenses ? this.usableLicenses : group.users_count;
                for(let i = 0; i< count; i++){
                  if(this.usableLicenses == 0) break;
                  this.toggleUserToRentedTraining(filtered_users[i]);
                }
                
            } 
            else {
                let action = group.added ? 'remove' : 'add';
                if(action == 'remove'){
                  this.$set(group, 'added', false);
                  let index = this.userInvitations.selectedItems.findIndex(el=>el == group.id);
                  if(index != -1){
                    this.userInvitations.selectedItems.splice(index, 1);
                  }
                }
                else{
                  this.$set(group, 'added', true);
                  let index = this.userInvitations.selectedItems.findIndex(el=>el == group.id);
                  if(index == -1){
                    this.userInvitations.selectedItems.push(group.id);
                  }
                }
             
        
            }
        },
      addUserToList(group, user){
          let update_to_state = !user.added;
          if(this.item.rented && this.item.purchase_type == 2){
            this.toggleUserToRentedTraining(user);
          }
          else{
              let index = this.users.findIndex(el=>el.id == user.id);
              if(index !== -1){
                this.users[index].added = update_to_state;
              }
              index = this.cloneUsers.findIndex(el=>el.id == user.id);
              if(index !== -1){
                this.$set(this.cloneUsers[index], 'added', update_to_state);
              }
              if(!update_to_state){
                index = this.userInvitations.selectedItems.findIndex(el=>el == user.id);
                if(index != -1){
                  this.userInvitations.selectedItems.splice(index, 1);
                }
              }
              else{
                let index = this.userInvitations.selectedItems.findIndex(el=>el == user.id);
                if(index == -1){
                  this.userInvitations.selectedItems.push(user.id);
                }
              }
           
            }
        },
        saveInvitations(){
          if(this.userInvitations.type == 'condition'){
            this.userInvitations.selectedItems = {
              filter: this.$refs.conditionWindow.filter
            }
          }
          this.isSavingInvitations = true;
          axios.post(
                `${this.$store.state.api_link}/training/invitations/save`,
                {
                  training_id: this.item.id,
                  item_type: this.userInvitations.type,
                  item_ids: this.userInvitations.selectedItems,
                  invitation_id: this.userInvitations.item ? this.userInvitations.item.id : null
                },
                this.$store.getters.axiosConfig()
              )
              .then((res)=>{
                this.isSavingInvitations = false;
                if(this.userInvitations.item && this.userInvitations.item.id){
                  let index = this.invitations.findIndex(el=>el.id == this.userInvitations.item.id);
                  if(index != -1){
                    this.$set(this.invitations,index, res.data.item);
                  }
                }
                else{
                  this.invitations.push(res.data.item);
                }
                this.resetInvitation()
              })
              .catch((err)=>{
                this.isSavingInvitations = false;
              })
        },
        bufferRemoveInvitation(invitation){
          this.isDeletingInvitation = true;
          this.tempInvitation = invitation;
        },
        removeInvitation(){
            let invitation = this.tempInvitation;
            this.isDeletingInvitation = false;
            this.tempInvitation = null;
            axios.delete(
                `${this.$store.state.api_link}/training/invitations/${invitation.id}`,
                this.$store.getters.axiosConfig()
              )
              .then((res)=>{
                let index = this.invitations.findIndex(el=>el.id == invitation.id);
                if(index != -1){
                  this.invitations.splice(index, 1);
                }
              })
              .catch((err)=>{
                
              })
        },
        viewInvitation(invitation){
          this.$set(this.preview, 'show', true);
          this.$set(this.preview, 'item', invitation);
          this.$set(this.preview, 'label', this.preview_labels[invitation.type]);
          if(invitation.type =='condition'){
            this.advancedFilter(invitation.condition, invitation.id);
          }
          else{
            this.getPreviewUsers(invitation);
          }
        },
        getPreviewUsers(invitation){
         
           axios.get(
                `${this.$store.state.api_link}/training/invitations/${invitation.id}/preview/users`,
                this.$store.getters.axiosConfig()
              )
              .then((res)=>{
                this.$set(this.preview, 'users', res.data.items);
              })
              .catch((err)=>{

              })
        },
        toggleInvitation(invitation){
           axios.get(
                `${this.$store.state.api_link}/training/invitations/toggle/${invitation.id}`,
                this.$store.getters.axiosConfig()
              )
              .then((res)=>{
                let index = this.invitations.findIndex(el=>el.id == invitation.id);
                if(index != -1){
                  this.$set(this.invitations[index], 'enabled', res.data.enabled);
                }
              })
              .catch((err)=>{

              })
        },
        resetInvitation(){
          this.$set(this.userInvitations,'type', null);
          this.$set(this.userInvitations,'selectedItems', []);
          this.$set(this.userInvitations,'show', false);
          this.$set(this.userInvitations,'item', null);
          this.conditionFilterUserCount=0;
        },
        resetPreview(){
          this.$set(this.preview,'users', []);
          this.$set(this.preview,'label', '');
          this.$set(this.preview,'item', null);
          this.$set(this.preview,'show', false);
        },
        saveLinkInvitation(users, date, codes){
          this.$set(this.userInvitations, 'selectedItems', {
            items: users,
            date: date,
            codes: codes
          });
          this.$set(this.userInvitations, 'type', 'link');
          this.saveInvitations()
        },
        editInvitation(invitation){
            axios.get(
              `${this.$store.state.api_link}/training/invitations/${invitation.id}/data`,
              this.$store.getters.axiosConfig()
            )
            .then((res)=>{
              this.$set(this.userInvitations, 'selectedItems', res.data.items);
              this.$set(this.userInvitations, 'show', true);
              this.$set(this.userInvitations, 'type', invitation.type);
              this.$set(this.userInvitations, 'item', invitation);
            })
            .catch((err)=>{
              console.log(err);
            })
        }

  },

};
</script>

<style lang="scss" scoped>
.item-header {
  z-index: 4;
  .save-btn {
    position: relative;
    padding: 10px 43px 10px 27px;
    background-color: rgba(244, 244, 244, 1);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    font-weight: 400;
    font-size: 14px;
    border-radius: 38px;
    transition: 0.25s;
    display: flex;
    align-items: center;
    white-space: nowrap;
    .arrow-right {
      position: absolute;
      top: 35%;
      right: 23px;
    }
    &:not(.faded):hover {
      cursor: pointer;
      background: #333333;
      color: #ffffff;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  .flex-row{
    width: 100%;
  }
  &.minimal{
    .flex-row{
      margin: 0;
      padding: 12px 16px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border:none;
    }
    .item-title{
      color: #7D7D7D;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      width: initial;
      padding-left: 8px;
      margin: 0;
    }
    .assign-title{
      color: #333;
      text-overflow: ellipsis;
      font-family: var(--main-site-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      padding-right: 8px;
      border-right: 1px solid #7D7D7D;
      svg{
        margin-left: 8px;
      }
    }
  }
}
.modal-window {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  margin: 0 auto;
  color: #333333;
  user-select: none;
  padding-top: 11vh;
  &.rent{
    padding-top: 103px;
    height: 100%;
  }
}
.icon{
  svg{
    path{
      &.active{
        fill: #333333;
      }
    }
  }
}
.inner-modal {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 60vw;
  max-width: 1096px;
  min-width: 920px;
  height: 75vh;
  position: relative;
  .closing-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    line-height: 16px;
    z-index: 5;
    cursor: pointer;
  }
  .item-img {
    width: 110px;
    max-width: 110px;
    max-height: 110px;
    min-width: 110px;
    height: 110px;
    border-radius: 8px;
    .item-cover {
      height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
    }
  }
  .item-title {
    margin-left: 20px;
    font-size: 24px;
    width: 100%;
    line-height: 32px;

    font-family: var(--main-site-font);
  }
  &.overlay-active{
    .overlay{
      opacity: 1;
    }
    .main-list{
      opacity: 0;
    }
    .item-upper-header{
      opacity: 0;
    }
  }
}


.save-bar{
  display: flex;
  justify-content: flex-end;
  border-radius: 0px 0px 12px 12px;
  background: #FFF;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  z-index: 5;
  .button-container{
    padding: 0;
  }
}
.save-btn {
  padding: 8px 28px;
  background-color: var(--third-color);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
  font-weight: 400;
  font-size: 16px;
  border-radius: 38px;
  transition: 0.25s;
  &:not(.faded):hover {
    cursor: pointer;
    background: #333333;
    color: #ffffff;
  }
}
.save-undertext {
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  text-align: center;

  /* LK fail raudona */
  color: #d45454;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #00000062;
  transition: opacity 0.3s ease-in-out;
  z-index: 0;
  border-radius: 16px;
  &.active{
    opacity: 1;
  }
}
.button-container{
  display: flex;
  justify-content: space-between;
  z-index: 2;
  &.drop-shadow{
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);
  }
}
.send-invitations-btn{
  display: flex;
  align-items: center;
  background: #F4F4F4;
  border: 1px solid #333333;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  padding: 8px 20px;
  user-select: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--main-site-font);
  cursor: pointer;
  svg{
    margin-left: 8px;
  }
}


.flex-row {
  display: flex;
  flex-direction: row;
  padding: 40px 40px 24px 40px;
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
}
.button-container{
  padding: 30px 40px;
}
.arrow{
  &.active{
    transform: rotate(180deg);
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 27px;
}
.label{
  white-space: nowrap;
  margin-bottom: 8px;
}
.left-aligned {
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
}
.underline {
}
.end {
  justify-content: flex-end;
}
.bottom-8 {
  margin-bottom: 8px;
}
.action-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 12%;
}
.big-bold-text {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 14px;
}
.small-font {
  font-size: 14px;
}
.flipped {
  transform: rotate(180deg);
}
.slide-enter-active {
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

.slide-leave-active {
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

.slide-enter-to,
.slide-leave {
  max-height: 100vh;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.faded {
  opacity: 0.5;
  cursor: not-allowed;
  cursor: no-drop;
}
.red {
  color: #d45454;
}
.user-training-list{
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}
.expand-invite-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.expand-invite-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.expand-invite-enter-to,
.expand-invite-leave {
  max-height: 100%;
  max-width: 100%;

  overflow: hidden;
}

.expand-invite-enter,
.expand-invite-leave-to {
  overflow: hidden;
  max-height: 0;
  max-width: 0;
  padding: 0;
  
}
.expand-invite-leave-to {
  left: 5px;
  bottom: 5px;
  
}

//Viso modalo 'sumazinimas', daug kur perpuse maziau or less
@media (max-width: 1024px){
  .modal-window{
    padding-left: 4vw;
    padding-top: 60px;
  }
  .inner-modal{
      height: 90%;
      .item-header{
        .flex-row{
          padding: 20px 30px 12px 30px;
            .item-img{
            max-width: 55px;
            max-height: 55px;
            min-width: 55px;
            width: 55px;
            height: 55px;
          }
          .item-title{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  .item-main{
    .item-upper-header{
      padding: 0 30px;
      margin: 12px 0;
    }
    .main-list{
      padding: 0 30px 0 30px;
    }
  }
  .button-container{
    padding: 15px 20px;
    .send-invitations-btn{
      padding: 4px 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 16px;
        height: 16px;
      }
    }
  }
  .item-upper-header .search-bar .search-inner{
    padding: 8px 12px;
    width: 200px;
    font-size: 14px;
  }
  .main-list .list{
    gap: 8px;
    margin: 8px 0;
  }
  .list-item-with-users{
    margin: 0;
    .list-item{
      padding: 4px 10px;
    }
  }
}
@media (max-width: 960px){
  .inner-modal{
    min-width: 768px;
    width: 92vw;
  }
  .main-list .sort-options .option-with-icon .option{
    min-width: 80px;
  }
}
@media (max-width: 768px){
  .modal-window{
    padding-left: 0vw;
  }
  .inner-modal{
   min-width: 95vw;
   width: 95vw;
   .item-img{
    height: 8vh;
    min-height: 64px;
    min-width: 64px;
    width: 8vh;
   }
  }
  .title-header{
    width: 35%;
  }
  .list-item-with-users .list-item .title.bought{
    width: 32%;
  }
  .list-item-with-users .list-item .user-count{
    width: 25%;
  }

  .main-list .sort-options .option-with-icon .option{
    min-width: 85px;
  }
  .flex-row{
    padding: 0 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .main-list{
    padding: 0 10px 0 20px;
  }
  .item-upper-header{
    padding: 0 20px;
  }
  .button-container{
    padding: 15px 20px;
  }
}
@media (max-width: 635px){
  .item-upper-header{
    flex-direction: column;
    align-items: flex-start;
    .search-type{
      margin-top: 10px;
    }
  }
  .inner-modal .item-title{
    font-size: 20px;
    line-height: 28px;
  }
}
</style>
<style lang="scss">
.expand-invite-enter-active, expand-invite-leave-active {
  .content, .header{
    opacity: 1;
    transition: all 0.5s;
  }
}
.expand-invite-enter,
.expand-invite-leave-to {
  .content, .header{
    opacity: 0;
  }
  
}
.item-upper-header{
  .custom-search-toggle{
    background: #F4F4F4;
    width: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    &.toggle-btn.active {
       background:#F4F4F4;
      .inner--circle{
        background: var(--secondary-color);
      }
    }
     &.toggle-btn{
       .inner--circle{
         background: var(--secondary-color);
       }
     }
  }
}
</style>