<template>
    <div class="footer-container">
        <div class="content">
            <div class="row main-row">
                <div class="col left">
                    <div class="col">
                        <a class="img" href="https://lkakademija.lt" target="_blank">
                            <svg width="179" height="32" viewBox="0 0 179 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1185_183)">
                                <path d="M2.91107 18.6101H11.9851V21.26H0.120605V0.241211H2.91107V18.6101Z" fill="#1D1D1B"/>
                                <path d="M30.0527 21.26H26.6198L17.5659 11.1622V21.26H14.7754V0.241211H17.5659V9.73682L26.3388 0.241211H29.7716L20.4768 10.4595L30.0527 21.26Z" fill="#1D1D1B"/>
                                <path d="M47.4778 6.2433H50.0876V21.2596H47.4778V18.6699C46.193 20.6574 44.2859 21.641 41.7965 21.641C39.6886 21.641 37.9019 20.8983 36.4163 19.3525C34.9508 17.8267 34.188 15.9196 34.188 13.7314C34.188 11.5432 34.9308 9.67618 36.4163 8.15045C37.8818 6.62473 39.6886 5.8418 41.7965 5.8418C44.2859 5.8418 46.1729 6.82549 47.4778 8.81294V6.2433ZM45.9521 17.6059C46.9759 16.562 47.4778 15.2973 47.4778 13.7715C47.4778 12.2458 46.9759 10.9811 45.9521 9.95723C44.9283 8.91331 43.6635 8.39136 42.1378 8.39136C40.6322 8.39136 39.3674 8.89324 38.3636 9.95723C37.3398 10.9811 36.8379 12.2458 36.8379 13.7715C36.8379 15.2973 37.3398 16.562 38.3636 17.6059C39.3875 18.6298 40.6522 19.1316 42.1378 19.1316C43.6635 19.1316 44.9283 18.6097 45.9521 17.6059Z" fill="#324E9C"/>
                                <path d="M66.7704 21.26H63.5182L56.6725 14.1333V21.26H54.0627V0.241211H56.6725V12.8886L63.1569 6.24372H66.5295L59.3626 13.511L66.7704 21.26Z" fill="#324E9C"/>
                                <path d="M80.241 6.2433H82.8508V21.2596H80.241V18.6699C78.9562 20.6574 77.049 21.641 74.5597 21.641C72.4518 21.641 70.6651 20.8983 69.1795 19.3525C67.714 17.8267 66.9512 15.9196 66.9512 13.7314C66.9512 11.5432 67.694 9.67618 69.1795 8.15045C70.645 6.62473 72.4518 5.8418 74.5597 5.8418C77.049 5.8418 78.9361 6.82549 80.241 8.81294V6.2433ZM78.7153 17.6059C79.7391 16.562 80.241 15.2973 80.241 13.7715C80.241 12.2458 79.7391 10.9811 78.7153 9.95723C77.6914 8.91331 76.4267 8.39136 74.901 8.39136C73.3953 8.39136 72.1306 8.89324 71.1068 9.95723C70.0829 10.9811 69.581 12.2458 69.581 13.7715C69.581 15.2973 70.0829 16.562 71.1068 17.6059C72.1306 18.6298 73.3953 19.1316 74.901 19.1316C76.4267 19.1316 77.6914 18.6097 78.7153 17.6059Z" fill="#324E9C"/>
                                <path d="M99.2522 0.241211H101.862V21.26H99.2522V18.6703C97.9674 20.6578 96.0603 21.6415 93.5709 21.6415C91.463 21.6415 89.6763 20.8987 88.1908 19.3529C86.7253 17.8272 85.9624 15.92 85.9624 13.7318C85.9624 11.5436 86.7052 9.67659 88.1908 8.15087C89.6562 6.62515 91.463 5.84222 93.5709 5.84222C96.0603 5.84222 97.9473 6.8259 99.2522 8.81336V0.241211ZM97.7265 17.6063C98.7504 16.5624 99.2522 15.2977 99.2522 13.772C99.2522 12.2462 98.7504 10.9815 97.7265 9.95765C96.7027 8.91373 95.4379 8.39178 93.9122 8.39178C92.4066 8.39178 91.1418 8.89366 90.118 9.95765C89.0941 10.9815 88.5923 12.2462 88.5923 13.772C88.5923 15.2977 89.0941 16.5624 90.118 17.6063C91.1418 18.6302 92.4066 19.1321 93.9122 19.1321C95.4379 19.1321 96.7027 18.6101 97.7265 17.6063Z" fill="#324E9C"/>
                                <path d="M107.664 14.9564C108.166 17.6264 110.253 19.1923 113.104 19.1923C115.092 19.1923 116.537 18.4696 117.42 17.0041L119.649 18.2688C118.183 20.5173 115.995 21.6616 113.044 21.6616C110.675 21.6616 108.728 20.9188 107.222 19.4332C105.757 17.9276 104.994 16.0405 104.994 13.7519C104.994 11.5035 105.716 9.61638 107.182 8.11074C108.647 6.60509 110.555 5.8623 112.863 5.8623C115.051 5.8623 116.858 6.64524 118.243 8.21111C119.649 9.73683 120.351 11.6038 120.351 13.792C120.351 14.1735 120.331 14.575 120.271 14.9564H107.664ZM107.664 12.6678H117.701C117.26 9.81713 115.272 8.31149 112.863 8.31149C110.053 8.31149 108.105 10.038 107.664 12.6678Z" fill="#324E9C"/>
                                <path d="M145.184 11.9246V21.2596H142.575V12.0049C142.575 9.69625 141.29 8.33113 139.262 8.33113C137.014 8.33113 135.568 9.79663 135.568 12.8882V21.2395H132.959V12.0049C132.959 9.69625 131.734 8.33113 129.767 8.33113C127.578 8.33113 125.932 9.85685 125.932 12.8882V21.2395H123.323V6.2433H125.932V8.25083C126.956 6.66488 128.462 5.8418 130.409 5.8418C132.417 5.8418 133.882 6.68496 134.826 8.39136C135.87 6.68496 137.435 5.8418 139.543 5.8418C142.896 5.86187 145.184 8.19061 145.184 11.9246Z" fill="#324E9C"/>
                                <path d="M148.376 1.76663C148.376 0.803011 149.159 0 150.143 0C151.107 0 151.89 0.803011 151.89 1.76663C151.89 2.73024 151.107 3.51317 150.143 3.51317C149.139 3.51317 148.376 2.73024 148.376 1.76663ZM148.818 21.2597V6.24341H151.428V21.2597H148.818Z" fill="#324E9C"/>
                                <path d="M155.744 6.24341H158.354V22.1631C158.354 26.0376 156.366 27.9247 152.552 27.6637V25.1343C154.74 25.335 155.744 24.4718 155.744 22.1631V6.24341ZM155.302 1.76662C155.302 0.803011 156.085 0 157.069 0C158.033 0 158.815 0.803011 158.815 1.76662C158.815 2.73024 158.033 3.51317 157.069 3.51317C156.085 3.51317 155.302 2.73024 155.302 1.76662Z" fill="#324E9C"/>
                                <path d="M174.755 6.2433H177.365V21.2596H174.755V18.6699C173.471 20.6574 171.563 21.641 169.074 21.641C166.966 21.641 165.18 20.8983 163.694 19.3525C162.228 17.8267 161.466 15.9196 161.466 13.7314C161.466 11.5432 162.208 9.67618 163.694 8.15045C165.159 6.62473 166.966 5.8418 169.074 5.8418C171.563 5.8418 173.451 6.82549 174.755 8.81294V6.2433ZM173.23 17.6059C174.254 16.562 174.755 15.2973 174.755 13.7715C174.755 12.2458 174.254 10.9811 173.23 9.95723C172.206 8.91331 170.941 8.39136 169.415 8.39136C167.91 8.39136 166.645 8.89324 165.621 9.95723C164.597 10.9811 164.095 12.2458 164.095 13.7715C164.095 15.2973 164.597 16.562 165.621 17.6059C166.645 18.6298 167.91 19.1316 169.415 19.1316C170.941 19.1316 172.206 18.6097 173.23 17.6059Z" fill="#324E9C"/>
                                <path d="M35.7543 31.9998H176.522C177.365 31.9998 178.068 31.3172 178.068 30.454C178.068 29.6108 177.385 28.9082 176.522 28.9082H35.7543C34.9111 28.9082 34.2085 29.5908 34.2085 30.454C34.2085 31.3172 34.9111 31.9998 35.7543 31.9998Z" fill="#324E9C"/>
                                <path d="M1.5458 31.9998H29.2497C30.0928 31.9998 30.7955 31.3172 30.7955 30.454C30.7955 29.6108 30.1129 28.9082 29.2497 28.9082H1.5458C0.702635 28.9082 0 29.5908 0 30.454C0 31.3172 0.68256 31.9998 1.5458 31.9998Z" fill="#1D1D1B"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1185_183">
                                <rect width="178.068" height="32" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <div class="links">
                            <span>Pagalba: <strong>help@lkakademija.lt</strong></span>
                            <a href="/privacy-policy" target="_blank">Privatumo politika</a>
                            <a href="/rules" target="_blank">Pirkimo – pardavimo taisyklės</a>
                        </div>
                    </div>
                </div>
                <div class="col right">
                    <div class="col">
                        <a class="img" href="https://learnkey.lt" target="_blank">
                            <svg width="130" height="32" viewBox="0 0 130 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1185_171)">
                                <path d="M13.8828 16.3916C13.8828 11.5326 16.6594 7.52637 22.2919 7.52637C27.9243 7.52637 30.7406 11.4632 29.7489 17.7699H18.5931C18.9302 20.0606 20.2392 21.7365 22.8075 21.7365C24.6615 21.6974 26.4611 21.1032 27.9739 20.0309L29.8084 23.1148C27.7846 24.6068 25.321 25.3814 22.8075 25.3163C17.4031 25.3163 13.8828 21.8158 13.8828 16.3916ZM18.5931 15.0727H25.9312C25.9907 13.0894 25.1279 10.888 22.3811 10.888C19.6343 10.888 18.5931 13.2679 18.5931 15.0727Z" fill="#111111"/>
                                <path d="M48.9074 8.01205V24.7905H45.516L44.7525 22.3114C44.1912 23.2734 43.374 24.0608 42.3919 24.586C41.4097 25.1112 40.3011 25.3537 39.1894 25.2863C34.3502 25.2863 31.2563 21.6768 31.2563 16.4211C31.2563 11.1654 34.3403 7.4964 39.0506 7.4964C40.1548 7.45106 41.251 7.70325 42.2244 8.22659C43.1978 8.74992 44.0127 9.52519 44.5839 10.4713V8.01205H48.9074ZM44.5641 16.4211C44.5641 13.5156 42.918 11.4629 40.191 11.4629C37.464 11.4629 35.7881 13.4462 35.7881 16.4211C35.7881 19.396 37.4739 21.3793 40.191 21.3793C42.908 21.3793 44.5641 19.3563 44.5641 16.4211Z" fill="#111111"/>
                                <path d="M63.4646 8.4984L61.4813 12.5938C60.8855 12.2199 60.2011 12.0112 59.498 11.9889C57.5148 11.9889 56.5231 14.3689 56.4041 16.3918V24.7909H51.9814V7.93317H55.353L56.1166 10.2238C56.4425 9.42983 56.9895 8.74609 57.6927 8.25389C58.3958 7.76169 59.2255 7.48172 60.0831 7.44727C61.2818 7.50164 62.4463 7.86363 63.4646 8.4984Z" fill="#111111"/>
                                <path d="M86.996 18.0973H87.0852V24.7908H82.5535V0H87.0852V13.8829L91.6963 7.98265H97.4478L90.784 16.0248L98.37 24.8305H92.4202L86.996 18.0973Z" fill="#111111"/>
                                <path d="M97.071 16.3916C97.071 11.5326 99.8476 7.52637 105.48 7.52637C111.113 7.52637 113.909 11.4929 112.937 17.7699H101.771C102.109 20.0606 103.427 21.7365 105.996 21.7365C107.849 21.6962 109.649 21.1022 111.162 20.0309L113.046 23.1148C111.022 24.6068 108.559 25.3814 106.045 25.3163C100.581 25.3163 97.071 21.8158 97.071 16.3916ZM101.771 15.0727H109.08C109.149 13.0894 108.286 10.888 105.54 10.888C102.793 10.888 101.771 13.2679 101.771 15.0727Z" fill="#111111"/>
                                <path d="M124.807 8.00203L121.307 19.7529L117.707 8.00203H113.135L119.085 24.8598C118.827 25.6134 117.102 30.522 116.586 32.0293H121.148L123.567 24.7904L129.269 7.93262L124.807 8.00203Z" fill="#111111"/>
                                <path d="M71.7348 7.5459C65.9139 7.5459 63.8018 10.144 63.8018 13.3569V24.7904H68.4228V12.3851H75.0667V24.7904H79.6877V13.3569C79.6877 10.144 77.5458 7.5459 71.7348 7.5459Z" fill="#111111"/>
                                <path d="M13.506 20.7351V24.8603H0V0.833008H4.45243V20.7351H13.506Z" fill="#111111"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1185_171">
                                <rect width="129.269" height="32" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <div class="details">
                            <div class="row">
                                <div class="col">
                                    <span>Įmonės kodas:</span>
                                    <span>PVM kodas:</span>
                                    <span>Adresas:</span>
                                    <span>Sąskaitos nr:</span>
                                    <span>Banko kodas:</span>
                                </div>
                                <div class="col">
                                    <span>111816244</span>
                                    <span>LT118162415</span>
                                    <span>J. Basanavičiaus g. 8A, LT-01118 Vilnius</span>
                                    <span>LT147300010078813080</span>
                                    <span>73000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-center mt-24">
                <div class="copyright">
                    <span>© {{currentYear}} Learnkey | v{{$store.state.system_settings.version}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        currentYear(){
            return new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-container{
    display:flex;
    justify-content: center;
    background-color: white;
    height: 272px;
    box-shadow: 0px -2px 6px 0 rgba(0, 0, 0, 0.16);
    background: #F4F4F4;
    width: 100%;
}
.content{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    font-family: var(--main-site-font);
    color:#000000;
    line-height: 20px;
}
.space-between{
    justify-content: space-between;
}
.row{
    display:flex;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.col{
    display:flex;
    flex-direction: column;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.links{
    display:flex;
    flex-direction: column;
    a{
        text-decoration: underline;
    }
}
.left{
    align-items: flex-end;
    width: 50%;
    padding-right: 66px;
    span{
        margin-top: 12px;
    }
    .links{
        a{
            margin-top:12px;
        }
    }
}
.right{
    width: 50%;
    padding-left: 75px;
}
.mt-24{
    margin-top: 24px;
}
.justify-center{
    justify-content: center;
}
.details{
    margin-top: 12px;
    span{
        margin-bottom: 2px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .col{
        &:nth-child(2){
            font-weight: 600;
            margin-left: 8px;
        }
    }
}
@media (max-width: 768px){
    .footer-container{
        height: 100%;
        padding: 20px;
    }
    .main-row{
        flex-direction: column;
        align-items: center;
    }
    .left{
        padding: 0;
        margin-bottom: 20px;
        align-items: flex-start;
        width: 100%;
    }
    .right{
        width: 100%;
        padding: 0;
    }
}
@media (max-width: 410px){
    .details{
        font-size: 12px;
        span{
            font-size: 12px;
        }
    }
}
</style>