<template>
    <div class="content-container">
        <div 
        class="training--content"
        :class="{'show-seperator': items.length>1}"
        v-if="$store.state.window_width > 768"
        >
            <div 
                class="content--row"
                v-for="(row_index) in rowCount"
                :key="row_index"
            >
                <div
                    v-for="(col_index) in items.length <= 1 ? 1 : col_count"
                    class="content--col"
                    :key="col_index"
                >
                    <div
                    class="content--item"
                    @click="$emit('action', items[getItemIndex(row_index, col_index)-1], getItemIndex(row_index, col_index)-1)"
                    v-if="items[getItemIndex(row_index, col_index)-1]"
                    >   
                        <span>{{ getItemIndex(row_index, col_index)}}</span> 
                        <div>
                            <p>{{ items[getItemIndex(row_index, col_index)-1].title }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="training--content" v-else>
            <div 
                class="content--row"
                v-for="(item, row_index) in items"
                :key="row_index"
            >
                <div
                class="content--item"
                @click="$emit('action', item, row_index)"
                >   
                    <span>{{row_index +1}}</span> 
                    <div>
                        <p>{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        items: {
            type: Array,
            default: () => []
        },
        text_label:{
            type: String,
            default: ''
        },
    },
    data(){
        return{
            col_count: 2,
        }
    },
    computed:{
        rowCount(){
            return (parseInt(this.items.length/this.col_count) + ((this.items.length%this.col_count) > 0 ? 1 : 0));
        },
        minItemsInColumn(){
            return parseInt(this.items.length/this.col_count);
        }
    },
    methods:{
        getItemIndex(row_index, col_index){
            if(col_index == 1){
                return row_index;
            }
            else{
                if(this.items.length%this.minItemsInColumn == 0 && this.minItemsInColumn != 1){
                    return ((col_index-1)*this.minItemsInColumn + (row_index));
                }
                else{
                    if(this.rowCount > 1)
                        return ((col_index-1)*(this.minItemsInColumn+1) + (row_index));
                    else return ((col_index-1)*(this.minItemsInColumn) + (row_index));
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.training--content {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 10px;
    &.show-seperator{
        &::after{
            content: '';
            position: absolute;
            left: 50%;
            width: 1px;
            background: #7D7D7D;
            height: 100%;
            top: 0;
        }
    }
  
  .content--item {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-height: 100%;
    font-family: var(--main-site-font);
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    div{
        display: flex;
        align-items: center;
    }
    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #333333;
        min-width: 32px;
        min-height: 32px;
        width: 32px;
        height: 32px;
        text-align: center;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        border-radius: 100%;
        margin-right: 8px;
        vertical-align: middle;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
  }
  .content--row{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    margin-bottom: 5px;
  }
  .content--col {
    display: flex;
    float: left;
    flex:1;
    padding: 0 20px;
    // max-width: 460px;
    max-height: 100%;
    &:nth-child(2){
        padding-right: 0;
    }

    &:first-child {
      border: none;
      padding-left: 0;
    }
  }
  p{
    max-height: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;
  }
    @media (max-width: 900px) {
        padding: 0 8vw;
    }
  @media (max-width: 768px) {
    margin-top: 25px;
    font-size: 17px;
    padding: 0;
    &::after{
        display: none;
    }

    .content--col {
      width: 80%;
      float: none;
      margin: auto;
      border-left: 0;
      padding: 0;
    }
  }
}

</style>