import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faUserClock,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteLeft,
  faUndo,
  faRedo,
  faSpinner,
  faHighlighter,
  faSquareRootAlt,
  faSubscript,
  faSuperscript,
  faImage,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueScrollTo from "vue-scrollto";
import $ from "jquery";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import AudioVisual from "vue-audio-visual";
import VueLazyload from "vue-lazyload";
import VScrollLock from "v-scroll-lock";
import Vuelidate from "vuelidate";
import VTooltip from "v-tooltip";
import Paginate from "vuejs-paginate";
import VueSkeletonLoader from "skeleton-loader-vue";
import "vue-select/dist/vue-select.css";
import VueSanitize from "vue-sanitize";
import VueMask from "v-mask";
import "./index.css";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag";
import PortalVue from 'portal-vue'
import IdleVue from 'idle-vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import TextareaAutosize from 'vue-textarea-autosize'
import {checkContrast, colorsMeetsMinimumRequirements} from './tools/contrast'
Vue.use(TextareaAutosize)
Vue.use(FloatingVue)
Vue.use(PortalVue)
Vue.use(VueGtag, {
  config: { id: "G-VKTZHMJ10S" },
  includes: [{ id: "G-7XVFT07C3D" }],
  bootstrap: false,
});
Vue.use(VueCookies);
Vue.use(VueMask);
let defaultOptions = {
  allowedTags: [
    "address",
    "article",
    "aside",
    "footer",
    "header",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hgroup",
    "main",
    "nav",
    "section",
    "blockquote",
    "dd",
    "div",
    "dl",
    "dt",
    "figcaption",
    "figure",
    "hr",
    "li",
    "main",
    "ol",
    "p",
    "pre",
    "ul",
    "a",
    "abbr",
    "b",
    "bdi",
    "bdo",
    "br",
    "cite",
    "code",
    "data",
    "dfn",
    "em",
    "i",
    "kbd",
    "mark",
    "q",
    "rb",
    "rp",
    "rt",
    "rtc",
    "ruby",
    "s",
    "samp",
    "small",
    "span",
    "strong",
    "sub",
    "sup",
    "time",
    "u",
    "var",
    "wbr",
    "caption",
    "col",
    "colgroup",
    "table",
    "tbody",
    "td",
    "tfoot",
    "th",
    "thead",
    "tr",
  ],
  allowedAttributes: {
    a: ["href", "name", "target"],
  },
};
Vue.use(VueSanitize, defaultOptions);

Vue.component("vue-skeleton-loader", VueSkeletonLoader);
Vue.component("paginate", Paginate);
Vue.use(Vuelidate);
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? "hover focus click" : "click",
});
Vue.use(VScrollLock);

// name is optional
Vue.use(VueLodash, { name: "custom", lodash: lodash });
window.$ = $;


library.add(
  faUser,
  faUserClock,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteLeft,
  faUndo,
  faRedo,
  faSpinner,
  faHighlighter,
  faSquareRootAlt,
  faSubscript,
  faSuperscript,
  faImage,
  faSignature
);
// library.add(faUserClock)
// library.add(faBold)
// library.add(faItalic)
// library.add(faStrikethrough)
// library.add(faUnderline)
// library.add(faCode)
// library.add(faParagraph)
// library.add(faListUl)
// library.add(faListOl)
// library.add(faQuoteLeft)
// library.add(faUndo)
// library.add(faRedo)
// library.add(faSpinner)
// library.add(faHighlighter)
// library.add(faSquareRootAlt)
// library.add(faSubscript)
// library.add(faSuperscript)

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueLazyload, {
  lazyComponent: true,
  preLoad: 2.3,
});
Vue.use(AudioVisual);
Vue.use(VueScrollTo);
Vue.use(Croppa);

Vue.config.productionTip = true;

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
Vue.directive("click-outside-2", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("mousedown", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("mousedown", el.clickOutsideEvent);
  },
});
Vue.directive("click-outside-3", {
  bind: function (el, binding, vnode) {
    setTimeout(() => {
      el.clickOutsideEvent = function (event) {
        if (
          !(
            el == event.target ||
            el.contains(event.target) ||
            el.parentElement.isSameNode(event.target.parentElement) ||
            el.parentElement.contains(event.target)
          )
        ) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener("mouseup", el.clickOutsideEvent);
    }, 50);
  },
  unbind: function (el) {
    document.body.removeEventListener("mouseup", el.clickOutsideEvent);
  },
});
Vue.directive("click-outside-params", {
  bind(el, binding, vnode) {
    const handleClickOutside = (event) => {
      if (!el.contains(event.target)) {
        const additionalParams = binding.value;
        const methodName = binding.arg;

        // Arrow function maintains the correct scope of `this`
        vnode.context[methodName](event, additionalParams);
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    el._clickOutsideParams = handleClickOutside;
  },
  unbind(el) {
    document.removeEventListener("mouseup", el._clickOutsideParams);
    delete el._clickOutsideParams;
  },
});
Vue.prototype.$callMethod = function (methodName, ...argsFromTemplate) {
  return (...argsFromDirective) =>
    this[methodName].call(this, ...argsFromTemplate, ...argsFromDirective);
};
Vue.mixin({
  computed: {
    customSysStyle() {
      return store.state.system_settings ? store.state.system_settings.custom_style : {};
    },
    systemSettings() {
      return store.state.system_settings ? store.state.system_settings : {};
    }
  },
  methods: {
    getFileUrl: function (url) {
      if (url == null || url == "") return "";
      if (url.includes(store.state.api_uploads_link)) {
        return url;
      } else if (url.includes("http") || url.includes("https")) {
        return url;
      }
      else {
        if (url.split('/').length == 1){
          if(store.state.isShopTraining){
            let protocol = store.state.ssl ? 'https://' : 'http://';

            return (
              protocol + process.env.VUE_APP_PARENT_LMS_API + "/uploads/" + url
            );
          } else {
            return store.state.api_uploads_link + url;
          }
        } else return url;
      }
    },
    checkColorContrast: checkContrast,
    colorsMeetsMinimumRequirements: colorsMeetsMinimumRequirements,
    isMobileWindow: function () {
      return this.$store.state.window_width <= 768;
    },
    isMobileDevice: function () {
      if (
        /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    canViewStats() {
      return ["admin", "super"].includes(store.state.user_role);
    },
    findRecursiveTestVersion(id, array, key) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          return array[i];
        } else if (array[i][key] != undefined && array[i][key].length > 0) {
          let found = this.findRecursiveTestVersion(id, array[i][key], key);
          return found;
        }
      }
      return undefined;
    },
    scrollNameHorizontal(e, isHovering) {
      $(e.target).stop();
      $(e.target).css("text-overflow", "initial");
      if (isHovering) {
        let hover_width = e.target.scrollWidth;
        let time_to_scroll = (hover_width / 200) * 1000;
        $(e.target).animate({ scrollLeft: e.target.scrollWidth }, time_to_scroll);
      } else
        $(e.target).animate({ scrollLeft: 0 }, 300, function () {
          $(this).css("text-overflow", "ellipsis");
        });
    },
    toLocal: function (queryString) {
      return store.getters.__(queryString);
    },
    getContrast: function (color_type = 'primary_color'){
      return colorsMeetsMinimumRequirements('#FFFFFF', store.state.system_settings[color_type]) ? 'whiteContrast' : 'blackContrast'
    },
    hasPermission: function (permission_key) {
      if (permission_key == "permissions.view_shop") {
        if (
          store.state.user_role == "user" &&
          store.state.system_settings.shop_to_users == 1
        ) {
          return true;
        }
        if (
          store.state.user_role == "admin" &&
          store.state.system_settings.shop_to_admins == 1
        ) {
          return true;
        } else {
          return store.state.user_permissions[permission_key] ? true : false;
        }
      } else {
        if(store.state.user_role == "admin"){
          return true;
        }
        else {
          return store.state.user_permissions[permission_key] ? true : false;
        }
      }
    },
    hex_is_light(color) {
      const hex = color.replace("#", "");
      const c_r = parseInt(hex.substring(0, 0 + 2), 16);
      const c_g = parseInt(hex.substring(2, 2 + 2), 16);
      const c_b = parseInt(hex.substring(4, 4 + 2), 16);
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
      return brightness > 155;
    },
  },
});
if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://1331aa83d48c4eec9c6b5b218ae1f2a8@o1156874.ingest.sentry.io/6238609",

    tracesSampleRate: 1.0,
  });
}
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1800000,
});
router.beforeEach((to, from, next) => {
  const history = JSON.parse(localStorage.getItem('routeHistory')) || []
  store.state.routeHistory.push(to.path)

  next()
})
store.dispatch('fetchAppSettings')
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
  .catch(error => {
    console.error('Failed to fetch settings:', error);
  });
