<template>
    <div class="login-wrapper">
        <span class="info-label">
            {{toLocal('login.login_to_your_account')}}
        </span>
        <button @click="loginAzure">
            <span>
                {{toLocal('login.azure_login_label')}}
            </span>
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.183058 11.4419C-0.0610194 11.1979 -0.0610195 10.8021 0.183058 10.5581L4.74112 6L0.183058 1.44194C-0.0610199 1.19786 -0.0610199 0.802136 0.183058 0.558059C0.427135 0.313981 0.822864 0.313981 1.06694 0.558059L6.06694 5.55806C6.31102 5.80214 6.31102 6.19786 6.06694 6.44194L1.06694 11.4419C0.822864 11.686 0.427136 11.686 0.183058 11.4419Z" fill="white"/>
            </svg>
        </button>
        <div class="error" v-if="error && error.length > 0">
            <span>
                {{ error }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            error: ''
        }
    },
    mounted() {
        if(this.$route.query.error && this.$route.query.error=='not_found' && this.$route.query.login_type == 'msazure') {
            if (this.$route.query.email) {
                this.error = this.toLocal('errors.user_mail_not_found').replace('[mail]', this.$route.query.email);
            }
            else {
                this.error = this.toLocal('errors.user_not_found')
            }
        }
    },
    methods: {
        loginAzure() {
            window.location.href = `${this.$store.state.api_link}/auth/azure/redirect`;
        }
    }
}
</script>

<style lang="scss" scoped>
.login-wrapper{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 8px;
    color: #333333;
}
.info-label{
    font-weight: 600;
}
button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333333;
    border: none;
    border-radius: 20px;
    color: white;
    padding: 6px 20px;
    font-size: 16px;
    line-height: 24px;
    width: 50%;
    min-width: 200px;
    cursor: pointer;
    box-shadow:  0 2px 3px 0 rgba(0, 0, 0, 0.16);
    transition: background-color 0.25s;
    &:hover{
        background-color: #7D7D7D;
    }
}
.error{
    color: red;
}
</style>