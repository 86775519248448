<template>
    <div class="modal-window" :class="[modalInfo.class ? modalInfo.class : '']" v-scroll-lock="true">
      <GlobalModal :modalInfo="{
        type:'multiChoice',
        text: 'users.role_delete_sure',
        undertext: 'users.role_delete_undertext',
        multiChoiceYes: 'training_edit.answer_yes',
        multiChoiceNo: 'training_edit.answer_no',

      }" v-if="deletingRole != -1" @onClose="deletingRole = -1" @onChoiceOne ="deleteRole" @onChoiceTwo="deletingRole = -1"/>
      <div class="modal-box" :style="modalInfo.style ? modalInfo.style : false">
        <div class="modal-close" @click="closeModal()"><CloseCrossSvg /></div>
        <div class="left-half">
          <div class="left-content">
            <div class="banner">
              <span>{{toLocal('users.roles_existing')}}</span>
            </div>
              <div class="all-roles">
                <div 
                  class="role"  
                  v-for="(role, index) in allRoles" 
                  :class="[{active: currentlyEditing == role.id} , {'disabled': role.id == 1 || role.id == 2}]" 
                  :key="'role_'+index+'_'+role.name"
                  :ref="'role_'+role.id"
                  @click="editRole(role)"
                >
                  <input 
                    type="text" 
                    @focusout="closeEditMode($event, role)" 
                    v-if="renamingMode && currentlyEditing == role.id" 
                    v-model="roleName"
                    :ref="'input_'+role.id"
                  />
                  <span class="name" v-else>{{role.name}}</span>
                  <div class="pencil icon" v-if="(!renamingMode || currentlyEditing != role.id) && role.id != 1 && role.id != 2" @click.stop="renameRole(role)">
                    <PencilSvg/>
                  </div>
                  <div class="delete icon" v-if="role.id != 1 && role.id != 2" @click="deletingRole = role.id">
                    <TrashSvg/>
                  </div>
                </div>
              </div>
                <div class="add-new-btn" @click="switchToNew()">
                  <font-awesome-icon icon="spinner" v-if="isCreating" />
                  {{ toLocal('users.roles_create') }}
                </div>
          </div>
        </div>
        <div class="right-half">
          <div class="banner" v-show="currentlyEditing!=-1">
            {{ toLocal('users.role_permissions') }}
          </div>
            <!-- <div class="add-new">
                <span class="add-new-title" >
                    <span v-if="currentlyEditing != -1" @click="switchToNew()">Nauja rolė</span>
                    <span class="delete" v-if="currentlyEditing != -1" @click="deleteRole()">Ištrinti rolę</span>
                </span>
                <input class="add-new-input" placeholder="pavadinimas" v-model="roleName"/>
            </div> -->
            <div class="permissions" v-show="currentlyEditing!=-1">
                  <form ref="checkboxForm" class="perm-container" >
                    <div 
                      class="permission-container" 
                      v-for="permission, index in Object.keys(allPermissions)" 
                      :key="'permission-'+index"
                      :class="{'not-all-selected': getNotAllSelected(permission)}"
                    >
                      <div class="single-input grandparent" @click="togglePermission(allPermissions[permission], null, 0)" :class="[{'active': addedPermissions.includes(parseInt(permission))}]"> 
                        <div 
                          class="checkbox grandparent-box" 
                          :class="{marked: addedPermissions.includes(parseInt(permission))}"
                        >
                          <CheckboxMarkedSvg v-if="addedPermissions.includes(parseInt(permission))"/>
                        </div>
                        <span>
                          {{toLocal(allPermissions[permission].locale_key)}}
                        </span>
                      </div>
                      <div class="children">
                        <div class="permission-container ml-32"
                          v-for="child, c_index in allPermissions[permission].children"
                          :key="'child-'+c_index"
                          :class="{'not-all-selected-parent': getNotAllSelectedChildren(child)}"
                        >
                            <div 
                              class="single-input parent" 
                              @click="togglePermission(child, child.parent_id, 1)"
                            >
                              <div 
                                class="checkbox parent-box" 
                                :class="{marked: addedPermissions.includes(parseInt(child.id))}"
                              >
                                <CheckboxMarkedSvg v-if="addedPermissions.includes(parseInt(child.id))"/>
                              </div>
                              <span>
                                {{toLocal(child.locale_key)}}
                              </span>
                            </div>
                            <div class="children">
                              <div 
                                class="single-input ml-32" 
                                v-for="grandChild, g_index in child.children"
                                :key="'gchild-'+g_index"
                                @click="togglePermission(grandChild, child.id, 2)"
                              >
                                <div 
                                  class="checkbox" 
                                  :class="{marked: addedPermissions.includes(child.id+'-'+grandChild.id)}"
                                >
                                  <CheckboxMarkedSvg v-if="addedPermissions.includes(child.id+'-'+grandChild.id)"/>
                                </div>
                                <span>
                                  {{toLocal(grandChild.label)}}
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
            </div>
            <div class="new-btn-container" v-if="currentlyEditing != -1">
              <button  class="add-new-btn" @click="submitData()">
                {{currentlyEditing == -1 ? toLocal('hierarchy.add') : isSaving ? '' : toLocal('generic.save')}}
                <font-awesome-icon icon="spinner" v-if="isSaving" />
              </button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
  import UploadComputerSvg from "@/assets/images/icons/training-item-upload.svg?inline";
  import UploadPhoneSvg from "@/assets/images/icons/upload_phone-black.svg?inline";
  import CheckboxMarkedSvg from "@/assets/images/icons/users/users-checkbox-marked.svg?inline";
  import PencilSvg from "@/assets/images/icons/users/users-role-pencil.svg?inline";
  import TrashSvg from "@/assets/images/icons/users/users-role-trash.svg?inline";
  import ToggleButton from "@/components/layout/emails/ToggleButton";
  import SelectInput from "@/components/layout/emails/SelectInput";
  import InputWithSearch from "@/components/layout/InputWithSearch";
  import GlobalModal from "./GlobalModal.vue"
  import DatePicker from "vue2-datepicker";
  import QrcodeVue from "qrcode.vue";
  import axios from "axios";
  
  export default {
    name: "NewRoleModal",
    data: function () {
      return {
        roleName: '',
        mode: 'new',
        currentlyEditing: -1,
        addedPermissions: [],
        checkboxesStatus: {},
        allPermissions: {},
        renamingMode: false,
        deletingRole: -1,
        isSaving: false,
        isCreating: false,
        allChildrenSelected: []
      };
    },
    components: {
      DatePicker,
      CloseCrossSvg,
      ToggleButton,
      SelectInput,
      UploadComputerSvg,
      UploadPhoneSvg,
      QrcodeVue,
      InputWithSearch,
      CheckboxMarkedSvg,
      PencilSvg,
      TrashSvg,
      GlobalModal,
    },
    props: {
      modalInfo: Object,
      permissionList: Object,
      allRoles: Array,
    },
    methods: {
      closeEditMode(e, role){
        if (this.renamingMode) {
          role.name = this.roleName;
            this.isSaving = true;
          axios.post(
              `${this.$store.state.api_link}/user/roles/new`,
                  {name:this.roleName,
                  permissions: this.addedPermissions,
                  mode: 'editing',
                  currentRole: role.id},
              this.$store.getters.axiosConfig()
          ).then((response) => {
              let changedRole = this.allRoles.findIndex(el=>el.id == role.id);
              this.$set(this.allRoles,changedRole, response.data.role[0]);
              this.roleName = '';
              this.renamingMode = false;
              this.isSaving = false;
              if (response.data.translation) {
                this.$store.state.translations.push(response.data.translation);
              }
          })
          .catch(() => {
              this.isSaving = false;
            
              console.log("Error while saving role info");
          });
        }
      },
      renameRole(role){
        this.renamingMode = true;
        this.roleName = role.name;
        this.currentlyEditing = role.id;
        this.$nextTick(()=>{
            let input = this.$refs['input_'+role.id];
            if(input){
              if(Array.isArray(input)){
                input[0].select();
              }
              else{
                input.select();
              }
            }
        })
      },
      closeModal() {
        this.$emit("onClose");
      },
      confirmModal() {
        this.$emit("onConfirm");
        },
        submitData(){
            if(this.isSaving) return;
            this.isSaving = true;
            let currentRole = this.allRoles.findIndex(el=>el.id == this.currentlyEditing);
              axios.post(
                  `${this.$store.state.api_link}/user/roles/new`,
                      {name:  currentRole.name,
                      permissions: this.addedPermissions,
                      mode: 'editing',
                      currentRole: this.currentlyEditing,
                      allChildrenSelected: this.allChildrenSelected
                  },
                  this.$store.getters.axiosConfig()
              ).then((response) => {
                if(response.data.status == 'success'){
                  let changedRole = this.allRoles.findIndex(el=>el.id == this.currentlyEditing);
                  this.$set(this.allRoles, changedRole, response.data.role[0]);
                }
                if (response.data.translation) {
                  this.$store.state.translations.push(response.data.translation);
                }
                this.isSaving=false;
              })
              .catch(() => {
                  console.log("Error while saving role info");
                  this.isSaving=false;
              });
        },
        editRole(role){
          if(['admin','user'].includes(role.name)){
            return;
          }
          if(this.renamingMode == true && this.currentlyEditing != role.id){
            this.renamingMode = false;
          }
          this.$refs.checkboxForm.reset();
          this.currentlyEditing = role.id;
          this.mode = 'editing';
          this.roleName = role.name;
          this.addedPermissions = [];
          Object.keys(this.allPermissions).forEach((key)=>{
            this.allPermissions[key].value = false;
          })
          role.permissions.forEach((permission)=>{
            if (permission.pivot && permission.pivot.data) {
              let data = JSON.parse(permission.pivot.data);
              data.forEach(element => {
                if (permission.id != null)
                  this.addedPermissions.push(permission.id+'-'+element);
              });
            }
            if (permission.id != null)
              this.addedPermissions.push(permission.id);
          })
          
        },
        deleteRole(){
          axios.post(
              `${this.$store.state.api_link}/user/roles/delete`,
                  {currentRole: this.currentlyEditing},
              this.$store.getters.axiosConfig()
          ).then((response) => {
              let changedRole = this.allRoles.map((el)=>{return el.id}).indexOf(this.currentlyEditing);
              this.allRoles.splice(changedRole, 1);
              this.currentlyEditing=-1
              this.deletingRole = -1;
              this.roleName = '';
          })
          .catch((e) => {
              console.log(e);
          });
        },
        switchToNew() {
          this.isCreating = true;
          axios.post(
              `${this.$store.state.api_link}/user/roles/new`,
                  {name:'',
                  permissions: [],
                  mode: 'new',},
              this.$store.getters.axiosConfig()
          ).then((response) => {
              this.allRoles.push(response.data.role[0]);
              this.currentlyEditing = response.data.role[0].id;
              this.addedPermissions = [];
              this.renameRole(response.data.role[0]);
              this.isCreating = false;
              if (response.data.translation) {
                this.$store.state.translations.push(response.data.translation);
              }
          })
          .catch((e) => {
              console.log(e);
              this.isCreating = false;

          });
        },
        togglePermission(permission, parent_id = null, level=0) {
          let id = level == 2 ? parent_id+'-'+permission.id : permission.id;
            if (!this.addedPermissions.includes(id)) {
                this.addPermission(permission, parent_id, level);
              } else {
                this.removePermission(permission, parent_id, level)
            }
          
          this.addedPermissions = this.addedPermissions.filter(this.onlyUnique);
        },
        addPermission(permission,parent_id, level) {
          const addChildren = (permission, parent_id, level) => {
            if (permission.children && permission.children.length > 0) {
              for (let i = 0; i < permission.children.length; i++) {
                let id = level == 2 ? permission.id + '-' + permission.children[i].id : permission.children[i].id;
                if (!this.addedPermissions.includes(id) && id != null) {
                  this.addedPermissions.push(id);
                  addChildren(permission.children[i], permission.id, level+1);
                }
              }
            }
            return;
          };
          if(permission.children){
            addChildren(permission, parent_id, level+1);
          }
          if (level == 2) {
            this.addedPermissions.push(parent_id + '-' + permission.id);
            if(parent_id != null && !this.addedPermissions.includes(parent_id)){
              this.addedPermissions.push(parent_id);
              let index = Object.values(this.allPermissions).findIndex(el => el.children_ids.includes(parent_id));
              if (index != -1 && !this.addedPermissions.includes(Object.values(this.allPermissions)[index])) {
                this.addedPermissions.push(Object.values(this.allPermissions)[index].id);
              }
            }
          }
          else {
              this.addedPermissions.push(permission.id);
              if(!this.addedPermissions.includes(permission.parent_id)){
                this.addedPermissions.push(permission.parent_id);
              }
          }
        },
        removePermission(permission,parent_id, level) {
          const removeChildren = (permission, parent_id, level) => {
            if (permission.children && permission.children.length > 0) {
              for (let i = 0; i < permission.children.length; i++) {
                  let id = level == 2 ? permission.id + '-' + permission.children[i].id : permission.children[i].id;
                if (this.addedPermissions.includes(id) && id != null) {
                    let index = this.addedPermissions.findIndex(el => el == id);
                  if(index != -1)
                    this.addedPermissions.splice(index, 1);
                  removeChildren(permission.children[i], permission.id, level+1);
                }
              }
            }
            return
          };
          if(permission.children){
            removeChildren(permission,parent_id, level+1);
          }
          if (level == 2) {
            this.addedPermissions.splice(this.addedPermissions.indexOf(parent_id + '-' + permission.id), 1)
            if(parent_id != null && this.addedPermissions.filter(el=> typeof el == 'string' && el.startsWith(parent_id)).length == 0){
              this.addedPermissions.splice(this.addedPermissions.indexOf(parent_id), 1)
            }
          }
          else {
            if(permission.id != null)
              this.addedPermissions.splice(this.addedPermissions.indexOf(permission.id), 1)
          }
        },
        onlyUnique(value, index, array) {
          return array.indexOf(value) === index;
        },
        getAllChildrenIds(permission) {
          if (permission.children && permission.children.length > 0) {
            let ids = [];
            for (let i = 0; i < permission.children.length; i++) {
              if (permission.children[i].parent_id) {
                ids.push(permission.children[i].id);
              }
              else {
                ids.push(permission.id+'-'+permission.children[i].id);
              }
              if (permission.children[i].children) {
                ids = ids.concat(this.getAllChildrenIds(permission.children[i]));
              }
            }
            return ids;
          } else {
            return [];
          }
        },
        includesAllElements(arr1, arr2) {
          return arr2.every(element => arr1.includes(element));
        },
        getNotAllSelected(permission, level=1) {
            if (this.allPermissions[permission].children_ids && this.allPermissions[permission].children_ids.length > 0) {
              let includes_all = this.includesAllElements(this.addedPermissions, this.allPermissions[permission].children_ids);
              let index = this.allChildrenSelected.findIndex(el => el == permission);
              if (includes_all) {
                  if(index == -1){
                    this.allChildrenSelected.push(permission);
                  }
              }
              else {
                if(index != -1){
                    this.allChildrenSelected.splice(index, 1);
                  }
              }
              return !includes_all;
            }
            else {
              return false;
            }
        },
        getNotAllSelectedChildren(child) {
            if (child.children && child.children.length > 0) {
              let includes_all = this.includesAllElements(this.addedPermissions, child.children.map(el => child.id + '-' + el.id));
              let index = this.allChildrenSelected.findIndex(el => el == child.id);
              if (includes_all) {
                if (index == -1) {
                  this.allChildrenSelected.push(child.id);
                }
              }
              else {
                if (index != -1) {
                  this.allChildrenSelected.splice(index, 1);
                }
              }
              return !includes_all;
            }
            else {
              return false;
            }
        }

    },
    mounted(){
      this.allPermissions = this.permissionList;
      for (let key in this.allPermissions) {
        this.allPermissions[key].children_ids = this.getAllChildrenIds(this.allPermissions[key]);
      }
      
    }
  } 
  </script>
  
  <style lang="scss" scoped>
  .modal-window {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1999;
    background: #1a19196b;
  }
  .danger{
    .modal-confirmation{
      background: #D45454;
    }
  }
  .modal-box {
    width: 900px;
    height: 600px;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    text-align: center;
    position: relative;
    user-select: none;
    padding: 24px 0;
    padding-left: 24px;
    padding-right: 40px;
  }
  .modal-text {
    width: 62%;
    font-size: 24px;
    margin-top: 24px;
  }
  .modal-undertext {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .modal-big-undertext{
    font-size: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .modal-confirmation {
    background: var(--secondary-color);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    border-radius: 38px;
    padding: 5px;
    width: 60%;
    cursor: pointer;
    margin-top: 30px;
    &:hover {
      background-color: var(--primary-color);
    }
  }
  .button-yellow {
    background: #f4dc00;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    padding: 8px 20px;
    transition: 0.25s;
    font-size: 16px;
    margin-top: 24px;
    cursor: pointer;
    &:hover {
      background: #333333;
      color: white;
    }
  }
  .button-gray {
    background: #f4f4f4;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    font-size: 16px;
    padding: 8px 20px;
    transition: 0.25s;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      background: #333333;
      color: white;
    }
  }
  .greyed {
    color: black;
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      cursor: not-allowed;
      background: #f4dc00;
      color: black;
    }
  }
  .saveOption {
    width: 80%;
  }
  .ml-32{
    margin-left: 32px;
  }
  .under-button-text {
    margin-top: 12px;
    font-size: 14px;
    width: 80%;
    color: #7d7d7d;
  }
  .modal-special-switch {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 45%;
  }
  .third-color{
    .modal-confirmation{
      background: var(--third-color);
    }
  }
  .publish{
    .modal-box{
      padding: 20px;
    }
    .modal-confirmation{
      &:hover{
        color: white;
      }
    }
  }
  .delete{
    .modal-box{
      padding: 20px;
    }
    .modal-confirmation{
      &:hover{
        color: white;
      }
    }
  }
  .banner{
    background-color: #4D4D4D;
    color: white;
    border-radius: 12px 12px 0px 0px; 
    padding: 12px 44px;
    font-size: 16px;
    height: 8%;
  }
  .left-half{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .left-content{
      border-right: 1px solid rgba(77, 77, 77, 1);
      height: 100%;
      width: 100%;
      padding-right:24px;
    }
    .roles-title{
        height: 10%;
        display: none;
    }
  }
  
.new-btn-container{
  height: 10%;
  width: 112%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: -24px;
  .add-new-btn{
            background: #f3de18;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
            border-radius: 44px;
            margin: 0 auto;
            width: 35%;
            margin-top: 16px;
            font-size: clamp(14px, 1vw, 16px);
            padding: 8px 30px;
            box-sizing: border-box;
            transition: 0.25s;
            cursor: pointer;
            border: none;
            @media (max-width: 987px) {
                width: 50%;
            }
            &:hover {
                background: #333333;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
                border-radius: 40px;
                color: #ffffff;
            }
        }
}
  .all-roles{
    display: flex;
    flex-direction: column;
    height: 85%;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    .role{
        cursor: pointer;
        display: flex;
        position: relative;
        width: 100%;
        transition: 0.25s;
        word-wrap: break-word;
        text-align: start;
        padding: 6px 12px;
        font-size: 16px;
        &:hover{
          &:not(.disabled){
            background: #F3DE18; 
          }
        }
        &.active{
          &:not(.disabled){
            background: #F3DE18; 
            font-weight: bold;
          }
        }
        &.disabled{
          color: #7D7D7D;
          cursor: default;
        }
        .icon{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          transition: 0.25s;
          &:hover{
            svg{
              stroke: rgba(51, 51, 51, 1);
            }
          }
        }
        .pencil{
          right: 30px;
          top: 1px;
        }
        .delete{
          right: 10px;
        }
        input{
          width: 80%;
          height: 100%;
          border: none;
          background: transparent;
          font-size: 16px;
        }
    }
    background-color: #fcfafa;
  }
  .add-new-btn{
    width: 100%;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 6px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.25s;
    &:hover{
      background: #333;
      color: white;
    }
  }
  .right-half{
    width: 65%;
    height: 100%;
    display: flex;
    margin-left: 24px;
    flex-direction: column;
    .add-new{
        width: 100%;
        height: 30%;
        background-color: #fcfafa;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .add-new-title{
            width: 100%;
            height: 30%;
            display: flex;
            gap: 16px;
            justify-content: flex-start;
            span{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 40px;
                text-align: center;
                font-size: clamp(14px, 1vw, 16px);
                padding: 8px 30px;
                max-height: 40px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                background: #f3de18;
                cursor: pointer;
                transition: 0.25s;
                &:hover {
                background: #333333;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
                border-radius: 40px;
                color: #ffffff;
                }
                &.delete{
                  background: #D45454;
                  &:hover{
                    background: #333333;
                  }
                }
            }
        }
        .add-new-input{
            margin: 32px 0;
            width: 80%;
            padding: 12px 16px;
            border-radius: 13px;
            font-size: 16px;
            border: 1px solid #3333339a;
        }

    }
    .permissions{
        width: 100%;
        flex-grow: 1;
        height: 80%;
        max-height: 80%;
        background-color: #fcfafa;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        .permission-container{
          display: flex;
          flex-direction: column;
          &.not-all-selected{
            .grandparent.active{
              .grandparent-box{
                background: #E2E2E2;
                &.marked{
                  background: #E2E2E2;
                }
              }
            }
          }
          &.not-all-selected-parent{
             .parent{
              .parent-box{
                background: #E2E2E2;
                &.marked{
                  background: #E2E2E2;
                }
              }
            }
          }
        }
        .children{
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 16px;
        }
        .perm-container{
            width: 100%;
            height: 80%;
            flex-grow: 1;
            padding: 8px;
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            overflow-y: auto;
            flex-direction: column;
            gap: 32px;
            .single-input{
                width: 100%;
                height: 24px;
                display: flex;
                align-items: center;
                font-size: 16px;
                border-radius: 13px;
                cursor: pointer;
                gap: 8px;
                .checkbox{
                  position: relative;
                  width: 24px;
                  height: 24px;
                  border-radius: 4px;
                  background: #E2E2E2;
                  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
                  &.marked{
                    background-color: #f3de18;
                  }
                  svg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                  }
                }
                &.active{
                  .checkbox{
                    background-color: #f3de18;
                  }
                }
            }
        }
    }
  }
  </style>
  <style lang="scss">
  </style>