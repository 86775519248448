<template>
  <div class="table-view" ref="mainTable" @scroll="onScroll">
    <table >
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            v-show="showColumn(header)"
            :class="header.class ? header.cell_class ? [...header.class, ...header.cell_class, getContrast()] : [...header.class, getContrast()] : [getContrast()]"
          >
            <div class="header-cell">
                    <div class="header-label">
                        {{toLocal(header.label)}}
                    </div>
                    <div class="tools-container">
                        <div 
                            v-if="header.filter"
                            class="filter" 
                            :class="{active: selectedFilters[header.id] && selectedFilters[header.id].length > 0}"
                        >
                              <CustomDropdown
                                :list="getFilterValues(header)"
                                :compare-list="selectedFilters[header.id] != undefined ? selectedFilters[header.id] : []"
                                key-label="id"
                                value-label="value"
                                :options="getFilterOptions(header)"
                                @elAll="clickedAllOption(header)"
                                @elClicked="(id) => selectDropdownOption(header.filter_type, 'id', id, header)"
                                @selectedElement="(type, key,value) => selectDropdownOption(type, key,value, header)"
                            />
                        </div>
                      
                        <svg v-if="showSort(header)" @click="setSort(header)" class="sort-icon" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortBy==header.value && sortDirection=='desc'}" d="M6.60243 19.7188C6.28496 20.0794 5.75871 20.0974 5.42453 19.7549C5.41617 19.7459 5.39943 19.7278 5.39108 19.7188L0.228004 13.8231C-0.089464 13.4625 -0.0727225 12.8946 0.261454 12.552C0.420188 12.3988 0.620747 12.3086 0.837962 12.3086H11.164C11.6235 12.3086 11.9995 12.7143 11.9995 13.2101C11.9995 13.4445 11.9159 13.6609 11.7739 13.8322L6.60243 19.7188Z" fill="#E2E2E2"/>
                            <path :class="{active: sortBy==header.value && sortDirection=='asc'}" d="M5.39757 0.281221C5.71504 -0.0793729 6.2413 -0.0974378 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549251 7.28574 0.000549293 6.78992C0.000549313 6.55553 0.0841098 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="#E2E2E2"/>
                        </svg>
                    </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="(training, index) in trainings"
          :key="index"
          :class="{deactivated: !training.published}"
          >
          <td
            v-for="(header, h_index) in headers"
            :key="h_index"
            v-show="showColumn(header)"
            :class="header.cell_class ? header.cell_class : []"
          >
            
            <div class="tools-container" v-if="header.value == 'settings'">
                <button v-for="tool in header.tools" :key="tool.id">
                    <svg @click="openTraining(training.training_id, training)" v-if="tool.icon=='eye'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#F4F4F4"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03162 15.9974C8.44924 19.6052 11.9426 21.8045 15.7521 21.8045C19.5608 21.8045 23.0465 19.6059 25.472 15.9967C23.0544 12.3894 19.5613 10.1904 15.7521 10.1904C11.9426 10.1904 8.44924 12.3896 6.03162 15.9974ZM4.39433 15.5465C7.04192 11.3138 11.1234 8.53125 15.7521 8.53125C20.3807 8.53125 24.4622 11.3138 27.1098 15.5465C27.2816 15.8213 27.2814 16.175 27.1091 16.4494C24.4533 20.6804 20.3814 23.4636 15.7521 23.4636C11.1234 23.4636 7.04192 20.681 4.39433 16.4483C4.22272 16.174 4.22272 15.8209 4.39433 15.5465Z" fill="#4D4D4D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7532 13.5087C14.4255 13.5087 13.3491 14.6229 13.3491 15.9974C13.3491 17.3719 14.4255 18.4862 15.7532 18.4862C17.081 18.4862 18.1574 17.3719 18.1574 15.9974C18.1574 14.6229 17.081 13.5087 15.7532 13.5087ZM11.7464 15.9974C11.7464 13.7066 13.5403 11.8496 15.7532 11.8496C17.9662 11.8496 19.7601 13.7066 19.7601 15.9974C19.7601 18.2882 17.9662 20.1453 15.7532 20.1453C13.5403 20.1453 11.7464 18.2882 11.7464 15.9974Z" fill="#4D4D4D"/>
                    </svg>
                    <svg @click="editTraining(training.training_id)" v-if="tool.icon=='pen' && hasPermission('trainings.edit')" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#F4F4F4"/>
                        <path d="M7.30418 25.576L12.0194 24.52H12.0589C12.2803 24.5197 12.4994 24.4758 12.7037 24.3907C12.9081 24.3057 13.0936 24.1812 13.2497 24.0243L26.1639 11.1197C26.483 10.7994 26.6621 10.3659 26.6621 9.91396C26.6621 9.46202 26.483 9.02848 26.1639 8.70819L23.2911 5.82989C22.9706 5.51104 22.5367 5.33203 22.0844 5.33203C21.6322 5.33203 21.1983 5.51104 20.8778 5.82989L7.95588 18.7422C7.79759 18.9008 7.67329 19.09 7.59056 19.2982C7.50782 19.5064 7.46837 19.7293 7.47463 19.9532L6.41304 24.6861C6.39134 24.7923 6.39134 24.9018 6.41304 25.0079C6.43385 25.1044 6.47366 25.1958 6.53016 25.2767C6.58666 25.3577 6.65872 25.4266 6.74213 25.4794C6.86235 25.5554 7.00149 25.5963 7.14377 25.5973C7.19859 25.5983 7.25332 25.5922 7.30654 25.579M21.6262 6.57834C21.7478 6.45743 21.9123 6.38954 22.0838 6.38954C22.2554 6.38954 22.4199 6.45743 22.5415 6.57834L25.4149 9.45605C25.4758 9.5152 25.5242 9.58603 25.5572 9.6643C25.5901 9.74258 25.6069 9.82669 25.6065 9.9116C25.6064 9.99697 25.5894 10.0815 25.5565 10.1603C25.5236 10.239 25.4755 10.3106 25.4149 10.3707L24.1699 11.6148L20.3776 7.82537L21.6262 6.57834ZM9.05168 19.1435L19.6286 8.57205L23.4209 12.3615L12.8439 22.9329L9.05168 19.1435ZM8.02665 22.3471L8.52619 20.1159L11.8808 23.468L9.48162 24.0031L8.02665 22.3471Z" fill="#4D4D4D"/>
                        <path d="M12.1929 20.3211C12.1235 20.3215 12.0548 20.3079 11.9907 20.2812C11.9267 20.2544 11.8687 20.2151 11.8202 20.1655C11.7211 20.0661 11.6655 19.9316 11.6655 19.7913C11.6655 19.651 11.7211 19.5165 11.8202 19.4171L19.8717 11.3721C19.9206 11.3223 19.9788 11.2828 20.0431 11.2558C20.1074 11.2288 20.1765 11.2148 20.2462 11.2148C20.316 11.2148 20.385 11.2288 20.4494 11.2558C20.5137 11.2828 20.5719 11.3223 20.6207 11.3721C20.7198 11.4715 20.7754 11.6061 20.7754 11.7463C20.7754 11.8866 20.7198 12.0211 20.6207 12.1205L12.5692 20.1655C12.52 20.2152 12.4614 20.2545 12.3968 20.2812C12.3321 20.3079 12.2628 20.3215 12.1929 20.3211Z" fill="#4D4D4D"/>
                    </svg>
                    <svg class="activate-btn" v-if="tool.icon=='activate' && hasPermission('trainings.edit')" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#F4F4F4"/>
                        <path d="M16.0001 8.37109C20.4202 8.37109 24 11.8679 24 16.1854C24 20.503 20.4202 23.9997 16.0001 23.9997C11.5799 23.9997 8 20.503 8 16.1854C8.01151 11.8791 11.5914 8.38234 16.0001 8.37109ZM16.0001 22.3582C19.4878 22.3582 22.3195 19.5922 22.3195 16.1854C22.3195 12.7786 19.4878 10.0127 16.0001 10.0127C12.5123 10.0127 9.68067 12.7786 9.68067 16.1854C9.69218 19.5922 12.5123 22.3469 16.0001 22.3582Z" fill="#4D4D4D"/>
                        <path d="M16.0024 14.6238C15.5419 14.6238 15.1621 14.2528 15.1621 13.8031V6.82076C15.1621 6.37102 15.5419 6 16.0024 6C16.4628 6 16.8426 6.37102 16.8426 6.82076V13.8031C16.8426 14.2528 16.4743 14.6238 16.0024 14.6238Z" fill="#4D4D4D"/>
                    </svg>
                    <svg @click="deleteTraining(training.training_id)" v-if="tool.icon=='delete' && hasPermission('trainings.edit')" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#F4F4F4"/>
                        <path d="M18.0475 25.0639H13.8351C11.6382 25.0639 9.85278 23.3064 9.85278 21.1437V10.0862H22.0298V21.1378C22.0298 23.3004 20.2444 25.0579 18.0475 25.0579V25.0639ZM10.7606 21.1437C10.7606 22.8119 12.1405 24.1702 13.8351 24.1702H18.0475C19.7421 24.1702 21.122 22.8119 21.122 21.1437V10.9799H10.7606V21.1437Z" fill="#D45454"/>
                        <path d="M21.9027 10.9919C21.8543 10.9919 21.7998 10.9919 21.7514 10.9859H10.0949C9.67726 10.9859 9.28387 10.825 8.98731 10.5391C8.69075 10.2471 8.52734 9.85989 8.52734 9.44881C8.52734 8.60281 9.2294 7.91172 10.0888 7.91172H21.7938C21.8361 7.91172 21.9027 7.91172 21.9753 7.91172H22.0419C22.9074 8.00108 23.5368 8.75175 23.4521 9.59775C23.4097 10.0088 23.21 10.3782 22.8892 10.6403C22.6048 10.8727 22.2598 10.9919 21.8966 10.9919H21.9027ZM10.0888 8.80537C9.72568 8.80537 9.43517 9.09134 9.43517 9.44881C9.43517 9.62158 9.50174 9.78244 9.62884 9.90755C9.75594 10.0327 9.91935 10.0982 10.0949 10.0982H21.7998C22.0237 10.1161 22.1872 10.0624 22.3264 9.95521C22.4595 9.84201 22.5442 9.68711 22.5624 9.5203C22.5987 9.16284 22.3324 8.84708 21.9693 8.81133C21.8906 8.81133 21.8603 8.81133 21.8301 8.81133H10.0949L10.0888 8.80537Z" fill="#D45454"/>
                        <path d="M18.6769 8.79939H13.1997L13.0847 8.51938C13.012 8.33469 12.9697 8.13809 12.9697 7.94148C12.9697 7.09549 13.6717 6.4044 14.5311 6.39844H17.3333C18.1988 6.39844 18.9008 7.08953 18.9008 7.94148C18.9008 8.13809 18.8645 8.33469 18.7919 8.51938L18.6769 8.79939ZM13.8836 7.90574H17.993C17.9748 7.56615 17.6843 7.29209 17.3394 7.29209H14.5372C14.1922 7.29209 13.9017 7.56615 13.8836 7.90574Z" fill="#D45454"/>
                        <path d="M13.2057 22.0732C12.9575 22.0732 12.7518 21.8706 12.7518 21.6264V12.8268C12.7518 12.5826 12.9575 12.38 13.2057 12.38C13.4538 12.38 13.6596 12.5826 13.6596 12.8268V21.6264C13.6596 21.8706 13.4538 22.0732 13.2057 22.0732Z" fill="#D45454"/>
                        <path d="M15.9413 22.0732C15.6931 22.0732 15.4874 21.8706 15.4874 21.6264V12.8268C15.4874 12.5826 15.6931 12.38 15.9413 12.38C16.1894 12.38 16.3952 12.5826 16.3952 12.8268V21.6264C16.3952 21.8706 16.1894 22.0732 15.9413 22.0732Z" fill="#D45454"/>
                        <path d="M18.6769 22.0732C18.4287 22.0732 18.223 21.8706 18.223 21.6264V12.8268C18.223 12.5826 18.4287 12.38 18.6769 12.38C18.925 12.38 19.1308 12.5826 19.1308 12.8268V21.6264C19.1308 21.8706 18.925 22.0732 18.6769 22.0732Z" fill="#D45454"/>
                    </svg>
                </button>
            </div>
            <div class="invitations" v-else-if="header.value == 'invitations'" :style="header.style ? header.style : ''">
                <span 
                    class="row" 
                    :class="{inactive: !showInvitationRow(invitations[index-1].id)}"
                    v-for="index in 3"
                    :key="index"
                >
                    <span>
                        {{getInvitedToTrainingInfoLabel(invitations[index-1].id)}} 
                    </span>
                    <span>
                        {{training.invitations[invitations[index-1].id]}}
                    </span>
                </span>
            </div>
            <span v-else :class="header.class" :style="header.style ? header.style : ''">
                {{training[header.value] == null ? '-' : training[header.value]}}
            </span>
          </td>
        </tr>
        <tr v-if="isLoading">
            <td
                v-for="(header, h_index) in headers"
                :key="h_index"
                v-show="showColumn(header)"
            >
                <div class="loader-cell">
                    <vue-skeleton-loader
                        type="rect"
                        animation="wave"
                        :height="36"
                        :width="150"
                        color="#efefef"
                        :rounded="true"
                        />
                </div>
            </td>
        </tr>
        <tr><td class="not-found-cell" v-if="!isLoading && trainings.length == 0" :colspan="headers.length">{{ toLocal('generic.no_records') }}</td></tr>
      </tbody>
    </table>
    <GlobalModal
      v-if="deletingTraining"
      :modalInfo="{
        type: 'confirmation',
        text: 'training_edit.delete_confirm',
        undertext: '',
        confirmBtnText: 'delete_modal.yes_delete',
        class:'delete'
      }"
      @onConfirm="deleteTrainingRequest"
      @onClose="deletingTraining = false"
    />
  </div>
</template>

<script>
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

import axios from 'axios'
export default {
    components:{
        GlobalModal,
        CustomDropdown
    },
    props:{
        trainingGroup: Object,
        selectedMode: Number
    },
    data(){
        return{
            headers:[
                {id:1, class:['width-min'], filter_type: 'date', label: 'generic.creation_date', value:'created_date', filter:true,sortable:true},
                {id:2, class:['width-max'], cell_class: ['sticky'], filter_type: 'text', label: 'generic.item_name', value:'title', filter:true,sortable:true},
                {id:3, class:['width-max'], filter_type: 'text', label: 'trainings.item_in_folder', value:'training_group', filter:true,sortable:true},
                {id:4, class:['width-min', 'centered'], filter_type: 'number', label: 'trainings.table_user_count', value:'user_count', filter:true,sortable:true},
                {id:5, class:['width-min', 'centered'], filter_type: 'percent', label: 'generic.started_percent', value:'started', filter:true,sortable:true},
                {id:6, class:['width-mid', 'centered'], filter_type: 'percent', label: 'generic.ended_percent', value:'ended', filter:true,sortable:true},
                {id:7, class:['width-min', 'centered'], filter_type: 'percent', label: 'test.test_avg_percent', value:'test_avg', filter:true,sortable:true},
                {id:8, class:['width-min', 'centered'], filter_type: 'date', label: 'sort.expiration_date', value:'deactivation_date', filter:true,sortable:true},
                {id:9,  filter_type: 'custom', label: 'generic.user_invitations', style:'min-width:228px', value:'invitations', filter:true,sortable:true},
                {id:10, label: 'generic.item_settings', value:'settings', filter:false,sortable:false, tools:[
                    {id: 1, name:'view', icon: 'eye'},
                    {id: 2, name:'edit', icon: 'pen'},
                    {id: 3, name:'activate', icon: 'activate'},
                    {id: 4, name:'delete', icon: 'delete'},
                ]},
            ],
            invitations: [
                {value: 'Pakviesta per nuorodą', id: 'link'},
                {value: 'Pakviesta pagal sąlygą', id: 'condition'},
                {value: 'Priskirta grupių', id: 'groups'},
            ],
            nextPageURL:'',
            page: 1,
            onLastPage: false,
            trainings: [],
            deletingTraining: false,
            deletingTrainingID: -1,
            sortBy: '',
            selectedFilters: [],
            filterURLString: '',
            sortDirection: 'asc',
            trainingFilterLoaded:false,
            training_filter_list: [],
            training_group_filter_list: [],
            shownColumns: [],
            isLoading: false,
        }
    },
    computed:{
        invitationsFilter(){
            let index = this.headers.findIndex(header => header.filter_type == 'custom');
            if(index == null){
                return null
            }
            return this.selectedFilters[this.headers[index].id] != undefined ? this.selectedFilters[this.headers[index].id] : null;
        }
    },
    watch:{
        selectedMode(){
            this.filterTrainings();
        }
    },
    mounted(){
         if(!this.trainingFilterLoaded && this.trainingGroup){
            this.setInitialTrainingGroupFilter();
        }
        else{
            this.loadTrainingsTable();
        }
    },
    methods:{
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.page++;
                this.loadTrainingsTable()
            }
        },
        getInvitedToTrainingInfoLabel(key){
            let text = '';
            if(key == 'link'){
                text+= 'Pakviesta per nuorodą:'
            }
            else if(key == 'condition'){
                text+= 'Pakviesta pagal sąlygą:'
            }
            else if(key == 'groups'){
                text+= 'Priskirta grupių:'
            }
            return text
        },
         loadTrainingsTable(){
            if(this.onLastPage) return;
            let query = '';
            if(this.sortBy.length > 0){
                query = `sort_by=${this.sortBy}&sort_direction=${this.sortDirection}`;
            }
            if(this.filterURLString != null){
                query+= this.filterURLString
            }
            if(!this.trainingFilterLoaded) query+='&get_training_data=1';
            let url = `${this.$store.state.api_link}/trainings/table`;
            if(this.nextPageURL.length > 0){
                url = this.nextPageURL;
            }
            if(this.selectedMode == 1){
                query+= `&view_mode=${this.selectedMode}`
            }
            if(query.length > 0)
                url+= (!url.includes('?') ? '?' : '&')+query;
            this.isLoading=true;
            axios
                .get(url,this.$store.getters.axiosConfig())
                .then((response) => {
                    this.trainings.push(...response.data.training_list);
                    this.nextPageURL = '';
                    if(response.data.next_page_url != null){
                        this.nextPageURL = response.data.next_page_url
                    }
                    if(response.data.on_last_page){
                        this.onLastPage = true;
                    }
                    if(response.data.training_filter){
                        this.training_filter_list = response.data.training_filter
                        this.trainingFilterLoaded=true;
                    }
                    if(response.data.training_group_filter){
                        this.training_group_filter_list = response.data.training_group_filter
                       
                    }
                    this.setShownColumns();
                    this.loadedTableTrainings = true;
                    this.isLoading = false;
                    let table = this.$refs.mainTable;
                    let hasVerticalScrollbar = table.scrollHeight > table.clientHeight;
                    if (!hasVerticalScrollbar && !this.onLastPage) {
                        this.page++;
                        this.loadTrainingsTable()
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    this.isLoading=false;
                    this.$store.commit("handleError", { error: error });
                });
        },
        setShownColumns(){
            let training = this.trainings[0];
            let keys = Object.keys(training);
            this.shownColumns = this.headers.map(el=>el.value);
            keys.forEach(key => {
                let index = this.headers.findIndex(header => header.value == key);
                if(index != -1){
                    let everyElementIsNull = this.trainings.every((element) => element[key] === null);
                    if(!everyElementIsNull){
                        if(!this.shownColumns.includes(key)){
                            this.shownColumns.push(key);
                        }
                    }
                    else{
                        if(this.shownColumns.includes(key)){
                            this.shownColumns.splice(this.shownColumns.indexOf(key), 1);
                        }
                    }
                }
                
            });
        },
        clickedAllOption(header){
            this.$set(this.selectedFilters,header.id, undefined);
            this.filterTrainings();
        },
        openTraining(id, training) {
            this.$store.state.loading.loadingStart();
            this.$store.state.previewMode = true;
            this.$store.state.currentTraining = id;
            this.$store.state.previewTopic = -1;
            this.$router.push("/training?id=" + id);
        },
        editTraining(id, query=null) {
            if(this.$store.state.user_role == 'admin'){
                this.$store.state.loading.loadingStart();
                this.$store.state.currentTraining = id;
                // this.$store.state.currentTrainingData = training;
                this.$store.state.previewTopic = -1;
                if(query){
                this.$router.push("/edit-training?id=" + id+query);
                }
                else{
                this.$router.push("/edit-training?id=" + id);
                }
            }
        },
        setInitialTrainingGroupFilter(){
            let index = this.headers.findIndex(header => header.value == 'training_group');
            if(index != -1){
                let header = this.headers[index];
                let header_id = header.id;
                this.selectedFilters[header_id] = [{id:this.trainingGroup.id}];
                this.filterTrainings();
            }
        },
        deleteTraining(id){
            this.deletingTrainingID = id;
            this.deletingTraining = true;
        },
        deleteTrainingRequest() {
            this.addingTraining = true;
            axios
                .post(
                    `${this.$store.state.api_link}/training/delete`,
                    { id: this.deletingTrainingID },
                    this.$store.getters.axiosConfig()
                )
                .then((response) => {
                    this.deletingTraining = false;
                    this.deletingTrainingID = -1;
                    this.loadTrainings();
                })
                .catch((error) => {
                    this.deletingTraining = false;
                    this.deletingTrainingID = -1;
                    this.$store.commit("handleError", { error: error });
                });
        },
        setSort(header){
            if(header.value == 'invitations'){
                let header = this.headers.find(header => header.value == 'invitations');
                let header_id = header.id;
                let filter = this.selectedFilters[header_id][0];
                if(filter){
                    this.sortBy = header.value+'.'+filter.id;
                }
            }
            else{
                this.sortBy = header.value;
            }
            this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
            this.onLastPage = false;
            this.nextPageURL = '';
            this.page = 1;
            this.trainings = [];
            this.loadTrainingsTable();
        },
        getFilterOptions(header){
            return {
                containerClass: 'no-padding-no-bg',
                left: true,
                singleIcon: true,
                checkbox: true,
                filterType: header.filter_type ? header.filter_type : 'text',
                showCreateNewBtn: false,
                closeOnClick: false,
                compareByValue: true,
                // selectAllChoice: this.toLocal('users.all_columns'),
                customMaxHeight: '300px',
                customDDWidth: '900%',
                rangeMin: 0,
                rangeMax: header.filter_type=='percent' ? 100 : null,
                searchBar: true,
                searchLocally: true,
            }
        },
        getFilterValues(header_item){
            if(header_item.filter_type == 'text'){
                if(header_item.value == 'title')
                    return this.training_filter_list
                if(header_item.value == 'training_group')
                    return this.training_group_filter_list
                let items = this.trainings.map(training => { return {id:training.training_id, value: training[header_item.value]}});
                return items;
            }
            else if(header_item.filter_type == 'custom'){
                return this.invitations;
            }
            else return []
        },
        filterTrainings(){
            let filterUrlString = '';
            this.selectedFilters.forEach((filter_item, index)=>{
                if(filter_item){
                    let h_index = this.headers.findIndex(header => header.id == index);
                    if(h_index !== -1){
                        let header = this.headers[h_index];
                        if(header.filter_type == 'text'){
                            filterUrlString += `&filter.${header.value}=${filter_item.map(el=>el.id)}`
                        }
                        else{
                            filterUrlString += `&filter.${header.value}=${filter_item}`
                        }

                    }
                }
            })
            this.onLastPage = false;
            this.trainings = [];
            this.nextPageURL = '';
            this.page = 1;
            this.filterURLString = filterUrlString;
            this.loadTrainingsTable();
        },
        selectDropdownOption(type, key, value,header){
            if(type == 'date' || type=='number'){
                if(key == 'from'){
                    if(!this.selectedFilters[header.id]) this.$set(this.selectedFilters, header.id, []);
                    this.selectedFilters[header.id][0] = value
                    if(this.selectedFilters[header.id][1] && this.selectedFilters[header.id][1].length > 0){
                        this.filterTrainings();
                    }
                    else{
                        if(!this.selectedFilters[header.id][1] || this.selectedFilters[header.id][1].length == 0){
                            this.$set(this.selectedFilters, header.id, undefined);
                        }
                    }
                }
                else {
                    if(value.length >0){
                        if(!this.selectedFilters[header.id]) this.$set(this.selectedFilters, header.id, []);
                        this.selectedFilters[header.id][1] = value;
                        
                        this.filterTrainings();
                    }
                    else{
                        if(this.selectedFilters[header.id][1]) {
                            if(!this.selectedFilters[header.id][0] || this.selectedFilters[header.id][0].length == 0){
                                this.$set(this.selectedFilters, header.id, undefined);
                            }
                            else{
                                this.$set(this.selectedFilters[header.id], 1, value);
                            }
                        }
                    }
                }
            }
            else if (type == 'text'){
                if(!this.selectedFilters[header.id]) this.$set(this.selectedFilters, header.id, []);
                let index = this.selectedFilters[header.id].findIndex((filterVal)=> filterVal.id == value);
                if(index != -1){
                    this.selectedFilters[header.id].splice(index,1);
                    if(this.selectedFilters[header.id].length == 0){
                        this.$set(this.selectedFilters,header.id,undefined)
                    }
                }
                else{
                    this.selectedFilters[header.id].push({id:value});
                }
                this.filterTrainings();
            }
            else if(type == 'custom'){
                if(!this.selectedFilters[header.id]) this.$set(this.selectedFilters, header.id, []);
                let index = this.selectedFilters[header.id].findIndex((filterVal)=> filterVal.id == value);
                if(index != -1){
                    this.selectedFilters[header.id].splice(index,1);
                }
                else{
                    let invitation = this.invitations.find(invitation => invitation.id == value);
                    if(invitation){
                        this.selectedFilters[header.id].push(invitation);
                    }
                }
                // this.filterTrainings();
            }
        },
        showInvitationRow(data){
            if(this.invitationsFilter == null || this.invitationsFilter.length == 0) return true;
            if(this.invitationsFilter != null && this.invitationsFilter.map(el=>el.id).includes(data))
                return true;
            else return false;
        },
        showSort(header){
            if(header.sortable){
                if(header.filter_type == 'custom'){
                    return this.invitationsFilter != null && this.invitationsFilter.length == 1;
                }
                else{
                    return true;
                }
            }
            else return false;
        },
        showColumn(header){
            if(this.shownColumns.includes(header.value)){
                return true;
            }
            else return false
        }
    }
}
</script>

<style lang="scss" scoped>
.table-view{
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    max-height: 74vh;
    height: 74vh;
    padding-right: 20px;
    overflow-x: visible;
    overflow-y: auto;
    @media (max-width: 768px){
        margin-left: 20px;
    }
}
table{
    border-collapse: collapse;
    font-size: 14px;
    font-family: var(--main-site-font);
    thead{
        position: sticky;
        top: 0;
        left: 0;
        z-index: 4;
        tr{
            &::before{
                opacity: 0;
            }
        }
    }
    th{
        background: #4D4D4D;
        padding: 4px 8px;
        color:white;
        font-family: var(--main-site-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        border-left: 1px solid white;
        &:first-child{
            border-top-left-radius: 8px;
            border-left: none;
        }
        &:last-child{
            border-top-right-radius: 8px;
        }
    }
    tr{
        position: relative;
        &:before{
            content: '';
            display: flex;
            width: calc(100% - 16px);
            position: absolute;
            bottom: 0;
            left: 8px;
            height: 1px;
            background: #CACACA;
            z-index: 3;
        }
        &:last-child{
            td{
                &:first-child{
                    border-bottom-left-radius: 8px;
                }
                &:last-child{
                    border-bottom-right-radius: 8px;
                }
            }
            // &:before{
            //     height: 0px;
            // }
        }
        &.deactivated{
            td{
                background: #efefef;
                .activate-btn{
                    rect{
                        fill:#D45454;
                    }
                    path{
                        fill: white;
                    }
                }
            }
        }
    }
    td{
        background: white;
        max-height: 32px;
        padding: 0;
        span{
            display: flex;
            align-items: center;
            &.centered{
                justify-content: center;
            }
        }
        span, .tools-container{
            border-left: 1px solid #CACACA;
            min-height: 32px;
            padding: 0 8px;
        }
        &:first-child{
            padding-left: 8px;
            span{
                border-left: none;
            }
        }
    }
}
.sticky{
    position: sticky;
    left: 0;
    z-index: 2;
}
.tools-container{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        border: none;
        background: none;
        cursor: pointer;
    }
}
.header-cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-label{
    display: flex;
    word-wrap: break-word;
}
.not-found-cell{
    padding: 8px;
    text-align: center;
    border-bottom: none;
}
.sort-icon{
    margin-left: 8px;
    path{
        &.active{
            fill: #ffffff;
        }
    }
}
.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.invitations{
    display: flex;
    flex-direction: column;
    justify-content: center;
    span{
        border: none;
        &.row{
            min-height: 18px;
            height: 18px;
            margin: 8px 0;
            &:first-child{
                margin-bottom: 0px;
            }
            &:last-child{
                margin-top: 0px;
            }
            &.inactive{
                color:#7D7D7D;
            }
            &:nth-child(2){
                span:first-child{
                    border-left: 1px solid #cacaca;
                }
            }
        }
    }
}
.width-min{
    min-width: 140px;
}
.width-mid{
    min-width: 160px;
}
.width-max{
    min-width: 225px;
}
.loader-cell{
    padding: 5px;
}
</style>
<style lang="scss">
.header-cell{
    .tools-container{
        .filter{
            &.active{
                .dropdown-btn-icon{
                    background: white;
                    border-radius: 50%;
                    svg{
                        path{
                            fill: #4D4D4D;
                        }
                    }
                }
            }
        }
    }
}
</style>