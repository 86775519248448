<template>
  <div 
  class="tree-select test" 
  :class="{
    active: selectedTestID == test.id && !$store.state.selectTestsModeActive,
    selected: $store.state.selectTestsModeActive && $store.state.selectModeSelectedTests.some(e => e.folder == folder.id && e.test_id == test.id),
    combo: test.type == 1}"
  >
            <div 
                 class="icon doc"
                :class="{active: activeTest == test.id}"
                v-tooltip="{
                    content: 'Klausimų skaičius teste',
                    classes: 'tooltip-container',
                }"
            >
                {{getQuestionCount}}
            
            </div>
        <!-- <div class="icon doc">
            {{test.questions != undefined ? test.questions.length : test.question_count}}

        </div> -->
        <div class="label" @click.stop="$store.state.selectTestsModeActive ? selectTest(test, folder) : setTest(test,folder)">
            <span v-if="!isEditingTitle">
                  {{test.name}}
            </span>
            <input 
                v-else 
                type="text"
                ref="testTitle"
                v-model.lazy="test.name"
                :placeholder="toLocal('generic.enter_name')"  
                @mouseover="$emit('dragDisable', true)"
                @mouseleave="$emit('dragDisable', false)"
                @enter="saveTestName"
                v-click-outside-2="endTitleEditing"
            >
        </div>
        <div class="icon" :class="{opened: optionsOpen}" v-if="!$store.state.selectTestsModeActive" @click="optionsOpen = true" v-click-outside-2="closeOptions">
            <svg class="select-icon" :style="{'z-index': optionsOpen ? '3' : ''}" xmlns="http://www.w3.org/2000/svg" width="26" height="7" viewBox="0 0 26 7">
            <g id="Group_9979" data-name="Group 9979" transform="translate(498.599 -710.5) rotate(90)">
                <circle id="Ellipse_572" data-name="Ellipse 572" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 498.599) rotate(-90)" fill="#28345c"/>
                <circle id="Ellipse_573" data-name="Ellipse 573" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 489.118) rotate(-90)" fill="#28345c"/>
                <circle id="Ellipse_574" data-name= "Ellipse 574" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 479.638) rotate(-90)" fill="#28345c"/>
            </g>
            </svg>
                <div class="action-container" v-if="optionsOpen" >
                    <div class="header">

                    </div>
                    <div class="option" @click.stop="rename">
                        <div class="option-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.406" height="15.412" viewBox="0 0 15.406 15.412">
                            <g id="Group_11540" data-name="Group 11540" transform="translate(0 0)">
                                <path id="Path_8221" data-name="Path 8221" d="M15.4,14.714l-.8-3.584V11.1a1.278,1.278,0,0,0-.377-.9L4.4.38a1.3,1.3,0,0,0-1.834,0L.38,2.563A1.3,1.3,0,0,0,.38,4.4L10.2,14.219a1.254,1.254,0,0,0,.921.366l3.6.809a.611.611,0,0,0,.245,0,.566.566,0,0,0,.358-.25.578.578,0,0,0,.088-.305.475.475,0,0,0-.014-.124M.949,3.828a.491.491,0,0,1,0-.7L3.138.949A.48.48,0,0,1,3.485.8a.491.491,0,0,1,.349.146l.946.946L1.9,4.777Zm9.557,9.557L2.467,5.347,5.349,2.464,13.388,10.5Zm2.437.781-1.7-.38,2.549-2.55.407,1.823Z" transform="translate(15.406 0) rotate(90)" fill="#28345c"/>
                                <path id="Path_8225" data-name="Path 8225" d="M6.926,6.523a.393.393,0,0,1-.118.283.4.4,0,0,1-.569,0L.12.687a.4.4,0,0,1,0-.569.4.4,0,0,1,.569,0L6.808,6.237a.4.4,0,0,1,.118.286" transform="translate(10.93 4.479) rotate(90)" fill="#28345c"/>
                            </g>
                            </svg>
                        </div>
                        <span class="option-label">
                            {{toLocal('generic.rename')}}
                        </span>
                    </div>
                    <div class="option" @click="deleteItem">
                        <div class="option-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.694" height="14.872" viewBox="0 0 11.694 14.872">
                                <g id="Group_10004" data-name="Group 10004" transform="translate(0 0)">
                                    <g id="Group_291" data-name="Group 291" transform="translate(0 0)">
                                    <path id="Rectangle_184" data-name="Rectangle 184" d="M6927.982,1771.573h9.547v8.808a3.123,3.123,0,0,1-3.117,3.12H6931.1a3.124,3.124,0,0,1-3.121-3.12Zm8.846.7h-8.144v8.106a2.421,2.421,0,0,0,2.419,2.418h3.308a2.422,2.422,0,0,0,2.417-2.418Z" transform="translate(-6926.94 -1768.63)" fill="#28345c"/>
                                    <path id="Path_550" data-name="Path 550" d="M6933.777,1764.2l.084,0a1.224,1.224,0,0,1-.155,2.443h-9.176a1.222,1.222,0,0,1,0-2.444h9.178Zm0,1.746a.522.522,0,0,0,.036-1.042.463.463,0,0,0-.071,0h-9.211a.52.52,0,1,0,0,1.04h9.247Z" transform="translate(-6923.308 -1763.003)" fill="#28345c"/>
                                    <path id="Path_551" data-name="Path 551" d="M6942.827,1761.029h-4.29l-.088-.22a1.225,1.225,0,0,1,1.13-1.682h2.2a1.226,1.226,0,0,1,1.136,1.681Zm-3.763-.7h3.235a.522.522,0,0,0-.521-.5h-2.2A.522.522,0,0,0,6939.064,1760.327Z" transform="translate(-6934.868 -1759.126)" fill="#28345c"/>
                                    <g id="Group_290" data-name="Group 290" transform="translate(3.316 4.772)">
                                        <path id="Line_35" data-name="Line 35" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(0.5 0.5)" fill="#28345c"/>
                                        <path id="Line_36" data-name="Line 36" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(2.647 0.5)" fill="#28345c"/>
                                        <path id="Line_37" data-name="Line 37" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(4.795 0.5)" fill="#28345c"/>
                                    </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span class="option-label">
                            {{toLocal('generic.delete')}}
                        </span>
                    </div>
                </div>
        </div>
       
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
export default {
    props:{
        test:{
            type:Object,
            required:true
        },
        selectedTestID:{
            type:Number,
            required:true
        },
        folder:{
            type:Object,
        },
        activeTest:{
            type: Number
        }
    },
    data(){
        return{
            optionsOpen: false,
            isEditingTitle: false
        }
    },
    computed:{
        getQuestionCount(){
            if(this.$store.state.selectedTest.id == this.test.id || this.$store.state.selectedTest.version_parent == this.test.id){
                if(this.$store.state.selectedTest.type == 1){
                    let test = this.$store.state.selectedTest;
                    return test.options && test.options.tests && test.options.tests.length != 0 ? test.options.tests.map(el=>el = parseInt(el.count)).reduce((a,b)=>a+b) : 0;         
                }
                else{
                    let test = this.$store.state.selectedTest.id == this.test.id ? this.$store.state.selectedTest : this.test;
                    return test.questions != undefined ? test.questions.length : test.question_count != null ? test.question_count : 0;
                }

            }
            else{
                let test = this.test;
                return test.questions != undefined ? test.questions.length : test.question_count != null ? test.question_count : 0;
            }
        }
    },
    watch:{
     
        'test.name'(){
            this.debounceSave(this);
        },
     
    },
    methods:{
        closeOptions() {
            this.optionsOpen = false;
        },
        deleteItem(){
   
            this.$store.state.testDeleteModal = {
                modalOpened: true,
                test: this.test,
                folder: this.folder,
                type: 'test'
            };
        },
        saveItem(){
            let id = this.test.id;
            axios.post(
                `${this.$store.state.api_link}/test-question-groups/edit`,
                {
                    data:this.test,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                
            })
        },
        debounceSave: _.debounce((self)=>{
            self.saveItem();
        }, 1000),
      
        setItemName(e, item){
            if(e.target.value != ''){
                item.name = e.target.value; 
                this.$set(item, 'editingTitle', false);
            }
            else return;
        },
        startTitleEdit(e, report){
            if(report.editingTitle) return;
            this.$set(report, 'editingTitle', true);
            let input = e.target.parentNode.children[1];
            setTimeout(()=>{
                if(input)
                    input.focus();
            },10);
        },
        setTest(test, folder){
            this.$emit('setTest', test, folder);
        },
        selectTest(test, folder){
            if(test.type == 1) return
            let index =  this.$store.state.selectModeSelectedTests.findIndex(el => el.folder == folder.id && el.test_id == test.id);
            if(index != -1){
                this.$store.state.selectModeSelectedTests.splice(index, 1);
            }
            else{
                this.$store.state.selectModeSelectedTests.push({
                    folder:folder.id, test_id:test.id, count: 0, test: test
                });
                this.$emit('selectModeSelectItem', folder.id, test.id); 
            }
        },
        endTitleEditing(e){
            this.$set(this.test, 'name', this.$refs.testTitle.value);
            this.saveTestName(this.test.name);
            this.isEditingTitle = false
        },
        saveTestName(e){
            this.$root.$emit('update_test_name',this.folder, this.test);
        },
        rename(){
            this.isEditingTitle = true; 
            this.optionsOpen = false;
            this.$nextTick(()=>{
                this.$refs.testTitle.focus();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.tree-select{
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    &.test{
        
        padding: 5px;
        height: 2rem;
        width: 100%;
        background: white;
        margin: 7px auto;
        border-radius: 5px;
        color: var(--primary-color);
        &.active, &.selected{
            background: var(--primary-color);
            color: white;
            .label{
                color: white;

            }
            font-weight: 700;
            .doc{
                svg{
                    path{
                        fill: var(--secondary-color)
                    }
                }
               
            }
            .icon{

                .select-icon{
                    path,circle{
                        fill: rgba(255, 255, 255, 0.425);
                    }
                }
                 &.opened{
                    .select-icon{
                        path, circle{
                            fill: var(--primary-color-opacity-50);
                        }
                    }
                
                }
            }
            input{
                color: white;
                border-bottom: 1px solid white;
            }
        }
        &.selected{
            border: 2px solid white;
        }
    }
    &.combo{
        border-radius: 18px;
        .icon{
            &.doc{
                border-radius: 50%;
                min-width: 30px;
            }
        }
    }
    .label{
        width: 100%;
        margin-left: 0.5rem;
        color: var(--primary-color);
        cursor: pointer;
         input{
            width: 100%;
            height: 100%;
            background: none;
            border:none;
            font-size: clamp(16px, 1.2vw, 20px);
            border-bottom: 1px solid var(--primary-color-opacity-50);
            &:focus{
                outline: none;
            }
        }
        span{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 10px;
            min-width: 100%;
        }
        &.selected{
            font-weight: 700;
        }
    }
    .icon{
        display: flex;
        padding: 0 0.25rem;
        align-items: center;
       
        border-radius: 3px;
        height: 1.5rem;
        width: 2rem;
        position: relative;
        cursor: pointer;
        svg{
            position: relative;
            path, circle{
                transition: fill 0.3s ease;
                fill: var(--primary-color);
            }
            rect, line{
                stroke: var(--primary-color);
            }
        }
        .select-icon{
            path, circle{
                fill: var(--primary-color-opacity-50);
            }
        }
        &:hover{
            .select-icon{
                path, circle{
                    fill: var(--primary-color);
                }
            }
        }
        &.doc{
            justify-content: center;
            background: #efefef;
            color: var(--primary-color);
            min-width: 30px;
            width: 30px;
            svg{
                path{
                    stroke: var(--primary-color);
                    fill: none;
                }
            }
            &.active{
                background: var(--secondary-color);
            }
        }
        &.opened{
            .select-icon{
                path, circle{
                    fill: var(--primary-color-opacity-50);
                }
            }
           
        }
    }
}
.action-container{
    position: absolute;
    right: -0.25rem;
    top: -0.5rem;
    border: 1px solid var(--primary-color);
    background: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 2;
    .header{
        height: 1rem;
        width: 100%;
    }
    .option{
        display: flex;
        align-items: center;
        color: var(--primary-color);
        margin: 0.25rem 0;
        .option-icon{
            display: flex;
            justify-content: center;
            width: 1rem;
            margin-right: 0.25rem;
            min-height: 16px;
            height: 1rem;
        }
        .option-label{
            width: 4.5rem;
            font-size: clamp(14px, 2vw, 16px);
            font-weight: 500;
        }
        &:hover{
            .option-label{
                font-weight: 700;
                
            }
        }
        svg{
            path{
                fill: var(--primary-color);
            }
        }
    }
}
.option-icon{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    svg{
        height: 100%;
        width: 0.75rem;
    }
}
.report-count{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -2.5rem;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 2rem;
    height: 70%;
    span{
        position: absolute;
        font-weight: 700;
        color: var(--primary-color);
    }
    svg{
        path{
            stroke: var(--primary-color);
        }
    }
}
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
  min-width: 2rem;
   min-height: 2rem;
}
.expand-enter-from,
.expand-leave-to {
  opacity: 0;
   min-width: 0;
    min-height: 0;
}

@media(max-width: 1280px){
    .tree-select{
        &.test{
            .label{
                margin-left: 4px;
                span{
                    font-size: 14px;
                }
            }
            .icon{
                &.has-tooltip{
                    min-width: 20px;
                    width: 20px;
                    min-height: 20px;
                    height: 20px;
                    font-size: 12px;
                }
                &:not(.has-tooltip){
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.v-test-popper{
    position: relative;
    width: initial;
    height: initial;
}
.test-list-popover{
    background: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    width: 80px;
    font-size: 16px;
    z-index: 25;
    padding: 5px;
    .tooltip-arrow {
        width: 20px;
        height: 20px;
        left: -11px;
        border-style: solid;
        position: absolute;
        border: 1px solid var(--primary-color);
        border-color: black;
        border-right: none;
        border-top: none;
        background: white;
        z-index: -1;
        transform: rotate(45deg);
    }
}
</style>