<template>
<portal to="upload-tiptap-modal">
  <div class="modal" v-if="show">
    <div class="modal-content">
      <h1>{{toLocal('modal.add_image')}}</h1>


      <div  class="tab-content left">
        <label for="url">{{toLocal('modal.link_url')}}</label>
        <div class="input-container">
          <input type="text" placeholder="URL" v-model="imageSrc" id="url">
        </div>
      </div>

      <div  class="tab-content right" >
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          class="dropzone"
          @vdropzone-success="vfileUploaded"
          :options="dropzoneOptions"
          :useCustomSlot=true
        >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">{{toLocal('editor.drag_file')}}</h3>
          <div class="subtitle">{{toLocal('editor.or_press')}}</div>
        </div>
        </vue-dropzone>
      </div>

      <footer class="modal-footer">
        <button
          @click="addImage"
          class="success"
          :title="validImage ? '' : toLocal('editor.photo_url_wrong')"
          :disabled="!validImage"
        >
        {{toLocal('modal.add_image')}}
        </button>
      </footer>
        <button @click="show = false;" class="exit">
          <span></span><span></span>
        </button>
    </div>
  </div>
  </portal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      imageSrc: "",
      command: null,
      show: false,
      tab: 1,
      dropzoneOptions: {
        url: `${this.$store.state.api_link}/upload-file`,
        thumbnailWidth: 200,
        headers: {Authorization: this.$store.getters.axiosConfigMultipart().headers.Authorization },
        maxFiles: 1,
        paramName: "file",
        withCredentials: true,
        uploadMultiple: false
      }
    };
  },
  computed: {
    validImage() {
      return (
        
        this.imageSrc.match(/\.(jpeg|jpg|gif|png)$/) != null
      );
    }
  },
  watch:{
    show(){
      this.$store.state.showingTiptapUploadModal = this.show
    }
  },
  methods: {
    showModal() {
      // Add the sent command
    //   this.command = command;
      this.show = true;
    },
    vfileUploaded(file) {
      let response = JSON.parse(file.xhr.response);
      this.imageSrc =response.file;
    },

    fileChange(e) {
      const file = this.$refs.file.files[0];

      let formData = new FormData();

      formData.append("file", file);


        axios.post(
            `${this.$store.state.api_link}/upload-file`,
            formData,
            this.$store.getters.axiosConfigMultipart()
        ).then((response) => {

        this.imageSrc = this.$store.state.api_uploads_link+response.data.file;
        });
    },
    insertImage() {
      const data = {
        command: this.command,
        data: {
          src: this.imageSrc
          // alt: "YOU CAN ADD ALT",
          // title: "YOU CAN ADD TITLE"
        }
      };

      this.$emit("onConfirm", data);
      this.closeModal();
    },
    addImage(){
        this.$emit("addNewImage", this.imageSrc);
        this.closeModal();
    },

    closeModal() {
      this.show = false;
      this.imageSrc = "";
      this.tab = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
h1{
  color: var(--primary-color);
  margin: 0.5rem 1rem;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  padding-left: 80px;
  color: var(--primary-color)
}

.modal-content {
  position: relative;
  width: 90%;
  background-color: #fff;
  padding: 30px;
  border-radius: 1rem;
  border: 4px solid #707070;
  max-width: 45rem;
  color: #707070;

}

.modal-footer {
  margin-top: 10px;
padding: 0.5rem 1rem;
}

label {
  display: block;
  margin: 0.25em 0;
  padding: 0 0.75rem;
  color: var(--primary-color)
}

button {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5em 1em;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border: transparent;
  background-color: #e6e6e6;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0px 0.1rem 0.5rem -0.1rem #707070;

}

button.danger {
  background: rgb(202, 60, 60);
}
button.success{
  background: white;
  border-radius: 1rem;
  color: var(--primary-color);
}



button:disabled {
  opacity: 0.5;
  cursor: not-allowed
}

button + button {
  margin-left: 10px;
}

.tab-header {
  display: flex;
  align-items: center;

}

.tab-header button {
  color: #222;
  background: none;
  border: 0;
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
}

.tab-header button.active {
  position: relative;
  border: 4px solid #707070;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  background: white;
  color:  #707070;
  &::after{
    content: "";
    display: flex;
    width: 100%;
    height: 5px;
    background: white;
    position: absolute;
    bottom: -4px;
    left: 0;
  }
}
.tab-content{
  // border: 4px solid #707070;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0.5rem 1rem;
  &.left{
    border-top-right-radius: 1rem;
  }
  &.right{
    border-top-left-radius: 1rem;

  }
}
.input-container{
    position: relative;
    display: flex;
    background: white;
    border: 2px solid white;
    border-radius: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    width: 100%;
    padding: 1px 0.25rem;
   box-shadow: 0px 0.1rem 0.5rem -0.1rem #707070;
   
}
input{
    background: linear-gradient(180deg, rgba(255,255,255,1) 65%, rgba(128,128,128,1) 100%);
    width: 100%;
    color: #24345F;
    background: white;
    border-radius: 2rem;
    border: none;
    padding: 0.25rem 0.5rem;
    font-size: clamp(16px, 1vw, 20px);
    background: linear-gradient(360deg, #faf9f9 13%, transparent 43%);
    &:focus{
        outline: none;
    }
    &::placeholder{
        color: #24345f73;
    }
}
.exit{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  padding: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  right: 0.5rem;
  top: 0.5rem;
  border-radius: 50%;
  color: #707070;
  background: white;
  box-shadow: 0px 0.1rem 0.5rem -0.1rem #707070;
  cursor: pointer;
  // border: 4px solid #707070;
  span{
    width: 4px;
    height: 70%;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background: var(--primary-color);
    border-radius: 2rem;
    transform: rotate(45deg);
  }
  span:last-child{
    transform: rotate(-45deg);
  }
}
.dropzone{
  background: none;
  box-shadow: 0px 0.1rem 0.5rem -0.1rem #707070;
   border-radius: 1rem;
   border: none;
}
</style>
