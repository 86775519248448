<template>
  <div 
    class="login--wrapper"
    :style="[{'background-image': `url('${this.$store.state.api_uploads_link + this.$store.state.system_settings['login_bg']}')`}]"
  >
    <div :class="['login--side', 'active']">
      
      <form
        class="login--form"
        method="POST"
        action=""
        @submit="checkRegisterForm"
      >
        <div class="form-group row">
          <label
            for="email"
            class="col-md-4 col-form-label text-md-right"
          >{{ toLocal("login.email_address") }}</label>

          <div class="col-md-6">
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              name="email"
              value=""
              required
              autocomplete="email"
              autofocus
            >

            <span
              v-if="errors.length"
              class="invalid-feedback"
              role="alert"
            >
              <strong
                v-for="error in errors"
                :key="error"
              >{{ toLocal(error) }} </strong>
            </span>
          </div>
        </div>

        <div class="form-group row">
          <label
            for="password"
            class="col-md-4 col-form-label text-md-right"
          >{{ toLocal("generic.password") }}</label>

          <div class="col-md-6">
            <input
              id="password"
              ref="newPassword"
              v-model="password"
              :type="show_password ? 'text' : 'password'"
              class="form-control"
              name="password"
              required
              autocomplete="new-password"
            >
            <svg v-if="$refs['newPassword'] && $refs['newPassword'].value != ''" @mousedown="show_password = true" @mouseup="show_password = false" @mouseout="show_password = false" xmlns="http://www.w3.org/2000/svg" width="20.211" height="12.43" viewBox="0 0 20.211 12.43">
              <g id="Group_231" data-name="Group 231" transform="translate(-0.054)">
                <path id="Path_511" data-name="Path 511" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_512" data-name="Path 512" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_24" data-name="Ellipse 24" cx="3.709" cy="3.709" r="3.709" transform="translate(6.451 2.505)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
              </g>
            </svg>
          </div>
        </div>

        <div class="form-group row">
          <label
            for="password"
            class="col-md-4 col-form-label text-md-right"
          >{{ toLocal("login.confirm_password") }}</label>

          <div class="col-md-6">
            <input
              id="password"
              ref="newPasswordConfirm"
              v-model="confirm_password"
              :type="show_password2 ? 'text' : 'password'"
              class="form-control"
              name="password"
              required
              autocomplete="new-password"
            >
            <svg v-if="$refs['newPasswordConfirm'] && $refs['newPasswordConfirm'].value != ''" @mousedown="show_password2 = true" @mouseup="show_password2 = false" @mouseout="show_password2 = false" xmlns="http://www.w3.org/2000/svg" width="20.211" height="12.43" viewBox="0 0 20.211 12.43">
              <g id="Group_231" data-name="Group 231" transform="translate(-0.054)">
                <path id="Path_511" data-name="Path 511" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_512" data-name="Path 512" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_24" data-name="Ellipse 24" cx="3.709" cy="3.709" r="3.709" transform="translate(6.451 2.505)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
              </g>
            </svg>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="col-md-8 offset-md-4">
            <button
              type="submit"
              class="btn btn-primary btn-full"
            >
              {{toLocal('register.register')}}
            </button>
          </div>
        </div>
      </form>
    </div>
  
    <transition name="fade">
      <div class="forgot--password--wrapper" v-click-outside="hideForgotPassword" v-if="forgot_password">
        <div class="input--block">
          <form @submit="resetFormSubmit">
            <div class="form-group row">
              <label
                for="forgot_password"
                class="col-md-4 col-form-label text-md-right"
              >{{ toLocal("login.email_address") }}</label>
              <div class="col-md-6">
                <input id="forgot_password" type="email" class="form-control" v-model="forgot_password_email">
              </div>
            </div>
            <br>
            <div class="form-group row">
              <button
                type="submit"
                class="btn btn-primary"
              >
                {{ toLocal("login.restore_button") }}
              </button>
            </div>
            <strong
              v-for="notification in forgot_password_notification"
              :key="notification"
            >
              {{ toLocal(notification) }} 
            </strong>
          </form>
        </div>
        <div class="lock--block">
          <img
            src="../assets/images/lock.svg"
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Register",
    data: function(){
      return {
        errors: [],
        email: null,
        password: null,
        confirm_password: null,
        remember: false,
        forgot_password: false,
        forgot_password_email: null,
        forgot_password_notification: [],
        selectNewGroup: false,
        new_group: -1,
        auth2: null,
        register: false,
        show_password: false,
        show_password2: false,
      }
    },
    activated() {
      // This is for android WebView
      document.addEventListener('message',this.handleNativeMessage.bind(this));
      // This is for iOS WebView
      window.addEventListener('message',this.handleNativeMessage.bind(this));

      this.register = false;
      this.selectNewGroup = false;
    },
    deactivated() {
      document.removeEventListener('message',this.handleNativeMessage.bind(this))
      window.removeEventListener('message',this.handleNativeMessage.bind(this))
    },
    mounted() {
        this.$store.state.header = false;
        this.loadFacebookSDK(document, "script", "facebook-jssdk");
        this.initFacebook();
        this.initGoogle();
    },
    methods: {
        handleNativeMessage: function(e) {
          try {
            let data = JSON.parse(e.data);
            let type = data.type;
            if(type == "facebook") {
              this.innerFacbookLogin(data.access_token, data.user_id);
            }
            if(type == "google") {
              this.innerGoogleLogin(null, data.access_token);
            }
          }catch(e) {
            
          }
        },
        googleSigninNative: function(e) {
          window.ReactNativeWebView.postMessage('googleLoginClicked');
        },
        checkForm: function(e) {
          this.$store.state.loading.loadingStart();
          e.preventDefault();
          let remember_me = this.remember;
          if(window.outerWidth <= 768) {
            remember_me = true;
          }

          axios.post(`${this.$store.state.api_link}/login`, {
              email: this.email,
              password: this.password
            })
            .then((res) => {
              this.errors = [];
              this.$store.state.user_email = this.email;
              this.$store.state.user_role = res.data.token.original.role;
              this.$store.state.user_trainings_started = res.data.stats.trainings_started;
              this.$store.state.user_trainings_ended = res.data.stats.trainings_ended;
              this.$store.state.user_test_average = res.data.stats.test_average;
              if(res.data.token.original.new_user == 1){
                this.selectNewGroup = true;
                window.scrollTo(0,0);
                this.$store.state.loading.loadingDone();
              }else{
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }
              this.$store.commit("checkTerms", {getters: this.$store.getters});
            })
            .catch((err) => {
              if(err.response.data.hasOwnProperty('error')){
                this.errors = [err.response.data.error];
              }else{
                console.log(err);
              }
              this.$store.state.loading.loadingDone();
            });
        },
        hideForgotPassword: function(event) {
          this.forgot_password = false;
        },
        openForgotPassword: function() {
          setTimeout(() => {
            this.forgot_password = true;
          });
        },
        resetFormSubmit: function(e) {
          this.$store.state.loading.loadingStart();
          e.preventDefault();

          axios.post(`${this.$store.state.api_link}/forgot/password`, {
              email: this.forgot_password_email
            })
            .then((res) => {
                this.forgot_password_notification = [res.data.message];
                this.$store.state.loading.loadingDone();
            })
            .catch((err) => {
              console.log(err);
              this.$store.state.loading.loadingDone();
            });
        },
        setGroup(group) {
          if(this.register) {
            this.selectNewGroup = false;
            this.new_group = group;
          }else{
            this.$store.state.loading.loadingStart();
            axios.post(`${this.$store.state.api_link}/user/group-select`, 
            { group: group },
            this.$store.getters.axiosConfig())
            .then((res) => {
              if(res.data.status == "success"){
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
                this.selectNewGroup = false;
              }else{
                console.log("Error while setting group");
              }
            })
            .catch((err) => {
              console.log(err);
              this.$store.state.loading.loadingDone();
            });
          }
        },
        logInWithFacebook() {
          if(window.isNative){
            this.$store.state.loading.loadingStart();
            window.ReactNativeWebView.postMessage('facebookLoginClicked');
          }else{
            window.FB.login((response) => {
              if (response.authResponse) {
                this.$store.state.loading.loadingStart();
                this.innerFacbookLogin(response.authResponse.accessToken, response.authResponse.userID)
              } else {
                this.$store.state.loading.loadingDone();
              }
            }, {scope: 'email'});
          }
          return false;
        },
        innerFacbookLogin(access_token, user_id) {
          axios.post(`${this.$store.state.api_link}/login/facebook`, {
            fb_access_token: access_token, fb_user_id: user_id
          })
          .then((res) => {
            this.errors = [];
            this.$store.state.access_token = res.data.access_token;
            this.$store.state.user_email = res.data.email;
            this.$store.state.user_role = res.data.role;
            window.localStorage.setItem("access_token", res.data.access_token);
            window.sessionStorage.setItem("access_token", null);
            if(res.data.new_user == 1){
              this.selectNewGroup = true;
              window.scrollTo(0,0);
              this.$store.state.loading.loadingDone();
            }else{
              this.$store.state.loading.loadingDone();
              this.$router.push("/");
            }
            this.$store.commit("checkTerms", {getters: this.$store.getters});
          })
          .catch((err) => {
            if(err.response && err.response.data.hasOwnProperty('error')){
              this.errors = [err.response.data.error];
            }else{
              console.log(err);
            }
            this.$store.state.loading.loadingDone();
          });
        },
        async initFacebook() {
          window.fbAsyncInit = function() {
            window.FB.init({
              appId: "722489895182777", //You will need to change this
              cookie: true, // This is important, it's not enabled by default
              version: "v7.0"
            });
          };
        },
        async loadFacebookSDK(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        },
        async initGoogle() {
          const client_id = "11225176651-ofbg4ja5t5q045adhd7te59lleo8r0o9";
          gapi.load('auth2', () => {
            this.auth2 = gapi.auth2.init({
              client_id: client_id,
              cookiepolicy: 'single_host_origin',
            });
            setTimeout(() => {
              if(!window.isNative){
                this.attachGoogleSignin(document.getElementById('google-signin-btn'));
              }else{
                document.getElementById('google-signin-btn').addEventListener("click",this.googleSigninNative.bind(this))
              }
            },3000);
          });
        },
        attachGoogleSignin(element) {
          this.auth2.attachClickHandler(element, {},
            (googleUser) => {
              this.$store.state.loading.loadingStart();
              let id_token = googleUser.getAuthResponse().id_token;
              this.innerGoogleLogin(id_token, null);
            }, (error) => {
              console.log(JSON.stringify(error, undefined, 2));
          });
        },
        innerGoogleLogin(id_token, access_token) {
          this.$store.state.loading.loadingStart();
          axios.post(`${this.$store.state.api_link}/login/google`, {
            google_id_token: id_token,
            google_access_token: access_token
          })
          .then((res) => {
            this.errors = [];
            this.$store.state.access_token = res.data.access_token;
            this.$store.state.user_email = res.data.email;
            this.$store.state.user_role = res.data.role;
            window.localStorage.setItem("access_token", res.data.access_token);
            window.sessionStorage.setItem("access_token", null);
            if(res.data.new_user == 1){
              this.selectNewGroup = true;
              window.scrollTo(0,0);
              this.$store.state.loading.loadingDone();
            }else{
              this.$store.state.loading.loadingDone();
              this.$router.push("/");
            }
            this.$store.commit("checkTerms", {getters: this.$store.getters});
          })
          .catch((err) => {
            if(err.response && err.response.data.hasOwnProperty('error')){
              this.errors = [err.response.data.error];
            }else{
              console.log(err);
            }
            this.$store.state.loading.loadingDone();
          });
        },
        registerClick() {
          this.register = true;
          // this.selectNewGroup = true;
        },
        checkRegisterForm(e) {
          this.$store.state.loading.loadingStart();
          e.preventDefault();

          axios.post(`${this.$store.state.api_link}/register`, {
              email: this.email,
              password: this.password,
              confirm_password: this.confirm_password,
              group: this.new_group,
            })
            .then((res) => {
              this.errors = [];
              this.$store.state.user_email = this.email;
              this.$store.state.user_role = res.data.role;
              this.$store.state.loading.loadingDone();
              this.$router.push("/");
              this.$store.commit("checkTerms", {getters: this.$store.getters});
            })
            .catch((err) => {
              if(err.response != undefined && err.response.data.hasOwnProperty('error')){
                this.errors = [err.response.data.error];
              }else{
                console.log(err);
              }
              this.$store.state.loading.loadingDone();
            });
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_forms.scss";
@import "@/assets/scss/views/_registerViewScoped.scss";
</style>