<template>
    <!-- <UpdateModal /> -->
  <header
    v-if="
      $store.state.header &&
      $store.state.header_enabled &&
      !$store.state.reportForming.previewMode
    "
    :class="{ hidden: $store.state.header_hidden }"
  >

        <div class="logo" @click="closeMobileDrawers" v-show="$store.state.window_width >768 ? true : !$store.state.showingHeaderSearchBar">
          <router-link to="/">
            <img
              :src="
                $store.state.api_uploads_link + $store.state.system_settings['logo']
              "
              alt="Logo"
            />
          </router-link>
        </div>
      <div class="vseperator" v-if="!isMobileWindow()" />
      <div class="progress--bars" v-if="!isMobileWindow()">
        <div 
          class="progress--bar-container"
          v-for="(item, index) in progress"
          :key="index"
        >
          <div class="progress--bar">
            <div class="bar--wrapper">
              <svg viewBox="0 0 100 100" class="progress--bar-cirle">
                <path
                  fill="none"
                  stroke-linecap="round"
                  stroke-width="7"
                  class="not-filled"
                  stroke-dasharray="251.2,251.2"
                  d="M50 10
                    a 40 40 0 0 1 0 80
                    a 40 40 0 0 1 0 -80"
                />
                <path
                  fill="none"
                  class="filled transition"
                  stroke-linecap="round"
                  stroke-width="7"
                  :stroke-dasharray="`${
                    $store.state[item.data_label] * item.cf
                  },251.2`"
                  d="M50 10
                    a 40 40 0 0 1 0 80
                    a 40 40 0 0 1 0 -80"
                />
              </svg>
              <div class="percent">
                {{ Math.round($store.state[item.data_label])
                }}<span class="percent--symbol">%</span>
              </div>
            </div>
            <div class="description">
              {{ toLocal(item.description) }}
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div
          class="search--wrapper"
          v-if="
            isMobileWindow()
              ? ['main', 'home'].includes(currentRouteName) &&
                $store.state.certificates &&
                !$store.state.certificates.isOpenedCert()
              : ['main', 'home'].includes(currentRouteName) &&
                !$store.state.showHomePageSearchInput &&
                $store.state.homePageSearchLoaded
          "
          @click="$refs.search.focus()"
        ></div>
      </transition>
      <div class="right-menu-section" v-if="!isMobileWindow()">
        <div class="platform-switcher" v-if="$store.state.connectedLMSes && $store.state.connectedLMSes.length >1">
          <div class="border-left">
            <CustomDropdown
                  :list="$store.state.connectedLMSes"
                  key-label="lms_key"
                  value-label="name"
                  :compareChosen="selectedLMS"
                  :options="{
                      closeOnClick: true,
                      checkbox: false,
                      customDDWidth: '100%',
                      customMaxHeight: '300px',
                      initial: selectedLMS && selectedLMS.name ? selectedLMS.name : '',
                      containerClass: 'platform-switch-dropdown',
                      showCreateNewBtn:false
                  }"
                  @elClicked="switchPlatform($event)"
              >
                  <template v-slot:arrow >
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z" fill="#7D7D7D"/>
                    </svg>
                  </template>
              </CustomDropdown>
          </div>
        </div>
        <div class="platform-switcher text-only" v-else-if="($store.state.system_settings.siblingPlatforms && $store.state.system_settings.siblingPlatforms.length >0) && $store.state.system_settings.current_platform_name">
            {{ $store.state.system_settings.current_platform_name	 }}
        </div>
        <div class="cert-button-container">
          <div class="cert-button" @click="openCertificates()">
            <span>{{ toLocal("user_side.certificates") }}</span>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3333 13.2487C13.8981 13.2487 15.1667 11.9802 15.1667 10.4154C15.1667 8.85056 13.8981 7.58203 12.3333 7.58203C10.7685 7.58203 9.5 8.85056 9.5 10.4154C9.5 11.9802 10.7685 13.2487 12.3333 13.2487Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.4443 12.7773V17.0273L12.3332 15.6107L14.2221 17.0273V12.7773" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.61111 14.1949H2.88889C2.38792 14.1949 1.90748 13.9959 1.55324 13.6416C1.19901 13.2874 1 12.807 1 12.306V2.86154C1 1.82266 1.85 0.972656 2.88889 0.972656H16.1111C16.6121 0.972656 17.0925 1.17166 17.4468 1.5259C17.801 1.88013 18 2.36058 18 2.86154V12.306C17.9997 12.6372 17.9122 12.9626 17.7465 13.2493C17.5807 13.5361 17.3424 13.7743 17.0556 13.9399" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.83301 4.75H15.1663" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.83301 7.58203H6.66634" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.83301 10.418H5.7219" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> 
          </div>
        </div>
      </div>
      <div class="clear" v-if="!isMobileWindow()" />
    <!-- <UpdateBanner/> -->
    <UpdateBanner v-if="$store.state.checkAutoUpdate"/>

    </header>
</template>

<script>
import UpdateBanner from "@/components/layout/modals/UpdateBanner.vue";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import axios from 'axios';
export default {
  name: "Header",
  components: {
    UpdateBanner,
    CustomDropdown
  },
  data(){
    return{
      progress:[
        {
          data_label: 'user_trainings_started', cf: 2.512, description:'header.started_trainings'
        },
        {
          data_label: 'user_trainings_ended', cf: 2.512, description:'header.finished_trainings'
        }, 
        {
          data_label: 'user_test_average', cf: 2.512, description:'header.test_average'
        }
      ],
      selectedLMS: {},
    }
  },
  watch: {
    '$store.state.currentLMS'() {
      let index = this.$store.state.connectedLMSes.findIndex((lms) => lms.lms_key == this.$store.state.currentLMS);
      if (index != -1) {
        this.selectedLMS = this.$store.state.connectedLMSes[index];
      }
    },
    '$store.state.connectedLMSes.length'() {
       let index = this.$store.state.connectedLMSes.findIndex((lms) => lms.lms_key == this.$store.state.currentLMS);
      if (index != -1) {
        this.selectedLMS = this.$store.state.connectedLMSes[index];
      }
    }
  },
  mounted() {
     let index = this.$store.state.connectedLMSes.findIndex((lms) => lms.lms_key == this.$store.state.currentLMS);
      if (index != -1) {
        this.selectedLMS = this.$store.state.connectedLMSes[index];
      }
  },
  methods: {

    openCertificates() {
      this.$store.state.certificates.openCert();
    },
    closeMobileDrawers() {
      if (this.isMobileWindow()) {
        this.$store.state.certificates.closeCert();
        this.$store.state.side_open = false;
      }
    },
    switchPlatform(platform) {
      let lms_key = platform;
      let platform_item = this.$store.state.connectedLMSes.find((p) => p.lms_key == lms_key);
      this.selectedLMS = platform_item;
      axios
        .get(`${this.$store.state.api_link}/switch/platform?lms_key=${lms_key}`)
        .then((res) => {
          let backend_domain = res.data.backend_domain;
          this.$store.state.loading.loadingStart();
          this.$store.commit("setNewUrl", backend_domain);
          // this.$store.commit("fetchSystemSettings");
          this.$store.state.currentLMS = lms_key;
         
          this.$store.state.pageRerenderKey++;
          this.$store.state.loading.loadingDone();
        })
        .catch((err) => {
          let index = this.$store.state.connectedLMSes.findIndex((lms) => lms.lms_key == lms_key);
          if (index != -1) {
            this.$store.state.connectedLMSes.splice(index, 1);
          }
        });
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";
@import "@/assets/scss/components/layout/_headerScoped.scss";
.platform-switcher{
  margin: auto;
    margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.right-menu-section{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3vh;
    display: flex;
    align-items: center;
    gap: 24px;
}
.text-only{
  font-size: clamp(14px,1.5vw,16px);
  padding: 6px 8px;
  color: #333;
}
</style>
<style lang="scss">
.platform-switch-dropdown{
  background-color: white;
  box-shadow: 0 2px 2px 0px rgba(0,0,0,0.16);
  min-width: 132px;
  &.dropdown-container:not(.no-padding-no-bg){
    border:1px solid #EAEAEA;
    border-radius: 6px;
    padding: 6px 8px;
    background-color: white;
  }
  &.dropdown-container{
    .dropdown-extended .dropdown-option span{
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>