<template>
  <div class="date-picker-container" >
     <div class="label main">
        Rodyti tik šiuos:
    </div>
    <div class="row">
        <div class="label">Nuo:</div>
        <input 
            type="text" 
            v-model="from_num" 
            @blur="$emit('changeFrom',from_num)" 
            @input="(e) =>filterNonNumeric(e, 'from_num')"
        />
        <span v-if="type=='percent'">%</span>
    </div>
    <div class="row">
    <div class="label">Iki:</div>
        <input 
            type="text" 
            v-model="to_num" 
            @blur="$emit('changeTo',to_num)" 
            @input="(e) =>filterNonNumeric(e, 'to_num')"
        />
        <span v-if="type=='percent'">%</span>
    </div>
  </div>
</template>

<script>
export default {

    props:{
        from:{
            type: String,
        },
        to:{
            type: String,
        },
        type:{
            type: String,
        },
        rangeMin:{
            type: Number,
        },
        rangeMax:{
            type: Number | null,
        }
    },

    data(){
        return{
            modalIsOpen: false,
            from_num: '',
            to_num: ''
        }
    },
    mounted(){
        this.from_num = this.from
        this.to_num = this.to
    },
    watch:{
        modalIsOpen(newVal){
            this.$emit('innerPopupState',newVal)
        }
    },
    methods:{
      filterNonNumeric(event, input) {
            let inputValue = event.target.value;
            if(inputValue[0] == '0'){
                inputValue = '0';
            }
            if(this.rangeMax != null && (parseInt(inputValue) > this.rangeMax)){
                inputValue = this.rangeMax.toString();
            }
            this[input] = inputValue.replace(/[^0-9]/g, '');
        }
    }

}
</script>

<style lang="scss" scoped>
.date-picker-container{
    padding-bottom: 20px;
}
.row{
    display: flex;
    align-items: center;
    position: relative;
    color: #333;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding: 0 20px 0 8px;
    margin-top: 12px;
    .label{
        flex:1;
       
    }
    input{
        border-radius: 6px;
        border: 1px solid #B2B2B2;
        background: #FFF;
        padding: 4px 8px;
        flex:4;
        width: 100%;
    }
    span{
        position: relative;
        right: -8px;
    }
}
.label{
    display: flex;
    justify-content: flex-start;
    margin-right: 8px;
    &.main{
        margin: 8px 0 0;
        padding: 0 8px;
    }
}
</style>