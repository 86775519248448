<template>
    <div class="group-leader-page">
        <!-- <div class="general-stats">
            <GeneralStats />
        </div> -->
        <div class="information-container">
            <div class="tabs">
                <div class="group-name" v-if="$store.state.managedGroups.length ==1">
                    {{ group.name }}
                </div>
                <div class="group-name dropdown" v-else-if="$store.state.managedGroups.length > 1 && group">
                    <CustomDropdown
                        :list="$store.state.managedGroups"
                        key-label="id"
                        value-label="name"
                        :compareChosen="group ? group : $store.state.managedGroups[0]"
                        :options="{
                            closeOnClick: true,
                            checkbox: false,
                            customDDWidth: 'initial',
                            customDDMaxWidth: '250px',
                            customDDMinWidth: '100%',
                            customMaxHeight: '300px',
                            initial: group ? group.name : $store.state.managedGroups[0].name,
                            containerClass: 'leader-page-dropdown',
                            searchBar: false,
                            left:true,
                            showCreateNewBtn: false,
                            valueWidthType: 'dynamic'
                        }"
                        @elClicked="selectDropdownItem"
                    >
                        <template v-slot:arrow >
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z" fill="#7D7D7D"/>
                            </svg>
                        </template>
                    </CustomDropdown>
                </div>
                <!-- <LeaderTabs :selectedTabIndex="selectedTabIndex" :tabs="tabs" /> -->
            </div>
            <div class="content">
                <div class="users">
                    <UserList :users="users" :selectedUser="selectedUser" @selectUser="selectedUser=$event" :isLoadingUsers="isLoadingUsers"/>
                </div>
                <div class="tab-content" :class="{'no-background': selectedTabIndex == 1 && Object.keys(selectedUser).length == 0}">
                    <LeaderNotifications v-if="selectedTabIndex == 0" />
                    <GroupMembers v-if="selectedTabIndex == 1 && selectedUser && Object.keys(selectedUser).length  > 0" :user="selectedUser" :userRatings="userRatings" :isLoadingUsers="isLoadingUsers"/>
                    <!-- <GroupStats v-if="selectedTabIndex == 1 && Object.keys(selectedUser).length == 0" :userRatings="userRatings" :group="group" /> -->
                    <GroupTrainings v-if="selectedTabIndex == 2" />
                </div>
            </div>
        </div>
        <portal-target name="training-confirm-modal"></portal-target>
    </div>
</template>

<script>
import LeaderTabs from '@/components/layout/groupLeader/LeaderTabs.vue'
import LeaderNotifications from '@/components/layout/groupLeader/LeaderNotifications.vue'
import GroupMembers from '@/components/layout/groupLeader/GroupMembers.vue'
import GroupTrainings from '@/components/layout/groupLeader/GroupTrainings.vue'
import GeneralStats from '@/components/layout/groupLeader/GeneralStats.vue'
import UserList from '@/components/layout/groupLeader/users/UserList.vue'
import GroupStats from '@/components/layout/groupLeader/users/GroupStats.vue'
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

import axios from 'axios'
export default {
    components: {
        LeaderTabs,
        LeaderNotifications,
        GroupMembers,
        CustomDropdown,
        GroupTrainings,
        GeneralStats,
        UserList,
        GroupStats
    },
    data(){
        return {
            tabs: [
                {'name': 'Pranešimai'},
                {'name': 'Komandos nariai'},
                {'name': 'Mokymosi elementai'},
            ],
            selectedUserID: 1,
            selectedUser: {},
            users: [],
            selectedTabIndex: 1,
            group: -1,
            isLoadingUsers: false,
            userRatings: []
        }
    },

    mounted() {
        let groups = this.$store.state.managedGroups;
        if(groups.length > 0) {
            this.group = groups[0];
            this.getGroupUsers(this.group.id);
        }
    },
    methods: {
        getGroupUsers(group_id) {
            this.isLoadingUsers = true;
            axios.get(`${this.$store.state.api_link}/group/users/${group_id}/0/50?current_users=true`, 
            this.$store.getters.axiosConfig())
            .then((res) => {
                if(res.data.status == "success"){
                    this.users = res.data.users;
                    this.selectedUser = this.users[0];
                    this.userRatings = res.data.users.map(user => Math.round(user.user_rating, 2));
                }
                this.isLoadingUsers = false;
            })
            .catch((err) => {
                this.isLoadingUsers = false;
                
            });
        },
        selectDropdownItem(group_id) {
            let index = this.$store.state.managedGroups.findIndex((item) => item.id == group_id);
            if(index != -1) {
                this.group = this.$store.state.managedGroups[index];
                this.getGroupUsers(group_id);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.group-leader-page{
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 90vw;
    margin: auto;
    padding-top: 16px;
    max-height: calc(100vh - 11vh);
    height: calc(100vh - 11vh);
}
.tab-content{
    overflow: hidden;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 20px 20px;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.16);
    border-radius: 0 15px 15px 0;
    &.no-background{
        background-color: transparent;
        box-shadow: none;
        padding: 12px 20px;
    }
}
.content{
    display: flex;
    max-height: 100%;
    height: 100%;
    gap: 4px;
    overflow: hidden;
    padding: 6px;
}
.information-container{
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
}
.group-name{
    display: flex;
    background-color: #FAFAFA;
    padding: 2px 4px;
    color: #333333;
    font-size: clamp(12px, 1.2vw, 14px);
    border-radius: 4px;
    margin: 0 6px;
    width: fit-content;
    &.dropdown{
        background-color: transparent;
        padding: 0;
    }
}
</style>
<style lang="scss">
.leader-page-dropdown{
    background-color: #FAFAFA;
    border-radius: 4px;
    &.dropdown-container:not(.no-padding-no-bg){
        background-color: #FAFAFA;
        padding: 2px 4px;
        .dropdown-initial{
            gap: 8px;
            span{
                max-width: 85%;
            }
        }
    }
}
</style>