<template>
<div>
    <div class="periodic-report-container" v-if="reportItem && reportItem.name">
        <div class="title-container">
            <input type="text" :placeholder="toLocal('generic.enter_name')" v-model="reportItem.name"> 
        </div>
        <div class="option-container">
            <div class="recipient-container">
                <span class="label">{{toLocal('report.select_recipients')}}</span>
                <div class="email-container" @click="$refs.emailInput.focus()">
                    <div class="email-tag" v-for="(email, email_index) in reportItem.data.periodicReportEmails" :key="email_index" @click="reportItem.data.periodicReportEmails.splice(email_index, 1)">
                        {{ email }}
                    </div>
                    <div class="email-input-container">
                        <input ref="emailInput" type="text" :placeholder="toLocal('report.email')" v-model="emailInput" @keypress.enter="addEmail" @keypress.space="addEmail" @blur="addEmail">
                    </div>
                </div>
            </div>
            <!-- <div class="frequent-domains">
                <div class="domain" v-for="(domain_item, index) in $store.state.reportForming.frequentDomains" :key="index" @click="appendEmail(domain_item)">
                    @{{ domain_item.domain }}
                </div>
            </div> -->
            <div class="report-timing-container">
                
                <div class="row" style="z-index:2">
                    <span class="row-label">
                        {{toLocal('report.send_every')}}
                    </span>
                    <div class="input-container">
                        <input type="text" v-model="reportItem.data.periodicReportTiming.formEvery" >
                    </div>
                    <div class="select-container" v-click-outside="closeDropdownEvery" @click="dropdownEveryActive= !dropdownEveryActive">
                        <CustomDropdown
                            :list="dropdown"
                            key-label="id"
                            value-label="label"
                            :compare-list="[]"
                            :options="{
                                closeOnClick: true,
                                customDDWidth: 'initial',
                                customDDMaxWidth: '250px',
                                customDDMinWidth: '100%',
                                customMaxHeight: '250px',
                                initial: getLabelEnd(reportItem.data.periodicReportTiming.formEvery, reportItem.data.periodicReportTiming.formEveryType),
                                containerClass: 'report-dropdown',
                                searchBar: false,
                                checkbox: false,
                                left:true,
                                showCreateNewBtn: false,
                                valueWidthType: 'dynamic'
                            }"
                            @elClicked="selectEveryType"
                        >
                            <template v-slot:arrow >
                                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.5L6 6.5L11 1.5" stroke="#333333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </template>
                        </CustomDropdown>
                    </div>
                </div>
                <div class="row">
                    <span class="row-label">{{toLocal('report.start_sending_report')}}</span>
                    <div class="date-picker">
                        <date-picker
                            v-model="reportItem.data.periodicReportTiming.date" 
                            class="send-start-date"
                            label="date"
                            format = "YYYY-MM-DD"
                            placeholder="2022-01-01"
                            :disabledDate="disabledDates"
                            valueType="format"
                        >
                            <template v-slot:icon-calendar>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.716396 15.985L4.43836 15.1511H4.46955C4.64427 15.1509 4.81724 15.1162 4.97853 15.0491C5.13982 14.9819 5.28626 14.8836 5.40946 14.7598L15.6033 4.57007C15.8552 4.31716 15.9966 3.97483 15.9966 3.61797C15.9966 3.26111 15.8552 2.91878 15.6033 2.66587L13.3357 0.39312C13.0827 0.141349 12.7402 0 12.3832 0C12.0262 0 11.6837 0.141349 11.4307 0.39312L1.23081 10.5889C1.10587 10.7141 1.00775 10.8635 0.942445 11.0279C0.877135 11.1923 0.846 11.3683 0.850937 11.5451L0.0129728 15.2823C-0.00415802 15.3662 -0.00415802 15.4526 0.0129728 15.5364C0.029398 15.6126 0.0608234 15.6847 0.105422 15.7487C0.15002 15.8126 0.206899 15.867 0.272741 15.9087C0.367633 15.9687 0.477468 16.001 0.589771 16.0018C0.633048 16.0026 0.67625 15.9978 0.71826 15.9873M12.0215 0.98411C12.1174 0.88863 12.2473 0.835027 12.3827 0.835027C12.5181 0.835027 12.648 0.88863 12.744 0.98411L15.0121 3.2564C15.0602 3.3031 15.0984 3.35903 15.1244 3.42084C15.1504 3.48264 15.1637 3.54906 15.1634 3.61611C15.1633 3.68351 15.1499 3.75024 15.1239 3.81244C15.0979 3.87465 15.0599 3.93112 15.0121 3.97861L14.0293 4.96096L11.0359 1.96878L12.0215 0.98411ZM2.09578 10.9058L10.4447 2.55838L13.4381 5.55055L5.08918 13.8979L2.09578 10.9058ZM1.28668 13.4354L1.68099 11.6736L4.32895 14.3205L2.43516 14.743L1.28668 13.4354Z" fill="#7D7D7D"/>
                                    <path d="M4.57455 11.8387C4.51977 11.839 4.46549 11.8283 4.41494 11.8072C4.3644 11.7861 4.31862 11.755 4.28033 11.7158C4.20215 11.6374 4.15826 11.5311 4.15826 11.4204C4.15826 11.3096 4.20215 11.2033 4.28033 11.1249L10.6358 4.77241C10.6744 4.7331 10.7204 4.70187 10.7711 4.68055C10.8219 4.65923 10.8764 4.64825 10.9314 4.64825C10.9865 4.64825 11.041 4.65923 11.0918 4.68055C11.1425 4.70187 11.1885 4.7331 11.2271 4.77241C11.3052 4.8509 11.3491 4.95715 11.3491 5.06791C11.3491 5.17867 11.3052 5.28492 11.2271 5.3634L4.87156 11.7158C4.83272 11.755 4.78644 11.7861 4.73544 11.8072C4.68444 11.8283 4.62974 11.839 4.57455 11.8387Z" fill="#7D7D7D"/>
                                </svg>
                            </template>
                        </date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="col"  v-if="(reportItem.data.reportData && reportItem.data.reportData[0] && reportItem.data.reportData[0].searchApi)">
            <span class="table-label"  ><strong>{{toLocal('report.choose_criteria') }} </strong></span>
            <ReportDataTable  :reportDataArray="reportItem.data.reportData"/>
        </div>
        <div class="col" v-else>
            <div class="row mb-0">
                <span class="row-label">{{toLocal('generic.data_filter')}}</span>
            </div>
            <ReportFilter 
                :key="reportItem.id"
                :selectedFilter="selectedFilter"
                @saveFilter="saveFilter"
            />

        </div>
        <div class="column-filter">
            <ShownColumnsRow
            :key="reportItem.id"
            ref="shownCols"
            :selectedShownColumns="reportItem.data.selectedShownColumns"
            @selectShownColumns="reportItem.data.selectedShownColumns=$event"
            />
        </div>
        <FormReportButtons :report="reportItem" formType="periodic" :activeForm="reportItem.data.reportData.length > 0"  :activeFormed="$store.state.reportForming.periodicReportHTML != ''"/>
    </div>
    <div class="pick-report" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="79.91" height="77.49" viewBox="0 0 79.91 77.49">
            <rect id="Rectangle_3273" data-name="Rectangle 3273" width="70" height="17" transform="translate(9.91 30.745)"/>
            <rect id="Rectangle_3274" data-name="Rectangle 3274" width="13" height="54.522" transform="translate(38.553) rotate(45)"/>
            <rect id="Rectangle_3275" data-name="Rectangle 3275" width="13" height="54.522" transform="translate(0 38.938) rotate(-45)"/>
        </svg>

        <h3>{{toLocal('report.choose_template')}}</h3>
    </div>
</div>
</template>

<script>
import FormReportButtons from "./FormReportButtons.vue";
import ReportDataTable from "./ReportDataTable.vue";
import DatePicker from 'vue2-datepicker';
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import ConditionFilter from "@/components/layout/groups/ConditionFilter.vue";
import ReportFilter from "./ReportFilter.vue";
import ShownColumnsRow from "./ShownColumnsRow.vue";

import moment from "moment";
import axios from 'axios';
export default {
    name: "ReportPeriodicContent",
    components: {
        FormReportButtons,
        DatePicker,
        ReportDataTable,
        CustomDropdown,
        ConditionFilter,
        ReportFilter,
        ShownColumnsRow
    },

    data() {
        return {
            dropdown:[
                {id: 0, label: this.toLocal('generic.days')},
                {id: 1, label: this.toLocal('generic.weeks')},
                {id: 2, label: this.toLocal('generic.months')},
                {id: 3, label: this.toLocal('generic.years')},
            ],
            dropdown_every:[
                {id: 0, label: this.toLocal('generic.days')},
                {id: 1, label: this.toLocal('generic.weeks')},
                {id: 2, label: this.toLocal('generic.months')},
                {id: 3, label: this.toLocal('generic.years')},
            ],
           
            dropdownActive: false,
            dropdownEveryActive: false,
            reportItem: {},
            filter: [],
            selectedFilter: [],
            additional_user_data: [],
            includable_columns: [],
            emailInput: '',
            emailRegex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        };
    },
    computed: {
        computedSelectedColumns() {
            if(this.reportItem.data && this.reportItem.data.selectedShownColumns)
                return this.shownColumns.filter(el => this.reportItem.data.selectedShownColumns.includes(el.value));
            else return []
        }
    },
    watch:{
        '$store.state.reportForming.selectedItem':{
            immediate: true,
            handler(newVal, oldVal){
                let report = this.$store.state.reportForming;
                let folder_index =report.reportFolderList.findIndex(el => el.id == report.selectedFolder);
                if (folder_index != -1) {
                    this.reportItem = report.reportFolderList[folder_index].reportList.find(el => el.id == report.selectedItem);
                    this.selectedFilter = this.reportItem.data.reportData;
                }
            }
        },
        'reportItem.data.periodicReportEmails.length':{
            immediate: true,
            handler(newVal, oldVal){
                if( this.reportItem && this.reportItem.data){
                    let emails= this.reportItem.data.periodicReportEmails.filter((el)=>{
                        return el.match(this.emailRegex) !== null ? true : false;
                    });
                    let frequent_emails_from_input = this.getFrequentDomains(emails);
                    frequent_emails_from_input = frequent_emails_from_input.filter((el)=>{
                        return this.$store.state.reportForming.frequentDomains.map(elem=>elem.domain).includes(el.domain) ? false : true;
                    });
                    if(frequent_emails_from_input.length > 0){
                        let new_domain =  this.getFrequentDomains(emails)[0];
                        if(this.$store.state.reportForming.frequentDomains.findIndex(el=>el.domain == new_domain.domain) == -1){
                            this.$store.state.reportForming.frequentDomains[2] = new_domain;
                        }
                    }
                }
            }
        },
       
        'reportItem':{
            deep: true,
            immediate:true,
            handler(newVal){
                let folder_id = this.$store.state.reportForming.selectedFolder;
                let folder_index = this.$store.state.reportForming.searchReportFolderList.findIndex(el => el.id == folder_id);
                if(folder_index != -1){
                    let folder = this.$store.state.reportForming.searchReportFolderList[folder_index];
                    let report_index = folder.reportList.findIndex(el => el.id == this.reportItem.id);
                    this.$set(folder.reportList, report_index, newVal);
                }
                if (this.reportItem && this.reportItem.data && (!this.reportItem.data.selectedShownColumns || this.reportItem.data.selectedShownColumns.length == 0)) {
                    let ref = this.$refs.shownCols;
                    if(ref)
                        this.reportItem.data.selectedShownColumns = this._.cloneDeep(ref.shownColumns.map(el => el.value));
                }
 
            }
        }
    },
    mounted(){
        let report = this.$store.state.reportForming;
        let folder_index =report.reportFolderList.findIndex(el => el.id == report.selectedFolder);
        if (folder_index != -1) {
            this.reportItem = report.reportFolderList[folder_index].reportList.find(el => el.id == report.selectedItem);
            this.selectedFilter = this.reportItem.data.reportData;
            // this.setReportData();
        }
        if (this.reportItem && this.reportItem.data && (!this.reportItem.data.selectedShownColumns || this.reportItem.data.selectedShownColumns.length == 0)) {
            let ref = this.$refs.shownCols;
            if(ref)
                this.reportItem.data.selectedShownColumns = this._.cloneDeep(ref.shownColumns.map(el => el.value));
        }
 
    },
    methods: {
        addEmail(e) {
            let email = (e.target.value).trim();
            if (email.length == 0) return;
            if(this.validateEmail(email)){
                e.target.value = "";
                this.emailInput = '';
                this.reportItem.data.periodicReportEmails.push(email);
            }
            return
            // this.$store.commit("addPeriodicReportEmail", this.reportItem, email);
        },
        validateEmail(email){
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        closeDropdown() {
            this.dropdownActive = false;
        },
        closeDropdownEvery() {
            this.dropdownEveryActive = false;
        },
        getFrequentDomains(emails){
            let temp = [];
            for(let i = 0; i < emails.length; i++){
                let obj = {};
                let domain = emails[i].split('@')[1];
                let index = temp.findIndex(el => el.domain == domain);
                if(index == -1){
                    obj.domain = domain;
                    obj.NUM = 1;
                    if(domain != null)
                        temp.push(obj);
                }
                else{
                    temp[index].NUM++;
                }
            }
            return temp;
        },
        
        disabledDates(time) {
            var date = moment(String(time)).format('YYYY-MM-DD');
            var d1 =moment().format('YYYY-MM-DD');
            if (date>=d1) {
                return false;
            }
            else return true;
        },
      
        getLabelEnd(number, id){
            if(number == undefined) number = 0;
            if(id == undefined) id = 0;
            
            if(number !== null && number == undefined){
                return '';
            }
            let text_array = [];
            if(id == 0)  text_array = [this.toLocal('report.day_single'), this.toLocal('report.days_what'), this.toLocal('report.days_who')];
            if(id == 1)  text_array = [this.toLocal('report.week_single'), this.toLocal('report.weeks_what'), this.toLocal('report.weeks_who')];
            if(id == 2) text_array = [this.toLocal('report.month_single'), this.toLocal('report.months_what'), this.toLocal('report.months_who')];
            if(id == 3)  text_array = [this.toLocal('report.years_single'), this.toLocal('report.years_single'), this.toLocal('generic.years_who')];
            let number_array = number.toString().split('');
            let last_number = number_array[number_array.length - 1];
            if(last_number == 0) return text_array[2];
            else if(parseInt(number)> 10 && parseInt(number)<= 19) return text_array[2];
            else if(last_number == 1) return text_array[0];
            else if(last_number> 1 && last_number<= 9) return text_array[1];
        },
        appendEmail(domain_item){
            let temp_input = this.emailInput;
            if(temp_input == '') return;
            temp_input = temp_input.trim().replaceAll('@', '');
            temp_input+='@'+domain_item.domain
            this.emailInput = temp_input;
            this.addEmail({target:{value:temp_input}});
            this.emailInput = '';
        },
        selectEveryType(id) {
            this.$set(this.reportItem.data.periodicReportTiming,'formEveryType', id)
        },
        saveFilter(filter) {
            this.selectedFilter = filter;
            this.$set(this.reportItem.data,'reportData', filter);
        },
       
    
       
    },
}
</script>

<style lang="scss" scoped>
.periodic-report-container{
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.title-container{
    width: 100%;
    color: var(--primary-color);
    padding: 0.5rem;
    border-bottom: 2px solid #7D7D7D;
    // margin-bottom: 1rem;
    input{
        background: none;
        width: 100%;
        border: none;
        font-size: clamp(20px, 1.2vw, 24px);
        color: var(--primary-color);
    }
}
.recipient-container{
    .label{
        width: 100%;
        text-align: center;
        font-size: clamp(14px, 2vw, 16px);
        color:  #333;
        font-family: var(--main-site-font);
        font-weight: 600;
    }
}
.email-container{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    background: white;
    padding: 0.5rem 0.75rem;
    margin: 1rem 0 1rem;
    color: var(--primary-color);
    overflow: hidden;
    border-radius: 8px;
    background: var(--balta, #FFF);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.20) inset;
    &::after{
        position: absolute;
        content: '';
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1rem;
        background: linear-gradient(360deg, #faf9f9 17%, transparent 43%);
    }
    .email-tag{
        position: relative;
        display: flex;
        background: #efefef;
        border-radius: 2rem;
        padding: 2px 10px;
        margin: 2px 4px;
        font-size: clamp(12px, 1vw, 16px);
        overflow: hidden;
        cursor: pointer;
        &:hover{
            &::before{
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                content: '—';
                font-weight: 900;
                left: 0%;
                top: 0%;
                width: 100%;
                height: 100%;
                color: rgb(255, 255, 255);
                background: #333333b0;
            }
        }
    }
    input{
        width: 100%;
        min-width: 5rem;
        background: none;
        border: none;
        height: 100%;
    }
}
.input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    // padding:0.25rem;
    // width: 2rem;
    max-width: 64px;
    // border: 1px solid var(--primary-color);
    input{
        border: none;
        background: none;
        text-align: center;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        padding: 6px;
        min-height: 32px;
        background: #FFF;
        min-width: 56px;
        font-size: clamp(14px, 1.5vw, 16px);
        width: clamp(44px, 3vw, 64px);
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.20) inset;
        &:focus{
            outline: none;
        }
    }
}
.report-timing-container{
    }
.email-input-container{
    input{
        &:focus{
            outline: none;
        }
    }
}
.label{
    color: var(--primary-color);
}
.row-period{
    padding-top: 20px;
    margin-top: 30px;
    border-top: 1px solid white;
}
.row{
    display: flex;
    position: relative;
    align-items: center;
    margin: 1rem 0;
    gap: 12px;
    color: var(--primary-color);
    .row-label{
        color: #333;
        font-family: var(--main-site-font);
        font-size: clamp(14px, 2vw, 16px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }
}

.select-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 1rem;
    min-width: 6rem;
    z-index: 3;
    cursor: pointer;
    .dropdown-icon{
        display: flex;
    }
    .label{
        font-size: clamp(16px, 1vw, 18px);
        color: var(--primary-color);
        width: 100%;
        text-align: center;
        
    }
    .select-button{
        display: flex;
        background: white;
        align-items: center;
        padding: 0.15rem 0.15rem;
        border-radius: 6px;
        border: 1px solid #B2B2B2;
        background: #FFF;
        justify-content: space-between;
        width: 100%;
        
        z-index: 2;
    }
}
.mb-0{
    margin-bottom: 0;
}
.option-container{
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0.5rem;
    border-bottom: 1px solid #FFF;
}
.table-label{
    margin-top: 1rem;
    color: var(--primary-color)
}
.col{
    border-bottom: 1px solid #FFF;
    padding-bottom: 28px;
}
.date-picker{
    display: flex;
    background: white;
    border-radius: 6px;
    border: 1px solid #B2B2B2;
    // border: 1px solid var(--primary-color);
}
.dropdown-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    border-radius: 0.5rem;
    position: absolute;
    left: 0%;
    top: 50%;
    padding: 0.25rem;
    padding-top: 1rem;
    background: white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .item{
        &:hover{
            font-weight: 700;
        }
    }

}
.pick-report{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 1rem 2rem;
    color: var(--primary-color);
    svg{
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.5rem;
        rect{
            fill: var(--primary-color);
        }
    }
}
.frequent-domains{
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    .domain{
        display: flex;
        align-items: center;
        text-align: center;
        font-size: clamp(14px, 2vw, 16px);
        color:  #333;
        font-family: var(--main-site-font);
        font-weight: 600;
        background: white;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        color: var(--primary-color);
        cursor: pointer;
    }
}
.column-filter{
    .row{
        gap: 12px
    }
    .row-label{
        text-align: end;
    }
   
}
 .report-dropdown{
    background-color: white;
    border-radius: 6px;
    border: 1px solid #B2B2B2;
    background: #FFF;
    .white{
        path{
            stroke: var(--primary-color);
        }
    }
    &:not(.no-padding-no-bg){
        background-color: white;
    }
}

</style>
<style lang="scss">
.send-start-date{
    background: none;
    width: 6rem;
    .mx-input{
        background: none;
        border: none;
        text-align: left;
        padding: 4px 8px;
        color:rgba(51, 51, 51, 1);
        &:focus{
            outline: none;
        }
    }
    .mx-icon-calendar{
        display: flex;
        color: rgba(51, 51, 51, 1);
        path{
            fill: rgba(51, 51, 51, 1);
        }
    }
}
.condition-report-container{
    .condition-filter-dropdown{
        background-color: white;
        &.dropdown-container:not(.no-padding-no-bg){
        background-color: white;

        }
    }
    .first-row-add-label{
        overflow: hidden;
        color: #333;
        text-overflow: ellipsis;
        font-family: var(--main-site-font);
        font-size: clamp(14px, 1.5vw, 16px)
    }
}
</style>