<template>
  <div class="test-folder-container">
          <div class="folder-container parent">
              <draggable 
                v-model="testList" 
                group="folders" 
                animation="200"
                ghostClass="ghost"
                :options="{ disabled: !movingFolders || dragDisabled}"
                @start="isDraggingFolders=true" 
                @end="isDraggingFolders=false"
               
                >
                 <transition-group type="transition" :name="!isDraggingFolders ? 'flip-list' : null">
                    <div
                        v-for="(folder, folder_index) in testList" 
                        :key="folder_index"
                        class="folder"
                    >
                        <Folder 
                        :folder="folder"
                        itemLabel="tests"
                        :activeTest="activeTest"
                        :selectedFolder="selectedFolder"
                        :selectedTestID="selectedTestID"
                        @disableFolderDrag="dragDisabled = $event" 
                        @duplicateFolder="duplicateFolder" 
                        @duplicateTemplate="duplicateTemplate"
                        @setTest="(test, folder) => $emit('setTest', test, folder)"
                        @selectFolder="selectCurrentFolder"
                        @selectModeSelectItem="(folder, test) => $emit('selectModeSelectItem', folder, test)"
                        @moveTest="moveTest"
                        />
                    </div>
                </transition-group>
            </draggable>
          </div>
  </div>
</template>

<script>
import Folder from './Folder.vue'
import draggable from 'vuedraggable'
import axios from 'axios'
export default {
    name: 'TestFolders',
    components:{
        Folder,
        draggable
    },
    props:{
        testList: {
            type: Array,
            default: function () {
                return []
            }
        },
        selectedTestID:{
            type: Number,
        },
        selectedFolder:{
            type: Number,
        },
        activeTest:{
           type: Number,
        }
    },
    data(){
        return{
            isDraggingFolders: false,
            searchInput: '',
            folderList: [],
            dragDisabled: false,
            movingFolders: false,
        }
    },
    watch:{
       
        searchInput(newValue){
            this.searchTemplates(newValue);
        }
    },
    mounted(){
        let folder = this.testList.find(el=>el.tests.findIndex(test=>test.id == this.activeTest) != -1);
        if(folder != undefined){
            this.$emit('selectFolder', folder.id)
        }
    },
    methods:{
        createFolder(){
            let id = 0;
            if( this.$store.state.reportForming.reportFolderList.length > 0){
                id = Math.max(0, ...this.$store.state.reportForming.reportFolderList.map(el => el.id))
            }
            let folder = {
                id: id+1,
                name: this.toLocal('mail.new_folder'),
                reportList: [],
                editingTitle: false,
            }
            axios.post(
                    `${this.$store.state.api_link}/report-folders`,
                    {
                        folder: folder,
                    },
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    this.$set(folder, 'id', response.data.folder.id);
                     let temp_list  = this._.cloneDeep(this.$store.state.reportForming.reportFolderList);
                    this.$store.state.reportForming.reportFolderList.unshift(folder);
                    let temp_folder = this._.cloneDeep(folder);
                    temp_folder.editingTitle = true;
                    temp_list.unshift(temp_folder);

                    this.$set(this.$store.state.reportForming, 'searchReportFolderList', this._.cloneDeep(temp_list));
                    // folder.reportList = [];
                });
            this.searchInput = '';
        },
        
        duplicateFolder(folder){
            axios.post(
                `${this.$store.state.api_link}/report-folders/duplicate`,
                {
                    folder_id: folder.id,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
           
                this.$store.state.reportForming.reportFolderList.unshift(response.data.folder);
                this.$store.state.reportForming.searchReportFolderList = this._.cloneDeep(this.$store.state.reportForming.reportFolderList);
                
            });
        
        },
        duplicateTemplate(item, folder){
            axios.post(
                `${this.$store.state.api_link}/test-question-groups/duplicate`,
                {
                    item: item,
                    folder: folder.id
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                let new_test = response.data.test_questions_groups;
                new_test.questions.forEach(question => {
                    this.$set(question, 'expanded', false);
                    this.$set(question, 'editing', false);
                });
                this.testQuestionsLast.push(JSON.stringify(new_test,this.censorJson(new_test)));
                let index = this.testQuestions.findIndex(folder => folder.id == this.selectedFolder);
                this.testQuestions[index].tests.unshift(new_test);
                index = this.searchTestQuestions.findIndex(folder => folder.id == this.selectedFolder);
                this.searchTestQuestions[index].tests.unshift(new_test);
                this.setTestQuestions(scrolledTopic, response.data.test_questions_groups.id, this.selectedFolder);
                this.$emit('setSearchTestQuestions', this.searchTestQuestions);
                    // folder.reportList = [];
                // let currentFolderIndex = this.$store.state.reportForming.reportFolderList.findIndex(el => el.id == folder.id);
                // this.$store.state.reportForming.reportFolderList[currentFolderIndex].reportList.unshift(response.data.item);
                // this.$store.state.reportForming.searchReportFolderList = this._.cloneDeep(this.$store.state.reportForming.reportFolderList);
            });
        
        },
      
        updateItemListEvent(e){
            console.log(e);
        },
        searchTemplates(input){
            this.$store.state.reportForming.searchReportFolderList = this._.cloneDeep(this.$store.state.reportForming.reportFolderList);
            if(input != ''){            
               this.$store.state.reportForming.searchReportFolderList = this.$store.state.reportForming.searchReportFolderList.filter((el) => {
                    let filter_report = [];
                    if(el.reportList){
                            filter_report = el.reportList.filter(el => {
                                return el.name.toLowerCase().includes(input.toLowerCase());
                            });
                    }
                    if(filter_report.length > 0 || el.name.toLowerCase().includes(input.toLowerCase())){
                        return true;
                    }
                    else{
                        return false;
                    }
                });
            }
        },
        selectCurrentFolder(e){
            this.$emit('selectFolder', e)
        },
        moveTest(item, folder_id){
            let folder_index = this.testList.findIndex(el => el.id == folder_id);
            if(folder_index != -1){
                let item_index = this.testList[folder_index].tests.findIndex(el => el.id == item.id);
                if(item_index != -1){
                    this.testList[folder_index].tests.splice(item_index, 1);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.test-folder-container{
   display: flex;
    gap: 1rem;
    width: 100%;

    height: 100%;
    padding-top: 2rem;
    // overflow: auto;
    position: relative;
}
.row{
    display: flex;
    flex-direction: row;
}
.btn{
    grid-area: btn;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem;
    background: white;
    cursor: pointer;
    user-select: none;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 0.75rem;
    &:hover{
        font-weight: 700;
    }
    &:active{
        background: var(--secondary-color);
    }
    .icon{
        margin: 0 0.25rem;
        margin-right: 0.5rem;
        svg{
            path{
                fill: none;
                stroke: var(--primary-color);
            }
        }
    }
    &:nth-child(3){
        grid-area: btn2;
    }
    @media (max-width: 1450px){
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        span{
             white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
    }
}
.search-container{
    grid-area: search;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    .search-icon{
        display: flex;
        margin-right: 0.25rem;
        height: 100%;
        align-items: center;
        .remove{
            display: flex;
            position: relative;
            width: 1rem;
            height: 100%;
            cursor: pointer;
            transition: transform 0.3s;
            span{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 80%;
                height: 2px;
                background: var(--primary-color);
                border-radius: 1rem;
                transform: rotate(45deg);
            }
            span:last-child{
                transform: rotate(-45deg);
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    svg{
        path{
            fill: var(--primary-color);
        }
    }
    input{
        background: none;
        width: 100%;
        height: 100%;
        border: none;
        &:focus{
            outline: none;
        }
    }
}
.folder-container{
    grid-area: f;
    display: flex;
    flex-direction: column;
    width: 100%;
    &.parent{
        position: absolute;
    }
}
.folder{
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: var(--secondary-color-opacity-50);
}
@media(max-width:1280px){
    .folder{
        margin-bottom: 6px;
    }
}
</style>
<style lang="scss">
.test--questions--list{
    display: flex;
    flex-direction: column;
    // position: absolute;
    overflow: visible;
    // left: -3rem;
    height: 100%;
    width: calc(100% + 3rem);
    margin-left: -3rem;
}
</style>