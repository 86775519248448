<template>
  <div class="home-container">
    <AssignModal
      v-if="selectedTraining"
      v-scroll-lock="selectedTraining"
      type="training"
      :item="selectedTraining"
      @modalClose="selectedTraining=null"
    />
    <section name="home" class="home-section">
      <HeaderButtons
        :options="{
          showCreateBtn: false, 
          showBackBtn:true,
          mode: 'edit'
        }"
        @goBack="goBack"
      />
      <!-- <TrainingGroupsSide :training="selectedTraining" /> -->
      <div
        :class="{
          'container wide--container main--container': true,
          'side--open': $store.getters.isSideOpen(),
          loaded: loaded,
        }"
      >
        <div class="folder-title">
          <span>{{ toLocal(trainingGroup.name) }}</span>
        </div>

        <div
          class="card-container"
          :class="{ flexStart: trainingGroup.trainings.length < 5 }"
          v-if="trainingGroup != ''"
        >
          <draggable
            class="inside-container"
            v-model="trainingGroup.trainings"
            animation="200"
            @end="onTrainingListChange(trainingGroup)"
          >
            <transition-group type="transition" class="centering">
              <div
                v-show="trainingGroup != undefined"
                v-for="(training, trainingIndex) in trainingGroup.trainings"
                :key="`training-${trainingGroup.id}-${trainingIndex}`"
                :class="[
                  'card',
                  $store.getters.hasPermission('training.*')
                    ? 'with--actions'
                    : '',
                ]"
              >
                <div
                  class="over-cover"
                  v-if="training.enabled == 0 || training.expired == 1"
                  :class="{ fadedCover: training.expired }"
                >

                  <TrainingEyeSvg />
                </div>
                <div class="tag-container">
                    <span class="disabled-tag" v-if="training.enabled == 0 || training.expired == 1">{{
                      toLocal("generic.disabled")
                    }}</span>
                    <span class="disabled-tag" v-if="training.rented == 1 && training.purchase_type == 2">{{
                      toLocal("generic.rented")
                    }}</span>
                    <span class="disabled-tag" v-if="training.purchase_type == 0">{{
                      toLocal('training_shop.free')
                    }}</span>
                    <span
                      class="time-tag"
                      v-if="training.valid_until != undefined"
                    >
                      <span v-if="training.expired != 1">{{
                        toLocal("generic.remaining")
                      }} </span>
                      {{ showExpiryDays(training.valid_until)
                      }}<span v-if="training.expired != 1"> d.</span></span
                    >
                </div>
                <div
                  class="cover"
                  :style="[
                    training.data.cover != ''
                      ? {
                          'background-image': `url('${getFileUrl(
                            training.data.cover
                          )}')`,
                        }
                      : {},
                  ]"
                  @click="openTraining(training.id, training)"
                >
                  <div class="play-button" v-if="training.enabled != 0">
                    <TrainingEyeSvg />
                  </div>
                  <div class="title">
                    <span class="card-title">{{ training.data.title }}</span>
                    <div class="slider">
                      <span v-if="training.data.time != 0" class="slider--time">
                        {{ getTrainingTime(training.data.time) }}
                      </span>
                      <div class="slider--bar">
                        <div class="slider--fill" style="width: 0%" />
                        <div class="slider--blob" />
                      </div>
                    </div>
                  </div>
                </div>
             
                <div
                  v-if="!$store.getters.hasPermission('training.*')"
                  class="description"
                >
                  {{ training.data.description }}
                </div>
                <div
                  class="option-dots"
                  v-if="training.id != -1"
                  @click.stop="
                    activeOptionMenu == training.id
                      ? closeMenus()
                      : activeOptionMenu == -50
                      ? (activeOptionMenu = training.id)
                      : (activeOptionMenu = -50)
                  "
                >
                  <OptionDotsSvg class="themable"/>
                </div>
                <div
                  class="option-menu-bought"
                  v-click-outside-2="closeMenus"
                  v-if="
                    $store.state.user_role == 'admin' &&
                    activeOptionMenu == training.id"
                >
                  <div class="color option-block" v-if="!training.rented == 1" @click.stop="editTraining(training.id, training)">
                    <HomeEditEditTrainingSvg class="stroke" />
                  </div>
                  <div class="pencil option-block" v-if="training.expired != 1" @click.stop="openAssignModal(training)">
                    <HomeEditOpenGroupSvg class="stroke" />
                  </div>
                  <div class="trash option-block" @click.stop="trainingStats(training.id, training.rented)">
                    <HomeEditStatsSvg class="stroke fill-gray" />
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import VueScrollTo from "vue-scrollto";
import TrainingGroupsSide from "../components/layout/trainings/TrainingGroupsSide";
import _ from "lodash";
import { ObserveVisibility } from "vue-observe-visibility";
import HeaderButtons from "@/components/layout/HeaderButtons.vue";

//SVG
import HomeEditSearchSvg from "@/assets/images/icons/homeEdit/home-edit-search.svg?inline";
import HomeEditSearchCancelSvg from "@/assets/images/icons/homeEdit/home-edit-search-cancel.svg?inline";
import HomeEditAddSvg from "@/assets/images/icons/homeEdit/home-edit-add.svg?inline";
import HomeEditDeleteSvg from "@/assets/images/icons/homeEdit/home-delete-widthless.svg?inline";
import HomeEditArrowDownSvg from "@/assets/images/icons/homeEdit/home-edit-arrow-down.svg?inline";
import HomeEditArrowUpSvg from "@/assets/images/icons/homeEdit/home-edit-arrow-up.svg?inline";
import HomeEditPlaySvg from "@/assets/images/icons/homeEdit/home-edit-play.svg?inline";
import HomeEditEditTrainingSvg from "@/assets/images/icons/homeEdit/home-edit-widthless.svg?inline";
import HomeEditOpenGroupSvg from "@/assets/images/icons/homeEdit/home-edit-group-widthless.svg?inline";
import HomeEditStatsSvg from "@/assets/images/icons/homeEdit/home-edit-stats-widthless.svg?inline";
import HomeEditDeleteTrainingSvg from "@/assets/images/icons/homeEdit/home-edit-delete-training.svg?inline";
import PlusNarrowSvg from "@/assets/images/icons/plus-narrow-white.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
import LoadingBubbleSvg from "@/assets/images/icons/loading-bubble.svg?inline";
import ArrowLeftSvg from "@/assets/images/icons/homeEdit/arrow-go-back-16-8.svg?inline";
import TrainingDisabledSvg from "@/assets/images/icons/training-edit/training_disabled.svg?inline";
import TrainingEyeSvg from "@/assets/images/icons/training-edit/training_eye.svg?inline";
import AssignModal from "../components/layout/modals/AssignModal.vue";
import OptionDotsSvg from "@/assets/images/icons/option-dots-edit.svg?inline";

export default {
  name: "BoughtTrainings",
  components: {
    OptionDotsSvg,
    draggable,
    TrainingGroupsSide,
    HeaderButtons,
    //SVG COMPONENTS
    HomeEditSearchSvg,
    HomeEditSearchCancelSvg,
    HomeEditAddSvg,
    HomeEditDeleteSvg,
    HomeEditArrowDownSvg,
    HomeEditArrowUpSvg,
    HomeEditPlaySvg,
    HomeEditEditTrainingSvg,
    HomeEditOpenGroupSvg,
    HomeEditStatsSvg,
    HomeEditDeleteTrainingSvg,
    PlusNarrowSvg,
    GroupSearchSvg,
    LoadingBubbleSvg,
    ArrowLeftSvg,
    TrainingDisabledSvg,
    TrainingEyeSvg,
    AssignModal,
  },
  directives: {
    "click-outside-editing-title": {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            let index = vnode.data.ref.split("-")[1];
            vnode.context.setGroupTitle(index, vnode.elm.value);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
    ObserveVisibility,
  },

  data: function () {
    return {
      addingTraining: false,
      addingNewGroup: false,
      trainingGroup: "",
      trainings: [],
      loaded: false,
      selectedTraining: null,
      isSearching: false,
      searchInputValue: "",
      isHeaderInView: true,
      searchTerm: "",
      assignModalOpen: false,
      activeOptionMenu: -50,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$store.state.loading.loadingStart();
    this.$store.state.isShopTraining = true;
    this.loaded = false;
    setTimeout(() => {
      this.loadTrainings();
    }, 10);
  },
  beforeDestroy() {
    this.loaded = false;
    this.searchInputValue = "";
    this.$store.state.homePageSearchInput = "";
    this.$store.state.homePageSearchLoaded = false;
    this.$store.state.showHomePageSearchInput = true;
    this.$store.state.isShopTraining = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    searchInputValue(newVal) {
      if (newVal == "") this.isSearching = false;
      else if (newVal.length > 0 && !this.isSearching) this.isSearching = true;
    },
    "$store.state.homePageSearchInput"(newVal) {
      this.searchTrainingsInput();
      if (newVal == "") this.isSearching = false;
      else if (newVal.length > 0 && !this.isSearching) this.isSearching = true;
    },
    loaded() {
      this.$store.state.homePageSearchLoaded = this.loaded;
    },
  },
  methods: {
    closeMenus() {
      setTimeout(() => {
        if (this.activeOptionMenu != -50) {
          this.activeOptionMenu = -50;
        }
      }, 200);
    },
    openAssignModal(training) {
      if (training.expired != 1) {
        this.selectedTraining = training;
        this.assignModalOpen = true;
        window.scrollTo(0, 0);
      }
    },
    closeAssignModal() {
      this.selectedTraining = {};
      this.assignModalOpen = false;
    },
    showExpiryDays(expiryDate) {
      let now = new Date(Date.now());
      let date = new Date(expiryDate);
      let difference = now - date;
      let days = Math.abs(Math.round(difference / 1000 / 60 / 60 / 24));
      if (difference >= 0) {
        days = this.toLocal("generic.expired");
      }
      return days;
    },
    goBack() {
      this.$router.push("/edit");
    },
    handleScroll() {
      if (this.$refs["headerbuttons"] != undefined) {
        if (this.$refs["headerbuttons"].getBoundingClientRect().top >= 100) {
          this.isHeaderInView = true;
        } else {
          this.isHeaderInView = false;
        }
      }
    },
    loadTrainings() {
      axios
        .get(
          `${this.$store.state.api_link}/training/get-bought`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          let temp = response.data;
          if (temp[0] == undefined) {
            this.$store.state.loading.loadingDone();
            return;
          } else if (temp[0] != undefined) {
            temp.forEach((training) => {
              training.data = JSON.parse(training.data);
            });
            setTimeout(() => {
              this.loaded = true;
            }, 20);
            this.trainingGroup = {
              name: "trainings.bought",
              trainings: temp,
            };
            this.trainings = temp;
            this.trainingsClone = _.cloneDeep(temp);
            VueScrollTo.scrollTo(`body`, 100);
            this.$store.state.loading.loadingDone();
          }
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    openTraining(id, training) {
      if (training.expired != 1) {
        this.$store.state.loading.loadingStart();
        this.$store.state.previewMode = true;
        this.$store.state.currentTraining = id;
        let query = "?id="+id;
        console.log(training);
        if(training.rented == 1){
          this.$store.state.isTrainingBought=true;
          query+="&rented=1";
        }
        this.$store.state.currentTrainingData = training;
        this.$store.state.previewTopic = -1;
        this.$router.push("/training"+query);
      }
    },
    editTraining(id, training) {
      this.$store.state.loading.loadingStart();
      this.$store.state.currentTraining = id;
      this.$store.state.currentTrainingData = training;
      this.$store.state.previewTopic = -1;
      this.$router.push("/edit-training");
    },
    trainingStats(id, rentedStatus) {
      if (rentedStatus != undefined && rentedStatus == 1) {
        this.$store.state.currentTrainingRented = true;
      } else {
        this.$store.state.currentTrainingRented = false;
      }
      this.$store.state.loading.loadingStart();
      this.$store.state.currentTraining = id;
      this.$router.push("/training-stats");
    },
    addNewTraining(group) {
      this.addingTraining = true;
      axios
        .post(
          `${this.$store.state.api_link}/training/add`,
          { training_group: group },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.loadTrainings();
          this.editTraining(response.data.training);
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    deleteTraining(id) {
      if (!confirm(this.toLocal("training_edit.delete_confirm")))
        return;
      this.addingTraining = true;
      axios
        .post(
          `${this.$store.state.api_link}/training/delete`,
          { id: id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.loadTrainings();
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    openGroupSide(training) {
      this.selectedTraining = training;
      this.$store.state.currentTraining = training;
      this.$store.commit("openSide", {
        side: "training-groups-side",
        sideInfo: { id: training.id },
      });
    },
    onTrainingListChange(group) {
      setTimeout(() => {
        let updatedWeightSearch = [];
        let updatedWeight = [];
        let weight = 1;
        group.trainings.forEach((training, index) => {
          updatedWeightSearch.push({ [training.id]: index + 1 });
        });
        group.trainings.forEach((training, index) => {
          updatedWeight.push({ [training.id]: index + 1 });
        });
        axios
          .post(
            `${this.$store.state.api_link}/training/set-training-weight`,
            { trainings: JSON.stringify(updatedWeightSearch) },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            // this.loadTrainings();
          })
          .catch((error) => {
            this.$store.commit("handleError", { error: error });
          });
      }, 10);
    },
    searchTrainingsInput(event) {
      this.searchTrainings(event, this);
    },
    searchTrainings: _.debounce((event, self) => {
      // let text = self.$store.state.homePageSearchInput;
      if (self.searchTerm != "") {
        self.trainingGroup.trainings = self.trainingsClone.filter(
          (training) =>
            training.data &&
            training.data.title &&
            training.data.title != null &&
            training.data.title
              .toLowerCase()
              .includes(self.searchTerm.toLowerCase())
        );
      } else {
        self.trainingGroup.trainings = self.trainingsClone;
      }
    }, 10),

    setGroupOrder(groups, id, up) {
      var canOrder = false;
      groups = groups.filter(function (element) {
        return element !== undefined;
      });
      if (up) {
        if (id != 0) {
          this.swapElement(groups, id, id - 1);
          id = id - 1;
          canOrder = true;
        }
      } else {
        if (groups.length - 1 != id) {
          this.swapElement(groups, id, id + 1);
          id = id + 1;
          canOrder = true;
        }
      }
      if (canOrder) {
        axios
          .post(
            `${this.$store.state.api_link}/training_group/set-order`,
            { training_groups: [this.trainingGroup] },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {})
          .catch(() => {});
      }
    },
    swapElement(array, indexA, indexB) {
      var tmp = array[indexA];
      array[indexA] = array[indexB];
      array[indexB] = tmp;
    },
    searchVisibilityChanged(e) {
      if (this.$store.state.homePageSearchLoaded)
        this.$store.state.showHomePageSearchInput = e;
    },
    getTrainingTime(time){
      let minutes = time;
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return  hours > 0 ? hours + ' val. '+minutes+' min.' : minutes+' min.'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_homeEditViewScoped.scss";
.card{
  .option-menu{
    height: 30%;
  }
}
.card-container{
  margin-top: clamp(32px, 4.4vh, 44px);
}
.home-container{
  position: initial;
  margin-top: 0px;
  top: 0;
}
.header-btn-wrapper{

}
.tag-container {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  span {
    position: relative;
    padding: 8px 12px 7px 16px;
    background: #F3DE18;
    color: var(--primary-color);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    border-radius: 0px 20px 20px 0px;
    top: initial;
    line-height: 24px;
    font-size: 16px;
    width: fit-content;
    &.white {
      display: flex;
      align-items: center;
      svg {
        margin-left: 11px;
      }
      background: white;
    }
  }
  .selling-tag{
    background: var(--third-color);
  }
}
.time-tag{
    display: flex;
    align-items: center;
  span{
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-right:5px;
    &:last-child{
      margin-right:0px;
      margin-left: 5px;

    }
  }
}
</style>
