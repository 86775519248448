<template>
    <div class="info-modal-container">
        <div class="info-modal">
            <div class="close-btn" @click="$emit('closeModal')">
                <div>
                    <span></span><span></span>
                </div>
            </div>
            <div class="row header">
                <h2>{{toLocal('test.attest_info')}}</h2>
            </div>
            <table>
                <tr>
                    <td>
                        {{toLocal('test.attest_user')}}
                    </td>
                    <td class="second-cell info">
                        <div class="info-container">
                            <CustomDropdown
                                :list="training.training_users ? training.training_users : []"
                                :compareChosen="user"
                                @elClicked="addUser"
                                key-label="id"
                                value-label="label"
                                :options="{
                                    closeOnClick: true,
                                    checkbox: false,
                                    customDDWidth: '100%',
                                    customMaxHeight: '300px',
                                    initial: getInitialDropdownText(),
                                    showCreateNewBtn: false,
                                    containerClass: 'no-padding-no-bg test-users-dropdown',
                                    searchBar: true,
                                    searchLocally: true
                                }"
                            />
                            <!-- <div class="info-button" :class="{inactive: user && user.id}">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.99994 18C13.9705 18 18 13.9705 18 8.99999C18 4.02942 13.9705 0 8.99994 0C4.02938 0 0 4.02942 0 8.99999C0 13.9705 4.02938 18 8.99994 18Z" fill="#333333"/>
                                    <path d="M8.90377 14.7749C8.31602 14.7749 7.84766 14.2973 7.84766 13.7188V7.97898C7.84766 7.39122 8.3252 6.92285 8.90377 6.92285C9.49153 6.92285 9.95991 7.4004 9.95991 7.97898V13.7188C9.95991 14.3065 9.48235 14.7749 8.90377 14.7749Z" fill="white"/>
                                    <path d="M9.00104 6.36245C9.843 6.36245 10.5255 5.67992 10.5255 4.83797C10.5255 3.99602 9.843 3.31348 9.00104 3.31348C8.15909 3.31348 7.47656 3.99602 7.47656 4.83797C7.47656 5.67992 8.15909 6.36245 9.00104 6.36245Z" fill="white"/>
                                </svg>
                            </div> -->

                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        {{toLocal('test.max_points')}}
                    </td>
                    <td class="second-cell">{{test.options.testMaxScore}}</td>
                </tr>
                <tr>
                    <td>
                        {{toLocal('test.passable_from')}}
                    </td>
                    <td class="second-cell"><span>{{test.options.testPassedFrom}} %</span></td>
                </tr>
                <tr>
                    <td>
                        {{toLocal('test.question_count')}}
                    </td>
                    <td class="second-cell"><span>{{test.options.testShownQuestionCount}}</span></td>
                </tr> 
            </table>
        </div>

    </div>
</template>

<script>
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

export default {
    components: {
        CustomDropdown
    },
    props: {
        test: Object,
        training: Object
    },
    data(){
        return {
            user: {}
        }
    },
    methods: {
        addUser(event) {
            let u_index = this.training.training_users.findIndex(el => el.id == event)
            if(u_index != -1) this.user = this.training.training_users[u_index]
        },
        getInitialDropdownText() {
            if(this.user && this.user.id)
                return this.user.label
            else return this.toLocal('dropdown.choose_user')
        }
    },
    watch: {
        'user.id'() {
            this.$set(this.test,'selectedUser', this.user)
            this.$set(this.training,'selectedUser', this.user)
        }
    }
}
</script>

<style lang="scss" scoped>
 .info-modal-container{
  width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
     &.in-line{
        position: relative;
        z-index: 1;
        background: transparent;
        .close-btn{
            display: none;
        }
        .info-modal{
            background: transparent;
        }
    }
}
.info-modal{
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 #00000031;
    padding: 32px;
    max-width: 800px;
    width: clamp(250px, 75vw, 800px);
}
.second-cell{
    width: 100%;

}
.info-container{
    display: flex;
    align-items: center;
}
td{
      width: 100%;
}
.justify-center{
    justify-content: center;
}
.row{
    display: flex;
    width: 100%;
    &.header{
        justify-content: center;
    }
}
h2{
    text-align: center;
    margin: 0;
}
.grid-row{
    display: grid;
    grid-template-rows: 1fr;
    gap: 0.75rem;
    margin-top: clamp(24px, 4.44vh, 48px);
}
.row-item{
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid;
    padding-left: 1rem;
    color: var(--primary-color);
    height: 58px;
    gap: 16px;
    .label{
        white-space: nowrap;
    }
    .value{
        span{
            font-weight: 700;
        }
    }
}
table{
    table-layout:fixed;
    color: #333333;
    border-collapse: collapse;
    margin-top: clamp(24px, 4.44vh, 48px);

    td{
        width:1px;
        white-space:nowrap;
        padding: 16px 8px 16px 20px;
        border-bottom: 1px solid #7D7D7D;
    }
    .second-cell{
        padding-left: 8px;
        padding: 9px 16px 9px 8px;
        font-weight: 700;
    }
}
.close-btn{
    display: flex;
    position: absolute;
    top:0.25rem;
    right: 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    padding: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }
    span{
        display: flex;
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--primary-color);
        transform: rotate(45deg);
        &:last-child{
            transform: rotate(-45deg);
        }
    }
}
.info-button{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border:1px solid #7D7D7D;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 16px;
    transition: background-color 0.3s;
    &.inactive{
        border: none;
        cursor: not-allowed;
        svg{
            path:first-child{
                fill: #CACACA;
            }
        }
    }
    &:not(.inactive):hover{
        border-color: #4D4D4D;
        background-color: #4D4D4D;
        svg{
            path{
                fill: #4D4D4D;
            }
            path:first-child{
                fill: white;
            }
        }
    }
}
</style>
<style lang="scss">
.test-users-dropdown{
    border:1px solid #7D7D7D;
    border-radius: 8px;
    padding: 4px 12px;
    height: 40px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: clamp(250px, 14.5vw, 300px);

    &.dropdown-container:not(.no-padding-no-bg){
        background-color: white;
    }
    &.dropdown-container .dropdown-initial .white path{
        stroke: #333333;
    }
    .dropdown-initial{
        align-items: center;
        font-weight: 500;
        
        .initial{
            .white{
                path{
                    stroke: #333333;
                }
            }
        }
    }
    .checkbox {
        &.checked{
            .checkmark{
                path{
                    stroke: white;
                }
            }
        }
    }
}
</style>