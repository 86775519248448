import Vue from 'vue'
import Router from 'vue-router'
import Users from './views/Users.vue'
import Home from './views/Home.vue'
import HomeEdit from './views/HomeEdit.vue'
import TrainingFolders from './views/TrainingFolders.vue'
import TrainingHomeFolders from './views/TrainingHomeFolders.vue'
import Groups from './views/Groups.vue'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import Settings from './views/Settings.vue'
import Email from './views/Email.vue'
import Training from './views/Training.vue'
import TrainingEdit from './views/TrainingEdit.vue'
import TrainingStats from './views/TrainingStats.vue'
import Hierarchy from './views/Hierarchy.vue'
import Report from './views/Report.vue'
import PayPage from './views/PayPage.vue'
import BoughtTrainings from './views/BoughtTrainings.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import Rules from './views/Rules.vue'
import ReturnPolicy from './views/ReturnPolicy.vue'
import RegisterToken from './views/RegisterToken.vue'
import Test from './views/Test.vue'
import QRUpload from './views/QRUpload.vue'
import LeaderPage from './views/LeaderPage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: TrainingHomeFolders,
      meta: {
        showFooter: true
      }
    },
    {
      path: '/home',
      name: 'home',
      component: TrainingHomeFolders,
      meta: {
        showFooter: true
      }
    },
    {
      path: '/training-shop',
      name: 'pay-page',
      component: PayPage,
      meta: {
        showFooter: true,
        nonRedirectable: true
      }
    },
    {
      path: '/edit',
      name: 'edit',
      component: TrainingFolders
    },
    {path: '/group-trainings',
      name: 'group-trainings',
      component: Home
    },
    {
      path: '/edit-group',
      name: 'edit-group',
      component: HomeEdit
    },
    {
      path: '/bought-trainings',
      name: 'bought-trainings',
      component: BoughtTrainings,
      meta: {
        showFooter: true,
        boughtTrainings:true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: Users
    },
    {
      path: '/groups',
      name: 'groups',
      component: Groups
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/login/:token',
      name: 'login-token',
      component: Login,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/group-leader',
      name: 'group-leader',
      component: LeaderPage,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/r/:token',
      name: 'register-token',
      component: RegisterToken,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/training',
      name: 'training',
      component: Training
    },
    {
      path: '/training/:key',
      name: 'training',
      component: Training,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/training/keyed/:key',
      name: 'training',
      component: Training,
      meta: {
        nonRedirectable: true,
        keyed: true
      }
    },
  
    // {
    //   path: '/testas',
    //   name: 'testas',
    //   component: Test,
    //   meta: {
    //     nonRedirectable: true
    //   }
    // },
    {
      path: '/edit-training',
      name: 'edit-training',
      component: TrainingEdit
    },
    {
      path: '/training-stats',
      name: 'training-stats',
      component: TrainingStats
    },
    {
      path: '/email',
      name: 'email',
      component: Email
    },
    {
      path: '/report',
      name: 'report',
      component: Report
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings
    },
    {
      path: '/hierarchy',
      name: 'hierarchy',
      component: Hierarchy
    },
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: PrivacyPolicy,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/privatumo-politika',
      name: 'privacyPolicy',
      component: PrivacyPolicy,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/taisykles',
      name: 'rules',
      component: Rules,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/rules',
      name: 'rules',
      component: Rules,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/grazinimo-salygos',
      name: 'returnPolicy',
      component: ReturnPolicy,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/return-policy',
      name: 'returnPolicy',
      component: ReturnPolicy,
      meta: {
        nonRedirectable: true
      }
    },
    {
      path: '/q/:qr',
      name: 'qr',
      component: QRUpload,
      meta: {
        nonRedirectable: true
      }
    },
  ]
})
