<template>
  <div class="modal-container">
      <div class="modal">
          <div class="close-btn" @click="$store.state.emailForming.deleteModal.modalOpen = false">
              <span></span><span></span>
          </div>
            <h2>
                {{toLocal('delete_modal.are_you_sure')}}
            </h2>
            <div class="btn-container">
                <div class="btn" @click="acceptPrompt">
                    {{toLocal('delete_modal.yes_delete')}}
                </div>
            </div>
      </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
        }
    },
    methods:{
        deleteFolder(folder){
                axios.delete(
                        `${this.$store.state.api_link}/email-folders/${folder.id}`,
                        this.$store.getters.axiosConfig()
                    ).then((response) => {
                        let index= this.$store.state.emailForming.emailFolders.findIndex(el=>el.id == folder.id);
                        if(index != -1){
                            this.$store.state.emailForming.emailFolders.splice(index, 1);
                        }
                        let search_index= this.$store.state.emailForming.searchEmailFolders.findIndex(el=>el.id == folder.id);
                        if(search_index != -1){
                            this.$store.state.emailForming.searchEmailFolders.splice(search_index, 1);
                        }
                         let modal_data ={
                            item: {},
                            folder: {},
                            modalOpen: false,
                        }
                        this.$store.state.emailForming.deleteModal = modal_data;
                        if(this.$store.state.emailForming.selectedFolder == folder.id){
                            this.$store.state.emailForming.selectedFolder = -1;
                            this.$store.state.emailForming.selectedItem = -1;
                        }
                        // folder.reportList = [];
                    });
            },
        deleteItem(folder, report){
                let id = report.id;
                axios.delete(
                    `${this.$store.state.api_link}/email-templates/${id}`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    let item_index = folder.reportList.findIndex(el=>el.id == id);
                    folder.reportList.splice(item_index, 1); 
                    let folder_index = this.$store.state.emailForming.emailFolders.findIndex(el => el.id == folder.id);
                    if(folder_index != -1){
                        let new_item_index = this.$store.state.emailForming.emailFolders[folder_index].reportList.findIndex(el=>el.id == id);
                        if(new_item_index != -1){
                            this.$store.state.emailForming.emailFolders[folder_index].reportList.splice(new_item_index, 1);
                        }
                    }
                    this.updateFolder(folder);
                    let modal_data ={
                        item: {},
                        folder: {},
                        modalOpen: false,
                    }
                    this.$store.state.emailForming.deleteModal = modal_data;
                    if(this.$store.state.emailForming.selectedItem == id){
                            this.$store.state.emailForming.selectedItem = -1;
                    }
                });
        },
        updateFolder(folder){
                axios.put(
                        `${this.$store.state.api_link}/email-folders/${folder.id}`,
                        {
                            folder: folder
                        },
                        this.$store.getters.axiosConfig()
                    ).then((response) => {
                    });
        },
        acceptPrompt(){
            let item = this.$store.state.emailForming.deleteModal.item;
            if(item && Object.keys(item).length === 0 && Object.getPrototypeOf(item) === Object.prototype){
                this.deleteFolder(this.$store.state.emailForming.deleteModal.folder);
                this.$store.state.emailForming.deleteModal.modalOpen = false;
            }
            else{
                this.deleteItem(this.$store.state.emailForming.deleteModal.folder, this.$store.state.emailForming.deleteModal.item);
                this.$store.state.emailForming.deleteModal.modalOpen = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: #1a19196b;
    .modal{
        position: relative;
        border-radius: 1rem;
        padding: 2rem;
        border: 0.25rem solid white;
        background: #efefef;
        .close-btn{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            background: white;
            cursor: pointer;
            span{
                position: absolute;
                height: 80%;
                width: 3px;
                border-radius: 2rem;
                background: var(--primary-color);
                transform: rotate(45deg);
            }
            span:nth-child(2){
                transform: rotate(-45deg);
            }
        }
    }
    
}
.btn-container{
    display: flex;
    justify-content: center;
}
h2{
    color: var(--primary-color);
}
.btn{
    display: block;
    background: var(--secondary-color);
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 2rem;
    cursor: pointer;
    user-select: none;
    &:hover{
        font-weight: 700;
    }
}
</style>