<template>
  <div class="container">
    <div class="row space-between pl-24 payment-info-container" v-if="$store.state.user_role == 'admin'">
        <div class="column">
            <div class="row">
                <div class="column mb-33">
                    <h3 class="mb-12">{{toLocal('payments.payment_info_label')}}</h3>
                    <div class="data">
                        <div class="row">
                            <div class="column ">
                                <div class="row ">{{toLocal('payments.company_name')}}</div>
                                <div class="row ">{{toLocal('payments.company_num')}} </div>
                                <div class="row ">{{toLocal('payments.vat_number')}}</div>
                                <div class="row ">{{toLocal('payments.address')}}</div>
                            </div>
                            <div class="column ml-24 bold">
                                <div class="row ">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.name : '-'}}</div>
                                <div class="row ">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.company_num : '-'}}</div>
                                <div class="row ">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.pvm_code : '-'}}</div>
                                <div class="row ">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.address : '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <h3 class="mb-12">{{toLocal('payments.send_to')}}</h3>
                    <div class="data">
                        <div class="row ">
                            <div class="column">
                                <div class="row">{{toLocal('payments.name_surname')}}</div>
                                <div class="row">{{toLocal('payments.email')}}</div>
                            </div>
                            <div class="column ml-24 bold">
                                <div class="row">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.name_surname : '-'}} </div>
                                <div class="row">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.email : '-'}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-48">
                <button class="mb-0" @click="$emit('showPaymentInfoModal', true)">
                    {{toLocal('payments.edit_info')}}
                </button>
            </div>
        </div>
        <div class="column main-right" v-if="$store.state.system_settings.showPlatformInvoicingInfo">
            <div class="row border-bottom">
                <h3>{{toLocal('payments.next_invoice')}} <span class="ml-16">{{paymentMonthlyData.next_invoice_date}}</span></h3>
                
            </div>
            <div class="row border-bottom">
                <div class="column py-20">
                    <h3>{{toLocal('payments.fee_rates')}}</h3>
                    <div class="data">
                        <div class="column ">
                            <div class="row mb-11">{{toLocal('payments.monthly_user_price').replace('[price]', $store.state.system_settings.lmsPrices.monthly_user)}}</div>
                            <div class="row">{{toLocal('payments.additional_data').replace('[price]', $store.state.system_settings.lmsPrices.additional_gb)}}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="row" v-show="paymentMonthlyData != undefined">
                <div class="column py-20">
                    <h3>{{toLocal('payments.current_month_info')}}</h3>
                    <div class="data">
                        <div class="row w-320 active-users">
                            <div class="column w-full ">
                                <div class="row  mb-8">{{toLocal('payments.additional_data_this_month')}}</div>
                               <div class="row  mb-8" @click="getActiveUsers">{{toLocal('payments.user_count')}}</div>
                                <div class="row white border-left marked">{{toLocal('payments.total_month_cost')}}</div>
                            </div>
                            <div class="column w-full bold">
                                <div class="row ml-20 mb-8">{{paymentMonthlyData.additional_gb ? paymentMonthlyData.additional_gb : 0}} GB</div>
                                <div class="row ml-20 mb-8" @click="getActiveUsers">{{paymentMonthlyData.user_count ? paymentMonthlyData.user_count : '0'}}</div>
                                <div class="row  white pl-20 border-right ">{{paymentMonthlyData.total_price ? paymentMonthlyData.total_price : 0}} €</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="user-settings">
        <div class="input-container" v-if="user_details.name != undefined">
            <div class="input-label">{{toLocal('generic.buyer')}}</div>
            <input type="text" :placeholder="toLocal('settings.current_username')" v-model="$v.user_details.name.$model">
            <span class="error" v-if="!$v.user_details.name.required">{{toLocal('settings.name_last_name_required')}}</span>
            <span class="error" v-else-if="!$v.user_details.name.minLength">{{toLocal('first_login.username_incorrect')}}</span>
        </div>
        <div class="input-container" v-if="user_details.email != undefined">
            <div class="input-label">{{toLocal('payments.email_extended')}}</div>
            <input type="text" :placeholder="toLocal('settings.current_username')" v-model="$v.user_details.email.$model">
            <span class="error" v-if="!$v.user_details.email.required">{{toLocal('email.required')}}</span>
            <span class="error" v-else-if="!$v.user_details.email.email">{{toLocal('email.email_format')}}</span>
            <span class="error" v-else-if="!$v.user_details.email.minLength">{{toLocal('email.error.short')}}</span>
        </div>
        <button @click="saveUserDetails">
            <font-awesome-icon v-if="isSavingUserData" icon="spinner" />
            Išsaugoti pakeitimus
        </button>
    </div>
    <div class="table-container">
        <table>
            <tr>
                <th>
                    {{toLocal('payments.invoiced')}}
                        <svg @click="sort('date')" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortLabel=='date' && sortValue=='asc'}" d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z" fill="#C5C5C5"/>
                            <path :class="{active: sortLabel=='date' && sortValue=='desc'}" d="M7.90988 19.7727C7.52988 20.1727 6.89996 20.1927 6.49996 19.8127C6.48996 19.8027 6.46993 19.7827 6.45993 19.7727L0.279872 13.2328C-0.100128 12.8328 -0.0800888 12.2028 0.319911 11.8228C0.509911 11.6528 0.749975 11.5527 1.00997 11.5527H13.3698C13.9198 11.5527 14.3698 12.0027 14.3698 12.5527C14.3698 12.8127 14.2698 13.0528 14.0998 13.2428L7.90988 19.7727Z" fill="#C5C5C5"/>
                        </svg>
                </th>
                <th>
                    {{toLocal('group.name')}}
                        <svg @click="sort('itle')" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortLabel=='title' && sortValue=='asc'}" d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z" fill="#C5C5C5"/>
                            <path :class="{active: sortLabel=='title' && sortValue=='desc'}" d="M7.90988 19.7727C7.52988 20.1727 6.89996 20.1927 6.49996 19.8127C6.48996 19.8027 6.46993 19.7827 6.45993 19.7727L0.279872 13.2328C-0.100128 12.8328 -0.0800888 12.2028 0.319911 11.8228C0.509911 11.6528 0.749975 11.5527 1.00997 11.5527H13.3698C13.9198 11.5527 14.3698 12.0027 14.3698 12.5527C14.3698 12.8127 14.2698 13.0528 14.0998 13.2428L7.90988 19.7727Z" fill="#C5C5C5"/>
                        </svg>
                </th>
                <th>
                    {{toLocal('payments.price')}}
                        <svg @click="sort('price')" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortLabel=='price' && sortValue=='asc'}" d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z" fill="#C5C5C5"/>
                            <path :class="{active: sortLabel=='price' && sortValue=='desc'}" d="M7.90988 19.7727C7.52988 20.1727 6.89996 20.1927 6.49996 19.8127C6.48996 19.8027 6.46993 19.7827 6.45993 19.7727L0.279872 13.2328C-0.100128 12.8328 -0.0800888 12.2028 0.319911 11.8228C0.509911 11.6528 0.749975 11.5527 1.00997 11.5527H13.3698C13.9198 11.5527 14.3698 12.0027 14.3698 12.5527C14.3698 12.8127 14.2698 13.0528 14.0998 13.2428L7.90988 19.7727Z" fill="#C5C5C5"/>
                        </svg>
                </th>
                <th >
                    {{toLocal('payments.invoice')}}
                        <svg @click="sort('invoice_num')" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortLabel=='invoice_num' && sortValue=='asc'}" d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z" fill="#C5C5C5"/>
                            <path :class="{active: sortLabel=='invoice_num' && sortValue=='desc'}" d="M7.90988 19.7727C7.52988 20.1727 6.89996 20.1927 6.49996 19.8127C6.48996 19.8027 6.46993 19.7827 6.45993 19.7727L0.279872 13.2328C-0.100128 12.8328 -0.0800888 12.2028 0.319911 11.8228C0.509911 11.6528 0.749975 11.5527 1.00997 11.5527H13.3698C13.9198 11.5527 14.3698 12.0027 14.3698 12.5527C14.3698 12.8127 14.2698 13.0528 14.0998 13.2428L7.90988 19.7727Z" fill="#C5C5C5"/>
                        </svg>
                </th>
                 <th v-if="$store.state.user_role == 'admin'">
                    {{toLocal('payments.report')}}
                        <svg @click="sort('show_report')" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortLabel=='show_report' && sortValue=='asc'}" d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z" fill="#C5C5C5"/>
                            <path :class="{active: sortLabel=='show_report' && sortValue=='desc'}" d="M7.90988 19.7727C7.52988 20.1727 6.89996 20.1927 6.49996 19.8127C6.48996 19.8027 6.46993 19.7827 6.45993 19.7727L0.279872 13.2328C-0.100128 12.8328 -0.0800888 12.2028 0.319911 11.8228C0.509911 11.6528 0.749975 11.5527 1.00997 11.5527H13.3698C13.9198 11.5527 14.3698 12.0027 14.3698 12.5527C14.3698 12.8127 14.2698 13.0528 14.0998 13.2428L7.90988 19.7727Z" fill="#C5C5C5"/>
                        </svg>
                </th>
                <th>
                    {{toLocal('payments.paid')}}
                        <svg @click="sort('paid')" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: sortLabel=='paid' && sortValue=='asc'}" d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z" fill="#C5C5C5"/>
                            <path :class="{active: sortLabel=='paid' && sortValue=='desc'}" d="M7.90988 19.7727C7.52988 20.1727 6.89996 20.1927 6.49996 19.8127C6.48996 19.8027 6.46993 19.7827 6.45993 19.7727L0.279872 13.2328C-0.100128 12.8328 -0.0800888 12.2028 0.319911 11.8228C0.509911 11.6528 0.749975 11.5527 1.00997 11.5527H13.3698C13.9198 11.5527 14.3698 12.0027 14.3698 12.5527C14.3698 12.8127 14.2698 13.0528 14.0998 13.2428L7.90988 19.7727Z" fill="#C5C5C5"/>
                        </svg>
                </th>
            </tr>
            <tr v-for="(bill, index) in invoices" :key="index">
                <td>{{bill.date}}</td>
                <td>{{bill.title}}</td>
                <td>{{(Math.round(bill.price * 100) / 100).toFixed(2)}} €</td>
                <td class="clickable" @click="downloadInvoice(bill)"> 
                    <svg class="download-icon" width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1V12M11 12L7 8.82692M11 12L15 8.82692" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 8V15C1 16.1046 1.89543 17 3 17H19C20.1046 17 21 16.1046 21 15V8" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{bill.invoice_num}}
                </td>
                <td v-if="$store.state.user_role == 'admin'" class="report-cell">
                    <span v-if="bill.show_report" @click="downloadMonthReport(bill)">
                       {{bill.downloadingMonthReport ? '' : toLocal('generic.download')}}
                       <font-awesome-icon icon="spinner" v-if="bill.downloadingMonthReport"/>
                    </span>
                </td>
                <td class="button-cell">
                    {{bill.paid == 1 ? toLocal('payments.paid')+' '+(bill.paid_date != null ? bill.paid_date : '') : ''}}
                    <svg class="check" v-if="bill.paid == 1" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1 6.42468L7.29068 13L17 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <button v-if="bill.paid ==0" class="primary pay" @click="payInvoice(bill)"> {{toLocal('payments.pay')}}</button>
                </td>
            </tr>
        </table>
    </div>
    <portal to="global-modal-settings">
        <GlobalModal
            :modalInfo="modalInfo"
            @onClose="showUsersModal=false"
            @onConfirm="downloadActiveUsers"
            v-if="showUsersModal"
        />
    </portal>
  </div>

</template>

<script>
import axios from 'axios';
import { required, requiredIf, minLength, maxLength, sameAs, email  } from 'vuelidate/lib/validators'
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";

export default {
    components:{
        GlobalModal
    },
    props:{
        showPaymentInfoModal: {
            type: Boolean,
            default: false
        },
        invoices:{
            type: Array,
        },
        paymentMonthlyData:{
            type: Object,
        }
    },
    data(){
        return{
           
            sortLabel: '',
            sortValue: '',
            user_details:{
                name: this.$store.state.user_name + ' ' + this.$store.state.user_last_name,
                email: this.$store.state.user_email,
            },
            isSavingUserData: false,
            showUsersModal: false,
            modalInfo: {
                text: 'training_edit.shop_cancel_sale_modal_text',
                table:{
                    header:[
                        {
                            label:'generic.name', 'value':'name', sortable:true
                        },
                        {
                            label:'generic.surname', 'value':'last_name', sortable:true
                        },
                        {
                            label:'settings.user_days_active_month', value:'active_days', sortable:true
                        }
                    ],
                    rows:[
                        {name:'AVardas', last_name:'APavardė', days_active: 10},
                        {name:'BVardas', last_name:'BPavardė', days_active: 20},
                        {name:'CVardas', last_name:'CPavardė', days_active: 30},
                    ],
                    sort: this.sortTable,
                    sortingItem: '',
                    sortDirection: '',
                },
                requestLoading: false,
                undertext: 'training_edit.shop_cancel_sale_modal_undertext',
                confirmBtnText: 'settings.download_list',
                type: 'table',
                style:{
                    width: '60vw',
                    'min-width': '700px',
                    height: 'initial'
                },
                class: 'table-modal'
            },
            activeUsers: [],
            
        }
    },
    validations: {
        user_details: {
            name:{
                required: required,
                minLength: minLength(2),
                maxLength: maxLength(64),
            },
            email:{
                required: required,
                email: email,
                minLength: minLength(6),
                maxLength: maxLength(64),
            },
         
        },
    },
    methods:{
        downloadInvoice(bill){
            axios.get(`${this.$store.state.api_link}/invoices/${bill.id}/download`, 
                this.$store.getters.axiosConfigDownload())
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'invoice.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
            });
        },
        sort(label){
            if(this.sortValue==''){
                this.sortValue = 'desc'
            }
            else if (this.sortLabel == label){
                if(this.sortValue == 'asc'){
                    this.sortValue = 'desc'
                }
                else{
                    this.sortValue = 'asc'
                }
            }
            else{
                this.sortValue = 'desc'
            }
            this.sortLabel = label;

            this.invoices.sort((a, b)=>{
                if(this.sortLabel == 'date'){
                    if(this.sortValue == 'asc')
                        return new Date(a.date) - new Date(b.date)
                    else
                        return new Date(b.date) - new Date(a.date)
                }
                if(this.sortLabel == 'invoice_num'){
                    if(this.sortValue == 'asc')
                        return (b.invoice_num).localeCompare(a.invoice_num) 
                    else
                        return (a.invoice_num).localeCompare(b.invoice_num)
                }
                if(this.sortLabel == 'paid'){
                    if(this.sortValue == 'asc')
                        return b.paid == 1
                    else    
                        return b.paid == 0
                }
                if(this.sortLabel == 'price'){
                    if(this.sortValue == 'asc')
                        return parseFloat(a.price) - parseFloat(b.price)
                    else    
                        return parseFloat(b.price) - parseFloat(a.price)
                }
                if(this.sortLabel == 'title'){
                    if(this.sortValue == 'asc')
                        return (b.invoice_num).localeCompare(a.invoice_num)
                    else    
                        return (a.invoice_num).localeCompare(b.invoice_num)
                }
                if(this.sortLabel == 'show_report'){
                    if(this.sortValue == 'asc')
                        return b.show_report - a.show_report
                    else    
                        return a.show_report - b.show_report
                }
            })
        },
        sortItem(){
            sort('date', sortValue=='' ? 'desc' : sortLabel == 'date' ? sortValue == 'asc' ? 'desc' : 'asc' : 'desc')
        },
        saveUserDetails(){
            if(this.isSavingUserData) return false;
            this.isSavingUserData = true;
            axios.post(`${this.$store.state.api_link}/user/save-details`,
                {data: this.user_details},
                this.$store.getters.axiosConfig())
            .then((res) => {
                this.$store.state.user_name = res.data.user.name;
                this.$store.state.user_last_name = res.data.user.last_name;
                this.$store.state.user_email = res.data.user.email;
                this.isSavingUserData = false;
            })
            .catch((err) => {
                this.isSavingUserData = false;
            });
        },
        payInvoice(invoice){
            let slug = invoice.order_slug;
            let link = this.$store.state.ssl ? 'https://' : 'http://';
            link += process.env.VUE_APP_PARENT_LMS_API+'/api/order/'+slug+'?method=none';
            window.location =link; 
        },
        getActiveUsers(){
            axios.get(`${this.$store.state.api_link}/settings/connected-users`,
                this.$store.getters.axiosConfig())
            .then((res) => {
                this.activeUsers = res.data;
                this.modalInfo.table.rows = res.data;
                this.showUsersModal=true;
            })
            .catch((err) => {
            });
        },
        downloadActiveUsers(){
            this.modalInfo.requestLoading = true;
            let formData = new FormData();
            formData.append('input', JSON.stringify(this.activeUsers));
            axios.post(`${this.$store.state.api_link}/active-users`,
                formData,
                this.$store.getters.axiosConfigDownload()
            )
            .then((res) => {
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(res.data);
                a.download = 'Aktyvus vartotojai.xlsx';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                this.modalInfo.requestLoading = false;
            })
            .catch((err) => {
                this.modalInfo.requestLoading = false;
            })
        },
        sortTable(key){
            this.modalInfo.table.sortingItem = key;
            if(this.modalInfo.table.sortDirection.length == 0) this.modalInfo.table.sortDirection = 'asc';
            else if(this.modalInfo.table.sortDirection == 'asc') this.modalInfo.table.sortDirection = 'desc';
            else this.modalInfo.table.sortDirection = 'asc';
            this.modalInfo.table.rows.sort((a,b) => {
                if(this.modalInfo.table.sortDirection=='desc'){
                    if(key=='active_days') return b[key] - a[key];
                    else return b[key] == null ? 1 : b[key].localeCompare(a[key]);
                }
                else{
                    if(key=='active_days') return a[key] - b[key];
                    else return a[key] == null ? -1 : a[key].localeCompare(b[key]);
                }
            })
        },
        downloadMonthReport(invoice){
            this.$set(invoice, 'downloadingMonthReport', true);
            axios.get(`${this.$store.state.api_link}/download-month-report/${invoice.invoice_id}`,
                this.$store.getters.axiosConfigDownload()
            )
            .then((res) => {
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(res.data);
                a.download = 'platform_report.xlsx';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                this.$set(invoice, 'downloadingMonthReport', false);
            })
            .catch((err) => {
                this.$set(invoice, 'downloadingMonthReport', false);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_settingsViewScoped.scss";

.container{
    display: flex;
    flex-direction: column;
    font-family: var(--main-site-font);
    margin: 0;
    margin-top: 48px;
    max-width: 100%;
    width: 100%;
}
.space-between{
    justify-content: space-between;
}
.row{
    display: flex;
    flex-direction: row;
    color: var(--primary-color);
    font-size: 16px;
    line-height: 28px;
    white-space: nowrap;
    span{
        font-weight: 700;
        color: var(--primary-color);
    }
}

h3{
    font-size: 20px;
    margin-bottom: 16px;
    color: #484848;
    font-weight: 400;
    font-family: var(--main-site-font);
}
.column{
    display: flex;
    flex-direction: column;
}
.main-right{
    width: 360px;
    padding: 0 0 0 28px;
    border-left: 1px solid #7D7D7D;
}
.white{
    background: white;
    padding: 4px 8px;
}
.ml-24{
    margin-left: 24px;
}
.bold{
    font-weight: 700;
}
.border-bottom{
    border-bottom: 1px solid #7D7D7D;
}
.mb-30{
    margin-bottom: 30px;
}
.mb-8{
    margin-bottom: 8px;
}
.py-20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.ml-16{
    margin-left: 16px;
}
.pl-24{
    padding-left: 24px;
}
.pl-8{
    padding-left: 8px;
}
.py-48{
    padding-top: 48px;
    padding-bottom: 48px;
}
.pt-48{
    padding-top: 48px;
}
.pl-20{
    padding-left: 20px;
}
.mb-0{
    margin-bottom: 0;
}
.mb-11{
    margin-bottom: 11px;
}
.mb-12{
    margin-bottom: 12px;
}
.mb-33{
    margin-bottom:33px;
}
.border-left{
    border-radius: 8px 0 0 8px;
}
.border-right{
    border-radius: 0 8px 8px 0;
}
.ml-20{
    margin-left: 20px;
}
.w-320{
    width: 320px;
}
.w-full{
    width: 100%;
}
.table-container{
    border-radius: 16px;
    background: white;
    padding: 0px 24px 24px;
    margin: 48px 0 150px;
    table{
        width: 100%;
        max-width: 100%;
    }
   th, td{
    text-align: left;
    width: 20%;
    border-bottom: 1px solid #EFEFEF;
    padding: 15px 0;
   }
   td{
    font-size: 16px;
    color: var(--primary-color);
   }
    button{
        margin: 0;
        padding: 9px 66px;
    }
   th{
    font-weight: 500;
    font-size: 20px;
    white-space: nowrap;

    svg{
        vertical-align: middle;
        margin-left: 15px;
        cursor: pointer;
        path{
            transition: transform 0.3s ease;
            &.active{
                fill: var(--primary-color);
            }
            &:hover{
                fill: #787878;
                transform: scale(1.1);
                transform-origin: center center;
            }
        }
    }
   }
   .report-cell{
        span{
            text-decoration: underline;
            cursor: pointer;
        }
   }
    .check{
        margin-left: 15px;
        path{
            stroke: var(--primary-color)
        }
    }
    .download-icon{
        margin-right: 10px;
    }
}
.marked{
    width: calc(100% + 8px);
    margin-left: -8px;
    
}
.clickable{
    cursor: pointer;
}
.payment-info-container{
    max-width: 1140px;
    width: 95%;
}
.user-settings{
    display: flex;
    align-items: flex-end;
    padding: 0 24px;
    margin-bottom: 12px;
    .input-container{
        flex-direction: column;
        margin-right: 20px;
        position: relative;

        .input-label{
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 8px;
            font-family: var(--main-site-font);
            padding-left: 16px;
        }
        input{
            background: #FFFFFF;
            border-bottom: 1px solid #EFEFEF;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.16);
            border-radius: 8px;
            padding: 11px 16px;
            font-size: 16px;
            font-family: var(--main-site-font);
            margin-bottom: 0;
        }
    }
    button{
        display: flex;
        align-items: center;
        margin: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        line-height: 22px;
        font-family: var(--main-site-font);
        padding: 11px 24px;
    }
}
.pop-top{
    top: -55px;
    right: initial;
    font-size: 16px;
    h3,span,ul,li{
        font-size: 16px;
    }
}
.error{
    position: absolute;
    bottom: -20px;
    left: 0;
    color: rgb(241, 65, 65);
    font-size: 12px;
    
}
.active-users{
    .column{
        
        .row:nth-child(2){
            border-bottom: 2px solid #00000075;;
            cursor: pointer;
            margin-bottom: 11px;
        }
        &:last-child{
            .row:nth-child(2){
                border-bottom: 2px solid #00000075;
                margin-left: 0;
                width: fit-content;
                padding-left: 20px;
                margin-bottom: 11px;
            }
        }
    }
    &:hover{
        .row:nth-child(2){
            border-bottom: 2px solid #000;
        }
        &:last-child{
            .row:nth-child(2){
                border-bottom: 2px solid #000;
            }
        }
    }
    
}

@media (max-width: 1500px){
    .payment-info-container{
        width: 80%;
    }
}
@media (max-width: 1000px){
    .payment-info-container{
        width: 90%;
    }
}
@media (max-width: 768px){
    .container{
        width: 100%;
        overflow: hidden;
    }
    .payment-info-container{
        flex-direction: column;
    }
    .mb-33{
        width: 100%;
    }
    .pt-48{
        padding-top: 24px;
    }
    .row.pl-24{
        padding-left: 0;
    }
    button{
        &.mb-0{
            margin-bottom: 20px;

        }
    }
    .main-right{
        padding: 0;
        border: none;
    }
    .table-container{
        width: 100%;
        overflow: auto;
        table{
            min-width: 750px;
            th{
                font-size: clamp(14px, 2vw, 16px);
            }
            td{
                font-size: clamp(14px, 2vw, 16px);
            }
        }
        button{
            padding: 5px 20px;
        }
    }
}
</style>