<template>
  
  <div class="training--main" :class="{'scorm': training && training.trainingType == 'scorm'}">
       
        <textarea-autosize
            class="title--editing"
            v-model="training.title"
            ref="trainingTitle"
            rows="1"
            maxLength="80"
            :min-height="55"
            :max-height="350"
            autosize
            :readOnly="false"
        />
        <VTooltip theme="users" class="notes--tooltip"  :triggers="['hover']" :placement="'top'">
            <TrainingEditNotesSvg class="notes--icon" @click="$emit('openNotes')" />
            <template #popper>
                {{ toLocal('training_edit.notes') }}
            </template>
        </VTooltip>
        <div
          v-if="!editingDescription"
          class="training--description"
          @click="editingDescription=true"
        >
          <editor
            :isEditing="editingDescription"
            :readOnly="true"
            :placeholder="toLocal('training.description')"
            v-model="training.description"
          />
        </div>
        <editor
          v-if="editingDescription"
          class="description--editing"
          v-click-outside-2="clickOutsideDescription"
          :isEditing="editingDescription"
          :readOnly="false"
          v-model="training.description"
        />
        <div class="main-info-container" v-if="!['singleDoc'].includes(training.trainingType)">
            <div class="col">
                <div class="row"><h2>{{ toLocal("training_edit.main_information") }}</h2></div>
                <div class="row">
                    {{ toLocal("training_edit.time") }}
                    <input
                      ref="trainingTime"
                      class="time--input"
                      type="text"
                      placeholder="00"
                      v-mask="lengthMask"
                      v-model="training.time"
                      @keyup.enter="setTrainingTime($event.srcElement.value)"
                    />
                    <span class="time--min">min.</span>
                </div>
            </div>
            <div class="col buy-btn-container" :class="{paid: training.paid}" v-if="$store.state.system_settings.showTrainingPaymentSettings">
                <button @click="toggleSale">
                    {{ training.paid ? toLocal('training_edit.cancel_sale') : ''}}
                    <svg v-if="!training.paid" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.26298 7.26743C8.06755 6.45196 9.17166 6 10.309 6H14.9425C15.8254 6 16.6625 6.34464 17.2883 6.97058L25.0305 14.7144C25.6562 15.3402 26 16.1765 26 17.0578C26 17.939 25.6562 18.7753 25.0305 19.4012L19.4034 25.0294C18.7776 25.6554 17.9405 26 17.0575 26C16.1746 26 15.3375 25.6554 14.7117 25.0294L6.96951 17.2856C6.34378 16.6598 6 15.8235 6 14.9422V10.3078C6 9.1694 6.45286 8.07859 7.26298 7.26743ZM10.309 7.31456C9.51888 7.31456 8.7546 7.62946 8.20172 8.19047L8.20006 8.19215L8.19839 8.19383C7.63229 8.76005 7.31844 9.51837 7.31844 10.3078V14.9422C7.31844 15.4779 7.52556 15.9798 7.90326 16.3576L15.6454 24.1014C16.0231 24.4791 16.5239 24.6854 17.0575 24.6854C17.5912 24.6854 18.092 24.4791 18.4696 24.1014L24.0967 18.4731C24.4744 18.0953 24.6816 17.5934 24.6816 17.0578C24.6816 16.5221 24.4744 16.0202 24.0967 15.6424L16.3546 7.89864C15.9769 7.52094 15.4761 7.31456 14.9425 7.31456H10.309Z" fill="#333333"/>
                        <path d="M10.3483 8.94938C10.5352 9.13633 10.6336 9.38232 10.6336 9.64799C10.6336 9.91366 10.5254 10.1597 10.3483 10.3466C10.1614 10.5336 9.91547 10.632 9.64986 10.632C9.38425 10.632 9.13831 10.5237 8.95139 10.3466C8.76448 10.1597 8.6661 9.91366 8.6661 9.64799C8.6661 9.38232 8.77432 9.13633 8.95139 8.94938C9.13831 8.76243 9.38425 8.66403 9.64986 8.66403C9.91547 8.66403 10.1614 8.77226 10.3483 8.94938Z" fill="#333333"/>
                        <path d="M18.7594 12.8072C18.7102 12.6793 18.6118 12.5711 18.4839 12.5219C18.3068 12.4432 18.1298 12.3841 17.9527 12.3448C17.6477 12.266 17.3132 12.2267 16.9689 12.2267H16.8509C16.0639 12.2562 15.4343 12.512 14.9621 12.9942C14.4899 13.4763 14.1751 14.1159 14.0373 14.9129H13.6635C13.4176 14.9129 13.211 15.1097 13.211 15.3655C13.211 15.6214 13.4176 15.8181 13.6635 15.8181H13.939C13.939 15.8181 13.939 15.8378 13.939 15.8575C13.939 15.9264 13.939 16.0051 13.939 16.0838V16.2904C13.939 16.2904 13.939 16.3396 13.939 16.3692H13.6635C13.4176 16.3692 13.211 16.566 13.211 16.8218C13.211 17.0776 13.4176 17.2744 13.6635 17.2744H14.0472C14.2046 18.0419 14.5194 18.652 14.9817 19.1144C15.4736 19.5966 16.1524 19.8425 16.9984 19.8425C17.3329 19.8425 17.6379 19.813 17.9035 19.7442C18.0609 19.7048 18.2183 19.6556 18.356 19.6064C18.5626 19.5375 18.6905 19.3506 18.6905 19.1341C18.6905 18.9767 18.6216 18.8389 18.4938 18.7405C18.3659 18.6421 18.2085 18.6126 18.0511 18.652C17.9822 18.6716 17.9035 18.6913 17.8248 18.711C17.569 18.77 17.3034 18.7995 17.0476 18.7995C16.5262 18.7995 16.1032 18.652 15.7983 18.3666C15.513 18.1009 15.3162 17.727 15.1982 17.2646H16.8902C17.1362 17.2646 17.3428 17.0678 17.3428 16.8119C17.3428 16.5561 17.1362 16.3593 16.8902 16.3593H15.0801C15.0801 16.3593 15.0801 16.3003 15.0801 16.2708V15.887C15.0801 15.887 15.0801 15.8378 15.0801 15.8181H17.1952C17.4411 15.8181 17.6477 15.6214 17.6477 15.3655C17.6477 15.1097 17.4411 14.9129 17.1952 14.9129H15.1883C15.4441 13.7912 16.0245 13.25 16.9689 13.25C17.2149 13.25 17.4706 13.2894 17.7067 13.3779C17.8248 13.4173 17.9429 13.4665 18.0511 13.5058C18.2872 13.6042 18.5725 13.5058 18.6905 13.2795L18.7299 13.2106C18.7889 13.0926 18.8086 12.945 18.7594 12.8171V12.8072Z" fill="#333333"/>
                    </svg>
                    <svg v-else width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.9992 8L8.00194 19.9981M19.9993 20L8 8.00393" stroke="#333333" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>

                    {{ !training.paid ? toLocal('training_edit.sell_training') : ''}}
                    
                </button>
                <button v-if="training.paid" @click="showPaidTrainingPreview=true">
                    {{toLocal('training_edit.product_preview')}}
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40418 14.2857C6.00599 18.0007 9.81467 20.2978 14 20.2978C18.1847 20.2978 21.9849 18.0013 24.5954 14.2851C21.9936 10.5705 18.1851 8.27361 14 8.27361C9.81467 8.27361 6.00599 10.5707 3.40418 14.2857ZM2.10648 13.9327C4.89589 9.73232 9.17509 7 14 7C18.8249 7 23.1041 9.73232 25.8935 13.9327C26.0357 14.1468 26.0355 14.4257 25.8929 14.6396C23.0947 18.8386 18.8255 21.5714 14 21.5714C9.17509 21.5714 4.89589 18.8391 2.10648 14.6387C1.96451 14.4249 1.96451 14.1465 2.10648 13.9327Z" fill="#333333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0642 11.4576C12.6615 11.4576 11.5243 12.5981 11.5243 14.0049C11.5243 15.4116 12.6615 16.5521 14.0642 16.5521C15.4669 16.5521 16.604 15.4116 16.604 14.0049C16.604 12.5981 15.4669 11.4576 14.0642 11.4576ZM10.2544 14.0049C10.2544 11.8947 11.9601 10.184 14.0642 10.184C16.1682 10.184 17.8739 11.8947 17.8739 14.0049C17.8739 16.115 16.1682 17.8257 14.0642 17.8257C11.9601 17.8257 10.2544 16.115 10.2544 14.0049Z" fill="#333333"/>
                    </svg>
                </button>
            </div>
        </div>
        
        <div class="payment-settings" v-if="training.paid && !['scorm', 'singleDoc'].includes(training.trainingType)">
            <div class="row grid-equal">
                <div class="row w-full left-col">
                    <div class="col label-container">
                        <div class="row">{{toLocal('training_edit.shop_rent_price')}}</div>
                        <div class="row">{{toLocal('training_edit.shop_price')}}</div>
                        <div class="row">{{toLocal('training_edit.shop_language')}}</div>
                        <div class="row">{{toLocal('training_edit.shop_subtitles')}}</div>
                    </div>
                    <div class="col input-wrapper">
                        <div class="row">
                            <!-- <input 
                            class="price" 
                            type="text" 
                            v-model.number="training.price_rent"
                            v-mask="numberMask"
                            @keypress="isNumber($event)" 
                            > -->
                            <VueAutoNumeric v-model="training.price_rent"/>
                            <span>{{toLocal('training_edit.shop_price_year')}}</span>
                        </div>
                        <div class="row">
                            <!-- <input 
                            class="price" 
                            type="text" 
                            v-model.number="training.price" 
                            @keypress="isNumber($event)" 
                            v-mask="numberMask"
                            > -->
                            <VueAutoNumeric v-model="training.price"/>
                            <span>{{toLocal('training_edit.shop_price_permanently')}}</span>
                        </div>
                        <div class="row">
                            <SelectInput
                                class="language-select"
                                :options="$store.state.system_settings.supportedLanguages"
                                v-model="training.language"
                                label="label"
                                idLabel="label"
                                :placeholder="toLocal('general.select_languages_placeholder')"
                                :addNew="false"
                                dropdownIcon="small"
                            />
                            <!-- <input type="text" v-model="training.language" maxlength="128"> -->
                        </div>
                        <div class="row">
                            <SelectInput
                                class="language-select"
                                :options="$store.state.system_settings.supportedLanguages"
                                v-model="training.subtitles"
                                label="label"
                                idLabel="label"
                                :placeholder="toLocal('general.select_subtitles_placeholder')"
                                :addNew="false"
                                dropdownIcon="small"
                            />
                            <!-- <input type="text" v-model="training.subtitles" maxlength="128"> -->
                        </div>
                    </div>
                </div>
                <div class="col w-full right-col">
                    <div class="row">
                        <label>{{toLocal('training_edit.upload_trailer_label')}}</label>
                    </div>
                    <div class="row upload-container center" :class="{'has-trailer': !isUploadingTrailer && training.trailer && training.trailer.length > 0}">
                        <div class="trailer-window" v-if="training.trailer && training.trailer.length > 0 && !isUploadingTrailer">
                            <div class="trailer">
                                <video :src="getFileUrl(training.trailer)" controls></video>
                            </div>
                        </div>
                        <div class="upload-bar" v-if="isUploadingTrailer">
                            <div class="progress-bar">
                                <div class="progress" :style="{width: uploadProgress+'%'}"></div>
                            </div>
                            <div class="cancel" @click="cancelUpload">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.9992 8L8.00194 19.9981M19.9993 20L8 8.00393" stroke="#333333" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>
                        </div>
                        <div class="col align-center" v-if="!isUploadingTrailer" @click="$refs.trailer.click()">
                            <div class="row">
                                <div class="icon-container">
                                    <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.361 17.8689H14.8803C13.4578 17.8689 12.293 16.6848 12.293 15.2169V11.1166C12.293 10.7447 12.5794 10.4512 12.9422 10.4512C13.305 10.4512 13.5914 10.7447 13.5914 11.1166V15.2169C13.5914 15.9411 14.1643 16.538 14.8803 16.538H24.361C25.0675 16.538 25.6499 15.9509 25.6499 15.2169V11.1851C25.6499 10.8133 25.9363 10.5197 26.2991 10.5197C26.6619 10.5197 26.9484 10.8133 26.9484 11.1851V15.2169C26.9484 16.675 25.7931 17.8689 24.361 17.8689Z" fill="#333333"/>
                                        <path d="M19.6092 15.2678C19.2464 15.2678 18.96 14.9742 18.96 14.6023V7.37052C18.96 6.99866 19.2464 6.70508 19.6092 6.70508C19.9624 6.70508 20.2584 6.99866 20.2584 7.37052V14.6023C20.2584 14.9742 19.972 15.2678 19.6092 15.2678Z" fill="#333333"/>
                                        <path d="M17.5652 9.92368C17.2024 9.92368 16.916 9.63011 16.916 9.25824C16.916 9.08209 16.9828 8.91573 17.107 8.78851L18.816 7.03683C19.2552 6.58668 19.9712 6.58668 20.4104 7.03683L22.043 8.71023C22.3008 8.96466 22.3103 9.38546 22.0621 9.64968C21.8139 9.9139 21.4033 9.92368 21.1456 9.66925L21.1265 9.64968L19.618 8.1035L18.0331 9.72796C17.909 9.85518 17.7466 9.92368 17.5748 9.92368H17.5652Z" fill="#333333"/>
                                        <path d="M3.24615 0H35.1539C36.9488 0 38.4 1.48746 38.4 3.32722V21.03C38.4 22.8697 36.9488 24.3572 35.1539 24.3572H3.24615C1.45122 24.3572 0 22.8697 0 21.03V3.32722C0 1.48746 1.45122 0 3.24615 0ZM35.1539 23.0165C36.2232 23.0165 37.1015 22.126 37.1015 21.0202V3.32722C37.1015 2.22141 36.2327 1.33089 35.1539 1.33089H3.24615C2.16728 1.33089 1.29846 2.22141 1.29846 3.32722V21.03C1.29846 22.1358 2.17683 23.0263 3.24615 23.0263H35.1539V23.0165Z" fill="#333333"/>
                                        <path d="M12.9658 23.0156H25.9313V29.4646H12.9658V23.0156ZM24.6329 24.3465H14.2643V28.1435H24.6329V24.3465Z" fill="#333333"/>
                                        <path d="M11.9826 28.2227H26.9053C27.9269 28.2227 28.7479 29.0642 28.7479 30.1113C28.7479 31.1584 27.9269 32 26.9053 32H11.9826C10.961 32 10.1399 31.1584 10.1399 30.1113C10.1399 29.0642 10.961 28.2227 11.9826 28.2227ZM26.9053 30.6691C27.2108 30.6691 27.4495 30.4147 27.4495 30.1113C27.4495 29.7982 27.2013 29.5535 26.9053 29.5535H11.9826C11.677 29.5535 11.4384 29.808 11.4384 30.1113C11.4384 30.4245 11.6866 30.6691 11.9826 30.6691H26.9053Z" fill="#333333"/>
                                    </svg>

                                </div>
                            </div>
                            <div class="row" v-if="!(training.trailer && training.trailer.length > 0)">
                                {{toLocal('training_edit.upload_from_pc')}}
                            </div>
                            <input style="display:none" ref="trailer" accept="video/mp4,video/x-m4v,video/*" type="file" name="file" id="file" class="inputfile" @change="uploadTrailer"/>
                        </div>
                        <div class="col align-center" v-if="!isUploadingTrailer">
                            <div class="row">
                                <div class="icon-container">
                                    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.8975 27.9462L13.5492 23.6937C13.3796 23.4817 13.0688 23.4394 12.8569 23.6089C12.8287 23.6372 12.8004 23.6654 12.7721 23.6937L9.4238 27.9462L8.33594 27.0844L11.6843 22.8319C12.0375 22.3798 12.5885 22.1113 13.1677 22.1113C13.747 22.1113 14.298 22.3798 14.6512 22.8319L17.9995 27.0844L16.9117 27.9462H16.8975Z" fill="#333333"/>
                                        <path d="M26.6029 27.9315L20.217 19.8644C20.0475 19.6525 19.7367 19.6101 19.5106 19.7796C19.4824 19.8079 19.4541 19.8362 19.4259 19.8644L15.6113 24.7103L14.5234 23.8485L18.338 19.0026C18.9879 18.1832 20.1747 18.0419 20.9941 18.6918C21.1071 18.7907 21.2201 18.8896 21.3049 19.0026L27.6908 27.0697L26.6029 27.9315Z" fill="#333333"/>
                                        <path d="M11.6116 15.4141C12.8549 15.4141 13.858 16.4172 13.858 17.6604C13.858 18.9037 12.8549 19.9068 11.6116 19.9068C10.3683 19.9068 9.36523 18.9037 9.36523 17.6604C9.36523 16.4313 10.3683 15.4282 11.6116 15.4141ZM11.6116 18.5081C12.0778 18.5081 12.4593 18.1266 12.4593 17.6604C12.4593 17.1942 12.0778 16.8127 11.6116 16.8127C11.1454 16.8127 10.7639 17.1942 10.7639 17.6604C10.7639 18.1266 11.1454 18.5081 11.6116 18.5081Z" fill="#333333"/>
                                        <path d="M18.9731 22.4219L18.5916 22.1817C18.5916 22.1817 17.97 21.8002 17.3908 21.4611C17.0658 21.2634 16.9528 20.8395 17.1506 20.5146C17.3484 20.1896 17.7722 20.0766 18.0972 20.2744C18.3939 20.4439 18.7047 20.6276 18.9307 20.7689L19.8349 20.1614L20.5696 20.6982L21.3749 20.1472C21.6857 19.9212 22.1237 19.9918 22.3497 20.3168C22.5758 20.6276 22.5051 21.0656 22.1802 21.2916L20.5696 22.4077L19.8208 21.8709L18.9872 22.436L18.9731 22.4219Z" fill="#333333"/>
                                        <path d="M8.50404 13.041H26.4608C27.6051 13.041 28.5376 13.9735 28.5517 15.132V26.1377C28.5517 27.2821 27.6193 28.2145 26.4608 28.2287H8.50404C7.35966 28.2287 6.42721 27.2962 6.41309 26.1377V15.132C6.41309 13.9876 7.34554 13.0551 8.50404 13.041ZM26.4608 26.83C26.8422 26.83 27.153 26.5192 27.153 26.1377V15.132C27.153 14.7505 26.8422 14.4397 26.4608 14.4397H8.50404C8.12258 14.4397 7.81176 14.7505 7.81176 15.132V26.1377C7.81176 26.5192 8.12258 26.83 8.50404 26.83H26.4608Z" fill="#333333"/>
                                        <path d="M3.4755 8.77344H31.2512C33.1726 8.77344 34.7267 10.3275 34.7267 12.2489V28.5244C34.7267 30.4458 33.1726 31.9999 31.2512 31.9999H3.4755C1.55408 31.9999 0 30.4458 0 28.5244V12.2489C0 10.3275 1.55408 8.77344 3.4755 8.77344ZM31.2512 30.6154C32.3956 30.6154 33.328 29.6829 33.3422 28.5244V12.2489C33.3422 11.1046 32.4097 10.1721 31.2512 10.158H3.4755C2.33113 10.158 1.39868 11.0904 1.38455 12.2489V28.5244C1.38455 29.6688 2.317 30.6013 3.4755 30.6154H31.2512Z" fill="#333333"/>
                                        <path d="M34.0203 13.1956C33.6389 13.1956 33.328 12.8848 33.328 12.5033V6.44239C33.328 5.55232 32.6075 4.84592 31.7174 4.83179H18.5784C17.5753 4.83179 16.6428 4.33731 16.0918 3.50375L15.1594 2.10508C14.8627 1.66711 14.3682 1.39868 13.8313 1.39868H2.99514C2.10508 1.39868 1.39868 2.11921 1.39868 3.00927V12.5174C1.39868 12.8989 1.08786 13.2097 0.706402 13.2097C0.324945 13.2097 0.014128 12.8989 0.014128 12.5174H0V2.99514C0 1.34216 1.34216 0 2.99514 0H13.8313C14.8344 0 15.7669 0.494481 16.3179 1.32804L17.2503 2.72671C17.547 3.16468 18.0556 3.43311 18.5784 3.43311H31.7174C33.3704 3.43311 34.7126 4.77528 34.7126 6.42826V12.4892C34.7126 12.8706 34.4018 13.1815 34.0203 13.1815V13.1956Z" fill="#333333"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="row" v-if="!(training.trailer && training.trailer.length > 0)">
                                {{toLocal('training_edit.upload_from_gallery')}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <transition name="languages">
            <div class="languages-container" v-if="showLanguagesSection && !['scorm', 'singleDoc'].includes(training.trainingType)">
                <div class="close-btn" @click="$emit('closeLanguageSection')">
                    <CloseCrossSvg />
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <p v-html="toLocal('general.linked_english_training')">
                                
                            </p>
                            <InputWithSearch 
                                v-model="selectedEnTraining"
                                :data="siblingTrainings"
                                icon="arrow"
                                :is-dropdown="true"
                                value-key="object"
                                :has-clear="true"
                                custom-class="languages"
                                :placeholder="toLocal('general.select_training')"
                                @clear="selectedEnTraining = null"
                            />
                        </div>

                    </div>
                    <div class="col">
                        <div class="row">
                            <p v-html="toLocal('general.linked_russian_training')"></p>
                            <InputWithSearch 
                                v-model="selectedRuTraining"
                                :data="siblingTrainings"
                                icon="arrow"
                                value-key="object"
                                :has-clear="true"
                                :is-dropdown="true"
                                custom-class="languages"
                                :placeholder="toLocal('general.select_training')"
                                @clear="selectedRuTraining = null"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="row actions" v-if="!['scorm', 'singleDoc'].includes(training.trainingType)">
            <h2>{{ toLocal("training.content") }}</h2>
            <div class="btn-container" v-if="!['attest'].includes(training.trainingType)">
                <button @click="$set(training,'lockTopics', !training.lockTopics)" :class="{active: training.lockTopics}">
                    <svg class="lock" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0868 13.1053H9.25C8.2835 13.1053 7.5 13.8888 7.5 14.8553V23.5132C7.5 24.4797 8.2835 25.2632 9.25 25.2632H21.4722C22.4387 25.2632 23.2222 24.4797 23.2222 23.5132V14.8553C23.2222 13.8888 22.4387 13.1053 21.4722 13.1053H20.6354C20.6324 12.3208 20.6192 11.5721 20.5632 10.8855C20.4896 9.98467 20.3385 9.13012 20.0034 8.39339C19.6589 7.63618 19.1246 7.01466 18.3251 6.5962C17.5441 6.18747 16.563 6 15.3611 6C14.1592 6 13.1781 6.18747 12.3972 6.5962C11.5976 7.01466 11.0633 7.63618 10.7188 8.39339C10.3837 9.13012 10.2326 9.98467 10.1591 10.8855C10.103 11.5721 10.0899 12.3208 10.0868 13.1053ZM11.5869 13.1053H19.1354C19.1323 12.3352 19.1196 11.6382 19.0681 11.0076C19.0003 10.1765 18.8685 9.5212 18.638 9.01451C18.4168 8.5283 18.1027 8.17284 17.6295 7.92518C17.1377 7.6678 16.4218 7.5 15.3611 7.5C14.3004 7.5 13.5845 7.6678 13.0927 7.92518C12.6195 8.17284 12.3054 8.5283 12.0842 9.01451C11.8537 9.5212 11.7219 10.1765 11.6541 11.0076C11.6026 11.6382 11.59 12.3352 11.5869 13.1053ZM9.25 14.6053C9.11193 14.6053 9 14.7172 9 14.8553V23.5132C9 23.6512 9.11193 23.7632 9.25 23.7632H21.4722C21.6103 23.7632 21.7222 23.6512 21.7222 23.5132V14.8553C21.7222 14.7172 21.6103 14.6053 21.4722 14.6053H9.25Z" fill="#333333"/>
                        <path d="M15.2751 19.5113C16.0322 19.5113 16.6459 18.8936 16.6459 18.1316C16.6459 17.3696 16.0322 16.752 15.2751 16.752C14.518 16.752 13.9043 17.3696 13.9043 18.1316C13.9043 18.8936 14.518 19.5113 15.2751 19.5113Z" fill="#333333"/>
                        <path d="M14.7598 19.4359C14.7598 19.152 14.9899 18.9219 15.2738 18.9219C15.5577 18.9219 15.7879 19.152 15.7879 19.4359V21.6872C15.7879 21.9711 15.5577 22.2013 15.2738 22.2013C14.9899 22.2013 14.7598 21.9711 14.7598 21.6872V19.4359Z" fill="#333333"/>
                    </svg>
                  
                    {{ toLocal("training_edit.lock_topics") }}
                </button>
                <button  @click="!hasTest ? addNewTest() : null" :class="{ deactivated: hasTest }">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4984 8L15.4989 24M23.5 16.0015L7.5 16.0037" stroke="#333333" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
        
                    {{ toLocal("training_edit.create_new_test") }}
                </button>
                <button @click="addNewTopic">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4984 8L15.4989 24M23.5 16.0015L7.5 16.0037" stroke="#333333" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    {{ toLocal("training_edit.create_new_item") }}
                </button>
            </div>
        </div>

        <draggable
          v-if="training.content && !['scorm', 'attest', 'singleDoc'].includes(training.trainingType)"
          v-model="training.content"
          class="content--list"
          animation="200"
          :options="{ disabled: editingTrainingTopic != -1 || hoveringTopicDescription}"
        >
          <transition-group type="transition">
            <div
              v-for="(
                trainingTopic, trainingTopicIndex
              ) in training.content.filter((el) => !el.test)"
              :key="trainingTopicIndex"
              class="item-container"
               
            >
                <div 
                :class="[
                    'content--list--item',
                    trainingTopic.enabled ? '' : 'disabled',
                    trainingTopic.test ? 'test' : '',
                    !training.paid ? 'no-description' : ''
                ]"
                >

                    <div class="item--move">
                        <svg width="10" height="30" viewBox="0 0 10 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 11L5 0.999999M5 0.999999L1 4.88889M5 0.999999L9 4.88889" stroke="#C5C5C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 19L5 29M5 29L9 25.1111M5 29L1 25.1111" stroke="#C5C5C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="item--number" @click="$emit('gotoTopic', trainingTopicIndex)">
                        {{ trainingTopicIndex + 1 }}
                    </div>
                    <div class="item--name">
                        <span
                        v-if="editingTrainingTopic != trainingTopicIndex"
                        @click="editTrainingTopic(trainingTopicIndex)"
                        >
                        {{ trainingTopic.title }}
                        </span>
                        <input
                        v-if="editingTrainingTopic == trainingTopicIndex"
                        :ref="`trainingContentTopic-${trainingTopicIndex}`"
                        class="training--content--topic--edit"
                        type="text"
                        maxLength="150"
                        @keyup.enter="
                            setTopicTitle(trainingTopicIndex, $event.srcElement.value)
                        "
                        @blur="setTopicTitle(trainingTopicIndex, $event.srcElement.value)"
                        />
                    </div>
                    <div class="item--actions" >
                        <svg  @click="dublicateTopic(trainingTopicIndex)" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.9955 10.8926L10.8936 16.9945V13.3946L13.3956 10.8926H16.9955Z" fill="#C5C5C5"/>
                            <path d="M21.106 14.6712V18.271L18.272 21.105H14.6721L21.106 14.6712Z" fill="#C5C5C5"/>
                            <path d="M20.9656 12.6287L12.6297 20.9646C11.7744 20.6837 11.1106 19.9561 10.9447 19.0498L19.0508 10.9437C19.9572 11.1096 20.6848 11.7734 20.9656 12.6287Z" fill="#C5C5C5"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.74 13.5494C10.74 11.9984 11.9974 10.741 13.5484 10.741H25.1905C26.7416 10.741 27.999 11.9984 27.999 13.5494V25.1916C27.999 26.7426 26.7416 28 25.1905 28H13.5484C11.9974 28 10.74 26.7426 10.74 25.1916V13.5494ZM13.5484 12.2729C12.8434 12.2729 12.2718 12.8444 12.2718 13.5494V25.1916C12.2718 25.8966 12.8434 26.4681 13.5484 26.4681H25.1905C25.8956 26.4681 26.4671 25.8966 26.4671 25.1916V13.5494C26.4671 12.8444 25.8956 12.2729 25.1905 12.2729H13.5484Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.80841C4 5.25737 5.25737 4 6.80841 4H18.4506C20.0016 4 21.259 5.25737 21.259 6.80841V18.4506C21.259 20.0016 20.0016 21.259 18.4506 21.259H6.80841C5.25737 21.259 4 20.0016 4 18.4506V6.80841ZM6.80841 5.53186C6.10339 5.53186 5.53186 6.10339 5.53186 6.80841V18.4506C5.53186 19.1556 6.10339 19.7271 6.80841 19.7271H18.4506C19.1556 19.7271 19.7271 19.1556 19.7271 18.4506V6.80841C19.7271 6.10339 19.1556 5.53186 18.4506 5.53186H6.80841Z" fill="#7D7D7D"/>
                        </svg>
                        <svg  @click="toggleTopic(trainingTopicIndex)" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 25.8947C20.6271 25.8947 24.3782 22.1436 24.3782 17.5165C24.3782 12.8893 20.6271 9.13831 16 9.13831C11.3729 9.13831 7.62183 12.8893 7.62183 17.5165C7.62183 22.1436 11.3729 25.8947 16 25.8947ZM16 27.5165C21.5228 27.5165 26 23.0393 26 17.5165C26 11.9936 21.5228 7.51649 16 7.51649C10.4772 7.51649 6 11.9936 6 17.5165C6 23.0393 10.4772 27.5165 16 27.5165Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0966 4C16.5445 4 16.9075 4.36306 16.9075 4.81091V13.7385C16.9075 14.1864 16.5445 14.5494 16.0966 14.5494C15.6488 14.5494 15.2857 14.1864 15.2857 13.7385V4.81091C15.2857 4.36306 15.6488 4 16.0966 4Z" fill="#7D7D7D"/>
                        </svg>
                        <svg @click="deleteTopic(trainingTopicIndex)" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77406 8.02246H23.4652V8.63001H24.0727V23.0493C24.0727 25.9933 21.6864 28.3796 18.7425 28.3796H13.1043C10.1604 28.3796 7.77406 25.9933 7.77406 23.0493V8.02246ZM22.8576 9.23757H8.98917V23.0493C8.98917 25.3222 10.8315 27.1645 13.1043 27.1645H18.7425C21.0154 27.1645 22.8576 25.3222 22.8576 23.0493V9.23757Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00003 7.13346C6.01144 5.98129 6.94754 5.05759 8.09809 5.05759H24.0784L24.1083 5.06054C25.2584 5.17442 26.1024 6.19845 25.9899 7.35037C25.8863 8.49533 24.875 9.3327 23.7374 9.23757H8.09809C6.93412 9.23757 6 8.29104 6 7.13948L6.00003 7.13346ZM7.21512 7.14248C7.21674 7.6259 7.61052 8.02246 8.09809 8.02246H23.7949L23.8248 8.02541C24.3094 8.07339 24.7372 7.71843 24.7799 7.23947L24.7804 7.23353C24.8274 6.75895 24.4864 6.33333 24.0146 6.2727H8.09809C7.6133 6.2727 7.2215 6.65971 7.21512 7.14248Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9459 5.09809C11.9459 3.94869 12.8779 3 14.0359 3H17.7866C18.9505 3 19.8847 3.94652 19.8847 5.09809C19.8847 5.36168 19.8404 5.62736 19.7341 5.88708L19.5797 6.2646H12.259L12.1046 5.88708C12.08 5.82689 12.0587 5.76638 12.0405 5.70565H11.9459V5.09809ZM13.1692 5.04949V4.97827C13.2278 4.54781 13.5975 4.21511 14.0359 4.21511H17.7866C18.2587 4.21511 18.6429 4.58698 18.6682 5.04949H13.1692Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2619 11.1412C12.5974 11.1412 12.8694 11.4133 12.8694 11.7488V23.7055C12.8694 24.041 12.5974 24.313 12.2619 24.313C11.9263 24.313 11.6543 24.041 11.6543 23.7055V11.7488C11.6543 11.4133 11.9263 11.1412 12.2619 11.1412Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9234 11.1412C16.2589 11.1412 16.531 11.4133 16.531 11.7488V23.7055C16.531 24.041 16.2589 24.313 15.9234 24.313C15.5879 24.313 15.3158 24.041 15.3158 23.7055V11.7488C15.3158 11.4133 15.5879 11.1412 15.9234 11.1412Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5849 11.1412C19.9205 11.1412 20.1925 11.4133 20.1925 11.7488V23.7055C20.1925 24.041 19.9205 24.313 19.5849 24.313C19.2494 24.313 18.9774 24.041 18.9774 23.7055V11.7488C18.9774 11.4133 19.2494 11.1412 19.5849 11.1412Z" fill="#7D7D7D"/>
                        </svg>
                    </div>
                    <div 
                        class="shown-lock-button"
                        :class="{locked: !shownTopics.includes(trainingTopic.id)}"
                        @click="toggleShownTopics(trainingTopic)"
                        v-if="training.paid"
                    >
                        <svg v-if="shownTopics.includes(trainingTopic.id)" width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.17015 6C3.33833 9.05942 6.51223 10.9511 10 10.9511C13.4873 10.9511 16.6541 9.05988 18.8295 5.99953C16.6613 2.94038 13.4876 1.04886 10 1.04886C6.51223 1.04886 3.33833 2.94058 1.17015 6ZM0.0887348 5.7093C2.41324 2.25015 5.97924 0 10 0C14.0208 0 17.5868 2.25015 19.9113 5.7093C20.0298 5.88562 20.0296 6.11528 19.9108 6.29141C17.5789 9.74943 14.0212 12 10 12C5.97924 12 2.41324 9.74986 0.0887348 6.2907C-0.0295783 6.11464 -0.0295783 5.88536 0.0887348 5.7093Z" fill="#333333"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0535 3.67099C8.88455 3.67099 7.93696 4.61017 7.93696 5.7687C7.93696 6.92724 8.88455 7.86641 10.0535 7.86641C11.2224 7.86641 12.17 6.92724 12.17 5.7687C12.17 4.61017 11.2224 3.67099 10.0535 3.67099ZM6.8787 5.7687C6.8787 4.0309 8.30009 2.62214 10.0535 2.62214C11.8069 2.62214 13.2283 4.0309 13.2283 5.7687C13.2283 7.5065 11.8069 8.91527 10.0535 8.91527C8.30009 8.91527 6.8787 7.5065 6.8787 5.7687Z" fill="#333333"/>
                        </svg>
                        <svg v-else width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.46797 6.63934H1.66961C0.747509 6.63934 0 7.37147 0 8.27459V16.3648C0 17.2679 0.74751 18 1.66961 18H13.3304C14.2525 18 15 17.2679 15 16.3648V8.27459C15 7.37147 14.2525 6.63934 13.3304 6.63934H12.532C12.5291 5.90631 12.5166 5.20671 12.4631 4.56515C12.3929 3.72337 12.2488 2.92487 11.929 2.23644C11.6004 1.52889 11.0906 0.948127 10.3278 0.557105C9.58271 0.175174 8.6467 0 7.5 0C6.3533 0 5.41729 0.175174 4.67221 0.557105C3.9094 0.948127 3.39962 1.52889 3.07097 2.23644C2.75122 2.92486 2.60708 3.72337 2.53691 4.56515C2.48342 5.20671 2.4709 5.90631 2.46797 6.63934ZM3.89913 6.63934H11.1009C11.0979 5.91977 11.0859 5.26851 11.0367 4.67922C10.972 3.90265 10.8463 3.2903 10.6264 2.81684C10.4154 2.36251 10.1156 2.03036 9.66418 1.79894C9.19499 1.55843 8.51199 1.40164 7.5 1.40164C6.48801 1.40164 5.80501 1.55843 5.33582 1.79894C4.88437 2.03036 4.58464 2.36251 4.37361 2.81684C4.1537 3.2903 4.028 3.90265 3.96325 4.67922C3.91413 5.26851 3.90208 5.91977 3.89913 6.63934ZM1.66961 8.04098C1.53788 8.04098 1.4311 8.14557 1.4311 8.27459V16.3648C1.4311 16.4938 1.53788 16.5984 1.66961 16.5984H13.3304C13.4621 16.5984 13.5689 16.4938 13.5689 16.3648V8.27459C13.5689 8.14557 13.4621 8.04098 13.3304 8.04098H1.66961Z" fill="#333333"/>
                            <path d="M7.41795 12.6253C8.14026 12.6253 8.7258 12.0481 8.7258 11.3361C8.7258 10.6241 8.14026 10.0469 7.41795 10.0469C6.69565 10.0469 6.11011 10.6241 6.11011 11.3361C6.11011 12.0481 6.69565 12.6253 7.41795 12.6253Z" fill="#333333"/>
                            <path d="M6.92628 12.5549C6.92628 12.2896 7.14586 12.0745 7.41672 12.0745C7.68758 12.0745 7.90716 12.2896 7.90716 12.5549V14.6585C7.90716 14.9238 7.68758 15.1389 7.41672 15.1389C7.14586 15.1389 6.92628 14.9238 6.92628 14.6585V12.5549Z" fill="#333333"/>
                        </svg>
                    </div>
                </div>
              <div v-if="$store.state.system_settings.showTrainingPaymentSettings && training.paid" class="description-container" @mouseover="hoveringTopicDescription=true" @mouseleave="hoveringTopicDescription=false">
                <div class="description-content">
                    <textarea v-model="trainingTopic.description" rows="3" :placeholder="toLocal('topic.description')" maxlength="500"></textarea>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
        <div
          v-for="(trainingTopic, trainingTopicIndex) in training.content.filter(
            (el) => el.test
          )"
          :key="trainingTopicIndex"
          class="item-container"
          v-show="!['scorm', 'singleDoc'].includes(training.trainingType)"
        >
                <div 
                    :class="[
                        'content--list--item',
                        trainingTopic.enabled ? '' : 'disabled',
                        trainingTopic.test ? 'test' : '',
                        !training.paid ? 'no-description' : ''
                    ]"
                >
                <div class="item--move hidden">
                </div>
                <div
                    class="item--number"
                    @click="$emit('gotoTopic',training.content.length - 1)"
                >
                    {{ ['attest'].includes(training.trainingType) ? training.content.length-1 : training.content.length }}
                </div>
                <div class="item--name">
                    <span
                    v-if="editingTrainingTopic != training.content.length - 1"
                    @click="editTrainingTopic(training.content.length - 1)"
                    >
                    {{ ['attest'].includes(training.trainingType) ? toLocal("training.attesting") : trainingTopic.title }}
                    </span>
                    <input
                        v-if="editingTrainingTopic == training.content.length - 1"
                        :ref="`trainingContentTopic-${training.content.length - 1}`"
                        class="training--content--topic--edit"
                        type="text"
                        @keyup.enter="
                            setTopicTitle(
                            training.content.length - 1,
                            $event.srcElement.value
                            )
                        "
                        @blur="
                            setTopicTitle(
                            training.content.length - 1,
                            $event.srcElement.value
                            );
                        "
                    />
                </div>
                <div class="item--actions" v-if="!['attest'].includes(training.trainingType)">
                    <svg  @click="toggleTopic(training.content.length - 1)" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 25.8947C20.6271 25.8947 24.3782 22.1436 24.3782 17.5165C24.3782 12.8893 20.6271 9.13831 16 9.13831C11.3729 9.13831 7.62183 12.8893 7.62183 17.5165C7.62183 22.1436 11.3729 25.8947 16 25.8947ZM16 27.5165C21.5228 27.5165 26 23.0393 26 17.5165C26 11.9936 21.5228 7.51649 16 7.51649C10.4772 7.51649 6 11.9936 6 17.5165C6 23.0393 10.4772 27.5165 16 27.5165Z" fill="#7D7D7D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0966 4C16.5445 4 16.9075 4.36306 16.9075 4.81091V13.7385C16.9075 14.1864 16.5445 14.5494 16.0966 14.5494C15.6488 14.5494 15.2857 14.1864 15.2857 13.7385V4.81091C15.2857 4.36306 15.6488 4 16.0966 4Z" fill="#7D7D7D"/>
                    </svg>
                    <svg @click="deleteTopic(training.content.length - 1)" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77406 8.02246H23.4652V8.63001H24.0727V23.0493C24.0727 25.9933 21.6864 28.3796 18.7425 28.3796H13.1043C10.1604 28.3796 7.77406 25.9933 7.77406 23.0493V8.02246ZM22.8576 9.23757H8.98917V23.0493C8.98917 25.3222 10.8315 27.1645 13.1043 27.1645H18.7425C21.0154 27.1645 22.8576 25.3222 22.8576 23.0493V9.23757Z" fill="#7D7D7D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00003 7.13346C6.01144 5.98129 6.94754 5.05759 8.09809 5.05759H24.0784L24.1083 5.06054C25.2584 5.17442 26.1024 6.19845 25.9899 7.35037C25.8863 8.49533 24.875 9.3327 23.7374 9.23757H8.09809C6.93412 9.23757 6 8.29104 6 7.13948L6.00003 7.13346ZM7.21512 7.14248C7.21674 7.6259 7.61052 8.02246 8.09809 8.02246H23.7949L23.8248 8.02541C24.3094 8.07339 24.7372 7.71843 24.7799 7.23947L24.7804 7.23353C24.8274 6.75895 24.4864 6.33333 24.0146 6.2727H8.09809C7.6133 6.2727 7.2215 6.65971 7.21512 7.14248Z" fill="#7D7D7D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9459 5.09809C11.9459 3.94869 12.8779 3 14.0359 3H17.7866C18.9505 3 19.8847 3.94652 19.8847 5.09809C19.8847 5.36168 19.8404 5.62736 19.7341 5.88708L19.5797 6.2646H12.259L12.1046 5.88708C12.08 5.82689 12.0587 5.76638 12.0405 5.70565H11.9459V5.09809ZM13.1692 5.04949V4.97827C13.2278 4.54781 13.5975 4.21511 14.0359 4.21511H17.7866C18.2587 4.21511 18.6429 4.58698 18.6682 5.04949H13.1692Z" fill="#7D7D7D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2619 11.1412C12.5974 11.1412 12.8694 11.4133 12.8694 11.7488V23.7055C12.8694 24.041 12.5974 24.313 12.2619 24.313C11.9263 24.313 11.6543 24.041 11.6543 23.7055V11.7488C11.6543 11.4133 11.9263 11.1412 12.2619 11.1412Z" fill="#7D7D7D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9234 11.1412C16.2589 11.1412 16.531 11.4133 16.531 11.7488V23.7055C16.531 24.041 16.2589 24.313 15.9234 24.313C15.5879 24.313 15.3158 24.041 15.3158 23.7055V11.7488C15.3158 11.4133 15.5879 11.1412 15.9234 11.1412Z" fill="#7D7D7D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5849 11.1412C19.9205 11.1412 20.1925 11.4133 20.1925 11.7488V23.7055C20.1925 24.041 19.9205 24.313 19.5849 24.313C19.2494 24.313 18.9774 24.041 18.9774 23.7055V11.7488C18.9774 11.4133 19.2494 11.1412 19.5849 11.1412Z" fill="#7D7D7D"/>
                    </svg>
                </div>
                <div 
                        class="shown-lock-button"
                        :class="{locked: !shownTopics.includes(trainingTopic.id)}"
                        @click="toggleShownTopics(trainingTopic)"
                        v-if="training.paid"
                >
                    <svg v-if="shownTopics.includes(trainingTopic.id)" width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.17015 6C3.33833 9.05942 6.51223 10.9511 10 10.9511C13.4873 10.9511 16.6541 9.05988 18.8295 5.99953C16.6613 2.94038 13.4876 1.04886 10 1.04886C6.51223 1.04886 3.33833 2.94058 1.17015 6ZM0.0887348 5.7093C2.41324 2.25015 5.97924 0 10 0C14.0208 0 17.5868 2.25015 19.9113 5.7093C20.0298 5.88562 20.0296 6.11528 19.9108 6.29141C17.5789 9.74943 14.0212 12 10 12C5.97924 12 2.41324 9.74986 0.0887348 6.2907C-0.0295783 6.11464 -0.0295783 5.88536 0.0887348 5.7093Z" fill="#333333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0535 3.67099C8.88455 3.67099 7.93696 4.61017 7.93696 5.7687C7.93696 6.92724 8.88455 7.86641 10.0535 7.86641C11.2224 7.86641 12.17 6.92724 12.17 5.7687C12.17 4.61017 11.2224 3.67099 10.0535 3.67099ZM6.8787 5.7687C6.8787 4.0309 8.30009 2.62214 10.0535 2.62214C11.8069 2.62214 13.2283 4.0309 13.2283 5.7687C13.2283 7.5065 11.8069 8.91527 10.0535 8.91527C8.30009 8.91527 6.8787 7.5065 6.8787 5.7687Z" fill="#333333"/>
                    </svg>
                    <svg v-else width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.46797 6.63934H1.66961C0.747509 6.63934 0 7.37147 0 8.27459V16.3648C0 17.2679 0.74751 18 1.66961 18H13.3304C14.2525 18 15 17.2679 15 16.3648V8.27459C15 7.37147 14.2525 6.63934 13.3304 6.63934H12.532C12.5291 5.90631 12.5166 5.20671 12.4631 4.56515C12.3929 3.72337 12.2488 2.92487 11.929 2.23644C11.6004 1.52889 11.0906 0.948127 10.3278 0.557105C9.58271 0.175174 8.6467 0 7.5 0C6.3533 0 5.41729 0.175174 4.67221 0.557105C3.9094 0.948127 3.39962 1.52889 3.07097 2.23644C2.75122 2.92486 2.60708 3.72337 2.53691 4.56515C2.48342 5.20671 2.4709 5.90631 2.46797 6.63934ZM3.89913 6.63934H11.1009C11.0979 5.91977 11.0859 5.26851 11.0367 4.67922C10.972 3.90265 10.8463 3.2903 10.6264 2.81684C10.4154 2.36251 10.1156 2.03036 9.66418 1.79894C9.19499 1.55843 8.51199 1.40164 7.5 1.40164C6.48801 1.40164 5.80501 1.55843 5.33582 1.79894C4.88437 2.03036 4.58464 2.36251 4.37361 2.81684C4.1537 3.2903 4.028 3.90265 3.96325 4.67922C3.91413 5.26851 3.90208 5.91977 3.89913 6.63934ZM1.66961 8.04098C1.53788 8.04098 1.4311 8.14557 1.4311 8.27459V16.3648C1.4311 16.4938 1.53788 16.5984 1.66961 16.5984H13.3304C13.4621 16.5984 13.5689 16.4938 13.5689 16.3648V8.27459C13.5689 8.14557 13.4621 8.04098 13.3304 8.04098H1.66961Z" fill="#333333"/>
                        <path d="M7.41795 12.6253C8.14026 12.6253 8.7258 12.0481 8.7258 11.3361C8.7258 10.6241 8.14026 10.0469 7.41795 10.0469C6.69565 10.0469 6.11011 10.6241 6.11011 11.3361C6.11011 12.0481 6.69565 12.6253 7.41795 12.6253Z" fill="#333333"/>
                        <path d="M6.92628 12.5549C6.92628 12.2896 7.14586 12.0745 7.41672 12.0745C7.68758 12.0745 7.90716 12.2896 7.90716 12.5549V14.6585C7.90716 14.9238 7.68758 15.1389 7.41672 15.1389C7.14586 15.1389 6.92628 14.9238 6.92628 14.6585V12.5549Z" fill="#333333"/>
                    </svg>
                </div>
            </div>
            <div 
                v-if="$store.state.system_settings.showTrainingPaymentSettings && training.paid" 
                class="description-container" 
                @mouseover="hoveringTopicDescription=true" 
                @mouseleave="hoveringTopicDescription=false"
            >
                <div class="description-content">
                    <textarea v-model="trainingTopic.description" rows="3" :placeholder="toLocal('topic.description')" maxlength="500"></textarea>
                </div>
            </div>
        </div>
        <div
            v-if="!['scorm', 'singleDoc'].includes(training.trainingType)"
          :class="[
            'content--list--item',
            'content--list--add',
            training.endingEnabled ? '' : 'disabled',
          ]"
        >
            <div class="item--move hidden">
               
            </div>
          <div class="item--number hidden" />
          <div class="item--name">
            {{ toLocal("training.ending") }}
          </div>
          <div class="item--actions">
            <svg @click="toggleTopic('ending')" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 25.8947C20.6271 25.8947 24.3782 22.1436 24.3782 17.5165C24.3782 12.8893 20.6271 9.13831 16 9.13831C11.3729 9.13831 7.62183 12.8893 7.62183 17.5165C7.62183 22.1436 11.3729 25.8947 16 25.8947ZM16 27.5165C21.5228 27.5165 26 23.0393 26 17.5165C26 11.9936 21.5228 7.51649 16 7.51649C10.4772 7.51649 6 11.9936 6 17.5165C6 23.0393 10.4772 27.5165 16 27.5165Z" fill="#7D7D7D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0966 4C16.5445 4 16.9075 4.36306 16.9075 4.81091V13.7385C16.9075 14.1864 16.5445 14.5494 16.0966 14.5494C15.6488 14.5494 15.2857 14.1864 15.2857 13.7385V4.81091C15.2857 4.36306 15.6488 4 16.0966 4Z" fill="#7D7D7D"/>
            </svg>
          </div>
        </div>
        <div class="scorm-task-settings" v-if="training.trainingType =='scorm' && trainingScormItem">
            <TrainingItem
                :trainingItem="trainingScormItem"
                :key="trainingScormItem.id"
                :training="training"
                :topic_index="0"
                :item_index="0"
                :trainingType="'content'"
                @onMediaChange="onMediaChange"
            />
        </div>
         <div class="scorm-task-settings" v-if="training.trainingType =='singleDoc' && singleDocItem">
            <TrainingItem
                :trainingItem="singleDocItem"
                :key="singleDocItem.id"
                :training="training"
                :topic_index="0"
                :item_index="0"
                :trainingType="'content'"
                @onMediaChange="onMediaChange"
            />
        </div>
        <h2>{{ toLocal("training.certificate") }}</h2>
        <div
          :class="[
            'content--list--item',
            'content--list--add',
            training.certificate
              ? training.certificate.enabled
                ? ''
                : 'disabled'
              : 'disabled',
          ]"
          :style="{
            'background-image': training.certificate
              ? training.certificate.enabled && selectedCert != null
                ? `url('${$store.state.api_assets_link}${selectedCert.path}')`
                : ''
              : '',
          }"
          @click="$emit('openCertificate')"
        >
            <div class="item--move hidden" />
          <div class="item--number hidden" />
          <div class="item--name">
            {{ toLocal("training.certificate") }}
          </div>
          <div class="item--actions">
            <svg @click.stop="toggleCertificate" class="stroke" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 25.8947C20.6271 25.8947 24.3782 22.1436 24.3782 17.5165C24.3782 12.8893 20.6271 9.13831 16 9.13831C11.3729 9.13831 7.62183 12.8893 7.62183 17.5165C7.62183 22.1436 11.3729 25.8947 16 25.8947ZM16 27.5165C21.5228 27.5165 26 23.0393 26 17.5165C26 11.9936 21.5228 7.51649 16 7.51649C10.4772 7.51649 6 11.9936 6 17.5165C6 23.0393 10.4772 27.5165 16 27.5165Z" fill="#7D7D7D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0966 4C16.5445 4 16.9075 4.36306 16.9075 4.81091V13.7385C16.9075 14.1864 16.5445 14.5494 16.0966 14.5494C15.6488 14.5494 15.2857 14.1864 15.2857 13.7385V4.81091C15.2857 4.36306 15.6488 4 16.0966 4Z" fill="#7D7D7D"/>
            </svg>
          </div>
          
        </div>
        <portal to="training-main-cancel-modal">
            <GlobalModal
                :modalInfo="{
                    text: toLocal('training_edit.shop_cancel_sale_modal_text'),
                    undertext: toLocal('training_edit.shop_cancel_sale_modal_undertext'),
                    confirmBtnText: toLocal('training_edit.shop_cancel_sale_modal_confirm'),
                    type: 'confirmation',
                }"
                @onConfirm="confirmCancel"
                @onClose="cancelModalOpened=false"
                v-if="cancelModalOpened"
            />
        </portal>
        <portal to="topic-delete-modal">
            <GlobalModal
                v-if="deletingTopic"
                :modalInfo="{
                    type: 'confirmation',
                    text: toLocal('training_edit.delete_topic_confirm').replace('<topic>',`'`+training.content[deletingTopicID].title+`'`),
                    undertext: '',
                    confirmBtnText: 'delete_modal.yes_delete',
                    class:'delete'
                }"
                @onConfirm="deleteTopicModal"
                @onClose="deletingTopic = false"
            />
        </portal>
        <portal to="paid-training-preview">
            <div class="paid-training-preview-container" v-scroll-lock="showPaidTrainingPreview" v-if="showPaidTrainingPreview">
                <PaidTraining
                    class="training-preview"
                    :training_id="$store.state.currentTraining"
                    :training_obj="training" 
                    @goBack="showPaidTrainingPreview=false" 
                />
            </div>
        </portal>
    </div>
</template>

<script>
import axios from "axios";
import TrainingEditNotesSvg from "@/assets/images/icons/notes.svg?inline";
import TrainingEditAddSvg from "@/assets/images/icons/training-edit/training-edit-add.svg?inline";
import TrainingEditLockSvg from "@/assets/images/icons/training-edit/training-edit-lock.svg?inline";
import TrainingEditCopyTopicSvg from "@/assets/images/icons/training-edit/training-edit-copy-topic.svg?inline";
import TrainingContentMoveSvg from "@/assets/images/icons/content-move.svg?inline";
import TrainingEditToggleTopicSvg from "@/assets/images/icons/training-edit/training-edit-toggle-topic.svg?inline";
import TrainingEditTrashTopicSvg from "@/assets/images/icons/training-edit/training-edit-trash-topic.svg?inline";
import draggable from "vuedraggable";
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";
import PaidTraining from "@/components/layout/payments/PaidTraining.vue";
import VueAutoNumeric from "@/components/layout/trainings/training_components/VueAutoNumeric.vue";
import SelectInput from "@/components/layout/SelectInput.vue";
import InputWithSearch from "@/components/layout/InputWithSearch";
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
import Training from '../../../../views/Training.vue';
import TrainingItem from "@/components/layout/TrainingItem.vue";

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  integerLimit: 2

});
const lengthMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 3

});
export default {
    components:{
        draggable,
        Editor,

        TrainingEditNotesSvg,
        TrainingEditAddSvg,
        TrainingEditLockSvg,
        TrainingContentMoveSvg,
        TrainingEditCopyTopicSvg,
        TrainingEditToggleTopicSvg,
        TrainingEditTrashTopicSvg,
        GlobalModal,
        PaidTraining,
        VueAutoNumeric,
        SelectInput,
        InputWithSearch,

        CloseCrossSvg,
        Training,
        TrainingItem

    },
  
    props:{
        training: {
            type: Object,
            default: () => {}
        },
        showLanguagesSection:{
            type: Boolean,
            default: false
        },
        siblingTrainings:{
            type: Array,
            default: () => []
        },
        
    },
    mounted(){
        this.$nextTick(()=>{
            
            if(!Array.isArray(this.training.language)) this.$set(this.training,'language', []);
            if(!Array.isArray(this.training.subtitles)) this.$set(this.training,'subtitles', []);

        })
    },
    data(){
        return{
            editingTrainingTopic: null,
            editingTrainingTopic: -1,
            editingTitle:false,
            editingDescription:false,
            isUploadingTrailer: false,
            uploadedData: null,
            uploadProgress: 0,
            numberMask,
            lengthMask,
            cancelModalOpened: false,
            cancelSource: null,
            hoveringTopicDescription: false,
            showPaidTrainingPreview:false,
            selectedLanguages: [],
            selectedSubtitles: [],
            deletingTopic: false,
            deletingTopicID: -1,
            selectedEnTraining: null,
            selectedRuTraining: null,

        }
    },
    computed:{
        hasTest() {
            if (this.training.content.find((el) => el.test) !== undefined)
                return true;
            else return false;
        },
        shownTopics(){
            return this.training.selectedShownTopics != undefined ? this.training.selectedShownTopics : [];
        },
        trainingScormItem() {
            if (this.training && this.training.content && this.training.trainingType =='scorm') {
                let item = null;
                this.training.content.forEach(element => {
                    let temp_item = element.items.find((el) => el.type == "scorm");
                    if(temp_item) item = temp_item;
                });
                return item;
            }
            else{
                return null
            }
        },
        singleDocItem() {
            if (this.training && this.training.content && this.training.content[0] && this.training.content[0].items[0] && this.training.trainingType =='singleDoc') {
                return this.training.content[0].items[0]
            }
            else {
                return null
            }
        }
    },
    watch:{
        training: {
            handler: function (val, oldVal) {
                if(this.training.price == undefined) this.training.price = 0;
                if(this.training.price_rent == undefined) this.training.price_rent = 0;
                this.$nextTick(()=>{
                    if(this.$route.query.editTitle && this.$route.query.editTitle == 1){
                        let ref = this.$refs.trainingTitle;
                        if(Array.isArray(ref)) ref = ref[0];
                        ref.$el.focus()
                        ref.$el.select();
                          
                    }
                })
            },
        },
        selectedEnTraining(){
            this.appendSiblingLanguage('en', this.selectedEnTraining)
        },
        selectedRuTraining(){
            this.appendSiblingLanguage('ru', this.selectedRuTraining)
        },
        'training.selectedEnTraining'(){
            if(this.selectedEnTraining == null) this.selectedEnTraining = this.training.selectedEnTraining;
        },
        'training.selectedRuTraining'(){
            if(this.selectedRuTraining == null) this.selectedRuTraining = this.training.selectedRuTraining;
        },
    },
    methods:{
        appendSiblingLanguage(language, obj){
            let training_id = null;
            if(this.selectedEnTraining != null){
                if(language == 'en'){
                    training_id = this.selectedEnTraining.value;
                }
                else{
                    training_id = this.selectedRuTraining.value;
                }
            }
            axios
            .post(
                `${this.$store.state.api_link}/training/${this.$store.state.currentTraining}/append-sibling-language`,
                { 
                    language: language,
                    language_object: obj,
                    sibling_training_id: training_id
                },
                this.$store.getters.axiosConfig()
            )
            .then((response) => {
                
            })
            .catch((error) => {
            });
      
        },  
        editTopicTitle(topicIndex) {
            setTimeout(() => {
                this.training.content[topicIndex].editingTitle = true;

                setTimeout(() => {
                this.$refs[`trainingTopic-${topicIndex}`][0].value =
                    this.training.content[topicIndex].title;
                }, 10);
            }, 10);
        },
        
        setTopicTitle(topicIndex, topicTitle) {
            if (topicTitle == "") topicTitle = "-";
            this.training.content[topicIndex].title = topicTitle;
            this.training.content[topicIndex].editingTitle = false;
            this.editingTrainingTopic = -1;
        },
        editTrainingTopic(topic) {
            if (this.editingTrainingTopic == topic) return;

            setTimeout(() => {
                this.editingTrainingTopic = topic;

                setTimeout(() => {
                let title = this.training.content[topic].title;
                this.$refs[`trainingContentTopic-${topic}`][0].value = title;
                this.$refs[`trainingContentTopic-${topic}`][0].focus();
                this.$refs[`trainingContentTopic-${topic}`][0].select();
                }, 10);
            }, 10);
        },
        onMediaChange(event, topic, index) {
            this.$emit("onMediaChange", event, topic, index);
        },
        setTrainingTime(trainingTime) {
            if (!this.$store.state.isUserTheOnlyOneEditingTraining) return;
            if (trainingTime == "") trainingTime = "0";
            var n = Math.floor(Number(trainingTime));
            if (!(n !== Infinity && String(n) === trainingTime && n >= 0)) {
                trainingTime = "0";
            }
            this.$refs["trainingTime"].value = trainingTime;
            this.training.time = trainingTime;
        },
        dublicateTopic(topic) {
            if (
                !this.training.content[topic].title.includes(
                this.toLocal("training_edit.topic_copy")
                )
            ) {
  
                this.arrayDublicateElement(this.training.content, topic);
                this.training.content[topic + 1].title +=
                " – " + this.toLocal("training_edit.topic_copy");
            } else {
                this.arrayDublicateElement(this.training.content, topic);
            }
        },
        arrayDublicateElement(arr, index) {
            let topic = JSON.parse(
                JSON.stringify(arr[index], this.censorJson(arr[index]))
            );
            let copy = this._.cloneDeep(topic);
            copy.id = this.training.topic_iter + 1;
            arr.splice(index+1, 0, copy);
            this.$set(this.training, "topic_iter", this.training.topic_iter + 1);
            return arr;
        },
        clickOutsideDescription(e) {
            this.editingDescription = false;
        },
        censorJson(censor) {
            return function (key, value) {
                if (key == "editor") return "";
                if (key == "innerScrollTop" || key == "visibleItems") return "-";

                return value;
            };
        },
        editTrainingTitle() {
            this.$set(this.training, "editingTitle", true);

            setTimeout(() => {
                let title = Array.isArray(this.$refs["trainingTitle"])
                ? this.$refs["trainingTitle"][0]
                : this.$refs["trainingTitle"];
                if (title) {
                title.focus();
                if (
                    this.training.title == "Tuščias mokymas" ||
                    this.training.title == "-"
                )
                    title.select();
                }
            }, 10);
        },
        toggleTopic(topic) {
            if (topic == "ending") {
                this.training.endingEnabled = !this.training.endingEnabled;
            } else {
                this.training.content[topic].enabled =
                !this.training.content[topic].enabled;
            }
        },
        deleteTopic(topic) {
            this.deletingTopicID = topic;
            this.$nextTick(()=>{
                this.deletingTopic = true;

            })
        },
        deleteTopicModal() {
            // if (
            //     !confirm(
            //     "\n\n🗑️ " +
            //         this.toLocal("training_edit.delete_topic_confirm").replace(
            //         "<topic>",
            //         this.training.content[topic].title
            //         ) +
            //         "\n\n"
            //     )
            // )
            //     return;

            this.training.content.splice(this.deletingTopicID, 1);
            this.deletingTopicID = -1;
            this.deletingTopic = false;
        },
        toggleCertificate() {
            this.training.certificate.enabled = !this.training.certificate.enabled;
            setTimeout(() => {
                this.certificateOpen = false;
            }, 50);
        },
        addNewTest() {
            const newItem = {
                id: this.training.topic_iter + 1,
                title: this.toLocal("test_side.new_test"),
                test: true,
                enabled: true,
                editingTitle: false,
                innerScrollTop: -1,
                visibleItems: [],
                testValidFrom: "2020",
                testExpiresAt: "2020",
                testPassedFrom: 80,
                testShownQuestionCount: 10,
                testAnswerShowType: 0,
                testQuestionsShuffle: false,
                testAnswersShuffle: false,
                testResultShowType: 0,
                addingQuestion: false,
                items: [],
            };
            this.training.content.splice(this.training.content.length, 0, newItem);
            this.$set(this.training, "topic_iter", this.training.topic_iter + 1);
        },
        addNewTopic() {
            const newItem = {
                id: this.training.topic_iter + 1,
                title: this.toLocal("training_edit.new_topic"),
                enabled: true,
                editingTitle: false,
                innerScrollTop: -1,
                visibleItems: [],
                items: [],
            };
            let has_test = this.training.content.filter((item) => item.test).length > 0;
            this.training.content.splice(
                this.training.content.length-(has_test?1:0),
                0,
                newItem
            );
            this.$set(this.training, "topic_iter", this.training.topic_iter + 1);
            // this.training.content = this.arrayInsert(this.training.content, this.training.content.length, newItem);

        },
        setTrainingTitle(trainingTitle) {
            if (!this.$store.state.isUserTheOnlyOneEditingTraining) return;
            if (trainingTitle == "") trainingTitle = "-";
            this.$set(this.training, "title", trainingTitle);
            this.$set(this.training, "editingTitle", false);
        },
        setTrainingDescription(trainingDescription) {
            if (trainingDescription == null)
                trainingDescription = this.editorDescription.getHTML();
            if (trainingDescription.length === 0 || !trainingDescription.trim())
                trainingDescription = "...";
            this.training.description = trainingDescription;
            this.training.editingDescription = false;
        },
        stopEditingTitle(){
            this.editingTitle=false;
        },
        toggleShownTopics(topic){
            if(this.training.selectedShownTopics == undefined) this.$set(this.training, 'selectedShownTopics', []);
            let index = this.training.selectedShownTopics.findIndex(el => el == topic.id);
            if(index != -1){
                this.training.selectedShownTopics.splice(index, 1);
            }else{
                this.training.selectedShownTopics.push(topic.id);
            }
        },
        uploadTrailer(e){
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            let file = files[0];
            let formData = new FormData();
            formData.append("file", file);
            this.isUploadingTrailer = true;
            const CancelToken = axios.CancelToken;
            this.cancelSource = CancelToken.source();
            axios
                .post(
                `${this.$store.state.api_link}/upload-file`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadedData = progressEvent.loaded
                        this.uploadProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    },
                    cancelToken: this.cancelSource.token
                }
                )
                .then((response) => {
                if (response.data.status == "success") {
                    let link = response.data.file;
                    this.$set(this.training, 'trailer', link);
                    this.isUploadingTrailer = false;
                } else {
                    alert("Error while uploading file");
                    this.isUploadingTrailer = false;
                }
                this.uploadProgress = 0;
                })
                .catch(() => {
                alert("Error while uploading file");
                this.isUploadingTrailer = false;
                this.uploadProgress = 0;
                });
        },
        isNumber(e){
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
            const keyPressed = e.key;
            
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },
        confirmCancel(){
            this.$set(this.training, 'price', undefined);
            this.$set(this.training, 'price_rent', undefined);
            this.$set(this.training, 'language', undefined);
            this.$set(this.training, 'subtitles', undefined);
            this.$set(this.training, 'trailer', undefined);
            this.$set(this.training, 'paid', false);
            this.cancelModalOpened = false;
        },
        cancelUpload(){
            this.cancelSource.cancel();
        },
        toggleSale(){
            let current_status = this.training.paid;
            if(!current_status){
                this.$set(this.training, 'paid', true);
                this.$emit('showBanner')
            }
            else{
                this.cancelModalOpened=true;

            }
        }
        
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
@import "@/assets/scss/views/_trainingEditViewScoped.scss";

.training--title {
    position: relative;
    font-weight: 500;
}
.notes--tooltip{
    position: absolute;
    top: 20px;
    right: 20px;
}
.training--description{
    font-size: 16px
}
.col{
    display: flex;
    flex-direction: column;
}
.row{
    display: flex;
    flex-direction: row;
}
.main-info-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: var(--main-site-font);
    margin-top: 30px;
    margin-bottom: 48px;
    .row{
        font-size: 16px;
        align-items: center;
    }
    input{
        background: white;
        border: none;
        text-align: center;
        padding: 5px;
        margin: 0 12px 0 8px;
        &:hover{
            border: none;
        }
    }
    .time--min{
        font-size: 16px;
        font-weight: 400;
    }
    h2{
        margin: 0 0 16px;
    }
}
.training--main{
    position: relative;
    h2{
        font-weight: 600;
        font-size: 24px;
        font-family: var(--main-site-font);
    }
}

.actions{
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    h2{
        margin: 0;
    }
    button{
        background: white;
    }
}
.btn-container{
    display: flex;
    gap: 16px;
    button{
        min-width: 160px;
    }
}
button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 22px 4px 8px;
    font-size: 16px;
    background: var(--secondary-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 44px;
    font-family: var(--main-site-font);
    cursor: pointer;
    border: none;
    svg{
        margin-right: 4px;
        path{
            // stroke: var(--primary-color);
        }
        &.lock{
            path{
                fill: var(--primary-color);
                stroke: initial;
            }
        }
    }
    &.deactivated{
        opacity: 0.5;
    }
    &.active{
        background: var(--primary-color);
        color: white;
        svg{
            &.lock{
                path{
                    fill: white;
                    stroke: initial;
                }
            }
        }
    }
}
.content--list--item{
    display: flex;
    position: relative;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    margin-left: 0;
    padding: 10px 20px;
    margin-bottom: 84px;
    z-index: 1;
    .item--move{
        display: flex;
        position: relative;
        margin: 0 16px 0 0;
        &.hidden{
            display: flex;
            opacity: 0;
            min-width: 10px;
            background: none;
        }
    }
    .item--name{
        display: flex;
        font-family: var(--main-site-font);
        padding: 0 16px;
        width: 100%;
    }
    .item--number{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 36px;
        height: 36px;
        min-width: 36px;
        background: #F4F4F4;
        border: none;
        color:#333333a6;
        &.hidden{
            background: none;
        }
    }
    .item--actions{
        display: flex;
        align-items: center;
        width: initial;
        svg{
            width: 32px;
            height: 32px;
            margin: 0 4px;
        }
    }
    &.no-description{
        margin-bottom: 12px;
    }
}
.content--list--item.content--list--add{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 44px;
}
.payment-settings{
    display: flex;
    background: white;
    border-radius: 15px;
    padding: 24px 24px;
    width: 100%;
    margin-bottom: 44px;
    .icon-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background: #F4F4F4;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        margin-bottom: 8px;
        cursor: pointer;
    }
    input{
        padding: 7px 10px;
        border: none;
        background: #F4F4F4;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        font-family: var(--main-site-font);
        &.price{
            max-width: 6rem;
        }
    }
    span{
        white-space: nowrap;
        margin-left: 8px;
        font-size: 16px;
        font-family: var(--main-site-font);
    }
    .input-wrapper{
        .row{
            margin-bottom: 18px;
            align-items: center;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    label{
        font-size: 16px;
        font-family: var(--main-site-font);
    }
}
.buy-btn-container{
    button{
        margin-bottom: 12px;
    }
    &.paid{
        button{
            justify-content: space-between;
            padding: 6px 8px 6px 14px;
            width: 200px;
            &:first-child{
                background: white;
            }
        }
    }
}
.w-full{
    width: 100%;
}
.label-container{
    margin-right: 7px;
    font-size: 16px;
    font-family: var(--main-site-font);
    .row{
        align-items: center;
        margin-bottom: 18px;
        white-space: nowrap;
        min-height: 38px;
        color: black;
        opacity: 0.7;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.left-col{
    border-right: 2px solid #E2E2E2;
    padding-right: 24px;
}
.right-col{
    padding-left: 24px;
}
.grid-equal{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
}
.upload-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 15px;
}
.align-center{
    align-items: center;
    font-size: 14px;
    font-family: var(--main-site-font);
}
.shown-lock-button{
    position: absolute;
    right: -44px;
    height: 32px;
    width: 32px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.locked{
        background: var(--secondary-color);
       
    }
}
.has-trailer{
    background: #EFEFEF;
    border-radius: 12px;
    padding: 4px;
    display: grid;
    justify-content: space-between;
    grid-template-areas: "trailer btn1"
    "trailer btn2";
    grid-template-columns: 2fr 1fr;
    grid-gap: 13px 0;
    margin-top: 12px;
    .trailer-window{
        grid-area: trailer;
        width: 288px;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
        .trailer{
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            video{
                height: 100%;
                position: absolute;
                top: 0;
            }
        }
    }
    .icon-container{
        margin: 0;
        background: white;
    }
    .col{
        height: 100%;
        &:nth-child(2){
            justify-content: flex-end;
            grid-area: btn1;
        }
        &:last-child{
            justify-content: flex-start;
            grid-area: btn2;
        }
    }
}
.title--editing { 
  font-size: 55px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
  font-family: var(--main-site-font);
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  background-color: transparent;
  color: var(--primary-color);
  height: initial;
}
.upload-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .progress-bar{
        position: relative; 
        border-radius: 20px;
        height: 30px;
        width: 70%;
        border:1px solid var(--primary-color);
        overflow: hidden;
        .progress{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0%;
            background: var(--secondary-color);
            border-radius: 0 20px 20px 0;
        }
    }
    .cancel{
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        border:1px solid var(--primary-color);
        margin-left: 10px;
    }
}
.item-container{
    position: relative;
}
.item-wrapper{
    display: flex;
    width: 100%;
    z-index: 1;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    margin-left: 0;
    padding: 10px 20px;
    margin-bottom: 84px;
}
.description-container{
   margin-top: -142px;
    height: 128px;
    padding: 68px 20px 12px 20px;
    width: 100%;
    background: #F4F4F4;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 30px 30px 12px 12px;
    z-index: 0;
    .description-content{
        width: 100%;
        height: 100%;
    }
    textarea{
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        font-size: 16px;
        font-family: var(--main-site-font);
        resize: none;
        color: #000000cf;
        overflow: auto;
        &:focus{
            outline: none;
        }
    }
}
.paid-training-preview-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    min-height: 100vh;
    background: #efefef;
    z-index: 99;
    overflow: auto;
}
.languages-container{
    position: relative;
    display: flex;
    width: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px 20px;
    margin-bottom: 44px;
    height: 120px;
    .row{
        display: flex;
        flex:1;
        align-items: center;
    }
    .close-btn{
        position: absolute;
        top: 7px;
        right: 10px;
        cursor: pointer;
    }
    .col{
        display: flex;
        flex:1;
        padding: 17px 0;
        p{
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            margin-right: 20px;
            opacity: 0.85;
            font-family: var(--main-site-font);
        }
        &:first-child{
            border-right: 2px solid #E2E2E2;
            padding-right: 27px;
        }
        &:last-child{
            padding-left: 27px;
        }
    }
}
.languages-enter-active, .languages-leave-active {
  transition: opacity .2s, max-height .3s;
  max-height: 120px;
}
.languages-enter, .languages-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    max-height: 0px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0
}

@media(max-width: 1280px){
    .content--list--item{
        .item--actions{
            svg{
                width: 24px;
                height: 24px;
            }
    }
    }
    .content--list--item{
        padding: 5px 10px;
        font-size: 16px;
    }
    .notes--icon{
        right: 150px;
    }
    .title--editing{
        width: 80%;
        font-size: 39px;
    }
    .row{
        &.actions{
            display: flex;
            flex-direction: column;
            h2{
                margin-left: 8px;
                margin-bottom: 8px;
            }
        }
    }
    .btn-container{
        button{
            font-size: 12px;
            min-width: 140px;
            padding: 2px 19px 2px 8px;
        }
    }
}
.scorm-task-settings{
    .media--action{
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        .description{
            display: flex;
            height: 40px;
            margin-top: 8px;
        }
    }
    img{
        width: 75px;
        height: 75px;
        max-height: 100%;
        max-width: 100%;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 10px;
        -webkit-transition: all .3s;
        transition: all .3s;
    }
}
</style>
<style lang="scss">
@import "@/assets/scss/views/_trainingEditView.scss";

.training--main{
    &.scorm{
        // @import "@/assets/scss/_trainingEdit.scss";
        h2{
            font-weight: 600;
            font-size: 24px;
            font-family: var(--main-site-font);  
        }
    }
}
.language-select{
    .select-input{
        background: #F4F4F4;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        padding: 7px 12px;
        border: none;
    }
    .select-container{
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
        border: none;
    }
    .dropdown-icon{
        background: none;
        padding: 0;
    }
}
</style>
