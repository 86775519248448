<template>
  <div class="main-input-container" v-click-outside-2="closeList" :class="{opened: selectOpen}" >
        <div class="select-input" >
    
            <div class="dropdown-btn"  >
               <span class="input-title" >
                    <input type="text" v-model="searchInput" :placeholder="getMainContainerLabel()" @focus="selectOpen=true">    
                </span>
                <div class="dropdown-icon" :class="{selected: selectOpen}" @click="selectOpen = !selectOpen">
                    <div v-if="dropdownIcon=='small'" class="small-icon">
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L7 7L13 1" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="21.564" height="11.881" viewBox="0 0 21.564 11.881">
                        <path id="Path_5930" data-name="Path 5930" d="M-3721.863-17217.617a1.1,1.1,0,0,1-.772-.324l-9.69-9.687a1.094,1.094,0,0,1,0-1.547,1.089,1.089,0,0,1,1.546,0l8.916,8.914,8.918-8.914a1.085,1.085,0,0,1,1.543,0,1.093,1.093,0,0,1,0,1.547l-9.688,9.688A1.1,1.1,0,0,1-3721.863-17217.617Z" transform="translate(3732.645 17229.498)" fill="#28345c"/>
                    </svg>
                </div>
            </div>
        </div>
        <div v-if="selectOpen" class="select-container">
            <span 
                v-for="(option, option_index) in filteredOptions" 
                :key="option_index" 
                :class="{selected: value && value.map(el=>el[idLabel]).includes(option[idLabel])}"
                @click="selectOption(option)"
                >
                {{ toLocal(option[label]) }}
            </span>
            <span v-if="filteredOptions.length == 0">
                {{toLocal('list.no_results')}}
            </span>
             <div 
                class="add-item"
                @click="$emit('addOption', searchInput); selectOption({[idLabel]: null, [label]:searchInput})"
                v-if="addNew && filteredOptions.length == 0 && searchInput.length > 0">

            <span class="icon-container">
                <span></span><span></span>
            </span>
             {{toLocal('hierarchy.add')}}
            </div>
        </div>
            
         
    </div>

</template>

<script>
export default {
    data(){
        return{
            selectOpen: false,
            filteredOptions: this.options,
            searchInput: ''
        }
    },
    props:{
        options:{
            type: Array,
        },
        value:{
            type: Array | String,
        },
        label:{
            type: String,
        },
        idLabel:{
            type: String,
            default: 'id'
        },
        placeholder:{
            type: String,
        },
        dropdownIcon:{
            type: String,
        },
        addNew:{
            type: Boolean,
            default: false
        }
    },
    watch:{
        searchInput(){
            this.filter();
        },
        options(){
            this.filteredOptions = this.options;
        }
    },
    methods:{
        closeList(){
            this.searchInput = '';
            this.selectOpen = false;
            
        },
        getMainContainerLabel(){
            return this.value ? this.value.length == 0 ? this.placeholder ? this.placeholder : '' : this.value.map(item => this.toLocal(item[this.label])).join(', ') : '';
        },
        selectOption(option){
            let temp = this.value;
            let index = -1;
            if(temp){
                index = temp.findIndex(el=>el[this.idLabel] == option[this.idLabel]);
                if(index == -1){
                    temp.push(option);
                }
                else{
                    temp.splice(index, 1);
                }
            }
            else{
                temp = [option]
            }
            this.$emit('input', temp)
        },
        filter(){
            this.filteredOptions = this._.cloneDeep(this.options);
            this.filteredOptions = this._.filter(this.filteredOptions, (el)=>{
                return el[this.label].toLowerCase().includes(this.searchInput.toLowerCase());
            });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/layout/_selectInputScoped.scss";
.small-icon{
    display: flex;
    svg{
        path{
            fill: none;
        }
    }
}
</style>