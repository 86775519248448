<template>
  <div class="change-password-overlay">
      <div class="change-password-modal">
          <h2>{{toLocal('settings.set_password')}}</h2>
          <div class="form">
              <div class="input-row">
                <label
                    for="password"
                    class="col-md-4 col-form-label text-md-right"
                >{{ toLocal("generic.password") }}</label>
                <div class="input-container">
                    <input
                        id="password"
                        ref="newPassword"
                        v-model="new_password"
                        :type="show_password ? 'text' : 'password'"
                        class="form-control"
                        name="password"
                        required
                        autocomplete="new-password"
                    >
                    <svg v-if="$refs['newPassword'] && $refs['newPassword'].value != ''" @mousedown="show_password = true" @mouseup="show_password = false" @mouseout="show_password = false" xmlns="http://www.w3.org/2000/svg" width="20.211" height="12.43" viewBox="0 0 20.211 12.43">
                    <g id="Group_231" data-name="Group 231" transform="translate(-0.054)">
                        <path id="Path_511" data-name="Path 511" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_512" data-name="Path 512" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                        <circle id="Ellipse_24" data-name="Ellipse 24" cx="3.709" cy="3.709" r="3.709" transform="translate(6.451 2.505)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                    </g>
                    </svg>
                </div>
            </div>
            <div class="input-row">
                <label
                for="conf_password"
                class="col-md-4 col-form-label text-md-right"
                >{{ toLocal("login.confirm_password") }}</label>
                <div class="input-container">
                    <input
                        id="conf_password"
                        ref="newPasswordConfirm"
                        v-model="new_password_confirmation"
                        :type="show_password2 ? 'text' : 'password'"
                        class="form-control"
                        name="password"
                        required
                        autocomplete="new-password"
                    >
                    <svg v-if="$refs['newPasswordConfirm'] && $refs['newPasswordConfirm'].value != ''" @mousedown="show_password2 = true" @mouseup="show_password2 = false" @mouseout="show_password2 = false" xmlns="http://www.w3.org/2000/svg" width="20.211" height="12.43" viewBox="0 0 20.211 12.43">
                    <g id="Group_231" data-name="Group 231" transform="translate(-0.054)">
                        <path id="Path_511" data-name="Path 511" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_512" data-name="Path 512" d="M19.669,6.216c-2.261,3.49-5.681,5.714-9.51,5.714S2.911,9.706.65,6.216C2.909,2.726,6.331.5,10.16.5S17.41,2.726,19.669,6.216Z" transform="translate(0)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                        <circle id="Ellipse_24" data-name="Ellipse 24" cx="3.709" cy="3.709" r="3.709" transform="translate(6.451 2.505)" fill="none" stroke="#595959" stroke-miterlimit="10" stroke-width="1"/>
                    </g>
                    </svg>
                </div>
            </div>
            {{errors}}
            <button @click="setPassword">
                {{toLocal('generic.save')}}
            </button>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'ChangePassword',
    data(){
        return{
            new_password: '',
            new_password_confirmation: '',
            errors: '',
            loading: false,
            success: false,
            show_password: false,
            show_password2: false,
        }
    },
    methods: {
        setPassword(){
            if(this.new_password == "" || this.new_password_confirmation == "")
                return;
            if(this.new_password != this.new_password_confirmation){
                this.errors = this.toLocal('settings.password_error_1');
                return;
            }
            if(this.new_password.length < 6){
                this.errors = this.toLocal('settings.password_error_3');
                return;
            }
            this.errors = this.toLocal('settings.password_changing');
            axios.post(`${this.$store.state.api_link}/user/set_password`, {
                email: this.$store.state.user_email,
                password: this.new_password,
                confirm_password: this.new_password_confirmation
            }, this.$store.getters.axiosConfig())
            .then((res) => {
                this.$store.commit('newUserStateChange', 0);
                this.errors = 'Slaptažodis sėkmingai pakeistas';
            })
            .catch((err) => {
                this.password_message = this.toLocal('settings.password_error_5');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/layout/_changePasswordScoped.scss';
</style>