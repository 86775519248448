<template>
  <div v-click-outside="clickOutsideEvent">
    <Modal ref="ytmodal" @addNewImage="addImage($event)" />
    <div v-if="editor && !readOnly" class="toolbar-container">
      <button class="editor--button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <font-awesome-icon icon="bold" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <font-awesome-icon icon="italic" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <font-awesome-icon icon="strikethrough" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        <font-awesome-icon icon="code" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
       <font-awesome-icon icon="highlighter" />
      </button>
      
      <button class="editor--button" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        h3
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <font-awesome-icon icon="list-ul" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <font-awesome-icon icon="list-ol" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        <font-awesome-icon icon="quote-left" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().insertContent('°').run()">
        °
      </button>
      <button class="editor--button" @click="editor.chain().focus().setHorizontalRule().run()">
       ―
      </button>
      <button class="editor--button" @click="editor.chain().focus().undo().run()">
        <font-awesome-icon icon="undo" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().redo().run()">
        <font-awesome-icon icon="redo" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleSubscript().run()" :class="{ 'is-active': editor.isActive('subscript') }">
        <font-awesome-icon icon="subscript" />
      </button>
      <button class="editor--button" @click="editor.chain().focus().toggleSuperscript().run()" :class="{ 'is-active': editor.isActive('superscript') }">
        <font-awesome-icon icon="superscript" />
      </button>
       <button v-if="imagesAllowed"  class="editor--button" @click="openModal();">
         <font-awesome-icon icon="image" />
        </button>
        <button v-if="signature && signature.show" class="editor--button signature-btn" :class="{ 'is-active': signature.enabled }" @click="$emit('enableSignature', signature.enabled != undefined ? !signature.enabled : true)">
          <font-awesome-icon icon="signature" />
        </button>

    </div>
    <div v-if="editor && showCharInfo" class="char-editor">
      <editor-content :class="{'heading-empty': isHeadingEmpty}" :editor="editor"  class="editor-content"/>
    </div>
    <editor-content v-else :class="{'heading-empty': isHeadingEmpty}" :editor="editor" class="editor-content" />
    <div class="signature" v-if="signature && signature.enabled" :class="{'not-editing': !signature.editing}">
      <div class="edit-icon-container" :class="{'save-icon': signature.editing}">
        <svg class="edit-icon" v-if="!signature.editing" @click="$emit('editSignature', true)" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
          <g id="Group_276" data-name="Group 276" transform="translate(0 -0.372)">
            <g id="Group_249" data-name="Group 249" transform="translate(3.057 3.056)">
              <rect id="Rectangle_146" data-name="Rectangle 146" width="53.075" height="16.532" rx="3.49" transform="translate(1.591 37.529) rotate(-45)" fill="#fff" stroke="#707070" stroke-miterlimit="10" stroke-width="2.637"/>
              <path id="Path_531" data-name="Path 531" d="M6747.266,1719.049l-13.788,3.094a.56.56,0,0,1-.669-.669l3.095-13.787a.559.559,0,0,1,.941-.273l10.694,10.694A.559.559,0,0,1,6747.266,1719.049Z" transform="translate(-6732.691 -1671.444)" fill="#fff" stroke="#707070" stroke-miterlimit="10" stroke-width="2.746"/>
              <line id="Line_26" data-name="Line 26" x2="11.513" y2="11.513" transform="translate(32.864 6.367)" fill="#fff" stroke="#707070" stroke-miterlimit="10" stroke-width="2.637"/>
              <path id="Path_532" data-name="Path 532" d="M6733.716,1718.164l-1.033,4.656,7.095-1.337-5.47-5.469Z" transform="translate(-6732.683 -1672.112)" fill="#707070"/>
              <line id="Line_27" data-name="Line 27" y1="20.724" x2="20.724" transform="translate(13.671 16.417)" fill="#fff" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.637"/>
            </g>
            <rect id="Rectangle_150" data-name="Rectangle 150" width="56" height="56" transform="translate(0 0.372)" fill="none"/>
          </g>
        </svg>

          <svg v-else @click="$emit('editSignature', false)" width="59" height="53" viewBox="0 0 59 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="8" height="59.0913" rx="4" transform="matrix(0.617188 0.786815 -0.617188 0.786815 53.436 0)" fill="#707070"/>
          <rect width="8" height="33.2347" rx="4" transform="matrix(0.617188 -0.786815 0.617188 0.786815 0.741486 25.7189)" fill="#707070"/>
          </svg>


      </div>
        <transition name="expand-editor" >
          <EditorComponent
              class="signature-editor"
              :isEditing="true"
              :readOnly="!signature.editing"
              :key="signature.editing"
              v-model="$store.state.emailForming.signature" 
            />
        </transition>
    </div>
     <!-- <portal to="signature-editor" v-if="signature.editing">
       <div class="modal-container">
         <div class="modal">
           <div class="close-btn" @click="$emit('signatureEditing', false)">
             <span></span><span></span>
           </div>
           <div class="modal-content">
             <EditorComponent
                :isEditing="true"
                :readOnly="false"
                v-model="signature.text" 
             />
           </div>
         </div>
       </div>
     </portal> -->
    <div  v-if="editor && showCharInfo && editor.storage.characterCount" class="char-count-container">
      <span>{{editor.storage.characterCount.words()}} {{getWordEnd(editor.storage.characterCount.words())}}</span>
      <span>{{editor.storage.characterCount.characters()}} / {{characterLimit}} {{toLocal('generic.symbols_multiple')}}</span>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import CharacterCount from '@tiptap/extension-character-count'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Modal from './Editor_extensions/Modal'
import Image from '@tiptap/extension-image'
import TipTapCustomImage from './Editor_extensions/TipTapImage.js';
// import EditorComponent from './Editor.vue'
// import {TextSelection} from "prosemirror-state";

export default {
  name:'Editor',
  components: {
    EditorContent,
    Modal,
    EditorComponent: () => import('./Editor.vue')
  },

    props: ["value", "readOnly", "isEditing", "item", "placeholder", "charLimit", "showCharInfo", "imagesAllowed", "signature"],
  data() {
    return {
      editor: null,
      emitAfterOnUpdate: false,
      characterLimit: 1000,
      mathfieldActive: false,
      lastNodeCursorPos: 0,
      editorIsEmpty: false,
      isHeadingEmpty: false,
      imageModalOpen: false,
    };
  },
  mounted() {
    if(this.charLimit){
      this.characterLimit = this.charLimit;
    }
    this.editor = new Editor({
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
      extensions: [
        StarterKit,
        Highlight,
     
        CharacterCount.configure({
          limit: this.characterLimit,
        }),
        Subscript,
        Superscript,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          placeholder: this.placeholder ? this.placeholder : '',
           showOnlyWhenEditable: false,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TipTapCustomImage.configure({
					inline: true
        }),
        Link.configure({
          protocols: [ 'mailto'],
        }),
        
        ],
        content: this.value,
        editable: this.readOnly ? false : true,
        item: this.item,
       
    });
   this.$on('deleteNode', function(e){
   });
    this.$on('mathfieldHasFocus', function(e, pos){
      setTimeout(()=>{

        if(e) this.mathfieldActive = true;
        else this.mathfieldActive = false;
        this.lastNodeCursorPos = pos;
      },100)
   });
    this.editorIsEmpty = this.editor.getHTML() === '<h2></h2>' || this.editor.getHTML() === '<h2><br></h2>' || this.editor.getHTML() === '';
    var regex_h2 = /<h2><\/h2>/g;
    var regex_h2_br = /<h2><\/br><\/h2>/g;
    var editor_html=this.editor.getHTML();
    if(editor_html.match(regex_h2) || editor_html.match(regex_h2_br)) this.isHeadingEmpty = true;
   
  },
  beforeUnmount() {
    if (this.editor) this.editor.destroy();
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value


      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
    charLimit(value) {
      this.characterLimit = value;
    },
  },
  methods:{
    clickOutsideEvent(e){
      this.$emit('clickedOutside')
    },
    addMathComponent(){
      if(!this.mathfieldActive){
        this.editor.chain().focus().insertContent('<math-component newElement="true" ></math-component').run()
      }
      else{
        this.editor.commands.focus(this.lastNodeCursorPos+1);
      }
    },
    getWordEnd(count){
      let number_array = count.toString().split('');
      let last = parseInt(number_array[number_array.length-1]);
      if(last === 1) return ' '+this.toLocal('generic.word');
      if(last === 0) return ' '+this.toLocal('generic.words');
      else if(last > 1 && last <= 9) return ' '+this.toLocal('generic.words_2');
 
    },
    openModal() {
      this.$refs.ytmodal.showModal();
      this.imageModalOpen = true;
      this.$emit('imageModalOpen', true);
    },
    addCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },
    addImage(url) {
      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
    setContent() {
      this.editor.setContent(this.content);
    }
  }

}
</script>
<style lang="scss" scoped>
.editor--button {
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  transform: scale(1);
  transition: .3s transform;
  // &.math{
  //   background: #28345C;
  // }

  &:hover{
    transform: scale(1.1);
  }
  &.is-active{
    background: var(--secondary-color);
  }
  
}
.signature-btn{
  position: relative;
  // .signature-tooltip{
  //     position: absolute;
  //     bottom: 30px;
  //     background: white;
  //     padding: 0.15rem 0.5rem;
  //     border-radius: 0.5rem;
  //     left: -57%;
  //     border: 1px solid var(--primary-color-opacity-50);
  //     opacity: 0;
  // }
  // &:hover{
  //   .signature-tooltip{
  //     opacity: 1;
  //   }
  // }
}
.toolbar-container{
    position: sticky;
    display: flex;
    align-items: center;
    top: 0;
    box-shadow: 0px 0px 9px -3px #a4a4a4;
    border-radius: 10px;
    // overflow: hidden;
    overflow: hidden;
    padding: 0.25rem 0;
    max-height: 1.75rem;
    z-index: 99;
    background: white;
}
.modal-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #0202025e;
  z-index: 99;
  .modal{
    position: relative;
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    .close-btn{
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      span{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3px;
        height: 80%;
        background: var(--primary-color);
        border-radius: 2rem;
        transform: rotate(45deg);
      }
      span:last-child{
        transform: rotate(-45deg);
      }
    }
  }
}
.edit-icon-container{
    position: absolute;
  left: -2px;
  // top: 20px;
  top: 5px;
  cursor: pointer;
  &.save-icon{
    top: 5px;
  }
  svg{
    height: 1rem;
    width: 1rem;
  }
  .edit-icon{
    transform: rotate(270deg);
  }
}
.signature{
  padding: 0 1rem;
  border-top: 1px solid var(--primary-color-opacity-50);
  color:var(--primary-color-opacity-50);
  //  animation: editor 0.3s ease;
    .signature-editor{
      overflow:hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 5px;

    }
   .toolbar-container{
     transform-origin: left;
     animation: toolBar 0.5s ease;
    //  transform: translateX(-100%);
   }
}
@keyframes editor{
  0%{
    transform: translateY(0);

    opacity: 0;
  }
  100%{
     transform: translateY(100%);
    opacity: 1;
  }
}
@keyframes toolBar{
  0%{
      transform: translateX(-100%);
      opacity: 0;
  }
  100%{
      transform: translateX(0%);
      opacity: 1;
  }
}
</style>
<style lang="scss">
.content--item--text{
  blockquote{
    position: relative;
    background: #efefef;
    padding: 0px 15px 0 35px;
    &::before{
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }
}
.heading-empty{
  h2{
    display: none;
  }
}
.ProseMirror{
  table{
    border-collapse: collapse;
    border-radius: 5px;
    td, th {
      border: 1px solid  var(--primary-color);
      padding: 8px;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color:  var(--secondary-color);
      color: white;
    }
  }
}
.test-task-editor{
  background: #F4F4F4;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 0px 6px -3px #a4a4a4;
  .toolbar-container{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 1rem;
    min-height: 2rem;
  }
  .char-editor{
    padding: 1rem;
    .editor-content{
      width: 100%;
      .ProseMirror{
      }
    }
    p{
      margin: 0
    }
  }
  .char-count-container{
    position: absolute;
    bottom: -1.6rem;
    right: 0;
    color: #bebebe;
    font-size: clamp(12px, 1vw, 16px);
    span{
      margin: 0 0.5rem;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.question-text{
  p{
    margin: 0;
  }
}
.expand-editor-enter-active, .expand-editor-leave-active {
  transition: all .5s;
   .toolbar-container{
    transition: all .5s;
   }
}
.expand-editor-enter{
    opacity: 0.5;
  .toolbar-container{
    height: 0%;
     margin: -1rem;
     transform: translateX(0%);
  }
} 
.expand-editor-leave-to{
  opacity: 0.5;

  .toolbar-container{
     margin: -1rem;
     transform: translateX(-110%);
  }
}
</style>
<style lang="scss">
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror{
  a{
    text-decoration: underline;
    color: #4c4cd0;
    cursor: pointer;
  }
}
.ProseMirror h2.is-empty {
  display: none;
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
  margin: 0;
}

.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}

.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}

.ProseMirror-hideselection * {
  // caret-color: transparent;
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}
</style>