<template>
    <div class="slider-container">
        <div class="slider-outer-on-top" :style="{left: 5.5+'%', width: Math.abs((sValue)/videoPlay.duration()*85) + '%'}"></div>
        <div class="slider-outer-on-top" :style="{right: 10+'%', width: Math.abs((invertEValue)/videoPlay.duration()*85) + '%'}"></div>
        <input class="s-slider" @input="(e)=>sliderCheck('start')" @change="submitSliderData('start')" :stop-propagation="true" v-model="sValue" type="range" step="1" min="0" :max="videoPlay.duration()"/>
        <input class="e-slider" @input="(e)=>sliderCheck('end')" @change="submitSliderData('end')" :stop-propagation="true" v-model="eValue" type="range" step="1"  min="0" :max="videoPlay.duration()"/>
        <div  class="slider-inner" :style="{left: calculateInnerSlider, width: calculateInnerSliderEndPoint}"></div>
    </div>
</template>

<script>
    export default {
        data(){
        return{
            sValue: 0,
            eValue: this.videoPlay.duration(),
            invertEValue: 0,

        }
        },
        props: ['startTime', 'endTime', 'videoPlay', 'updateVideoStartTime','updateVideoEndTime', 'countVideoTime', 'isAnyVideoTests'],
        watch:{
            updateVideoStartTime(){
                this.changeSliderData(this.startTime, 'start');
            },
            updateVideoEndTime(){
                this.changeSliderData(this.endTime, 'end');
            }
            // startTime:{
            //     deep: true,
            //     handler(newVal, oldVal){
            //         console.log(this.startTime);
            //         let totalTime = parseInt(this.startTime.h * 60 * 60) + parseInt(this.startTime.m) * 60 + parseInt(this.startTime.s)
            //         this.sValue = (totalTime / this.videoPlay.duration() * 100);
            //         this.countVideoTime(this.sValue, this.startTime);
            //     }
            // }
        },
        computed:{
            calculateInnerSlider(){
                let videoCurrent = this.videoPlay.currentTime() ? this.videoPlay.currentTime() : 0
                let startPoint = parseInt(videoCurrent) > parseInt(this.sValue) ? parseInt((videoCurrent)) : parseInt(this.sValue);
                let currentProgress = (startPoint/this.videoPlay.duration());
                return (5+(85 * currentProgress))+'%';
            },
            calculateInnerSliderEndPoint(){
                let videoCurrent = this.videoPlay.currentTime() ? this.videoPlay.currentTime() : 0
                let startPoint = parseInt((videoCurrent)) > parseInt(this.sValue) ? parseInt((videoCurrent)) : parseInt(this.sValue);
                let currentTimePassed = parseInt((videoCurrent)) < parseInt(this.eValue);
                let width = (this.eValue - startPoint)/this.videoPlay.duration()*85;
                return currentTimePassed ? width + '%' : 0  + '%'
            }
        },
        methods:{
            sliderCheck(side) {
                
                if(side == 'start'){
                    if(this.sValue  > parseInt(this.eValue)-5){
                        this.sValue = parseInt(this.eValue)-5
                    }

                    this.countVideoTime(this.sValue, this.startTime, 'start_time');
                }else{
                    if(this.eValue < parseInt(this.sValue)+5){
                        this.eValue = parseInt(this.sValue)+5
                    }else{
                        this.invertEValue = (this.videoPlay.duration()-this.eValue);
                    }
                    this.countVideoTime(this.eValue, this.endTime, 'end_time');
                }
            },
            submitSliderData(side){
                if(side == 'start'){
                    this.countVideoTime(this.sValue, this.startTime, 'start_time');
                }else{
                    this.countVideoTime(this.eValue, this.endTime, 'end_time');
                }
            },
            changeSliderData(dataObject, place) {
                let totalTime = parseInt(dataObject.h * 60 * 60) + parseInt(dataObject.m) * 60 + parseInt(dataObject.s)
                if (place == 'start') {
                    this.sValue = totalTime;
                    this.sliderCheck('start');
                }else{
                    this.eValue = totalTime;
                    this.invertEValue = (this.videoPlay.duration()-this.eValue);
                    this.sliderCheck('end');
                }
            },
        },
        mounted(){
            this.changeSliderData(this.startTime, 'start')
            this.changeSliderData(this.endTime, 'end');
        }
    }
</script>

<style lang="scss" scoped>
.slider-container{
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    height: 2em;
    background-color: rgba(43, 51, 63, 0.1);
    pointer-events: none;

    // .slider-outer{
    //     position: absolute;
    //     width: 80%;
    //     height: 5px;
    //     bottom: 12px;
    //     z-index: -1;
    //     pointer-events: none;
    //     background: #7D7D7D;
    //     border-radius: 5px;
    // }
    .slider-outer-on-top{
        position: absolute;
        width: 80%;
        background-color: white;
        height: 5px;
        bottom: 21px;
        z-index: 2;
        pointer-events: none;
        background: #7D7D7D;
        border-radius: 5px;
    }
    
    .slider-inner{
        position: absolute;
        width: 80%;
        background-color: white;
        height: 5px;
        bottom: 21px;
        z-index: 2;
        pointer-events: none;
    }
    .s-slider::-moz-range-thumb{
        // transform: rotate(180deg);
        background: url('../../../../assets/images/icons/training-edit/range_slider_image.svg');
        background-repeat: no-repeat;
        background-size: 12px 24px;
        z-index: 9999;
    }

    .s-slider::-webkit-slider-thumb{
        // transform: rotate(180deg);
        background: url('../../../../assets/images/icons/training-edit/range_slider_image.svg');
        background-repeat: no-repeat;
        background-size: 12px 24px;
        z-index: 9999;
    }

    
    input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 12px;
    height: 24px;
    cursor: pointer;
    z-index: 4;
    }
    input[type=range]::-moz-range-thumb {
        position: absolute;
    -webkit-appearance: none;
    pointer-events: all;
    width: 12px;
    height: 24px;
    cursor: pointer;  
    z-index: 4;
    border-radius: 0 4px 4px 0;
    border: none;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
    opacity: 1;
    z-index: 4;
    }
    input[type="range"] {
    -webkit-appearance: none; 
    appearance: none;
    height: 5px;
    width: 85%;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    bottom: 21px;
    z-index: 3;
    left: 5%;
    }
    input[type="range"]::-webkit-slider-runnable-track, input[type="range"]::-moz-range-track, input[type="range"]::-ms-track{
        display: none;
        -webkit-appearance: none;
    }
    .s-slider{
        height: 0;
        z-index: 3;
    }
    .e-slider{
        z-index: 2;
        &::-moz-range-thumb{
            transform: rotate(180deg);
            background: url('../../../../assets/images/icons/training-edit/range_slider_image.svg');
            background-repeat: no-repeat;
            background-size: 12px 24px;
            z-index: 9999;
        }
        &::-webkit-slider-thumb{
            transform: rotate(180deg);
            background: url('../../../../assets/images/icons/training-edit/range_slider_image.svg');
            background-repeat: no-repeat;
            background-size: 12px 24px;
            z-index: 9999;
        }
    }
}
</style>