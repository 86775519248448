<template>
    <div class="overlay-container" v-if="$store.state.itemPreview && $store.state.itemPreview.open" @click="closeModal">
    <!-- <div class="overlay-container" v-if="false" @click="closeModal"> -->
        <div 
            class="preview-modal"
           
        >
        <div class="bg"
            :key="image ? image.src : ''"
            :style="{
                 height: imageHeight ? imageHeight+'px' : height + 'px',
                width: imageWidth ? imageWidth + 'px' : width + 'px',
                left: left + 'px',
                top: top + 'px',
                'background-image': 'url(' + $store.state.itemPreview.src + ')',
                'clip-path': getClipPath($store.state.itemPreview.item)
            }"
        >
            <img style="display: none;" ref="image" :src="$store.state.itemPreview.src" alt="">
        </div>
            <!-- <NewImageEditor 
              :readOnly="true"
              :item="$store.state.itemPreview.item"
              :key="$store.state.itemPreview.item.content != null ? $store.state.itemPreview.item.content : ''"
              type="item"
              :isImageRounded="true"
              :isEditing="false"
              :half="$store.state.itemPreview.item.half"
              :editingState="false"
              ref="imageEditor"
              :src="$store.state.itemPreview.src"
              @getImageDimensions="setDimensions"
            /> -->
        </div>
    </div>
</template>

<script>
import NewImageEditor from '@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue';

export default {
    components: {
        NewImageEditor
    },
    data() {
        return {
            width: 0,
            height: 0,
            ratio: 1,
            left: 0,
            top: 0,
            imageHeight: 0,
            imageWidth: 0,
            image: null,
            maxHeightRatio: 0.88,
            maxWidthRatio: 0.88
        }
    },
    watch: {
        "$store.state.itemPreview.src"() {
            this.$nextTick(() => {
                this.image = null;
                this.image = this.$refs.image;
                this.height = 0;
                this.width = 0;
                this.left = 0;
                this.top = 0;
                this.imageHeight = 0;
                this.imageWidth = 0;
                
               
                
            })
        },
        'image.src'() {
            let vm = this;
            this.$nextTick(() => {
                if (this.image) {
                    this.image.onload = () => {
                        vm.setDimensions(this.image.naturalHeight, this.image.naturalWidth);
                    }
                }
            })
        }
    },
    methods: {
        closeModal() {
            this.$store.state.itemPreview = {
                open: false,
                item: null,
                src: null
            }
        },
        getClipPath(item) {
            let metadata = item.metadata;
            let clip_path = '';
            if(metadata) {
                let top = parseFloat(metadata.top);
                let left = parseFloat(metadata.left);
                let parentHeight = parseFloat(metadata.parentHeight);
                let height = parseFloat(metadata.height);
                let parentWidth = parseFloat(metadata.parentWidth);
                let width = parseFloat(metadata.width);
                let clip_top = top < 0 ? -top : 0;
                let clip_right = (width+left) > parentWidth && parentWidth >0 ? width+left - parentWidth : 0;
                let clip_bottom = height+top > parentHeight && parentHeight >0 ? height+top - parentHeight : 0;
                let clip_left = left < 0 ? -left : 0;
                this.ratio = this.width / width;
                clip_path = `inset(${clip_top*this.ratio}px ${clip_right*this.ratio}px ${clip_bottom*this.ratio}px ${clip_left*this.ratio}px)`;
                this.left = ((clip_right * this.ratio) - (clip_left*this.ratio))/2 ;
                this.top = ((clip_bottom * this.ratio)-(clip_top*this.ratio))/2 ;
            }  
            return clip_path;
        },
        setDimensions(height, width) {
            let ratio = height / width;
            let proportions_set = false;
            if(height > (window.innerHeight * this.maxHeightRatio)) {
                this.height = window.innerHeight * this.maxHeightRatio;
                this.width = this.height / ratio;
                proportions_set = true;
            }
            if(width > (window.innerWidth * this.maxWidthRatio)) {
                this.width = window.innerWidth * this.maxWidthRatio;
                this.height = this.width * (height / width);
                proportions_set = true;
            }
            if(!proportions_set) {
                this.width = width;
                this.height = height;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    backdrop-filter: blur(5px);
}
.bg{
    width: 100%;
    height: 100%;
    clip-path: inset(0px 0px 0px 0);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.preview-modal{
    position: relative;
    transform-origin: top left;
    border-radius: 8px;
}
</style>