var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle-btn",class:[
      { 'active': _vm.toggledState },
      _vm.theme
  ],style:({height: _vm.height+'px', width: _vm.width+'px'}),on:{"click":function($event){return _vm.$emit('changeState', !_vm.toggledState)}}},[_c('div',{staticClass:"inner--circle",style:({
        height: _vm.innerHeight+'px', 
        width: _vm.innerWidth+'px', 
        left: _vm.toggleDirection == 'left' ? 
                  _vm.toggledState || _vm.toggledState == 1 ? 
                      (_vm.height - _vm.innerHeight)/2 + 'px' 
                      : 'calc(100% - '+((_vm.height - _vm.innerHeight)/2+_vm.innerWidth) + 'px)' 
              : '',
          right: _vm.toggleDirection == 'right' ? 
                  _vm.toggledState || _vm.toggledState == 1 ? 
                      (_vm.height - _vm.innerHeight)/2 + 'px' 
                      : 'calc(100% - '+((_vm.height - _vm.innerHeight)/2+_vm.innerWidth) + 'px)' 
          : '',

      })})])}
var staticRenderFns = []

export { render, staticRenderFns }