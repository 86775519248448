<template>
  <div>
    <div 
      :class="['side--background', $store.getters.isCurrentSide('training-comments-side') ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('training-comments-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
       <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
        <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
          <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
            <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
              <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
            </g>
          </g>
        </g>
      </svg>

      </div>
      <div class="side--content">
        <div class="group--title">
          <span v-if="loading"><font-awesome-icon icon="spinner" /></span>
          <span v-if="!loading">{{ toLocal('training.comments') }} ({{ comments.length }})</span>
        </div>
        <div 
          v-for="(comment, commentIndex) in comments"
          :key="commentIndex"
          class="comment"
        >
          <div class="comment--info">
            {{ comment.user_name }}
            <span class="stars">
              <img 
                v-for="star in comment.rating" 
                :key="star"
                src="@/assets/images/icons/star-active.svg"
              >
              <img 
                v-for="star in 5 - comment.rating" 
                :key="star"
                src="@/assets/images/icons/star.svg"
              >
            </span>
            {{ comment.date }}
          </div>
          <div class="comment--avatar">
            {{ comment.user_name.toUpperCase().substring(0,1) }}
          </div>
          <div class="comment--box">
            {{ comment.comment }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TrainingCommentsSide",
  data: function(){
    return {
      "id": "-1",
      loading: false,
      loadInterval: null,
      comments: [],
    }
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      if(!this.$store.state.side_info['loaded'] && this.$store.state.side_open == "training-comments-side") {
        this.$store.state.side_info['loaded'] = true;
        this.loading = true;
        this.comments = [];
        this.id = this.$store.state.side_info['id'];
        axios.post( 
          `${this.$store.state.api_link}/training/stats/comments`,
          {training: this.$store.state.currentTraining, is_rented: this.$store.state.currentTrainingRented },
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.comments = response.data;
          this.loading = false;
        }).catch((error) => {
          console.log(error)
        });
      }
    }, 200);
  },
  beforeDestroy() {
    if(this.loadInterval != null){
      clearInterval(this.loadInterval);
      this.loadInterval = null;
      this.loading = true;
    }
  },
  methods: {
    closeSide() {
      this.$store.state.side_open = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";

    .side--background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 26;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .group--side {
        position: fixed;
        right: -850px;
        background-color: #fff;
        height: calc(100vh - 100px);
        top: 100px;
        width: 750px;
        z-index: 27;
        font-family: var(--main-site-font);
        transition: .3s right;

        &.side--open {
          right: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }

    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 47px;
            height: 47px;
            path{
              fill: var(--primary-color)
            }
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -2px;
      }
    }
    .group--title{ 
      font-size: 35px;
      padding-top: 15px;
      font-weight: 900;
      padding-left: 30px;

      span {
        font-size: 35px;
        display: block;
      }
    }

    .comment {
      padding-left: 50px;

      .comment--info {
        border-bottom: 1px solid #707070;
        font-size: 15px;
        padding-bottom: 5px;
        margin: 15px;

        .stars {
          margin: 0 25px;
        }

        img {
          height: 15px;
        }
      }

      .comment--avatar {
        position: absolute;
        margin-left: -70px;
        background-color: #EFEFEF;
        font-size: 30px;
        font-weight: 900;
        width: 58px;
        height: 58px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .comment--box {
        background-color: #EFEFEF;
        border-radius: 20px;
        padding: 20px 25px;
        text-align: justify;
      }
    }
</style>