<template>
    <div>
        <div class="check-answers-container"> 
            <div class="filter-row left">
                <div class="filter-col ">
                        <!-- <div class="filter-col width-75">
                            <h2>{{toLocal('tests.do_filter')}}</h2>
                            <div class="filter-container">
                                <div 
                                    class="filter"
                                    :class="{active: selectedFilter == filter_id}"
                                    v-for="(filter, filter_id) in filters"
                                    :key="filter_id"
                                    @click="selectedFilter = filter_id"
                                >
                                    {{filter.filter_title}}
                                </div>
                            </div>
                        </div> -->
                        <div class="filter-row">
                            <TestDropdown 
                                :title="filters[selectedFilter].all_rows_title"
                                :expandedTitle="filters[selectedFilter].all_rows_title_expanded"
                                :dropDownList="getListRows"
                                :test="test"
                                :selectedItemID=" $store.state.testCheckAnswersFilterItemID"
                                :selectedFilter="selectedFilter"
                                @selectedItem="$emit('selectedFilterItemID', $event); $store.state.testCheckAnswersFilterItemID = $event"
                            />
                        </div>
                </div>
            </div>
            <div class="filter-row right">
                <div class="filter-col width-75">
                    <div class="test-answer-showing-container">
                        <h3>{{toLocal('tests.show_stat_answers')}}</h3>
                        <div class="filter-col option-container">
                            <div
                                class="filter"
                                :class="{active: selectedShowTestAnswersOption == option_index}"
                                v-for="(option, option_index) in showTestAnswersOptions"
                                :key="option_index"
                                @click="selectedShowTestAnswersOption = option_index; $store.state.testCheckAnswersFilter = option_index"
                            >
                                {{option.title}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import TestDropdown from './TestDropdown.vue'
export default {
    name: 'TestCheckAnswersTab',
    components:{
        TestDropdown
    },
    data(){
        return{
            filters:[
                // {filter_title: this.toLocal('test_check.filter_group'), all_rows_title: this.toLocal('test_check.filter_group_all'), all_rows_title_expanded: this.toLocal('test_check.filter_group_pick'), filter_key: 'name'},
                {filter_title: this.toLocal('test_check.filter_users'), all_rows_title: this.toLocal('chat.select_all_users'), all_rows_title_expanded: this.toLocal('test_check.filter_users_pick'), filter_key: 'name'},
                // {filter_title: this.toLocal('test_check.filter_questions'), all_rows_title: this.toLocal('test_check.filter_questions_all'), all_rows_title_expanded: this.toLocal('test_check.filter_questions_pick'), filter_key: 'name'},
                // {filter_title: this.toLocal('test_check.filter_date'), all_rows_title: this.toLocal('test_check.filter_date_all'), all_rows_title_expanded: this.toLocal('test_check.filter_date_pick'), filter_key: 'name'},
            ],
            showTestAnswersOptions:[
                {'title':this.toLocal('test_check.show_all')},
                {'title':this.toLocal('test_check.show_incorrect')},
                {'title':this.toLocal('test_check.show_unrated')},
            ],  
            selectedFilter:0,
            selectedFilterItemID: 0,
            selectedShowTestAnswersOption:0,
            filterExpanded: false,
            users: [],
            dataArrays: [],
        }
    },
    props:{
        topic: Object,
        groups: Array,
        test: Object,
        availableTestQuestions: Array,
    },
    mounted(){
        this.dataArrays = [
            this.$store.state.testStats.groups,
            this.$store.state.testStats.users,
            this.$store.state.testStats.questions,
            this.$store.state.testStats.dates,
        ];
    },
    computed:{
        getListRows(){
            if(this.selectedFilter == 0) return this.$store.state.testStats.users;
            if(this.selectedFilter == 1) return this.$store.state.testStats.users;
            if(this.selectedFilter == 2) return this.$store.state.testStats.questions;
            if(this.selectedFilter == 3) return [...new Set(this.$store.state.testStats.dates.map(el=>el.created_at.split(' ')[0]))];
        }
    },
    watch:{
      
        topic:{
            immediate: true,
            handler(){
                // this.questions = this.availableTestQuestions[this.topic.questionsindex].questions;
                // this.questions.forEach(element => {
                //     element.users = this.users.filter(user=>user.id == element.user_id);
                // });
            }
        },
        selectedFilter(newValue){
            this.$emit('changedFilter', newValue);
        },
        selectedFilterItemID(newValue){
            this.$emit('changedFilterItemID', newValue);
        },
    },
    methods:{
        stripHTML(html){
            return html.replace(/<(?:.|\n)*?>/gm, '');
        },
    }
}
</script>

<style lang="scss" scoped>
    .check-answers-container{
        display: grid;
        grid-template-columns: 1.965fr 1fr;
    }
    .filter-row{
        display: flex;
        flex-direction: row;
        width: 100%;
        &.left{
            border-right: 2px solid var(--primary-color);
        }
    }
    .filter-col{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .width-75{
        width: 75%;
        margin: 0 auto;
    }
    .filter-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        margin: 1rem 0;
    }
    .filter{
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: clamp(14px, 1vw, 16px);
        text-align: center;
        border-radius: 20px;
        border: 1px solid var(--primary-color);
        padding:0 1rem;
        transition: background-color 0.3s;
        cursor:pointer;
        user-select: none;
        color: var(--primary-color);
        &.active{
            border: none;
            background-color: var(--secondary-color);
            font-weight: 700;
        }
    }
    .test-answer-showing-container{

    }
    .option-container{
        margin-top: 0.5rem;
        .filter{
            margin: 0.3rem 0;
        }
    }
    .filter-list-container{
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 700;
        justify-content: space-between;
        background: white;
        border-radius: 1rem;
        padding: 0;
        width: 100%;
        margin-right: 1rem;
        z-index: 1;
        user-select: none;
        cursor: pointer;
        .filter-list-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .dropdown-arrow-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #efefef;
            border-radius: 50%;
            padding:0.25rem;
            height: 100%;
            width: 2rem;
            height: 2rem;
        }
        .dropdown-container{
            display: flex;
            position: absolute;
            left: 0%;
            top:0;
            width: 100%;
            background: white;
            border-radius: 1rem;
            z-index: -1;
            padding: 1rem;
            padding-top: 3rem;
            .dropdown-list{
                width: 100%;
            }
            .row{
                display: flex;
                border-bottom: 1px solid var(--primary-color);
                padding: 0 0.5rem;
                width: 100%;
                margin-bottom: 0.5rem;
                font-weight: 500;
                &.active{
                    font-weight: 700;
                }
            }
        }
    }
    h2{
        margin: 0;
        font-weight: 700;
        color: var(--primary-color);
        font-size: clamp(16px, 1.5vw, 26px);
    }
    h3{
        margin: 0;
        font-weight: 700;
        color: var(--primary-color);
        font-size: clamp(16px, 1.2vw, 17px);
    }
    @media(max-width:1280px){
        .filter-list-container{
            .filter-list-wrapper{
                span{
                color: white;
            }
            .dropdown-arrow-container{
                width: 0.25rem;
                height: 0.25rem;
            }
            }
        }
    }
</style>