<template>
    <div class="training-shop-container" :class="{'training-opened': viewingTraining}">
        <div v-if="!viewingTraining" class="background-image" :style="{ 'background-image': `url(${getFileUrl(training.cover)})` }">
            <div class="background-cover" >
            </div>
        </div>
        <div v-if="!viewingTraining" class="back-button-container" :class="{'buying-page': isBuying}">
            <div class="back-btn" @click="$emit('goBack')">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M9 1L1 9L9 17" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{toLocal('generic.back')}}
            </div>
        </div>
        <div class="training-card" v-if="!isBuying && !isRenting && !viewingTraining && !isLoading">
            <div class="main-section">
                <div class="row">
                    <div class="header">
                        <h3 class="title" >
                            {{training.title}}
                        </h3>
                        <div class="rating-container">
                            <div class="star-avg-container">
                                <div class="avg">
                                    {{rating == null ? 0 : rating}}
                                </div>
                                <div
                                    :class="[
                                        'end--rating--star',
                                        'active',
                                    ]"
                                    :style="{
                                        'background-image': 'url(' +require('@/assets/images/icons/star-active.svg')+')'
                                    }"
                                >
                                </div>
                            </div>
                            <label v-if="training_obj.purchased || training_obj.rented"> Įvertinkite: </label>
                            <div class="star-container" v-if="training_obj.purchased || training_obj.rented">
                                <div
                                    v-for="star in 5"
                                    :key="star"
                                    :class="[
                                        'end--rating--star',
                                        hoveredStar >= star || starRating >= star ? 'active' : '',
                                    ]"
                                    :style="{
                                        'background-image':
                                        hoveredStar >= star || starRating >= star
                                            ? 'url(' +
                                            require('@/assets/images/icons/star-active.svg') +
                                            ')'
                                            : 'url(' + require('@/assets/images/icons/star-outline.svg') + ')',
                                    }"
                                    @mouseover="hoverStar(star)"
                                    @mouseout="unhoverStar()"
                                    @click="rate(star)"
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="tags">
                        <div class="length">
                            {{ toLocal('payments.training_length_label') }} <strong>{{getTrainingTime(training.time)}}</strong>
                        </div>
                        <div class="language"  v-if="training.language && training.language.length > 0">
                            {{toLocal('training_edit.shop_language')}} <strong>{{Array.isArray(training.language) ? training.language.map(el=>toLocal(el.label)).join(', ') : toLocal(training.language)}}</strong>
                        </div>
                        <div class="subtitles" v-if="training.subtitles && training.subtitles.length > 0">
                            {{toLocal('training_edit.shop_subtitles')}} <strong>{{(Array.isArray(training.subtitles) ? training.subtitles.map(el=>toLocal(el.label)).join(', ') : toLocal(training.subtitles)) }}</strong>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="description" v-html="training.description">
                    </div>
                </div>
                <div class="row">
                    <div class="video-container">
                        <video  controls>
                            <source :src="getFileUrl(training.trailer)" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div class="row" :class="{disabled: training_obj.rented && $store.state.user_role == 'user' || training_obj.purchased}">
                    <div class="buy-btn-container" :class="{'user-view': $store.state.user_role == 'user'}">
                        <div class="col free" v-if="training.price == 0 && training.price_rent == 0">
                            <div class="row">
                                <button v-tooltip="{
                                    content: training_obj.purchased ?
                                     toLocal('payments.purchased_tooltip') : 
                                     '', classes:['warning-tooltip'], offset:'16px'}" 
                                     @click="getFreeTraining"
                                >
                                     {{toLocal('payments.free_btn_label')}}
                                </button>
                            </div>
                            <div class="row">
                                <p  v-html="toLocal('payments.free_description')">  </p>
                            </div>
                        </div>
                        <div class="col" :class="{'inline': !training.price_rent || training.price_rent == 0 }" v-else-if="$store.state.user_role == 'admin'">
                            <div class="row">
                                <button  v-tooltip="{content: training_obj.purchased ? toLocal('payments.training_purchased_tooltip') : '', classes:['warning-tooltip'], offset:'16px'}" @click="training_obj.purchased ? '' : isBuying = true">{{toLocal('payments.buy_btn_label')}} {{training.price}} € </button>
                            </div>
                            <div class="row">
                                <p v-html="toLocal('payment.buy_description')"> </p>
                            </div>
                        </div>
                        <div class="col" :class="{'inline': !training.price || training.price == 0}" v-if="training.price_rent > 0">
                            <div class="row">
                                <button v-tooltip="{
                                    content: training_obj.rented && $store.state.user_role == 'admin' 
                                        ? '' 
                                        : training_obj.rented && $store.state.user_role == 'user'
                                        ? toLocal('payments.training_rented_tooltip')
                                        : training_obj.purchased  && $store.state.user_role == 'admin'
                                        ?  toLocal('payments.cant_rent_purchased_tooltip')
                                        : '', 
                                        classes:['warning-tooltip'], 
                                        offset:'16px'
                                    }" 
                                    @click="training_obj.purchased ? '' : isRenting = true"
                                >

                                    {{toLocal('payments.rent_btn_label')}} {{training.price_rent}} € 
                                </button>
                            </div>
                            <div class="row">
                                <p v-html="toLocal('payments.rent_description')">  </p>
                                <span v-if="training_obj.rented && $store.state.user_role =='admin'" class="license_count">
                                    {{ toLocal('training_shop.licences_left') }} {{ training_obj.license_count }}
                                </span>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="row">
                    <div 
                        class="view-topics" 
                        :class="{disabled: training.selectedShownTopics ? training.selectedShownTopics.length == 0 : true}"
                        @click="training.selectedShownTopics && training.selectedShownTopics.length > 0 ? viewTopics(training.selectedShownTopics[0]) : false"
                    >
                        <button>{{toLocal('payments.training_preview_label')}}</button>
                    </div>
                </div>
                <div class="row">
                    <div class="content">
                        <div 
                            class="content-item" 
                            v-for="(topic, index) in training.content" 
                            :key="index"
                            @click="training.selectedShownTopics ? training.selectedShownTopics.includes(topic.id) ? viewTopics(index) : false : false"
                        >
                            <div class="row">
                                <div class="icon">
                                    <svg v-if="training.selectedShownTopics ? !training.selectedShownTopics.includes(topic.id) : true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4745 13.6053C11.4745 9.34211 11.4745 6.5 15.9998 6.5C20.525 6.5 20.525 9.34211 20.525 13.6053M23.1109 14.6053V23.2632C23.1109 23.8155 22.6632 24.2632 22.1109 24.2632H9.88867C9.33639 24.2632 8.88867 23.8155 8.88867 23.2632V14.6053C8.88867 14.053 9.33639 13.6053 9.88867 13.6053H22.1109C22.6632 13.6053 23.1109 14.053 23.1109 14.6053Z" stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.9138 19.2613C16.6709 19.2613 17.2846 18.6436 17.2846 17.8816C17.2846 17.1196 16.6709 16.502 15.9138 16.502C15.1567 16.502 14.543 17.1196 14.543 17.8816C14.543 18.6436 15.1567 19.2613 15.9138 19.2613Z" fill="#7D7D7D"/>
                                        <rect x="15.3984" y="18.6719" width="1.02811" height="3.2794" rx="0.514056" fill="#7D7D7D"/>
                                    </svg>
                                    <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.6663 15.4417C24.1262 19.2392 20.2894 21.6588 15.9997 21.6588C11.7099 21.6588 7.86423 19.2392 5.33301 15.4417C7.86423 11.6443 11.7099 9.22461 15.9997 9.22461C20.2894 9.22461 24.1351 11.6443 26.6663 15.4417Z" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
                                        <path d="M15.9997 18.1063C17.4724 18.1063 18.6663 16.9134 18.6663 15.4418C18.6663 13.9703 17.4724 12.7773 15.9997 12.7773C14.5269 12.7773 13.333 13.9703 13.333 15.4418C13.333 16.9134 14.5269 18.1063 15.9997 18.1063Z" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
                                    </svg>

                                </div>
                                <div class="topic-title">
                                    {{index+1}}. {{ topic.title }}
                                </div>
                            </div>
                            <div class="row description">
                                {{ topic.description != null ? topic.description : '' }}
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button @click="isBuying = true">Buy buy buy</button> -->
        </div>
        <div class="buy-page" v-else-if="!viewingTraining && !isLoading">
            <ShopPaymentPage :training="training" :purchase_type="isRenting ? 'rent' : 'buy'" />
        </div>
        <Training 
            v-if="viewingTraining" 
            :training_prop="trainingCopy" 
            :current_topic_prop="currentTopic" 
            @goBack="viewingTraining=false"
        />
        <GlobalModal
            v-if="freeTrainingModalOpen"
            :modalInfo="multiChoiceInfo"
            @onChoiceOne="freeTrainingModalOpen=false;$emit('goBack')"
            @onChoiceTwo="checkoutTraining(training_obj)"
            @onClose="freeTrainingModalOpen=false; training_obj.purchased=true"

        />
        <GlobalModal
            v-if="boughtTrainingModalOpen"
            :modalInfo="multiChoiceBoughtInfo"
            @onClose="closeBuyModal"
        />
    </div>
</template>

<script>
import ShopPaymentPage from './ShopPaymentPage.vue'
import Training from '@/views/Training.vue'
import axios from 'axios'
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";

export default {
    components:{
        ShopPaymentPage,
        Training,
        GlobalModal
    },
    props:{
        training_id:{
            default: null
        },
        training_obj:{
            default: null
        },
        isOpenedExternally: Boolean,
    },
    data(){
        return{
            training:{},
            trainingCopy: {},
            isBuying: false,
            isRenting: false,
            isLoading: false,
            hoveredStar:0,
            starRating: 0,
            rating: 0,
            viewingTraining: false,
            freeTrainingModalOpen: false,
            boughtTrainingModalOpen: false,
            currentTopic: -1,
            multiChoiceInfo: {
                type: "multiChoice",
                text: "Puiku!",
                undertext: "Mokymas jau pridėtas prie Jūsų įsigytų mokymų",
                multiChoiceYes: "Tęsti apsipirkimą",
                multiChoiceNo: "Peržiūrėti mokymą",
            },
            multiChoiceBoughtInfo: {
                type: "info",
                text: "Sveikiname įsigijus mokymą!",
                undertext: "Naujus mokymus visuomet rasite skiltyje „Įsigyti mokymai“",
                confirmBtnText: "Supratau",
            },
        }
    },
    mounted(){
        this.$store.state.isShopTraining = true;
        if(this.$route.query.order){
            this.boughtTrainingModalOpen = true;
            this.training_id = this.$route.query.training_id;
            if(this.$route.query.status != undefined && this.$route.query.status == 0){
                this.multiChoiceBoughtInfo= {
                    type: "info",
                    text: "Deja, mokėjimas nepavyko!",
                    undertext: "Grįžkite ir pamėginkite apmokėti iš naujo",
                    confirmBtnText: "Bandyti dar kartą",
                };
            }
            else if(this.$route.query.status != undefined && this.$route.query.status == 1){
                this.multiChoiceBoughtInfo= {
                    type: "info",
                    text: "Sveikiname įsigijus mokymą!",
                    undertext: "Naujus mokymus visuomet rasite skiltyje „Įsigyti mokymai“",
                    confirmBtnText: "Supratau",
                };
            }
        }
        this.getTraining(1);

    },
    beforeDestroy(){
        this.$store.state.isShopTraining = false;
    },
    methods:{
        getTraining(min, index = -1){
            this.isLoading = true;
            axios
            .get(
                `${this.$store.state.api_link}/training/info/${this.training_id}?paid=1&min=${min}`,
                this.$store.getters.axiosConfig()
            )
            .then((response) => {
                this.trainingCopy = JSON.parse(response.data.data);
                this.rating = response.data.rating;
                if(response.data.user_rating!=null){
                    this.starRating = response.data.user_rating;
                }
                if(this.training && this.training.content){
                    let new_id = this.training.content[index].id
                    let new_index = this.trainingCopy.content.findIndex(el=>el.id == new_id);
                    if(new_index != -1)
                        this.currentTopic = new_index;
                }
                else{
                    this.currentTopic = index;
                }
                this.$set(this.trainingCopy, 'id', this.training_id);
                if(min == 0){
                    this.viewingTraining=true;
                    this.$store.state.isShopTraining = true;
                }
                if(min == 1)
                    this.training = this._.cloneDeep(this.trainingCopy)
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
                console.log(error);
            });
        },
        getFreeTraining(){
            if(this.training_obj.purchased) return;
            axios
            .get(
                `${this.$store.state.api_link}/training/buy/free/${this.training_id}`,
                this.$store.getters.axiosConfig()
            )
            .then((response) => {
               this.freeTrainingModalOpen = true;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        hoverStar(star) {
            this.hoveredStar = star;
        },
        unhoverStar() {
            this.hoveredStar = 0;
        },
        viewTopics(index = -1){
            this.getTraining(0, index);
            
        },
        getTrainingTime(time){
            let minutes = time;
            let hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            return  hours > 0 ? hours + ' val. '+minutes+' min.' : minutes+' min.'
        },
        closeBuyModal(){
            this.boughtTrainingModalOpen=false;
            this.$router.replace({query : null})
            this.$router.go('/training-shop')
            if(this.$store.state.user_id == null){
                this.$router.push("/login");
                this.$store.state.loading.loadingDone();
            }
        },
        rate(rating){
            this.starRating = rating;
            axios
            .post(
                `${this.$store.state.api_link}/training/rate?paid=true`,
                {
                    training_id: this.training_id,
                    rating: rating
                },
                this.$store.getters.axiosConfig()
            )
            .then((response) => {
                this.rating = response.data.new_rating
            })
            .catch((error) => {
                console.log(error);
            });
        },
        checkoutTraining(training){
            this.freeTrainingModalOpen=false;
            this.$router.push('/training?id='+training.id); 
            this.$set(training, 'purchased', true);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_training.scss';
.training-shop-container{
    position: relative;
    padding: 48px 0;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    &.training-opened{
        padding: 0;
    }
}
.background-image{
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    height: 50%;
    background-position: center;
    background-size: cover;
    filter: blur(8px);
    z-index: -1;
    .background-cover{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background: transparent linear-gradient(180deg, #EFEFEF88 0%, #EFEFEF88 54%, #EFEFEFF4 96%, #EFEFEF 100%) 0% 0% no-repeat padding-box;
    }
}
.training-card{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    padding: 44px;
    height: 100%;
    max-width: 1330px;
    margin: auto;
    margin-bottom: 100px;
}
button{
    padding: 8px 20px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    background: var(--secondary-color);
    color: var(--primary-color);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    font-family: var(--main-site-font);
    border-radius: 30px;
    border: none;
    cursor: pointer
}
.main-section{

}
.back-btn{
    display:flex;
    align-items:center;
    background: rgb(255, 255, 255);
    opacity: 0.5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    padding: 4px 16px;
    margin-bottom: 48px;
    width: 100px;
    height: 40px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    svg{
        margin-right: 10px;
    }
    &:hover, &:active{
        opacity: 1;
    }
   
}
.row{
    display:flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}
.video-container{
    display: flex;
    width: 100%;
    margin-top: 60px;
    video{
        width: 100%;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
    }
}
.col{
    display: flex;
    flex-direction: column;
}
.header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    color:var(--primary-color);
    h3{
        margin-right: 40px;
    }

}
.tags{
    display: flex;
    align-items: center;
    color: #7D7D7D;
    margin: 32px 0;
    font-size: 16px;
    .length{
        padding-right: 20px;
    }
    .language{
        padding-left: 20px;
        padding-right: 20px;
        border-left: 1px solid #7D7D7D;
    }
    .subtitles{
        padding-left: 20px;
        border-left: 1px solid #7D7D7D;

    }
}
.buy-btn-container{
    display: flex;
    margin: 60px 0;
    justify-content: space-between;
    background:#F4F4F4;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 52px;
    width: 100%;
    .col{
        flex: 1;
        align-items: center;
        justify-content: flex-start;
        &:first-child:not(:only-child){
            border-right: 2px solid #E2E2E2;
            p{
                max-width: 350px;
            }
        }
        &.free{
            flex-direction: row;
            border-right: none;
            .row{
                margin: 0;
                width: initial;
                &:last-child{
                    width: 100%;
                    p{
                        width: 100%;
                        max-width: 100%;
                        text-align: left;
                    }
                }
            }
            button{
                margin-right: 35px;
            }
            .col{
                flex-direction: row;
                border-right: none;
            }
        }
       &.inline{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border: none;
        .row{
            justify-content: flex-start;
            width: initial;
            margin: 0;
            &:first-child{
                margin-right: 20px;
            }
            &:last-child{
                width: 100%;
            }
            p{
                text-align: left;
                width: 100%;
                min-width: 100%;
            }
        }
       }
    }
    .row{
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        &:first-child{
            margin-bottom: 23px;
        }
        &:last-child{
            color: #333333;
        }
    }
    p{
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        max-width: 408px;
        margin: 0;
        opacity: 0.5;
    }
    button{
        width: 260px;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        transition: background 0.3s, color 0.3s;
        &:hover{
            background: var(--primary-color);
            color: white;
        }
        &:active{
            font-weight: 700;
        }
    }
    &.user-view{
        padding: 38px 40px;
        .row{
            margin: 0;
            width: initial;
            &:last-child{
                width: 100%;
                p{
                    width: 100%;
                    max-width: 100%;
                    text-align: left;
                }
            }
        }
        button{
            margin-right: 35px;
        }
        .col{
            flex-direction: row;
            border-right: none;
        }
    }
}
.view-topics{
    display: flex;
    width: 100%;
    margin-bottom: 48px;
    button{
        padding: 28px 0;
        width: 100%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        font-size: 24px;
        transition: background 0.3s, color 0.3s;
        &:hover{
            background: var(--primary-color);
            color: white;
        }
        &:active{
            font-weight: 700;
        }
    }
    &.disabled{
        button{
            cursor: not-allowed;
            opacity: 0.5;
             &:hover, &:active{
                font-weight: initial;
                background: var(--secondary-color);
            }
        }
        
    }
}
.disabled{
    button{
        opacity: 0.5;
        cursor: not-allowed;
        &:hover{
            background: var(--secondary-color);
            color: #333333;
        }
        &:active{
            font-weight: initial;
        }
    }
}
.content{
    max-width: 100%;
    .row{
        align-items: center;
    }
    .icon{
        margin-right: 8px;
        align-items: center;
        display: flex;
    }
    .description{
        padding-left: 3rem;
        color: #333333;
        opacity: 0.5;
        font-size: 14px;
    }
    .topic-title{
        font-size: 16px;
      
    }
}
.end--rating--star{
    height: 28px;
    width: 28px;
    background-size: contain;
}
.back-button-container{
    display: flex;
    width: 100%;
    max-width: 1330px;
    margin: auto;
    &.buying-page{
        .back-btn{
            margin-bottom: 27px;
        }
    }
}
.rating-container{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .star-avg-container{
        display: flex;
        align-items: center;
        background: #F4F4F4;
        border-radius: 30px;
        padding: 4px 12px;
        .avg{
            font-size: 16px;
            margin-right: 8px;
            font-weight: 600;
        }
    }
    label{
        margin:0 8px;
        color: #7D7D7D;
    }
}
.license_count{
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #333333;
    font-family: var(--main-site-font);
}
.content-item{
    cursor: pointer;
}
.star-container{
    display: flex;
    align-items: center;
    white-space: nowrap;
}

@media (max-width: 768px){
    .training-shop-container{
        padding: 20px;
    }
    .training-card{
        padding: 20px;
    }
    .header{
        flex-direction: column;
        .title{
            margin-right: 0;

        }

    }
    .rating-container{
        margin-top: 20px;
        label{
            font-size: 14px;
        }
    }
    .end--rating--star{
        width: 24px;
        height: 24px;
    }
    .tags{
        margin: 20px 0;
        align-items: flex-start;
        flex-direction: column;
        .length{
            padding-right: 0;
            border-right: none;
        }
        .language{
            padding-left: 0;
        }
    }
    .video-container{
        margin-top: 30px;
    }
    .back-btn{
        margin-bottom: 20px;
    }
    .buy-btn-container{
        margin: 30px 0;
        padding: 20px;
        flex-direction: column;
        .col{
            &:first-child{
                border-right: none;
                border-bottom: 2px solid #E2E2E2;
                margin-bottom: 20px;
                padding-bottom: 20px;
            }
        }
        &.user-view{
            padding: 20px;
            .col{
                flex-direction: column;
                margin: 0;
                padding: 0;
                &:first-child{
                    border: none;
                }
            }
            button{
                margin: 0px 0 20px 0;
            }
        }
    }
    .content{
        max-width: 100%;
    }
    .license_count{
        bottom: -18px;
    }

}
</style>
<style lang="scss">
.warning-tooltip{
    display: flex;
    margin: auto;
    align-items: center;
    padding-bottom: 20px;
    justify-content: center;
    border-radius: 10px;
    height: 80px;
    width: 205px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #D45454;
    font-family: var(--main-site-font);
	background-image: url("data:image/svg+xml,%3Csvg width='207' height='82' viewBox='0 0 207 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M92.8565 64.4092C93.4742 64.4092 94.0492 64.7459 94.3367 65.278L102.462 80.4054C102.92 81.1982 104.07 81.1982 104.538 80.4054L112.663 65.278C112.951 64.735 113.526 64.4092 114.143 64.4092L117.008 64.3006H198.28C202.539 64.3006 206 60.9342 206 56.7858V8.51485C206 4.36648 202.539 1 198.28 1H8.72038C4.46086 1 1 4.36648 1 8.51485V56.7858C1 60.9342 4.46086 64.3006 8.72038 64.3006H91.2486L92.8565 64.4092Z' fill='white' stroke='%23D45454' stroke-width='1.5'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    .popover-arrow {
    }
    // .tooltip-arrow {
    //     position: absolute;
    //     border-color: red;
    //     border-right: 1.5px solid #D45454;
    //     border-bottom: 1.5px solid #D45454;
    //     bottom: -16px;
    //     right: 0;
    //     left: 0;
    //     margin: auto;
    //     top: initial;
    //     transform: rotate(45deg);
	//     background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0.5L19.4999 0.499981L9.5 18L-5.14664e-05 0.499981L9 0.5Z' fill='white'/%3E%3C/svg%3E");
    //     z-index: 1;
    // }
}
</style>