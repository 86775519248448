<template>
  <div class="filter-container">
    <div class="filter-content">
        <div class="close-btn" @click="$emit('closeModal')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.00269L22.9942 22.9873" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
            <path d="M23 1L1.00583 22.9919" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
            </svg>

        </div>
        <h3>{{toLocal('filter_modal.filter')}}</h3>
        <div class="filter-section">
            <h3>{{toLocal('filter_modal.show_users')}}</h3>
            <div class="user-states">
                <div 
                    class="state"
                    :class="{selected: userFilters.includes(filter.value)}"
                    v-for="(filter, index) in state_filter" 
                    :key="index"
                    @click="selectUserFilter(filter)"
                >
                    {{filter.name}}
                </div>
            </div>
        </div>
        <div class="filter-section filter-groups">
            <h3>{{toLocal('filter_modal.show_users')}}</h3>
            <div class="groups">
                <div class="group-wrapper">
                    <div class="search-container">
                        <div class="search-input">
                            <input type="text" v-model="groupSearchInput" :placeholder="toLocal('filter_modal.find_group')">
                            <svg v-if="groupSearchInput == ''" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.92282 14.85C11.7462 14.85 14.8456 11.7496 14.8456 7.925C14.8456 4.10043 11.7462 1 7.92282 1C4.09945 1 1 4.10043 1 7.925C1 11.7496 4.09945 14.85 7.92282 14.85Z" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.4197 17.6173L12.8604 12.8643" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else @click="groupSearchInput = ''" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.00208L17.9955 17.9964" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
                                <path d="M18 1L1.0045 18" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                    <div 
                        v-for="(group, index) in filteredGroups" 
                        :key="index"
                        :class="[`bc-${group.color_id}`, groupFilters.includes(group.id) ? `gc-${group.color_id}` : 'default']"
                        class="group"
                        v-tooltip="{content: currentlyHoveringGroup == group.id ? group.name : '', classes: 'tooltip-container',}"
                        @mouseover="checkIfTextOverflows($event, group.id, 'hovering')"
                        @mouseleave="checkIfTextOverflows($event, group.id, 'leaving')"
                        @click="selectGroupFilter(group)" 
                    >
                        {{group.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="action-container">
            <button class="btn btn-secondary" @click="clearFilters">{{toLocal('filter_modal.clear_filters')}}</button>
            <button class="btn btn-primary" @click="filter">{{toLocal('generic.save')}}</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        groups:{
            type: Array,
            default: () => []
        },
        selectedGroupFilters:{
            type: Array,
            default: () => []
        },
        selectedUserFilters:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            state_filter:[
                {
                    name: this.toLocal('chat.select_all_users'),
                    value: 'all'
                },
                {
                    name: this.toLocal('filter_modal.active_users'),
                    value: 'active'
                },
                {
                    name: this.toLocal('filter_modal.disabled_users'),
                    value: 'disabled'
                },
                {
                    name: this.toLocal('filter_modal.not_logged'),
                    value: 'notlogged'
                }
            ],
            groupSearchInput: '',
            filteredGroups: [],
            userFilters: this.selectedUserFilters,
            groupFilters: this.selectedGroupFilters,
            currentlyHoveringGroup: -1
            
        }
    },
    watch:{
        groups:{
            immediate:true,
            handler(){
                this.filteredGroups=this._.cloneDeep(this.groups)
            }
        },
        groupSearchInput(){
            this.filterGroups(this.groupSearchInput);
        },
        groupFilters(){
            this.$emit('selectedGroupFilters', this.groupFilters)
        },
        userFilters(){
            this.$emit('selectedUserFilters', this.userFilters)
        }
    },
    methods:{
        filterGroups(input){
            this.filteredGroups=this._.cloneDeep(this.groups)
            this.filteredGroups = this.filteredGroups.filter((group) => {
                return group.name.toLowerCase().includes(input.toLowerCase());
            });
        },
        selectGroupFilter(group){
            let index = this.groupFilters.findIndex((item) => item == group.id);
            if(index == -1)
                this.groupFilters.push(group.id);
            else this.groupFilters.splice(index,1)
        },
        selectUserFilter(filter){
            let index = this.userFilters.findIndex((item) => item == filter.value);
            if(index == -1){
                this.userFilters.push(filter.value);
                if(filter.value != 'all' && this.userFilters.includes('all')){
                    let all_index = this.userFilters.findIndex((item) => item == 'all');
                    this.userFilters.splice(all_index,1)
                }
                else if(filter.value == 'all' && this.userFilters.length > 0){
                    this.userFilters = ['all']
                }

            }
            else if(filter.value != 'all'){
                this.userFilters.splice(index,1)
                if(this.userFilters.length == 0)
                    this.userFilters.push('all')
            }
        },
        clearFilters(){
            this.userFilters = ['all'];
            this.groupFilters = [];

        },
        checkIfTextOverflows(e,id, state){
            if(state == 'hovering'){
                if(e.target.offsetWidth < e.target.scrollWidth){
                    this.currentlyHoveringGroup = id;
                }
                else{
                    this.currentlyHoveringGroup = -1;
                }
            }
            else{
                this.currentlyHoveringGroup = -1;
            }

        },
        filter(){
           
            this.$emit('filter', this.groupFilters, this.userFilters)
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.filter-content{
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 70%;
    width: 100%;
    max-height: 70vh;
    height: 100%;
    background: #EFEFEF;
    border-radius: 20px;
    padding: 27px 42px;
}
.filter-section{
    position: relative;
    margin-top: 2rem;
}
.action-container{
    display: flex;
    position: absolute;
    right: 27px;
    bottom: 27px;
}
.search-container{
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    max-width: 18%;
    width: 100%;
    background: #EFEFEF;
    max-height: 36px;
    &::before{
        content: '';
        display: flex;
        max-width: calc(100% + 12px);
        height: calc(100% + 12px);
        width: calc(100% + 12px);
        background: #EFEFEF;
        position: absolute;
        right: -12px;
        bottom: -12px;
        z-index: -1;
    }
}
.search-input{
    display: flex;
    width: 100%;
    align-items: center;
    background: white;
    border-radius: 20px;
    border: 1px solid #4D4D4D;
    padding-right: 5px;
    max-height: 36px;
    height: 36px;
    input{
        border: none;
        background: none;
        font-size: 16px;
        padding: 5px 35px 5px 15px;
        width: 100%;
        &:focus{
            outline: none;
        }
    }
    svg{
        max-width: 19px;
        max-height: 19px;
        pat{
            width: 100%;
        }
        cursor: pointer;
    }
}
.groups{
    top: 24px;
    position: absolute;
    overflow-y: auto;
    height: calc(100% - 5rem);
    margin-top: 24px;
    width: 100%;
}
.group-wrapper{
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: 100%;
    gap: 24px;
}
.user-states{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;

}
.user-states{
    .state{
        display: flex;
        justify-content: center;
        width: 12rem;
        font-size: 16px;
        padding: 5px 0;
        background: white;
        border-radius: 20px;
        color: #4D4D4D;
        border: 1px solid #4D4D4D;
        cursor: pointer;
        user-select: none;
        &.selected{
            background: #4D4D4D;
            color: white;
        }
    }
}
.group{
    display: block;
    min-width: 18%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 18%;
    width: 10px;
    text-align: center;
    border-radius: 20px;
    padding: 5px 10px;
    max-height: 36px;
    height: 36px;
    color: #4D4D4D;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &.selected{
   
    }
    &.default{
        background-color: white;
    }
}
.btn{
    display: flex;
    &.btn-primary{
        justify-content: center;
        background: white;
        color: #4D4D4D;
        font-size: 18px;
        border-radius: 35px;
        border: 1px solid #4D4D4D;
        width: 205px;
        transition: all 0.3s;
        &:hover{
            background: #4D4D4D;
            color: white;
        }
    }
    &.btn-secondary{
        background: none;
        font-size: 16px;
        text-decoration: underline;
        padding: 5px 0;
        margin-right: 35px;
    }
}
.filter-groups{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.close-btn{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
h3{
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}
.btn-secondary{
    box-shadow: none;
}
</style>