<template>
  <div class="moment-container">
       <div class="row date-row">
            <!-- <span class="row-label">
                {{toLocal('report.report_desc')}}
            </span> -->
            <div class="date">
                <div class="label">
                    {{toLocal('report.momentary_from')}}
                </div>
                <div class="date-picker">
                    <date-picker
                        v-model="$store.state.reportForming.momentaryReport.date_from" 
                        class="momentary-date send-start-date"
                        label="date"
                        format = "YYYY-MM-DD"
                        placeholder="2022-01-01"
                        :disabledDate="disabledDatesFrom"
                        valueType="format"
                    >
                        <template v-slot:icon-calendar>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.716396 15.985L4.43836 15.1511H4.46955C4.64427 15.1509 4.81724 15.1162 4.97853 15.0491C5.13982 14.9819 5.28626 14.8836 5.40946 14.7598L15.6033 4.57007C15.8552 4.31716 15.9966 3.97483 15.9966 3.61797C15.9966 3.26111 15.8552 2.91878 15.6033 2.66587L13.3357 0.39312C13.0827 0.141349 12.7402 0 12.3832 0C12.0262 0 11.6837 0.141349 11.4307 0.39312L1.23081 10.5889C1.10587 10.7141 1.00775 10.8635 0.942445 11.0279C0.877135 11.1923 0.846 11.3683 0.850937 11.5451L0.0129728 15.2823C-0.00415802 15.3662 -0.00415802 15.4526 0.0129728 15.5364C0.029398 15.6126 0.0608234 15.6847 0.105422 15.7487C0.15002 15.8126 0.206899 15.867 0.272741 15.9087C0.367633 15.9687 0.477468 16.001 0.589771 16.0018C0.633048 16.0026 0.67625 15.9978 0.71826 15.9873M12.0215 0.98411C12.1174 0.88863 12.2473 0.835027 12.3827 0.835027C12.5181 0.835027 12.648 0.88863 12.744 0.98411L15.0121 3.2564C15.0602 3.3031 15.0984 3.35903 15.1244 3.42084C15.1504 3.48264 15.1637 3.54906 15.1634 3.61611C15.1633 3.68351 15.1499 3.75024 15.1239 3.81244C15.0979 3.87465 15.0599 3.93112 15.0121 3.97861L14.0293 4.96096L11.0359 1.96878L12.0215 0.98411ZM2.09578 10.9058L10.4447 2.55838L13.4381 5.55055L5.08918 13.8979L2.09578 10.9058ZM1.28668 13.4354L1.68099 11.6736L4.32895 14.3205L2.43516 14.743L1.28668 13.4354Z" fill="#7D7D7D"/>
                                <path d="M4.57455 11.8387C4.51977 11.839 4.46549 11.8283 4.41494 11.8072C4.3644 11.7861 4.31862 11.755 4.28033 11.7158C4.20215 11.6374 4.15826 11.5311 4.15826 11.4204C4.15826 11.3096 4.20215 11.2033 4.28033 11.1249L10.6358 4.77241C10.6744 4.7331 10.7204 4.70187 10.7711 4.68055C10.8219 4.65923 10.8764 4.64825 10.9314 4.64825C10.9865 4.64825 11.041 4.65923 11.0918 4.68055C11.1425 4.70187 11.1885 4.7331 11.2271 4.77241C11.3052 4.8509 11.3491 4.95715 11.3491 5.06791C11.3491 5.17867 11.3052 5.28492 11.2271 5.3634L4.87156 11.7158C4.83272 11.755 4.78644 11.7861 4.73544 11.8072C4.68444 11.8283 4.62974 11.839 4.57455 11.8387Z" fill="#7D7D7D"/>
                            </svg>
                        </template>
                    </date-picker>
                </div>
            </div>
            <div class="date date-to">
                <div class="label">
                    {{toLocal('generic.to')}}
                </div>
                <div class="date-picker">
                    <date-picker
                        v-model="$store.state.reportForming.momentaryReport.date_to" 
                        class="momentary-date send-start-date"
                        label="date"
                        format = "YYYY-MM-DD"
                        placeholder="2022-01-01"
                        :disabledDate="disabledDatesTo"
                        valueType="format"
                    >
                        <template v-slot:icon-calendar>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.716396 15.985L4.43836 15.1511H4.46955C4.64427 15.1509 4.81724 15.1162 4.97853 15.0491C5.13982 14.9819 5.28626 14.8836 5.40946 14.7598L15.6033 4.57007C15.8552 4.31716 15.9966 3.97483 15.9966 3.61797C15.9966 3.26111 15.8552 2.91878 15.6033 2.66587L13.3357 0.39312C13.0827 0.141349 12.7402 0 12.3832 0C12.0262 0 11.6837 0.141349 11.4307 0.39312L1.23081 10.5889C1.10587 10.7141 1.00775 10.8635 0.942445 11.0279C0.877135 11.1923 0.846 11.3683 0.850937 11.5451L0.0129728 15.2823C-0.00415802 15.3662 -0.00415802 15.4526 0.0129728 15.5364C0.029398 15.6126 0.0608234 15.6847 0.105422 15.7487C0.15002 15.8126 0.206899 15.867 0.272741 15.9087C0.367633 15.9687 0.477468 16.001 0.589771 16.0018C0.633048 16.0026 0.67625 15.9978 0.71826 15.9873M12.0215 0.98411C12.1174 0.88863 12.2473 0.835027 12.3827 0.835027C12.5181 0.835027 12.648 0.88863 12.744 0.98411L15.0121 3.2564C15.0602 3.3031 15.0984 3.35903 15.1244 3.42084C15.1504 3.48264 15.1637 3.54906 15.1634 3.61611C15.1633 3.68351 15.1499 3.75024 15.1239 3.81244C15.0979 3.87465 15.0599 3.93112 15.0121 3.97861L14.0293 4.96096L11.0359 1.96878L12.0215 0.98411ZM2.09578 10.9058L10.4447 2.55838L13.4381 5.55055L5.08918 13.8979L2.09578 10.9058ZM1.28668 13.4354L1.68099 11.6736L4.32895 14.3205L2.43516 14.743L1.28668 13.4354Z" fill="#7D7D7D"/>
                                <path d="M4.57455 11.8387C4.51977 11.839 4.46549 11.8283 4.41494 11.8072C4.3644 11.7861 4.31862 11.755 4.28033 11.7158C4.20215 11.6374 4.15826 11.5311 4.15826 11.4204C4.15826 11.3096 4.20215 11.2033 4.28033 11.1249L10.6358 4.77241C10.6744 4.7331 10.7204 4.70187 10.7711 4.68055C10.8219 4.65923 10.8764 4.64825 10.9314 4.64825C10.9865 4.64825 11.041 4.65923 11.0918 4.68055C11.1425 4.70187 11.1885 4.7331 11.2271 4.77241C11.3052 4.8509 11.3491 4.95715 11.3491 5.06791C11.3491 5.17867 11.3052 5.28492 11.2271 5.3634L4.87156 11.7158C4.83272 11.755 4.78644 11.7861 4.73544 11.8072C4.68444 11.8283 4.62974 11.839 4.57455 11.8387Z" fill="#7D7D7D"/>
                            </svg>
                        </template>
                    </date-picker>
                </div>
            </div>
        </div>
        <div class="col">
            <span class="row-label">{{toLocal('generic.data_filter')}}</span>
            <!-- <ConditionFilter
                class="condition-report-container"
                :groupUserFilter="[]"
                :initial_cols="cols"
                filterStyle="report"
                :selectedFilter="[]"
            /> -->
            <ReportFilter 
                :selectedFilter="selectedFilter"
                @saveFilter="saveFilter"
            />
        </div>
         <div class="column-filter">
            <ShownColumnsRow
                :key="initialLoaded"
                ref="shownCols"
                :selectedShownColumns="selectedShownColumns"
                @selectShownColumns="selectedShownColumns=$event"
            />
        </div>
        <!-- <ReportDataTable :reportDataArray="$store.state.reportForming.momentaryReport.reportList"/> -->
        <FormReportButtons formType="momentary" :report="$store.state.reportForming.momentaryReport" :activeForm="$store.state.reportForming.momentaryReport.formReportButtonActive" :activeFormed="$store.state.reportForming.momentaryReport.formedReportActive" />
  </div>
</template>

<script>
import FormReportButtons from "./FormReportButtons.vue";
import ShownColumnsRow from "./ShownColumnsRow.vue";
import ReportDataTable from "./ReportDataTable.vue";
import ConditionFilter from "@/components/layout/groups/ConditionFilter.vue";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

import DatePicker from 'vue2-datepicker';
import moment from "moment";
import ReportFilter from "./ReportFilter.vue";

export default {
    components: {
        FormReportButtons,
        ReportDataTable,
        DatePicker,
        ConditionFilter,
        CustomDropdown,
        ReportFilter,
        ShownColumnsRow
    },

    data(){
        return{
        
            selectedShownColumns: [],
            selectedFilter: [],
            initialLoaded:false,
        }
    },
    watch:{
        '$store.state.reportForming.momentaryReport':{
            deep: true,
            handler(newVal){
                if(newVal.date_from != '' && newVal.reportList.length > 0){
                    let can_be_active = true;
                    newVal.reportList.forEach(row => {
                        if(row.cols.length < 3){
                            can_be_active = false;
                        }
                    });
                    this.$store.state.reportForming.momentaryReport.formReportButtonActive = can_be_active;
                }
                else{
                    this.$store.state.reportForming.momentaryReport.formReportButtonActive = false;
                }
            }
        },
        selectedShownColumns(){
            this.$store.state.reportForming.momentaryReport.shownColumns = this.selectedShownColumns;
        }
    },
    mounted() {
        if (this.$store.state.reportForming.momentaryReport.date_from == '')
            this.$store.state.reportForming.momentaryReport.date_from = moment().subtract(7,'d').format('YYYY-MM-DD');
        if(this.$store.state.reportForming.momentaryReport.date_to == '')
            this.$store.state.reportForming.momentaryReport.date_to = moment().format('YYYY-MM-DD');
        this.$nextTick(() => {
            let ref = this.$refs.shownCols;
            if (ref)
                this.selectedShownColumns = ref.shownColumns.map(el => el.value);
            this.initialLoaded = true;
        })
    },
    methods:{
        disabledDatesTo(time) {
            let date = moment(String(time)).format('YYYY-MM-DD');
            var currentDate =moment().format('YYYY-MM-DD');
            let d1 = moment(String(this.$store.state.reportForming.momentaryReport.date_from)).format('YYYY-MM-DD');
            if(!this.$store.state.reportForming.momentaryReport.date_from) return false;
            // return date > currentDate || date < d1 ;
            return date < d1 ;

        },
         async getItemData(item) {
            if (item.api_route) {
               try {
                    let res = await this.getDataListRequest(item.api_route);
                   let temp = item.value == 'groups' ? res.data : res.data.data;
                   if (['users', 'groups', 'trainings', 'training_ended', 'tests', 'certificates'].includes(item.value)) {
                       temp = temp.map(element => {
                           if (item.value == 'users')
                               return { id: element.id, label: (element.name ? element.name : '') + ' ' + (element.last_name ? element.last_name : ''), value: element.id };
                            else if (['groups','tests'].includes(item.value))
                                return { id: element.id, label: element.name ? element.name : '', value: element.id };
                            else if (['trainings', 'training_ended','certificates'].includes(item.value))
                                return { id: element.id, label: element.title ? element.title : '', value: element.id };
                       });
                       temp = temp.filter(element => {
                            return element.label ? element.label.length > 0 : false
                       });
                    }
                    return temp;
                } catch (err) {
                    // Handle error if necessary
                    console.error(err);
                    return [];
                }
            }
            else {
                if (item.value == 'user_activity') {
                    return this.user_activity;
                }
                if (item.value == 'test_status') {
                    return this.test_status
                }
                 if (item.value == 'training_rating') {
                    return this.training_rating
                }
             
                return [];
            }
        },
        getDataListRequest(link){
            return axios.get(`${this.$store.state.api_link}/${link}`,
                this.$store.getters.axiosConfig()
            )
        },
        disabledDatesFrom(time) {
            let date = moment(String(time)).format('YYYY-MM-DD');
            let d1 = moment(String(this.$store.state.reportForming.momentaryReport.date_to)).format('YYYY-MM-DD');
            if(!this.$store.state.reportForming.momentaryReport.date_to) return false;
            return date > d1;
        },
        saveFilter(filter) {
            this.selectedFilter = filter;
            this.$set(this.$store.state.reportForming.momentaryReport,'reportList', filter);
        },
    },
    
}
</script>

<style lang="scss" scoped>
.moment-container{
    display: flex;
     flex-direction: column;
    padding: 1rem;
    font-size: clamp(14px, 1vw, 18px);
    color: #333;
}
.date-to{
    margin-left: 28px;
    
}
.column-filter{
    .row{
        gap: 12px;
        border: none;
    }
}
.row{
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: 28px 0;
    color: #333;
    border-bottom: 1px solid #FFF;
    .row-label{
        min-width: 30%;
    }
}
.row-label{
    font-weight: 600;
}
.date-row{
    margin-top: 6px;
    padding: 28px 0;
    font-weight: 600;
    margin-bottom: 28px;
}
.date{
    display: flex;
    align-items: center;
    gap:12px;
}
.date-picker{
    display: flex;
    background: white;
    border-radius: 2rem;
    width: 7rem;
    border: 1px solid var(--primary-color);
}
.date-picker{
    display: flex;
    background: white;
    border-radius: 6px;
    border: 1px solid #B2B2B2;
    // border: 1px solid var(--primary-color);
}
.col{
    border-bottom: 1px solid #fff;
    padding-bottom: 28px;
}
.select-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 1rem;
    min-width: 6rem;
    margin: 0 1rem;
    z-index: 3;
    cursor: pointer;
    .dropdown-icon{
        display: flex;
    }
    .label{
        font-size: clamp(14px, 1vw, 18px);
        color: #333;
        width: 100%;
        text-align: center;
    }
    .select-button{
        display: flex;
        background: white;
        align-items: center;
        padding: 0.15rem 0.15rem;
        border-radius: 1rem;
        border: 1px solid var(--primary-color);
        justify-content: space-between;
        width: 100%;
        
        z-index: 2;
    }
}
 .report-dropdown{
    background-color: white;
    border-radius: 6px;
    border: 1px solid #B2B2B2;
    background: #FFF;
    .white{
        path{
            stroke: var(--primary-color);
        }
    }
    &:not(.no-padding-no-bg){
        background-color: white;
    }
}
</style>
<style lang="scss">
.momentary-date{
    width: 100%;
    .mx-input{
        box-shadow: none;
    }
}
</style>