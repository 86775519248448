<template>
   <div class="filter-list-container" v-if="test && test.options" v-click-outside-3="closeDropdown">
        <div class="filter-list-wrapper"  @click="dropdownExpanded = !dropdownExpanded">
            <span>{{getItemTitle()}}</span>
            <div class="dropdown-arrow-container" :class="{active: dropdownExpanded}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.212" height="12.107" viewBox="0 0 22.212 12.107">
                    <path id="Path_5930" data-name="Path 5930" d="M-3731.645-17228.5l9.692,9.691,3.23-3.23,6.461-6.461" transform="translate(3733.059 17229.914)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </svg>
            </div>
        </div>
        <div class="dropdown-container" v-if="dropdownExpanded" >
            <div class="dropdown-list" ref="dropdown" :class="{'overflow': dropdownOverflown}">
                <!-- <div 
                class="row"
                :class="{active: selectedItem == 0, 'version': type == 'version', 'active-version': test.options.active_version_id == 0}"
                @click="selectedItem = 0; $emit('selectedItem', 0)"
                >
                    <div v-if="type == 'version'" class="version-row">
                        <div></div>
                        <div class="name">{{test.name}}</div>
                        <div class="date">{{test.created_at.split('T')[0]}}</div>
                        <div class="questions">{{test.questions.length+' klaus.'}}</div>
                    </div>
                    <div v-else>
                        {{title}}
                    </div>
                </div> -->
                <div 
                v-for="(row, row_index) in type=='version' ? flattenedList : dropDownList"
                :key="row_index"
                class="row"
                :id="`version-row-${row_index}${selectedItem == row.id ? '-selected' : ''}`"
                :class="{active: selectedItem == row.id , 'version': type == 'version', 'active-version': parentTest && parentTest.options.active_version_id == row.id && topic && topic.questions == parentTest.id , 'root': row.version_parent == 0}"

                >
                    <div v-if="type == 'version'" class="version-row">
                        <div class="version-num" @click="setFilter(row)">{{row.version_slug}}</div>
                        <div class="name" @click="setFilter(row)">{{getItemName(row)}}</div>
                        <div class="date" @click="setFilter(row)">{{row.created_at.split('T')[0]}}</div>
                        <div class="questions" @click="deleteItem(row)">{{row.type == 1 ? row.options && row.options.tests && row.options.tests.map(el=>parseInt(el.count)).length > 0 ? row.options.tests.map(el=>parseInt(el.count)).reduce((a,b)=>a+b)+' klaus.' : row.questions.length+' klaus.' : row.questions.length+' klaus.'}}</div>
                      
                    </div>
                    <div v-else @click="setFilter(row)">
                        {{getItemName(row)}}
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    name: 'TestDropdown',
    props:{
        title:{
            type: String,
            default: '',
        },
        expandedTitle: {
            type: String,
            default: '',
        },
        test:{
            type: Object,
            required: true,
        },
        parentTest:{
            type: Object,
            required: false,
        },
        topic:{
            type: Object,
            required: false,
        },
        dropDownList:{
            type: Array,
            default: () => [],
        },
        selectedItemID: {
            type: Number,
            default: 0,
        },
        selectedFilter:{
            type: Number,
            default: 0,
        },
        type:{
            type: String,
            default: '',
        },
    },
    data(){
        return{
            dropdownExpanded: false,
            dropdownOverflown: false,
            selectedItem: this._.cloneDeep(this.selectedItemID),
        }
    },
   updated() {
        this.$nextTick(function () {
           if(this.isOverflown(this.$refs.dropdown)){
               this.dropdownOverflown = true;
           }
        })
    },
    watch:{
        selectedItemID:{
            handler(){
                this.selectedItem = this._.cloneDeep(this.selectedItemID);
            },
        },
    },
    computed:{
        flattenedList(){
            let temp_list = this._.cloneDeep(this.dropDownList);
            if(temp_list.length > 0){
                let new_list = [];
                temp_list = temp_list.sort((a,b) => {
                    if(a.id > b.id){
                        return -1;
                    }
                    if(a.id < b.id){
                        return 1;
                    }
                    return 0;
                });
                new_list =  this.flattenList(temp_list, '', 0);
                temp_list = [...new Set(new_list)];
                this.$emit('flattenedList', temp_list);
                return temp_list;
            }
            else return [];
        }
    },
    methods:{
        stripHTML(html){
            return html.replace(/<(?:.|\n)*?>/gm, '');
        },
        getItemName(row){
            if(this.type == 'version'){
                return row.name
            }else{
                return this.selectedFilter == 2 ? this.stripHTML(row.question.text) : this.selectedFilter == 3 ? row : row.name
            }
        },
        getItemTitle(){
            if(this.type == 'version'){
                if(this.dropdownExpanded){
                    return this.expandedTitle;
                }
                else if(this.selectedItem == 0){
                    let version = this._.cloneDeep(this.flattenedList.find(el=>el.id == this.test.options.active_version_id));
                    if(version){
                        return version.version_slug+ ' ' + version.name; 
                    }
                    else return '';
                }
                else{
                    let version = this._.cloneDeep(this.flattenedList.find(el=>el.id == this.selectedItem));
                    if(version){
                        return version.version_slug+ ' ' + version.name; 
                    }
                    else return '';
                }
            }
            else{
                return this.expandedTitle;
            }
        },
        setFilter(row){
            if(this.selectedFilter == 3 && this.type != 'version'){
                this.selectedItem = row
                this.$emit('selectedItem', row);
                this.dropdownExpanded = false;
            }
            else{
                this.selectedItem = row.id; 
                this.$emit('selectedItem', row.id);
                this.dropdownExpanded = false;

            }
        },
        flattenList(list, slug, level){
            let new_list = [];
            let previous_slug = slug;
            if(list.length > 0){
                list.forEach((element, key)=>{
                    if(element != undefined){
                        if(level == 0)
                            slug = 'v'+(list.length-key);
                        else{
                            slug += '.'+(list.length-key);
                        }
                        if(element.version_parent !== 0 && element.versions.length > 0){
                            new_list.push(...this.flattenList(element.versions, slug, level+1));
                        }
                        element.version_slug = slug;
                        new_list.push(element);               
                        slug = previous_slug;
                    }
                });
            }
            return new_list;
        },
        // getVersionNumber(row){
        //     let list_copy = this.flattenedList;
        //     let index = list_copy.findIndex(el=>el.id == row.id && row.version_parent == this.test.id);
        //     if(index == -1){
        //         let list_index = list_copy.findIndex(el=>el.id == row.version_parent);
        //         if(list_index !== -1){
        //             let version_index = list_copy[list_index].versions.findIndex(el=>el.id == row.id);
        //             return list_copy.length-list_index+'.'+list_copy[list_index].versions.length-version_index;
        //         }
        //         else return '0';

        //     }
        //     else return list_copy.length-index;
        // },
        isOverflown(element) {
            if(element)
                return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
            else return false;
        },
        closeDropdown(e){
            this.dropdownExpanded = false
        },
        deleteItem(row){
            if( row.version_parent != 0){
                this.$emit('deleteVersion', row.id);
                if(this.selectedItem == row.id){
                    let item = this.flattenedList.sort((a,b)=>{
                        let date_a = new Date(a.created_at);
                        let date_b = new Date(b.created_at);
                        return date_a <= date_b ? 1 : -1;
                    });
                    if(row.id == item[0].id){
                        if(item[1])
                            item = item[1]; 
                        else item = item[0];
                    }
                    else{
                        item = item[0];
                    }
                    this.selectedItem = item.id;
                    this.$emit('selectedItem', item.id);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .filter-list-container{
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 700;
        justify-content: space-between;
        background: white;
        border-radius: 1rem;
        width: 100%;
        margin-right: 1rem;
        padding: 0;

        z-index: 1;
        user-select: none;
        cursor: pointer;
        .filter-list-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0.25rem 0.25rem 0.25rem 1rem;

            color: var(--primary-color);
            span{
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-width: 88%;
                width: 5px;
            }
        }
        .dropdown-arrow-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #efefef;
            border-radius: 50%;
            padding:0.25rem;
            height: 100%;
            width: 2rem;
            height: 2rem;
            transition: transform 0.2s ease-in-out;
            svg{
                path{
                    stroke: var(--primary-color);
                }
            }
            &.active{
                transform: rotate(-180deg);
            }
        }
        .dropdown-container{
            display: flex;
            position: absolute;
            left: 0%;
            top:0;
            width: 100%;
            background: white;
            border-radius: 1rem;
            z-index: -1;
            padding: 1rem;
            padding-top: 3rem;
            .dropdown-list{
                position: relative;
                width: 100%;
                max-height: 38vh;
                overflow: auto;
                &.overflow{
                    &::after{
                        content: '';
                        display: flex;
                        position: sticky;
                        bottom: 0;
                        left: 0;
                        width: 100%;                    
                        height: 2rem;
                        background: linear-gradient(180deg, rgba(244,244,244,0) 0%, rgba(255,255,255,1) 53%);
                    }   
                }
            }
            .row{
                display: flex;
                border-bottom: 1px solid var(--primary-color);
                padding: 0 0.5rem;
                width: 100%;
                margin-bottom: 0.5rem;
                font-weight: 500;
                &.active{
                    font-weight: 700;
                }
                &.version{
                    border-bottom: none;
                    background: #efefef;
                    border-radius: 2rem;
                    font-size: clamp(12px, 1vw, 17px);
               
                    &.active{
                        font-weight: 300;
                        background: var(--third-color);
                        .questions{
                            &::after{
                                background: var(--third-color);
                            }
                        }
                    }
                    &.active-version{
                        font-weight: 300;
                        background: var(--secondary-color);
                        .questions{
                            &::after{
                                background: var(--secondary-color);
                            }
                        }
                    }
                    &.root{
                        .questions{
                            &::after{
                             display: none;
                            }
                        }
                    }
                    &:hover{
                        text-shadow: 1px 0 0 var(--primary-color);
                    }
                }
            }
            .version-row{
                display: grid;
                grid-template-columns: 0.7fr 5.3fr 2.3fr 2fr;
                width: 100%;
                font-weight: 500;
                padding: 0.3rem 0;
                color: var(--primary-color);
                div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .date{
                    font-size: clamp(12px, 1vw, 15px);
                }
                .questions{
                    font-size: clamp(12px, 1vw, 15px);
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '✖';
                        margin: auto;
                        text-align: center;
                        left: 0;
                        right: 0;
                        top: -2px;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        background: #efefef;
                        font-size: clamp(18px, 1vw, 20px);
                        color: var(--primary-color);

                    }
                }
                .name{
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    justify-content: flex-start;
                    min-width: 100%;
                    width: 10px;
                }
                .version-num{
                    font-size: clamp(12px, 1vw, 16px);
                    width: 2rem;
                    display: block;
                    overflow: hidden;
                    text-overflow: initial;
                    min-width: 100%;
                    width: 48px;
                    text-align: center;
                }
                &:hover{
                    .questions{
                        
                        &::after{
                            opacity: 1;
                        }
                    }
                }
            }
            
        }
    }
    .delete{
        position: absolute;
        right: 0.5rem;
        background: var(--primary-color);
        color: white;

    }
    @media(max-width:1280px){
        .filter-list-container .dropdown-container{
            padding-top: 2rem;
        }
        .filter-list-container .dropdown-container .version-row{
            .version-num{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            }
            .name{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10px;
            }
            .date{
                font-size: 10px;
            }
            .questions{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10px;
            }
        } 
        .filter-list-container{
            .dropdown-arrow-container{
                width: 1rem;
                height: 1rem;
            }
            }
    }
</style>