<template>
    <div class="select-container" :class="{'filter': theme == 'filter'}" v-click-outside-2="closeSelect">
        <div class="input-container" >
            <input :style="{width: width ? width : ''}" type="text" v-model="searchInput" @focus="selectActive = true" :placeholder="value ? Array.isArray(value) ? value.length == 0 && placeholder ? placeholder : value.map(el=>el[label] ? el[label] : label == 'email' ? el['name'] + ' ' + el['last_name'] ? el['last_name'] : '' : '').join(', ') : value == 'all' ? selectedReceiverType == toLocal('mail.user') ? toLocal('chat.all') : toLocal('chat.all_who') : typeof value === 'object' ? value[label] : (value == toLocal('mail.user') && selectedUsers && selectedUsers.length >1) ? toLocal('generic.users_lc') : value : ''">
            <div class="select-wrapper" v-if="selectActive">
                <span v-if="canSelectAll" class="select-all" :class="{selected: value ? value == 'all' : ''}" @click="handleInput('all')">
                    {{selectAllLabel}}
                </span>
                <span v-for="option in  disabled? optionsCopy.filter(el => !disabled(el)) : optionsCopy" :key="option.id" :class="{selected: value ? Array.isArray(value) ? value.map(el=>el.id).includes(option.id) : value == option : '', disabled: selectable ? !selectable(option) : ''}" @click="handleInput(option)">
                    {{ option[label] ? option[label] : label == 'email' ? option['name'] + ' ' + option['last_name'] ? option['last_name'] : '' : option }}
                </span>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: 'SelectInput',
    props: {
        value: [Array, Object, String, Number],
        options: Array,
        label: String,
        multichoice: Boolean,
        canSelectAll: Boolean,
        selectAllLabel: String,
        selectable: Function,
        disabled: Function,
        width: String,
        selectedReceiverType: String,
        selectedUsers: [Array, Object, String],
        theme: String,
        placeholder:String
    },
    data () {
        return {
        content: this.value,
        searchInput: '',
        selectActive: false,
        optionsCopy: this._.cloneDeep(this.options),
        }
    },
    watch:{
        'options'(){
            this.optionsCopy = this._.cloneDeep(this.options);
        },
        searchInput(){
            this.search();
        }
    },

    methods: {
        handleInput (input) {
            if(this.selectable){
                if(!this.selectable(input)) return;
            }
            if(input == 'all'){
                this.content = 'all';
            }
            else{
                if(this.multichoice){
                    if(this.content == null) this.content = [];
                    else if(!Array.isArray(this.content)) this.content = [];
                    let index = this.content.findIndex(el => el.id == input.id);
                    if(index != -1){
                        this.content.splice(index, 1);
                    }
                    else{
                        this.content.push(input);
                    }
                }
                else{
                    if(this.content == null) this.content = {};
                    this.content = input;
                }
            } 
            if(!this.multichoice) this.closeSelect();
            this.$emit('input', this.content)
        },
        search(){
            this.optionsCopy = this._.cloneDeep(this.options);
            this.optionsCopy = this.optionsCopy.filter((el) => {
                if(el[this.label])
                    return el[this.label].toLowerCase().includes(this.searchInput.toLowerCase())
                else if(this.label == 'email' && el.last_name != undefined){
                    return el.last_name.toLowerCase().includes(this.searchInput.toLowerCase())
                }
                else if(this.label == 'email' && el.username != undefined){
                    return el.username.toLowerCase().includes(this.searchInput.toLowerCase())
                }
                else return false;
            });

        },
        closeSelect(e){
            this.selectActive = false;
            this.searchInput= '';
        }
    }
}
</script>

<style lang="scss" scoped>
.select-container{
    display: inline-flex;
    margin: 0 0.5rem;
    &.filter{
        .input-container{
            border-bottom: none;
            input{
                font-size: clamp(16px, 1.1vw, 20px);

            }
        }
        .select-wrapper{
            background: #efefef;
            border: none;
        }
    }
    .input-container{
        border-bottom: 2px solid var(--primary-color);
        position: relative;
        line-height: initial;
        input{
            background: none;
            border: none;
            height: 100%;
            width: 100%;
            font-size: clamp(24px, 1.1vw, 30px);
            text-align: center;
            &:focus{
                outline: none
            }
            &::-webkit-input-placeholder {
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--primary-color);
                opacity: 1;     
            }

            &::-moz-placeholder {
               overflow: hidden;
                text-overflow: ellipsis;
                color: var(--primary-color);
                opacity: 1;      
            }

            &::-ms-placeholder {
               overflow: hidden;
                text-overflow: ellipsis;
                color: var(--primary-color);
                opacity: 1;      
            }

            &::placeholder{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: var(--primary-color);
                opacity: 1;
            }
            
        }
        input[placeholder] {
            overflow: hidden;  
            text-overflow:ellipsis;
            white-space: nowrap;
        }

    }
    .select-wrapper{
        position: absolute;
        top: calc(100% + 1px);
        background: white;
        border-radius: 0.5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid var(--primary-color);
        z-index: 999;
        font-size: clamp(14px, 1vw, 18px);
        display: flex;
        flex-direction: column;
        padding: 0;
        width: 100%;
        max-height: 17rem;
        overflow: hidden;
        overflow-y: auto;
        span{
            line-height: initial;
            padding: 0.25rem 0.5rem;
            cursor: pointer;
            color: var(--primary-color);
            &:hover{
                font-weight: 700;
                &.disabled{
                   font-weight: 300;
                }
            }
            &.selected{
                background: var(--secondary-color-opacity-50);
            }
            &.disabled{
                color: var(--primary-color-opacity-50);
                cursor: not-allowed;
            }
            &.select-all{
                border-bottom: 1px solid var(--primary-color);
            }
        }
    }
}
</style>