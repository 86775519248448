<template>
    <div>
        <div
            v-if="topic.result != undefined && showTestResult && testHasStarted"
            :class="['test--result']"
        >
            <h3>
                {{ training.trainingType =='attest' ? toLocal("test.attest_result") : toLocal("test.final_result") }}
            </h3>
            <div
                :class="[
                'test--result--circle',
                { green: (topic.result * 100) >= topic.options.testPassedFrom },
                { score: topic.options.testResultShowType == 1 },
                ]"
            >
                {{ topic.options.testResultShowType == 2 ? "" : getResult(topic) }}
            </div>
            <div class="test--result--text">
                {{
                topic.result * 100 >= topic.options.testPassedFrom
                    ? toLocal("test_user.passed")
                    : toLocal("test_user.failed")
                }}
            </div>
        </div>
        <div
            class="test-done-window"
            v-if="topic.test_done && !showTestResult && !showTestAnswers"
            :class="['test--result']"
        >
            <h2>{{toLocal('test_user.completed')}}</h2>
            <span>
                {{
                !topic.testIsChecked
                    ? toLocal("training.please_wait")
                    : topic.canSolveAgain
                    ? toLocal("training.see_evaluation_or")
                    : toLocal("training.see_evaluation")
                }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        training: Object,
        topic: Object,
        showTestResult: Boolean,
        testHasStarted: Boolean,
        showTestAnswers: Boolean
    },
    methods: {
            getResult(topic) {
                if (topic.options.testResultShowType == 1) {
                    return (
                    parseFloat(topic.result * this.maxPossibleScore).toFixed(1) +
                    " "+this.toLocal('test_user.out_of')+" " +
                    this.maxPossibleScore
                    );
                } else {
                    return Math.round(topic.result * 100, 1) + "%";
                }
                },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_training.scss";

.test--result {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: transparent;
  border: 0.25rem solid white;
  text-align: center;
  h3{
    font-weight: 900;
    color: var(--primary-color);
  }
  .test--result--circle {
    width: 150px;
    height: 150px;
    background-color: #F47979;
    font-size: 45px;
    font-weight: bold;
    border-radius: 100%;
    color: #fff;
    line-height: 150px;
    display: inline-block;
    margin: 15px 0;

    &.green {
      background-color: #6ACD77;
    }
    &.score{
      font-size: clamp(12px, 3vw, 30px);
    }
  }
  
  .test--result--text {
    font-size: 30px;
    color: var(--primary-color);
  }
  @media (max-width: 768px){
    .test--result--circle{
      &.score{
        font-size: clamp(12px, 7vw, 30px);
      }
    }
  }
}
</style>