<template>
  <div class="main">
    <div
      class="prev-btn page-btn"
      :class="{ inactive: currentPage == 1 }"
      @click="changePage('prev')"
    >
      <span class="svg"> &lt; </span> <span class="text">{{toLocal('generic.back')}}</span>
    </div>
    <div class="inner">
      <span class="page-text"> {{toLocal('pagination.page')}} </span>
      <input
        v-model="currentPage"
        type="number"
        id="page-number"
        @blur="setPage($event.target.value)"
        @paste="sanitize"
        @keydown.enter.prevent="
          $event.preventDefault();
          $event.currentTarget.blur();
        "
        :size="options.pageCount.toString().length"
        class="page-number"
      />
      <span class="page-text"> {{toLocal('pagination.of')}} </span>
      <div class="page-max">
        {{ options.pageCount }}
      </div>
    </div>
    <div
      class="next-btn page-btn"
      :class="{ inactive: currentPage == options.pageCount }"
      @click="changePage('next')"
    >
      <span class="text">{{toLocal('generic.next')}}</span> <span class="svg"> > </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPaginate",
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      currentPage: this.options.currentPage,
    };
  },
  mounted() {
    $("#page-number").keypress(function (e) {
      if (isNaN(String.fromCharCode(e.which))) e.preventDefault();
    });
  },
  methods: {
    changePage(type) {
      if (type == "next") {
        if (this.currentPage < this.options.pageCount) {
          this.currentPage = parseInt(this.currentPage) + 1;
        }
      } else {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      }
      this.checkIfPageOverflow();
      this.setPage(this.currentPage);
    },
    checkIfPageOverflow() {
      console.log(this.currentPage > this.options.pageCount);
      if (this.currentPage < 1) {
        this.currentPage = 1;
      } else if (this.currentPage > this.options.pageCount) {
        this.currentPage = parseInt(this.options.pageCount);
      }
    },
    setPage(data) {
      this.currentPage = data;
      this.$emit("setPage", this.currentPage);
    },
    sanitize(event) {
      event.preventDefault();
      const text = this.$sanitize(event.clipboardData.getData("text"), {
        allowedTags: [],
      });
      document.execCommand("insertText", false, text);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  gap: 28px;
  align-items: center;
  font-size: 14px;
  .inner {
    display: flex;
    gap: 8px;
    align-items: center;
    input {
      padding: 4px 8px;
      border-radius: 6px;
      background-color: #fff;
      color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: fit-content;
      font-size: 14px;
      text-align: center;
    }
  }
  .page-btn {
    user-select: none;
    padding: 4px 8px;
    display: flex;
    gap: 8px;
    border-radius: 6px;
    background: #fff;
    color: hsla(0, 0%, 49%, 1);
    transition: 0.25s;
    font-size: 14px;
    &:not(.inactive) {
      &:hover {
        color: hsla(0, 0%, 20%, 1);
        cursor: pointer;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
