<template>
    <Report/>
</template>

<script>
import Report from "@/components/layout/Reports/ReportFormer.vue";

export default {
    data(){
        return{

        }
    },
    components:{
        Report
    }
}
</script>

<style lang="scss" scoped>

</style>