<template>
  <div class="group--wrapper">
    <div
      class="group--parent--container"
      @mouseover="hovering = true"
      @mouseleave="hovering = false"
    >
      <div
        v-for="(item, index) in items.slice(0, maxShownItems)"
        :key="index"
        :style="{
          'margin-left': index == 0 ? '' : '-12px',
        }"
        class="group--item"
      >
        <VTooltip
          v-if="hovering"
          theme="users"
          :triggers="['hover']"
          :placement="'top'"
        >
          <div :class="['group', 'g' + (index + 1), 'c' + item.color_id]">
            {{ item.name[0] }}
          </div>
          <template #popper>
            {{ item.name }}
          </template>
        </VTooltip>
        <div v-else :class="['group', 'g' + (index + 1), 'c' + item.color_id]">
          {{ item.name[0] }}
        </div>
      </div>
      <span
        class="more"
        v-if="items.length > maxShownItems"
        @mouseover="hoveringExtra = true"
        @mouseleave="hoveringExtra = false"
      >
        <VTooltip
          v-if="hoveringExtra"
          theme="users"
          :triggers="['hover']"
          :placement="'top'"
        >
          <span>...+{{ items.length - maxShownItems }}</span>
          <template #popper>
            Grupės:
            <span v-for="(item, index) in otherGroups" :key="index">
              {{ item.name }}{{ index == otherGroups.length - 1 ? "" : "," }}
            </span>
          </template>
        </VTooltip>
        <span v-else>...+{{ items.length - maxShownItems }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    maxShownItems: {
      type: Number,
      default: 4,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hovering: false,
      hoveringExtra: false,
    };
  },
  computed: {
    otherGroups() {
      if (this.items && this.items.length > 0)
        return this.items.slice(this.maxShownItems, this.items.length);
      else return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.group--wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  padding-right: 10px;
  position: relative;
  height: 100%;
  width: 100%;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  // overflow: hidden;

  .group--parent--container {
    display: flex;
    justify-content: center;

    position: relative;
  }
  &:hover .group {
    @for $i from 2 through 15 {
      &.g#{$i} {
        width: 10% - ($i - 1) * 4;
      }
    }
  }
  .group--item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: visible;
  }
  .group {
    width: 100%;
    height: 100%;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s width;
    user-select: none;
    border-radius: 50%;
    min-width: 32px;
    height: 32px;
    max-width: 32px;
    font-size: 14px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: var(--main-site-font);
    font-weight: 500;

    @for $i from 0 through 15 {
      &.c#{$i} {
        background-color: hsl(
          ($i * 40 % 360),
          (20 + 2.5 * $i) % 70 * 1%,
          var(--theme-lightness)
        );
      }
      &.g#{$i} {
        width: 100% - ($i - 1) * 10;
      }
    }

    &:hover {
      @for $i from 0 through 15 {
        &.g#{$i} {
          width: 100% - ($i - 1) * 2;
        }
      }
    }
  }
}
.group--parent--container {
  position: relative;
  display: flex;
}
.group--wrapper .group--item {
  width: initial;
}
.more {
  position: absolute;
  right: -14px;
  bottom: -2px;
  font-size: 10px;
}
</style>
