<template>
    <div class="rules-container">
        <transition name="fade">
            <div v-html="toLocal('payment.rules')" v-show="$store.state.translations.length>0">

            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style lang="scss">
.rules-container{
    padding: 0 40px 150px;
    a{
        color: #000000;
        text-decoration: underline;
    }
}
</style>