<template>
  <div>
    <div
      :class="['side--background', sideOpen != -1 ? 'side--open' : '']"
      v-scroll-lock="sideOpen != -1"
      @click="$emit('close')"
    />
    <section :class="{ 'group--side': true, 'side--open': sideOpen != -1 }">
      <div class="side--padding">
        <img
          class="side--padding--corner"
          src="@/assets/images/side-corner-narrow.svg"
        />
        <div class="side--padding--bottom" />
      </div>
      <div class="side--close" @click="$emit('close')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.521"
          height="36.521"
          viewBox="0 0 36.521 36.521"
        >
          <g
            id="Group_1147"
            data-name="Group 1147"
            transform="translate(-995 -94.204)"
          >
            <circle
              id="Ellipse_62"
              data-name="Ellipse 62"
              cx="18.26"
              cy="18.26"
              r="18.26"
              transform="translate(995 94.204)"
              fill="#fff"
            />
            <g
              id="Group_656"
              data-name="Group 656"
              transform="translate(1012.934 96.686) rotate(45)"
            >
              <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                <path
                  id="Path_751"
                  data-name="Path 751"
                  d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z"
                  transform="translate(0)"
                  fill="#6d66a2"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="side--content">
        <div class="test--title">
          <!-- <span >{{ test.name }}</span> -->
          {{ user.name }} {{ user.last_name }}
        </div>
        <div class="stats">
          <!-- <span> Vidutinis rezultatas: <strong>{{test.result * 100 +' %'}}</strong></span>
            <span>Vidutinis sprendimo laikas: <strong>{{getTestSolvingTime(test.time)}}</strong></span> -->
        </div>
        <div class="filter-container" :class="{ empty: totalPageCount == 0 }">
          <div class="filter">
            <div class="search-container">
              <div class="search-icon">
                <svg
                  v-if="searchInput == ''"
                  id="Group_10091"
                  data-name="Group 10091"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20.933"
                  height="20.934"
                  viewBox="0 0 20.933 20.934"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_2331"
                        data-name="Rectangle 2331"
                        width="20.933"
                        height="20.934"
                        fill="#28345c"
                      />
                    </clipPath>
                  </defs>
                  <g id="Group_7557" data-name="Group 7557">
                    <path
                      id="Path_6095"
                      data-name="Path 6095"
                      d="M.555,20.382a1.893,1.893,0,0,1,0-2.677L6.2,12.057a7.785,7.785,0,0,1-.568-1.131A7.94,7.94,0,1,1,10.008,15.3a7.545,7.545,0,0,1-1.13-.567L3.229,20.382a1.9,1.9,0,0,1-2.674,0M8.527,3.467A6.324,6.324,0,1,0,13,1.616a6.323,6.323,0,0,0-4.47,1.851Z"
                      transform="translate(0 0)"
                      fill="#28345c"
                    />
                  </g>
                </svg>
                <div v-else class="remove" @click="searchInput = ''">
                  <span></span><span></span>
                </div>
              </div>
              <input
                type="text"
                :placeholder="toLocal('training_side.enter_search_query')"
                v-model="searchInput"
                @keydown.enter="getUsers"
              />
            </div>
          </div>
        </div>

        <div class="grid-table">
          <div class="table-row header">
            <div class="table-cell">
              <span class="name-cell header">
                {{ toLocal("training_side.confirmation_text") }}
                <img
                  v-if="orderByElement == 'text' && orderByDirection == 'desc'"
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('text')"
                />
                <img
                  v-if="orderByElement == 'text' && orderByDirection == 'asc'"
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('text')"
                />
                <img
                  v-if="orderByElement != 'text'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('text')"
                />
              </span>
            </div>
            <div class="table-cell">
              <span class="header">
                {{ toLocal("training_side.confirmation_date") }}
                <img
                  v-if="
                    orderByElement == 'created_at' && orderByDirection == 'desc'
                  "
                  src="@/assets/images/icons/order-by-desc.svg"
                  @click="setOrderBy('created_at')"
                />
                <img
                  v-if="
                    orderByElement == 'created_at' && orderByDirection == 'asc'
                  "
                  src="@/assets/images/icons/order-by-asc.svg"
                  @click="setOrderBy('created_at')"
                />
                <img
                  v-if="orderByElement != 'created_at'"
                  src="@/assets/images/icons/order-by.svg"
                  @click="setOrderBy('created_at')"
                />
              </span>
            </div>
          </div>
          <div
            class="table-row parent"
            v-for="(stat, stat_id) in searchStats"
            :key="stat_id"
          >
            <div class="table-row">
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ stat.text }}
                </span>
              </div>
              <div class="table-cell">
                <span
                  @mouseover="scrollNameHorizontal($event, true)"
                  @mouseleave="scrollNameHorizontal($event, false)"
                >
                  {{ getDate(stat.created_at) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ToggleButton from "@/components/layout/emails/ToggleButton.vue";
import SelectInput from "@/components/layout/SelectInput.vue";
import axios from "axios";
import _ from "lodash";
export default {
  name: "TrainingConfirmationUserSide",
  components: {
    ToggleButton,
    SelectInput,
  },
  props: {
    sideOpen: {
      type: Number,
      default: -1,
    },
    test: {
      type: Object,
    },
    training_id: {
      type: Number,
    },
    stats: {
      type: Array,
    },
    user: {
      type: Object,
    },
  },
  data: function () {
    return {
      id: "-1",
      name: "...",
      description: "...",
      cover: "",
      groups: [],
      search_groups: [],
      searching: false,
      lastSearchValue: "",
      searchLoading: false,
      loadInterval: null,
      searchInterval: null,
      orderByElement: "",
      orderByDirection: "",
      users: [],
      searchStats: [],
      allStats: [],
      showAllStats: false,
      orderByDirection: "",
      exporting: false,
      searchingStats: false,
      totalSearchPageCount: 0,
      totalPageCount: 0,
      page: 1,
      searchPage: 1,
      skip: 0,
      limit: 100,
      searchSkip: 0,
      searchLimit: 100,
      user_count: 0,
      users_started: 0,
      table_user_count: 0,
      table_users_started: 0,
      allUsersIterator: 0,
      avg_result: 0,
      avg_rating: 0,
      pagesViewed: [],
      searchInput: "",
      groupFilter: [],
      groups: [],
      firstStatsLoaded: false,
      firstSearchStatsLoaded: false,
      searchMode: false,
      lastSearch: "",
      cover: "",
      expandedStat: -1,
    };
  },
  watch: {
    searchInput() {
      this.searchDebounce();
    },
    stats() {
      this.searchStats = this._.cloneDeep(this.stats);
    },
  },
  mounted() {
    this.searchStats = this._.cloneDeep(this.stats);
  },

  methods: {
    setOrderBy(element) {
      if (element == this.orderByElement) {
        this.orderByDirection =
          this.orderByDirection == "desc" ? "asc" : "desc";
      } else {
        this.orderByElement = element;
        this.orderByDirection = "asc";
      }
      if (element == "text") {
        if (this.orderByDirection == "desc") {
          this.searchStats.sort((a, b) => b[element].localeCompare(a[element]));
        } else {
          this.searchStats.sort((a, b) => a[element].localeCompare(b[element]));
        }
      } else if (element == "created_at") {
        if (this.orderByDirection == "desc") {
          this.searchStats.sort(
            (a, b) => Date.parse(b[element]) - Date.parse(a[element])
          );
        } else {
          this.searchStats.sort(
            (a, b) => Date.parse(a[element]) - Date.parse(b[element])
          );
        }
      }
    },
    getDate(date) {
      let new_date = date.split("T")[0];
      let time = date.split("T")[1];
      time = time.split(".")[0];
      return new_date + " " + time;  
    },
    searchDebounce: _.debounce(function () {
      this.search();
    }, 500),
    search() {
      this.searchStats = this._.cloneDeep(this.stats);
      this.searchStats = this.searchStats.filter((el) => {
        let showStat = false;
        if (el.text.toLowerCase().includes(this.searchInput.toLowerCase()))
          showStat = true;
        if (
          el.created_at.toLowerCase().includes(this.searchInput.toLowerCase())
        )
          showStat = true;
        return showStat;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";

.side--background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s opacity;

  &.side--open {
    pointer-events: auto;
    opacity: 1;
  }
}
.group--side {
  position: fixed;
  right: -900px;
  background-color: #fff;
  height: calc(100vh - 85px);
  top: 85px;
  width: 800px;
  z-index: 11;
  font-family: var(--main-site-font);
  transition: 0.3s right;
  z-index: 1006;

  &.side--open {
    right: 0;
  }

  &.header--disabled {
    height: 100vh;
    top: 0px;
  }
}
.side--content {
  overflow: auto;
  height: 100%;
  padding: 60px 50px;
  padding-left: 20px;
  overflow-x: hidden;
}
.side--close {
  position: absolute;
  left: -100px;
  top: 44px;
  border: transparent;
  // border-right: 20px solid #EFEFEF;
  padding: 20px;
  border-radius: 100%;
  width: 87px;
  height: 87px;
  cursor: pointer;
  user-select: none;

  svg {
    width: 47px;
    height: 47px;
    path {
      fill: var(--primary-color);
    }
  }
}

.side--padding {
  position: absolute;
  margin-left: -35px;
  margin-top: -80px;
  width: 40px;

  .side--padding--corner {
    height: 250px;
    display: block;
  }

  .side--padding--bottom {
    height: calc(100vh - 150px);
    width: 35px;
    background-color: #fff;
    margin-top: -4px;
  }
}

.group--section--select {
  margin-top: -60px;

  .group--section--select--button {
    width: 50%;
    float: left;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    user-select: none;
    background-color: $background_color;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: 0.3s box-shadow, 0.3s background-color;

    &.active,
    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background-color: $group_color;
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.test--title {
  font-size: 35px;
  padding-top: 15px;
  font-weight: 900;
  padding-left: 30px;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  .group--cover {
    width: 105px;
    height: 105px;
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    background-color: #dbdbdb;
    // background-image: url("@/assets/images/placeholders/pia21974.0.jpg");
  }

  span {
    font-size: 35px;
    padding-left: 23px;
    // display: block;
  }
}

.lesson--list {
  position: relative;
}

.side--table--control {
  margin-top: 50px;
  width: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  transition: 0.3s left;

  &.hidden {
    left: calc(-100% - 150px);

    &.search--table {
      left: calc(200% + 150px);
    }
  }

  svg {
    margin-top: -2px;
  }

  // tbody tr {
  // background-color: #efefef;
  // }

  tbody td {
    background-color: #efefef;
  }

  &.search--table {
    // tr td {
    // border-top: 1px solid #464646;
    // border-bottom: 1px solid #464646;

    // &:first-child {
    // border-left: 1px solid #464646;
    // }
    // &:last-child {
    // border-right: 1px solid #464646;
    // }
    // }
    svg {
      margin-top: -9px;
    }
  }
}

.group--add--button {
  padding: 12px;
  border-radius: 35px;
  padding-right: 35px;
  user-select: none;
  transition: 1s background-color, 0.3s transform, 0.3s box-shadow;
  // background-color: #EFEFEF;
  // margin: 0 -35px;
  margin-top: 20px;
  border: 1px solid #707070;

  .group--add--button--icon {
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 100%;
    vertical-align: middle;

    svg {
      max-width: 28px;
      max-height: 28px;
      fill: var(--primary-color);
    }
  }

  .group--add--button--title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #707070;
    width: calc(100% - 70px);

    input {
      background: transparent;
      border: none;
      outline: none;
      color: $font_color;
      font-size: 20px;
      font-family: var(--main-site-font);
      width: calc(100% - 40px);
    }
  }

  &:hover {
    box-shadow: 0px 3px 6px #00000033;
    transform: translateY(-3px);
  }
}
.stats {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
}
.grid-table {
  display: grid;
  grid-template-rows: auto;
  background: white;
  padding: 1rem 0;
  border-radius: 1rem;
  .table-row {
    display: grid;
    grid-template-columns: minmax(100px, 1.67fr) minmax(100px, 1.67fr);
    margin: 0.25rem 0;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    background: #efefef;
    &.header {
      background: white;
      font-weight: 700;
      color: var(--primary-color);
      font-size: 16px;
    }
    &.parent {
      grid-template-columns: 1fr;
      margin: 0.25rem 0;
      padding: 0;
    }
    &.expanded {
      box-shadow: 0px 0px 2px 2px var(--primary-color-opacity-50);
    }
    .table-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 0.25rem 0;
      border-left: 1px solid var(--primary-color);
      &:first-child {
        border-left: none;
      }
      span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        &.name-cell {
          margin-left: 1.7rem;
          max-width: 290px;
        }
        img {
          margin: 0 0.25rem;
        }
        &.header {
          display: flex;
          white-space: normal;
        }
      }

      svg {
        height: 100%;
        width: 1.7rem;
      }
      &.action {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          display: flex;
          padding: 0.125rem 0;
        }
        svg {
          background: white;
          border-radius: 50%;
          width: 1.75rem;
          height: 1.75rem;
          border: 1px solid var(--primary-color);
          cursor: pointer;

          circle {
            stroke: var(--primary-color);
          }
          path {
            stroke: var(--primary-color);
          }
        }
        &.active {
          svg {
            background: var(--primary-color);
            //border-color: white;
            #Ellipse_33 {
              stroke: white;
            }
            path {
              stroke: white;
            }
          }
        }
      }
    }
  }
}
.search-container {
  grid-area: search;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 2rem;
  color: var(--primary-color);
  padding: 0.25rem 0.5rem;
  .search-icon {
    display: flex;
    margin-right: 0.25rem;
    height: 100%;
    align-items: center;
    .remove {
      display: flex;
      position: relative;
      width: 1rem;
      height: 100%;
      cursor: pointer;
      transition: transform 0.3s;
      span {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 80%;
        height: 2px;
        background: var(--primary-color);
        border-radius: 1rem;
        transform: rotate(45deg);
      }
      span:last-child {
        transform: rotate(-45deg);
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  svg {
    path {
      fill: var(--primary-color);
    }
  }
  input {
    background: none;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 16px;
    color: var(--primary-color);
    &:focus {
      outline: none;
    }
  }
}
.filter-container {
  display: flex;
  flex-direction: column;
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  //padding: 1rem 0rem;
  margin-top: 1rem;
  color: var(--primary-color);
  .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .search-container {
    background: #efefef;
    display: flex;
    width: 100%;
    max-width: 20rem;
    height: 100%;
    height: 36px;

    input {
      font-size: 16px;
    }
  }
  .group-filter {
    display: flex;
    align-items: center;
    background: #efefef;
    border-radius: 1rem;
    margin: 0 1rem;
    height: 36px;
    min-width: 14rem;
    max-width: 14rem;
  }
  .label {
    margin-right: 1rem;
  }
  &.empty {
    border-radius: 1rem;
    // margin-bottom: 1rem;
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  flex-direction: row;

  //   background: #efefef;
  padding: 0.5rem;
  border-radius: 1.5rem;
  margin: 0;
  list-style-type: none;
  &.stats {
    border-radius: 1rem;
    flex-direction: row;
  }
  &.top {
    // margin-top:0;
    // border-top-right-radius: 0;
    // border-top-left-radius: 0;
  }
}
.page-item {
  &.active {
    background-color: var(--primary-color);
    color: white;
  }
  margin: 0 0.25rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
}
</style>
