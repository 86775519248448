<template>
<div v-scroll-lock="true">
    <h3>
        {{toLocal('import.update_fail')}}
    </h3>
    <div class="table-container">
        <table id="users">
            <tr>
                <th>{{toLocal('table.row_num')}}</th>
                <th>{{toLocal('generic.name')}}</th>
                <th>{{toLocal('generic.surname')}}</th>
                <th>{{toLocal('generic.email')}}</th>
                <th>{{toLocal('generic.username')}}</th>
                <th>{{toLocal('generic.state')}}</th>
                <th>{{toLocal('generic.reason')}}</th>
            </tr>
            <tr v-for="(user, user_index) in users" :key="user_index">
                <td>{{user.row_number}}</td>
                <td>{{user.name}}</td>
                <td>{{user.last_name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.username}}</td>
                <td>{{user.id != undefined ? toLocal('import.updated') : toLocal('import.not_added')}}</td>
                <td>{{getUserReason(user.reason)}}</td>
            </tr>
        
        </table>
    </div>
</div>
</template>

<script>
export default {
    props:{
        users:{
            type: Array,
            required: true
        }
    },
    data(){
        return{

        }
    },
    methods:{
        getUserReason(reason){
            if(reason == 'email'){
                return this.toLocal('import.user_exists')
            }else if(reason == 'username'){
                return this.toLocal('import.user_name_exists')
            }
            else if(reason == 'emailUsername'){
                return this.toLocal('import.no_name_email')
            }
            else return '';

        }
    }
}
</script>

<style lang="scss" scoped>
#users {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  
  width: 100%;
}

#users td, #users th {
  border: 1px solid #ddd;
  padding: 8px;
}

#users tr:nth-child(even){background-color: #f2f2f2;}
#users th{
    position: sticky;
    top: 0;
    left: 0;
}
#users tr:hover {background-color: #ddd;}

#users th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--primary-color);
  color: white;
}
#users td {

    text-align: left;
    &:first-child{
        text-align: center;
    }
}
.table-container{
    max-height: 30rem;
    overflow: auto;
    padding: 0 0.5rem;
}
</style>