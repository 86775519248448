<template>
    <div class="stats-container">
        <div class="header">
            <h2 class="group-name">

            </h2>
            <div class="timeframe-selector">
                <span>Laikotarpis:</span>
                <div class="tab-button-container">
                    <div 
                        class="tab-button"
                        :class="{active: selectedTimeframe == timeframe.key}"
                        v-for="(timeframe, index) in timeframes" 
                        :key="index"
                        @click="selectedTimeframe=timeframe.key"
                    >
                        {{ toLocal(timeframe.label) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        group: Object
    },
    data() {
        return {
            timeframes: [
                {label: 'Metai', key: 'year'},
                {label: 'Ketvirtis', key: 'quarter'},
                {label: 'Mėnuo', key: 'month'},
                {label: 'Savaitė', key: 'week'},
            ],
            selectedTimeframe: 'year'
        }
    }
    
}
</script>

<style lang="scss" scoped>
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
}
.stats-container{
    display: flex;
    align-items: center;
    background-color: white;
    padding: 18px 32px;
}
.timeframe-selector{
    display: flex;
    gap: 8px;
}
.tab-button-container{
    display: flex;
    align-items: center;
    .tab-button{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4D4D4D;
        background-color: white;
        border: 1px solid #4D4D4D;
        width: 64px;
        height: 24px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
        &.active{
            background-color: #4D4D4D;
            color: white;
        }
        &:first-child{
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        &:last-child{
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
}
</style>