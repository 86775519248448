<template>
  <section name="settings">
    <div 
      v-if="hasPermission('users.send_mail')" 
      :class="{' main--container training': true, 'side--open': $store.getters.isSideOpen() }"
    >
      <div class="user--list">
        <div class="header">
          <div class="tab--container">
            <div
            class="tab"
            :style="{'z-index': tab == tab_index ? 5 : tabs.length-tab_index}"
            :class="{selected: tab == tab_index}"
            v-for="(tab_item, tab_index) in tabs" 
            :key="tab_index" 
            @click="tab = tab_index"
            >
                {{tab_item.name}}
            </div>
        </div>
        </div>

        <div
          v-if="tab == 0"
          class="user--table"
        >
          <ChatList @openChat="openChat"  :selectedChat="selectedChat" @setSendMessageMode="chatMode=1"/>
        </div>

        <div 
          v-if="tab == 1"
          class="email--table"
        >
        <EmailFolders reportType="email" />

        </div>
      </div>
      <div class="vertical--border" />
      <div class="message--wrapper">
        <div 
          v-show="tab == 0"
          class="chat-window"
        >
          <ChatWindow :chatMessages="chatMessages" :chatMode="chatMode" :selectedChatItem="selectedChatItem" :selectedChat="selectedChat" />
        </div>
        <div 
          v-show="tab == 1"
          class="message--wrapper--child"
        >
 
          <EmailTemplate />
        </div>
      </div>
    </div>
    <DeleteModal v-if="$store.state.emailForming.deleteModal.modalOpen" reportType="email" />
  </section>
</template>

<script>
import axios from "axios";
import EmailFolders from '@/components/layout/emails/EmailFolders.vue';
import DeleteModal from '@/components/layout/emails/DeleteModal.vue';
import EmailTemplate from '@/components/layout/emails/EmailTemplate.vue';
import ChatList from '@/components/layout/chats/ChatList.vue';
import ChatWindow from '@/components/layout/chats/ChatWindow.vue';
export default {
  name: "Email",
  components: {
    EmailFolders,
    DeleteModal,
    EmailTemplate,
    ChatList,
    ChatWindow
},
  data: function(){
    return {
      tab: 0,
      selectedEmail: -1,
      emailTemplates: [],
      lastEmailTemplateJson: "",
      savingEmail: false,
      emailTemplatesLoading: false,
      lastChatMessages: "",
      positiveEvents:[],
      input: '',
      selectedChat: -1,
      chatMessages: [],
      chatUpdateInterval: null,
      selectedChatItem: {},
      tabs: [
          {name: this.toLocal('email.messages')},
          {name: this.toLocal('email.automatic_mails')},
      ],
      chatMode: 0,
    }
  },
  watch:{
    '$store.state.chatMessagesQueue':{
      immediate:true,
      deep:true,
      handler(newData, oldData){
        newData.forEach((el, index) =>{
          if(this.selectedChat != el.chat_id) return;
          if(this.chatMessages.findIndex(elem => elem.id == el.id) == -1) {
            this.chatMessages.push(el);
            this.$store.state.chatMessagesQueue.splice(index, 1);
            let unreadIndex = this.$store.state.unread_chats.findIndex(elem => elem == el.chat_id);
            if(unreadIndex != -1){
              this.$store.state.unread_chats.splice(unreadIndex, 1);
            }
          }
          else{
            this.$store.state.chatMessagesQueue.splice(index, 1);
            let unreadIndex = this.$store.state.unread_chats.findIndex(elem => elem == el.chat_id);
            if(unreadIndex != -1){
              this.$store.state.unread_chats.splice(unreadIndex, 1);
            }
          }
          
        })
        // this.chatMessages.push()
      }
    }
  },
  mounted(){
    this.getEmailFolders();
        // this.chatUpdateInterval = setInterval(() => {
        //   this.loadMessages();
        // },3000);
  },
  methods: {
      openChat(chat_id, chats, chat_item) {
            this.selectedChat = chat_id;
            this.chatLoading = true;
            this.loadMessages(chat_item);
            this.chatMode = 0;
            axios.get(
              `${this.$store.state.api_link}/user/${chat_item.user.id}/groups`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
              this.$set(chat_item.user, 'groups', response.data.groups);
              this.selectedChatItem = chat_item;
            })
            .catch(() => {
            });
            let index = this.$store.state.unread_chats.indexOf(chat_item.id);
            if (index !== -1) this.$store.state.unread_chats.splice(index, 1);
        },
      loadMessages(chat_item) {
          if(this.selectedChat < 0)
              return;
          axios.post(
              `${this.$store.state.api_link}/chat/open`,
              {user: chat_item.user.id},
              this.$store.getters.axiosConfig()
          ).then((response) => {
              this.chatMessages = response.data.data;
              this.chatLoading = false;
              if(this.lastChatMessages != JSON.stringify(this.chatMessages)){
              this.lastChatMessages = JSON.stringify(this.chatMessages);
              setTimeout(() => {
                  let wrapper = this.$el.querySelector(".chat--messages--wrapper");
                  wrapper.scrollTop = wrapper.scrollHeight;
              },10);
              }
          })
          .catch(() => {
          });
      },
      getEmailFolders(){
        this.emailTemplatesLoading = true;
        axios.get( 
          `${this.$store.state.api_link}/email-folders`,
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.$store.state.emailForming.emailFolders = response.data.folders;
          this.$store.state.emailForming.searchEmailFolders = this._.cloneDeep(response.data.folders);
          this.$store.state.emailForming.signature = response.data.signature != null ? response.data.signature.text : '';
          this.emailTemplatesLoading = false;
        }).catch((error) => {
          console.log(error);
          this.emailTemplatesLoading = false;
        });
      },
    // startEchoListen(){
    //   window.Echo.chat(`trainingEdit.${this.$store.state.currentTraining}`)
    // },
   
    

   
  
   
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/views/_emailViewScoped.scss";
</style>

<style lang="scss">
@import "@/assets/scss/views/_emailView.scss";
</style>