<template>
  <div 
    class="test-content-container" 
    :class="{locked: testIsLocked}"
    >
      <!-- <div class="test-locked" v-if="test.locked === 1 || (parentTest.options.active_version_id == test.id && (parentTest.versions && parentTest.versions.length >0))">

      </div> -->
       <div 
            v-if="topic.test && test"
            :class="['test--question--add--select', {locked: testIsLocked}]"
          >
             <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 1, true)"
              >
                <div class="img-container">
                  <img 
                    src="@/assets/images/icons/task-text.svg"
                  >
                </div>
                <span>
                  {{toLocal('training_edit.test_question_type_1')}}

                </span>
              </div>
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 2, true)"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/task-image.svg"
                >
              </div>
                <span>
                 {{toLocal('training_edit.test_question_type_2')}}
                </span>
              </div>
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 3, true)"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/task-connect.svg"
                >
              </div>
              <span>
                {{toLocal('training_edit.test_question_type_3')}}
              </span>
              </div>
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 4, true)"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/open_question.svg"
                >
              </div>
                <span>
                  {{toLocal('training_edit.test_question_type_4')}}
                </span>
              </div>
               <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 5, true)"
              >
              <div class="img-container big">
                <img 
                  src="@/assets/images/icons/clickTask.svg"
                >
              </div>
                <span>
                {{toLocal('training_edit.test_question_type_5')}}
                </span>
              </div>
               <div 
                class="media--action upload"
                @click="importTestQuestions('top')"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/importQuestions.svg"
                >
              </div>
                <span>
                  {{toLocal('test.upload_test')}}
                </span>
              </div>
          </div>
        <div 
            class="test--questions content--item--task" 
            v-if="topic.test && test"
          >
            <div 
              v-for="(question, questionIndex) in test.questions"
              :id="`trainingItem-${topicIndex}-${questionIndex}`"
              :key="questionIndex"
              :ref="`trainingItem-${topicIndex}-${questionIndex}`"
              :class="['test--question', {'disabled': !question.enabled}, {'expanded': question.expanded}]"
            >
             <div
                v-if="question.type > 0"
                class="task"
              >
                <div class="question--number">
                  {{ questionIndex + 1 }}
                </div>
                    
                <div class="task--text--wrapper" >
                  <!-- <p 
                    v-if="!question.editing"
                    class="task--text"
                    @click="editQuestionItem(topicIndex, questionIndex, question)"
                    v-html="question.question"
                    @mouseover="$emit('disableDrag', true)"
                    @mouseout="$emit('disableDrag', false)"
                  /> -->
                  <div
                    @click="question.expanded == false || question.expanded == undefined ? $set(question,'expanded', true) : editQuestionItem(topicIndex, questionIndex, question)"
                    @mouseover="$emit('disableDrag1', true)"
                    @mouseout="$emit('disableDrag1', false)"
                    class="editor-class-container"
                    v-if="!question.editing || question.editing == undefined"
                  >
                    <editor
                      :class="{'editor': !question.expanded}"
                      :isEditing="question.editing"
                      :readOnly="true"
                      :imagesAllowed="false"
                      :placeholder="toLocal('generic.question')"
                      v-model="question.question"
                    />
                  </div>
                  <editor 
                    v-if="question.editing"
                    :ref="`trainingQuestionTitle-${topic.questionsindex}-${questionIndex}`"
                    :class="['editor--content', (question.editing ? 'editing' : '')]"
                    :isEditing="question.editing"
                    :readOnly="false"
                    :imagesAllowed="true"
                    :charLimit="500"
                    v-model="question.question"
                    @clickedOutside="endTestEditing(question)"
                  />
                  <div class="score--container">
                    <div class="score--col">
                      <div>{{toLocal('test_options.worth_scores')}}</div>
                      <div><input type="text" v-model="question.score" :disabled="testIsLocked" v-mask="numberMask" @keydown="inputNumbersOnlyInput($event, question)"  @change="inputNumbersOnly($event, question)"   @blur="$event.target.value == '' ? question.score = 1 : '';"></div>
                    </div>
                  </div>
                </div>
                <div 
                  v-if="question.type == 1"
                  :class="['question--settings', {'expanded': question.expanded}]"
                >
                  <div
                    class="task--answers"
                  
                  >
                    <draggable
                      v-model="question.answers"
                      :options="{disabled: disableItemDrag2 || testIsLocked }"
                      animation="200"
                      @change="saveAnswerPosition($event, question.answers)"
                    >
                      <transition-group type="transition">
                        <div 
                          v-for="(answer, answerIndex) in question.answers"
                          :key="answerIndex"
                          class="task--answer"
                        >
                          <svg @click="deleteQuestionAnswer(question, answerIndex)" class="stroke task--answer--remove" xmlns="http://www.w3.org/2000/svg" width="20.317" height="25.807" viewBox="0 0 20.317 25.807">
                            <g id="Group_308" data-name="Group 308" transform="translate(-7004.16 -1767.753)">
                              <path id="Rectangle_184" data-name="Rectangle 184" d="M0,0H14.988a0,0,0,0,1,0,0V13A6.028,6.028,0,0,1,8.96,19.023H6.028A6.028,6.028,0,0,1,0,13V0A0,0,0,0,1,0,0Z" transform="translate(7006.825 1773.636)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="1.8"/>
                              <path id="Path_550" data-name="Path 550" d="M7023.577,1775.469a1.48,1.48,0,0,1-1.476,1.476h-15.566a1.476,1.476,0,1,1,0-2.951H7022.1A1.474,1.474,0,0,1,7023.577,1775.469Z" transform="translate(0 -3.309)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="1.8"/>
                              <path id="Path_551" data-name="Path 551" d="M7027.308,1770.129a1.489,1.489,0,0,1-.106.555h-6.465a1.48,1.48,0,0,1-.107-.555,1.481,1.481,0,0,1,1.476-1.476h3.723A1.478,1.478,0,0,1,7027.308,1770.129Z" transform="translate(-9.649 0)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="1.8"/>
                              <g id="Group_290" data-name="Group 290" transform="translate(7010.68 1776.735)">
                                <line id="Line_35" data-name="Line 35" y2="11.889" transform="translate(7.278)" fill="none" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.8"/>
                                <line id="Line_36" data-name="Line 36" y2="11.889" transform="translate(3.639)" fill="none" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.8"/>
                                <line id="Line_37" data-name="Line 37" y2="11.889" fill="none" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.8"/>
                              </g>
                            </g>
                          </svg>
                          <div 
                            :class="['task--answer--letter', {'active': answer.correct}]"
                            @click=" testIsLocked ? '' : answer.correct = !answer.correct; editAnswerRequest(answer)"
                          >
                            {{ String.fromCharCode(65 + answerIndex) }}
                          </div>
                          <div
                            class="task--answer--text"
                            v-if="currentQuestionAnswerEditing[0] != topicIndex || currentQuestionAnswerEditing[1] != questionIndex || currentQuestionAnswerEditing[2] != answerIndex"
                            @click="editQuestionAnswer(topicIndex, questionIndex, question, answerIndex, -1)"
                            @mouseover="$emit('disableDrag1', true); disableItemDrag2 =true"
                            @mouseout="$emit('disableDrag1', false); disableItemDrag2 =false"
                          >
                            {{ answer.text }}
                          </div>
                          <input
                            v-if="currentQuestionAnswerEditing[0] == topicIndex && currentQuestionAnswerEditing[1] == questionIndex && currentQuestionAnswerEditing[2] == answerIndex"
                            :ref="`questionAnswer-${topicIndex}-${questionIndex}-${answerIndex}`"
                            v-click-outside-editing-title="undefined"
                            class="training--content--topic--edit task--answer--text--edit"
                            type="text"
                            @mouseover="$emit('disableDrag1', true)"
                            @mouseout="$emit('disableDrag1', false)"
                            @keyup.enter="setQuestionAnswer(question, answerIndex, -1, $event.srcElement.value)"
                          >
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                  <div 
                    class="add--task--answer"
                    @click="addQuestionAnswer(topicIndex, questionIndex, question)"
                  >
                    <img src="@/assets/images/icons/plus-gray.svg">
                    {{ toLocal('hierarchy.add') }}
                  </div>
                  <div class="task--colors">
                    <div class="task--color--correct" />
                    {{ toLocal('training_edit.correct') }}
                    <div class="task--color--incorrect" />
                    {{ toLocal('training_edit.incorrect') }}
                  </div>
                </div>
                <div 
                  v-if="question.type == 2"
                  :class="['question--settings', {'expanded': question.expanded}]"
                >
                <div class="image-size-action">
                  <svg v-if="question.size == 0" @click="$set(question, 'size', 1); editQuestionRequest(question)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_2057" data-name="Rectangle 2057" width="19.022" height="19.023" transform="translate(0 0)" fill="#28345c"/>
                      </clipPath>
                    </defs>
                    <g id="Group_6888" data-name="Group 6888" transform="translate(-723 -1408)">
                      <circle id="Ellipse_460" data-name="Ellipse 460" cx="15" cy="15" r="15" transform="translate(723 1408)" fill="#ffdb93"/>
                      <g id="Group_6634" data-name="Group 6634" transform="translate(728.568 1413.568)">
                        <g id="Group_6634-2" data-name="Group 6634" transform="translate(0 0)" clip-path="url(#clip-path)">
                          <path id="Path_5898" data-name="Path 5898" d="M16.77,2.253a7.7,7.7,0,0,0-11.87,9.7L.449,16.407a1.532,1.532,0,1,0,2.167,2.167l4.451-4.451a7.7,7.7,0,0,0,9.7-11.87m-.908,9.987a6.417,6.417,0,1,1,0-9.08,6.428,6.428,0,0,1,0,9.08" transform="translate(0 0)" fill="#28345c"/>
                          <path id="Path_5899" data-name="Path 5899" d="M29.006,14.061h-1.5v2.5h-2.5v1.5h2.5v2.5h1.5v-2.5h2.5v-1.5h-2.5Z" transform="translate(-16.978 -9.547)" fill="#28345c"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <svg v-else @click="$set(question, 'size', 0); editQuestionRequest(question)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <g id="Group_6601" data-name="Group 6601" transform="translate(-723 -1408)">
                      <circle id="Ellipse_460" data-name="Ellipse 460" cx="15" cy="15" r="15" transform="translate(723 1408)" fill="#ffdb93"/>
                      <g id="Group_6574" data-name="Group 6574" transform="translate(728.607 1413.634)">
                        <path id="Path_5896" data-name="Path 5896" d="M16.727,2.248a7.685,7.685,0,0,0-11.84,9.678L.448,16.366a1.528,1.528,0,1,0,2.161,2.161l4.439-4.44a7.685,7.685,0,0,0,9.679-11.84m-.906,9.962a6.4,6.4,0,1,1,0-9.056,6.412,6.412,0,0,1,0,9.056" transform="translate(0 -0.001)" fill="#28345c"/>
                        <rect id="Rectangle_2044" data-name="Rectangle 2044" width="6.489" height="1.492" transform="translate(8.007 7)" fill="#28345c"/>
                      </g>
                    </g>
                  </svg>
                </div>
                  <div class="task--images">
                    <div 
                      v-for="(answer, answerIndex) in question.answers"
                      :key="getFileUrl(answer.image)"
                      
                      class="task--image"
                      :class="{'big': question.size == 1}"
                    >
                      <div 
                        :class="['task--answer--letter', {'active': answer.correct}]"
                        @click=" testIsLocked ? '' : answer.correct = !answer.correct; editAnswerRequest(answer)"
                      >
                        {{ String.fromCharCode(65 + answerIndex) }}
                      </div>
                       <div 
                        :class="['task--answer--delete']"
                        v-if="answer.image && question.size == 1"
                        @click="deleteQuestionAnswer(question, answerIndex)"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30.413" height="38.743" viewBox="0 0 30.413 38.743">
                          <g id="Group_1474" data-name="Group 1474" transform="translate(0 0)">
                            <g id="Group_291" data-name="Group 291" transform="translate(0 0)">
                              <path id="Rectangle_184" data-name="Rectangle 184" d="M6927.982,1771.573h24.783v22.969a8.068,8.068,0,0,1-8.051,8.059h-8.673a8.068,8.068,0,0,1-8.06-8.059Zm23.186,1.6h-21.589v21.371a6.47,6.47,0,0,0,6.463,6.462h8.673a6.469,6.469,0,0,0,6.453-6.462Z" transform="translate(-6925.251 -1763.857)" fill="#28345c"/>
                              <path id="Path_550" data-name="Path 550" d="M6950.63,1764.2c.071,0,.144,0,.214.008a3.088,3.088,0,0,1,2.867,3.292,3.1,3.1,0,0,1-3.077,2.875c-.062,0-.125,0-.186-.006h-24.055a3.082,3.082,0,1,1-.009-6.163h24.063C6950.508,1764.206,6950.569,1764.2,6950.63,1764.2Zm0,4.577a1.49,1.49,0,0,0,.1-2.976,1.469,1.469,0,0,0-.2,0l-.058,0h-24.088a1.484,1.484,0,1,0,.009,2.969l24.135,0Q6950.581,1768.781,6950.634,1768.781Z" transform="translate(-6923.306 -1761.056)" fill="#28345c"/>
                              <path id="Path_551" data-name="Path 551" d="M6949.862,1763.87h-11.08l-.2-.5a3.093,3.093,0,0,1,2.854-4.242h5.761a3.094,3.094,0,0,1,2.866,4.241Zm-9.9-1.6h8.727q0-.03,0-.06a1.491,1.491,0,0,0-1.489-1.49h-5.759a1.493,1.493,0,0,0-1.481,1.49C6939.958,1762.233,6939.958,1762.253,6939.958,1762.273Z" transform="translate(-6929.203 -1759.126)" fill="#28345c"/>
                              <g id="Group_290" data-name="Group 290" transform="translate(8.694 12.509)">
                                <path id="Line_35" data-name="Line 35" d="M.3,19.49a.8.8,0,0,1-.8-.8V.3A.8.8,0,1,1,1.1.3V18.691A.8.8,0,0,1,.3,19.49Z" transform="translate(0.5 0.5)" fill="#28345c"/>
                                <path id="Line_36" data-name="Line 36" d="M.3,19.49a.8.8,0,0,1-.8-.8V.3A.8.8,0,1,1,1.1.3V18.691A.8.8,0,0,1,.3,19.49Z" transform="translate(6.129 0.5)" fill="#28345c"/>
                                <path id="Line_37" data-name="Line 37" d="M.3,19.49a.8.8,0,0,1-.8-.8V.3A.8.8,0,1,1,1.1.3V18.691A.8.8,0,0,1,.3,19.49Z" transform="translate(11.759 0.5)" fill="#28345c"/>
                              </g>
                            </g>
                          </g>
                        </svg>

                      </div>
                      <input 
                            type="file" 
                            style="display:none"
                            @change="onTrainingMediaChange($event,question)"
                          >
                        <ImageEditor
                        v-if="(answer.metadata ? answer.metadata.data : false)"
                          :height="question.size == 1 ? 416.25: 200"
                          :width="question.size == 1 ? 740 : 200"
                          :initial="getFileUrl(answer.image)" 
                          :metadata="answer.metadata"
                          :metadataString="answer.metadata ? answer.metadata.data : ''"
                          :readOnly="testIsLocked"
                          @saveMetadata="saveMetadata($event, answer)"
                          @deleteImage="deleteQuestionAnswer(question, answerIndex)"
                         >
                          <template v-slot:big-icon>
                            <img 
                              class="choose-file-big"
                              src="@/assets/images/icons/training-item-remove.svg"
                              @click="deleteQuestionAnswer(question, answerIndex)"
                            >
                          </template>
                        </ImageEditor>
                         <NewImageEditor 
                            v-if="(answer.metadata ? !answer.metadata.data : true)"
                            :readOnly="false"
                            :item="answer"
                            type="cover"
                            :height="question.size == 1 ? 416.25: 200"
                            :isEditing="true"
                            :src="getFileUrl(answer.image)"
                            @deleteImage="deleteQuestionAnswer(question, answerIndex)"
                            @saveMetadata="saveMetadata($event, answer)"
                        />

                    </div>
                    <div class="add--image task--image" :class="{'big':  question.size == 1}">
                      <div :class="['uploading', (question.uploading ? 'active': '')]">
                        <div 
                          class="upload--progress"
                          :style="[{'width': `${$store.state.uploadedData / question.file_size * 100}%`}]" 
                        />
                      </div>
                             
                      <input 
                        :ref="`trainingItemFile-${topicIndex}-${questionIndex}-${question.answers.length}`" 
                        accept=".jpeg,.jpg,.png"
                        type="file"
                        style="display: none;"
                        :disabled="testIsLocked"
                        @change="onTrainingMediaChange($event,question)"
                      >
                      <div class="media--action">
                        <img 
                          src="@/assets/images/icons/training-item-upload.svg"
                          @click="testIsLocked ? '' : $refs[`trainingItemFile-${topicIndex}-${questionIndex}-${question.answers.length}`][0].click()" 
                        >
                      </div>
                      <!-- <div class="media--action">
                        <img
                          src="@/assets/images/icons/training-item-gallery.svg"
                        >
                      </div> -->
                    </div>
                  </div>
                  <div class="task--colors">
                    <div class="task--color--correct" />
                    {{ toLocal('training_edit.correct') }}
                    <div class="task--color--incorrect" />
                    {{ toLocal('training_edit.incorrect') }}
                  </div>
                </div>
                <div 
                  v-if="question.type == 3"
                  :class="['question--settings', {'expanded': question.expanded}]"
                >
                  <div 
                    class="add--task--answer"
                    @click="addQuestionAnswer(topicIndex, questionIndex, question)"
                  >
                    <img src="@/assets/images/icons/plus-gray.svg">
                    {{ toLocal('hierarchy.add') }}
                  </div>
                  <div
                    class="task--connects"
                    @mouseover="$emit('disableDrag1', true)"
                    @mouseout="$emit('disableDrag1', false)"
                  >
                    <draggable
                      v-model="question.answers"
                      @change="saveAnswerPosition($event, question.answers)"
                      :options="{disabled: testIsLocked }"
                      animation="200"
                    >
                      <transition-group type="transition">
                        <div 
                          v-for="(answer, answerIndex) in question.answers"
                          :key="`${answer.part1}-${answerIndex}`"
                          class="task--connect"
                        >
                          <div 
                            v-if="currentQuestionAnswerEditing[0] != topicIndex || currentQuestionAnswerEditing[1] != questionIndex || currentQuestionAnswerEditing[2] != answerIndex || currentQuestionAnswerEditing[3] != 0"
                            class="task--connect--part1"
                            @click="editQuestionAnswer(topicIndex, questionIndex, question, answerIndex, 0)"
                          >
                            <div class="text-wrapper">
                              <div>
                                {{ answer.part1 }}
                              </div>
                            </div>
                          </div>
                          <textarea
                            v-if="currentQuestionAnswerEditing[0] == topicIndex && currentQuestionAnswerEditing[1] == questionIndex && currentQuestionAnswerEditing[2] == answerIndex && currentQuestionAnswerEditing[3] == 0"
                            :ref="`questionAnswer-${topicIndex}-${questionIndex}-${answerIndex}-0`"
                            v-click-outside-editing-title="undefined"
                            class="training--content--topic--edit task--connect--text--edit part--1"
                            type="text"
                            @keyup.enter="setQuestionAnswer(question, answerIndex, 0, $event.srcElement.value)"
                          />
                          <div 
                            v-if="currentQuestionAnswerEditing[0] != topicIndex || currentQuestionAnswerEditing[1] != questionIndex || currentQuestionAnswerEditing[2] != answerIndex || currentQuestionAnswerEditing[3] != 1"
                            class="task--connect--part2"
                            @click="editQuestionAnswer(topicIndex, questionIndex, question, answerIndex, 1)"
                          >
                            <div class="text-wrapper">
                              <div>
                                {{ answer.part2 }}
                              </div>
                            </div>
                          </div>
                          <textarea
                            v-if="currentQuestionAnswerEditing[0] == topicIndex && currentQuestionAnswerEditing[1] == questionIndex && currentQuestionAnswerEditing[2] == answerIndex && currentQuestionAnswerEditing[3] == 1"
                            :ref="`questionAnswer-${topicIndex}-${questionIndex}-${answerIndex}-1`"
                            v-click-outside-editing-title="undefined"
                            class="training--content--topic--edit task--connect--text--edit part--2"
                            type="text"
                            @keyup.enter="setQuestionAnswer(question, answerIndex, 1, $event.srcElement.value)"
                          />
                          <svg @click="deleteQuestionAnswer(question, answerIndex)" class="stroke" xmlns="http://www.w3.org/2000/svg" width="20.317" height="25.807" viewBox="0 0 20.317 25.807">
                            <g id="Group_308" data-name="Group 308" transform="translate(-7004.16 -1767.753)">
                              <path id="Rectangle_184" data-name="Rectangle 184" d="M0,0H14.988a0,0,0,0,1,0,0V13A6.028,6.028,0,0,1,8.96,19.023H6.028A6.028,6.028,0,0,1,0,13V0A0,0,0,0,1,0,0Z" transform="translate(7006.825 1773.636)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="1.8"/>
                              <path id="Path_550" data-name="Path 550" d="M7023.577,1775.469a1.48,1.48,0,0,1-1.476,1.476h-15.566a1.476,1.476,0,1,1,0-2.951H7022.1A1.474,1.474,0,0,1,7023.577,1775.469Z" transform="translate(0 -3.309)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="1.8"/>
                              <path id="Path_551" data-name="Path 551" d="M7027.308,1770.129a1.489,1.489,0,0,1-.106.555h-6.465a1.48,1.48,0,0,1-.107-.555,1.481,1.481,0,0,1,1.476-1.476h3.723A1.478,1.478,0,0,1,7027.308,1770.129Z" transform="translate(-9.649 0)" fill="none" stroke="#707070" stroke-miterlimit="10" stroke-width="1.8"/>
                              <g id="Group_290" data-name="Group 290" transform="translate(7010.68 1776.735)">
                                <line id="Line_35" data-name="Line 35" y2="11.889" transform="translate(7.278)" fill="none" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.8"/>
                                <line id="Line_36" data-name="Line 36" y2="11.889" transform="translate(3.639)" fill="none" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.8"/>
                                <line id="Line_37" data-name="Line 37" y2="11.889" fill="none" stroke="#707070" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.8"/>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
                <div 
                  v-if="question.type == 4"
                  :class="['question--settings', {'expanded': question.expanded}]"
                >
                  <div class="open-task-container">
                    <editor 
                        v-if="question.answers && question.answers[0] && question.answers[0].text != undefined"
                        :class="['test-task-editor']"
                        :isEditing="true"
                        :readOnly="true"
                        :showCharInfo="true"
                        :charLimit="1000"
                        :imagesAllowed="false"
                        :placeholder="toLocal('test.enter_answer')"
                        v-model="question.answers[0].text"
                        @input="saveOpenQuestionAnswerInput(question.answers[0])"
                      />
                  </div>
                </div>
                <div 
                  v-if="question.type == 5"
                  :class="['question--settings', 'type-5', {'expanded': question.expanded}]"
                >
                <transition type="transition">
                  <div class="open-task-container" >
                    <ClickTask
                    :key="question.id"
                    :question="question" 
                    :answer="question.answers[0]"
                    :readOnly="false"
                    :statsMode="false"
                    :isLocked="testIsLocked"
                    @saveAnswer="editAnswerRequest(question.answers[0])"
                    />
                  </div>
                </transition>
                </div>
              </div>
              <div 
                :class="['media--editing task--editing to--side', ((!question.type || question.type == -1) ? 'full--editing narrow--editing' : 'side--editing')]"
              >
                <div 
                  @click="testIsLocked ? '' : removeQuestion(topicIndex, questionIndex)" 
                  class="media--action"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.155" height="21.68" viewBox="0 0 17.155 21.68">
                    <g id="Group_5641" data-name="Group 5641" transform="translate(-1665.725 -3646.525)">
                      <g id="Group_291" data-name="Group 291" transform="translate(1666.475 3647.275)">
                        <path id="Rectangle_184" data-name="Rectangle 184" d="M6941.08,1772.073h-12.6v12.044a3.945,3.945,0,0,0,3.945,3.944h4.711a3.944,3.944,0,0,0,3.941-3.944v-12.044Z" transform="translate(-6926.999 -1767.881)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                        <path id="Path_550" data-name="Path 550" d="M6923.807,1765.944a1.243,1.243,0,0,0,1.243,1.243h13.082a1.243,1.243,0,1,0,.171-2.481,1.21,1.21,0,0,0-.171,0H6925.05a1.239,1.239,0,0,0-1.243,1.235Z" transform="translate(-6923.807 -1762.994)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                        <path id="Path_551" data-name="Path 551" d="M6938.86,1760.869a1.251,1.251,0,0,0,.09.467h5.432a1.27,1.27,0,0,0,.089-.467,1.243,1.243,0,0,0-1.243-1.243H6940.1A1.243,1.243,0,0,0,6938.86,1760.869Z" transform="translate(-6933.885 -1759.626)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                        <g id="Group_290" data-name="Group 290" transform="translate(4.723 6.796)">
                          <line id="Line_35" data-name="Line 35" y2="9.992" transform="translate(0 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                          <line id="Line_36" data-name="Line 36" y2="9.992" transform="translate(3.058 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                          <line id="Line_37" data-name="Line 37" y2="9.992" transform="translate(6.117 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <!-- <div 
                  @click="testIsLocked ? '' : question.enabled = !question.enabled" 
                  :class="['media--action', {'question--off' : !question.enabled}]"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.805" height="16.978" viewBox="0 0 14.805 16.978">
                    <g id="Group_5667" data-name="Group 5667" transform="translate(-1057.438 -373)">
                      <g id="Group_748" data-name="Group 748" transform="translate(1058.438 374)">
                        <ellipse id="Ellipse_31" data-name="Ellipse 31" cx="6.403" cy="6.403" rx="6.403" ry="6.403" transform="translate(0 2.172)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                        <line id="Line_34" data-name="Line 34" y2="6.392" transform="translate(6.403)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>
                </div> -->
                <div
                  @click=" question.expanded ? $set(question,'expanded', false) : $set(question,'expanded', true)"
                  :class="['media--action', {'expanded' : question.expanded}]"
                >
                  <svg v-if="!question.expanded" xmlns="http://www.w3.org/2000/svg" width="21.345" height="21.346" viewBox="0 0 21.345 21.346">
                    <g id="Group_5627" data-name="Group 5627" transform="translate(-4.526 -4.526)">
                      <g id="Group_3728" data-name="Group 3728" transform="translate(6.233 20.435) rotate(-45)">
                        <g id="Group_3727" data-name="Group 3727" transform="translate(0 0)">
                          <line id="Line_95" data-name="Line 95" x1="8.34" y1="0.093" transform="translate(0 2.582)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                          <path id="Path_2829" data-name="Path 2829" d="M2.425,5.274.184,3.034a.629.629,0,0,1,0-.89L2.328,0" transform="translate(0 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                      </g>
                      <g id="Group_5626" data-name="Group 5626" transform="translate(24.165 9.963) rotate(135)">
                        <g id="Group_3727-2" data-name="Group 3727">
                          <line id="Line_95-2" data-name="Line 95" x1="8.34" y1="0.093" transform="translate(0 2.582)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                          <path id="Path_2829-2" data-name="Path 2829" d="M2.425,5.274.184,3.034a.629.629,0,0,1,0-.89L2.328,0" transform="translate(0 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <svg v-if="question.expanded" xmlns="http://www.w3.org/2000/svg" width="17.116" height="17.14" viewBox="0 0 17.116 17.14">
                    <g id="Group_5627" data-name="Group 5627" transform="translate(-6.641 -6.629)">
                      <g id="Group_3728" data-name="Group 3728" transform="translate(15.86 18.268) rotate(135)">
                        <g id="Group_3727" data-name="Group 3727" transform="translate(0 0)">
                          <line id="Line_95" data-name="Line 95" x1="8.34" y1="0.093" transform="translate(0 2.582)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                          <path id="Path_2829" data-name="Path 2829" d="M2.425,5.274.184,3.034a.629.629,0,0,1,0-.89L2.328,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                      </g>
                      <g id="Group_5626" data-name="Group 5626" transform="translate(14.538 12.13) rotate(-45)">
                        <g id="Group_3727-2" data-name="Group 3727">
                          <line id="Line_95-2" data-name="Line 95" x1="8.34" y1="0.093" transform="translate(0 2.582)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                          <path id="Path_2829-2" data-name="Path 2829" d="M2.425,5.274.184,3.034a.629.629,0,0,1,0-.89L2.328,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        <div 
            v-if="topic.test && test"
            :class="['test--question--add--select']"
          >
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 1, false)"
              >
                <div class="img-container">
                  <img 
                    src="@/assets/images/icons/task-text.svg"
                  >
                </div>
                <span>
                  {{toLocal('training_edit.test_question_type_1')}}

                </span>
              </div>
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 2, false)"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/task-image.svg"
                >
              </div>
                <span>
                 {{toLocal('training_edit.test_question_type_2')}}
                </span>
              </div>
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 3, false)"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/task-connect.svg"
                >
              </div>
              <span>
                {{toLocal('training_edit.test_question_type_3')}}
              </span>
              </div>
              <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 4, false)"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/open_question.svg"
                >
              </div>
                <span>
                  {{toLocal('training_edit.test_question_type_4')}}
                </span>
              </div>
               <div 
                class="media--action"
                @click="addNewQuestion(topicIndex, 5, false)"
              >
              <div class="img-container big">
                <img 
                  src="@/assets/images/icons/clickTask.svg"
                >
              </div>
                <span>
                {{toLocal('training_edit.test_question_type_5')}}
                </span>
              </div>
               <div 
                class="media--action upload"
                @click="importTestQuestions('bottom')"
              >
              <div class="img-container">
                <img 
                  src="@/assets/images/icons/importQuestions.svg"
                >
              </div>
                <span>
                  {{toLocal('test.upload_test')}}
                </span>
              </div>
          </div>
      <ImportModal  @test="$set(test, 'questions', $event.questions)"/>
  </div>

</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import ImageEditor from "@/components/layout/trainings/ImageEditor.vue";
import _ from "lodash"
import ImportModal from '@/components/layout/ImportModal.vue'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import ClickTask from '@/components/layout/trainings/training_components/ClickTask.vue'
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";


const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  integerLimit: 2,
  

});
export default {
    name: 'TestOptionTabContent',
    components:{
        Editor,
        draggable,
        ImageEditor,
        ImportModal,
        ClickTask,
        NewImageEditor
    },
    data(){
        return{
            currentTaskEditing: [-1, -1],
            currentTaskAnswerEditing: [-1, -1, -1, -1],
            currentQuestionEditing: [-1, -1],
            currentQuestionAnswerEditing: [-1, -1, -1, -1],
            currentlyEditedTestItem: [-1, -1],
            disableItemDrag2: false,
            saveChanges: [],
            defaultScore: -1,
            numberMask
        }
    },
     props:{
       content: {
            type: Array,
            required: true,
        },
        topic: {
            type: Object,
            required: true
        },
        test:{
            type: Object,
            required: true
        },
        parentTest:{
            type: Object,
            required: true
        },
        topicIndex: {
            type: Number,
            required: true
        },
        availableTestQuestions: {
          type: Array,
            required: true
        },
    },
    computed:{
      testIsLocked(){
        return this.test.locked === 1 || (this.parentTest.options && this.parentTest.options.active_version_id == this.test.id && (this.parentTest.versions && this.parentTest.versions.length >0) && this.topic.questions == this.parentTest.id)
      }
    },

    mounted(){
      let self = this;
      this.$root.$on('update-max-score', function(score){
        // if(self.test && self.test.questions){
        //  self.test.questions.forEach(question=>{
        //     if(question.score < score){
        //       self.$set(question, 'score', score);
        //       self.editQuestionRequest(question);
        //     }
        //   })
        // }
        self.defaultScore = score;
      })
    },
    methods:{
        onTrainingMediaChange(event, question){
           if(this.testIsLocked) return;
            let file;
            if(event.size){
              file = event;
            }
            else{
              let files = event.target.files || event.dataTransfer.files;
              if (!files.length)
                  return;
              file = files[0];
            }
            this.$store.state.uploadedData = 0;
            this.$set(question, 'file_size', file.size);
            this.$set(question, 'uploading', true);
            let formData = new FormData();
                  formData.append('file', file);
            axios.post(
              `${this.$store.state.api_link}/upload-file`,
              formData,
              this.$store.getters.axiosConfigMultipart()
          ).then((response) => {
                  let answers = question.answers ? question.answers : [];
                  let weights = answers.length > 0 ? answers.map((el)=> el.weight ? el.weight : 0) : 0;
                  let weight = 0;
                  if(weights != 0) weight = Math.max(0, ...weights)+1;
                let answer = {
                  id:null,
                  image: response.data.file,
                  metadata:[],
                  correct: false,
                  weight: weight,
                  question_id: question.id
                }
                question.answers.push(answer);
                this.$set(question, 'uploading', false);

                this.addNewAnswerRequest(answer);
          })
          .catch(err =>{
            console.log(err);
          })
            // this.$emit('on-training-media-change1', event, topicIndex, questionIndex, anwerIndex);
        },
        saveMetadata(event, answer){
            // this.$emit('save-metadata', event, answer);
            if(this.testIsLocked) return;
            let metadata= event;
            answer.metadata = metadata;
            //this.saveRequestQueue.push({function: this.editAnswerRequest, params: answer})
            this.editAnswerRequest(answer);
        },
        replaceQuestionLength(q_len){
          return this.toLocal('training_edit.test_questions_out_of').replace('<question_length>', q_len);
        },
        addNewQuestion (testIndex, type, top = false) {
            this.test.addingQuestion = false;
          if(this.testIsLocked) return;
            setTimeout(() => {
              let questions =this.test.questions;
              let weights = questions.length > 0 ? questions.map((el)=> el.weight ? el.weight : 0) : [0];
                let weight = Math.max(0, ...weights)+1;
                let newItem = {
                question: "-",
                weight: weight,
                test_id: this.test.id,
                type: type,
                enabled: true,
                editing: false,
                expanded: true,
                size: 0,
                score: this.defaultScore != -1 ? this.defaultScore : this.test.options.testQuestionValue,
                answers: [],
                };


                this.test.questions.forEach(question => {
                    this.$set(question, 'expanded', false);
                });

                let toIndex = this.test.questions.length;
                if(top) {
                    toIndex = 0;
                }

                 this.$set(this.test, 'questions', this.arrayInsert(this.test.questions, toIndex, newItem));
                this.$set(this.test.questions[toIndex], 'question', "<p>" + this.toLocal('training_edit.new_question') + "</p>");

                if(type == 1) {
                    let answers = [
                        {
                        text: this.toLocal('training_edit.answer_yes'),
                        correct: false,
                        id: 1,
                        },
                        {
                        text: this.toLocal('training_edit.answer_no'),
                        correct: false,
                        id: 2
                        },
                    ];
                    this.$set(this.test.questions[toIndex], 'answers', answers);
                }
                if(type == 2) {
                    this.$set(this.test.questions[toIndex], 'answers', []);
                }
                if(type == 3) {
                  let answers = [
                      {
                      id:1,
                      part1: this.toLocal('generic.question'),
                      part2: this.toLocal('generic.answer'),
                      }
                  ];
                  this.$set(this.test.questions[toIndex], 'answers', answers);
                }
                if(type == 4){
                  let answers = [
                      {
                        id:1,
                        text: ''
                      }
                  ];
                  // if(!this.hasAcceptedModal)
                  //   this.taskInfoModalOpen = true;
                  this.$root.$emit("testInfoModal", true);
                  this.$set(this.test.questions[toIndex], 'answers', answers);
                }
                if(type == 5){
                   let answers = [
                      {
                        id:1,
                        text: ''
                      }
                  ];
                  this.$set(this.test.questions[toIndex], 'answers', answers);
                }
                setTimeout(() => {
                // this.handleScroll();
                if(top) {
                    this.editQuestionItem (testIndex, 0, this.test.questions[0]);
                }else{
                    this.editQuestionItem (testIndex, this.test.questions.length-1, this.test.questions[this.test.questions.length-1]);
                }
                }, 100);
                this.test.testShownQuestionCount = this.test.questions.length;

                this.addNewQuestionRequest(newItem).then(()=>{
                  if(top){
                    this.saveQuestionPosition({},this.test.questions );
                  }
                  this.$store.commit('setSelectedTest', this.test);           
                });
            },10)
        },
        addNewQuestionRequest(question){
           if(this.testIsLocked) return;
          return axios.post(`${this.$store.state.api_link}/test-question-groups/crud-question`,
            {data: question, action: 'add', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
               Object.assign(question, res.data.question);
            // if(res.data.deleted)
            //   this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.success_remove_item'));
            })
            .catch((err)=>{
            });
        },
        editQuestionRequest(question){
           if(this.testIsLocked) return;
          axios.post(`${this.$store.state.api_link}/test-question-groups/crud-question`,
            {data: question, action: 'edit', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
               Object.assign(question, res.data.question);
            })
            .catch((err)=>{
            });
        },
        deleteQuestionRequest(question){
           if(this.testIsLocked) return;
          return axios.post(`${this.$store.state.api_link}/test-question-groups/crud-question`,
            {data: question, action: 'delete', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
              // this.$set(question, 'id', res.data.question.id);
            // if(res.data.deleted)
            //   this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.success_remove_item'));
            })
            .catch((err)=>{
            });
        },
        addNewAnswerRequest(answer){
           if(this.testIsLocked) return;
           return axios.post(`${this.$store.state.api_link}/test-question-groups/crud-answer`,
            {data: answer, action: 'add', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
               if(answer.text != res.data.answer.text){
                this.$set(answer, 'id', res.data.answer.id);
                this.editAnswerRequest(answer);
              }
              else{
                Object.assign(answer, res.data.answer);
              }
            // if(res.data.deleted)
            //   this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.success_remove_item'));
            })
            .catch((err)=>{
            });
        },
        editAnswerRequest(answer){
           if(this.testIsLocked) return;
          let initial = answer;
          return axios.post(`${this.$store.state.api_link}/test-question-groups/crud-answer`,
            {data: answer, action: 'edit', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
              if(answer.text != res.data.answer.text){
                // this.$set(answer, 'id', res.data.answer.id);
                this.editAnswerRequest(answer);
              }
              else{
                Object.assign(answer, res.data.answer);
              }
            })
            .catch((err)=>{
              Object.assign(answer, initial);
            });
        },
        deleteAnswerRequest(answer){
           if(this.testIsLocked) return;
          axios.post(`${this.$store.state.api_link}/test-question-groups/crud-answer`,
            {data: answer, action: 'delete', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
            // if(res.data.deleted)
            //   this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.success_remove_item'));
            })
            .catch((err)=>{
            });
        },
        arrayInsert: (arr, index, ...newItems) => [
          ...arr.slice(0, index),
          ...newItems,
          ...arr.slice(index)
        ],
         editQuestionItem (testIndex, questionIndex, question) {
            if(this.testIsLocked) return;
            if(question.editing)
                return;
            if(this.currentQuestionEditing[0] != -1 ){
                let arr = this.currentQuestionEditing;
                let item = this.test.questions[arr[1]];
                this.$set(item, 'editing', false);
            }

            setTimeout(() => {
                this.currentQuestionEditing = [testIndex, questionIndex];
                let item = this.test.questions[questionIndex];
                this.currentlyEditedTestItem= [testIndex, questionIndex];
                this.$set(item, 'editing', true);

            }, 10);
        },
        deleteQuestionAnswer(question, answerIndex) {
           if(this.testIsLocked) return;
            this.deleteAnswerRequest(question.answers[answerIndex]);
             question.answers.splice(answerIndex, 1);
        },
        editQuestionAnswer(testIndex, questionIndex, question, answerIndex, partIndex) {
           if(this.testIsLocked) return;
            if(this.currentQuestionAnswerEditing[0] == testIndex && this.currentQuestionAnswerEditing[1] == questionIndex && this.currentTaskAnswerEditing[2] == answerIndex && this.currentTaskAnswerEditing[3] == partIndex)
                return;

            setTimeout(() => {
                this.currentQuestionAnswerEditing = [testIndex, questionIndex, answerIndex, partIndex];

                setTimeout(() => {
                let title = question.answers[answerIndex].text;
                if(partIndex == 0) {
                    title = question.answers[answerIndex].part1;
                }else if(partIndex == 1) {
                    title = question.answers[answerIndex].part2;
                }
                if(partIndex >= 0) {
                    this.$refs[`questionAnswer-${testIndex}-${questionIndex}-${answerIndex}-${partIndex}`][0].value = title;
                    this.$refs[`questionAnswer-${testIndex}-${questionIndex}-${answerIndex}-${partIndex}`][0].focus();
                    this.$refs[`questionAnswer-${testIndex}-${questionIndex}-${answerIndex}-${partIndex}`][0].select();
                } else {
                    this.$refs[`questionAnswer-${testIndex}-${questionIndex}-${answerIndex}`][0].value = title;
                    this.$refs[`questionAnswer-${testIndex}-${questionIndex}-${answerIndex}`][0].focus();
                    this.$refs[`questionAnswer-${testIndex}-${questionIndex}-${answerIndex}`][0].select();
                }
                }, 10);
            }, 10);
        },
        setQuestionAnswer(question, answerIndex, partIndex, topicTitle) {
           if(this.testIsLocked) return;
            if(topicTitle == "")
                topicTitle = "-";
            if(partIndex == 0) {
                question.answers[answerIndex].part1 = topicTitle.replace(/[\n\r\t]/g,'');
            }else if(partIndex == 1) {
                question.answers[answerIndex].part2 = topicTitle.replace(/[\n\r\t]/g,'');
            } else {
                question.answers[answerIndex].text = topicTitle.replace(/[\n\r\t]/g,'');
            }
            this.currentQuestionAnswerEditing = [-1, -1, -1, -1];
            //this.saveRequestQueue.push({function: this.editAnswerRequest, params: question.answers[answerIndex]})
             this.editAnswerRequest(question.answers[answerIndex]);

        },
        addQuestionAnswer(testIndex, questionIndex, question) {
           if(this.testIsLocked) return;
            if(question.answers.length >= 26)
                return;
            let item = {};
            let answers = question.answers;
            let weights = answers.length > 0 ? answers.map((el)=> el.weight ? el.weight : 0) : 0;
             let weight = 1;
            if(Array.isArray(weights))
              weight = Math.max(0, ...weights)+1;
            if(question.type == 1){
                var id_list = question.answers.map(el=>el.id);
                let id = id_list.length > 0 ? Math.max(1, ...id_list) + 1 : 1;
                item = {
                  id: id,
                  text: this.toLocal('training_edit.answer_empty'),
                  weight: weight,
                  correct: false,
                  question_id: question.id,
                }
                question.answers.push(item);
                this.editQuestionAnswer(testIndex, questionIndex, question, question.answers.length-1, -1);
            }
            if(question.type == 3){
                var id_list = question.answers.map(el=>el.id);
                let id = id_list.length > 0 ? Math.max(1, ...id_list) + 1 : 1;
                item = {
                  id: id,
                  text: this.toLocal('training_edit.answer_empty'),
                  weight: weight,
                  correct: false,
                  part1: this.toLocal('generic.question'),
                  part2: this.toLocal('generic.answer'),
                  question_id: question.id,
                }
                question.answers.push(item)
            }
            //this.saveRequestQueue.push({function: this.addNewAnswerRequest, params: question.answers[question.answers.length-1]});
            this.addNewAnswerRequest(question.answers[question.answers.length-1]);
        },
        removeQuestion (testIndex, questionIndex) {
           if(this.testIsLocked) return;
          //this.saveRequestQueue.push({function: this.deleteQuestionRequest, params: this.test.questions[questionIndex]})
          this.deleteQuestionRequest(this.test.questions[questionIndex]);
          this.test.questions.splice(questionIndex, 1);
          this.test.testShownQuestionCount = this.test.questions.length;

        },
        setQuestionAnswerRaw(testIndex, questionIndex, answerIndex, partIndex, topicTitle) {
           if(this.testIsLocked) return;
            this.setQuestionAnswer(this.test.questions[questionIndex], answerIndex, partIndex, topicTitle);
        },
        deleteQuestionConnect (item, answerIndex) {
           if(this.testIsLocked) return;
           
            item.answers.splice(answerIndex, 1);
        },
        endTestEditing(item){
           if(this.testIsLocked) return;
           if(this.$store.state.showingTiptapUploadModal) return;
          if(this.currentQuestionEditing[0] == -1)
            return;
          this.$set(item,'editing',false);
          this.editQuestionRequest(item);
          this.disableItemDrag = false;
        },
        saveAnswerPosition(e, answers){
           if(this.testIsLocked) return;
          let new_order = answers.map(el=>el.id);
          axios.post(`${this.$store.state.api_link}/test-question-groups/crud-answer`,
            {order: new_order, action: 'edit', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
            })
            .catch((err)=>{
            });
        },
         saveQuestionPosition(e, questions){
            if(this.testIsLocked) return;
          let new_order = questions.map(el=>el.id);
          axios.post(`${this.$store.state.api_link}/test-question-groups/crud-question`,
            {order: new_order, action: 'edit', training: this.$store.state.currentTraining},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
            })
            .catch((err)=>{
            });
        },
        saveOpenQuestionAnswerInput(answer) {
           if(this.testIsLocked) return;
            this.saveOpenQuestionAnswer(answer, this);
        },
        saveOpenQuestionAnswer: _.debounce((answer, self) =>{
          //self.saveRequestQueue.push({function: self.editAnswerRequest, params: answer})
         self.editAnswerRequest(answer);
        }, 3000),
        saveTestInput: _.debounce((e) =>{
           if(this.testIsLocked) return;
          this.saveTest(e);
        },200),
        saveTest(e){
           if(this.testIsLocked) return;
          let self = this;
          // const deb =  _.debounce(() =>{
            axios.post(`${self.$store.state.api_link}/test-question-groups/edit`,
              {data: e},
              self.$store.getters.axiosConfig()
              ).then((res)=>{
              })
              .catch((err)=>{
              });
          // },2000)
          // deb();
        },
        inputNumbersOnlyInput(e, question){
          let start = e.target.selectionStart;
          if(e.key == ',') this.$set(question, 'score', [question.score.slice(0, start) + '.' + question.score.slice(start)].join(''));

        },
        inputNumbersOnly(e, question){
          // let input = e.target.value;
          // this.$set(question, 'score', input);
          this.saveQuestionDataDebounce(question, this);
          // let text = parseInt(e.target.value);
        },
        saveQuestionDataDebounce: _.debounce((question, self) =>{
          if(self.testIsLocked) return;
          self.editQuestionRequest(question);
        }, 300),
        importTestQuestions(position){
          if(this.testIsLocked) return;
          this.$store.state.importModal.setData({
            training_id: this.$store.state.currentTraining,
            test_id: this.test.id,
            position: position
            });
          this.$store.state.importModal.toggleModal(true, 'test');
        }
    },
    directives:{
        'click-outside-editing-title': {
            bind: function (el, binding, vnode) {
                let hasSomeParentWithClass = function (target, selector) {
                return [...document.querySelectorAll(selector)].some(el =>
                    el !== target && el.contains(target)
                )
                }
                el.clickOutsideEvent = function (event) {
                if (!(el == event.target || el.contains(event.target))) {
                    if(vnode.data.ref == "trainingTitle"){
                    vnode.context.setTrainingTitle(vnode.elm.value);
                    return;
                    }
                    if(vnode.data.ref == "trainingDescription") {
                    if(hasSomeParentWithClass(event.target, ".menububble"))
                        return;
                    vnode.context.setTrainingDescription(null);
                    return;
                    }
                    if(vnode.data.ref == "trainingTime") {
                    vnode.context.setTrainingTime(vnode.elm.value);
                    return;
                    }
                    if(vnode.data.ref == "trainingEnding") {
                    vnode.context.setTrainingEnding(vnode.elm.value);
                    return;
                    }
                    if(vnode.data.ref == "trainingEndingTitle") {
                    vnode.context.setTrainingEndingTitle(vnode.elm.value);
                    return;
                    }
                    if(vnode.data.ref.startsWith("taskAnswer")) {
                    let indexes = vnode.data.ref.split("-");
                    if(indexes.length > 4){
                        vnode.context.setTaskAnswer(indexes[1], indexes[2], indexes[3], indexes[4], vnode.elm.value);
                    }else{
                        vnode.context.setTaskAnswer(indexes[1], indexes[2], indexes[3], -1, vnode.elm.value);
                    }
                    return;
                    }
                    if(vnode.data.ref.startsWith("questionAnswer")) {
                    let indexes = vnode.data.ref.split("-");
                    if(indexes.length > 4){
                        vnode.context.setQuestionAnswerRaw(indexes[1], indexes[2], indexes[3], indexes[4], vnode.elm.value);
                    }else{
                        vnode.context.setQuestionAnswerRaw(indexes[1], indexes[2], indexes[3], -1, vnode.elm.value);
                    }
                    return;
                    }
                    let index = vnode.data.ref.split("-")[1];
                    vnode.context.setTopicTitle(index, vnode.elm.value);
                }
                };
                document.body.addEventListener('mousedown', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('mousedown', el.clickOutsideEvent)
            },
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
.open-task-container{
  margin: 1rem 0 2rem 0;
}
.test-content-container{
  position: relative;
  margin-top: 2rem;
  &.locked{
    opacity: 0.5;
  }
}
.test-locked{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
     &::after{
        display: flex;
        content: '';
        background: #efefef08;
        // -webkit-backdrop-filter: saturate(180%) blur(1px);
        // backdrop-filter: saturate(180%) blur(1px);
        background: #efefef94;
        z-index: 5;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
}
.test-content-container{
    &.locked{
      .editor-class-container .editor::before{
        background: linear-gradient(#fff0 30px, #f7f7f7);
      }
    }

  }
@supports not (backdrop-filter: blur(1px)) {
  .test-locked{
      background: #efefef94;
      &::after{
          background: #efefef94;
      }
  }
  .test-content-container{
    &.locked{
      .editor-class-container .editor::before{
        background: linear-gradient(#fff0 30px, #f7f7f7);
      }
    }

  }
}
.test--question--add--select{
  &.locked{
    .media--action{
      cursor: not-allowed;
    }
  }
  .media--action{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 22px 10px 22px;
    border-radius: 15px;
    width: 120px;
    &:first-child{
      img{
        height: 100%;
      }
    }
    .img-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50%;
      // width: 80px;
      padding: 0 5px;
      &.big{
        img{
          width: 100%;
          height: 100%;
          max-height: 100%;
        }
      }
    }
    img{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      max-height: 100%;
      max-width: 100%;
    }
    
    span{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // height: 50%;
      max-height: 64px;
      min-height: 64px;
      height: 64px;
      color: var(--primary-color);
    }
    &.upload{
      border: 2px solid var(--primary-color);
      span{
        font-size: 18px;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style lang="scss">
 .editor-class-container{

    .editor{
        word-wrap: break-word;
        position: relative;
        margin: auto 0;
        &:before {
          content:'';
          width:100%;
          height:100%;    
          position:absolute;
          left:0;
          top:0;
          z-index: 9;
          background: linear-gradient(transparent 30px, white);
        }
        p{
          margin: 0;
        }
      }
    
  }
.score--container{
  display: flex;
  width: 4rem;
  background: #F4F4F4;
  border-radius: 10px;
  padding: 4px 10px;
  max-height: 4rem;
  .score--col{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: clamp(14px, 0.7vw, 16px);  
    input{
      width: 100%;
      text-align: center;
      border: 1px solid var(--primary-color);
      border-radius: 20px;
      background: transparent;
      padding: 5px 10px;
      color: var(--primary-color);
    }
  }
}
.test--question .task .task--text--wrapper{
  padding-right: 40px;
  justify-content: space-between;
  .editor-class-container{
    width: 80%;
  }
}

</style>