<template>
  <section name="group-list">
    <div :class="{'container main--container': true, 'side--open': $store.getters.isSideOpen() }">
      <h1
        v-if="!editingNodeTitle"
        @click="editNodeTitle"
      >
        {{ node.name ? node.name : toLocal("hierarchy.title") }}
      </h1>
      <input  
        ref="nodeTitle"
        v-click-outside="endEditingNodeTitle"
        type="text"
        :class="['node--name--edit', {'shown': editingNodeTitle}]"
        @keyup.enter="endEditingNodeTitle"
      >
      <h3 class="hierarchy--breadcrumb">
        <span 
          @click="openNode(0)"
        >
          {{ toLocal("hierarchy.title") }}
        </span>
        <span 
          v-for="_parent in node.parents" 
          :key="_parent.id"
          @click="openNode(_parent.id)"
        >
          {{ _parent.name }}
        </span>
        <span 
          v-if="parent != 0"
        >
          {{ node.name }}
        </span>
      </h3>
      <div class="item--wrapper">
        <div 
          v-for="node in nodes"
          :key="node.id"
          class="item--block"
        >
          <div class="item--text">
            {{ node.name }}
          </div>
          <div 
            class="item--cover"
            @click="openNode(node.id)"
          />
          <div 
            class="item--delete"
            @click="removeNode(node.id)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="17.475" height="22.209" viewBox="0 0 17.475 22.209">
              <g id="Group_308" data-name="Group 308" transform="translate(-5.064 -3.899)">
                <path id="Path_1464" data-name="Path 1464" d="M0,0H12.93V11.953A4.457,4.457,0,0,1,8.473,16.41H4.457A4.457,4.457,0,0,1,0,11.953Z" transform="translate(7.337 8.948)" fill="none" stroke="#28345c" stroke-width="1.5"/>
                <path id="Path_550" data-name="Path 550" d="M7021.034,1775.266a1.277,1.277,0,0,1-1.273,1.273h-13.429a1.273,1.273,0,1,1,0-2.546h13.429A1.272,1.272,0,0,1,7021.034,1775.266Z" transform="translate(-6999.245 -1767.592)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_551" data-name="Path 551" d="M7026.391,1769.926a1.271,1.271,0,0,1-.092.479h-5.577a1.271,1.271,0,0,1-.092-.479,1.277,1.277,0,0,1,1.272-1.273h3.213A1.274,1.274,0,0,1,7026.391,1769.926Z" transform="translate(-7009.708 -1764.004)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <g id="Group_290" data-name="Group 290" transform="translate(10.663 11.621)">
                  <line id="Line_35" data-name="Line 35" y2="10.256" transform="translate(6.278 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                  <line id="Line_36" data-name="Line 36" y2="10.256" transform="translate(3.139 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                  <line id="Line_37" data-name="Line 37" y2="10.256" transform="translate(0 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div 
          v-if="loadingHierarchy" 
          class="item--block gray"
        >
          <div class="item--load">
            <font-awesome-icon icon="spinner" />
          </div>
        </div>
        <div 
          class="item--block gray"
          @click="addNode"
        >
          <div class="item--plus">
            <svg xmlns="http://www.w3.org/2000/svg" width="62.222" height="62" viewBox="0 0 62.222 62">
              <g id="Group_3522" data-name="Group 3522" transform="translate(-1409.5 -768)" opacity="0.34">
                <rect id="Rectangle_1305" data-name="Rectangle 1305" width="11" height="62" rx="5.5" transform="translate(1435 768)" fill="#28345c"/>
                <rect id="Rectangle_1306" data-name="Rectangle 1306" width="10.842" height="62.222" rx="5" transform="translate(1471.722 793.469) rotate(90)" fill="#28345c"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div
        :class="['users--wrapper', {'hidden': parent == 0}]"
      >
        <div @click="clickAddExecutive" class="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.928" height="23.937" viewBox="0 0 23.928 23.937">
            <g id="Component_151" data-name="Component 151" transform="translate(-8.84 -9.41)">
              <g id="Group_656" data-name="Group 656" transform="translate(8.84 9.41)">
                <g id="Path_749" data-name="Path 749">
                  <path id="Path_751" data-name="Path 751" d="M202.225,58.639a1.29,1.29,0,0,1-.381.915,1.262,1.262,0,0,1-.915.39h-9.363v9.363a1.315,1.315,0,0,1-1.305,1.3,1.289,1.289,0,0,1-.915-.381,1.262,1.262,0,0,1-.39-.915V59.944h-9.363a1.308,1.308,0,0,1-.924-2.221,1.359,1.359,0,0,1,.924-.39h9.363V47.98a1.273,1.273,0,0,1,.39-.934,1.289,1.289,0,0,1,.915-.381,1.319,1.319,0,0,1,1.305,1.314v9.354h9.363A1.322,1.322,0,0,1,202.225,58.639Z" transform="translate(-178.297 -46.666)" fill="#a9b9ee"/>
                </g>
              </g>
            </g>
          </svg>
          {{this.toLocal('hierarchy.add_executive')}}
        </div>
        <div @click="clickAddEmployee" class="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.928" height="23.937" viewBox="0 0 23.928 23.937">
            <g id="Component_151" data-name="Component 151" transform="translate(-8.84 -9.41)">
              <g id="Group_656" data-name="Group 656" transform="translate(8.84 9.41)">
                <g id="Path_749" data-name="Path 749">
                  <path id="Path_751" data-name="Path 751" d="M202.225,58.639a1.29,1.29,0,0,1-.381.915,1.262,1.262,0,0,1-.915.39h-9.363v9.363a1.315,1.315,0,0,1-1.305,1.3,1.289,1.289,0,0,1-.915-.381,1.262,1.262,0,0,1-.39-.915V59.944h-9.363a1.308,1.308,0,0,1-.924-2.221,1.359,1.359,0,0,1,.924-.39h9.363V47.98a1.273,1.273,0,0,1,.39-.934,1.289,1.289,0,0,1,.915-.381,1.319,1.319,0,0,1,1.305,1.314v9.354h9.363A1.322,1.322,0,0,1,202.225,58.639Z" transform="translate(-178.297 -46.666)" fill="#a9b9ee"/>
                </g>
              </g>
            </g>
          </svg>
          {{toLocal('hierarchy.add_employee')}}
        </div>
        <br>
        <div 
          v-click-outside="stopAddingExecutive"
          :class="['user--add--wrapper', {'hidden': !addingExecutive}]"
        >
          <div class="search--bar">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.679" height="26.687" viewBox="0 0 26.679 26.687">
              <g id="Group_223" data-name="Group 223" transform="translate(2.121 1.5)">
                <g id="Group_222" data-name="Group 222" transform="translate(0 0)">
                  <circle id="Ellipse_22" data-name="Ellipse 22" cx="9.767" cy="9.767" r="9.767" transform="translate(3.524)" fill="none" stroke="#28347b" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_26" data-name="Line 26" y1="6.386" x2="6.386" transform="translate(0 16.679)" fill="none" stroke="#28347b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                </g>
              </g>
            </svg>
            <input
              ref="executiveInput"
              type="text"
            >
          </div>
          <div class="user--seperator" />
          <div 
            v-for="user in executiveSearchResults"
            :key="user.key"
            class="user"
          >
            <div class="user--name">
              {{ user.name }}
            </div>
            <div class="user--actions">
              <svg @click="addUser(user.id, 1)" xmlns="http://www.w3.org/2000/svg" width="23.928" height="23.937" viewBox="0 0 23.928 23.937">
                <g id="Component_191_1" data-name="Component 191 – 1" transform="translate(-8.84 -9.41)">
                  <g id="Group_656" data-name="Group 656" transform="translate(8.84 9.41)">
                    <g id="Path_749" data-name="Path 749">
                      <path id="Path_751" data-name="Path 751" d="M202.225,58.639a1.29,1.29,0,0,1-.381.915,1.262,1.262,0,0,1-.915.39h-9.363v9.363a1.315,1.315,0,0,1-1.305,1.3,1.289,1.289,0,0,1-.915-.381,1.262,1.262,0,0,1-.39-.915V59.944h-9.363a1.308,1.308,0,0,1-.924-2.221,1.359,1.359,0,0,1,.924-.39h9.363V47.98a1.273,1.273,0,0,1,.39-.934,1.289,1.289,0,0,1,.915-.381,1.319,1.319,0,0,1,1.305,1.314v9.354h9.363A1.322,1.322,0,0,1,202.225,58.639Z" transform="translate(-178.297 -46.666)" fill="#a9b9ee"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div 
          v-for="user in node.executives" 
          :key="user.id" 
          class="user"
        >
          <div class="user--name">
            {{ user.name }}
          </div>
          <div class="user--actions">
            <svg @click="removeUser(user.id)" xmlns="http://www.w3.org/2000/svg" width="21.148" height="26.973" viewBox="0 0 21.148 26.973">
              <g id="Group_3609" data-name="Group 3609" transform="translate(0.75 0.75)">
                <path id="Rectangle_184" data-name="Rectangle 184" d="M1,0H14.9a1,1,0,0,1,1,1V17.185a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V1A1,1,0,0,1,1,0Z" transform="translate(1.873 5.288)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_550" data-name="Path 550" d="M7024.708,1775.559a1.571,1.571,0,0,1-1.565,1.566h-16.517a1.566,1.566,0,1,1,0-3.132h16.517A1.564,1.564,0,0,1,7024.708,1775.559Z" transform="translate(-7005.059 -1771.838)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_551" data-name="Path 551" d="M7027.716,1770.219a1.565,1.565,0,0,1-.113.589h-6.859a1.566,1.566,0,0,1-.114-.589,1.57,1.57,0,0,1,1.566-1.566h3.95A1.569,1.569,0,0,1,7027.716,1770.219Z" transform="translate(-7014.346 -1768.653)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <g id="Group_290" data-name="Group 290" transform="translate(5.963 8.576)">
                  <line id="Line_35" data-name="Line 35" y2="12.616" transform="translate(7.722)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                  <line id="Line_36" data-name="Line 36" y2="12.616" transform="translate(3.861)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                  <line id="Line_37" data-name="Line 37" y2="12.616" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="user--seperator" />
        <div 
          v-click-outside="stopAddingEmployee"
          :class="['user--add--wrapper', {'hidden': !addingEmployee}]"
        >
          <div class="search--bar">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.679" height="26.687" viewBox="0 0 26.679 26.687">
              <g id="Group_223" data-name="Group 223" transform="translate(2.121 1.5)">
                <g id="Group_222" data-name="Group 222" transform="translate(0 0)">
                  <circle id="Ellipse_22" data-name="Ellipse 22" cx="9.767" cy="9.767" r="9.767" transform="translate(3.524)" fill="none" stroke="#28347b" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_26" data-name="Line 26" y1="6.386" x2="6.386" transform="translate(0 16.679)" fill="none" stroke="#28347b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                </g>
              </g>
            </svg>
            <input
              ref="employeeInput"
              type="text"
            >
          </div>
          <div class="user--seperator" />
          <div 
            v-for="user in employeeSearchResults"
            :key="user.key"
            class="user"
          >
            <div class="user--name">
              {{ user.name }}
            </div>
            <div class="user--actions">
              <svg @click="addUser(user.id, 0)" xmlns="http://www.w3.org/2000/svg" width="23.928" height="23.937" viewBox="0 0 23.928 23.937">
                <g id="Component_191_1" data-name="Component 191 – 1" transform="translate(-8.84 -9.41)">
                  <g id="Group_656" data-name="Group 656" transform="translate(8.84 9.41)">
                    <g id="Path_749" data-name="Path 749">
                      <path id="Path_751" data-name="Path 751" d="M202.225,58.639a1.29,1.29,0,0,1-.381.915,1.262,1.262,0,0,1-.915.39h-9.363v9.363a1.315,1.315,0,0,1-1.305,1.3,1.289,1.289,0,0,1-.915-.381,1.262,1.262,0,0,1-.39-.915V59.944h-9.363a1.308,1.308,0,0,1-.924-2.221,1.359,1.359,0,0,1,.924-.39h9.363V47.98a1.273,1.273,0,0,1,.39-.934,1.289,1.289,0,0,1,.915-.381,1.319,1.319,0,0,1,1.305,1.314v9.354h9.363A1.322,1.322,0,0,1,202.225,58.639Z" transform="translate(-178.297 -46.666)" fill="#a9b9ee"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div 
          v-for="user in node.employees" 
          :key="user.id" 
          class="user"
        >
          <div class="user--name">
            {{ user.name }}
          </div>
          <div class="user--actions">
            <svg @click="removeUser(user.id)" xmlns="http://www.w3.org/2000/svg" width="21.148" height="26.973" viewBox="0 0 21.148 26.973">
              <g id="Group_3609" data-name="Group 3609" transform="translate(0.75 0.75)">
                <path id="Rectangle_184" data-name="Rectangle 184" d="M1,0H14.9a1,1,0,0,1,1,1V17.185a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V1A1,1,0,0,1,1,0Z" transform="translate(1.873 5.288)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_550" data-name="Path 550" d="M7024.708,1775.559a1.571,1.571,0,0,1-1.565,1.566h-16.517a1.566,1.566,0,1,1,0-3.132h16.517A1.564,1.564,0,0,1,7024.708,1775.559Z" transform="translate(-7005.059 -1771.838)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_551" data-name="Path 551" d="M7027.716,1770.219a1.565,1.565,0,0,1-.113.589h-6.859a1.566,1.566,0,0,1-.114-.589,1.57,1.57,0,0,1,1.566-1.566h3.95A1.569,1.569,0,0,1,7027.716,1770.219Z" transform="translate(-7014.346 -1768.653)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1.5"/>
                <g id="Group_290" data-name="Group 290" transform="translate(5.963 8.576)">
                  <line id="Line_35" data-name="Line 35" y2="12.616" transform="translate(7.722)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                  <line id="Line_36" data-name="Line 36" y2="12.616" transform="translate(3.861)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                  <line id="Line_37" data-name="Line 37" y2="12.616" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Hierarchy",
  components: {
  },
  data: function(){
    return {
      parent: 0,
      loadingHierarchy: false,
      nodes: [],
      node: {},

      editingNodeTitle: false,
      addingExecutive: false,
      addingEmployee: false,

      executiveSearchResults: [],
      lastExecutiveSearch: "-",
      employeeSearchResults: [],
      lastEmployeeSearch: "-",

      searchInterval: null
    }
  },
  activated() {
    this.loadHierarchyInformation();

    this.searchInterval = setInterval(() => {
      if(this.lastExecutiveSearch != this.$refs['executiveInput'].value) {
        this.lastExecutiveSearch = this.$refs['executiveInput'].value;

        axios.post( 
          `${this.$store.state.api_link}/hierarchy/node/user/search`,
          {node: this.node.id, type: 1, search: this.lastExecutiveSearch},
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.executiveSearchResults = response.data.result;
        }).catch((error) => {
          console.log(error)
        });
      }
      if(this.lastEmployeeSearch != this.$refs['employeeInput'].value) {
        this.lastEmployeeSearch = this.$refs['employeeInput'].value;

        axios.post( 
          `${this.$store.state.api_link}/hierarchy/node/user/search`,
          {node: this.node.id, type: 0, search: this.lastEmployeeSearch},
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.employeeSearchResults = response.data.result;
        }).catch((error) => {
          console.log(error)
        });
      }
    },1000);
  },
  deactivated() {
    
    setTimeout(() => {
      if(this.searchInterval != null){
        clearInterval(this.searchInterval);
        this.searchInterval = null;
      }
    }, 300);
  },
  methods: {
    loadHierarchyInformation() {
      this.loadingHierarchy = true;
      axios.get( 
        `${this.$store.state.api_link}/hierarchy/nodes/${this.parent}`,
        this.$store.getters.axiosConfig()
      ).then((response) => {
        this.nodes = response.data.data;
        this.node = response.data.node;
        this.loadingHierarchy = false;
        this.lastExecutiveSearch = "-";
        this.lastEmployeeSearch = "-";
      }).catch((error) => {
        console.log(error)
      });
    },
    openNode(id) {
      this.parent = id;
      this.nodes = [];
      this.loadHierarchyInformation();
    },
    editNodeTitle() {
      if(!this.node.name)
        return;
      setTimeout(() => {
        this.$refs['nodeTitle'].value = this.node.name;
        this.$refs['nodeTitle'].focus();
        this.editingNodeTitle = true;
      }, 10);
    },
    endEditingNodeTitle() {
      if(!this.editingNodeTitle)
        return;
      this.node.name = this.$refs['nodeTitle'].value;
      this.editingNodeTitle = false;
      
      axios.post( 
        `${this.$store.state.api_link}/hierarchy/node/edit`,
        {node: this.node.id, name: this.node.name},
        this.$store.getters.axiosConfig()
      ).then((response) => {
      }).catch((error) => {
        console.log(error)
      });
    },
    addNode() {
      this.loadingHierarchy = true;
      axios.post( 
        `${this.$store.state.api_link}/hierarchy/node/add`,
        {node: this.parent},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        this.loadHierarchyInformation();
      }).catch((error) => {
        console.log(error)
      });
    },
    removeNode(node) {
      if(!confirm(this.toLocal("hierarchy.confirm_remove")))
        return;
      this.loadingHierarchy = true;
      axios.post( 
        `${this.$store.state.api_link}/hierarchy/node/remove`,
        {node: node},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "error"){
          this.loadingHierarchy = false;
          alert(response.data.message);
        }else{
          this.loadHierarchyInformation();
        }
      }).catch((error) => {
        console.log(error)
      });
    },
    clickAddExecutive() {
      setTimeout(() => {
        this.addingExecutive = true;
      }, 10);
    },
    clickAddEmployee() {
      setTimeout(() => {
        this.addingEmployee = true;
      }, 10);
    },
    stopAddingExecutive() {
      this.addingExecutive = false;
    },
    stopAddingEmployee() {
      this.addingEmployee = false;
    },
    addUser(id, type) {
      axios.post( 
        `${this.$store.state.api_link}/hierarchy/node/user/add`,
        {node: this.parent, type: type, user: id},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        this.loadHierarchyInformation();
        this.addingExecutive = false;
      }).catch((error) => {
        console.log(error)
      });
    },
    removeUser(id) {
      axios.post( 
        `${this.$store.state.api_link}/hierarchy/node/user/remove`,
        {node: this.parent, user: id},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        this.loadHierarchyInformation();
      }).catch((error) => {
        console.log(error)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/views/_hierarchyViewScoped.scss";
</style>