import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=8998e44e&scoped=true&"
import script from "./Editor.vue?vue&type=script&lang=js&"
export * from "./Editor.vue?vue&type=script&lang=js&"
import style0 from "./Editor.vue?vue&type=style&index=0&id=8998e44e&lang=scss&scoped=true&"
import style1 from "./Editor.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Editor.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8998e44e",
  null
  
)

export default component.exports