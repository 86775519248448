<template>
  <div class="modal-window" :class="[modalInfo.class ? modalInfo.class : '']">
    <div class="modal-box" :style="modalInfo.style ? modalInfo.style : false">
      <div class="modal-close" @click="closeModal()"><CloseCrossSvg /></div>
      <div
        class="modal-text"
        v-if="modalInfo.type != 'table' && modalInfo.text"
      >
        {{ toLocal(modalInfo.text) }}
      </div>
      <div
        class="modal-undertext"
        v-if="modalInfo.undertext != undefined && modalInfo.type != 'table'"
      >
        {{ toLocal(modalInfo.undertext) }}
      </div>
      <div
        class="modal-big-undertext"
        v-if="modalInfo.bigUnderText != undefined && modalInfo.type != 'table'"
      >
        {{ toLocal(modalInfo.bigUnderText) }}
      </div>
      <div
        v-if="modalInfo.type == 'confirmation'"
        class="modal-confirmation"
        :class="[getContrast('secondary_color')]"
        @click="confirmModal()"
      >
        {{ toLocal(modalInfo.confirmBtnText) }}
      </div>
      <div
        v-else-if="modalInfo.type == 'info' && modalInfo.show_button"
        class="modal-confirmation"
        @click="modalInfo.loading ? '' : closeModal()"
      >
        {{ modalInfo.loading ? "" : toLocal(modalInfo.confirmBtnText) }}
        <font-awesome-icon icon="spinner" v-if="modalInfo.loading" />
      </div>
      <div
        class="modal-multiChoice"
        v-else-if="modalInfo.type == 'multiChoice'"
      >
        <div class="multichoice-yes button-yellow" @click="choiceOne">
          {{ toLocal(modalInfo.multiChoiceYes) }}
        </div>
        <div class="multichoice-no button-gray" @click="choiceTwo">
          {{ toLocal(modalInfo.multiChoiceNo) }}
        </div>
      </div>
      <!-- <div
        class="modal-multiChoice"
        v-else-if="modalInfo.type == 'passwordChange'"
      >
        <div class="multichoice-yes button-yellow" @click="choiceOne">
          {{ toLocal(modalInfo.multiChoiceYes) }}
        </div>
        <div class="multichoice-no button-gray" @click="choiceTwo">
          {{ toLocal(modalInfo.multiChoiceNo) }}
        </div>
      </div> -->
      <div
        class="modal-special-switch"
        v-else-if="modalInfo.type == 'dropdown'"
      >
        <select v-model="selectOption" class="dropdown">
          <option value="" disabled hidden>
            {{
              modalInfo.dropdownPlaceholder
                ? toLocal(modalInfo.dropdownPlaceholder)
                : "---"
            }}
          </option>
          <option
            v-for="item in modalInfo.dropdownList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <div
          class="saveOption button-yellow"
          :class="[
            {
              greyed: selectOption == '' || disabledSaveBtn == true,
            },
          ]"
          @click="saveSelect"
        >
          {{ toLocal(this.modalInfo.confirmBtnText) }}
        </div>
      </div>
      <div class="modal-data" v-else-if="modalInfo.type == 'multiData'">
        <div
          class="input-container"
          v-for="(key, index) in Object.keys(modalInfo.data)"
          :key="index"
        >
          <label>{{ modalInfo.data[key].label }}</label>
          <input
            v-if="modalInfo.data[key].type == 'input'"
            type="text"
            v-model="modalInfo.data[key].value"
          />
          <ToggleButton
            v-else-if="modalInfo.data[key].type == 'toggle'"
            :toggledState="modalInfo.data[key].value"
            theme="gray"
            @changeState="modalInfo.data[key].value = $event"
          />
          <div class="list" v-else-if="modalInfo.data[key].type == 'list'">
            <SelectInput
              v-model="modalInfo.data[key].value"
              width="15rem"
              theme="filter"
              :placeholder="toLocal('general.select_shown_topics')"
              :options="modalInfo.data[key].options"
              multichoice
              :canSelectAll="false"
              label="title"
            />
          </div>
        </div>
        <div class="modal-confirmation" @click="$emit('saveData', modalInfo)">
          {{ toLocal(modalInfo.confirmBtnText) }}
        </div>
      </div>
      <div class="modal-data" v-else-if="modalInfo.type == 'table'">
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(item, index) in modalInfo.table.header"
                  :key="index"
                >
                  <span @click="modalInfo.table.sort(item.value)">
                    {{ toLocal(item.label) }}
                    <div class="sort" v-if="item.sortable">
                      <svg
                        :class="[
                          modalInfo.table.sortingItem == item.value
                            ? modalInfo.table.sortDirection
                            : '',
                        ]"
                        width="15"
                        height="21"
                        viewBox="0 0 15 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.45996 0.312953C6.83996 -0.0870468 7.46987 -0.107086 7.86987 0.272914C7.87987 0.282914 7.89991 0.302953 7.90991 0.312953L14.0898 6.85299C14.4698 7.25299 14.45 7.8829 14.05 8.2629C13.86 8.4329 13.6199 8.53292 13.3599 8.53292H1C0.45 8.53292 0 8.08292 0 7.53292C0 7.27292 0.0998975 7.03298 0.269897 6.84298L6.45996 0.312953Z"
                          fill="#C5C5C5"
                        />
                        <path
                          d="M7.90975 19.7727C7.52975 20.1727 6.89984 20.1927 6.49984 19.8127C6.48984 19.8027 6.4698 19.7827 6.4598 19.7727L0.27975 13.2328C-0.10025 12.8328 -0.0802109 12.2028 0.319789 11.8228C0.509789 11.6528 0.749853 11.5527 1.00985 11.5527H13.3697C13.9197 11.5527 14.3697 12.0027 14.3697 12.5527C14.3697 12.8127 14.2697 13.0528 14.0997 13.2428L7.90975 19.7727Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </div>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in modalInfo.table.rows" :key="index">
                <td
                  v-for="(key, k_index) in Object.keys(modalInfo.table.header)"
                  :key="k_index"
                >
                  {{ item[modalInfo.table.header[k_index].value] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-confirmation table-btn" @click="confirmModal()">
          <font-awesome-icon icon="spinner" v-if="modalInfo.requestLoading" />
          {{ toLocal(modalInfo.confirmBtnText) }}
        </div>
      </div>
      <div class="inputs-container" v-else-if="modalInfo.type == 'inputs'">
        <div class="input-container" v-for="(input, index) in modalInfo.models" :key="index">
          <label>{{toLocal(input.label)}}</label>
          <div class="input-wrapper">
            <input 
            :type="input.type"
            v-model="input.value"
            :placeholder="toLocal(input.placeholder)"
            />
            <slot name="inputIcon" :input="input">

            </slot>
          </div>
          <span class="error-message" v-if="input.errorMessage && input.errorMessage.length > 0">
            {{toLocal(input.errorMessage)}}
          </span>
        </div>
        <div class="modal-confirmation" :class="getContrast('primary_color')" @click="confirmModal()">
          <font-awesome-icon icon="spinner" v-if="modalInfo.requestLoading" />
          {{ toLocal(modalInfo.confirmBtnText) }}
        </div>
      </div>
      <div class="doc-upload" v-else-if="modalInfo.type == 'new-doc'">
        <input
          :ref="`documentFile`"
          accept=".png,.jpg,.jpeg,.pdf,.docx"
          type="file"
          style="display: none"
          @change="uploadFromPc($event)"
        />
        <div class="upper-modal">
          <div class="left-side">
            <span class="title">{{ toLocal("modal.document_upload") }}</span>
            <!-- <div
              class="upload-block"
              v-if="fileName == '' || fileName == undefined"
            >
              <div class="block">
                <div class="gray-block" @click="$refs[`documentFile`].click()">
                  <UploadComputerSvg />
                </div>
                <span>{{ toLocal("training_edit.upload_from_pc") }}</span>
              </div>
              <div class="block">
                <div class="gray-block">
                  <UploadPhoneSvg class="phone" />
                </div>
                <span>{{
                  toLocal("training_edit.upload_options.mobile")
                }}</span>
              </div>
            </div> -->
            <div
              class="upload-block upload-block-rel"
              v-if="qrCodeReady == false"
              :style="{
                'background-image': fileSource
                  ? 'url(' + fileSource + ')'
                  : 'url(' + $store.state.api_uploads_link + fileName + ')',
              }"
              :class="{ 'file-selected': fileSource || fileName != undefined }"
            >
              <div class="block-container">
                <div class="block">
                  <div
                    class="gray-block"
                    @click="$refs[`documentFile`].click()"
                  >
                    <UploadComputerSvg />
                  </div>
                  <span>{{ toLocal("training_edit.upload_from_pc") }}</span>
                </div>
                <div class="block" @click="generateQRCode">
                  <div class="gray-block"><UploadPhoneSvg class="phone" /></div>
                  <span>{{
                    toLocal("training_edit.upload_options.mobile")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="upload-block" v-else>
              <qrcode-vue :value="QrValue" />
            </div>
          </div>
          <div class="right-side">
            <span class="title">{{ toLocal("modal.doc_info") }}</span>
            <div class="document-form">
              <div class="info-block">
                <span>{{ toLocal("group.name") }}:</span>
                <InputWithSearch
                  v-model="documentName"
                  :data="modalInfo.data"
                  @searchValue="documentName = $event"
                  :placeholder="toLocal('first_login.enter')"
                />
              </div>
              <div class="info-block">
                <span class="with-info">
                  {{ toLocal("modal.doc_number") }}
                  <svg v-tooltip="{content: toLocal('validation.max_symbol_count')+' 11', classes:'doc-tooltip-custom'}" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><circle data-v-26084dc2="" cx="8.5" cy="8.5" r="8" fill="#EFEFEF" stroke="#333333"></circle><path data-v-26084dc2="" d="M8.5 4.25H8.51286" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-26084dc2="" d="M8.5 12.7497V7.89258" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </span>
                <textarea
                  class="input-small"
                  v-model="documentNumber"
                  maxlength="11"
                  :placeholder="toLocal('first_login.enter')"
                />
              </div>
              <div class="info-block">
                <span class="date-label">{{
                  toLocal("modal.passing_date")
                }}</span>
                <date-picker
                  required
                  v-model="docPassDate"
                  label="date"
                  :placeholder="toLocal('users_side.select_date')"
                  class="doc-calendar"
                  type="date"
                  valueType="format"
                >
                  <template v-slot:icon-calendar>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="15.182"
                      height="15.189"
                      viewBox="0 0 15.182 15.189"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_2454"
                            data-name="Rectangle 2454"
                            width="15.189"
                            height="15.182"
                            fill="#28345c"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_8034"
                        data-name="Group 8034"
                        transform="translate(15.182) rotate(90)"
                        opacity="0.5"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          id="Path_6192"
                          data-name="Path 6192"
                          d="M15.175,14.5l-.791-3.532v-.03a1.26,1.26,0,0,0-.371-.892L4.339.374a1.279,1.279,0,0,0-1.808,0L.374,2.526a1.279,1.279,0,0,0,0,1.808l9.679,9.679a1.236,1.236,0,0,0,.908.36l3.548.8a.6.6,0,0,0,.241,0,.558.558,0,0,0,.352-.247.57.57,0,0,0,.087-.3.468.468,0,0,0-.014-.122M.935,3.773a.484.484,0,0,1,0-.686L3.092.935A.473.473,0,0,1,3.434.791a.484.484,0,0,1,.344.144l.932.932L1.87,4.708Zm9.418,9.418L2.431,5.269l2.84-2.84,7.922,7.922Zm2.4.77-1.672-.374,2.512-2.512.4,1.8Z"
                          transform="translate(0)"
                          fill="#28345c"
                        />
                        <path
                          id="Path_6193"
                          data-name="Path 6193"
                          d="M23.111,22.7a.388.388,0,0,1-.117.279.4.4,0,0,1-.561,0l-6.03-6.03a.393.393,0,0,1,0-.561.4.4,0,0,1,.561,0l6.03,6.03a.394.394,0,0,1,.117.282"
                          transform="translate(-11.871 -11.862)"
                          fill="#28345c"
                        />
                      </g>
                    </svg>
                  </template>
                </date-picker>
              </div>
              <div class="info-block">
                <span class="date-label">{{
                  toLocal("user_side.deactivate_after")
                }}</span>
                <date-picker
                  required
                  v-model="docDeacDate"
                  label="date"
                  :placeholder="toLocal('users_side.select_date')"
                  class="doc-calendar"
                  type="date"
                  valueType="format"
                >
                  <template v-slot:icon-calendar>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="15.182"
                      height="15.189"
                      viewBox="0 0 15.182 15.189"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_2454"
                            data-name="Rectangle 2454"
                            width="15.189"
                            height="15.182"
                            fill="#28345c"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_8034"
                        data-name="Group 8034"
                        transform="translate(15.182) rotate(90)"
                        opacity="0.5"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          id="Path_6192"
                          data-name="Path 6192"
                          d="M15.175,14.5l-.791-3.532v-.03a1.26,1.26,0,0,0-.371-.892L4.339.374a1.279,1.279,0,0,0-1.808,0L.374,2.526a1.279,1.279,0,0,0,0,1.808l9.679,9.679a1.236,1.236,0,0,0,.908.36l3.548.8a.6.6,0,0,0,.241,0,.558.558,0,0,0,.352-.247.57.57,0,0,0,.087-.3.468.468,0,0,0-.014-.122M.935,3.773a.484.484,0,0,1,0-.686L3.092.935A.473.473,0,0,1,3.434.791a.484.484,0,0,1,.344.144l.932.932L1.87,4.708Zm9.418,9.418L2.431,5.269l2.84-2.84,7.922,7.922Zm2.4.77-1.672-.374,2.512-2.512.4,1.8Z"
                          transform="translate(0)"
                          fill="#28345c"
                        />
                        <path
                          id="Path_6193"
                          data-name="Path 6193"
                          d="M23.111,22.7a.388.388,0,0,1-.117.279.4.4,0,0,1-.561,0l-6.03-6.03a.393.393,0,0,1,0-.561.4.4,0,0,1,.561,0l6.03,6.03a.394.394,0,0,1,.117.282"
                          transform="translate(-11.871 -11.862)"
                          fill="#28345c"
                        />
                      </g>
                    </svg>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="save-btn" @click="saveDocument">
          <font-awesome-icon icon="spinner" v-if="savingDocument" />
          {{ savingDocument ? "" : toLocal("settings.save") }}
        </div>
      </div>
      <div class="button-container buttons-type" v-else-if="modalInfo.type == 'buttons'">
        <div class="button-wrapper">
          <button v-for="(btn,index) in modalInfo.buttons" :key="index" @click="$emit('buttonSelected', btn)">
            {{ btn.label }}
          </button>
        </div>
      </div>
      
      <div class="under-button-text" v-if="modalInfo.underBtnText != undefined">
        {{ toLocal(modalInfo.underBtnText) }}
      </div>
    </div>
  </div>
</template>

<script>
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
import UploadComputerSvg from "@/assets/images/icons/training-item-upload.svg?inline";
import UploadPhoneSvg from "@/assets/images/icons/upload_phone-black.svg?inline";
import ToggleButton from "@/components/layout/emails/ToggleButton";
import SelectInput from "@/components/layout/emails/SelectInput";
import InputWithSearch from "@/components/layout/InputWithSearch";
import DatePicker from "vue2-datepicker";
import QrcodeVue from "qrcode.vue";
import axios from "axios";

export default {
  name: "GlobalModal",
  data: function () {
    return {
      selectOption: "",
      disabledSaveBtn: false,
      fileName: "",
      fileSource: "",
      saveableFile: "",
      documentName: "",
      documentNumber: "",
      docPassDate: 0,
      docDeacDate: 0,
      isUploading: false,
      qrCodeReady: false,
      QrValue: "",
      savingDocument: false,
    };
  },
  components: {
    DatePicker,
    CloseCrossSvg,
    ToggleButton,
    SelectInput,
    UploadComputerSvg,
    UploadPhoneSvg,
    QrcodeVue,
    InputWithSearch,
  },
  props: {
    modalInfo: Object,
  },
  methods: {
    generateQRCode() {
      this.qrLoading = true;
      if (this.item && this.item.id) {
        axios
          .post(
            `${this.$store.state.api_link}/qr/generate`,
            {
              training: this.$store.state.currentTraining,
              type: this.type,
              topic: this.topic.id,
              item: this.item.id,
            },
            this.$store.getters.axiosConfig()
          )
          .then((res) => {
            if (res.data.status == "success") {
              let full = (full = location.protocol + "//" + location.host);
              // this.$set(this.item, 'QRLink', full+'/q/'+res.data.data.code );
              this.$emit("setQRCode", full + "/q/" + res.data.data.code);
              startListeningToQr(res.data.data.code);
              this.qrLoading = false;
            }
          })
          .catch((err) => {
            this.qrLoading = false;
          });
      }
    },
    startListeningToQr(code) {
      window.Echo.join(
        `${this.$store.state.system_settings.lms_key}.QRUpload.${code}`
      ).listen("QRUpload", (e) => {
        this.fileName = e.filename;
        this.qrCodeReady = false;
      });
    },
    uploadFromPc(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var file = e.target.files[0];
      this.fileSource = URL.createObjectURL(file);
      this.saveableFile = file;
    },
   
    closeModal() {
      this.$emit("onClose");
    },
    saveDocument() {
      if (this.savingDocument) return;
      this.savingDocument = true;
      let formData = new FormData();
      formData.append("file", this.saveableFile);

      formData.append(
        "id",
        this.modalInfo.editedDocument.id ? this.modalInfo.editedDocument.id : ""
      );
      formData.append("filename", this.fileName ? this.fileName : "");
      formData.append("docName", this.documentName);
      formData.append("docNum", this.documentNumber ? this.documentNumber : "");
      formData.append("docPassDate", this.docPassDate ? this.docPassDate : "");
      formData.append("docDeacDate", this.docDeacDate ? this.docDeacDate : "");
      formData.append("userId", this.modalInfo.user.id);
      axios
        .post(
          `${this.$store.state.api_link}/user/save-document`,
          formData,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.savingDocument = false;
          this.closeModal();
        })
        .catch((error) => {
          this.savingDocument = false;
        });
    },
    confirmModal() {
      this.$emit("onConfirm");
    },
    choiceOne() {
      this.$emit("onChoiceOne");
    },
    choiceTwo() {
      this.$emit("onChoiceTwo");
    },
    saveSelect() {
      if (this.selectOption == "" || this.modalInfo.disableTime == true) {
        return;
      }
      this.disabledSaveBtn = true;
      this.$emit("onSelectSave", this.selectOption);
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
  },
  mounted() {
    if (this.modalInfo.editedDocument != undefined) {
      this.fileName = this.modalInfo.editedDocument.file;
      this.documentName = this.modalInfo.editedDocument.title;
      this.documentNumber = this.modalInfo.editedDocument.number;
      this.docPassDate = this.modalInfo.editedDocument.date_at;
      this.docDeacDate = this.modalInfo.editedDocument.expires_at;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background: #1a19196b;
}
.danger {
  .modal-confirmation {
    background: #d45454;
  }
}
.modal-box {
  width: 550px;
  height: 350px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-align: center;
  position: relative;
  user-select: none;
}
.modal-text {
  width: 62%;
  font-size: 24px;
  margin-top: 24px;
}
.modal-undertext {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.modal-big-undertext {
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.modal-confirmation {
  background: var(--secondary-color);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
  border-radius: 38px;
  padding: 5px;
  width: 60%;
  cursor: pointer;
  margin-top: 30px;
  &:hover {
    background-color: #333;
    color: white;
  }
}
.button-yellow {
  background: #f4dc00;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  padding: 8px 20px;
  transition: 0.25s;
  font-size: 16px;
  margin-top: 24px;
  cursor: pointer;
  &:hover {
    background: #333333;
    color: white;
  }
}
.button-gray {
  background: #f4f4f4;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  font-size: 16px;
  padding: 8px 20px;
  transition: 0.25s;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    background: #333333;
    color: white;
  }
}
.greyed {
  color: black;
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed;
    background: #f4dc00;
    color: black;
  }
}
.saveOption {
  width: 80%;
}
.under-button-text {
  margin-top: 12px;
  font-size: 14px;
  width: 80%;
  color: #7d7d7d;
}
.modal-special-switch {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 45%;
}
.third-color {
  .modal-confirmation {
    background: var(--third-color);
  }
}
.with-info{
  position: relative;
  svg{
    position: absolute;
    right: -25px;
    top: 2px;
  }
}
.publish {
  .modal-box {
    padding: 20px;
  }
  .modal-confirmation {
    &:hover {
      color: white;
    }
  }
}
.delete {
  .modal-box {
    padding: 20px;
  }
  .modal-confirmation {
    &:hover {
      color: white;
    }
  }
}
.dropdown {
  background: #f9f9f9;
  border: 1px solid #000000;
  border-radius: 22px;
  width: 100%;
  padding: 8px 16px;
  color: #000000;
  opacity: 0.5;
  font-size: 16px;
}
.list {
  z-index: 2;
}
.table-modal {
  .modal-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 32px 35px 32px 29px;
  }
  .table-container {
    height: 100%;
    width: 100%;
    max-height: 555px;
    overflow-y: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 16px 18px;
      text-align: left;
      border-bottom: 1px solid #e2e2e2;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-family: var(--main-site-font);
    }
    th {
      padding: 16px 18px;
      text-align: left;
      border-bottom: 1px solid #e2e2e2;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      opacity: 0.7;
      font-family: var(--main-site-font);
      span {
        display: flex;
        svg {
          margin-left: 12px;
          cursor: pointer;
          &.desc {
            path:last-child {
              fill: #333333;
            }
          }
          &.asc {
            path:first-child {
              fill: #333333;
            }
          }
        }
      }
      .sort {
        display: flex;
        align-items: center;
      }
    }
  }
  .modal-confirmation {
    max-width: 240px;
    background: #e2e2e2;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    color: #333333;
    margin: 42px 0 auto auto;
  }
  .table-btn {
    font-size: 16px;
    padding: 10px;
    margin-top: 32px;
    width: 240px;
    &:hover {
      background: #333333;
      color: white;
    }
  }
}
.doc-upload {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  .save-btn {
    max-width: 240px;
  }
  .doc-calendar {
    input {
      background: #ffffff;
      border-bottom: 1px solid #efefef;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
    }
  }
}
.upper-modal {
  display: flex;
  height: 85%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
  .left-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 32px;
    .upload-block {
      &.upload-block-rel {
        position: relative;
        height: 85%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        // max-height: 404px;
        // max-width: 404px;
        .block-container {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 55px;
          .block {
            // opacity: 0.25;
          }
        }
      }
      .upload-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      margin-top: clamp(20px, 1.5vh, 24px);
      width: 100%;
      border: 2px solid #f4f4f4;
      border-radius: 8px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 55px;
      flex-wrap: wrap;
      .block {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
        span {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #7d7d7d;
        }
        .gray-block {
          min-width: 72px;
          min-height: 72px;
          max-width: 80px;
          max-height: 80px;
          background: #f4f4f4;
          box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          transition: 0.25s;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 80%;
            height: 80%;
            &.phone {
              width: 40%;
              height: 60%;
              path {
                fill: #707070;
              }
            }
          }
        }
        &:hover {
          .gray-block {
            background: #7d7d7d;
          }
          svg {
            path,
            rect {
              stroke: white;
            }
          }
        }
        &:nth-child(2) {
          &:hover {
            svg {
              path {
                stroke: none;
                fill: white;
              }
            }
          }
        }
      }
      &.file-selected {
        .gray-block {
          opacity: 0.5;
        }
        .gray-block {
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .title {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: start;
      color: #000000;
      opacity: 0.7;
    }
  }
  .right-side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    padding-left: 32px;
    border-left: 1px solid rgba(125, 125, 125, 0.5);
    gap: 8px;
    .document-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      max-height: 381px;
      margin-top: clamp(5px, 1.5vh, 21px);
    }
    .title {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: start;
      color: #000000;
      opacity: 0.7;
    }
    .info-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 8px;
      }
      textarea {
        width: 100%;
        background: #ffffff;
        border: 1px solid #efefef;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        resize: none;
        font-family: "Noto Sans";
        font-style: normal;
        font-size: 16px;
      }
      .input-big {
        height: 92px;
        text-align: start;
        padding: clamp(2px, 1.23vh, 10px) 16px;
      }
      .input-small {
        height: clamp(25px, 6vh, 44px); 
        padding: clamp(2px, 1.23vh, 10px) 16px;
        box-sizing: border-box;
      }
    }
  }
}
.save-btn {
  background: #f3de18;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 44px;
  margin: 0 auto;
  width: 35%;
  font-size: clamp(14px, 1vw, 16px);
  padding: 8px 30px;
  box-sizing: border-box;
  transition: 0.25s;
  cursor: pointer;
  @media (max-width: 987px) {
    width: 50%;
  }
  &:hover {
    background: #333333;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    color: #ffffff;
  }
}
.modal-multiChoice {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  gap: 16px;
  .button-yellow {
    width: 45%;
    margin-top: 24px;
  }
  .button-gray {
    width: 45%;
    margin-top: 24px;
  }
}
.inputs-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  .input-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
  }
  .input-wrapper{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    svg{
      cursor: pointer;
      &.position-left{

      }
      &.position-right{

      }
    }
  }
  label{
    color: #232323;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom:8px;
    padding: 0 12px;
  }
  input{
    border-radius: 6px;
    border-bottom: 1px solid #CACACA;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20) inset;
    border: none;
    padding: 8px 12px;
    color: #333333;
    width: 100%;
    font-family: var(--main-site-font);
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .modal-confirmation{
    color: #333333;
    position: relative;

  }
  .error-message{
    position: absolute;
    bottom: -12px;
    left: 12px;
    font-size: 14px;
    color: rgb(253, 99, 99);
  }
  .whiteContrast{
    &:hover{
      color: #fff;
    }
  }
  .blackContrast{
    &:hover{
      color: #333333;
    }
  }
}
.buttons-type{
  margin-top: 20px;
  .button-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 5px 8px;

  }
  button{
    background-color: #333333;
    border: none;
    border-radius: 20px;
    color: white;
    padding: 5px 15px;
    font-weight: 600;
    font-size: clamp(16px, 1.5vw, 20px);
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
    &:hover,&:active{
      background-color: #6b6b6b;
    }
  }
}


</style>
<style lang="scss">
.doc-calendar {
  .mx-input-wrapper .mx-icon-calendar {
    display: flex;
  }
  .mx-input:hover,
  .mx-input:focus {
    border-color: #d5d5d5;
  }
  input {
    border: none;
    background: #ffffff;
    border-bottom: 1px solid #efefef;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: clamp(2px, 1.5vh, 10px) 16px;
    height: clamp(25px, 6vh, 44px); 
  }
}
.doc-tooltip-custom{
  z-index: 99;
  border-bottom: 1px solid #efefef;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 5px 10px;
  background-color: white;
}
.doc-upload-modal{
  .modal-box{
    height: 65vh;
    width: 60vw;

  }
  .info-block{
    span{
      font-size: clamp(14px, 2vh, 16px);
    }
 
    input{
      padding: clamp(2px, 1.5vh, 10px) 16px;
      height: clamp(25px, 6vh, 44px); 
    }
  }
  @media (max-height: 765px){
    .modal-box{
      margin-top: 11vh;
      width: 90vw;
      height: 80vh;
    }
  }
}
</style>
