<template>
  <div class="img-viewer-container" >
    <ImageEditor
    
        class="none"
        ref="editor"
        :initial="src"
        :metadata="getAnswerMetadata(metadata)"
        :metadataString="metadataString"
        :isEditing="false"
        :readOnly="true"
        :align="'top'"
        :height="height"
        :width="width"
        @loaded="updateImage()"
        @canvasClick="$emit('canvasClick')"
        >
    </ImageEditor>
    <img v-if="imageSrc != ''" :src="imageSrc" alt="">
  </div>
</template>

<script>
import ImageEditor from '@/components/layout/trainings/ImageEditor.vue';
import _ from 'lodash'
export default {
    data(){
        return{
            imageSrc: '',
            loaded: false
        }
    },
    components:{
        ImageEditor
    },
    props:{
        src:{
            type: String,
            required: false,
            default: ''
        },
        height:{
            type: Number,
            required: false,
            default: undefined
        },
        width:{
            type: Number,
            required: false,
            default: undefined
        },
        size:{
            type: Number,
            required: false,
            default: null
        },
        metadata:{
            type: Object | String,
        },
        metadataString:{
            type: String,
            required: false,
            default: ''
        }


    },

    mounted(){
        this.$nextTick(()=>{
            this.imageSrc = this.getImageSRC();
        })
    },
    methods:{
        getImageSRC(){
            let editor = this.$refs.editor;
            if(editor){
                if(Array.isArray(editor)) editor = editor[0];
                if(editor.imgCroppa)
                    return editor.imgCroppa.generateDataUrl();
                else return '';
            }
            else return '';
        },
        getAnswerMetadata(metadata) {
            if (this._.isString(metadata)) {
                let data = JSON.parse(metadata);
                return data.metadata
            }
            else return metadata;
                
        },
        updateImage(){
            this.imageSrc = this.$refs.editor.imgCroppa.generateDataUrl();
        },
        imageLoadDebounce: _.throttle((self)=>{
            self.imageSrc = self.$refs.editor.imgCroppa.generateDataUrl();
        },200),
        test(e){
            console.log(e);
        }
    }

}
</script>

<style lang="scss" scoped> 
.none{
    display: none;
}
img{
    display: flex;
    width: 100%;
    height: 100%;
}
.img-viewer-container{
    width: 100%;
    img{
        display: flex;
        width: 100%;
        // height: auto;
    }
    &.big{
        width: 740px;
        height: 416.25px;
    }
    &.small{
        height: 200px;
        width: 200px;
    }
}
</style>