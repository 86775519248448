<template>
    <transition name="slide-right">
        <div 
        v-if="display"
        class="info-modal"
        :class="type"
        >
            <div class="info-header">
                {{headerText[type]}}
                <div class="close-btn" @click="display=false">
                    <div>
                        <span></span><span></span>
                    </div>
                </div>
            </div>
            <div class="info-content">
                {{message}}
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'InfoModal',
    data: function(){
        return{
            display: false,
            message: '',
            type: 'info',
            show_button: true,
            headerText: {
            'error': this.toLocal('info_modal.error'), 
            'success': this.toLocal('info_modal.success'), 
            'info': this.toLocal('info_modal.info')
            }
        }
    },
    watch:{
        '$store.state.translations'(){
            this.headerText = {
            'error': this.toLocal('info_modal.error'), 
            'success': this.toLocal('info_modal.success'), 
            'info': this.toLocal('info_modal.info')
            }
        }
    },
    mounted(){
        this.$store.state.infoMessage = this;
    },
 
    methods:{
        setMessage(type, message){
            this.type = type; //types: info, success, error
            this.message = message;
            this.showMessage();
        },
        showMessage(type){
            this.display = true;
            setTimeout(()=>{
                this.display = false;
            }, 3000);
        },
        closeModal(){
            this.display =false;
        }

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_transitions.scss";
    .info-modal{
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 1rem;
        top: 2rem;
        padding: 15px;
        background: white;
        border-radius: 10px;
        max-width: 20rem;
        min-width: 13rem;
        z-index: 9999;
        .info-content{
            padding: 6px 0 0 0;
            font-size: 16px;
            word-break: break-word;
            max-height: 10rem;
            overflow-y: auto;
        }
        @media (max-width: 768px){
           display: none;
        }
    }
    .info-header{
        display: flex;
        position: relative;
    }
    .close-btn{
        position: absolute;
        right: -12px;
        top: -12px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        div{
            display: flex;
            justify-content: center;
            height: 100%;
            width: 100%;
            align-items: center;
            border: 1px solid;
            border-radius: 50%;
            span{
                display: flex;
                position: absolute;
                height: 60%;
                width: 2px;
                background: #853434;
                border-radius: 10px;
                transform: rotate(45deg);
            }
            span:nth-child(2){
                transform: rotate(-45deg);
            }
        }
    }

    .info{
        border: 2px solid var(--primary-color);
        .info-header{
            color: var(--primary-color);
            font-weight: 700;
            .close-btn{
                div{
                    span{
                        background: var(--primary-color);
                    }
                }
            }
        }
    }
    .success{
         border: 2px solid #3DAC39;
        .info-header{
            color: #3DAC39;
            font-weight: 700;
            .close-btn{
                div{
                    span{
                        background: #3DAC39;
                    }
                }
            }
        }
    }
    .error{
        border: 2px solid #FC7747;
        .info-header{
            color: #FC7747;
            font-weight: 700;
            .close-btn{
                div{
                    span{
                        background: #FC7747;
                    }
                }
            }
        }
    }
</style>