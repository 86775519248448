let certificate_filter = [
    {
        id: 1, name: 'days', content: '', label: 'generic.days', short_label: 'generic.days_short',
    },
    {
        id: 2, name: 'weeks', content: '', label: 'generic.weeks', short_label: 'mail.week_short',
    },
    {
        id: 3, name: 'months', content: '', label: 'generic.months', short_label: 'generic.months_short',
    },
    {
        id: 4, name: 'years', content: '', label: 'generic.years', short_label: 'generic.years_short',
    },

];
export let additional_certificate_filters = [
    {
        id: 1, name: 'expiration', label: 'report.expiration', selectLabel: 'report.expiration_end', filters: certificate_filter, selectedFilter: certificate_filter[2], filterInput: ''
    },

]