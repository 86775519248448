<template>
    <div class="gif-container" @mouseenter="$emit('disableDrag')" @mouseleave="$emit('enableDrag')" >
        <div class="img-slider-container" v-if="isEditing && !readOnly">
                
            <div class="slider">
                <label for="width">{{toLocal('generic.zoom')}}</label>
                <input id="width" type="range" novalidate @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal">
            </div>
            <div class="slider">
                <label for="height">{{toLocal('generic.expand')}}</label>
                <input id="height"  class="height-slider" type="range" @input="onSliderHeightChange" :min="sliderHeightMin" :max="sliderHeightMax" step="5" v-model="sliderHeightVal">
            </div>
        </div>
        <div class="img-container" ref="container" :style="{height: sliderHeightVal+'px'}">

            <img 
                :src="src" 
                alt=""
                ref="gifImage"
                draggable="false"
                :class="{'read-only': readOnly ? readOnly : !isEditing}"
                :style="{
                    width: readOnly && item.metadata ? item.metadata.width+'px' : naturalWidth*sliderVal+'px', 
                    height: readOnly && item.metadata ? (item.metadata.width/naturalWidth)*naturalHeight+'px' : sliderVal*naturalHeight+'px',
                }"
                @mousedown="onMouseDown"
                @mousemove="onMouseMove"
                @mouseup="onMouseUp"
            >
        </div>
    </div>
</template>

<script>
import { fabric } from 'fabric-with-gestures';

export default {
    props:{
        readOnly: {
            type: Boolean,
            default: false
        },
        src:{
            type: String,
            required: true
        },
        item:{
            type: Object,
            required: true
        },
        isEditing:{
            type: Boolean,
            required: true
        }
    },
    data(){
        return{
            canvas: null,
            image: null,
            naturalHeight:0,
            naturalWidth:0,
            sliderVal: 1,
            sliderHeightVal: 50,
            sliderBrightnessVal: 0,
            sliderMin: 0.1,
            sliderMax: 5,
            translateX: 0,
            translateY: 0,
            sliderHeightMin: 50,
            sliderHeightMax: window.innerHeight*0.88,
            sliderBrightnessMin: -100,
            sliderBrightnessMax: 100,
            isDown: false,
            container: null,
            offset: [0,0]
        }
    },
    mounted(){
        // this.initCanvas();
        this.getImageDimensions(this.src);
    },
    methods:{
        getImageDimensions(imageSrc) {
            var img = document.createElement("img");
            let vm = this;
            vm.gifImage = this.$refs.gifImage;
            vm.container = this.$refs.container;

            img.onload = function (event)
            {
                vm.naturalHeight = img.naturalHeight;
                vm.naturalWidth = img.naturalWidth;
                vm.image = img;
                vm.sliderHeightVal = Math.min(window.innerHeight*0.88, vm.naturalHeight);
                if(vm.item.metadata && vm.item.metadata.left){
                    vm.gifImage.style.left = vm.item.metadata.left;
                    vm.gifImage.style.top = vm.item.metadata.top;
                    vm.sliderHeightVal = vm.item.metadata.height;
                    vm.gifImage.style.width = vm.item.metadata.width;
                    vm.gifImage.style.height = vm.item.metadata.height;
                    vm.sliderVal = vm.item.metadata.width/vm.naturalWidth;
                }
                else{
                    vm.$nextTick(()=>{
                        vm.gifImage.style.left = ((vm.container.clientWidth - vm.gifImage.clientWidth)/2)+'px';
                        vm.gifImage.style.top = ((vm.container.clientHeight - vm.gifImage.clientHeight)/2)+'px';
                    })
                }
            }
            img.src = imageSrc;
        },
        onSliderChange(e){
            let value = e.target.value;
            let topCenter = (this.container.clientHeight - this.gifImage.clientHeight)/2;
            let leftCenter = (this.container.clientWidth - this.gifImage.clientWidth)/2;
            this.gifImage.style.left = leftCenter+'px';
            this.gifImage.style.top = topCenter+'px';
            // this.imageHeight = value;
            this.$set(this.item, 'metadata', {
                left: this.gifImage.style.left,
                top: this.gifImage.style.top,
                height: this.container.clientHeight,
                width: this.gifImage.clientWidth,
            })

        },
        onSliderHeightChange(e){
            let value = e.target.value;
            // this.imageHeight = value;
             this.$set(this.item, 'metadata', {
                left: this.gifImage.style.left,
                top: this.gifImage.style.top,
                height: this.container.clientHeight,
                width: this.gifImage.clientWidth,
            })
        },
      
        onMouseDown(e){
            if(this.readOnly) return;
            if(!this.isEditing) return;
            this.isDown = true;
            this.offset = [
                this.gifImage.offsetLeft - e.clientX,
                this.gifImage.offsetTop - e.clientY
            ];
        },
        onMouseMove(e){
            if(this.readOnly) return;
            if(!this.isEditing) return;
            
            e.preventDefault();
            if (this.isDown) {
                this.gifImage.style.left = (e.clientX + this.offset[0]) + 'px';
                this.gifImage.style.top  = (e.clientY + this.offset[1]) + 'px';
            }
        },
        onMouseUp(e){
            if(this.readOnly) return;
            if(!this.isEditing) return;
            this.isDown = false;
            this.item.metadata = {
                left: this.gifImage.style.left,
                top: this.gifImage.style.top,
                height: this.container.clientHeight,
                width: this.gifImage.clientWidth,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.gif-container{
    width: 100%;
    position: relative;
}
canvas{
    width: 100%;
}
img{
    position:absolute; 
    left:0; 
    top:0; 
    width: 100%;
    cursor: move;
}
.read-only{
    cursor: default;
}
.img-container{
    position: relative;
    overflow: hidden;
}
.img-slider-container{
    opacity: 1;
    width: 95%;
    min-width: 95%;
    max-width: 100%;
    gap: 15px;
    margin: 0;
    top: 0;
    margin-bottom: 1rem;
    .slider{
        width:100%;
        max-width: 46%;
        label{
            font-size: 12+px;
            padding-left: 6px;
            color: var(--primary-color);
        }
        input{
            margin-top: 7px;
        }
    }
    .rotate{
        min-width: 20px;
        display: flex;
        min-height: 100%;
        align-self: flex-end;
    }
}
.height-slider, .brightness-slider{
    position: initial;
    transform: none;
    opacity: 1;
}

.img-slider-container, .height-slider-container{
  display:flex;
   width: 100%;

  bottom: 0.5rem;
  transition: opacity 0.3s ease;
  cursor:pointer;
  input{
     -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: var(--secondary-color);
    border-radius: 10px;
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); /* Green background */
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
  }
}
.img-slider-container{
    margin: 23px 20px 20px 20px;
    flex-wrap: wrap;
    position: relative;
}
.height-slider-container{
    bottom: 0.5rem;
    right: 0.5rem;
    input{
        bottom: 0.5rem;
        right: 0.5rem;
    }
}
.height-slider, .brightness-slider{
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    // opacity: 0;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: var(--secondary-color);
    border-radius: 10px;
    outline: none; /* Remove outline */
    right: -10.5rem;
    top: 0.5rem;
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    &::-webkit-slider-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); 
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px; /* Set a specific slider handle width */
      height: 20px; /* Slider handle height */
      background: var(--primary-color); 
      border-radius: 50%;
      border: 1px solid white;
      z-index: 10;
      cursor: pointer;
    }
}
</style>