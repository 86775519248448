<template>
  <div class="privacy-policy-container">
    <div class="content" v-html="toLocal('privacy.policy')">
      
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" >
.privacy-policy-container{
    display: flex;
    padding: 40px;
    
    table{
      border-collapse: collapse;
      th,td{
        border: 1px solid;
        padding: 5px 15px
      }
    }
}
@media (max-width: 768px) {
    .privacy-policy-container{
        padding: 20px;
    }
}
</style>