<template>
  <div class="data-input-container">
      <div class="row">
            <div class="main-input-container" v-if="parent.name != 'expiration'" v-click-outside="closeList"  :class="{'add-btn-main': inputType == 'main'}">
                <div class="select-input" :class="{'add-btn': inputType == 'main'}">
                    <div class="main-btn" v-if="inputType == 'main'"  @click="selectOpen = !selectOpen" >
                       <span 
                            class="title" 
                            :class="{selected: parent.selectedFilter && parent.selectedFilter.id != undefined && !selectOpen,}"
                        >
                                {{getMainContainerLabel(parent)}}
                        </span>
                        <div class="icon">
                            +
                        </div>
                    </div>
                    <div v-else class="dropdown-btn"  @click="selectOpen = !selectOpen">
                        <span 
                            class="title" 
                            :class="{selected: parent.selectedFilter && parent.selectedFilter.id != undefined && !selectOpen}"
                        >
                                {{getMainContainerLabel(parent)}}
                        </span>
                        <div class="dropdown-icon" :class="{selected: selectOpen}">
                            <svg  xmlns="http://www.w3.org/2000/svg" width="21.564" height="11.881" viewBox="0 0 21.564 11.881">
                                <path id="Path_5930" data-name="Path 5930" d="M-3721.863-17217.617a1.1,1.1,0,0,1-.772-.324l-9.69-9.687a1.094,1.094,0,0,1,0-1.547,1.089,1.089,0,0,1,1.546,0l8.916,8.914,8.918-8.914a1.085,1.085,0,0,1,1.543,0,1.093,1.093,0,0,1,0,1.547l-9.688,9.688A1.1,1.1,0,0,1-3721.863-17217.617Z" transform="translate(3732.645 17229.498)" fill="#28345c"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div v-if="selectOpen" class="select-container">
                    <span 
                        v-for="(data, data_index) in dataList" 
                        :key="data_index" 
                        :class="{selected: (parent && parent.selectedFilters && parent.selectedFilters.includes(data.id)) || (parent.selectedFilter != undefined && parent.selectedFilter == data.id), active:  parent && parent.selectedFilter && parent.selectedFilter.id == data.id}"
                        @click="selectOption(data)"
                        >
                        {{ data.label }}
                    </span>
                </div>
                <div class="delete-icon" v-if="inputType != 'main'" @click="$emit('removeFilter', parent)">
                    <span class="delete-inside">{{toLocal('reports.'+parent.name)}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.221" height="21.221" viewBox="0 0 21.221 21.221">
                    <g id="Path_749" data-name="Path 749" transform="translate(10.613) rotate(45)">
                        <path id="Path_751" data-name="Path 751" d="M15,7.507a.809.809,0,0,1-.239.574.791.791,0,0,1-.574.244H8.32V14.2a.825.825,0,0,1-.818.813.808.808,0,0,1-.574-.239.791.791,0,0,1-.244-.574V8.325H.813a.82.82,0,0,1-.58-1.392.852.852,0,0,1,.58-.244h5.87V.824A.8.8,0,0,1,6.927.239.808.808,0,0,1,7.5,0,.827.827,0,0,1,8.32.824V6.689h5.87A.829.829,0,0,1,15,7.507Z" fill="#28345c"/>
                    </g>
                    </svg>
                </div>
            </div>
            <div v-else class="additional-delete">
                <div class="delete-icon" v-if="inputType != 'main'" @click="$emit('removeFilter', parent)">
                    <span class="delete-inside">{{toLocal('reports.'+parent.name)}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.221" height="21.221" viewBox="0 0 21.221 21.221">
                    <g id="Path_749" data-name="Path 749" transform="translate(10.613) rotate(45)">
                        <path id="Path_751" data-name="Path 751" d="M15,7.507a.809.809,0,0,1-.239.574.791.791,0,0,1-.574.244H8.32V14.2a.825.825,0,0,1-.818.813.808.808,0,0,1-.574-.239.791.791,0,0,1-.244-.574V8.325H.813a.82.82,0,0,1-.58-1.392.852.852,0,0,1,.58-.244h5.87V.824A.8.8,0,0,1,6.927.239.808.808,0,0,1,7.5,0,.827.827,0,0,1,8.32.824V6.689h5.87A.829.829,0,0,1,15,7.507Z" fill="#28345c"/>
                    </g>
                    </svg>
                </div>
            </div>
            <div v-if="parent && ['start', 'end', 'test_show', 'expiration', 'trainings'].includes(parent.name) && ['expiration', 'test_show'].includes(parent.name)  ? true : parent.selected_type_id > 1" class="secondary-input">
                <div 
                    v-if="parent.filters && parent.selected_type_id && ['from', 'to'].includes(parent.filters[parent.selected_type_id-1].name)"
                    class="right-input-wrapper" 
                >
                    <span class="relation-label">{{getRelationLabel(parent) }}</span>
                      <date-picker
                            v-model="parent.selectedFilter.date" 
                            label="date"
                            class="secondary-calendar"
                            format = "YYYY-MM-DD"
                            placeholder="2022-01-01"
                            :popup-style="{'marginLeft': '-14px'}"
                            valueType="format"
                        >
                            <template v-slot:icon-calendar>

                            </template>
                        </date-picker>
                </div>
                <div 
                    v-else-if="parent.name == 'expiration'"
                    class="right-input-wrapper" 
                >
                    <span class="relation-label">{{toLocal('report.certificate_expires')}} </span>
                    <div class="select-input cert">
                        <span class="input-title">
                            <input type="text" v-model="parent.selectedFilter.content" :placeholder="0">    
                        </span>
                    </div>
                    <div class="main-input-container dropdown certificate" :class="{selected: secondarySelectOpen}" v-click-outside="closeSecondaryList">
                        <div class="select-input" @click="secondarySelectOpen = !secondarySelectOpen">
                            <span class="title">
                                <span> {{parent.selectedFilter.short_label}}</span>
                            </span>
                                <div class="dropdown-icon"  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.564" height="11.881" viewBox="0 0 21.564 11.881">
                                        <path id="Path_5930" data-name="Path 5930" d="M-3721.863-17217.617a1.1,1.1,0,0,1-.772-.324l-9.69-9.687a1.094,1.094,0,0,1,0-1.547,1.089,1.089,0,0,1,1.546,0l8.916,8.914,8.918-8.914a1.085,1.085,0,0,1,1.543,0,1.093,1.093,0,0,1,0,1.547l-9.688,9.688A1.1,1.1,0,0,1-3721.863-17217.617Z" transform="translate(3732.645 17229.498)" fill="#28345c"/>
                                    </svg>

                                </div>
                        </div>
                        <div v-if="secondarySelectOpen" class="select-container">
                            <span 
                                v-for="(data, data_index) in parent.filters" 
                                :key="data_index"
                                class="select-option"
                                :class="{active: data.id ==  parent.selectedFilter.id}"
                                @click="parent.selectedFilter = data"

                            >
                                {{ data.label }}
                            </span>

                        </div>
                    </div>

                </div>
                <div
                    v-else-if="parent.selectedFilter && ['score', 'percent'].includes(parent.selectedFilter.name)"
                    class="right-input-wrapper test-result" 
                >
                    <div class="main-input-container dropdown" :class="{selected: secondarySelectOpen}" v-click-outside="closeSecondaryList">
                        <div class="select-input result-options" @click="secondarySelectOpen=true">
                            <span class="input-title" :class="{selected: parent && parent.selectedFilter && parent.selectedFilter.selectedFilter && parent.selectedFilter.selectedFilter.id != undefined}">
      
                            {{parent.selectedFilter && parent.selectedFilter.selectedFilter && parent.selectedFilter.selectedFilter.label ? parent.selectedFilter.selectedFilter.label : parent.selectLabel}}
                            </span> 
                                <div class="dropdown-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.564" height="11.881" viewBox="0 0 21.564 11.881">
                                        <path id="Path_5930" data-name="Path 5930" d="M-3721.863-17217.617a1.1,1.1,0,0,1-.772-.324l-9.69-9.687a1.094,1.094,0,0,1,0-1.547,1.089,1.089,0,0,1,1.546,0l8.916,8.914,8.918-8.914a1.085,1.085,0,0,1,1.543,0,1.093,1.093,0,0,1,0,1.547l-9.688,9.688A1.1,1.1,0,0,1-3721.863-17217.617Z" transform="translate(3732.645 17229.498)" fill="#28345c"/>
                                    </svg>

                                </div>
                        </div>
                        <div v-if="secondarySelectOpen" class="select-container">
                            <span 
                                v-for="(data, data_index) in parent.selectedFilter.multiFilter" 
                                :key="data_index"
                                class="select-option"
                                :class="{selected: data.id ==  parent.selectedFilter.selectedFilter.id}"
                                @click="parent.selectedFilter.selectedFilter = data"

                            >
                                {{ data.label }}
                            </span>

                        </div>
                    </div>
                    <span class="relation-label">{{getRelationLabel(parent) }}</span>
                      <div class="main-input-container small-input">
                        <div class="select-input result">
                            <span class="input-title">
                                <input type="text" v-model="parent.selectedFilter.selectedFilter.content" :placeholder="0">    
                            </span>
                             
                        </div>
                    </div>

                </div>
                 <div
                    v-else-if="parent.selectedFilter && ['multi','all_except'].includes(parent.selectedFilter.name) && ['start','end'].includes(parent.name)"
                    class="right-input-wrapper" 
                >
                     <span class="relation-label">{{getRelationLabel(parent) }}</span>
                     <div class="calendar-container">
                         <date-picker
                            v-model="parent.selectedFilter.dates" 
                            label="date"
                            class="custom-calendar"
                            format = "YYYY-MM-DD"
                            placeholder="2022-01-01"
                            input-class="calendar-select-input"
                            :popup-style="{'marginLeft': '4.75rem'}"
                            valueType="format"
                            :multiple="true"
                            :clearable="false"
                        >
                            <template v-slot:icon-calendar>

                            </template>
                        </date-picker>
                    </div>
                    <div class="main-input-container dropdown dates" :class="{selected: secondarySelectOpen}" v-click-outside="closeSecondaryList">
                        <div class="select-input" >
                            <span class="input-title" >
                                <input type="text" v-model="searchInput" :placeholder="parent.selectedIds && parent.selectedIds.length >0 ? parent.selectedLabel : parent.selectedFilter && parent.selectedFilter.dates && parent.selectedFilter.dates.length ? parent.selectedLabel : parent.selectLabel" @focus="secondarySelectOpen=true">    
                            </span>
                                <div class="dropdown-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.564" height="11.881" viewBox="0 0 21.564 11.881">
                                        <path id="Path_5930" data-name="Path 5930" d="M-3721.863-17217.617a1.1,1.1,0,0,1-.772-.324l-9.69-9.687a1.094,1.094,0,0,1,0-1.547,1.089,1.089,0,0,1,1.546,0l8.916,8.914,8.918-8.914a1.085,1.085,0,0,1,1.543,0,1.093,1.093,0,0,1,0,1.547l-9.688,9.688A1.1,1.1,0,0,1-3721.863-17217.617Z" transform="translate(3732.645 17229.498)" fill="#28345c"/>
                                    </svg>

                                </div>
                        </div>
                        <div v-if="secondarySelectOpen" class="select-container">
                            <span 
                                v-for="(data, data_index) in parent.selectedFilter.dates" 
                                :key="data_index"
                                class="select-option"

                            >
                                <span class="remove" @click="parent.selectedFilter.dates.splice(parent.selectedFilter.dates.findIndex(el=>el == data), 1)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.221" height="21.221" viewBox="0 0 21.221 21.221">
                                        <g id="Path_749" data-name="Path 749" transform="translate(10.613) rotate(45)" opacity="1">
                                            <path id="Path_751" data-name="Path 751" d="M15,7.507a.809.809,0,0,1-.239.574.791.791,0,0,1-.574.244H8.32V14.2a.825.825,0,0,1-.818.813.808.808,0,0,1-.574-.239.791.791,0,0,1-.244-.574V8.325H.813a.82.82,0,0,1-.58-1.392.852.852,0,0,1,.58-.244h5.87V.824A.8.8,0,0,1,6.927.239.808.808,0,0,1,7.5,0,.827.827,0,0,1,8.32.824V6.689h5.87A.829.829,0,0,1,15,7.507Z" fill="#28345c"/>
                                        </g>
                                    </svg>
                                </span>
                                <span class="date">
                                    {{ data }}
                                </span>
                            </span>
                            <span v-if="parent.selectedFilter.dates.length == 0">
                                {{toLocal('list.no_results')}}
                            </span>
                        </div>
                    </div>

                </div>
                <div class="right-input-wrapper" v-else-if="parent && parent.selectedFilter ? parent.selectedFilter.name != 'none' : true">
                    <span class="relation-label">{{getRelationLabel(parent) }}</span>
                    
                    <div class="main-input-container dropdown" :class="{selected: secondarySelectOpen}" v-click-outside="closeSecondaryList">
                        <div class="select-input" >
                            <span class="input-title" >
                                <input type="text" v-model="searchInput" :placeholder="secondaryFilterInputPlacehoder(parent)" @focus="secondarySelectOpen=true">    
                            </span>
                                <div class="dropdown-icon" @click="secondarySelectOpen=!secondarySelectOpen">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.564" height="11.881" viewBox="0 0 21.564 11.881">
                                        <path id="Path_5930" data-name="Path 5930" d="M-3721.863-17217.617a1.1,1.1,0,0,1-.772-.324l-9.69-9.687a1.094,1.094,0,0,1,0-1.547,1.089,1.089,0,0,1,1.546,0l8.916,8.914,8.918-8.914a1.085,1.085,0,0,1,1.543,0,1.093,1.093,0,0,1,0,1.547l-9.688,9.688A1.1,1.1,0,0,1-3721.863-17217.617Z" transform="translate(3732.645 17229.498)" fill="#28345c"/>
                                    </svg>

                                </div>
                        </div>
                        <div v-if="secondarySelectOpen" class="select-container">
                            <span 
                                v-for="(data, data_index) in secondarySelectFiltered" 
                                :key="data_index"
                                class="select-option"
                                :class="
                                    {selected: showSelectedIds(data) 
                                    }"
                                @click="addItem(data)"

                            >
                                {{ getDataLabel(data) }}
                            </span>
                            <span v-if="secondarySelectFiltered.length == 0">
                                {{toLocal('list.no_results')}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
        <div class="additional-filter-container">
            <div 
                v-for="(filter, filter_id) in parent.selectedAdditionalFilters" 
                class="additional-filter" 
                :key="filter_id"
                :style="{'z-index': parent.selectedAdditionalFilters.length-filter_id +1}"
            >
                <ReportDataInput 
                    :style="{'z-index': parent.selectedAdditionalFilters.length-filter_id +1}" 
                    :index="filter_id" 
                    :parent="filter"
                    :inputType="[ 'status', 'duration'].includes(filter.name) ? 'additional' : ''"
                    :dataList="filter.filters" 
                    :selectLabel="filter.selectLabel"
                    @removeFilter="removeFilter($event, parent.selectedAdditionalFilters, filter_id)"
                /> 

            </div>
            <div class="additional-filter" v-if="parent && [3,4,5].includes(parent.id)" v-click-outside="closeAdditionalList">
                <ReportDataInput inputType="main" :selectLabel="this.toLocal('generic.select_filter')" :parent="parent.additionalFilters" :dataList="parent.additionalFilters" @selected="parent.selectedAdditionalFilters.push($event)"/> 
            </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
import ReportDataInput from './ReportDataInput';
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
    name: 'ReportDataInput',
    components:{
        ReportDataInput,
        DatePicker
    },
    props:{
        dataList:{
            type: Array,
            default: () => []
        },
        selectLabel:{
            type: String,
            default: 'Pasirinkti filtrą'
        },
        parent:{
            default: () => {}
        },
        index:{
            type: Number,
            default: 0
        },
        inputType:{
            type: String
        }
    },
    data(){
        return{

            selectOpen: false,
            secondarySelectOpen: false,
            additionalSelectOpen: false,
            searchInput: '',
            secondarySelect:[
                {'title': '1'},
                {'title': '2'},
                {'title': '3'},
                {'title': '4'},
            ],
            secondarySelectFiltered: [],
        }
    },
    computed:{
        selectedLabel(){
            let index = this.dataList.findIndex((el)=>el.id == this.parent.selected_type_id);
            if(index != -1){
                return this.dataList[index].label;
            }
            return '';
        }
    },
    watch:{
        parent: {
            handler(newVal, oldVal){
            //    this.getDataList();
               if(this.parent.secondarySelect && this.parent.secondarySelect.length == 0 && !this.parent.sentRequest){
                   this.getDataList();
               }
               if(this.parent.selectedFilter){
                    if(this.parent.selectedFilter.secondarySelect && this.parent.selectedFilter.secondarySelect.length == 0){
                        this.parent.selectedFilter.secondarySelect = this.generateDateList();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        searchInput(){
            this.filter();
        },
        'parent.selectedFilter.secondarySelect':{
            immediate: true,
            handler(newVal){
                this.secondarySelectFiltered = newVal;
            }
        },
        'parent.secondarySelect':{
            immediate: true,
            handler(newVal){
                this.secondarySelectFiltered = newVal;
            }
        }
    },
    mounted(){
        if(this.parent){
            if(this.parent.selectedFilter){
                if(this.parent.selectedFilter.secondarySelect && this.parent.selectedFilter.secondarySelect.length == 0){
                    this.parent.selectedFilter.secondarySelect = this.generateDateList();
                }
            }
        }
        // this.searchData();
    },
    methods:{
        closeList(){
            this.selectOpen = false;
        },
        closeSecondaryList(){
            this.secondarySelectOpen = false;
        },
        closeAdditionalList(){
            this.additionalSelectOpen = false;
        },
        generateDateList(){
            var date = moment();
            let dates = [];
            let dates_count = 60;
            for(let i = 0; i < dates_count; i++){
                dates.push(moment().subtract(i, 'd').format('YYYY-MM-DD'));
            }
            return dates;
        },
        disabledDates(time) {
            var date = moment(String(time)).format('YYYY-MM-DD');
            var d1 =moment().format('YYYY-MM-DD');
            if (date>=d1) {
                return false;
            }
            else return true;
        },
        selectOption(data){
            if(this.inputType != 'main'){
                if(this.inputType == 'additional'){
                    this.addItem(data);
                    this.closeAdditionalList();
                }
                else{
                    this.$set(this.parent, 'selected_type_id', data.id);
                    this.parent.selectedFilter =  this.parent.filters[data.id-1];
                    this.closeList();
                    this.closeSecondaryList();
                }
                
            }
            else{
                this.$emit('selected', this._.cloneDeep(data));
                this.closeList();
            }
        },
        getDataList(){
            axios.get(`${this.$store.state.api_link}/${this.parent.searchApi}`,
            this.$store.getters.axiosConfig()
            ).then((res)=>{
                let temp = this.parent.id == 2 ? res.data : res.data.data;
                
                this.parent.secondarySelect =temp;
                this.$set(this.parent, 'sentRequest', true)
                // this.secondarySelectFiltered = this._.cloneDeep(this.secondarySelect);
            })
            .catch((err)=>{
                this.$set(this.parent, 'sentRequest', true)

            });
        },
        unwrapTests(tests){
            tests.forEach(test => {
                
            });
        },
        filter(){
            this.secondarySelectFiltered = this._.cloneDeep(this.parent.secondarySelect);
            if(this.searchInput == '') return;
            this.secondarySelectFiltered = this._.filter(this.secondarySelectFiltered, (el)=>{
                switch(this.parent.id){
                    case 1:
                        return el.name != null ? (el.name+' '+el.last_name).toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1 : false;
                    case 2:
                        return el.name != null ? el.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1 : false;
                    case 3:
                        return el.title != null ? el.title.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1 : false;
                    case 4:
                        return el.name != null ? el.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1 : false;
                    case 5:
                        return el.training_title != null && el.training_title != undefined ? el.training_title.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1 : el.title != undefined ? el.title.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1  : false;
                }
            });
        },
        getMainContainerLabel(parent){
            if(parent != undefined){
                if(parent.selected_type_id != undefined){
                    if(this.selectOpen){
                        return parent.selectLabel;
                    }
                    else{
                        return parent.selectedFilter.label;
                    }
                }
                else if(parent.selectedFilters != undefined && parent.selectedFilters.length > 0){
                    return this.inputPlaceholder(parent);
                }
                else if( parent.selectLabel) return parent.selectLabel;
                else{
                    return this.selectLabel;
                }
            }
            else{
                return this.selectLabel;
            }
        },
        getDataLabel(data){
            if(this.parent.selectedFilter && this.parent.selectedFilter.dates !=undefined){
                return data;
            }
            else{
                switch(this.parent.id){
                    case 1:
                        return data.name+' '+data.last_name;
                    case 2:
                        return data.name;
                    case 3:
                        return data.title;
                    case 4:
                        return data.name;
                    case 5:
                        if(data.title) return data.title;
                        else return data.training_title;
                    default:
                        return data.title;
                }
            }
            
        },
        addItem(data){
            if(this.inputType == 'additional'){
                if(this.parent.selectedFilter != undefined){
                    this.parent.selectedFilter = data.id
                }
                else{
                   
                    let index = this.parent.selectedFilters.findIndex(el=>el == data.id);
                    if(index != -1){
                        this.parent.selectedFilters.splice(index, 1);
                    }
                    else{
                        this.parent.selectedFilters.push(data.id);
                    }
                }
            }
            else{
                 if(this.parent.selectedFilter != undefined){
                     if(this.parent.selectedFilter.dates != undefined){
                        let index = this.parent.selectedFilter.dates.findIndex(el=>el == data);
                        if(index != -1){
                            this.parent.selectedFilter.dates.splice(index, 1);
                        }
                        else{
                            this.parent.selectedFilter.dates.push(data);
                        }
                     }
                     else{
                        if(data.title != undefined && this.parent.name=='certificates'){
                            let index = this.parent.selectedIds.findIndex(el=>el == 'doc_'+data.id);
                            if(index != -1){
                                this.parent.selectedIds.splice(index, 1);
                            }
                            else{
                                this.parent.selectedIds.push('doc_'+data.id);
    
                            }
                        }
                        else{
                            let index = this.parent.selectedIds.findIndex(el=>el == data.id);
                            if(index != -1){
                                this.parent.selectedIds.splice(index, 1);
                            }
                            else{
                                this.parent.selectedIds.push(data.id);
    
                            }
                        }
                     }
                }
                else{
                    let index = this.parent.selectedIds.findIndex(el=>el == data.id);
                    if(index != -1){
                        this.parent.selectedIds.splice(index, 1);
                    }
                    else{
                        this.parent.selectedIds.push(data.id);
                    }
                }
            }
        },
        selectAdditionalOption(data){
            this.parent.selectedAdditionalFilters.push(data);
        },
        removeFilter(data, parent, index){
            parent.splice(index, 1);
        },
        getRelationLabel(parent){
            switch(parent.name){
                case 'end':
                    if([2,3].includes(parent.selected_type_id)) return this.toLocal('reports.this_date');
                    else if([4].includes(parent.selected_type_id)) return this.toLocal('reports.including');
                    else if([5].includes(parent.selected_type_id)) return this.toLocal('reports.these_dates');
                case 'start':
                    if([2,3].includes(parent.selected_type_id)) return this.toLocal('reports.this_date');
                    else if([4].includes(parent.selected_type_id)) return this.toLocal('reports.including');
                    else if([5].includes(parent.selected_type_id)) return this.toLocal('reports.these_dates');
                case 'test_show':
                    if([1].includes(parent.selected_type_id)) return this.toLocal('reports.these_small');
                    else if([2].includes(parent.selected_type_id)) return this.toLocal('reports.these_small');
                default:
                    if(parent.selected_type_id == 1){
                        return this.toLocal('reports.including');
                    }
                    else{
                        if(parent.name == 'groups') return this.toLocal('reports.these_fem');
                        else return this.toLocal('reports.these');
                    }
            }
           
        },
        secondaryFilterInputPlacehoder(parent){
            if( parent.selectedIds && parent.selectedIds.length >0){
                let text = this.inputPlaceholder(parent);
                return text;
            }
            else{
                if(parent.selectedFilter && parent.selectedFilter.dates && parent.selectedFilter.dates.length){
                    return parent.selectedLabel;
                }
                else{
                   return parent.selectLabel;
                } 
            }
        },
        inputPlaceholder(parent){
            let selectIds = [];
            let text = '';
            if(parent.selectedIds != undefined){
                selectIds = parent.selectedIds.slice(0.5);
            }
            else{
                selectIds = parent.selectedFilters.slice(0.5);
            }
            if(parent.secondarySelect != undefined){
                text = parent.secondarySelect.filter((el)=>{
                    if(parent.name == 'certificates' && el.title != undefined){
                        return selectIds.includes('doc_'+el.id);
                    }
                    else return selectIds.includes(el.id);
                });
            }
            else{
                text  = parent.filters.filter((el)=>{
                    return selectIds.includes(el.id);
                });
            }
            if(parent.name == 'users'){
                text = text.map(el=>el.name+' '+el.last_name);
            }
            else if(parent.name == 'trainings'){
                text = text.map(el=>el.title);
            }
            else if(parent.name == 'certificates'){
                if(text[0] && text[0].title){
                    text = text.map(el=>el.title);
                }
                else{
                    text = text.map(el=>el.training_title);
                }
            }
            else if(['status', 'duration'].includes(parent.name)){
                text = text.map(el=>el.label);
            }
            else{
                text = text.map(el=>el.name);
            }
            text = text.join(', ');
            return text;
        },
        showSelectedIds(data){
            if(this.parent.selectedIds){
                if(this.parent.name =='certificates' && data.title != undefined){
                    return this.parent.selectedIds.includes('doc_'+data.id);
                }
                else{
                    return this.parent.selectedIds.includes(data.id);
                }
            }
            else if(this.parent.selectedFilter && this.parent.selectedFilter.dates){
                return this.parent.selectedFilters.includes(data.id);
            }
            else{
                return false;
            }
        }
        
    }
}
</script>

<style lang="scss" scoped>
.data-input-container{
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 1rem 0 0;
    // max-width: 30%;
    z-index: 1;
}
.row{
    display: flex;
    width: 100%;
    z-index: 2;
}

.select-input{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    background: white;
    border-radius: 1rem;
    border: 1px solid var(--primary-color);
    color: var(--primary-color-opacity-50);
    padding: 0.25rem 0.35rem 0.25rem 0.5rem;
    max-width: 100%;
    width: 100%;
    min-width: 4rem;
    z-index: 2;
    span{
        display: flex;
        align-items: center;
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        font-size: clamp(16px, 1.2vw, 19px);
    }
    .title{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }
    .main-btn{
        display: flex;
        align-items: center;
        border-radius: 2rem;
        background: white;
        width: 100%;
        .icon{
            padding: 0.25rem 0 0.25rem 0.25rem;
        }
    }
    .dropdown-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        padding: 0.15rem 0.5rem;
        background: #efefef;
    }
    &.add-btn{
        padding: 0.15rem;
        border: 1px solid var(--primary-color);
        width: 100%;
        cursor: pointer;
        .main-btn{
            padding: 0 0.5rem;
            background: #efefef;
            .title, .icon{
                color: var(--primary-color);

            }
        }
        .icon{
            padding-left: 0.25rem;
        }
    }
    &.cert{
        max-width: 2.08rem;
        text-align: center;
        margin-right: 1rem;
        padding: 0.25rem;
        input{
            text-align: center;
            height: 1.5rem;
        }
    }
    &.result{
        max-width: 4rem;
        text-align: center;
        .title{
            height: 1.5rem;
        }
        input{
            text-align: center;
        }
    }
}
.select-container{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top:50%;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: white;
    padding: 0.5rem;
    padding-top: 1.5rem;
    color: var(--primary-color);
    width: 100%;
    max-height: 16rem;
    overflow: auto;
    z-index: 1;
    span{
        cursor: pointer;
        &:hover{
            font-weight: 700;
        }
        &.selected{
            background: #efefef;
            border-radius: 0.5rem;
        }
        &.active{
            font-weight: 700;
        }
    }
}
.dropdown-icon{
    background: #efefef;
    border-radius: 50%;
    padding: 3px 4px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    svg{
        path{
            fill: var(--primary-color-opacity-50);
        }
    }
     &.selected{
        transform: rotate(180deg);
    }
}
.main-input-container{
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 15rem;
    &.small-input{
        width: initial;
    }
    &.add-btn-main{
        max-width: 8.2rem;
    }
    &.dropdown{
        &.selected{
            .dropdown-icon{
                transform: rotate(180deg);
            }
        }
        &.dates{
            .select-input{
                border:none;
            }
            .select-container{
                max-height: 12rem;
                .select-option{
                    display: flex;
                    align-items: center;
             
                    .remove{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        svg{
                            path{
                                fill: var(--primary-color-opacity-50);
                            }
                        }
                    }
                    &:hover{
                        font-weight: 500;
                        .remove{
                            svg{
                                path{
                                    fill: var(--primary-color);
                                }
                            }
                        }
                    }
                }
                span{
                    &:hover{
                        font-weight: 500;
                    }
                }
            }
        }
    }
    &.certificate{
        max-width: 5rem;
    }

}
.delete-icon{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: -7rem;
    height: 2em;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 13px;
    padding: 2px 2px 2px 0.5rem;
    border: 1px solid var(--primary-color-opacity-50);

    cursor: pointer;
    .delete-inside{

    }
    svg{
        path{
            fill: var(--primary-color-opacity-50);
        }
    }
}
.additional-filter-container{
    display: flex;
    flex-direction: column;
    z-index: 1;
    
}
.additional-filter{
    .main-input-container{
        max-width: 13rem;
        margin-left: 2rem;
        &.add-btn-main{
            max-width: 8.4rem;
        }
        &.dropdown{
            margin-left: 0rem;
        }
    }
}
.secondary-input{
    display: flex;
    align-items: center;
    width: 100%;
}
.relation-label{
    margin: 0 1rem 0 0.25rem;
}
.select-container{
    .select-option{
        padding: 0 0.5rem;
        padding: 0 8px;
        font-size: 17px;
        margin-bottom: 1px;
        &.selected{
            background: #efefef;
            border-radius: 0.5rem;
        }
    }
}
.additional-filter{
    z-index: 1;
}
.title{
    display: flex;
    align-items: center;
    &.selected{
        color: var(--primary-color);
    }
}
input{
    display: block;
    width: 100%;
    background: none;
    border: none;
    font-size: clamp(16px, 1vw, 18px);
    min-width: 100%;
    width: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:focus{
        outline: none;
    }
}

.right-input-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    &.test-result{
        .result-options{
            margin: 0 1rem;
        }
        .select-input{
            
            &.result{

            }
        }
        .small-input{
            margin: 0;
        }
        .select-container{
            width: calc(100% - 2rem);
            margin: auto;
            left: 0;
            right: 0;
        }
    }
}
.additional-delete{
    display: flex;
    align-items: center;
    margin-left: 2rem;
    position: relative;
}
.calendar-container{
    display: flex;
    margin-right: 1rem
}
</style>
<style lang="scss">
.calendar-select-input{
    display: none;
}
.custom-calendar{
    background: white;
    display: flex !important;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    cursor: pointer;
    .mx-input-wrapper{
        width: 100%;
    }
    .mx-icon-calendar{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 0px;
        right: 0px;
        margin: auto;
        height: 100%;
        padding: 0.45rem;
        svg{
            fill: var(--primary-color);
            width: 100%;
            height: 100%;
        }
    }
}
.secondary-calendar{
    background: white;
     border-radius: 2rem;
    display: flex !important;
    &.mx-datepicker{
        width: 7rem;

    }
    .mx-input{
        border-radius: 8px;
        border: 1px solid var(--primary-color);
        font-weight: 600;
        font-size: 14px;
        text-align: start;
        padding: 0 12px;
    }
      .mx-input-wrapper{
        width: 100%;
    }
    .mx-icon-calendar{
       display: none;
    }
}
</style>