<template>
    <div class="frame-container">
        <div 
            :class="['media-editing', (item.content != '' ? 'has-content' : ''), (item.uploading ? 'is-uploading' : '')]"
            v-show="!mobileUploadModalOpen && !audioRecordModalOpen"
        >

            <div :class="['is-uploading', (item.uploading ? 'active': '')]">
            <div 
                class="upload--progress"
                :style="[{'width': `${$store.state.uploadedData / item.file_size * 100}%`}]" 
            ></div>
                <div 
                class="upload--progress--label"
                :style="{'left': `max(0px, ${$store.state.uploadedData / item.file_size * 100}% - 52px)`}"
            >
                    <span>{{($store.state.uploadedData / item.file_size * 100).toFixed(0)}} %</span>
                    {{toLocal('training.file_being_uploaded')}}
                </div>
            </div>
            <input 
                :ref="`trainingItemFile`" 
                accept=".mp4,.mov,.mp3,.pdf,.pptx,.xlsx,.docx,.ppt,.xls,.doc,.png,.jpg,.jpeg,.m4a,.wav,.aac,.3gp,.amr,.odp,.ods,.odt,.webm,.gif,.bmp,.heic,.heif,.zip"
                type="file"
                style="display: none;"
                @change="$emit('onTrainingMediaChange',$event, topic_index, item_index); item.contentType = ''; item.url =''"
            >
            <div class="media" v-if="item.type == 'video'">
            <img 
                src="@/assets/images/icons/training-item-audio-record.svg"
                @click="audioRecordModalOpen = true"
            >
            <div class="description" :class="{'shown': item.content == ''}">
                {{toLocal('training_edit.upload_options.audio')}}
            </div>
            </div>
            <div class="media" v-if="options && options.showQR != undefined ? options.showQR : true">
                <img 
                    src="@/assets/images/icons/training-item-mobile-upload.svg"
                    @click="mobileUploadModalOpen = true; generateQRCode(topic_index, item_index)"
                >
                <div class="description" :class="{'shown': item.content == ''}">
                    {{toLocal('training_edit.upload_options.mobile')}}
                </div>
            </div>
            <div class="media">
                <img 
                    src="@/assets/images/icons/training-item-upload.svg"
                    @click="$refs[`trainingItemFile`].click()" 
                >
                <div class="description" :class="{'shown': item.content == ''}">
                    {{toLocal('training_edit.upload_options.computer')}}
                </div>
            </div>
            <div class="media" v-if="item.type != 'scorm'">
                <img 
                    src="@/assets/images/icons/training-item-gallery.svg"
                    @click="$emit('openGallery')"
                >
                <div class="description" :class="{'shown': item.content == ''}">
                    {{toLocal('training_edit.upload_options.elklase_gallery')}}
                </div>
            </div>
        
    
        </div>
            <div class="QR-code-modal-container" v-show="mobileUploadModalOpen">
            <div class="QR-code-modal">
                <div class="QR-code-modal-close" @click="mobileUploadModalOpen = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                    <g id="Group_6902" data-name="Group 6902" transform="translate(-1283 -278)">
                        <g id="Ellipse_493" data-name="Ellipse 493" transform="translate(1283 278)" fill="none" stroke="#28345c" stroke-width="1">
                        <circle cx="21" cy="21" r="21" stroke="none"/>
                        <circle cx="21" cy="21" r="20.5" fill="none"/>
                        </g>
                        <g id="Group_5546" data-name="Group 5546" transform="translate(1304.003 283.224) rotate(45)">
                        <g id="Path_749" data-name="Path 749">
                            <path id="Path_751" data-name="Path 751" d="M200.6,57.828a1.2,1.2,0,0,1-.355.853,1.176,1.176,0,0,1-.853.363h-8.728v8.728a1.226,1.226,0,0,1-1.217,1.208,1.2,1.2,0,0,1-.853-.355,1.176,1.176,0,0,1-.363-.853V59.044h-8.728a1.219,1.219,0,0,1-.862-2.07,1.267,1.267,0,0,1,.862-.363h8.728v-8.72a1.186,1.186,0,0,1,.363-.87,1.2,1.2,0,0,1,.853-.355,1.23,1.23,0,0,1,1.217,1.225v8.72H199.4A1.232,1.232,0,0,1,200.6,57.828Z" transform="translate(-178.297 -46.666)" fill="#28345c"/>
                        </g>
                        </g>
                    </g>
                </svg>
                </div>
                <qrcode-vue :key="item.QRLink" :value="item.QRLink ? item.QRLink : ''" :size="200" level="H" />
            </div>
            </div>
            <div class="audio-record-modal-container" v-show="audioRecordModalOpen">
            <div class="audio-record-modal">
                <div class="audio-modal-close" @click="audioRecordModalOpen = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                    <g id="Group_6902" data-name="Group 6902" transform="translate(-1283 -278)">
                        <g id="Ellipse_493" data-name="Ellipse 493" transform="translate(1283 278)" fill="none" stroke="#28345c" stroke-width="1">
                        <circle cx="21" cy="21" r="21" stroke="none"/>
                        <circle cx="21" cy="21" r="20.5" fill="none"/>
                        </g>
                        <g id="Group_5546" data-name="Group 5546" transform="translate(1304.003 283.224) rotate(45)">
                        <g id="Path_749" data-name="Path 749">
                            <path id="Path_751" data-name="Path 751" d="M200.6,57.828a1.2,1.2,0,0,1-.355.853,1.176,1.176,0,0,1-.853.363h-8.728v8.728a1.226,1.226,0,0,1-1.217,1.208,1.2,1.2,0,0,1-.853-.355,1.176,1.176,0,0,1-.363-.853V59.044h-8.728a1.219,1.219,0,0,1-.862-2.07,1.267,1.267,0,0,1,.862-.363h8.728v-8.72a1.186,1.186,0,0,1,.363-.87,1.2,1.2,0,0,1,.853-.355,1.23,1.23,0,0,1,1.217,1.225v8.72H199.4A1.232,1.232,0,0,1,200.6,57.828Z" transform="translate(-178.297 -46.666)" fill="#28345c"/>
                        </g>
                        </g>
                    </g>
                </svg>
                </div>
                <AudioRecord v-if="audioRecordModalOpen" @fileBlob="$emit('onTrainingMediaChange',$event, topic_index, item_index);" @closeModal="audioRecordModalOpen = false"> </AudioRecord>
            </div>
            </div>
        </div>
</template>

<script>
import AudioRecord from '@/components/layout/AudioRecord'
import QrcodeVue from 'qrcode.vue'
import axios from 'axios'
export default {
    props: {
        item: Object,
        topic_index: Number,
        item_index: Number,
        trainingType: String,
        options: Object
    },
    components: {
        AudioRecord,
        QrcodeVue
    },
    data() {
        return {
            mobileUploadModalOpen: false,
            audioRecordModalOpen: false
        }
    },
    methods: {
        generateQRCode(topicIndex, itemIndex){
            axios.post(`${this.$store.state.api_link}/training/generate-qr`,
                {training: this.$store.state.currentTraining, type: this.trainingType, topicIndex: topicIndex, itemIndex: itemIndex, api_key: this.$store.state.lms_key},
                this.$store.getters.axiosConfig()
                ).then((res)=>{
                    if(res.data.status == 'success'){
                      this.$set(this.item, 'QRLink',res.data.url );
                    }
                })
                .catch((err)=>{

                });
        },
    }
        
}
</script>

<style lang="scss" scoped>
.media-editing{
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    position: relative;
    padding: 20px 0;
    gap: 24px;
    &.is-uploading {
        height: 30px;
        display: block;
        padding: 0;

        img {
            transition: none;
            transform: scale(0);
        }
        .media{
            display: none;
        }
      }
    .is-uploading{
        border-radius: 20px;
        overflow: hidden;
        display: none;
        &.active {
            display: block;
            position: relative;
        }

        .upload--progress {
            background-color: var(--primary-color);
            height: 30px;
            transition: .1s width;
            border-radius: 20px;
            opacity: .36;
            transition: width 0.3s;
        }
        .upload--progress--label{
            position: absolute;
            color:#707070;
            top: 0;
            height: 30px;
            display: flex;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            transition: left 0.3s;
            span{
            color:var(--primary-color);
            margin-right: 15px;

            }
        }
    }
}
.media{
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    img{
        width: 75px;
        height: 75px;
        max-height: 100%;
        max-width: 100%;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 10px;
        -webkit-transition: all .3s;
        transition: all .3s;
        &:hover{
            transform: scale(1.1);
        }
    }
}
.description{
    display: flex;
    color: var(--primary-color);
    &.shown{
        display: flex;
        height: 40px;
        margin-top: 8px;
    }
}

.audio-record-modal-container, .QR-code-modal-container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;
  border-radius: 10px;
  .audio-record-modal, .QR-code-modal{
    position: relative;
    background: white;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    .audio-modal-close, .QR-code-modal-close{
      position: absolute;
      right: -10px;
      top: -10px;
      height: 24px;
      width: 24px;
      cursor: pointer;
      svg{
        height: 100%;
        width: 100%;
      }
    }
  }
  .QR-code-modal{
    display: flex;
    justify-content: center;
  }
}
</style>