<template>
  <input 
  type="text" 
  ref="input"
  v-model="inner_value"
  @blur="setTrueValue"
  >
</template>

<script>
import AutoNumeric from 'autonumeric';
export default {
    props:{
        value: {
            type: String | Number,
            default: ''
        },
        decimalChar: {
            type: String,
            default: ','
        }
    },
    data(){
        return{
            input: null,
            inner_value: ''
        }
    },
    watch:{
        value(){
            if(this.input == null){
                let options = {
                    decimalCharacter: ',',
                    digitGroupSeparator: ' ',
                    maximumValue: '1000',
                    minimumValue: '0',
                };
                this.input = new AutoNumeric(this.$refs.input, this.inner_value, options);
            }
        }
    },
    mounted(){
        let options = {
            decimalCharacter: ',',
            digitGroupSeparator: ' ',
            maximumValue: '1000',
            minimumValue: '0',
        };
        this.inner_value = this.value;
        this.input = new AutoNumeric(this.$refs.input, this.inner_value, options);
    },
    methods:{
      
        setTrueValue(e){
            if(this.input == null)
                this.$emit('input', e.target.value)
            else{
                this.$emit('input', this.input.getNumber())
            }
        }
    }

}
</script>

<style lang="scss" scoped>

</style>