<template>
  <div 
    @click="editText" 
    v-click-outside-2="outsideClick"
    @mouseover="$emit('disableItemDrag', true)"
    @mouseout="$emit('disableItemDrag', false)"
  >
      <!-- <div 
        v-if="!item.editing"
        :ref="`itemTitle-${topicIndex}-${itemIndex}${(!item.half || isLeftHalf(topicIndex, itemIndex)) ? '' : 'disabled'}`"
        :class="['content--item--text--inner', h2Centered(topicIndex, itemIndex) ? 'h2--centered' : '']"
        v-html="item.title+item.content"
        
        @click="editText"
        /> -->
         <editor 
            v-if="!item.editing"
            :ref="`itemTitle-${topicIndex}-${itemIndex}${(!item.half || isLeftHalf(topicIndex, itemIndex)) ? '' : 'disabled'}`"
            :class="['content--item--text--inner', h2Centered(topicIndex, itemIndex) ? 'h2--centered' : '']"
            :isEditing="isEditing"
            :readOnly="true"
            :imagesAllowed="false"
            :item="item"
            :placeholder="toLocal('training_edit.text_item_placeholder')"
            :value="item.title ? item.title +item.content : item.content"
            @click="editText"
            
        />
        <div 
        :class="['text--editing', (item.editing ? 'active' : '')]"
        >
            <input 
                class="text--editing--title"
                :ref="`itemTitle-${topicIndex}-${itemIndex}${(!item.half || isLeftHalf(topicIndex, itemIndex)) ? '' : 'disabled'}`"
                maxlength="150"
                v-model="item.title"
                type="text" 
                :placeholder="toLocal('training_edit.h2')"
          
            >
            <div 
                v-if="item.tooLong"
                class="too--long"
            >
            </div>
            <editor 
                v-if="item.editing"
                :ref="`editor-${topicIndex}-${itemIndex}`"
                :isEditing="isEditing"
                :readOnly="false"
                :item="item"
                :imagesAllowed="true"
                :charLimit="100000"
                v-model="item.content" 
                @imageModalOpen="imageModalIsOpen = $event" 
            />
            <div 
                v-if="item.tooLong"
                class="too--long"
            >
            </div>
        </div>
        <div 
        v-if="item.type == 'text'"
        :class="['media--editing', 'side--editing', 'to--side']"
        >
        <div class="media--action"  @click="removeItem(topicIndex, itemIndex)">
            <img 
            src="@/assets/images/icons/training-item-remove.svg"
            
            >
        </div>
        </div>
  </div>
</template>

<script>
import Editor from "@/components/layout/trainings/training_components/Editor.vue";


export default {
    name: 'TextItem',
    components:{
        Editor
    },
    props:{
        topic: Object,
        item: Object,
        topicIndex: Number,
        itemIndex: Number,
        isNewItem: Boolean,
    },
    data(){
        return{
            isEditing: false,
            imageModalIsOpen: false,
        }
    },
    mounted(){
        if(this.isNewItem){
            this.item.editing = true;
            this.$emit('newItemExpire');
        }
    },
   
    methods:{
        removeItem(){
            this.$emit('removeItem')
        },
        outsideClick(e){
            if(this.imageModalIsOpen) return;
            if(this.isEditing &&  $(e.target).closest('.content--item--text').length == 0){
                this.item.title = '<h2>'+this.item.title+'</h2>'
                this.item.editing = 0;
                this.isEditing = false;
            }
        },
        h2Centered (topicIndex, itemIndex) {
            if(this.item.half) {
                if(this.topic.items[itemIndex+1] && this.isLeftHalf(topicIndex, itemIndex) && 
                (this.topic.items[itemIndex + 1].type == "url" || this.topic.items[itemIndex + 1].type == "audio")){
                return true;
                }
                if(this.topic.items[itemIndex-1] && !this.isLeftHalf(topicIndex, itemIndex) && 
                (this.topic.items[itemIndex - 1].type == "url" || this.topic.items[itemIndex - 1].type == "audio")){
                return true;
                }
            }
            return false;
        },
        getHalfPosition (topicIndex, itemIndex) {
            if(this.item.half){
                let halves = 0;
                for (let i = 0; i < this.topic.items.length; i++) {
                    if(i == itemIndex){
                        return halves % 2 == 0 ? "left" : "right";
                    }
                    const item = this.topic.items[i];
                    if(item.half){
                        halves++;
                    }
                }
            }
            return false;
        },
        isLeftHalf(topicIndex, itemIndex) {
            return this.getHalfPosition(topicIndex, itemIndex) == "left";
        },
        canEditText (topicIndex, itemIndex) {
          return true;
        },
        editText(){
            this.item.title = this.item.title ? this.item.title.replaceAll('<h2>', '').replaceAll('</h2>', '') : '';
            this.$set(this.item, 'editing', 1); 
            setTimeout(()=>{

                this.isEditing = true;
            }, 100)
        }

  
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";

</style>
<style lang="scss">
.content--item--text--inner {
    h2{
    font-weight: 700;
    }
}
</style>
