<template>
  <div>
      <div
            v-for="(user, user_id) in filteredUsers"
            :key="user_id"
            class="user-answer"
            :class="{expanded: expandedIndex == user_id}"
        >
            <div class="user-col">
                <div class="user-row">
                    <h2>
                        {{user.name}} {{user.last_name}}
                    </h2>
                    <div 
                        :class="{active: user.checked}"
                        class="btn" 
                        @click="confirmMark(user)"
                    >
                        <span>
                            {{ user.isLoading != undefined && user.isLoading ? toLocal('generic.saving') : user.checked ? toLocal('test_user.evaluation_approved') : toLocal('test_user.validate')}}
                        </span>
                        <div class="icon">
                            <svg v-if="user.isLoading == undefined || !user.isLoading" xmlns="http://www.w3.org/2000/svg" width="19.246" height="16.078" viewBox="0 0 19.246 16.078">
                                <path id="Path_6137" data-name="Path 6137" d="M-379.365-14990.762l6.142,7.359,10.3-13.678" transform="translate(380.773 14998.48)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </svg>
                            <sync-loader  v-else-if="user.isLoading != undefined && user.isLoading" :loading="true" size="5px" ></sync-loader>
                        </div>
                    </div>
                </div>
                <div class="user-row bottom">
                    <div class="group-container">
                        <!-- {{toLocal('user_side.assigned_groups_list')}}
                        <div 
                            v-for="(group, group_id) in user.groups"
                            :key="group_id"
                            class="group"
                        >
                            {{group.name}}
                        </div> -->
                    </div>
                    <div class="expand-icon" v-if="expandedIndex == -1" @click="expandedIndex == user_id ? expandedIndex = -1 : expandedIndex = user_id; getDetailedStats(user)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.638" height="16.643" viewBox="0 0 16.638 16.643">
                            <g id="Group_5627" data-name="Group 5627" transform="translate(1.28 1.281)">
                                <g id="Group_3728" data-name="Group 3728" transform="translate(0 11.151) rotate(-45)">
                                <g id="Group_3727" data-name="Group 3727" transform="translate(0 0)">
                                    <line id="Line_95" data-name="Line 95" x1="6.548" y1="0.073" transform="translate(0 2.027)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Path_2829" data-name="Path 2829" d="M1.9,4.141.145,2.382a.494.494,0,0,1,0-.7L1.827,0" transform="translate(0 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                                </g>
                                <g id="Group_5626" data-name="Group 5626" transform="translate(6.52 4.63) rotate(-45)">
                                <g id="Group_3727-2" data-name="Group 3727" transform="translate(6.548 4.14) rotate(180)">
                                    <line id="Line_95-2" data-name="Line 95" x1="6.548" y1="0.073" transform="translate(0 2.027)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Path_2829-2" data-name="Path 2829" d="M1.9,4.141.145,2.382a.494.494,0,0,1,0-.7L1.827,0" transform="translate(0 0)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="expand-icon" v-else :class="{active: expandedIndex == user_id}" @click="expandedIndex = -1">
                        <svg  xmlns="http://www.w3.org/2000/svg" width="13.337" height="13.356" viewBox="0 0 13.337 13.356">
                            <g id="Group_5627" data-name="Group 5627" transform="translate(-0.37 -0.361)">
                                <g id="Group_3728" data-name="Group 3728" transform="translate(7.558 9.448) rotate(135)">
                                <g id="Group_3727" data-name="Group 3727" transform="translate(0 0)">
                                    <line id="Line_95" data-name="Line 95" x1="6.548" y1="0.073" transform="translate(0 2.027)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Path_2829" data-name="Path 2829" d="M1.9,4.141.145,2.382a.494.494,0,0,1,0-.7L1.827,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                                </g>
                                <g id="Group_5626" data-name="Group 5626" transform="translate(6.52 4.63) rotate(-45)">
                                <g id="Group_3727-2" data-name="Group 3727" transform="translate(0 0)">
                                    <line id="Line_95-2" data-name="Line 95" x1="6.548" y1="0.073" transform="translate(0 2.027)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Path_2829-2" data-name="Path 2829" d="M1.9,4.141.145,2.382a.494.494,0,0,1,0-.7L1.827,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                </div>
                <transition name="scale" mode="out-in">
                    <div class="user-row" v-if="expandedIndex == user_id">
                        <div class="answer-container">
                            <div 
                                v-for="(question, question_id) in getStats"
                                :key="question_id"
                                class="answer"
                            >
                                <div class="answer-col left">
                                    <div class="answer-row">
                                        <div class="answer-col sm"> {{question_id +1}}</div>
                                        <div class="answer-col">
                                            <div class="answer-row" v-html="question.text"></div>
                                            <div class="answer-row">
                                                 <div class="task-container" v-if="question.type == 1">
                                                    <div 
                                                        class="task-type-1" 
                                                    >
                                                        <div 
                                                            class="answer type-1" 
                                                            v-for="(answer, answer_index) in question.answers" 
                                                            :key="answer.id"
                                                            :class="{correct: answer.isCorrect, incorrect:  !answer.isCorrect}"
                                                        >
                                                            <div class="answer-letter" :class="{active: answer.selected}">{{String.fromCharCode(65+answer_index)}}</div>
                                                            <div class="answer-text">
                                                                {{answer.text}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="task-container" v-if="question.type == 2">
                                                    <div 
                                                        class="task-type-2" 
                                                    >
                                                        <div 
                                                            class="answer type-2"
                                                            v-for="(answer, answer_index) in question.answers" 
                                                            :key="answer.id"
                                                            :class="{correct: answer.isCorrect, incorrect: !answer.isCorrect, big: question.size == 1}"
                                                        >
                                                            <div class="answer-letter" :class="{active: answer.selected}">{{String.fromCharCode(65+answer_index)}}</div>
                                                            <div class="answer-image">
                                                                <ImageViewer
                                                                    v-if="(answer.metadata ? answer.metadata.data : false)"
                                                                    :src="`${$store.state.api_uploads_link}${answer.image}`"
                                                                    :height="question != undefined ? question.size == 1 ? 416.25 : 200 : undefined"
                                                                    :width="question != undefined ? question.size == 1 ? 740 : 200 : undefined"
                                                                    :metadata="answer.text"
                                                                    :size="question.size"
                                                                />
                                                                <NewImageEditor 
                                                                    v-if="(answer.metadata ? !answer.metadata.data : true)"
                                                                    :readOnly="true"
                                                                    :item="answer"
                                                                    type="cover"
                                                                    :height="question.size == 1 ? 187 : 151"
                                                                    :width="question.size == 1 ? 325 : 151"
                                                                    :isEditing="false"
                                                                    :src="getFileUrl(answer.image)"
                                                                
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="task-container" v-if="question.type == 3">
                                                     <div 
                                                        class="task-type-3" 
                                                    >
                                                        <div 
                                                            class="answer type-3" 
                                                            v-for="(answer, answer_index) in question.answers" 
                                                            :key="answer.id"
                                                            :class="{correct: answer.isCorrect && answer.selected, incorrect: !answer.selected }"
                                                        >
                                                            <div class="part-1">{{answer.part1}}</div>
                                                            <div class="part-1">{{answer.part2}}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="task-container" v-if="question.type == 4">
                                                    <div 
                                                        class="task-type-4" 
                                                    >
                                                        <div class="answer type-4" v-html="question.answers[0].answer_text"></div>
                                                    </div>
                                                </div>
                                                <div class="task-container" v-if="question.type == 5">
                                                    <div 
                                                        class="task-type-5" 
                                                    >
                                                        <div class="tries-container">
                                                            <div class="tries" v-if="question.answers[0].clicks">
                                                                <span>Bandymai:</span>
                                                                <span>{{question.answers[0].clicks.length}}</span>
                                                            </div>
                                                        </div>
                                                        <ClickTask
                                                        v-if="question.answers[0]"
                                                        :question="question"
                                                        :answer="question.answers[0]"
                                                        :readOnly="true"
                                                        :statsMode="true"
                                                        :clicks="question.answers[0].clicks"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="answer-col right" >
                                    <div class="answer-row">
                                        <div class="answer-col">
                                            <div class="answer-col center mb-1">
                                                <div class="answer-row center">{{toLocal('trainingEdit.test_rate')}}:</div>
                                                <div class="answer-row score-input" :class="{disabled: question.type == 5}">
                                                    <input 
                                                    v-if="question.type != 5"
                                                    type="text" 
                                                    v-model="question.set_score" 
                                                    v-mask="numberMask"
                                                    @keydown="inputNumbersOnlyInput($event, question)" 
                                                    @input="inputScore($event, question)"  
                                                    @blur="$event.target.value == '' ? question.set_score = 0 : ''">
                                                    <input 
                                                    v-else-if="question.answers[0] && question.answers[0].clicks"
                                                    type="text"
                                                    :value="question.score == 0 ? 0 : question.answers[0].clicks.length/question.score"
                                                    :readonly="true"
                                                    >
                                                </div>
                                            </div>
                                            <div class="answer-row">
                                                <div 
                                                    :class="{active: question.type != 5 ? question.set_score == question.score : ''}"
                                                    class="answer-col answer-state center"
                                                    @click="question.type != 5 ? $set(question, 'set_score', question.score) : ''"
                                                >
                                                    {{toLocal('test_user.answered_correct')}}
                                                    <svg id="Group_7025" data-name="Group 7025" xmlns="http://www.w3.org/2000/svg" width="17.85" height="17.85" viewBox="0 0 17.85 17.85">
                                                    <ellipse id="Ellipse_463" data-name="Ellipse 463" cx="8.925" cy="8.925" rx="8.925" ry="8.925" transform="translate(0 0)" fill="#45c34a"/>
                                                    <path id="Path_5921" data-name="Path 5921" d="M-379.365-14993.943l2.662,3.654,4.462-6.793" transform="translate(385.195 15002.29)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                </div>
                                                <div 
                                                    :class="{active: question.type != 5 ? question.set_score >0 && question.set_score < question.score : false }" 
                                                    class="answer-col answer-state center"
                                                    @click="question.type != 5 ? $set(question, 'set_score', question.score - 1) : ''"
                                                >
                                                    {{toLocal('test_user.answered_partially')}}
                                                    <svg id="Group_7293" data-name="Group 7293" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.85" height="17.85" viewBox="0 0 17.85 17.85">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                        <rect id="Rectangle_2253" data-name="Rectangle 2253" width="12.257" height="12.252" fill="#28345c"/>
                                                        </clipPath>
                                                    </defs>
                                                    <ellipse id="Ellipse_463" data-name="Ellipse 463" cx="8.925" cy="8.925" rx="8.925" ry="8.925" transform="translate(0 0)" fill="#f2db7d"/>
                                                    <g id="Group_7292" data-name="Group 7292" transform="translate(15.234 2.615) rotate(90)" clip-path="url(#clip-path)">
                                                        <path id="Path_6020" data-name="Path 6020" d="M12.246,11.7l-.639-2.85V8.828a1.017,1.017,0,0,0-.3-.72L3.5.3A1.032,1.032,0,0,0,2.043.3L.3,2.038A1.032,1.032,0,0,0,.3,3.5l7.811,7.811a1,1,0,0,0,.733.291l2.863.643a.486.486,0,0,0,.195,0,.45.45,0,0,0,.284-.2.46.46,0,0,0,.07-.243.378.378,0,0,0-.011-.1M.755,3.045a.391.391,0,0,1,0-.553L2.5.755A.382.382,0,0,1,2.771.639a.391.391,0,0,1,.278.116l.752.752L1.509,3.8Zm7.6,7.6L1.962,4.252,4.254,1.96l6.393,6.393Zm1.938.621-1.35-.3,2.028-2.028.324,1.45Z" transform="translate(0)" fill="#28345c"/>
                                                        <path id="Path_6021" data-name="Path 6021" d="M5.508,5.188a.313.313,0,0,1-.094.225.321.321,0,0,1-.453,0L.1.546A.317.317,0,0,1,.1.094a.321.321,0,0,1,.453,0L5.414,4.96a.318.318,0,0,1,.094.227" transform="translate(3.562 3.559)" fill="#28345c"/>
                                                    </g>
                                                    </svg>
                                                </div>
                                                <div 
                                                    :class="{active: question.type != 5 ? question.set_score == 0 : false}" 
                                                    class="answer-col answer-state center"
                                                    @click="question.type != 5 ? $set(question, 'set_score', 0) : ''"
                                                >
                                                    {{toLocal('test_user.answered_incorrect')}}
                                                    <svg id="Group_7021" data-name="Group 7021" xmlns="http://www.w3.org/2000/svg" width="17.85" height="17.85" viewBox="0 0 17.85 17.85">
                                                    <ellipse id="Ellipse_463" data-name="Ellipse 463" cx="8.925" cy="8.925" rx="8.925" ry="8.925" transform="translate(0 0)" fill="#ff6969"/>
                                                    <g id="Group_7020" data-name="Group 7020" transform="translate(8.816 0.277) rotate(45)">
                                                        <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                                                        <path id="Path_751" data-name="Path 751" d="M11.775,5.892a.635.635,0,0,1-.187.45.621.621,0,0,1-.45.192H6.53v4.607a.647.647,0,0,1-.642.638.635.635,0,0,1-.45-.187.621.621,0,0,1-.192-.45V6.534H.638A.643.643,0,0,1,.183,5.441.669.669,0,0,1,.638,5.25H5.245V.647A.626.626,0,0,1,5.437.187.634.634,0,0,1,5.887,0,.649.649,0,0,1,6.53.647v4.6h4.607A.65.65,0,0,1,11.775,5.892Z" transform="translate(0 0)" fill="#28345c"/>
                                                        </g>
                                                    </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="answer-col width-80" >
                                            <div class="answer-row label">{{toLocal('training_stats.comment')}}:</div>
                                            <div class="answer-row">
                                                <textarea :value="question.comment"  @input="setComment($event, question)" cols="30" rows="10" :placeholder="toLocal('test.enter_comment')"></textarea>
                                            </div>
                                            <div class="answer-row flex-end">
                                                <span class="word-count">{{question.comment ? question.comment.length : '0'}} / {{maxCommentLength}} {{toLocal('generic.symbols_multiple')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            

        </div>
  </div>
</template>

<script>
import axios from 'axios';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import ClickTask from "@/components/layout/trainings/training_components/ClickTask.vue";
import ImageViewer from '@/components/layout/trainings/training_components/ImageViewer.vue';
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  integerLimit: 2

});
export default {
    name:'TestCheckAnswersUsers',
    components:{
        SyncLoader,
        ClickTask,
        ImageViewer,
        NewImageEditor
    },
    props:{
        users:{
            type: Array,
            required: true,
        },
        groups:{
            type: Array,
            required: true,
        },
        type:{
            type: Number,
            required: true,
        },
        topic: {
            type: Object,
            required: true
        },
        test: {
            type: Object,
            required: true
        },
    },
    data(){
        return{
            expandedIndex: -1,
            stats: [],
            comments: [],
            maxCommentLength: 150,
            filteredUsers: [],
            numberMask
        }
    },
    watch:{
        users:{
            immediate:true,
            handler(newVal, oldVal){
                if(newVal != undefined && !Array.isArray(newVal)){
                    this.users = [newVal];
                }
                this.filteredUsers = this._.cloneDeep(this.users);
            }
        },
        // '$store.state.testCheckAnswersFilter':{
        //     immediate: true,
        //     handler(newVal){
        //         this.filterUsers(newVal);
        //     }
        // },

    },
    computed:{
        getStats(){
           return this.filteredUsers[this.expandedIndex] && this.filteredUsers[this.expandedIndex].answered ? this.filteredUsers[this.expandedIndex].answered.filter((el)=>{
               if(this.$store.state.testCheckAnswersFilter == 1){
                     return el.set_score == 0;
               }
               else if(this.$store.state.testCheckAnswersFilter == 2){
                    return el.edited == 0;
                }
               return true;
           }) : [];
        }
    },
    methods:{
         getDetailedStats(user){
            if(user.answered == undefined){
                axios.post(
                    `${this.$store.state.api_link}/training/stats/user/answers`,
                    {user_id: user.id, test_id: this.test.id, user_key: user.training_code},

                    this.$store.getters.axiosConfig()
                    ).then((response) => {
                        this.stats= response.data.questions;
                        // this.stats = Array.from(new Set(this.stats.map(a => a.id)))
                        //     .map(id => {
                        //         return this.stats.find(a => a.id === id)
                        //     });
                        this.$set(user, 'answered', this.stats);
                    })
                    .catch(() => {
                        console.log("Server error while getting user statistics");
                    });
            }
        },
        countWords(text) {
            if(text){
                let words = 0;
                if ((text.match(/\S+/g)) != null) {
                    words = text.match(/\S+/g).length;
                }
                return words
            }
            else return 0;
        },
        confirmMark(user){
            if(user.answered == undefined) return;
            this.$set(user, 'isLoading', true);
            axios.post(
                `${this.$store.state.api_link}/training/stats/confirm/answer`,
                {user_id: user.id, answered: user.answered, test_id: this.test.id, user_key: user.training_code},

                this.$store.getters.axiosConfig()
                ).then((response) => {
                   this.$set(user, 'checked', true);
                   this.expandedIndex = -1;
                    this.$set(user, 'isLoading', false);

                })
                .catch(() => {
                    console.log("Server error while getting user statistics");
                    this.$set(user, 'isLoading', false);
                });
        },
        stripHTML(html){
            return html.replace(/<(?:.|\n)*?>/gm, '');
        },
        setComment(e, question){
            if(e.target.value.length > this.maxCommentLength){
                e.target.value = e.target.value.substring(0, this.maxCommentLength);
                this.$set(question, 'comment', e.target.value);
            }
            else{
               this.$set(question, 'comment', e.target.value);
            }
           
        },
        filterUsers(index){
            switch(index){
                case 0:
                    this.filteredUsers = this._.cloneDeep(this.users)
                    break;
                case 1:
                    this.filteredUsers = this.users.filter(el=>el.questions.map(elem=>elem.score).includes(0));
                    break;
                case 2:
                    this.filteredUsers = this.users.filter(el=>!el.checked);
                    break;
            }
        },
        inputScore(e, question){
            let input = e.target.value;
           
            if(parseFloat(input) > question.score){
                input = question.score;
            }
            this.$set(question, 'set_score', input);
        },
        inputNumbersOnlyInput(e, question){
            let start = e.target.selectionStart;
           
            if(e.key == ',') this.$set(question, 'set_score',  [question.set_score.slice(0, start) + '.' + question.set_score.slice(start)].join(''));

        },
    }
}
</script>

<style lang="scss" scoped>
.user-row{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     width: 100%;
     &.bottom{
         margin-top: 0.5rem;
     }
 }
  .user-col{
     display: flex;
     flex-direction: column;
     width: 100%;
 }
 .user-answer{
    display: flex;
    width:100%;
    background: white;
    border-radius: 1rem;
    margin: 1rem 0;
    padding:1rem;
    color: var(--primary-color);
    transition: max-height 0.5s, height 0.5s;
    .btn{
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem 0.25rem 2rem;
        background: #FFDB93;
        border-radius: 1rem;
        cursor: pointer;
        user-select: none;
        svg{
            // margin-left: 1rem;
            path{
                stroke: var(--primary-color);
            }
        }
        &.active{
            background: var(--secondary-color);
            font-weight: 700;
        }
    }
    &.expanded{
        max-height: 100%;
        height: 100%;
    }

 }

.task-container{
    display: flex;
    flex-direction: column;
    width: 100%;
 }
.task-type-1{
    background: white;
    border-radius: 1rem;
    padding: 1rem 0.5rem;
    margin: 0.5rem 0;
    p{
        margin: 0;
    }
}
.label{
    padding: 0 0.5rem;
}
.word-count{
    font-size: clamp(12px, 1vw, 16px);
    color: var(--primary-color-opacity-50);
}

 .answer-letter{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
 .answer-container{
    // background: #efefef;
    // padding: 1rem;
    border-radius: 1rem;
    width: 100%;
    margin: 1rem 0;
    .answer{
        display: flex;
        background: #efefef;
        padding: 1rem;
        border-radius: 1rem;
        padding: 1rem;
        margin: 1rem 0;
        color: var(--primary-color);
        
    }
 }
 .answer-row{
     display: flex;
     flex-direction: row;
     width: 100%;
     font-size: clamp(12px, 1.1vw, 20px);
     &.center{
        justify-content: center;
        align-items: center;
    }
   
 }
 .answer-col{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.sm{
        width: 5%;
    }
    &.center{
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &.disabled{
        position: relative;
        svg{
            ellipse{
                fill: #aeaeae;
            }
        }
        &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(239, 239, 239, 0.48);
            cursor: not-allowed;
        }
    }
 }
 .flex-end{
     justify-content: flex-end;
 }
.answer-state{
    position: relative;
    background: rgba(255, 255, 255, 0.377);
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 30%;
    margin: 0 auto;
    font-size: clamp(12px, 0.8vw, 20px);
    cursor: pointer;
    &.active{
        background: white;
        &::after{
            display: none;
        }
    }
    &::after{
        display: flex;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.541);
        border-radius: 1rem;
    }
    &:first-child{
        margin-left: 0.3rem;
    }
    &:last-child{
        margin-right: 0.3rem;
    }
}
 .score-input{
     display: flex;
     padding: 0.125rem;
     background: none;
     border: 1px solid var(--primary-color);
     border-radius: 1rem;
     width: 3rem;
     input{
         display: flex;
         width: 100%;
         background: none;
         border: none;
         font-weight: 900;
         color: var(--primary-color-opacity-50);
        text-align: center;
        justify-content: center;
        &:focus{
            outline: none;
        }
     }
     &.disabled{
        background: #efefef;
        border-color: #d7d7d7;
        input{
            color: #d7d7d7;
        cursor: not-allowed;

        }
        cursor: not-allowed;

     }
 }
 .task-type-2{
     display: flex;
     flex-wrap: wrap;
 }
  .answer{
     .answer-letter{
        background: white;
        border-radius: 50%;
        padding: 0.25rem;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.active{
            background: var(--secondary-color);
        }  
    }
    &.type-1{
        display: grid;
        grid-template-columns: 1fr 9fr;
        background: #efefef;
        margin: 0.5rem 0;
        padding: 0.5rem 0.5rem;
        border-radius: 1rem;
        .answer-text{
            display: flex;
            align-items: center;
            margin-left: 1rem;
            max-width: 21rem;
            width: 100%;
        }
        &.correct{
            border:3px solid #6ACD77;
        }
        &.incorrect{
             border:3px solid #F47979
        }
    }
    &.type-2{
        position: relative;
        padding: 0;
        max-width: 170px;
        width: 170px;
        height: 170px;
        margin: 1rem 0.25rem;
        overflow: hidden;
        .answer-letter{
            position: absolute;
            left: 0.5rem;
            top:0.5rem;
            z-index: 2;
        }
        .answer-image{
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 10rem;
            background: #efefef;
            border-radius: 0.5rem;
            overflow: hidden;
            object-fit: contain;
            img{
                width: 100%;
                height: 100%;
            }
        }
         &.correct{
            border:3px solid #6ACD77;
        }
        &.incorrect{
             border:3px solid #F47979
        }
        &.big{
            max-width: 350px;
            width: 350px;
            height: 196px;
            .answer-image{
                max-height: 350px;
                
            }
        }
    }
    &.type-3{
        display: flex;
        margin: 1rem 0 ;
        background: white;
        border-radius: 0.5rem;
        padding: 0;
        .part-1, .part-2{
            display: flex;
            padding: 1rem 0.75rem;
            width: 100%;
            // background: white;
            
        }
        &.correct{
            border:3px solid #6ACD77;
        }
        &.incorrect{
             border:3px solid #F47979
        }
    }
    &.type-4{
        display: block;
        background: white;
        padding: 0.25rem 0.5rem;
        min-height: 8rem;
        p{
            width: 100%;
            max-width: 100%;
            word-wrap: anywhere;
            margin: 0;
            padding: 0;
        }
    }
 }
.task-type-5{
        .tries-container{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;
        .tries{
            display: flex;
            background: white;
            padding: 2px 10px;
            border-radius: 10px;
            width: 6rem;
            justify-content: center;
            align-items: center;
            text-align: right;
            font-size: 16px;
            span:nth-child(2){
                margin: 0 11px;
                font-weight: 700;
                margin-bottom: -2px;
            }
        }
    }
}
 .mb-1{
    margin-bottom: 1rem;
 }
 .left{
     border-right: 2px solid var(--primary-color);
     padding-right: 1rem;
     width: 50%;
 }
 .right{
     width: 50%;

 }
 .group-container{
     display: flex;
     flex-direction: row;
     .group{
        display: flex;
        align-items: center;
         margin: 0 0.25rem;
     }
 }
 textarea{
     border-radius: 0.5rem;
     border:1px solid var(--primary-color);
     background: none;
     width: 100%;
     padding: 0.5rem;
      resize: none;
 }
 .icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 2rem;
 }
 .expand-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #efefef;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 0.3rem;
    cursor: pointer;
    user-select: none;
    svg{
        height: 1rem;
        width: 1rem;
    }
    &.active{
        background: var(--secondary-color);
    }
 }
.width-80{
     width: 80%;
 }
 h2{
     margin: 0;
     font-weight: 700;
     color: var(--primary-color);
     font-size: clamp(20px, 1.5vw, 32px);
 }
</style>
<style lang="scss" scoped>
.scale-enter-active, .scale-leave-active {
    transition: height .5s, max-height .5s, opacity .5s;
    opacity: 1;
    max-height: 100%;
    overflow: hidden;
}
.scale-enter, .scale-leave-to {
    opacity: 0;
    max-height: 0;
    
}
</style>
<style lang="scss">
.task-type-1{
    p{
        margin: 0;
    }
}
.v-spinner{
    .v-sync{
        background-color: var(--primary-color) !important;
    }
}
.user-answer{
    .answer-container{
        .answer-row{
             p{
                margin: 0;
            }
        }
    }
}
</style>