var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$store.state.pageRerenderKey,class:{
    'header-hidden':
      _vm.$store.state.header_hidden ||
      !_vm.$store.state.isLoggedIn ||
      !_vm.$store.state.header ||
      !_vm.$store.state.header_enabled,
  },attrs:{"id":"app"}},[(_vm.$store.state.alertMessages.length > 0)?_c('div',{staticClass:"alert"},_vm._l((_vm.$store.state.alertMessages),function(msg,msgIndex){return _c('span',{key:msgIndex},[_vm._v(" "+_vm._s(msg)),_c('br')])}),0):_vm._e(),_c('LoadingScreen',{ref:"loading"}),_c('CookieModal'),_c('InfoModal'),(_vm.$store.state.user_is_new == 1 && _vm.$route.name != 'privacyPolicy')?_c('FirstLoginModal'):_vm._e(),(_vm.$store.getters.isCurrentSide('training-comments-side'))?_c('TrainingCommentsSide'):_vm._e(),_c('Header',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isLoggedIn),expression:"$store.state.isLoggedIn"}],ref:"header"}),_c('Toast',{ref:"toast"}),_c('Menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isLoggedIn),expression:"$store.state.isLoggedIn"}]}),_c('ChatSide'),_c('Certificates',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isLoggedIn),expression:"$store.state.isLoggedIn"}],ref:"certificates"}),_c('DocumentSide',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isLoggedIn),expression:"$store.state.isLoggedIn"}]}),(_vm.$store.state.privacy_policy_modal)?_c('PrivacyPolicy'):_vm._e(),_c('item-preview-modal'),(_vm.loaded)?_c('section',{class:( _obj = {
      'router--wrapper': true
    }, _obj[_vm.$route.name+'-view'] = true, _obj['header--hidden'] =  !_vm.$store.state.header, _obj['header--disabled'] =  !_vm.$store.state.header_enabled, _obj['header--temp--hidden'] =  _vm.$store.state.header_hidden, _obj['non-logged-in'] =  !_vm.$store.state.isLoggedIn, _obj['preview-mode'] =  _vm.$store.state.reportForming.previewMode, _obj )},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('keep-alive',{attrs:{"include":_vm.$store.state.user_id == null ? [] : _vm.keepAliveComponents}},[_c('router-view')],1)],1)],1):_vm._e(),(_vm.$route.meta && _vm.$route.meta.showFooter && _vm.loaded && _vm.systemSettings.showFooter)?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }