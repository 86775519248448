import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import TipTapImageComponent from './TipTapImageComponent.vue'
export default Image.extend({
    name: 'ResizableImage',
    allowGapCursor: true,
    addAttributes() {
        return {
            ...this.parent(),

            width: {
                default: '0',
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width
                    };
                }
            },

            height: {
                default: '0',
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height
                    };
                }
            },
            x: {
                default: '0',
                renderHTML: (attributes) => {
                    return {
                        x: attributes.x
                    };
                }
            },
            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            }
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),

            toggleResizable:
                () =>
                    ({ tr }) => {
                        const { node } = tr?.selection;

                        if (node?.type?.name === 'ResizableImage') {
                            node.attrs.isDraggable = !node.attrs.isDraggable;
                        }
                    }
        };
    },

    addNodeView() {
        return VueNodeViewRenderer(TipTapImageComponent);
    }
});
