<template>
<div 
    v-if="folder"
    :class="{'dragover': draggingOver, selected: selectedFolder == folder.id}"
    class="folder-container" 
    
    @drop="dropItem(); draggingOver = false" 
    @dragover="draggingOver = true"  
    @dragleave="draggingOver = false"
    @mouseleave="draggingOver = false"
>
    <div class="folder-tree-select">
        <div class="tree-select" @click.stop="clickFolder($event, folder)"  >
                <div class="icon arrow" :class="{selected: selectedFolder == folder.id}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.103" height="9.422" viewBox="0 0 17.103 9.422">
                    <path id="Path_5930" data-name="Path 5930" d="M-3724.094-17220.074a.884.884,0,0,1-.612-.254l-7.685-7.687a.865.865,0,0,1,0-1.225.861.861,0,0,1,1.226,0l7.071,7.068,7.073-7.068a.859.859,0,0,1,1.224,0,.863.863,0,0,1,0,1.225l-7.684,7.688A.884.884,0,0,1-3724.094-17220.074Z" transform="translate(3732.645 17229.496)" fill="#28345c"/>
                    </svg>
                </div>
                <div class="label"  :class="{selected: selectedFolder == folder.id}" v-click-outside-2="clickAwayInput">
                    <span v-if="!editingTitle">
                        {{folder.name}}
                    </span>
                    <input 
                        v-else-if="editingTitle" 
                        v-model="folder.name" 
                        type="text"
                        ref="folderTitle"
                        :placeholder="toLocal('generic.enter_name')" 
                        @blur="editingTitle = false"
                        
                        @mouseover="$emit('disableFolderDrag', true)"
                        @mouseleave="$emit('disableFolderDrag', false)"
                    >
                </div>
                <div class="icon" v-if="!$store.state.selectTestsModeActive" @click.stop="optionsOpen = true" v-click-outside-2="closeOptions">
                    <svg class="select-icon" :style="{'z-index': optionsOpen ? '3' : ''}" xmlns="http://www.w3.org/2000/svg" width="26" height="7" viewBox="0 0 26 7">
                    <g id="Group_9979" data-name="Group 9979" transform="translate(498.599 -710.5) rotate(90)">
                        <circle id="Ellipse_572" data-name="Ellipse 572" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 498.599) rotate(-90)" fill="#28345c"/>
                        <circle id="Ellipse_573" data-name="Ellipse 573" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 489.118) rotate(-90)" fill="#28345c"/>
                        <circle id="Ellipse_574" data-name="Ellipse 574" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 479.638) rotate(-90)" fill="#28345c"/>
                    </g>
                    </svg>
                    <div class="action-container" v-if="optionsOpen" >
                        <div class="header">

                        </div>
                        <div class="option" @click.stop="rename">
                            <div class="option-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.406" height="15.412" viewBox="0 0 15.406 15.412">
                            <g id="Group_11540" data-name="Group 11540" transform="translate(0 0)">
                                <path id="Path_8221" data-name="Path 8221" d="M15.4,14.714l-.8-3.584V11.1a1.278,1.278,0,0,0-.377-.9L4.4.38a1.3,1.3,0,0,0-1.834,0L.38,2.563A1.3,1.3,0,0,0,.38,4.4L10.2,14.219a1.254,1.254,0,0,0,.921.366l3.6.809a.611.611,0,0,0,.245,0,.566.566,0,0,0,.358-.25.578.578,0,0,0,.088-.305.475.475,0,0,0-.014-.124M.949,3.828a.491.491,0,0,1,0-.7L3.138.949A.48.48,0,0,1,3.485.8a.491.491,0,0,1,.349.146l.946.946L1.9,4.777Zm9.557,9.557L2.467,5.347,5.349,2.464,13.388,10.5Zm2.437.781-1.7-.38,2.549-2.55.407,1.823Z" transform="translate(15.406 0) rotate(90)" fill="#28345c"/>
                                <path id="Path_8225" data-name="Path 8225" d="M6.926,6.523a.393.393,0,0,1-.118.283.4.4,0,0,1-.569,0L.12.687a.4.4,0,0,1,0-.569.4.4,0,0,1,.569,0L6.808,6.237a.4.4,0,0,1,.118.286" transform="translate(10.93 4.479) rotate(90)" fill="#28345c"/>
                            </g>
                            </svg>

                            </div>
                            <span class="option-label">
                                {{toLocal('generic.rename')}}
                            </span>
                        </div>
                        <div class="option" @click="deleteFolder">
                            <div class="option-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.694" height="14.872" viewBox="0 0 11.694 14.872">
                                    <g id="Group_10004" data-name="Group 10004" transform="translate(0 0)">
                                        <g id="Group_291" data-name="Group 291" transform="translate(0 0)">
                                        <path id="Rectangle_184" data-name="Rectangle 184" d="M6927.982,1771.573h9.547v8.808a3.123,3.123,0,0,1-3.117,3.12H6931.1a3.124,3.124,0,0,1-3.121-3.12Zm8.846.7h-8.144v8.106a2.421,2.421,0,0,0,2.419,2.418h3.308a2.422,2.422,0,0,0,2.417-2.418Z" transform="translate(-6926.94 -1768.63)" fill="#28345c"/>
                                        <path id="Path_550" data-name="Path 550" d="M6933.777,1764.2l.084,0a1.224,1.224,0,0,1-.155,2.443h-9.176a1.222,1.222,0,0,1,0-2.444h9.178Zm0,1.746a.522.522,0,0,0,.036-1.042.463.463,0,0,0-.071,0h-9.211a.52.52,0,1,0,0,1.04h9.247Z" transform="translate(-6923.308 -1763.003)" fill="#28345c"/>
                                        <path id="Path_551" data-name="Path 551" d="M6942.827,1761.029h-4.29l-.088-.22a1.225,1.225,0,0,1,1.13-1.682h2.2a1.226,1.226,0,0,1,1.136,1.681Zm-3.763-.7h3.235a.522.522,0,0,0-.521-.5h-2.2A.522.522,0,0,0,6939.064,1760.327Z" transform="translate(-6934.868 -1759.126)" fill="#28345c"/>
                                        <g id="Group_290" data-name="Group 290" transform="translate(3.316 4.772)">
                                            <path id="Line_35" data-name="Line 35" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(0.5 0.5)" fill="#28345c"/>
                                            <path id="Line_36" data-name="Line 36" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(2.647 0.5)" fill="#28345c"/>
                                            <path id="Line_37" data-name="Line 37" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(4.795 0.5)" fill="#28345c"/>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <span class="option-label">
                                {{toLocal('generic.delete')}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="report-count">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.906" height="28.5" viewBox="0 0 33.906 28.5">
                    <g id="Group_9971" data-name="Group 9971" transform="translate(-314.802 -388.75)">
                        <path id="Path_8229" data-name="Path 8229" d="M18.329,5.8l-3.748-3a1.364,1.364,0,0,0-.865-.3L3.85,2.595A1.363,1.363,0,0,0,2.5,3.958V28.136A1.363,1.363,0,0,0,3.863,29.5H33.542a1.364,1.364,0,0,0,1.364-1.364V7.459A1.363,1.363,0,0,0,33.542,6.1H19.181A1.364,1.364,0,0,1,18.329,5.8Z" transform="translate(313.052 387)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                    </svg>
                    <span>
                        {{folder && folder[itemLabel] && folder[itemLabel].length > 0 ? folder[itemLabel].length : 0}}
                    </span>
                </div>
            </div>
    </div>
            <div class="item-list-container" v-if="selectedFolder == folder.id">
                <draggable 
                        v-model="folder[itemLabel]" 
                        group="items" 
                        animation="200"
                        ghostClass="ghost"
                        :options="{ disabled: !$store.state.testDrag.movingItems || dragDisabled}"
                        @start="isDraggingItems=true; $store.state.testDrag.currentlyDraggingItem = folder[itemLabel][$event.oldIndex]; $store.state.testDrag.draggingFromFolder = folder.id" 
                        @end="isDraggingItems=false; "
                        @update="updateItemListEvent"
                >
                    <transition-group type="transition" :name="!isDraggingItems ? 'flip-list' : null">
                        <div v-for="(item, item_index) in folder[itemLabel]" :key="item_index" @click="selectedItem = item.id">
                            <FolderItem
                            :key="item.name" 
                            :test="item"
                            :folder="folder"
                            :activeTest="activeTest"
                            :selectedTestID="selectedTestID"
                            @dragDisable="dragDisabled = $event; $emit('disableFolderDrag', $event)" 
                            @deleteItem="deleteSelectedItem(folder,item, item_index)" 
                            @duplicateItem="$emit('duplicateTemplate', $event, folder)"
                            @setTest="(test, folder) => $emit('setTest', test, folder)"
                            @selectModeSelectItem="(folder, test) => $emit('selectModeSelectItem', folder, test)"
                            />
                        </div>
                    </transition-group>
                </draggable>
            </div>
</div>
</template>

<script>
import FolderItem from "./FolderItem.vue";
import draggable from 'vuedraggable'
import axios from 'axios';
import _ from 'lodash';
export default {
    props: {
        folder: {
            type: Object,
            default: () => { },
        },
        itemLabel:{
            type: String,
            default: 'tests',
        },
        selectedTestID:{
            type: Number,
  
        },
        selectedFolder:{
            type: Number,
          
        },
        activeTest:{
            type: Number,
       
        }
       
    },
    data() {
        return {
            optionsOpen: false,
            isDraggingItems: false,
            draggingOver: false,
            dragDisabled: false,
            editingTitle: false
        };
    },
    watch:{
        'folder.name':{
            handler(){
                this.$root.$emit('update_test_folder', this.folder);
                this.debounceUpdate(this, this.folder);
            },
        },
    },
    methods: {
        closeOptions() {
            this.optionsOpen = false;
        },
        dropItem(e){
            if(this.$store.state.testDrag.currentlyDraggingItem.order_list || Object.keys(this.$store.state.testDrag.currentlyDraggingItem).length == 0) return;
            if(this.$store.state.testDrag.currentlyDraggingItem != {} && this.$store.state.testDrag.currentlyDraggingItem != undefined){
                this.folder[this.itemLabel].push(this.$store.state.testDrag.currentlyDraggingItem);
                // let folder_id  = this.$store.state.testDrag.searchEmailFolders.findIndex(el=>el.id == this.$store.state.testDrag.draggingFromFolder);
                // if(folder_id != -1){
                //     let item_index = this.$store.state.testDrag.searchEmailFolders[folder_id].reportList.findIndex(el=> el.id == this.$store.state.testDrag.currentlyDraggingItem.id);
                //     if(item_index != -1){
                //         this.$store.state.testDrag.searchEmailFolders[folder_id].reportList.splice(item_index, 1);
                //     }
                // }
                this.$emit('moveTest', this.$store.state.testDrag.currentlyDraggingItem, this.$store.state.testDrag.draggingFromFolder);
                this.$store.state.testDrag.currentlyDraggingItem = {};
            }
            this.draggingOver=false;
        },
        updateItemListEvent(e){
            this.updateFolder(this.folder);
        },
        deleteFolderPrompt(){
            let reportForm = this.$store.state.emailForming;
            let folder_index = reportForm.searchEmailFolders.findIndex(el=>el.id == this.folder.id);
            if(folder_index != -1){
                this.$store.state.emailForming.deleteModal.folder_index = folder_index;
            }
            this.$store.state.emailForming.deleteModal.folder = this.folder;
             this.$store.state.emailForming.deleteModal.modalOpen = true;
        },
        deleteFolder(){
           
            axios.delete(
                    `${this.$store.state.api_link}/email-folders/${this.folder.id}`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    let index= this.$store.state.emailForming.searchEmailFolders.findIndex(el=>el.id == this.folder.id);
                    if(index != -1){
                        this.$store.state.emailForming.searchEmailFolders.splice(index, 1);
                    }
                    let index_full= this.$store.state.emailForming.emailFolders.findIndex(el=>el.id == this.folder.id);
                    if(index_full != -1){
                        this.$store.state.emailForming.emailFolders.splice(index_full, 1);
                    }
                    // folder[itemLabel] = [];
                });
        },
        debounceUpdate: _.debounce((self, folder)=>{
            self.updateFolder(folder);
        }, 1000),
        updateFolder(folder){
   
            axios.put(
                    `${this.$store.state.api_link}/test-question-groups/folders`,
                    {
                        folder: folder
                    },
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    // folder = response.data.folder;
                });
            
        },
        setFolderName(e, folder){
            if(e.target.value != ''){
                folder.name = e.target.value; 
                this.$set(folder, 'editingTitle', false);
                let folder_old_index = this.$store.state.emailForming.emailFolders.findIndex(el=>el.id == folder.id);
                if(folder_old_index!= -1){
                    this.$set(this.$store.state.emailForming.emailFolders[folder_old_index], 'editingTitle', false);
                    this.$set(this.$store.state.emailForming.emailFolders[folder_old_index], 'name', e.target.value); 
                }
            }
            else return;
        },
        deleteSelectedItem(folder, item, item_index){
            let modal_data ={
                item: item,
                folder: folder,
                modalOpen: true,
            }
            this.$store.state.emailForming.deleteModal = modal_data;
        },
         startTitleEdit(e, folder){
            if(folder.editingTitle) return;
            this.$set(folder, 'editingTitle', true);
            let input = e.target.parentNode.children[1];
            setTimeout(()=>{
                if(input)
                    input.focus();
            },10);
        },
        clickAwayInput(){
            this.$set(this.folder, 'editingTitle', false);
            let folder_old_index = this.$store.state.emailForming.emailFolders.findIndex(el=>el.id == this.folder.id);
            if(folder_old_index!= -1){
                this.$set(this.$store.state.emailForming.emailFolders[folder_old_index], 'editingTitle', false);
                this.$set(this.$store.state.emailForming.emailFolders[folder_old_index], 'name', this.folder.name); 
            }
        },
        clickFolder(event, folder){
            if(this.editingTitle) return
            if(this.selectedFolder == folder.id){
                this.$emit('selectFolder', -1)
            }
            else{
                this.$emit('selectFolder', folder.id)
                }
        },
        rename(){
            this.editingTitle = true; 
            this.optionsOpen = false;
            this.$nextTick(()=>{
                this.$refs.folderTitle.focus();
            })
        },
        deleteFolder(){
            this.$store.state.testDeleteModal = {
                modalOpened: true,
                test: null,
                folder: this.folder,
                type: 'folder'
            };
        }
        
    },
    components: { FolderItem, draggable }
}
</script>

<style lang="scss" scoped>  
.folder-container{
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    height: 100%;
    right: 2px;
    width: 100%;
    padding-left: 3rem;
    &.dragover{
        background: rgb(201, 201, 201);
    }
    &.selected{

        .folder-tree-select{

            border: 2px solid var(--primary-color);
        }
        .report-count{
             border: 2px solid var(--primary-color);
             border-right: none;
             height: calc(100% + 14px);
        }
    }
}
.folder-tree-select{
    padding: 5px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 0;
}
.tree-select{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    //  border-bottom-left-radius: 0;
    // border-top-left-radius: 0;
    background: #efefef;
    padding: 0.5rem 0.5rem;
    height: calc(3rem - 10px);
    .label{
        width: 100%;
        margin-left: 0.5rem;
        color: var(--primary-color);
        cursor: pointer;
        input{
            width: 100%;
            height: 100%;
            background: none;
            border:none;
            font-size: clamp(16px, 1.2vw, 20px);
            border-bottom: 1px solid var(--primary-color-opacity-50);
            &:focus{
                outline: none;
            }
        }
        span{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 10px;
            min-width: 100%;
        }
        &.selected{
            font-weight: 700;
        }
    }
    .icon{
        padding: 0 0.25rem;
        display: flex;
        align-items: center;
        height: 1.5rem;
        width: 2rem;
        position: relative;
        cursor: pointer;
        svg{
            position: relative;
            path, circle{
                transition: fill 0.3s ease;
                fill: var(--primary-color);
            }
            rect, line{
                    stroke: var(--primary-color);
            }
            // path{
            // }
        }
        .select-icon{
            path, circle{
                fill: var(--primary-color-opacity-50);
            }
        }
        &:hover{
            .select-icon{
                path, circle{
                    fill: var(--primary-color);
                }
            }
        }
        &.arrow{
            svg{
                transition: transform 0.3s;
            }
            &.selected{
                svg{
                    transform: rotate(-180deg);
                }
            }
        }
    }
    &.report{
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        width: 100%;
        margin: 0.25rem 0;
    }
}
.action-container{
    position: absolute;
    right: -0.5rem;
    top: 0;
    border: 1px solid var(--primary-color);
    background: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 2;
    .header{
        height: 1rem;
        width: 100%;
    }
    .option{
        display: flex;
        align-items: center;
        color: var(--primary-color);
        margin: 0.25rem 0;
        .option-icon{
            display: flex;
            justify-content: center;
            width: 1rem;
            margin-right: 0.25rem;
            min-height: 16px;
            height: 1rem;
        }
        .option-label{
            width: 4.5rem;
            font-size: clamp(14px, 2vw, 16px);
        }
        &:hover{
            .option-label{
                font-weight: 700;
                
            }
        }
        svg{
            path{
                fill: var(--primary-color);
            }
        }
    }
}
.option-icon{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    svg{
        height: 100%;
        width: 0.75rem;
    }
}
.report-count{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(-2rem - 14px);
    background: white;

    top: 0;
    bottom: 0;
    margin: auto;
    height: calc(100% + 10px);
    border-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 0 0.5rem;
    span{
        position: absolute;
        font-weight: 700;
        color: var(--primary-color);
    }
    svg{
        path{
            stroke: var(--primary-color);
        }
    }
}
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
  min-width: 2rem;
   min-height: 2rem;
}
.expand-enter-from,
.expand-leave-to {
  opacity: 0;
   min-width: 0;
    min-height: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
//   background: var(--secondary-color-opacity-50);
}
.item-list-container{
}


@media(max-width:1280px){
    .report-count{
        padding: 0 0.25rem;
        svg{
            width: 18px;
            height: 18px;
        }
        span{
            font-size: 12px;
        }
        left: -28px;
    }
    .folder-container{
        padding-left:30px;
        .tree-select{
            padding: 0.25rem 0.25rem;
            height: 38px;
            .icon {
                &.arrow{
                    width: 20px;
                    height: 20px;
                }
                &:not(.arrow){
                    width: 30px;
                    height: 20px;
                }
            }
            .label{
                margin-left: 4px;
                span{
                    font-size: 14px;
                }
            }
        }
    }
}
</style>