<template>
    <div class="overlay-container" v-scroll-lock="modalOpen">
        <div class="modal-container advanced-user-selection" >
            <div 
                v-if="group"
                class="modal-header"
                :class="[`gc-${group.color_id}`]"
            >
                <div class="tabs-container">
                    <div 
                        class="tab" 
                        v-for="(tab, index) in tabs" 
                        :key="index"
                        :class="{selected: selectedTab==index}"
                        @click="selectedTab=index"
                        v-show="index == 1 ? training_count > 0 : true"
                    >
                        {{ tab.label }}
                    </div>
                </div>
                <div class="close-btn" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.00195L14.9963 14.9973" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
                        <path d="M15 1L1.00371 15" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </div>
            </div>
            <div class="modal-content">
                <GroupSelectedUsers :group="group" :groupUserFilter="filter" :includable_columns="includable_columns" :user_data="user_data" :users="users" v-if="selectedTab == 0"/>
                <GroupSelectedItems :group="group" v-if="selectedTab == 1"/>
            </div>
        </div>
    </div>
</template>

<script>
import GroupSelectedUsers from "@/components/layout/groups/GroupSelectedUsers";
import GroupSelectedItems from "@/components/layout/groups/GroupSelectedItems";
import axios from 'axios'
export default {
    props:{
        group: Object,
        user_data: Array,
        includable_columns: Array
    },
    components:{
        GroupSelectedUsers,
        GroupSelectedItems
    },
    mounted(){
        this.loadUsers();
    },
    data(){
        return{
            tabs:[
                {
                    label:this.toLocal('groups.assigned_users'),
                },
                {
                    label:this.toLocal('groups.assigned_items')
                }
            ],
            selectedTab: 0,
            users: [],
            modalOpen:true,
            filter: [],
            training_count: 0
        }   
    },
    beforeDestroy(){
        this.modalOpen = false;
    },
    activated(){
        this.modalOpen = true;  
    },
    deactivated(){
        this.modalOpen = false;
    },
    methods:{
        loadUsers(){
        axios.get( 
            `${this.$store.state.api_link}/group/users/${this.group.id}/0/50`,
            this.$store.getters.axiosConfig()
            ).then((response) => {
                this.users = response.data.users;
                this.filter = response.data.filter;
                this.training_count = response.data.training_count;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.overlay-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 105px;
    background: rgba(0, 0, 0, 0.60);
    z-index: 100;
}
.modal-container{
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 12px;
    background: #FFF;
    max-height: calc((100vh - 105px) * 0.9);
    min-width: 60vw;
    max-width: 80vw;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.advanced-user-selection{
    min-height: calc((100vh - 105px) * 0.9);
}
.modal-header{
    display: flex;
    padding: 16px 16px 0 16px;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
}
.modal-content{
    overflow: hidden;
    height: 100%;
    padding: 16px 16px 0 16px;
}
.close-btn{
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;

}
.tabs-container{
    display: flex;
    width: 100%;
    // border-bottom: 2px solid #4D4D4D;
   
}
.tab{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0px 0px;
    background: #4D4D4D;
    min-width: 240px;
    cursor: pointer;
    user-select: none;
    padding: 9px 0;
    width: 240px;
    height: 40px;
    color: white;
    text-align: center;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.8;
    &.selected{
        background: white;
        color: #333333;
        font-weight: 600;
        box-shadow: 0px -1px 5px -3px #fff;
        z-index: 2;
        opacity: 1;
    }
}
</style>