<template>
  <div class="stats-container">
      <div class="row short">
          <div class="col">
                <div class="stats-item">
                    <div class="stats-item-icon">
                        <font-awesome-icon icon="user" />
                    </div>
                    <span>
                        {{ toLocal('generic.users_who') }}
                    </span>
                    <span class="number">
                        {{user_count}}    
                    </span>
                </div>
          </div>
      </div>
      <div class="row short">
        <div class="col">
            <div class="stats-item" v-tooltip="{content: toLocal('server_stats.users_logged_in'), classes: 'tooltip-container',}"
            >
                <div class="stats-item-icon">
                    <font-awesome-icon icon="user-clock" />
                </div>
                <span>{{toLocal('generic.active_users')}}</span>
                <span class="number">{{active_user_count}}</span>    
            </div>
        </div>
      </div>
      <div class="row disk-usage">
          <div class="col" v-if="disk_usage">
              <line-chart class="chart" style="position:relative;max-height:25rem"  :chartData="disk_usage" :options="{responsive:true, maintainAspectRatio:false}"></line-chart>
          </div>
      </div>
       <div class="row no-padding" v-if="stats">
          <div class="col" >
              <div class="stats">
                <table>
                    <thead>
                        <tr >
                            <th v-for="(item, index) in header" :key="index">{{toLocal(item.text)}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in stats" :key="index">
                            <td>{{item.month}}</td>
                            <td>{{item.newly_added_users}}</td>
                            <td>{{item.users}}</td>
                            <td>{{item.user_change}}</td>
                            <td>{{item.connected_users}}</td>
                            <td>{{item.bandwidth}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </div>
      </div>
    
  </div>
</template>

<script>

import axios from 'axios'
import LineChart from '@/components/layout/charts/LineChart'
import Bar from '@/components/layout/charts/Bar'
export default {
    name: 'ServerStats',
    components:{
        LineChart,
        Bar
    },
    data(){
        return{
            user_count: 0,
            active_user_count: 0,
            disk_usage: {},
            stats: [],
            header:[
                {text: 'settings.stats_month', value: 'month'},
                {text: 'settings.stats_newly_added_users', value: 'newly_added_users'},
                {text: 'generic.users', value: 'users'},
                {text: 'settings.stats_user_change', value: 'user_change'},
                {text: 'settings.stats_connected_users', value: 'connected_users'},
                {text: 'settings.stats_bandwidth', value: 'bandwidth'},
            ]
        }
    },
    mounted(){
        this.getStats();

    },
    methods:{
        getStats(){
            axios.get(`${this.$store.state.api_link}/server/stats`, 
            this.$store.getters.axiosConfig())
            .then((res) => {
                if(res.data.status == "success"){
                    this.user_count = res.data.data.user_count;
                    this.active_user_count = res.data.data.active_users;
                    this.stats = res.data.data.stats;
                    let disk_usages = res.data.data.disk_usage;
                    let disk_usage_dates = res.data.data.disk_usage_dates;

                    this.disk_usage = {
                        labels: [...disk_usage_dates],
                        datasets:[
                            {
                                label: this.toLocal('settings.usage'),
                                backgroundColor:  document.documentElement.style.getPropertyValue(`--secondary-color`),
                                data: [...disk_usages],
                            }
                        ]
                    };
                }
            })
            .catch((err) => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.stats-container{
    width: 100%;
    margin-bottom: 5rem;
}
.stats-item{
    display: grid;
    grid-template-columns: 1fr 11fr 1fr;
    
}
.row{
    background: white;
    border-radius: 1rem;
    padding: 0.25rem 1rem;
    margin: 0.5rem 0;
    color: var(--primary-color);
    &.no-padding{
        padding: 0;
    }
    &.short{
        max-width: 555px;
    }
}
.number{
    text-align: center;
    font-weight: 700;
}
.bandwidth{
    font-size: clamp(16px, 1vw, 18px);

}
.stats{
    table{
        width: 100%;
        border-spacing: 0;
        font-size: clamp(16px, 1vw, 18px);
      
        th{
            background: var(--secondary-color);
        }
        td{
            padding: 5px 10px;
            text-align: center;
        }
        tr:last-child th:first-child {
            border-top-left-radius: 1rem;
        }
        tr:last-child th:last-child {
            border-top-right-radius: 1rem;
        }
    }
}
.disk-usage{
    max-height: 25rem;
    .col{
        max-height: 100%;
    }
    .chart{
        max-height: 100%;
    }
}
@media (max-width: 768px){
    .stats{
        overflow: auto;
        table{
            min-width: 600px;
        }
    }
}
</style>
<style lang="scss">
.bandwidth{
    font-size: clamp(16px, 1vw, 18px);
    // border-radius: 0.5rem;
    table{
        border-collapse: collapse;
        width: 100%;
        td{
            color: var(--primary-color);
        }
        tr:first-child{
            td{
                color: var(--primary-color);
                font-weight: 700;
                padding: 0.25rem;
                background: var(--secondary-color) !important;
            }
        }
    }
}

</style>