<template>
  <div class="info-modal-container">
    <div class="info-modal">
        <div class="close-btn" @click="$emit('closeModal')">
            <div>
                <span></span><span></span>
            </div>
        </div>
        <h2>{{toLocal('task_info.open_ended_check')}}</h2>
        <div class="row">
            <div class="btn" @click="$emit('closeModal')">{{toLocal('task.understood')}}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "TaskInfoModal",
}
</script>

<style lang="scss" scoped>
.info-modal-container{
  position: fixed;
  width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.info-modal{
    display: flex;
    flex-direction: column;
    position: relative;
    background: #efefef;
    border: 0.25rem solid white;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    max-width: 27rem;
    left: 11rem;
}
.close-btn{
    display: flex;
    position: absolute;
    top:0.25rem;
    right: 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    padding: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }
    span{
        display: flex;
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--primary-color);
        transform: rotate(45deg);
        &:last-child{
            transform: rotate(-45deg);
        }
    }
}
.btn{
    background: var(--secondary-color);
    color: var(--primary-color);
    font-weight: 700;
    border-radius: 2rem;
    text-align: center;
    padding: 0.25rem 2rem; 
    margin-top: 1rem;
    cursor: pointer;
}
h2{
    text-align: center;
    padding: 0 2rem;
}
.row{
    display: flex;
    justify-content: center;
}
</style>