<template>
  <div>
    <h1>Test</h1>
    <p>Test</p>
    <iframe  ref="testukas" src="" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
        var settings = {}
        window.API = new Scorm12API(settings);
        window.API.on("LMSInitialize", function() {
            console.log('initialized')
        });
        window.API.on("LMSSetValue.cmi.core.student_id", function(CMIElement, value) {
            console.log(value)
        });
        window.API.on("LMSSetValue.cmi.*", function(CMIElement, value) {
            console.log(value)
        });
        this.$refs.testukas.src = 'http://localhost:5500/'
    },
    beforeDestroy(){
    },
    methods:{
       
    }
}
</script>

<style lang="scss" scoped>
iframe{
    width: 600px;
    height: 600px;
}
</style>