<template>
    <div class="certificate-preview">
            <!-- <img :src="`${$store.state.backend_link}${certificate.cert_path}`" alt="" @load="certImageLoad"> -->
            <iframe v-if="certificate.viewSrc" :src="certificate.viewSrc" frameborder="0"></iframe>
            <!-- <div :class="{'portrait': orientation == 'portrait'}" class="name">{{$store.state.user_name}} {{$store.state.user_last_name ? $store.state.user_last_name : '' }}</div>
            <div class="finished" v-if="orientation == 'landscape'">Sėkmingai pabaigė</div>
            <div class="title" v-if="orientation == 'landscape'" >{{certificates[selectedPreviewID].training_title}}</div>
            <div  class="dates" v-if="scertificates[selectedPreviewID].expires == 1 && orientation == 'landscape'">Galioja nuo  {{certificates[selectedPreviewID].created_at.split(' ')[0] +'  iki  '+ certificates[selectedPreviewID].expiration_date.split('T')[0]}}</div>
            <div :class="{'portrait': orientation == 'portrait'}" class="dates" v-else-if="certificates[selectedPreviewID].expires == 0">Galioja neribotą laiką</div>
            <div :class="{'portrait': orientation == 'portrait'}" class="cert_id" v-if=" certificates[selectedPreviewID].cert_custom_id && orientation == 'portrait'">{{certificates[selectedPreviewID].cert_custom_id}}</div>
            <div :class="{'portrait': orientation == 'portrait'}" class="dates" v-if=" certificates[selectedPreviewID].expires == 1 && orientation == 'portrait'">{{ certificates[selectedPreviewID].expiration_date.split('T')[0]}}</div>
            <div :class="{'portrait': orientation == 'portrait'}" class="current_date" v-if=" orientation == 'portrait'">{{ certificates[selectedPreviewID].created_at.split(' ')[0]}}</div> -->

    </div>
</template>

<script>
export default {
    props:{
        certificate: Object,
    },
    data(){
        return {
            orientation: 'landscape'      
        }
    },
    methods: {
        certImageLoad(e) {
            let ratio = e.target.height/ e.target.width;
            if(ratio >1){
                this.orientation = 'portrait'
            }
            else{
                this.orientation = 'landscape'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.certificate-preview{
    position: relative;
    display: flex;
    overflow: auto;
    min-width: 70vw;
    min-height: 80vh;
    transition: all 0.5s;
        &.active{
        right:0;
    }
    img{
        width: 100%;
        max-width: 900px;
        max-height: 96vh;
        margin: auto;
        height: fit-content;
    }
    div{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        top: 53%;
    }
    .name{
        top: 42%;
        font-size: clamp(16px, 5vmin, 32px);
        &.portrait{
            top: 32.5%;
            left: 16%;
            font-size: clamp(9px, 3.5vmin, 27px);
        }
    }
    .finished{
        font-size: clamp(9px, 3vmin, 14px);
        top: 51%;
    }
    .title{
        top: 55%;
        font-size: clamp(16px, 5vmin, 28px);
    }
    .dates{
        top: 63%;
        font-size: clamp(9px, 3vmin, 14px);
        &.portrait{
            top: 67.5%;
            left: 40%;
            font-size: clamp(8px, 2vmin, 16px);
        }
    }
    .current_date{
        top: 27.5%;
        left: 6%;
        font-size: clamp(9px, 1.6vh, 16px);
    }
    .cert_id{
        top: 27.5%;
        left: 38.5%;
        font-size: clamp(9px, 1.6vh, 16px);
    }
    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>