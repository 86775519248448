<template>
  <div
    class="modal-container"
    v-if="isOpen && type == $store.state.importModal.type"
    :class="{ 'delete-modal': type == 'user-delete', 'training-invite': type=='user-training-import', table: showFailTable }"
  >
    <div class="modal">
      <div v-if="type == 'user-delete'" class="content-container">
        <span>
          {{ toLocal("import_modal.delete_confirmation") }}
        </span>
        <button @click="deleteAccount">{{ toLocal("generic.delete") }}</button>
      </div>
      <div v-else-if="type == 'user-training-import' && !showUserTable" class="content-container">
        <span>
          Pakvieskite vartotojus į šį mokymą
        </span>
         <button v-if="!loadingImport" @click="$refs.fileInput.click()">
          {{ isUploading ? "" : toLocal("import_modal.upload_training_template") }}
          <span class="progress" :style="{ width: uploadProgress + '%' }">
          </span>
          <span class="progress-text" v-if="isUploading">
            {{ toLocal("import_modal.processing") }}
          </span>
        </button>
         <input
          type="file"
          ref="fileInput"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="importTrainingUsers"
        />
        <span class="link" @click="downloadTemplate">
          {{ toLocal("import_modal.download_template") }}
        </span>
      </div>
      <div v-else-if="type == 'user-training-import' && showUserTable" class="content-container">
          <table>
            <thead>
              <tr>
                <th v-for="(item, index) in tableHeaders" :key="index">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tableData" :key="index">
                <td v-for="(key, k_i) in Object.keys(item)" :key="k_i">
                  {{ item[key] }}
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="sendInvitations">
            {{ toLocal("import_modal.send_training_invitations") }}
          </button>
      </div>
      <div v-else-if="type == 'test'" class="content-container">
        <span>
          {{ toLocal("import_modal.upload_excel") }}
        </span>
        <button v-if="!loadingImport" @click="$refs.fileInput.click()">
          {{ isUploading ? "" : toLocal("import_modal.upload_prepared") }}
          <span class="progress" :style="{ width: uploadProgress + '%' }">
          </span>
          <span class="progress-text" v-if="isUploading">
            {{ toLocal("import_modal.processing") }}
          </span>
        </button>

        <input
          type="file"
          ref="fileInput"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="upload"
        />
        <span class="link" @click="downloadTemplate">
          {{ toLocal("import_modal.download_template") }}
        </span>
      </div>
      <div
        v-else-if="type == 'users' && !showFailTable"
        class="content-container"
      >
        <span>
          {{ toLocal("import_modal.upload_users") }}
        </span>
        <button v-if="!loadingImport" @click="$refs.fileInput.click()">
          {{
            isUploading
              ? ""
              : type == "test"
              ? toLocal("import_modal.upload_prepared")
              : toLocal("import_modal.upload_users_inner")
          }}
          <span class="progress" :style="{ width: uploadProgress + '%' }">
          </span>
          <span class="progress-text" v-if="isUploading">
            {{ toLocal("import_modal.processing") }}
          </span>
        </button>
        <div class="import-loader" v-else>
          <font-awesome-icon icon="spinner" v-if="importProgress == 0" />
          <div class="progress-bar" v-else-if="importProgress > 0">
            <span
              class="progress"
              :style="{
                width: Math.min(100, Math.round(importProgress * 100, 0)) + '%',
              }"
            >
            </span>

            <span class="progress-text">
              Importuojama:
              {{ Math.min(100, Math.round(importProgress * 100, 0)) }} %</span
            >
          </div>
          <span v-if="importProgress > 0"
            >{{ toLocal("import_modal.time_left") }} ~{{ timeLeft }} s.</span
          >
        </div>

        <input
          type="file"
          ref="fileInput"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="upload"
        />
        <span class="link" @click="downloadTemplate">
          {{ toLocal("import_modal.download_template") }}
        </span>
      </div>
      <div
        v-else-if="type == 'users' && showFailTable"
        class="content-container"
      >
        <ImportFailTable :users="notAddedUsers" />
      </div>
      <div class="close" @click="toggleModal(false)">
        <span></span><span></span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;

import ImportFailTable from "@/components/layout/users/ImportFailTable.vue";
export default {
  components: {
    ImportFailTable,
  },
  props:{
    type:{
      type: String,
      default: "test"
    }
  },
  data() {
    return {
      isOpen: false,
      training_data: {
        training_id: null,
        test_id: null,
        position: null,
      },
      uploadedData: 0,
      uploadProgress: 0,
      isUploading: false,
      loadingImport: false,
      notAddedUsers: [],
      showFailTable: false,
      totalParts: 0,
      importProgress: 0,
      timeLeft: 0,
      showUserTable: false,
      tableHeaders: [],
      tableData: [],
    };
  },
  mounted() {
    this.$store.state.importModal = this;
    let vm = this;
    if(this.type == 'users'){
      this.$root.$on("user_import", (notif) => {
        vm.loadingImport = false;
        vm.importProgress = 0;
        vm.timeLeft = 0;
        if (notif.status == "success") {
          vm.$emit("refreshUsers");
          let not_added_users = notif.data.not_added;
          if (notif.data.added_users && notif.data.added_users > 0) {
            vm.$store.state.infoMessage.setMessage(
              "success",
              "Importuoti " + notif.data.added_users + " nauji vartotojai"
            );
          }
          if (not_added_users.length > 0) {
            vm.notAddedUsers = not_added_users;
            vm.showFailTable = true;
          }
        } else {
          vm.$store.state.infoMessage.setMessage("error", notif.message);
        }
      });
      this.$root.$on("user_import_progress", (notif) => {
        vm.importProgress = notif.progress;
        vm.timeLeft = notif.timeLeft;
      });
    }
  },
  beforeDestroy() {
    this.$store.state.importModal = null;
  },
  methods: {
    toggleModal(state) {
      this.isOpen = state;
      this.showFailTable = false;
    },
    setData(data) {
      this.training_data = data;
    },
    upload(e) {
      let file;
      if (e.size) {
        file = e;
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        file = files[0];
      }
      let regex = new RegExp("(.*?)\.(xlsx|csv|xls)$");
      if (file.name)
        if (!regex.test(file.name)) {
          this.$store.state.infoMessage.setMessage(
            "error",
            this.toLocal("import.failed_format")
          );
          return;
        }
      let formData = new FormData();
      formData.append("file", file);
      this.isUploading = true;
      axios
        .post(`${this.$store.state.api_link}/upload-file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.uploadedData = progressEvent.loaded;
            this.uploadProgress = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        })
        .then((response) => {
          if (this.type == "test") {
            this.importQuestions(response.data.file);
          } else if (this.type == "users") {
            this.importUsers(response.data.file);
          }
          this.isUploading = false;
          this.uploadedData = 0;
          this.uploadProgress = 0;
        })
        .catch((err) => {
          this.isUploading = false;
          this.uploadedData = 0;
          this.uploadProgress = 0;
          this.$store.state.infoMessage.setMessage(
            "error",
            this.toLocal("import_modal_import_failed")
          );
          console.log(err);
        });
    },
    importTrainingUsers(e){
      let file;
      if (e.size) {
        file = e;
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        file = files[0];
      }
      let regex = new RegExp("(.*?)\.(xlsx|csv|xls)$");
      if (file.name)
        if (!regex.test(file.name)) {
          this.$store.state.infoMessage.setMessage(
            "error",
            this.toLocal("import.failed_format")
          );
          return;
        }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("training_id", this.$store.state.currentTraining);
      this.isUploading = true;
      axios
        .post(`${this.$store.state.api_link}/read-training-invitation`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.uploadedData = progressEvent.loaded;
            this.uploadProgress = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        })
        .then((response) => {
          this.showUserTable = true;
          this.tableHeaders = [
            { text: this.toLocal("geenric.name"), value: "name" },
            { text: this.toLocal("generic.surname"), value: "last_name" },
            { text: this.toLocal("generic.email"), value: "email" },
          ];
          this.tableData = response.data.users;
          this.isUploading = false;
          this.uploadedData = 0;
          this.uploadProgress = 0;
        })
        .catch((err) => {
          this.isUploading = false;
          this.uploadedData = 0;
          this.uploadProgress = 0;
          this.$store.state.infoMessage.setMessage(
            "error",
            this.toLocal("import_modal_import_failed")
          );
          console.log(err);
        });
    },
    importQuestions(path) {
      this.loadingImport = true;
      axios
        .post(
          `${this.$store.state.api_link}/test-question-groups/import/questions`,
          {
            file_path: path,
            training_id: this.training_data.training_id,
            test_id: this.training_data.test_id,
            position: this.training_data.position,
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.loadingImport = false;
          if (response.data.status != "error") {
            let test = response.data.test;
            this.$emit("test", test);
            this.toggleModal(false);
          } else {
            this.$store.state.infoMessage.setMessage(
              "error",
              response.data.message
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.infoMessage.setMessage(
            "error",
            this.toLocal("import.error_generic")
          );
          this.loadingImport = false;
        });
    },
    downloadTemplate() {
      let file_name = "testai.xlsx";
      let template_name = "testų_šablonas.xlsx";
      if (this.type == "users") {
        file_name = "vartotojai.xlsx";
        template_name = "vartotojų_šablonas.xlsx";
      }
      if(this.type == "user-training-import"){
        file_name = "vartotojai_mokymas.xlsx";
        template_name = this.toLocal('generic.users_lc')+".xlsx";
      }

      axios
        .get(
          `${this.$store.state.api_link}/download/template/${file_name}`,
          this.$store.getters.axiosConfigDownload()
        )
        .then((response) => {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(response.data);
          a.download = template_name;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
        });
    },
    importUsers(path, part) {
      this.loadingImport = true;
      axios
        .post(
          `${this.$store.state.api_link}/users/import`,
          { file_path: path, part: part },
          this.$store.getters.axiosConfig()
        )
        .then((res) => {})
        .catch((error) => {
          if (error.response.status == 504) {
            this.$store.state.infoMessage.setMessage(
              "error",
              this.toLocal("import.error_too_long")
            );
          }
          this.loadingImport = false;
        });
    },
    sendInvitations(){
      let data = this.tableData;
        axios
        .post(
          `${this.$store.state.api_link}/training/users/invite`,
          { users: data, training_id: this.$store.state.currentTraining },
          this.$store.getters.axiosConfig()
        )
        .then((res) => {
          this.$store.state.infoMessage.setMessage(
            "success",
            this.toLocal("users.invitations_sent")
          );
          this.showUserTable = false;
          this.tableData = [];
          this.$store.state.importModal.toggleModal(false);

        })
        .catch((error) => {
          if (error.response.status == 504) {
            this.$store.state.infoMessage.setMessage(
              "error",
              this.toLocal("import.error_too_long")
            );
          }
          this.loadingImport = false;
        });
    },
    deleteAccount() {
      axios
        .delete(
          `${this.$store.state.api_link}/user/delete/account`,
          this.$store.getters.axiosConfig()
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.logout();
          }
        })
        .catch((error) => {});
    },
    logout() {
      this.$store.state.loading.loadingStart();
      axios
        .get(
          `${this.$store.state.api_link}/logout`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$router.push("/login");
          this.$store.state.user_email = null;
          this.$store.state.user_role = "user";
          window.Echo.leave(`${this.$store.state.system_settings.lms_key}.chat.${this.$store.state.user_id}`);
          window.Echo.leave(`${this.$store.state.system_settings.lms_key}.chat.admins`);
          window.Echo.leave(`${this.$store.state.system_settings.lms_key}.reportForming`);
          window.Echo.leave();
          this.$store.state.websockets = null;
          window.Echo = null;

          this.$store.state.loading.loadingDone();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/layout/_importModalScoped.scss";
table{
  max-width: 450px;
  width: 450px;
  border-collapse: separate;
  border-spacing: 0px;
  border-radius: 10px;
  border: 1px solid #acacac;
  max-height: 500px;
  overflow: auto;
  td, th {
      border-left:1px solid #acacac;
      border-top:1px solid #acacac;
  }
  th {
      border-top: none;
  }
  td:first-child, th:first-child {
      border-left: none;
  }
}
.training-invite{
  .modal{
    max-width: 700px;
    padding: 60px 20px 20px;
  }
}
</style>
