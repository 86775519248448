<template>
  
  <div class="overlay" v-scroll-lock="true">
    <div class="modal">
        <div class="close" @click="$emit('close')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.00195L16.9957 16.9967" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round"/>
                <path d="M17 1L1.00424 17" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <div class="row">
            <div class="column">
                <div class="form">
                    <div class="input-container" v-for="(input, index) in info" :key="index">

                        <label for="name">{{input.label}}</label>
                        <input type="text" v-model="input.value" :placeholder="input.placeholder">
                    </div>
                   
                </div>
            </div>
        </div>
        <div class="row flex-end">
            <button class="primary" :class="{light: isLight, saving: isSaving}" @click="save" >
                <font-awesome-icon v-if="isSaving" icon="spinner" />
                {{toLocal('settings.save')}}
            </button>
        </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            info:[
                {name: 'name', label: this.toLocal('payments.company_name_extended'), placeholder: this.toLocal('payments.placeholder_enter'), value: this.$store.state.system_settings.billingSettings ? this.$store.state.system_settings.billingSettings.name : ''},
                {name: 'company_num', label: this.toLocal('payments.company_num'), placeholder: this.toLocal('payments.placeholder_company_num'), value: this.$store.state.system_settings.billingSettings ? this.$store.state.system_settings.billingSettings.company_num : ''},
                {name: 'pvm_code', label: this.toLocal('payments.vat_number_extended'), placeholder: this.toLocal('payments.placeholder_VAT'), value: this.$store.state.system_settings.billingSettings ? this.$store.state.system_settings.billingSettings.pvm_code: ''},
                {name: 'address', label: this.toLocal('payments.address_extended'), placeholder: this.toLocal('payments.placeholder_enter'), value: this.$store.state.system_settings.billingSettings ? this.$store.state.system_settings.billingSettings.address: ''},
                {name: 'name_surname', label: this.toLocal('payments.send_to'), placeholder: this.toLocal('payments.placeholder_name_surname'), value: this.$store.state.system_settings.billingSettings ? this.$store.state.system_settings.billingSettings.name_surname :''},
                {name: 'email', label: this.toLocal('payments.email_extended'), placeholder: this.toLocal('payments.placeholder_enter'), value: this.$store.state.system_settings.billingSettings ? this.$store.state.system_settings.billingSettings.email : ''},
            ],
            isSaving: false,
        }
    },
    computed:{
        isLight(){
            let color = document.documentElement.style.getPropertyValue(`--secondary-color`);
            return color;
        }
    },
    methods:{
        save(){
            this.isSaving=true;
            let info = {
                name: this.info[0].value,
                company_num: this.info[1].value,
                pvm_code: this.info[2].value,
                address: this.info[3].value,
                name_surname: this.info[4].value,
                email: this.info[5].value,
            }
            axios.post(
                `${this.$store.state.api_link}/payment-info`,
                {'info': info},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.$store.state.system_settings.billingSettings = info;
                this.isSaving = false;
                this.$emit('closeSaved');
                this.$emit('close')
            })
            .catch(() => {
                this.isSaving = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_settingsViewScoped.scss";

.overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000bb;
}
.modal{
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 44px 32px 32px;
    border-radius: 16px;
    background: white;
    max-height: 500px;
    height: 500px;
    width: 800px;
}
.close{
    display: flex;
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}
.row{
    display: flex;
    flex-direction: row;
    max-height: 100%;
}
.column{
    display: flex;
    flex-direction: column;
}
.form{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 21px 96px;
    max-height: 368px;
    &::after{
        content: '';
        width: 2px;
        height: 100%;
        background: #7d7d7d74;
        position: absolute;
        right: -48px;
    }
}
.input-container{
    display: flex;
    flex-direction: column;
    // width: 50%;
    label{
        font-size: 16px;
        font-family: var(--main-site-font);
        color:var(--primary-color);
        margin: 0;
        margin-bottom: 8px;
        padding: 0;
        font-weight: 600;
    }
    input{
        width: 320px;
        padding: 11px 16px;
        font-size: 16px;
        font-family: var(--main-site-font);
        color:var(--primary-color);
        border: 1px solid #EFEFEF;
        box-shadow: inset 0 1px 4px 0 #00000021;
        border-radius: 8px;
        margin: 0;
    }
}
.flex-end{
    justify-content: flex-end;
}
button.primary{
    display: flex;
    align-items: center;
    margin: 16px 40px 0;
    padding: 9px 44px;
    background: var(--secondary-color);
    color: white;
    width: 250px;
    max-width: 250px;
    svg{
        margin-right: 5px;
    }
    &.light{
        color: var(--primary-color);
    }
    &.saving{
        padding: 9px 35px;
    }
}
@media (max-width: 768px){
    .modal{
        max-height: calc(100vh - 105px);
        margin-top: 105px;
        height: 100%;
        .row{
            flex-direction: column;
        }
        .form{
            gap: 15px 96px;
            max-height: initial;
        }
        .input-container{
            label{
                margin-bottom: 4px;
            }
        }
    }
}
</style>