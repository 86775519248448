<template>
    <div v-if="[1,2,3].includes(item.result)">
        <div
            class="task--result"
            :class="{correct: item.result == 1, incorrect: item.result == 2 }"
        >
           
            <div
                class="image-container"
                v-if="item.comment_file"
            >
                <img :src="getFileUrl(item.comment_file)" alt="">
            </div>
            
             <TrainingTaskCorrectSvg v-else-if="item.result == 1" class="training-svg-centered" />
            <TrainingTaskIncorrectSvg v-else-if="item.result == 2" class="training-svg-centered" />
            <svg class="training-svg-centered default-icon" v-else width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" fill="#B2B2B2"/>
                <path d="M30 47.6352L47.1905 67L57.3175 54.3446L76 31" stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h4>{{ item.result == 1 ? toLocal("training.task_correct") : item.result == 2 ? toLocal("training.task_incorrect") : toLocal('trainingEdit.answer_accepted') }}</h4>
            <p v-if="answerText && answerText.length >0" v-html="convertToHyperlinks(answerText)" >
            </p>
        </div>
    </div>
</template>

<script>
import TrainingTaskCorrectSvg from "@/assets/images/icons/task-correct.svg?inline";
import TrainingTaskIncorrectSvg from "@/assets/images/icons/task-incorrect.svg?inline";
import DOMPurify from 'dompurify';
export default {
    components: {
        TrainingTaskCorrectSvg,
        TrainingTaskIncorrectSvg,
    },
    props: {
        item: Object
    },
    data() {
        return {
            answerTextComponent: null,
            customText: ''
        }
    },
    watch: {
        answerTextComponent() {
            this.transformHyperlink()
        }
    },
    computed: {
        answerText() {
            if (this.item.result_text && this.item.result_text != "") {
                if(!this.answerTextComponent) this.answerTextComponent = this.$refs.answerText
                return this.item.result_text
            }
            else if (this.item.result == 1) {
                return this.toLocal("test.congratulations")
            }
            else if (this.item.result == 2) {
                return this.toLocal("test.incorrect_picked")
            }
            return this.toLocal("training.task_answer_comment")
        },
    },
   methods: {
        convertToHyperlinks(input) {
            // Sanitize the input
            let sanitizedInput = DOMPurify.sanitize(input);

            // Convert URLs to hyperlinks
            const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            sanitizedInput = sanitizedInput.replace(urlPattern, function(url) {
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            });
            const cleanInput = DOMPurify.sanitize(sanitizedInput, {
                ALLOWED_TAGS: ['a'],
                ALLOWED_ATTR: ['href', 'target', 'rel']
            });

            return cleanInput;
        }
    }
}
</script>

<style lang="scss" scoped>
.task--result {
    margin-top: 30px;

    h4 {
    text-align: center;
    margin-top: 15px;
    }
    p {
    border: 2px solid #707070;
    border-radius: 20px;
    padding: 15px;
    }
    &.correct p {
    border-color: #4CB953;
    }
    &.incorrect p {
    border-color: #F56363;
    }
}
  img, .training-svg-centered{
    // max-width: calc(100% - 70px);
    display: block;
    // margin: 35px auto;
    max-width: 100%;
    margin: auto;
    border-radius: 20px;
  }
  .image-container{
    position: relative;
    max-width: 100%;
    margin: auto;
    border-radius: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .img-wrapper{
        margin: 0 auto;
    }
    img{
        width: auto;
        max-width: 100%;
        max-height: 400px;
    }
    // box-shadow: 0 0 8px 0 #00000036;
}
a{
    text-decoration: underline;
}
</style>
<style lang="scss">
.task--result{
    p{
        a{
            text-decoration: underline;
            color: #4c4cd0;
        }
    }
}
</style>