<template>
  <div class="report-forming-container">
      <ReportFolders class="left" v-show="!$store.state.reportForming.previewMode" :class="{hidden: $store.state.reportForming.selectedTab == 1}"/>
      <ReportForm  class="right" v-show="!$store.state.reportForming.previewMode"/>
      <DeleteModal v-if="$store.state.reportForming.reportDeleteModal.modalOpen" />
      <PreviewMode />
  </div>
</template>

<script>
import ReportFolders from "./ReportFolders.vue";
import ReportForm from "./ReportForm.vue";
import axios from 'axios';
import DeleteModal from "./DeleteModal.vue";
import PreviewMode from "./PreviewMode.vue";
export default {
    name: "Report",
    components: { ReportFolders, ReportForm, DeleteModal, PreviewMode },
    data(){
      return{
        modalOpen: false,
        echoInitialized: false,
      }
    },
    watch:{
      '$store.state.websockets'(){
         if(!this.echoInitialized)
          this.startEchoListen();
      },
    
    },
    mounted(){
      this.getFolders();
      if(this.$store.state.reportForming.frequentDomains.length == 0){
            this.getPopularDomains();
      }
      this.startEchoListen();
    },

    methods:{
        getFolders(){
            axios.get(
                    `${this.$store.state.api_link}/report-folders`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    this.$store.state.reportForming.reportFolderList = response.data.folders;
            
                });
        },
        getPopularDomains(){
             axios.get(
                    `${this.$store.state.api_link}/user/domains`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                  let domains = response.data.domains;
                  domains = domains.filter(el => el.domain != null);
                    this.$store.state.reportForming.frequentDomains = domains.slice(0,2);
                });
        },
        startEchoListen(){
          if(!window.Echo) return;
          this.echoInitialized = true;
          window.Echo.private(`${this.$store.state.system_settings.lms_key}.reportForming`)
            .listen('ReportForming', (e) => {
             let report = e.report;
             let html = e.html;
              let path = e.file_path;
              this.$store.state.reportForming.reportUrl = path;
             if(report.id != undefined){
               this.$store.state.reportForming.periodicReportHTML = html;
                this.$store.state.reportForming.reportHTML = html;
               let folder_index = this.$store.state.reportForming.reportFolderList.findIndex(folder => folder.id == report.folder_id);
               if(folder_index != -1){
                 let report_index = this.$store.state.reportForming.reportFolderList[folder_index].reportList.findIndex(report => report.id == report.id);
                 if(report_index != -1){
                    this.$store.state.reportForming.periodicReportHTML = html;
                 }
               }

              }
              else{
               this.$store.state.reportForming.reportHTML = html;
               this.$store.state.reportForming.momentaryReport.formedReportActive = true;
              }
            this.$store.state.reportForming.reportIsForming = false;
          })
          .listen('Progress', (e) => {
            let progress = e.progress;
            this.$store.state.reportForming.progress = progress;
          });
        },


    },
    beforeDestroy(){
        window.Echo.leave(`reportForming`);
    }
}
</script>

<style lang="scss" scoped>
.report-forming-container{
  display: flex;
  margin: 2rem auto;
  margin-bottom: 5rem;
  justify-content: center;
}
.left, .right{
  margin: 0 0.5rem;
}
.left{
  position: relative;
  min-height: 65vh;
  height: auto;
  overflow: auto;
   &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    z-index: -1;
   }
}
.hidden{
  position: relative;
  overflow: hidden;
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #efefef;
    z-index: 9;
  }
}
</style>