<template>
  <div>
    <div 
      :class="['side--background', $store.getters.isCurrentSide('training-group-stats-side') ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('training-group-stats-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
        <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
          <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
            <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
              <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
            </g>
          </g>
        </g>
      </svg>
      </div>
      <div class="side--content">
        <div class="group--title">
          <span v-if="name != '...'">{{ name }}</span>
          <span v-if="name == '...'"><font-awesome-icon icon="spinner" /></span>
        </div>
        <div class="lesson--list">
          <table 
            :class="['side--table--control']"
          >
            <thead>
              <tr>
                <th class="large">
                  {{ toLocal('generic.users') }}
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement != 'name'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('name')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_started_percent') }}
                  <img
                    v-if="orderByElement == 'started' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('started')"
                  >
                  <img
                    v-if="orderByElement == 'started' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('started')"
                  >
                  <img
                    v-if="orderByElement != 'started'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('started')"
                  >
                </th>
                <th>
                  {{ toLocal('training.users_ended_date') }}
                  <img
                    v-if="orderByElement == 'ended' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('ended')"
                  >
                  <img
                    v-if="orderByElement == 'ended' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('ended')"
                  >
                  <img
                    v-if="orderByElement != 'ended'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('ended')"
                  >
                </th>
                <th>
                  Rezultatai
                  <img
                    v-if="orderByElement == 'results' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('results')"
                  >
                  <img
                    v-if="orderByElement == 'results' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('results')"
                  >
                  <img
                    v-if="orderByElement != 'results'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('results')"
                  >
                </th>
                <!-- <th>{{ toLocal('training.users_comment') }}</th> -->
                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in users"
                :key="user.id"
                :class="`gc-td-${color_id}`"
                :style="{'z-index': show_comment == user.id ? 2 : ''}"
              >
                <td>
                  <div 
                    v-if="show_comment == user.id"
                    class="comment"
                  >
                    {{ user.comment }}
                  </div>
                  {{ user.name }} {{ user.last_name }}
                </td>
                <td class="centered time">
                  <div class="border-left">
                    {{ user.started }}
                  </div>
                </td>
                <td class="centered time">
                  <div class="border-left">
                    {{ user.ended }}
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    {{ !isNaN(user.results) ? (user.results *100).toFixed(0)+' %' : '-' }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GroupSide",
  data: function(){
    return {
      "id": "-1",
      "name": "...",
      "description": "...",
      "users": [],
      "color_id": -1,
      show_comment: -1,
      loadInterval: null,
      orderByElement: "",
      orderByDirection: "",
    }
  },

  mounted() {
    this.loadInterval = setInterval(() => {
      if(!this.$store.state.side_info['loaded'] && this.$store.state.side_open == "training-group-stats-side") {
        this.$store.state.side_info['loaded'] = true;
        this.id = this.$store.state.side_info['id'];
        this.name = "...";
        this.description = "...";
        this.users = [];
        this.color_id = -1;
        this.show_comment = -1;
        axios.post( 
          `${this.$store.state.api_link}/training/stats/group`,
          {group: this.id, training: this.$store.state.currentTraining},
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.name = response.data.name;
          this.description = response.data.description;
          this.color_id = response.data.color_id;
          this.users = response.data.users;
          this.resort();
        }).catch((error) => {
          console.log(error)
        });
      }
    }, 200);
  },
  beforeDestroy() {
    if(this.loadInterval != null){
      clearInterval(this.loadInterval);
      this.loadInterval = null;
    }
  },
  methods: {
    closeSide() {
      this.$store.state.side_open = false;
      this.name = "...";
      this.description = "...";
      this.color_id = -1;
      this.users = [];
      this.show_comment = -1;
    },
    resort() {
      this.setOrderBy(this.orderByElement);
      this.setOrderBy(this.orderByElement);
    },
    openComment(user) {
      if(this.show_comment == user.id){
        this.show_comment = -1;
      }else{
        this.show_comment = user.id;
      }
    },
    setOrderBy(element) {
      if(element == "")
        return;
      if(element == this.orderByElement){
        this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
      }else{
        this.orderByElement = element;
        this.orderByDirection = "asc";
      }
      // if(this.users.length && isNaN(this.users[0][element])){
        if(this.orderByDirection == "desc"){
          if(this.users.length) this.users.sort((a,b) => b[element].toString().localeCompare(a[element].toString(), undefined, {numeric: true, sensitivity: 'base'})); 
        }else{
          if(this.users.length) this.users.sort((a,b) => a[element].toString().localeCompare(b[element].toString(),  undefined, {numeric: true, sensitivity: 'base'})); 
        }
      // }else{
      //   if(this.orderByDirection == "desc"){
      //     if(this.users.length) this.users.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
      //   }else{
      //     if(this.users.length) this.users.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
      //   }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";

    .side--background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .group--side {
        position: fixed;
        right: -850px;
        background-color: #fff;
        height: calc(100vh - 100px);
        top: 100px;
        width: 750px;
        z-index: 11;
        font-family: var(--main-site-font);
        transition: .3s right;

        &.side--open {
          right: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }
    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 47px;
            height: 47px;
            path{
              fill: var(--primary-color);
            }
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      width: 40px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -4px;
      }
    }

    .group--section--select {
      margin-top: -60px;

      .group--section--select--button {
        width: 50%;
        float: left;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        user-select: none;
        background-color: $background_color;
        box-shadow: 0 0 5px rgba(0,0,0,0);
        transition: .3s box-shadow, .3s background-color;

        &.active, &:hover {
          box-shadow: 0 0 5px rgba(0,0,0,.3);
          background-color: $group_color;
        }
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .group--title{ 
      font-size: 35px;
      padding-top: 15px;
      font-weight: 900;
      padding-left: 30px;

      span {
        font-size: 35px;
        display: block;
      }
    }

    .lesson--list {
      position: relative;
    }

    .side--table--control {
      margin-top: 50px;
      width: 100%;
      margin: 0;
      position: absolute;
      right: 0;
      transition: .3s right;
      td{
        &:first-child{
          max-width: 235px;
          overflow: hidden;
          text-overflow: ellipsis;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-child{
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      &.hidden {
        right: calc(-100% - 150px);
      }
      
      tr {
        position: relative;
        border-radius: 35px;
      }

      svg {
        margin-top: -2px;
        user-select: none;
      }

      .time {
        font-size: 15px;
        line-height: 15px;

        div {
          white-space: pre-line;
        }
      }
      
      .comment {
        position: absolute;
        left: 0;
        z-index: -1;
        top: -13px;
        padding-top: 30px;
        background-color: #efefef;
        width: 100%;
        margin-top: 46px;
        border-radius: 0 30px 30px 30px;
        padding: 30px 20px 10px;
      }
    }

    .group--add--button {
      padding: 12px;
      border-radius: 35px;
      padding-right: 35px;
      cursor: pointer;
      user-select: none;
      transition: 1s background-color, .3s transform, .3s box-shadow;
      // background-color: #EFEFEF;
      margin: 0 -35px;
      margin-top: 20px;

      .group--add--button--icon {
        width: 45px;
        height: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 100%;
        vertical-align: middle;

        svg {
          max-width: 28px;
          max-height: 28px;
          fill: var(--primary-color);
        }
      }

      .group--add--button--title {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #707070;
        width: calc(100% - 70px);

        input {
          background: transparent;
          border: none;
          outline: none;
          color: $font_color;
          font-size: 20px;
          font-family: var(--main-site-font);
          width: calc(100% - 40px);
        }
      }

      &:hover {
        box-shadow: 0px 3px 6px #00000033;
        transform: translateY(-3px);
      }
    }
</style>