<template>
    <div>
        <div class="item-main" >
        <SearchHeader 
          v-model="searchTerm" 
          :searchingForGroup="invitationType=='groups'" 
          @searchingForGroup="searchingForGroup=$event"/>
        
        <div
          class="main-list"
          v-if="groups"
        >
          <div class="sort-options" v-if="groups">
            <div class="option-with-icon"
              v-for="(item, index) in groupHeader"
              :key="index"
              :class="item.class"
              v-show="showHeaderItem(item)"
            >
              <span class="option" @click="item.sortable ? setSort('groups', item.key, item.sortOptions.type) : ''">{{ item.label }}</span>
              <div class="icon" v-if="item.sortable" @click="item.sortable ? setSort('groups', item.key, item.sortOptions.type) : ''">
                <svg  width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path  :class="{active: currentSort.groups.key==item.key && currentSort.groups.order=='asc'}" d="M8.80364 25.6332C8.38033 26.102 7.67863 26.1255 7.23304 25.6802C7.2219 25.6684 7.19957 25.645 7.18843 25.6332L0.304019 17.9689C-0.119291 17.5001 -0.0969678 16.7618 0.348621 16.3164C0.560276 16.1172 0.827701 16 1.11733 16H14.886C15.4987 16 16 16.5274 16 17.1719C16 17.4766 15.8886 17.758 15.6992 17.9806L8.80364 25.6332Z" fill="#C5C5C5"/>
                  <path  :class="{active: currentSort.groups.key==item.key && currentSort.groups.order=='desc'}" d="M7.19636 0.366759C7.61967 -0.102014 8.32138 -0.125498 8.76696 0.319836C8.7781 0.331555 8.80043 0.355039 8.81157 0.366759L15.696 8.03109C16.1193 8.49987 16.097 9.23822 15.6514 9.68356C15.4397 9.88278 15.1723 10 14.8827 10L1.11397 10C0.501288 10 8.25422e-08 9.47263 1.38892e-07 8.82807C1.65529e-07 8.52337 0.111419 8.24203 0.300795 8.01936L7.19636 0.366759Z" fill="#C5C5C5"/>
                </svg>
              </div>
            </div>
          </div>
          <span v-if="loadingGroups">{{toLocal('general.loading_text')}}</span>
          <div class="list" ref="groupList" v-else>
            <div
              class="list-item-with-users"
              v-for="(group, index) in groups"
              :key="`group-${index}`"
              @click="selectGroup(group)"
            >
              <div class="user-sticky-header" :class="{expanded: activeGroup===group.id}">
                <div 
                class="list-item" 
                :class="getGroupClass(group)"
                >
                  <div class="arrow" :class="{active: activeGroup == group.id}">
                    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L9 9L17 1" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <span class="title" :class="{ bought: !item.rented }">{{group.name}}</span>
                  <div class="user-count border-right" v-if="groupHeader.filter(e=>e.key == 'added_user_count').length > 0">
                    {{ getAddedUserCount(group) }}
                  </div>
                  <div class="user-count">
                    {{ group.users_count }}
                  </div>
                  <div class="action-container" :class="{inactive: !showHeaderItem(groupHeader.find(el=>el.key=='group_full'))}">
                    <div 
                      v-if="invitationType=='groups'"
                      class="add-group" 
                      :class="{added:isGroupAdded(group)}" 
                      @click.stop="addGroupToIndex(group, !isGroupAdded(group))"
                    >
                      <AssignCrossSvg v-if="isGroupAdded(group)" />
                      <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2347 10.0004C19.2347 10.4147 18.899 10.7504 18.4847 10.7505L10.7484 10.7507L10.7495 18.4857C10.7495 18.8999 10.4138 19.2357 9.99957 19.2358C9.58536 19.2358 9.24953 18.9001 9.24947 18.4859L9.24844 10.7508L1.51749 10.751C1.10328 10.751 0.767483 10.4153 0.767469 10.001C0.767456 9.58683 1.10323 9.25103 1.51744 9.25102L9.24825 9.25076L9.24722 1.51862C9.24717 1.1044 9.58291 0.768572 9.99712 0.768517C10.4113 0.768462 10.7472 1.1042 10.7472 1.51842L10.7482 9.25071L18.4847 9.25046C18.8989 9.25045 19.2347 9.58622 19.2347 10.0004Z" fill="#333333"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="user-header" v-if="activeGroup===group.id" @click.stop>
                    <span
                    v-for="(item, index) in userHeader"
                    :key="index"
                    :class="item.class" 
                    @click="item.sortable ? setSort('users', item.key, item.sortOptions.type, group) : ''"
                    >
                      {{item.label}}
                      <div class="icon" v-if="item.key=='added' ? invitationType == 'single' : item.sortable">
                        <svg  width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active: currentSort.users.key==item.key && currentSort.users.order=='asc'}" d="M8.80364 25.6332C8.38033 26.102 7.67863 26.1255 7.23304 25.6802C7.2219 25.6684 7.19957 25.645 7.18843 25.6332L0.304019 17.9689C-0.119291 17.5001 -0.0969678 16.7618 0.348621 16.3164C0.560276 16.1172 0.827701 16 1.11733 16H14.886C15.4987 16 16 16.5274 16 17.1719C16 17.4766 15.8886 17.758 15.6992 17.9806L8.80364 25.6332Z" fill="#C5C5C5"/>
                            <path :class="{active: currentSort.users.key==item.key && currentSort.users.order=='desc'}" d="M7.19636 0.366759C7.61967 -0.102014 8.32138 -0.125498 8.76696 0.319836C8.7781 0.331555 8.80043 0.355039 8.81157 0.366759L15.696 8.03109C16.1193 8.49987 16.097 9.23822 15.6514 9.68356C15.4397 9.88278 15.1723 10 14.8827 10L1.11397 10C0.501288 10 8.25422e-08 9.47263 1.38892e-07 8.82807C1.65529e-07 8.52337 0.111419 8.24203 0.300795 8.01936L7.19636 0.366759Z" fill="#C5C5C5"/>
                          </svg>
                      </div>
                    </span>
                </div>
              </div>
              <transition name="slide" mode="in-out">
                <UserList 
                    :users="selectedGroupUsers"
                    :group="group"
                    :activeGroup="activeGroup"
                    :isLoadingUsers="isLoadingUsers"
                    :addedUsersIndex="addedUsersIndex"
                    :training="item"
                    :invitationType="invitationType"
                    :type="type"
                    :selectedItems="selectedItems"
                    :addedUsers="addedUsers"
                    @addUserToList="(group, user, state) => addUserToList(group, user, state)"
                    @revokeLicence="$emit('revokeLicence', $event)"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
import CloseCrossFadedSvg from "@/assets/images/icons/close-cross-faded.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
import ArrowDownSvg from "@/assets/images/icons/arrow-down.svg?inline";
import OrderBySvg from "@/assets/images/icons/order-by.svg?inline";
import OrderByAscSvg from "@/assets/images/icons/order-by-asc.svg?inline";
import OrderByDescSvg from "@/assets/images/icons/order-by-desc.svg?inline";
import ArrowRightSvg from "@/assets/images/icons/arrow-right.svg?inline";
import AssignPlusSvg from "@/assets/images/icons/assign-plus.svg?inline";
import AssignCrossSvg from "@/assets/images/icons/assign-cross.svg?inline";
import SendTrainingInvitations from "@/components/layout/SendTrainingInvitations.vue";
import ToggleButton from "@/components/layout/emails/ToggleButton";
import SearchHeader from "./trainingList/SearchHeader.vue"
import UserList from "./trainingList/UserList.vue"
import axios from 'axios';
export default {
    components: {
        SendTrainingInvitations,
        ToggleButton,
        SearchHeader,
        UserList,

        CloseCrossSvg,
        CloseCrossFadedSvg,
        GroupSearchSvg,
        ArrowDownSvg,
        OrderBySvg,
        OrderByAscSvg,
        OrderByDescSvg,
        ArrowRightSvg,
        AssignPlusSvg,
        AssignCrossSvg,
    },
    props:{
        groupHeader:{
            type: Array,
            default: []
        },
        loadingGroups:{
            type:Boolean,
            default:false
        },
        userHeader:{
            type: Array,
            default:[]
        },
     
        cloneUsers:{
            type:Array,
            default:()=>[]
        },
        cloneGroups:{
            type:Array,
            default:()=>[]
        },
        usersData:{
            type:Array,
            default:()=>[]
        },
        groupsData:{
            type:Array,
            default:()=>[]
        },
        item:{
            type:Object,
            default:()=>{}
        },
        type:{
            type:String,
            default:'training'
        },
        addedUsers:{
            type:Array,
            default:()=>[]
        },
        mode:{
          type:String,
          default: 'trainings',
        },
        invitationType:{
            type:String,
        },
        selectedItems:{
            type:Array,
        }
    },
    data(){
        return{
            searchTerm: '',
            users: [],
            groups: [],
            searchingForGroup:true,
            currentSort: {
                'groups':{
                    key: '',
                    order: '',
                    type: ''
                },
                'users':{
                    key: '',
                    order: '',
                    type: ''
                },
            },
            activeGroup: -1,
            isLoadingUsers: false,
            addedUsersIndex: [],
        }
    },

    watch:{
        addedUsersIndex: {
            handler(newVal) {
                if (this.item.rented) {
                    this.usableLicenses = this.item.license_count - newVal.length;
                }
            },
        },
        searchTerm: {
            handler(newVal, oldVal) {
                if (newVal == "") {
                    if(this.searchingForGroup  && this.type != 'invitations'){
                        this.groups = _.cloneDeep(this.cloneGroups);
                    }
                    else{
                        this.users = _.cloneDeep(this.cloneUsers);
                    }
                } else {
                    if(this.searchingForGroup && this.type != 'invitations'){
                        this.groups = this.cloneGroups.filter((group) =>
                            group.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                        );
                    }
                    else{
                        this.users = this.cloneUsers.filter((user) =>{
                            return user.name && user.name.toLowerCase().includes(newVal.toLowerCase()) 
                                || user.last_name && user.last_name.toLowerCase().includes(newVal.toLowerCase())
                                || user.email && user.email.toLowerCase().includes(newVal.toLowerCase())
                        });
                    }
                }
            },
        },
        activeGroup(){
            if(this.activeGroup!=null){
                this.loadGroupUsers(this.activeGroup);
            }
            else{
                setTimeout(()=>this.checkOverflow(), 300);
            }
        },
        groupsData:{
          deep:true,
          immediate:true,
          handler(newVal){
              this.groups = _.cloneDeep(newVal);
          }
        },
        usersData:{
          deep:true,
          immediate:true,
          handler(newVal){
              this.users = _.cloneDeep(newVal);
          }
        }
    },
    computed:{
        selectedGroupUsers(){
            let group = this.groups.find(group => group.id == this.activeGroup);
            if(group){
                if(this.activeGroup == 0){
                    return this.users;
                }
                let user_ids = group.users;
                let users = this.users.filter(user => user_ids.includes(user.id));
                return users;
            }
            else return [];
        },
        mappedAddedUserIds(){
            return this.addedUsers.map(user => user.id);
        },
        
    },
    methods:{
        selectGroup(group){
            if(this.activeGroup===group.id){
                this.activeGroup=null;
            }
            else{
                this.activeGroup=group.id;
            }
            return;
        },
        getGroupClass(group){
            let groups = [];
            if(group.id !=0 && (this.isGroupAdded(group)  || this.groupHasAddedUsers(group))){ //if group is not "all users" and group is added or atleast has 1 added user
                if(!(group.users_count == 0 && !this.isGroupAdded(group)))
                  groups.push(`gc-${group.color_id}`);
            }
            // if(group.added_user_count != group.users_count && group.added_user_count > 0){
            //     groups.push('incomplete'); //stripes
            // }
            if(group.id == 0){ //if group is "all users"
                if(this.selectedItems.includes(group.id)) groups.push('group-all');
            }
            if(this.activeGroup===group.id){ //expanded group
                groups.push('expanded');
            }
            if(this.invitationType == 'groups' && this.selectedItems.includes(group.id)){
                groups.push('added');
            }
            return groups;
        },
        groupHasAddedUsers(group){
            if(this.type == 'invitations'){
                if(group.users && group.users.length > 0)
                    return this.mappedAddedUserIds.filter(id => group.users.includes(id)).length > 0;
                else return false;
            }
            else{
                return group.added_user_count > 0 ? true : false;
            }
        },
        searchClear() {
            this.searchTerm = "";
        },
        loadGroupUsers(group_id){
            if(!this.item.id) return;
            if(this.isLoadingUsers) return;
            let index = this.groups.findIndex(group => group.id == group_id);
            if(this.groups[index].users && this.groups[index].users.length > 0) {
                setTimeout(()=>{
                this.checkOverflow();
                },300)
                return;
            }
            this.isLoadingUsers = true;
            axios
                .get(
                `${this.$store.state.api_link}/training/users/${this.item.rented ? this.item.rented : 0}/${this.item.id}/${group_id}`,
                this.$store.getters.axiosConfig()
                )
                .then((response) => {
                this.isLoadingUsers = false;
                this.$set(this.groups[index], 'users', response.data.users);
                this.checkOverflow()
                
                })
                .catch((error) => {
                this.$store.commit("handleError", { error: error });
                this.isLoadingUsers = false;
            });
        },
        checkOverflow() {
            if(this.listRef)
                this.listHeight = this.listRef.scrollHeight;
        },
        sortArrayByType(type, direction, sortArray, varType) {
            if (varType == "text") {
                if (direction == "asc") {
                    sortArray = sortArray.sort(function (a, b) {
                        var var1 = a[type].toUpperCase();
                        var var2 = b[type].toUpperCase();
                        if(a.id == 0) return -1;
                        if (var1 < var2) {
                        return -1;
                        }
                        if (var1 > var2) {
                        return 1;
                        }
                        return 0;
                    });
                } else if (direction == "desc") {
                    sortArray = sortArray.sort(function (a, b) {
                        var var1 = a[type].toUpperCase();
                        var var2 = b[type].toUpperCase();
                        if(a.id == 0) return -1;
                        if (var1 < var2) {
                        return 1;
                        }
                        if (var1 > var2) {
                        return -1;
                        }
                        return 0;
                    });
                }
            } 
            else if(varType=='number'){
                sortArray = sortArray.sort(function (a, b) {
                    if(a.id == 0) return -1;
                    if (direction == "asc") {
                    return b[type] - a[type];
                    }
                    else if (direction == "desc") {
                    return a[type] - b[type];
                    }
                });
            }
            else if(varType=='relation'){
              let vm = this;
              if (direction == "asc") {
                sortArray = sortArray.sort(function (a, b) {
                      let added_a = vm.selectedItems.includes(a.id);
                      let added_b = vm.selectedItems.includes(b.id);
                        if(a.id == 0) return -1;
                        if(type =='group_full'){
                          return added_b - added_a;
                        }
                        else if(type =='added'){
                          return added_b - added_a
                        }
                        return 0;
                    });
                } else if (direction == "desc") {
                    sortArray = sortArray.sort(function (a, b) {
                        let added_a = vm.selectedItems.includes(a.id);
                        let added_b = vm.selectedItems.includes(b.id);
                        if(a.id == 0) return -1;
                        if(type =='group_full'){
                        return added_a - added_b;
                        }
                        else if(type =='added'){
                        return added_a - added_b;
                        }
                        return 0;
                    });
                }
            }
            else {
                if (direction == "asc") {
                    if(a.id == 0) return -1;
                    sortArray = sortArray.sort(function (a, b) {
                        var var1 = a[type].length;
                        var var2 = b[type].length;
                        if (var1 < var2) {
                        return 1;
                        }
                        if (var1 > var2) {
                        return -1;
                        }
                        return 0;
                    });
                } else if (direction == "desc") {
                    if(a.id == 0) return -1;
                    sortArray = sortArray.sort(function (a, b) {
                        var var1 = a[type].length;
                        var var2 = b[type].length;
                        if (var1 < var2) {
                        return -1;
                        }
                        if (var1 > var2) {
                        return 1;
                        }
                        return 0;
                    });
                }
            }
        },
        setSort(item_type, key, data_type, group = null) {
            if(this.currentSort[item_type].key != key) this.currentSort[item_type].order == '';
            this.currentSort[item_type].key = key;
            if(this.currentSort[item_type].order == 'asc'){
                this.currentSort[item_type].order = 'desc';
            }
            else{
                this.currentSort[item_type].order = 'asc';
            }

            if (item_type == 'groups') {
                this.sortArrayByType(key, this.currentSort[item_type].order, this.groups, data_type);
            }
            else if (item_type == 'users') {
                this.sortArrayByType(key, this.currentSort[item_type].order, this.users, data_type);
            }
        },
        getAddedUserCount(group) {
            if(this.type == 'invitations'){
                if(group.id == 0 ){
                    return this.mappedAddedUserIds.filter(el=>el != null).length;
                }
                else{
                    return this.mappedAddedUserIds.filter(el=>el != null && group.users.includes(el)).length;
                }
            }
            else{
                if(group.id == 0 ){
                    let added = this.users.filter(el=>el.added).length;
                    group.added_user_count = added;
                    return added
                }
                else{
                    let added = this.users.filter(el=>el.added && (group.users && group.users.includes(el.id))).length;
                    group.added_user_count = added;
                    return added
                }
            }
        },
        toggleUserToRentedTraining(user){
            this.$emit('toggleUser', user);
        },
        addGroupToIndex(group, state) {
            this.$emit('addGroupToIndex', group, state);
        },
        addUserToList(group, user, state){
            this.$emit('addUserToList', group, user, state);
        },
        isGroupAdded(group){
            if(this.item.rented){
                return group.added_user_count == group.users_count;
            }
            else{
                if(this.type == 'invitations'){
                    if(group.users && group.users.length > 0){
                        return group.users.every(id => this.mappedAddedUserIds.includes(id));
                    }
                    else if(group.id == 0){
                        return this.mappedAddedUserIds.length == this.users.length;
                    }
                }
                else{
                    return this.selectedItems.includes(group.id)
                }
            }
        },
        showHeaderItem(item){
          if(item.key == 'group_full'){
            return this.invitationType != 'single'
          }
          else return true;
        }
  },
  mounted() {
    if(this.invitationType && this.invitationType != 'groups'){
      this.searchingForGroup = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.main-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px 0 40px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  .sort-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px;
    margin-bottom: 4px;
    margin-right: 8px;
    .option-with-icon {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      .option {
        width: min-content;
        min-width: 92px;
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
      .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding-right: 20px;
  }
}
.item-header {
  .save-btn {
    position: relative;
    padding: 10px 43px 10px 27px;
    background-color: rgba(244, 244, 244, 1);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    font-weight: 400;
    font-size: 14px;
    border-radius: 38px;
    transition: 0.25s;
    display: flex;
    align-items: center;
    white-space: nowrap;
    .arrow-right {
      position: absolute;
      top: 35%;
      right: 23px;
    }
    &:not(.faded):hover {
      cursor: pointer;
      background: #333333;
      color: #ffffff;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  .flex-row{
    width: 100%;
  }
}
.modal-window {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  margin: 0 auto;
  color: #333333;
  user-select: none;
  padding-top: 11vh;
  &.rent{
    padding-top: 103px;
    height: 100%;
  }
}
.icon{
  svg{
    path{
      &.active{
        fill: #333333;
      }
    }
  }
}
.inner-modal {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 60vw;
  max-width: 1096px;
  min-width: 920px;
  height: 75vh;
  position: relative;
  .closing-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 16px;
    line-height: 16px;
    z-index: 2;
    cursor: pointer;
  }
  .item-img {
    width: 110px;
    max-width: 110px;
    max-height: 110px;
    min-width: 110px;
    height: 110px;
    border-radius: 8px;
    .item-cover {
      height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
    }
  }
  .item-title {
    margin-left: 20px;
    font-size: 24px;
    width: 100%;
    line-height: 32px;

    font-family: var(--main-site-font);
  }
  &.overlay-active{
    .overlay{
      opacity: 1;
    }
    .main-list{
      opacity: 0;
    }
    .item-upper-header{
      opacity: 0;
    }
  }
}
.item-main{
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 16px;
  transition: margin-top 0.25s;
}


.save-btn {
  padding: 8px 28px;
  background-color: var(--third-color);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
  font-weight: 400;
  font-size: 16px;
  border-radius: 38px;
  transition: 0.25s;
  &:not(.faded):hover {
    cursor: pointer;
    background: #333333;
    color: #ffffff;
  }
}
.save-undertext {
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  text-align: center;

  /* LK fail raudona */
  color: #d45454;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #00000062;
  transition: opacity 0.3s ease-in-out;
  z-index: 0;
  border-radius: 16px;
  &.active{
    opacity: 1;
  }
}
.list-title {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 0 40px;
  &.marginTop {
    margin-top: 27px;
  }
}
.button-container{
  display: flex;
  justify-content: space-between;
  &.drop-shadow{
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);
  }
}
.send-invitations-btn{
  display: flex;
  align-items: center;
  background: #F4F4F4;
  border: 1px solid #333333;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  padding: 8px 20px;
  user-select: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--main-site-font);
  cursor: pointer;
  svg{
    margin-left: 8px;
  }
}

.title-header{
  width: 57%;
  padding-left: 38px;
}
.user-count-header{
  width: 15%;
  justify-content: center;
}
.actions-header{
  width: 13%;
  justify-content: flex-end;
  padding-right: 17px;
  &.option-with-icon {
    .option{
      min-width: 64px;
    }
  }
}
.main-list .sort-options .actions-header.option-with-icon .option{
  min-width: 64px;
}
.ungrouped-users {
  border-top: 1px solid rgba(125, 125, 125, 0.5);
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ungrouped-title {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
  }
  .ungrouped-head {
    display: flex;
    width: 85%;
    margin-bottom: 8px;
    .name {
      display: flex;
      align-items: center;
      width: 60%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7d7d7d;
      .icon {
        margin-left: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .email {
      display: flex;
      align-items: center;
      width: 20%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7d7d7d;
      .icon {
        margin-left: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .single-user {
    display: flex;
    background: #f4f4f4;
    border-radius: 30px;
    width: 90%;
    padding: 6px 12px;
    min-height: 32px;
    margin: 4px 0;
    transition: 0.25s;
    &.active {
      background: #d4d3d3;
    }
    .add-user {
      cursor: pointer;
      background: #ffffff;
      border-radius: 20px;
      padding: 2.5px 10px;
      .rotated-slightly {
        transform: rotate(45deg);
        background: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
      }
    }
    .name-surname {
      width: 60%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      display: flex;
      align-items: center;
    }
    .email {
      width: 35%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #333333;
    }
  }
}
.user-sticky-header{
  display: flex;
  flex-direction: column;
  width: 100%;
  &.expanded{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .user-header{
    width: calc(100% - 40px);
    margin: auto;
    background: #F4F4F4;
    box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    .icon{
      display: flex;
    }
  }
}

.list-item-with-users {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 8px 0;
  .list-item {
    width: 100%;
    display: flex;
    padding: 5px 20px;
    padding-right: 8px;
    position: relative;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    background-color: #F4F1F1;
    cursor: pointer;
     @for $i from 0 through 14 {
        &.gc-#{$i}{
          background-color: hsl(
            ($i * 40 % 360),
            (20 + 2.5 * $i) % 70 * 1%,
            var(--theme-lightness)
          );
        }
      }
    &.group-no-users{
      background: #F4F1F1;
      border: 1px solid #333333;

    }
    &.group-all{
      background: var(--secondary-color);
      border: 1px solid #333333;
      &.incomplete{
        background: var(--secondary-color);
        background-image: url('data:image/svg+xml,<svg width="911" height="56" viewBox="0 0 911 56" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 72.6533 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 263.342 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 454.029 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 644.717 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 835.405 -52)" fill="white" fill-opacity="0.4"/></svg>');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &.incomplete{
      background-image: url('data:image/svg+xml,<svg width="911" height="56" viewBox="0 0 911 56" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 72.6533 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 263.342 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 454.029 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 644.717 -52)" fill="white" fill-opacity="0.4"/><rect width="85.1797" height="135.708" transform="matrix(0.882882 0.469596 -0.531032 0.847352 835.405 -52)" fill="white" fill-opacity="0.4"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
    }
    &.expanded{
      z-index: 3;
    }
    &.added{
      outline: 1px solid #4D4D4D;
    }
    .title {
      width: 55%;
      font-size: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-left: 20px;
      border-right: 1px solid #33333333;
      flex-grow:0.5;
      &.bought {
        width: 55%;
      }
    }
    .group-heading-arrow {
      font-size: 16px;
      width: 33%;
      display: flex;
      justify-content: space-between;
      border-right: 1px solid rgba(125, 125, 125, 0.5);
      border-left: 1px solid rgba(125, 125, 125, 0.5);
      padding: 0px 8px;
      cursor: pointer;
      .arrow {
        transition: 0.25s;
      }
    }
    .user-count {
      width: 15%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      flex-grow: 0.5;
      &.border-right{
        border-right: 1px solid #33333333;
      }
    }
    .add-group {
      display: flex;
      align-items: center;
      justify-content: center;
      right: 8px;
      background: white;
      border-radius: 20px;
      top: 8px;
      cursor: pointer;
      transition: 0.25s;
      width: 32px;
      height: 32px;
      .rotated-slightly {
        transform: rotate(45deg);
      }
      &:hover {
        background-color: #333333;
        color: #ffffff;
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
      &.added{
        background: #333333;
         svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .user-header{
        position: sticky;
        left: 0;
        top: 0;
        z-index: 2;
        display: grid;
        grid-template-columns: 2fr 3fr 2fr 1.67fr;
        font-family: var(--main-site-font);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        span{
          display: flex;
          align-items: center;
          svg{
            margin-left: 8px;
          }
        }
        .name{
          padding-left: 20px;
        }
        .last-name{
          padding-left: 16px;
        }
        .user-groups{
          padding: 0 0 0 5px;
        }
        .email{
          padding-left: 11px;
        }
        .icon{
          cursor: pointer;
        }
  }
  .user-list {
    position: relative;
    width: calc(100% - 40px);
    transform-origin: top;
    transition: 0.25s;
    background: #F4F4F4;
    box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 12px 12px;
    overflow-y: auto;
    padding: 0 12px 16px 12px;
    .user-container{
      }
    
    .user-in-list {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr 1.3fr 0.37fr;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: 30px;
      padding: 6px 8px;
      align-items: center;
      margin: 12px 0;
      @for $i from 0 through 14 {
        &.gc-#{$i}-opacity-50{
          background-color: hsla(
            ($i * 40 % 360),
            (20 + 2.5 * $i) % 70 * 1%,
            var(--theme-lightness), 50%
          );
        }
      }
      @for $i from 0 through 14 {
        &.gc-#{$i}-opacity-70{
          background-color: hsla(
            ($i * 40 % 360),
            (20 + 2.5 * $i) % 70 * 1%,
            var(--theme-lightness), 70%
          );
        }
      }
      &:first-child{
        margin-top: 0;
      }
      // &.active {
      //   background: rgba(115, 229, 229, 0.2);
      // }
      span{
        min-width: 0;

      }
      .inner, .user-groups{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        height: 100%;
        max-width: 250px;
        width: 250px;
        border-right: 1px solid rgba(125, 125, 125, 0.5);
        border-left: 1px solid rgba(125, 125, 125, 0.5);
      }
      .name{
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        max-width: 215px;
        width: 215px;
        height: 100%;
      }
      .last-name {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        height: 100%;
        border-left: 1px solid rgba(125, 125, 125, 0.5);
      }
      .user-groups{
        font-size: 16px;
        padding: 0 12px;
        max-width: 120px;
        height: 100%;
        border-right: 1px solid rgba(125, 125, 125, 0.5);
      }
      .add-btn {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background: #f4f4f4;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.25s;
          &.used-licence{
            opacity: 0.5;
          }
          &:hover {
            background-color: #333333;
            color: #ffffff;
            svg{
              path{
                stroke: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  padding: 40px 0 24px 0;
  margin: 0 40px;
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
}
.button-container{
  padding: 30px 40px;
}
.arrow{
  &.active{
    transform: rotate(180deg);
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 27px;
}
.label{
  white-space: nowrap;
  margin-bottom: 8px;
}
.left-aligned {
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
}
.underline {
}
.end {
  justify-content: flex-end;
}
.bottom-8 {
  margin-bottom: 8px;
}
.action-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 12%;
  &.inactive{
    width: 0;
  }
}
.big-bold-text {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 14px;
}
.small-font {
  font-size: 14px;
}
.flipped {
  transform: rotate(180deg);
}
.slide-enter-active {
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

.slide-leave-active {
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

.slide-enter-to,
.slide-leave {
  max-height: 100vh;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.faded {
  opacity: 0.5;
  cursor: not-allowed;
  cursor: no-drop;
}
.red {
  color: #d45454;
}
.expand-invite-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.expand-invite-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.expand-invite-enter-to,
.expand-invite-leave {
  max-height: 100%;
  max-width: 100%;

  overflow: hidden;
}

.expand-invite-enter,
.expand-invite-leave-to {
  overflow: hidden;
  max-height: 0;
  max-width: 0;
  padding: 0;
  
}
.expand-invite-leave-to {
  left: 5px;
  bottom: 5px;
  
}

//Viso modalo 'sumazinimas', daug kur perpuse maziau or less
@media (max-width: 1024px){
  .modal-window{
    padding-left: 4vw;
    padding-top: 60px;
  }
  .inner-modal{
      height: 90%;
      .item-header{
        .flex-row{
          padding: 20px 0 12px 0;
          margin: 0 30px;
            .item-img{
            max-width: 55px;
            max-height: 55px;
            min-width: 55px;
            width: 55px;
            height: 55px;
          }
          .item-title{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  .item-main{
    .item-upper-header{
      padding: 0 30px;
      margin: 12px 0;
    }
    .main-list{
      padding: 0 30px 0 30px;
    }
  }
  .button-container{
    padding: 15px 20px;
    .send-invitations-btn{
      padding: 4px 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 16px;
        height: 16px;
      }
    }
  }
  .item-upper-header .search-bar .search-inner{
    padding: 8px 12px;
    width: 200px;
    font-size: 14px;
  }
  .main-list .list{
    gap: 8px;
    margin: 8px 0;
  }
  .list-item-with-users{
    margin: 0;
    .list-item{
      padding: 4px 10px;
    }
  }
}
@media (max-width: 960px){
  .inner-modal{
    min-width: 768px;
    width: 92vw;
  }
  .main-list .sort-options .option-with-icon .option{
    min-width: 80px;
  }
}
@media (max-width: 768px){
  .modal-window{
    padding-left: 0vw;
  }
  .inner-modal{
   min-width: 95vw;
   width: 95vw;
   .item-img{
    height: 8vh;
    min-height: 64px;
    min-width: 64px;
    width: 8vh;
   }
  }
  .title-header{
    width: 35%;
  }
  .list-item-with-users .list-item .title.bought{
    width: 32%;
  }
  .list-item-with-users .list-item .user-count{
    width: 25%;
  }
  .user-count-header{
    width: 25%;
  }
  .main-list .sort-options .option-with-icon .option{
    min-width: 85px;
  }
  .flex-row{
    margin: 0 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .main-list{
    padding: 0 10px 0 20px;
  }
  .item-upper-header{
    padding: 0 20px;
  }
  .button-container{
    padding: 15px 20px;
  }
}
@media (max-width: 635px){
  .item-upper-header{
    flex-direction: column;
    align-items: flex-start;
    .search-type{
      margin-top: 10px;
    }
  }
  .inner-modal .item-title{
    font-size: 20px;
    line-height: 28px;
  }
}
</style>
<style lang="scss">
.expand-invite-enter-active, .expand-invite-leave-active {
  .content, .header{
    opacity: 1;
    transition: all 0.5s;
  }
}
.expand-invite-enter,
.expand-invite-leave-to {
  .content, .header{
    opacity: 0;
  }
  
}
.item-upper-header{
  .custom-search-toggle{
    background: #F4F4F4;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    &.toggle-btn.active {
       background:#F4F4F4;
      .inner--circle{
        background: var(--secondary-color);
      }
    }
     &.toggle-btn{
       .inner--circle{
         background: var(--secondary-color);
       }
     }
  }
}
</style>