var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"folder-page-container"},[_c('HeaderButtons',{attrs:{"options":{
      showCreateBtn: (_vm.$store.state.user_role == 'admin' || _vm.hasPermission('trainings.training_group_create')) && _vm.showCreateFolder, 
      loadingItem: _vm.isFolderCreating, 
      createBtnText: 'training_groups.create_new',
      createItemAction: _vm.addNewGroup,
      showBackBtn:false,
      mode: 'edit'
    }},on:{"selectView":function($event){_vm.selectedView=$event},"selectMode":function($event){_vm.selectedMode=$event}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedView==0),expression:"selectedView==0"}],staticClass:"table-container"},[_c('div',{staticClass:"table-wrapper"},[_c('TrainingTableView',{attrs:{"selectedMode":_vm.selectedMode}})],1)]),(_vm.deleteModalOpened)?_c('GlobalModal',{attrs:{"modalInfo":{
      text: 'training.group_delete_confirm',
      confirmBtnText: 'delete_modal.yes_delete',
      type: 'confirmation',
    }},on:{"onConfirm":_vm.confirmedGroupDelete,"onClose":_vm.closeDeleteModal}}):_vm._e(),(_vm.infoModalOpened)?_c('GlobalModal',{attrs:{"modalInfo":{
      type: 'info',
      show_button: true,
      text: 'training.delete_error_1',
      confirmBtnText: 'generic.back',
    }},on:{"onClose":function($event){_vm.infoModalOpened = false}}}):_vm._e(),(_vm.multiChoiceOpened)?_c('GlobalModal',{attrs:{"modalInfo":_vm.multiChoiceInfo},on:{"onChoiceOne":_vm.choiceOne,"onChoiceTwo":_vm.choiceTwo,"onClose":_vm.closeModals,"onSelectSave":_vm.selectSave,"onConfirm":_vm.deleteGroupWithTrainings}}):_vm._e(),(_vm.selectedFolder)?_c('GroupAssignModal',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.selectedFolder),expression:"selectedFolder"}],attrs:{"type":"training","item":_vm.selectedFolder},on:{"modalClose":function($event){_vm.selectedFolder=null}}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedView==1),expression:"selectedView==1"}],staticClass:"folder-container"},_vm._l((_vm.trainingRows),function(row,rowIndex){return _c('draggable',{key:rowIndex,staticClass:"folder-rows",class:{ 'grid-center': _vm.$store.state.system_settings.grid_dynamic_size != 0 ? _vm.trainingRowGroups[rowIndex].length == 1 : false },attrs:{"group":"folders","options":{animation:300,
      disabled: _vm.activeOptionMenu != -50 || _vm.renameMode },"ghost-class":"drag-ghost"},on:{"end":function (e){ return _vm.draggableTest(e, _vm.trainingRowGroups); }},model:{value:(_vm.trainingRowGroups[rowIndex]),callback:function ($$v) {_vm.$set(_vm.trainingRowGroups, rowIndex, $$v)},expression:"trainingRowGroups[rowIndex]"}},_vm._l((_vm.trainingRowGroups[rowIndex]),function(folder){return _c('div',{key:'index-'+folder.id,staticClass:"folder-block",class:[
          { bright: _vm.$store.state.system_settings.color_palette == 0 },
          { medium: _vm.$store.state.system_settings.color_palette == 1 },
          { blank: _vm.$store.state.system_settings.color_palette == 2 },
          { 'colors-open': _vm.activeOptionMenu == folder.id && _vm.colorExpanded } ],style:(_vm.getFolderStyle(folder, rowIndex)),attrs:{"id":folder.id},on:{"click":function($event){$event.stopPropagation();return _vm.openGroup(folder.id)}}},[_c('div',{staticClass:"folder-block-cover"}),(_vm.showSettings && folder.id != -1 && _vm.showEditOptions(folder))?_c('div',{staticClass:"option-dots",on:{"click":function($event){$event.stopPropagation();_vm.activeOptionMenu == folder.id
              ? _vm.closeMenus()
              : _vm.activeOptionMenu == -50
              ? (_vm.activeOptionMenu = folder.id)
              : _vm.closeMenus() && (_vm.activeOptionMenu = -50)}}},[_c('OptionDotsSvg',{staticClass:"themable"})],1):_vm._e(),(
            (_vm.showEditOptions(folder)) &&
            _vm.activeOptionMenu == folder.id &&
            !_vm.colorExpanded &&
            !_vm.renameMode
          )?_c('div',{directives:[{name:"click-outside-2",rawName:"v-click-outside-2",value:(_vm.closeMenus),expression:"closeMenus"}],staticClass:"option-menu",class:{short: (_vm.showEditOptions(folder)) && _vm.$store.state.user_role != 'admin'},on:{"click":function($event){$event.stopPropagation();}}},[((_vm.showEditOptions(folder)))?_c('div',{staticClass:"color option-block",on:{"click":function($event){$event.stopPropagation();return _vm.colorMode(folder)}}},[_c('div',{staticClass:"circle"})]):_vm._e(),((_vm.showEditOptions(folder)))?_c('div',{staticClass:"pencil option-block",on:{"click":function($event){$event.stopPropagation();return _vm.enterRename(folder)}}},[_c('EditFolderNameSvg')],1):_vm._e(),(_vm.$store.state.user_role == 'admin')?_c('div',{staticClass:"trash option-block",on:{"click":function($event){$event.stopPropagation();return _vm.deleteGroup(folder)}}},[_c('GroupFolderTrashSvg')],1):_vm._e()]):(
            _vm.activeOptionMenu == folder.id &&
            _vm.colorExpanded &&
            !_vm.renameMode
          )?_c('div',{directives:[{name:"click-outside-2",rawName:"v-click-outside-2",value:(_vm.closeMenus),expression:"closeMenus"}],staticClass:"option-menu color",on:{"click":function($event){$event.stopPropagation();}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.colorHue),expression:"colorHue"}],staticClass:"color-input",class:[
              { bright: _vm.$store.state.system_settings.color_palette == 0 },
              { medium: _vm.$store.state.system_settings.color_palette == 1 },
              { blank: _vm.$store.state.system_settings.color_palette == 2 } ],style:({
              '--thumb-color': ("" + (folder.color_hue != null
                  ? folder.color_hue
                  : _vm.colorPalettes[
                      _vm.$store.state.system_settings.color_palette
                    ][0])),
            }),attrs:{"type":"range","min":"0","max":"11","step":"0.1"},domProps:{"value":(_vm.colorHue)},on:{"input":function($event){$event.stopPropagation();return _vm.updateColorHue(folder)},"click":function($event){$event.stopPropagation();},"__r":function($event){_vm.colorHue=$event.target.value}}})]):_vm._e(),(_vm.renameMode == true && _vm.activeOptionMenu == folder.id)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupName),expression:"groupName"}],ref:"nameInput",refInFor:true,staticClass:"folder-name-input",attrs:{"spellcheck":"false"},domProps:{"value":(_vm.groupName)},on:{"blur":_vm.finishEditing,"input":[function($event){if($event.target.composing){ return; }_vm.groupName=$event.target.value},function($event){return _vm.changeGroupName(folder)}],"click":function($event){$event.stopPropagation();}}}):_c('span',{staticClass:"folder-name",class:{
            darkLetters:
              folder.id == -1 &&
              _vm.$store.state.system_settings.color_palette == 0,
          }},[_vm._v(_vm._s(folder.name))]),_c('div',{staticClass:"bottom-line",class:[
            { end: !folder.trainings.length && !folder.trainings },
            {
              darkLetters:
                folder.id == -1 &&
                _vm.$store.state.system_settings.color_palette == 0,
            } ]},[(folder.trainings != 0 || Array.isArray(folder.trainings) && folder.trainings.length > 0 )?_c('span',{staticClass:"training-count"},[_vm._v(_vm._s(_vm.toLocal("groups.training_count"))+" "+_vm._s(Array.isArray(folder.trainings) ? folder.trainings.length : folder.trainings))]):_vm._e(),_c('div',{staticClass:"arrow-container"},[_c('ArrowRightFadeSvg',{staticClass:"themable"})],1)])])}),0)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }