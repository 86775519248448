<template>
  <div class="folder-page-container" v-if="loaded">
    <HeaderButtons
      :options="{
        showCreateBtn: ($store.state.user_role == 'admin' || hasPermission('trainings.training_group_create')) && showCreateFolder, 
        loadingItem: isFolderCreating, 
        createBtnText: 'training_groups.create_new',
        createItemAction: addNewGroup,
        showBackBtn:false,
        mode: 'edit'
      }"
      @selectView="selectedView=$event"
      @selectMode="selectedMode=$event"
    />
    <div class="table-container" v-show="selectedView==0">
      <div class="table-wrapper">
        <TrainingTableView
        :selectedMode="selectedMode"
        />

      </div>
    </div>
    <GlobalModal
      :modalInfo="{
        text: 'training.group_delete_confirm',
        confirmBtnText: 'delete_modal.yes_delete',
        type: 'confirmation',
      }"
      @onConfirm="confirmedGroupDelete"
      @onClose="closeDeleteModal"
      v-if="deleteModalOpened"
    />
    <GlobalModal
      v-if="infoModalOpened"
      :modalInfo="{
        type: 'info',
        show_button: true,
        text: 'training.delete_error_1',
        confirmBtnText: 'generic.back',
      }"
      @onClose="infoModalOpened = false"
    />
    <GlobalModal
      v-if="multiChoiceOpened"
      :modalInfo="multiChoiceInfo"
      @onChoiceOne="choiceOne"
      @onChoiceTwo="choiceTwo"
      @onClose="closeModals"
      @onSelectSave="selectSave"
      @onConfirm="deleteGroupWithTrainings"
    />
    <GroupAssignModal
      v-if="selectedFolder"
      v-scroll-lock="selectedFolder"
      type="training"
      :item="selectedFolder"
      @modalClose="selectedFolder=null"
    />
    <div class="folder-container" v-show="selectedView==1">
      <draggable
        v-model="trainingRowGroups[rowIndex]"
        group="folders"
        :options="{animation:300,
        disabled: activeOptionMenu != -50 || renameMode }"
        ghost-class="drag-ghost"
        @end="(e)=>draggableTest(e, trainingRowGroups)"
        class="folder-rows"
        :class="{ 'grid-center': $store.state.system_settings.grid_dynamic_size != 0 ? trainingRowGroups[rowIndex].length == 1 : false }"
        v-for="(row, rowIndex) in trainingRows"
        :key="rowIndex"
      >
        <div 
          class="folder-block"
          @click.stop="openGroup(folder.id)"
          :class="[
            { bright: $store.state.system_settings.color_palette == 0 },
            { medium: $store.state.system_settings.color_palette == 1 },
            { blank: $store.state.system_settings.color_palette == 2 },
            { 'colors-open': activeOptionMenu == folder.id && colorExpanded },
          ]"
          v-for="folder in trainingRowGroups[rowIndex]"
          :key="'index-'+folder.id"
          :id="folder.id"
          :style="getFolderStyle(folder, rowIndex)"
        >
        <div class="folder-block-cover"></div>
          <div
            class="option-dots"
            v-if="showSettings && folder.id != -1 && showEditOptions(folder)"
            @click.stop="
              activeOptionMenu == folder.id
                ? closeMenus()
                : activeOptionMenu == -50
                ? (activeOptionMenu = folder.id)
                : closeMenus() && (activeOptionMenu = -50)
            "
          >
            <OptionDotsSvg class="themable"/>
          </div>
          <div
            class="option-menu"
            v-click-outside-2="closeMenus"
            :class="{short: (showEditOptions(folder)) && $store.state.user_role != 'admin'}"
            v-if="
              (showEditOptions(folder)) &&
              activeOptionMenu == folder.id &&
              !colorExpanded &&
              !renameMode
            "
            @click.stop
          >
            <div class="color option-block" @click.stop="colorMode(folder)" v-if="(showEditOptions(folder))">
              <div class="circle"></div>
            </div>
            <!-- <div class="group option-block" @click.stop="openGroupSide(folder)" v-if="$store.state.user_role == 'admin' || hasPermission('users.assign_trainings')">
              <HomeEditOpenGroupSvg class="stroke" />
            </div> -->
            <div class="pencil option-block" @click.stop="enterRename(folder)" v-if="(showEditOptions(folder))">
              <EditFolderNameSvg />
            </div>
            <div class="trash option-block" @click.stop="deleteGroup(folder)" v-if="$store.state.user_role == 'admin'">
              <GroupFolderTrashSvg />
            </div>
          </div>
          <div
            class="option-menu color"
            v-click-outside-2="closeMenus"
            v-else-if="
              activeOptionMenu == folder.id &&
              colorExpanded &&
              !renameMode
            "
            @click.stop
          >
            <input
              class="color-input"
              :class="[
                { bright: $store.state.system_settings.color_palette == 0 },
                { medium: $store.state.system_settings.color_palette == 1 },
                { blank: $store.state.system_settings.color_palette == 2 },
              ]"
              type="range"
              min="0"
              max="11"
              step="0.1"
              v-model="colorHue"
              @input.stop="updateColorHue(folder)"
              @click.stop=""
              :style="{
                '--thumb-color': `${
                  folder.color_hue != null
                    ? folder.color_hue
                    : colorPalettes[
                        $store.state.system_settings.color_palette
                      ][0]
                }`,
              }"
            />
          </div>
          <textarea
            class="folder-name-input"
            ref="nameInput"
            v-if="renameMode == true && activeOptionMenu == folder.id"
            @blur="finishEditing"
            @input="changeGroupName(folder)"
            @click.stop=""
            v-model="groupName"
            spellcheck="false"
          />
          <span
            class="folder-name"
            :class="{
              darkLetters:
                folder.id == -1 &&
                $store.state.system_settings.color_palette == 0,
            }"
            v-else
            >{{ folder.name }}</span
          >
          <div
            class="bottom-line"
            :class="[
              { end: !folder.trainings.length && !folder.trainings },
              {
                darkLetters:
                  folder.id == -1 &&
                  $store.state.system_settings.color_palette == 0,
              },
            ]"
          >
            <span class="training-count" v-if="folder.trainings != 0 || Array.isArray(folder.trainings) && folder.trainings.length > 0 "
              >{{ toLocal("groups.training_count") }}
              {{ Array.isArray(folder.trainings)  ? folder.trainings.length : folder.trainings }}</span
            >
            <div class="arrow-container"><ArrowRightFadeSvg class="themable"/></div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OptionDotsSvg from "@/assets/images/icons/option-dots.svg?inline";
import EditFolderNameSvg from "@/assets/images/icons/edit-folder-name.svg?inline";
import GroupFolderTrashSvg from "@/assets/images/icons/group-folder-trash.svg?inline";
import VueScrollTo from "vue-scrollto";
import HeaderButtons from "@/components/layout/HeaderButtons.vue";
import GlobalModal from "../components/layout/modals/GlobalModal.vue";
import GroupAssignModal from "../components/layout/modals/GroupAssignModal.vue";
import PlusNarrowSvg from "@/assets/images/icons/plus-narrow-white.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
import LoadingBubbleSvg from "@/assets/images/icons/loading-bubble.svg?inline";
import ArrowRightFadeSvg from "@/assets/images/icons/arrow-right-fade.svg?inline";
import HomeEditOpenGroupSvg from "@/assets/images/icons/homeEdit/home-edit-group-widthless.svg?inline";
import TrainingTableView from "../components/layout/trainings/TrainingTableView";

import draggable from "vuedraggable";
export default {
  name: "TrainingFolders",
  data: function () {
    return {
      deletingStatus: "",
      multiChoiceInfo: {
        type: "multiChoice",
        text: "modal.group_with_trainings",
        undertext: "modal.choose_what_to_do",
        multiChoiceYes: "modal.transfer_to_another",
        multiChoiceNo: "modal.delete_forever",
      },
      multiChoiceOpened: false,
      isFolderCreating: false,
      searchTerm: "",
      isHeaderInView: true,
      colorSliderPos: 0,
      colorHue: 5.5,
      groupName: "",
      addingTraining: false,
      addingNewGroup: false,
      trainingGroupsClone: [],
      trainingGroups: [],
      searchTrainingGroups: [],
      loaded: false,
      selectedTraining: {},
      isSearching: false,
      searchInputValue: "",
      trainingRows: 0,
      trainingRowGroups: [],
      activeOptionMenu: -50,
      colorExpanded: false,
      renameMode: false,
      deleteModalOpened: false,
      infoModalOpened: false,
      changedFolders: [],
      selectedView: 1,

      list1: [
        { name: "John", id: 1 },
        { name: "Joao", id: 2 },
        { name: "Jean", id: 3 },
        { name: "Gerard", id: 4 }
      ],
      list2: [
        { name: "John", id: 1 },
        { name: "Joao", id: 2 },
        { name: "Jean", id: 3 },
        { name: "Gerard", id: 4 }
      ],
      colorPalettes: [
        [
          "rgba(230, 69, 69, 1)",
          "rgba(229, 115, 0, 1)",
          "rgba(191, 143, 0, 1)",
          "rgba(100, 153, 46, 1)",
          "rgba(46, 153, 46, 1)",
          "rgba(46, 153, 100, 1)",
          "rgba(46, 153, 153, 1)",
          "rgba(69, 150, 229, 1)",
          "rgba(69, 69, 229, 1)",
          "rgba(150, 69, 230, 1)",
          "rgba(153, 46, 153, 1)",
          "rgba(229, 69, 150, 1)",
        ],
        [
          "rgba(229, 115, 115, 1)",
          "rgba(229, 166, 103, 1)",
          "rgba(229, 229, 103, 1)",
          "rgba(184, 229, 138, 1)",
          "rgba(138, 229, 138, 1)",
          "rgba(115, 229, 172, 1)",
          "rgba(115, 229, 229, 1)",
          "rgba(115, 172, 229, 1)",
          "rgba(138, 138, 229, 1)",
          "rgba(184, 138, 230, 1)",
          "rgba(229, 138, 229, 1)",
          "rgba(229, 138, 184, 1)",
        ],
        [
          "rgba(229, 184, 184, 1)",
          "rgba(229, 207, 184, 1)",
          "rgba(229, 229, 184, 1)",
          "rgba(207, 229, 184, 1)",
          "rgba(184, 229, 184, 1)",
          "rgba(184, 229, 207, 1)",
          "rgba(184, 229, 229, 1)",
          "rgba(184, 207, 229, 1)",
          "rgba(184, 184, 229, 1)",
          "rgba(207, 184, 229, 1)",
          "rgba(229, 184, 229, 1)",
          "rgba(229, 184, 207, 1)",
        ],
      ],
      color1: "rgba(230, 69, 69, 1)",
      color2: "rgba(229, 115, 0, 1)",
      windowWidth: window.innerWidth,
      movingTrainings: false,
      selectedFolder: null,
      selectedMode: 1,
    };
  },
  props: {
    showCreateFolder: {
      default: true,
    },
    showSettings: {
      default: true,
    },
    openGroupFunction: {
      default: null,
    },
  },
  components: {
    OptionDotsSvg,
    EditFolderNameSvg,
    GroupFolderTrashSvg,
    GlobalModal,
    PlusNarrowSvg,
    GroupSearchSvg,
    LoadingBubbleSvg,
    ArrowRightFadeSvg,
    HomeEditOpenGroupSvg,
    GroupAssignModal,
    HeaderButtons,
    TrainingTableView,
    draggable,
  },

  mounted() {
    this.$store.state.loading.loadingStart();
    this.loaded = false;
    setTimeout(() => {
      this.loadTrainings();
    }, 10);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    showEditOptions(folder) {
      if (this.$store.state.user_role == 'admin') return true;
      else if (this.$store.state.user_permissions
        && this.$store.state.user_permissions['trainings.edit_in_groups']
        && this.$store.state.user_permissions['trainings.edit_in_groups'].items
        && this.$store.state.user_permissions['trainings.edit_in_groups'].items.includes(folder.id)) {
        return true;
      }
      else if(folder.created_by == this.$store.state.user_id) return true;
      else {
        return false;
      }
    },
    draggableTest(e, groups){
      this.changedFolders = [];
      let newList = []
      groups.forEach((row)=>{
        row.forEach((folder)=>{
          newList.push(folder);
        })
      })
      let tempFolder = this.trainingGroups.find((el)=> {return el.id == e.item.id});
      let folderIndex = this.trainingGroups.indexOf(tempFolder);


      this.changedFolders.push({
        id: this.trainingGroups[folderIndex+1].id,
        goes_after: this.trainingGroups[folderIndex-1] != undefined ? this.trainingGroups[folderIndex-1].id : -1
      })

      let alreadyBehindFolder = this.trainingGroups.find((el)=> {return el.goes_after == tempFolder.id});
      if(alreadyBehindFolder){
        alreadyBehindFolder.goes_after = this.trainingGroups[folderIndex-1] != undefined ? this.trainingGroups[folderIndex-1].id : -1;
        this.changedFolders.push({
          id: alreadyBehindFolder.id,
          goes_after: alreadyBehindFolder.goes_after,
        })
      }


      //BEFORE DRAG
      this.trainingGroups = newList;
      //AFTER DRAG

      let folder = this.trainingGroups.find((el)=> {return el.id == e.item.id});
      folderIndex = this.trainingGroups.indexOf(folder);
      folder.goes_after = this.trainingGroups[folderIndex-1] != undefined ? this.trainingGroups[folderIndex-1].id : -1;

      this.changedFolders.push({
        id: folder.id,
        goes_after: folder.goes_after,
      })

      if(this.trainingGroups[folderIndex+1].goes_after != 0){
        this.trainingGroups[folderIndex+1].goes_after = folder.id;
        this.changedFolders.push({
        id: this.trainingGroups[folderIndex+1].id,
        goes_after: folder.id,
      })
      }
      let additional_folders = this.trainingGroups.filter((el) => { return el.goes_after == 0 && el.id != -1 });
      additional_folders.forEach((el) => {
        let index = this.trainingGroups.indexOf(el);
        if (index != -1 && this.trainingGroups[index-1]) {
          this.trainingGroups[index].goes_after = this.trainingGroups[index - 1].id;
           this.changedFolders.push({
            id: el.id,
            goes_after: this.trainingGroups[index - 1].id,
          })
        }
      });

      this.saveFolderPos();
      this.makeRows();
    },
    getFolderStyle(folder, rowIndex) {
       let style = '';
      if (this.$store.state.system_settings.grid_dynamic_size != 0) {
        style += `grid-column: ${this.trainingRowGroups[rowIndex].length == 1 && this.windowWidth >= 1200
            ? '7/'
            : this.windowWidth <= 1200 && this.windowWidth > 900
              ? ''
              : this.windowWidth <= 900 && this.windowWidth > 768 ? '2/' : ''
          }span ${folder.columnSize};`
      }
      else{
        style += `grid-column: 12 span;`;
      }
      style+=`background-color: ${
          folder.color_hue != null
            ? folder.color_hue
            : colorPalettes[$store.state.system_settings.color_palette]
            ? colorPalettes[$store.state.system_settings.color_palette][0]
            : colorPalettes[0][0]
        };`
      if (this.$store.state.system_settings.custom_style && this.$store.state.system_settings.custom_style.training_grid_style) {
        if (this.$store.state.system_settings.custom_style.training_grid_style.color) {
          style += `color: ${this.$store.state.system_settings.custom_style.training_grid_style.color};`
        }
        if (this.$store.state.system_settings.custom_style.training_grid_style['font-weight']) {
          style += `font-weight: ${this.$store.state.system_settings.custom_style.training_grid_style['font-weight']};`
        }
        if (this.$store.state.system_settings.custom_style.training_grid_style['font-size']) {
          style += `font-size: ${this.$store.state.system_settings.custom_style.training_grid_style['font-size']};`
        }
      }
      return style
    },
    openGroupSide(folder){
      this.selectedFolder = folder;
    },
    closeModals() {
      this.infoModalOpened = false;
      this.deleteModalOpened = false;
      this.multiChoiceOpened = false;
    },
    handleScroll() {
      if (this.$refs["headerbuttons"] != undefined) {
        if (this.$refs["headerbuttons"].getBoundingClientRect().top >= 100) {
          this.isHeaderInView = true;
          this.$store.state.showingHeaderSearchBar = false;
        } else {
          this.isHeaderInView = false;
          this.$store.state.showingHeaderSearchBar = true;
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.makeRows();
    },
    addNewGroup() {
      if (this.isFolderCreating) {
        return;
      }
      this.isFolderCreating = true;
      this.addingNewGroup = true;
      axios
        .post(
          `${this.$store.state.api_link}/training_group/add`,
          { name: this.toLocal("training.new_training_group") },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          let new_training_group = response.data.training_group;
          this.trainingGroups.unshift(new_training_group);
          this.addingNewGroup = false;
          axios
            .post(
              `${this.$store.state.api_link}/training_group/set-order`,
              { training_groups: this.trainingGroups.map((el) => el.id) },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              this.$store.state.currentGroupOpen = new_training_group.id;
              this.$router.push("/edit-group?id=" + new_training_group.id + "&editTitle=1");
              // this.loadTrainings();
            })
            .catch((err) => {
              console.log(err)
            });
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
          this.addingNewGroup = false;
        });
    },
    openGroup(id) {
      this.$store.state.currentGroupOpen = id;
      if (id == -1) {
        this.$router.push("/bought-trainings");
      } else {
        this.$router.push("/edit-group?id=" + id);
      }
    },
    updateColorHue(folder) {
      window.requestAnimationFrame(() => {
        this.changeGroupHue(folder);
      });
    },
    finishEditing() {
      this.closeMenus();
    },
    enterRename(folder) {
      this.groupName = "";
      this.groupName = folder.name;
      this.renameMode = true;
      this.$nextTick(() => this.$refs["nameInput"][0].focus());
    },
    saveFolderPos() {
      let training_groups = this.trainingGroups.filter(el => el.id != -1);
      let order = training_groups.map((el, index) => {
        return { id: el.id, order_index: index }
      });
      axios
      .post(
        `${this.$store.state.api_link}/training_group/edit_info/positions`,
        {
          groups: this.changedFolders,
          order: order
        },
        this.$store.getters.axiosConfig()
      ).then(()=>{
        this.changedFolders = [];
      })
      .catch((error) => {
        this.$store.commit("handleError", { error: error });
      });
    },
    closeMenus() {
      setTimeout(() => {

        if (this.activeOptionMenu != -50) {
          let updatedGroup = this.trainingGroups.find((obj) => obj.id == this.activeOptionMenu);
          if (updatedGroup == undefined) {
            this.colorExpanded = false;
            this.activeOptionMenu = -50;
            this.groupName = "";
            this.renameMode = false;
            return;
          }
          let groupClone = this.trainingGroupsClone.find((obj) => obj.id == this.activeOptionMenu);
          this.colorExpanded = false;
          this.activeOptionMenu = -50;
          this.groupName = "";
          this.renameMode = false;
          if (
            updatedGroup.name === groupClone.name &&
            updatedGroup.color_hue === groupClone.color_hue
          ) {
            return;
          } 
          else {
            axios
              .post(
                `${this.$store.state.api_link}/training_group/editInfo/${updatedGroup.id}`,
                {
                  name: updatedGroup.name,
                  color_pos: this.colorHue,
                },
                this.$store.getters.axiosConfig()
              )
              .then((response) => {
                this.trainingGroupsClone = _.cloneDeep(this.trainingGroups);
              })
              .catch((error) => {
                this.$store.commit("handleError", { error: error });
              });
          }
        }
      }, 200);
    },
    changeGroupName(folder) {
      folder.name = this.groupName;
    },
    changeGroupHue(folder, optionalColor) {
      if (optionalColor != undefined) {
        this.colorHue = optionalColor;
      }
      let colorGroup = Math.floor(
        this.colorHue %
          this.colorPalettes[this.$store.state.system_settings.color_palette]
            .length
      );
      this.color1 =
        this.colorPalettes[this.$store.state.system_settings.color_palette][
          colorGroup
        ];
      if (
        this.colorPalettes[this.$store.state.system_settings.color_palette][
          colorGroup + 1
        ] == undefined
      ) {
        this.color2 ==
          this.colorPalettes[this.$store.state.system_settings.color_palette][
            this.colorPalettes[this.$store.state.system_settings.color_palette]
              .length - 1
          ];
      } else {
        this.color2 =
          this.colorPalettes[this.$store.state.system_settings.color_palette][
            colorGroup + 1
          ];
      }
      // this.colorHue /= 100;
      let color1Values = this.color1
        .replace("rgba(", "")
        .replace(")", "")
        .split(",");
      let color2Values = this.color2
        .replace("rgba(", "")
        .replace(")", "")
        .split(",");
      let copiedColor = this.colorHue;
      for (let i = 1; i <= copiedColor; ) {
        copiedColor -= 1;
      }
      let midwayValues = color1Values.map(
        (val, index) =>
          (1 - copiedColor) * parseInt(val) +
          copiedColor * parseInt(color2Values[index])
      );
      midwayValues[midwayValues.length - 1] = 1;
      let color = `rgba(${midwayValues
        .map((val) => Math.floor(val))
        .join(",")})`;
      if(!folder.color_hue){
        folder.color_hue = color;
        
      }
      if(folder.color_hue && !folder.color_hue.startsWith('#'))
        folder.color_hue = color;
    },
    colorMode(folder) {
      this.colorExpanded = true;
      if (folder.color_hue == null) {
        this.colorHue = 0;
      } else {
        if (folder.color_slider_pos != null) {
          this.colorHue = folder.color_slider_pos;
        }
      }
    },
    choiceOne() {
      this.multiChoiceInfo.type = "dropdown";
      this.multiChoiceInfo.text = "modal.choose_training_dropdown";
      this.multiChoiceInfo.dropdownPlaceholder = "test_check.filter_group_pick";
      this.multiChoiceInfo.undertext = "";
      this.multiChoiceInfo.confirmBtnText = "settings.save";
      this.multiChoiceInfo.dropdownList = [];
      this.trainingGroups.forEach((group) => {
        if (group.id != this.deletingStatus && group.id != -1) {
          this.multiChoiceInfo.dropdownList.push({
            id: group.id,
            name: group.name,
          });
        }
      });
      this.multiChoiceInfo.underBtnText = "modal.under_btn_dropdown_text";
    },
    selectSave(id) {
      this.multiChoiceInfo.disableTime = true;
      axios
        .post(
          `${this.$store.state.api_link}/training_group/move_all_trainings`,
          { id: id, deletingGroup: this.deletingStatus },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.status == 200) {
            let deletableIndex = this.trainingGroups.findIndex(el=>el.id==this.deletingStatus);
            if(deletableIndex != -1){
              let deletableGroup = this.trainingGroups[deletableIndex];
              let newGroupIndex = this.trainingGroups.findIndex(el=>el.id==id);
              if(newGroupIndex != -1){
                this.trainingGroups[newGroupIndex].trainings = this.trainingGroups[newGroupIndex].trainings + deletableGroup.trainings
              }
              this.trainingGroups.splice(deletableIndex, 1)
              this.trainingGroupsClone = this.trainingGroups;
              this.makeRows();
            }
            this.deletingStatus = "";
            this.multiChoiceInfo.disableTime = false;
          }
          this.closeModals();
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    choiceTwo() {
      this.multiChoiceInfo.type = "confirmation";
      this.multiChoiceInfo.confirmBtnText = "delete_modal.yes_delete";
      this.multiChoiceInfo.undertext = "modal.trainings_will_not_be_saved";
    },
    deleteGroupWithTrainings() {
      axios
        .post(
          `${this.$store.state.api_link}/training_group/delete_with_trainings`,
          { id: this.deletingStatus },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.status == 200) {
            this.trainingGroups = this.trainingGroups.filter(
              (obj) => obj.id != this.deletingStatus
            );
            this.trainingGroupsClone = this.trainingGroups;
            this.deletingStatus = "";
            this.makeRows();
            this.multiChoiceInfo.disableTime = false;
          }
          this.closeModals();
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    getColumnSize(folder, groups) {
      if (folder.sized == true) return;
      if (groups.length == 2) {
        if (window.innerWidth <= 1200) {
          folder.columnSize = 6;
        } else if (window.innerWidth <= 1700) {
          folder.columnSize = 12;
        } else {
          folder.columnSize = 18;
        }
        folder.sized = true;
      } else if (groups.length == 1) {
        if (this.windowWidth <= 768) {
          folder.columnSize = 1;
        } else {
          folder.columnSize = 12;
        }
        folder.sized = true;
      } else {
        groups.forEach((group) => {
          if (group.id != folder.id) {
            if (folder.trainings > group.trainings) {
              if (folder.id != -1) {
                if (group.columnSize != 3) {
                  folder.columnSize += 1;
                  group.columnSize -= 1;
                }
              }
            }
          }
        });
        folder.sized = true;
      }
      folder.columnSize = Math.round(folder.columnSize);
    },
    loadTrainings() {
      this.trainingGroups = [];
      this.trainingGroupsClone = [];
      axios
        .get(
          `${this.$store.state.api_link}/training_group/training_groups_edit`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          let boughtTrainings = response.data.bought;
          let temp = response.data.groups;
          if (temp == undefined) {
            return;
          }
          let training_count = temp.length;
          temp.forEach((trainingGroup) => {
            trainingGroup["editingTitle"] = false;
            this.addingTraining = false;
            this.addingNewGroup = false;
            if (
              trainingGroup.color_slider_pos != null &&
              trainingGroup.color_slider_pos != undefined
            ) {
              this.changeGroupHue(
                trainingGroup,
                trainingGroup.color_slider_pos
              );
            }
          });
          let sortableTemp = temp;
          // sortableTemp.forEach((trainingGroup)=>{
          //     if(trainingGroup.goes_after != 0){
          //       if(trainingGroup.goes_after == -1){
          //         let trainingGroupCopy = _.cloneDeep(trainingGroup);
          //         let currentIndex = temp.indexOf(trainingGroup);
          //         temp.splice(currentIndex, 1);
          //         temp.splice(0, 0, trainingGroupCopy);
          //       }else{
          //         let folderInFront = temp.find((el)=> {return el.id == trainingGroup.goes_after});
          //         let folderIndex = temp.indexOf(folderInFront);
          //         let trainingGroupCopy = _.cloneDeep(trainingGroup);
          //         let currentIndex = temp.indexOf(trainingGroup);
          //         temp.splice(currentIndex, 1);
          //         temp.splice(folderIndex+1, 0, trainingGroupCopy);
          //       }
          //   }
          // })

          setTimeout(() => {
              this.loaded = true;
          }, 20);
          this.trainingGroups = temp;
          if (boughtTrainings != 0) {
            this.trainingGroups.unshift({
              id: -1,
              name: this.toLocal("trainings.your_bought"),
              color_hue: "rgba(255, 255, 255, 1)",
              color_slider_pos: 0,
              trainings: [],
            });
            for (let i = 0; i < boughtTrainings; i++) {
              this.trainingGroups[0].trainings.push(" ");
            }
          }
          if(training_count == 0){
            this.loaded = true
          }
          this.trainingGroupsClone = _.cloneDeep(temp);
          this.searchTrainingGroups = _.cloneDeep(temp);
          VueScrollTo.scrollTo(`body`, 100);
          this.makeRows();
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    makeRows() {
      if (this.trainingGroups != undefined) {
        this.trainingRowGroups = [];
        this.trainingRows = 0;
        let itemInRow = 3;
        if(window.innerWidth <= 900){
          itemInRow = 1;
        }
        else if (window.innerWidth <= 1200) {
          itemInRow = 2;
        } else if (window.innerWidth <= 1700) {
          itemInRow = 2;
        }
        this.trainingRows = Math.ceil(this.trainingGroups.length / itemInRow);
        for (let i = 0; i < this.trainingRows; i++) {
          let tempGroup = [];
          for (let j = 0; j < itemInRow; j++) {
            if (this.trainingGroups[i * itemInRow + j] != undefined) {
              this.trainingGroups[i * itemInRow + j].columnSize = 12;
              this.trainingGroups[i * itemInRow + j].sized = false;
              tempGroup.push(this.trainingGroups[i * itemInRow + j]);
            }
          }
          tempGroup.forEach((folder) => {
            if (folder !== undefined && tempGroup !== undefined)
              this.getColumnSize(folder, tempGroup);
          });
          this.trainingRowGroups.push(tempGroup);
        }
        this.$store.state.loading.loadingDone();
        this.isFolderCreating = false;
      }
    },
    deleteGroup(group) {
      if (group.trainings.length == 0 || group.trainings == 0) {
        this.$store.state.globalModal.target = group.id;
        this.deleteModalOpened = true;
      } else {
        this.multiChoiceOpened = true;
        this.multiChoiceInfo = {
          type: "multiChoice",
          text: "modal.group_with_trainings",
          undertext: "modal.choose_what_to_do",
          multiChoiceYes: "modal.transfer_to_another",
          multiChoiceNo: "modal.delete_forever",
          underBtnText: "",
        };
        this.deletingStatus = this.activeOptionMenu;
      }
    },
    confirmedGroupDelete() {
      if (this.$store.state.globalModal.target != "") {
        axios
          .post(
            `${this.$store.state.api_link}/training_group/delete`,
            { id: this.$store.state.globalModal.target },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.deleteModalOpened = false;
            this.trainingGroups = this.trainingGroups.filter((group) => {
              return group.id != this.$store.state.globalModal.target;
            });
            this.trainingGroupsClone = _.cloneDeep(this.trainingGroups);
            this.makeRows();
            this.$store.state.globalModal.target = "";
          })
          .catch((error) => {
            this.$store.commit("handleError", { error: error });
            this.addingNewGroup = false;
          });
      }
    },
    closeDeleteModal() {
      this.deleteModalOpened = false;
      this.$store.state.globalModal.target = "";
    },
  },
  watch: {
    activeOptionMenu: {
      handler() {
        this.colorExpanded = false;
      },
    },
    searchTerm: {
      handler(newVal) {
        if (newVal != "") {
          this.trainingGroups = this.trainingGroupsClone.filter((group) =>
            group.name.toLowerCase().includes(newVal.toLowerCase())
          );
        } else {
          this.trainingGroups = this.trainingGroupsClone;
        }
        this.makeRows();
      },
    },
    trainingRowGroups: {
      handler(newVal) {
        if(this.$store.state.system_settings.grid_dynamic_size == 0) return
        if (newVal != undefined) {
          if (newVal[newVal.length - 1].length == 1) {
            if (
              newVal[newVal.length - 1] != undefined &&
              newVal[newVal.length - 2] &&
              newVal[newVal.length - 2][2] != undefined
            ) {
              newVal[newVal.length - 1].push(newVal[newVal.length - 2][2]);
              newVal[newVal.length - 2].pop();
              newVal[newVal.length - 1].forEach((group) => {
                if (window.innerWidth <= 1700) {
                  group.columnSize = 12;
                } else {
                  group.columnSize = 18;
                }
              });
              newVal[newVal.length - 2].forEach((group) => {
                if (window.innerWidth <= 1700) {
                  group.columnSize = 12;
                } else {
                  group.columnSize = 18;
                }
              });
            }
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .grid-center {
    grid-template-columns: repeat(24, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
.folder-button-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
}
.header-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
  }
  .create-new {
    padding: 8px 16px;
    padding-right: 46px;
    height: 40px;
    color: white;
    background: #333333;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    display: flex;
    position: relative;
    margin-right: 40px;
    user-select: none;
    transition: 0.25s;
    &:hover {
      background: #e2e2e2;
      color: black;
      .group-plus {
        svg {
          stroke: black;
          fill: black;
        }
      }
    }
    &.active {
      background: #e2e2e2;
      color: black;
      transition: 0.1s;
      .group-plus {
        svg {
          stroke: black;
          fill: black;
        }
      }
    }
    &:active {
      font-weight: bolder;
    }
    cursor: pointer;
    font-size: 16px;
    @media (max-width: 1680px) {
      width: 30%;
    }
    @media (max-width: 1280px) {
      width: 35%;
    }
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 768px) {
      margin-right: 0;
      width: 50%;
      min-height: 60px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .group-plus {
      position: absolute;
      right: 16px;
      top: 10px;
      @media (max-width: 768px) {
        height: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        width: 16px;
        height: 16px;
        stroke: white;
        fill: white;
      }
    }
  }
  .search-bar {
    width: 25%;
    border-radius: 40px;
    position: relative;
    background: #ffffff;
    border: 1px solid #7d7d7d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    display: flex;
    height: 40px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    @media (max-width: 1680px) {
      width: 30%;
    }
    @media (max-width: 1280px) {
      width: 35%;
    }
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 768px) {
      width: 50%;
      min-height: 60px;
      text-align: center;
    }
    &::placeholder {
      font-size: 16px;
    }
    &::-moz-placeholder {
      font-size: 16px;
    }
    .search-input {
      outline: none;
      border: none;
      width: 90%;
      font-size: 16px;
      background: #ffffff;
      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }
    .search-icon {
      position: absolute;
      right: 16px;
      top: 8px;
      @media (max-width: 768px) {
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.table-container{
  position: relative;
  width: 100%;
  height: 100%;
  .table-wrapper{
    position: absolute;
    left: 2rem;
    min-width: calc(100% - 3rem);
    
    top: 0;
  }
}
.folder-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 40px;
  min-height: 70vh;
  align-items: center;
  justify-content: center;
  .drag-ghost{
    display: none;
  }
}
.folder-rows {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  column-gap: 40px;
  margin-top: 40px;
  @media (max-width: 1700px) {
    grid-template-columns: repeat(24, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(12, 1fr);
    gap: 40px;
  }
}
.folder-block {
  min-height: 300px;
  min-width: 300px;
  max-width: 100%;
  background-color: rgb(97, 3, 3);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  position: relative;
  transition: 0.2s;
  &.bright {
    color: white;
    svg {
      &.themable{
        stroke: white;
      g {
        fill: white;
      }
      path {
        stroke: white;
      }
      }
    }
  }
  &.medium {
    color: #333333;
    color: #333333;
    svg {
      &.themable{
      g {
        fill: #333333;
      }
      path {
        stroke: #333333;
      }
      }
    }
  }
  &.blank {
    color: #333333;
    svg {
      &.themable{
      g {
        fill: #333333;
      }
      path {
        stroke: #333333;
      }
    }
    }
  }
  &.colors-open{
    &:hover {
    .folder-block-cover{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.25s;
      background-color: initial;
      border-radius: 16px;
    }
    .bottom-line {
      .arrow-container {
        svg {
          opacity: 1;
        }
      }
    }
  }
  }
  @media (max-width: 421px) {
      min-width: 150px;
    min-height: 250px;
  }
  cursor: pointer;
  &:hover {
    .folder-block-cover{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.25s;
      background-color: rgba($color: #000000, $alpha: 0.1);
      border-radius: 16px;
    }
    .bottom-line {
      .arrow-container {
        svg {
          opacity: 1;
        }
      }
    }
  }
}
.bottom-line {
  display: flex;
  width: 90%;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  bottom: 24px;
  left: 24px;
  cursor: pointer;
  .end{
    justify-content: flex-end;
  }
  .arrow-container {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 50%;
    justify-self: flex-end;
    svg {
      opacity: 0.5;
      transition: 0.5s;
    }
    &:hover {
      // background-color: hsla(0, 0%, 100%, 0.199);
      svg {
        opacity: 1;
      }
    }
  }
  &.end {
    justify-content: flex-end;
  }
}
.folder-name {
  width: 75%;
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 28px;
  height: 68%;
  cursor: pointer;
  word-wrap: break-word;
  overflow-y: hidden;
}
.folder-name-input {
  width: 70%;
  height: 70%;
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 28px;
  word-wrap: break-word;
  background-color: transparent;
  color: white;
  border: none;
  resize: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  font-family: var(--main-site-font);
  &:focus {
    outline: none;
  }
}
.option-dots {
  position: absolute;
  top: 20px;
  right: 16px;
  height: 42px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border-radius: 20px;
  transition: 0.5s;
  svg {
    width: 100%;
    height: 100%;
    overflow: visible;
    g {
      opacity: 0.5;
      transition: 0.5s;
    }
  }
  &:hover {
    svg {
      g {
        opacity: 1;
      }
    }
  }
}
.training-count {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.option-menu {
  z-index: 2;
  position: absolute;
  right: 18px;
  top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  max-height: 100%;
  color: black;
  background-color: rgba(249, 249, 249, 1);
  transform-origin: top;
  animation: expand 0.5s;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
    overflow: hidden;
  &.color {
    height: 80%;
    justify-content: center;
  }
  &.short{
    height: fit-content;
    animation: none;
  }
  .color-input {
    &.bright {
      background: linear-gradient(
        90deg,
        #e64545 0%,
        #e57300 10.33%,
        #b28500 19.44%,
        #64992e 28.3%,
        #2e992e 37.29%,
        #2e9964 45.88%,
        #2e9999 54.61%,
        #4596e5 63.72%,
        #4545e5 72.45%,
        #9645e6 81.95%,
        #992e99 91.2%,
        #e54596 100%
      );
    }
    &.medium {
      background: linear-gradient(
        90deg,
        #e57373 0%,
        #e5a667 10.33%,
        #e5e567 19.44%,
        #b8e58a 28.3%,
        #8ae58a 37.29%,
        #73e5ac 45.88%,
        #73e5e5 54.61%,
        #72ace5 63.72%,
        #8a8ae5 72.45%,
        #b88ae6 81.95%,
        #e58ae5 91.2%,
        #e58ab8 100%
      );
    }
    &.blank {
      background: linear-gradient(
        90deg,
        #e5b8b8 0%,
        #e5cfb8 10.33%,
        #e5e5b8 19.44%,
        #cfe5b8 28.3%,
        #b8e5b8 37.29%,
        #b8e5cf 45.88%,
        #b8e5e5 54.61%,
        #b8cfe5 63.72%,
        #b8b8e5 72.45%,
        #cfb8e5 81.95%,
        #e5b8e5 91.2%,
        #e5b8cf 100%
      );
    }
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 209px;
    max-height: 12px;
    -webkit-transform: rotate(90deg);
    // max-width: 12px;
    -webkit-appearance: none;
    cursor: pointer;
    &::-webkit-slider-runnable-track {
      width: 209px;
      max-width: 209px;
      height: 12px;
      // transform: r;
      // -webkit-transform: rotate(90deg);
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-sizing: border-box;
      margin-top: -6px;
      border: 3px solid #f9f9f9;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
      background-color: var(--thumb-color);
    }
    &::-moz-range-thumb {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-sizing: border-box;
      margin-top: -6px;

      border: 3px solid #f9f9f9;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
      background-color: var(--thumb-color);
    }
  }
  .option-block {
    width: 100%;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.group{
      svg{
        width: 50%;
      }
    }
    &:hover {
      background-color: #d9d9d9;
      &.group{
        svg path{
          fill: white;
        }
      }
    }
    &.color {
      .circle {
        width: 29px;
        background: conic-gradient(
          at 50% 50%,
          red,
          #ff0 16.66%,
          #0f0 33.33%,
          #0ff 50%,
          #00f 66.66%,
          #f0f 83.33%,
          red
        );
        height: 29px;
        border-radius: 50%;
      }
    }
  }
}
@keyframes expand {
  from {
    max-height: 0%;
  }
  to {
    max-height: 100%;
  }
}
.fixed {
  width: 50%;
  position: fixed;
  z-index: 1001;
  top: 0;
  height: 11vh;
  max-height: 11vh;
  .create-new {
    width: fit-content;
    padding: 0.8vh 1.6vh;
    padding-right: 6.8vh;
    height: 4.3vh;
    font-size: clamp(0px, 1.72vh, 1.5em);
  }
  .search-bar {
    width: fit-content;
    padding-right: 70px;
  }
  @media (max-width: 1280px) {
    max-width: 60%;
    justify-content: flex-end;
    .create-new {
      height: fit-content;
      width: 50%;
      text-align: center;
      margin-right: 10px;
      padding: 12px 16px;
      font-size: 13px;
      display: flex;
      align-items: center;
      span{
        flex-grow: 1;
        text-align: start;
      }
      .group-plus{
        height: 16px;
        width: 16px;
        position: initial;
      }
      
    }
    .search-bar {
      padding-right: 0;
    }
  }
  @media (max-width: 768px) {
    max-width: 75%;
    width: 75%;
    display: flex;
    justify-content: center;
    left: 20px;
    .create-new {
      padding-right: 50px;
    }
  }
}
.spinning {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.folder-page-container {
  .folder-block {
    .darkLetters {
      color: rgba(51, 51, 51, 1);
      path {
        stroke: rgba(51, 51, 51, 1);
      }
    }
  }
}
</style>
