<template>
    <div>
        <transition name="fade">
            <div v-html="toLocal('payment.return_policy')" v-show="$store.state.translations.length>0">

            </div>
        </transition>
      
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>

</style>