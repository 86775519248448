<template>
<div>
  <div class="test-tab-parent-container" v-if="test && test.options">
    <div class="test-locked" v-if="test.locked === 1 || (parentTest.options.active_version_id == test.id && (parentTest.versions.length >0) && topic.questions == parentTest.id)">
        <div class="info--header">
            <h3>{{toLocal('test_options.active')}}</h3>
            <p>{{toLocal('test_options.to_change')}}</p>
        </div>
    </div>
    <b>
        <div class="header">
            <input class="title" type="text" v-model="test.name" @focus="test.name == toLocal('test_options.new_question_group') ? test.name = '' : ''" @blur="test.name == '' ? test.name = toLocal('test_options.new_question_group') : ''"> 
            <span class="question-count"> {{ testQuestionCount }} {{textEnd(testQuestionCount, 'question')}}</span> 
        </div>
    </b>
    <textarea 
        :placeholder="toLocal('training_edit.insights_and_notes')"
        ref="notes"
        :style="notesInputStyle"
        v-model="test.description" 
    />
    <textarea class="shadow" v-model="test.description" ref="shadow" tabindex="0"></textarea>
    <!-- <div class="vertical--seperator" /> -->
    <transition name="fade" mode="out-in">
    <div class="test--settings--columns" v-if="expanded">
        <div class="test--settings--column">
        <div class="test--settings--inner--columns">
            <div class="option--row">
                <div class="option--col align-start">
                    <span class="option-title">{{toLocal('training_edit.test_passed_from')}}</span>
                    <div class="option--row inner answer">
                        <div class="option-input">
                            <input 
                                type="text" 
                                v-model="test.options.testPassedFrom"
                                @input="inputNumbersOnly($event, 'testPassedFrom', test.options, 0, 100)"
                                :class="{'error': test.options.testPassedFrom < 0 || test.options.testPassedFrom > 100 }"
                            > 
                        </div>
                            %
                    </div>
                </div>
                <div class="option--col align-start" v-if="test.type != 1">
                    <span class="option-title">{{toLocal('test_options.question_worth')}}</span>
                    <div class="option--row inner answer">
                        <div class="option-input">
                            <input 
                                type="text" 
                                name="question_value" 
                                :max="1000"
                                v-model="test.options.testQuestionValue"
                                v-mask="numberMask"
                                @input="$root.$emit('update-max-score', test.options.testQuestionValue)"
                                @keydown="inputNumbersOnlyInput"
                            >
                        </div>
                            {{textEnd(test.options.testQuestionValue, 'score')}}
                    </div>
                </div>
            </div>
            <div class="option--row">
                <div class="option--col align-start">
                    <span class="option-title">{{toLocal('test.length')}}</span>
                    <div class="question-count-container column">
                        <div 
                        class="question-count-btn all" 
                        :class="{'active': test.options.testLength == 0}" 
                        @click="test.options.testLength = 0"
                        >
                        {{toLocal('test.length_infinite')}}
                        </div>
                        <span>{{toLocal('training_edit.test_questions_or')}}</span>
                        <div class="flex-row">
                            <input 
                                type="text" 
                                v-model="test.options.testLength"
                                :max="test.questions.length"
                                @input="inputNumbersOnly($event, 'testLength', test.options, 0, 1000)"
                                :class="{'error': test.options.testLength < 0, 'active': test.options.testLength > 0 }"
                            >
                            <span>{{test.options.testTimeFormat == 0 ? toLocal('generic.min') : toLocal('generic.hours_short') }}</span>
                            <div class="time-option-container" @click="$set(test.options, 'testTimeFormat', test.options.testTimeFormat == 0 ? 1 : 0)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.271" height="7.002" viewBox="0 0 10.271 7.002">
                                    <path id="Polygon_3" data-name="Polygon 3" d="M4.329,1.1a1,1,0,0,1,1.613,0L9.1,5.411A1,1,0,0,1,8.3,7H1.974a1,1,0,0,1-.806-1.591Z" fill="#c5c5c5"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.271" height="7.002" viewBox="0 0 10.271 7.002">
                                    <path id="Polygon_2" data-name="Polygon 2" d="M4.329,1.1a1,1,0,0,1,1.613,0L9.1,5.411A1,1,0,0,1,8.3,7H1.974a1,1,0,0,1-.806-1.591Z" transform="translate(10.271 7.002) rotate(180)" fill="#c5c5c5"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="option--row" v-if="test.type != 1">
                <div class="option--col align-start">
                    <span class="option-title">{{toLocal('training_edit.questions_given')}}</span>
                    <div class="question-count-container">
                        <div 
                            class="question-count-btn all" 
                            :class="{'active': test.options.testShownQuestionCount == test.questions.length}" 
                            @click="test.options.testShownQuestionCount = test.questions.length"
                        >
                            {{toLocal('training_edit.test_questions_all')}}
                        </div>
                        <span>{{toLocal('training_edit.test_questions_or')}}</span>
                        <div class="flex-row"><input 
                            type="text" 
                            v-model="test.options.testShownQuestionCount"
                            @input="inputNumbersOnly($event, 'testShownQuestionCount', test.options, 0, test.options.testShownQuestionCount)"
                            :max="test.questions.length"
                            :class="{'error': test.options.testShownQuestionCount < 1 || test.options.testShownQuestionCount > test.questions.length, 'active': test.options.testShownQuestionCount != test.questions.length }"
                        >
                        <span>{{replaceQuestionLength(test.questions.length)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="option--row mb-0">
                <div class="option--col align-start">
                    <span class="option-title">{{ toLocal('tests.maximum_score') }}</span>
                    <div class="question-count-container">
                        <div 
                        class="question-count-btn all" 
                        :class="{'active': test.type == 1 ? test.options.testMaxScore == $store.state.testScores.maxScore : test.options.testMaxScore == maxPossibleSum}" 
                        @click="test.type == 1 ? $set(test.options, 'testMaxScore', $store.state.testScores.maxScore) : $set(test.options, 'testMaxScore', maxPossibleSum)"
                        >
                        {{toLocal('test_options.estimated')}}
                        </div>
                        {{toLocal('training_edit.test_questions_or')}}
                        <input 
                            type="text" 
                            v-model="test.options.testMaxScore"
                            @input="inputNumbersOnly($event, 'testMaxScore', test.options, 0, 10000)"
                            :class="{
                                error: test.type == 1 ?
                                    test.options.testMaxScore > $store.state.testScores.maxScore  || test.options.testMaxScore < $store.state.testScores.minScore
                                    :
                                    test.options.testMaxScore > maxPossibleSum || test.options.testMaxScore < minPossibleSum,
                                active: test.options.testMaxScore > 0 && test.options.testMaxScore <= maxPossibleSum}"
                        >
                    </div>
                </div>
            </div>
            <div class="option--row text-small" v-if="test.type != 1"> {{toLocal('tests.different')}} {{possibleCombinations}}</div>
            <div class="option--row text-small"> 
                {{toLocal('test_options.minimum_amount')}} {{test.type != 1 ? isNaN(minPossibleSum) ? '-' : minPossibleSum : $store.state.testScores.minScore}}
            </div>
            <div class="option--row text-small"> 
                {{toLocal('test_options.maximum_amount')}} {{test.type != 1 ?  isNaN(maxPossibleSum) ? '-' : maxPossibleSum : $store.state.testScores.maxScore}}
            </div>

            </div>

            <div class="test--settings--inner--column">
        </div>
        </div>
        <div class="test--settings--column align-start">
            <!-- <span class="option-title mb-1">{{toLocal('training_edit.test_answer_showing')}}</span>
            <div
                v-for="(showOption, index) in correctAnswerShowingList" 
                :key="index"
                :class="['answer--select', {'active': test.options.testAnswerShowType == showOption.id}]"
                @click="test.options.testAnswerShowType = showOption.id"
            >
                {{showOption.name}}
            </div> -->
            <span class="option-title">{{toLocal('training_edit.test_answer_mix')}}</span>
            <div class="option--row align-start mt-1 ">
                <div 
                :class="['answer--select max-content', {'active': test.options.testQuestionsShuffle}]"
                @click="test.options.testQuestionsShuffle = !test.options.testQuestionsShuffle"
                >
                    {{toLocal('generic.questions_multiple_who')}}
                </div>
                <div 
                :class="['answer--select max-content', {'active': test.options.testAnswersShuffle}]"
                @click="test.options.testAnswersShuffle = !test.options.testAnswersShuffle"
                >
                    {{toLocal('generic.question_choices')}}
                </div>
            </div>
            <div class="option--row">
                <div class="option--col align-start">
                    <span class="option-title">{{toLocal('test.solve_count')}}</span>
                    <div class="question-count-container">
                        <div 
                        class="question-count-btn all" 
                        :class="{'active': test.options.testSolveCount == 0}" 
                        @click="test.options.testSolveCount = 0"
                        >
                        {{toLocal('test_options.unlimited')}}
                        </div>
                        {{toLocal('training_edit.test_questions_or')}}
                        <input 
                            type="text" 
                            v-model="test.options.testSolveCount"
                            :max="test.questions.length"
                            @input="inputNumbersOnly($event, 'testSolveCount', test.options, 0, 100)"
                            :class="{ 'active': test.options.testSolveCount > 0 }"
                        >
                        {{textEnd(test.options.testSolveCount, 'times')}}
                       
                    </div>
                </div>
            </div>
            <div class="option--row">
                <div class="option--col align-start">
                    <span class="option-title">{{toLocal('tests.allow_retry_after_label')}}</span>
                    <div class="question-count-container">
                        <div 
                            class="question-count-btn all" 
                            :class="{'active': test.options.retryAfterInput == 0 || !test.options.retryAfterInput}" 
                            @click="test.options.retryAfterInput = 0"
                        >
                            {{toLocal('email.template_static_time_type_2')}}
                        </div>
                        {{toLocal('training_edit.test_questions_or')}}
                        <input 
                            type="text" 
                            v-model="test.options.retryAfterInput"
                            placeholder="0"
                            :class="{ 'active': test.options.retryAfterInput > 0 }"
                        >
                        <CustomDropdown
                            class="c-dropdown time-frame-tests-dropdown"
                            :list="timeFrameOptions"
                            :compareChosen="chosenTimeFrame"
                            key-label="id"
                            value-label="label_short"
                            :options="{
                                closeOnClick: true,
                                checkbox: false,
                                initial: chosenTimeFrame.label_short,
                            }"
                            @elClicked="selectTimeFrame"
                        />
                        
                       
                    </div>
                </div>
            </div>
            <div class="option--row align-start">
                <div class="option--col">
                    <span class="option-title">{{toLocal('test_options.partially_correct')}}</span>
                    <div class="option--row correct--answer--variations flex-start mb-1">
                        <div @click="$set(test.options, 'testPartiallySolvable', 1)" :class="['answer--select', 'small', {'active': test.options.testPartiallySolvable == 1}]">{{this.toLocal('training_edit.answer_yes')}}</div>
                        <div @click="$set(test.options, 'testPartiallySolvable', 0)" :class="['answer--select', 'small', {'active': test.options.testPartiallySolvable == 0}]">{{this.toLocal('training_edit.answer_no')}}</div>
                    </div>
                </div>
            </div>
           
            <!-- <div class="option--row align-start">
                <div class="option--col">
                    <span class="option-title">Norėsiu patikrinti atsakymus</span>
                    <div class="option--row correct--answer--variations flex-start mb-1">
                        <div @click="$set(test.options, 'testIsCheckable', 1)" :class="['answer--select', 'small', {'active': test.options.testIsCheckable == 1}]">Taip</div>
                        <div @click="$set(test.options, 'testIsCheckable', 0)" :class="['answer--select', 'small', {'active': test.options.testIsCheckable == 0}]">Ne</div>
                    </div>
                </div>
            </div> -->
            
            <!-- <div class="option--row mt-1 justify-center">
                <div class="option--col">
                    <span class="option-title">Teisingi atsakymų variantai</span>
                    <div class="option--row correct--answer--variations">
                        <div @click="$set(test.options, 'testCorrectAnswersShown', 1)" :class="['answer--select', {'active': test.options.testCorrectAnswersShown == 1}]">Rodomi</div>
                        <div @click="$set(test.options, 'testCorrectAnswersShown', 0)" :class="['answer--select', {'active': test.options.testCorrectAnswersShown == 0}]">Nerodomi</div>
                    </div>
                </div>
            </div> -->
            
        </div>
        <div class="test--settings--column last">
        <!-- <span class="option-title">{{toLocal('training_edit.test_answer_mix')}}</span>
        <div class="option--col align-start mt-1">
            <div 
            :class="['answer--select max-content', {'active': test.options.testQuestionsShuffle}]"
            @click="test.options.testQuestionsShuffle = !test.options.testQuestionsShuffle"
            >
                Klausimus
            </div>
            <div 
            :class="['answer--select max-content', {'active': test.options.testAnswersShuffle}]"
            @click="test.options.testAnswersShuffle = !test.options.testAnswersShuffle"
            >
                Atsakymų variantus
            </div>
        </div> -->
        <span class="option-title mb-1">{{toLocal('training_edit.test_answer_showing')}}</span>
        <div
            v-for="(showOption, index) in correctAnswerShowingList" 
            :key="index"
            :class="['answer--select', {'active': test.options.testAnswerShowType == showOption.id}]"
            @click="$set(test.options, 'testAnswerShowType', showOption.id)"
        >
            {{showOption.name}}
        </div>
        <br>
        <div class="option--col middle-col">
            <span class="option-title">{{toLocal('test_options.correct_answers')}}</span>
            <div class="option--row correct--answer--variations">
                <div @click="$set(test.options, 'testCorrectAnswersShown', 1)" :class="['answer--select', {'active': test.options.testCorrectAnswersShown == 1}]">{{this.toLocal('test_options.shown')}}</div>
                <div @click="$set(test.options, 'testCorrectAnswersShown', 0)" :class="['answer--select', {'active': test.options.testCorrectAnswersShown == 0}]">{{this.toLocal('test_options.not_displayed')}}</div>
            </div>
        </div>
        <div class="option--col last--col">
            <span class="option-title">{{toLocal('training_edit.test_answers_shown')}}</span>
                <div class="mt-1 flex-wrap">
                    <div 
                        class="option--row answer results-shown third-width"
                        v-for="(shownResult, index) in showResultsList"
                        :key="index"
                    >
                        <div 
                        :class="['answer--select answer--select--round', {'active': test.options.testResultShowType == index}]"
                        @click="test.options.testResultShowType = index"
                        >
                            <span v-if="index == 0"> %</span>
                            <svg v-if="index == 1" xmlns="http://www.w3.org/2000/svg" width="19.749" height="19.749" viewBox="0 0 19.749 19.749">
                                <g id="Group_7045" data-name="Group 7045" transform="translate(-1357.126 -976.234)">
                                    <text id="_10" data-name="10" transform="translate(1360.001 990.107)" fill="#28345c" font-size="12" font-family="SegoeUIBlack, Segoe UI" letter-spacing="-0.03em"><tspan x="0" y="0">10</tspan></text>
                                    <circle id="Ellipse_455" data-name="Ellipse 455" cx="9.5" cy="9.5" r="9.5" transform="translate(1357.501 976.609)" fill="none" stroke="#28345c" stroke-width="0.75"/>
                                </g>
                            </svg>
                            <svg v-if="index == 2" xmlns="http://www.w3.org/2000/svg" width="14.468" height="3" viewBox="0 0 14.468 3">
                                <path id="Path_731" data-name="Path 731" d="M-3188.371,207h11.468" transform="translate(3189.871 -205.5)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="3"/>
                            </svg>
                        </div>
                        <span >{{shownResult.name}}</span>
                    </div>
                </div>
        </div>
                <!-- <div class="option--col full-width">
                    <span class="option-title">Testo atsakymus rodyti</span>
                    <div class="option--col full-width">
                        <div @click="$set(test.options, 'testShowAnswersToUser', 1)" :class="['answer--select', 'justify-center', {'active': test.options.testShowAnswersToUser == 1}]">Iškart po testo</div>
                        <div @click="$set(test.options, 'testShowAnswersToUser', 2)" :class="['answer--select', {'active': test.options.testShowAnswersToUser == 2}]">
                            nuo 
                            <date-picker
                                class="show-answers-date-picker"
                                ref="addingTrainingGroupInput"
                                v-model="test.options.testShowAnswersToUserDate" 
                                label="date"
                                format = "YYYY-MM-DD"
                                placeholder="2022-00-00"
                                :popup-style="{'marginLeft': '-14px'}"
                                :disabledDate="disabledDates"
                                valueType="format"
                            >
                                <template v-slot:icon-calendar>

                                </template>
                            </date-picker>
                        </div>
                        <div @click="$set(test.options, 'testShowAnswersToUser', 3)" :class="['answer--select', {'active': test.options.testShowAnswersToUser == 3}]">Tik mokytojui leidus</div>
                        
                    </div>
                </div> -->
            <!-- <div class="option--row text-small">
                Bet kada galėsite paspausti viršuje esantį mygtuką "Skelbti atsakymus"
            </div> -->
        </div>
    </div>
    </transition>
  </div>
    <SkeletonLoader v-else layout="testOptionsTab" />
</div>
</template>

<script>
import _ from 'lodash';
import moment from "moment";
import SkeletonLoader from '@/components/layout/loaders/SkeletonLoader.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  integerLimit: 2

});
export default {
    name: 'TestOptionTab',
    components:{
        SkeletonLoader,
        CustomDropdown
    },
    data(){
        return{
            correctAnswerShowingList: [
                {id:0, name: this.toLocal('training_edit.test_answer_showing_dont')},
                {id:2, name: this.toLocal('training_edit.test_answer_showing_all')},
                {id:1, name: this.toLocal('training_edit.test_answer_showing_only_correct')},
                {id:3, name: this.toLocal('training_edit.test_answer_showing_only_incorrect')},
            ],
            showResultsList:[
                { name: this.toLocal('generic.percent_multiple'), icon: 'percent.svg'},
                { name: this.toLocal('generic.scores_multiple_who'), icon: 'score.svg'},
                // { name: 'Dalimis', icon: '2-3.svg'},
                { name: this.toLocal('test_options.not_displayed'), icon: 'line.svg'},
            ],
            maxQuestionPointSum: 0,
            possibleCombinations: 0,
            minPossibleSum: 0,
            minPossibleSumArray: [],
            combinationArray: [],
            maxPossibleSum: 0,
            numberMask,
            notesInputHeight: '0',
    
            timeFrameInput: 0,
            timeFrameOptions: [
                {
                    id: 0,
                    label: this.toLocal("generic.days_multiple"),
                    label_short: this.toLocal("generic.days_short"),
                    value: 1
                },
                {
                    id: 1,
                    label: this.toLocal("generic.weeks_multiple"),
                    label_short: this.toLocal("generic.weeks_short"),
                    value: 7
                },
                {
                    id: 2,
                    label: this.toLocal("generic.months_multiple"),
                    label_short: this.toLocal("generic.months_short"),
                    value: 30
                },
                {
                    id: 3,
                    label: this.toLocal("generic.years_multiple"),
                    label_short: this.toLocal("generic.years_short"),
                    value: 365
                },
            ],
            chosenTimeFrame:  {
                id: 0,
                label: this.toLocal("generic.days_multiple"),
                label_short: this.toLocal("generic.days_short"),
                value: 1
            },
        }
    },
    props:{
        topic: Object,
        test: Object,
        parentTest: Object,
        availableTestQuestions: Array,
        expanded: Boolean,
    },
    watch:{
        'test.name'(){
            this.emitSaveOption(this.test, this);
        },
        'test.options':{
            deep:true,
            handler: function(newValue, oldValue){
                this.emitSaveOption(this.test, this);
            }
        },
        'test.description'(){
            this.emitSaveOption(this.test, this);
        },
        'test.locked'(){
            this.emitSaveOption(this.test, this);
        },

        'test.options.testShownQuestionCount': function(newValue, oldValue){
            this.throttleCalc(this)
        },
        'test.options.testMaxScore': function(newValue, oldValue){
           this.throttleCalc(this)
        },
        questionScoreSum(){
            if(this.test.options && this.test.type != 1)
             this.throttleCalc(this)
        },
        'test.questions.length'(newValue, oldValue){
            if(this.test.options.testShownQuestionCount == oldValue){
                this.test.options.testShownQuestionCount = newValue;
            }
        },
        maxPossibleSum:{
            handler(){
                if((this.test.options.testMaxScore > this.maxPossibleSum) && this.test.type != 1){
                    this.test.options.testMaxScore = this.maxPossibleSum;
                }
            }
        },
        minPossibleSum:{
            handler(){
                if((this.test.options.testMaxScore < this.minPossibleSum) && this.test.type != 1)
                    this.test.options.testMaxScore = this.minPossibleSum;
            }
        },
        'test.description'(){
            if(this.$refs.shadow)
                this.autoSizeElement();
        },
        'test.options.retryAfterTimeFrame'() {
             if(!this.test.options.retryAfterTimeFrame){
                this.chosenTimeFrame = this.timeFrameOptions[0];
            }
            else {
                this.chosenTimeFrame =  this.timeFrameOptions.find(el => el.value == this.test.options.retryAfterTimeFrame)
            }
        }
   
    },
    mounted(){
        if(this.test && this.test.options){
            this.throttleCalc(this)
            this.$nextTick(()=>{
                if(this.$refs.shadow)
                    this.autoSizeElement();
            })
             if(!this.test.options.retryAfterTimeFrame){
                this.chosenTimeFrame = this.timeFrameOptions[0];
            }
            else {
                this.chosenTimeFrame =  this.timeFrameOptions.find(el => el.value == this.test.options.retryAfterTimeFrame)
            }
        }
    },
    computed:{
        questionScoreSum: function(){
            if(this.test.questions && this.test.questions.length >0){
                let mapped_scores = this.test.questions.map(el => el.score);
                const score_sum = (a, b) => parseFloat(a) + parseFloat(b);
                return mapped_scores.reduce(score_sum);
            }
            else{
                return 0;
            }
        },
        notesInputStyle () {
            return {
            'min-height': this.notesInputHeight
            }
        },
       
        testQuestionCount(){
            if(this.test.type == 1){
                if(this.test.options.tests){
                    let mapped = this.test.options.tests.map(el=>el = parseInt(el.count))
                    if(mapped.length > 0){
                        return mapped.reduce((a,b)=>a+b);
                    }
                    else{
                        return 0;
                    }
                }
                else return 0;
            }
            else if(this.test.questions){
               return this.test.questions.length;
            }
            else{
                return 0;
            }
        }
    },
    methods: {
        replaceQuestionLength(q_len){
          return this.toLocal('training_edit.test_questions_out_of').replace('<question_length>', q_len);
        },
        emitSaveOption: _.debounce(function(test, self){
                self.$emit("saveTestOption", test);
        }, 500),
        selectTimeFrame(id) {
            let index = this.timeFrameOptions.findIndex(el => el.id == id);
            if(index != -1){
                this.$set(this.test.options, 'retryAfterTimeFrame', this.timeFrameOptions[index].value);
                this.chosenTimeFrame
                console.log(this.chosenTimeFrame)
            }
        },
        inputNumbersOnly(e, key, options, min, max){
          let input = e.target.value;
          let replaced_input = input.replace(/\D+/g, '');
          if(parseInt(replaced_input) < min){
            replaced_input = min;
          }
          if(replaced_input > max){
            replaced_input = max;
          }
          this.$set(options, key, replaced_input);
        //   this.saveQuestionDataDebounce(question, this);
          // let text = parseInt(e.target.value);
        },
        inputNumbersOnlyInput(e){
            let start = e.target.selectionStart;
            if(e.key == ',') this.$set(this.test.options, 'testQuestionValue', [this.test.options.testQuestionValue.slice(0, start) + '.' + this.test.options.testQuestionValue.slice(start)].join(''));
        },
        
        textEnd(number, type){
            if(number == undefined){
                return '';
            }
            let text_array = [];
            if(type == 'score') text_array = [this.toLocal('generic.score_who'), this.toLocal('generic.score_one'), this.toLocal('generic.score_multiple')];
            if(type == 'times') text_array = [this.toLocal('generic.times_who'), this.toLocal('generic.times_one'), this.toLocal('generic.times_multiple')];
            if(type == 'question') text_array = [this.toLocal('generic.questions_who'), this.toLocal('generic.question'), this.toLocal('generic.questions_multiple')];
            let number_array = number.toString().split('');
            let last_number = number_array[number_array.length - 1];
            if(last_number == 0) return text_array[0];
            else if(parseInt(number)> 10 && parseInt(number)<= 19) return text_array[0];
            else if(last_number == 1) return text_array[1];
            else if(last_number> 1 && last_number<= 9) return text_array[2];
        },
        throttleCalc: _.throttle(function(self){
            self.calculatePossibleCombinations()
        }, 1000),
        calculatePossibleCombinations(){

            if(this.test.options.testShownQuestionCount != 0 &&  this.test.questions.length != 0 ){
                let mapped_scores = this.test.questions.map(el =>parseFloat(el.score));
                mapped_scores = mapped_scores.filter((el)=>{
                    return el == '' || isNaN(el) ? false : true;
                })
                const score_sum = (a, b) => parseFloat(a) + parseFloat(b);
                this.maxQuestionPointSum = mapped_scores.reduce(score_sum);
                this.minPossibleSum = this.maxQuestionPointSum * 2;
                this.maxPossibleSum = 0;
                this.calcMaxScore(mapped_scores, this.test.options.testShownQuestionCount);
                this.calcMinScore(mapped_scores, this.test.options.testShownQuestionCount);
                this.calcPossibleCombinations(this.combinationArray, this.minPossibleSumArray);
                
            }
            else if( this.test.questions.length == 0){
          
                this.maxQuestionPointSum = 0
                this.minPossibleSum = 0;
                this.maxPossibleSum = 0;
     
            }
        },

        calcMaxScore(score_list, list_length){
            let list_copy = this._.cloneDeep(score_list);
            list_copy = list_copy.filter((el)=>{
                if(el != ''){
                    return true;
                }
                else{
                    return false;
                }
            })
            let subset_max = [];
            let max_sum = 0;
            if(list_copy.length >= list_length){
                let finished = false;

                while(!finished){
                    let max_value_index = list_copy.indexOf(Math.max(...list_copy));
                    if(max_value_index != -1){
                        subset_max.push(list_copy[max_value_index]);
                        max_sum += list_copy[max_value_index];
                        list_copy.splice(max_value_index, 1);
                    }
                    if(subset_max.length == list_length) finished = true;
                }
                this.maxPossibleSum = Math.round(max_sum * 1000) / 1000;
            }
        },
        calcMinScore(score_list, list_length){
            let list_copy = this._.cloneDeep(score_list);
             list_copy = list_copy.filter((el)=>{
                if(el != ''){
                    return true;
                }
                else{
                    return false;
                }
            })
            let subset_min = [];
            let min_sum = 0;
            if(list_copy.length >= list_length){
                let finished = false;
                let it = 0;
                while(!finished){
                    let min_value_index = list_copy.indexOf(Math.min(...list_copy));
                    if(min_value_index != -1){
                        subset_min.push(list_copy[min_value_index]);
                        min_sum += list_copy[min_value_index];
                        list_copy.splice(min_value_index, 1);
                    }
                    if(subset_min.length == list_length) finished = true;
                    if(it > 50 ) finished = true;
                    it++;
                }
                this.combinationArray = subset_min;
                this.minPossibleSumArray = list_copy;

                this.minPossibleSum = Math.round(min_sum * 1000) / 1000;
            }
        },
        calcPossibleCombinations(comb_array, min_possible_sums){
            this.possibleCombinations = 1;
            min_possible_sums.forEach(element => {
                comb_array.forEach((el)=>{
                    if(this.minPossibleSum - el + element <= parseFloat(this.test.options.testMaxScore)){
                        this.possibleCombinations++;
                    }
                })
            });
        },
        calcCombinations(a, min) {
            var fn = function(n, src, got, all) {
                if (n == 0) {
                    if (got.length > 0) {
                        all[all.length] = got;
                    }
                    return;
                }
                for (var j = 0; j < src.length; j++) {
                    fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
                }
                return;
            }
            var all = [];
            for (var i = min; i < a.length; i++) {
                fn(i, a, [], all);
            }
            all.push(a);
            return all;
        },
        disabledDates(time) {
            var date = moment(String(time)).format('YYYY-MM-DD');
            var d1 =moment().format('YYYY-MM-DD');
            if (date>=d1) {
                return false;
            }
            else return true;
        },
        autoSizeElement(){
            this.notesInputHeight = `${this.$refs.shadow.scrollHeight}px`
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
.test-tab-parent-container{
    .test-locked{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 5;
        &::after{
            display: flex;
            content: '';
            background: #efefef08;
            // -webkit-backdrop-filter: saturate(180%) blur(1px);
            // backdrop-filter: saturate(180%) blur(1px);
             background: #efefef94;
            z-index: 5;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
        }

        .info--header{
            background: var(--third-color);
            width: 100%;
            top: 0;
            position: absolute;
            padding: 1.75rem 1rem;
            text-align: center;
            color: var(--primary-color);
            h3{
                margin: 0;
                font-weight: 700;
            }
            p{
                margin: 0;
            }
        }
    }
}
@supports not (backdrop-filter: blur(1px)) {
    .test-tab-parent-container{
        .test-locked{
            background: #efefef94;
            &::after{
                background: #efefef94;
            }
        }
    }
}
span{
    font-size: clamp(10px, 1vw, 14px);
}
.option-title{
    position: relative;
    color: var(--primary-color);
    font-size: clamp(10px, 1vw, 16px);
    // &::after{
    //     position: absolute;
    //     content: '';
    //     bottom: 0%;
    //     left: 0;
    //     width: 100%;
    //     height: 10px;
    //     background: white;
    //     border-radius: 10px;
    //     z-index: -1;
    // }
}
.test--settings--column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.last{
        padding-right: 0px;
    }
}
.test--settings{
    margin-bottom: 2rem;
    .answer--select {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin: 0.3rem 0;
        width: 100%;
        text-align: center;
        &.active, &:hover {
            background-color: var(--secondary-color);
            border-color: transparent;
            color:  var(--primary-color);
        }
        &.max-content{
            width: max-content;
        }
        &.answer--select--round{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
            span{
                font-weight: 900;
            }
            svg{
                path{
                    stroke: var(--primary-color);
                }
            }
        }
        &.small{
            width: 3rem;
            margin: 0.3rem 0;
        }
    }
}
.width-80{
    width: 80%;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
    .half-width{
        width: 50%;
    }
}
.last--col{
    margin-top: 1rem;
}
.option--row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
    &.inner{
        margin-top: 0.5rem;
    }
    &.answer{
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.25rem;
        text-align: center;
    }
    &.correct--answer--variations{
        margin-top: 0.5rem;
        margin-bottom: 0rem;
        .answer--select{
            margin: 0 0.1rem;
            width: 100%;
            text-align: center;
            
            &.small{
                width: 3rem;
                margin: 0.3rem 0;
                margin-right: 1rem;
            }
        }
    }
    &.results-shown{
        flex-direction: column;
        align-items: center;
        // justify-content: center;
    }
    &.text-small{
        font-size: 12px;
        margin-bottom: 0.25rem;
        width: 80%;
        color: var(--primary-color-opacity-50);
    }
    &.third-width{
        width: 33%;
    
    }
    &.mb-0{
        margin-bottom: 0;
    }
}
.flex-start{
    justify-content: flex-start !important;
}
.option--col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width:45%;
    font-size: clamp(10px, 1vw, 16px);
    &.middle-col{
        margin-top: 1rem;
    }
    // &:first-child{
    //    margin-right: 0.5rem;
    // }
    // &:last-child{
    //    margin-left: 0.5rem;
    // }
}
.full-width{
    width: 100%;
    // .answer--select{
    //     width: 100%;
    // }
}
.option-input{
    border-radius: 20px;
    padding: 0.25rem;
    background: none;
    border: 1px solid var(--primary-color);
    font-size: clamp(16px, 0.5vw, 1vw);
    text-align: center;
    width: 2.5rem;
    margin: 5px;
    display: flex;
    justify-content: center;
    input{
        border: none;
        background: none;
        width: 100%;
        text-align: center;
        color: #8d8d8d;
        font-weight: 700;
        &:focus{
            outline: 0;
        }
        &.error{
            color: red;
        }
    }
}
.time-option-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 1rem;
    cursor: pointer;
    svg{
        margin: 2px 0;
        transition: transform 0.2s ease-in-out;
        &:hover{
            transform: scale(1.1);
        }
    }
}
.flex-row{
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.align-start{
    align-items: flex-start;
    .question-count-btn{
        margin-left: 0 !important;
    }
}
.justify-center{
    justify-content: center;
}
.test--settings .plus--minus--select{
    margin-top: 0.5rem;
}
.test--settings .question-count-container .question-count-btn{
    padding: 1px 10px;

}
.test--settings .question-count-container .question-count-btn.active, .test--settings .question-count-container input.active{
    background-color: var(--secondary-color);
    border-color: transparent;
    color:  var(--primary-color);

}
.test--settings .question-count-container input.error{
    color: red;
}

.test--settings .question-count-container input{
     margin-right: 3px;
     color: #8d8d8d;
    font-weight: 700;
}
.test--settings .question-count-container .question-count-btn{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.test--settings .test--settings--inner--columns{
    color: var(--primary-color);
    width: 100%;
}
.test--settings .question-count-container{
    color: var(--primary-color);

}
.test--settings{
    .test--settings--columns{
        display: grid;
        grid-template-columns: 1.1fr 1.1fr 208px;
        gap: 0 49px;
        .test--settings--column{
            width: 100%;
            border-right: none;
            justify-content: flex-start;
            padding: 0;
            
        }
    }
    .vertical--seperator{
        background: var(--primary-color);
    }
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .question-count{
        font-size: 16px;
        font-weight: 500;
        color: var(--primary-color-opacity-50);
    }
  
}
.mt-1{
    margin-top: 0.5rem;
}
.mb-1{
    margin-bottom: 0.5rem;
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  max-height: 558px;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  padding: 0;
  max-height: 0px;
}

@media(max-width:1280px){
    .header{
    .question-count{
      font-size: 14px;
    }
  }
  .test--settings{
    .test--settings--columns{
        padding-top: 28px;
    }
    textarea{
        font-size: 14px;
        height: 40px;
    }
  }
}

@media(max-width:1024px){
    .test--settings{
    .test--settings--columns{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .test--settings--column{
        width: 29%;
        &:last-child{
            width: 22%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .option--row{
            gap: 2px;
            margin-bottom: 15px;
            &:not(.inner){
                gap: 10px;
            }
        }
      }
    }
    .answer--select{
        font-size: 10px;
    }
    }
  .option-input{
    margin: 0;
    input{
        font-size: 10px;
    }
  }
}
</style>
<style lang="scss">
    .show-answers-date-picker{
        width: 4rem !important;
        input{
            background: none;
            border: none;
            box-shadow: none;
                height: auto;
            padding:0;
            &::placeholder{
                font-weight: 700;
            }
        }
        .mx-icon-calendar{
            display: none;
        }
        .mx-icon-clear{
            right: -0.5rem;
        }
    }
    .time-frame-tests-dropdown{
        &.dropdown-container{
            &:not(.no-padding-no-bg){
                border: 1px solid var(--primary-color);
                background: none;
                border-radius: 27px;
                padding-top: 0;
                padding-bottom: 0;
                width: 65px;
                padding: 0px 5px 0px 8px;
            }
            .dropdown-initial{
                gap: 5px;
                .white{
                    path{
                        stroke: var(--primary-color);
                    }
                }
            }
            .dropdown-extended .dropdown-option span{
                max-width: 100%;
            }
        }
    }
</style>