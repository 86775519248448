<template>
  <div 
    v-if="!hide"
    :class="{ 'loading--screen': true, loaded: loaded, ie: ifIE() }" 
  >
    <img
      :src="this.$store.state.api_uploads_link + this.$store.state.system_settings['main_logo']"
    >
    <svg class="loader">
      <g>
        <path d="M 50,100 A 1,1 0 0 1 50,0"/>
      </g>
      <g>
        <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
      </g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-opacity:1" />
          <stop offset="100%" style="stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
    data: function() {
        return {
            loaded: false,
            hide: false
        }
    },
    methods: {
        ifIE() { // if internet explorer
          var ua = window.navigator.userAgent;
          var msie = ua.indexOf("MSIE ");
          return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)
        },
        loadingDone() {
          setTimeout(() => {
            this.loaded = true;
            setTimeout(() => {
                this.hide = true;
            }, 300);
          },300);
        },
        loadingStart() {
            this.hide = false;
            setTimeout(() => {
              this.loaded = false;
            });
        },
        isLoading() {
          return !this.loaded;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/layout/_loadingScreenScoped.scss';
</style>