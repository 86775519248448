<template>
    <div class="test--options--container" :class="{'test-select-filter': $store.state.selectTestsModeActive && scrolledTopic == topicIndex}">
        <div class="tab--container">
            <div
            class="tab"
            :style="{'z-index': selectedTabIndex == tab_index ? 5 : tabs.length-tab_index}"
            :class="{selected: selectedTabIndex == tab_index}"
            v-for="(tab, tab_index) in tabs" 
            :key="tab_index" 
            @click="selectedTabIndex = tab_index"
            >
                {{tab.name}}
            </div>
        </div>
        <div class="test--settings" v-if="topic.test && test">
            <transition name="fade" mode="out-in">
            <TestOptionTab v-if="selectedTabIndex == 0" :expanded="tabExpanded" :topic="topic" :test="test" :availableTestQuestions="availableTestQuestions"  :parentTest="parentTest" @saveTestOption="$emit('saveTestOptionParent', $event);"/>
            <TestStatisticsTab v-else-if="selectedTabIndex == 1" :topic="topic" :test="test" :availableTestQuestions="availableTestQuestions" @changeGroup="$emit('changeGroupContent', $event)"/>
            <TestCheckAnswersTab v-else-if="selectedTabIndex == 2" :topic="topic" :test="test" :availableTestQuestions="availableTestQuestions" :questions="questions" @changedFilter="$emit('changeAnswerFilter', $event)" @changedFilterItemID="$emit('changedFilterItem', $event)"/>
            </transition>
        </div>
        <div class="expand-btn" v-if="selectedTabIndex == 0" @click="tabExpanded = !tabExpanded">
            <span>{{tabExpanded ? toLocal('test.settings_collapse') : toLocal('test.settings_expand')}} {{toLocal('settings.window')}}</span>
            <span :class="{active: tabExpanded}"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                    <circle id="Ellipse_581" data-name="Ellipse 581" cx="13" cy="13" r="13" fill="#efefef"/>
                    <path id="Path_8390" data-name="Path 8390" d="M-3731.645-17228.5l7.632,6.723,7.632-6.723" transform="translate(3737.013 17239.139)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </svg>
            </span>
        </div>
    </div>
</template>

<script>
import TestOptionTab from './TestOptionTab.vue'
import TestStatisticsTab from './TestStatisticsTab.vue'
import TestCheckAnswersTab from './TestCheckAnswersTab.vue'
export default {
    name: 'TestOptions',
    components:{
        TestOptionTab,
        TestStatisticsTab,
        TestCheckAnswersTab
    },
    data(){
        return{
            tabs: [
                {name: this.toLocal('test.test')},
                {name: this.toLocal('test.stats')},
                {name: this.toLocal('test.check_answers')},
            ],
            selectedTabIndex: 0,
            tabExpanded: false,
        }
    },
    watch:{
        selectedTabIndex(newVal){
            this.$emit('changeTab', newVal);
        }
    },
    props:{
        topic: Object,
        topicIndex: Number,
        scrolledTopic: Number,
        test: Object,
        parentTest: Object,
        availableTestQuestions: Array,
        groups: Array,
        users: Array,
        dates: Array,
        questions: Array,
    },
    methods:{
        replaceQuestionLength(q_len){
          return this.toLocal('training_edit.test_questions_out_of').replace('<question_length>', q_len);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
.test--options--container{
    position: relative;
}
.test--settings{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    background-color: #efefef;
    z-index: 2;
    position: relative;
}
.tab--container{
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;
    .tab{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.7rem 1rem 1.5rem;
        width: 100%;
        background: white;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        box-shadow: 3px -1px 7px -2px #cbcbcb;
        margin-right: -1rem;
        cursor: pointer;
        font-weight: 900;
        color: var(--primary-color);
        &:last-child{
            margin-right: 0;
        }
        &::before{
            position: absolute;
            content: '';
            width: 50%;
            height: 0.5rem;
            background-color: #efefef;
            border-radius: 10px;
            left: 0%;
            right: 0%;
            bottom: 0.55rem;
            margin: auto;
        }
         &::after{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #ffffff70;
            border-radius: 10px;
            left: 0%;
            right: 0%;
            bottom: 0;
            top:0;
            margin: auto;
        }
        &.selected{
            border: 4px solid #fff;
            border-bottom: none;
            background-color: #efefef;
            &::before{
                background-color: #fff;
            }
            &::after{
                display: none;
            }
        }

    }
}
.expand-btn{
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 0px;
    background: white;
    bottom: -35px;
    height: 3rem;
    padding: 5px 5px 5px 2rem;
    border-radius: 6px;
    color: var(--primary-color-opacity-50);
    z-index: 1;
    transition: width 0.5s ease;
    cursor: pointer;
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: width 0.5s ease;
    }
    span:first-child{
        width: 100%;
        margin-right: 22px;
    }
    span:nth-child(2){
      border-radius: 50%;  
    }
    svg{
        transition: transform 0.5s;
        path{
            stroke: var(--primary-color);
        }
    }
    .active{
        svg{
            transform: rotate(180deg);
        }
    }
    &:hover{
        span{
            color: var(--primary-color);
        }
        // span:nth-child(2){
        //     border: 1px solid var(--primary-color);
        // }
    }
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  max-height: 30rem;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  max-height: 0rem;
}
@media(max-width:1280px){
    .expand-btn{
        padding: 5px 5px 5px 8px;
    span{
        font-size: 16px;
        &:first-child{
            margin-right: 16px;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }

  }
}
</style>