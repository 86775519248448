<template>
  <div>
    <div 
      :class="['side--background', $store.getters.isCurrentSide('training-downloads-side') ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('training-downloads-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <img src="@/assets/images/icons/side-close.svg">
      </div>
      <div class="side--content">
        <div class="group--title">
          <span v-if="loading"><font-awesome-icon icon="spinner" /></span>
          <span v-if="!loading">{{ toLocal('training.files_title') }} ({{ downloads.length }})</span>
        </div>
        <div class="lesson--list">
          <table 
            :class="['side--table--control']"
          >
            <thead>
              <tr>
                <th class="large">
                  {{ toLocal('training.files') }}
                </th>
                <th>{{ toLocal('training.file_download_count') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(download, downloadIndex) in downloads"
                :key="downloadIndex"
              >
                <td>{{ download.name }}</td>
                <td class="centered">
                  <div class="border-left">
                    {{ download.downloads }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>  
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TrainingDownloadsSide",
  data: function(){
    return {
      "id": "-1",
      loading: false,
      loadInterval: null,
      downloads: [],
    }
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      if(!this.$store.state.side_info['loaded'] && this.$store.state.side_open == "training-downloads-side") {
        this.$store.state.side_info['loaded'] = true;
        this.loading = true;
        this.downloads = [];
        this.id = this.$store.state.side_info['id'];
        axios.post( 
          `${this.$store.state.api_link}/training/stats/downloads`,
          {training: this.$store.state.currentTraining},
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.downloads = response.data;
          this.loading = false;
        }).catch((error) => {
          console.log(error)
        });
      }
    }, 200);
  },
  beforeDestroy() {
    if(this.loadInterval != null){
      clearInterval(this.loadInterval);
      this.loadInterval = null;
      this.loading = true;
    }
  },
  methods: {
    closeSide() {
      this.$store.state.side_open = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";

    .side--background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .group--side {
        position: fixed;
        right: -850px;
        background-color: #fff;
        height: calc(100vh - 100px);
        top: 100px;
        width: 750px;
        z-index: 11;
        font-family: var(--main-site-font);
        transition: .3s right;

        &.side--open {
          right: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }

    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        img {
            width: 47px;
            height: 47px;
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -2px;
      }
    }
    .group--title{ 
      font-size: 35px;
      padding-top: 15px;
      font-weight: 900;
      padding-left: 30px;

      span {
        font-size: 35px;
        display: block;
      }
    }

    .lesson--list {
      position: relative;
    }

    .side--table--control {
      margin-top: 50px;
      width: 100%;
      margin: 0;
      // position: absolute;
      right: 0;
      transition: .3s right;

      &.hidden {
        right: calc(-100% - 150px);
      }
      
      tr {
        border-radius: 35px;
      }

      td {
        background-color: #EFEFEF;
      }

      svg {
        margin-top: -2px;
      }

      .time {
        font-size: 15px;
        line-height: 15px;

        div {
          white-space: pre-line;
        }
      }
    }
</style>