<template>
  <div v-scroll-lock="isMobileWindow() && $store.getters.isCurrentSide('chat-side')">
    <div 
      :class="['side--background', $store.getters.isCurrentSide('chat-side') ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('chat-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
        <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
          <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
            <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
              <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
            </g>
          </g>
        </g>
      </svg>

      </div>
      <div class="side--content">
        <h1 v-if="chatLoading">
          <font-awesome-icon icon="spinner" />
        </h1>
        <div
          v-if="!chatLoading && chatMessages.length == 0"
          class="placeholder"
        >
          <img 
            class="chat--bg--1"
            src="@/assets/images/icons/chat-bg-1.svg"
          >
          <h1>
            {{ toLocal('chat.initial') }}
          </h1>
          <img 
            class="chat--bg--2"
            src="@/assets/images/icons/chat-bg-2.svg"
          >
        </div>
        <div 
          v-if="!chatLoading && chatMessages.length > 0"
          class="chat--messages--wrapper"
        >
          <div
            v-for="(message, messageIndex) in chatMessages"
            :key="messageIndex"
          >
            <div 
              v-if="displayTimestamp(messageIndex)"
              class="timestamp"
            >
              {{ message.time }}
            </div>
            <div :class="['message message--' + message.location]">
              <div
                v-if="displayAvatar(messageIndex)" 
                class="avatar"
              >
                {{ message.avatar }}
              </div>
              <div 
                v-if="message.sending" 
                class="message--loading"
              >
                <font-awesome-icon icon="spinner" />
              </div>
              <div class="message--text">
                {{ message.message }}
              </div>
            </div>
          </div>
        </div>
        <div 
          v-if="!chatLoading"
          class="chat--input--wrapper"
        >
          <div 
            v-if="messageTooLong()"
            class="error--message"
          >
            {{ toLocal('chat.max_symbols') }}
          </div>
          <input 
            ref="chatInput"
            type="text"
            class="chat--input"
            placeholder="Rašyti žinutę"
            name="chat-input"
            @keyup.enter="sendMessage()"
          >
          <svg @click="sendMessage()" xmlns="http://www.w3.org/2000/svg" width="26.464" height="25.401" viewBox="0 0 26.464 25.401">
            <g id="Group_707" data-name="Group 707" transform="translate(0.251 0.27)">
              <path id="Path_783" data-name="Path 783" d="M437.333,426.667Z" transform="translate(-415.157 -405.031)" fill="#707070" stroke="#707070" stroke-width="0.5"/>
              <path id="Path_784" data-name="Path 784" d="M25.953.929c0-.021.005-.041.007-.062s0-.02,0-.031,0-.023,0-.034,0-.026,0-.039,0-.011,0-.017A.81.81,0,0,0,24.782.086l-.007,0L24.757.1,24.747.1.434,12.8a.812.812,0,0,0,.115,1.487l8.916,3.047v6.732a.811.811,0,0,0,.811.811h0a.816.816,0,0,0,.654-.33l3.942-5.365,7.042,2.407a.829.829,0,0,0,.262.043.813.813,0,0,0,.8-.691L25.952.936h0ZM21.325,3.722l-11.277,12.1L2.862,13.363ZM11.088,21.593v-3.7l2.175.743Zm10.428-1.854-9.785-3.345L23.964,3.273Z" transform="translate(0.001 0.003)" fill="#707070" stroke="#707070" stroke-width="0.5"/>
            </g>
          </svg>
        </div>
      </div>
    </section>
 </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ChatSide",
  data: function(){
    return {
      id: -1,
      loadInterval: null,
      chatUpdateInterval: null,
      sendingMessage: false,
      chatMessages: [],
      chatLoading: true,
      chatInput: "",
      lastChatMessages: "",
    }
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      if(!this.$store.state.side_info['loaded'] && this.$store.state.side_open == "chat-side") {
        this.$store.state.side_info['loaded'] = true;
        this.id = this.$store.state.side_info['id'];
        this.$store.state.unread_chats = [];
        this.loadMessages();
      }
    }, 200);
    this.chatUpdateInterval = setInterval(() => {
      this.loadMessages();
    },3000);
  },
  watch:{
    '$store.state.chatMessagesQueue':{
      immediate:true,
      deep:true,
      handler(newData, oldData){
        if(!this.$store.getters.isCurrentSide('chat-side')) return;
        newData.forEach((el, index) =>{
          if(this.chatMessages.findIndex(elem => elem.id == el.id) == -1) {
            this.chatMessages.push(el);
            this.$store.state.chatMessagesQueue.splice(index, 1);
           
            this.$store.state.unread_chats = [];
          }
          else{
            this.$store.state.chatMessagesQueue.splice(index, 1);
          }
          
        })
        // this.chatMessages.push()
      }
    }
  },
  destroyed() {
    if(this.loadInterval != null){
      clearInterval(this.loadInterval);
      this.loadInterval = null;
    }
    if(this.chatUpdateInterval != null) {
      clearInterval(this.chatUpdateInterval);
      this.chatUpdateInterval = null;
    }
  },
  methods: {
    closeSide() {
      this.$store.state.side_open = false;
    },
    sendMessage(){
      if(this.messageTooLong())
        return;
      let element = this.$refs['chatInput'];
      let msg = element.value;
      if(msg.length == 0)
        return;
      element.value = "";

      let message = {
        avatar: this.$store.state.user_email.substring(0,1).toUpperCase(),
        location: "right",
        time: moment(new Date()).format("YYYY-MM-DD | HH:mm"),
        message: msg,
        sending: true,
      };
      this.chatMessages.push(message);

      axios.post(
        `${this.$store.state.api_link}/chat/send/user`,
        {message: msg},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        this.chatMessages = response.data.data;
      })
      .catch(() => {

      });

      setTimeout(() => {
        let wrapper = this.$el.querySelector(".chat--messages--wrapper");
        wrapper.scrollTop = wrapper.scrollHeight;
      },10);
    },
    loadMessages() {
      if(this.$store.state.side_open != 'chat-side')
        return;
      axios.post(
        `${this.$store.state.api_link}/chat/open`,
        {},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        this.chatMessages = response.data.data;
        this.chatLoading = false;
        if(this.lastChatMessages != JSON.stringify(this.chatMessages)){
          this.lastChatMessages = JSON.stringify(this.chatMessages);
          setTimeout(() => {
            let wrapper = this.$el.querySelector(".chat--messages--wrapper");
            wrapper.scrollTop = wrapper.scrollHeight;
          },10);
        }
      })
      .catch(() => {

      });
    },
    displayTimestamp(msgIndex) {
      if(msgIndex == 0)
        return true;

      let currentTime = this.chatMessages[msgIndex].time.split(" \| ");
      let currentDate = currentTime[0];
      let currentHour = currentTime[1].split(":")[0];

      let lastTime = this.chatMessages[msgIndex - 1].time.split(" \| ");
      let lastDate = lastTime[0];
      let lastHour = lastTime[1].split(":")[0];

      if(currentDate != lastDate || currentHour != lastHour)
        return true;

      return false;
    },
    displayAvatar(msgIndex) {
      if(msgIndex == 0)
        return true;
      if(this.chatMessages[msgIndex].location != this.chatMessages[msgIndex - 1].location)
        return true;
      if(this.chatMessages[msgIndex].avatar != this.chatMessages[msgIndex - 1].avatar)
        return true;

      let currentTime = this.chatMessages[msgIndex].time.split(" \| ");
      let currentDate = currentTime[0];
      let currentHour = currentTime[1].split(":")[0];

      let lastTime = this.chatMessages[msgIndex - 1].time.split(" \| ");
      let lastDate = lastTime[0];
      let lastHour = lastTime[1].split(":")[0];

      if(currentDate != lastDate || currentHour != lastHour)
        return true;

      return false;
    },
    messageTooLong() {
      return this.$refs['chatInput'] && this.$refs['chatInput'].value.length > 2048;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";
@import "@/assets/scss/components/layout/users/_chatSideScoped.scss";
</style>