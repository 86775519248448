<template>
  <div class="content-container">
    <div class="refresh-button" :class="{active: isLoadingNewData}" @click="getUserStats">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.04 122.88"><path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/></svg>
    </div>
      <div class="table-container">
          <h2>{{getGroupName}}</h2>

          <div class="grid-table">
              <div class="table-row header">
                  <div class="table-cell">
                      <span class="name-cell header">
                            {{toLocal('generic.name')}}
                            <img
                                v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                                src="@/assets/images/icons/order-by-desc.svg"
                                @click="setOrderBy('name')"
                            >
                            <img
                                v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                                src="@/assets/images/icons/order-by-asc.svg"
                                @click="setOrderBy('name')"
                            >
                            <img
                                v-if="orderByElement != 'name'"
                                src="@/assets/images/icons/order-by.svg"
                                @click="setOrderBy('name')"
                            >
                        </span>
                  </div>
                  <div class="table-cell">
                      <span class="header">
                            {{toLocal('generic.surname')}}
                            <img
                                v-if="orderByElement == 'last_name' && orderByDirection == 'desc'"
                                src="@/assets/images/icons/order-by-desc.svg"
                                @click="setOrderBy('last_name')"
                            >
                            <img
                                v-if="orderByElement == 'last_name' && orderByDirection == 'asc'"
                                src="@/assets/images/icons/order-by-asc.svg"
                                @click="setOrderBy('last_name')"
                            >
                            <img
                                v-if="orderByElement != 'last_name'"
                                src="@/assets/images/icons/order-by.svg"
                                @click="setOrderBy('last_name')"
                            >
                        </span>
                  </div>
                  <div class="table-cell">
                      <span class="header">
                            {{toLocal('test_stats.date_of_attempt')}}
                            <img
                                v-if="orderByElement == 'end_date' && orderByDirection == 'desc'"
                                src="@/assets/images/icons/order-by-desc.svg"
                                @click="setOrderBy('end_date')"
                            >
                            <img
                                v-if="orderByElement == 'end_date' && orderByDirection == 'asc'"
                                src="@/assets/images/icons/order-by-asc.svg"
                                @click="setOrderBy('end_date')"
                            >
                            <img
                                v-if="orderByElement != 'end_date'"
                                src="@/assets/images/icons/order-by.svg"
                                @click="setOrderBy('end_date')"
                            >
                        </span>
                  </div>
                  <div class="table-cell">
                       <span class="header">
                            {{toLocal('test_stats.attempt_duration')}}
                            <img
                                v-if="orderByElement == 'done_time_in_seconds' && orderByDirection == 'desc'"
                                src="@/assets/images/icons/order-by-desc.svg"
                                @click="setOrderBy('done_time_in_seconds')"
                            >
                            <img
                                v-if="orderByElement == 'done_time_in_seconds' && orderByDirection == 'asc'"
                                src="@/assets/images/icons/order-by-asc.svg"
                                @click="setOrderBy('done_time_in_seconds')"
                            >
                            <img
                                v-if="orderByElement != 'done_time_in_seconds'"
                                src="@/assets/images/icons/order-by.svg"
                                @click="setOrderBy('done_time_in_seconds')"
                            >
                        </span>
                  </div>
                  <div class="table-cell">
                      <span class="header">
                            {{toLocal('generic.result')}}
                            <img
                                v-if="orderByElement == 'result' && orderByDirection == 'desc'"
                                src="@/assets/images/icons/order-by-desc.svg"
                                @click="setOrderBy('result')"
                            >
                            <img
                                v-if="orderByElement == 'result' && orderByDirection == 'asc'"
                                src="@/assets/images/icons/order-by-asc.svg"
                                @click="setOrderBy('result')"
                            >
                            <img
                                v-if="orderByElement != 'result'"
                                src="@/assets/images/icons/order-by.svg"
                                @click="setOrderBy('result')"
                            >
                        </span>
                  </div>
              </div>
              <div class="table-row parent" v-for="(user, user_id) in users" :key="user_id" @click="setUser(user, test)">
                  <div v-if="$store.state.testUserStatsShowAll == 0" class="table-row">
                    <div class="table-cell">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <clipPath id="clip-path">
                                <circle id="Ellipse_467" data-name="Ellipse 467" cx="14" cy="14" r="14" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1"/>
                                </clipPath>
                            </defs>
                            <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-1212 -2055.711)" clip-path="url(#clip-path)">
                                <g id="Ellipse_464" data-name="Ellipse 464" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1">
                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                </g>
                                <g id="Ellipse_465" data-name="Ellipse 465" transform="translate(1221 2062.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                                <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                                </g>
                                <g id="Ellipse_466" data-name="Ellipse 466" transform="translate(1215 2070.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
                                <circle cx="11.5" cy="11.5" r="11" fill="none"/>
                                </g>
                            </g>
                            </svg>
                            <span  
                                @mouseover="scrollNameHorizontal($event,true)" 
                                @mouseleave="scrollNameHorizontal($event,false)"
                            >
                            {{user.name}}
                        </span>
                        </div>
                    <div class="table-cell">
                        <span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >
                            {{user.last_name}}
                        </span>
                    </div>
                    <div class="table-cell">
                        <span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >
                        {{user.stats[0].end_date.split(' ')[0]}}
                        </span>
                    </div>
                    <div class="table-cell"><span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >
                        {{getTestSolvingTime(user.stats[0].done_time_in_seconds)}}
                        </span>
                    </div>
                    <div class="table-cell"><span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >{{getUserResult(user.stats[0].result)}}
                        </span>
                    </div>
                  </div>
                  <div v-else class="table-row" v-for="(stat, stat_id) in user.stats" :key="stat_id" @click="setUser(user, test)">
                      <div class="table-cell">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <clipPath id="clip-path">
                                <circle id="Ellipse_467" data-name="Ellipse 467" cx="14" cy="14" r="14" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1"/>
                                </clipPath>
                            </defs>
                            <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-1212 -2055.711)" clip-path="url(#clip-path)">
                                <g id="Ellipse_464" data-name="Ellipse 464" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1">
                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                </g>
                                <g id="Ellipse_465" data-name="Ellipse 465" transform="translate(1221 2062.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                                <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                                </g>
                                <g id="Ellipse_466" data-name="Ellipse 466" transform="translate(1215 2070.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
                                <circle cx="11.5" cy="11.5" r="11" fill="none"/>
                                </g>
                            </g>
                            </svg>
                            <span
                                @mouseover="scrollNameHorizontal($event,true)" 
                                @mouseleave="scrollNameHorizontal($event,false)"
                            >
                            {{user.name}}
                        </span>
                        </div>
                    <div class="table-cell">
                        <span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >
                        {{user.last_name}}
                        </span>
                    </div>
                    <div class="table-cell"><span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >{{stat.end_date.split(' ')[0]}}
                        </span>
                    </div>
                    <div class="table-cell"><span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >{{getTestSolvingTime(stat.done_time_in_seconds)}}
                        </span>
                    </div>
                    <div class="table-cell"><span
                            @mouseover="scrollNameHorizontal($event,true)" 
                            @mouseleave="scrollNameHorizontal($event,false)"
                        >{{getUserResult(stat.result)}}
                        </span>
                    </div>
                  </div>
              </div>
              
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:'TestStatisticsTabContent',

    data(){
        return{
            orderByElement: "",
            orderByDirection: "",
            users: [],
            isLoadingNewData: false,
        }
    },
    props:{
        topic: {
            type: Object,
            required: true
        },
        availableTestQuestions: {
            type: Array,
            required: true
        },
        selectedGroup:{
            type: Number,
            required: true
        },
        test:{
            type: Object,
            required: true
        }
    },
    computed:{
        getGroupName(){
            if(this.$store.state.testUserStatsFilter == 0){
                return this.toLocal('tests.all_groups');
            }
            else{
                return this.$store.state.testUserStats.groups.find(group => group.id == this.$store.state.testUserStatsFilter).name;
            }
        },
        getTest(){
            return this.availableTestQuestions[this.topic.questionsindex];
        }
    },
    watch:{
        groups:{
            immediate: true,
            handler: function(){
                this.setUsers();
            }
        },
        selectedGroup(){
            this.setUsers();
        },
        '$store.state.testUserStats':{
            immediate: true,
            handler: function(){
                this.setUsers();
            }
        },
        '$store.state.testUserStatsFilter':{
            immediate: true,
            handler: function(){
                this.setUsers();
            }
        },

    },
    mounted(){
        if(this.users.length == 0)
            this.setUsers();
    },
    methods:{
        setUsers(){
            this.users = this._.cloneDeep(this.$store.state.testUserStats.users);
            if( this.$store.state.testUserStatsFilter == 0){
                    this.users.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id
                        ))
                    )
            }
            else{
                let groups = this.$store.state.testUserStats.groups;
                let group_users_ids =groups.find(group => group.id == this.$store.state.testUserStatsFilter).users.map(el=>el.id);
                this.users = this.users.filter(user=>{
                    return group_users_ids.includes(user.id) ? true : false;

                })
            } 
        },
        getUserStats(){
            if(this.test == undefined) return;
            if(this.isLoadingNewData) return;
            this.isLoadingNewData = true;
            axios.post(`${this.$store.state.api_link}/training/stats/users`,
            {training_id: this.$store.state.currentTraining, 'test_id': this.test.id},
            this.$store.getters.axiosConfig()
            ).then((res)=>{
                let users = res.data.users;
                let groups = res.data.groups;
                this.$store.commit('setTestUserStats', {users: users, groups: groups});
                this.isLoadingNewData = false;
            })
            .catch((err)=>{
                this.isLoadingNewData = false;
            });
        },
        getTestSolvingTime(time_in_seconds){
            let hours = parseInt(time_in_seconds/3600);
            if(hours<10){
                hours = '0'+hours;
            }
            let minutes = parseInt(time_in_seconds/60);
            if(minutes<10){
                minutes = '0'+minutes;
            }
            let seconds = parseInt(time_in_seconds%60);
            if(seconds<10){
                seconds = '0'+seconds;
            }
            return `${hours}:${minutes}:${seconds}`;
        },
         setOrderBy(element) {
            if(element == this.orderByElement){
                this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
            }else{
                this.orderByElement = element;
                this.orderByDirection = "asc";
            }
            if(['end_date', 'done_time_in_seconds', 'result'].includes(element)){
                if(this.orderByDirection == "desc"){
                    
                    if(element == 'result'){
                        console.log()
                        this.users.sort((a,b) => b.stats[0][element] <a.stats[0][element] ? 1 : -1); 
                    }
                    else this.users.sort((a,b) => b.stats[0][element].localeCompare(a.stats[0][element])); 
                }else{
                    if(element == 'result'){
                        this.users.sort((a,b) => b.stats[0][element] > a.stats[0][element] ? 1 : -1); 
                    }
                    else this.users.sort((a,b) => a.stats[0][element].localeCompare(b.stats[0][element])); 
                }
            }
            else{
                if(isNaN(this.users[0][element])){
                    if(this.orderByDirection == "desc"){
                      this.users.sort((a,b) => b[element].localeCompare(a[element])); 
                    }else{
                     this.users.sort((a,b) => a[element].localeCompare(b[element])); 
                    }
                }else{
                    if(this.orderByDirection == "desc"){
                    this.users.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
                    }else{
                    this.users.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
                    }
                }
            }
        },
        setUser(user, test){
            this.$store.state.userTrainingStatSide.setUser(user, test);
            this.$store.state.userTrainingStatSide.openSide();
        },
        getUserResult(result){
            if(this.test.options.testResultShowType == 1){
                return parseInt(Math.round(this.test.options.testMaxScore * result));
            }
            else return parseInt(result*100)+' %';
        }
    }
}
</script>

<style lang="scss" scoped>
.content-container{
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 2rem 0;
    padding: 2rem 1rem;
    background: white;
    border-radius: 20px;
}
.refresh-button{
    display: flex;
    position: absolute;
    height: 25px;
    width: 25px;
    right: 20px;
    top: 15px;
    cursor: pointer;
    svg{
        path{
            fill: #7a7b7a91;
        }
    }
    &.active{
        animation: loading 0.5s linear infinite;
    }
}
@keyframes loading{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
table{
    width: 100%;
    border-collapse: collapse;
    tr{
        td{
            padding:0.5rem 0;
            span{
                display: flex;
                border-right: 1px solid var(--primary-color);    
                justify-content: center;
                color: var(--primary-color);
            }   
            text-align: center;
            &:first-child{
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
            }
            &:last-child{
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                span{
                    border-right: none;
                }
            }

        }
        th{
            padding: 1rem 0rem;
            span{
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                //width: 100%;
                border-right: 1px solid var(--primary-color); 
                img{
                    margin-left: 0.25rem;
                }
                &.name-cell{
                    margin-left: 2.3rem;
                }
            }
            &:last-child{
  
                span{
                    border-right: none;
                }
            }

        }
    }
    .user-row{
        border-radius: 30px;
        border-bottom-left-radius: 30px;
        background: #efefef;
        border-bottom: 9px solid white;
        svg{
        }
        .double-cell{
            justify-content: flex-start;
            svg{
                margin: 0 0.5rem;
            }
            div{
                margin: 0 auto;
            }
        }
    }
}
h2{
    margin-top: 0;
    font-size: clamp(20px, 1.5vw, 26px);
    font-weight: 700;
    color: var(--primary-color);
}
.jcc{
    justify-content: space-between;
}
@media(max-width:1280px){
    .content-container{
        margin: 1rem 0;
        padding: 1rem 0.5rem;
        .grid-table{
            .table-row{
                padding: 0;
            }
            .table-cell{
                span{
                    font-size: 14px;
                }
            }
        }
        .grid-table .table-row .table-cell span.name-cell{
            margin-left: 0.5rem;
        }
    }
}
</style>
<style lang="scss" scoped>
.grid-table{
    display: grid;
    grid-template-rows: auto;
    .table-row{
        display: grid;
        grid-template-columns: minmax(100px, 1.67fr) minmax(100px, 2.33fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr);
        margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        background: #efefef;
        &.header{
            background: white;
            font-weight: 700;
            color: var(--primary-color);
        }
        &.parent{
            grid-template-columns:1fr;
            margin: 0.25rem 0;
            padding:0;
        }
        .table-cell{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            padding: 0.25rem 0;
            border-left: 1px solid var(--primary-color);
            &:first-child{
                border-left: none;
            }
            span{
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                margin:0 auto;
                justify-content: center;
                &.name-cell{
                    margin-left: 1.7rem;
                }
                img{
                    margin: 0 0.25rem;
                }
                &.header{
                    display: flex;
                   white-space: normal;
                }
            }

            svg{
                height: 100%;
                width: 1.7rem;
                
            }
        }
    }
}
</style>