<template>
    <div class="line-chart-container">
        <div class="label">

        </div>
        <div class="content">
            <div class="bubble-container" v-if="statGroups" >
                <div 
                    class="bubble-col"
                    :style="{
                        'left': `calc(${percent_item.percent}% - ${percent_item.items.length == 1 ? percent_item.percent == 100 ? '37' : '36' : percent_item.percent == 100 ? '15' : '14'}px)`,
                    }"
                    v-for="(percent_item, index) in statGroups" 
                    :class="{
                        long: percent_item.items.length == 1,
                        opened: openedTooltip == percent_item.percent
                    }"
                    :key="index"
                >   
                    <div class="bubble-wrapper"
                    :style="{
                        bottom: bubblePositions[percent_item.percent] ? bubblePositions[percent_item.percent] + '%' : '',
                    }">
                        <VTooltip  
                                :triggers="[]"
                                :placement="'right-end'"
                                :distance="0"
                                :shown="openedTooltip == percent_item.percent"
                                :skidding="-28"
                                @hide="openedTooltip=-1"
                                auto-hide
                                popperClass="bubble-tooltip-container"
                            >
                                <div 
                                    class="bubble"
                                    @click="openedTooltip=percent_item.percent"
                                    :style="{
                                        background:  openedTooltip == percent_item.percent ? '#4D4D4D' : `linear-gradient(0deg, ${percent_item.percent < 20 ? colors[0][0] : percent_item.percent < 60 ? colors[1][0] : colors[2][0]} 0%, ${percent_item.percent < 20 ? colors[0][1] : percent_item.percent < 60 ? colors[1][1] : colors[2][1]} 100%)`,
                                    }"
                                
                                >
                                
                                    <div class="bubble-text">
                                        <span>
                                            {{ percent_item.items.length > 1 ? percent_item.items.length : percent_item.items[0].training.title }}
                                        </span>
                                    </div>
                                </div>
                                <template #popper>
                                    <div class="item" v-for="(item, index) in percent_item.items" :key="index">
                                        <div class="training-row">
                                            <span class="text">
                                                {{ item.training.title}}
                                            </span>
                                            <span class="result">
                                                Išlaikė {{ item.training_result * 100 }} %
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </VTooltip>
                        </div>
                    
                    <span 
                        class="bubble-line"
                        :style="{
                            height:  bubblePositions[percent_item.percent] ? bubblePositions[percent_item.percent] + '%' : '',
                            background: openedTooltip == percent_item.percent ? '#4D4D4D' : (percent_item.percent < 20 ? '#EB4D56' : percent_item.percent < 60 ? '#FD9B40': '#33C493'),
                        }"
                    >
                    </span>
                </div>
            </div>
            <div class="line">
                <div 
                    class="percent-tabs"
                    :style="{
                        background: `linear-gradient(90deg, ${color[0]} 0%, ${color[1]} 100%)`,
                        width: `${width[i]}%`
                    }"
                    v-for="(color, i) in colors" 
                    :key="i"
                >
                </div>
            </div>
            <div class="percent-container">
                <div 
                    class="percent" 
                    v-for="i in 11" :key="i"
                >
                    {{ (i-1)*10 }} %
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object
    },
    data(){
        return {
            colors: [
                    ['#FB333E', '#FFA0B7'],
                    ['#FD902B','#FDDF40'],
                    ['#00D9B2', '#A1FAC4'],
            ],
            width: [20, 40, 40],
            minBubbleDeviation: 10,
            maxBubbleDeviation: 45,
            bubblePositions: {},
            openedTooltip: -1,
            statGroups: [],
        }
    },
    mounted() {
        (Object.keys(this.data)).forEach((key) => {
            this.$set(this.bubblePositions, key, Math.random() * (this.maxBubbleDeviation - this.minBubbleDeviation) + this.minBubbleDeviation)
        });
        this.statGroups= []   
        this.getStatGroups()
    },
    beforeDestroy() {
        this.statGroups= []   
    },
    methods: {
        getStatGroups() {
            let group_index = 0;
            let groups = [];
            
            const ordered = Object.keys(this.data).sort().reduce(
                (obj, key) => { 
                    obj[key] = this.data[key]; 
                    return obj;
                }, 
                {}
            );
            for (let [key, value] of Object.entries(ordered)) {
                if(groups[group_index]){
                    if (((parseInt(key) - parseInt(groups[group_index].first_item_percent))) <= 10) {
                        groups[group_index].items.push(...value)
                    }
                    else {
                        group_index++;
                    }
                }
                if (!groups[group_index]) {
                    groups[group_index] = {
                        percent: key,
                        items: [],
                        first_item_percent: key
                    }
                }
                if (groups[group_index].items.length == 0) {
                    groups[group_index].items.push(...value)
                }
               
            }
            this.statGroups = groups
        },
        getLinearGradient(color) {
            return `linear-gradient(90deg, ${color[0]} 0%, ${color[1]} 100%);`
        },
        getBubblePosition(percent, axis) {
           return this.bubblePositions[percent]
        }
    }
}
</script>

<style lang="scss" scoped>
.percent-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 22px);
    .percent{
        color: #CACACA;
        font-weight: 600;
        font-size: 14px;
        width: 36px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        &:nth-child(odd){
            color: #333333;
        }
    }

}
.line{
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
}
.bubble{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 4px
}
.bubble-col{
    display: flex;
    position: absolute;
    flex-direction: column;
    height: 100%;
    bottom: 0;
    width: 28px;
    &.long{
        width: 72px;
        .bubble{
            width: 72px;
            border-radius: 4px;
        }
    }
    &.opened{
        z-index: 10;
       
    }
}
.percent-tabs{
    display: flex;
    height: 16px;
    background: red;
}
.bubble-container{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50px;
    .bubble{
        display: flex;
        align-items: center;
        position: relative;
        
     
      
        color: white;
        cursor: pointer;
        text-align: center;
        box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.20);
    }
}
.bubble-wrapper{
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
}
.bubble-text{
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    user-select: none;
    width: 100%;
}
.bubble-line{
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    width: 1px;
    background-color: red;
}
.content{
    position: relative;
}
.line-chart-container{
    position: relative;
    width: 100%;
}
.training-row{
    display: flex;
    flex-direction: column;
    .result{
        font-size: 10px;
        color: #00000069
    }
}
</style>
<style lang="scss">
.bubble-tooltip-container{
    &.v-popper--theme-tooltip{

        .v-popper__inner{
            background: white;
            box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.24);
            color: #333333;
            max-height: 150px;
            max-width: 248px;
            overflow: auto;
            font-size: 14px;
        }
        .v-popper__arrow-container{
            display: none;
        }
    }
}
</style>