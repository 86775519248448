<template>
  <div
    v-if="topic.test && showTest"
    :class="[
      'container main--container training',
      { 'side--open': $store.getters.isSideOpen() },
    ]"
    :style="{
      'max-width': customSysStyle && customSysStyle.general ? customSysStyle.general['width']+'%' : '' 
    }"
  >
    <AnsweredQuestions
      v-if="showTestAnswers"
      :answeredQuestions="topic.user_answers"
      :topic="topic"
    />
    <div
      class="training--block test--training--block"
      v-if="!topic.test_done && testHasStarted"
    >
      <div
        v-if="
          topic.result == undefined &&
          topic.currentquestion > 0 &&
          !topic.test_done &&
          testHasStarted
        "
        @click="topic.currentquestion--"
        class="test--arrow arrow--back"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37.754"
          height="95.393"
          viewBox="0 0 37.754 95.393"
        >
          <path
            id="Path_5869"
            data-name="Path 5869"
            d="M413,322.538l40.8-25.854,40.8,25.854"
            transform="translate(-291.684 501.494) rotate(-90)"
            fill="none"
            stroke="#28345c"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="10"
          />
        </svg>
      </div>
      <div
        v-if="
          training.trainingType != 'attest' &&
          topic.result == undefined &&
          topic.currentquestion < topic.items.length - 1 &&
          !topic.test_done &&
          testHasStarted &&
          (topic.items[topic.currentquestion].type == 5
            ? topic.items[topic.currentquestion].correct
            : true)
        "
        @click="topic.currentquestion++"
        class="test--arrow arrow--next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37.754"
          height="95.393"
          viewBox="0 0 37.754 95.393"
        >
          <path
            id="Path_5870"
            data-name="Path 5870"
            d="M413,322.538l40.8-25.854,40.8,25.854"
            transform="translate(329.438 -406.1) rotate(90)"
            fill="none"
            stroke="#28345c"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="10"
          />
        </svg>
      </div>
      <div
        v-for="(question, questionIndex) in topic.items"
        :key="`question-${topicIndex}-${questionIndex}`"
        :class="['content--item--wrapper']"
      >
        <div
          v-if="
            !(topic.result != undefined && topic.testAnswerShowType == 0) &&
            !topic.test_done &&
            testHasStarted
          "
          :class="['content--item', 'content--item--test', training.trainingType =='attest' ? 'attesting-task' : '']"
        >
          <div
            :class="[
              'task',
              {
                hidden: training.trainingType != 'attest' &&
                  (topic.result == undefined &&
                  topic.currentquestion != questionIndex),
              },
              { 'task-5': question.type == 5 },
            ]"
            :key="topic.result == undefined && topic.currentquestion != questionIndex"
          >
            <div class="task--number" v-if="training.trainingType=='attest'">
              {{  questionIndex+1}}.
            </div>
            <div class="task--text">
              <editor
                :isEditing="false"
                :readOnly="true"
                :value="question.text"
              />
            </div>
            <div v-if="question.type == 1">
              <div class="task--answers">
                <div
                  v-for="(answer, answerIndex) in question.answers"
                  :key="answerIndex"
                  :class="[
                    'task--answer',
                    { selected: answer.selected },
                    {
                      correct:
                        topic.result != undefined &&
                        answer.correct &&
                        (topic.testAnswerShowType == 1 ||
                          topic.testAnswerShowType == 2),
                    },
                    {
                      incorrect:
                        topic.result &&
                        !answer.correct &&
                        (topic.testAnswerShowType == 2 ||
                          topic.testAnswerShowType == 3),
                    },
                  ]"
                  @click="selectTaskAnswer(question, answerIndex)"
                >
                  <div :class="['task--answer--letter']">
                    {{ String.fromCharCode(65 + answerIndex) }}
                  </div>
                  <div class="task--answer--text">
                    {{ answer.text }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 2">
              <div class="task--images">
                <div
                  v-for="(answer, answerIndex) in question.answers"
                  :key="`${answer.image}-${answerIndex}`"
                  :class="[
                    'task--image',
                    { big: question.size == 1 },
                    { selected: answer.selected },
                    {
                      correct:
                        topic.result != undefined &&
                        answer.correct &&
                        (topic.testAnswerShowType == 1 ||
                          topic.testAnswerShowType == 2),
                    },
                    {
                      incorrect:
                        topic.result != undefined &&
                        !answer.correct &&
                        (topic.testAnswerShowType == 2 ||
                          topic.testAnswerShowType == 3),
                    },
                  ]"
                  @click="selectTaskAnswer(question, answerIndex)"
                >
                  <div :class="['task--answer--letter']">
                    {{ String.fromCharCode(65 + answerIndex) }}
                  </div>
                  <div style="z-index: -1" class="img-wrapper">
                    <ImageViewer
                      v-if="answer.metadata ? answer.metadata.data : false"
                      :src="
                        answer.image != null
                          ? $store.state.api_uploads_link + answer.image
                          : ''
                      "
                      :height="
                        question != undefined
                          ? question.size == 1
                            ? 416.25
                            : 200
                          : undefined
                      "
                      :width="
                        question != undefined
                          ? question.size == 1
                            ? 740
                            : 200
                          : undefined
                      "
                      :metadata="answer.metadata"
                      :size="question.size"
                      @clickCanvas="selectTaskAnswer(question, answerIndex)"
                    />
                    <NewImageEditor
                      v-if="answer.metadata ? !answer.metadata.data : true"
                      :readOnly="true"
                      :item="answer"
                      type="cover"
                      :height="question.size == 1 ? 416.25 : 200"
                      :width="question.size == 1 ? 740 : 200"
                      :isEditing="false"
                      :src="getTrainingFileURL(answer.image)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 3">
              <div class="task--connects task--connects--part1">
                <draggable
                  v-model="question.selected"
                  :options="{
                    disabled: question.result,
                    animation: 200,
                    group: 'description',
                    swap: true,
                  }"
                  class="list-group"
                  :move="(event) =>handleMove(event, topicIndex, questionIndex)"
                  @start="answerDrag = true"
                  @end="handleDragEnd(topicIndex, questionIndex)"
                >
                  <transition-group
                    type="transition"
                    :name="!answerDrag ? 'flip-list' : null"
                  >
                    <div
                      v-for="answer in question.selected"
                      :key="answer.id"
                      :class="['task--connect']"
                    >
                      <div
                        :class="[
                          'task--connect--part1',
                          {
                            correct:
                              topic.result &&
                              !answer.incorrect &&
                              (topic.testAnswerShowType == 1 ||
                                topic.testAnswerShowType == 2),
                          },
                          {
                            incorrect:
                              topic.result != undefined &&
                              answer.incorrect &&
                              (topic.testAnswerShowType == 2 ||
                                topic.testAnswerShowType == 3),
                          },
                        ]"
                      >
                        <div
                          :class="[
                            'task--connect--temp',
                            { 'task--connect--temp--active': answer.temptext },
                          ]"
                        >
                          <div class="text-wrapper">
                            <div>
                              {{ answer.temptext }}
                            </div>
                          </div>
                        </div>
                        <div class="text-wrapper">
                          <div>
                            {{ answer.part1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
              <div class="task--connects task--connects--part2">
                <div
                  v-for="(answer, answer_key) in question.answers"
                  :key="`${answer.part2}-${answer_key}`"
                  :class="['task--connect']"
                >
                  <div
                    :class="[
                      'task--connect--part2',
                      {
                        correct:
                          topic.result &&
                          !answer.incorrect &&
                          (topic.testAnswerShowType == 1 ||
                            topic.testAnswerShowType == 2),
                      },
                      {
                        incorrect:
                          topic.result != undefined &&
                          answer.incorrect &&
                          (topic.testAnswerShowType == 2 ||
                            topic.testAnswerShowType == 3),
                      },
                    ]"
                  >
                    <div class="text-wrapper">
                      <div>
                        {{ answer.part2 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 4" :class="{'attesting-4':training.trainingType=='attest'}">
              <editor
                v-if="question.answers && question.answers[0]"
                :class="['test-task-editor', 'lg']"
                :isEditing="true"
                :readOnly="false"
                :showCharInfo="true"
                :charLimit="1000"
                :placeholder="toLocal('test.enter_answer')"
                v-model="question.answers[0].answer_text"
              />
              <TestScoringComponent class="test-attest-4" v-if="training.trainingType=='attest'" :question="question" />        
            </div>
            <div v-else-if="question.type == 5">
              <ClickTask
                v-if="question.answers && question.answers[0]"
                :question="question"
                :currentQuestion="topic.currentquestion"
                :answer="question.answers[0]"
                :readOnly="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!testHasStarted" class="start-training">
      <TestAttestingModal v-if="training.trainingType =='attest'" :training="training" :test="topic" @closeModal="goBack" />
      <TestInfoModal v-else displayType="in-line" :test="topic" :isOpen="true" />
      <div class="btn retake" :class="{'attesting-btn': training.trainingType =='attest', inactive: !topic.selectedUser}" @click="startTest()">
        {{ training.trainingType =='attest' ? toLocal("tests.start_attesting") : toLocal("tests.begin") }}
      </div>
    </div>
    <TestResultModal
      :training="training"
      :showTestResult="showTestResult" 
      :topic="topic" 
      :testHasStarted="testHasStarted" 
      :showTestAnswers="showTestAnswers"
    />

    <div
      v-if="
        showEndButton && currentTopic == topicIndex && topic.result != undefined
      "
      class="end--button"
      @click="$emit('endTraining')"
    >
      {{ toLocal("training.end") }}
    </div>
    <!-- <div
      v-else-if="
        !showEndButton && topic.test && topic.test_done && !showTestAnswers
      "
      class="btn retake"
      @click="$emit('retakeTraining')"
    >
      {{ toLocal("training.try_again") }}
    </div> -->
    <div
      class="test-ended-button-container"
      :class="{ row: topic.resultstatus }"
      v-if="
        !showEndButton &&
        topic.test &&
        topic.result != undefined &&
        showTestResult
      "
    >
      <div
        class="btn top"
        :class="{ deactivated: topic.options.testAnswerShowType == 0 }"
        @click="
          topic.options.testAnswerShowType !== 0
            ? (showTestAnswers = true)
            : '';
          topic.options.testAnswerShowType !== 0
            ? (showTestResult = false)
            : '';
        "
      >
        {{ toLocal("training.see_questions") }}
      </div>
      <div
        class="btn restart"
        v-if="
          !showEndButton &&
          topic.test &&
          topic.test_done &&
          topic.canSolveAgain &&
          !showTestAnswers
        "
        :class="{ deactivated: training.trainingType =='attest' ? false : !topic.testIsChecked }"
        @click="retakeTest()"
      >
        <span>
          {{ training.trainingType=='attest' ? toLocal('tests.attest_again') : toLocal("test.test_again") }}
        </span>
      </div>
    </div>
    <div
      class="test-ended-button-container"
      v-if="
        !showEndButton &&
        topic.test &&
        topic.test_done &&
        !showTestResult &&
        !showTestAnswers
      "
    >
      <div class="btn icon" @click="goBack">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.602"
          height="21.199"
          viewBox="0 0 11.602 21.199"
        >
          <path
            id="Path_6221"
            data-name="Path 6221"
            d="M20230.473-15605.5l-9.187,9.186,9.188,9.186"
            transform="translate(-20220.285 15606.912)"
            fill="none"
            stroke="#28345c"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
        <span>
          {{ toLocal("training.go_to_start") }}
        </span>
      </div>
      <div
        class="btn top"
        :class="{ deactivated: topic.options.testAnswerShowType == 0 }"
        @click="
          topic.options.testAnswerShowType !== 0
            ? (showTestAnswers = true)
            : '';
          topic.options.testAnswerShowType !== 0
            ? (showTestResult = false)
            : '';
        "
      >
        {{ toLocal("training.see_questions") }}
      </div>
      <div
        v-if="topic.testIsChecked"
        class="btn top"
        :class="{ deactivated: !topic.testIsChecked }"
        @click="topic.testIsChecked ? (showTestResult = true) : ''"
      >
        {{ toLocal("training.see_score") }}
      </div>
    </div>
    <div
      v-if="
        !showEndButton &&
        topic.test &&
        topic.result != undefined &&
        !showTestResult &&
        showTestAnswers
      "
      class="end--button"
      @click="
        showTestAnswers = false;
        showTestResult = true;
      "
    >
      {{ toLocal("training.go_back") }}
    </div>
    <div
      v-if="testHasStarted && !topic.test_done && (training.trainingType == 'attest' ? true : (topic.currentquestion == topic.items.length - 1))"
      class="end--button"
      @click="!isCheckingTest ? checkTest(topic, topicIndex) : ''"
    >
      <span>{{
        isCheckingTest
          ? toLocal("generic.checking")
          : toLocal("training.task_check")
      }}</span>
      <sync-loader
        v-if="isCheckingTest"
        :loading="true"
        size="5px"
      ></sync-loader>
    </div>
    <div
      v-if="
        !showEndButton &&
        currentTopic == topicIndex &&
        topic.result != undefined &&
        !showTestAnswers
      "
      class="end--button"
      @click="$emit('nextTopic')"
    >
      {{ training && lastShownTopic == topic.id ? 'Grįžti į pirkimo langą' : toLocal("generic.next") }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageEditor from "@/components/layout/trainings/ImageEditor.vue";
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import AnsweredQuestions from "./AnsweredQuestions.vue";
import draggable from "vuedraggable";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import ClickTask from "@/components/layout/trainings/training_components/ClickTask.vue";
import ImageViewer from "@/components/layout/trainings/training_components/ImageViewer.vue";
import TestInfoModal from "@/components/layout/trainings/training_components/test/TestUser/TestInfoModal.vue";
import TestAttestingModal from "@/components/layout/trainings/training_components/test/TestUser/TestAttestingModal.vue";
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";
import TestResultModal from "@/components/layout/trainings/training_components/test/TestUser/TestEndComponents/TestResultModal.vue";
import TestScoringComponent from "@/components/layout/trainings/training_components/test/TestUser/TestEndComponents/TestScoringComponent.vue";

export default {
  name: "TrainingTestUser",
  props: {
    topic: Object,
    training: Object,
    user_id: Number | String,
    topicIndex: Number,
    showEndButton: Boolean,
    showingEnding: Boolean,
    showTest: Boolean,
    currentTopic: Number,
  },
  components: {
    ImageEditor,
    Editor,
    draggable,
    AnsweredQuestions,
    SyncLoader,
    ClickTask,
    ImageViewer,
    TestInfoModal,
    TestAttestingModal,
    NewImageEditor,
    TestResultModal,
    TestScoringComponent
  },
  data() {
    return {
      testPassed: false,
      testHasStarted: false,
      testHasEnded: false,
      maxPossibleScore: -1,
      showTestResult: false,
      showTestAnswers: false,
      testResults: 0,
      answeredQuestions: [],
      answerDrag: false,
      isCheckingTest: false,
    };
  },
  computed:{
    training_key(){
      return this.$route.params.key
    },
    lastShownTopic(){
      if(this.training && this.training.selectedShownTopics){
        return this.training.selectedShownTopics[this.training.selectedShownTopics.length-1];
      }
      else return null;
    }
  },
  watch: {
    "topic.options.testMaxScore"() {
      this.maxPossibleScore = this.topic.options.testMaxScore;
    },
  },
  mounted() {
    if (this.topic.test_done && this.training.trainingType != 'attest') {
      this.$emit("showEndBtn");
      this.testHasStarted = true;
      this.testHasEnded = true;
      this.showTestResult = true;
      if (this.topic.result != undefined) {
        // this.$set(this.topic, 'result', this.topic.user_results.result);
        this.$set(
          this.topic,
          "resultstatus",
          this.topic.result * 100 >= this.topic.options.testPassedFrom
        );
        this.answeredQuestions = this.topic.user_answers;
        this.maxPossibleScore = this.topic.options.testMaxScore;
      }
    }
    else if(this.training.trainingType == 'attest'){
      delete this.topic["result"];
      delete this.topic["resultstatus"];
      this.$set(this.topic, "test_done", false);
    }
  },
  methods: {
    checkTest(topic, topicIndex) {
      this.isCheckingTest = true;
      topic.items.forEach((item) => {
        if (item.type == 3) {
          item.selected.forEach((selected_item, index) => {
            selected_item.part2 = item.answers[index].part2;
            selected_item.text = JSON.stringify({
              part1: selected_item.part1,
              part2: selected_item.part2,
            });
          });
        }
      });
      if (!this.testHasEnded) {
        axios
          .post(
            `${this.$store.state.api_link}/training/stats/check/test`,
            {
              training_id: this.$store.state.currentTraining,
              test_id: topic.version_id,
              answers: topic.items,
              is_rented: this.$store.state.currentTrainingRented,
              key: this.training_key,
              user_id: this.training.trainingType == 'attest' && this.topic.selectedUser ? this.topic.selectedUser.id : null
            },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.$store.commit("getStats");
            this.hasEnded = true;
            this.testHasEnded = true;
            if (response.data.test_passed != undefined) {
              if (response.data.result != undefined)
                this.$set(topic, "result", response.data.result);
              this.$set(topic, "resultstatus", response.data.test_passed);
              if (
                this.training.certificate &&
                this.training.certificate.enabled &&
                response.data.test_passed == true
              ) {
                this.getCertificate();
              }
            }
            if (response.data.max_score != undefined)
              this.maxPossibleScore = response.data.max_score;
            if (response.data.questions != undefined)
              this.topic.user_answers = response.data.questions;
            this.$set(topic, "canSolveAgain", response.data.can_retry);
            if (this.training.trainingType == 'attest') {
              this.$set(topic, "testIsChecked", true);
            }
            else if (response.data.test_is_checked != undefined)
              this.$set(topic, "testIsChecked", response.data.test_is_checked);
            this.$set(topic, "test_done", true);
            this.$emit("showEndBtn");
            this.$emit("endTestTraining");
            if(this.training_key){
              this.sendPostMessage({ 
                type: "test_end", 
                user_id: this.user_id, 
                training_id: this.$store.state.currentTraining,
                result:response.data.result,
                test_status: response.data.test_passed,
                date: new Date().toISOString(), 
                timestamp: Date.now()
              })
            }
            window.removeEventListener("beforeunload", this.leavePageTest);
            this.showTestResult = true;
            this.isCheckingTest = false;
            this.$emit('stopTimer')
          })
          .catch((err) => {
            this.hasEnded = false;
            console.log(err);
            console.log("Error while sending statistics");
            this.isCheckingTest = false;
            window.removeEventListener("beforeunload", this.leavePageTest);
          });
      }
    },
    retakeTest() {
      if (!this.topic.testIsChecked && this.training.trainingType != 'attest') return;
      this.testPassed = false;
      if (this.training.trainingType == 'attest') {
        this.testHasStarted = false;
        this.$set(this.topic, 'selectedUser', undefined);
        this.$set(this.training, 'selectedUser', undefined);
      }
      else {
        this.testHasStarted = true;
      }
      this.testHasEnded = false;
      this.maxPossibleScore = -1;
      this.showTestResult = false;
      this.showTestAnswers = false;
      this.testResults = 0;
      this.answeredQuestions = [];
      this.$set(this.topic, "currentquestion", 0);
      // this.$set(this.topic, 'testIsChecked', false);
      this.$set(this.topic, "test_done", false);
      this.topic.items.forEach((elem) => {
        elem.answers.forEach((ans) => {
          ans.selected = false;
        });
        if (elem.type == 5) elem.correct = false;
      });
      delete this.topic["result"];
      delete this.topic["resultstatus"];
      if (this.training.trainingType != 'attest') {
        // delete this.topic['test_done'];
        this.startTest();
      }
    },
    sendPostMessage(data){
      let data_encoded = JSON.stringify(data);
      window.postMessage(data_encoded);
    },
    startTest() {
      if(this.training.trainingType == 'attest' && !this.training.selectedUser) return
      if(this.training_key)
        this.sendPostMessage({ 
          type: "test_start", 
          user_id: this.user_id, 
          training_id: this.$store.state.currentTraining, 
          date: new Date().toISOString(), 
          timestamp: Date.now()
        })
      axios
        .post(
          `${this.$store.state.api_link}/training/stats/start/test`,
          {
            training_id: this.$store.state.currentTraining,
            test_id: this.topic.version_id,
            is_rented: this.$store.state.currentTrainingRented,
            key: this.training_key,
            user_id: this.training.trainingType == 'attest' && this.topic.selectedUser ? this.topic.selectedUser.id : null
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.testHasStarted = true;
          // this.$emit("endTraining");
          this.$emit("testStarted");
        })
        .catch(() => {
          console.log("Error while sending statistics");
        });
    },
    getCertificate() {
      axios
        .post(
          `${this.$store.state.api_link}/certificates/attach`,
          {
            cert_id: this.training.certificate.background,
            training_id: this.$store.state.currentTraining,
            training_title: this.training.title,
            expiration_date: this.training.certificate.expiration,
            expires: this.training.certificate.expires,
            user_id: this.training.selectedUser ? this.training.selectedUser : null,
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success" && (this.training.trainingType != 'attest')) {
            if (response.data.new_cert == 1) {
              let cert = this.$store.state.certificates.siteCertificates.find(
                (el) => el.id == this.training.certificate.background
              );
              let imgURL = "";
              if (cert != null) {
                imgURL = `${this.$store.state.api_assets_link}/${cert.path}`;
              }
              this.$store.state.toast.showPopup(
                {
                  img: imgURL,
                  title: this.toLocal("training.congrats"),
                  message: this.toLocal("training.got_cert"),
                },
                3000
              );
              this.$store.state.certificates.appendCertificate(
                response.data.certificate
              );
            } else {
              this.$store.state.certificates.updateCertificate(
                response.data.certificate
              );
            }
          }
        })
        .catch((err) => {});
    },
    handleDragEnd(topicIndex, trainingItemIndex) {
      this.futureItem = this.training.content[topicIndex].items[trainingItemIndex].selected[this.futureIndex]
      this.movingItem = this.training.content[topicIndex].items[trainingItemIndex].selected[this.movingIndex]
      this.futureItem.temptext = null;
      this.movingItem.temptext = null;
      const _items = Object.assign([], this.training.content[topicIndex].items[trainingItemIndex].selected)
      _items[this.futureIndex] = this.movingItem
      _items[this.movingIndex] = this.futureItem
      this.answerDrag = false;
      this.training.content[topicIndex].items[trainingItemIndex].selected = _items
    },
    handleMove(e, topicIndex, trainingItemIndex) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      this.training.content[topicIndex].items[
        trainingItemIndex
      ].selected.forEach((answer, answerIndex) => {
        if (answerIndex == this.futureIndex) {
          this.$set(
            answer,
            "temptext",
            this.training.content[topicIndex].items[trainingItemIndex].selected[
              index
            ].part1
          );
        } else if (answerIndex == this.movingIndex) {
          this.$set(answer, "temptext", null);
          // this.$set(answer, 'temptext', this.training.content[topicIndex].items[trainingItemIndex].selected[this.futureIndex].part1);
        } else {
          this.$set(answer, "temptext", null);
        }
      });
      return false; // disable sort
    },
    selectTaskAnswer(task, answerIndex) {
      if (task.result) return;
      let index = task.answers.findIndex((el) => el.selected);
      if (index != -1 && !task.multiple_correct_answers) {
        this.$set(task.answers[index], "selected", false);
      }
      if (!task.answers[answerIndex].selected)
        this.$set(task.answers[answerIndex], "selected", true);
      else
        task.answers[answerIndex].selected =
          !task.answers[answerIndex].selected;
    },
    goBack() {
      this.$store.state.loading.loadingStart();
      this.$router.back();
      setTimeout(() => {
        if (this.prevRoute.fullPath != this.$router.currentRoute.fullPath)
          this.$router.go(this.prevRoute.fullPath);
      }, 1000);
    },

    getTrainingFileURL(url){
      if(this.$store.state.currentTrainingRented){
        let protocol = this.$store.state.ssl ? 'https://' : 'http://';
        return protocol +  process.env.VUE_APP_PARENT_LMS_API + '/uploads/' + url;
      }
      else{
        return this.getFileUrl(url)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_training.scss";
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  background: white;
  border-radius: 2rem;
  // border: 1px solid var(--primary-color);
  color: var(--primary-color);
  margin-top: 2rem;
  cursor: pointer;
  &.retake {
    border: 1px solid var(--primary-color);
    background: white;
  }
  &.restart {
    background: var(--third-color);
    border: 1px solid var(--third-color);
  }
  &:hover {
    font-weight: 700;
  }
  &.deactivated {
    opacity: 0.5;
    &:hover {
      font-weight: 300;
      cursor: not-allowed;
    }
  }
  &.attesting-btn{
    width: clamp(250px, 75vw, 800px);
    background-color: var(--secondary-color);
    margin: 2rem auto 0;
    border: none;
    box-shadow: 0 2px 6px 0 #00000044;
    padding: 12px;
    font-size: clamp(24px, 2.9vh, 32px);
    &.inactive{
      opacity: 0.5;
      cursor: not-allowed;
       &:hover {
        font-weight: 400;
      }
    }
  }
}
.ghost {
  opacity: 0.5;
  background: #ffffff;
}
.test--arrow {
  svg {
    path {
      stroke: var(--primary-color);
    }
  }
}
.end--button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.task--number{
  position: absolute;
  left: 18px;
  top: 25px;
  font-weight: 600;
}
.attesting-4{
  display: flex;
  // align-items: center;
  gap: 30px;
  @media(max-width: 1100px){
    flex-direction: column;
  }
  .training--block .task .task--text{
    padding: 0 20px;
  }
}
.attesting-task{
  .task .task--text{
    padding: 0 20px;
  }
  .task{
    padding: 5px 40px;
  }
}
</style>
<style>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
<style lang="scss">
.attesting-4{
  .test-task-editor.lg, .test-attest-4{
    width: 50%;
  }
  .test-attest-4{
    &.answer-row{
      width: 50%;
    }
  }
  @media(max-width: 1100px){
    .test-task-editor.lg, .test-attest-4{
      width: 100%;
    }
     .test-attest-4{
      &.answer-row{
        width: 100%;
      }
    }
  }
}
.task {
  &.hidden {
    &.task-5 {
      .tries-container {
        display: none;
      }
    }
  }
}
</style>
