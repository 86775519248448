<template>
  <div 
    class="login--wrapper"
    :style="[{'background-image': `url('${this.$store.state.api_uploads_link + this.$store.state.system_settings['login_bg']}')`}]"
  >
 
    <div :class="['login--side', register && !selectNewGroup ? 'active' : '']">
      <div 
        v-if="new_group == 38" 
        class="group"
      >
        <div class="group--image">
          <img src="@/assets/images/icons/group-tevams.svg">
        </div>
        <div class="group--title">
          {{toLocal('login.parents')}}
        </div>
      </div>
      <div
        v-if="new_group == 39" 
        class="group"
      >
        <div class="group--image">
          <img src="@/assets/images/icons/group-med_specialistams.svg">
        </div>
        <div class="group--title">
          {{toLocal('login.medical_specialists')}}
        </div>
      </div>
      <div 
        v-if="new_group == 40" 
        class="group"
      >
        <div class="group--image">
          <img src="@/assets/images/icons/group-specialistams.svg">
        </div>
        <div class="group--title">
          {{toLocal('login.specialists')}}
          <br>
          <span>
            {{toLocal('login.health_education')}}<br>{{toLocal('login.social_fields')}}
          </span>
        </div>
      </div>
      <form
        class="login--form"
        method="POST"
        action=""
        @submit="checkRegisterForm"
      >
        <div class="form-group row">
          <label
            for="email"
            class="col-md-4 col-form-label text-md-right "
          >
          {{ toLocal("login.email_address") }}
            
          </label>

          <div class="col-md-6">
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              name="email"
              value=""
              required
              autocomplete="email"
              autofocus
            >

            <span
              v-if="errors.length"
              class="invalid-feedback"
              role="alert"
            >
              <strong
                v-for="error in errors"
                :key="error"
              >{{ toLocal(error) }} </strong>
            </span>
          </div>
        </div>

        <div class="form-group row">
          <label
            for="password"
            class="col-md-4 col-form-label text-md-right"
          >{{ toLocal("generic.password") }}</label>

          <div class="col-md-6">
            <input
              id="password"
              ref="newPassword"
              v-model="password"
              :type="show_password ? 'text' : 'password'"
              class="form-control"
              name="password"
              required
              autocomplete="new-password"
            />
            <LoginShowPasswordSvg v-if="$refs['newPassword'] && $refs['newPassword'].value != ''" @mousedown="show_password = true" @mouseup="show_password = false" @mouseout="show_password = false" xmlns="http://www.w3.org/2000/svg"/>
          </div>
        </div>

        <div class="form-group row">
          <label
            for="password"
            class="col-md-4 col-form-label text-md-right"
          >{{ toLocal("login.confirm_password") }}</label>

          <div class="col-md-6">
            <input
              id="password"
              ref="newPasswordConfirm"
              v-model="confirm_password"
              :type="show_password2 ? 'text' : 'password'"
              class="form-control"
              name="password"
              required
              autocomplete="new-password"
            >
            <LoginShowPasswordSvg v-if="$refs['newPasswordConfirm'] && $refs['newPasswordConfirm'].value != ''" @click="show_password2 = !show_password2"/>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="col-md-8 offset-md-4 ">
            <button
              type="submit"
              class="btn btn-primary btn-full"
            >
              {{ toLocal("login.register") }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div :class="['login--side', selectNewGroup || register ? '' : 'active']">
      <a
        href="/login"
        class="logo"
      >
        <img :src="this.$store.state.api_uploads_link + this.$store.state.system_settings['main_logo']">
      </a>
      <PlatformSelect v-if="$store.state.system_settings['otherPlatforms'] != undefined"/>
      <form
        class="login--form"
        method="POST"
        action=""
        @submit="checkForm"
      >
      <div class="lang-row">
        <span class="section-label">{{toLocal('settings.language_select')}}:</span>
        <div class="lang-container">
          <LoginChangeLanguageSvg class="lang-svg"/>
          <select @change="changeLang" v-model="chosenLanguage"
          name="language" class="lang-selector">
          <option value="" hidden>{{ toLocal('generic.choose') }}</option>
            <option v-for="(language, index) in languages" 
            :key="`lang-${index}`" :value="language.value">{{ language.label }}</option>
          </select>
        </div>
      </div>
      <LoginTypeTabs 
        v-if="$store.state.system_settings.loginTypes && $store.state.system_settings.loginTypes.length > 1" 
        :types="$store.state.system_settings.loginTypes"
        :selectedTab="selectedLoginTab"
        @changeTab="changeLoginTab"
        />
        <div class="form-group row" v-if="selectedLoginTab=='password' || !selectedLoginTab" :class="{'no-pass': loginWithoutPass}">
          <label
            for="email"
            class="col-md-4 col-form-label text-md-right has-icon"
          >
          {{ $store.state.system_settings.loginWithPhone ? toLocal("generic.login_name") : toLocal("login.email_username") }}
            <div class="info-parent">
              <v-popover
                  placement="right"
                  offset="12"
                  class="login-info-tooltip"
                  popoverClass="login-info-popover"
                  :autoHide='true'
              >
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8.5" cy="8.5" r="8" fill="#EFEFEF" stroke="#333333"/>
                  <path d="M8.5 4.25H8.51286" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.5 12.7497V7.89258" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                  <template slot="popover">
                    <div class="tooltip-container">
                        <span>{{toLocal('login.can_enter')}} </span>
                        <ul>
                          <li>
                            {{toLocal('login.can_enter_username')}}
                          </li>
                           <li>
                            {{toLocal('login.can_enter_email')}}
                          </li>
                           <li>
                           {{toLocal('login.can_enter_phone')}}
                          </li>
                        </ul>
                    </div>
                  </template>
              </v-popover>
            </div>
          </label>

          <div class="col-md-6">
            <input
              id="email2"
              v-model="email"
              :placeholder="toLocal('generic.enter_text')"
              type="text"
              class="form-control"
              name="email"
              value=""
              required
              autocomplete="email"
              autofocus
            >

            <span
              v-if="errors.length"
              class="invalid-feedback"
              role="alert"
            >
              <strong
                v-for="error in errors"
                :key="error"
              >{{ toLocal(error) }} </strong>
            </span>
          </div>
        </div>

        <div class="form-group row" v-if="!loginWithoutPass && selectedLoginTab=='password' || !selectedLoginTab">
          <label
            for="password"
            class="col-md-4 col-form-label text-md-right"
            
          >{{ toLocal("generic.password") }}</label>

          <div class="col-md-6">
            <input
              id="password"
              ref="currentPassword"
              v-model="password"
              :type="show_password ? 'text' : 'password'"
              class="form-control"
              :placeholder="toLocal('generic.enter_text')"
              name="password"
              required
              autocomplete="current-password"
            >
            <LoginShowPasswordSvg v-if="$refs['currentPassword'] && $refs['currentPassword'].value != ''" @click="show_password = !show_password"/>
            <a
              class="forgot--credentials"
              href="javascript:;"
              @click="openForgotPassword"
            >
              {{ toLocal("login.forgot_credentials") }}
            </a>
          </div>
        </div>

        <div class="form-group row mb-0" v-if="selectedLoginTab=='password' || !selectedLoginTab">
          <div class="col-md-8 offset-md-4 action-buttons">
            <div class="form-check remember--wrapper">
              <input
                id="remember"
                v-model="remember"
                class="form-check-input form-checkbox"
                type="checkbox"
                name="remember"
                value="true"
              >

              <label
                class="form-check-label remember--text"
                for="remember"
              >
                {{ toLocal("login.remember_me") }}
              </label>
            </div>
            <button
              type="submit"
              class="btn btn-primary login-btn"
            >
              {{ toLocal("generic.login") }}
            </button>
          </div>
        </div>
      </form>
      <PhoneLogin v-if="selectedLoginTab=='phone'" @triggerSelectPlatform="selectPlatformModal = true; selectablePlatforms = $event; currentLoginType='phone'"/>
      <AzureLogin v-if="selectedLoginTab=='msazure'" @triggerSelectPlatform="selectPlatformModal = true; selectablePlatforms = $event; currentLoginType='azure'"/>

       <div v-if="$store.state.system_settings.loginWithoutPass && (selectedLoginTab=='password' || !selectedLoginTab)" class="register-btn" @click="loginWithoutPass = !loginWithoutPass"> {{loginWithoutPass ? toLocal('login.login_with_password') : toLocal('login.login_without_password')}}</div>
      <div class="register-btn" v-if="$store.state.system_settings.registration" @click="registerClick"> Registruotis</div>
    </div>
      <GlobalModal
      :modalInfo="{
        text: 'platforms.login_text_label',
        type: 'buttons',
          buttons: selectablePlatforms,
      }"
      @onClose="selectPlatformModal=false"
      @buttonSelected="selectPlatform($event)"
      v-if="selectPlatformModal"/>
    <ResetPasswordModal :forgot_password="forgot_password" @setForgotPasswordState="forgot_password = $event"/>
  </div>
</template>

<script>
import axios from 'axios';
import * as Sentry from "@sentry/browser";
import ResetPasswordModal from '@/components/layout/login/ResetPasswordModal.vue'
import PlatformSelect from '@/components/layout/login/PlatformSelect.vue'
import GlobalModal from '@/components/layout/modals/GlobalModal.vue';
import LoginTypeTabs from '@/components/layout/login/LoginTypeTabs.vue'
import PhoneLogin from '@/components/layout/login/PhoneLogin.vue'
import AzureLogin from '@/components/layout/login/AzureLogin.vue'
//SVG
import LoginShowPasswordSvg from "@/assets/images/icons/login/login-show-password.svg?inline";
import LoginChangeLanguageSvg from "@/assets/images/icons/login/login-change-language.svg?inline";
export default {
    name: "Login",
    components:{
      ResetPasswordModal,
      PlatformSelect,
      GlobalModal,
      LoginTypeTabs,
      PhoneLogin,
      AzureLogin,

      //SVG COMPONENTS
      LoginShowPasswordSvg,
      LoginChangeLanguageSvg
    },
    data: function(){
      return {
        errors: [],
        email: null,
        password: null,
        confirm_password: null,
        remember: false,
        forgot_password: false,
        forgot_password_email: null,
        forgot_password_notification: [],
        selectNewGroup: false,
        new_group: -1,
        auth2: null,
        register: false,
        show_password: false,
        show_password2: false,
        chosenLanguage: '',
        loginWithoutPass: false,
        selectPlatformModal: false,
        currentLoginType: '',
        selectedLoginTab: null,
        selectablePlatforms: [{label: 'Mokymai', lms_key: 'training'}, {label: 'Mokymai 2', lms_key: 'training2'}],
        languages:[
                {'label':'Lietuvių', 'value':'lt'},
                {'label':'English', 'value':'en'},
                {'label':'По-русски', 'value':'ru'},
                {'label':'Latviešu', 'value':'lv'},
                {'label':'Eesti', 'value':'et'},
        ],

      }
  },
    
    activated() {
      // This is for android WebView
      document.addEventListener('message',this.handleNativeMessage.bind(this));
      // This is for iOS WebView
      window.addEventListener('message',this.handleNativeMessage.bind(this));

      this.register = false;
      this.selectPlatformModal = false
      this.selectNewGroup = false;
    },
    deactivated() {
      document.removeEventListener('message',this.handleNativeMessage.bind(this))
      window.removeEventListener('message',this.handleNativeMessage.bind(this))
    },
    mounted() {
        if(this.$route.params.token){
          this.loginWithToken();
      }
         if(this.$route.query.select_platform && this.$route.query.select_platform == 1){
          this.getSelectablePlatforms();
        }
        this.$store.state.header = false;
        this.loadFacebookSDK(document, "script", "facebook-jssdk");
        this.initFacebook();
        if (this.$route.query.error && this.$route.query.login_type == 'msazure') {
          this.changeLoginTab('msazure')
        }
        // this.initGoogle();
    },
    methods: {
        changeLoginTab(tab){
          this.selectedLoginTab = tab
      },
      getSelectablePlatforms() {
        axios
          .get(`${this.$store.state.api_link}/login/platforms`)
          .then((res) => {
             if (res.data.platforms) {
                  // this.selectPlatformModal = true
                  // this.selectablePlatforms = res.data.platforms
                let mapped = res.data.platforms.map((item) => {
                  return {name: item.label, lms_key: item.lms_key};
                })
               this.$store.state.connectedLMSes = mapped
                
                this.selectPlatformModal = true;
                this.selectablePlatforms = res.data.platforms;
                this.currentLoginType = 'azure'
              }
          })
          .catch((err) => console.log(err));
      },
      switchPlatforms(platform) {
           let lms_key = platform;
          let platform_item = this.$store.state.connectedLMSes.find((p) => p.lms_key == lms_key);
          this.selectedLMS = platform_item;
          axios
            .get(`${this.$store.state.api_link}/switch/platform?lms_key=${lms_key}`)
            .then((res) => {
              let backend_domain = res.data.backend_domain;
              this.$store.state.loading.loadingStart();
              this.$store.commit("setNewUrl", backend_domain);
              // this.$store.commit("fetchSystemSettings");
              this.$router.push("/");
              
              this.$store.state.currentLMS = lms_key;
            
              this.$store.state.pageRerenderKey++;
              this.$store.state.loading.loadingDone();
            })
            .catch((err) => console.log(err));
        },
        selectPlatform(btn) {
          if (['phone', 'azure'].includes(this.currentLoginType)) {
            this.switchPlatforms(btn.lms_key);
          }
          else {
              this.checkForm(null, btn.lms_key);
          }
          this.currentLoginType = '';
        },
        changeLang(e){
          this.$store.state.user_selected_language = e.target.value;
          this.$store.state.language = e.target.value;
        },
        handleNativeMessage: function(e) {
          try {
            let data = JSON.parse(e.data);
            let type = data.type;
            if(type == "facebook") {
              this.innerFacbookLogin(data.access_token, data.user_id);
            }
            if(type == "google") {
              this.innerGoogleLogin(null, data.access_token);
            }
          }catch(e) {
            
          }
        },
        googleSigninNative: function(e) {
          window.ReactNativeWebView.postMessage('googleLoginClicked');
        },
        checkForm: function(e, selectedPlatform = null) {
          this.$store.state.loading.loadingStart();
          if(e)
            e.preventDefault();
          let remember_me = this.remember;
          if(window.outerWidth <= 768) {
            remember_me = true;
          }
          let form = {
              email: this.email,
              remember_me: remember_me,
              language: this.chosenLanguage
          }
          if(!this.loginWithoutPass){
            form.password = this.password
          }
          let is_native = this.$store.state.isNative;
          let url = `${this.$store.state.api_link}/login${this.$store.state.isNative ? '?native=1' : ''}`;
          if (is_native && selectedPlatform) {
            url += `&platform=${selectedPlatform}`;
          }
          else if(selectedPlatform){
            url += `?platform=${selectedPlatform}`;
          }
          axios.post(url, form)
            .then((res) => {
              if (res.data.platforms) {
                this.selectPlatformModal = true
                this.selectablePlatforms = res.data.platforms
                if (res.data.connected_lmses) {
                  this.$store.state.connectedLMSes = res.data.connected_lmses
                }
                this.$store.state.loading.loadingDone();
                return;
              }
              if (res.data.lms_key) {
                this.$store.state.currentLMS = res.data.lms_key;
                this.$store.commit("setNewUrl", res.data.domain);
                this.$router.push('/');
                this.$store.state.pageRerenderKey++;
                return;
              }
              this.selectPlatformModal = false
              this.$store.state.user_checked = true;
              this.errors = [];
              if(res.data.token.original.access_token)
                this.$store.state.access_token = res.data.token.original.access_token;
              this.$store.state.user_email = res.data.user.email;
              Sentry.setUser({ email: this.$store.state.user_email });
              this.$store.state.user_id = res.data.user.id;
              this.$store.state.user_name = res.data.user.name;
              this.$store.state.user_last_name = res.data.user.last_name;
              this.$store.state.user_permissions = res.data.user.permissions;
              this.$store.state.user_role = res.data.token.original.role;
              this.$store.state.user_is_new = res.data.user.new_user;
              this.$store.state.user_trainings_started = res.data.stats.trainings_started;
              this.$store.state.user_trainings_ended = res.data.stats.trainings_ended;
              this.$store.state.user_test_average = res.data.stats.test_average;
              this.$store.state.user_username = res.data.user.username;
              if(res.data.user.phone)
                this.$store.state.user_phone = res.data.user.phone;
              if (res.data.user.managed_groups) {
                this.$store.state.managedGroups = res.data.user.managed_groups;
              }
              this.$store.state.user_can_contact = res.data.user.can_contact;
              this.$store.state.user_can_notify = res.data.user.can_notify;
              this.$store.state.user_can_identify = res.data.user.can_identify;
              if(res.data.user.selected_language != null && res.data.user.selected_language != "" && res.data.user.selected_language != undefined){
                this.$store.state.user_selected_language = res.data.user.selected_language;
                this.$store.state.language = res.data.user.selected_language;
              }
              if (res.data.token.original.access_token) {
                window.localStorage.setItem("access_token", res.data.token.original.access_token);
                window.sessionStorage.setItem("access_token", null);
                
              }
             
              if(res.data.token.original.new_user == 1){
                this.selectNewGroup = true;
                window.scrollTo(0,0);
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }else{
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }
              this.$store.state.certificates.getCertificates();
              this.$store.commit("checkTerms", {getters: this.$store.getters});
              this.$store.commit('startWebsockets');
              this.$store.commit('userLoggedIn');
              this.$store.commit("initializeUserCols");
              this.$store.commit("loadUserInfo");

            })
            .catch((err) => {
              if(err.response &&err.response.data.hasOwnProperty('error')){
                this.errors = [err.response.data.error];
              }else{
                console.log(err);
              }
              this.selectPlatformModal = false

              this.$store.state.loading.loadingDone();
            });
        },
  
        openForgotPassword: function() {
          setTimeout(() => {
            this.forgot_password = true;
          });
        },

        setGroup(group) {
          if(this.register) {
            this.selectNewGroup = false;
            this.new_group = group;
          }else{
            this.$store.state.loading.loadingStart();
            axios.post(`${this.$store.state.api_link}/user/group-select`, 
            { group: group },
            this.$store.getters.axiosConfig())
            .then((res) => {
              if(res.data.status == "success"){
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
                this.selectNewGroup = false;
              }else{
                console.log("Error while setting group");
              }
            })
            .catch((err) => {
              console.log(err);
              this.$store.state.loading.loadingDone();
            });
          }
        },
        logInWithFacebook() {
          if(this.$store.state.isNative){
            this.$store.state.loading.loadingStart();
            window.ReactNativeWebView.postMessage('facebookLoginClicked');
          }else{
            window.FB.login((response) => {
              if (response.authResponse) {
                this.$store.state.loading.loadingStart();
                this.innerFacbookLogin(response.authResponse.accessToken, response.authResponse.userID)
              } else {
                this.$store.state.loading.loadingDone();
              }
            }, {scope: 'email'});
          }
          return false;
        },
         loginWithToken(){
           if(this.$store.state.loading)
             this.$store.state.loading.loadingStart();
           axios.post(`${this.$store.state.api_link}/login${this.$store.state.isNative ? '?native=1' : ''}`, {
              registration_token: this.$route.params.token
            })
            .then((res) => {
              // console.log(res);
              if(res.data.status == 'error'){
                this.$router.push("/login");
                return;
              } 
              this.errors = [];
              if(res.data.token.original.access_token)
                this.$store.state.access_token = res.data.token.original.access_token;
              this.$store.state.user_id =  res.data.user.id;
              this.$store.state.user_email = this.email;
              this.$store.state.user_name = res.data.user.name;
              this.$store.state.user_last_name = res.data.user.last_name;
              this.$store.state.user_role = res.data.user.role;
              this.$store.state.user_trainings_started = res.data.stats.trainings_started;
              this.$store.state.user_trainings_ended = res.data.stats.trainings_ended;
              this.$store.state.user_test_average = res.data.stats.test_average;
              this.$store.state.user_selected_language = res.data.user.selected_language;
              this.$store.state.language = res.data.user.selected_language;
              if(res.data.token.original.access_token)
                window.localStorage.setItem("access_token", res.data.token.original.access_token);
                // window.sessionStorage.setItem("access_token", res.data.token.original.access_token);
              if(res.data.token.original.new_user == 1){
                this.selectNewGroup = true;
                window.scrollTo(0,0);
                if(this.$store.state.loading)
                 this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }else{
                if(this.$store.state.loading)
                  this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }
              this.$store.state.certificates.getCertificates();
              this.$store.commit("checkTerms", {getters: this.$store.getters});
              this.$store.commit('startWebsockets');
              this.$store.commit("initializeUserCols");
            })
            .catch((err) => {
              if(this.$store.state.loading)
                this.$store.state.loading.loadingDone();
               this.$router.push("/login")
            });
        },
        innerFacbookLogin(access_token, user_id) {
          axios.post(`${this.$store.state.api_link}/login/facebook`, {
            fb_access_token: access_token, fb_user_id: user_id
          })
          .then((res) => {
            this.errors = [];
            this.$store.state.access_token = res.data.access_token;
            this.$store.state.user_email = res.data.email;
            this.$store.state.user_role = res.data.role;
            window.localStorage.setItem("access_token", res.data.access_token);
            window.sessionStorage.setItem("access_token", null);
            if(res.data.new_user == 1){
              this.selectNewGroup = true;
              window.scrollTo(0,0);
              this.$store.state.loading.loadingDone();
            }else{
              this.$store.state.loading.loadingDone();
              this.$router.push("/");
            }
            this.$store.commit('userLoggedIn');
            this.$store.commit("checkTerms", {getters: this.$store.getters});
          })
          .catch((err) => {
            if(err.response && err.response.data.hasOwnProperty('error')){
              this.errors = [err.response.data.error];
            }else{
              console.log(err);
            }
            this.$store.state.loading.loadingDone();
          });
        },
        async initFacebook() {
          window.fbAsyncInit = function() {
            window.FB.init({
              appId: "722489895182777", //You will need to change this
              cookie: true, // This is important, it's not enabled by default
              version: "v7.0"
            });
          };
        },
        async loadFacebookSDK(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        },
    
        attachGoogleSignin(element) {
          this.auth2.attachClickHandler(element, {},
            (googleUser) => {
              this.$store.state.loading.loadingStart();
              let id_token = googleUser.getAuthResponse().id_token;
              this.innerGoogleLogin(id_token, null);
            }, (error) => {
          });
        },
        innerGoogleLogin(id_token, access_token) {
          this.$store.state.loading.loadingStart();
          axios.post(`${this.$store.state.api_link}/login/google`, {
            google_id_token: id_token,
            google_access_token: access_token
          })
          .then((res) => {
            this.errors = [];
            this.$store.state.access_token = res.data.access_token;
            this.$store.state.user_email = res.data.email;
            this.$store.state.user_role = res.data.role;
            window.localStorage.setItem("access_token", res.data.access_token);
            window.sessionStorage.setItem("access_token", null);
            if(res.data.new_user == 1){
              this.selectNewGroup = true;
              window.scrollTo(0,0);
              this.$store.state.loading.loadingDone();
            }else{
              this.$store.state.loading.loadingDone();
              this.$router.push("/");
            }
            this.$store.commit("checkTerms", {getters: this.$store.getters});
          })
          .catch((err) => {
            if(err.response && err.response.data.hasOwnProperty('error')){
              this.errors = [err.response.data.error];
            }else{
              console.log(err);
            }
            this.$store.state.loading.loadingDone();
          });
        },
        registerClick() {
          this.register = true;
          // this.selectNewGroup = true;
        },
        checkRegisterForm(e) {
          this.$store.state.loading.loadingStart();
          e.preventDefault();

          axios.post(`${this.$store.state.api_link}/register${this.$store.state.isNative ? '?native=1' : ''}`, {
              email: this.email,
              password: this.password,
              confirm_password: this.confirm_password,
              group: this.new_group,
            })
            .then((res) => {
              this.$store.state.user_checked = true;
              this.errors = [];
              if(res.data.token.original.access_token)
                this.$store.state.access_token = res.data.token.original.access_token;
              this.$store.state.user_email = this.email;
              this.$store.state.user_id = res.data.user.id;
              this.$store.state.user_name = res.data.user.name;
              this.$store.state.user_role = res.data.token.original.role;
              this.$store.state.user_is_new = res.data.user.new_user;
              this.$store.state.user_trainings_started = res.data.stats.trainings_started;
              this.$store.state.user_trainings_ended = res.data.stats.trainings_ended;
              this.$store.state.user_test_average = res.data.stats.test_average;
              this.$store.state.user_username = res.data.user.username;
              this.$store.state.user_last_name = res.data.user.last_name;
              if(res.data.user.phone)
                this.$store.state.user_phone = res.data.user.phone;
              this.$store.state.user_can_contact = res.data.user.can_contact;
              this.$store.state.user_can_notify = res.data.user.can_notify;
              this.$store.state.user_can_identify = res.data.user.can_identify;
              if(res.data.token.original.access_token)
                window.localStorage.setItem("access_token", res.data.token.original.access_token);
              // window.sessionStorage.setItem("access_token", res.data.token.original.access_token);
              if(res.data.token.original.new_user == 1){
                this.selectNewGroup = true;
                window.scrollTo(0,0);
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }else{
                this.$store.state.loading.loadingDone();
                this.$router.push("/");
              }
              this.$store.state.certificates.getCertificates();
              this.$store.commit("checkTerms", {getters: this.$store.getters});
              this.$store.commit('startWebsockets');
              this.$store.commit('userLoggedIn');
            })
            .catch((err) => {
              if(err.response != undefined && err.response.data.hasOwnProperty('error')){
                this.errors = [err.response.data.error];
              }else{
                console.log(err);
              }
              this.$store.state.loading.loadingDone();
            });
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_forms.scss";
@import "../assets/scss/views/_loginViewScoped.scss";
.action-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 44px;
  @media(max-width:768px){
    margin-top: 20px;
  }
}
.has-icon{
  position: relative;
  .info-parent{
    position: absolute;
    right: -12px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    margin-top: 26px;
    @media (max-width: 768px){
      margin-top: 12px;
    }
  }
}
.login-btn{
  font-size: clamp(16px, 1.5vw, 18px);
  padding:  6px 40px;
  transition: background 0.25s, color 0.25s;
  border-radius: 20px;
  background: var(--LK-tamsi-pilka, #4D4D4D);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16); 
  text-align: center;
  font-style: normal;
  font-weight: 400; 
  color: var(--balta, #FFF); 
  &:hover{
    background: white;
    color: #333;
  }
  &:active{
    background: white;
    color: #333;
    box-shadow: inset 0px -1px 5px rgba(255, 255, 255, 0.9);
  }
}

</style>
<style lang="scss">
.login-info-popover{
  max-width: 200px;
  font-family: var(--main-site-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  .tooltip-container{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 8px;
    ul{
      margin: 0;
      padding-left: 15px;
    }
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 15px;

    .tooltip-arrow {
      border-width: 0;
      height: 15px;
      width: 15px;
      transform: rotate(45deg);
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
      box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.2);
      background: white;
      left: -7.5px;
      top: calc(50% - 15px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
   &.popover {
    $color: #fff;

    .popover-arrow {
      border-color: $color;
    }
  }


  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
.lang-row{

  display: flex;
  justify-content: space-between;
  gap: 10px;
  .section-label{
  color: var(--Tamsus-tekstas, #333);
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */ 
  padding-left: 12px;
  }
}
.lang-container{
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  .lang-svg{
    position: absolute;
    left: 8px;
  }
}
.lang-selector{
  padding: 4px 8px;
  text-align: center;
  display: flex;
  padding-left: 24px;
  color: #333;
  text-align: center;
 font-family: var(--main-site-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */ 
  background-color: white;
  border: none;
  border-radius: 4px;
  background: var(--balta, #FFF);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16); 
  option{
    font-family: var(--main-site-font);
  }
}
</style>