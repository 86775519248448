<template>
    <div
        class="video-test-container" 
        :class="{readOnlyComponent: readOnly}" 
        @mouseover="$emit('toggleDrag', true)"
        @mouseleave="$emit('toggleDrag', false)"
    >
        <span v-if="!readOnly" @click="deleteQuestion" class="close-cross"><CloseCrossSvg/></span>
        <div class="not-readOnly" v-if="!readOnly && currentTest">
            <div class="container">
                <span class="test-number">{{number}}</span>
                <span class="test-time-tag">{{toLocal('test.question_time')}}</span>
                <div class="timestamp">
                    <div class="time-container">
                        <input class="time-input" max="60" v-mask="numberMask" type="text" v-model="testVars.timeHours" @input="checkNumberInput"/>
                        {{toLocal('generic.hours_short')}}
                    </div>
                    <div class="time-container">
                        <input class="time-input" max="60" v-mask="numberMask" type="text" v-model="testVars.timeMinutes" @input="checkNumberInput"/>
                        {{toLocal('generic.min')}}
                    </div>
                    <div class="time-container">
                        <input class="time-input" max="60" v-mask="numberMask" type="text" v-model="testVars.timeSeconds" @input="checkNumberInput"/>
                        <span>{{toLocal('generic.sec_short')}}</span>
                    </div>
                </div>
            </div>
            <div class="container">
                <input class="video-quest-input" :placeholder="toLocal('test.add_question')" v-model="testVars.timeQuestion"/>
            </div>
            <div class="answers-container">
                <div v-for="(answer, index) in arrAnswers" class="answer" :key="`vtest-arr-answers-${index}`">
                    <div class="is-correct" @click="toggleAnswer(index)" :class="{correct: arrAnswers[index].correct}">
                    <span class="answer-bubble correct" v-if="arrAnswers[index].correct">{{alphabet[index].toUpperCase()}}</span>
                    <span class="answer-bubble incorrect" v-else>{{alphabet[index].toUpperCase()}}</span>
                    </div>
                    <input :class="`answer-input answer-${index}`" v-model="arrAnswers[index].text"/>
                    <div class="delete" @click="deleteAnswer(index)">
                        <CloseCrossSvg/>
                    </div>
                </div>
                <div class="answer new-answer-blank">
                    <div class="plus" @click="addNewAnswer"><AssignPlusSvg height="20px" width="20px"/></div>
                    <div class="gray-rectangle"></div>
                    <div class="answer-colors">
                        <span class="test-info first"><div class="circle yellow"></div>{{toLocal('test.correct_answer')}}</span>
                        <span class="test-info"><div class="circle gray"></div>{{toLocal('test.incorrect_answer')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="readOnly" v-else-if="readOnly && currentTest">
            <div class="timer"><span class="time">{{ timer }}</span></div>
            <span class="question">{{ currentTest.question }}</span>
            <div class="question-container">
                <span class="answer-btn" :class="[{active: currentAnswerClicked.includes(index)}, getAnsweredBtnState(index)]" @click="answerQuestion(index)" v-for="answer, index in currentTest.answers" :key="`vtest-answers-${index}`">
                    <span class="letter">{{alphabet[index].toUpperCase()}}</span> <span class="text">{{ answer.text }}</span>
                </span>
            </div>
            <div class="bottom-row" :class="{fEnd: !isAnswerState}">
                <span class="response-text" v-if="isAnswerState" :class="[{green: is_correct}, {red: !is_correct && !isMultichoiceFailed}, {orange: isMultichoiceFailed}]">
                    {{is_correct ? toLocal('video_tests.correct_answered_text'): !isMultichoiceFailed ? toLocal('video_tests.incorrectly_answered_text') : toLocal('video_tests.incorrectly_answered_multi_text')}}
                </span>
                <div class="check-btn" @click="checkAnswers()" :class="{inactive: currentAnswerClicked.length == 0}">{{isAnswerState || !this.videoSettings.show_answers ? toLocal('generic.next') : toLocal('training.task_check')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import axios from 'axios';
import _ from 'lodash';
import CloseCrossSvg from '@/assets/images/icons/close-cross.svg?inline';
import AssignPlusSvg from '@/assets/images/icons/assign-plus.svg?inline';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,

});
    export default {
        name: 'VideoTest',
        components:{
            DatePicker,
            
            CloseCrossSvg,
            AssignPlusSvg,
        },
        props:{
            videoDuration: Number,
            videoId: Number,
            trainingId: null,
            currentTest: Object,
            readOnly: Boolean,
            number: Number | String,
            videoSettings: Object,
        },

        data(){
            return{
                testId: '',
                timeHours: 0,
                timeMinutes: 0,
                alphabet: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                timeSeconds: 0,
                timeQuestion: '',
                currentAnswerClicked: [],
                testVars:{
                    timeHours: 0,
                    timeMinutes: 0,
                    timeSeconds: 0,
                    timeQuestion: '',

                },
                arrAnswers: [{
                    text: this.toLocal('training_edit.answer_yes'), correct: true}, 
                    {text: this.toLocal('training_edit.answer_no'), correct: false}
                ],
                correctAnswers: [0],
                maxMinutes: 0,
                maxSeconds: 0,
                maxHours: 0,
                timer: 10,
                numberMask,
                readonlyTest: false,
                isAlreadyAnswered: false,
                isAnswerState: false,
                is_correct: 0,
                isMultichoiceFailed: false,
                isInitialDataLoaded: false,
            }
        },
        methods:{
            getAnsweredBtnState(index){
                if(!this.isAnswerState){
                    return
                }else{
                    if(this.currentAnswerClicked.includes(index)){
                        if(this.currentTest.answers[index].correct){
                            return 'answeredCorrect';
                        }else if(!this.currentTest.answers[index].correct){
                            return 'answeredIncorrect';
                        };
                    }else{
                        if(this.currentTest.answers[index].correct){
                            return 'shouldBeAnswered';
                        }else{
                            return 'unclickable';
                        }
                    }
                }
            },
            checkAnswers(){
                if(this.isAnswerState){
                    this.continuePlayingVideo(this.is_correct)
                }
                if(this.currentAnswerClicked.length == 0){
                    return;
                }else{
                    let neededCorrectAnswerCount = this.currentTest.answers.filter(function(answer){
                        return answer.correct;
                    }).length;
                    let currentTest = this.currentTest;
                    let correctAnswerCount = this.currentAnswerClicked.filter(function(answer){
                        return currentTest.answers[answer].correct;
                    }).length;
                    let incorrectAnswerCount = this.currentAnswerClicked.filter(function(answer){
                        return !currentTest.answers[answer].correct;
                    }).length;
                    if(this.videoSettings.partially_correct){
                        if(correctAnswerCount != 0 && incorrectAnswerCount == 0){
                            this.is_correct = correctAnswerCount / neededCorrectAnswerCount;
                        }else{
                            this.is_correct = 0;
                        }
                    }else{
                        this.isMultichoiceFailed = neededCorrectAnswerCount != 1 && neededCorrectAnswerCount != correctAnswerCount ? true : false;
                        this.is_correct = neededCorrectAnswerCount == correctAnswerCount && incorrectAnswerCount == 0 ? 1 : 0;
                    }
                    axios.post(`${this.$store.state.api_link}/answer-vtest`, {
                        training_id: this.currentTest.training_id,
                        question_id: this.currentTest.id,
                        answer_type: this.is_correct,
                        chosen_answer: this.currentAnswerClicked,
                    }, 
                    this.$store.getters.axiosConfig()).then(()=>{
                        if(this.videoSettings.show_answers){
                            this.isAnswerState = true;
                        }else{
                            this.continuePlayingVideo(this.is_correct);
                        }
                    });
                }
            },
            startTimer(){
                if(!this.isAnswerState){
                setTimeout(()=>{
                    this.timer -= 1;
                    if(this.timer <= 0){
                        this.timerOut()
                    }else{
                        this.startTimer();
                    }
                }, 1000)
            }
            },
            continuePlayingVideo(is_correct){
                this.currentAnswerClicked = [];
                this.isAlreadyAnswered = true;
                this.isAnswerState = false;
                this.isMultichoiceFailed = false;
                //nes parodo zalius buttonus ant kito klausimo dar neatsakius
                this.$emit('continueVideo', is_correct);
            },
            deleteQuestion(){
                this.$emit('deleteQuestion', this.currentTest.id);
            },
            hotsaveTests: _.debounce(function() {
                this.saveQuestion();
            }, 500),
            timerOut(){
                if(!this.isAlreadyAnswered){
                    axios.post(`${this.$store.state.api_link}/answer-vtest`, {
                    training_id: this.currentTest.training_id,
                    question_id: this.currentTest.id,
                    answer_type: false,
                    }, 
                    this.$store.getters.axiosConfig()).then(()=>{
                        this.isAlreadyAnswered = true;
                        this.$emit('continueVideo')
                    })
                }
            },
            answerQuestion(answer){
                if(this.isAnswerState){
                    return;
                }
                if(this.currentAnswerClicked.includes(answer)){
                    this.currentAnswerClicked.splice(this.currentAnswerClicked.indexOf(answer), 1);
                }else{
                    this.currentAnswerClicked.push(answer);
                }
                let correctAnswerCount = this.currentTest.answers.filter(function(answer){
                    return answer.correct;
                }).length;
            },
            addNewAnswer(){
                if(this.arrAnswers.length != this.alphabet.length){
                    this.arrAnswers.push({text: this.toLocal('generic.answer'), correct: false});
                }
            },
            toggleAnswer(index){
                this.arrAnswers[index].correct = !this.arrAnswers[index].correct
                this.hotsaveTests();
            },
            deleteAnswer(number){
                this.arrAnswers.splice(number, 1);
                this.hotsaveTests();
            },
            saveQuestion(){
                if(!this.isInitialDataLoaded) return;
                let timeStamp = parseInt(this.testVars.timeHours)*60*60 + parseInt(this.testVars.timeMinutes) * 60 + parseInt(this.testVars.timeSeconds);
                if(timeStamp == null) return
                axios.post(`${this.$store.state.api_link}/save-vtest`, {
                    question_id: this.testId ? this.testId : null,
                    training_id: this.trainingId,
                    video_id:this.videoId,
                    video_time: timeStamp,
                    question:this.testVars.timeQuestion,
                    answers:this.arrAnswers,
                }, this.$store.getters.axiosConfig())
                .then((res)=>{
                    this.$set(this.currentTest, 'video_time', timeStamp);
                    this.$set(this.currentTest, 'question', this.testVars.timeQuestion);
                })
            },

            calcMaxTime(maxTime){
                this.maxMinutes = Math.floor(maxTime / 60);
                this.maxHours = Math.floor(maxTime / 60 / 60);
            },
            calcPresetTime(time){
                let tempTime = time;
                let hours = Math.floor(tempTime / 3600);
                let minutes = Math.floor((tempTime % 3600) / 60);
                let seconds = tempTime % 60;
                this.testVars.timeHours = hours;
                this.testVars.timeMinutes = minutes;
                this.testVars.timeSeconds = seconds;
            },
            checkNumberInput(e){
                if(e.target.value > 60) {
                    e.target.value = 60;
                }
            },
            playCurrentTest(){
            this.timer = this.videoSettings.test_shown_duration;
            this.startTimer();
            this.readonlyTest = true;
            }
        },
        
        mounted(){
            this.isInitialDataLoaded = false;
            if(this.currentTest != undefined && this.readOnly){
                this.playCurrentTest()
            }else if(this.currentTest != undefined && !this.readOnly){
                this.testId = this.currentTest.id;
                this.testVars.timeQuestion=this.currentTest.question
                this.arrAnswers = this.currentTest.answers;
                this.calcPresetTime(parseInt(this.currentTest.video_time));
            }
            this.calcMaxTime(parseInt(this.videoDuration));
            setTimeout(()=>{
                this.isInitialDataLoaded = true;
            }, 500)
        },
          
       watch:{
        testVars:{
            deep: true,
            handler(newVal, oldVal){
                if(!this.isInitialDataLoaded) return;
                this.hotsaveTests()
            }
        },
        arrAnswers:{
            deep: true,
            handler(){
                if(!this.isInitialDataLoaded) return;
                this.hotsaveTests();
            }
        },
       }
    }
</script>

<style lang="scss" scoped>
.video-test-container{
    display: flex;
    flex-direction: column;    
    padding: 16px 16px;
    background: #EFEFEF;
    border-radius: 12px;
    position: relative;
    margin-bottom: 15px;
    .close-cross{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 16px;
        top: 16px;
        font-size: 25px;
        z-index: 100;
        cursor: pointer;
    }
}
.container{
    .test-number{
        height: 36px;
        width: 36px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 18px;
        border-radius: 50%;
    }
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    .test-time-tag{
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.7);
        width: 15%;
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .video-time-input::-webkit-datetime-edit-ampm-field{
        display: none;
    }
    .video-quest-input{
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        border: none;
        margin: 14px 0;
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 16px;
        padding: 12px 8px;
        &::placeholder{
            color: rgba(0, 0, 0, 0.5);
            padding: 12px 8px;
        }
    }
}
.timestamp{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    .time-container{
        height: 100%;
    }
    .time-input{
        height: 100%;
        min-height: 32px;
        width: 60px;
        background: #FFFFFF;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        text-align: center;
        border: none;
        font-weight: 400;
        font-size: 16px;
        margin: 0 6px;
    }
}
.answers-container{
    display: flex;
    flex-direction: column;
    .answer{
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-items: center;
        margin: 6px 0;
        .answer-input{
            flex-grow: 1;
            height: 100%;
            min-height: 40px;
            border: none;
            background: #FFFFFF;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            padding: 8px 12px;
            font-weight: 400;
            font-size: 16px;
            &::placeholder{
                padding: 8px 12px;
                font-weight: 400;
                font-size: 16px;
            }
        }
        .delete{
            position: absolute;
            right: 14px;
            cursor: pointer;
            display: flex;
        }
    }
    .answer-bubble{
        user-select: none;
        height: 32px;
        width: 32px;
        border: 1px solid #7D7D7D;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 4px;
        cursor: pointer;
        transition: 0.25s;
        &:hover{
            background-color: #dfdfdf;
        }
        &.correct{
            background: #F3DE18;
            &:hover{
                background-color: #e2ce16;
            }
        }
    }
    .new-answer-blank{
        display: flex;
        position: relative;
        .plus{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            align-items: center;
            margin: 4px;
            transition: 0.25s;
            background: #EFEFEF;
            border: 1px solid #7D7D7D;
            &:hover{
                background: #dfdfdf;
            }
        }
        .gray-rectangle{
            display: flex;
            flex-grow: 1;
            height: 100%;
            min-height: 40px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 13.26%);
            border-radius: 6px;
        }
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.half{
    .readOnly{
        position: absolute;
        top: 10%;
        left: -75%;
        width: 150%;
        height: 125%;
        .question-container{
            width: 75%;
            margin-bottom: 64px;
        }
    }
    @media(max-width: 900px){
        .readOnly{
            max-height: 80%;
        }
    }
    @media(max-width:500px){
        .readOnly{
            .question-container{
            width: 100%;
        }
        }
    }
}
.readOnly{
    margin: auto;
    z-index: 100;
    width: 85%;
    max-height: 85%;
    background: #F9F9F9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 36px;
    position: relative;
    .bottom-row{
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0px 36px;
        padding-bottom: 32px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        &.fEnd{
            justify-content: flex-end;
        }
        @media(max-width: 500px){
            flex-direction: column;
            padding-top: 32px;
            text-align: center;
        }
        @media(max-width:900px){
            padding-bottom: 16px;
        }
        span{
            font-size: 20px;
            font-weight: 700;
            @media(max-width:500px){
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
        .green{
            color: #49B357;
        }
        .red{
            color: #D45454;
        }
        .orange{
            color: #DFB10D;
        }

    }
    .check-btn{
        background: #F3DE18;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 30px;
        height: 36px;
        font-size: 16px;
        padding: 6px 32px;
        user-select: none;
        cursor: pointer;
        transition: 0.25s;
        &.inactive{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .timer{
        position: absolute;
        right: 16px;
        top: 12px;
        padding: 3px 2px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 16px;
        border-radius: 50%;
        background: #E2E2E2;
        font-weight: 600;
        user-select: none;
    }
    .question-container{
        margin-top: 28px;
        width: 82%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 62%;
        overflow-y: auto;
        @media(max-width:769px){
            width: 100%;
            max-height: 60%;
        }
        @media(max-width:386px){
            max-height: 50%;
        }
    }
    .question{
        padding: 16px 16px;
        font-size: 20px;
        width: 80%;
        padding-left: 0;
    }
    .answer-btn{
        display: flex;
        text-align: center;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 4px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #7D7D7D;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 20px;
        color: black;
        transition: 0.25s;
        user-select: none;
        &.active{
            background: #E2E2E2;
            border: 1px solid #000000;
            .letter{
                background-color: #333333;
                color: white;
            }
            &:hover{
                background: #E2E2E2;
                border: 1px solid #000000;
            }
        }
        &.answeredCorrect{
            background: #6ACD77;
            border: none;
            .letter{
                background-color: #333333;
                color: white;
            }
            &:hover{
                background: #6ACD77;
                border: none;
            }
        }
        &.answeredIncorrect{
            background: #FF7F7F;
            border: none;
            .letter{
                background-color: #333333;
                color: white;
            }
            &:hover{
                background: #FF7F7F;
                border: none;
            }
        }
        &.shouldBeAnswered{
            border: 2px solid #49B357;
            &:hover{
                cursor: default;
                border: 2px solid #49B357;
                background: #FFFFFF;
            }
        }
        &.unclickable{
            background: #FFFFFF;
            &:hover{
                cursor: default;
                background: #FFFFFF;
            }
        }
        .letter{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background: #EFEFEF;
            margin-right: 12px;
            padding: 4px 10px;
            color: rgba(0, 0, 0, 0.7);
            border-radius: 50%;
            font-weight: 600;
            font-size: 18px;
        }
        &:hover{
            background-color:#e2e2e2;
            cursor: pointer;
            color: black;
        }
    }
}

.readOnlyComponent{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.answer-colors{
    position: absolute;
    right: 16px;
}
@media(max-width:450px){
    .response-text{
        font-size: 12px;
    }
}
</style>