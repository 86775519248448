<template>
  <div> 
      <div class="stats-filter-container">
        <div class="filter-col mr-1">
            <h2>{{toLocal('tests.groups') }}</h2>
            <div class="filter-row grid grid-3">
                <div class="group" @click="$set($store.state, 'testUserStatsFilter', 0)"  :class="{'active': $store.state.testUserStatsFilter==0}">
                    {{toLocal('tests.all_groups')}}
                </div>
                <div 
                    class="group" 
                    :class="{'active': $store.state.testUserStatsFilter==group.id}"
                    v-for="group in $store.state.testUserStats.groups" 
                    :key="group.id" 
                    @click="$store.commit('setTestUserStatsFilter', group.id)"
                >
                    {{group.name}}
                </div>
            </div>
        </div>
        <div class="filter-col right">
            <div class="avg-score-container">
                <h2>{{toLocal('tests.average_score')}}</h2>
                <div class="score-round">
                     {{avgScore}} 
                </div>
                 
               <h2 v-if="test.options.testResultShowType == 0" class="ml-1">%</h2>
            </div>
            <div class="filter-row margin-center result-showing">
                <div class="filter-col">
                    <h3>
                        {{toLocal('tests.result_showing')}}
                    </h3>
                    <div class="selectable" @click="$set($store.state, 'testUserStatsShowAll', 0)" :class="{active: $store.state.testUserStatsShowAll == 0}">{{toLocal('tests.show_final_attempt')}}</div>
                    <div class="selectable" @click="$set($store.state, 'testUserStatsShowAll', 1)" :class="{active: $store.state.testUserStatsShowAll == 1}">{{toLocal('tests.show_all_attempts')}}</div>
                </div>
            </div>

            
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'TestStatisticsTab',
    data(){
        return{
            selectedGroup: 0,
            selectedShowingOption: 0
        }
    },
    props:{
        topic: Object,
        availableTestQuestions: Array,
        test: Object,
    },
    watch:{
        selectedGroup:{
            handler(newVal){
                this.$emit('changeGroup', newVal)
            }
        },
    },
    computed:{
        avgScore(){
            const stat_reducer = (a, b) => a + b;
            if(this.selectedGroup === 0){
                let users =this.$store.state.testUserStats.groups.map(group => group.users).flat();
                if(users.length == 0 && this.$store.state.testUserStats.users.length > 0)
                    users = this.$store.state.testUserStats.users;
                let user_stats = users.map(user => user.stats).flat();
                let user_stats_result = user_stats.map(stat => this.getUserStatResult(stat.result));
                if(user_stats_result && user_stats_result.length > 0)
                    return parseInt(Math.round(user_stats_result.reduce(stat_reducer)/user_stats_result.length* 100, 1) / 100);
                return  0;

            }
            else{
                let users = this.$store.state.testUserStats.groups.filter(group => group.id == this.selectedGroup).map(group => group.users).flat();
                let user_stats = users.map(user => user.stats).flat();
                let user_stats_result = user_stats.map(stat => this.getUserStatResult(stat.result));
                return parseInt(Math.round(user_stats_result.reduce(stat_reducer)/user_stats_result.length* 100, 1) / 100);
            }
        },
    },
    mounted(){
    
    },
    methods:{
       
         getUserStatResult(result){
            if(this.test.options.testResultShowType ==1)
                return parseInt(Math.round(result * this.test.options.testMaxScore,2));
            else return parseInt(Math.round(result*100,1));
        },
    }
}
</script>

<style lang="scss" scoped>
.stats-filter-container{
    display: grid;
    grid-template-columns: 2.185fr 1fr;
    .filter-col{
        display: flex;
        flex-direction: column;
        .filter-row{
            width: 100%;
        }
        &.right{
            padding: 0 1rem;
            border-left: 2px solid var(--primary-color);
        }
    }
    .group{
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        border-radius: 20px;
        min-width: 100%;
        width: 6rem;
        border: 1px solid var(--primary-color);
        padding:0 1rem;
        transition: all 0.3s;
        cursor:pointer;
        &.active{
            border: none;
            background-color: var(--secondary-color);
        }
    }
}
.avg-score-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    .score-round{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;
        background: white;
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        margin-left: 0.5rem;
        height: 2rem;
        width: 2rem;
        font-size: clamp(12px, 1vw, 18px);
        font-weight: 700;
        color: var(--primary-color);
    }
    h2{
        margin-bottom: 0;
        font-weight: 700;
        font-size: clamp(16px, 1.3vw, 18px);
        color: var(--primary-color);
    }
}
.result-showing{
    display: flex;
    flex-direction: column;
    h3{
        margin: 0;
        font-weight: 300;
        margin-bottom: 0.25rem;
        font-size: clamp(16px, 1.1vw, 17px);
        color: var(--primary-color);

    }
    .selectable{
        border-radius: 1rem;
        border: 1px solid var(--primary-color);
        background-color: transparent;
        font-size: clamp(14px, 0.8vw, 16px);
        padding:0.125rem 0.5rem;
        text-align: center;
        margin: 0.25rem 0;
        color: var(--primary-color);
        cursor: pointer;
        white-space: nowrap;
        transition: background-color 0.3s;
        &.active{
            background-color: var(--secondary-color);
            border: none;
            font-weight: 900;
        }
    }

}
h2{
    margin: 0 0 1rem 0;
    font-size: clamp(20px, 1.5vw, 26px);
}
.grid{
    display: grid;
}
.grid-3{
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}
.mr-1{
    margin-right: 1rem;
}
.ml-1{
    margin-left: 0.25rem;
}
@media(max-width:1280px){
    .stats-filter-container{
        h2{
            font-size: 16px;
            margin-bottom: 8px;
        }
        .group{
        font-size: 12px;
    }
    }
    .avg-score-container{
        display: flex;
        justify-content: start;
        align-items: center;
        h2{
            margin: 0 2px;
            &:first-child{
                margin-left: 0;
            }
        }
        .score-round{
            height: 1.5rem;
            width: 1.5rem;
        }
    }
    .filter-row{
        h3{
            font-size: 14px;
        }
    }
}
</style>