<template>
    <div class="detail-container">
        <!-- <div class="label-container">
            <div class="label left"> Klausimas:</div>
            <div class="label right"> Atsakyta:</div>
        </div> -->
        <div class="answer-container comment-container" v-if="comment && comment.length > 0" >
            <div class="answer-left">
            </div>
            <div class="answer-center comment">
                <div class="answer-text question">
                    {{ toLocal('training.users_comment') }}:
                </div>
                <div class="answer-content">
                    <div class="text-answer-container">
                        {{ comment }}
                    </div>
                </div>
            </div>
        </div>
        <div class="answer-container" v-for="(question, question_index) in stat" :key="question_index">
            <div class="answer-left">
                {{parseInt(question_index)+1}}.
            </div>
            <div class="answer-center">
                <div class="answer-text question">
                    <editor
                        class="question-text"
                        :readOnly="true"
                        v-model="question.text"
                    />
                </div>
                <div class="answer-content">
                    <div class="answer-type" v-if="question.type == 1">
                        <div 
                            class="answer type-1" 
                            v-for="(answer, answer_index) in question.answers" 
                            :key="answer_index"
                            :class="{correct: answer.correct, incorrect: !answer.correct && answer.selected}"
                        >
                            <div class="answer-letter" :class="{active: answer.selected}">{{String.fromCharCode(65+answer_index)}}</div>
                            <div class="answer-text">
                                {{answer.text}}
                            </div>
                        </div>
                    </div>
                    <div class="answer-type type-img" v-if="question.type == 2">
                       <div 
                            class="answer type-2" 
                            v-for="(answer, answer_index) in question.answers" 
                            :key="answer_index"
                            :class="{correct: answer.correct, incorrect: !answer.correct, big: question.size == 1}"
                        >
                            <div class="answer-letter" :class="{active: answer.selected}">{{String.fromCharCode(65+answer_index)}}</div>
                            <div class="answer-image">
                                <ImageViewer
                                    v-if="(answer.metadata ? answer.metadata.data : false)"
                                    :src="answer.image != null ? $store.state.api_uploads_link+answer.image : ''"
                                    :height="question != undefined ? question.size == 1 ? 416.25 : 200 : undefined"
                                    :width="question != undefined ? question.size == 1 ? 740 : 200 : undefined"
                                    :metadata="answer.text"
                                    :size="question.size"
                                />
                                 <NewImageEditor 
                                    v-if="(answer.metadata ? !answer.metadata.data : true)"
                                    :readOnly="true"
                                    :item="answer"
                                    type="cover"
                                    :height="question.size == 1 ? 243 : 200"
                                    :width="question.size == 1 ? 420 : 200"
                                    :isEditing="false"
                                    :src="getFileUrl(answer.image)"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="answer-type " v-if="question.type == 3">
                        <div 
                            class="answer type-3" 
                            v-for="(answer, answer_index) in question.answers" 
                            :key="answer_index"
                            :class="{correct: answer.correct && answer.selected, incorrect: !answer.correct && answer.selected}"
                            >
                            <div class="part-1">{{answer.part1}}</div>
                            <div class="part-1">{{answer.part2}}</div>
                        </div>
                    </div>
                    <div class="answer-type" v-if="question.type == 4">
                        <div class="answer type-4" v-for="(answer, answer_index) in question.answers" :key="answer_index">
                            <div class="text-answer-container" v-html="$sanitize(answer.answer_text)"></div>
                            <!-- <div class="part-1">{{answer.part2}}</div> -->
                        </div>
                    </div>
                    <div class="answer-type" v-if="question.type == 5">
                        <div class="answer type-5"  v-if="question.answers && question.answers[0]">
                            <div class="tries-container">
                                <div class="tries" v-if="question.answers[0].clicks">
                                    <span>Bandymai:</span>
                                    <span>{{question.answers[0].clicks.length}}</span>
                                </div>
                            </div>
                            <ClickTask
                            :question="question"
                            :answer="question.answers[0]"
                            :readOnly="true"
                            :statsMode="true"
                            :clicks="question.answers[0].clicks"
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div class="answer-right">
                <div class="answer-state" :class="{'correct': question.isCorrect}"> {{getQuestionStatusLabel(question)}}</div>
                <div class="state-icon" :class="{'correct': question.isCorrect || question.type == 5, 'partial':!question.isCorrect && question.set_score > 0}"> 
                    <svg v-if="question.isCorrect || question.type == 5" xmlns="http://www.w3.org/2000/svg" width="9.961" height="9.296" viewBox="0 0 9.961 9.296">
                        <path id="Path_5921" data-name="Path 5921" d="M-379.365-14993.614l2.942,4.038,4.932-7.506" transform="translate(380.413 14998.121)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </svg>
                    <svg v-else-if="test.options.testPartiallySolvable == 1 && !question.isCorrect && question.set_score > 0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.252" height="12.257" viewBox="0 0 12.252 12.257">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_2253" data-name="Rectangle 2253" width="12.257" height="12.252" fill="#28345c"/>
                            </clipPath>
                        </defs>
                        <g id="Group_7292" data-name="Group 7292" transform="translate(12.252) rotate(90)" >
                            <path id="Path_6020" data-name="Path 6020" d="M12.246,11.7l-.639-2.85V8.828a1.017,1.017,0,0,0-.3-.72L3.5.3A1.032,1.032,0,0,0,2.043.3L.3,2.038A1.032,1.032,0,0,0,.3,3.5l7.811,7.811a1,1,0,0,0,.733.291l2.863.643a.486.486,0,0,0,.195,0,.45.45,0,0,0,.284-.2.46.46,0,0,0,.07-.243.378.378,0,0,0-.011-.1M.755,3.045a.391.391,0,0,1,0-.553L2.5.755A.382.382,0,0,1,2.771.639a.391.391,0,0,1,.278.116l.752.752L1.509,3.8Zm7.6,7.6L1.962,4.252,4.254,1.96l6.393,6.393Zm1.938.621-1.35-.3,2.028-2.028.324,1.45Z" transform="translate(0)" fill="#28345c"/>
                            <path id="Path_6021" data-name="Path 6021" d="M5.508,5.188a.313.313,0,0,1-.094.225.321.321,0,0,1-.453,0L.1.546A.317.317,0,0,1,.1.094a.321.321,0,0,1,.453,0L5.414,4.96a.318.318,0,0,1,.094.227" transform="translate(3.562 3.559)" fill="#28345c"/>
                        </g>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="18.408" height="18.408" viewBox="0 0 18.408 18.408">
                        <g id="Group_7020" data-name="Group 7020" transform="translate(9.206) rotate(45)">
                            <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                            <path id="Path_751" data-name="Path 751" d="M13.014,6.512a.7.7,0,0,1-.207.5.686.686,0,0,1-.5.212H7.217v5.092a.715.715,0,0,1-.71.7.7.7,0,0,1-.5-.207.686.686,0,0,1-.212-.5V7.222H.7A.711.711,0,0,1,.2,6.014.739.739,0,0,1,.7,5.8H5.8V.715A.692.692,0,0,1,6.009.207.7.7,0,0,1,6.507,0a.717.717,0,0,1,.71.715V5.8h5.092A.719.719,0,0,1,13.014,6.512Z" transform="translate(0)" fill="#28345c"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ImageViewer from '@/components/layout/trainings/training_components/ImageViewer.vue';
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import ClickTask from "@/components/layout/trainings/training_components/ClickTask.vue";
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";

    export default {
        name: 'UserTestDetails',
        props:{
            user_start_stat:{
                type: Object,
                required: true
            },
            user_end_stat:{
                type: Object,
                required: true
            },
            comment: {
                type: String,
                required: false
            }
    
        },
        components:{
            Editor,
            ImageViewer,
            ClickTask,
            NewImageEditor
        },
        data(){
            return{
                stat: {},
                test: {}
            }
        },
        mounted(){
            this.getStats();
        },
        methods:{
            getStats(){
                axios.post(
                    `${this.$store.state.api_link}/training/stats/user/detailed2`,
                    {start_stat: this.user_start_stat, end_stat: this.user_end_stat},

                    this.$store.getters.axiosConfig()
                    ).then((response) => {
                        this.stat= response.data.stat;
                        this.test = response.data.test;
                
                    
                    })
                    .catch((err) => {
                        console.log(err);
                    console.log("Server error while getting user statistics");
                    });
            },
            getQuestionStatusLabel(question){
                if(question.isCorrect || question.type == 5){
                    return this.toLocal('training.task_correct');
                }
                else if(this.test.options.testPartiallySolvable == 1){
                    if(question.set_score != 0){
                        return this.toLocal('generic.partially');
                    }
                    else{
                        return this.toLocal('training.task_incorrect');
                    }
                }
                else return this.toLocal('training.task_incorrect');
            }
        }
        
    }
</script>

<style lang="scss" scoped>
.detail-container{
            padding: 1rem;
            display: grid;
            grid-column: 1/-1;
            .label-container, .answer-container{
                display: grid;
                max-width: 34rem;
                grid-template-columns: minmax(1rem, 2rem) 8fr 2fr;
                .left{
                    text-align: left;
                }
                &.comment-container{
                    grid-template-columns: minmax(1rem, 2rem) 10fr ;
                    .text-answer-container{
                        max-width: 100%;
                    }
                }
            }
            .label-container{
                grid-area: 
                    ". label label";
                padding: 0 0.5rem;
                //grid-column-start: 2;
                .label.left{
                  grid-column-start: 2;
                }
            }
       
            .answer-container{
                background: white;
                border-radius: 1rem;
                padding: 0.5rem;
                padding-right: 0;
                .answer-center{
                    display: grid;
                    grid-area: 
                        "text"
                        "content";
                    text-align: left;
                    font-size: clamp(14px, 1vw, 24px);
                    grid-template-rows: auto auto;
                    padding-right: 1rem;
                    border-right: 2px solid var(--primary-color);
                    &.comment{
                        border-right: 0px;
                    }
                    .answer-type{
                        // display: flex;

                        .answer{

                        }
                        .answer-letter{
                            background: white;
                            border-radius: 50%;
                            padding: 0.25rem;
                            width: 1.5rem;
                            height: 1.5rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            &.active{
                                background: #A9B9EE;
                            }  
                        }
                        .type-1{
                            display: grid;
                            align-items: center;
                            grid-template-columns: 1fr 9fr;
                            background: #efefef;
                            margin: 0.5rem 0;
                            padding: 0.5rem 0.5rem;
                            border-radius: 1rem;
                            .answer-text{
                                display: flex;
                                align-items: center;
                                margin-left: 0rem;
                                max-width: 21rem;
                                width: 100%;
                                font-size: 16px;
                                overflow: auto;
                            }
                             &.correct{
                                border:2px solid #6ACD77;
                            }
                            &.incorrect{
                                border:2px solid #F47979
                            }
                        }
                        .type-2{
                            position: relative;
                            max-width: 45%;
                            // width: 45%;
                            margin: 1rem 0.25rem;
                            border-radius: 1rem;
                            width: 200px;
                            height: 200px;
                            .answer-letter{
                                position: absolute;
                                left: 0.5rem;
                                top:0.5rem;
                                z-index: 2;
                            }
                            .answer-image{
                                width: 100%;
                                height: 100%;
                                max-width: 10rem;
                                max-height: 10rem;
                                background: #efefef;
                                border-radius: 1rem;
                                overflow: hidden;
                                object-fit: contain;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                }
                            }
                            &.big{
                                width: 420px;
                                max-width: 420px;
                                max-height: 233px;
                                height: 233px;
                                overflow: hidden;
                                .answer-image{
                                    max-width: 420px;
                                    max-height: 231px;
                                    height: 231px;
                                }
                            }
                            &.correct{
                                border:2px solid #6ACD77;
                            }
                            &.incorrect{
                                border:2px solid #F47979
                            }
                        }
                        &.type-img{
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .type-3{
                            display: flex;
                            margin: 1rem 0 ;
                            background: #efefef;
                            border-radius: 0.5rem;
                            word-break: break-all;
                            .part-1, .part-2{
                                display: flex;
                                padding: 1rem 0.75rem;
                                width: 100%;
                                justify-content: center;
                                align-items: center;
                                font-size: 16px;
                            }
                            &.correct{
                                border:2px solid #6ACD77;
                            }
                            &.incorrect{
                                border:2px solid #F47979
                            }
                        }
                        
                        .type-4{
                              margin-bottom: 1rem;
                        }
                    }
                    .answer-text{
                        &.question{
                            margin-bottom: 0.5rem;
                        }
                    }
                }
                .answer-right{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .answer-state{
                        font-weight: 700;
                        font-size: 18px;
                        color: var(--primary-color);
                    }
                    .state-icon{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: #FF6969;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        &.correct{
                            background: #45C34A;
                            padding: 0.4rem;
                        }
                        &.partial{
                            background: #F2DB7D;
                            padding: 0.4rem;
                        }
                        svg{
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            gap: 1rem;
            //background: red;
            //height: 1000px;
        }
    .question-text{
        width: 100%;
        max-width: 21rem;
        div{
            max-width: 100%;
        }
    }
    .text-answer-container{
        word-wrap: break-word;
        max-width: 474px;
        *{
            word-wrap: break-word;
        }
    }
</style>