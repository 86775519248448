<template>
    <transition name="fade">
      <div class="forgot--password--wrapper" v-click-outside-2="hideForgotPassword" v-if="forgot_password">
        <div class="input--block">
          <form v-if="!SMSReset" @submit="resetFormSubmit">
            <div class="tabs">
              <label
                for="forgot_password"
                v-if="$store.state.system_settings.passwordResetTabs && $store.state.system_settings.passwordResetTabs.email"
                class="col-md-4 col-form-label text-md-right"
                :class="{'selected': selectedTab == 0}"
                @click="selectedTab = 0"
              >{{ toLocal("login.email_address") }}</label>
            <label
                for="forgot_password"
                v-if="$store.state.system_settings.passwordResetTabs && $store.state.system_settings.passwordResetTabs.phone"
                class="col-md-4 col-form-label text-md-right"
                :class="{'selected': selectedTab == 1}"
                @click="selectedTab = 1"
              >{{toLocal('generic.phone')}}</label>
            </div>
            <div class="form-group row" :class="{'only-tab': !Object.values($store.state.system_settings.passwordResetTabs).every(item => item)}">
              <div class="col-md-6">
                <input :class="{'selected-left': selectedTab == 0}" v-if="selectedTab==0" id="forgot_password" type="email" class="form-control" v-model="forgot_password_email">
                <input maxlength="14" placeholder="+370 XXX XXXXX" v-mask="$store.state.system_settings.phoneMask" :class="{'selected-right': selectedTab == 1, 'no-tl-radius': !$store.state.system_settings.passwordResetTabs.email}" v-else id="forgot_password_2" type="text" class="form-control" v-model="forgot_password_number">

              </div>
            </div>
            <br>
            <div class="form-group row">
              <button
                type="submit"
                class="btn btn-primary"
              >
                {{ toLocal("login.restore_button") }}
              </button>
            </div>
            <strong
              v-for="notification in forgot_password_notification"
              :key="notification"
            >
              {{ toLocal(notification) }} 
            </strong>
          </form>
          <div v-else class="SMS">
            <SMSWizard @goBack="SMSReset = false" :resetPasswordForm="resetPasswordForm" :token="sms_token" @resetPasswordFormState="resetPasswordForm = $event" @setToken="sms_token = $event" @goToStart="SMSReset = false; hideForgotPassword()"/>
          </div>
          
        </div>
        <div class="lock--block">
          <svg xmlns="http://www.w3.org/2000/svg" width="210.386" height="222.214" viewBox="0 0 210.386 222.214">
              <g id="Artwork_2" data-name="Artwork 2" transform="translate(105.193 111.107) rotate(30)">
                <g id="Group_4" data-name="Group 4" transform="translate(-71.093 -87.25)">
                  <path id="Path_41" data-name="Path 41" d="M122.8,64.953v-18.1C122.8,21,99.53,0,71.093,0s-51.7,21-51.7,46.856v18.1A23.267,23.267,0,0,0,0,87.9v63.337A23.267,23.267,0,0,0,23.267,174.5h95.652a23.267,23.267,0,0,0,23.267-23.267V87.9a23.267,23.267,0,0,0-19.391-22.944Zm-96.944-18.1c0-22.3,20.358-40.394,45.241-40.394s45.241,18.1,45.241,40.394V64.63H25.852Zm109.87,104.377a16.8,16.8,0,0,1-16.8,16.8H23.267a16.8,16.8,0,0,1-16.8-16.8V87.9a16.8,16.8,0,0,1,16.8-16.8h95.652a16.8,16.8,0,0,1,16.8,16.8Z"/>
                  <path id="Path_42" data-name="Path 42" d="M30.926,28A12.926,12.926,0,0,0,18,40.926V60.315a12.926,12.926,0,0,0,25.852,0V40.926A12.926,12.926,0,0,0,30.926,28Zm6.463,32.315a6.463,6.463,0,0,1-12.926,0V40.926a6.463,6.463,0,1,1,12.926,0Z" transform="translate(40.167 62.481)"/>
                </g>
              </g>
            </svg>

        </div>
      </div>
    </transition>
</template>

<script>
import axios from 'axios';
import SMSWizard from './SMSWizard.vue';
export default {
  components:{
    SMSWizard
  },
    props:{
        forgot_password:{
            type: Boolean
        }
    },
    data: function(){
        return{
            forgot_password_email: null,
            forgot_password_number: '',
            forgot_password_notification: [],
            selectedTab: 0,
            SMSReset: false,
            resetPasswordForm: false,
            sms_token: '',
        }
    },
    watch:{
      '$store.state.system_settings.passwordResetTabs.email': function(){
        if(!this.$store.state.system_settings.passwordResetTabs.email && this.$store.state.system_settings.passwordResetTabs.phone){
          this.selectedTab = 1;
        }
      },
    },
    mounted(){
       if(this.$store.state.system_settings.passwordResetTabs && !this.$store.state.system_settings.passwordResetTabs.email && this.$store.state.system_settings.passwordResetTabs.phone){
          this.selectedTab = 1;
        }
    },
    methods:{
        hideForgotPassword: function(event) {
          this.$emit('setForgotPasswordState', false);
        },
      //   resetFormSubmit: function(e) {
      //     this.$store.state.loading.loadingStart();
      //     this.forgot_password_notification = [];
      //     e.preventDefault();
      //     let vm = this;
      //     grecaptcha.ready(function() {
      //       grecaptcha.execute(process.env.VUE_APP_GOOGLE_CAPTCHA_KEY, {action: 'submit'}).then(function(token) {
      //           // Add your logic to submit to your backend server here.
      //           let body = {};
      //           if(vm.selectedTab == 0){
      //               body = {
      //                   email: vm.forgot_password_email,
      //               };
      //           }
      //           else{
      //               body = {
      //                   phone: vm.forgot_password_number.replaceAll(' ', ''),
      //               };
      //           }
      //           body.token = token;
      //           let url = vm.selectedTab == 0 ? `${vm.$store.state.api_link}/forgot/password` : `${vm.$store.state.api_link}/forgot/password/sms`;
      //         axios.post(url, body)
      //           .then((res) => {
      //               vm.forgot_password_notification = [res.data.message];
      //               vm.$store.state.loading.loadingDone();
      //               if(vm.selectedTab == 1 && res.data.status == 'success'){
      //                 vm.SMSReset = true;
      //               }
      //           })
      //           .catch((err) => {
      //             console.log(err);
      //             vm.$store.state.loading.loadingDone();
      //           });
      //       });
      //     });
      // },
         resetFormSubmit: function(e) {
          this.$store.state.loading.loadingStart();
          this.forgot_password_notification = [];
          e.preventDefault();
          let vm = this;
          let body = {};
          if(vm.selectedTab == 0){
              body = {
                  email: vm.forgot_password_email,
              };
          }
          else{
              body = {
                  phone: vm.forgot_password_number.replaceAll(' ', ''),
              };
          }
          let url = vm.selectedTab == 0 ? `${vm.$store.state.api_link}/forgot/password` : `${vm.$store.state.api_link}/forgot/password/sms`;
          axios.post(url, body)
            .then((res) => {
                vm.forgot_password_notification = [res.data.message];
                vm.$store.state.loading.loadingDone();
                if(vm.selectedTab == 1 && res.data.status == 'success'){
                  vm.SMSReset = true;
                }
            })
            .catch((err) => {
              console.log(err);
              vm.$store.state.loading.loadingDone();
            });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_forms.scss";

.forgot--password--wrapper {
  display: block;
  position: fixed;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  padding: 50px;
  border-radius: 15px;
  width: 710px;

  .input--block {
    width: 100%;
    max-width: 360px;
    float: left;
  }

  .lock--block {
    float: left;
    padding-left: 30px;
    svg{
      path{
        fill: var(--primary-color);
      }
    }
  }
.tabs{
    display: flex;
    padding: 0rem 0;
    label{
        display: flex;
        width: 100%;
        justify-content: center;
        border-radius: 0.5rem;
        font-size: clamp(14px, 5vw, 18px);
        border:4px solid #efefef;
        cursor: pointer;
        &.selected{
            
            border:4px solid white;
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

    }

}
.only-tab{
  input{
    border-top-right-radius: 0;
  }
}
input{
  &.selected-right{
      border-top-right-radius: 0;
      box-shadow: none;
    }
    &.selected-left{
        border-top-left-radius: 0;
        box-shadow: none;
    }
    &.no-tl-radius{
        border-top-left-radius: 0;
    }
}
  @media (max-width: 768px) {
    position: absolute;
    top: 20%;
    left: 2%;
    width: 96%;
    transform: none;
    padding: 1rem;
    border: 1px solid var(--primary-color-opacity-50);

    .input--block {
      max-width: none;
    }
    .lock--block {
      display: none;
      width: 100%;
      text-align: center;
    }
    .btn-primary{
      width: 100%;
      font-size: clamp(14px, 5vw, 18px);
    }
  }
}
</style>