<template>
    <div class="toast-container" v-show="showToast">
        <transition-group name="toast">
            <div class="toast" v-for="(toast, toast_id) in toastList" :key="toast.id">
                <img v-if="toast.img" :src="toast.img" alt="">
                <div class="text-container">
                    <span class="title" v-if="toast.title">{{ toast.title }}</span>
                    <span class="message" v-if="toast.message">{{ toast.message }}</span>
                </div>
                <div class="close-toast" @click="close(toast_id)">
                    <span></span><span></span>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'Toast',
    data(){
        return{
            showToast: false,
            toastList: [],
        }
    },
    methods:{
        showPopup(notification, time){
            var temp = notification;
            temp.id = uuidv4();
            this.toastList.push(temp);
            setTimeout(()=>{
                if(this.toastList.length> 0) this.toastList.shift();
            },time);
            this.showToast = true;
        },
        close(index){
            this.toastList.splice(index, 1);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_transitions.scss";
.toast-container{
    display: flex;
    position: fixed;
    flex-direction: column;
    bottom: 2rem;
    right: 2rem;
    z-index: 99;
    .toast{
        display: flex;
        position: relative;
        align-items: center;
        overflow: hidden;
        box-shadow: 1px 1px 8px -1px #b3b3b3;
        margin: 10px 0;
        background: white;
        color: var(--primary-color);
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s;
        .close-toast{
            position: absolute;
            right: 5px;
            top: 5px;
            height: 15px;
            width: 15px;
            cursor: pointer;
            transition: transform 0.3s;
            span{
                background: var(--primary-color);
                height: 100%;
                width: 2px;
                border-radius: 10px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                transform: rotate(45deg);
            }
            span:nth-child(2){
                transform: rotate(-45deg);
            }
            &:hover{
                transform: scale(1.1);
            }
        }
        img{
            display: flex;
            flex:2;
            height: 100%;
            max-width: 100px;
        }
        .text-container{
            flex:5;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 10px 15px 10px 10px;
            .title{
                font-weight: 700;
            }
        }
    }
}
</style>