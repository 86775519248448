<template>
  <div class="test-version-controller" :class="{'test-select-filter': $store.state.selectTestsModeActive && scrolledTopic == topicIndex}">
    <div class="select-container">
        <TestDropdown 
            v-if="parentTest && parentTest.options && Array.isArray(parentTest.versions)"
            :title="parentTest.name"
            :expandedTitle="toLocal('test.select_version')"
            :test="test"
            :dropDownList="[parentTest, ...parentTest.versions]"
            :selectedItemID="selectedVersion"
            :selectedFilter="0"
            :parentTest="parentTest"
            :topic="topic"
            :type="'version'"
            class="version-dropdown"
            :class="{'active': parentTest.options.active_version_id == 0 ? test.id == parentTest.id && topic.questions == parentTest.id : parentTest.options.active_version_id == test.id && topic.questions == parentTest.id}"
            @selectedItem="$emit('selectedVersion', $event); selectedVersion = $event"
            @flattenedList="dropdownFlattenedList = $event"
            @deleteVersion="deleteVersion($event)"
        />
    </div>
    <div class="btn" @click="createNewVersion">
        <div>{{toLocal('tests.create_copy')}}</div>
        <div class="icon-container" :class="{loading: addingLoading}">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.627" height="16.628" viewBox="0 0 15.627 16.628">
                <path id="Path_5899" data-name="Path 5899" d="M34.615,14.061H31.022v6.4H25.005v3.823h6.017v6.4h3.593v-6.4h6.017V20.463H34.615Z" transform="translate(-25.005 -14.061)" fill="#28345c"/>
            </svg>
        </div>
    </div>
    <div 
        v-if="parentTest && parentTest.options"
        class="btn activate" 
        :class="{
        'active': parentTest.options.active_version_id == 0 ? test.id == parentTest.id && topic.questions == parentTest.id : parentTest.options.active_version_id == test.id && topic.questions == parentTest.id,
        'disabled': test.type == 1 && test.options.tests && test.options.tests.length == 0
        }"
        v-tooltip="{
            content: test.type == 1 && test.options.tests && test.options.tests.length == 0 ? toLocal('tests.add_test_questions') : '',
            classes: 'tooltip-container',
            }
        "
        @click="activateVersion"
    >
        <svg class="check" v-if="parentTest.options.active_version_id == 0 ? test.id == parentTest.id && topic.questions == parentTest.id : parentTest.options.active_version_id == test.id && topic.questions == parentTest.id" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
        <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
        </svg>
        <span>
            {{toLocal('tests.publish_test')}}
        </span>
    </div>
  </div>
</template>

<script>
import TestDropdown from './TestDropdown.vue';
import axios from 'axios';
export default {
    name: 'TestVersion',
    components:{
        TestDropdown
    },
    data(){
        return{
            selectedVersion: 0,
            parentPropLoaded: false,
            addingLoading: false,
            dropdownFlattenedList: [],
            parentIndexArray: [],
        }
    },
    props:{
        test:{
            type: Object,
            default: () => {},
        },
        parentTest:{
            type: Object,
            default: () => {},
        },
        topic:{
            type: Object,
            default: () => {},
        },
        topicIndex: Number,
        scrolledTopic: Number,

    },
    watch:{
        parentTest:{
            immediate: true,
            handler(){
                if(!this.parentPropLoaded){
                    if(this.parentTest && this.parentTest.options && this.parentTest.options.active_version_id && this.selectedVersion == 0){
                        this.selectedVersion = this.parentTest.options.active_version_id;
                        this.parentPropLoaded=true;
                    }
                }
   
            }
        },
       
    },
    mounted(){
    },
    methods:{
        createNewVersion(){
            if(this.selectedVersion != undefined)
                this.appendToParent(this.parentTest, this.selectedVersion);
            else this.appendToParent(this.parentTest,  this.parentTest.options.active_version_id);

        },
        newVersionRequest(id, parent_id){
            // this.addingLoading = true;
            return axios.post( 
                `${this.$store.state.api_link}/test-question-groups/create/version`,
                {
                    test_id: id,
                    parent_id: parent_id
                },
                this.$store.getters.axiosConfig()
            );
        },
        activateVersion(){
            this.$emit('activateVersion', this.test.id);
        },
        appendToParent(parentTest, selectedID){
            let first_version = parentTest && parentTest.versions && parentTest.versions.length > 0 ? parentTest.versions[0].id : parentTest.id;
            if(parentTest.versions && parentTest.versions.length > 0 && parentTest.id != selectedID){
                for(var i = 0; i < parentTest.versions.length; i++){
                    let version = parentTest.versions[i];
                    let version_index = i;
                    if(version.id == selectedID){
                        this.addingLoading = true;
                        let id = version.id;
                        let parent_id = null
                        if(selectedID == this.dropdownFlattenedList[0].id){
                            parent_id = parentTest.id
                        }
                        else {
                            parent_id =selectedID
                        }
                        this.newVersionRequest(id, parent_id).then(response => {
                            if(response.data.test){
                                if(selectedID == this.dropdownFlattenedList[0].id){
                                    parentTest.versions.unshift(response.data.test);
                                }
                                else{
                                    version.versions.unshift(response.data.test);
                                }
                                this.$emit('selectedVersion', response.data.test.id); 
                                this.selectedVersion =response.data.test.id;
                            }
                            this.addingLoading = false;
                            return;
                        }).catch((error) => {
                            this.addingLoading = false;
                            return;
                        });
                    }
                    else{
                        if(version.versions.length > 0){
                            this.appendToParent(version, selectedID);
                        }
                        else continue;
                    }
                }
            }
            else{
                this.addingLoading = true;
                this.newVersionRequest(parentTest.id, parentTest.id).then(response => {
                    if(response.data.test){
                        parentTest.versions.unshift(response.data.test);
                        this.$emit('selectedVersion', response.data.test.id); 
                        this.selectedVersion =response.data.test.id;
                    }
                    this.addingLoading = false;
                    return;
                }).catch((error) => {
                    console.log(error)
                    this.addingLoading = false;
                    return;

                });
            }
     
        },
        deleteVersion(id){
            axios.delete( 
                `${this.$store.state.api_link}/test-question-groups/delete/version/${this.parentTest.id}/${id}`,
                this.$store.getters.axiosConfig()
            ).then((res)=>{
                if(res.data.status == 'success')
                    this.$set(this.parentTest, 'versions', res.data.parentTest.versions);
                // this.deleteRecursive(this.parentTest, id,  res.data.parentTest);
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        deleteRecursive(parentTest, id, parent){
            if(parentTest.versions.length > 0){
                for(var i = 0; i < parentTest.versions.length; i++){
                    let version = parentTest.versions[i];
                    let version_index =i;
                    if(version.id == id){
                        // if( parentTest.versions[i-1]){
                        //     parentTest.versions[i-1].version_parent = parentTest.versions[i].version_parent;
                        // }
                        // this.changeParentRecursive( parentTest);
                        // parentTest.versions.splice(version_index, 1);
                        parentTest = parent;
                        // return;
                    }
                    else{
                        if(version.versions.length > 0){
                            this.deleteRecursive(version, id);
                        }
                        else continue;
                    }
                }
            }
         },
        // changeParentRecursive(parent){
        //     if(parent.versions.length > 0){
        //         for(var i = 0; i < parent.versions.length; i++){
        //             let version = parent.versions[i];
        //             let version_index =i;
        //             parent.versions[i].version_parent = parent.id;
        //             parent
        //             if(version.versions.length > 0){
        //                 this.changeParentRecursive( parent.versions[i]);
        //             }
        //             else continue;
        //         }
        //     }
        // }

    }
}
</script>

<style lang="scss" scoped>
.test-version-controller{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    position: relative;
    font-size: clamp(16px,1vw, 20px);
    z-index: 3;
    .btn, .select-container{
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 2rem;
        padding: 0.3rem 0.4rem 0.3rem 1rem;
        //width: 25%;
        font-size: clamp(16px, 1.2vw, 20px);
        color: var(--primary-color);
        cursor: pointer;
        div{
            display: flex;
            width: 100%;
        }
        .icon-container{
            min-width: 1.7rem;
            max-width: 1.7rem;
            height: 1.7rem;
        }
        &.activate{
            padding: 0.3rem 1rem;
            background-color: var(--third-color);
            width: 21%;
            justify-content: center;
            // transition: all 0.3s;
            &:hover{
                font-weight: 700;
            }
            &.active{
                background: var(--secondary-color);
                 &:hover{
                    font-weight: 400;
                }
            }
            &.disabled{
                color: var(--primary-color-opacity-50);
                cursor: not-allowed;
                &:hover{
                    font-weight: 400;
                }
                &.active{
                    background: initial;
                }
            }
        }
        .select-container{
            padding: 0;
        }
    }
    .btn{
        gap: 8px;
    }
    .select-container{
        width: 50%;
        margin-left: 0rem;
        padding: 0;
        background: none;
    }

}

.loading{
    svg{
        animation: loading_animation 1s infinite;

    }
}
.icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #efefef;
    svg{
        path{
            fill: var(--primary-color)
        }
    }
}
.check{
    path{
       fill: var(--primary-color)
    }
}
@keyframes loading_animation{
    0%{
        transform: scale(0.7);
    }
    40%{
        transform: rotate(0deg);
    }
    50%{
        transform: scale(1);
        transform: rotate(20deg);
    }
    60%{
        transform: rotate(-20deg);
    }
    100%{
        transform: scale(0.7);
    }
}
</style>
<style lang="scss">
.test-version-controller{
    .select-container{
        .filter-list-container{
            margin-right: 0;
            padding: 0;
            .dropdown-arrow-container{
                min-width: 1.7rem;
                max-width: 1.7rem;
                height: 1.7rem;
            }
        }
    }
}
.test-version-controller  .select-container .version-dropdown{
    // background: #FFDB93;
    // &.active{
    //     background: var(--secondary-color);
    // }
    .filter-list-wrapper{
        .dropdown-arrow-container{
            // background: white ;
        }
    }
}

@media(max-width: 1280px){
    body{
        .training--main{
            .test-version-controller{
                .filter-list-container{
                    .filter-list-wrapper{
                        span{
                            font-size: 14px;
                        }
                    }
                    .dropdown-arrow-container{
                        min-width: 1.25rem;
                        max-width: 1.25rem;
                        height: 1.25rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .btn{
                    font-size: 12px;
                    &:not(.activate){
                        padding: 0 4px;
                        .icon-container{
                            min-width: 1.25rem;
                            height: 1.25rem;
                            width: 1.25rem;
                            svg{
                                width: 50%;
                                height: 50%;
                            }
                        }
                    }
                    &.activate{
                        padding: 0 4px;
                    }
                }
            }
            .test--options--container{
                .tab{
                    padding: 21px 16px;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>