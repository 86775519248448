<template>
    <div 
        class="image-editor-container"
        ref="parentRef"
        @mouseenter="$emit('disableDrag')" 
        @mouseleave="$emit('enableDrag')" 
        @click="openPreview(item, src)"
    >
        <div class="image-select" :class="{active: src == ''}" v-if="type=='cover' && !readOnly">
            <div class="upload-progress-container">
                <span :style="{width: uploadProgress + '%'}">

                </span>
            </div>
            <img
                v-if="src == '' && !readOnly" 
                class="choose-file-big"
                src="@/assets/images/icons/training-item-upload.svg"
                @click="$refs.imageSelect.click()"
            >
        </div>
        <Toolbar
            v-if="!readOnly && src != ''"
            :isEditing="isEditing" 
            :readOnly="readOnly" 
            :type="type"
            :sliderVal="sliderVal"
            :sliderHeightVal="sliderHeightVal"
            @sliderChange="onSliderChange"
            @sliderHeightChange="onSliderHeightChange"
            @rotate="rotate"
            @chooseFile="$refs.imageSelect.click()"
            @deleteImage="deleteImage"
            @mouseenter="$emit('disableDrag')" 
            @mouseleave="$emit('enableDrag')" 
        />
        <input v-if="!readOnly" ref="imageSelect" type="file" style="display:none" @change="uploadImage">
        <div class="img-container" ref="container" :style="{height: type=='cover' ? height*windowScale+'px' : sliderHeightVal+'px', width: width ? width*windowScale+'px' : ''}">
            <img
                v-if="containerLoaded"
                class="main-image"
                :src="src" 
                alt=""
                ref="gifImage"
                draggable="false"
                :class="{'read-only': readOnly ? readOnly : false}"
                :style="{
                    borderRadius: readOnly && isImageRounded ? '15px': '0',
                    width: getImageWidth + 'px', 
                    height: getImageHeight + 'px',
                    top: (readOnly ? imageY : item[metadataKey] ? top : imageY) + 'px',
                    left: (readOnly ? imageX : item[metadataKey] ? left : imageX) + 'px',
                    transform: readOnly && item[metadataKey] ?  'rotate('+item[metadataKey].rotation*90+'deg)' : 'rotate('+rotation*90+'deg)'
                }"
                @mousedown="onMouseDown"
                @mousemove="onMouseMove"
                @mouseup="onMouseUp"
                @mouseleave="onMouseUp"
            >
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Toolbar from './Toolbar.vue'
import _ from 'lodash';
axios.defaults.withCredentials = true;

export default {
    components:{
        Toolbar
    },
    props:{
        readOnly: {
            type: Boolean,
            default: false
        },
        src:{
            type: String,
            required: true
        },
        item:{
            type: Object,
            required: true
        },
        isEditing:{
            type: Boolean,
            required: true
        },
        type:{
            type: String,
        },
        viewMode:{
            type: String,
        },
        height:{
            type: Number,
        },
        width:{
            type: Number,
        },
        metadataKey:{
            type: String,
            default: 'metadata'
        },
        isImageRounded: {
          type: Boolean,
          default: false,
        },
        half: {
            type: Boolean,
            default: false,
        },
        editingState:{
            type: Boolean,
            default: false,
        }
    },
    data(){
        return{
            canvas: null,
            image: null,
            naturalHeight:0,
            naturalWidth:0,
            sliderVal: 1,
            sliderHeightVal: 50,
            sliderBrightnessVal: 0,
            translateX: 0,
            translateY: 0,
            isDown: false,
            container: null,
            offset: [0,0],
            gifImage: null,
            rotation: 0,
            uploadedData: null,
            uploadProgress: 0,
            left: 0,
            top: 0,
            imageHeight: 0,
            imageWidth: 0,
            containerLoaded: false,
            vm: this,
            oldSlider: 1,
            sliderHalf: 1,
            windowScale: 1,
        }
    },
    mounted(){
        this.getImageDimensions(this.src);
    },
    beforeDestroy(){
    },
    watch:{
        editingState:{
            handler(){
                if(this.half){
                    if(this.editingState == false){
                        this.fitToScreen('small'); 
                    }
                    if(this.editingState == true){
                        this.fitToScreen('big');
                    }
                }
            }
        },
        currentMetadata:{
            deep:true,
            handler(){
                if(this.readOnly){
                    this.useMetadata();
                }
            }
        },
        '$store.state.window_width':{
            handler(){
                if(this.item[this.metadataKey] && (this.item[this.metadataKey].left != undefined || this.item[this.metadataKey].data)){
                    this.$nextTick(()=>{
                        let ratio = this.height != undefined ? this.height/this.item[this.metadataKey].parentHeight : 1;
                        let mobile_width_ratio = this.$refs.container.clientWidth/this.item[this.metadataKey].parentWidth;
                        this.sliderHeightVal = this.item[this.metadataKey].parentHeight * ratio * mobile_width_ratio;
                        if (this.width && this.width != 0 && this.readOnly)
                            this.windowScale = this.$refs.parentRef.clientWidth / this.width;
                        else if(this.readOnly){
                            this.windowScale = this.$refs.parentRef.clientWidth / 900
                        }
                    })
                }
                else {
                     if (this.width && this.width != 0 && this.readOnly)
                        this.windowScale = this.$refs.parentRef.clientWidth / this.width;
                    else if(this.readOnly) {
                        this.windowScale = this.$refs.parentRef.clientWidth / 900
                    }
                }
            }
        }
    },
    computed:{
         getImageHeight(){
            //  this.$store.state.window_width;
             if(this.readOnly && this.item[this.metadataKey] && (this.item[this.metadataKey].left != undefined || this.item[this.metadataKey].data)){
                let ratio = this.height != undefined ? this.height/this.item[this.metadataKey].parentHeight : 1;
                let mobile_width_ratio = this.$refs.container ? this.$refs.container.clientWidth/this.item[this.metadataKey].parentWidth : 1;
                // this.$store.state.window_width; //on resize trigger
                if(this.item[this.metadataKey].data){
                    return (this.item[this.metadataKey].height* this.item[this.metadataKey].data.scale)
                }
                else {
                    if (this.item[this.metadataKey].height) {
                        return this.item[this.metadataKey].height*ratio*this.windowScale
                    }
                    else if (this.height) {
                        return this.height * ratio*this.windowScale
                        // return this.item[this.metadataKey].height*ratio*mobile_width_ratio
                        
                    }
                }
            }
            else{
                if(this.readOnly){
                    this.setSliderVal();
                    if (this.height) {
                        if ((this.height*this.windowScale) > this.naturalHeight) {
                            return this.naturalHeight
                        }
                        else {
                            return this.height*this.windowScale*this.heightWidthRatio
                        }
                    }
                    return this.sliderHeightVal
                }
                else {
                    return parseFloat(this.sliderVal)*this.naturalHeight
                }
                // return this.windowScale*this.naturalHeight
                
            }
        },
        getImageWidth(){
            // this.$store.state.window_width; //on resize trigger
            if(this.readOnly && this.item[this.metadataKey] && (this.item[this.metadataKey].left != undefined || this.item[this.metadataKey].data)){
                let ratio = this.height != undefined ? this.height/this.item[this.metadataKey].parentHeight : 1;
                let mobile_width_ratio = 1;
                if(this.$refs.container){
                    mobile_width_ratio = this.$refs.container.clientWidth/this.item[this.metadataKey].parentWidth;
                }
                if(this.item[this.metadataKey].data){
                    return (this.naturalWidth * this.item[this.metadataKey].data.scale)
                }
                else {
                    if(this.height){
                        return this.item[this.metadataKey].width*ratio*this.windowScale
                    }
                    else {
                        return this.item[this.metadataKey].width*ratio*this.windowScale
                    }
                }
            }
            else{
                if (this.readOnly) {
                     this.setSliderVal();
                    if (this.width) {
                        if ((this.width*this.windowScale) > this.naturalWidth) {
                            return this.naturalWidth
                        }
                        if (this.naturalWidth < this.width) {
                            return this.naturalWidth*this.windowScale
                        }
                        else {
                            return this.width*this.windowScale
                        }
                    }
                    // else{
                    //     this.setSliderVal();
                    // }
                }
                if (this.width) {
                    if ((this.width*this.windowScale) > this.naturalWidth) {
                        return this.naturalWidth
                    }
                }
                else {
                    if (this.readOnly) {
                        
                        let ratio = this.naturalHeight/this.naturalWidth;
                        return this.getImageHeight/ratio
                    }
                    else {
                         return parseFloat(this.naturalWidth)*this.sliderVal*this.windowScale
                    }
                } 
            }
        },
        imageY(){
            // this.$store.state.window_width;
            if(this.item[this.metadataKey] && (this.item[this.metadataKey].left != undefined || this.item[this.metadataKey].data)){
                let ratio = this.height != undefined ? this.height/this.item[this.metadataKey].parentHeight : 1;
                let mobile_width_ratio = 1;
                if(this.$refs.container){
                    mobile_width_ratio = this.$refs.container.clientWidth/this.item[this.metadataKey].parentWidth;
                }
                if(this.height){
                    return (parseFloat(this.item[this.metadataKey].top)*ratio*this.windowScale);
                }
                else{
                    return (parseFloat(this.item[this.metadataKey].top)*ratio*this.windowScale);
                }
            }
            else {
                if (this.height) {
                    return ((this.height*this.windowScale) - parseFloat(this.getImageHeight))/2
                }
                return ((this.container.clientHeight - parseFloat(this.getImageHeight))/2);
            }
        },
        imageX(){
            // this.$store.state.window_width;
            if(this.item[this.metadataKey] && (this.item[this.metadataKey].left != undefined || this.item[this.metadataKey].data)){
                let ratio = this.height != undefined ? this.height/this.item[this.metadataKey].parentHeight : 1;
                let mobile_width_ratio = 1;
                if(this.$refs.container){
                    mobile_width_ratio = this.$refs.container.clientWidth/this.item[this.metadataKey].parentWidth;
                }
                if(this.height){
                    return (parseFloat(this.item[this.metadataKey].left)*ratio*this.windowScale)
                }
                else{
                    return (parseFloat(this.item[this.metadataKey].left)*ratio*this.windowScale)
                }
            }
            else {
                if (this.width) {
                    return ((this.width*this.windowScale) - parseFloat(this.getImageWidth))/2
                }
                return ((this.container.clientWidth - parseFloat(this.getImageWidth))/2)
            }
        },
        currentMetadata(){
            return this.item[this.metadataKey];
        },
        heightWidthRatio() {
            if(this.naturalHeight && this.naturalWidth){
                return this.naturalHeight/this.naturalWidth
            }
            else return 1
        }
  
    },
    methods:{
        windowResize(){
            this.getImageHeight;
        },
        fitToScreen(state){
            
            if(state == 'small'){
                this.sliderVal = this.sliderVal/2
                if(this.top != 0){
                    this.top = this.top + ((parseFloat(this.oldSlider)*this.naturalHeight - parseFloat(this.sliderVal)*this.naturalHeight)/2);
                }
                if(this.left != 0){
                    this.left=  this.left/2;
                }
            }else if(state == 'big'){
                if(this.top != 0){
                    this.top = this.top - ((parseFloat(this.oldSlider)*this.naturalHeight - parseFloat(this.sliderVal)*this.naturalHeight)/2);
                }
                if(this.left != 0){
                    this.left = this.left*2;
                }
                this.sliderVal = this.sliderVal *2
            }
            this.setMetadata();
        },
        getImageDimensions(imageSrc) {
            var img = document.createElement("img");
            let vm = this;
            vm.gifImage = this.$refs.gifImage;
            vm.container = this.$refs.container;

            img.onload = function (event)
            {
                vm.naturalHeight = img.naturalHeight;
                vm.naturalWidth = img.naturalWidth;
                vm.$emit('getImageDimensions', vm.naturalHeight,vm.naturalWidth);
                let width_ratio = vm.$refs.container.clientWidth/vm.naturalWidth;
                vm.image = img;
                vm.sliderHeightVal = Math.min(window.innerHeight*0.88, vm.naturalHeight*width_ratio*vm.windowScale);
                if(vm.item[vm.metadataKey] && (vm.item[vm.metadataKey].left != undefined || vm.item[vm.metadataKey].data)){
                    vm.useMetadata()
                }
                else{
                    vm.setImageSize();
                }
            }
            img.src = imageSrc;
        },
        useMetadata(){
            if(!this.item[this.metadataKey]) return
            this.$nextTick(()=>{
                if(this.item[this.metadataKey].data != undefined){ //for old editor

                    if(typeof this.item[this.metadataKey].data == 'string'){
                        this.item[this.metadataKey].data = JSON.parse(this.item[this.metadataKey].data);
                    }
                    
                    this.left = this.item[this.metadataKey].data.startX;
                    this.top =  this.item[this.metadataKey].data.startY*this.item[this.metadataKey].data.scale;
                    this.sliderHeightVal = this.item[this.metadataKey].height;
                    this.imageWidth = this.naturalWidth * this.item[this.metadataKey].data.scale;
                    this.imageHeight = this.item[this.metadataKey].height;
                    this.sliderVal = this.item[this.metadataKey].data.scale;
                    this.oldSlider = this.sliderVal;
                    this.sliderHalf = this.sliderVal / 2;
                }
                else{
                    if(!this.$refs.container) return
                    let ratio = this.height != undefined ? this.height/this.item[this.metadataKey].parentHeight : 1;
                    let mobile_width_ratio = this.$refs.container.clientWidth / this.item[this.metadataKey].parentWidth;
                    if (this.width && this.width != 0 && this.readOnly)
                        this.windowScale = this.$refs.parentRef.clientWidth / this.width;
                    else if(this.readOnly){
                        this.windowScale = this.$refs.parentRef.clientWidth / 900
                    }
                    this.left = (parseFloat(this.item[this.metadataKey].left) * ratio);
                    this.top = (parseFloat(this.item[this.metadataKey].top)*ratio);
                    this.sliderHeightVal = this.item[this.metadataKey].parentHeight*ratio*mobile_width_ratio;
                    this.imageWidth = this.item[this.metadataKey].width*ratio;
                    this.imageHeight = this.item[this.metadataKey].height*ratio;
                    this.sliderVal = (this.item[this.metadataKey].width*ratio)/this.naturalWidth;
                    this.oldSlider = this.sliderVal;
                    this.sliderHalf = this.sliderVal / 2;
                    if(this.item[this.metadataKey].rotation){
                        this.rotation = this.item[this.metadataKey].rotation;
                    }
                }
                this.containerLoaded = true;
            });
        },
        setImageSize(){
            this.setSliderVal();
            this.$nextTick(()=>{
                this.top = this.imageY;
                this.left = this.imageX;
                this.containerLoaded = true;
                if (this.width && this.width != 0 && this.readOnly)
                    this.windowScale = this.$refs.parentRef.clientWidth / this.width;
                else if(this.readOnly){
                    this.windowScale = this.$refs.parentRef.clientWidth / 900
                }
            })
        },
        setSliderVal(){
            let container_height = this.type =='cover' ? this.container.offsetHeight : this.sliderHeightVal;
            let sliderValWidth = 1;
            let sliderValHeight = 1;
            if(this.naturalWidth > this.container.offsetWidth){
                sliderValWidth = this.container.offsetWidth/this.naturalWidth;
            }
            if(this.naturalHeight > container_height){
                sliderValHeight = container_height/this.naturalHeight;
            }
            this.oldSlider = Math.min(sliderValWidth, sliderValHeight);
            this.sliderVal = Math.min(sliderValWidth, sliderValHeight);
            this.sliderHalf = this.sliderVal / 2;
        },
        getImageOrientation(){
            if(this.naturalHeight > this.naturalWidth){
                return 'portrait';
            }
            else{
                return 'landscape';
            }
        },
        onSliderChange(e){
            this.sliderVal = e.target.value;
            this.oldSlider = this.sliderVal;
            this.sliderHalf = this.sliderVal / 2;

            let topCenter = (this.container.clientHeight - (parseFloat(this.sliderVal)*this.naturalHeight))/2;
            let leftCenter = (this.container.clientWidth - (parseFloat(this.naturalWidth)*this.sliderVal))/2;
            this.left = leftCenter
            this.top = topCenter
            this.setMetadata();
            // this.imageHeight = value;

        },
        onSliderHeightChange(e) {
            this.sliderHeightVal = e.target.value;
            let topCenter = (this.container.clientHeight - parseFloat(this.getImageHeight))/2;
            let leftCenter = (this.container.clientWidth - parseFloat(this.getImageWidth))/2;
            this.left = leftCenter
            this.top = topCenter
            this.setMetadata()
        },
        setMetadata(){
            if(this.readOnly) return
            
            this.saveMetadataDebounce(this);
        },
        saveMetadataDebounce: _.debounce(function(vm){
            vm.$set(vm.item, vm.metadataKey, {
                left: parseFloat(vm.left).toFixed(2),
                top: parseFloat(vm.top).toFixed(2),
                height: parseInt(vm.getImageHeight),
                width: parseInt(vm.getImageWidth),
                parentHeight: vm.container.clientHeight,
                parentWidth: vm.container.clientWidth,
                rotation: vm.rotation
            })
            vm.$emit('saveMetadata', vm.item[vm.metadataKey]);
        },1000),
       
        onMouseDown(e){
            if(this.readOnly) return;
            e.preventDefault();
            this.isDown = true;
            this.offset = [
                parseFloat(this.left) - e.clientX,
                parseFloat(this.top) - e.clientY
            ];
        },
        onMouseMove(e){
            if(this.readOnly) return;
            
            e.preventDefault();
            if (this.isDown) {
                this.left = (e.clientX + this.offset[0])
                this.top  = (e.clientY + this.offset[1])
            }
        },
        onMouseUp(e){
            if(this.readOnly) return;
            e.preventDefault();
            this.isDown = false;
            this.setMetadata();
        },
        rotate(){
            this.rotation = (this.rotation+1)%4;
            this.setMetadata();
        },
        uploadImage(e){
            if(!this.$store.state.isUserTheOnlyOneEditingTraining) return;
            let file;
            if(e.size){
                file = e;
            }
            else{
                let files = e.target.files || e.dataTransfer.files;

                if (!files.length)
                    return;
                file = files[0];
            }
            this.uploadProgress = 0;
            let formData = new FormData();
            this.$set(this.item, this.metadataKey, {});
            formData.append('file', file, 'image.jpg');
            axios.post(
                `${this.$store.state.api_link}/upload-file`,
                formData,
                {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Lms-key': this.$store.state.lms_key
                  },
                  onUploadProgress: progressEvent => {
                      this.uploadedData = progressEvent.loaded;
                      this.uploadProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                  }
                }
            ).then((response) => {
              if(response.data.status == "success"){
                  let link = response.data.file;
                  this.$emit('saveImageLink', link);
                  this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.success_saved_image'));
                  
              }else{
                  this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.error_saved_image'));
              }
              this.uploadProgress = 0;
              this.uploadedData = 0;
            })
            .catch(() => {
              this.$store.state.infoMessage.setMessage('success', this.toLocal('training_edit.error_saved_image'));
              this.uploadProgress = 0;
              this.uploadedData = 0;
            });
        },
        deleteImage(){
            this.$emit('deleteImage');
        }, 
        openPreview(item, url) {
            if(!this.readOnly) return
            this.$store.state.itemPreview = {
                open: true,
                item: item,
                src: url
            }
        },  
    }
}
</script>

<style lang="scss" scoped>
.upload-progress-container{
  height: 1rem;
  width: 100%;
  position: absolute;
  top:0;
  left:0;
  span{
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #8c92a5;
    border-radius: 1rem;
    z-index: 2;
  }
}
.image-editor-container{
    width: 100%;
    position: relative;
    height: 100%;
}
.image-select{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position:absolute; 
    left:0; 
    top:0; 
    width: 100%;
    cursor: move;
    z-index: 0;
    &.active{
        z-index: 2;
    }
}
canvas{
    width: 100%;
}
.main-image{
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    cursor: move;
}
.read-only{
    cursor: default;
}
.img-container{
    position: relative;
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
    z-index: 1;
}

</style>
<style lang="scss">
.image-editor-container{
    &:hover{
        .image-manipulation{
            opacity: 1;
        }
        .img-slider-container{
            &.cover{
                opacity: 1;
            }
        }
    }
}
</style>