<template>
  <div>
    <div 
      :class="['side--background', $store.getters.isCurrentSide('document-side') ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('document-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <img src="@/assets/images/icons/side-close.svg">
      </div>
      <div class="side--tabs">
        <div 
          :class="['side--tab', {'active': sideTab == 0}]"
          @click="sideTab = 0"
        >
          {{toLocal('document_side.with_expiry')}}
        </div>
        <div
          :class="['side--tab', {'active': sideTab == 1}]"
          @click="sideTab = 1"
        >
          {{toLocal('document_side.without_expiry')}}
        </div>
      </div>
      <div class="side--content">
        <div class="group--title">
          {{toLocal('document_side.achievement')}}
        </div>
        <div :class="['document--search--button upload--button']">
          <div class="document--search--button--icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.812" height="22.812" viewBox="0 0 22.812 22.812">
              <path id="Path_681" data-name="Path 681" d="M6845.1,1676.416h-9.3v-9.3a.8.8,0,1,0-1.61,0v9.3h-9.3a.805.805,0,0,0,0,1.611h9.3v9.3a.8.8,0,1,0,1.61,0v-9.3h9.3a.805.805,0,0,0,0-1.611Z" transform="translate(-6823.593 -1665.815)" fill="#fff" stroke="#fff" stroke-width="1"/>
            </svg>
          </div>
          <div class="document--search--button--title">
            {{toLocal('document.add_document')}}
            <font-awesome-icon 
              v-if="searchLoading" 
              icon="spinner" 
            />
          </div>
        </div>
        <div :class="['document--search--button']">
          <div class="document--search--button--icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.679" height="26.687" viewBox="0 0 26.679 26.687">
              <g id="Group_1055" data-name="Group 1055" transform="translate(-1115.867 -207.234)">
                <g id="Group_223" data-name="Group 223" transform="translate(1117.988 208.734)">
                  <g id="Group_222" data-name="Group 222" transform="translate(0 0)">
                    <circle id="Ellipse_22" data-name="Ellipse 22" cx="9.767" cy="9.767" r="9.767" transform="translate(3.524)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
                    <line id="Line_26" data-name="Line 26" y1="6.386" x2="6.386" transform="translate(0 16.679)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="document--search--button--title">
            <input 
              ref="searchInput"
              type="text"
              :placeholder="toLocal('document_side.search')"
            >
            <font-awesome-icon 
              v-if="searchLoading" 
              icon="spinner" 
            />
          </div>
        </div>
        <div class="document--list">
          <div 
            v-for="document in (sideTab == 0 ? documentsWithExpireDate : documentsWithoutExpireDate)"
            :key="document.id"
            class="document"
            :style="{'background-image': `url('${$store.state.api_assets_link}images/certificates/c${document.background}.png')`}"
          >
            <div 
              class="document--download"
              @click="downloadDocument(document)"
            >
              <img src="@/assets/images/icons/download-document.svg">
            </div>
            <div 
              class="document--inner"
              @click="showDocument(document)"
            >
              <div class="document--title">
                {{ document.title }}
              </div>
              {{ document.subtitle }}
              <div
                v-if="document.expires"
                class="document--expiration"
              >
                {{ document.expires_at == null ? '-' : document.expires_at }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DocumentSide",
  data: function(){
    return {
      id: -1,
      sideTab: 0,
      searchLoading: false,
      documentsLoading: true,
      documentsWithExpireDate: [],
      documentsWithoutExpireDate: [],
    }
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      if(!this.$store.state.side_info['loaded'] && this.$store.state.side_open == "document-side") {
        this.$store.state.side_info['loaded'] = true;
        this.id = this.$store.state.side_info['id'];
        this.documentsLoading = true;
        this.loadDocuments();

      }
    }, 200);
  },
  destroyed() {
  },
  methods: {
    closeSide() {
      this.$store.state.side_open = false;
    },
    loadDocuments() {
      if(this.$store.state.side_open != 'document-side')
        return;
      axios.post(
        `${this.$store.state.api_link}/documents`,
        {},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        let documents = response.data.data;
        this.documentsWithExpireDate = [];
        this.documentsWithoutExpireDate = [];
        documents.forEach(document => {
          if(document.expires){
            this.documentsWithExpireDate.push(document);
          }else{
            this.documentsWithoutExpireDate.push(document);
          }
        });
        this.documentsLoading = false;
      })
      .catch(() => {

      });
    },
    showDocument(document) {
      let w = window.open(this.$store.state.api_link + "/document/show/" + document.secret, '_blank');
      w.focus();
    },
    downloadDocument(document) {
      window.open(this.$store.state.api_link + "/document/download/" + document.secret, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";
@import "@/assets/scss/components/layout/users/_documentSideScoped.scss";
</style>