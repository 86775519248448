<template>
    <div class="outer-container">    
        <GlobalModal v-if="errorModalOpen" :modalInfo="{
        type:'info',
        text: 'mail.folder_not_chosen',
        confirmBtnText: 'save_modal.ok'
    
    }"
    @onClose="errorModalOpen=false"/>
  <div class="report-folder-container">
          <div class="search-container">
              <div class="search-icon">
                  <svg  v-if="searchInput == ''" id="Group_10091" data-name="Group 10091" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.933" height="20.934" viewBox="0 0 20.933 20.934">
                    <defs>
                        <clipPath id="clip-path">
                        <rect id="Rectangle_2331" data-name="Rectangle 2331" width="20.933" height="20.934" fill="#28345c"/>
                        </clipPath>
                    </defs>
                    <g id="Group_7557" data-name="Group 7557" >
                        <path id="Path_6095" data-name="Path 6095" d="M.555,20.382a1.893,1.893,0,0,1,0-2.677L6.2,12.057a7.785,7.785,0,0,1-.568-1.131A7.94,7.94,0,1,1,10.008,15.3a7.545,7.545,0,0,1-1.13-.567L3.229,20.382a1.9,1.9,0,0,1-2.674,0M8.527,3.467A6.324,6.324,0,1,0,13,1.616a6.323,6.323,0,0,0-4.47,1.851Z" transform="translate(0 0)" fill="#28345c"/>
                    </g>
                    </svg>
                    <div v-else class="remove" @click="searchInput = ''">
                        <span></span><span></span>
                    </div>
                </div>
              <input type="text" :placeholder="toLocal('generic.enter_name')" v-model="searchInput">
          </div>
          <div class="btn" @click="createFolder">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.897" height="23.411" viewBox="0 0 31.897 23.411">
                    <g id="Group_9923" data-name="Group 9923" transform="translate(-429.75 -419.75)">
                        <path id="Path_8227" data-name="Path 8227" d="M29.015,9.228V7a1.569,1.569,0,0,0-1.49-1.639H15.546a.742.742,0,0,1-.47-.169L12.025,2.669a.742.742,0,0,0-.47-.169H3.7A1.225,1.225,0,0,0,2.5,3.75V22.773a1.638,1.638,0,0,0,.976,1.539h0a1.363,1.363,0,0,0,.512.1H27.525" transform="translate(428 418)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Path_8228" data-name="Path 8228" d="M33.613,39.037H9.318a1.018,1.018,0,0,0-.942.787L4.657,52.7A1.138,1.138,0,0,0,5.6,54.221H29.893a1.018,1.018,0,0,0,.942-.787l3.72-12.875A1.138,1.138,0,0,0,33.613,39.037Z" transform="translate(426.283 388.191)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                </svg>
              </div>
              <span class="label">{{toLocal('chat.new_folder')}}</span>
          </div>
           <div class="btn" @click="createNewReport">
              <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.051" height="30.857" viewBox="0 0 23.051 30.857">
                    <g id="Group_9925" data-name="Group 9925" transform="translate(0.75 -2.491)">
                        <path id="Path_558" data-name="Path 558" d="M6759.987,1871.409v24.651h-21.551V1866.7h16.845Z" transform="translate(-6738.437 -1863.462)" fill="#fff" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Path_559" data-name="Path 559" d="M6764.2,1871.409V1866.7l4.706,4.706Z" transform="translate(-6747.359 -1863.462)" fill="#fff" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <g id="Group_299" data-name="Group 299" transform="translate(4.501 12.139)">
                        <line id="Line_38" data-name="Line 38" x2="11.973" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Line_39" data-name="Line 39" x2="11.973" transform="translate(0 5.78)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Line_40" data-name="Line 40" x2="11.973" transform="translate(0 11.56)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                    </g>
                    </svg>
              </div>
              <span class="label">{{toLocal('chat.new_template')}}</span>
          </div>
        <div class="folder-wrapper">
          <div class="folder-container parent">
              <draggable 
                v-model="$store.state.emailForming.searchEmailFolders" 
                group="folders" 
                animation="200"
                ghostClass="ghost"
                :options="{ disabled:  dragDisabled}"
                @start="isDraggingFolders=true" 
                @end="isDraggingFolders=false"
                class="draggable-folder"
                >
                 <transition-group type="transition" :name="!isDraggingFolders ? 'flip-list' : null">
                    <div
                        v-for="(folder, folder_index) in $store.state.emailForming.searchEmailFolders" 
                        :key="folder_index"
                        class="folder"
                    >
                        <Folder :folder="folder" @disableFolderDrag="dragDisabled = $event" @duplicateFolder="duplicateFolder" @duplicateTemplate="duplicateTemplate"/>
                    </div>
                </transition-group>
            </draggable>
          </div>
        </div>
  </div></div>
</template>

<script>
import Folder from './Folder.vue'
import draggable from 'vuedraggable'
import axios from 'axios'
import GlobalModal from '../modals/GlobalModal.vue'
export default {
    name: 'ReportFolders',
    components:{
        Folder,
        draggable,
        GlobalModal
    },
    data(){
        return{
            isDraggingFolders: false,
            searchInput: '',
            folderList: [],
            dragDisabled: false,
            errorModalOpen: false,
        }
    },
    watch:{
        '$store.state.emailForming.emailFolders.length'(newVal, oldVal){
            if(this.$store.state.emailForming.searchEmailFolders.length == 0){
                this.$store.state.emailForming.searchEmailFolders = this._.cloneDeep(this.$store.state.emailForming.emailFolders);
            }
        },
        searchInput(newValue){
            this.searchTemplates(newValue);
        }
    },
    methods:{
        createFolder(){
            let id = 0;
            if( this.$store.state.emailForming.emailFolders.length > 0){
                id = Math.max(0, ...this.$store.state.emailForming.emailFolders.map(el => el.id))
            }
            let folder = {
                id: id+1,
                name: this.toLocal('mail.new_folder'),
                reportList: [],
                editingTitle: false,
            }
            axios.post(
                    `${this.$store.state.api_link}/email-folders`,
                    {
                        folder: folder,
                    },
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    this.$set(folder, 'id', response.data.folder.id);
                    let temp_list  = this._.cloneDeep(this.$store.state.emailForming.emailFolders);
                    this.$store.state.emailForming.emailFolders.unshift(folder);
                    let temp_folder = this._.cloneDeep(folder);
                    temp_folder.editingTitle = true;
                    temp_list.unshift(temp_folder);

                    this.$set(this.$store.state.emailForming, 'searchEmailFolders', this._.cloneDeep(temp_list));
                });
            this.searchInput = '';
        },
        createNewReport(){
            if(this.$store.state.emailForming.selectedFolder == -1) {
                this.errorModalOpen = true;
                return;
            }
            let currentFolderIndex = this.$store.state.emailForming.emailFolders.findIndex(el => el.id == this.$store.state.emailForming.selectedFolder);
                axios.post(
                    `${this.$store.state.api_link}/email-template/new`,
                {
                    
                    },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                let newReport = response.data.template;
                newReport.editingTitle = true;
                if(this.$store.state.emailForming.emailFolders[currentFolderIndex].reportList != undefined){
                    this.$store.state.emailForming.emailFolders[currentFolderIndex].reportList.unshift(newReport);
                }else{
                    this.$store.state.emailForming.emailFolders[currentFolderIndex].reportList = [newReport];
                }
                this.$store.state.emailForming.searchEmailFolders = this._.cloneDeep(this.$store.state.emailForming.emailFolders);
            });
        },
      
        updateItemListEvent(e){
        },
        searchTemplates(input){
            this.$store.state.emailForming.searchEmailFolders = this._.cloneDeep(this.$store.state.emailForming.emailFolders);
            if(input != ''){            
               this.$store.state.emailForming.searchEmailFolders = this.$store.state.emailForming.searchEmailFolders.filter((el) => {
                    let filter_report = [];
                    if(el.reportList){
                            filter_report = el.reportList.filter(el => {
                                return el.name.toLowerCase().includes(input.toLowerCase());
                            });
                    }
                    if(filter_report.length > 0 || el.name.toLowerCase().includes(input.toLowerCase())){
                        return true;
                    }
                    else{
                        return false;
                    }
                });
            }
        },
        duplicateFolder(folder){
            axios.post(
                `${this.$store.state.api_link}/email-folders/duplicate`,
                {
                    folder_id: folder.id,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
           
                this.$store.state.emailForming.emailFolders.unshift(response.data.folder);
                this.$store.state.emailForming.searchEmailFolders = this._.cloneDeep(this.$store.state.emailForming.emailFolders);
                
            });
        
        },
        duplicateTemplate(item, folder){
            axios.post(
                `${this.$store.state.api_link}/email-templates/duplicate`,
                {
                    item: item,
                    folder: folder.id
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                    // folder.reportList = [];
                let currentFolderIndex = this.$store.state.emailForming.emailFolders.findIndex(el => el.id == folder.id);
                this.$store.state.emailForming.emailFolders[currentFolderIndex].reportList.unshift(response.data.item);
                //this.$store.state.emailForming.emailFolders[currentFolderIndex].order_list = response.data.order_list;
                this.$store.state.emailForming.searchEmailFolders = this._.cloneDeep(this.$store.state.emailForming.emailFolders);
            });
        
        },
    }
}
</script>

<style lang="scss" scoped>
.outer-container{
    width: 100%;
    
}
.report-folder-container{
    position: absolute;
    display: grid;
    grid-template-areas: 'search search' 'btn btn2' 'f f';
    grid-template-rows: 2rem 3rem auto;
    grid-template-columns: 5fr 6fr;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}
.row{
    display: flex;
    flex-direction: row;
}
.btn{
    grid-area: btn;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem;
    background: white;
    cursor: pointer;
    user-select: none;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 0.75rem;
    text-align: left;
    &:hover{
        font-weight: 700;
    }
    &:active{
        background: var(--secondary-color);
    }
    .icon{
        display: flex;
        margin: 0 0.25rem;
        margin-right: 0.5rem;
        svg{
            path{
                fill: none;
                stroke: var(--primary-color);
            }
        }
    }
    &:nth-child(3){
        grid-area: btn2;
    }
    .label{
          font-size: clamp(16px, 1.2vw, 18px);
    }
    @media (max-width: 1450px){
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        span{
             white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
    }
}
.search-container{
    grid-area: search;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 2rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    .search-icon{
        display: flex;
        margin-right: 0.25rem;
        height: 100%;
        align-items: center;
        .remove{
            display: flex;
            position: relative;
            width: 1rem;
            height: 100%;
            cursor: pointer;
            transition: transform 0.3s;
            span{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 80%;
                height: 2px;
                background: var(--primary-color);
                border-radius: 1rem;
                transform: rotate(45deg);
            }
            span:last-child{
                transform: rotate(-45deg);
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    svg{
        path{
            fill: var(--primary-color);
        }
    }
    input{
        background: none;
        width: 100%;
        height: 100%;
        border: none;
        &:focus{
            outline: none;
        }
    }
}
.folder-wrapper{
    position: relative;
    height: 100%;
    width: 100%;
    grid-area: f;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

}
.folder-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    &.parent{
        position: absolute;
    }
}
.folder{
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: var(--secondary-color-opacity-50);
}
.draggable-folder{
    display:flex;
    flex-direction: row;
    width: 100%;
    span{
        width: 100%;
    }
}
</style>