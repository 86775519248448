let test_status_filter = [
    {
        id: 1, name: 'none', label: 'reports.test_not_assigned', 
    },
    {
        id: 2, name: 'done', label: 'reports.test_completed', 
    },
    {
        id: 3, name: 'not_done', label: 'reports.test_not_finished', 
    },
    {
        id: 4, name: 'not_started', label: 'reports.test_not_started', 
    },
];

let test_date_start_filter = [
    {
        id: 1, name: 'all', label: 'reports.dates_all', 
    },
    {
        id: 2, name: 'from', label: 'reports.dates_all_from', date: '',
    },
    {
        id: 3, name: 'to', label: 'reports.dates_all_to', date: '',
    },
    {
        id: 4, name: 'multi', label: 'reports.dates_several',  dates: [], secondarySelect: []
    },
    {
        id: 5, name: 'all_except', label: 'reports.dates_all_except',  dates: [], secondarySelect: []
    },
];
let duration_filter = [
    {
        id: 1, name: 'all', label: 'reports.all_times',
    },
    {
        id: 2, name: '15', label: 'reports.up_to_15',
    },
    {
        id: 3, name: '1530', label: 'reports.15_to_30',
    },
    {
        id: 4, name: '3060', label: 'reports.30-to-60',
    },
    {
        id: 5, name: '1', label: 'reports.more_than_one',
    }
];
let test_show_filter = [

    {
        id: 1,
        name: 'percent',
        label: 'generic.percent_multiple',
        type: 'multi',
        selectedFilter: {},
        multiFilter: [
            {
                id: 1, name: 'gt', label: 'reports.show_greater_than', type: 'single', content: ''
            },
            {
                id: 2, name: 'lt', label: 'reports.show_lower_than', type: 'single', content: ''
            },
        ],
    },
    {
        id: 2,
        name: 'none',
        label: 'reports.dont_show_results',
        type: 'multi',
    },
];
export let additional_test_filters = [
    {
        id: 1, name: 'status', label: 'report.status', selectLabel: 'report.select_status', selectedLabel: 'report.selected_results', filters: test_status_filter, selectedFilters: [],
    },
    {
        id: 2, name: 'start', label: 'report.start_date', selectLabel: 'report.select_start_date', selectedLabel: 'report.selected_dates', filters: test_date_start_filter, selectedFilters: [], 
    },
    {
        id: 3, name: 'end', label: 'report.end_date', selectLabel: 'report.select_end_date', selectedLabel: 'report.selected_dates', filters: test_date_start_filter, selectedFilters: [],
    },
    {
        id: 4, name: 'duration', label: 'report.duration', selectLabel: 'report.select_duration', selectedLabel: 'report.selected_duration', filters: duration_filter, selectedFilters: [],
    },
    {
        id: 5, name: 'test_show', label: 'report.results', selectLabel: 'report.select_results', selectedLabel: 'Pasirinkote rezultatą', filters: test_show_filter, selectedFilters: [],
    },

]