<template>
  <div class="preview-container" v-scroll-lock="$store.state.reportForming.previewMode" v-if="$store.state.reportForming.previewMode">
        <div
        class="preview--header"
        >
        <div 
            class="exit--preview"
            @click="$store.state.reportForming.previewMode = false"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
            <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
              <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
              <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
                <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                  <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        </div>
      <div class="preview-content" v-html="$store.state.reportForming.reportHTML">

      </div>
  </div>

</template>

<script>
export default {

  beforeDestroy(){
    this.$store.state.reportForming.previewMode = false
  }
}
</script>

<style lang="scss" scoped>
.preview--header { 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  opacity: 0.8;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 900;
font-family: var(--main-site-font);
  .exit--preview {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    svg{
      path{
        fill: var(--primary-color);
      }
    }
  }
}

.preview-container{
    display: flex;
    padding: 2rem 5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    min-height: 100vh;
    left: 0px;
    top: 0;
    background: #efefef;
    z-index: 99;
    .preview-content{
        width: 100%;
        height: 100%;
        max-height: 90vh;
        overflow: auto;
        margin-left: 80px;
        background: white;
        padding: 1rem;
        border-radius: 1rem;
    }
}
</style>