<template>
   <div class="item-upper-header">
          <div class="search-bar">
            <span class="search-icon" v-if="value == ''"><GroupSearchSvg /></span>
            <span
              class="search-clear"
              v-else
              @click="$emit('input', '')"
              ><CloseCrossFadedSvg
            /></span>
            <input
                class="search-inner"
                :placeholder="toLocal('generic.search')"
                :value="value"
                @input="$emit('input', $event.target.value)"
                key="input"
            />
          </div>
          <!-- <div class="search-type">
            <span class="label">{{toLocal('general.search_placeholder')}}</span>
            <div class="toggle">
              <span :class="{active:searchingForGroup}">{{toLocal('general.search_for_groups_label')}}</span>
              <ToggleButton
                :toggledState="searchingForGroup"
                theme="gray"
                :height="24"
                :width="48"
                :innerHeight="24"
                :innerWidth="24"
                class="custom-search-toggle"
                @changeState="changeToggleState"
              />
              <span :class="{active:!searchingForGroup}">{{toLocal('general.search_for_users_label')}}</span>
            </div>
          </div> -->
        </div>
</template>
<script>
import ToggleButton from "@/components/layout/emails/ToggleButton";
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
import CloseCrossFadedSvg from "@/assets/images/icons/close-cross-faded.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
export default {
    components:{
        ToggleButton,
        CloseCrossSvg,
        CloseCrossFadedSvg,
        GroupSearchSvg
    },
    props:{
        value: {
            type: String,
            default: ''
        },
        searchingForGroup:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
        }
    },
    methods:{
        changeToggleState(e){
            this.$emit('input', '')
            this.$nextTick(()=>{
              this.$emit('searchingForGroup', e);
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.item-upper-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 0;
  padding: 0 40px;
  transition: opacity 0.3s ease-in-out;
  .search-bar {
    position: relative;
    max-width: 200px;
    min-width: 150px;
    height: 100%;
    .search-icon {
      position: absolute;
      top: 0px;
      right: 10px;
      display: flex;
      align-items: center;
      height: 100%;
    }
    .search-clear {
      position: absolute;
      right: 10px;
      top: 4px;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
        svg {
          path {
            stroke: rgba(51, 51, 51, 1);
          }
        }
      }
    }
    .search-inner {
      width: 200px;
      padding: 6px 16px;
      background-color: #ffffff;
      background: #FFFFFF;
      border: 1px solid #7D7D7D;
      border-radius: 38px;
      font-size: 14px;
    }
  }
}
.search-type{
  display: flex;
  align-items: center;
  .toggle{
    display: flex;
    align-items: center;
    font-family: var(--main-site-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(51, 51, 51, 0.5);
    span{
      &.active{
        font-weight: 600;
        color: #333333;
      }
      &:first-child{
        margin-right: 6px;
      }
      &:last-child{
        margin-left: 6px;
      }
    }
  }
  .label{
    margin: 0 16px;
    font-family: var(--main-site-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
  }
}
</style>