<template>
  <div>
    <div 
      :class="['side--background', $store.getters.isCurrentSide('training-groups-side') ? 'side--open' : '']" 
      @click="closeSide"
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('training-groups-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
        <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
          <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
            <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
              <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
            </g>
          </g>
        </g>
      </svg>

      </div>
      <div class="side--content">
        <!-- <div class="group--section--select">
          <div class="group--section--select--button">
            Grupės nariai
          </div>
          <div class="group--section--select--button active">
            Priskirti mokymai
          </div>
        </div> -->
        <div class="group--title">
          <div 
            :class="['group--cover',(cover != '' ? 'has--image' : '')]" 
            :style="[(cover != '' ? {'background-image': `url('${getFileUrl(cover)}')`} : {})]"
          />
          <span v-if="name != '...'">{{ name }}</span>
          <span v-if="name == '...'"><font-awesome-icon icon="spinner" /></span>
        </div>
        <div :class="['group--add--button']">
          <div class="group--add--button--icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.679" height="26.687" viewBox="0 0 26.679 26.687">
              <g id="Group_1055" data-name="Group 1055" transform="translate(-1115.867 -207.234)">
                <g id="Group_223" data-name="Group 223" transform="translate(1117.988 208.734)">
                  <g id="Group_222" data-name="Group 222" transform="translate(0 0)">
                    <circle id="Ellipse_22" data-name="Ellipse 22" cx="9.767" cy="9.767" r="9.767" transform="translate(3.524)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
                    <line id="Line_26" data-name="Line 26" y1="6.386" x2="6.386" transform="translate(0 16.679)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  </g>
                </g>
                <g id="Group_1052" data-name="Group 1052" transform="translate(132.297 -1299.329) rotate(45)">
                  <g id="Group_656" data-name="Group 656" transform="translate(1779.738 359.239) rotate(45)">
                    <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                      <path id="Path_751" data-name="Path 751" d="M10.689,5.31a.585.585,0,0,1-.579.579H5.928v4.152a.586.586,0,0,1-.583.575.578.578,0,0,1-.409-.169.558.558,0,0,1-.174-.406V5.888H.579A.579.579,0,0,1,.166,4.9a.609.609,0,0,1,.413-.173H4.762V.583A.562.562,0,0,1,4.936.169.578.578,0,0,1,5.345,0a.587.587,0,0,1,.583.583V4.731H10.11A.588.588,0,0,1,10.689,5.31Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="1.3"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="group--add--button--title">
            <input 
              ref="searchInput"
              type="text"
              :placeholder="toLocal('group_side.find_groups')"
              @keyup="searchGroups"
            >
            <font-awesome-icon 
              v-if="searchLoading" 
              icon="spinner" 
            />
          </div>
        </div>
        <div class="lesson--list">
          <table 
            :class="['side--table--control', (searching ? 'hidden' : '')]"
          >
            <thead>
              <tr>
                <th class="large">
                  {{ toLocal('training.group_list') }}
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement != 'name'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('name')"
                  >
                </th>
                <th>
                  {{ toLocal('training.group_users') }}
                  <img
                    v-if="orderByElement == 'user_count' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('user_count')"
                  >
                  <img
                    v-if="orderByElement == 'user_count' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('user_count')"
                  >
                  <img
                    v-if="orderByElement != 'user_count'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('user_count')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_training_count') }}
                  <img
                    v-if="orderByElement == 'training_count' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('training_count')"
                  >
                  <img
                    v-if="orderByElement == 'training_count' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('training_count')"
                  >
                  <img
                    v-if="orderByElement != 'training_count'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('training_count')"
                  >
                </th>
                <th />
              </tr>
            </thead>
            <tbody :key="searchedGroups.length>0">
              <tr
                v-for="(group, group_id) in  searchedGroups"
                :key="group_id"
                :class="[group.added ? `gtc-${group.color_id}` : '']"
              >
                <td>{{ group.name }}</td>
                <td class="centered">
                  <div class="border-left">
                    {{ group.user_count }}
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    {{ group.training_count }}
                  </div>
                </td>

                <td class="centered actions">
                  <div class="border-left" :key="searchedGroups[group_id].added">
                    <svg :key="groups[group_id].added" v-if="group.added" @click="removeGroup(group_id)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 34">
                      <g id="Group_1052" data-name="Group 1052" transform="translate(-1769 -356)">
                        <g id="Group_656" data-name="Group 656" transform="translate(1785.898 359.239) rotate(45)">
                          <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                            <path id="Path_751" data-name="Path 751" d="M19.461,9.667a1.066,1.066,0,0,1-1.054,1.054H10.792v7.56a1.066,1.066,0,0,1-1.062,1.046,1.053,1.053,0,0,1-.745-.307,1.015,1.015,0,0,1-.317-.739v-7.56H1.054A1.054,1.054,0,0,1,.3,8.928a1.11,1.11,0,0,1,.752-.315H8.669V1.061A1.024,1.024,0,0,1,8.986.307,1.053,1.053,0,0,1,9.731,0a1.069,1.069,0,0,1,1.062,1.061V8.613h7.615A1.071,1.071,0,0,1,19.461,9.667Z" transform="translate(0 0)" fill="#464646"/>
                          </g>
                        </g>
                        <g id="Ellipse_135" data-name="Ellipse 135" transform="translate(1769 356)" fill="none" stroke="#464646" stroke-width="2">
                          <circle cx="17" cy="17" r="17" stroke="none"/>
                          <circle cx="17" cy="17" r="16" fill="none"/>
                        </g>
                      </g>
                    </svg>
                    <svg :key="searchedGroups[group_id].added" v-else @click="addGroup(group_id)" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.9477 23.544C14.7019 23.5413 14.467 23.4424 14.2931 23.2686C14.1193 23.0948 14.0205 22.8598 14.0177 22.614L14.0177 15.8949L7.34712 15.8949C7.10134 15.8903 6.86714 15.7896 6.69464 15.6145C6.52214 15.4394 6.42506 15.2037 6.42418 14.9578C6.42375 14.7115 6.52119 14.475 6.69506 14.3005C6.77897 14.2119 6.88008 14.1414 6.99221 14.0933C7.10434 14.0452 7.22511 14.0205 7.34712 14.0208L14.0177 14.0208V7.30255C14.0173 7.11909 14.0711 6.9396 14.1725 6.78667C14.2738 6.63374 14.4181 6.51419 14.5872 6.44308C14.7563 6.37196 14.9427 6.35245 15.1229 6.387C15.303 6.42155 15.469 6.50862 15.5998 6.63725C15.7732 6.81516 15.8726 7.05235 15.8777 7.30078V14.0217H22.5412C22.6654 14.0201 22.7886 14.0441 22.903 14.0922C23.0175 14.1403 23.1208 14.2115 23.2065 14.3014C23.3804 14.4759 23.4778 14.7124 23.4774 14.9587C23.4758 15.2066 23.3767 15.4439 23.2015 15.6192C23.0263 15.7946 22.7891 15.8939 22.5412 15.8958H15.8777L15.8777 22.6149C15.8736 22.8602 15.7743 23.0943 15.6007 23.2677C15.4272 23.4411 15.193 23.5402 14.9477 23.544Z" fill="#464646"/>
                      <path d="M15 29.1176C22.7969 29.1176 29.1176 22.7969 29.1176 15C29.1176 7.20301 22.7969 0.882324 15 0.882324C7.20301 0.882324 0.882324 7.20301 0.882324 15C0.882324 22.7969 7.20301 29.1176 15 29.1176Z" stroke="#464646" stroke-width="1.76471"/>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TrainingGroupsSide",
  data: function(){
    return {
      "id": "-1",
      "name": "...",
      "description": "...",
      "cover": "",
      "groups": [],
      "search_groups": [],
      searching: false,
      lastSearchValue: "",
      searchLoading: false,
      loadInterval: null,
      searchInterval: null,
      orderByElement: "",
      orderByDirection: "",
      searchedGroups: [],
    }
  },
  props:{
    training: Object,
  },
  watch:{
    '$store.state.side_info.id':{
      handler: function handle(newData){
        if(newData != undefined){ 
          this.id = newData;
          this.name = this.training.data ? this.training.data.title : '';
          this.description = this.training.data ? this.training.data.description : '';
          this.cover = this.training.data ? this.training.data.cover : '';
          this.getGroups();
        }

      },
      immediate: true
    },
    'training'(newData){
       if(newData != undefined){ 
          this.id = this.training.id;
          this.name = this.training.data ? this.training.data.title : '';
          this.description = this.training.data ? this.training.data.description : '';
          this.cover = this.training.data ? this.training.data.cover : '';
          this.getGroups();
        }
    }

  },

  methods: {
    getGroups(){
      if(this.training.id != undefined)
      axios.get(
          `${this.$store.state.api_link}/group/training-groups/${this.training.id}`,
          this.$store.getters.axiosConfig()
        ).then((response) => {
          var groups = response.data;
          // groups.forEach((el, i) =>{
          //   var index = this.groups.findIndex(elem => elem.id == el.id);
          //   if(index != -1)
          //     el.added = this.groups[index].added;
          //   else el.added = false;
          // })
          this.groups = groups;
          this.searchedGroups =  this.groups;
        })
        .catch(() => {
          console.log("Server error while getting groups");
        });
    },
    getTrainingInfo(id){
        this.name = "...";
        this.description = "...";
        this.groups = [];
        this.search_groups = [];
        axios.get( 
          `${this.$store.state.api_link}/training/info/${this.$store.state.currentTraining}`,
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.$store.state.side_info['loaded'] = true;
          let trainingData = JSON.parse(response.data.data);
          this.name = trainingData.title;
          this.description = trainingData.description;
          this.cover = trainingData.cover;
          var temp = response.data.groups;
          temp.forEach((el)=>{
            el.added =true;
          })
          this.groups = temp;
           this.getGroups();
        }).catch((error) => {
          console.log(error)
        });
    },
    closeSide() {
      this.$store.state.side_open = false;
      this.name = "...";
      this.description = "...";
    },
    searchGroups(e){
      var keyword = e.target.value.toLowerCase();
      var filtered_groups = this.groups.filter(function(group){
        // group.name = group.name.toLowerCase();
       return group.name.toLowerCase().indexOf(keyword) > -1; 
      });
      this.$set(this, 'searchedGroups', filtered_groups);
    },
    addGroup(group_array_id) {
      var group_id = this.searchedGroups[group_array_id].id;
      axios.post(
        `${this.$store.state.api_link}/training/group/add`,
        {training: this.id, group: group_id},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){
          this.$store.state.side_info['loaded'] = false;
          this.lastSearchValue = "-1";
          // this.$set(this.groups[group_array_id], 'added', true);
          this.$set(this.searchedGroups[group_array_id], 'added', true);
           this.$set(this.searchedGroups[group_array_id], 'training_count', this.searchedGroups[group_array_id].training_count+1);
        }else{
          console.log("Error while adding group");
        }
      })
      .catch(() => {
        console.log("Server error while adding group");
      });
    },
    removeGroup(group_array_id) {
        var group_id = this.searchedGroups[group_array_id].id;
      axios.post(
        `${this.$store.state.api_link}/training/group/remove`,
        {training: this.id, group: group_id},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){
          this.$store.state.side_info['loaded'] = false;
          // this.$set(this.groups[group_array_id], 'added', false);
          this.$set(this.searchedGroups[group_array_id], 'added', false);
          this.$set(this.searchedGroups[group_array_id], 'training_count', this.searchedGroups[group_array_id].training_count-1);

        }else{
          console.log("Error while removing group");
        }
      })
      .catch(() => {
        console.log("Server error while removing group");
      });
    },
    setOrderBy(element) {
      if(element == "")
        return;
      if(element == this.orderByElement){
        this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
      }else{
        this.orderByElement = element;
        this.orderByDirection = "asc";
      }
      if((this.groups.length && isNaN(this.groups[0][element])) || (this.search_groups.length && isNaN(this.search_groups[0][element]))){
        if(this.orderByDirection == "desc"){
          if(this.groups.length) this.groups.sort((a,b) => b[element].localeCompare(a[element])); 
          if(this.search_groups.length) this.search_groups.sort((a,b) => b[element].localeCompare(a[element])); 
        }else{
          if(this.groups.length) this.groups.sort((a,b) => a[element].localeCompare(b[element])); 
          if(this.search_groups.length) this.search_groups.sort((a,b) => a[element].localeCompare(b[element])); 
        }
      }else{
        if(this.orderByDirection == "desc"){
          if(this.groups.length) this.groups.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
          if(this.search_groups.length) this.search_groups.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
        }else{
          if(this.groups.length) this.groups.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
          if(this.search_groups.length) this.search_groups.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";


    .side--background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .group--side {
        position: fixed;
        right: -900px;
        background-color: #fff;
        height: calc(100vh - 100px);
        top: 100px;
        width: 800px;
        z-index: 11;
        font-family: var(--main-site-font);
        transition: .3s right;
        z-index: 1006;

        &.side--open {
          right: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }
    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 47px;
            height: 47px;
            path{
              fill: var(--primary-color);
            }
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      width: 40px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -4px;
      }
    }

    .group--section--select {
      margin-top: -60px;

      .group--section--select--button {
        width: 50%;
        float: left;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        user-select: none;
        background-color: $background_color;
        box-shadow: 0 0 5px rgba(0,0,0,0);
        transition: .3s box-shadow, .3s background-color;

        &.active, &:hover {
          box-shadow: 0 0 5px rgba(0,0,0,.3);
          background-color: $group_color;
        }
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .group--title{ 
      font-size: 35px;
      padding-top: 15px;
      font-weight: 900;
      padding-left: 30px;

      .group--cover {
        width: 105px;
        height: 105px;
        display: inline-block;
        vertical-align: middle;
        background-position: center;
        background-size: cover;
        border-radius: 20px;
        background-color: #DBDBDB;
        // background-image: url("@/assets/images/placeholders/pia21974.0.jpg");
      }

      span {
        font-size: 35px;
        padding-left: 23px;
        // display: block;
      }
    }

    .lesson--list {
      position: relative;
    }

    .side--table--control {
      margin-top: 50px;
      width: 100%;
      margin: 0;
      position: absolute;
      left: 0;
      transition: .3s left;

      &.hidden {
        left: calc(-100% - 150px);

        &.search--table {
          left: calc(200% + 150px);
        }
      }

      svg {
        margin-top: -2px;
      }

      // tbody tr {
        // background-color: #efefef;
      // }

      tbody td {
        background-color: #efefef;
      }

      &.search--table {
        // tr td {
          // border-top: 1px solid #464646;
          // border-bottom: 1px solid #464646;

          // &:first-child {
            // border-left: 1px solid #464646;
          // }
          // &:last-child {
            // border-right: 1px solid #464646;
          // }
        // }
        svg {
          margin-top: -9px;
        }
      }
    }

    .group--add--button {
      padding: 12px;
      border-radius: 35px;
      padding-right: 35px;
      user-select: none;
      transition: 1s background-color, .3s transform, .3s box-shadow;
      // background-color: #EFEFEF;
      // margin: 0 -35px;
      margin-top: 20px;
      border: 1px solid #707070;

      .group--add--button--icon {
        width: 45px;
        height: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 100%;
        vertical-align: middle;

        svg {
          max-width: 28px;
          max-height: 28px;
          fill: var(--primary-color);
        }
      }

      .group--add--button--title {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #707070;
        width: calc(100% - 70px);

        input {
          background: transparent;
          border: none;
          outline: none;
          color: $font_color;
          font-size: 20px;
          font-family: var(--main-site-font);
          width: calc(100% - 40px);
        }
      }

      &:hover {
        box-shadow: 0px 3px 6px #00000033;
        transform: translateY(-3px);
      }
    }
</style>