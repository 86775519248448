<template>
  <div class="comment-container">
      <div class="comment-bubble-arrow bottom"></div>
      <div class="comment-bubble-arrow top"></div>
      <div class="comment-body">
          <slot name="comment"></slot>
      </div>
      <div class="date">
          <slot name="date"></slot>
      </div>

  </div>
</template>

<script>
export default {
    name: 'Comment',
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
.comment-container{
    display: flex;
    position: relative;
    width: 100%;
    padding: 0 0.5rem;
    margin-top: 1.25rem;
}
.comment-body{
    display: flex;
    width: 100%;
    height: 100%;
    background: white;
    border: 2px solid #dddddd80;
    box-shadow: 0px 0px 12px -5px #d5d5d58c;
    border-radius: 1rem;
    padding: 1rem;
    font-size: clamp(16px, 1vw, 18px);
    color: var(--primary-color);
    z-index: 1;
}
.comment-bubble-arrow, .top, .bottom{
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: white;
    transform: rotate(45deg);
    top: -0.75rem;
    left: 1.75rem;
    z-index: 1;
  
}
.top{
    z-index: 2;
    width: calc(1.5rem - 2px);
    height: calc(1.5rem - 2px);
    left: calc(1.75rem + 1px);
    top: calc(-0.75rem + 3px);
}
.bottom{
    z-index: 0;
    box-shadow: 0px 0px 12px -5px #d5d5d58c;
    border: 2px solid #dddddd80;
}
.date{
    position: absolute;
    bottom: 0.25rem;
    right: 1.5rem;
    color: #88888880;
    z-index: 3;
    font-size: clamp(14px, 0.8vw, 16px);
}
</style>