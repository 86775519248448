<template>
    <div class="group-stats-container">
        <div class="cards">
            <div 
                class="card"
                v-for="card in cards" 
                :key="card.type"
                 :style="{ gridColumnEnd: 'span ' + card.size }"
            >
            <div class="card-wrapper">
                <div class="header">
                    {{ card.label }}
                </div>
                <div class="content">
                    <div class="percent" v-if="card.type =='group_rating'">
                        0 %
                    </div>
                    <div class="graph-wrapper">
                        <RatingGraph :height="100" :width="100" v-if="card.type =='group_rating' && userRatingStats.loaded" :options="{type:'big'}" :data="userRatingStats"/>
                    </div>
                    <div class="percent" v-if="card.type =='group_rating'">
                        100 %
                    </div>
                </div>
            </div>

            </div>
        </div>
    </div>
</template>

<script>
import RatingGraph from "@/components/layout/groupLeader/graphs/RatingGraph";
import axios from 'axios';
export default {
    components: {
        RatingGraph
    },
    props: {
        userRatings: Array,
        group: Object
    },
    data() {
        return {
            cards: [
                {type:'group_rating', size: 11, label: 'Komandos reitingas'},
                {type:'status', size: 9, label: 'Elementų statusas'},
                {type:'certificates', size: 20, label: 'Pažymėjimų galiojimas'},
            ],
            userRatingStats: {
                rating: 0,
                other_ratings: [],
                loaded: false
            },
            isLoading: false
        }
    },
    watch: {
        'group.id'() {
            this.getGroupRatingScores()
        }
    },
    mounted() {
        this.getGroupRatingScores()
        // this.setUserStats()
    },
    computed: {
        colWidth() {
            // Define dynamic grid columns based on the number of items
            const columnWidths = this.cards.map(item => item.size + '%').join(' ');
            return columnWidths
        },
    },
    methods: {
        
        getGroupRatingScores() {
            if(!this.group.id) return
            this.isLoading = true;
            this.userRatingStats.loaded = false
            axios
            .get(
                `${this.$store.state.api_link}/group/general-stats?id=${this.group.id}`,
                this.$store.getters.axiosConfig()
            )
            .then((response) => {
                let group_ratings = response.data.group_ratings;
                let current_group = group_ratings.filter(group_rating => group_rating.id == this.group.id);
                if(current_group.length > 0) {
                    this.userRatingStats.rating = Math.round(current_group[0].group_rating, 2);
                }
                group_ratings = group_ratings.map(group_rating => {
                    return Math.round(group_rating.group_rating,2);
                })
              
                this.userRatingStats.other_ratings = group_ratings;
                this.isLoading = false;
                this.$nextTick(()=>{
                    this.userRatingStats.loaded = true
                })
               
            })
            .catch((error) => {
                this.isLoading = false;
                this.userRatingStats.loaded = true
                
                console.log("error getting info");
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.group-stats-container{
    height: 100%;
}
.cards{
    display: grid;
    grid-template-columns: repeat(20, 1fr); /* 20 equal columns to control span */
    gap: 12px 16px;
    height: 100%;
    grid-template-rows: 6fr 5fr;
}
.content{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 8px;
    max-height: 100%;
    overflow: hidden;
}
.card-wrapper{
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
}
.graph-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.card{
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    border-radius: 16px;
    box-sizing: border-box;
    min-height: 45%;
    padding: 12px;
}
.percent{
    display: flex;
  align-self: flex-end;
  white-space: nowrap;
}

.header{
    display: flex;
    justify-content: center;
}
</style>