
<template>
    <div class="training-card-container">
        <div class="training">
            <div class="title">
                <span class="training-title">{{ training.title }}</span>
                <span class="group-title">{{ training.training_groups ? training.training_groups[0].name : '' }}</span>
            </div>
            <div class="stats-section-container">
                <div 
                    class="section training-section"
                    v-for="(section, index) in sections"
                    :class="{'divider-right': index < sections.length - 1}"
                    :key="index" 
                >
                   
                    <div class="not-found content" v-if="showNotFound(section.type)">
                        <div class="left-section">
                            <div class="progress">
                                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="72" height="72" fill="white"/>
                                    <circle cx="35.9996" cy="35.9996" r="29.6471" stroke="#CACACA" stroke-width="6"/>
                                    <path d="M6.35254 36.0002C6.35254 52.3738 19.626 65.6472 35.9996 65.6472C52.3732 65.6472 65.6467 52.3738 65.6467 36.0002C65.6467 26.5514 61.2264 18.135 54.341 12.7061" stroke="#EAEAEA" stroke-width="6"/>
                                    <rect x="20" y="29" width="22" height="14" rx="1" fill="#EAEAEA"/>
                                    <rect x="46" y="33" width="6" height="10" rx="1" fill="#EAEAEA"/>
                                </svg>

                            </div>
                        </div>
                        <div class="right-section">
                            <div class="select-section">
                                <svg width="152" height="28" viewBox="0 0 152 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="151" height="27" rx="5.5" fill="white" stroke="#EAEAEA"/>
                                    <rect x="8" y="9" width="86" height="10" rx="1" fill="#EAEAEA"/>
                                    <rect x="98" y="9" width="28" height="10" rx="1" fill="#EAEAEA"/>
                                    <rect x="138" y="9" width="6" height="10" rx="1" fill="#EAEAEA"/>
                                    </svg>
                            </div>
                            <div class="bottom-section">
                                <svg width="149" height="24" viewBox="0 0 149 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="84" width="65" height="24" rx="12" fill="#F4F4F4"/>
                                    <rect y="7" width="62" height="10" rx="1" fill="#EAEAEA"/>
                                    <rect x="66" y="7" width="6" height="10" rx="1" fill="#EAEAEA"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="content" v-else>
                        <div class="left-section">
                            <span class="header">
                                {{ toLocal(section.label) }}
                            </span>

                            <div class="progress"  >
                                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40 3C49.813 3 59.2241 6.8982 66.1629 13.837C73.1018 20.7759 77 30.187 77 40C77 49.813 73.1018 59.2241 66.1629 66.1629C59.2241 73.1018 49.813 77 40 77C30.187 77 20.7759 73.1018 13.837 66.1629C6.8982 59.2241 3 49.813 3 40C3 30.187 6.8982 20.7759 13.837 13.837C20.7759 6.8982 30.187 3 40 3Z" stroke="#E2E2E2" stroke-width="6"/>
                                <path 
                                    d="M40 3C49.813 3 59.2241 6.8982 66.1629 13.837C73.1018 20.7759 77 30.187 77 40C77 49.813 73.1018 59.2241 66.1629 66.1629C59.2241 73.1018 49.813 77 40 77C30.187 77 20.7759 73.1018 13.837 66.1629C6.8982 59.2241 3 49.813 3 40C3 30.187 6.8982 20.7759 13.837 13.837C20.7759 6.8982 30.187 3 40 3Z" 
                                    :stroke="`url(#gradient-${training.id}-${index})`"
                                    :stroke-dasharray="`${
                                        section.progress*strokeDashArray
                                    },251.2`"
                                    stroke-width="6"/>
                                <defs>
                                <linearGradient :id="`gradient-${training.id}-${index}`" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
                                    <stop :stop-color="section.progress_colors[0]"/>
                                    <stop offset="1" :stop-color="section.progress_colors[1]"/>
                                </linearGradient>
                                </defs>
                                </svg>

                                <span class="progress-percent">
                                    {{getProgressLabel(section)}}
                                </span>

                            </div>
                        </div>
                        <div class="right-section">
                            <div class="select-section">
                                <CustomDropdown
                                    :list="section.dates"
                                    key-label="id"
                                    value-label="label"
                                    :compareChosen="section.selectedDate ? section.selectedDate : {}"
                                    :options="{
                                        closeOnClick: true,
                                        checkbox: false,
                                        customDDWidth: 'initial',
                                        customDDMaxWidth: '250px',
                                        customDDMinWidth: '100%',
                                        customMaxHeight: '300px',
                                        initial: section.selectedDate ? section.selectedDate.label : 'Pasirinkti...',
                                        containerClass: 'card-date-dropdown',
                                        searchBar: false,
                                        left:true,
                                        showCreateNewBtn: false,
                                        valueWidthType: 'dynamic'
                                    }"
                                    @elClicked="selectDropdownItem(section, $event)"
                                >
                                    <template v-slot:arrow >
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z" fill="#7D7D7D"/>
                                        </svg>
                                    </template>
                                </CustomDropdown>
                            </div>
                            <div class="bottom-section">
                                <div class="confirmation-container" v-if="section.type == 'training' && section.confirmStats && section.confirmStats > 0">
                                    <span class="label-text">{{toLocal('generic.confirmations_2')}}:</span>
                                    <button @click="view('confirmations', section)">
                                        <span>
                                            {{ section.confirmStats }} 
                                        </span>
                                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.205025 11.8047C-0.0683418 11.5444 -0.0683418 11.1223 0.205025 10.8619L5.31005 6L0.205025 1.13807C-0.0683422 0.877722 -0.0683423 0.455611 0.205025 0.195262C0.478392 -0.0650873 0.921607 -0.0650874 1.19497 0.195262L6.79497 5.52859C7.06834 5.78894 7.06834 6.21105 6.79497 6.4714L1.19497 11.8047C0.921608 12.0651 0.478392 12.0651 0.205025 11.8047Z" fill="#7D7D7D"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="button-container">
                                    <div class="answers-button" v-if="section.type == 'test'">
                                        <button @click="view('test',section)">
                                            <span>
                                            {{toLocal('generic.answers')}} 
                                            </span>
                                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.205025 11.8047C-0.0683418 11.5444 -0.0683418 11.1223 0.205025 10.8619L5.31005 6L0.205025 1.13807C-0.0683422 0.877722 -0.0683423 0.455611 0.205025 0.195262C0.478392 -0.0650873 0.921607 -0.0650874 1.19497 0.195262L6.79497 5.52859C7.06834 5.78894 7.06834 6.21105 6.79497 6.4714L1.19497 11.8047C0.921608 12.0651 0.478392 12.0651 0.205025 11.8047Z" fill="#7D7D7D"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="preview-btn" v-if="section.type == 'certificate'">
                                        <button @click="view('certificate',section)">
                                            <span>
                                            {{toLocal('generic.view')}} 
                                            </span>
                                            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.38372 6C3.278 8.8993 6.01516 10.6667 9 10.6667C11.9843 10.6667 14.7154 8.89987 16.6159 5.99942C14.7216 3.10046 11.9846 1.33333 9 1.33333C6.01516 1.33333 3.278 3.1007 1.38372 6ZM0.100844 5.63765C2.17532 2.23614 5.37332 0 9 0C12.6267 0 15.8247 2.23614 17.8992 5.63765C18.0338 5.85845 18.0336 6.14267 17.8986 6.36324C15.8177 9.76331 12.6272 12 9 12C5.37332 12 2.17532 9.76386 0.100844 6.36235C-0.0336148 6.14187 -0.0336148 5.85813 0.100844 5.63765Z" fill="#7D7D7D"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00074 4C7.96039 4 7.11702 4.89543 7.11702 6C7.11702 7.10457 7.96039 8 9.00074 8C10.0411 8 10.8845 7.10457 10.8845 6C10.8845 4.89543 10.0411 4 9.00074 4ZM5.86121 6C5.86121 4.15905 7.26683 2.66667 9.00074 2.66667C10.7347 2.66667 12.1403 4.15905 12.1403 6C12.1403 7.84095 10.7347 9.33333 9.00074 9.33333C7.26683 9.33333 5.86121 7.84095 5.86121 6Z" fill="#7D7D7D"/>
                                            </svg>
                                        </button>
                                        <button class="download-btn">
                                            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.18574 4.65968C1.51565 4.65968 1.7831 4.93035 1.7831 5.26425V9.39747C1.7831 10.1635 2.39889 10.7908 3.15985 10.7908H12.8404C13.5973 10.7908 14.2172 10.1676 14.2172 9.39747V5.33291C14.2172 4.99901 14.4846 4.72833 14.8145 4.72833C15.1445 4.72833 15.4119 4.99901 15.4119 5.33291V9.39747C15.4119 10.8381 14.2544 12 12.8404 12H3.15985C1.7364 12 0.588379 10.8285 0.588379 9.39747V5.26425C0.588379 4.93035 0.855827 4.65968 1.18574 4.65968Z" fill="#7D7D7D"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90409 0C8.234 0 8.50145 0.270678 8.50145 0.604576V7.8892C8.50145 8.2231 8.234 8.49378 7.90409 8.49378C7.57418 8.49378 7.30673 8.2231 7.30673 7.8892V0.604576C7.30673 0.270678 7.57418 0 7.90409 0Z" fill="#7D7D7D"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4213 5.55916C10.6546 5.79526 10.6546 6.17805 10.4213 6.41416L8.67786 8.17867C8.25463 8.60701 7.56434 8.60701 7.14111 8.17867L5.47228 6.48968C5.23899 6.25358 5.23899 5.87078 5.47228 5.63468C5.70556 5.39858 6.08379 5.39858 6.31707 5.63468L7.90949 7.24633L9.57652 5.55916C9.8098 5.32305 10.188 5.32305 10.4213 5.55916Z" fill="#7D7D7D"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";

export default {
    props: {
        training: Object,
        user: Object
    },
    components: {
        CustomDropdown,
    },
    data(){
        return {
            strokeDashArray: 251.2,
            sections: [
                { type: 'training', 'label': 'generic.training', progress: 0, progress_colors: ['#FF8FAA', '#FF3C46'], dates:[], selectedDate: null, confirmStats: []},
                { type: 'test', 'label': 'test.test', progress: 0, progress_colors: ['#FF8FAA', '#FF3C46'], dates:[]},
                { type: 'certificate', 'label': 'generic.certificate', progress: 0, progress_colors: ['#FF8FAA', '#FF3C46'], dates:[]},
            ],
            selectedItems: {
                training: null,
                test: null,
                certificate: null
            },
            progressColors: [
                ['#16EDA4', '#59CCA5'],
                ['#FDDF40', '#FD9B40'],
                ['#FF8FAA', '#FF3C46'],
            ],
            loaded: false,
            showConfirmModal: false,
            
        }
    },
    mounted() {
        this.setProgress(this.training, 'training', this.sections[0])
        this.setProgress(this.training, 'test', this.sections[1])
        this.setProgress(this.training, 'certificate', this.sections[2])
    },
    methods: {
        selectDropdownItem(section, $event) {
            let index = section.dates.findIndex(el => el.id == $event);
            if (index != -1) {
                this.$set(section, 'selectedDate', section.dates[index]);
                if (section.type == 'test' && this.training.user_training_stats && this.training.user_training_stats.test) {
                    let end_stat_index = this.training.user_training_stats.test.findIndex(stat => stat.id == section.dates[index].id);
                    if (end_stat_index != -1) {
                        this.$set(section, 'progress', this.training.user_training_stats.test[end_stat_index].training_result)
                        this.$set(section, 'progress_colors',[this.getGradientColor(section, 'from'), this.getGradientColor(section, 'to')])
                    }
                }
            }
        },
        setProgress(training, type, section) {
            if(section.type == 'test' && training.user_training_stats && training.user_training_stats.test)
            {   
                let end_stats = training.user_training_stats.test.filter(stat => stat.stat_key == 'test_end');
                if (end_stats.length > 0) {
                    let dates = end_stats.map(stat => {
                        return {
                            label: stat.formatted_created_at,
                            id: stat.id
                        }
                    });
                    this.$set(section, 'dates', dates)
                    this.$set(section, 'selectedDate', dates[0])

                    let end_stat = end_stats[0];
                    this.$set(section, 'progress', end_stat.training_result)
                }
                else {
                    this.$set(section, 'progress', 0)


                }
                this.$set(section, 'progress_colors',[this.getGradientColor(section, 'from'), this.getGradientColor(section, 'to')])
                
            }
            else if (section.type == 'training' && training.user_training_stats && training.user_training_stats.training) {
                let end_stats = training.user_training_stats.training.filter(stat => stat.stat_key == 'training_end');
                if (end_stats.length > 0) {
                    let dates = end_stats.map(stat => {
                        return {
                            label: stat.formatted_created_at,
                            id: stat.id
                        }
                    });
                    section.dates = dates
                    section.selectedDate = dates[0]
                  
                    section.progress = 1
                }
                else {
                    section.progress = 0
                }
                section.progress_colors = [this.getGradientColor(section, 'from'), this.getGradientColor(section, 'to')]
                if(training.confirm_stats && training.confirm_stats > 0) {
                    section.confirmStats = training.confirm_stats
                }
            }
             else if (section.type == 'certificate' && training.user_training_stats && training.user_training_stats.certificate) {
                let certificate = training.user_training_stats.certificate;
                let dates = [{id:certificate.id, label: certificate.formatted_created_at}]
                section.dates = dates
                section.selectedDate = dates[0]
                if (certificate.expires) {
                    if (certificate.difference_in_days <= 0) {
                        this.$set(section,'progress', 1)
                    }
                    else {
                        let val_progress = 1 - (certificate.days_since_creation / certificate.total_validity_in_days)
                        this.$set(section,'progress', val_progress)
                    }
                }
                else {
                    this.$set(section,'progress', 1)
                }
                this.$set(section,'progress_colors', [this.getGradientColor(section, 'from'), this.getGradientColor(section, 'to')])
             
            }
        },
        view(type, section) {
            this.$emit('toggle', type, this.training, section.selectedDate)
        },
        showNotFound(type) {
            return this.training.user_training_stats ? !this.training.user_training_stats[type] : true 
        },
        getGradientColor(section, pos) {
            let progress = section.progress;
            if (section.type == 'certificate' && (this.training.user_training_stats.certificate.difference_in_days <=1) && (this.training.user_training_stats.certificate.expires)) {
                return pos =='from' ? this.progressColors[2][0] : this.progressColors[2][1];
            }
            if (progress) {
                if(progress >= 0 && progress <= 0.1) {
                    return pos =='from' ? this.progressColors[2][0] : this.progressColors[2][1];
                }
                else if (progress >= 0.11 && progress <= 0.5) {
                    return pos =='from' ? this.progressColors[1][0] : this.progressColors[1][1];
                }
                else if (progress >= 0.51 && progress <= 1) {
                    return pos =='from' ? this.progressColors[0][0] : this.progressColors[0][1];
                }
            }
            return pos =='from' ? this.progressColors[2][0] : this.progressColors[2][1];
            
        },
        getProgressLabel(section) {
            if(section.type == 'test' && section.progress != null) {
                return Math.round(section.progress  * 100,2) + ' %'
            }
            else if (section.type == 'training' && section.progress != null) {
                return Math.round(section.progress  * 100,2) + ' %'
            }
            else if(section.type == 'certificate' && section.progress != null) {
                let certificate = this.training.user_training_stats.certificate
                if (certificate.expires) {
                    if (certificate.difference_in_days <= 0) {
                        return this.toLocal('generic.invalid')
                    }
                    else return this.toLocal('generic.valid_label')+' '+ certificate.difference_in_days + ' '+this.toLocal('email.template_static_time_unit_1')
                }
                else {
                    return this.toLocal('groups.unlimited')
                }
            }
            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
.training{
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
    
}
.bottom-section{
    min-height: 24px;
}
.title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 600;
    background-color: #F4F4F4;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 12px 12px 0 0;
    
}
.training-title{
    max-width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.group-title{
    color: #7D7D7D;
    font-weight: 400;
    max-width: 30%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.stats-section-container{
    display: flex;
    flex: 1;
}
.section{
    display: flex;
    flex-direction: column;
    flex:1;
    padding: 0 20px;
    margin: 20px 0;
}
.divider-right{
    border-right:1px solid #E2E2E2; 
}
.header{
    text-transform: uppercase;
    font-weight: 600;
    color: #7D7D7D;
    font-size: 14px;
}
.progress{
    display: flex;
    position: relative;
    max-height: 72px;
    max-width: 72px;
    height: 72px;
    width: 72px;
    svg{
        width: 100%;
        height: 100%;
        path{
            transition: stroke-dasharray 0.5s;
        }
    }
    .progress-percent{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        max-width: 48px;
        text-align: center;
        font-weight: 600;
    }
}
.right-section{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    gap: 14px;
    width: 100%;
}
.content{
    display: flex;
    align-items: center;
    gap: 12px;
}
.left-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
button{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 24px;
    border-radius: 20px;
    background-color: #EAEAEA;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    border: none;
    padding: 2px 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16); 
    user-select: none;
    transition: all 0.25s;
    &:hover,&:active{
        background-color: #4D4D4D;
        color: white;
        svg{
            path{
                fill: white;
            }
        }
    }

}
.confirmation-container{
    display: flex;
    align-items: center;
    gap: 8px;
    .label-text{
        color: #7D7D7D;
        font-size: 14px;
    }
}
.button-container{
    display: flex;
    align-items: center;
}
.preview-btn{
    display: flex;
    align-items: center;
    gap: 8px;
}
.not-found{
    &.content{
        height: 100%;
        align-items: flex-end;
    }
    .progress{
        width: 78px;
        height: 78px;
        max-width: 78px;
        max-height: 78px;
        svg{
            height: 100%;
            width: 100%;
        }
    }
}
.progress .not-filled{
    stroke: #E2E2E2
}
.progress .filled{
    stroke: linear-gradient(90deg, rgba(22,237,164,1) 0%, rgba(51,196,147,1) 100%);
}
.download-btn{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0;
    justify-content: center;
}
</style>
<style lang="scss">
.card-date-dropdown{
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    border-radius: 6px;
    border: 1px solid #EAEAEA;
    &.dropdown-container{
        &:not(.no-padding-no-bg){
            background-color: white;
        }
    }
}
</style>