<template>
<div class="bubbles" >
</div>
</template>

<script>
export default {
  props: {
    videoPlayer: {
      type: Object,
      required: true
    },
    timeframes: {
      type: Array,
      required: true
    }
  },
  watch:{
    'timeframes':{
        handler(newVal, oldVal){
          if(JSON.stringify(newVal)!=JSON.stringify(oldVal)){
            this.destroyBubbles();
            this.initCustomTimebar();
          }
        },
        deep: true
    },
    "$store.state.currentVtest"(){
        this.destroyBubbles();
        this.initCustomTimebar();
      },
  },
  mounted() {
    this.initCustomTimebar();
  },
  beforeDestroy(){
    this.destroyBubbles();
  },
  methods: {
    initCustomTimebar() {
      const player = this.videoPlayer;
      const progressControl = player.controlBar.progressControl;
      const timeTooltipContainer = progressControl.el().querySelector('.vjs-progress-holder');
      const bubbleContainer = document.createElement('div');
      let duration = player.duration();
      let currentTime = player.currentTime();
      bubbleContainer.classList.add('vjs-bubble-container');
      this.timeframes.forEach((timeframe, index) => {
        const timeTooltip = document.createElement('div');
        timeTooltip.classList.add('vjs-bubble');
        if(timeframe.video_time == Math.floor(currentTime) && timeframe.is_triggered){
          timeTooltip.classList.add('vjs-bubble--active');
        }
        else{
          timeTooltip.classList.remove('vjs-bubble--active');
        }
        if(index == this.$store.state.currentVtest){
          timeTooltip.classList.add('vjs-bubble--active');
        }
        timeTooltip.style.left = (timeframe.video_time/duration)*100 + '%';
        timeTooltip.style.visibility = 'visible';
        if(timeframe.video_time <0){
          timeTooltip.style.visibility = 'hidden';
        }
        timeTooltip.addEventListener('click', ()=>this.setCurrentVtest(index))
        bubbleContainer.appendChild(timeTooltip);
      });
      timeTooltipContainer.appendChild(bubbleContainer);
    },
    setCurrentVtest(index){
      this.$store.state.currentVtest = index;
    },
    destroyBubbles(){
         const player = this.videoPlayer;
        const progressControl = player.controlBar.progressControl;
        if(progressControl && progressControl.el()){
          const timeTooltipContainer = progressControl.el().querySelector('.vjs-progress-holder');
          if(timeTooltipContainer){
              const bubbleContainer = timeTooltipContainer.querySelector('.vjs-bubble-container');
              timeTooltipContainer.removeChild(bubbleContainer);
          }
        }
    }
  }
}
</script>

<style lang="scss">
.vjs-bubble {
    position: absolute;
    top: -20px;
    margin-left: -3px;
    pointer-events: all;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 0.5px 0.5px #000;
    transition: 0.25s;
    &:hover{
      background-color: var(--secondary-color);
    }
}
.vjs-bubble--active{
    background: var(--secondary-color);
}
.video-js .vjs-big-play-button {

  background-image: url('../../../assets/images/icons/play-button.png');
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  &:before {
    content: "";
    display: none;
  }
  &:hover {
    background-color: transparent;
    opacity: .7;
  }
  .vjs-icon-placeholder{
    display: none;
  }
}
.video-js:hover .vjs-big-play-button{
  background-color: transparent;
}
</style>
