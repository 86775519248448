<template>
  <div class="folder-page-container" v-if="loaded == true">
     <HeaderButtons
     :options="{showBackBtn:false, mode: 'view'}"
    />
    <!-- <div class="download-files">
      <button @click="downloadTrainingFiles">
        Atsisiųsti failus
      </button>
    </div> -->
    <div class="folder-container">
      <div
        class="folder-rows"
        :class="{ 'grid-center':  $store.state.system_settings.grid_dynamic_size != 0 ? trainingRowGroups[rowIndex].length == 1 : false}"
        v-for="(row, rowIndex) in trainingRows"
        :key="'folder-row-' + rowIndex"
      >
        <div
          class="folder-block"
          @click.stop="openGroup(folder.id)"
          :class="[
            { bright: $store.state.system_settings.color_palette == 0 },
            { medium: $store.state.system_settings.color_palette == 1 },
            { blank: $store.state.system_settings.color_palette == 2 },
          ]"
          v-for="(folder, fIndex) in trainingRowGroups[rowIndex]"
          :key="'folder-block-' + fIndex"
          :style="getFolderStyle(folder, rowIndex)"
        >
          <span
            class="folder-name"
            :class="{
              darkLetters:
                folder.id == -1 &&
                $store.state.system_settings.color_palette == 0,
            }"
            >{{ folder.name }}</span
          >
          <div
            class="bottom-line"
            :class="[
              { end: folder.trainings.length == 0 },
              {
                darkLetters:
                  folder.id == -1 &&
                  $store.state.system_settings.color_palette == 0,
              },
            ]"
          >
            <span class="training-count" v-if="folder.trainings.length != 0"
              >{{ toLocal("groups.finished_trainings") }}
              {{ folder.ended_percent.toFixed(0) }} %</span
            >
            <div class="arrow-container">
              <ArrowRightFadeSvg
                :class="{
                  darkLetters:
                    folder.id == -1 &&
                    $store.state.system_settings.color_palette == 0,
                }"
              />
            </div>
          </div>
          <div class="bottom-bar">
            <div
              class="inner-bar"
              :style="`width: ${folder.ended_percent.toFixed(
                0
              )}%; border-top-right-radius: ${
                folder.ended_percent.toFixed(0) == 100 ? '0px' : '16px'
              }`"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OptionDotsSvg from "@/assets/images/icons/option-dots.svg?inline";
import EditFolderNameSvg from "@/assets/images/icons/edit-folder-name.svg?inline";
import GroupFolderTrashSvg from "@/assets/images/icons/group-folder-trash.svg?inline";
import HeaderButtons from "@/components/layout/HeaderButtons.vue";
import VueScrollTo from "vue-scrollto";
import GlobalModal from "../components/layout/modals/GlobalModal.vue";
import PlusNarrowSvg from "@/assets/images/icons/plus-narrow-white.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/group-search-black.svg?inline";
import LoadingBubbleSvg from "@/assets/images/icons/loading-bubble.svg?inline";
import ArrowRightFadeSvg from "@/assets/images/icons/arrow-right-fade.svg?inline";
export default {
  name: "TrainingHomeFolders",
  data: function () {
    return {
      isFolderCreating: false,
      searchTerm: "",
      isHeaderInView: true,
      colorSliderPos: 0,
      colorHue: 5.5,
      groupName: "",
      addingTraining: false,
      addingNewGroup: false,
      trainingGroupsClone: [],
      trainingGroups: [],
      searchTrainingGroups: [],
      loaded: false,
      selectedTraining: {},
      isSearching: false,
      searchInputValue: "",
      trainingRows: 0,
      trainingRowGroups: [],
      activeOptionMenu: -1,
      colorExpanded: false,
      renameMode: false,
      deleteModalOpened: false,
      infoModalOpened: false,
      colorPalettes: [
        [
          "rgba(230, 69, 69, 1)",
          "rgba(229, 115, 0, 1)",
          "rgba(191, 143, 0, 1)",
          "rgba(100, 153, 46, 1)",
          "rgba(46, 153, 46, 1)",
          "rgba(46, 153, 100, 1)",
          "rgba(46, 153, 153, 1)",
          "rgba(69, 150, 229, 1)",
          "rgba(69, 69, 229, 1)",
          "rgba(150, 69, 230, 1)",
          "rgba(153, 46, 153, 1)",
          "rgba(229, 69, 150, 1)",
        ],
        [
          "rgba(229, 115, 115, 1)",
          "rgba(229, 166, 103, 1)",
          "rgba(229, 229, 103, 1)",
          "rgba(184, 229, 138, 1)",
          "rgba(138, 229, 138, 1)",
          "rgba(115, 229, 172, 1)",
          "rgba(115, 229, 229, 1)",
          "rgba(115, 172, 229, 1)",
          "rgba(138, 138, 229, 1)",
          "rgba(184, 138, 230, 1)",
          "rgba(229, 138, 229, 1)",
          "rgba(229, 138, 184, 1)",
        ],
        [
          "rgba(229, 184, 184, 1)",
          "rgba(229, 207, 184, 1)",
          "rgba(229, 229, 184, 1)",
          "rgba(207, 229, 184, 1)",
          "rgba(184, 229, 184, 1)",
          "rgba(184, 229, 207, 1)",
          "rgba(184, 229, 229, 1)",
          "rgba(184, 207, 229, 1)",
          "rgba(184, 184, 229, 1)",
          "rgba(207, 184, 229, 1)",
          "rgba(229, 184, 229, 1)",
          "rgba(229, 184, 207, 1)",
        ],
      ],
      color1: "rgba(230, 69, 69, 1)",
      color2: "rgba(229, 115, 0, 1)",
      windowWidth: window.innerWidth,
    };
  },
  components: {
    OptionDotsSvg,
    EditFolderNameSvg,
    GroupFolderTrashSvg,
    HeaderButtons,
    GlobalModal,
    PlusNarrowSvg,
    GroupSearchSvg,
    LoadingBubbleSvg,
    ArrowRightFadeSvg,
  },
  mounted() {
    this.$store.state.loading.loadingStart();
    this.loaded = false;
    setTimeout(() => {
      this.loadTrainings();
    }, 10);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    handleScroll() {
      if (this.$refs["headerbuttons"] != undefined) {
        if (this.$refs["headerbuttons"].getBoundingClientRect().top >= 100) {
          this.isHeaderInView = true;
          this.$store.state.showingHeaderSearchBar = false;
        } else {
          this.isHeaderInView = false;
          this.$store.state.showingHeaderSearchBar = true;
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.makeRows();
    },
    openGroup(id) {
      this.$store.state.currentGroupOpen = ( id == -1 ? 'other' : id);
      this.$router.push("/group-trainings?id="+( id == -1 ? 'other' : id));
    },
    downloadTrainingFiles() {
      let trainings = this.trainingGroups.map((elem) => {
        let trainings = elem.trainings.map((t) => t.id ? t.id : t);
        
        return trainings  
      });
      trainings = trainings.flat();
      axios
        .post(
          `${this.$store.state.api_link}/training/get-download-files`,
          {
            trainings: trainings
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          let files = response.data.files;
          files.forEach((file, i) => {
            return files[i] = `${this.$store.state.api_uploads_link}${file}`
          });
          window.ReactNativeWebView.postMessage("downloadFiles:" + JSON.stringify(files));
        })
        .catch((error) => {});
    },
    getColumnSize(folder, groups) {
      // let totalTrainings = 0;
      // groups.forEach((group) => {
      //   console.log(group);
      //   totalTrainings += group.trainings.length;
      // });
      // console.log(totalTrainings);
      // const proportion = folder.trainings.length / totalTrainings;
      // folder.columnSize = 6 + proportion * (24 - 6);
      // console.log(folder.columnSize);
      // if (isNaN(folder.columnSize)) {
      //   console.log(folder.columnSize);
      //   folder.columnSize = 6;
      // }
      // console.log(folder.columnSize);
      // folder.columnSize = parseInt(folder.columnSize);
      if (folder.sized == true) {
        return;
      }
      if (groups.length == 2) {
        if (window.innerWidth <= 1200) {
          folder.columnSize = 6;
        } else if (window.innerWidth <= 1700) {
          folder.columnSize = 12;
        } else {
          folder.columnSize = 18;
        }
        folder.sized = true;
      } else if (groups.length == 1) {
        if (this.windowWidth <= 421) {
          folder.columnSize = 1;
        } else {
          if (this.$store.state.system_settings.grid_dynamic_size != 0) {
            folder.columnSize = 10;
          }
          else {
            folder.columnSize = 12;
          }
        }
        folder.sized = true;
      } else {
        groups.forEach((group) => {
          if (this.$store.state.system_settings.grid_dynamic_size != 0) {
            if (group.id != folder.id) {
              if (folder.trainings.length > group.trainings.length) {
                if (group.columnSize != 3) {
                  folder.columnSize += 1;
                  group.columnSize -= 1;
                }
              }
            }
          }
          else {
            folder.columnSize = 12
            group.columnSize = 12
          }
        });
        folder.sized = true;
      }
      // folder.trainings.forEach((training) => {
      //   size += 0.2;
      // });
      folder.columnSize = Math.round(folder.columnSize);
    },
    changeGroupHue(folder, optionalColor) {
      if (optionalColor != undefined) {
        this.colorHue = optionalColor;
      }
      let colorGroup = Math.floor(
        this.colorHue %
          this.colorPalettes[this.$store.state.system_settings.color_palette]
            .length
      );
      this.color1 =
        this.colorPalettes[this.$store.state.system_settings.color_palette][
          colorGroup
        ];
      if (
        this.colorPalettes[this.$store.state.system_settings.color_palette][
          colorGroup + 1
        ] == undefined
      ) {
        this.color2 ==
          this.colorPalettes[this.$store.state.system_settings.color_palette][
            this.colorPalettes[this.$store.state.system_settings.color_palette]
              .length - 1
          ];
      } else {
        this.color2 =
          this.colorPalettes[this.$store.state.system_settings.color_palette][
            colorGroup + 1
          ];
      }
      // this.colorHue /= 100;
      let color1Values = this.color1
        .replace("rgba(", "")
        .replace(")", "")
        .split(",");
      let color2Values = this.color2
        .replace("rgba(", "")
        .replace(")", "")
        .split(",");
      let copiedColor = this.colorHue;
      for (let i = 1; i <= copiedColor; ) {
        copiedColor -= 1;
      }
      let midwayValues = color1Values.map(
        (val, index) =>
          (1 - copiedColor) * parseInt(val) +
          copiedColor * parseInt(color2Values[index])
      );
      midwayValues[midwayValues.length - 1] = 1;
      let color = `rgba(${midwayValues
        .map((val) => Math.floor(val))
        .join(",")})`;
      if (!folder.color_hue)
        folder.color_hue = color;
      if(folder.color_hue && !folder.color_hue.startsWith('#'))
        folder.color_hue = color;
    },
    loadTrainings() {
      axios
        .get(
          `${this.$store.state.api_link}/training_group/user_training_groups`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          let temp = response.data;

          if (temp.length == 1 && temp[0] != undefined) {
            this.$store.state.currentGroupOpen = temp[0].id;
            this.$store.state.fromRoute = 'home';
            this.$store.state.isOnlyTrainingGroup = true;
            this.$router.push('/group-trainings?id='+temp[0].id);
          } else if (temp[0] == undefined) {
            this.$store.state.loading.loadingDone();
            return;
          }
          temp.forEach((trainingGroup) => {
            trainingGroup["editingTitle"] = false;
            if (
              trainingGroup.color_slider_pos != null &&
              trainingGroup.color_slider_pos != undefined
            ) {
              this.changeGroupHue(
                trainingGroup,
                trainingGroup.color_slider_pos
              );
            }
            //   trainingGroup.trainings = response.data.data;
            this.addingTraining = false;
            this.addingNewGroup = false;
            if (this.$store.state.loading != null)
              this.$store.state.loading.loadingDone();
            setTimeout(() => {
              this.loaded = true;
            }, 20);
            // }).catch((error) => {
            //   console.log(error)
            // });
          });

          let sortableTemp = temp;
          // sortableTemp.forEach((trainingGroup)=>{
          //     if(trainingGroup.goes_after != 0){
          //       if(trainingGroup.goes_after == -1){
          //         let trainingGroupCopy = _.cloneDeep(trainingGroup);
          //         let currentIndex = temp.indexOf(trainingGroup);
          //         temp.splice(currentIndex, 1);
          //         temp.splice(0, 0, trainingGroupCopy);
          //       }else{
          //         let folderInFront = temp.find((el)=> {return el.id == trainingGroup.goes_after});
          //         let folderIndex = temp.indexOf(folderInFront);
          //         let trainingGroupCopy = _.cloneDeep(trainingGroup);
          //         let currentIndex = temp.indexOf(trainingGroup);
          //         temp.splice(currentIndex, 1);
          //         temp.splice(folderIndex+1, 0, trainingGroupCopy);
          //       }
          //   }
          // })

          // temp.sort((a, b) => (a.name > b.name) ? 1 : -1)
          this.trainingGroups = temp;
          this.trainingGroupsClone = temp;
          this.searchTrainingGroups = _.cloneDeep(temp);
          VueScrollTo.scrollTo(`body`, 100);
          this.makeRows();
          if (this.trainingGroups.length == 0) {
            if (this.$store.state.loading != null)
              this.$store.state.loading.loadingDone();
            setTimeout(() => {
              this.loaded = true;
            }, 500);
          }
        })
        .catch((error) => {
          this.$store.commit("handleError", { error: error });
        });
    },
    makeRows() {
      if (this.trainingGroups != undefined) {
        this.trainingRowGroups = [];
        this.trainingRows = 0;
        let itemInRow = 3;
        if (window.innerWidth <= 1200) {
          itemInRow = 1;
        } else if (window.innerWidth <= 1700) {
          itemInRow = 2;
        }
        this.trainingRows = Math.ceil(this.trainingGroups.length / itemInRow);
        for (let i = 0; i < this.trainingRows; i++) {
          let tempGroup = [];
          for (let j = 0; j < itemInRow; j++) {
            if (this.trainingGroups[i * itemInRow + j] != undefined) {
              this.trainingGroups[i * itemInRow + j].columnSize = 12;
              this.trainingGroups[i * itemInRow + j].sized = false;
              tempGroup.push(this.trainingGroups[i * itemInRow + j]);
            }
          }
          tempGroup.forEach((folder) => {
            if (folder !== undefined && tempGroup !== undefined)
              this.getColumnSize(folder, tempGroup);
          });
          this.trainingRowGroups.push(tempGroup);
        }
        this.$store.state.loading.loadingDone();
        this.isFolderCreating = false;
      }
    },
    deleteGroup(group) {
      if (group.trainings.length == 0) {
        this.$store.state.globalModal.target = group.id;
        this.deleteModalOpened = true;
      } else {
        this.infoModalOpened = true;
      }
    },
    confirmedGroupDelete() {
      if (this.$store.state.globalModal.target != "") {
        axios
          .post(
            `${this.$store.state.api_link}/training_group/delete`,
            { id: this.$store.state.globalModal.target },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.deleteModalOpened = false;
            this.trainingGroups = this.trainingGroups.filter((group) => {
              return group.id != this.$store.state.globalModal.target;
            });
            this.trainingGroupsClone = _.cloneDeep(this.trainingGroups);
            this.makeRows();
            this.$store.state.globalModal.target = "";
          })
          .catch((error) => {
            this.$store.commit("handleError", { error: error });
            this.addingNewGroup = false;
          });
      }
    },
    closeDeleteModal() {
      this.deleteModalOpened = false;
      this.$store.state.globalModal.target = "";
    },
    getFolderStyle(folder, rowIndex) {
      let style = '';
      if (this.$store.state.system_settings.grid_dynamic_size != 0) {
        style+=`grid-column: ${
            this.trainingRowGroups[rowIndex].length == 1 && this.windowWidth >= 1200
              ? '9' + '/'
              : this.windowWidth <= 1200 && this.windowWidth > 768
              ? '5/'
              : ''
          }span ${folder.columnSize};`
      }
      else{
        style +=  this.windowWidth < 768 ? '' : `grid-column: 12 span;`;
      }
      style += `background-color: ${folder.color_hue != null ? folder.color_hue : this.colorPalettes[0][0]};`
      if (this.customSysStyle && this.customSysStyle.training_grid_style) {
        if (this.customSysStyle.training_grid_style.color) {
          style += `color: ${this.customSysStyle.training_grid_style.color};`
        }
        if (this.customSysStyle.training_grid_style['font-weight']) {
          style += `font-weight: ${this.customSysStyle.training_grid_style['font-weight']};`
        }
        if (this.customSysStyle.training_grid_style['font-size']) {
          style += `font-size: ${this.customSysStyle.training_grid_style['font-size']};`
        }
      }
      return style
    }
  },
  watch: {
    searchTerm: {
      handler(newVal) {
        if (newVal != "") {
          this.trainingGroups = this.trainingGroupsClone.filter((group) =>
            group.name.toLowerCase().includes(newVal.toLowerCase())
          );
        } else {
          this.trainingGroups = this.trainingGroupsClone;
        }
        this.makeRows();
      },
    },
    trainingRowGroups: {
      handler(newVal) {
        if (this.$store.state.system_settings.grid_dynamic_size == 0) return;

        if (newVal != undefined) {
          if (
            newVal[newVal.length - 1] != undefined &&
            newVal[newVal.length - 2] != undefined &&
            newVal[newVal.length - 1].length == 1
          ) {
            if (
              newVal[newVal.length - 1] != undefined &&
              newVal[newVal.length - 2][2] != undefined
            ) {
              newVal[newVal.length - 1].push(newVal[newVal.length - 2][2]);
              newVal[newVal.length - 2].pop();
              newVal[newVal.length - 1].forEach((group) => {
                if (window.innerWidth <= 1700) {
                  group.columnSize = 12;
                } else {
                    group.columnSize = 18;
                }
              });
              newVal[newVal.length - 2].forEach((group) => {
                if (window.innerWidth <= 1700) {
                  group.columnSize = 12;
                } else {
                    group.columnSize = 18;
                }
              });
            }
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .grid-center {
    @media (min-width: 769px) {
      grid-template-columns: repeat(24, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: (1fr);
    }
  }
}
.folder-button-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
}
.header-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 0.3s;

  .create-new {
    padding: 8px 16px;
    padding-right: 46px;
    height: 40px;
    color: white;
    background: #333333;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    display: flex;
    position: relative;
    margin-right: 40px;
    user-select: none;
    &:hover {
      background: #e2e2e2;
      color: black;
      .group-plus {
        svg {
          stroke: black;
          fill: black;
        }
      }
    }
    &.active {
      background: #e2e2e2;
      color: black;
      transition: 0.1s;
      .group-plus {
        svg {
          stroke: black;
          fill: black;
        }
      }
    }
    &:active {
      font-weight: bolder;
    }
    cursor: pointer;
    font-size: 16px;
    @media (max-width: 1680px) {
      width: 25%;
    }
    @media (max-width: 1280px) {
      width: 35%;
    }
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    .group-plus {
      position: absolute;
      right: 16px;
      top: 10px;
      svg {
        width: 16px;
        height: 16px;
        stroke: white;
        fill: white;
      }
    }
  }
  .search-bar {
    width: 20%;
    border-radius: 40px;
    position: relative;
    background: #ffffff;
    border: 1px solid #7d7d7d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    display: flex;
    height: 40px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    @media (max-width: 1680px) {
      width: 25%;
    }
    @media (max-width: 1280px) {
      width: 35%;
    }
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    &::placeholder {
      font-size: 16px;
    }
    &::-moz-placeholder {
      font-size: 16px;
    }
    .search-input {
      outline: none;
      border: none;
      width: 90%;
      font-size: 16px;
      background: #ffffff;
      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }
    .search-icon {
      position: absolute;
      right: 16px;
      top: 8px;
    }
  }
}
.folder-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 40px;
  min-height: 70vh;
  align-items: center;
  justify-content: center;
}
.folder-rows {
  width: 100%;
  display: grid;
  @media (min-width: 1700px) {
    grid-template-columns: repeat(36, 1fr);
  }
  column-gap: 40px;
  margin-top: 40px;
  @media (min-width: 1201px) and (max-width: 1700px) {
    grid-template-columns: repeat(24, 1fr);
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    grid-template-columns: repeat(12, 1fr);
  }
}
.folder-block {
  min-height: 300px;
  min-width: 300px;
  max-width: 100%;
  background-color: rgb(97, 3, 3);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  color: white;
  position: relative;
  transition: 0.2s;
  overflow: hidden;
  &.bright {
    color: white;
    svg {
      g {
        fill: white;
      }
      path {
        stroke: white;
      }
    }
  }
  &.medium {
    color: #333333;
    color: #333333;
    svg {
      g {
        fill: #333333;
      }
      path {
        stroke: #333333;
      }
    }
  }
  &.blank {
    color: #333333;
    color: #333333;
    svg {
      g {
        fill: #333333;
      }
      path {
        stroke: #333333;
      }
    }
  }

  @media (max-width: 421px) {
    min-height: 250px;
    min-width: 150px;
  }
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
    .bottom-line {
      .arrow-container {
        svg {
          opacity: 1;
        }
      }
    }
  }
}
.bottom-line {
  display: flex;
  width: 90%;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  bottom: 24px;
  left: 24px;
  cursor: pointer;
  &:has(.training-count){
    justify-content: space-between;
  }
  .arrow-container {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 50%;
    svg {
      opacity: 0.5;
      transition: 0.5s;
    }
    &:hover {
      // background-color: hsla(0, 0%, 100%, 0.199);
      svg {
        opacity: 1;
      }
    }
  }
  &.end {
    justify-content: flex-end;
  }
}
.bottom-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  min-height: 20px;
  border-bottom-right-radius: 16px;
  .inner-bar {
    min-height: 20px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background: rgba(255, 255, 255, 0.2);
  }
}
.folder-name {
  width: 75%;
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 28px;
  height: 68%;
  overflow-y: hidden;
  cursor: pointer;
  word-wrap: break-word;
}
.folder-name-input {
  width: 70%;
  height: 70%;
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 28px;
  word-wrap: break-word;
  background-color: transparent;
  color: white;
  border: none;
  resize: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  font-family: var(--main-site-font);
  &:focus {
    outline: none;
  }
}
.option-dots {
  position: absolute;
  top: 32px;
  right: 24px;
  height: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  svg {
    g {
      opacity: 0.5;
      transition: 0.5s;
    }
  }
  &:hover {
    svg {
      g {
        opacity: 1;
      }
    }
  }
}
.training-count {
  font-size: 20px;
  display: flex;
  align-items: center;
}
@keyframes expand {
  from {
    height: 0%;
  }
  to {
    height: 49%;
  }
}
.fixed {
  width: 50%;
  position: fixed;
  z-index: 1001;
  top: 0;
  height: 11vh;
  max-height: 11vh;

  .create-new {
    width: fit-content;
    padding-right: 3.8vh;
  }
  .search-bar {
    width: fit-content;
    padding-right: 70px;
  }
  @media (max-width: 1280px) {
    max-width: 60%;
    justify-content: flex-end;
    .create-new {
      height: fit-content;
      width: 45%;
      padding-right: 0;
      text-align: center;
      margin-right: 10px;
    }
    .search-bar {
      padding-right: 0;
    }
  }
  @media (max-width: 768px) {
    max-width: 75%;
    width: 75%;
    display: flex;
    justify-content: center;
    left: 20px;
    
    .create-new {
      padding-right: 50px;
      .group-plus {
        top: 35%;
      }
    }
    .search-bar{
      width: 100%;
    }
  }
}
.spinning {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.folder-page-container {
  .folder-block {
    .darkLetters {
      color: rgba(51, 51, 51, 1);
      path {
        stroke: rgba(51, 51, 51, 1);
      }
    }
  }
}
</style>
