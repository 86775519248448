var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('span',{staticClass:"row-label"},[_vm._v(_vm._s(_vm.toLocal('report.shown_colums_label'))+":")]),_c('CustomDropdown',{attrs:{"list":_vm.shownColumns,"key-label":"value","value-label":"label","compare-list":_vm.computedSelectedColumns,"options":{
            closeOnClick: false,
            customDDWidth: 'initial',
            customDDMaxWidth: '300px',
            customDDMinWidth: '100%',
            customMaxHeight: '250px',
            initial: 'generic.select_columns',
            containerClass: 'report-dropdown '+_vm.getContrast(),
            searchBar: false,
            selectAllChoice:_vm.toLocal('generic.all_data'),
            checkbox: true,
            left:true,
            showCreateNewBtn: false,
            valueWidthType: 'dynamic'
        }},on:{"elClicked":_vm.selectShownColumn,"elAll":_vm.selectAllShownColumn},scopedSlots:_vm._u([{key:"arrow",fn:function(){return [_c('svg',{attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1.5L6 6.5L11 1.5","stroke":"#333333","stroke-width":"1.25","stroke-linecap":"round","stroke-linejoin":"round"}})])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }