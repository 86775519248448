import { render, staticRenderFns } from "./UsersTableHeader.vue?vue&type=template&id=49fdc056&scoped=true&"
import script from "./UsersTableHeader.vue?vue&type=script&lang=js&"
export * from "./UsersTableHeader.vue?vue&type=script&lang=js&"
import style0 from "./UsersTableHeader.vue?vue&type=style&index=0&id=49fdc056&lang=scss&scoped=true&"
import style1 from "./UsersTableHeader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49fdc056",
  null
  
)

export default component.exports