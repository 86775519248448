<template>
  <div class="data">
    <div class="data-container" v-if="!isLoading">
      <div v-for="(dataEl, idx) in data" :key="`data-${idx}`" class="data-row">
        <div class="default-row" v-if="!checkIfDataIsArray(dataEl.colValue)">
          <span class="title">
            {{ toLocal(dataEl.col.name) }}:
            <!-- {{ toLocal(dataEl.info_cols.name) }} -->
          </span>
          <span class="value">
            {{ dataEl.colValue ? dataEl.colValue.value : "" }}
          </span>
        </div>
        <div class="table-el-row" v-if="checkIfDataIsArray(dataEl.colValue)">
          <div class="head table-row">
            <div
              class="head-column column" 
              :style="{width : calcCellWidth(dataEl.colValue) + '%'}"
              v-for="(headEl, headIdx) in dataEl.colValue.columns"
              :key="`head-${headIdx}`"
            >
              <span>{{ changeTitleText(headEl) }}</span>
            </div>
          </div>
          <div class="table-inner">
            <div
              class="table-row"
              v-for="(tableEl, tableIdx) in dataEl.colValue.value"
              :key="`table-row-${tableIdx}`"
            >
              <div class="cell-column column" 
              :style="{width : calcCellWidth(dataEl.colValue) + '%'}"
              v-for="(cellEl, cellIdx) in dataEl.colValue.columns" :key="`cell-${cellIdx}`">
                <span>{{ tableEl[cellEl] }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserReadInfo",
  data: function () {
    return {
      data: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadUserInfo();
  },
  methods: {
    changeTitleText(text){
        if(text == 'DokNr'){
            text = this.toLocal('settings.document_number')
        }
        return text;
    },
    clearUneededColumns(columns){
        columns = columns.filter((col)=>{
            return col != 'IsdavimoData' && col != 'GrazinimoData' && col != 'PersonaloNr';
        })
        return columns;
    },
    calcCellWidth(value){
        return 100/value.columns.length;
    },
    checkIfDataIsArray(value) {
      if (value && value.value && typeof value.value == "object") {
        let tableCols = [];
        value.value.forEach((el) => {
          tableCols = tableCols.concat(Object.keys(el));
        });
        tableCols = [...new Set(tableCols)];
        tableCols = this.clearUneededColumns(tableCols);
        value["columns"] = tableCols;
        return true;
      } else return false;
    },
    loadUserInfo() {
      this.isLoading = true;
      axios
        .get(
          `${this.$store.state.api_link}/user/get-self-info`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.data = response.data.data;
          let cols = {};
          this.data.forEach((el, index) => {
            if (el.colValue != null) {
              try {
                el.colValue.value = JSON.parse(el.colValue.value);
                cols[el.col.name] = el.colValue.value;
              } catch (e) {}
            }
         
          });
          this.$store.state.user_cols = cols;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.data-container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  padding: 24px;
  .data-row {
    padding: 12px 0;
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($color: #cacaca, $alpha: 0.5);
    }
    .title {
      width: 30%;
      font-size: clamp(14px, 1.2vw, 16px);
    }
    .value {
      font-weight: 600;
      font-size: clamp(14px, 1.2vw, 16px);
    }
  }
}
.table-el-row{
    display: flex;
    flex-direction: column;
    width: 100%;
    .head{
        display: flex;
    }
    .table-inner{
        display: flex;
        flex-direction: column;
    }


}
.data{
  padding-bottom: clamp(50px, 6vh, 150px)
}
.table-row{
    display: flex;
    width: 100%;
    &.head{
        background: #E2E2E2; 
    }
    .column{
        word-wrap: break-word;
        flex-wrap: wrap;
        font-size: clamp(14px, 1.2vw, 16px);
        padding: 14px 10px;
        &:not(:last-child){
            border-right: 2px solid hsla(0, 0%, 79%, 1);
        }
        &.cell-column{
            font-weight: 600; 
        }
    }
}
</style>
