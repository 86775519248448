<template>
    <div :class="['terms']" v-scroll-lock="$store.state.privacy_policy_modal">
    <div class="terms--inner">
      <div v-html="toLocal('terms.conditions')" />
    </div>
    <div class="close" @click="$store.state.privacy_policy_modal = false">
        <span></span><span></span>
    </div>
  </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
}
</script>

<style lang="scss" scoped>
   @import "@/assets/scss/_variables.scss";
   @import "@/assets/scss/components/layout/_privacyPolicyScoped.scss";
</style>