<template>

        <node-view-wrapper class="img-node-wrapper" draggable="false" :class="{'read-only': $parent.$parent.readOnly}" >
           <vue-resizable 
            class="tiptap-image-borders" 
            :class="{'read-only': $parent.$parent.readOnly}" 
            :minHeight="100" 
            :minWidth="100" 
            :maxHeight="820" 
            :active="$parent.$parent.readOnly ? [] : ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt']" 
            :maxWidth="820" 
            :height="getItemHeight()" 
            :width="Math.min($store.state.window_width, width)" 
            fitParent  
            :left="calcLeftPosition" 
            :top="0" 
            :dragSelector="$parent.$parent.readOnly ? undefined : '.tiptap-image'" 
            @resize:end="onResize" 
            @drag:end="onResize"
           >
                   

            <img @click="openPreview" v-show="!loading" ref="image" class="tiptap-image" :src="getFileUrl(src)" alt="Nuotrauka" @load="loading = false">
            <div class="loading" v-if="loading">
                <font-awesome-icon  icon="spinner" />
            </div>

            </vue-resizable>
        </node-view-wrapper>
</template>
<script>
// import VueDraggableResizable from 'vue-draggable-resizable';
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import { NodeViewWrapper } from '@tiptap/vue-2';
import VueResizable from 'vue-resizable'

export default {
    props: ['node', 'updateAttributes', 'view', 'selected', 'getPos', 'options'],
    components: {
        VueResizable,
        NodeViewWrapper
    },
    data(){
        return {
            loading: true,
        }
    },
    computed: {
        src: {
            get() {
                return this.node.attrs.src;
            },
            set(src) {
                this.updateAttributes({src});
            }
        },
        width: {
            get() {
                return parseInt(this.node.attrs.width);
            },
            set(width) {
                this.updateAttributes({
                    width: width
                });
            }
        },
        height: {
            get() {
                return parseInt(this.node.attrs.height);
            },
            set(height) {
                this.updateAttributes({
                    height: height
                });
            }
        },
         x: {
            get() {
                return parseInt(this.node.attrs.x);
            },
            set(x) {
                this.updateAttributes({
                    x: x
                });
            }
        },
        calcLeftPosition(){
            if (this.$store.state.window_width > 900) {
                if(this.$store.state.trainingMainContainer){
                    let width = this.$store.state.trainingMainContainer.clientWidth;
                    if(width > 900){
                        let ratio = width / 900;
                        return this.x * ratio;
                    }
                }
                else {
                    return this.x;
                }
            }
            else if(this.x+this.width > this.$store.state.window_width){
                // return Math.max(0, this.$store.state.window_width-this.width)
                let relativeLeftPosition = Math.max(0,(this.x/900)*this.$store.state.window_width);
                if(relativeLeftPosition+this.width > this.$store.state.window_width)
                    return 0;
                else
                    return relativeLeftPosition
            }
            else{
                return Math.max(0,(this.x/900)*this.$store.state.window_width)
            
            }

        }
    },

    mounted(){
        if(this.$refs.image){
            let vm = this;
            this.$refs.image.addEventListener('load', function() {
                if(vm.height == 0 && vm.width == 0){
                    if(this.naturalWidth > 820){
                        vm.width = 820;
                        vm.height = this.naturalHeight * (820 / this.naturalWidth);
                    }
                    else{
                        vm.height = this.naturalHeight;
                        vm.width = this.naturalWidth;
                    }
                }
                if(vm.width == 0){
                }
            });
        }
    },
    methods: {

        onResize: function (obj) {
            // console.log(x, y, width, height);
    
            this.width = obj.width;
            this.height = obj.height;
            this.x = obj.left;
        },
        getItemHeight() {
            if ((this.$store.state.window_width < 900) && (this.width > this.$store.state.window_width)) {
                return this.$store.state.window_width/this.width * this.height
            }
            else {
                return this.height
            }
        },
        openPreview() {
            if (this.$route.name != 'training') {
                return
            }
            this.$store.state.itemPreview = {
                open: true,
                item: this.editor.options.item,
                src: this.src
            }
        }, 
    }
}
</script>
<style lang="scss" >
.tiptap-image{
    background-size:cover;
    background-repeat:no-repeat;
    height: 100%;
    width: 100%;
    max-width: 100%;
}
.tiptap-image-borders{
    transition: border-color 0.2s ease-in-out;
    border: 2px dashed transparent;
    
    &:not(.read-only):hover{
        border-color: var(--primary-color);

    }

}
.img-node-wrapper{
    // overflow: hidden;
    &:not(.read-only){
        &.ProseMirror-selectednode {
        outline: 3px solid var(--secondary-color);
        }
    }
}
.loading{
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    bottom: 0;
    right: 0;
    height: 40px;
    width: 40px;
}
</style>