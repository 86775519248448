<template>
<div v-click-outside-2="clickAway" v-scroll-lock="showCertWindow">
    <transition :name="isMobileWindow() ? 'slide-out-mobile' : 'slide-out'">
        <div class="certificate-window-container" :key="showCertWindow" v-if="showCertWindow">
            <div v-if="!isMobileWindow()" class="close-btn" @click="closeCert">
                <span></span><span></span>
            </div>
            <div class="filter-container">
                <div class="search-container">
                    <input type="text" @input="search" v-model="searchInput">
                    <svg v-show="searchInput == ''" xmlns="http://www.w3.org/2000/svg" width="30.166" height="25.977" viewBox="0 0 30.166 25.977">
                        <path id="search" d="M29.6,23.363,21.681,16.5a2.338,2.338,0,0,0-1.508-.49A9.347,9.347,0,0,0,22.624,9.8c0-5.391-5.09-9.8-11.312-9.8S0,4.411,0,9.8s5.09,9.8,11.312,9.8a11.863,11.863,0,0,0,7.164-2.287,1.266,1.266,0,0,0,.566,1.307l7.918,6.862a2.022,2.022,0,0,0,2.639,0A1.278,1.278,0,0,0,29.6,23.363ZM11.312,17.155c-4.713,0-8.484-3.268-8.484-7.352S6.6,2.451,11.312,2.451,19.8,5.718,19.8,9.8,16.025,17.155,11.312,17.155Z" fill="#28345c"/>
                    </svg>
                    <div v-show="searchInput != ''" @click="searchInput = ''; search({target:{value:''}})" class="cancel-search">
                        <span></span><span></span>
                    </div>
                </div>
                <div class="sort-container">
                    <div class="sort-wrapper" @click="sortOptionsActive = !sortOptionsActive">
                        <span> 
                            {{selectedSort == -1 ? toLocal('user_side.sort_by') : toLocal(sortOptions[selectedSort].name) }}
                            <div class="arrow" :class="{active: sortOptionsActive}"></div>
                        </span>
                    </div>
                    <ul v-show="sortOptionsActive">
                        <li v-for="(sortOption, option_id) in sortOptions" :key="option_id" @click="selectedSort = option_id; sortOptionsActive =false; sort(option_id)">
                            {{toLocal(sortOption.name)}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="certificate-window-wrapper">
                <div class="certificate-container">
                    <h1 v-if="certificates.length == 0">{{toLocal('certificates.no_certificates')}}</h1>
                    <div class="certificate-wrapper" v-for="(certificate, cert_id) in certificates" :key="cert_id">
                        <div class="certificate-image">
                            <img :src="`${$store.state.backend_link}${certificate.cert_path}`" alt="">
                        </div>
                        <div class="certificate-info-container">
                            <div class="certificate-title">{{certificate.training_title}}</div>
                            <div class="certificate-dates" :class="{expiring: certificate.expires ? certificate.expiration_status == 1 : '', 'expiring-soon': certificate.expires ? certificate.expiration_status == 2 : '', expired: certificate.expires ? certificate.expiration_status == -1 : ''}">
                                <div class="date" v-if="certificate.expires == 1">
                                    <span>Galioja nuo</span>
                                    <span>{{certificate.created_at.split(' ')[0].split('T')[0]}}</span>
                                </div>
                                <div class="date">
                                    <span>Galioja iki</span>
                                    <span >{{certificate.expires == 1 ? certificate.expiration_date.split('T')[0] : 'Galioja neribotą laiką'}}</span>
                                </div>
                                <div class="certificate-id">
                                <span>{{certificate.cert_custom_id}}</span>
                                </div>
                                <div class="expiring-title" :class="{'expired': certificate.expires == 0 ? false : certificate.expiration_status == -1}" >
                                    <span v-if="certificate.expiration_status == 0 || certificate.expires == 0">sertifikatas galioja</span>
                                    <span v-else-if="certificate.expiration_status == 1">galiojimas greitai baigsis</span>
                                    <span v-else-if="certificate.expiration_status == 2">galiojimas beveik baigėsi</span>
                                    <span v-else-if="certificate.expiration_status == -1">Nebegalioja</span>
                                </div>
                            </div>
                        </div>
                        <div class="certificate-action-container">
                            <div @click.stop="download(certificate)">
                                <span>
                                    <svg v-if="!certificate.loading" xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15">
                                    <g id="Group_8105" data-name="Group 8105" transform="translate(-11.732 -14.744)">
                                        <g id="Group_8040" data-name="Group 8040" transform="translate(12.232 15.244)">
                                        <g id="Group_6883" data-name="Group 6883" transform="translate(0 0)">
                                            <g id="Group_6876" data-name="Group 6876" transform="translate(0 0)">
                                            <g id="Group_6875" data-name="Group 6875" transform="translate(0 5.286)">
                                                <g id="Group_6874" data-name="Group 6874">
                                                <path id="Path_7425" data-name="Path 7425" d="M-122.669,1199.674v5.674a2.626,2.626,0,0,1-2.409,2.793h-11.8a2.626,2.626,0,0,1-2.41-2.793v-5.766" transform="translate(139.285 -1199.582)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                </g>
                                            </g>
                                            <line id="Line_117" data-name="Line 117" y2="10.178" transform="translate(8.191)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                            <path id="Path_7426" data-name="Path 7426" d="M-112.549,1206.589l-2.462,2.462a.691.691,0,0,1-.978,0l-2.355-2.355" transform="translate(123.694 -1199.075)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                    <div class="loader-container" v-else>
                                        <beat-loader :loading="true" :color="$store.state.system_settings.primary_color" size="7px"></beat-loader>
                                    </div>
                                </span>
                                <span>{{toLocal('generic.download')}}</span>
                            </div>
                            <div @click.stop="selectedPreviewID = cert_id; previewMode = true">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.811" height="14.846" viewBox="0 0 28.811 14.846">
                                    <g id="Group_8107" data-name="Group 8107" transform="translate(-8.663 -19.24)">
                                        <g id="Group_8041" data-name="Group 8041" transform="translate(9.286 19.74)">
                                        <path id="Path_561" data-name="Path 561" d="M6929.455,1419.841a17.183,17.183,0,0,1-27.564,0,17.177,17.177,0,0,1,27.564,0Z" transform="translate(-6901.891 -1412.916)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                                        <ellipse id="Ellipse_33" data-name="Ellipse 33" cx="4.53" cy="4.53" rx="4.53" ry="4.53" transform="translate(9.252 2.394)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                                        </g>
                                    </g>
                                    </svg>
                                </span>
                                <span>{{toLocal('generic.view')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="fade">
        <div class="certificate-preview-container" v-if="showCertWindow && previewMode">
            <div class="close-btn" @click="previewMode = false">
                <span></span><span></span>
            </div>
            <div class="certificate-preview" v-if="previewMode" v-click-outside="test">
                    <img v-if="selectedPreviewID!=-1" :src="`${$store.state.backend_link}${certificates[selectedPreviewID].cert_path}`" alt="" @load="certImageLoad">
                    <div :class="{'portrait': orientation == 'portrait'}" class="name" v-if="selectedPreviewID!=-1">{{$store.state.user_name}} {{$store.state.user_last_name ? $store.state.user_last_name : '' }}</div>
                    <div class="finished" v-if="selectedPreviewID!=-1 && orientation == 'landscape'">Sėkmingai pabaigė</div>
                    <div class="title" v-if="selectedPreviewID!=-1 && orientation == 'landscape'" >{{certificates[selectedPreviewID].training_title}}</div>
                    <div  class="dates" v-if="selectedPreviewID!=-1 && certificates[selectedPreviewID].expires == 1 && orientation == 'landscape'">Galioja nuo  {{certificates[selectedPreviewID].created_at.split(' ')[0] +'  iki  '+ certificates[selectedPreviewID].expiration_date.split('T')[0]}}</div>
                    <div :class="{'portrait': orientation == 'portrait'}" class="dates" v-else-if="selectedPreviewID!=-1 && certificates[selectedPreviewID].expires == 0">Galioja neribotą laiką</div>
                    <div :class="{'portrait': orientation == 'portrait'}" class="cert_id" v-if="selectedPreviewID!=-1 && certificates[selectedPreviewID].cert_custom_id && orientation == 'portrait'">{{certificates[selectedPreviewID].cert_custom_id}}</div>
                    <div :class="{'portrait': orientation == 'portrait'}" class="dates" v-if="selectedPreviewID!=-1 && certificates[selectedPreviewID].expires == 1 && orientation == 'portrait'">{{ certificates[selectedPreviewID].expiration_date.split('T')[0]}}</div>
                    <div :class="{'portrait': orientation == 'portrait'}" class="current_date" v-if="selectedPreviewID!=-1  && orientation == 'portrait'">{{ certificates[selectedPreviewID].created_at.split(' ')[0]}}</div>

            </div>
        </div>
    </transition>
</div>
</template>

<script>
import axios from 'axios';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

export default {
    name: 'Certificates',
    components:{
        BeatLoader
    },
    data(){
        return{
            showCertWindow: false,
            certificates: [],
            certificatesCopy: [],
            siteCertificates: [],
            sortOptions: [
                {name: this.toLocal('sort.expiration_date'), sort_key: "expiration_date"},
                {name: this.toLocal('sort.name'), sort_key: "training_title"},
                {name: this.toLocal('sort.issue_date'), sort_key: "created_at"},
                {name: this.toLocal('sort.number'), sort_key: "cert_custom_id"},
            ],
            sortOptionsActive: false,
            selectedSort: -1,
            previewMode: false,
            selectedPreviewID: -1,
            searchInput: '',
            orientation: 'landscape'
        }
    },
    mounted(){
        if(window.ReactNativeWebView) {
            window.addEventListener("message",  this.setWebViewData); //android 
            document.addEventListener("message", this.setWebViewData); //ios
        } 
    },
    beforeDestroy(){
        window.removeEventListener("message", this.setWebViewData)
        document.removeEventListener("message", this.setWebViewData)
    },
    methods:{
        setWebViewData(data){
            if(data.data.startsWith('certsAreOpen:')){
                this.showCertWindow = false
            }
        },
        openCert(){
            this.showCertWindow = true;
            if(window.ReactNativeWebView) 
                window.ReactNativeWebView.postMessage('certificatesOpen:'+true);
        },
        closeCert(){
            this.showCertWindow = false;
            if(window.ReactNativeWebView) 
                window.ReactNativeWebView.postMessage('certificatesOpen:'+false);
        },
        isOpenedCert(){
            return this.showCertWindow;
        },
        appendCertificate(certificate){
            var cert_id =  this.certificates.findIndex((el)=>el.id == certificate.id);
            if(cert_id == -1){
                this.certificates.push(certificate);
            }
            this.sort(0);
        },
        updateCertificate(certificate){
            var cert_id =  this.certificates.findIndex((el)=>el.id == certificate.id);
            if(cert_id != -1){
                this.$set(this.certificates, cert_id, certificate);
            }
        },

        search(e){
            this.certificates = this._.cloneDeep(this.certificatesCopy);
            var input =e.target.value;
            this.certificates = this.certificates.filter((el)=>{
                if(el.training_title.toLowerCase().includes(input.toLowerCase())) return true;
                else if(el.created_at.toLowerCase().includes(input.toLowerCase())) return true;
                else if(el.expiration_date.toLowerCase().includes(input.toLowerCase())) return true;
                else if(el.cert_custom_id.toLowerCase().includes(input.toLowerCase())) return true;
                else return false;
            });
        },
        sort(id){
            var sort_key = this.sortOptions[id].sort_key;
            this.certificates.sort(function (a, b) {
                if(sort_key == 'expiration_date')
                    return a.weight > b.weight ? -1 : 1;
                else
                    return a[sort_key].localeCompare(b[sort_key]);
            });
        },
        getCertificates(){
            axios.get( 
                `${this.$store.state.api_link}/certificates`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                let temp_cert = response.data.certificates;
                temp_cert.sort((a,b)=>{
                    return a.weight > b.weight ? -1 : 1;
                })
                this.certificates = temp_cert;
                this.certificatesCopy = temp_cert;
            }).catch((error) => {
                this.$store.commit("handleError", {"error": error});
            });
            this.getSiteCertificates();
        },
        getSiteCertificates(){
            axios.get( 
            `${this.$store.state.api_link}/certificate/files`,
            this.$store.getters.axiosConfig()
            ).then((response) => {
            if(response.data.status=='success')
                this.siteCertificates = response.data.certificates;
            }).catch((error) => {
            this.$store.commit("handleError", {"error": error});
            });
        },
        download(certificate){
            this.$set(certificate, 'loading', true);
            var id = certificate.id;
             axios.get( 
                `${this.$store.state.api_link}/certificates/download/${id}`,
                this.$store.getters.axiosConfigDownload()
            ).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'eakademija_cert.pdf');
                link.classList.add("download-link");
                document.body.appendChild(link);
                link.click();
                this.$set(certificate, 'loading', false);
            }).catch((error) => {
                this.$store.commit("handleError", {"error": error});
            });
        },
        test(e){
            if(e.target.classList.contains('certificate-preview-container')) this.previewMode = false;
        },
        certImageLoad(e){
            let ratio = e.target.height/ e.target.width;
            if(ratio >1){
                this.orientation = 'portrait'
            }
            else{
                this.orientation = 'landscape'
            }
        },
        clickAway(e){
            if(!this.isMobileWindow()){
                this.closeCert();
            }
        }
        
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_transitions.scss';
@import "@/assets/scss/components/layout/users/_certificatesScoped.scss";
</style>