import { render, staticRenderFns } from "./TestStatisticsTabContent.vue?vue&type=template&id=bea423d6&scoped=true&"
import script from "./TestStatisticsTabContent.vue?vue&type=script&lang=js&"
export * from "./TestStatisticsTabContent.vue?vue&type=script&lang=js&"
import style0 from "./TestStatisticsTabContent.vue?vue&type=style&index=0&id=bea423d6&lang=scss&scoped=true&"
import style1 from "./TestStatisticsTabContent.vue?vue&type=style&index=1&id=bea423d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bea423d6",
  null
  
)

export default component.exports