<template>
  <div class="custom-radio">
    <input type="radio" :id="id" :value="radioValue" :checked="radioValue === selectedValue" @change="handleChange" />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    radioValue: {
      type: String,
      required: true
    },
    selectedValue: {
      type: String,
      required: true
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style scoped>
.custom-radio {
}
label{
    color: #333;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.custom-radio input[type="radio"] {
  display: none;
}

.custom-radio label {
  cursor: pointer;
  padding-left: 25px;
  position: relative;
}

.custom-radio label:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    border: 1px solid #4D4D4D;
    left: 0;
    top: 0;
}

.custom-radio input:checked + label:before {
    background-color: var(--secondary-color);
    border: 1px solid #4D4D4D;
}

.custom-radio label:after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 8px;
  border: solid var(--primary-color);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 7px;
  top: 0px;
  bottom: 2px;
  margin: auto;
  visibility: hidden;
}

.custom-radio input:checked + label:after {
  visibility: visible;
}
</style>