import { render, staticRenderFns } from "./TestTask.vue?vue&type=template&id=96775e80&scoped=true&"
import script from "./TestTask.vue?vue&type=script&lang=js&"
export * from "./TestTask.vue?vue&type=script&lang=js&"
import style0 from "./TestTask.vue?vue&type=style&index=0&id=96775e80&lang=scss&scoped=true&"
import style1 from "./TestTask.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96775e80",
  null
  
)

export default component.exports