<template>
  <div>
    <div 
      :class="['side--background', $store.getters.isCurrentSide('group-side') ? 'side--open' : '']" 
      v-scroll-lock="$store.getters.isCurrentSide('group-side')"
      @click="closeSide" 
    />
    <section :class="{ 'group--side': true, 'side--open': $store.getters.isCurrentSide('group-side'), 'header--disabled': !$store.state.header_enabled }">
      <div class="side--padding">
        <img 
          class="side--padding--corner" 
          src="@/assets/images/side-corner-narrow.svg"
        >
        <div class="side--padding--bottom" />
      </div>
      <div 
        class="side--close" 
        @click="closeSide()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
        <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
          <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
            <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
              <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
            </g>
          </g>
        </g>
      </svg>


      </div>
      <div class="side--content">
        <!-- <div class="group--section--select">
          <div class="group--section--select--button">
            Grupės nariai
          </div>
          <div class="group--section--select--button active">
            Priskirti mokymai
          </div>
        </div> -->
        <div class="header-top">
          <div class="group--title">
            <span v-if="name != '...'">{{ name }}</span>
            <span v-if="name == '...'"><font-awesome-icon icon="spinner" /></span>
          </div>
        </div>
        <div class="group--button--container">
          <div :class="['group--add--button', `gc-${color_id}`]">
            <div class="group--add--button--icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.679" height="26.687" viewBox="0 0 26.679 26.687">
                <g id="Group_1055" data-name="Group 1055" transform="translate(-1115.867 -207.234)">
                  <g id="Group_223" data-name="Group 223" transform="translate(1117.988 208.734)">
                    <g id="Group_222" data-name="Group 222" transform="translate(0 0)">
                      <circle id="Ellipse_22" data-name="Ellipse 22" cx="9.767" cy="9.767" r="9.767" transform="translate(3.524)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
                      <line id="Line_26" data-name="Line 26" y1="6.386" x2="6.386" transform="translate(0 16.679)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                    </g>
                  </g>
                  <g id="Group_1052" data-name="Group 1052" transform="translate(132.297 -1299.329) rotate(45)">
                    <g id="Group_656" data-name="Group 656" transform="translate(1779.738 359.239) rotate(45)">
                      <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                        <path id="Path_751" data-name="Path 751" d="M10.689,5.31a.585.585,0,0,1-.579.579H5.928v4.152a.586.586,0,0,1-.583.575.578.578,0,0,1-.409-.169.558.558,0,0,1-.174-.406V5.888H.579A.579.579,0,0,1,.166,4.9a.609.609,0,0,1,.413-.173H4.762V.583A.562.562,0,0,1,4.936.169.578.578,0,0,1,5.345,0a.587.587,0,0,1,.583.583V4.731H10.11A.588.588,0,0,1,10.689,5.31Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="1.3"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="group--add--button--title">
              <input 
                ref="searchInput"
                type="text"
                v-model="searchString"
                @input="searchInput"
                :placeholder="toLocal('group_side.find_users')"
                @blur="searchString == '' ? searchingUsers = false : ''"
              >
              <font-awesome-icon 
                v-if="searchLoading" 
                icon="spinner" 
              />
            </div>
          </div>
          <div :class="['filter-button', `gc-${color_id}`]" @click="selectedFilter = (selectedFilter + 1) % 3">
            {{userFilter[selectedFilter].text}}
          </div>
        </div>
        <div class="lesson--list">
          <paginate
           v-if="searchingUsers ? searchUsers.length > 0 : users.length > 0"
          v-model="page"
          :page-count="searchingUsers ? totalSearchPageCount : totalPageCount"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="toLocal('generic.back')"
          :next-text="toLocal('generic.next')"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :active-class="`gc-${color_id}`"
          >
        </paginate>
          <table 
            :class="['side--table--control', (searching ? 'hidden' : '')]"
          >
            <thead>
              <tr>
                <th class="large">
                  {{ toLocal('generic.users') }}
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement != 'name'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('name')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_training_count') }}
                  <img
                    v-if="orderByElement == 'training_count' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('training_count')"
                  >
                  <img
                    v-if="orderByElement == 'training_count' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('training_count')"
                  >
                  <img
                    v-if="orderByElement != 'training_count'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('training_count')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_started_percent') }}
                  <img
                    v-if="orderByElement == 'started_percent' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('started_percent')"
                  >
                  <img
                    v-if="orderByElement == 'started_percent' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('started_percent')"
                  >
                  <img
                    v-if="orderByElement != 'started_percent'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('started_percent')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_ended_percent') }}
                  <img
                    v-if="orderByElement == 'ended_percent' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('ended_percent')"
                  >
                  <img
                    v-if="orderByElement == 'ended_percent' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('ended_percent')"
                  >
                  <img
                    v-if="orderByElement != 'ended_percent'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('ended_percent')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_role') }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, user_id) in searchingUsers ? searchUsers : users"
                :key="`${user.belongsToGroup}-${user_id}`"
                :class="[user.belongsToGroup ? `gc-td-${color_id}` : 'inactive']"
              >
                <td >{{ user.name }} {{user.last_name}}</td>
                <td class="centered">
                  <div class="border-left">
                    {{ user.training_count }}
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    {{ user.started_percent.toFixed(1) }}%
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    {{ user.ended_percent.toFixed(1) }}%
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    <img 
                      v-if="user.role == 'user'" 
                      src="@/assets/images/icons/user.svg"
                    >
                    <img 
                      v-if="user.role == 'admin'" 
                      src="@/assets/images/icons/crown.svg"
                    >
                  </div>
                </td>
                <td class="centered actions">
                  <div class="border-left" v-if="name != toLocal('chat.select_all_users')" >
                    <svg v-if="user.belongsToGroup" @click="removeUser(user)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 34">
                      <g id="Group_1052" data-name="Group 1052" transform="translate(-1769 -356)">
                        <g id="Group_656" data-name="Group 656" transform="translate(1785.898 359.239) rotate(45)">
                          <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                            <path id="Path_751" data-name="Path 751" d="M19.461,9.667a1.066,1.066,0,0,1-1.054,1.054H10.792v7.56a1.066,1.066,0,0,1-1.062,1.046,1.053,1.053,0,0,1-.745-.307,1.015,1.015,0,0,1-.317-.739v-7.56H1.054A1.054,1.054,0,0,1,.3,8.928a1.11,1.11,0,0,1,.752-.315H8.669V1.061A1.024,1.024,0,0,1,8.986.307,1.053,1.053,0,0,1,9.731,0a1.069,1.069,0,0,1,1.062,1.061V8.613h7.615A1.071,1.071,0,0,1,19.461,9.667Z" transform="translate(0 0)" fill="#464646"/>
                          </g>
                        </g>
                        <g id="Ellipse_135" data-name="Ellipse 135" transform="translate(1769 356)" fill="none" stroke="#464646" stroke-width="2">
                          <circle cx="17" cy="17" r="17" stroke="none"/>
                          <circle cx="17" cy="17" r="16" fill="none"/>
                        </g>
                      </g>
                    </svg>
                    <svg v-else @click="addUser(user)" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path d="M24.5445 16.0661C24.5414 16.3119 24.4421 16.5467 24.268 16.7202C24.094 16.8938 23.8589 16.9923 23.6131 16.9946L16.8939 16.9841L16.8835 23.6547C16.8785 23.9005 16.7775 24.1345 16.6021 24.3067C16.4267 24.479 16.1908 24.5757 15.945 24.5762C15.6986 24.5762 15.4623 24.4784 15.2881 24.3043C15.1996 24.2202 15.1293 24.119 15.0814 24.0068C15.0334 23.8946 15.0089 23.7738 15.0094 23.6518L15.0198 16.9812L8.3016 16.9707C8.11814 16.9708 7.93874 16.9167 7.78597 16.8151C7.6332 16.7136 7.51388 16.5691 7.44303 16.3998C7.37217 16.2306 7.35296 16.0442 7.38779 15.8641C7.42262 15.684 7.50995 15.5182 7.63878 15.3876C7.81696 15.2144 8.05431 15.1154 8.30275 15.1107L15.0236 15.1212L15.034 8.45767C15.0326 8.3335 15.0568 8.21038 15.1052 8.09599C15.1535 7.9816 15.2248 7.87839 15.3148 7.79281C15.4896 7.61921 15.7262 7.52214 15.9726 7.52296C16.2204 7.52496 16.4576 7.62446 16.6326 7.79992C16.8077 7.97539 16.9067 8.21273 16.9082 8.4606L16.8977 15.1241L23.6168 15.1346C23.8621 15.1391 24.0961 15.2388 24.2692 15.4126C24.4423 15.5864 24.541 15.8208 24.5445 16.0661Z" fill="#464646"/>
                        <path d="M30.117 15.8714C30.0461 8.07481 23.6681 1.81194 15.8714 1.88293C8.0748 1.95393 1.81193 8.33191 1.88293 16.1285C1.95392 23.9252 8.3319 30.1881 16.1285 30.1171C23.9252 30.0461 30.188 23.6681 30.117 15.8714Z" stroke="#464646" stroke-width="1.76471"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="30" height="30" fill="white" transform="translate(1.13721 31.136) rotate(-90.5217)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table 
            :class="['side--table--control', 'search--table', (searching ? '' : 'hidden')]"
          >
            <thead>
              <tr>
                <th class="large">
                  {{ toLocal('generic.users') }}
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('name')"
                  >
                  <img
                    v-if="orderByElement != 'name'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('name')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_training_count') }}
                  <img
                    v-if="orderByElement == 'training_count' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('training_count')"
                  >
                  <img
                    v-if="orderByElement == 'training_count' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('training_count')"
                  >
                  <img
                    v-if="orderByElement != 'training_count'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('training_count')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_started_percent') }}
                  <img
                    v-if="orderByElement == 'started_percent' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('started_percent')"
                  >
                  <img
                    v-if="orderByElement == 'started_percent' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('started_percent')"
                  >
                  <img
                    v-if="orderByElement != 'started_percent'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('started_percent')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_ended_percent') }}
                  <img
                    v-if="orderByElement == 'ended_percent' && orderByDirection == 'desc'"
                    src="@/assets/images/icons/order-by-desc.svg"
                    @click="setOrderBy('ended_percent')"
                  >
                  <img
                    v-if="orderByElement == 'ended_percent' && orderByDirection == 'asc'"
                    src="@/assets/images/icons/order-by-asc.svg"
                    @click="setOrderBy('ended_percent')"
                  >
                  <img
                    v-if="orderByElement != 'ended_percent'"
                    src="@/assets/images/icons/order-by.svg"
                    @click="setOrderBy('ended_percent')"
                  >
                </th>
                <th>
                  {{ toLocal('group.users_role') }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in searchUsers"
                :key="user.id"
              >
                <td>{{ user.name }} {{user.last_name}}</td>
                <td class="centered">
                  <div class="border-left">
                    {{ user.training_count }}
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    {{ user.started_percent.toFixed(1) }}%
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    {{ user.ended_percent.toFixed(1) }}%
                  </div>
                </td>
                <td class="centered">
                  <div class="border-left">
                    <img 
                      v-if="user.role == 'user'" 
                      src="@/assets/images/icons/user.svg"
                    >
                    <img 
                      v-if="user.role == 'admin'" 
                      src="@/assets/images/icons/crown.svg"
                    >
                  </div>
                </td>
                <td class="centered actions">
                  <div class="border-left">
                    <svg @click="addUser(user)" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 48.083 48.083">
                      <g id="Group_1052" data-name="Group 1052" transform="translate(-975.1 -1502.602) rotate(45)">
                        <g id="Group_656" data-name="Group 656" transform="translate(1785.898 359.239) rotate(45)">
                          <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                            <path id="Path_751" data-name="Path 751" d="M19.461,9.667a1.066,1.066,0,0,1-1.054,1.054H10.792v7.56a1.066,1.066,0,0,1-1.062,1.046,1.053,1.053,0,0,1-.745-.307,1.015,1.015,0,0,1-.317-.739v-7.56H1.054A1.054,1.054,0,0,1,.3,8.928a1.11,1.11,0,0,1,.752-.315H8.669V1.061A1.024,1.024,0,0,1,8.986.307,1.053,1.053,0,0,1,9.731,0a1.069,1.069,0,0,1,1.062,1.061V8.613h7.615A1.071,1.071,0,0,1,19.461,9.667Z" transform="translate(0 0)" fill="#464646"/>
                          </g>
                        </g>
                        <g id="Ellipse_135" data-name="Ellipse 135" transform="translate(1769 356)" fill="none" stroke="#464646" stroke-width="2">
                          <circle cx="17" cy="17" r="17" stroke="none"/>
                          <circle cx="17" cy="17" r="16" fill="none"/>
                        </g>
                      </g>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import _ from 'lodash';
import DatePicker from 'vue2-datepicker';
export default {
  name: "GroupSide",
  data: function(){
    return {
      "id": "-1",
      "name": "...",
      "description": "...",
      "users": [],
      "users_copy": [],
      searchUsers: [],
      "color_id": -1,
      "searching": false,
      lastSearch: "",
      searchLoading: false,
      loadInterval: null,
      searchInterval: null,
      orderByElement: "",
      orderByDirection: "",
      searchString: '',
      userFilter: [
        {text: this.toLocal('groups.shown_all'), code: 0},
        {text: this.toLocal('groups.shown_selected'), code: 1},
        {text: this.toLocal('groups.shown_not_selected'), code: 2},
      ],
      selectedFilter: 1,
      page: 1,
      skip:0,
      limit: 50,
      searchSkip: 0,
      searchLimit: 50,
      totalUserCount: 0,
      totalPageCount: 0,
      totalSearchPageCount: 0,
      searchingUsers: false,
      allUsers: [],
      allSearchableUsers: [],
      allFilteredUsers: [],
      deactivationDate: '',
    }
  },
  watch:{
    selectedFilter(){
      this.filterUsers();
    }
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      if(!this.$store.state.side_info['loaded'] && this.$store.state.side_open == "group-side") {
        this.id = this.$store.state.side_info['id'];
        this.$store.state.side_info['loaded'] = true;
        this.name = "...";
        this.description = "...";
        this.users = [];
        this.searchUsers = [];
        this.color_id = -1;
        this.deactivationDate = '';
        axios.get( 
          `${this.$store.state.api_link}/group/info/${this.id}`,
          this.$store.getters.axiosConfig()
        ).then((response) => {
          this.name = response.data.name;
          this.description = response.data.description;
          this.color_id = response.data.color_id;
          if(response.data.deactivation_date != undefined || response.data.deactivation_date != null){
            this.deactivationDate = response.data.deactivation_date
          }
          this.loadUsers();
          this.resort();
        }).catch((error) => {
          console.log(error)
        });
      }
    }, 200);

  },
  beforeDestroy() {
    if(this.loadInterval != null){
      clearInterval(this.loadInterval);
      this.loadInterval = null;
    }
    if(this.searchInterval != null){
      clearInterval(this.searchInterval);
      this.searchInterval = null;
    }
    this.$store.state.side_open = "";
  },
  methods: {
    setDeactivationDate(){
          if(this.deactivationDate != ''){
            axios.post(`${this.$store.state.api_link}/group/deactivationDate/${this.id}`, {deactivationDate: this.deactivationDate}, this.$store.getters.axiosConfig()).then((response) => {

            }).catch((error) => {
                console.log(error);
            });
          }
    },
    clearDeactivationDate(){
      axios.delete(`${this.$store.state.api_link}/group/deactivationDate/${this.id}`, this.$store.getters.axiosConfig()).then((response) => {

        }).catch((error) => {
            console.log(error);
        });
      },
    filterUsers(){
      let users = this.searchingUsers ? this.allSearchableUsers : this.allUsers;
      this.allFilteredUsers = users;
      let paginated_users = this.searchingUsers ? this.searchUsers : this.users;
      var filter_val = this.userFilter[this.selectedFilter]
      if(filter_val.code == 1)  this.allFilteredUsers = users.filter((el)=> el.belongsToGroup == true);
      else if(filter_val.code == 2)  this.allFilteredUsers = users.filter((el)=> el.belongsToGroup == false);
        this.page = 1;
      paginated_users = this.allFilteredUsers.slice((this.page-1) * this.limit, (this.page) * this.limit);
      if(this.searchingUsers){
        this.totalSearchPageCount = Math.ceil(this.allFilteredUsers.length / this.searchLimit);
        this.searchUsers = paginated_users;
      } else {
       
          this.totalPageCount = Math.ceil(this.allFilteredUsers.length / this.limit);
        this.users = paginated_users;
      }

    },
    closeSide() {
      this.$store.state.side_open = false;
      this.name = "...";
      this.description = "...";
      this.color_id = -1;
      this.skip = 0;
      this.users = [];
      this.users_copy = [];
      this.searchUsers = [];
      this.selectedFilter = 0;
      this.searchingUsers = false;
      this.allUsers = [];
      this.allSearchableUsers = [];
      this.allFilteredUsers = [];
      this.loadInterval = null,
      this.searchInterval = null;
      this.orderByElement = "";
      this.orderByDirection = "";
      this.searchString = '';
      this.page = 1;
      this.skip =0;
      this.limit = 50;
      this.searchSkip = 0;
      this.searchLimit = 50;
      this.totalUserCount = 0;
      this.totalPageCount = 0;
      this.totalSearchPageCount = 0;
      this.selectedFilter= 1;
    },
    clickCallback (pageNum){
      var filter_val = this.userFilter[this.selectedFilter];
      let temp_users = [];
      if(filter_val.code == 1) temp_users = this.allFilteredUsers;
      else if(filter_val.code == 2) temp_users = this.allFilteredUsers;
      else if(filter_val.code == 0 && !this.searchingUsers) temp_users = this.allUsers;
      else temp_users = this.allSearchableUsers;
       if(!this.searchingUsers){
        if(!this.searchingUsers && filter_val.code == 0 && this.totalUserCount != this.allUsers.length){
          this.skip = (pageNum-1) * this.limit;
          this.loadUsers();
        }
        else{
         this.users = temp_users.slice((pageNum-1) * this.limit, (pageNum) * this.limit);
        }
      }
      else{
         this.searchUsers = temp_users.slice((pageNum-1) * this.limit, (pageNum) * this.limit);
      }
    },
    loadUsers(){
      //let query = `${this.searchInput != '' ? '?q='+this.searchInput : ''}${this.userFilter[this.selectedFilter].code ? this.searchInput != '' ?  '&filter='+this.userFilter[this.selectedFilter].code : '?filter='+this.userFilter[this.selectedFilter].code : ''}`;
      axios.get( 
          `${this.$store.state.api_link}/group/users/${this.id}/${this.skip}/${this.limit}`,
          this.$store.getters.axiosConfig()
        ).then((response) => {
          let temp = response.data.users.slice(this.skip, this.page * this.limit);
          this.users = temp;
          if(response.data.count &&  this.totalUserCount == 0) {
            this.totalUserCount = response.data.count;
            this.totalPageCount = Math.ceil(this.totalUserCount / this.limit);
          }
        
          this.allUsers = response.data.users;
          this.allSearchableUsers = response.data.users;
          if(this.allUsers.length != this.totalUserCount) {
            this.searchUsers= response.data.users
            this.users= response.data.users
          }
          this.users_copy = this.lodash.cloneDeep(this.users);
          this.filterUsers();
        });
    },
    addUser(user) {
      var user_id = user.id;
      var index = this.users_copy.findIndex(el => el.id == user.id);
      if(index != -1)
        this.$set(this.users_copy[index], 'belongsToGroup', true);
      var index_user = this.users.findIndex(el => el.id == user.id);
      if(index_user != -1)
        this.$set(this.users[index_user], 'belongsToGroup', true);
      var index_search_user = this.searchUsers.findIndex(el => el.id == user.id);
      if(index_search_user != -1)
        this.$set(this.searchUsers[index_search_user], 'belongsToGroup', true);
      this.$emit('UserCountModified', this.id, this.allUsers.filter(el => el.belongsToGroup == true).length);
      axios.post(
        `${this.$store.state.api_link}/group/user/add`,
        {group: this.id, user: user_id},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){

        }else{
          console.log("Error while adding user");
        }
      })
      .catch(() => {
        console.log("Server error while adding user");
      });
    },
    removeUser(user) {
      var user_id = user.id;
      var index = this.users_copy.findIndex(el => el.id == user.id);
      if(index != -1)
        this.$set(this.users_copy[index], 'belongsToGroup', false);
      var index_user = this.users.findIndex(el => el.id == user.id);
      if(index_user != -1)
        this.$set(this.users[index_user], 'belongsToGroup', false);
      var index_search_user = this.searchUsers.findIndex(el => el.id == user.id);
      if(index_search_user != -1)
        this.$set(this.searchUsers[index_search_user], 'belongsToGroup', false);
      this.$emit('UserCountModified', this.id, this.allUsers.filter(el => el.belongsToGroup == true).length);
      axios.post(
        `${this.$store.state.api_link}/group/user/remove`,
        {group: this.id, user: user_id},
        this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){
          // this.$store.state.side_info['loaded'] = false;
        }else{
          console.log("Error while removing user");
        }
      })
      .catch(() => {
        console.log("Server error while removing user");
      });
    },
    resort() {
      this.setOrderBy(this.orderByElement);
      this.setOrderBy(this.orderByElement);
    },
    setOrderBy(element) {
      if(element == "")
        return;
      if(element == this.orderByElement){
        this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
      }else{
        this.orderByElement = element;
        this.orderByDirection = "asc";
      }
      let users = this.searchingUsers ? this.allsearchableUsers : this.allUsers;
      if((users.length && isNaN(users[0][element]))){
        if(this.orderByDirection == "desc"){
          if(users.length) users.sort((a,b) => b[element].localeCompare(a[element])); 
        }else{
          if(users.length) users.sort((a,b) => a[element].localeCompare(b[element])); 
        }
      }else{
        if(this.orderByDirection == "desc"){
          if(users.length) users.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
        }else{
          if(users.length) users.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
        }
         if(this.searchingUsers){
        this.searchUsers = users;
      }
      else{
        this.users = users;
      }
      }
    },
     searchInput(e){
      this.searchForUsers(e, this);
    },
    searchForUsers: _.debounce((e, self) => {
      if(!e.target)
        return;
      let search = e.target.value.toLowerCase();
      let allUsers= self.allUsers;
      if(search == self.lastSearch || search == '' && self.lastSearch == ''){
        if(search == '' && self.lastSearch != ''){
          self.searchingUsers = false;
          self.page = 1;
          self.users = self.allUsers.slice((self.page-1) * self.limit, (self.page) * self.limit);
        }
        return;
      }
      else{
        self.allSearchableUsers = allUsers.filter(user => {
          let name = user.name.toLowerCase();
          let last_name = user.last_name ? user.last_name.toLowerCase() : null;
          if(last_name && last_name != null)
            return name.includes(search) || last_name.includes(search);
          else
            return name.includes(search);
        });
        self.page = 1;
        self.totalSearchPageCount = Math.ceil(self.allSearchableUsers.length / self.searchLimit);
        self.searchUsers = self.allSearchableUsers.slice(self.searchSkip, self.searchSkip*self.searchLimit + self.searchLimit);
      }
      self.filterUsers();

      self.lastSearch = search;
      if(!self.searchingUsers)
        self.searchingUsers = true;
    }, 500)
  },
  components:{
    DatePicker 
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";

    .side--background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .group--side {
        position: fixed;
        right: -850px;
        background-color: #fff;
        height: calc(100vh - 100px);
        top: 100px;
        width: 750px;
        z-index: 11;
        font-family: var(--main-site-font);
        transition: .3s right;

        &.side--open {
          right: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }

    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 47px;
            height: 47px;
            path{
              fill: var(--primary-color)
            }
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      width: 40px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -4px;
      }
    }

    .group--section--select {
      margin-top: -60px;

      .group--section--select--button {
        width: 50%;
        float: left;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        user-select: none;
        background-color: $background_color;
        box-shadow: 0 0 5px rgba(0,0,0,0);
        transition: .3s box-shadow, .3s background-color;

        &.active, &:hover {
          box-shadow: 0 0 5px rgba(0,0,0,.3);
          background-color: $group_color;
        }
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .group--title{ 
      font-size: 35px;
      padding-top: 15px;
      font-weight: 900;
      padding-left: 30px;
      width: 80%;

      span {
        font-size: 35px;
        display: block;
      }
    }

    .lesson--list {
      position: relative;
    }

    .side--table--control {
      margin-top: 50px;
      width: 100%;
      margin: 0;
      position: absolute;
      // right: 0;
      left: 0;
      transition: .3s left;

      &.hidden {
        left: calc(-100% - 150px);

        &.search--table {
          left: calc(200% + 150px);
        }
      }
      
      tr {
        border-radius: 35px;
      }

      svg {
        margin-top: -2px;
      }

      &.search--table {

        tr td {
          border-top: 1px solid #464646;
          border-bottom: 1px solid #464646;

          &:first-child {
            border-left: 1px solid #464646;
          }
          &:last-child {
            border-right: 1px solid #464646;
          }
        }
        svg {
          margin-top: -9px;
        }
      }
    }
    .group--button--container{
      display: flex;
      .filter-button{
        flex: 1;
        border-radius: 35px;
        margin-top: 20px;
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }
    }

    .group--add--button {
      flex: 2;
      padding: 10px;
      border-radius: 35px;
      padding-right: 35px;
      cursor: pointer;
      user-select: none;
      transition: 1s background-color, .3s transform, .3s box-shadow;
      // background-color: #EFEFEF;
      // margin: 0 -35px;
      margin-top: 20px;

      .group--add--button--icon {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 100%;
        vertical-align: middle;

        svg {
          max-width: 24px;
          max-height: 24px;
          fill: var(--primary-color)
        }
      }

      .group--add--button--title {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #707070;
        width: calc(100% - 70px);

        input {
          background: transparent;
          border: none;
          outline: none;
          color: $font_color;
          font-size: 20px;
          font-family: var(--main-site-font);
          width: calc(100% - 40px);
        }
      }

      &:hover {
        box-shadow: 0px 3px 6px #00000033;
        transform: translateY(-3px);
      }
    }
    .inactive{
      td{
        background: #EFEFEF;
      }
    }
    .header-top{
      display: flex;
    }
    .group--disabling--container{
      display: flex;
      justify-content: center;
      text-align: center;
      .deactivation-time{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .deactivate-btn-container{
    display: flex;
    justify-content: center;
    button{
      padding: 2px;
      max-width: 160px;
      width: 80px;
      margin: 4px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
</style>
