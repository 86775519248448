<template>
    <div class="modal-container">
        <div class="modal">
            <div class="header">
                <div class="close-btn" @click="$emit('close')">
                   <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195247 0.197231C0.455588 -0.0631273 0.877698 -0.0631412 1.13806 0.1972L11.8019 10.8603C12.0622 11.1207 12.0623 11.5428 11.8019 11.8031C11.5416 12.0635 11.1195 12.0635 10.8591 11.8032L0.195278 1.14004C-0.0650805 0.879699 -0.0650943 0.457589 0.195247 0.197231Z" fill="#7D7D7D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8047 0.1952C12.0651 0.455515 12.0651 0.877625 11.8048 1.13801L1.14096 11.8047C0.880647 12.0651 0.458536 12.0651 0.198153 11.8048C-0.0622311 11.5445 -0.0622873 11.1224 0.198028 10.862L10.8619 0.195325C11.1222 -0.0650594 11.5443 -0.0651154 11.8047 0.1952Z" fill="#7D7D7D"/>
                    </svg>
                </div>
            </div>
            <div class="row">
                <div class="content">
                    <div class="left">
                        <input 
                        type="text" 
                        :placeholder="toLocal('generic.enter_name')"
                        v-model="name"
                        />
                        <span class="error-message" v-if="errorMessage.length > 0">
                            {{toLocal(errorMessage)}}
                        </span>
                        <div class="checkbox-container">
                            <CheckBox 
                                id="tableBox" 
                                v-model="showInUsersTable"
                                :label="toLocal('users.show_in_profile')"
                            />
                        </div>
                        <div class="radio-container">
                            <span class="label">
                                {{toLocal('users.columns_select_type')}}
                            </span>
                            <div class="radio-wrapper">
                                
                                <RadioButton
                                    v-for="(columnType, index) in columnTypes"
                                    :key="index"
                                    :id="`radio-${columnType.id}`" 
                                    v-model="selectedColumnType" 
                                    :label="columnType.label" 
                                    :radio-value="columnType.type" 
                                    :selected-value="selectedColumnType"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="label">{{toLocal('generic.example')}}:</div>
                        <div class="example-container">
                            <div class="table-header">
                                <span>
                                    {{name.length > 0 ? toLocal(name) : toLocal(selectedColumn.label)}}
                                </span>
                                <div class="tools">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.60243 19.7168C6.28496 20.0774 5.75871 20.0955 5.42453 19.7529C5.41617 19.7439 5.39943 19.7258 5.39108 19.7168L0.228004 13.8212C-0.089464 13.4606 -0.0727225 12.8926 0.261454 12.5501C0.420188 12.3968 0.620747 12.3066 0.837962 12.3066H11.164C11.6235 12.3066 11.9995 12.7123 11.9995 13.2081C11.9995 13.4425 11.9159 13.6589 11.7739 13.8302L6.60243 19.7168Z" fill="#CACACA"/>
                                        <path d="M5.39757 0.281221C5.71504 -0.079373 6.2413 -0.0974379 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549351 7.28574 0.000549393 6.78992C0.000549413 6.55553 0.0841094 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="white"/>
                                    </svg>
                                    <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="20" width="32" height="20" rx="4" transform="rotate(90 20 0)" fill="#4D4D4D"/>
                                        <circle cx="10" cy="10" r="2" transform="rotate(90 10 10)" fill="white"/>
                                        <circle cx="10" cy="16" r="2" transform="rotate(90 10 16)" fill="white"/>
                                        <circle cx="10" cy="22" r="2" transform="rotate(90 10 22)" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div 
                                class="table-content"
                                :class="[selectedColumn.type]"
                            >
                                {{selectedColumn.preview ? selectedColumn.preview : ''}}
                                <CustomDropdown
                                    v-if="selectedColumn.type == 'select'"
                                    class="preview-dropdown"
                                    key-label="id"
                                    value-label="label"
                                    :list="selectedColumn.options"
                                    :compare-list="selectedDropdownItem"
                                    :options="{
                                        closeOnClick: false,
                                        checkbox: false,
                                        customDDWidth: '100%',
                                        customMaxHeight: '300px',
                                        initial: toLocal('users.tag'),
                                        selectAllChoice: toLocal('users.many_folders'),
                                        containerClass: 'groups-table-dropdown',
                                        searchBar: false,
                                        createNewAtEmpty: false,
                                        showCreateNewBtn:false
                                    }"
                                />
                                <date-picker
                                    v-if="selectedColumn.type == 'date'"
                                    v-model="datePreview"
                                    label="date"
                                    :placeholder="toLocal('generic.select_date')"
                                    class="preview-modal-datepicker"
                                    type="date"
                                    valueType="format"
                                >
                                    <template v-slot:icon-calendar>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.630608 13.9861L3.88713 13.2565H3.91442C4.0673 13.2563 4.21864 13.2259 4.35976 13.1672C4.50088 13.1084 4.62901 13.0224 4.7368 12.914L13.6559 3.99858C13.8763 3.7773 14 3.47778 14 3.16554C14 2.85331 13.8763 2.55378 13.6559 2.3325L11.6718 0.34396C11.4505 0.123673 11.1508 0 10.8385 0C10.5261 0 10.2265 0.123673 10.0051 0.34396L1.0807 9.26472C0.971378 9.37432 0.885531 9.50503 0.828388 9.64888C0.771246 9.79273 0.744004 9.9467 0.748324 10.1014L0.0151472 13.3713C0.00015831 13.4446 0.00015831 13.5202 0.0151472 13.5936C0.0295181 13.6602 0.0570145 13.7234 0.096036 13.7793C0.135056 13.8352 0.184823 13.8828 0.242432 13.9193C0.325458 13.9718 0.421556 14.0001 0.519817 14.0007C0.557682 14.0015 0.595481 13.9972 0.632237 13.9881M10.522 0.861047C10.6059 0.777507 10.7196 0.730607 10.8381 0.730607C10.9565 0.730607 11.0702 0.777507 11.1541 0.861047L13.1386 2.84918C13.1807 2.89004 13.2141 2.93898 13.2369 2.99306C13.2596 3.04714 13.2712 3.10525 13.271 3.16391C13.2709 3.22289 13.2592 3.28127 13.2364 3.3357C13.2137 3.39013 13.1805 3.43953 13.1386 3.48109L12.2787 4.34059L9.65968 1.72259L10.522 0.861047ZM1.8375 9.54199L9.14238 2.23845L11.7615 4.85645L4.45657 12.16L1.8375 9.54199ZM1.12958 11.7553L1.47458 10.2138L3.79141 12.5297L2.13444 12.8994L1.12958 11.7553Z" fill="#333333"/>
                                            <path d="M4.00813 10.3577C3.96021 10.358 3.91271 10.3486 3.86849 10.3301C3.82426 10.3116 3.78421 10.2845 3.75071 10.2502C3.68231 10.1815 3.6439 10.0886 3.6439 9.99166C3.6439 9.89475 3.68231 9.80179 3.75071 9.73311L9.31145 4.17505C9.34516 4.14065 9.3854 4.11332 9.42982 4.09467C9.47423 4.07602 9.52192 4.06641 9.57009 4.06641C9.61827 4.06641 9.66596 4.07602 9.71037 4.09467C9.75479 4.11332 9.79503 4.14065 9.82874 4.17505C9.89714 4.24372 9.93555 4.33668 9.93555 4.43359C9.93555 4.5305 9.89714 4.62346 9.82874 4.69213L4.268 10.2502C4.23402 10.2845 4.19353 10.3117 4.14891 10.3301C4.10429 10.3486 4.05643 10.358 4.00813 10.3577Z" fill="#333333"/>
                                        </svg>

                                    </template>
                                </date-picker>
                                <svg v-if="selectedColumn.type == 'checkmark'" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6.25L6.76 13L17 1" stroke="#49B357" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="button-container">
                    <button @click="save">
                        <font-awesome-icon v-if="isSaving" icon="spinner" />
                        {{isSaving ? '' : toLocal('generic.save')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckBox from '@/components/layout/inputs/CheckBox'
import RadioButton from '@/components/layout/inputs/RadioButton'
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import DatePicker from "vue2-datepicker";
import axios from 'axios'
export default {
    components:{
        CheckBox,
        RadioButton,
        CustomDropdown,
        DatePicker
    },
    data(){
        return{
            name: '',
            showInUsersTable: false,
            columnTypes:[
                {
                    id: 1,
                    label: this.toLocal('training_edit.droplet_text'),
                    type: 'text',
                    preview: this.toLocal('training_edit.droplet_text')
                },
                {
                    id: 2,
                    label: this.toLocal('generic.number'),
                    type: 'number',
                    preview: '10'
                },
                {
                    id: 3,
                    label: this.toLocal('generic.list'),
                    type: 'select',
                    options: [
                        {
                            id: 1,
                            label: this.toLocal('generic.option')+' 1',
                        },
                        {
                            id: 2,
                            label: this.toLocal('generic.option')+' 2',
                        },
                        {
                            id: 3,
                            label: this.toLocal('generic.option')+' 3',
                        },

                    ]
                },
                {
                    id: 4,
                    label: this.toLocal('generic.date'),
                    type: 'date'
                },
                {
                    id: 5,
                    label: this.toLocal('generic.checkmark'),
                    type: 'checkmark',
                    
                }
            ],
            selectedColumnType: 'text',
            selectedDropdownItem: [],
            datePreview: '',
            errorMessage: '',
            isSaving: false
        }
    },
    computed:{
        selectedColumn(){
            return this.columnTypes.find((el)=>el.type == this.selectedColumnType);
        }
    },
    methods:{
        save(){
            if(this.isSaving) return;
            this.isSaving = true;
            this.errorMessage = '';
            axios.post(
                `${this.$store.state.api_link}/user/columns`,
                {
                    name: this.name,
                    showInTable: this.showInUsersTable,
                    colType: this.selectedColumnType
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.isSaving = false;
                let new_col = response.data.new_column;
                if(new_col){
                    this.$store.state.userColumns.push(new_col);
                    if(new_col.enabled){
                        this.$set(this.$store.state.enabledUserColumns, new_col.value, new_col.enabled);
                    }

                }
                this.$emit('close');
                location.reload();

            })
            .catch((err) => {
                this.isSaving = false;
                this.errorMessage = err.response.data.message
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.445);
}
.content{
    display: flex;
}
.left,.right{
    display: flex;
    flex-direction: column;
}
.left{
    border-right: 1px solid #CACACA;
    padding-right: 20px;
}
.right{
    padding-left: 20px;
}
.row{
    display: flex;
    flex-direction: row;
}
.modal{
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    background: white;
    padding: 28px 28px 20px;
    max-width: 758px;
}
.radio-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 12px 36px;
}
.checkbox-container{
    margin-top: 8px;
}
.close-btn{
    display: flex;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    &:hover{
        svg{
            path{
                fill:#4D4D4D;
            }
        }
    }
}
.radio-container{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .label{
        color: #333;
        font-family: var(--main-site-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 8px;
    }
}
input{
    border-radius: 6px;
    border:none;
    border-bottom: 2px solid #E2E2E2;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20) inset;
    padding: 8px;
    color: #333;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.error-message{
    color: #ff4343;
    font-family: var(--main-site-font);
    font-size: 14px;
    margin-top: 2px;
    padding: 0 8px;
}
.button-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 48px;
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 44px;
        background: var(--secondary-color);
        color: var(--primary-color);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
        color: #333;
        text-align: center;
        font-family: var(--main-site-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        min-width: 180px;
        padding: 6px 20px;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        transition: all 0.3s;
        &:hover, &:active{
            background: #333;
            color: white;
        }
    }
}
.right{
    .label{
        color: #333;
        font-family: var(--main-site-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
}
.example-container{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    font-family: var(--main-site-font);
    border-radius: 8px;
    background: #FFF;
    min-width: 240px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
    .table-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #4D4D4D;
        padding: 6px 16px 6px 20px;
        color: white;
        font-size: 14px;
        border-radius: 8px 8px 0px 0px;
        font-family: var(--main-site-font);
        .tools{
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
    .table-content{
        display: flex;
        align-items: center;
        background: white;
        color: #4D4D4D;
        text-align: center;
        font-size: 14px;
        border-radius: 0px 0px 8px 8px;
        font-weight: 400;
        padding: 10px 20px;
        &.number, &.checkmark{
            justify-content: center;
        }
    }
}
.preview-dropdown{
    width: 100%;
}
.preview-modal-datepicker{
    width: 100%;
}
</style>
<style lang="scss">
.radio-wrapper{
    .custom-radio{
        min-width: 77px;
    }
}
.preview-dropdown{
    &.dropdown-container{
        &:not(.no-padding-no-bg){
            border-radius: 6px;
            border: 1px solid #B2B2B2;
            background: #FFF;
        }
        .dropdown-initial .white path{
            stroke: #333;
        }
    }
}
.preview-modal-datepicker{
    .mx-input-wrapper .mx-icon-calendar{
        display: flex;
    }
}
</style>