<template>
  <div class="register-container">
    <div class="registration-wrapper">
        <div class="registration-header">
            <h3>
                {{toLocal('login.register')}}
            </h3>
            <select class="lang-select" v-model="selectedLanguage" @change="changeLanguage(selectedLanguage)">
                <option v-for="(language, index) in languages" :key="index" :value="language.toLowerCase()">{{ language }}</option>
            </select>
        </div>
        <div class="input-wrapper">
            <div 
                class="input-outer"
                v-for="(input, index) in form_data" :key="index"
            >
                <span 
                    v-if="form[input.key] != undefined"
                >
                    {{toLocal(input.label)}}
                </span>
                <div class="input-container" v-if="form[input.key] != undefined">
                    <input 
                        :type="input.type" 
                        :placeholder="toLocal(input.placeholder)" 
                        v-model="$v.form[input.key].$model" 
                    >
                    <span class="error-message" v-if="errors[input.key] && errors[input.key].length > 0">
                        <span v-for="(error, index) in errors[input.key]" :key="index">
                            {{getErrorMessage(error)}}
                        </span>
                    </span>
                </div>
            </div>
            <div class="input-outer">
                <div 
                    class="checkmark-container" 
                    :class="{'non-selectable': !selectables[0].isSelectable}"
                    @click="selectables[0].isSelectable ? selectables[0].selected = !selectables[0].selected : ''"
                >
                    <div class="checkmark" :class="[{selected: selectables[0].selected}, {error: errorCode == 4 && selectables[0].code == errorCode && isMobileWindow()}]"  >
                        <div class="checkmark-icon">
                                <svg v-if="selectables[0].selected" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                                <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                                </svg>
                        </div>
                    </div>
                    <span v-html="toLocal(selectables[0].name)"> </span>
                </div>
                <span class="error-message" v-if="errors.privacy">
                    <span>
                        {{toLocal(errors.privacy)}}
                    </span>
                </span>
            </div>
        </div>

        <div class="button-container">
            <button @click="save">
                <font-awesome-icon v-if="isSaving" icon="spinner" />
                {{toLocal('generic.save')}}
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
import * as Sentry from "@sentry/browser";

export default {
    name: 'RegisterToken',
    data(){
        return{
            form: {
                name: '',
                last_name: '',
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
            },
            form_data: [
                {key: 'name', value: '', errorMessage:'', label: 'generic.name', type: 'text', placeholder: 'first_login.enter'},
                {key: 'last_name', value: '', errorMessage:'', label: 'generic.surname', type: 'text', placeholder: 'first_login.enter'},
                {key: 'username', value: '', errorMessage:'', label: 'generic.username', type: 'text', placeholder: 'first_login.enter'},
                {key: 'email', value: '', errorMessage:'', label: 'generic.email', type: 'text', placeholder: 'first_login.enter'},
                {key: 'password', value: '', errorMessage:'', label: 'login.password', type: 'password', placeholder: 'first_login.enter'},
                {key: 'confirmPassword', value: '', errorMessage:'', label: 'first_login.repeat_password', type: 'password', placeholder: 'first_login.enter'},
            ],
            languages:[
                {'label':'Lietuvių', 'value':'lt'},
                {'label':'English', 'value':'en'},
                { 'label': 'По-русски', 'value': 'ru' },
                {'label':'Latviešu', 'value':'lv'},
                {'label':'Eesti', 'value':'et'},
            ],
            errorMessage: '',
            errors:{
                name: '',
                last_name: '',
                username:'',
                email:'',
                password:'',
                confirmPassword:'',
            },
            selectables: [
                {
                    name: 'settings.privacy_policy_agree',
                    isSelectable: true,
                    selected:  false,
                    required:true,
                    code: 4
                }
            ],
            errorCode: -1,
            isSaving: false,
            selectedLanguage: this.$store.state.language,
            languages: [
                'LT', 'EN', 'RU','LV','ET'
            ]
        }
    },
  
    mounted(){
        this.token = this.$route.params.token
        this.checkToken()
    },
    validations: {
        form: {
            name: '',
            last_name: '',
            username:{
                required: requiredIf((el) => {
                    return el.username != undefined;
                }),
                minLength: minLength(2),
                maxLength: maxLength(32),
            },
            password:{
                required: requiredIf((el) => {
                    return el.password != undefined;
                }),
                minLength: minLength(8),
                maxLength: maxLength(1024),
            },
            confirmPassword:{
                required: requiredIf((el) => {
                    return el.confirmPassword != undefined;
                }),
                minLength: minLength(8),
                maxLength: maxLength(1024),
                sameAsPassword: sameAs('password')
            },
            email:{
                required: requiredIf((el) => {
                    return el.email != undefined;
                }),
                minLength: minLength(6),
                maxLength: maxLength(64),
            },
        },
    },
    methods:{
        checkToken(){
            axios
            .get(
                `${this.$store.state.api_link}/register/token/check/${this.token}`,
                this.$store.getters.axiosConfig()
            )
            .then((res) => {
                if (res.data.status == "success") {
                    
                }
            })
            .catch((error) => {
                this.$router.push('/login')
            });
        },
        save(){
            this.$set(this.errors, 'privacy', null);
            if(!this.selectables[0].selected){
                this.$set(this.errors, 'privacy', 'first_login.required_clause');
                return
            }
            if(this.form.password != this.form.confirmPassword){
                this.$set(this.errors, 'confirmPassword', [['settings.validation_error_password_mismatch']]);
                return
            }
            this.isSaving = true;
            axios.post( 
                `${this.$store.state.api_link}/user/register/token`,
                {
                    name: this.form.name,
                    last_name: this.form.last_name,
                    username: this.form.username,
                    password: this.form.password,
                    email: this.form.email,
                    token:this.token
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
               this.isSaving=false;
                this.setUserLoginData(response);

            }).catch((error) => {
                if(error.response.data && error.response.data.errors){
                    let errors = error.response.data.errors;
                    for (let key in errors) {
                        let error_array = []
                        for(let i=0; i<errors[key].length; i++){
                            let formatted = errors[key][i].split(':');
                            error_array.push(formatted);
                        }
                        this.$set(this.errors, key, error_array);
                    }
                }
                this.isSaving=false;
                
            });
        },
        setUserLoginData(res){
            this.$store.state.user_checked = true;
                this.errors = [];
                this.$store.state.user_email = res.data.user.email;
                Sentry.setUser({ email: this.$store.state.user_email });
                this.$store.state.user_id = res.data.user.id;
                this.$store.state.user_name = res.data.user.name;
                this.$store.state.user_last_name = res.data.user.last_name;
                this.$store.state.user_role = res.data.token.original.role;
                this.$store.state.user_is_new = res.data.user.new_user;
                if(res.data.stats){
                    this.$store.state.user_trainings_started = res.data.stats.trainings_started;
                    this.$store.state.user_trainings_ended = res.data.stats.trainings_ended;
                    this.$store.state.user_test_average = res.data.stats.test_average;
                }
                else{
                    this.$store.state.user_trainings_started = 0;
                    this.$store.state.user_trainings_ended = 0;
                    this.$store.state.user_test_average = 0;
                }
                this.$store.state.user_username = res.data.user.username;
                if(res.data.user.phone)
                    this.$store.state.user_phone = res.data.user.phone;
                this.$store.state.user_can_contact = res.data.user.can_contact ? res.data.user.can_contact : 0;
                this.$store.state.user_can_notify = res.data.user.can_notify ? res.data.user.can_notify : 0;
                this.$store.state.user_can_identify = res.data.user.can_identify ? res.data.user.can_identify : 0;
                if(res.data.user.selected_language != null && res.data.user.selected_language != "" && res.data.user.selected_language != undefined){
                    this.$store.state.user_selected_language = res.data.user.selected_language;
                    this.$store.state.language = res.data.user.selected_language;
                }
            
                if(res.data.token.original.new_user == 1){
                    window.scrollTo(0,0);
                    this.$store.state.loading.loadingDone();
                    this.$router.push("/");
                }else{
                    this.$store.state.loading.loadingDone();
                    this.$router.push("/");
                }
                this.$store.state.certificates.getCertificates();
                this.$store.commit("checkTerms", {getters: this.$store.getters});
                this.$store.commit('startWebsockets');
                this.$store.commit('userLoggedIn');
        },
        getErrorMessage(error){
            let number =null;
            let error_formatted = this.toLocal(error[0]);
            if(error[1]){
                number = error[1];
                error_formatted = error_formatted.replace('[number]', number);
            }
            return error_formatted
        },
        changeLanguage(lang){
            this.$store.state.user_selected_language = lang;
            this.$store.state.language = this.$store.state.user_selected_language;
        },
    }
}
</script>

<style lang="scss" scoped>
.register-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--main-site-font);
    min-height: 100vh;
}
.registration-wrapper{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 600px;
    padding: 20px;
    background: white;
    margin: auto;
    border-radius: 20px;
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        height: 100%;
        margin:0;
        flex: 1;
        h3{
            font-size: clamp(20px, 5vw, 26px);
        }
        .input-wrapper{
            span{
                font-size: clamp(16px, 1.5vw, 18px);
            }
        }
        .button-container{
            margin-top: 40px;
            button{
                font-size: 20px;
                width: 50%;
                min-width: 250px;
            }
        }
    }
}
h3{
    margin-top: 0;
    color: #000;
    font-size: clamp(18px, 3vw, 24px);
    margin-bottom: clamp(5px, 1vh, 15px)
}
.input-outer{
    margin-top: clamp(5px, 1vh, 10px);
}
.checkmark-container{
    display: flex;
    align-items: center;
    margin: 12px 0;
    position: relative;
    width: fit-content;
    cursor: pointer;
    .checkmark-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        svg{
            path{
                fill: #000
            }
        }
    }
    .checkmark{
        background: #F9F9F9;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        border: none;
        height: 28px;
        width: 28px;
        min-width: 28px;
        &.error{
            border: 1px solid red;
        }
        &.selected{
            background: var(--secondary-color);
            border: none;
            
        }
    }
    span{
        
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 12px;
        font-family: var(--main-site-font);
    }
    &.non-selectable{
        .checkmark{
            opacity: 0.5;
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
}
.input-container{
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    margin-bottom: 24px;
    margin-top: 0.25rem;
    width: 100%;
    padding: 0;
    border-radius: 8px;
    &:last-child{
        margin-bottom: 0;
    }
    .eye{
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        height: 100%;
        margin: auto;
        cursor: pointer;
        z-index: 2;
    }
    &.password-container{
        input{
            padding-right: 32px;
        }
    }
    input{
        border: 1px solid #ddd;
    }
}
.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    button{
        background: var(--primary-color);
        color: white;
        border-radius: 20px;
        padding: 7px 30px;
        font-size: 16px;
        cursor: pointer;
        border: none;
    }
}
.input-wrapper {
    .error-message{
        display: flex;
        flex-direction: column;
        padding-left: 19px;
        font-size: 12px;
        color: rgb(255, 90, 90);
        span{
            font-size: 12px;
            color: rgb(255, 90, 90);
        }
    }
}
.input-wrapper{
    span{
        font-size: clamp(12px, 1.5vw, 16px);
        font-family: var(--main-site-font);
        font-weight: 600;
        color: #333333;
        // margin-left: 0.75rem;
    }
    input{
        font-size: 16px;
        font-family: var(--main-site-font);
    }
}
input{
    width: 100%;
    color: var(--primary-color);
    background: white;
    border-radius: 8px;
    border: none;
    padding-top: clamp(4px, 1vh, 10px);
    padding-bottom: clamp(4px, 1vh, 10px);
    padding-left:19px;
    padding-right:19px;
    font-size: clamp(16px, 1vw, 20px);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
    -webkit-appearance: none;
    &:focus{
        outline: none;
    }
    &::placeholder{
        color: var(--primary-color-opacity-50);
    }
 
}
input[type="password-field"]{
    -webkit-text-security: disc;
    padding-right: 32px;
}
.registration-header{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    .lang-select{
        position: absolute;
        right: 0;
        top: 0;
    }
}
</style>