<template>
   <div
        v-if="training.content[scrolledTopic] && training.content[scrolledTopic].test"
        class="test--questions--side"
        :class="{'select-mode': $store.state.selectTestsModeActive}"
        @scroll="onFolderListScroll"
      >
        <div class="tools-container" :class="{'show-shadow': showScrollShadow}">
            <div class="search-container" >
                <div class="search-icon">
                    <svg v-show="search.length == 0" id="Group_11461" data-name="Group 11461" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_2331" data-name="Rectangle 2331" width="17" height="17" fill="#28345c"/>
                            </clipPath>
                        </defs>
                        <g id="Group_7557" data-name="Group 7557" clip-path="url(#clip-path)">
                            <path id="Path_6095" data-name="Path 6095" d="M.45,16.552a1.537,1.537,0,0,1,0-2.174L5.037,9.791a6.322,6.322,0,0,1-.461-.919,6.448,6.448,0,1,1,3.552,3.552,6.128,6.128,0,0,1-.918-.461L2.622,16.552a1.54,1.54,0,0,1-2.172,0M6.925,2.816a5.135,5.135,0,1,0,3.63-1.5,5.135,5.135,0,0,0-3.63,1.5Z" transform="translate(0 0)" fill="#28345c"/>
                        </g>
                    </svg>
                <div v-show="search != ''" @click="search = ''; searchQuestionGroups('')" class="cancel-search">
                            <span></span><span></span>
                    </div>
                </div>
                <div class="search-input">
                <input 
                    type="text" 
                    :placeholder="toLocal('test_side.find_test')" 
                    v-model="search"
                    @input="searchQuestionGroups($event.target.value)"
                />
                </div>
            </div>
            <div 
                class="add-button-container" 
                :class="{'is-adding': isAdding, 'hovering': isHoveringCreateBtn}"
                @mouseover="isHoveringCreateBtn = true"
                @mouseleave="isHoveringCreateBtn = false"
                @click.stop="isAdding = true"
                v-click-outside="closeCreateNewItem"
            >
                <button v-show="!isAdding">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.264" height="20.264" viewBox="0 0 20.264 20.264">
                        <g id="Group_11673" data-name="Group 11673" transform="translate(-206.868 -253.302)">
                            <path id="Path_8406" data-name="Path 8406" d="M-18239.449-14636.016v18.264" transform="translate(18456.449 14890.317)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_8407" data-name="Path 8407" d="M0,0V18.264" transform="translate(226.132 263.434) rotate(90)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </svg>
                    {{toLocal('test_side.create')}}
                </button>
                <div class="button-group" v-show="isAdding">
                    <div class="btn-group-item" @click="addNewTestFolder(); isAdding = false">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                            <g id="Group_11730" data-name="Group 11730" transform="translate(-430 -420)">
                                <path id="Path_8227" data-name="Path 8227" d="M18.011,16.581H3.423a1.343,1.343,0,0,1-.482-.089l-.024-.009A1.523,1.523,0,0,1,2,15.065V3.275A1.261,1.261,0,0,1,3.243,2h4.87a.962.962,0,0,1,.608.218L10.6,3.776h7.409a1.473,1.473,0,0,1,1.423,1.516V6.67a.5.5,0,0,1-1,0V5.291a.477.477,0,0,0-.423-.516H10.586a.962.962,0,0,1-.608-.218L8.1,3H3.243A.261.261,0,0,0,3,3.275v11.79a.521.521,0,0,0,.28.485l.013,0a.344.344,0,0,0,.131.025H18.011a.5.5,0,1,1,0,1Z" transform="translate(428 418)" fill="#28345c"/>
                                <path id="Path_8228" data-name="Path 8228" d="M7.526,38.537H22.584a1.077,1.077,0,0,1,.863.444,1.3,1.3,0,0,1,.2,1.139L21.342,48.1a1.125,1.125,0,0,1-1.064.849H5.22a1.077,1.077,0,0,1-.863-.444,1.3,1.3,0,0,1-.2-1.139l2.306-7.98A1.125,1.125,0,0,1,7.526,38.537Zm12.752,9.411c.028,0,.079-.044.1-.127l2.306-7.98a.3.3,0,0,0-.04-.26c-.01-.013-.036-.044-.064-.044H7.526c-.028,0-.079.044-.1.127l-2.306,7.98a.3.3,0,0,0,.04.26c.01.013.036.044.064.044Z" transform="translate(426.136 385.633)" fill="#28345c"/>
                            </g>
                            </svg>
                        </div>
                        {{toLocal('mail.new_folder')}}
                    </div>
                    <span class="divider"></span>
                    <div class="btn-group-item" @click="addNewTestQuestions(scrolledTopic, 0); isAdding = false">
                        <div class="icon">
                            <svg id="Group_11729" data-name="Group 11729" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                <rect id="Rectangle_3371" data-name="Rectangle 3371" width="20" height="15" rx="2" fill="#fff"/>
                                <path id="Rectangle_3371_-_Outline" data-name="Rectangle 3371 - Outline" d="M2,.75A1.251,1.251,0,0,0,.75,2V12.475A1.251,1.251,0,0,0,2,13.725H17.836a1.251,1.251,0,0,0,1.25-1.25V2A1.251,1.251,0,0,0,17.836.75H2M2,0H17.836a2,2,0,0,1,2,2V12.475a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" fill="#28345c"/>
                                <path id="Line_38" data-name="Line 38" d="M14.931.054H-.348a.4.4,0,0,1-.4-.4.4.4,0,0,1,.4-.4H14.931a.4.4,0,0,1,.4.4A.4.4,0,0,1,14.931.054Z" transform="translate(2.492 3.938)" fill="#28345c"/>
                                <path id="Line_132" data-name="Line 132" d="M10.106.054H-.348a.4.4,0,0,1-.4-.4.4.4,0,0,1,.4-.4H10.106a.4.4,0,0,1,.4.4A.4.4,0,0,1,10.106.054Z" transform="translate(2.492 7.691)" fill="#28345c"/>
                                <path id="Line_133" data-name="Line 133" d="M8.5.054H-.348a.4.4,0,0,1-.4-.4.4.4,0,0,1,.4-.4H8.5a.4.4,0,0,1,.4.4A.4.4,0,0,1,8.5.054Z" transform="translate(2.492 11.444)" fill="#28345c"/>
                                <path id="Path_8376" data-name="Path 8376" d="M.983,3.511a1.32,1.32,0,0,1,.147-.653A1.952,1.952,0,0,1,1.65,2.3a3.764,3.764,0,0,0,.4-.331,1.018,1.018,0,0,0,.214-.281.737.737,0,0,0,.068-.326.512.512,0,0,0-.2-.436.925.925,0,0,0-.566-.15,2.04,2.04,0,0,0-.63.1,4.473,4.473,0,0,0-.6.248L0,.421A4.216,4.216,0,0,1,.747.12a3.119,3.119,0,0,1,.9-.12A1.705,1.705,0,0,1,2.809.368a1.212,1.212,0,0,1,.416.953,1.349,1.349,0,0,1-.1.551,1.4,1.4,0,0,1-.3.424,4.8,4.8,0,0,1-.48.412,3.493,3.493,0,0,0-.357.308.669.669,0,0,0-.165.255.965.965,0,0,0-.042.3v.188h-.8ZM.84,4.95A.533.533,0,0,1,1.009,4.5a.649.649,0,0,1,.409-.131.639.639,0,0,1,.4.131.533.533,0,0,1,.169.447.534.534,0,0,1-.169.443.624.624,0,0,1-.4.135.635.635,0,0,1-.409-.135A.534.534,0,0,1,.84,4.95" transform="translate(14.475 6.134)" fill="#28345c"/>
                            </svg>
                        </div>
                        {{toLocal('test_side.new_test')}}
                    </div>
                    <div class="btn-group-item" @click="addNewTestQuestions(scrolledTopic, 1); isAdding = false">
                        <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21">
                            <g id="Group_11728" data-name="Group 11728" transform="translate(-264.561 -235.443)">
                                <rect id="Rectangle_3371" data-name="Rectangle 3371" width="19.948" height="14.8" rx="2" transform="matrix(0.966, -0.259, 0.259, 0.966, 264.561, 240.606)" fill="#fff"/>
                                <path id="Rectangle_3371_-_Outline" data-name="Rectangle 3371 - Outline" d="M2,.75A1.251,1.251,0,0,0,.75,2V12.8A1.251,1.251,0,0,0,2,14.05H17.948A1.251,1.251,0,0,0,19.2,12.8V2A1.251,1.251,0,0,0,17.948.75H2M2,0H17.948a2,2,0,0,1,2,2V12.8a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 264.561, 240.606)" fill="#28345c"/>
                                <g id="Group_11366" data-name="Group 11366" transform="translate(268.213 241.269) rotate(-4)">
                                <rect id="Rectangle_3371-2" data-name="Rectangle 3371" width="19.948" height="14.8" rx="2" fill="#fff"/>
                                <path id="Rectangle_3371_-_Outline-2" data-name="Rectangle 3371 - Outline" d="M2,.75A1.251,1.251,0,0,0,.75,2V12.8A1.251,1.251,0,0,0,2,14.05H17.948A1.251,1.251,0,0,0,19.2,12.8V2A1.251,1.251,0,0,0,17.948.75H2M2,0H17.948a2,2,0,0,1,2,2V12.8a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" fill="#28345c"/>
                                <path id="Line_38" data-name="Line 38" d="M16.089.965H.483A.483.483,0,0,1,.483,0H16.089a.483.483,0,0,1,0,.965Z" transform="translate(1.708 3.2)" fill="#28345c"/>
                                <path id="Line_38-2" data-name="Line 38" d="M9.333.965.483.98a.483.483,0,1,1,0-.965L9.333,0a.483.483,0,1,1,0,.965Z" transform="translate(1.777 7.098)" fill="#28345c"/>
                                <path id="Path_8376" data-name="Path 8376" d="M1,3.586a1.349,1.349,0,0,1,.15-.666,1.994,1.994,0,0,1,.532-.567,3.844,3.844,0,0,0,.41-.338,1.04,1.04,0,0,0,.219-.287.753.753,0,0,0,.069-.333.523.523,0,0,0-.2-.445A.944.944,0,0,0,1.6.8,2.084,2.084,0,0,0,.958.9a4.568,4.568,0,0,0-.613.253L0,.43A4.306,4.306,0,0,1,.763.123,3.185,3.185,0,0,1,1.678,0,1.742,1.742,0,0,1,2.869.375a1.238,1.238,0,0,1,.425.973,1.378,1.378,0,0,1-.1.563,1.428,1.428,0,0,1-.3.433,4.9,4.9,0,0,1-.49.421,3.568,3.568,0,0,0-.364.314.684.684,0,0,0-.168.26.986.986,0,0,0-.042.307v.192H1ZM.858,5.056A.544.544,0,0,1,1.03,4.6a.663.663,0,0,1,.418-.134.652.652,0,0,1,.41.134.544.544,0,0,1,.172.456.545.545,0,0,1-.172.452.638.638,0,0,1-.41.138.648.648,0,0,1-.418-.138.545.545,0,0,1-.172-.452" transform="translate(14.785 6.28)" fill="#28345c"/>
                                </g>
                            </g>
                        </svg>
                        </div>
                        {{toLocal('test_side.new_test_combo')}}
                    </div>
            
                </div>
            </div>
        </div>
        <div class="test--questions--list" ref="folderList" :class="{'is-adding-active': isAdding}">

            <TestFolders
                :testList="searchTestQuestions"
                :selectedTestID="selectedTestID"
                :activeTest="activeTest"
                :selectedFolder="selectedFolder"
                @setTest="(test, folder) => setTestQuestions(scrolledTopic, test.id, folder.id)"
                @selectFolder="selectedFolder = $event"
                @selectModeSelectItem="loadDetailedTestData"
            />
        </div>
      </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import TestFolders from './test_folder_tree/TestFolders.vue'
export default {
    components:{
        TestFolders
    },
    data(){
        return{
            search: '',
            searchTestQuestions: this._.cloneDeep(this.testQuestions),
            selectedTestID: -1,
            selectedFolder: -1,
            addingNewTestQuestions: false,
            addingNewTestCombination: false,
            addingNewFolder: false,
            testQuestionsLast:[],
            questionsLoaded: false,
            isAdding: false,
            isHoveringCreateBtn: false,
            showScrollShadow: false,
      
        }
    },
    props:{
        training:{
            type: Object,
            required: true
        },
        testQuestions:{
            type: Array,
            required: true
        },
        scrolledTopic:{
            type: Number,
        },
        activeTest:{
            type: Number,
        },
        lastSavedTest:{
            type: Object,
        },
    },
    watch:{
        testQuestions:{
            deep: true,
            handler(newVal){
                this.searchTestQuestions = this._.cloneDeep(newVal)
                let testIndex = -1;
                  
                let folderIndex = this.searchTestQuestions.findIndex(folder => {
                    if(folder.tests && folder.tests.length > 0){
                        let item_index = folder.tests.findIndex(test => test.id == newVal.id);
                        if(item_index != -1){
                            testIndex = item_index;
                            return true;
                        }
                        else return false;
                    }
                    else return false;
                });
                if(folderIndex != -1 && testIndex != -1){
                    this.$set(this.searchTestQuestions[folderIndex].tests, testIndex, newVal);
                }
                if(this.search != ''){
                    this.searchQuestionGroups(this.search)
                }
            }
          
        },
        activeTest(){
            this.selectedTestID = this.activeTest
        },
        lastSavedTest(newVal, oldVal){
            if(newVal != null){
                let testIndex = -1;
                let folderIndex = this.searchTestQuestions.findIndex(folder => {
                    let item_index = folder.tests ? folder.tests.findIndex(test => test.id == newVal.id) : -1;
                    if(item_index != -1){
                        testIndex = item_index;
                        return true;
                    }
                    else return false;
                });
                if(folderIndex != -1 && testIndex != -1){
                    this.$set(this.searchTestQuestions[folderIndex].tests, testIndex, newVal);
                }
             
            }
        }
  
    },
    
    mounted(){
        this.selectedTestID = this.activeTest;
        const activeTestWatched = this.$watch('activeTest', (state) =>{
            if(state != 0) {
                this.selectedTestID = state;
                activeTestWatched();
            }

        })
        let vm = this;
          this.$root.$on('update_test_folder', function(folder){
            let folder_index = vm.testQuestions.findIndex(item => item.id == folder.id);
            
            if(folder_index != -1){
                vm.$set(vm.testQuestions, folder_index, folder);
            }
        });
         this.$root.$on('update_test_name', function(folder, test){
            let folder_index = vm.testQuestions.findIndex(item => item.id == folder.id);
            if(folder_index != -1){
                let test_index = vm.testQuestions[folder_index].tests.findIndex(item => item.id == test.id);
                if(folder_index != -1){
                    vm.$set(vm.testQuestions[folder_index].tests[test_index], 'name', test.name);
                }
            }
        });
     
    },
    methods:{
        async addNewTestFolder(){
            if(this.addingNewFolder) return
            this.addingNewFolder = true;
            return axios.post(
                `${this.$store.state.api_link}/test-question-groups/folders`,
                {},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.testQuestions.unshift(response.data.folder);
                this.selectedFolder = response.data.folder.id;
                this.addingNewFolder = false;
                this.isAdding =false;
            })
            .catch((err) => {
                this.addingNewFolder = false;
                console.log("Error while adding new test questions group");
            });
        },
        deleteFolder(folder){
              axios.delete(
                `${this.$store.state.api_link}/test-question-groups/folders`,
                {folder_id: folder.id},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                let index = this.searchTestQuestions.indexOf(folder);
                this.searchTestQuestions.splice(index, 1);
                index = this.testQuestions.indexOf(folder);
                this.testQuestions.splice(index, 1);
            })
            .catch((err) => {
                console.log("Error while adding new test questions group");
            });
        },
        async addNewTestQuestions(scrolledTopic, type) {
            console.log(this.selectedFolder);
            if(this.selectedFolder==-1) await this.addNewTestFolder();
            console.log(this.selectedFolder);
            if(type == 0){
                if(this.addingNewTestQuestions) return;
                this.addingNewTestQuestions = true;
            }
            else if(type == 1 ) {
                if(this.addingNewTestCombination) return;
                this.addingNewTestCombination = true;
            }
            axios.post(
                `${this.$store.state.api_link}/test-question-groups/new`,
                {type: type, folder: this.selectedFolder},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == "success"){
                // this.loadAvailableQuestionGroups(response.data.test_questions_groups);
                    let new_test = response.data.test_questions_groups;
                    new_test.questions.forEach(question => {
                        this.$set(question, 'expanded', false);
                        this.$set(question, 'editing', false);
                    });

                    this.testQuestionsLast.push(JSON.stringify(new_test,this.censorJson(new_test)));
                    let index = this.testQuestions.findIndex(folder => folder.id == this.selectedFolder);
                    if(this.testQuestions[index] && this.testQuestions[index].tests == undefined) this.$set(this.testQuestions[index], 'tests', []);
                    this.testQuestions[index].tests.unshift(new_test);
                    index = this.searchTestQuestions.findIndex(folder => folder.id == this.selectedFolder);
                     if(this.searchTestQuestions[index] && this.searchTestQuestions[index].tests == undefined) this.$set(this.searchTestQuestions[index], 'tests', []);
                    this.searchTestQuestions[index].tests.unshift(new_test);
                    this.setTestQuestions(scrolledTopic, response.data.test_questions_groups.id, this.selectedFolder);
                    this.$emit('setSearchTestQuestions', this.searchTestQuestions);
                    this.$emit('setTestData', new_test, this.selectedFolder.id );
                    this.isAdding =false;
               }else{
                    console.log("Error while adding new test questions group");
                }
                if(type == 0)
                    this.addingNewTestQuestions = false;
                else if(type == 1 ) this.addingNewTestCombination = false;

            })
            .catch((err) => {
                if(type == 0)
                    this.addingNewTestQuestions = false;
                else if(type == 1 ) this.addingNewTestCombination = false;
                console.log(err);
                console.log("Error while adding new test questions group");
            });
        },
     
        setTestQuestions(topic_index, questionsid, folderId = -1) {
            let folderIndex =this.searchTestQuestions.findIndex(folder => folder.id === folderId);
            let questionsindex = 0;
            if(folderIndex != -1){
                let test_id = this.searchTestQuestions[folderIndex].tests.findIndex(test => test.id === questionsid);
                if(test_id != -1){
                    questionsindex = test_id;
                }
            }
            this.$set(this.training.content[topic_index], "selectedTest", questionsid);
            this.$set(this.training.content[topic_index], "questionsindex", questionsindex);
            this.selectedTestID = questionsid;
            this.loadDetailedTestData(folderId, questionsid);
        },
        loadDetailedTestData(folder_id, id){
            axios.get(
                `${this.$store.state.api_link}/test-question-group/${id}`,
                this.$store.getters.axiosConfig()
                ).then((response) => {
                // this.$set(test_list, index, response.data.data);
                this.$emit('setTestData', response.data.data, folder_id);
                }).catch((error) => {
                console.log(error)
                });
        },
         searchQuestionGroups(value){
            this.searchQuestionGroupsDebounce(value, this)
        },
        searchQuestionGroupsDebounce: _.debounce(function(value, self){
            self.searchTestQuestions = _.cloneDeep(self.testQuestions);
            self.searchTestQuestions = self.searchTestQuestions.filter(elem=>{
                let found = false;
                if(elem.name.toLowerCase().includes(value.toLowerCase())) found = true;
                if(elem.tests){
                    elem.tests = elem.tests.filter(test=>{
                        return test.name.toLowerCase().includes(value.toLowerCase());
                    })
                    if(elem.tests.length > 0) found = true;
                }
                return found;
                //return elem.name.toLowerCase().includes(e.target.value.toLowerCase());
            })
            self.$emit('setSearchTestQuestions', self.searchTestQuestions);
        }, 300),

        censorJson(censor) {
            return function(key, value) {
                if(key == "editor")
                return "";
                if(key == "innerScrollTop" || key == "visibleItems")
                return "-";

                return value;  
            }
        },
        closeCreateNewItem(e){
            this.isAdding = false;
        },
        onFolderListScroll(e){
            if(e.target.scrollTop > 0)
                this.showScrollShadow = true;
            else this.showScrollShadow = false;
        }
    }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/_trainingEdit.scss";
</style>
<style lang="scss" scoped>

.test--questions--side{
    position: relative;
}
.tools-container{
    position: fixed;
    top: 0;
    max-width: 475px;
    width: 25vw;
    min-width: 300px;
    margin-left: -3rem;
    padding-left: 3rem;
    padding-right: 25px;
    background: #efefef;
    z-index: 2;
    transition: box-shadow 0.3s ease;
    &.show-shadow{
        box-shadow: 0px 3px 5px -8px #000;

    }
}
.test--questions--list{
    margin-top: 6rem;
    transition: margin-top 0.3s ease;
    &.is-adding-active{
        margin-top: 10rem;
    }
}
.add--new--test--folder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: sticky;
    border: 4px solid white;
    background: none;
    border-radius: 6px;
    color: var(--primary-color);
    cursor: pointer;
    margin: 20px 0;
    svg{
        path{
            stroke: var(--primary-color);
        }
    }
    &:hover{
        font-weight: 500;
    }
}
.test-add-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    .test-add-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: 1px solid var(--primary-color);
        background: white;
        border-radius: 10px;
        color: var(--primary-color);
        cursor: pointer;
        padding: 5px 10px;
        font-size: 16px;
        svg{
            margin-right: 10px;
            path{
                stroke: var(--primary-color);
            }
        }
        &:hover{
            span{
                font-weight: 500;
            }
        }
    }
    span{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .test-add-button:first-child{
        margin-right: 7px;
    }
    .test-add-button:nth-child(2){
        margin-left: 7px;
    }
}
.add-button-container{
    display: flex;
    align-items: center;
    background: white;
    margin: 1rem 0;
    padding: 17px;
    border-radius: 0.5rem;
    font-size: 18px;
    width: 130px;
    height: 56px;
    cursor: pointer;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    button{
        display: flex;
        align-items: center;
        background: none;
        border: none;
        font-size: 18px;
        padding: 0;
        color: var(--primary-color);
        cursor: pointer;
        svg{
            margin-right: 8px;
            path{
                stroke: var(--primary-color);
            }
        }
    }
    .button-group{
        opacity: 0;
        transition: opacity 0.5s;
    }
    &.hovering{
        button{
            svg{
                animation: rotate 1.5s linear infinite; 
            }
        }
    }
   
    &.is-adding{
        width: 100%;
        flex-direction: column;
        border: 1px solid var(--primary-color);
        align-items: flex-start;
        height: 135px;
        .button-group{
            width: 100%; 
            overflow: hidden;
            opacity: 1;
            animation: fadeIn 0.5s;
            .btn-group-item{
                display: flex;
                align-items: center;
                padding: 4px 0;
                color: var(--primary-color);
                .icon{
                    width: 32px;
                    svg{
                        path{
                            fill: var(--primary-color);
                        }
                    }
                }
                &:hover{
                    font-weight: 700;
                }
                &:first-child{
                    padding-top: 0;
                }
                &:last-child{
                    padding-bottom: 0;
                }
               
            }
        }
        .divider{
            display: flex;
            width: 100%;
            height: 1px;
            background: var(--primary-color-opacity-50);
            margin: 4px 0
        }
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@keyframes rotate{
  to{ transform: rotate(360deg); }
}
@keyframes fadeIn{
  0%{ opacity: 0; }
  20%{ opacity: 0; }
  30%{ opacity: 0.2; }
  100%{ opacity: 1; }
}
</style>
<style lang="scss">

.select-mode{
    .tree-select{
        cursor: pointer;
    }
}
</style>