<template>
  <div class="record-content">
      <!-- <VueRecordAudio mode="press" @stream="onStream" @result="onResult"/> -->
        <div 
            @click="toggleRecord" 
            class="record-icon"
            :class="{'is-recording': isRecording}"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24.499" height="39.776" viewBox="0 0 24.499 39.776">
            <g id="Group_7217" data-name="Group 7217" transform="translate(-235.991 -373.389)">
                <g id="microphone" transform="translate(240.42 374.389)">
                <g id="Group_7220" data-name="Group 7220">
                    <g id="Group_7219" data-name="Group 7219">
                    <path id="Path_7531" data-name="Path 7531" d="M248.387,374.389a7.833,7.833,0,0,0-7.816,7.82V394.9a7.82,7.82,0,1,0,15.64.047V382.209A7.833,7.833,0,0,0,248.387,374.389Z" transform="translate(-240.571 -374.389)" fill="none" stroke="#17274b" stroke-width="2"/>
                    </g>
                </g>
                </g>
                <g id="Group_7216" data-name="Group 7216" transform="translate(236.991 395.014)">
                <path id="Path_7530" data-name="Path 7530" d="M237,395.922c0,.034,0,.068,0,.1a11.256,11.256,0,0,0,22.5,0c0-.034-.006-.068-.006-.1" transform="translate(-236.991 -395.922)" fill="none" stroke="#17274b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                <line id="Line_126" data-name="Line 126" y2="5.085" transform="translate(11.315 11.897)" fill="none" stroke="#17274b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                <line id="Line_127" data-name="Line 127" x1="13.367" transform="translate(4.526 17.151)" fill="none" stroke="#17274b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                </g>
            </g>
            </svg>
            <av-media 
                class="visualizer" 
                :media="stream" 
                type="circle"
                :canv-width="75" 
                :canv-height="75"
                :line-width="2"
                :line-space="0.1"
                :radius="25" 
                :line-color=" isRecording ? '#ff9393' : '#A9B9EE'"
                canv-class="visualizer-canvas"
            />
        </div>
      <div class="timer">
          {{timeConverted}}
      </div>

  </div>
</template>

<script>
export default {
    name: 'AudioRecord',
 
    data: function(){
        return{
            timer: 0,
            timerInterval: null,
            recorder: null,
            dataArray: [],
            isRecording: false,
            canvas: null,
            canvasCtx: null,
            stream: null,
        }
    },
    mounted(){
        var vm = this;
        let audioIN = { audio: true };
        // vm.canvas = document.querySelector('#output');
        // vm.canvasCtx = vm.canvas.getContext("2d");
        navigator.mediaDevices.getUserMedia(audioIN)    
            .then(function (mediaStreamObj) {
  
            vm.stream = mediaStreamObj;

            vm.recorder = new MediaRecorder(mediaStreamObj);

            vm.recorder.ondataavailable = function (ev) {
                vm.dataArray.push(ev.data);
            }
        //let data
            vm.recorder.onstop = function (ev) {
    
                let audioData = new Blob(vm.dataArray, 
                            { 'type': 'audio/mp3;' });
                    
                vm.dataArray = [];
        
                    vm.$emit('fileBlob', audioData);
                    vm.$emit('closeModal');
                    clearInterval(vm.timerInterval);
                    vm.timerInterval = null;
                    vm.timer = 0;
            }
      })
      .catch(function (err) {
        console.log(err.name, err.message);
      });
    },
    beforeDestroy(){
        this.stream.getTracks().forEach(track => track.stop())
        this.recorder = null;
        this.isRecording = false;
    },
    computed:{
        timeConverted () {
            var sec = this.timer%60;
            var min = parseInt(this.timer/60);
            var hour = parseInt(min/60);
            if(hour >0){
                return hour.toString().padStart(2, "0")+":"+min.toString().padStart(2, "0") + ":" + sec.toString().padStart(2, "0");

            }
            else return min.toString().padStart(2, "0") + ":" + sec.toString().padStart(2, "0");
        },
    },
    methods:{
        toggleRecord() {
            if(this.isRecording){
                this.recorder.stop();
                // this.stream.getTracks().forEach(track => track.stop())
                // this.recorder = null;
                this.isRecording = false;
            }
            else{
                this.recorder.start();
                this.timerInterval = setInterval(()=>{
                    this.timer +=1;
                },1000)
                this.isRecording = true;
            }
        },

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/layout/_audioRecordScoped.scss";
</style>