<template>
<div 
    v-if="folder"
    :class="{'dragover': draggingOver}"
    class="folder-container" 
    @drop="dropItem(); draggingOver = false" 
    @dragover="draggingOver = true"  
    @dragleave="draggingOver = false" 
    @mouseleave="draggingOver = false"
>
   <div class="tree-select" @click="$store.state.reportForming.selectedFolder == folder.id ? $store.state.reportForming.selectedFolder = -1 : $store.state.reportForming.selectedFolder = folder.id"  >
        <div class="icon arrow" :class="{selected: $store.state.reportForming.selectedFolder == folder.id}">
            <svg xmlns="http://www.w3.org/2000/svg" width="17.103" height="9.422" viewBox="0 0 17.103 9.422">
            <path id="Path_5930" data-name="Path 5930" d="M-3724.094-17220.074a.884.884,0,0,1-.612-.254l-7.685-7.687a.865.865,0,0,1,0-1.225.861.861,0,0,1,1.226,0l7.071,7.068,7.073-7.068a.859.859,0,0,1,1.224,0,.863.863,0,0,1,0,1.225l-7.684,7.688A.884.884,0,0,1-3724.094-17220.074Z" transform="translate(3732.645 17229.496)" fill="#28345c"/>
            </svg>
        </div>
        <div class="label" @click.stop="startTitleEdit($event, folder)" :class="{selected: $store.state.reportForming.selectedFolder == folder.id}">
            <span v-if="!folder.editingTitle || folder.editingTitle ==undefined">
                  {{folder.name}}
            </span>
            <input 
                v-else-if="folder.editingTitle" 
                v-model="folder.name" 
                type="text"
                :placeholder="toLocal('generic.enter_name')" 
                 @blur="setFolderName($event, folder)" 
                v-click-outside-2="clickAwayInput"
                @mouseover="$emit('disableFolderDrag', true)"
                @mouseleave="$emit('disableFolderDrag', false)"
            >
        </div>
        <div class="icon" @click.stop="optionsOpen = true" v-click-outside="closeOptions">
            <svg class="select-icon" :style="{'z-index': optionsOpen ? '3' : ''}" xmlns="http://www.w3.org/2000/svg" width="25.667" height="6.706" viewBox="0 0 25.667 6.706">
            <g id="Group_9979" data-name="Group 9979" transform="translate(498.599 -710.5) rotate(90)">
                <circle id="Ellipse_572" data-name="Ellipse 572" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 498.599) rotate(-90)" fill="#28345c"/>
                <circle id="Ellipse_573" data-name="Ellipse 573" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 489.118) rotate(-90)" fill="#28345c"/>
                <circle id="Ellipse_574" data-name="Ellipse 574" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 479.638) rotate(-90)" fill="#28345c"/>
            </g>
            </svg>
                <div class="action-container" v-if="optionsOpen" >
                    <div class="header">

                    </div>
                    <div class="option" @click="$emit('duplicateFolder', folder)">
                        <div class="option-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.87" height="15.871" viewBox="0 0 15.87 15.871">
                            <g id="Group_11113" data-name="Group 11113" transform="translate(0.5 0.5)">
                                <rect id="Rectangle_187" data-name="Rectangle 187" width="9.914" height="9.914" rx="2.835" transform="translate(4.957 4.957)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                <rect id="Rectangle_188" data-name="Rectangle 188" width="9.914" height="9.914" rx="2.835" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                <line id="Line_44" data-name="Line 44" x1="2.273" y2="2.273" transform="translate(7.484 7.5)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.5"/>
                                <line id="Line_45" data-name="Line 45" x1="4.188" y2="4.188" transform="translate(5.349 5.365)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.5"/>
                                <line id="Line_46" data-name="Line 46" x1="2.273" y2="2.273" transform="translate(5.138 5.154)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.5"/>
                            </g>
                            </svg>
                        </div>
                        <span class="option-label">
                            {{toLocal('generic.create_copy')}}
                        </span>
                    </div>
                    <div class="option" @click="deleteFolderPrompt">
                        <div class="option-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.694" height="14.872" viewBox="0 0 11.694 14.872">
                                <g id="Group_10004" data-name="Group 10004" transform="translate(0 0)">
                                    <g id="Group_291" data-name="Group 291" transform="translate(0 0)">
                                    <path id="Rectangle_184" data-name="Rectangle 184" d="M6927.982,1771.573h9.547v8.808a3.123,3.123,0,0,1-3.117,3.12H6931.1a3.124,3.124,0,0,1-3.121-3.12Zm8.846.7h-8.144v8.106a2.421,2.421,0,0,0,2.419,2.418h3.308a2.422,2.422,0,0,0,2.417-2.418Z" transform="translate(-6926.94 -1768.63)" fill="#28345c"/>
                                    <path id="Path_550" data-name="Path 550" d="M6933.777,1764.2l.084,0a1.224,1.224,0,0,1-.155,2.443h-9.176a1.222,1.222,0,0,1,0-2.444h9.178Zm0,1.746a.522.522,0,0,0,.036-1.042.463.463,0,0,0-.071,0h-9.211a.52.52,0,1,0,0,1.04h9.247Z" transform="translate(-6923.308 -1763.003)" fill="#28345c"/>
                                    <path id="Path_551" data-name="Path 551" d="M6942.827,1761.029h-4.29l-.088-.22a1.225,1.225,0,0,1,1.13-1.682h2.2a1.226,1.226,0,0,1,1.136,1.681Zm-3.763-.7h3.235a.522.522,0,0,0-.521-.5h-2.2A.522.522,0,0,0,6939.064,1760.327Z" transform="translate(-6934.868 -1759.126)" fill="#28345c"/>
                                    <g id="Group_290" data-name="Group 290" transform="translate(3.316 4.772)">
                                        <path id="Line_35" data-name="Line 35" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(0.5 0.5)" fill="#28345c"/>
                                        <path id="Line_36" data-name="Line 36" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(2.647 0.5)" fill="#28345c"/>
                                        <path id="Line_37" data-name="Line 37" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(4.795 0.5)" fill="#28345c"/>
                                    </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span class="option-label">
                            {{toLocal('generic.delete')}}
                        </span>
                    </div>
                </div>
        </div>
        <div class="report-count">
            <svg xmlns="http://www.w3.org/2000/svg" width="33.906" height="28.5" viewBox="0 0 33.906 28.5">
            <g id="Group_9971" data-name="Group 9971" transform="translate(-314.802 -388.75)">
                <path id="Path_8229" data-name="Path 8229" d="M18.329,5.8l-3.748-3a1.364,1.364,0,0,0-.865-.3L3.85,2.595A1.363,1.363,0,0,0,2.5,3.958V28.136A1.363,1.363,0,0,0,3.863,29.5H33.542a1.364,1.364,0,0,0,1.364-1.364V7.459A1.363,1.363,0,0,0,33.542,6.1H19.181A1.364,1.364,0,0,1,18.329,5.8Z" transform="translate(313.052 387)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
            </svg>
            <span>
                {{folder && folder.reportList && folder.reportList.length > 0 ? folder.reportList.length : 0}}
            </span>
        </div>
    </div>
    <div class="item-list-container" v-if="$store.state.reportForming.selectedFolder == folder.id">
         <draggable 
                v-model="folder.reportList" 
                group="items" 
                animation="200"
                ghostClass="ghost"
                :options="{ disabled: !$store.state.reportForming.movingItems || dragDisabled}"
                @start="isDraggingItems=true; $store.state.reportForming.currentlyDraggingItem = folder.reportList[$event.oldIndex]; $store.state.reportForming.draggingFromFolder = folder.id" 
                @end="isDraggingItems=false; "
                @update="updateItemListEvent"
        >
            <transition-group type="transition" :name="!isDraggingItems ? 'flip-list' : null">
                <div v-for="(item, item_index) in folder.reportList" :key="item_index" @click="$store.state.reportForming.selectedItem = item.id">
                    <FolderItem  :report="item"  @dragDisable="dragDisabled = $event; $emit('disableFolderDrag', $event)" @deleteItem="deleteSelectedItem(folder,item, item_index)" @duplicateItem="$emit('duplicateTemplate', $event, folder)"/>
                </div>
            </transition-group>
        </draggable>
    </div>
</div>
</template>

<script>
import FolderItem from "./FolderItem.vue";
import draggable from 'vuedraggable'
import axios from 'axios';
import _ from 'lodash';
export default {
    props: {
        folder: {
            type: Object,
            default: () => { },
        }
    },
    data() {
        return {
            optionsOpen: false,
            isDraggingItems: false,
            draggingOver: false,
            dragDisabled: false,
        };
    },
    watch:{
        'folder.name':{
            handler(){
                this.debounceUpdate(this, this.folder);
            },
        },
        'folder.reportList.length':{
            handler(){
                this.$set(this.folder, 'order_list', String('['+this.folder.reportList.map(item => item.id)+']'));
                this.updateFolder(this.folder);
            },
        },
    },
    methods: {
        closeOptions() {
            this.optionsOpen = false;
        },
        dropItem(e){
            if(!this.$store.state.reportForming.currentlyDraggingItem.data) return;
            if(this.$store.state.reportForming.currentlyDraggingItem != {} && this.$store.state.reportForming.currentlyDraggingItem != undefined){
                this.folder.reportList.push(this.$store.state.reportForming.currentlyDraggingItem);
                let folder_id  = this.$store.state.reportForming.searchReportFolderList.findIndex(el=>el.id == this.$store.state.reportForming.draggingFromFolder);
                if(folder_id != -1){
                    let item_index = this.$store.state.reportForming.searchReportFolderList[folder_id].reportList.findIndex(el=> el.id == this.$store.state.reportForming.currentlyDraggingItem.id);
                    if(item_index != -1){
                        this.$store.state.reportForming.searchReportFolderList[folder_id].reportList.splice(item_index, 1);
                    }
                }
                this.$store.state.reportForming.currentlyDraggingItem = {};
            }
            this.draggingOver=false;
        },
        updateItemListEvent(e){
            this.updateFolder(this.folder);
        },
        deleteFolderPrompt(){
            let reportForm = this.$store.state.reportForming;
            let folder_index = reportForm.searchReportFolderList.findIndex(el=>el.id == this.folder.id);
            if(folder_index != -1){
                this.$store.state.reportForming.reportDeleteModal.folder_index = folder_index;
            }
            this.$store.state.reportForming.reportDeleteModal.folder = this.folder;
             this.$store.state.reportForming.reportDeleteModal.modalOpen = true;
        },
        deleteFolder(){
           
            axios.delete(
                    `${this.$store.state.api_link}/report-folders/${this.folder.id}`,
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    let index= this.$store.state.reportForming.searchReportFolderList.findIndex(el=>el.id == this.folder.id);
                    if(index != -1){
                        this.$store.state.reportForming.searchReportFolderList.splice(index, 1);
                    }
                    let index_full= this.$store.state.reportForming.reportFolderList.findIndex(el=>el.id == this.folder.id);
                    if(index_full != -1){
                        this.$store.state.reportForming.reportFolderList.splice(index_full, 1);
                    }
                    // folder.reportList = [];
                });
        },
        debounceUpdate: _.debounce((self, folder)=>{
            self.updateFolder(folder);
        }, 1000),
        updateFolder(folder){
   
            axios.put(
                    `${this.$store.state.api_link}/report-folders/${folder.id}`,
                    {
                        folder: folder
                    },
                    this.$store.getters.axiosConfig()
                ).then((response) => {
                    // folder = response.data.folder;
                });
            
        },
        setFolderName(e, folder){
            if(e.target.value != ''){
                folder.name = e.target.value; 
                this.$set(folder, 'editingTitle', false);
                let folder_old_index = this.$store.state.reportForming.reportFolderList.findIndex(el=>el.id == folder.id);
                if(folder_old_index!= -1){
                    this.$set(this.$store.state.reportForming.reportFolderList[folder_old_index], 'editingTitle', false);
                    this.$set(this.$store.state.reportForming.reportFolderList[folder_old_index], 'name', e.target.value); 
                }
            }
            else return;
        },
        clickAwayInput(){
            this.$set(this.folder, 'editingTitle', false);
            let folder_old_index = this.$store.state.reportForming.reportFolderList.findIndex(el=>el.id == this.folder.id);
            if(folder_old_index!= -1){
                this.$set(this.$store.state.reportForming.reportFolderList[folder_old_index], 'editingTitle', false);
                this.$set(this.$store.state.reportForming.reportFolderList[folder_old_index], 'name', this.folder.name); 
            }
        },
        deleteSelectedItem(folder, item, item_index){
            let modal_data ={
                item: item,
                folder: folder,
                modalOpen: true,
            }
            this.$store.state.reportForming.reportDeleteModal = modal_data;
        },
         startTitleEdit(e, folder){
            if(folder.editingTitle) return;
            this.$set(folder, 'editingTitle', true);
            let input = e.target.parentNode.children[1];
            setTimeout(()=>{
                if(input)
                    input.focus();
            },10);
        }
    },
    components: { FolderItem, draggable }
}
</script>

<style lang="scss" scoped>
.folder-container{
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    &.dragover{
        background: rgb(201, 201, 201);
    }
}
.tree-select{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
     border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background: white;
    padding: 1rem 0.5rem;
    height: 3rem;
    .label{
        width: 100%;
        margin-left: 0.5rem;
        color: var(--primary-color);
        input{
            width: 100%;
            height: 100%;
            background: none;
            border:none;
            font-size: clamp(16px, 1.2vw, 20px);
            border-bottom: 1px solid var(--primary-color-opacity-50);
            &:focus{
                outline: none;
            }
        }
        span{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 10px;
            min-width: 100%;
        }
        &.selected{
            font-weight: 700;
        }
    }
    .icon{
        padding: 0 0.25rem;
        display: flex;
        align-items: center;
        height: 1.5rem;
        width: 2rem;
        position: relative;
        cursor: pointer;
        svg{
            position: relative;
            path, circle{
                transition: fill 0.3s ease;
                fill: var(--primary-color);
            }
            rect, line{
                    stroke: var(--primary-color);
            }
            // path{
            // }
        }
        .select-icon{
            path, circle{
                fill: var(--primary-color-opacity-50);
            }
        }
        &:hover{
            .select-icon{
                path, circle{
                    fill: var(--primary-color);
                }
            }
        }
        &.arrow{
            svg{
                transition: transform 0.3s;
            }
            &.selected{
                svg{
                    transform: rotate(-180deg);
                }
            }
        }
    }
    &.report{
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        width: 100%;
        margin: 0.25rem 0;
    }
}
.action-container{
    position: absolute;
    right: -0.5rem;
    top: 0;
    border: 1px solid var(--primary-color);
    background: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 2;
    .header{
        height: 1rem;
        width: 100%;
    }
    .option{
        display: flex;
        align-items: center;
        color: var(--primary-color);
        margin: 0.25rem 0;
        .option-icon{
            display: flex;
            justify-content: center;
            width: 1rem;
            margin-right: 0.25rem;
            min-height: 16px;
            height: 1rem;
        }
        .option-label{
            width: 4.5rem;
            font-size: clamp(14px, 2vw, 16px);
        }
        &:hover{
            .option-label{
                font-weight: 700;
                
            }
        }
        svg{
            path{
                fill: var(--primary-color);
            }
        }
    }
}
.option-icon{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    svg{
        height: 100%;
        width: 0.75rem;
    }
}
.report-count{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -3rem;
    background: white;

    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    border-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 0 1rem;
    span{
        position: absolute;
        font-weight: 700;
        color: var(--primary-color);
    }
    svg{
        path{
            stroke: var(--primary-color);
        }
    }
}
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
  min-width: 2rem;
   min-height: 2rem;
}
.expand-enter-from,
.expand-leave-to {
  opacity: 0;
   min-width: 0;
    min-height: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
//   background: var(--secondary-color-opacity-50);
}
</style>