<template>
  <div class="tab--container">
    <div
        class="tab"
        :style="{'z-index': $store.state.reportForming.selectedTab == tab_index ? 5 : tabs.length-tab_index}"
        :class="{selected: $store.state.reportForming.selectedTab == tab_index}"
        v-for="(tab, tab_index) in tabs" 
        :key="tab_index" 
        @click="$store.state.reportForming.selectedTab = tab_index"
        >
            {{tab.name}}
    </div>
  </div>
</template>

<script>
export default {
    name: 'TabContainer',
    data(){
        return{
            tabs: [
                {name: this.toLocal('report.periodic_form')},
                {name: this.toLocal('report.instant_form')},
            ],
            selectedTabIndex: this.$store.state.reportForming.selectedTab,
        }
    }
}
</script>

<style lang="scss" scoped>
.tab--container{
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;
    .tab{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 1.5rem;
        margin-bottom: -8px;
        width: 100%;
        background: white;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.20);
        margin-right: -1rem;
        cursor: pointer;
        font-weight: 600;
        color: var(--primary-color);
        &:last-child{
            margin-right: 0;
        }
        &::before{
            position: absolute;
            content: '';
            width: 100%;
            height: 0.75rem;
            background-color: #e2e2e2;
            left: 0%;
            right: 0%;
            bottom: -0.75rem;
            margin: auto;
        }
        //  &::after{
        //     position: absolute;
        //     content: '';
        //     width: 100%;
        //     height: 100%;
        //     background-color: #ffffff70;
        //     border-radius: 1rem;
        //     left: 0%;
        //     right: 0%;
        //     bottom: 0;
        //     top:0;
        //     margin: auto;
        // }
        &.selected{
            border-bottom: none;
            background-color: #E2E2E2;
            &::before{
                display: initial;
                // background-color: #E2E2E2;
            }
            // &::after{
            //     display: none;
            // }
        }

    }
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  max-height: 30rem;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  max-height: 0rem;
}
</style>