var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.itemPreview && _vm.$store.state.itemPreview.open)?_c('div',{staticClass:"overlay-container",on:{"click":_vm.closeModal}},[_c('div',{staticClass:"preview-modal"},[_c('div',{key:_vm.image ? _vm.image.src : '',staticClass:"bg",style:({
             height: _vm.imageHeight ? _vm.imageHeight+'px' : _vm.height + 'px',
            width: _vm.imageWidth ? _vm.imageWidth + 'px' : _vm.width + 'px',
            left: _vm.left + 'px',
            top: _vm.top + 'px',
            'background-image': 'url(' + _vm.$store.state.itemPreview.src + ')',
            'clip-path': _vm.getClipPath(_vm.$store.state.itemPreview.item)
        })},[_c('img',{ref:"image",staticStyle:{"display":"none"},attrs:{"src":_vm.$store.state.itemPreview.src,"alt":""}})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }