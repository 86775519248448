<template>
  <div class="condition-container">
    <ConditionFilter 
      :groupUserFilter="filter"
      filterStyle="trainings"
      :includable_columns="includable_columns" 
      :user_data="additional_user_data"
      :user_count="user_count"
      :selectedFilter="Array.isArray(selectedFilter) ? selectedFilter : []"
      @filterEvent="saveFilter"
      @viewList="viewUserList"
      />
      <InvitedUserListModal
        class="embedded-modal"
        v-if="preview.show" 
        :users="preview.users" 
        :item="preview.item"
       />
  </div>
</template>

<script>
import ConditionFilter from "@/components/layout/groups/ConditionFilter.vue";
import InvitedUserListModal from "@/components/layout/trainings/training_users/invitation_list/InvitedUserListModal.vue";

import axios from 'axios'
export default {
    components:{
        ConditionFilter,
        InvitedUserListModal
    },
    props:{
        additional_user_data:Array,
        includable_columns:Array,
        preview: Object,
        selectedFilter: Array | Object,
        user_count: Number
    },
    data(){
        return{
            filter: [],
        }
    },
    mounted(){
    },
    methods:{
      
        saveFilter(filters){
            this.filter = filters
            if(Array.isArray(this.filter)){
                if(!this.filter[0])  {
                    return
                }
                let cols = this.filter[0].cols;
                if(cols.length == 0) {
                    return
                }
                if(cols.length < 3) {
                    return
                }
                this.$emit('filterUsers',this.filter);
            }
        },
        viewUserList(){
            if(Array.isArray(this.filter)){
                if(!this.filter[0])  {
                    return
                }
                let cols = this.filter[0].cols;
                if(cols.length == 0) {
                    return
                }
                if(cols.length < 3) {
                    return
                }
                this.$emit('previewFilter',this.filter);
            }
        }
       
    }
}
</script>

<style lang="scss" scoped>
.condition-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    z-index: 3;
    overflow: auto;
    position: relative;
}
.embedded-modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background: white;
}
</style>