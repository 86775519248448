var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded == true)?_c('div',{staticClass:"folder-page-container"},[_c('HeaderButtons',{attrs:{"options":{showBackBtn:false, mode: 'view'}}}),_c('div',{staticClass:"folder-container"},_vm._l((_vm.trainingRows),function(row,rowIndex){return _c('div',{key:'folder-row-' + rowIndex,staticClass:"folder-rows",class:{ 'grid-center':  _vm.$store.state.system_settings.grid_dynamic_size != 0 ? _vm.trainingRowGroups[rowIndex].length == 1 : false}},_vm._l((_vm.trainingRowGroups[rowIndex]),function(folder,fIndex){return _c('div',{key:'folder-block-' + fIndex,staticClass:"folder-block",class:[
          { bright: _vm.$store.state.system_settings.color_palette == 0 },
          { medium: _vm.$store.state.system_settings.color_palette == 1 },
          { blank: _vm.$store.state.system_settings.color_palette == 2 } ],style:(_vm.getFolderStyle(folder, rowIndex)),on:{"click":function($event){$event.stopPropagation();return _vm.openGroup(folder.id)}}},[_c('span',{staticClass:"folder-name",class:{
            darkLetters:
              folder.id == -1 &&
              _vm.$store.state.system_settings.color_palette == 0,
          }},[_vm._v(_vm._s(folder.name))]),_c('div',{staticClass:"bottom-line",class:[
            { end: folder.trainings.length == 0 },
            {
              darkLetters:
                folder.id == -1 &&
                _vm.$store.state.system_settings.color_palette == 0,
            } ]},[(folder.trainings.length != 0)?_c('span',{staticClass:"training-count"},[_vm._v(_vm._s(_vm.toLocal("groups.finished_trainings"))+" "+_vm._s(folder.ended_percent.toFixed(0))+" %")]):_vm._e(),_c('div',{staticClass:"arrow-container"},[_c('ArrowRightFadeSvg',{class:{
                darkLetters:
                  folder.id == -1 &&
                  _vm.$store.state.system_settings.color_palette == 0,
              }})],1)]),_c('div',{staticClass:"bottom-bar"},[_c('div',{staticClass:"inner-bar",style:(("width: " + (folder.ended_percent.toFixed(
              0
            )) + "%; border-top-right-radius: " + (folder.ended_percent.toFixed(0) == 100 ? '0px' : '16px')))})])])}),0)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }