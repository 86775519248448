<template>
  <div class="date-picker-container" >
     <div class="label main">
        Rodyti tik šiuos:
    </div>
    <div class="row">
        <div class="label">Nuo:</div>
        <date-picker
            v-model="from_date"
            label="date"
            :placeholder="toLocal('users_side.select_date')"
            class="secondary-active-calendar"
            type="date"
            valueType="format"
            @change="$emit('changeFrom', from_date)"
            @open="modalIsOpen=true"
            @close="modalIsOpen=false"
        >
            <template v-slot:icon-calendar> 
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.716396 15.985L4.43836 15.1511H4.46955C4.64427 15.1509 4.81724 15.1162 4.97853 15.0491C5.13982 14.9819 5.28626 14.8836 5.40946 14.7598L15.6033 4.57007C15.8552 4.31716 15.9966 3.97483 15.9966 3.61797C15.9966 3.26111 15.8552 2.91878 15.6033 2.66587L13.3357 0.39312C13.0827 0.141349 12.7402 0 12.3832 0C12.0262 0 11.6837 0.141349 11.4307 0.39312L1.23081 10.5889C1.10587 10.7141 1.00775 10.8635 0.942445 11.0279C0.877135 11.1923 0.846 11.3683 0.850937 11.5451L0.0129728 15.2823C-0.00415802 15.3662 -0.00415802 15.4526 0.0129728 15.5364C0.029398 15.6126 0.0608234 15.6847 0.105422 15.7487C0.15002 15.8126 0.206899 15.867 0.272741 15.9087C0.367633 15.9687 0.477468 16.001 0.589771 16.0018C0.633048 16.0026 0.67625 15.9978 0.71826 15.9873M12.0215 0.98411C12.1174 0.88863 12.2473 0.835027 12.3827 0.835027C12.5181 0.835027 12.648 0.88863 12.744 0.98411L15.0121 3.2564C15.0602 3.3031 15.0984 3.35903 15.1244 3.42084C15.1504 3.48264 15.1637 3.54906 15.1634 3.61611C15.1633 3.68351 15.1499 3.75024 15.1239 3.81244C15.0979 3.87465 15.0599 3.93112 15.0121 3.97861L14.0293 4.96096L11.0359 1.96878L12.0215 0.98411ZM2.09578 10.9058L10.4447 2.55838L13.4381 5.55055L5.08918 13.8979L2.09578 10.9058ZM1.28668 13.4354L1.68099 11.6736L4.32895 14.3205L2.43516 14.743L1.28668 13.4354Z" fill="#7D7D7D"/>
                <path d="M4.57455 11.8387C4.51977 11.839 4.46549 11.8283 4.41494 11.8072C4.3644 11.7861 4.31862 11.755 4.28033 11.7158C4.20215 11.6374 4.15826 11.5311 4.15826 11.4204C4.15826 11.3096 4.20215 11.2033 4.28033 11.1249L10.6358 4.77241C10.6744 4.7331 10.7204 4.70187 10.7711 4.68055C10.8219 4.65923 10.8764 4.64825 10.9314 4.64825C10.9865 4.64825 11.041 4.65923 11.0918 4.68055C11.1425 4.70187 11.1885 4.7331 11.2271 4.77241C11.3052 4.8509 11.3491 4.95715 11.3491 5.06791C11.3491 5.17867 11.3052 5.28492 11.2271 5.3634L4.87156 11.7158C4.83272 11.755 4.78644 11.7861 4.73544 11.8072C4.68444 11.8283 4.62974 11.839 4.57455 11.8387Z" fill="#7D7D7D"/>
                </svg>

            </template>
        </date-picker>
    </div>
    <div class="row">
    <div class="label">Iki:</div>
        <date-picker
            v-model="to_date"
            label="date"
            :placeholder="toLocal('users_side.select_date')"
            class="secondary-active-calendar"
            type="date"
            valueType="format"
            :disabledDate="disabledDates"
            @change="$emit('changeTo', to_date)"
            @open="modalIsOpen=true"
            @close="modalIsOpen=false"
        >
            <template v-slot:icon-calendar> 
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.716396 15.985L4.43836 15.1511H4.46955C4.64427 15.1509 4.81724 15.1162 4.97853 15.0491C5.13982 14.9819 5.28626 14.8836 5.40946 14.7598L15.6033 4.57007C15.8552 4.31716 15.9966 3.97483 15.9966 3.61797C15.9966 3.26111 15.8552 2.91878 15.6033 2.66587L13.3357 0.39312C13.0827 0.141349 12.7402 0 12.3832 0C12.0262 0 11.6837 0.141349 11.4307 0.39312L1.23081 10.5889C1.10587 10.7141 1.00775 10.8635 0.942445 11.0279C0.877135 11.1923 0.846 11.3683 0.850937 11.5451L0.0129728 15.2823C-0.00415802 15.3662 -0.00415802 15.4526 0.0129728 15.5364C0.029398 15.6126 0.0608234 15.6847 0.105422 15.7487C0.15002 15.8126 0.206899 15.867 0.272741 15.9087C0.367633 15.9687 0.477468 16.001 0.589771 16.0018C0.633048 16.0026 0.67625 15.9978 0.71826 15.9873M12.0215 0.98411C12.1174 0.88863 12.2473 0.835027 12.3827 0.835027C12.5181 0.835027 12.648 0.88863 12.744 0.98411L15.0121 3.2564C15.0602 3.3031 15.0984 3.35903 15.1244 3.42084C15.1504 3.48264 15.1637 3.54906 15.1634 3.61611C15.1633 3.68351 15.1499 3.75024 15.1239 3.81244C15.0979 3.87465 15.0599 3.93112 15.0121 3.97861L14.0293 4.96096L11.0359 1.96878L12.0215 0.98411ZM2.09578 10.9058L10.4447 2.55838L13.4381 5.55055L5.08918 13.8979L2.09578 10.9058ZM1.28668 13.4354L1.68099 11.6736L4.32895 14.3205L2.43516 14.743L1.28668 13.4354Z" fill="#7D7D7D"/>
                <path d="M4.57455 11.8387C4.51977 11.839 4.46549 11.8283 4.41494 11.8072C4.3644 11.7861 4.31862 11.755 4.28033 11.7158C4.20215 11.6374 4.15826 11.5311 4.15826 11.4204C4.15826 11.3096 4.20215 11.2033 4.28033 11.1249L10.6358 4.77241C10.6744 4.7331 10.7204 4.70187 10.7711 4.68055C10.8219 4.65923 10.8764 4.64825 10.9314 4.64825C10.9865 4.64825 11.041 4.65923 11.0918 4.68055C11.1425 4.70187 11.1885 4.7331 11.2271 4.77241C11.3052 4.8509 11.3491 4.95715 11.3491 5.06791C11.3491 5.17867 11.3052 5.28492 11.2271 5.3634L4.87156 11.7158C4.83272 11.755 4.78644 11.7861 4.73544 11.8072C4.68444 11.8283 4.62974 11.839 4.57455 11.8387Z" fill="#7D7D7D"/>
                </svg>

            </template>
        </date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
    props:{
        from:{
            type: String,
        },
        to:{
            type: String,
        }
    },
    components:{
        DatePicker
    },
    data(){
        return{
            modalIsOpen: false,
            from_date: '',
            to_date: ''
        }
    },
    mounted(){
        this.from_date = this.from
        this.to_date = this.to
    },
    watch:{
        modalIsOpen(newVal){
            this.$emit('innerPopupState',newVal)
        }
    },
    methods:{
        disabledDates(time){
            var date = moment(String(time)).format('YYYY-MM-DD');
            if(this.from_date.length > 0){
                var d1 = moment(new Date(this.from_date)).format('YYYY-MM-DD');
            }
            else{
                var d1 = moment().format('YYYY-MM-DD');
            }
            if (date>=d1) {
                return false;
            }
            else return true;
        },
    }

}
</script>

<style lang="scss" scoped>
.date-picker-container{
    padding-bottom: 20px;
}
.row{
    display: flex;
    align-items: center;
    color: #333;
    font-family: var(--main-site-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding: 0 20px 0 8px;
    margin-top: 12px;
    .label{
        flex:1;
    }
    .secondary-active-calendar{
        flex:4;
    }
}
.label{
    display: flex;
    justify-content: flex-start;
    &.main{
        margin: 8px 0 0;
        padding: 0 8px;
    }
}
</style>