<template>
  <div class="invitations-container">
    <h3>{{toLocal('trainings.select_how_to_assign_users')}}:</h3>
    <div class="button-container">
        <button 
            v-for="(invitation_type, index) in invitation_types"
            :key="index"
            @click="$emit('selectedType', invitation_type.value)"
            :class="getContrast('secondary_color')"
        >
            {{invitation_type.label}}
        </button>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        invitation_types: Array
    },
    data(){
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
.invitations-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    font-family: var(--main-site-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding-top: 10%;
    z-index: 3;
}
.button-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

}
button{
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background: #4D4D4D;
    color: #fff;
    padding: 8px 32px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    border: none;
    cursor: pointer;
  
    &.blackContrast{
        &:hover{
            background: var(--secondary-color);
            color: #000
        }
    }
    &.whiteContrast{
        &:hover{
            background: var(--secondary-color);
            color: white;
        }
    }
}
</style>