<template>
  <div v-scroll-lock="sideOpen">
    <div
      :class="['side--background', sideOpen ? 'side--open' : '']"
      @click="$emit('closeSide')"
    />
    <section
      :class="{
        'group--side': true,
        'side--open': sideOpen,
        'header--disabled': !$store.state.header_enabled,
      }"
    >
      <div class="side--padding">
        <img
          class="side--padding--corner"
          src="@/assets/images/side-corner-narrow.svg"
        />
        <div class="side--padding--bottom" />
      </div>
      <div class="side--close" @click="$emit('closeSide')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.521"
          height="36.521"
          viewBox="0 0 36.521 36.521"
        >
          <g
            id="Group_1147"
            data-name="Group 1147"
            transform="translate(-995 -94.204)"
          >
            <circle
              id="Ellipse_62"
              data-name="Ellipse 62"
              cx="18.26"
              cy="18.26"
              r="18.26"
              transform="translate(995 94.204)"
              fill="#fff"
            />
            <g
              id="Group_656"
              data-name="Group 656"
              transform="translate(1012.934 96.686) rotate(45)"
            >
              <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                <path
                  id="Path_751"
                  data-name="Path 751"
                  d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z"
                  transform="translate(0)"
                  fill="#6d66a2"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="content-container">
        <div class="option-container">
          <div class="header">
            <h2>{{ group.name }}</h2>
            <div class="training-count">
              <div
                class="long"
                v-if="shownExpiryDate != undefined && shownExpiryDate != ''"
              >
                {{
                  toLocal(!isExpired ? "users.user_expires" : "groups.expired")
                }}
                {{ shownExpiryDate }}
              </div>
              <div class="label">{{ toLocal("group_side.assigned") }}:</div>
              <div class="number">{{ trainings.length }}</div>
            </div>
          </div>
          <div class="row">
            <div class="deactivation-time">
              <span class="deactivation-text">{{
                toLocal("user_side.deactivate_after")
              }}</span>
              <date-picker
                v-model="deactivationDate"
                label="date"
                :placeholder="toLocal('users_side.select_date')"
                class="secondary-active-calendar"
                type="date"
                valueType="format"
                @change="handleDeactivation"
              >
                <template v-slot:icon-calendar> 
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.716396 15.985L4.43836 15.1511H4.46955C4.64427 15.1509 4.81724 15.1162 4.97853 15.0491C5.13982 14.9819 5.28626 14.8836 5.40946 14.7598L15.6033 4.57007C15.8552 4.31716 15.9966 3.97483 15.9966 3.61797C15.9966 3.26111 15.8552 2.91878 15.6033 2.66587L13.3357 0.39312C13.0827 0.141349 12.7402 0 12.3832 0C12.0262 0 11.6837 0.141349 11.4307 0.39312L1.23081 10.5889C1.10587 10.7141 1.00775 10.8635 0.942445 11.0279C0.877135 11.1923 0.846 11.3683 0.850937 11.5451L0.0129728 15.2823C-0.00415802 15.3662 -0.00415802 15.4526 0.0129728 15.5364C0.029398 15.6126 0.0608234 15.6847 0.105422 15.7487C0.15002 15.8126 0.206899 15.867 0.272741 15.9087C0.367633 15.9687 0.477468 16.001 0.589771 16.0018C0.633048 16.0026 0.67625 15.9978 0.71826 15.9873M12.0215 0.98411C12.1174 0.88863 12.2473 0.835027 12.3827 0.835027C12.5181 0.835027 12.648 0.88863 12.744 0.98411L15.0121 3.2564C15.0602 3.3031 15.0984 3.35903 15.1244 3.42084C15.1504 3.48264 15.1637 3.54906 15.1634 3.61611C15.1633 3.68351 15.1499 3.75024 15.1239 3.81244C15.0979 3.87465 15.0599 3.93112 15.0121 3.97861L14.0293 4.96096L11.0359 1.96878L12.0215 0.98411ZM2.09578 10.9058L10.4447 2.55838L13.4381 5.55055L5.08918 13.8979L2.09578 10.9058ZM1.28668 13.4354L1.68099 11.6736L4.32895 14.3205L2.43516 14.743L1.28668 13.4354Z" fill="#7D7D7D"/>
                  <path d="M4.57455 11.8387C4.51977 11.839 4.46549 11.8283 4.41494 11.8072C4.3644 11.7861 4.31862 11.755 4.28033 11.7158C4.20215 11.6374 4.15826 11.5311 4.15826 11.4204C4.15826 11.3096 4.20215 11.2033 4.28033 11.1249L10.6358 4.77241C10.6744 4.7331 10.7204 4.70187 10.7711 4.68055C10.8219 4.65923 10.8764 4.64825 10.9314 4.64825C10.9865 4.64825 11.041 4.65923 11.0918 4.68055C11.1425 4.70187 11.1885 4.7331 11.2271 4.77241C11.3052 4.8509 11.3491 4.95715 11.3491 5.06791C11.3491 5.17867 11.3052 5.28492 11.2271 5.3634L4.87156 11.7158C4.83272 11.755 4.78644 11.7861 4.73544 11.8072C4.68444 11.8283 4.62974 11.839 4.57455 11.8387Z" fill="#7D7D7D"/>
                  </svg>

                </template>
              </date-picker>
            </div>
            <div class="column grid-buttons flex">
              <span>{{ toLocal("group_side.show_to") }}</span>
              <div class="btn-container">
                <div
                  class="btn"
                  @click="setTrainingShowing(1)"
                  :class="{ active: group.show_trainings == 1 }"
                >
                  {{ toLocal("group_side.only_when_finished") }}
                </div>
                <div
                  class="btn"
                  @click="setTrainingShowing(0)"
                  :class="{ active: group.show_trainings == 0 }"
                >
                  {{ toLocal("group_side.set_time") }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <h3>
              {{ toLocal("group_side.assigned_trainings") }}
            </h3>
          </div>
          <div class="row">
            <div class="row-block">
              <span class="long">{{
                toLocal("group_side.show_categories")
              }}</span>
              <div
                class="dropdown big"
                :class="{
                  active: activeDropdown == 0 && activeDropdownType == 5,
                }"
              >
                <div class="btn-container" @click="openDropdown(0, 5)">
                  <span class="title">{{ selectedMultiChoice }}</span>
                  <div class="dropdown-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.427"
                      height="10.146"
                      viewBox="0 0 18.427 10.146"
                    >
                      <path
                        id="Path_5930"
                        data-name="Path 5930"
                        d="M-3723.431-17219.354a.923.923,0,0,1-.66-.271l-8.279-8.275a.94.94,0,0,1,0-1.326.932.932,0,0,1,1.32,0l7.619,7.621,7.62-7.621a.932.932,0,0,1,1.32,0,.94.94,0,0,1,0,1.326l-8.278,8.275A.924.924,0,0,1-3723.431-17219.354Z"
                        transform="translate(3732.645 17229.5)"
                        fill="#28345c"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="dropdown-list multi"
                  v-if="activeDropdown == 0 && activeDropdownType == 5"
                  v-click-outside-3="clickOutsideCategoryFilter"
                >
                  <div class="dropdown-wrapper">
                    <div
                      class="list-item multichoice"
                      v-for="(filter, filter_index) in training_groups"
                      :key="filter_index"
                      @click="
                        $set(filter, 'checked', filter.checked ? false : true)
                      "
                    >
                      <div class="checkbox" :class="{ active: filter.checked }">
                        <svg
                          v-if="filter.checked"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.375"
                          height="13.434"
                          viewBox="0 0 14.375 13.434"
                        >
                          <path
                            id="Path_8272"
                            data-name="Path 8272"
                            d="M-379.365-14991.977l4.332,5.945,2.552-3.885,4.708-7.166"
                            transform="translate(380.762 14998.466)"
                            fill="none"
                            stroke="#28345c"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                      </div>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-block on-end">
              <span class="long">{{ toLocal("user_side.sort_by") }}</span>
              <div
                class="dropdown big"
                :class="{
                  active: activeDropdown == 0 && activeDropdownType == 0,
                }"
              >
                <div class="btn-container" @click="openDropdown(0, 0)">
                  <span class="title">{{
                    selectedFilter == -1
                      ? toLocal("group_side.filter_not_selected")
                      : filter_list[selectedFilter].name
                  }}</span>
                  <div class="dropdown-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.427"
                      height="10.146"
                      viewBox="0 0 18.427 10.146"
                    >
                      <path
                        id="Path_5930"
                        data-name="Path 5930"
                        d="M-3723.431-17219.354a.923.923,0,0,1-.66-.271l-8.279-8.275a.94.94,0,0,1,0-1.326.932.932,0,0,1,1.32,0l7.619,7.621,7.62-7.621a.932.932,0,0,1,1.32,0,.94.94,0,0,1,0,1.326l-8.278,8.275A.924.924,0,0,1-3723.431-17219.354Z"
                        transform="translate(3732.645 17229.5)"
                        fill="#28345c"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="dropdown-list"
                  v-if="activeDropdown == 0 && activeDropdownType == 0"
                  v-click-outside-3="clickOutsideMainSort"
                >
                  <div
                    class="list-item"
                    v-for="(filter, filter_index) in filter_list"
                    :key="filter_index"
                    v-show="filter.key == 'order_index' ? group.show_trainings==1 : true"
                    @click="selectedFilter = filter_index"
                  >
                    {{ filter.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row full-wide on-end">
            <div class="search-container">
              <input
                type="text"
                class="search"
                :placeholder="toLocal('group_side.search_training')"
                @input="searchTraining"
                v-model="searchInput"
              />
              <div class="icon">
                <svg
                  v-if="searchInput == ''"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="22.882"
                  height="22.883"
                  viewBox="0 0 22.882 22.883"
                >
                  <defs>
                    <clipPath>
                      <rect
                        data-name="Rectangle 2331"
                        width="22.882"
                        height="22.883"
                        fill="#28345c"
                      />
                    </clipPath>
                  </defs>
                  <g id="Group_7557" data-name="Group 7557">
                    <path
                      id="Path_6095"
                      data-name="Path 6095"
                      d="M.606,22.279a2.069,2.069,0,0,1,0-2.927L6.779,13.18a8.509,8.509,0,0,1-.621-1.237,8.679,8.679,0,1,1,4.781,4.781A8.248,8.248,0,0,1,9.7,16.1L3.53,22.279a2.072,2.072,0,0,1-2.923,0M9.321,3.79a6.912,6.912,0,1,0,4.886-2.024A6.911,6.911,0,0,0,9.321,3.79Z"
                      transform="translate(0 0)"
                      fill="#28345c"
                    />
                  </g>
                </svg>
                <svg
                  v-else
                  @click="
                    searchInput = '';
                    searchTraining({ target: { value: '' } });
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.191"
                  height="17.191"
                  viewBox="0 0 17.191 17.191"
                >
                  <g
                    id="Group_8028"
                    data-name="Group 8028"
                    transform="translate(-1377.29 -528.908)"
                  >
                    <g
                      id="Group_656"
                      data-name="Group 656"
                      transform="translate(1385.887 530.693) rotate(45)"
                    >
                      <g
                        id="Path_749"
                        data-name="Path 749"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_751"
                          data-name="Path 751"
                          d="M10.357,5.183a.558.558,0,0,1-.165.4.546.546,0,0,1-.4.169H5.743V9.8a.569.569,0,0,1-.565.561.558.558,0,0,1-.4-.165.546.546,0,0,1-.169-.4V5.748H.561a.566.566,0,0,1-.4-.961.588.588,0,0,1,.4-.169H4.613V.569a.551.551,0,0,1,.169-.4A.558.558,0,0,1,5.178,0a.571.571,0,0,1,.565.569V4.618H9.8A.572.572,0,0,1,10.357,5.183Z"
                          transform="translate(0 0)"
                          fill="#28345c"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="training-container">
          <div
            v-for="(training, training_index) in filteredTrainings"
            :key="training_index"
            class="training"
          >
            <div class="training-header">
              <div class="order-number">
                <span>{{ training_index + 1 }}</span>
              </div>
              <h2>{{ training.title }}</h2>
            </div>
            <div class="training-options">
              <div
                class="cover"
                :style="{
                  'background-image': training.cover
                    ? `url('${getFileUrl(training.cover)}')`
                    : 'url(' +
                      require(`@/assets/images/placeholder_image_${$store.state.language}.png`) +
                      ')',
                }"
              ></div>
              <div class="label-container">
                <span>
                  {{ toLocal("group_side.average_score") }}
                  <span class="result">{{
                    training.average_result == null
                      ? "-"
                      : parseInt(training.average_result)
                  }}</span>
                  %
                </span>
                <span v-if="group.show_trainings==0">{{ toLocal("group_side.training_opens") }}</span>
                <span>{{ toLocal("group_side.training_repeats") }}</span>
                <span v-if="group.show_trainings==1">{{ toLocal("group_side.training_order_number") }}</span>
              </div>
              <div class="input-container">
                <div class="input-wrapper">
                  <div></div>
                  <div class="dropdown hidden"></div>
                </div>
                <div class="input-wrapper" v-if="group.show_trainings==0">
                  <input
                    type="text"
                    v-model="training.accessible_after"
                    @input="editedElement($event, training)"
                  />
                  <div
                    class="dropdown white small top"
                    :class="{
                      active:
                        activeDropdown == training_index &&
                        activeDropdownType == 1,
                    }"
                  >
                    <div
                      class="btn-container"
                      @click="openDropdown(training_index, 1)"
                    >
                      <span>{{
                        accessible_dropdown[training.accessible_after_type]
                          .name_short
                      }}</span>
                      <div class="dropdown-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.053"
                          height="7.191"
                          viewBox="0 0 13.053 7.191"
                        >
                          <path
                            id="Path_5930"
                            data-name="Path 5930"
                            d="M-3726.119-17222.309a.659.659,0,0,1-.467-.2l-5.866-5.861a.664.664,0,0,1,0-.937.66.66,0,0,1,.936,0l5.4,5.4,5.4-5.4a.658.658,0,0,1,.935,0,.663.663,0,0,1,0,.938l-5.865,5.861A.66.66,0,0,1-3726.119-17222.309Z"
                            transform="translate(3732.646 17229.5)"
                            fill="#28345c"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="dropdown-list"
                      v-if="
                        activeDropdown == training_index &&
                        activeDropdownType == 1
                      "
                      v-click-outside-3="clickOutsideSideSort"
                    >
                      <div
                        class="list-item"
                        v-for="(
                          dropdown, dropdown_index
                        ) in accessible_dropdown"
                        :key="dropdown_index"
                        :class="{
                          active: dropdown.id == training.accessible_after_type,
                        }"
                        @click="
                          training.accessible_after_type = dropdown.id;
                          editedElement(null, training);
                        "
                      >
                        {{ dropdown.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input-wrapper">
                  <input
                    type="text"
                    v-model="training.training_repeats_every"
                    @input="editedElement($event, training)"
                  />
                  <div
                    class="dropdown white small bottom"
                    :class="{
                      active:
                        activeDropdown == training_index &&
                        activeDropdownType == 2,
                    }"
                  >
                    <div
                      class="btn-container"
                      @click="openDropdown(training_index, 2)"
                    >
                      <span>{{
                        repeat_dropdown[training.training_repeats_every_type]
                          .name_short
                      }}</span>
                      <div class="dropdown-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.053"
                          height="7.191"
                          viewBox="0 0 13.053 7.191"
                        >
                          <path
                            id="Path_5930"
                            data-name="Path 5930"
                            d="M-3726.119-17222.309a.659.659,0,0,1-.467-.2l-5.866-5.861a.664.664,0,0,1,0-.937.66.66,0,0,1,.936,0l5.4,5.4,5.4-5.4a.658.658,0,0,1,.935,0,.663.663,0,0,1,0,.938l-5.865,5.861A.66.66,0,0,1-3726.119-17222.309Z"
                            transform="translate(3732.646 17229.5)"
                            fill="#28345c"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="dropdown-list"
                      v-if="
                        activeDropdown == training_index &&
                        activeDropdownType == 2
                      "
                      v-click-outside-3="clickOutsideSideSort"
                    >
                      <div
                        class="list-item"
                        v-for="(dropdown, dropdown_index) in repeat_dropdown"
                        :key="dropdown_index"
                        :class="{
                          active:
                            dropdown.id == training.training_repeats_every_type,
                        }"
                        @click="
                          training.training_repeats_every_type = dropdown.id;
                          editedElement(null, training);
                        "
                      >
                        {{ dropdown.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input-wrapper" v-if="group.show_trainings==1">
                  <div
                    class="dropdown white small bottom"
                    :class="{ active: activeDropdown == training_index &&activeDropdownType == 3}"
                  >
                    <div
                      class="btn-container"
                      @click="openDropdown(training_index, 3)"
                    >
                      <span> {{ filteredTrainings[training_index] && filteredTrainings[training_index].order_index != undefined ? filteredTrainings[training_index].order_index+1 : training_index+1}}</span>
                      <div class="dropdown-icon">
                        <svg                          xmlns="http://www.w3.org/2000/svg"                          width="13.053"                          height="7.191"                          viewBox="0 0 13.053 7.191"                        >                          <path                            id="Path_5930"                            data-name="Path 5930"                            d="M-3726.119-17222.309a.659.659,0,0,1-.467-.2l-5.866-5.861a.664.664,0,0,1,0-.937.66.66,0,0,1,.936,0l5.4,5.4,5.4-5.4a.658.658,0,0,1,.935,0,.663.663,0,0,1,0,.938l-5.865,5.861A.66.66,0,0,1-3726.119-17222.309Z"                            transform="translate(3732.646 17229.5)"                            fill="#28345c"                          />                        </svg>
                      </div>
                    </div>
                    <div
                      class="dropdown-list"
                      v-if="
                        activeDropdown == training_index &&
                        activeDropdownType == 3
                      "
                      v-click-outside-3="clickOutsideOrderList"
                      
                    >
                      <div
                        class="list-item"
                        v-for="item in filteredTrainings.length"
                        :key="item"
                        :class="{
                          active: training_index + 1 == item,
                        }"
                        @click="reOrderList(training_index + 1, item)"
                      >
                        {{ item}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import DatePicker from "vue2-datepicker";
export default {
  name: "NewGroupSide",
  data: function () {
    return {
      deactivationDate: this.group.deactivation_date,
      isExpired: false,
      shownExpiryDate: "",
      trainings: [],
      trainingsCopy: [],
      filteredTrainings: [],
      filteredTrainingsCopy: [],
      accessible_dropdown: [
        {
          id: 0,
          name: this.toLocal("generic.hours_multiple"),
          name_short: this.toLocal("generic.hours_short"),
        },
        {
          id: 1,
          name: this.toLocal("generic.days_multiple"),
          name_short: this.toLocal("generic.days_short"),
        },
        {
          id: 2,
          name: this.toLocal("generic.weeks_multiple"),
          name_short: this.toLocal("generic.weeks_short"),
        },
      ],
      repeat_dropdown: [
        {
          id: 0,
          name: this.toLocal("generic.days_multiple"),
          name_short: this.toLocal("generic.days_short"),
        },
        {
          id: 1,
          name: this.toLocal("generic.weeks_multiple"),
          name_short: this.toLocal("generic.weeks_short"),
        },
        {
          id: 2,
          name: this.toLocal("generic.months_multiple"),
          name_short: this.toLocal("generic.months_short"),
        },
        {
          id: 3,
          name: this.toLocal("generic.years_multiple"),
          name_short: this.toLocal("generic.years_short"),
        },
      ],
      filter_list: [
        {
          id: 0,
          name: this.toLocal("group_side.training_name_a_z"),
          key: "title",
          value: "desc",
        },
        {
          id: 1,
          name: this.toLocal("group_side.training_name_z_a"),
          key: "title",
          value: "asc",
        },
        {
          id: 2,
          name: this.toLocal("group_side.opening_of"),
          key: "accessible_after_type",
          value: 0,
        },
        {
          id: 3,
          name: this.toLocal("group_side.repetition"),
          key: "training_repeats_every_type",
          value: 0,
        },
        {
          id: 4,
          name: this.toLocal("group_side.training_order_index"),
          key: "order_index",
          value: 'asc',
        },
      ],
      selectedFilter: 0,
      activeDropdownType: -1,
      activeDropdown: -1,
      selectedCategoryFilter: -1,
      changedIDs: [],
      trainingsLoaded: false,
      searchInput: "",
      training_groups: [],
      filteredGroupIds: [],
    };
  },
  props: {
    group: {
      type: Object,
    },
    sideOpen: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    this.sideOpen = false;
  },
  computed: {
    selectedMultiChoice() {
      let filtered_groups = this.training_groups.filter((el) => el.checked);
      this.filteredGroupIds = filtered_groups.map((el) => el.id);
      if (filtered_groups.length == 0)
        return this.toLocal("users.select_category");
      let list = filtered_groups.map((el) => el.name).join(", ");
      return list;
    },
  },
  watch: {
    deactivationDate: {
      handler(newVal) {
        if (newVal != null) {
          let now = new Date(Date.now());
          let date = new Date(this.deactivationDate);
          let difference = now - date;
          let hours = Math.abs(Math.round(difference / 1000 / 60 / 60));

          if (date < now) {
            this.isExpired = true;
          } else {
            this.isExpired = false;
          }
          let days = 0;
          let months = 0;
          let years = 0;
          let fullDate = "";
          if (hours >= 24) {
            days = Math.floor(hours / 24);
            hours -= days * 24;
            if (days >= 30) {
              months = Math.floor(days / 30);
              days -= months * 30;
              if (months >= 12) {
                years = Math.floor(months / 12);
                months -= years * 12;
              }
            }
          }
          if (years != 0) {
            fullDate += years + this.toLocal("generic.years_short") + " ";
          }
          if (months != 0) {
            fullDate += months + this.toLocal("generic.months_short") + " ";
          }
          if (days != 0) {
            fullDate += days + this.toLocal("generic.days_short") + " ";
          }
          this.shownExpiryDate = fullDate;
        } else {
          this.shownExpiryDate = "";
        }
      },
    },
    group: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.deactivation_date != null) {
          this.deactivationDate = newVal.deactivation_date;
        }
        if (newVal && oldVal) {
          if (newVal.id != oldVal.id || !this.trainingsLoaded)
            this.getGroupTrainings();
        } else {
          this.getGroupTrainings();
        }
        this.searchInput = "";
      },
    },
    selectedFilter: {
      immediate: true,
      handler(newVal) {
        if (newVal != -1) {
          this.sortTrainings();
        }
      },
    },
    sideOpen() {
      this.searchInput = "";
    },

    "filteredGroupIds.length"() {
      this.searchTraining({ target: { value: this.searchInput } });
    },
  },

  methods: {
    handleDeactivation() {
      if (this.deactivationDate == "" || this.deactivationDate == null) {
        this.clearDeactivationDate();
      } else {
        this.setDeactivationDate();
      }
    },
    setDeactivationDate() {
      if (this.deactivationDate != "") {
        axios
          .post(
            `${this.$store.state.api_link}/group/deactivationDate/${this.group.id}`,
            { deactivationDate: this.deactivationDate },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },
    clearDeactivationDate() {
      axios
        .delete(
          `${this.$store.state.api_link}/group/deactivationDate/${this.group.id}`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    getGroupTrainings() {
      if (this.group.id == undefined) return;
      axios
        .get(
          `${this.$store.state.api_link}/group/${this.group.id}/trainings`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.trainings = response.data.trainings;
          if(this.trainings[0].order_index == undefined || this.trainings[0].order_index == null){
            this.trainings.forEach((el,i) => {
              el.order_index = i;
            });
          }
          this.trainingsCopy = this._.cloneDeep(this.trainings);
          this.filteredTrainings = this._.cloneDeep(this.trainings);
          this.filteredTrainingsCopy = this._.cloneDeep(this.trainings);
          this.training_groups = response.data.training_groups;
          if(response.data.group){
            this.group.show_trainings = response.data.group.show_trainings;
            this.group.deactivation_date = response.data.group.deactivation_date;
          }
          this.sortTrainings();
          this.trainingsLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDropdown(index, type) {
      if (type == 0 && this.activeDropdownType != 0) {
        this.activeDropdown = 0;
        this.activeDropdownType = type;
      } else if ((this.activeDropdown == index && this.activeDropdownType == type) || (type == 0 && this.activeDropdownType == 0)) {
        this.activeDropdown = -1;
        this.activeDropdownType = -1;
      } else {
        this.activeDropdown = index;
        this.activeDropdownType = type;
      }
    },
    sortTrainings() {
      
      this.filteredTrainings = this._.cloneDeep(this.filteredTrainingsCopy);
      let filter = this.filter_list[this.selectedFilter];
      let multiplier = [1, 24, 24 * 7, 24 * 30, 24 * 365];
      this.filteredTrainings.sort((a, b) => {
        if (filter.key == "title") {
          if (filter.value == "asc")
            return b[filter.key]
              .toLowerCase()
              .localeCompare(a[filter.key].toLowerCase());
          else {
            return a[filter.key]
              .toLowerCase()
              .localeCompare(b[filter.key].toLowerCase());
          }
        } else if (filter.key == "accessible_after_type") {
          let training1 =
            a.accessible_after * multiplier[a.accessible_after_type];
          let training2 =
            b.accessible_after * multiplier[b.accessible_after_type];
          return training1 > training2 ? 1 : -1;
        } else if (filter.key == "training_repeats_every_type") {
          let training1 =
            a.training_repeats_every *
            multiplier[a.training_repeats_every_type + 1];
          let training2 =
            b.training_repeats_every *
            multiplier[b.training_repeats_every_type + 1];
          return training1 > training2 ? 1 : -1;
        }
        else if(filter.key == "order_index"){
          return a.order_index > b.order_index;
        }
      });
    },
    editedElement(e, training) {
      let editingElementIndex = this.changedIDs.findIndex(
        (x) => x.id == training.id
      );
      if (editingElementIndex == -1) {
        this.changedIDs.push(training.id);
        this.saveChanges(this);
      }
    },
    saveChanges: _.debounce((self) => {
      let trainings = self.filteredTrainings.filter((el) =>
        self.changedIDs.includes(el.id)
      );
      axios
        .post(
          `${self.$store.state.api_link}/group/trainings/edit`,
          { trainings: trainings, group_id: self.group.id },
          self.$store.getters.axiosConfig()
        )
        .then((response) => {
          trainings.forEach((training) => {
            let index = self.trainingsCopy.findIndex(
              (x) => x.id == training.id
            );
            if (index != -1) {
              self.trainingsCopy[index] = training;
            }
          });
          self.changedIDs = [];
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    setTrainingShowing(newValue) {
      if (this.group.show_trainings != 0 && this.group.show_trainings != 1)
        return;
      if(newValue == this.group.show_trainings) return;
      let oldValue = this.group.show_trainings;
      this.group.show_trainings = newValue;
      axios
        .post(
          `${this.$store.state.api_link}/group/edit/shown_trainings`,
          { group_id: this.group.id, show_trainings: newValue },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.group.show_trainings = newValue;
        })
        .catch((error) => {
          this.group.show_trainings = oldValue;

        });
    },
    clickOutsideMainSort: function (e) {
      setTimeout(() => {
        if (this.activeDropdownType == 0) {
          this.activeDropdown = -1;
          this.activeDropdownType = -1;
        }
      }, 10);
    },
    clickOutsideSideSort: function (e) {
      setTimeout(() => {
        if (this.activeDropdownType == 1 || this.activeDropdownType == 2) {
          this.activeDropdown = -1;
          this.activeDropdownType = -1;
        }
      }, 10);
    },
    clickOutsideCategoryFilter: function (e) {
      setTimeout(() => {
        if (this.activeDropdown == 0 && this.activeDropdownType == 5) {
          this.activeDropdown = -1;
          this.activeDropdownType = -1;
        }
      }, 10);
    },
    clickOutsideOrderList: function (e) {
      setTimeout(() => {
        if (this.activeDropdownType == 3) {
          this.activeDropdown = -1;
          this.activeDropdownType = -1;
        }
      }, 10);
    },
    
    searchTraining(e) {
      let text = e.target.value;
      this.filteredTrainings = this._.cloneDeep(this.filteredTrainingsCopy);
      this.filteredTrainings = this.filteredTrainings.filter((el) => {
        let training_viable = false;
        if (this.filteredGroupIds.length > 0) {
          el.training_groups.forEach((group) => {
            if (this.filteredGroupIds.includes(group.id))
              training_viable = true;
          });
        } else {
          training_viable = true;
        }
        if (training_viable && text != "")
          training_viable = el.title.toLowerCase().includes(text.toLowerCase());

        return training_viable;
      });
    },
    reOrderList(currentWeight, newWeight){
      let currentIndex = currentWeight-1;
      let newIndex = newWeight-1;
      let currentItem = this.filteredTrainings[currentIndex];
      let currentNewItemIndex = this.filteredTrainings.findIndex(el=>el.order_index == newIndex);
      if(currentNewItemIndex != -1){
        this.$set(this.filteredTrainings[currentNewItemIndex], 'order_index', currentItem.order_index);
        let temp_index = this.filteredTrainingsCopy.findIndex(el=>el.order_index == newIndex);
        if(temp_index != -1){
          this.$set(this.filteredTrainingsCopy[temp_index], 'order_index', currentItem.order_index);
        }
      }

      let temp_index = this.filteredTrainingsCopy.findIndex(el=>el.id == currentItem.id);
      if(temp_index != -1){
        this.$set(this.filteredTrainingsCopy[temp_index], 'order_index', newIndex);
      }

      currentItem.order_index = newIndex;
      this.filteredTrainings[currentIndex] = currentItem;
      let temp_array = this._.cloneDeep(this.filteredTrainings);
      let ids = temp_array.sort((a,b)=>a.order_index > b.order_index).map((el) => el.id);
      if (this.activeDropdownType == 3) {
          this.activeDropdown = -1;
          this.activeDropdownType = -1;
      }
      
      axios
        .post(
          `${this.$store.state.api_link}/group/trainings/edit`,
          { training_ids: ids, group_id: this.group.id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.sortTrainings();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  components: {
    DatePicker,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";
.full-wide {
  width: 100%;
}
.on-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.row {
  .row-block {
    width: 50%;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .long {
      margin-bottom: 8px;
      width: 88%;
      text-align: start;
    }
  }
}
.deactivation-time {
  display: flex;
  flex-direction: column;
  width: 32%;
  .deactivation-text {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 8px;
  }
}
.side--background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s opacity;

  &.side--open {
    opacity: 1;
    pointer-events: auto;
  }
}
.group--side {
  position: fixed;
  right: -45rem;
  background-color: #fff;
  height: calc(100vh - 100px);
  top: 100px;
  width: 750px;
  z-index: 11;
  font-family: var(--main-site-font);
  transition: 0.3s right;

  &.side--open {
    right: 0rem;
  }

  &.header--disabled {
    height: 100vh;
    top: 0px;
  }
}
.side--content {
  overflow: auto;
  height: 100%;
  padding: 60px 50px;
  padding-left: 20px;
  overflow-x: hidden;
}

.side--close {
  position: absolute;
  left: -100px;
  top: 44px;
  border: transparent;
  // border-right: 20px solid #EFEFEF;
  padding: 20px;
  border-radius: 100%;
  width: 87px;
  height: 87px;
  cursor: pointer;
  user-select: none;

  svg {
    width: 47px;
    height: 47px;
    path {
      fill: var(--primary-color);
    }
  }
}

.side--padding {
  position: absolute;
  margin-left: -35px;
  margin-top: -80px;
  width: 40px;

  .side--padding--corner {
    height: 250px;
    display: block;
  }

  .side--padding--bottom {
    height: calc(100vh - 150px);
    width: 35px;
    background-color: #fff;
    margin-top: -4px;
  }
}
.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    width: 100%;
    padding: 0.25rem 1.5rem 0 0;
    margin-bottom: 12px;
    font-family: var(--main-site-font);
    font-weight: 600;
    
  }
  .training-count {
    width: 100%;
    margin: 0;
    margin-bottom: 1.5rem;
    .number {
      min-height: initial;
      background: none;
      font-weight: 500;
    }
  }
}
.group--section--select {
  margin-top: -60px;

  .group--section--select--button {
    width: 50%;
    float: left;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    user-select: none;
    background-color: $background_color;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: 0.3s box-shadow, 0.3s background-color;

    &.active,
    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background-color: $group_color;
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.group--title {
  font-size: 35px;
  padding-top: 15px;
  font-weight: 900;
  padding-left: 30px;

  span {
    font-size: 35px;
    display: block;
  }
}

.lesson--list {
  position: relative;
}

.side--table--control {
  margin-top: 50px;
  width: 100%;
  margin: 0;
  position: absolute;
  // right: 0;
  left: 0;
  transition: 0.3s left;

  &.hidden {
    left: calc(-100% - 150px);

    &.search--table {
      left: calc(200% + 150px);
    }
  }

  tr {
    border-radius: 35px;
  }

  svg {
    margin-top: -2px;
  }

  &.search--table {
    tr td {
      border-top: 1px solid #464646;
      border-bottom: 1px solid #464646;

      &:first-child {
        border-left: 1px solid #464646;
      }
      &:last-child {
        border-right: 1px solid #464646;
      }
    }
    svg {
      margin-top: -9px;
    }
  }
}
.group--button--container {
  display: flex;
  .filter-button {
    flex: 1;
    border-radius: 35px;
    margin-top: 20px;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
}

.group--add--button {
  flex: 2;
  padding: 12px;
  border-radius: 35px;
  padding-right: 35px;
  cursor: pointer;
  user-select: none;
  transition: 1s background-color, 0.3s transform, 0.3s box-shadow;
  // background-color: #EFEFEF;
  // margin: 0 -35px;
  margin-top: 20px;

  .group--add--button--icon {
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #28345c;
    border-radius: 100%;
    vertical-align: middle;

    svg {
      max-width: 28px;
      max-height: 28px;
      fill: #28345c;
    }
  }

  .group--add--button--title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #707070;
    width: calc(100% - 70px);

    input {
      background: transparent;
      border: none;
      outline: none;
      color: $font_color;
      font-size: 20px;
      font-family: var(--main-site-font);
      width: calc(100% - 40px);
    }
  }

  &:hover {
    box-shadow: 0px 3px 6px #00000033;
    transform: translateY(-3px);
  }
}
.inactive {
  td {
    background: #efefef;
  }
}
.content-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  overflow: auto;
  color: var(--primary-color);

  .row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      margin-top: 40px;
      color: var(--primary-color);
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }
    &.top {
      z-index: 2;
    }
    &.bottom {
      z-index: 1;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .grid-row {
    display: grid;
    width: 100%;
    align-items: center;
    &.header {
      width: 95%;
      grid-template-columns: 70% 30%;
      h2 {

        padding: 0.25rem 1rem;
      }
    }
  }
  .grid-buttons {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 2fr 2fr;
    grid-template-areas: "label button button2";
    gap: 0.25rem 0.25rem;
    align-items: center;
    span {
      font-size: clamp(14px, 0.8vw, 16px);
      width: 100%;
      text-align: start;
    }
    .grid-row {
      width: 100%;
    }
    width: 100%;
    span:first-child {
      grid-area: label;
    }
    .btn:first-child {
      grid-area: button;
    }
    .dropdown {
      grid-area: dropdown;
    }
    &.flex {
      display: flex;
      span {
        width: 100%;
      }
      .btn {
        margin: 0 8.5px;
        padding: 15px .25rem;
        font-size: 14px;
        background: #FFFFFF;
        border: 1px solid #7D7D7D;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 44px;
        &:first-child{
          margin-left: 0;
        }
        &.active{
          background: var(--secondary-color);
          border: none;
        }
      }
    }
  }
}
.row-dropdown {
  width: 100%;
  margin-top: 1rem;
  padding-left: 1.5rem;
  span {
    min-width: 17%;
    display: flex;
    font-size: clamp(16px, 0.8vw, 18px);
  }
  .long {
    min-width: 35%;
  }
}
.dropdown {
    display: flex;
    position: relative;
    border-radius: 2rem;
    padding: 0.25rem 0.5rem;
    justify-content: space-between;
    cursor: pointer;
    width: 90%;
    z-index: 2;
    height: 32px;
    margin: 0;
    width: 100%;
    font-size: clamp(14px, 1vw, 16px);
    background: #FFFFFF;
    border: 1px solid #7D7D7D;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 44px;
    
  .dropdown-icon {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.25rem;
    min-width: 1.2rem;
    width: 1.2rem;
    min-height: 1.2rem;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    .title {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 86%;
      width: 14px;
    }
  }
  .dropdown-list {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0.25rem;
    top: 0.5rem;
    padding-top: 1rem;
    border-radius: 1rem;

    background: #efefef;
    border: none;
    border-top: none;
    z-index: -1;
    &.multi {
      padding-bottom: 0;
      overflow: hidden;
    }
    .list-item {
      font-size: clamp(14px, 0.8vw, 18px);
      margin: 0.5rem 0;
      user-select: none;
      &.multichoice {
        display: flex;
        align-items: center;
        .checkbox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 1.5rem;
          width: 1.5rem;
          background: white;
          border-radius: 50%;
          margin-right: 0.5rem;
          &.active {
            background: var(--secondary-color);
          }
          svg {
            transform: rotate(0deg);
            path {
              stroke: var(--primary-color);
            }
          }
        }
      }
      &:hover {
        font-weight: 700;
      }

      &.active {
        font-weight: 700;
      }
    }
    .dropdown-wrapper {
      display: flex;
      flex-direction: column;
      max-height: 14rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &.white {
    background: white;
    .dropdown-icon {
      background: white;
    }
    .dropdown-list {
      background: white;
    }
  }
  &.small {
    padding: 0.2rem 0.25rem;
    width: 4.5rem;
    .btn-container {
      background: white;
    }
    span {
      text-align: center;
      font-size: clamp(0.7em, 1.1vw, 1em);
    }
  }
  &.big {
    height: 37px;
    border: 1px solid var(--primary-color-opacity-50);
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    max-width: 300px;
    .dropdown-icon {
      height: 25px;
      width: 25px;
    }
    .dropdown-list {
      .list-item {
        &:first-child {
          // border-top: 1px solid var(--primary-color);
          padding-top: 0.5rem;
          // margin-top: 0.75rem;
        }
      }
    }
  }
  &.top {
    z-index: 3;
  }
  &.bottom {
    z-index: 2;
  }
  &.active {
    padding: 0;
    border-bottom: none;
    background: none;
    border: none;
    z-index: 9;
    .btn-container {
      background: #efefef;
      padding: 0.25rem 0.5rem;
      border: 1px solid var(--primary-color-opacity-50);
      border-radius: 1rem;
    }
    svg {
      transform: rotate(180deg);
    }
    &.small {
      .btn-container {
        background: white;
        border: none;
        padding: 0.2rem 0.25rem;
      }
      .dropdown-list {
        top: 0;
        border-radius: 0.75rem;
        .list-item:first-child {
          border-top: 1px solid;
          padding-top: 0.25rem;
          margin-top: 5px;
        }
      }
    }
  }
  &.hidden {
    opacity: 0;
  }
}

.training-count {
  display: flex;
  margin: 0 0rem 0 2rem;
  .long {
    width: 70%;
  }
  .label,
  .number,
  .long {
    font-size: clamp(14px, 0.8vw, 16px);
  }
}
.number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  min-width: 3rem;
  border-radius: 50%;
  background: #efefef;
  // border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 700;
  font-size: clamp(1em, 1.2vw, 18px);
}

.btn {
  display: flex;
  width: 100%;
  // margin: 0 0.25rem;
  justify-content: center;
  border-radius: 2rem;
  font-size: clamp(0.5em, 1vw, 16px);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  cursor: pointer;
  height: 30px;
  align-items: center;
  &.active {
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    font-weight: 900;
  }
}
.training {
  background: #efefef;
  padding: 12px 16px;
  border-radius: 1rem;
  margin: 0.5rem 0;
  box-sizing: border-box;
  width: 100%;
  padding-right: 0;
  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 100px;
  }
  .label-container,
  .input-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    color: var(--primary-color);
    .input-wrapper {
      display: flex;
    }
    .result {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 50%;
      font-size: clamp(16px, 0.8vw, 18px);
      min-width: 32px;
      width: 0.8vw;
      max-width: 36px;
      min-height: 32px;
      height: 0.8vw;
      max-height: 36px;
      padding: 0.5rem 0rem;
      margin: 0rem 0.25rem 0 0.5rem;
    }
  }
  .input-container{
    height: 132px;
    margin-left: 12px;
  }
  .label-container {
    margin-left: 12px;
    height: 132px;
    span {
      display: flex;
      font-size: 16px;
      line-height: 22px;
      align-items: center;
    }
  }
  input {
    width: 2.65rem;
    background: none;
    border: 1px solid #aaa;
    border-radius: 2rem;
    padding: 0.2rem;
    font-size: clamp(16px, 1vw, 18px);
    text-align: center;
    font-weight: 300;
    height: 32px;
    margin-right: 12px;
  }
}
.training-options {
  display: flex;
  align-items: center;
  align-items: center;
  .cover {
    display: flex;
    height: 132px;
    width: 132px;
    border-radius: 0.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
  }
}
.training-header {
  display: flex;
  width: 100%;
  // align-items: center;
  align-items: center;
  margin-bottom: 16px;
  .order-number{
    span{
      color: #7D7D7D;
      font-weight: 600;
      padding-right: 12px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  // border-bottom: 1px solid var(--primary-color);
  h2 {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: none;
    font-size: 20px;
    line-height: 28px;
    color: var(--primary-color);
    margin: 0;
    padding-left: 12px;
    min-height: 27px;
    border-left: 1px solid #C5C5C5;
  }
  .avg-result {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: min-content;
    margin-left: 0.3rem;
    color: var(--primary-color);
    font-size: clamp(14px, 1vw, 16px);

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 2vw;
      padding: 0.25rem;
      margin-left: 0.5rem;
      font-weight: 700;
      width: 4.5rem;
      height: 30px;
    }
    span {
      display: flex;
      align-self: center;
      margin-right: 0.25rem;
    }
  }
}
.option-container {
  background: white;
  z-index: 2;
  padding-bottom: 20px;
}
.training-container {
  // position: absolute;
  // padding-top: 26%;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  // margin-top: 1rem;
  z-index: 1;
}
.search-container {
  display: flex;
  border-radius: 1vw;
  padding: 4px 12px;
  margin: 0 0rem 0 0.75rem;
  height: 32px;
  background: #F4F4F4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 44px;
  max-width: 200px;
  input {
    background: none;
    border: none;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .icon {
    display: flex;
    height: 100%;
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
      height: 100%;
      width: 100%;
      path {
        fill: var(--primary-color-opacity-50);
      }
    }
  }
}
.btn-container {
  display: flex;
  width: 100%;
  span {
    width: 100%;
  }
}
</style>
<style lang="scss" >
.secondary-active-calendar{
  background: #FFFFFF;
  border-radius: 8px;
  max-width: 130px;
  .mx-input{
    border: 1px solid #7D7D7D;
    max-width: 130px;
    border-radius: 8px;
  }
  .mx-input-wrapper{
    .mx-icon-calendar{
      display: flex;
    }
  }
}
</style>