<template>
  <div class="info-modal-container">
    <div class="info-modal">
        <div class="close-btn" @click="$emit('cancel')">
            <div>
                <span></span><span></span>
            </div>
        </div>
        <h2 v-if="modalType == 'save'">{{toLocal('save_modal.confirmation')}} {{user.name && user.last_name ? user.name + ' ' + user.last_name : user.email ? user.email : ''}}?</h2>
        <h2 v-else> {{toLocal('save_modal.new_added_user')}} {{user.name && user.last_name ? user.name + ' ' + user.last_name : user.email ? user.email : ''}} {{toLocal('save_modal.username_is')}}</h2>
        <h3 v-if="modalType == 'add'">{{user.username}}</h3>
        <div class="row">
            <div class="btn" v-if="modalType == 'save'" @click="$emit('cancel')">{{toLocal('save_modal.cancel')}}</div>
            <div class="btn" v-if="modalType == 'save'" @click="$emit('confirm')">{{toLocal('save_modal.save')}}</div>
            <div class="btn" v-if="modalType == 'add'" @click="$emit('accept')">{{toLocal('save_modal.ok')}}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        user:{
            type: Object,
            required: true
        },
        modalType:{
            type: String,
            required: true,
            default: 'save'
        }
    },
    name: "TaskInfoModal",
}
</script>

<style lang="scss" scoped>
.info-modal-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;
    background: #9d9d9d66;
    z-index: 999;
}
.info-modal{
    display: flex;
    flex-direction: column;
    position: relative;
    background: #efefef;
    border: 0.25rem solid white;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    max-width: 27rem;
}
.close-btn{
    display: flex;
    position: absolute;
    top:0.25rem;
    right: 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    padding: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }
    span{
        display: flex;
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--primary-color);
        transform: rotate(45deg);
        &:last-child{
            transform: rotate(-45deg);
        }
    }
}
.btn{
    background: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 2rem;
    text-align: center;
    padding: 0.25rem 2rem; 
    margin-top: 1rem;
    cursor: pointer;
    &:hover{
        text-shadow: 1px 0 0 var(--primary-color);
    }
    &:active{
         box-shadow: 0 0 0 1px var(--primary-color);
    }
    &:first-child{
        background: white;
    }
}
h2{
    text-align: center;
    padding: 0 2rem;
    color: var(--primary-color);
}
h3{
    margin: 0;
    text-align: center;
    background: white;
    border-radius: 10px;
    padding: 5px 0;
    color: var(--primary-color);
}
.row{
    display: flex;
    justify-content: space-evenly;

}
</style>