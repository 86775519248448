import store from '@/store'
export function getDroplets(){

    return [
        [
            {
            id: 0,
            title: store.getters.__("training_edit.droplet_text"),
            image: require("@/assets/images/icons/droplet-text.svg"),
            type: "text",
            },
            {
            id: 2,
            title: store.getters.__("training_edit.block.media"),
            image: require("@/assets/images/icons/droplet-video.svg"),
            type: "video",
            },
            {
            id: 3,
            title: store.getters.__("training_edit.doc"),
            image: require("@/assets/images/icons/droplet-document.svg"),
            type: "doc",
            },
        ],
        [
            {
            id: 6,
            title: store.getters.__("training_edit.droplet_seperator"),
            image: require("@/assets/images/icons/droplet-divider.svg"),
            type: "seperator",
            },
            {
            id: 8,
            title: store.getters.__("training_edit.droplet_confirm"),
            image: require("@/assets/images/icons/droplet-confirm.svg"),
            type: "confirm",
            },
        ],
        [
            {
            id: 7,
            title: store.getters.__("training_edit.droplet_task"),
            image: require("@/assets/images/icons/droplet-task.svg"),
            type: "task",
            },
        ]
    ];
}
