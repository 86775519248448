<template>
  <div class="answered-questions-container">
      <div 
        class="question"
        v-for="question in questions" 
        :key="question.id">
        <editor
            class="question-title"
            :isEditing="false"
            :readOnly="true"
            :value="question.text"
        />
        <div class="question-content-container" :class="{images: question.type == 2, big: question.size == 1}">
            <div v-for="(answer, answerIndex) in question.answers" :key="answer.id">
                <div  
                    v-if="question.type == 1" 
                    class="answer" 
                    :class="{selected: answer.selected, correct: topic && topic.options.testCorrectAnswersShown == 1 ? answer.correct : type=='showAll' ? answer.correct : ''}"
                >
                    <div 
                    :class="['task--answer--letter']"
                    >
                     {{ String.fromCharCode(65 + answerIndex) }}
                    </div>
                    {{ answer.text }}
                </div>
                <div 
                    v-else-if="question.type == 2 " 
                    class="answer image" 
                    :class="{selected: answer.selected, correct: topic && topic.options.testCorrectAnswersShown == 1 ? answer.correct : type=='showAll' ? answer.correct : '', big: question.size == 1}"
                >
                    <div 
                    :class="['task--answer--letter', answer.selected ? 'selected' : '']"
                    >
                     {{ String.fromCharCode(65 + answerIndex) }}
                    </div>
                    <div class="image-container">
                    <ImageViewer
                        v-if="(answer.metadata ? answer.metadata.data : false)"
                        :src="answer.image != null ? $store.state.api_uploads_link+answer.image : ''"
                        :height="question != undefined ? question.size == 1 ? 416.25 : 200 : undefined"
                        :width="question != undefined ? question.size == 1 ? 740 : 200 : undefined"
                        :metadata="answer.metadata"
                        :size="question.size"
                    />
                     <NewImageEditor 
                        v-if="(answer.metadata ? !answer.metadata.data : true)"
                        :readOnly="true"
                        :item="answer"
                        type="cover"
                        :isEditing="false"
                        :height="question != undefined ? question.size == 1 ? 416.25 : 200 : undefined"
                        :width="question != undefined ? question.size == 1 ? 740 : 200 : undefined"
                        :src="answer.image != null ? $store.state.api_uploads_link+answer.image : ''"
                    />
                  
                    </div>
                </div>
                 <div  class="answer joint" v-else-if="question.type == 3">
                    <div class="part1">{{answer.part1}}</div>
                    <div class="part2">{{answer.part2}}</div>
                </div>
                <div  class="answer open" v-else-if="question.type == 4" :class="{correct: topic && topic.options.testCorrectAnswersShown == 1 ? answer.score == question.score : type=='showAll' ? answer.score == question.score :  ''}">
                     <editor
                        class="open-answer"
                        :isEditing="false"
                        :readOnly="true"
                        :value="answer.answer_text"
                    />
                </div>
                <div  class="answer click" v-else-if="question.type == 5">
                    <div class="tries-container">
                        <div class="tries" v-if="answer.clicks">
                            <span>Bandymai:</span>
                            <span>{{answer.clicks.length}}</span>
                        </div>
                    </div>
                    <ClickTask
                    v-if="answer"
                    class="click-task-container"
                    :question="question"
                    :answer="answer"
                    :readOnly="true"
                    :statsMode="true"
                    :clicks="answer.clicks"
                    />
                </div>
                    <!-- <div class="comment" v-if="answer.comment != undefined && answer.comment != ''">
                        <h3>Komentaras:</h3>
                        <p>{{answer.comment}}</p>
                        
                    </div> -->
            </div>
        </div>

        <comment v-if="question.comment && question.comment != ''">
            <template v-slot:comment>
                {{question.comment}}
            </template>
            <template v-slot:date>
                {{question.comment_date}}
            </template>
        </comment>
      </div>
  </div>
</template>

<script>
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import ClickTask from "@/components/layout/trainings/training_components/ClickTask.vue";
import ImageEditor from "@/components/layout/trainings/ImageEditor.vue";
import Comment from "@/components/layout/trainings/training_components/test/TestUser/Comment.vue";
import ImageViewer from '@/components/layout/trainings/training_components/ImageViewer.vue';
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";

export default {
    name: 'AnsweredQuestions',
    components:{
        Editor,
        ImageEditor,
        Comment,
        ClickTask,
        ImageViewer,
        NewImageEditor
    },
    data(){
        return {
            questions: [],
        }
    },
    props:{
        answeredQuestions: {
            type: Array,
            required: true,
        },
        topic: {
            type: Object,
            required: true
        },
        type: String
    },
    watch:{
        answeredQuestions:{
            immediate:true,
            handler(){
                this.questions = this._.cloneDeep(this.answeredQuestions);
                if(this.topic && this.topic.options.testAnswerShowType == 1){
                        this.questions = this.questions.filter(el=>el.isCorrect);
                }
                if(this.topic && this.topic.options.testAnswerShowType == 3){
                    this.questions = this.questions.filter(el=>!el.isCorrect);
                }
            }
        }
    },
    mounted(){
        this.questions = this._.cloneDeep(this.answeredQuestions);

        if(this.topic && this.topic.options){
            if(this.topic && this.topic.options.testAnswerShowType == 1){
                this.questions = this.questions = this.questions.filter(el=>{
                    if(el.type == 4){
                        return el.score == el.answers[0].score;
                    }
                    return el.isCorrect
                });
            }
            if(this.topic && this.topic.options.testAnswerShowType == 3){
                this.questions = this.questions = this.questions.filter(el=>{
                     if(el.type == 4){
                        return el.score != el.answers[0].score;
                    }
                    return !el.isCorrect
                });
            }

        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_training.scss";
.answered-questions-container{
    display: flex;
    flex-direction: column;

    width: 100%;
    background: white;
    border-radius: 2rem;
    padding: 2rem;
}
.question-title{
    margin: 0 1rem 1rem;
}
.question{
    margin: 1rem 0;
}
.question-content-container{
    &.images{
        display: flex;
        flex-wrap: wrap;
    }
    &.big{
        flex-direction: column;
        align-items: center;
    }
}
.answer{
    display: flex;
    align-items: center;
    background: #efefef;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    &.image{
        padding: 0;
        position: relative;
        width: 200px;
        height: 200px;
        overflow: hidden;

        .task--answer--letter{
            position: absolute;
            left: 0.5rem;
            top: 0.5rem;
            width: 2rem;
            height: 2rem;
        }
        &.selected{
            // border-color: white;
            // color: white;
            .task--answer--letter{
                border: 3px solid var(--primary-color);
                background: var(--primary-color);
                color: white;
            }
            background: white;
            outline: 3px solid var(--primary-color);

        }
        &.correct{
            .task--answer--letter{
                border: 3px solid #56BC5D;
            }
            border: 3px solid #56BC5D;
        }
        &.big{
            width: 740px;
            height: 416.25px;
        }
    }
    &.click{
        flex-direction: column;
    }
    &.joint{
        display: grid;
        grid-template-columns: 1fr 1fr;;
        .part1, .part2{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1.5rem 1rem;
        }
    }
    &.open{
        &.correct{
            border: 3px solid #56BC5D;
        }
    }
    &.selected{
        background: var(--primary-color);
        color: white;
        .task--answer--letter{
            border-color: white;
        }
    }
    &.correct{
        border: 3px solid #56BC5D;
    }
    &.incorrect{
        border: 3px solid #F48282;
    }
}
.image-container{
    z-index: 0;
    display: flex;
}
.task--answer--letter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    margin-right: 0.5rem;
    background: white;
    z-index: 2;
    color: var(--primary-color);
}
.open-answer{
    width: 100%;
}
.comment{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: 2px solid var(--primary-color);
    padding-left: 1rem;
    h3{
        font-size: clamp(16px, 1.3vw, 24px);

    }
    p{
        display: flex;
        word-break: break-all;
        // background: white;
        // padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
    }
}
 .tries-container{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;
        .tries{
            display: flex;
            background: white;
            padding: 2px 10px;
            border-radius: 10px;
            width: 6rem;
            justify-content: center;
            align-items: center;
            text-align: right;
            font-size: 16px;
            span:nth-child(2){
                margin: 0 11px;
                font-weight: 700;
                margin-bottom: -2px;
            }
        }
 }
 .click-task-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
 }
</style>