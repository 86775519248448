<template>
  <section name="settings">
    <div :class="{'container main--container settings-container training': true, 'side--open': $store.getters.isSideOpen() }">
      <SettingsTabs 
        :settings_page="settings_page" 
        :showBillTab="$store.state.user_role == 'admin' ? true : invoices.length > 0" 
        @tabChange="changeSettingsGroup"
        >
          <template v-slot:content>
              <div class="content mobile" v-if="settings_page == 'userInfo' && isMobileWindow()">
                <UserReadInfo />
              </div>
              <div class="content mobile" v-if="settings_page == 'user' && isMobileWindow()">
                <UserInfo />
              </div>
              <div class="content mobile" v-else-if="settings_page == 'system' && $store.getters.hasPermission('training.*') && isMobileWindow()">
                <SystemSettings />
              
              </div>
              <div class="content mobile" v-else-if="$store.getters.hasPermission('training.*')" v-show="settings_page == 'stats' && isMobileWindow()">
                <ServerStats />
              </div>
              <div class="content mobile billing" v-show="settings_page == 'bills' && isMobileWindow()">
                <BillAccountManagement :paymentMonthlyData="paymentMonthlyData" :invoices="invoices" @showPaymentInfoModal="paymentInfoModal = $emit" />
              </div>
          </template>
        </SettingsTabs>
        <div v-if="settings_page == 'userInfo' && !isMobileWindow()">
          <UserReadInfo />
        </div>
        <div v-if="settings_page == 'user' && !isMobileWindow()">
          <UserInfo />
        </div>
        <div v-else-if="settings_page == 'system' && $store.getters.hasPermission('training.*') && !isMobileWindow()">
          <SystemSettings />
        
        </div>
        <div v-else-if="$store.getters.hasPermission('training.*')" v-show="settings_page == 'stats' && !isMobileWindow()">
          <ServerStats />
        </div>
        <div v-show="settings_page == 'bills' && !isMobileWindow()">
          <BillAccountManagement :paymentMonthlyData="paymentMonthlyData" :invoices="invoices" @showPaymentInfoModal="paymentInfoModal = $emit" />
        </div>
    </div>
    <ImportModal type="user-delete" />
    <PaymentInfoModal v-if="paymentInfoModal" @close="paymentInfoModal=false"/>
    <portal-target name="global-modal-settings"></portal-target>
  </section>
</template>

<script>
import axios from "axios";
import { Chrome } from 'vue-color'  
import ServerStats from '@/components/layout/ServerStats'
import UserInfo from '@/components/layout/settings/UserInfo'
import UserReadInfo from '@/components/layout/settings/UserReadInfo.vue'
import ImportModal from '@/components/layout/ImportModal.vue'
import SystemSettings from "../components/layout/settings/SystemSettings.vue";
import SettingsTabs from '@/components/layout/settings/SettingsTabs.vue'
import BillAccountManagement from '@/components/layout/settings/BillAccountManagement.vue'
import PaymentInfoModal from '@/components/layout/settings/PaymentInfoModal.vue'
export default {
  name: "Settings",
  components: {
    ServerStats,
    UserInfo,
    ImportModal,
    SystemSettings,
    SettingsTabs,
    BillAccountManagement,
    PaymentInfoModal,
    UserReadInfo,
  },
  data: function(){
    return {
      primary_color: {hex: '#fff'},
      secondary_color: {hex: '#fff'},
      third_color: {hex: '#fff'},
      primary_color_selector: false,
      secondary_color_selector: false,
      third_color_selector: false,
      current_password: '',
      password: '',
      confirm_password: '',
      password_message: '',
      settings_page: 'userInfo',
      system_settings_message: '',

      static_main_logo_label: "", // 1. Prisijungimo lango logotipas (250x250px)
      static_login_bg_label: "", // 2. Prisijungimo lango fonas (1920x1080px)
      static_logo_label: "", // 3. Logotipas puslapio višuje (50x50px)

      main_logo_label: this.static_main_logo_label,
      login_bg_label: this.static_login_bg_label,
      logo_label: this.static_logo_label,
      paymentInfoModal: false,
      invoices: [],
      paymentMonthlyData:{},
    }
  },
  activated() {
    this.static_main_logo_label = this.toLocal('settings.setting_1'); // 1. Prisijungimo lango logotipas (250x250px)
    this.static_login_bg_label = this.toLocal('settings.setting_2'); // 2. Prisijungimo lango fonas (1920x1080px)
    this.static_logo_label = this.toLocal('settings.setting_3'); // 3. Logotipas puslapio višuje (50x50px)
    this.password_message = "";
    this.current_password = "";
    this.password = "";
    this.confirm_password = "";
    this.settings_page = "userInfo";
    this.system_settings_message = "";
    this.main_logo_label = this.static_main_logo_label;
    this.login_bg_label = this.static_login_bg_label;
    this.logo_label = this.static_logo_label;
    this.primary_color = {hex: this.$store.state.system_settings.primary_color};
    this.secondary_color = {hex:  this.$store.state.system_settings.secondary_color};
    this.third_color= {hex:  this.$store.state.system_settings.third_color};
    this.getInvoices(this.$store.state.user_role);
    if(this.$route.name == 'settings') {
      let query = this.$route.query;
      if (query.tab) {
        this.settings_page= query.tab
      }
    }
    // this.loadAndConnect('meet.jit.si', 'learnkeytest');
  },
  watch: {
    '$route'() {
      if(this.$route.name == 'settings') {
        let query = this.$route.query;
        if (query.tab) {
          this.settings_page= query.tab
        }
      }
    }
  },

  methods: {
    mainLogoChange(e) {
      let main_logo_files = this.$refs['main_logo'].files;
      if(main_logo_files.length){
        this.main_logo_label = main_logo_files[0].name;
      }else{
        this.main_logo_label = static_main_logo_label;
      }
    },
    loginBgChange(e) {
      let login_bg_files = this.$refs['login_bg'].files;
      if(login_bg_files.length){
        this.login_bg_label = login_bg_files[0].name;
      }else{
        this.login_bg_label = static_login_bg_label;
      }
    },
    logoChange(e) {
      let logo_files = this.$refs['logo'].files;
      if(logo_files.length){
        this.logo_label = logo_files[0].name;
      }else{
        this.logo_label = static_logo_label;
      }
    },

    changeSettingsGroup(group) {
      if (this.isMobileWindow() && this.settings_page == group) {
        this.settings_page = -1
      }
      else {
        this.settings_page = group;
      }
    },
    changeSystemSettings() {
      let main_logo_files = this.$refs['main_logo'].files;
      let login_bg_files = this.$refs['login_bg'].files;
      let logo_files = this.$refs['logo'].files;

      if (!main_logo_files.length && !login_bg_files.length && !logo_files.length)
        return;
        
      let formData = new FormData();
      if(main_logo_files.length){
        formData.append('main_logo', main_logo_files[0]);
      }
      if(login_bg_files.length){
        formData.append('login_bg', login_bg_files[0]);
      }
      if(logo_files.length){
        formData.append('logo', logo_files[0]);
      }
      
      this.system_settings_message = this.toLocal('settings.settings_updating');

      axios.post(
          `${this.$store.state.api_link}/change-settings`,
          formData,
          this.$store.getters.axiosConfigMultipart()
      ).then((response) => {
        if(response.data.status == "success"){
          this.system_settings_message = this.toLocal('settings.settings_update_success');
        }else{
          this.system_settings_message = this.toLocal('settings.settings_update_error_1');
        }
      })
      .catch(() => {
          this.system_settings_message = this.toLocal('settings.settings_update_error_2');
      });
    },
    clickOff(e){
      if(!e.target.classList.contains('color-container')){
        this.primary_color_selector = false;
        this.secondary_color_selector = false;
        this.third_color_selector = false;
      }
    },
    saveTheme(){
      axios.post(
          `${this.$store.state.api_link}/change-settings/theme`,
          {'primary_color': this.primary_color.hex, 'secondary_color': this.secondary_color.hex, 'third_color': this.third_color.hex},
          this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){

          this.$store.commit('changeThemeColor', [this.primary_color.hex, this.secondary_color.hex, this.third_color.hex]);
        }
      })
      .catch(() => {
      });
    },
    getInvoices(role){
      axios.get(
          `${this.$store.state.api_link}/invoices${role=='admin'? '' : '/user'}`,
          this.$store.getters.axiosConfig()
      ).then((response) => {
        if(response.data.status == "success"){
          this.invoices = response.data.invoices;
          if(role == 'admin')
            this.paymentMonthlyData= response.data.info;
        }
      })
      .catch(() => {
      });
    }

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_settingsViewScoped.scss";

</style>
<style lang="scss">
.settings-container{

  .content{
    &.mobile{
      background-color: white;
      border-radius: 0 6px 6px 6px;
      .data-container{
        border-top-left-radius: 0;
      }
      .container{
        margin: 0;
      }
      &.billing{
        padding: 24px;
      }
      .row.short{
        margin-top: 0;
      }
    }
  }
}
</style>