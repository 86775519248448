<template>
  <div class="modal">
    <!-- <div class="header">
        <div class="training-title">
            {{ training.data.title }}
        </div>
        <div class="close-btn" @click="$emit('close')">
            <CloseCrossSvg />
        </div>
    </div> -->
   
    <div class="content">
        <span class="instruction">
                {{toLocal('assign_modal.instructions_new')}}:
            </span>
        <div class="upload-button-container">
                <div class="empty-space left-space">
                    
                </div>
                <div class="upload-btns">
                    <div class="upload-button" @click="selectingUsers=true">
                        <span>
                            {{toLocal('users.select_from_list')}}
                        </span>
                    </div>
                    <div class="upload-button" @click="!isUploading ? $refs.fileInput.click() : false">
                        <input
                            style="display: none"
                            type="file"
                            ref="fileInput"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            @change="importTrainingUsers"
                        />
                        <span>
                            <font-awesome-icon v-if="isUploading" icon="spinner" />
                            {{isUploading ? '' : toLocal('files.upload_list')}}
                        </span>
                    </div>
                </div>
                <div class="empty-space right-space">
                <div class="file-template" @click="downloadTemplate">
                    <span >
                        {{toLocal('generic.template')}}
                    </span>
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.677009 5.43629C1.05091 5.43629 1.35402 5.75208 1.35402 6.14163V10.9637C1.35402 11.8574 2.05192 12.5893 2.91433 12.5893H13.8857C14.7434 12.5893 15.446 11.8622 15.446 10.9637V6.22173C15.446 5.83218 15.7491 5.51639 16.123 5.51639C16.4969 5.51639 16.8 5.83218 16.8 6.22173V10.9637C16.8 12.6445 15.4882 14 13.8857 14H2.91433C1.30109 14 0 12.6333 0 10.9637V6.14163C0 5.75208 0.303107 5.43629 0.677009 5.43629Z" fill="#4D4D4D"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29114 0C8.66504 0 8.96815 0.315791 8.96815 0.705339V9.20407C8.96815 9.59362 8.66504 9.90941 8.29114 9.90941C7.91724 9.90941 7.61413 9.59362 7.61413 9.20407V0.705339C7.61413 0.315791 7.91724 0 8.29114 0Z" fill="#4D4D4D"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.144 6.48568C11.4084 6.76113 11.4084 7.20773 11.144 7.48318L9.16808 9.54178C8.68842 10.0415 7.90609 10.0415 7.42643 9.54178L5.53508 7.57129C5.2707 7.29584 5.2707 6.84925 5.53508 6.57379C5.79947 6.29834 6.22813 6.29834 6.49252 6.57379L8.29725 8.45405L10.1866 6.48568C10.4509 6.21023 10.8796 6.21023 11.144 6.48568Z" fill="#4D4D4D"/>
                    </svg> 
                </div>
                </div>
            </div>
        <div class="row options">
            <span>
                {{toLocal('assign_modal.link_duration')}}
            </span>
            <div class="infinite-button" :class="{active: category.value == 2}" @click="category = {title: toLocal('training.unrestricted'), value: 2}">Neribota</div>
            <span>
                {{toLocal('training_edit.test_questions_or')}}
            </span>
            <InputWithSearch 
                v-model="category"
                :data="categories"
                icon="arrow"
                class="invitations-dropdown mx-12"
                :is-dropdown="true"
                :searchable="false"
                value-key="object"
                :has-clear="false"
                :placeholder="toLocal('general.select_category')"
                @clear="category = null"
            />
            <span v-if="[0,1].includes(category.value)">
                {{toLocal('general.and')}}
            </span>
            <input v-if="[0,1,4].includes(category.value)" type="text" v-model="selectedTimeQuantity" @input="filterNonNumeric"/>
             <InputWithSearch 
                v-if="[0,1,4].includes(category.value)"
                v-model="timeType"
                :data="timeTypes"
                icon="arrow"
                class="invitations-dropdown small mx-12"
                :is-dropdown="true"
                :searchable="false"
                value-key="object"
                :has-clear="false"
                :placeholder="toLocal('general.select_category')"
                @clear="timeType = null"
            />
            <date-picker
                v-if="category.value==3"
                required
                v-model="date"
                label="date"
                :disabledDate="disabledDates"
                :placeholder="toLocal('users_side.select_date')"
                class="invitation-date-picker"
                type="datetime"
                valueType="format"
            >
            </date-picker>
            <div class="user-count">
                <span> {{toLocal('general.loaded_users')}} {{tableData.length}}</span>
            </div>
        </div>
        <div class="content-upload">
            <div class="table-container">
                <div class="table">
                    <div class="header">
                        <span v-for="(item, index) in tableHeaders" :key="index">
                        {{ item.text }}
                        </span>
                    </div>
                    <div class="body" v-if="tableData.length >0">
                        <div class="row" v-for="(item, index) in tableData" :key="'row-'+index">
                            <div class="cell">
                                <input type="text" v-if="item.editable" v-model.lazy="item.name">
                                <span v-else>{{ item.name }}</span>
                            </div>
                            <div class="cell">
                                <input type="text" v-if="item.editable" v-model.lazy="item.last_name">
                                <span v-else>{{ item.last_name }}</span>
                            </div>
                            <div class="cell" 
                            :class="{invalid: !isValidEmail(item.email), 'error-animated': errorAnimation && !isValidEmail(item.email)}" 
                            v-tooltip="{content: 'test'}"
                            >
                                <input type="text" v-if="item.editable && isValidEmail(item.email)" v-model.lazy="item.email">
                                <VTooltip v-else-if="item.editable && !isValidEmail(item.email)" theme="users"  :triggers="['hover']" :placement="'top'">
                                    <input class="tooltip-cell" type="text" v-if="item.editable" v-model.lazy="item.email">

                                    <template #popper>
                                        El. paštas yra netinkamai suformuotas
                                    </template>
                                </VTooltip>
                                <span v-else>{{item.email}}</span>
                            </div>
                            <div class="remove" @click="removeTableRow(item)">
                                <CloseCrossSvg />
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell" v-for="(item, k_i) in newItem" :key="'temp-row-'+k_i">
                                <input :placeholder="item.placeholder" type="text" v-model.lazy="item.value">
                            </div>
                        </div>
                        <div v-if="tableData.length < 7">
                            <div class="row" v-for="index in (7-tableData.length)" :key="'empty-row-'+index">
                                <div class="cell" v-for="i in 3" :key="`${index+'-'+i}`">
                                </div>
                            </div>
                        </div>
                    </div>
                      <div class="body" v-else>
                        <div class="row">
                            <div class="cell" v-for="(item, k_i) in newItem" :key="k_i">
                                <input :placeholder="item.placeholder" type="text" v-model.lazy="item.value">
                            </div>
                        </div>
                        <div class="row" v-for="index in 7" :key="index">
                            <div class="cell" v-for="i in 3" :key="`${index+'-'+i}`">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="send-invitations-button" :class="{active:tableData.length > 0}" @click="sendInvitations">
            <span>
               {{ sendingInvitations ? '' : toLocal('training.send_invitation_btn_label')}}
                <font-awesome-icon v-if="sendingInvitations" icon="spinner" />
            </span>
        
            <svg v-if="!sendingInvitations"  width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1086 10.1347C20.1066 10.4027 19.9513 10.6459 19.709 10.7604L1.88738 19.1821C1.62875 19.3043 1.32171 19.2562 1.11277 19.0609C0.903836 18.8655 0.83534 18.5623 0.93997 18.2961L4.1928 10.0189L1.06072 1.69535C0.959976 1.42761 1.03287 1.12551 1.24463 0.933184C1.45639 0.740858 1.76409 0.697284 2.02092 0.823252L19.7181 9.50325C19.9588 9.62128 20.1105 9.86671 20.1086 10.1347ZM5.41435 10.7255L2.88228 17.1686L16.3487 10.8051L5.41435 10.7255ZM16.3588 9.40975L2.98643 2.85095L5.4245 9.33021L16.3588 9.40975Z" fill="#333333"/>
            </svg>
        </div>
    </div>
    <portal to="training-invitation-status" >
        <GlobalModal
            v-if="showInvitationStatusModal"
            :modalInfo="invitationStatusModal"
            @onClose="onInvitationSendConfirmButtonClick"
        />
    </portal>
    <div class="user-select-modal" v-if="selectingUsers">
        <div class="close-btn select-close-btn" @click="selectingUsers=false">
            <CloseCrossSvg />
        </div>
        <UserTrainingList
            :key="groupsCopy.length"
            class="user-training-list"
            :groupHeader="groupHeader"
            :loadingGroups="loadingGroups"
            :userHeader="userHeader"
            :cloneGroups="groupsCopy"
            :cloneUsers="usersCopy"
            :usersData="usersCopy"
            :groupsData="groupsCopy"
            :addedUsers="tableDataTemp"
            :selectedItems="tableDataTemp"
            :item="training"
            type="invitations"
            @addGroupToIndex="addGroupToIndex"
            @addUserToList="addUserToList"
        />
        <div class="button-container">
            <div class="button" @click="insertExistingUsers">
                {{toLocal('users.include_to_list')}}
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import InputWithSearch from "@/components/layout/InputWithSearch";
import CloseCrossSvg from "@/assets/images/icons/close-cross.svg?inline";
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import UserTrainingList from "@/components/layout/users/UserTrainingList.vue";

import axios from 'axios';
export default {
    components:{
        InputWithSearch,
        CloseCrossSvg,
        GlobalModal,
        UserTrainingList,
        DatePicker
    },
    props:{
        training_id: {
            type: Number,
            required: true
        },
        training:{
            type: Object,
            required: true
        },
        users:{
            type: Array,
        },
        groups:{
            type: Array,
        },
        selectedUsers:{
            type: Array,
        }
    },
    data(){
        return{
            category: {title: this.toLocal('training.unrestricted'), value: 2},
            categories: [
                {title: this.toLocal('training.till_training_seen'), value: 0},
                {title: this.toLocal('training.till_training_done'), value: 1},
                // {title: this.toLocal('training.unrestricted'), value: 2},
                {title: this.toLocal('training.till_date'), value: 3},
                {title: this.toLocal('training.this_time_period'), value: 4},
            ],
            timeType:  {title: 'd.', value: 1},
            timeTypes: [
                {title: this.toLocal('generic.days_short'), value: 1},
                {title: this.toLocal('generic.weeks_short'), value: 7},
                {title: this.toLocal('generic.months_short'), value: 30},
                {title: this.toLocal('generic.years_short'), value: 365},
            ],
            tableHeaders:[
                { text: this.toLocal("generic.name"), value: "name" },
                { text: this.toLocal("generic.surname"), value: "last_name" },
                { text: this.toLocal("generic.email"), value: "email" },
            ],
            newItem:[
                { placeholder: 'Įrašykite', key: "name", value: '' },
                { placeholder: 'Įrašykite', key: "last_name", value: '' },
                { placeholder: 'Įrašykite', key: "email", value: '' },
            ],
            userHeader:[
                {'key':'name', 'label':this.toLocal('generic.name'), class:'name', sortable:true, sortOptions:{type:'text'}},
                {'key':'last_name', 'label':this.toLocal('generic.surname'), class:'last-name', sortable:true, sortOptions:{type:'text'}},
                {'key':'email', 'label':this.toLocal('generic.email'), class:'email', sortable:true, sortOptions:{type:'text'}},
                {'key':'added', 'label':this.toLocal('training.added_to_training'), class:'user-groups', sortable:true, sortOptions:{type:'relation'}},
            ],
            groupHeader:[
                {'key':'name', 'label':this.toLocal('generic.group_name'), class:'title-header', sortable:true, sortOptions:{type:'text'}},
                {'key':'added_user_count', 'label':this.toLocal('training.added_user_count_to_training'), class:'user-count-header', sortable:true, sortOptions:{type:'number'}},
                {'key':'users_count', 'label':this.toLocal('groups.user_count_in_group'), class:'user-count-header', sortable:true, sortOptions:{type:'number'}},
                {'key':'group_full', 'label':this.toLocal('training.added_to_training'), class:'actions-header', sortable:true, sortOptions:{type:'relation'}},
            ],
            date: '',
            tableData:[
                
            ],
            tableDataTemp:[
                
            ],
            selectingUsers:false,
            selectedTimeQuantity: 1,
            isUploading: false,
            invitationTimerInterval: null,
            showInvitationStatusModal:false,
            showInvitationSuccessModal: false,
            invitationStatusModal:{
                type: 'info',
                show_button: true,
                text: 'training.invitation_modal_success_text',
            },
            showInvitationSuccessModal:false,
            trainingInvitationModalTextLabel: 'Pakvietimas į mokymą jau siunčiamas!',
            invitationTime: 5,
            sendingInvitations: false,
            loadingGroups:false,
            groupsCopy: [],
            usersCopy: [],
            errorAnimation: false,
        }
    },
    watch:{
        newItem:{
            handler(){
                let hasContent = true;
                this.newItem.forEach(item=>{
                    if(item.value.length == 0){
                        hasContent = false;
                    }
                })
                if(hasContent){
                    let index = this.tableData.findIndex(item=>item.email == this.newItem[2].value);
                    if(index == -1){
                        this.tableData.push({name: this.newItem[0].value, last_name: this.newItem[1].value, email: this.newItem[2].value, editable:true});
                        this.newItem[0].value = ''; 
                        this.newItem[1].value = ''; 
                        this.newItem[2].value = ''; 
                    }
                }
            },
            deep:true
        },
        showInvitationSuccessModal(newVal){
            if(newVal){
                this.invitationStatusModal = {
                    type: 'info',
                    show_button: true,
                    invitation_modal: true,
                    text: 'training.invitation_modal_success_text',
                    confirmBtnText: 'Testi darbą... '+this.invitationTime+' s'
                };
                this.invitationTimerInterval = setInterval(() => {
                    this.invitationTime--;
                    this.invitationStatusModal.confirmBtnText = 'Testi darbą... '+this.invitationTime+' s';
                    if (this.invitationTime == 0) {
                        this.closeAfterInvitation();
                    }
                }, 1000);
            }
        },
        showInvitationStatusModal(newVal){
            if(newVal){
                this.invitationStatusModal = {
                    type: 'info',
                    show_button: true,
                    class: 'danger',
                    text: 'training.invitation_modal_sending_text',
                    confirmBtnText: 'Atšaukti siuntimą... '+this.invitationTime+' s'
                };
                this.invitationTimerInterval = setInterval(() => {
                    this.invitationTime--;
                    this.invitationStatusModal.confirmBtnText = 'Atšaukti siuntimą... '+this.invitationTime+' s';
                    if (this.invitationTime == 0) {
                        clearInterval(this.invitationTimerInterval);
                        this.sendInvitationsRequest();
                    }
                }, 1000);
            }
            else{
                clearInterval(this.invitationTimerInterval);
                this.showInvitationSuccessModal = false;
            }
        },
        invitationTime(newVal){
            if(newVal == 0){
                clearInterval(this.invitationTimerInterval);
                if(this.showInvitationStatusModal){
                    if(this.showInvitationSuccessModal){
                        this.sendingInvitations = false;
                        this.tableData = [];
                        this.showInvitationStatusModal = false;
                        this.$emit('close');
                    }
                    else{
                        this.sendInvitationsRequest();
                    }
                }
            }
        }
    },  
    mounted(){
        this.groupsCopy = this._.cloneDeep(this.groups);
        this.usersCopy = this._.cloneDeep(this.users);
        if(this.selectedUsers && this.selectedUsers.length > 0){
            this.tableData = this._.cloneDeep(this.selectedUsers);
            this.tableDataTemp = this._.cloneDeep(this.selectedUsers);
        }
    },
    methods:{
        downloadTemplate() {
            let file_name = "vartotojai_mokymas.xlsx";
            let template_name = this.toLocal('generic.users_lc')+".xlsx";
            axios
            .get(
                `${this.$store.state.api_link}/download/template/${file_name}`,
                this.$store.getters.axiosConfigDownload()
            )
            .then((response) => {
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(response.data);
                a.download = template_name;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            });
        },
        importTrainingUsers(e){
            if(this.isUploading) return;
            let file;
            if (e.size) {
                file = e;
            } else {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                file = files[0];
            }
            let regex = new RegExp("(.*?)\.(xlsx|csv|xls)$");
            if (file.name)
                if (!regex.test(file.name)) {
                this.$store.state.infoMessage.setMessage(
                    "error",
                    this.toLocal("import.failed_format")
                );
                return;
                }
            let formData = new FormData();
            formData.append("file", file);
            formData.append("training_id", this.training_id);
            this.isUploading = true;
            axios
                .post(`${this.$store.state.api_link}/read-training-invitation`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    this.uploadedData = progressEvent.loaded;
                    this.uploadProgress = parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    );
                },
                })
                .then((response) => {
                    this.showUserTable = true;
                    this.tableHeaders = [
                        { text: this.toLocal("generic.name"), value: "name" },
                        { text: this.toLocal("generic.surname"), value: "last_name" },
                        { text: this.toLocal("generic.email"), value: "email" },
                    ];
                    let user_list = response.data.users;
                    user_list.forEach(user => {
                        let index = this.tableData.findIndex((item) => item.email == user.email);
                        if(index == -1) this.tableData.push(user);
                    });
                    this.isUploading = false;
                    this.uploadedData = 0;
                    this.uploadProgress = 0;
                })
                .catch((err) => {
                    this.isUploading = false;
                    this.uploadedData = 0;
                    this.uploadProgress = 0;
                    this.$store.state.infoMessage.setMessage(
                        "error",
                        this.toLocal("import_modal.import_failed")
                    );
                console.log(err);
                });
        },
        sendInvitations(){
            if(this.sendingInvitations) return;
            let valid =true;
            if(this.tableData.length == 0) return;
            this.tableData.forEach(user => {
                if(!this.isValidEmail(user.email)) valid = false;
            });
            if(!valid){
                this.errorAnimation = true;
                setTimeout(()=>{
                    this.errorAnimation = false;
                },500)
                return
            } 
            this.sendingInvitations = true;
            this.invitationTime = 5;
            this.showInvitationStatusModal = true;            
        },
        sendInvitationsRequest(){
            this.invitationTime = 5;
            this.invitationStatusModal.loading = true;
            axios
                .post(
                `${this.$store.state.api_link}/training/users/invite`,
                { 
                    users: this.tableData, 
                    training_id: this.training_id,
                    category: this.category.value,
                    time_type: this.timeType.value,
                    time_quantity: this.selectedTimeQuantity,
                    date: this.date
                },
                this.$store.getters.axiosConfig()
                )
                .then((res) => {
                    this.showInvitationSuccessModal = true;
                    this.sendingInvitations = false;
                    this.invitationStatusModal.loading = false;
                    this.$emit('close');
                    let training_codes = res.data.training_codes
                    this.$emit('saveItem', this.tableData, this.date, training_codes)
                })
                .catch((error) => {
                    if (error.response.status == 504) {
                        this.$store.state.infoMessage.setMessage(
                        "error",
                        this.toLocal("import.error_too_long")
                        );
                    }
                    this.sendingInvitations = false;
                    this.invitationStatusModal.loading = false;
                    this.showInvitationStatusModal = false;
                  
                });
        },
        filterNonNumeric() {
            this.selectedTimeQuantity = this.selectedTimeQuantity.replace(/[^0-9]/g, "");
        },
        disabledDates(time){
            var date = moment(String(time)).format('YYYY-MM-DD');
            var d1 =moment().format('YYYY-MM-DD');
            if (date>=d1) {
                return false;
            }
            else return true;
        },
        closeAfterInvitation(){
            this.sendingInvitations = false;
            this.tableData = [];
            clearInterval(this.invitationTimerInterval);
            this.showInvitationStatusModal = false;
            this.$emit('close');
        },
        addGroupToIndex(group, state){
            let users = this.users;
            let group_users = [];
            let mapped_emails = this.tableDataTemp.map((item) => item.email);
            if(group.id == 0){
                group_users = users;
                group_users.forEach(user => {
                    let email_exist = mapped_emails.includes(user.email);
                    if(state){
                        if(email_exist) return;
                        this.tableDataTemp.push(user);
                    }
                    else{
                        if(email_exist){
                            let index = this.tableDataTemp.findIndex((item) => item.email == user.email);
                            if(index != -1) this.tableDataTemp.splice(index, 1);
                        }
                    }
                });
            }
            else{
                group_users = group.users;
                group_users.forEach(id => {
                    let index = users.findIndex((item) => item.id == id);
                    if(index != -1){
                        let user = users[index];
                        let email_exist = mapped_emails.includes(user.email);
                        if(state){
                            if(email_exist) return;
                            this.tableDataTemp.push(user);
                        }
                        else{
                            if(email_exist){
                                let index = this.tableDataTemp.findIndex((item) => item.email == user.email);
                                if(index != -1) this.tableDataTemp.splice(index, 1);
                            }
                        }
                    }
                });
            }
        },
        addUserToList(group, user, state){
            let mapped_emails = this.tableDataTemp.map((item) => item.email);
            let email_exist = mapped_emails.includes(user.email);
            if(state){
                if(email_exist) return;
                this.tableDataTemp.push(user);
            }
            else{
                if(email_exist){
                    let index = this.tableDataTemp.findIndex((item) => item.email == user.email);
                    if(index != -1) this.tableDataTemp.splice(index, 1);
                }
            }
        },
        insertExistingUsers(){
            let user_list = this._.cloneDeep(this.tableDataTemp);
            user_list.forEach(user => {
                let index = this.tableData.findIndex((item) => item.email == user.email);
                if(index == -1) this.tableData.push(user);
            });
            this.selectingUsers = false;
        },
        toggleUser(user){
            console.log(user)
        },
        isValidEmail(email){
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        },
        onInvitationSendConfirmButtonClick(){
           
            this.showInvitationStatusModal = false
            this.showInvitationSuccessModal = false
            this.sendingInvitations = false;
            clearInterval(this.invitationTimerInterval);
            if(this.invitationStatusModal.invitation_modal){
                this.closeAfterInvitation();
            }
        },
        removeTableRow(user){
            let index = this.tableData.findIndex(el => el.email == user.email && el.name == user.name && el.last_name == user.last_name);
            if(index !== -1){
                this.tableData.splice(index, 1);
            }
            index = this.tableDataTemp.findIndex(el => el.email == user.email && el.name == user.name && el.last_name == user.last_name);
            if(index !== -1){
                this.tableDataTemp.splice(index, 1);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modal{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: white;
    border-radius: 16px;
    transform-origin: bottom left;
    padding: 0;
    padding-bottom: 32px;
    overflow: hidden;
    z-index: 3;
}
.training-title{
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    font-family: var(--main-site-font);
    color: #7D7D7D;
}
.row{
    display: flex;
    align-items: center;
    position: relative;
    
    span{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
    }
}
.remove{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    cursor: pointer;
}
.seperator{
    opacity: 0.5;
    background: #7D7D7D;
    height: 1px;
    width: calc(100% - 80px);
    margin: 24px auto;
}
.header{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    padding: 12px 44px 10px 32px;
    span{
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }
}
.close-btn{
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
}
.mx-12{
    margin: 0 12px 0 13px;
}
input{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    max-width: 56px;
    border: none;
    text-align: center;
    border-bottom: 2px solid #7D7D7D;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.16);
    &:focus{
        outline: none;
    }
}
.instruction{
    font-family: var(--main-site-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    color: #333333;
    margin: 32px 0 16px 0;
}
.tooltip-cell{
    display: flex;
    width: 100%;
}
.upload-btns{
    display: flex;
    gap: 20px;
}
.upload-button{
    display: flex;
    justify-content: center;
    border-radius: 30px;
    background: var(--LK-tamsi-pilka, #4D4D4D);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    color: white;
    padding: 4px 20px; 
    cursor: pointer;
    max-width: 220px;
    width: 220px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: 0.25s;
    &:hover{
        border-radius: 30px;
        background: var(--LK-geltona, #F3DE18);
        color: #333333;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16); 
    }
}
.upload-button-container{
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    .empty-space{
        flex-shrink: 1;
        height: 100%;
        width: 100%;
    }
    .right-space{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.content, .header{
    transition: all 0.3s;
}
.options{
    padding: 25px 32px 20px 32px;
    gap: 6px;
    font-size: 14px;
    span:first-child{
        font-weight: 600;
        font-size: 14px;
    }
    span{
        font-size: 14px;
    }
}
.infinite-button{
    display: inline-flex;
    padding: 4px 20px;
    justify-content: center;
    align-items: center; 
    border-radius: 20px;
    background: #4D4D4D;
    color: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16); 
    transition: 0.25s;
    &:hover{
        cursor: pointer;
        background: #F3DE18;
        color: #333; 
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16); 
    }
    &.active{
        cursor: default;
        background: #F3DE18;
        color: #333; 
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16); 
    }
}
.file-template{
    display: flex;
    justify-content: center;
    margin-left: 16px;
    border-radius: 4px;
    background: #E2E2E2; 
    justify-content: center;
    align-items: center;
    padding: 4px 8px; 
    gap: 8px;
    transition: 0.25s;
    cursor: pointer;
    &:hover{
        background: #F3DE18; 
    }
    span{
        font-family: var(--main-site-font);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #333333;
        cursor: pointer;
    }
}
.send-invitations-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    background: rgba(243, 222, 24, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    max-width: 280px;
    width: 280px;
    margin: 24px auto 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-height: 40px;
    color: #333333;
    cursor: not-allowed;
    svg{
        margin-left: 8px;
    }
    &.active{
        background: rgb(243, 222, 24);
        cursor: pointer;
    }
}
.user-count{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-left: 12px;
}
.table-container{
    display: flex;
    flex-direction: column;
    background: #F4F4F4;
    border-radius: 16px;
    margin: 0 16px;
    height: 100%;
    overflow: auto;
}
.content{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 5px;
    height: 100%;
}
.info{
    padding: 7px 24px;
    font-family: var(--main-site-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
}
.content-upload{
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    flex: 1;
    overflow: auto;
}
.table{
    display: flex;
    flex-direction: column;
    .header{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        background: #E2E2E2;
        padding: 7px 16px;
        margin:0;
        span{
            font-family: var(--main-site-font);
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            &:first-child{
                padding-left: 8px;
            }
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        .row{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            border-bottom: 1px solid #7d7d7d70;
            margin: 0 16px;
            &:last-child{
                border-bottom: none;
            }
            &:hover{
                .cell{
                        font-weight: 600;
                }
             
            }
        }
        .cell{
            font-family: var(--main-site-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            min-height: 36px;
            padding: 7px 0;
            &:first-child{
                padding-left: 8px;
            }
            &.invalid{
                position: relative;
                &::after{
                    content: '';
                    height: 2px;
                    width: 100%;
                    background: #ff5c5c;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
        input{
            display: flex;
            width: 100%;
            background: none;
            padding: 0;
            border: none;
            box-shadow: none;
            text-align: left;
            max-width: 100%;
            margin: 0;
            &::placeholder{
                color: rgba(0, 0, 0, 0.4);
                opacity: 1;
            }
        }
    }
}
.select-close-btn{
    z-index: 3;
}
.user-select-modal{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    z-index: 3;
}
.user-training-list{
    display: flex;
    overflow: auto;
    height: 100%;
}
.button-container{
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    .button{
        display: flex;
        align-items: center;
        padding: 4px 20px;
        justify-content: center;
        width: 240px;
        background: #F3DE18;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 30px;
        cursor: pointer;
    }
}
.error-animated{
    animation: error 0.5s infinite;
}
@keyframes error {
    0%{
        transform: rotate(0deg) translateX(0);
    }
    12%{
        transform: rotate(1deg) translateX(-5px);
    }
    25%{
        transform: rotate(0deg) translateX(0px);
    }
    37%{
        transform: rotate(-1deg) translateX(5px);
    }
    50%{
        transform: rotate(0deg) translateX(0px);
    }
    63%{
        transform: rotate(-1deg) translateX(-5px);
    }
    76%{
        transform: rotate(0deg) translateX(0px);
    }
    89%{
        transform: rotate(-1deg) translateX(5px);
    }
    100%{
        transform: rotate(0deg) translateX(0px);
    }
}
</style>
<style lang="scss">

.invitations-dropdown{
    max-width: 235px;
    &.input-container{
        &.dropdown-active{
            border-radius: 44px;
            outline: none;
        }
        &.small{
            max-width: 84px;
        }
        .dropdown-list {
            outline: none;
            background: #F4F4F4;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
            border-radius: 0px 0px 8px 8px;
            .list-item{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .icon{
            svg{
                width: 10.5px;
            }
        }
    }
    input{
        padding: 4px 13.5px 5px 12px;
        font-size: 14px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        border: 1px solid #CACACA;
        background: #FFF; 
        &:focus{
            outline: none;
        }
    }
}
.invitation-date-picker{
    height: 28px;
    .mx-input-wrapper{
        height: 100%;
        input{
            height: 100%;
            font-size: 14px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            border: 1px solid #7D7D7D;
            background: #FFF; 
        }
        .mx-icon-calendar{
            display: block;
        }
    }
}
.table{
    .body{
        .row{
            &:hover{
                .remove{
                    svg{
                        path{
                            stroke: #1c1c1c;
                        }
                    }
                }
            }
        }
    }
}
</style>