<template>
  <div class="row-container">
    <div 
        class="row"
        v-for="(row, index) in rows"
        :key="index"
    >
        <div class="label">
            {{row.label}}
        </div>
        <div class="button-row">
            <button 
                v-for="(btn, index) in row.buttons"
                :key="index"
                :class="[getContrast('secondary_color')]"
                @click="$emit('inviteUsers', btn.value)"
            >
                {{btn.label}}
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        invitation_types: Array
    },
    data(){
        return{
            rows: [
                {
                    label: this.toLocal('invitations.add_users_label'),
                    type: 'existing_users',
                    buttons: this.invitation_types
                },
               
            ]
          
        }
    }
}
</script>

<style lang="scss" scoped>
.row-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    border-bottom: 1px solid #e2e2e27c;
    font-family: var(--main-site-font);
    text-overflow: ellipsis;
    color:#333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0 40px;
}
button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 4px 16px;
    font-size: 14px;
    color: #FFF;
    background: #4D4D4D;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    border:none;
    user-select: none;
    transition: background 0.3s;
    &.blackContrast{
        &:hover{
            color: #333;
        }
    }
    &:hover{
        background: var(--secondary-color);
    }
}
.row{
    display: flex;
    align-items: center;
}
.label{
    min-width: 190px;
}
.button-row{
    display: flex;
    gap:12px;
    margin-left: 12px;
}
</style>