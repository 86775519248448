<template>
    <div class="tab-container">
        <div 
            class="tab"
            :class="{selected: selectedTab == type.type || (selectedTab==null && index == 0)}"
            v-for="(type, index) in types" 
            :key="index"
            @click="$emit('changeTab', type.type)"
        >
            {{toLocal(type.label)}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        types: {
            type: Array,
        },
        selectedTab: String
    },
    data() {
        return {
        }
    },
    mounted() {
        if(this.selectedTab == null) {
            this.$emit('changeTab', this.types[0].type)
        }
    }

}
</script>

<style lang="scss" scoped>
.tab-container{
    display: flex;
    align-items: center;
    gap: 4px;
    border-bottom: 3px solid #4D4D4D;
    margin: 25px 0 20px 0;
}
.tab{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    padding: 6px 10px;
    border-radius: 8px 8px 0 0;
    box-shadow:  0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    font-size: clamp(14px, 1.2vw, 16px);
    white-space: nowrap;
    cursor: pointer;
    &.selected{
        background-color: #4D4D4D;
        box-shadow: none;
        color: white
    }
}
</style>