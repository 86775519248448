<template>
  <div class="tree-select report" :class="{active: $store.state.emailForming.selectedItem == report.id}">
        <div class="icon doc">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.626" height="19.563" viewBox="0 0 14.626 19.563">
            <g id="Group_9998" data-name="Group 9998" transform="translate(0.5 0.5)">
                <path id="Path_8230" data-name="Path 8230" d="M192.007,9.246v14.6a.7.7,0,0,1-.7.7H179.077a.7.7,0,0,1-.7-.7V6.676a.7.7,0,0,1,.7-.7h9.666a.7.7,0,0,1,.493.2L191.8,8.753A.7.7,0,0,1,192.007,9.246Z" transform="translate(-178.381 -5.98)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <path id="Path_8231" data-name="Path 8231" d="M246.072,8.958V5.98l2.976,2.975Z" transform="translate(-235.423 -5.98)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <g id="Group_299" data-name="Group 299" transform="translate(3.079 5.628)">
                <line id="Line_38" data-name="Line 38" x2="7.57" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <line id="Line_39" data-name="Line 39" x2="7.57" transform="translate(0 3.655)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <line id="Line_40" data-name="Line 40" x2="7.57" transform="translate(0 7.309)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </g>
            </g>
            </svg>

        </div>
        <div class="label" @click="startTitleEdit($event, report)">
            <span v-if="!report.editingTitle || report.editingTitle ==undefined">
                  {{report.name}}
            </span>
            <input 
                v-else 
                type="text" 
                v-model="report.name"
                :placeholder="toLocal('generic.enter_name')"  
                @mouseover="$emit('dragDisable', true)"
                @mouseleave="$emit('dragDisable', false)"
                v-click-outside-2="clickAwayInput"
            >
        </div>
        <div class="icon" :class="{opened: optionsOpen}" @click="optionsOpen = true" v-click-outside="closeOptions">
            <svg class="select-icon" :style="{'z-index': optionsOpen ? '3' : ''}" xmlns="http://www.w3.org/2000/svg" width="25.667" height="6.706" viewBox="0 0 25.667 6.706">
            <g id="Group_9979" data-name="Group 9979" transform="translate(498.599 -710.5) rotate(90)">
                <circle id="Ellipse_572" data-name="Ellipse 572" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 498.599) rotate(-90)" fill="#28345c"/>
                <circle id="Ellipse_573" data-name="Ellipse 573" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 489.118) rotate(-90)" fill="#28345c"/>
                <circle id="Ellipse_574" data-name="Ellipse 574" cx="3.353" cy="3.353" r="3.353" transform="translate(710.5 479.638) rotate(-90)" fill="#28345c"/>
            </g>
            </svg>
                <div class="action-container" v-if="optionsOpen" >
                    <div class="header">

                    </div>
                    <div class="option" @click="$emit('duplicateItem', report)">
                        <div class="option-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.87" height="15.871" viewBox="0 0 15.87 15.871">
                            <g id="Group_11113" data-name="Group 11113" transform="translate(0.5 0.5)">
                                <rect id="Rectangle_187" data-name="Rectangle 187" width="9.914" height="9.914" rx="2.835" transform="translate(4.957 4.957)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                <rect id="Rectangle_188" data-name="Rectangle 188" width="9.914" height="9.914" rx="2.835" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                <line id="Line_44" data-name="Line 44" x1="2.273" y2="2.273" transform="translate(7.484 7.5)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.5"/>
                                <line id="Line_45" data-name="Line 45" x1="4.188" y2="4.188" transform="translate(5.349 5.365)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.5"/>
                                <line id="Line_46" data-name="Line 46" x1="2.273" y2="2.273" transform="translate(5.138 5.154)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.5"/>
                            </g>
                            </svg>
                        </div>
                        <span class="option-label">
                            {{toLocal('generic.create_copy')}}
                        </span>
                    </div>
                    <div class="option" @click="deleteItem">
                        <div class="option-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.694" height="14.872" viewBox="0 0 11.694 14.872">
                                <g id="Group_10004" data-name="Group 10004" transform="translate(0 0)">
                                    <g id="Group_291" data-name="Group 291" transform="translate(0 0)">
                                    <path id="Rectangle_184" data-name="Rectangle 184" d="M6927.982,1771.573h9.547v8.808a3.123,3.123,0,0,1-3.117,3.12H6931.1a3.124,3.124,0,0,1-3.121-3.12Zm8.846.7h-8.144v8.106a2.421,2.421,0,0,0,2.419,2.418h3.308a2.422,2.422,0,0,0,2.417-2.418Z" transform="translate(-6926.94 -1768.63)" fill="#28345c"/>
                                    <path id="Path_550" data-name="Path 550" d="M6933.777,1764.2l.084,0a1.224,1.224,0,0,1-.155,2.443h-9.176a1.222,1.222,0,0,1,0-2.444h9.178Zm0,1.746a.522.522,0,0,0,.036-1.042.463.463,0,0,0-.071,0h-9.211a.52.52,0,1,0,0,1.04h9.247Z" transform="translate(-6923.308 -1763.003)" fill="#28345c"/>
                                    <path id="Path_551" data-name="Path 551" d="M6942.827,1761.029h-4.29l-.088-.22a1.225,1.225,0,0,1,1.13-1.682h2.2a1.226,1.226,0,0,1,1.136,1.681Zm-3.763-.7h3.235a.522.522,0,0,0-.521-.5h-2.2A.522.522,0,0,0,6939.064,1760.327Z" transform="translate(-6934.868 -1759.126)" fill="#28345c"/>
                                    <g id="Group_290" data-name="Group 290" transform="translate(3.316 4.772)">
                                        <path id="Line_35" data-name="Line 35" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(0.5 0.5)" fill="#28345c"/>
                                        <path id="Line_36" data-name="Line 36" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(2.647 0.5)" fill="#28345c"/>
                                        <path id="Line_37" data-name="Line 37" d="M-.149,7.218A.351.351,0,0,1-.5,6.867V-.149A.351.351,0,0,1-.149-.5.351.351,0,0,1,.2-.149V6.867A.351.351,0,0,1-.149,7.218Z" transform="translate(4.795 0.5)" fill="#28345c"/>
                                    </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span class="option-label">
                            {{toLocal('generic.delete')}}
                        </span>
                    </div>
                </div>
        </div>
       
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
export default {
    props:{
        report:{
            type:Object,
            required:true
        }
    },
    data(){
        return{
            optionsOpen: false,
        }
    },
    watch:{
        report:{
            deep:true,
            handler(newVal,oldVal){
                // if(JSON.stringify(newVal) !== JSON.stringify(oldVal)){
                this.debounceSave(this);
                let folder_id = this.$store.state.emailForming.selectedFolder;
                let folder_index = this.$store.state.emailForming.emailFolders.findIndex(el => el.id == folder_id);
                let folder = this.$store.state.emailForming.emailFolders[folder_index];
                let report_index = folder.reportList.findIndex(el => el.id == this.report.id);
                this.$set(folder.reportList, report_index, newVal);
            }
        }
    },
    methods:{
        closeOptions() {
            this.optionsOpen = false;
        },
        deleteItem(){
   
            this.$emit('deleteItem');
        },
        saveItem(){
            let id = this.report.id;
            let item = this.unsetData(this.report);
            axios.put(
                `${this.$store.state.api_link}/email-templates/${id}`,
                {
                    item:item,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
                
            })
        },
        debounceSave: _.debounce((self)=>{
            self.saveItem();
        }, 1000),
        unsetData(item){
            let temp = this._.cloneDeep(item);
            if(temp.data){
                if(temp.data.reportData && temp.data.reportData.length> 0){
                    temp.data.reportData.forEach((item,index)=>{
  
                        if(item.secondarySelect){
                             item.secondarySelect=[];
                        }
                    });
                }
            }
            return temp;
        },
        setItemName(e, item){
            if(e.target.value != ''){
                item.name = e.target.value; 
                this.$set(item, 'editingTitle', false);
            }
            else return;
        },
         startTitleEdit(e, report){
            if(report.editingTitle) return;
            this.$set(report, 'editingTitle', true);
            let input = e.target.parentNode.children[1];
            setTimeout(()=>{
                if(input)
                    input.focus();
            },10);
        },
        clickAwayInput(){
            this.$set(this.report, 'editingTitle', false);
            let folder_id = this.$store.state.emailForming.selectedFolder;
            let folder_index = this.$store.state.emailForming.emailFolders.findIndex(el => el.id == folder_id);
            let item_index = this.$store.state.emailForming.emailFolders[folder_index].reportList.findIndex(el=>el.id == this.report.id);
            if(item_index!= -1){
                this.$set(this.$store.state.emailForming.emailFolders[folder_index].reportList[item_index], 'editingTitle', false);
                this.$set(this.$store.state.emailForming.emailFolders[folder_index].reportList[item_index], 'name', this.report.name); 
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.tree-select{
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 0.5rem;
    &.report{
        
        padding: 0.5rem;
        height: 2rem;
        width: 90%;
        margin: 0.25rem auto;
        &.active{
            .doc{
                svg{
                    path{
                        fill: var(--secondary-color)
                    }
                }
            }
        }
    }
    .label{
        width: 100%;
        margin-left: 0.5rem;
        color: var(--primary-color);
         input{
            width: 100%;
            height: 100%;
            background: none;
            border:none;
            font-size: clamp(16px, 1.2vw, 20px);
            border-bottom: 1px solid var(--primary-color-opacity-50);
            &:focus{
                outline: none;
            }
        }
        span{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 10px;
            min-width: 100%;
        }
        &.selected{
            font-weight: 700;
        }
    }
    .icon{
        display: flex;
        padding: 0 0.25rem;
        align-items: center;
        height: 1.5rem;
        width: 2rem;
        position: relative;
        cursor: pointer;
        svg{
            position: relative;
            path, circle{
                transition: fill 0.3s ease;
                fill: var(--primary-color);
            }
            rect, line{
                stroke: var(--primary-color);
            }
        }
        .select-icon{
            path, circle{
                fill: var(--primary-color-opacity-50);
            }
        }
        &:hover{
            .select-icon{
                path, circle{
                    fill: var(--primary-color);
                }
            }
        }
        &.doc{
            svg{
                path{
                    stroke: var(--primary-color);
                    fill: none;
                }
            }
        }
        
    }
}
.action-container{
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    border: 1px solid var(--primary-color);
    background: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 2;
    .header{
        height: 1rem;
        width: 100%;
    }
    .option{
        display: flex;
        align-items: center;
        color: var(--primary-color);
        margin: 0.25rem 0;
        .option-icon{
            display: flex;
            justify-content: center;
            width: 1rem;
            margin-right: 0.25rem;
            min-height: 16px;
            height: 1rem;
        }
        .option-label{
            width: 4.5rem;
            font-size: clamp(14px, 2vw, 16px);
        }
        &:hover{
            .option-label{
                font-weight: 700;
                
            }
        }
        svg{
            path{
                fill: var(--primary-color);
            }
        }
    }
}
.option-icon{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    svg{
        height: 100%;
        width: 0.75rem;
    }
}
.report-count{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -2.5rem;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 2rem;
    height: 70%;
    span{
        position: absolute;
        font-weight: 700;
        color: var(--primary-color);
    }
    svg{
        path{
            stroke: var(--primary-color);
        }
    }
}
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
  min-width: 2rem;
   min-height: 2rem;
}
.expand-enter-from,
.expand-leave-to {
  opacity: 0;
   min-width: 0;
    min-height: 0;
}


</style>