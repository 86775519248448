<template>
  
  <div class="container">
    <div class="user-payment-info">
        <div class="column w-full" v-if="$store.state.user_role == 'admin'">
            <div class="row parent-row">
                <div class="column flex-1 mb-33">
                    <h3 class="mb-12">{{toLocal('payments.payment_info_label')}}</h3>
                    <div class="data">
                        <div class="row">
                            <div class="column ">
                                <div class="row mb-10">{{toLocal('payments.company_name')}}</div>
                                <div class="row mb-10">{{toLocal('payments.company_num')}} </div>
                                <div class="row mb-10">{{toLocal('payments.vat_number')}}</div>
                                <div class="row mb-10">{{toLocal('payments.address')}}</div>
                            </div>
                            <div class="column ml-24 bold">
                                <div class="row mb-10">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.name : '-'}}</div>
                                <div class="row mb-10">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.company_num : '-'}}</div>
                                <div class="row mb-10">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.pvm_code : '-'}}</div>
                                <div class="row mb-10">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.address : '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column flex-1 pl-48 ml-48">
                    <div class="column">
                        <h3 class="mb-12">{{toLocal('payments.send_to')}}</h3>
                        <div class="data">
                            <div class="row ">
                                <div class="column">
                                    <div class="row mb-10">{{toLocal('payments.name_surname')}}</div>
                                    <div class="row mb-10">{{toLocal('payments.email')}}</div>
                                </div>
                                <div class="column ml-24 bold">
                                    <div class="row mb-10">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.name_surname : '-'}} </div>
                                    <div class="row mb-10">{{$store.state.system_settings.billingSettings ? $store.state.system_settings.billingSettings.email : '-'}} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-center mt-42">
                <button class="mb-0" @click="paymentInfoModal=true">
                    {{toLocal('payments.edit_info')}}
                </button>
            </div>
        </div>
        <div v-else class="user-settings">
            <h3 class="mb-12">{{toLocal('payments.payment_info_label')}}</h3>
            <div class="row">
                <div class="input-container" v-if="user_details.name != undefined">
                    <div class="input-label">Pirkėjas:</div>
                    <input type="text" :placeholder="toLocal('settings.current_username')" v-model="$v.user_details.name.$model">
                    <span class="error" v-if="!$v.user_details.name.required">{{toLocal('settings.name_last_name_required')}}</span>
                    <span class="error" v-else-if="!$v.user_details.name.minLength">{{toLocal('first_login.username_incorrect')}}</span>
                </div>
                <div class="input-container" v-if="user_details.email != undefined">
                    <div class="input-label">El. pašto adresas:</div>
                    <input type="text" :placeholder="toLocal('generic.email')" v-model="$v.user_details.email.$model">
                    <span class="error" v-if="!$v.user_details.email.required">{{toLocal('email.required')}}</span>
                    <span class="error" v-else-if="!$v.user_details.email.email">{{toLocal('email.email_format')}}</span>
                    <span class="error" v-else-if="!$v.user_details.email.minLength">{{toLocal('email.error.short')}}</span>
                </div>
            </div>
            <div class="row justify-center mt-42">
                <button @click="saveUserDetails">
                    <font-awesome-icon v-if="isSavingUserData" icon="spinner" />
                    Išsaugoti pakeitimus
                </button>
            </div>
    </div>
    </div>
    <div class="selected-trainings">
        <label class="main-label">Jūsų pasirinkti mokymai:</label>
        <div class="training">
            <div class="training-block" v-if="training">
                <div class="cover" 
                :style="[
                        training && training.cover != ''
                        ? {
                            'background-image': `url('${getFileUrl(
                                training.cover
                            )}')`,
                            }
                        : {},
                    ]"
                >

                </div>
                <div class="training-data">
                    <div class="training-title">
                        {{training.title}}
                    </div>
                    <div class="length">
                        Trukmė: {{ getTrainingTime(training.time) }}
                    </div>
                    <div class="price">
                        Kaina: {{precisionRoundMod(price, 2)}} eur/vnt.
                    </div>
                </div>
            </div>
            <div class="price-container">
                
                <div class="quantity-input" v-if="purchase_type == 'rent' && $store.state.user_role =='admin'">
                    <div class="modifier"  @click="quantity < 2 ? false : quantity--">
                        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1L1 1.00333" stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>

                    </div>
                    <input type="number" min="1" max="1000" v-model.number="quantity" @keypress="isNumber($event)"/>
                    <div class="modifier plus" @click="quantity==999 ? false : quantity++">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.99861 1L7.99907 15M15 8.00134L1 8.0032" stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                </div>
                <span > {{ purchase_type != 'rent' ? '1' : ''}} vnt. </span>
                <span class="multiplier" >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.999 1L1.00294 12.9968M13 12.9998L1 1.00293" stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                </span>
                <div class="training-price">
                    {{ precisionRoundMod(price, 2) }} €
                </div>
            </div>
        </div>
        <div class="row price-row">
            <div class="col">
                <div class="vat">
                    <label>PVM mokestis (21 %):</label>
                    <div class="vat-price">
                        {{precisionRoundMod(quantity*price * 0.21, 2)}} €
                    </div>
                </div>
                <div class="total-price">
                    <span> Iš viso mokėti: </span>
                    <span class="price"> {{precisionRoundMod((quantity*price)+ (quantity*price * 0.21),  2)}} € </span>
                </div>
            </div>

        </div>
    </div>
    <div class="payment-type-container">
        <label class="main-label"> Pasirinkite mokėjimo būdą </label>
        <div 
            v-for="(type, index) in paymentTypes"
            :key="index"
        >
            <div class="payment-type">
                <div class="payment-type-name">
                    {{type.name}}
                </div>
                <div class="payment-type-items">
                    <div 
                        v-for="(item, index) in type.items"
                        :key="index"
                        class="payment-type-item"
                        :class="{selected: selectedPayment == item.name}"
                        @click="selectedPayment=item.name"
                    >
                        <img :src="require(`@/assets/images/icons/payments/${item.icon}`)" />
                    </div>
                </div>
            </div>        
        </div>

    </div>
    <div class="button-container">
        <button @click="pay">
            <font-awesome-icon icon="spinner" v-if="loadingPayment" />
            Mokėti
        </button>
    </div>
    <PaymentInfoModal v-if="paymentInfoModal" @close="paymentInfoModal=false" @closeSaved="paymentInfoModal=false"/>
  </div>
</template>

<script>
import axios from 'axios';
import PaymentInfoModal from '@/components/layout/settings/PaymentInfoModal.vue'
import { required, requiredIf, minLength, maxLength, sameAs, email  } from 'vuelidate/lib/validators'

export default {
    components:{
        PaymentInfoModal
    },
    props:{
        training:{
            type: Object
        },
        purchase_type:{
            type: String
        }
    },
    mounted(){
        window.scroll(0,0)
    },
    computed:{
        price(){
            return this.purchase_type=='buy' ? this.training.price : this.training.price_rent
        }
    },
    data(){
        return{
            paymentTypes:[
                {type: 'banktransfer', name: 'Pavedimas banke', items: [{name:'banktransfer', icon: 'bank.png', url: ''}]},
                {type: 'banklink', name: 'Internetinė bankininkystė', items: [
                    {name: 'banklink_swedbank', icon: 'swedbank.png', url: ''},
                    {name: 'banklink_seb', icon: 'seb.png', url: ''},
                    {name: 'banklink_dnb', icon: 'luminor.png', url: ''},
                    {name: 'banklink_citadele', icon: 'citadele.png', url: ''},
                    {name: 'banklink_sb', icon: 'siauliu_bankas.png', url: ''},
                    {name: 'banklink_medbank', icon: 'medicinos_bankas.png', url: ''},
                ]},
                // {type: 'card', name: 'Mokėjimų kortelė', items: [
                //     {name:'card', icon: 'visa.png', url: ''}
                // ]},
            ],
            quantity: 1,
            selectedPayment: '',
            paymentInfoModal: false,
            user_details:{
                name: this.$store.state.user_name + ' ' + this.$store.state.user_last_name,
                email: this.$store.state.user_email,
            },
            isSavingUserData: false,
            loadingPayment:false,
        }
    },
    validations: {
        user_details: {
            name:{
                required: required,
                minLength: minLength(2),
                maxLength: maxLength(64),
            },
            email:{
                required: required,
                email: email,
                minLength: minLength(6),
                maxLength: maxLength(64),
            },
         
        },
    },
    methods:{
        isNumber(e){
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
            const keyPressed = e.key;
            
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },
        getTrainingTime(time){
            const hours = Math.floor(time/60);
            const minutes = time % 60;
            if(hours == 0){
                return `${minutes} min.`
            }
            else return `${hours} val. ${minutes} min.`
        },
        precisionRoundMod(number, precision) {
            var factor = Math.pow(10, precision);
            var n = precision < 0 ? parseFloat(number) : 0.01 / factor + parseFloat(number);
            return Math.round( n * factor) / factor;
        },
        pay(){
            if(this.$store.state.user_role == 'admin' && !this.$store.state.system_settings.billingSettings){
                this.paymentInfoModal = true;
                return;
            }
            this.loadingPayment=true;
            axios
            .post(
                `${this.$store.state.api_link}/payments/order`,
                {
                    data: {
                        training_id: this.training.id,
                        quantity: this.quantity,
                        payment_type: this.selectedPayment,
                        purchase_type: this.purchase_type
                    }
                },
                {
                    headers:{
                        'locale': this.$store.state.language,
                    }
                }
            )
            .then((response) => {
                this.loadingPayment=false;
                let slug = response.data.slug;
                let link = this.$store.state.ssl ? 'https://' : 'http://';
                // link += process.env.VUE_APP_PARENT_LMS_API+'/api/order/'+slug;
                link += this.$store.state.api_link+'/api/order/'+slug;
                 location.replace(link);

            })
            .catch((error) => {
                this.loadingPayment=false;
            });
        },
        saveUserDetails(){
            if(this.isSavingUserData) return false;
            this.isSavingUserData = true;
            axios.post(`${this.$store.state.api_link}/user/save-details`,
                {data: this.user_details},
                this.$store.getters.axiosConfig())
            .then((res) => {
                this.$store.state.user_name = res.data.user.name;
                this.$store.state.user_last_name = res.data.user.last_name;
                this.$store.state.user_email = res.data.user.email;
                this.isSavingUserData = false;
            })
            .catch((err) => {
                this.isSavingUserData = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: white;
    border-top: 6px solid #7D7D7D;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 40px;
    max-width: 1332px;
    margin-bottom: 100px;
}
.selected-trainings{
    display: flex;
    flex-direction: column;
    margin: 36px 0;
}
.main-label{
    display: flex;

    margin-bottom: 40px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}
.price-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.training{
    border-bottom: 1px solid #E2E2E2;
}
.training, .total-price, .vat{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
    color:#7D7D7D;
}
.multiplier{
    margin: 0 20px;
}
.vat{
    padding: 33px 24px;
    font-size: 18px;
}
.total-price{
    max-width: 410px;
    margin-left: auto;
    padding: 29px 24px;
    background: #F4F4F4;
    border-top: 1px solid #E2E2E2;
    color:var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    .price{
        font-size: 24px;
        line-height: 32px;
    }
}
.payment-type{
    margin-bottom: 48px;
}
.payment-type-name{
    margin-bottom: 20px;
    font-size: 18px;
   
}
.payment-type-items{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
    max-width: 710px;
}
.payment-type-item{
    display:flex;
    justify-content:center;
    align-items: center;
    max-height: 128px;
    max-width: 220px;
    min-height: 128px;
    min-width: 220px;
    width: 100%;
    height: 100%;
    border: 1px solid #7D7D7D;
    border-radius: 8px;
    cursor: pointer;
    &.selected{
        border: 3px solid var(--secondary-color);
    }
   
}
button{
    padding: 12px 28px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    background: var(--secondary-color);
    color: var(--primary-color);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    font-family: var(--main-site-font);
    border-radius: 30px;
    border: none;
    cursor: pointer;
    min-width: 300px;
    font-family: var(--main-site-font);

}
.cover{
    width: 120px;
    height: 120px;
    margin-right: 20px;
    border-radius: 8px;
    background-image: url("../../../assets/images/placeholders/pia21974.0.jpg");
    background-size: cover;
    background-position: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
}
.button-container{
    display: flex;
    justify-content: center;
}
.training-block{
    display: flex;
    align-items: center;

}
.training-data{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color:#7D7D7D;
    line-height: 24px;
    font-size: 16px;
}
.training-price{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    max-width: 66px;
    width: 66px;
    text-align: right;
    color:var(--primary-color);
}
.training-title{
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--primary-color);
    margin-bottom: 12px;
}
.length{
    margin-bottom: 4px;
}
.quantity-input{
    display: flex;
    align-items: center;
    background: #F4F4F4;
    border-radius: 25px;
    padding: 6px 36px;
    position: relative;
    max-height: 36px;
    margin-right: 12px;
    input{
        text-align: center;
        background: none;
        border: none;
        max-width: 68px;
        height: 100%;
        font-size: 18px;
        line-height: 24px;
        color: var(--primary-color);
        &:focus{
            outline: none;
            
        }
    }
    .modifier{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 36px;
        width: 36px;
        background: #FFFFFF;
        border: 1px solid #7D7D7D;
        border-radius: 50%;
        cursor: pointer;
        &.plus{
            right: 0;
            left: initial;
        }
        &:active{
            border-color: var(--primary-color);
            svg{
                path{
                    stroke: var(--primary-color);
                }
            }
        }
    }
}
.row{
    display: flex;

}
.col{
    display: flex;
    flex-direction: column;
}
.price-row{
    width: 100%;
    justify-content: flex-end;
    border-bottom: 1px solid #E2E2E2;
    .col{
        max-width: 376px;
        width: 100%;

    }
}
.column{
    display: flex;
    flex-direction: column;
}
.w-full{
    width: 100%;
}
.mt-42{
    margin-top: 42px;
}
.ml-24{
    margin-left: 24px;
}
.bold{
    font-weight: 600;
}
h3{
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin: 0 0 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.user-payment-info{
    display: flex;
    align-items: center;
    background: #F4F4F4;
    border-radius: 12px;
    padding: 24px;
    font-size: 16px;
    color: #000000;
    button{
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 44px;
        padding: 9px 53px;
    }
}
.flex-1{
    flex: 1;
}
.pl-48{
    padding-left: 48px;
    border-left: 1px solid #7d7d7d7e;
}
.ml-48{
    margin-left: 48px;
}
.mb-10{
    margin-bottom: 10px;
}
.justify-center{
    justify-content: center;
}
.user-settings{
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    width: 100%;
    h3{
        padding-left: 16px;
    }
    .input-container{
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        position: relative;
        color: #333333;

        .input-label{
            font-weight: 600;
            font-size: 16px;
            font-family: var(--main-site-font);
            margin-bottom: 8px;
            padding-left: 16px;
        }
        input{
            background: #FFFFFF;
            border-bottom: 1px solid #EFEFEF;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.16);
            border-radius: 8px;
            padding: 11px 16px;
            font-size: 16px;
            width: 320px;
            font-family: var(--main-site-font);
            border: none;
            margin-bottom: 0;
        }
        .error{
            padding-left: 15px;
            color: #ec6363;
        }
    }
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        line-height: 22px;
        font-family: var(--main-site-font);
        padding: 9px 57px;
        min-width: initial;
    }
}
@media (max-width: 768px){
    .training{
        flex-direction: column;
    }
    .training-block{
        flex-direction: column;
        .cover{
            width: 220px;
            height: 220px;
            margin: 0;
        }

    }
    .main-label{
        font-size: 22px;
        margin-bottom: 20px;
        justify-content: center;
    }
    .container{
        padding: 20px;
    }
    .training-data{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .price-container{
        flex-wrap: wrap;
        justify-content: center;

        .multiplier{
            text-align: center;
            margin: 0 15px;
        }
        .training-price{
            width: initial;
        }
    }
    .payment-type-items{
        grid-template-columns: 1fr;
    }
    .payment-type-item{
        min-height: 150px;
        min-width: 100%;
    }
    .payment-type-name{
        text-align: center;
    }
    .user-payment-info{
        flex-direction: column;
        padding: 20px;
        .w-full{
            .parent-row{
                flex-direction: column;
            }
        }
        .ml-48{
            margin-left: 0;
        }
        .pl-48{
            padding-left: 0;
            border-left: none;
        }
        .mt-42{
            margin-top: 20px;
        }
        button{
            margin-top: 20px;
        }
    }
}

</style>