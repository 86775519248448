var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"header"},[_c('GroupsTitle',{staticClass:"group-title",attrs:{"group":_vm.group}}),_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"upper-half"}),_c('div',{staticClass:"lower-half"},[_c('div',{staticClass:"search-container col-container"},[_c('span',{staticClass:"col-title"},[_vm._v(_vm._s(_vm.toLocal("groups.assigned_elements"))+": "+_vm._s(_vm.elements.length))]),_c('div',{staticClass:"search-box"},[(_vm.searchTerm == '')?_c('SearchIconSVG'):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"search-input",class:{ padded: _vm.searchTerm == '' },attrs:{"type":"text","placeholder":_vm.toLocal('generic.search')},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}})],1)]),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-one col-container"},[_c('span',{staticClass:"col-title"},[_vm._v(_vm._s(_vm.toLocal("groups.elements_in_folders"))+":")]),_c('CustomDropdown',{staticClass:"c-dropdown",attrs:{"key-label":"id","value-label":"name","list":_vm.folders,"compare-list":_vm.chosenFolders,"options":{
                closeOnClick: false,
                checkbox: true,
                customDDWidth: '100%',
                customMaxHeight: '300px',
                initial: 'Keli aplankai',
                selectAllChoice: _vm.toLocal('users.many_folders'),
                containerClass: 'groups-table-dropdown',
                searchBar: true,
                createNewAtEmpty: false,
                showCreateNewBtn:false
              }},on:{"elClicked":_vm.changeFolderFilter,"elAll":_vm.toggleAllFolders}})],1),_c('div',{staticClass:"filter-two col-container"},[_c('span',{staticClass:"col-title"},[_vm._v(_vm._s(_vm.toLocal("group_side.sort_by")))]),_c('CustomDropdown',{staticClass:"c-dropdown",attrs:{"key-label":"id","value-label":"name","list":_vm.sortFunctions,"compareChosen":_vm.chosenSort,"options":{
                closeOnClick: true,
                checkbox: false,
                customDDWidth: '115%',
                customMaxHeight: '300px',
                initial:
                  _vm.chosenSort != ''
                    ? _vm.chosenSort.name
                    : 'Pasirinkite rūšiavimą',
                containerClass: 'groups-table-dropdown',
                searchBar: true,
                showCreateNewBtn:false
              }},on:{"elClicked":_vm.changeSort}})],1)])])])],1),_c('div',{staticClass:"item-container"},_vm._l((_vm.elementsCopy),function(element,idx){return _c('div',{key:("element-" + idx),staticClass:"single-item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.getFileUrl(element.cover)
              ? _vm.getFileUrl(element.cover)
              : './img/pia21974.0.17df4049.jpg',"onerror":"this.src='./img/pia21974.0.17df4049.jpg'","alt":element.title}})]),_c('div',{staticClass:"text-side"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(element.title)+" ")]),_c('div',{staticClass:"item-bottom-half"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.toLocal("group_side.average_score")))]),_c('span',{staticClass:"result"},[_vm._v(_vm._s(element.average_result ? element.average_result + "%" : "-"))])]),_c('div',{staticClass:"row gapped"},[_c('span',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.toLocal("groups.element_opens"))+":")]),_c('div',{staticClass:"time-select"},[_c('span',{staticClass:"front-text"},[_vm._v(" "+_vm._s(_vm.toLocal("mail.after"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.elementInfoIDs[element.id].time),expression:"elementInfoIDs[element.id].time"}],class:{ grayed: element.accessible_after == 0 },attrs:{"placeholder":"0","type":"number"},domProps:{"value":(_vm.elementInfoIDs[element.id].time)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.elementInfoIDs[element.id], "time", $event.target.value)},function (e) { return _vm.changeElementInfo(e, element, _vm.elementInfoIDs[element.id]); }]}}),_c('CustomDropdown',{key:_vm.updateKey,staticClass:"white-c-dropdown",class:{ grayed: element.accessible_after == 0 },attrs:{"list":_vm.elementEnablingDD,"compareChosen":_vm.elementInfoIDs[element.id].typeObj,"options":{
                  closeOnClick: true,
                  checkbox: false,
                  customDDWidth: '150%',
                  customMaxHeight: '300px',
                  initial: _vm.elementInfoIDs[element.id].typeObj
                    ? _vm.elementInfoIDs[element.id].typeObj.short
                    : '',
                  containerClass: 'groups-table-dropdown',
                  searchBar: false,
                  attached: {
                    type: 'after',
                    element: element,
                    element_idx: idx,
                  },
                }},on:{"elClicked":_vm.changeElementType}}),_c('span',{staticClass:"front-text"},[_vm._v(" "+_vm._s(_vm.toLocal("training_edit.test_questions_or"))+" ")]),_c('div',{staticClass:"time-select-btn",class:{ active: element.accessible_after == 0 },on:{"click":function($event){return _vm.clearElementTime('after', element)}}},[_vm._v(" "+_vm._s(_vm.toLocal("groups.instant"))+" ")])],1)]),_c('div',{staticClass:"row gapped"},[_c('span',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.toLocal("group.element_repeats"))+":")]),_c('div',{staticClass:"time-select"},[_c('span',{staticClass:"front-text"},[_vm._v(" "+_vm._s(_vm.toLocal("mail.every"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.elementInfoIDs[element.id].repeatTime),expression:"elementInfoIDs[element.id].repeatTime"}],class:{ grayed: element.training_repeats_every == 0 },attrs:{"placeholder":"0","type":"number"},domProps:{"value":(_vm.elementInfoIDs[element.id].repeatTime)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.elementInfoIDs[element.id], "repeatTime", $event.target.value)},function (e) { return _vm.changeElementInfo(e, element, _vm.elementInfoIDs[element.id]); }]}}),_c('CustomDropdown',{key:_vm.updateKey,staticClass:"white-c-dropdown",class:{ grayed: element.training_repeats_every == 0 },attrs:{"list":_vm.elementRepeatingDD,"compareChosen":_vm.elementInfoIDs[element.id].repeatingTypeObj,"options":{
                  closeOnClick: true,
                  checkbox: false,
                  customDDWidth: '150%',
                  customMaxHeight: '300px',
                  initial: _vm.elementInfoIDs[element.id].repeatingTypeObj
                    ? _vm.elementInfoIDs[element.id].repeatingTypeObj.short
                    : '',
                  containerClass: 'groups-table-dropdown',
                  searchBar: false,
                  attached: {
                    type: 'repeat',
                    element: element,
                    element_idx: idx,
                  },
                }},on:{"elClicked":_vm.changeElementType}}),_c('span',{staticClass:"front-text"},[_vm._v(" "+_vm._s(_vm.toLocal("training_edit.test_questions_or"))+" ")]),_c('div',{staticClass:"time-select-btn",class:{ active: element.training_repeats_every == 0 },on:{"click":function($event){return _vm.clearElementTime('repeat', element)}}},[_vm._v(" "+_vm._s(_vm.toLocal("group.doesnt_repeat"))+" ")])],1)])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }