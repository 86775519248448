<template>
  <div class="custom-checkbox">
    <input type="checkbox" :id="id" :checked="value" @change="handleChange" />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.checked);
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-checkbox{
    display: flex;
    align-items: center;
}
label{
    color: #333;
    font-family: var(--primary-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}
.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox label {
  cursor: pointer;
  padding-left: 25px;
  position: relative;
}

.custom-checkbox label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #4D4D4D;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 2px;
}

.custom-checkbox input:checked + label:before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 2px;

}

.custom-checkbox label:after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 7px;
  top: 0px;
  bottom: 2px;
  margin: auto;
  visibility: hidden;
}

.custom-checkbox input:checked + label:after {
  visibility: visible;
}
</style>