<template>
  <div 
    class="test-combo-container" 
    :class="{'test-select-mode-selectable': $store.state.selectTestsModeActive, locked: testIsLocked}" 
  >
    <div class="button-container" v-if="!$store.state.selectTestsModeActive && $store.state.selectModeSelectedTests.length == 0">
        <div class="button" @click="setSelectMode">
            <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="42.438" height="35.822" viewBox="0 0 42.438 35.822">
                    <g id="Group_11608" data-name="Group 11608" transform="translate(-1301.081 -1029.53)">
                        <rect id="Rectangle_3371" data-name="Rectangle 3371" width="33.04" height="24.513" rx="3" transform="matrix(0.966, -0.259, 0.259, 0.966, 1302, 1039)" fill="#f4dc00" stroke="#28345c" stroke-width="1.5"/>
                        <rect id="Rectangle_3371-2" data-name="Rectangle 3371" width="33.04" height="24.513" rx="3" transform="translate(1308.05 1040.098) rotate(-4)" fill="#f4dc00" stroke="#28345c" stroke-width="1.5"/>
                        <g id="Group_11577" data-name="Group 11577" transform="matrix(0.999, -0.052, 0.052, 0.999, -132.406, 18.518)">
                        <path id="Path_8379" data-name="Path 8379" d="M-14231.588-18859.51v12.088" transform="translate(15633.335 19961)" fill="#fff" stroke="#28345c" stroke-linecap="round" stroke-width="1.5"/>
                        <path id="Path_8380" data-name="Path 8380" d="M0,0V12.088" transform="translate(1407.792 1107.534) rotate(90)" fill="#fff" stroke="#28345c" stroke-linecap="round" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="text">
                {{toLocal('test.add_questions_from_tests')}}
            </div>
        </div>
    </div>
    <div 
    class="selected-tests"
    :class="{editing: $store.state.selectTestsModeActive}"
    v-if="$store.state.selectModeSelectedTests.length > 0 || $store.state.selectTestsModeActive"
    >
        <div class="header">
            <span class="label">
                {{$store.state.selectTestsModeActive ? toLocal('test_combo.header_label') : ''}}
            </span>
            <div class="save-btn"  @click="saveTestData($store.state.selectTestsModeActive)">
                <span>
                    {{$store.state.selectTestsModeActive ? toLocal('generic.save') : toLocal('test_combo.header_edit')}}
                </span>
                <span v-if="$store.state.selectTestsModeActive">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.645" height="18.83" viewBox="0 0 20.645 18.83">
                    <path id="Path_8390" data-name="Path 8390" d="M-3728.7-17223.531l7.494,8.254,10.359-16.451" transform="translate(3730.114 17233.107)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </svg>
                </span>
            </div>
        </div>
        <div class="tests">
            <div class="test" v-for="(test_item, test_index) in $store.state.selectModeSelectedTests" :key="test_index">
                <div 
                    class="test-name" 
                    v-tooltip="{content: showOverflowTooltip ? tooltipText : '', classes: 'tooltip-container',}"
                    @mouseover="checkIfTextOverflows($event, 'hovering')"
                    @mouseleave="checkIfTextOverflows($event, 'leaving')"
                >
                    {{getTestName(test_item)}}
                </div>
                <div class="test-actions">
                    <div 
                        class="count-input" 
                        v-if="$store.state.selectTestsModeActive" 
                        :class="{'error': test_item.count > test_item.question_count}"
                    >
                        <input 
                            type="text" 
                            inputmode="numeric"
                            :readOnly="!$store.state.selectTestsModeActive" 
                            v-model.number="test_item.count"
                            @input="updateTestCount($event, test_index)"
                             @keypress="isNumber($event)"
                            v-tooltip="{content: test_item.count > test_item.question_count ? toLocal('test_combo.questions_not_enough'): '', classes: 'tooltip-container',}"
                        >
                    </div>
                    <div class="count-text">
                        {{!$store.state.selectTestsModeActive ? test_item.count+' ' : ''}}{{  getTestCountLabel(test_item.count)}} iš {{getTestQuestionCount(test_item)}}
                    </div>
           
                    <div class="remove-test" v-if="$store.state.selectTestsModeActive" @click="$store.state.selectModeSelectedTests.splice(test_index, 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="Group_11357" data-name="Group 11357" transform="translate(0.028 0.028)">
                                <circle id="Ellipse_460" data-name="Ellipse 460" cx="12" cy="12" r="12" transform="translate(-0.028 -0.028)" fill="#efefef"/>
                                <g id="Group_10334" data-name="Group 10334" transform="translate(11.971 1) rotate(45)">
                                <path id="Path_8278" data-name="Path 8278" d="M0,0H15.517" transform="translate(0 7.757)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="1"/>
                                <path id="Path_8279" data-name="Path 8279" d="M0,0H15.515" transform="translate(7.759) rotate(90)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            showOverflowTooltip: false,
            tooltipText: '',
        }
    },
    props:{
        content: {
            type: Array,
            required: true,
        },
        topic: {
            type: Object,
            required: true
        },
        test:{
            type: Object,
            required: true
        },
        parentTest:{
            type: Object,
            required: true
        },
        topicIndex: {
            type: Number,
            required: true
        },
        availableTestQuestions: {
          type: Array,
            required: true
        },
        folders:{
            type: Array,
            required: true
        },
    },
    watch:{
        test(){
             this.updateTestCount()
            this.$store.state.selectModeSelectedTests = this.test.options.tests != undefined ? this.test.options.tests : [];
            this.$store.state.selectModeSelectedTests.forEach(test_item => {
                let folder = this.folders.find(el=>el.id == test_item.folder);
                if(folder !=null){
                    let test = folder.tests.find(el=>el.id == test_item.test_id);
                    if(test != null){
                        this.$set(test_item, 'test', test);
                    }
                }
            });
        }
    },
    computed:{
      testIsLocked(){
        return this.test.locked === 1 || (this.parentTest.options && this.parentTest.options.active_version_id == this.test.id && (this.parentTest.versions && this.parentTest.versions.length >0) && this.topic.questions == this.parentTest.id)
      }
    },
    mounted(){

        this.updateTestCount()
        this.$store.state.selectModeSelectedTests = this.test.options.tests != undefined ? this.test.options.tests : [];
        this.$store.state.selectModeSelectedTests.forEach(test_item => {
            let folder = this.folders.find(el=>el.id == test_item.folder);
            if(folder !=null){
                let test = folder.tests.find(el=>el.id == test_item.test_id);
                if(test != null){
                    this.$set(test_item, 'test', test);
                }
            }
        });
    },

    methods:{
        getTestName(test_item){
            let folder = this.folders.find(el=>el.id == test_item.folder);
            if(folder !=null){
                let test = folder.tests.find(el=>el.id == test_item.test_id);
                if(test != null){
                    return test.name;
                }
                else return '-';
            }
            else return '-';
        },
        getTestQuestionCount(test_item){
            if(test_item.question_count != undefined){
                return test_item.question_count;
            }
            else{
                let folder = this.folders.find(el=>el.id == test_item.folder);
                if(folder !=null){
                    let test = folder.tests.find(el=>el.id == test_item.test_id);
                    if(test != null){
                        this.$set(test_item, 'question_count', test.question_count != undefined ? test.question_count : test.questions.length);
                        return test.question_count != undefined ? test.question_count : test.questions.length;
                    }
                    else return '-';
                }
                else return '-';
            }
        },
        getTestCountLabel(number){
            if(number == undefined) return this.toLocal('generic.questions_who');
            let number_text = number.toString();
            let last_digit = parseInt(number_text[number_text.length-1]);
            if(last_digit == 1 && (number < 10 || number > 20)){
                return this.toLocal('generic.question').toLowerCase();
            }
            else if(last_digit == 0){
                return this.toLocal('generic.questions_who');
            }
            else if(last_digit > 1 && last_digit < 10 && (number < 10 || number > 20)){
                return this.toLocal('generic.questions_multiple');
            }
            else return this.toLocal('generic.questions_who');
        },
        saveTestData(modeActive){
            if(this.testIsLocked) return;
            if(modeActive){
                this.$store.state.selectTestsModeActive = false;
                let tests = this.$store.state.selectModeSelectedTests.map(el=>{
                    return this._.omit(el,'test');
                })
                this.$set(this.test.options, 'tests', tests);
                let max_score = this.test.options.tests.map(el=> el.questions.map(elem=>elem.score).sort((a,b)=>b-a).slice(0, el.count).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
                this.$set(this.test.options, 'testMaxScore', max_score);
                 this.$store.state.selectModeSelectedTests.forEach(test_item => {
                    let folder = this.folders.find(el=>el.id == test_item.folder);
                    if(folder !=null){
                        let test = folder.tests.find(el=>el.id == test_item.test_id);
                        if(test != null){
                            this.$set(test_item, 'test', test);
                        }
                    }
                });
                this.updateTestCount()
            }
            else{
                this.setSelectMode()
            }
        },
        setSelectMode(){
            if(this.testIsLocked) return;
            this.$store.state.selectTestsModeActive = true;
            if(this.test.options.tests && this.test.options.tests.length > 0 && this.$store.state.selectModeSelectedTests.length == 0){
                this.$store.state.selectModeSelectedTests = this.test.options.tests;
            }
            
        },
        updateTestCount(e, test_index = null){
            if(!this.test.options.tests) return;
            if(this.test.options.tests.length == 0) return;
           
            let max_score = this.test.options.tests.map(el=> el.questions ? el.questions.map(elem=>elem.score).sort((a,b)=>b-a).slice(0, parseInt(el.count)).reduce((a,b)=>a+b,0) : 0).reduce((a,b)=>a+b,0)
            // this.$set(this.test.options, 'testMaxScore', max_score);
            this.$store.state.testScores.minScore = this.test.options.tests.map(el=> el.questions.map(elem=>elem.score).sort((a,b)=>a-b).slice(0, parseInt(el.count)).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0);
            this.$store.state.testScores.maxScore = max_score;
            let score_list = this.test.options.tests.map(el=> el.questions.map(elem=>elem.score).sort((a,b)=>b-a).slice(0, parseInt(el.count)).reduce((a,b)=>a+b,0));
             let subset_min = [];
            let min_sum = 0;
            let finished = false;
            let it = 0;
            let minPossibleSumArray = []
            while(!finished){
                let min_value_index = score_list.indexOf(Math.min(...score_list));
                if(min_value_index != -1){
                    subset_min.push(score_list[min_value_index]);
                     min_sum += score_list[min_value_index];
                    score_list.splice(min_value_index, 1);
                }
                if(subset_min.length == this.$store.state.testScores.minScore) finished = true;
                if(it > 50 ) finished = true;
                it++;
            }
            let combinationArray = subset_min;
            minPossibleSumArray = score_list;
            let minPossibleSum = Math.round(min_sum * 1000) / 1000;
            let possibleCombinations = this.calcPossibleCombinations(combinationArray, minPossibleSumArray, minPossibleSum, this.test.options.testMaxScore)
        },
        calcPossibleCombinations(comb_array, min_possible_sums, minScore, maxScore){
            let possibleCombinations = 1;
            min_possible_sums.forEach(element => {
                comb_array.forEach((el)=>{
                    if(minScore - el + element <= parseFloat(maxScore)){
                       possibleCombinations++;
                    }
                })
            });
            return possibleCombinations;
        },
        checkIfTextOverflows(e, state){
            if(state == 'hovering'){
                if(e.target.offsetWidth < e.target.scrollWidth){
                    this.showOverflowTooltip = true;
                    this.tooltipText = e.target.innerText;
                }
                else{
                    this.showOverflowTooltip = false;
                }
            }
            else{
                this.showOverflowTooltip = false;
            }

        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
.test-combo-container{
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    color: var(--primary-color);
    &.locked{
        .selected-tests{
            .save-btn{
                border-color: var(--primary-color-opacity-50);
                color: var(--primary-color-opacity-50);
                &:hover{
                    font-weight: 400;
                }
                &:active{
                    background: initial;
                    color: var(--primary-color-opacity-50);
                    font-weight: 400;

                    span:nth-child(2) svg path{
                        stroke: initial;
                    }
                }
            }
        }
    }
}
.button-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button{
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--secondary-color);
        border-radius: 28px;
        padding: 0 2rem;
        color: var(--primary-color);
        cursor: pointer;
        .icon{
            display: flex;
        }
        svg{
            margin-right: 13px;
            rect, path{
                stroke: var(--primary-color);
            }
            rect{
                fill: var(--secondary-color);;
            }
        }
        .text{
            max-width: 145px;
            font-size: 16px;
        }
        &:hover{
            .text{
                font-weight: 500;
            }
        }
    }
}
.selected-tests{
    background: white;
    padding: 25px;
    border-radius: 20px;

    .save-btn{
        border-color: var(--primary-color);
        color: var(--primary-color);
        &:hover{
            font-weight: 700;
        }
        &:active{
            background: var(--primary-color);
            color: white;
            span:nth-child(2) svg path{
                stroke: white;
            }
        }

    }
    .tests{
        .test{
            
            border-color: var(--primary-color-opacity-50);
            color: var(--primary-color-opacity-50);
            .test-actions{
                width: 13rem;
                .count-input{
            
                    input{
                        border-color: var(--primary-color-opacity-50);
                        color: var(--primary-color-opacity-50);
                        font-size: 18px;
                    }
                  
                    &.error{
                        input{
                            border-color: red;
                            color: red;
                        }
                    }
                }
            }
        }
    }
    &.editing{
        .save-btn{
            background: var(--secondary-color);
            color: var(--primary-color);
            border: none;

        }
        .tests{
            .test{
                border-color: var(--primary-color);
                color: var(--primary-color);
                .test-actions{
                    
                    .count-text{

                        color: var(--primary-color-opacity-50);
                    }
                    .count-input{
                        input{
                            border-color: var(--primary-color);
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}
.tests{
    display: flex;
    flex-direction: column;
    .test{
        display: flex;
        align-items: center;
        padding: 5px 0 5px 8px;
        border-bottom: 1px solid var(--primary-color-opacity-50);
        .test-name{
            align-items: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            min-width: 10%;
            overflow: hidden;
            margin-right: 1rem;
        }
        .test-actions{
            display: flex;
            min-width: 205px;
            justify-content: center;
            .count-input{
                display: flex;
                align-items: center;
                height: 30px;
                width: 70px;
                border-radius: 28px;
                margin: 0 6px 0 0;
                input{
                    height: 100%;
                    width: 100%;
                    border-radius: 28px;
                    border: 1px solid var(--primary-color);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .count-text{
                display: flex;
                align-items: center;
                font-size: 16px;
                min-width: 100px;
                white-space: nowrap;
                // margin-right: 25px;
                color: var(--primary-color-opacity-50);
            }
            .remove-test{
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-left: 15px;
                svg{
                    circle{
                        transition: fill 0.3s;
                    }
                    path{
                        stroke: var(--primary-color);
                    }
                }
                &:hover{
                    svg{
                        circle{
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}
.header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .label{
        display: flex;
        align-items: center;
        font-weight: 500;
    }
}
.save-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 205px;
    background: var(--secondary-color);
    border-radius: 28px;
    padding: 4px 0;
    cursor: pointer;
    background: none;
    border: 1px solid var(--primary-color);
    span{
        display: flex;
        justify-content: center;
    }
    span:first-child{
        width: 100%;
    }
    span:nth-child(2){
        position: absolute;
        right: 10px;
        svg{
            path{
                stroke: var(--primary-color);
            }
        }
    }
  
}
.total-questions{
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    margin-right: 0.5rem;
}
</style>