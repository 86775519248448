<template>
    <div class="user-list" v-if="activeGroup == group.id" @click.stop>
        <div class="user-container" v-if="users && !isLoadingUsers">
          <div
              class="user-in-list"
              v-for="(user, userIndex) in users"
              :key="`user-${userIndex}`"
              :class="getUserClass(user)"
          >
              <div v-for="(header, index) in headers" :key="index" :class="header.class">
                  <div class="inner" v-if="header.key != 'groups'">
                      {{ user[header.key] }}
                  </div>
                  <GroupBubbleRow v-else :items="user.groups" :maxShownItems="4"/>
              </div>
                  <span class="add-btn" v-if="invitationType == 'single' || type =='invitations'">
                      <span
                        v-if="type=='training' ? user.editable : true"
                        class="icon"
                        :class="{'used-licence': training.rented && training.purchase_type == 2 && user.added && !addedUsersIndex.includes(user.id)}"
                        @click="toggleUser(user)"
                      >
                        <svg v-if="!isUserAdded(user)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">                            <path d="M10.0001 18.4853L10.0001 1.51747L10.0001 18.4853Z" fill="#F4F4F4"/>                            <path d="M1.51479 10L18.4826 9.99722L1.51479 10Z" fill="#F4F4F4"/>                            <path d="M10.0001 18.4853L10.0001 1.51747M1.51479 10L18.4826 9.99722" stroke="#333333" stroke-width="1.5" stroke-linecap="round"/>                        </svg>
                        <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">                            <path d="M13 13L1.00195 1.00194L13 13Z" fill="#F4F4F4"/>                            <path d="M1 13L12.9961 1L1 13Z" fill="#F4F4F4"/>                            <path d="M13 13L1.00195 1.00194M1 13L12.9961 1" stroke="#333333" stroke-width="1.5" stroke-linecap="round"/>                        </svg>
                      </span>
                  </span>
          </div>
        </div>
        <div class="loading-users" v-else>
            {{toLocal('general.loading_text')}}
        </div>
    </div>
</template>
<script>
import GroupBubbleRow from "@/components/layout/groups/GroupBubbleRow.vue";

export default {
  components:{
    GroupBubbleRow
  },
    props:{
        users:{
            type:Array,
            default:()=>[]
        },
        group:{
            type:Object,
            default:()=>({})
        },
        activeGroup:{
            type:Number,
            default:-1
        },
        isLoadingUsers:{
            type:Boolean,
            default:false
        },
        training:{
            type:Object,
            default:()=>({})
        },
        addedUsersIndex:{
            type:Array,
            default:()=>[]
        },
        type:{
            type:String,
            default:''
        },
        addedUsers:{
            type:Array,
            default:()=>[]
        },
        invitationType:{
            type:String,
        },
        selectedItems:{
            type:Array,
        }
    },
    data(){
        return{
            headers:[
                {key: 'name', class: 'name'},
                {key: 'last_name', class: 'last-name'},
                {key: 'email', class: 'email'},
                {key: 'groups', class: 'user-groups'},
            ]
        }
    },
    computed:{
      addedUserEmails(){
        return this.addedUsers.map(user=>user.email).filter(email=>email != '');
      }
    },
    methods:{
      getUserClass(user){
        let classes = [];
        if(this.isUserAdded(user)){
          classes.push('added');
        }
        if(this.invitationType != 'single' && this.type != 'invitations'){
           classes.push(`no-add-btn`);
        }
        if(this.training.rented){
          if(this.addedUsersIndex.includes(user.id) && user.added){
            classes.push(`gc-${this.group.color_id}-opacity-70`);
          }
          else if(user.added){
            classes.push(`gc-${this.group.color_id}-opacity-50`);
          }
        }
        else if(this.isUserAdded(user)){
          if(this.group.id == 0){
            classes.push(`gc-secondary-opacity-50`);
          }
          else{
            classes.push(`gc-${this.group.color_id}-opacity-50`);
          }
        }
        return classes;
      },
      toggleUser(user){
        if(user.added && this.training.rented && !this.addedUsersIndex.includes(user.id) && this.training.purchase_type == 2){
          this.$emit('revokeLicence',user);
        }
        else{
          this.$emit('addUserToList', this.group, user, !this.isUserAdded(user));
        }
      },
      isUserAdded(user){
        if(this.type=='invitations'){
          return this.addedUserEmails.includes(user.email);
        }
        else{
          if(this.selectedItems)
            return this.selectedItems.includes(user.id);
          else{
            return user.added;
          }
        }
      }
    }
}
</script>
<style lang="scss" scoped>
.user-list {
    position: relative;
    width: calc(100% - 40px);
    transform-origin: top;
    transition: 0.25s;
    background: #F4F4F4;
    box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 12px 12px;
    overflow-y: auto;
    padding: 0 12px 16px 12px;
    .user-container{
      }
    
    .user-in-list {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr 1.3fr 0.37fr;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: 30px;
      padding: 6px 8px;
      align-items: center;
      margin: 12px 0;
      min-height: 40px;
      &.gc-secondary-opacity-50{
        background-color: var(--secondary-color-opacity-50);
      }
      &.no-add-btn{
        grid-template-columns: 2fr 3fr 2fr 1.67fr;
        .user-groups{
          border-right: 0;
          width: 100%;
          max-width: 100%;
        }
      }
      @for $i from 0 through 14 {
        &.gc-#{$i}-opacity-50{
          background-color: hsla(
            ($i * 40 % 360),
            (20 + 2.5 * $i) % 70 * 1%,
            var(--theme-lightness), 50%
          );
        }
      }
      @for $i from 0 through 14 {
        &.gc-#{$i}-opacity-70{
          background-color: hsla(
            ($i * 40 % 360),
            (20 + 2.5 * $i) % 70 * 1%,
            var(--theme-lightness), 70%
          );
        }
      }
      &:first-child{
        margin-top: 0;
      }
      // &.active {
      //   background: rgba(115, 229, 229, 0.2);
      // }
      span{
        min-width: 0;

      }
      .inner, .user-groups{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        height: 100%;
        max-width: 250px;
        width: 250px;
        border-right: 1px solid rgba(125, 125, 125, 0.5);
        border-left: 1px solid rgba(125, 125, 125, 0.5);
      }
      .name{
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        height: 100%;
        max-width: 215px;
        width: 10vw;
      }
      .last-name {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        height: 100%;
        max-width: 300px;
        width: 12vw;
        border-left: 1px solid rgba(125, 125, 125, 0.5);
      }
      .user-groups{
        font-size: 16px;
        padding: 0 12px;
        max-width: 120px;
        width: 6vw;
        height: 100%;
        border-right: 1px solid rgba(125, 125, 125, 0.5);
      }
      .add-btn {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        max-width: 35px;
        width: 5vw;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          background: #f4f4f4;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.25s;
          &.used-licence{
            opacity: 0.5;
          }
          &:hover {
            background-color: #333333;
            color: #ffffff;
            svg{
              path{
                stroke: #ffffff;
              }
            }
          }
        }
      }
    }
  }
</style>