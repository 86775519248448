<template>
    <transition name="slide-in" mode="in-out" >
  <div v-scroll-lock="sideOpen && !openingAnimation" v-if="sideOpen" class="side--container">
    <div 
      :class="['side--background',  sideOpen ? 'side--open' : '']" 
      @click="$emit('closeSide')" 
    />
        <section :key="sideOpen" :class="{ 'group--side': true, 'header--disabled': !$store.state.header_enabled }">
        <div class="side--padding">
            <img 
            class="side--padding--corner" 
            src="@/assets/images/side-corner-narrow.svg"
            >
            <div class="side--padding--bottom" />
        </div>
        <div 
            class="side--close" 
            @click="$emit('closeSide')"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="36.521" height="36.521" viewBox="0 0 36.521 36.521">
            <g id="Group_1147" data-name="Group 1147" transform="translate(-995 -94.204)">
            <circle id="Ellipse_62" data-name="Ellipse 62" cx="18.26" cy="18.26" r="18.26" transform="translate(995 94.204)" fill="#fff"/>
            <g id="Group_656" data-name="Group 656" transform="translate(1012.934 96.686) rotate(45)">
                <g id="Path_749" data-name="Path 749" transform="translate(0 0)">
                <path id="Path_751" data-name="Path 751" d="M22.983,11.5a1.239,1.239,0,0,1-.366.879,1.212,1.212,0,0,1-.879.374H12.745v8.993a1.263,1.263,0,0,1-1.254,1.245,1.238,1.238,0,0,1-.879-.366,1.212,1.212,0,0,1-.374-.879V12.754H1.245a1.256,1.256,0,0,1-.888-2.133,1.306,1.306,0,0,1,.888-.374h8.993V1.262a1.222,1.222,0,0,1,.374-.9A1.238,1.238,0,0,1,11.491,0a1.267,1.267,0,0,1,1.254,1.262v8.984h8.993A1.269,1.269,0,0,1,22.983,11.5Z" transform="translate(0)" fill="#6d66a2"/>
                </g>
            </g>
            </g>
        </svg>

        </div>
        <div class="content-container">
            <div class="option-container">
                <div class="header">
                    <h2>{{toLocal('users.email_receivers')}}</h2>
                
                </div>
            </div>
            <div class="row total">
                <span>
                    {{toLocal('users.total_recipients')}} {{sideUsers.length}}
                </span>
            </div>
            <div class="row table" >
                <div class="grid-table">
                <div class="table-row header">
                    <div class="table-cell">
                        <span class="name-cell header">
                                {{toLocal('generic.name')}}
                                <img
                                    v-if="orderByElement == 'name' && orderByDirection == 'desc'"
                                    src="@/assets/images/icons/order-by-desc.svg"
                                    @click="setOrderBy('name')"
                                >
                                <img
                                    v-if="orderByElement == 'name' && orderByDirection == 'asc'"
                                    src="@/assets/images/icons/order-by-asc.svg"
                                    @click="setOrderBy('name')"
                                >
                                <img
                                    v-if="orderByElement != 'name'"
                                    src="@/assets/images/icons/order-by.svg"
                                    @click="setOrderBy('name')"
                                >
                            </span>
                    </div>
                    <div class="table-cell">
                        <span class="header">
                                {{toLocal('generic.surname')}}
                                <img
                                    v-if="orderByElement == 'last_name' && orderByDirection == 'desc'"
                                    src="@/assets/images/icons/order-by-desc.svg"
                                    @click="setOrderBy('last_name')"
                                >
                                <img
                                    v-if="orderByElement == 'last_name' && orderByDirection == 'asc'"
                                    src="@/assets/images/icons/order-by-asc.svg"
                                    @click="setOrderBy('last_name')"
                                >
                                <img
                                    v-if="orderByElement != 'last_name'"
                                    src="@/assets/images/icons/order-by.svg"
                                    @click="setOrderBy('last_name')"
                                >
                            </span>
                    </div>
                    <div class="table-cell">
                        <span class="header">
                                {{toLocal('generic.group')}}
                                <img
                                    v-if="orderByElement == 'groups' && orderByDirection == 'desc'"
                                    src="@/assets/images/icons/order-by-desc.svg"
                                    @click="setOrderBy('groups')"
                                >
                                <img
                                    v-if="orderByElement == 'groups' && orderByDirection == 'asc'"
                                    src="@/assets/images/icons/order-by-asc.svg"
                                    @click="setOrderBy('groups')"
                                >
                                <img
                                    v-if="orderByElement != 'groups'"
                                    src="@/assets/images/icons/order-by.svg"
                                    @click="setOrderBy('groups')"
                                >
                            </span>
                    </div>
                   
                </div>
                <div class="table-row parent" v-for="(user, user_id) in sideUsers" :key="user_id" >
                    <div v-if="$store.state.testUserStatsShowAll == 0" class="table-row">
                        <div class="table-cell">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                                <defs>
                                    <clipPath id="clip-path">
                                    <circle id="Ellipse_467" data-name="Ellipse 467" cx="14" cy="14" r="14" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-1212 -2055.711)" clip-path="url(#clip-path)">
                                    <g id="Ellipse_464" data-name="Ellipse 464" transform="translate(1212 2055.711)" fill="#fff" stroke="#707070" stroke-width="1">
                                    <circle cx="14" cy="14" r="14" stroke="none"/>
                                    <circle cx="14" cy="14" r="13.5" fill="none"/>
                                    </g>
                                    <g id="Ellipse_465" data-name="Ellipse 465" transform="translate(1221 2062.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                    <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                                    <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                                    </g>
                                    <g id="Ellipse_466" data-name="Ellipse 466" transform="translate(1215 2070.711)" fill="#707070" stroke="#707070" stroke-width="1">
                                    <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
                                    <circle cx="11.5" cy="11.5" r="11" fill="none"/>
                                    </g>
                                </g>
                                </svg>
                                <span  
                                    @mouseover="scrollNameHorizontal($event,true)" 
                                    @mouseleave="scrollNameHorizontal($event,false)"
                                >
                                {{user.name}}
                            </span>
                            </div>
                        <div class="table-cell">
                            <span
                                @mouseover="scrollNameHorizontal($event,true)" 
                                @mouseleave="scrollNameHorizontal($event,false)"
                            >
                                {{user.last_name}}
                            </span>
                        </div>
                        <div class="table-cell" @mouseover="hoveringItem = user_id" @mouseleave="hoveringItem = -1" >
                            <div class="group--wrapper" >
                                <div class="group--parent--container">
                
                                    <div
                                    v-for="(group, groupIndex) in user.groups"
                                    :key="groupIndex"
                                    
                                    :style="{'max-width': user.groups.length > 3 ? `${100 / user.groups.length}%` : '', 'margin': '0 0.3rem', 'margin-left': groupIndex != 0 ? `-${user.groups.length-1}%` : `${user.groups.length-1}%`}"
                                    class="group--item"
                                    
                                    >

                                    <VTooltip v-if="hoveringItem == user_id" theme="users"  :triggers="['hover']" :placement="'top'">
                                        <div  :class="['group', 'g'+(groupIndex + 1), 'c'+group.color_id]">
                                        {{ group.name[0] }}
                                        </div>
                                            <template #popper>
                                        {{group.name}}
                                    </template>
                                    </VTooltip>
                                     <div v-else  :class="['group', 'g'+(groupIndex + 1), 'c'+group.color_id]">
                                        {{ group.name[0] }}
                                    </div>

                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    
                </div>
                
            </div>
            </div>
            <span v-if="loadingUsers">
                  <font-awesome-icon icon="spinner" />
            </span>
        </div>
    </section>
  </div>
    </transition>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { ObserveVisibility } from 'vue-observe-visibility'
export default {
  name: "NewGroupSide",
  data: function(){
    return {
        openingAnimation: false,
        sideUsers: [],
        orderByElement: "",
        orderByDirection: "",  
        loadingUsers: false,
        hoveringItem: -1,
        busy:false,
      
    }
  },
  directives:{ObserveVisibility},

  props:{

      sideOpen:{
        type: Boolean,
        default: false
      },
      users:{
        type: [Array, String],
      },
      usersType:{
          type: String,
      }
  },

  beforeDestroy() {
       this.sideOpen = false;
  },

  watch:{
     
      sideOpen(newValue, oldValue){
        if(newValue){
            this.loadUsers();
        }
      },
  },
  methods: {

    loadUsers(){
        this.loadingUsers =true;
        let user_ids = this.users != 'all' ? this.users.map(el=>el.id) : 'all';
        axios.post(
        `${this.$store.state.api_link}/email-templates/users`,
        {users: user_ids, type:this.usersType},
        this.$store.getters.axiosConfig()
        ).then((response) => {
            let users = response.data;
            users.forEach(user=>{
                delete user.ended_percent;
                delete user.training_count;
                delete user.started_percent;
                delete user.enabled;
                delete user.email;
                delete user.role;
                user.visible =false;
            })
            this.sideUsers = users;
            this.loadingUsers =false;
        })
        .catch(() => {
            this.loadingUsers =false;

        });
    },
    visibilityChanged(isVisible, entry, user){
        if(isVisible){
            this.$set(user, 'visible', true);
        }
        else{
            this.$set(user, 'visible', false);
        }
    },
    setOrderBy(element) {
            if(element == this.orderByElement){
                this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
            }else{
                this.orderByElement = element;
                this.orderByDirection = "asc";
            }
        
            if(isNaN(this.users[0][element])){
                if(this.orderByDirection == "desc"){
                    this.sideUsers.sort((a,b) => b[element].localeCompare(a[element])); 
                }else{
                    this.sideUsers.sort((a,b) => a[element].localeCompare(b[element])); 
                }
            }else{
                if(this.orderByDirection == "desc"){
                this.sideUsers.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
                }else{
                this.sideUsers.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
                }
            }
        },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_progressbars.scss";
.side--container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: calc(100vw - 80px);
    height: 100vh;
    overflow: hidden;
}

    .side--background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba(0,0,0,.5);
      content: '';
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: .3s opacity;

      &.side--open {
        opacity: 1;
        pointer-events: auto;
        z-index: 99;
      }
    }
    .group--side {
        position: absolute;
        right: 0rem;
        background-color: #fff;
        height: 100vh;
        width: 750px;
        z-index: 99;
        top: 0;
        font-family: var(--main-site-font);
        transition: .3s right;
        // animation: openSide 0.5s forwards;

        &.side--open {
          right: 0rem;
          z-index: 99;
            top: 0;
        }

        &.header--disabled {
          height: 100vh;
          top: 0px;
        }
    }
    @keyframes openSide{
        0% {
            right: -45rem;
        }
        100% {
            right: 0rem;
        }
    }
    .side--content {
        overflow: auto;
        height: 100%;
        padding: 60px 50px;
        padding-left: 20px;
        overflow-x: hidden;
    }

    .side--close {
        position: absolute;
        left: -100px;
        top: 44px;
        border: transparent;
        // border-right: 20px solid #EFEFEF;
        padding: 20px;
        border-radius: 100%;
        width: 87px;
        height: 87px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 47px;
            height: 47px;
            path{
              fill: var(--primary-color);
            }
        }
    }

    .side--padding {
      position: absolute;
      margin-left: -35px;
      margin-top: -80px;
      width: 40px;
      
      .side--padding--corner {
        height: 250px;
        display: block;
      }

      .side--padding--bottom {
        height: calc(100vh - 150px);
        width: 35px;
        background-color: #fff;
        margin-top: -4px;
      }
    }
.header{
  display: flex;
  flex-direction: column;
  width: 100%;
  h2{
    width: 100%;
    padding: 0.5rem 1.5rem;
    border-bottom: 2px solid #AAAAAA;

  }

}
.row{
    &.total{
        padding: 0 1.5rem;
        margin-bottom: 1rem;
    }
    &.table{
        display: flex;
        flex-direction: column;
    }
}
    .group--section--select {
      margin-top: -60px;

      .group--section--select--button {
        width: 50%;
        float: left;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        user-select: none;
        background-color: $background_color;
        box-shadow: 0 0 5px rgba(0,0,0,0);
        transition: .3s box-shadow, .3s background-color;

        &.active, &:hover {
          box-shadow: 0 0 5px rgba(0,0,0,.3);
          background-color: $group_color;
        }
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .group--title{ 
      font-size: 35px;
      padding-top: 15px;
      font-weight: 900;
      padding-left: 30px;

      span {
        font-size: 35px;
        display: block;
      }
    }

    .lesson--list {
      position: relative;
    }

    .side--table--control {
      margin-top: 50px;
      width: 100%;
      margin: 0;
      position: absolute;
      // right: 0;
      left: 0;
      transition: .3s left;

      &.hidden {
        left: calc(-100% - 150px);

        &.search--table {
          left: calc(200% + 150px);
        }
      }
      
      tr {
        border-radius: 35px;
      }

      svg {
        margin-top: -2px;
      }

      &.search--table {

        tr td {
          border-top: 1px solid #464646;
          border-bottom: 1px solid #464646;

          &:first-child {
            border-left: 1px solid #464646;
          }
          &:last-child {
            border-right: 1px solid #464646;
          }
        }
        svg {
          margin-top: -9px;
        }
      }
    }
    .group--button--container{
      display: flex;
      .filter-button{
        flex: 1;
        border-radius: 35px;
        margin-top: 20px;
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }
    }

    .group--add--button {
      flex: 2;
      padding: 12px;
      border-radius: 35px;
      padding-right: 35px;
      cursor: pointer;
      user-select: none;
      transition: 1s background-color, .3s transform, .3s box-shadow;
      // background-color: #EFEFEF;
      // margin: 0 -35px;
      margin-top: 20px;

      .group--add--button--icon {
        width: 45px;
        height: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 100%;
        vertical-align: middle;

        svg {
          max-width: 28px;
          max-height: 28px;
          fill: var(--primary-color);
        }
      }

      .group--add--button--title {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #707070;
        width: calc(100% - 70px);

        input {
          background: transparent;
          border: none;
          outline: none;
          color: $font_color;
          font-size: 20px;
          font-family: var(--main-site-font);
          width: calc(100% - 40px);
          -webkit-user-select: text;
        }
      }

      &:hover {
        box-shadow: 0px 3px 6px #00000033;
        transform: translateY(-3px);
      }
    }
    .inactive{
      td{
        background: #EFEFEF;
      }
    }
    .content-container{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        height: 100%;
        overflow: auto;
        color: var(--primary-color);
        z-index: 99;
        top: 0;

        .row{
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            h3{
              font-weight: 300;
              color: var(--primary-color);
              border-bottom: 2px solid #AAAAAA;
              padding: 0.5rem 1.5rem;
            }
            &.top{
              z-index: 2;
            }
            &.bottom{
              z-index: 1;
            }
        }
        .grid-row{
            display: grid;
            width: 100%;
            align-items: center;
            &.header{
                width: 95%;
                grid-template-columns: 70% 30%;
                h2{
                    padding: 0.25rem 1rem;
                }
            }
        }
         .grid-buttons{
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 3fr 2fr 2fr;
            grid-template-areas: 
                "label button button2";
            gap: 1rem 0.25rem;
            align-items: center;
            padding-left: 1.5rem;
            span{
              font-size: clamp(16px, 0.8vw, 18px);
              width: 45%;
            }
            .grid-row{
                width: 100%;
            }
            width: 100%;
            span:first-child{
                grid-area: label;
            
            }
            .btn:first-child{
                grid-area: button;
            }
            .dropdown{
                grid-area: dropdown;
            }
            &.flex{
              display: flex;
              span{
                width: 50%;
              }
              .btn{
                margin: 0 0.25rem;
              }
            }
        }
    }
    .row-dropdown{
        width: 100%;
        margin-top: 1rem;
        padding-left: 1.5rem;
        span{
          min-width: 17%;
          display: flex;
          font-size: clamp(16px, 0.8vw, 18px);
        }
        .long{
          min-width: 35%;
        }
    }
    .dropdown{
        display: flex;
        position: relative;
        background: #efefef;
        border-radius: 2rem;
        padding: 0.25rem 0.5rem;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        z-index: 2;
        height: 25px;
        margin: 0;
        margin-left: 0.75rem;
        border: 1px solid var(--primary-color-opacity-50)
        span{
            width: 100%;
            font-size: clamp(14px, 1vw, 16px);
        }
        .dropdown-icon{
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 0.25rem;
            min-width: 1rem;
            width: 1rem;
            min-height: 1rem;
        }
        .btn-container{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;  
          cursor: pointer;
          .title{
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 86%;
            width: 14px;
          }
        }
        .dropdown-list{
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0.25rem;
          top: 0.5rem;
          padding-top: 1rem;
          border-radius: 1rem;
          
          background: #efefef;
          border: none;
          border-top: none;
          z-index: -1;
          &.multi{
            padding-bottom: 0;
            overflow: hidden;
          }
          .list-item{
            font-size: clamp(14px, 0.8vw, 18px);
            margin: 0.5rem 0;
            user-select: none;
            &.multichoice{
              display: flex;
              align-items: center;
              .checkbox{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 1.5rem;
                width: 1.5rem;
                background: white;
                border-radius: 50%;
                margin-right: 0.5rem;
                &.active{
                  background: var(--secondary-color);
                }
                svg{
                  transform: rotate(0deg);
                  path{
                    stroke: var(--primary-color);
                  }
                }
              }
            }
            &:hover{
              font-weight: 700;
            }
           
            &.active{
              font-weight: 700;
            }
          }
          .dropdown-wrapper{
            display: flex;
            flex-direction: column;
            max-height: 14rem;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
        &.white{
            background: white;
            .dropdown-icon{
                background: #efefef;
            }
            .dropdown-list{
              background: white;
            }
        }
        &.small{
            padding: 0.2rem 0.25rem;
            width: 4.5rem;
            .btn-container{
              background: white;
            }
            span{
                text-align: center;
                font-size: clamp(0.7em, 1.1vw, 1em);
            }
        }
        &.big{
          height: 37px;
          border: 1px solid var(--primary-color-opacity-50);
            padding: 0.25rem 0.25rem 0.25rem 0.5rem;
          .dropdown-icon{
            height: 25px;
            width: 25px;
          }
          .dropdown-list{
            .list-item{
                &:first-child{
                  // border-top: 1px solid var(--primary-color);
                  padding-top: 0.5rem;
                  // margin-top: 0.75rem;
              }
            }
          }
        }
        &.top{
          z-index: 3;
        }
        &.bottom{
          z-index: 2;
        }
        &.active{
          padding: 0;
          border-bottom: none;
          background: none;
          border: none;
          .btn-container{
            background: #efefef;  
            padding: 0.25rem 0.5rem;
            border: 1px solid var(--primary-color-opacity-50);
            border-radius: 1rem;

          }
          svg{
            transform: rotate(180deg);
          }
          &.small{
            .btn-container{
              background: white;
              border: none;
              padding: 0.2rem 0.25rem;
            }
            .dropdown-list{
                top: 0;
                  border-radius: 0.75rem;
                .list-item:first-child{
                  border-top: 1px solid;
                  padding-top: 0.25rem;
                  margin-top: 5px;
                }
            }
          }
        }
        &.hidden{
          opacity: 0;
        }
    }
    
    .training-count{
        display: flex;
        margin: 0 0rem 0 2rem;
        padding: 0 1.5rem;
        .label, .number{
          font-size: clamp(16px, 0.8vw, 18px);
        }
    }
    .number{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height:3rem;
        min-width: 3rem;
        border-radius: 50%;
        background: #efefef;
        // border: 1px solid var(--primary-color);
        color: var(--primary-color);
        font-weight: 700;
        font-size: clamp(1em, 1.2vw, 18px);

    }
   
    .btn{
        display: flex;
        width: 100%;
        // margin: 0 0.25rem;
        justify-content: center;
        border-radius: 2rem;
        font-size: clamp(0.5em, 1vw, 16px);
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        cursor: pointer;
        height: 30px;
        align-items: center;
        &.active{
          background: var(--secondary-color);
          border: 1px solid var(--secondary-color);
          font-weight: 900;

        }
    }
.slide-in-enter-active, .slide-in-leave-active {
        transition: right .3s;

    .group--side{
        transition: right .3s;

    }
}
.slide-in-enter, .slide-in-leave-to /* .fade-leave-active below version 2.1.8 */ {
     .group--side{
         
        right: -45rem;
    }
}
.grid-table{
    display: grid;
    grid-template-rows: auto;
    width: 100%;
    .table-row{
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) ;
        margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        background: #efefef;
        &.header{
            display: flex;
            flex-direction: row;
            background: white;
            font-weight: 700;
            color: var(--primary-color);
        }
        &.parent{
            grid-template-columns:1fr;
            margin: 0.25rem 0;
            padding:0;
        }
        .table-cell{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            padding: 0;
            border-left: 1px solid var(--primary-color);
            &:first-child{
                border-left: none;
            }
            span{
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                margin:0 auto;
                justify-content: center;
                &.name-cell{
                    margin-left: 1.7rem;
                }
                img{
                    margin: 0 0.25rem;
                }
                &.header{
                    display: flex;
                    flex-direction: row;
                   white-space: normal;
                }
            }

            svg{
                height: 100%;
                width: 1.7rem;
                
            }
        }
    }
}
.group--wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0;
  padding-right: 10px;
  position: relative;
  padding: 0;
  // float: right;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  overflow: hidden;
    justify-content: center;

  .group--parent--container{
    position: relative;
    display: flex;
    width: 100%;
  }
  &:hover .group {
    @for $i from 2 through 15 {
      &.g#{$i} {
        width: 10% - ($i - 1) * 4;
      }
    }
  }
.group--item {
  display: flex;
  justify-content: center;
  align-items: center;

  // position: relative;
  width: 100%;
  overflow: visible;
}
  .group {
    // position: relative;
    width: 100%;
    height: 100%;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s width;
    user-select: none;
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
    max-width: 40px;
    font-size: clamp(24px, 2vh, 30px);

    // @for $i from 0 through 15 {
    //   &.c#{$i} {
    //     background-color: hsl((3 + ($i - 1) * 24), 100%, 76%);
    //   }
    //   &.g#{$i} {
    //     width: 100% - ($i - 1) * 10;
    //   }
    // }
     @for $i from 0 through 15 {
      &.c#{$i} {
        background-color: hsl(($i * 40 % 360), (20 + 2.5 * $i) % 70 * 1%, var(--theme-lightness));
      }
      &.g#{$i} {
        width: 100% - ($i - 1) * 10;
      }
    }

    &:hover {
      @for $i from 0 through 15 {
        &.g#{$i} {
          width: 100% - ($i - 1) * 2;
        }
      }
    }
  }
}

</style>
