<template>
    <div class="platform-selection" v-if="countries.length > 1">
        <div class="countries-container">
            <div class="label">{{toLocal('generic.country')}}:</div>
            <div 
                class="country" 
                v-for="(country, index) in countries" 
                :key="index"
                :class="{selected: selectedCountry==index}"
                @click="selectPlatform(country, index)"
            >
                {{ country.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            countries: [
                {label: 'LT', language: 'lt'},
                {label: 'LV', language: 'lv'},
                {label: 'EE', language: 'et'},
            ],
            selectedCountry: 0
        }
    },
    watch: {
        '$store.state.system_settings.otherPlatforms': {
            immediate: true,
            handler() {
                if (this.$store.state.system_settings.otherPlatforms) {
                    let mapped_languages = this.$store.state.system_settings.otherPlatforms.map((platform) => platform.language);
                    this.countries = this.countries.filter((country) => mapped_languages.includes(country.language));
                }
            }
        },
        '$store.state.system_settings.platformCountry': {
            immediate: true,
            handler() {
                let mapped_codes = this.countries.map((country) => country.label);
                let index = mapped_codes.indexOf(this.$store.state.system_settings.platformCountry);
                if (index != -1) {
                    this.selectPlatform(this.countries[index], index);
                    this.selectedCountry = index;
                    let language = this.countries[this.selectedCountry].language;
                    this.$store.state.user_selected_language = language
                    this.$store.state.language = language
                }

            }
        }, 
        selectedCountry() {
            let language = this.countries[this.selectedCountry].language;
            this.$store.state.user_selected_language = language
            this.$store.state.language = language
        }
    },
    methods: {
        selectPlatform(country, index) {
            if(this.selectedCountry == index) {
                return
            }
            this.selectedCountry = index;
            let platform_index = this.$store.state.system_settings.otherPlatforms.findIndex((item) => item.language == country.language);
            this.$cookies.set('user_country', this.countries[this.selectedCountry].label, '2y')
            if (platform_index != -1) {
                let backend_url = this.$store.state.system_settings.otherPlatforms[platform_index].backend_domain;
                this.setNewUrl(backend_url);
            }
        },
        setNewUrl(backend_url) {
            this.$store.state.api_link = `${this.$store.state.ssl ? "https" : "http"}://${
                backend_url
            }/api`;
            this.$store.state.api_uploads_link = `${this.$store.state.ssl ? "https" : "http"}://${
                backend_url
            }/uploads/`;
             this.$store.state.api_assets_link = `${this.$store.state.ssl ? "https" : "http"}://${
                backend_url
            }/assets/`;
             this.$store.state.backend_link = `${this.$store.state.ssl ? "https" : "http"}://${
                backend_url
                }`;
            this.$store.commit("fetchSystemSettings");
        }
    }
}
</script>

<style lang="scss" scoped>
.platform-selection{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 32px 0px;
    border-bottom: 2px solid #7D7D7D;
    margin-bottom: 32px;
    font-family: var(--main-site-font);
}
.countries-container{
    display: flex;
    align-items: center;
    position: relative;
    gap: 20px;
}
.country{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 60px;
    background-color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 2px 3px 0 #00000033;
    color:#333333;
    transition: background-color 0.3s ease;
    
    &:hover, &:active{
        background-color: #C4EBE6;
    }
    &.selected{
        background-color: #333333;
        color: white;
    }
}
.label{
    position: absolute;
    right: calc(100% + 20px);
    font-weight: 600;
    font-size: 18px;
    color:#333333;

}
</style>