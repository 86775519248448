<template>
  <div class="main-container" v-if="isLoading == false">
    <div class="top-row">

      <div class="user-expiration">
        <span
          class="will-expire"
          v-if="shownExpiryDate != '' && shownExpiryDate != undefined"
        >
          {{ toLocal(!isExpired ? "users.user_expires" : "groups.expired") }}:
          <span class="date-bold">{{ shownExpiryDate }}</span></span
        >
        <div class="date-with-label">
          <span class="will-expire date-label">{{
            toLocal("user_side.deactivate_after")
          }}</span>
          <DatePicker
            v-model="userDeactivationDate"
            label="date"
            :placeholder="toLocal('users_side.select_date')"
            class="secondary-calendar"
            type="date"
            valueType="format"
            @change="changeDateHandler"
          >
            <template v-slot:icon-calendar> </template>
          </DatePicker>
        </div>
      </div>
      <div class="user-info-container">
        <div class="username-container" v-if="user.username && user.username.length > 0">
          <span>{{toLocal('generic.username')}}:</span> <strong>{{user.username}}</strong>
        </div>
        <div class="change-password-container" v-if="hasPermission('users.set_passwords')">
          <span class="password-success" v-if="changePasswordModal.showSuccessMessage">
            {{toLocal('passwords.new_password_saved')}}
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 4.93939L5.8 11L13 1" stroke="#49B357" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <button  @click="$set(changePasswordModal, 'show', true)">{{toLocal('generic.change_password')}}</button>
        </div>
      </div>
    </div>

    <!-- <div class="user-info-container" :class="[getContrast('secondary_color')]">
      <div class="inputs">
        <div class="input-container">
          <span>{{toLocal('generic.username')}}:</span>
          <input type="text" v-model="editableUserData.username" @blur="blurCheckUser('username')">
        </div>
        <div class="input-container">
          <span>{{toLocal('login.password')}}:</span>
          <input type="text" v-model="editableUserData.password" @blur="blurCheckUser('password')">
        </div>
      
      </div>
    </div> -->
    <div class="groups">
      <div class="top-row">
        <span>
          {{ toLocal("user_side.assigned_groups_list") }}
          <span class="bold">
            {{ groups.length }}
          </span>
        </span>
        
        <CustomDropdown
          class="c-dropdown"
          :class="[getContrast('secondary_color')]"
          :list="all_groups"
          :compare-list="groups"
          :options="{
            checkbox: true,
            closeOnClick: false,
            customMaxHeight: 250
          }"
          @elClicked="addNewGroup"
        />
      </div>
      <div class="groups-inner">
        <div
          class="group"
          :class="['group_color-' + group.color_id]"
          v-for="group in groups"
          :key="`gr-${group.id}`"
        >
          <span>{{ group.name }}</span>
          <div class="remove-icon" @click="removeUserFromGroup(group.id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.191"
              height="17.191"
              viewBox="0 0 17.191 17.191"
            >
              <g
                id="Group_8028"
                data-name="Group 8028"
                transform="translate(-1377.29 -528.908)"
              >
                <circle
                  id="Ellipse_470"
                  data-name="Ellipse 470"
                  cx="8.595"
                  cy="8.595"
                  r="8.595"
                  transform="translate(1377.29 528.908)"
                  fill="#efefef"
                />
                <g
                  id="Group_656"
                  data-name="Group 656"
                  transform="translate(1385.887 530.693) rotate(45)"
                >
                  <g
                    id="Path_749"
                    data-name="Path 749"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_751"
                      data-name="Path 751"
                      d="M10.357,5.183a.558.558,0,0,1-.165.4.546.546,0,0,1-.4.169H5.743V9.8a.569.569,0,0,1-.565.561.558.558,0,0,1-.4-.165.546.546,0,0,1-.169-.4V5.748H.561a.566.566,0,0,1-.4-.961.588.588,0,0,1,.4-.169H4.613V.569a.551.551,0,0,1,.169-.4A.558.558,0,0,1,5.178,0a.571.571,0,0,1,.565.569V4.618H9.8A.572.572,0,0,1,10.357,5.183Z"
                      transform="translate(0 0)"
                      fill="#28345c"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- <SaveModal
      :user="user"
      :modalType="'save'"
      v-if="saveUserModal"
      @cancel="saveUserModal=false"
      @confirm="saveUserInfo"
    /> -->
    <GlobalModal
      :modalInfo="{
        text: 'passwords.password_change',
        undertext: '',
        confirmBtnText: 'generic.save',
        type: 'inputs',
        class: 'user-password-reset-modal '+(changePasswordModal.password.svg && changePasswordModal.password.svg.position ? 'input-icon-position-'+changePasswordModal.password.svg.position : ''),
        models: [changePasswordModal.password,changePasswordModal.confirmPassword],
        requestLoading: loadingPasswordResetRequest
      }"
      @onConfirm="resetUserPassword"
      @onClose="$set(changePasswordModal,'show', false)"
      v-if="changePasswordModal.show"
    >
      <template v-slot:inputIcon="slotProps">
          <svg v-if="!slotProps.input.showText" @click="slotProps.input.svg.action(slotProps.input.key)" :class="{'position-left': changePasswordModal.password.svg && changePasswordModal.password.svg.position == 'left', 'position-right': changePasswordModal.password.svg && changePasswordModal.password.svg.position == 'right'}" width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 6.5C3.64222 9.64091 6.68351 11.5556 10 11.5556C13.3159 11.5556 16.3504 9.64152 18.4621 6.49937C16.3574 3.35884 13.3163 1.44444 10 1.44444C6.68351 1.44444 3.64222 3.35909 1.53746 6.5ZM0.112049 6.10746C2.41702 2.42249 5.97035 0 10 0C14.0296 0 17.583 2.42249 19.888 6.10746C20.0376 6.34665 20.0373 6.65456 19.8873 6.89351C17.5753 10.5769 14.0302 13 10 13C5.97035 13 2.41702 10.5775 0.112049 6.89254C-0.0373498 6.6537 -0.0373498 6.3463 0.112049 6.10746Z" fill="#7D7D7D"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 4.33333C8.84509 4.33333 7.90801 5.30338 7.90801 6.5C7.90801 7.69662 8.84509 8.66667 10.001 8.66667C11.157 8.66667 12.0941 7.69662 12.0941 6.5C12.0941 5.30338 11.157 4.33333 10.001 4.33333ZM6.51267 6.5C6.51267 4.50564 8.07446 2.88889 10.001 2.88889C11.9276 2.88889 13.4894 4.50564 13.4894 6.5C13.4894 8.49436 11.9276 10.1111 10.001 10.1111C8.07446 10.1111 6.51267 8.49436 6.51267 6.5Z" fill="#7D7D7D"/>
          </svg>
          <svg v-else @click="slotProps.input.svg.action(slotProps.input.key)" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 7.25005C3.64222 10.391 6.68351 12.3056 10 12.3056C13.3159 12.3056 16.3504 10.3916 18.4621 7.24942C16.3574 4.10888 13.3163 2.19449 10 2.19449C6.68351 2.19449 3.64222 4.10914 1.53746 7.25005ZM0.112049 6.8575C2.41702 3.17253 5.97035 0.750047 10 0.750047C14.0296 0.750047 17.583 3.17253 19.888 6.8575C20.0376 7.0967 20.0373 7.40461 19.8873 7.64356C17.5753 11.327 14.0302 13.75 10 13.75C5.97035 13.75 2.41702 11.3276 0.112049 7.64259C-0.0373498 7.40374 -0.0373498 7.09635 0.112049 6.8575Z" fill="#7D7D7D"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 5.08338C8.84509 5.08338 7.90801 6.05343 7.90801 7.25005C7.90801 8.44666 8.84509 9.41671 10.001 9.41671C11.157 9.41671 12.0941 8.44666 12.0941 7.25005C12.0941 6.05343 11.157 5.08338 10.001 5.08338ZM6.51267 7.25005C6.51267 5.25569 8.07446 3.63893 10.001 3.63893C11.9276 3.63893 13.4894 5.25568 13.4894 7.25005C13.4894 9.24441 11.9276 10.8612 10.001 10.8612C8.07446 10.8612 6.51267 9.24441 6.51267 7.25005Z" fill="#7D7D7D"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.391998 0.310937C0.634516 -0.0248573 1.10333 -0.100473 1.43913 0.142046L19.4391 13.142C19.7749 13.3846 19.8505 13.8534 19.608 14.1892C19.3655 14.525 18.8967 14.6006 18.5609 14.3581L0.560889 1.35806C0.225095 1.11555 0.14948 0.646731 0.391998 0.310937Z" fill="#7D7D7D"/>
          </svg>
      </template>
    </GlobalModal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";
import _ from "lodash";
import CustomDropdown from "../../elements/CustomDropdown.vue";
import SaveModal from "@/components/layout/users/SaveModal.vue";
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";

export default {
  name: "UserSettings",
  data: function () {
    return {
      userDeactivationDate: 0,
      shownExpiryDate: "",
      userDetails: {},
      saveUserModal:false,
      editableUserData:{
        username: '',
        password: '',
      },
      editingField: '',
      groups: [],
      all_groups: [],
      isLoading: false,
      changePasswordModal:{
        show: false,
        password: {'value': '', type: 'password', key:'password', label: this.toLocal('passwords.enter_new_password')+':', extraLabel: this.toLocal('password.validation_label_n_symbols'), showText:false, 'placeholder': this.toLocal('first_login.enter'), svg: {position: 'right', action: this.changeVisibility}},
        confirmPassword: {'value': '', type: 'password', key:'confirmPassword', label:  this.toLocal('passwords.repeat_new_password')+':', extraLabel: this.toLocal('password.validation_label_n_symbols'), showText:false, 'placeholder': this.toLocal('first_login.enter'), svg: {position: 'right', action: this.changeVisibility}},
        errorMessage: '',
        showSuccessMessage: false
      },
      loadingPasswordResetRequest: false,
    };
  },
  props: {
    user: {
      type: Object,
    },
  },
  components: {
    DatePicker,
    SaveModal,
    GlobalModal,
    CustomDropdown,
  },
  methods: {
    isGroupAdded(group_id) {
      return this.groups.find((el) => el.id == group_id);
    },
    changeDateHandler() {
      if (
        this.userDeactivationDate != null &&
        this.userDeactivationDate != ""
      ) {
        this.setDeactivationDate();
      } else {
        this.clearDeactivationDate();
      }
    },
    resetUserPassword(){
      let password = this.changePasswordModal.password.value
      this.$set(this.changePasswordModal.confirmPassword, 'errorMessage', '')
      if(password.length < 8){
        this.$set(this.changePasswordModal.confirmPassword, 'errorMessage', this.toLocal("password.error.short"))
        return
      }
      if(this.changePasswordModal.confirmPassword.value != password){
        this.$set(this.changePasswordModal.confirmPassword, 'errorMessage', this.toLocal("first_login.repeat_wrong"))
        return
      }
      this.loadingPasswordResetRequest = true;

        axios.post(
            `${this.$store.state.api_link}/user/save`,
            { user_id: this.user.id, field: 'password', value: password },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if(response.data.status == 'success'){
              this.$set(this.changePasswordModal.password, 'value', '');
              this.$set(this.changePasswordModal.confirmPassword, 'value', '');
              this.$set(this.changePasswordModal, 'show', false);
              this.$set(this.changePasswordModal, 'showSuccessMessage', true);
              setTimeout(() => {
                this.$set(this.changePasswordModal, 'showSuccessMessage', false);
              },3000)
            }
            else{
              this.$set(this.changePasswordModal.confirmPassword, 'errorMessage', this.toLocal("users.failed_to_save"))
         
            }
          this.loadingPasswordResetRequest = false;
        }).catch((err) => {
          if (err.response.data && err.response.data.message) {
    
              this.$set(this.changePasswordModal.confirmPassword, 'errorMessage', this.toLocal(err.response.data.message))

          } else{
  
            this.$set(this.changePasswordModal.confirmPassword, 'errorMessage', this.toLocal("users.failed_to_save"))
          }
          this.loadingPasswordResetRequest = false;

        })
    },
    changeVisibility(type) {
      this.$set(this.changePasswordModal[type], 'showText', !this.changePasswordModal[type].showText);
      if(this.changePasswordModal[type].showText){
        this.$set(this.changePasswordModal[type], 'type', 'text');
      }
      else{
        this.$set(this.changePasswordModal[type], 'type', 'password');
      }
    },
    setDeactivationDate() {
      if (this.userDeactivationDate != "") {
        axios
          .post(
            `${this.$store.state.api_link}/user/deactivationDate`,
            { user: this.user.id, time: this.userDeactivationDate },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            let user = this.user;
            user.deactivation_date = this.userDeactivationDate;
            this.$emit("updateUser", user);
          })
          .catch((error) => {
            console.log("error setting deactivation date");
          });
      }
    },
    clearDeactivationDate() {
      this.userDeactivationDate = "";
      axios
        .post(
          `${this.$store.state.api_link}/user/deactivationDate/clear`,
          { user: this.user.id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          let user = this.user;
          user.deactivation_date = "";
          this.$emit("updateUser", user);
        })
        .catch((error) => {
          console.log("error clearing deactivation date");
        });
    },
    removeUserFromGroup(group_id) {
      this.groups.splice(
        this.groups.findIndex((x) => x.id == group_id),
        1
      );
      axios
        .post(
          `${this.$store.state.api_link}/group/user/remove`,
          { group: group_id, user: this.user.id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
            // this.user.groups.splice(this.user.groups.findIndex(x => x.id == group_id), 1);
            // this.$store.state.side_info['loaded'] = false;
          } else {
            console.log("Error while removing user");
          }
        })
        .catch(() => {
          console.log("Server error while removing user");
        });
    },
    addNewGroup(id) {
      let group_id = id;
      let isGroupAlreadyAdded = this.groups.find((el) => el.id == group_id);
      if (group_id != "" && !isGroupAlreadyAdded) {
        this.groups.push(this.all_groups.find((el) => el.id == group_id));
        axios
          .post(
            `${this.$store.state.api_link}/group/user/add`,
            { group: group_id, user: this.user.id },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if (response.data.status != "success") {
              console.log("Error while adding user");
            }
          })
          .catch(() => {
            console.log("Server error while adding user");
          });
      } else if (isGroupAlreadyAdded) {
        this.removeUserFromGroup(group_id);
      }
    },

    changeShownExpiry() {
      if (this.userDeactivationDate == null || this.userDeactivationDate == "")
        return (this.shownExpiryDate = "");
      let now = new Date(Date.now());
      let date = new Date(this.userDeactivationDate);
      let difference = now - date;
      let hours = Math.abs(Math.round(difference / 1000 / 60 / 60));
      let days = 0;
      let months = 0;
      let years = 0;
      let fullDate = "";
      if (date <= now) {
        this.isExpired = true;
      } else {
        this.isExpired = false;
      }
      if (hours >= 24) {
        days = Math.floor(hours / 24);
        hours -= days * 24;
        if (days >= 30) {
          months = Math.floor(days / 30);
          days -= months * 30;
          if (months >= 12) {
            years = Math.floor(months / 12);
            months -= years * 12;
          }
        }
      }
      if (years != 0) {
        fullDate += years + " " + this.toLocal("generic.years_short") + " ";
      }
      if (months != 0) {
        fullDate += months + " " + this.toLocal("generic.months_short") + " ";
      }
      if (days != 0) {
        fullDate += days + " " + this.toLocal("generic.days_short") + " ";
      }

      this.shownExpiryDate = fullDate;
    },
    getDetailedInfo(id) {
      this.isLoading = true;
      axios
        .post(
          `${this.$store.state.api_link}/user/detailed`,
          { user_id: id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status != "error") {
            this.userDetails = response.data.data.user;
            this.groups = response.data.data.groups;
            this.all_groups = response.data.data.all_groups;
            this.userDeactivationDate = this.userDetails.deactivation_date;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error getting info");
        });
    },
    blurCheckUser(field){
      if(this.editableUserData[field] != this.user[field]){
        this.saveUserModal = true;
        this.editingField = field;
      }
    },
    saveUserInfo(user){
      let field = this.editingField;
      let value = this.editableUserData[field];
      this.errorMessage = '';
      axios
          .post(
            `${this.$store.state.api_link}/user/save`,
            { user_id: this.user.id, field: field, value: value },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if (response.data.status == "success") {
              if(field == 'username'){
                this.$set(this.user,field, value);
              }
              this.saveUserModal = false;
              this.editingField = '';
            }
            else{
              this.errorMessage = this.toLocal("users.failed_to_save")
            }
          })
          .catch((err)=>{
            this.saveUserModal = false;
             if (err.response.data && err.response.data.message) {
              this.$store.state.infoMessage.setMessage(
                "error",
                this.toLocal(err.response.data.message)
              );
            } else
              this.$store.state.infoMessage.setMessage(
                "error",
                this.toLocal("users.failed_to_save")
              );
          })
    }
  },
  mounted() {
    if (this.user.id != undefined) {
      this.getDetailedInfo(this.user.id);
    }
    // if(this.user.username != undefined){
    //   this.editableUserData.username = this.user.username;
    // }
    this.changeShownExpiry();
  },
  watch: {
    userDeactivationDate: {
      handler(newVal) {
        this.changeShownExpiry();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  .user-expiration {
    padding: 4px 16px 0 16px;
    display: flex;
    padding-right: 40px;
    flex-direction: column;
    gap: 18px;
    width: fit-content;
    .will-expire {
      font-size: clamp(12px, 1.5vw, 14px);
      .date-bold {
        font-weight: 600;
      }
    }
  }
  .date-with-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.secondary-calendar {
  max-height: 32px;
  .mx-input {
    text-align: start;
  }
}
.groups {
  display: flex;
  margin: 20px 0;
  border-radius: 12px;
  background: #f4f4f4;
  padding: 16px 20px;
  gap: 16px;
  min-height: 160px;
  flex-direction: column;
  .top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color:#333333;
    .c-dropdown {
      background-color: var(--secondary-color);
      border-radius: 6px;
      color: white;
      padding: 4px 10px;
      border: none;
      width: 18%;
    }
    .bold{
      font-weight: 700;
    }
  }
  .groups-inner {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .group {
    display: flex;
    min-width: 180px;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background: #fff;
    border-bottom: 2px solid #2fc900;
    padding: 6px 12px;
    font-size: clamp(12px, 1.5vw, 14px);
  }
  .remove-icon{
    display: flex;
  }
  
}
.seperator{
  display: flex;
  width: 100%;
  background: #33333369;
  height: 1px;
}
.user-info-container{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 20px;
    border-radius: 12px;
    width: fit-content;
    .inputs{
      display: flex;
      position: relative;
      align-items: flex-end;
      gap: 10px;
    }
    .input-container{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 0.25rem;
        padding: 0;
        border-radius: 8px;
        &:last-child{
            margin-bottom: 0;
        }
      span{
        margin-bottom: 5px;
        font-size: 14px;
        padding-left: 10px;
        color: #333;
      }
    }
    .error-message{
      color:rgb(250, 81, 81);
      padding-left: 10px;
      font-size: clamp(14px, 0.7vw, 16px);
    }
    
    input{
        width: 100%;
        max-width: 250px;
        color: var(--primary-color);
        background: white;
        border-radius: 8px;
        border: none;
        padding: 5px 10px;
        height: 33px;
        font-size: clamp(16px, 1vw, 20px);
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
        -webkit-appearance: none;
        &:focus{
            outline: none;
        }
        &::placeholder{
            color: var(--primary-color-opacity-50);
        }
    
    }
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 150px;
      min-width: 100px;
      height: 33px;
      background: var(--secondary-color);
      padding: 5px 15px;
      min-height: 32px;
      height: fit-content;
      border:none;
      font-size: clamp(16px, 1vw, 20px);
      font-weight: 500;
      border-radius: 15px;
      cursor: pointer;
    }
    &.whiteContrast{
      button{
        color: #fff
      }
    }
     &.blackContrast{
      button{
        color: #333
      }
    }
  }
  .top-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: var(--main-site-font);
  }
  .username-container{
    display: flex;
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 12px;
    strong{
      margin-left: 8px;
      font-weight: 600;
    }
  }
  .change-password-container{
    display: flex;
    align-items: center;
    button{
      background: white;
      padding: 4px 10px;
      color: #333;
      border-radius: 6px;
      border: 1px solid #B2B2B2;
      max-width: initial;
      min-width: 160px;
      justify-content: center;
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .password-success{
      color: #49B257;
      display: flex;
      align-items: center;
      margin-right: 9px;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      svg{
        margin-left: 8px;
      }
    }
  }
  
</style>
<style lang="scss">
.groups .top-row{
  .c-dropdown{
    &.blackContrast{
      color: #333;
      
      .initial,.reverse{
        svg{
          path{
            stroke: #333;
          }
        }
      }
    }
    &.whiteContrast{
      color: #fff;
      .initial,.reverse{
        svg{
          path{
            stroke: #fff;
          }
        }
      }
    }
  }
}
.user-password-reset-modal{
  &.input-icon-position-left{
    .input-container{
      .input-wrapper{
        svg{
          position: absolute;
          left: 8px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  &.input-icon-position-right{
    .input-container{
      .input-wrapper{
        svg{
          position: absolute;
          right: 8px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
</style>
