<template>
  <div class="table-container">
    <table>
        <thead>
            <tr>
                <th
                    v-for="(header, index) in headers" :key="index"

                >
                    <div class="header-cell">
                        <span>
                            {{header.label}} 
                            <!-- <span class="bold" v-if="header.value == 'total'">
                                {{totalUserCount}}
                            </span> -->
                        </span>
                        <svg @click="setSort(header)" v-if="header.sortable" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :class="{active:sortBy == header.value && sortDirection == 'desc'}" d="M6.60243 19.7188C6.28496 20.0794 5.75871 20.0974 5.42453 19.7549C5.41617 19.7459 5.39943 19.7278 5.39108 19.7188L0.228004 13.8231C-0.089464 13.4625 -0.0727225 12.8946 0.261454 12.552C0.420188 12.3988 0.620747 12.3086 0.837962 12.3086H11.164C11.6235 12.3086 11.9995 12.7143 11.9995 13.2101C11.9995 13.4445 11.9159 13.6609 11.7739 13.8322L6.60243 19.7188Z" fill="#B2B2B2"/>
                            <path :class="{active:sortBy == header.value && sortDirection == 'asc'}" d="M5.39757 0.281221C5.71504 -0.0793729 6.2413 -0.0974378 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549251 7.28574 0.000549293 6.78992C0.000549313 6.55553 0.0841098 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="#B2B2B2"/>
                        </svg>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr 
                v-for="(invitation, i_index) in items"
                :key="i_index"
                :class="{disabled: !invitation.enabled}"
            >
                <td
                    v-for="(header, index) in headers" :key="index"
                    :class="header.class ? header.class : ''"
                >
                    <div class="cell tools-cell" v-if="header.isSettings" >
                        <div class="tools">
                            <div 
                                class="tool"
                                :class="{'delete-btn': option.icon == 'delete', disabled: !invitation.enabled && option.icon == 'activate'}"
                                v-for="(option, o_i) in header.options" :key="o_i" @click="performToolAction(option.icon, invitation)"
                            >
                                <svg v-if="option.icon == 'edit'" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.30418 20.576L6.0194 19.52H6.05892C6.28027 19.5197 6.49939 19.4758 6.70373 19.3907C6.90806 19.3057 7.09359 19.1812 7.24966 19.0243L20.1639 6.11973C20.483 5.79945 20.6621 5.3659 20.6621 4.91396C20.6621 4.46202 20.483 4.02848 20.1639 3.70819L17.2911 0.829893C16.9706 0.511041 16.5367 0.332031 16.0844 0.332031C15.6322 0.332031 15.1983 0.511041 14.8778 0.829893L1.95588 13.7422C1.79759 13.9008 1.67329 14.09 1.59056 14.2982C1.50782 14.5064 1.46837 14.7293 1.47463 14.9532L0.41304 19.6861C0.391338 19.7923 0.391338 19.9018 0.41304 20.0079C0.433849 20.1044 0.473661 20.1958 0.530161 20.2767C0.58666 20.3577 0.658718 20.4266 0.742132 20.4794C0.862347 20.5554 1.00149 20.5963 1.14377 20.5973C1.19859 20.5983 1.25332 20.5922 1.30654 20.579M15.6262 1.57834C15.7478 1.45743 15.9123 1.38954 16.0838 1.38954C16.2554 1.38954 16.4199 1.45743 16.5415 1.57834L19.4149 4.45605C19.4758 4.5152 19.5242 4.58603 19.5572 4.6643C19.5901 4.74258 19.6069 4.82669 19.6065 4.9116C19.6064 4.99697 19.5894 5.08147 19.5565 5.16025C19.5236 5.23904 19.4755 5.31055 19.4149 5.37069L18.1699 6.61477L14.3776 2.82537L15.6262 1.57834ZM3.05168 14.1435L13.6286 3.57205L17.4209 7.36146L6.8439 17.9329L3.05168 14.1435ZM2.02665 17.3471L2.52619 15.1159L5.88081 18.468L3.48162 19.0031L2.02665 17.3471Z" fill="#4D4D4D"/>
                                </svg>
                                <svg v-if="option.icon == 'eye'" width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53746 6.5C3.64222 9.64091 6.68351 11.5556 10 11.5556C13.3159 11.5556 16.3504 9.64152 18.4621 6.49937C16.3574 3.35884 13.3163 1.44444 10 1.44444C6.68351 1.44444 3.64222 3.35909 1.53746 6.5ZM0.112049 6.10746C2.41702 2.42249 5.97035 0 10 0C14.0296 0 17.583 2.42249 19.888 6.10746C20.0376 6.34665 20.0373 6.65456 19.8873 6.89351C17.5753 10.5769 14.0302 13 10 13C5.97035 13 2.41702 10.5775 0.112049 6.89254C-0.0373498 6.6537 -0.0373498 6.3463 0.112049 6.10746Z" fill="#4D4D4D"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 4.33333C8.84509 4.33333 7.90801 5.30338 7.90801 6.5C7.90801 7.69662 8.84509 8.66667 10.001 8.66667C11.157 8.66667 12.0941 7.69662 12.0941 6.5C12.0941 5.30338 11.157 4.33333 10.001 4.33333ZM6.51267 6.5C6.51267 4.50564 8.07446 2.88889 10.001 2.88889C11.9276 2.88889 13.4894 4.50564 13.4894 6.5C13.4894 8.49436 11.9276 10.1111 10.001 10.1111C8.07446 10.1111 6.51267 8.49436 6.51267 6.5Z" fill="#4D4D4D"/>
                                </svg>
                                <svg class="toggle-btn" v-if="option.icon == 'activate'" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00007 2.37109C12.4202 2.37109 16 5.86787 16 10.1854C16 14.503 12.4202 17.9997 8.00007 17.9997C3.57993 17.9997 0 14.503 0 10.1854C0.0115108 5.87912 3.59145 2.38234 8.00007 2.37109ZM8.00007 16.3582C11.4878 16.3582 14.3195 13.5922 14.3195 10.1854C14.3195 6.77861 11.4878 4.01265 8.00007 4.01265C4.51231 4.01265 1.68067 6.77861 1.68067 10.1854C1.69218 13.5922 4.51231 16.3469 8.00007 16.3582Z" fill="#4D4D4D"/>
                                    <path d="M8.00628 8.62384C7.54585 8.62384 7.16602 8.25282 7.16602 7.80308V0.820763C7.16602 0.371018 7.54585 0 8.00628 0C8.46671 0 8.84654 0.371018 8.84654 0.820763V7.80308C8.84654 8.25282 8.47822 8.62384 8.00628 8.62384Z" fill="#4D4D4D"/>
                                </svg>
                                <svg v-if="option.icon == 'delete'" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0416 19.06H5.82927C3.63232 19.06 1.84692 17.3025 1.84692 15.1398V4.08232H14.0239V15.1339C14.0239 17.2965 12.2385 19.054 10.0416 19.054V19.06ZM2.75475 15.1398C2.75475 16.808 4.13465 18.1663 5.82927 18.1663H10.0416C11.7362 18.1663 13.1161 16.808 13.1161 15.1398V4.97597H2.75475V15.1398Z" fill="#D45454"/>
                                    <path d="M13.8968 4.98795C13.8484 4.98795 13.794 4.98794 13.7455 4.98199H2.089C1.6714 4.98199 1.27801 4.82113 0.98145 4.53516C0.684893 4.24323 0.521484 3.85598 0.521484 3.4449C0.521484 2.59891 1.22354 1.90781 2.08295 1.90781H13.7879C13.8303 1.90781 13.8968 1.90781 13.9695 1.90781H14.036C14.9015 1.99718 15.5309 2.74785 15.4462 3.59384C15.4038 4.00492 15.2041 4.3743 14.8834 4.63644C14.5989 4.86879 14.2539 4.98795 13.8908 4.98795H13.8968ZM2.08295 2.80147C1.71982 2.80147 1.42931 3.08744 1.42931 3.4449C1.42931 3.61767 1.49589 3.77853 1.62298 3.90364C1.75008 4.02875 1.91349 4.09429 2.089 4.09429H13.794C14.0179 4.11216 14.1813 4.05854 14.3205 3.9513C14.4536 3.83811 14.5384 3.68321 14.5565 3.51639C14.5928 3.15893 14.3266 2.84317 13.9634 2.80743C13.8847 2.80743 13.8545 2.80743 13.8242 2.80743H2.089L2.08295 2.80147Z" fill="#D45454"/>
                                    <path d="M10.671 2.79549H5.1938L5.07881 2.51547C5.00618 2.33079 4.96381 2.13418 4.96381 1.93758C4.96381 1.09158 5.66587 0.400489 6.52528 0.394531H9.32745C10.1929 0.394531 10.895 1.08563 10.895 1.93758C10.895 2.13418 10.8587 2.33079 10.786 2.51547L10.671 2.79549ZM5.8777 1.90183H9.98714C9.96898 1.56224 9.67848 1.28819 9.3335 1.28819H6.53133C6.18636 1.28819 5.89585 1.56224 5.8777 1.90183Z" fill="#D45454"/>
                                    <path d="M5.19982 16.0693C4.95168 16.0693 4.74591 15.8667 4.74591 15.6224V6.82292C4.74591 6.57865 4.95168 6.37609 5.19982 6.37609C5.44796 6.37609 5.65374 6.57865 5.65374 6.82292V15.6224C5.65374 15.8667 5.44796 16.0693 5.19982 16.0693Z" fill="#D45454"/>
                                    <path d="M7.93541 16.0693C7.68727 16.0693 7.4815 15.8667 7.4815 15.6224V6.82292C7.4815 6.57865 7.68727 6.37609 7.93541 6.37609C8.18355 6.37609 8.38933 6.57865 8.38933 6.82292V15.6224C8.38933 15.8667 8.18355 16.0693 7.93541 16.0693Z" fill="#D45454"/>
                                    <path d="M10.671 16.0693C10.4229 16.0693 10.2171 15.8667 10.2171 15.6224V6.82292C10.2171 6.57865 10.4229 6.37609 10.671 6.37609C10.9192 6.37609 11.1249 6.57865 11.1249 6.82292V15.6224C11.1249 15.8667 10.9192 16.0693 10.671 16.0693Z" fill="#D45454"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="cell" v-else-if="header.value=='condition'">
                        <div class="groups" v-if="invitation.type=='groups'">
                            <div 
                                class="bubble" 
                                v-for="(group, g_index) in invitation.condition"
                                :key="g_index"
                                :class="[group.color_id ? 'gc-'+group.color_id : 'gc-1']"
                            >
                                <span>
                                    {{group.name ? group.name[0].toUpperCase() : ''}}
                                </span>

                            </div>

                        </div>
                        <div class="users" v-if="invitation.type=='single'">
                            {{getUserSingleInvitationLabel(invitation.condition)}}
                        </div>
                        <div class="condition" v-if="invitation.type=='condition'">
                            <div class="tag" v-for="(tag, t_index) in invitation.condition" :key="t_index">
                                {{tag}}
                            </div>
                        </div>
                        <div class="link" v-if="invitation.type=='link'">
                            {{invitation.condition ? toLocal('user_side.deactivate_after')+' '+invitation.condition : toLocal('invitations.valid_indefinitely')}} 
                        </div>
                    </div>
                    <div class="cell" :class="header.tdClass ? header.tdClass : ''" v-else>

                        <span>
                            {{getInvitationCellValue(invitation, header)}}
                        </span>
                    </div>
                </td>
            </tr>

        </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props:{
        invitations: Array
    },
    data(){
        return{
            headers:[
                {label: this.toLocal('invitations.assign_date'), value: 'created_at', sortable:true, tdClass: ['center']},
                {label: this.toLocal('invitations.assign_type'), value: 'type', sortable:true, class:['tag']},
                {label: this.toLocal('invitations.added_count'), value: 'user_count', sortable:true, tdClass: ['center']},
                {label: this.toLocal('invitations.add_condition'), value: 'condition'},
                {label: '', value: 'total', isSettings:true, options:[
                    {icon: 'edit'},
                    {icon:'eye'},
                    // {icon:'activate'},
                    {icon:'delete'},
                ]},
            ],
            invitationTypes:{
                link: this.toLocal('invitations.email_link'),
                groups: this.toLocal('invitations.by_groups'),
                single: this.toLocal('invitations.add_by_one'),
                condition: this.toLocal('invitations.add_by_condition'),
            },
            items: [],
            sortBy: null,
            sortDirection: 'asc'
        }
    },
    mounted(){
        this.items = this._.cloneDeep(this.invitations);
    },
    computed:{
        totalUserCount(){
            if(this.items && this.items.length > 0){
                let total_count =this.items.reduce((a,b)=>a+b.user_count, 0);
                return total_count;
            }
            else return 0;
        }
    },
    watch:{
        invitations:{
            deep:true,
            handler(){
                this.items = this._.cloneDeep(this.invitations);
                if(this.sortBy != null){
                    this.sortItems();
                }
            }
        }
    },
    methods:{
        performToolAction(icon, invitation){
            switch(icon){
                case 'eye':
                    this.viewItem(invitation)
                    break;
                case 'activate':
                    this.toggleItem(invitation)
                    break;
                case 'delete':
                    this.removeInvitation(invitation)
                    break;
                case 'edit':
                    this.editItem(invitation)
            }
        },
        getTableHeaderLabel(header){
            if(header.value == 'total'){
                return header.label+' 255';
            }
            else{
                return header.label
            }
        },
        getInvitationCellValue(invitation, header){
            if(header.value == 'type'){
                return  this.invitationTypes[invitation.type];
            }
            else{
                return invitation[header.value];
            }
        },
        setSort(header){
            this.sortBy = header.value;
            this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
            this.sortItems();
        },
        sortItems(){
            this.items = this._.cloneDeep(this.invitations);
            this.items = this.items.sort((a,b)=>{
                if(this.sortBy == 'created_at'){
                    return this.sortDirection == 'asc' ? new Date(a[this.sortBy]) - new Date(b[this.sortBy]) : new Date(b[this.sortBy]) - new Date(a[this.sortBy])
                }
                else if(this.sortBy == 'type'){
                    return this.sortDirection == 'asc' ?
                        a[this.sortBy].localeCompare(b[this.sortBy]) 
                        : b[this.sortBy].localeCompare(a[this.sortBy]) 
                }
                else{
                    return this.sortDirection == 'asc' ? a[this.sortBy] - b[this.sortBy] : b[this.sortBy] - a[this.sortBy];
                }
            })
        },
        getUserSingleInvitationLabel(users){
            if(users && users.length > 0 && Array.isArray(users))
                return users.map(user => user.name+(user.last_name ? ' '+user.last_name : '')).join('|');
            else return ''
        },
        removeInvitation(item){
            this.$emit('removeItem',item)
        },
        toggleItem(item){
            this.$emit('toggleItem',item)
        },
        viewItem(item){
            this.$emit('viewItem',item)
        },
        editItem(item){
            this.$emit('editItem',item)
        }
    }
}
</script>

<style lang="scss" scoped>
.table-container{
    display: flex;
    flex-direction: column;
    margin: 0 20px 0 40px;
    margin-top: 20px;
    overflow: auto;
    padding-bottom: 50px;
    padding-right: 20px;
}
.header-cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #B2B2B2;
    font-family: var(--main-site-font);
    padding-left: 8px;
    svg{
        margin: 0 8px;
    }
    .bold{
        font-weight: 600;
    }
}
table{
    color: #333;
    font-family: var(--main-site-font);
    font-size: 14px;
    border-collapse: collapse;

    thead{
        th{
            font-weight: 500;
            svg{
                path{
                    &.active{
                        fill: #7D7D7D;
                    }
                }
            }
            &:last-child{
                .header-cell{
                    border-right: none;
                }
            }
        }
    }
    tr{
        &.disabled{
            .cell{
                background: white;
                border-style: dashed;
                border-left: 0;
                border-right: 0;
            }
            td{
                &:first-child{
                    .cell{
                        border-left-style: dashed;
                    }
                }
                &:last-child{
                    .cell{
                        border-right-style: dashed;
                    }
                }
            }
           
        }
    }
    td{
       padding: 0;
        &:first-child{
            .cell{
                border-left: 1px solid #CACACA;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
        &:last-child{
            .cell{
                border-right: 1px solid #CACACA;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
        
    }
    .cell{
        background: #F4F4F4;
        border-top: 1px solid #CACACA;
        border-bottom: 1px solid #CACACA;
        display: flex;
        align-items: center;
        padding: 10px 8px;
        min-height: 54px;
        margin-top: 12px;
        &.center{
            justify-content: center;
        }
        .users{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 380px;
        }
        .condition{
            display: flex;
            align-items: center;
            gap: 8px;
            .tag{
                padding: 2px 8px;
                background: white;
                border-radius: 4px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
      
    }
}
.tag{
    .cell{
        span{
            padding: 2px 8px;
            border-radius: 4px;
            background: #FFF;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
.tools-cell{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.tools{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 150px;
}
.tool{
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    &:hover{
        border:1px solid #4D4D4D;
        background: white;
    }
    &.delete-btn{
        &:hover{
            border:1px solid #D45454;
        }
    }
    &.disabled{
        background: #D45454;
        .toggle-btn{
            path{
                fill: white;
            }

        }
        
    }
}
.groups{
    display: flex;
    align-items: center;
    .bubble{
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        box-shadow: -1px 0 2px rgba(0, 0, 0, 0.16);
        span{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            color: #333;
            text-align: center;
            font-family: var(--main-site-font);
            font-size: 14px;
            font-style: normal;
        }
        &:not(:first-child){
            margin-left: -8px;
        }
    }
}
</style>