import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');
import axios from 'axios';
axios.defaults.withCredentials = true;

export default async function echoInit(state, auth_api) {
    if (process.env.NODE_ENV == 'production') {
        return new Echo({
          broadcaster: 'pusher',
          key: '12345',
          wsHost: 'api.lkakademija.lt',
          wsPort: 6063,
          wssPort: 443,
          authEndpoint: `${auth_api}/broadcasting/auth`,
          auth: {
              headers: {
                Accept: "application/json",
              },
          },
           authorizer: (channel, options) => {
              return {
                authorize: (socketId, callback) => {
                  axios.post(`${state.backend_link}/broadcasting/auth`, {
                    socket_id: socketId,
                    channel_name: channel.name
                  },
                    {
                    headers: {
                        'Content-Type': 'application/json',
                      },
                      withCredentials: true
                    }
                  )
                    .then(response => {
                      callback(false, response.data);
                    })
                    .catch((error) => {
                      callback(true, error);
                    });
                }
              };
            },
          encrypted: true,
          forseTLS: true,
          cluster: 'mt1',
          disableStats: true,
          enabledTransports: ['ws', 'wss'],
          disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
        });
    }
    else {
        return new Echo({
            broadcaster: 'pusher',
            key: '12345',
            wsHost: window.location.hostname,
            wsPort: 6001,
            authEndpoint: process.env.VUE_APP_BROADCAST_URL,
            auth: {
               headers: {
                Accept: "application/json",
              },
            },
            authorizer: (channel, options) => {
              return {
                authorize: (socketId, callback) => {
                  axios.post(`${state.backend_link}/broadcasting/auth`, {
                    socket_id: socketId,
                    channel_name: channel.name
                  },
                    {
                    headers: {
                        'Content-Type': 'application/json',
                      },
                      withCredentials: true
                    }
                  )
                    .then(response => {
                      callback(false, response.data);
                    })
                    .catch((error) => {
                      callback(true, error);
                    });
                }
              };
            },
            cluster: 'mt1',
            forseTLS: false,
            disableStats: true,
            enabledTransports: ['ws'],
        });
    }
}