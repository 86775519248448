<template>
  <div :class="['terms', $store.state.user_terms_accepted ? 'hidden' : '']" v-scroll-lock="!$store.state.user_terms_accepted">
    <div class="terms--inner">
      <div v-html="toLocal('terms.conditions')" />
      <div 
        v-if="!loading"
        class="button"
        @click="accept"
      >
        {{ toLocal('terms.accept') }}
      </div>
      <div 
        v-if="loading"
        class="button"
      >
        <font-awesome-icon icon="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Terms",
    data: function() {
      return {
        loading: false,
      }
    },
    methods: {
        accept() {
          this.loading = true;
          axios.post(
            `${this.$store.state.api_link}/user/terms/accept`,
            {},
            this.$store.getters.axiosConfig()
          ).then((response) => {
            if(response.data.status == "success"){
              this.$store.state.user_terms_accepted = 1;
            }else{
              console.log("Error while accepting policy");
            }
          })
          .catch(() => {
            console.log("Error while accepting policy");
          });
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    
    .terms {
        font-family: var(--main-site-font);;
        position: fixed;
        z-index: 999;
        top: 0; 
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        overflow-y: scroll;
        transition: .5s left, .5s opacity;

        &.hidden {
          left: -110vw;
          opacity: 0;
        }
    }
    .terms--inner {
        width: 90%;
        max-width: 1150px;
        margin: auto;
        margin-top: 7rem;
    }
    h2 {
        font-size: 45px;
        font-weight: bold;
        color: #28345c;

        @media (max-width: 768px) {
            font-size: 25px;
        }
    }
    h3 {
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    p {
        text-align: justify;

        @media (max-width: 768px) {
            font-size: 17px;
        }
    }

    .button {
        margin: 40px 0;
        width: 100%;
        background-color: #FFD93E;
        padding: 15px;
        text-align: center;
        color: #28345c;
        font-weight: 500;
        font-size: 34px;
        border-radius: 15px;
        cursor: pointer;
        user-select: none;

        @media (max-width: 768px) {
            margin: 20px 10px;
            width: calc(100% - 20px);
            font-size: 20px;
            padding: 8px;
        }
    }

</style>