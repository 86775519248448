<template>
  <div v-if="Object.keys(parentTest).length > 0">
    <TestVersion
      v-if="parentTest.options"
      :test="test"
      :scrolledTopic="scrolledTopic"
      :topicIndex="topicIndex"
      :parentTest="parentTest"
      :topic="topic"
      @selectedVersion="changeTestVersion"
      @activateVersion="activateTestVersion"
  />
    <TestOptions
      :topic="topic"
      :scrolledTopic="scrolledTopic"
      :topicIndex="topicIndex"
      :availableTestQuestions="availableTestQuestions"
      :parentTest="parentTest"
      :test="test"
      @saveTestOptionParent="saveTest"
      @changeTab="tabIndex = $event"
      @changeGroupContent="selectedGroup = $event"
      @changeAnswerFilter="selectedAnswerFilter = $event"
      @changedFilterItem="selectedFilterItemID = $event"
    />
     <TestOptionTabContent
      v-if="tabIndex == 0 && test.type != 1"
      :content="content"
      :topic="topic"
      :test="test"
      :parentTest="parentTest"
      :topicIndex="topicIndex"
      :availableTestQuestions="availableTestQuestions"
      @on-training-media-change1="uploadImage"
      @disableDrag1="$emit('disableDrag', $event)"
     />
     <TestOptionComboContent
     v-if="tabIndex == 0 && test.type == 1"
      :content="content"
      :topic="topic"
      :test="test"
      :parentTest="parentTest"
      :topicIndex="topicIndex"
      :availableTestQuestions="availableTestQuestions"
      :folders="folders"
      @disableDrag1="$emit('disableDrag', $event)"
     />
     <TestStatisticsTabContent
      v-if="tabIndex == 1"
      :topic="topic"
      :test="test"
      :selectedGroup="selectedGroup"
      :availableTestQuestions="availableTestQuestions"
     />
      <TestCheckAnswersTabContent
      v-if="tabIndex == 2"
      :topic="topic"
      :test="test"
      :selectedAnswerFilter="selectedAnswerFilter"
      :selectedItemID="selectedFilterItemID"
      :availableTestQuestions="availableTestQuestions"
     />

  </div>
</template>

<script>
import TestOptions from "@/components/layout/trainings/training_components/test/TestOptions.vue";
import TestOptionTabContent from "@/components/layout/trainings/training_components/test/TestOptionTabContent.vue";
import TestOptionComboContent from "@/components/layout/trainings/training_components/test/TestOptionComboContent.vue";
import TestStatisticsTabContent from "@/components/layout/trainings/training_components/test/TestStatisticsTabContent.vue";
import TestCheckAnswersTabContent from "@/components/layout/trainings/training_components/test/TestCheckAnswersTabContent.vue";
import TestVersion from "@/components/layout/trainings/training_components/test/TestVersion.vue";

import axios from 'axios';
import _ from "lodash"

export default {
    name: 'TrainingTest',
    components:{
        TestOptions,
        TestOptionTabContent,
        TestStatisticsTabContent,
        TestCheckAnswersTabContent,
        TestVersion,
        TestOptionComboContent

    },
    props:{
        content: {
            type: Array,
            required: true,
        },
        topic: {
            type: Object,
            required: true
        },
        topicIndex: {
            type: Number,
            required: true
        },
        scrolledTopic: {
            type: Number,
        },
        availableTestQuestions: {
            type: Array,
            required: true
        },
        folders:{
            type: Array,
        },
        parentTest:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tabIndex: 0,
            userStats: [],
            users: [],
            groups: [],
            selectedGroup: 0,
            selectedAnswerFilter: 0,
            selectedFilterItemID: 0,
            test:{},
            allVersions: [],
        }
    },
    mounted(){
      // let self = this;
      // this.$root.$on('fileUpload', this._.debounce(this.addNewAnswerRequest, 300));
      // if(this.parentTest == undefined || this.parentTest == null || this.parentTest == {} ||  Object.keys(this.parentTest).length === 0){
      //     this.parentTest = this.availableTestQuestions[this.topic.questionsindex];
      //     this.initTest();
      //     this.$store.commit('setSelectedParentTest', this.parentTest);
      // }
      // if(this.parentTest.options.active_version_id !=0){
      //     let found_version = this.parentTest.versions.find(version => version.id == this.parentTest.options.active_version_id);
      //     if(found_version != undefined){
      //       this.test = found_version;
      //     }
      //     else{
      //       this.test = this.parentTest;
      //     }
      //   }
      //   else if(this.parentTest.options.active_version_id == 0) this.test = this.parentTest;
      // this.getUserStats();
      //  this.getUserMinimalStats();
      // this.getGroups()
    },
    watch:{
      tabIndex(newValue){
        if(newValue == 2) this.getUserMinimalStats();
      },
      parentTest:{
        immediate:true,
        handler(newVal){
          if(newVal != undefined && newVal.options != undefined && newVal.versions != undefined && typeof newVal.options != 'string'){

            if(this.allVersions.length == 0){
              this.allVersions = this.getVersions(newVal);
            }
            this.initTest();
            if(this.test)
              this.$store.commit('setSelectedTest', this.test);
            this.$store.commit('setSelectedParentTest', this.parentTest);
          }
        }
      },
      'topic.questions':{
        handler(){
          // this.parentTest = this.availableTestQuestions[this.topic.questionsindex];
          // if(this.parentTest.options.active_version_id !=0)
          //   this.test = this.parentTest.versions.find(version => version.id == this.parentTest.options.active_version_id);
          // else if(this.parentTest.options.active_version_id == 0) this.test = this.parentTest;
        }
      },
      test:{
        immediate: true,
        handler(){
           this.getUserStats();
        }
      }

    },
    methods:{
      getUserStats(){
        if(this.test == undefined) return;
          axios.post(`${this.$store.state.api_link}/training/stats/users`,
          {training_id: this.$store.state.currentTraining, 'test_id': this.test.id},
          this.$store.getters.axiosConfig()
          ).then((res)=>{
            this.users = res.data.users;
            this.groups = res.data.groups;
            this.$store.commit('setTestUserStats', {users: this.users, groups: this.groups});
          })
          .catch((err)=>{
          });
      },
      uploadImage(e, topicIndex, itemIndex, answerIndex){
        this.$emit('on-training-media-change', e, topicIndex, itemIndex, answerIndex);
      },
      findRecursive(id, array, key){
        for(let i = 0; i < array.length; i++){
          if(array[i].id === id){
            return array[i];
          }
          else if(array[i][key] != undefined && array[i][key].length > 0){
            let found = this.findRecursive(id, array[i][key], key);
            if(found != undefined) return found;
         
          }
        }
        return undefined;
      },
      setRecursive(id, array, key, value){
        for(let i = 0; i < array.length; i++){
          if(array[i].id === id){
            array[i] = value;
            return array[i];
            }
          else if(array[i][key] != undefined && array[i][key].length > 0){
            let found = this.findRecursive(id, array[i][key], key);
            if(found != undefined) found = value;
            return found;
         
          }
        }
        return undefined;
      },
      initTest(){
         if(this._.isEmpty(this.test) || this.test == undefined || this.test == {}){
           if(this.parentTest.options.active_version_id !==0){
                let found_version = this.findRecursive(this.parentTest.options.active_version_id, this.parentTest.versions, 'versions');
                if(found_version != undefined){
                  this.test = found_version;
                }
                else{
                  this.test = this.parentTest;
                }
              }
              else if(this.parentTest.options.active_version_id == 0) this.test = this.parentTest;
          }
      },
      saveTest(e){
          let self = this;
            axios.post(`${self.$store.state.api_link}/test-question-groups/edit`,
              {data: e},
              self.$store.getters.axiosConfig()
              ).then((res)=>{
                this.$emit('saveTest', e);
                if(e.version_parent == 0){
                  // this.$emit('saveTest', e)
                }
                else{
                   this.setRecursive(e.id, this.parentTest.versions, 'versions', e);

                }
              })
              .catch((err)=>{
              });
        },
        getUserMinimalStats(){
          let groups = this.groups.map(group=>group.id);
          axios.post(
              `${this.$store.state.api_link}/training/stats/users/minimal`,
              {test_id: this.test.id, groups: groups},
              this.$store.getters.axiosConfig()
          ).then((response) => {
              // this.dates = response.data.dates;
              // this.questions = response.data.questions;
              // this.users = response.data.users;
              this.$store.commit('setTestStats', response.data.data);
          })
          .catch((err) => {
              console.log(err);
              console.log("Server error while getting user statistics");
          });
        },
        changeTestVersion(id){
          
          let test;
          let all_tests;
          this.test = {};
          this.allVersions = this.getVersions(this.parentTest);
          if(id !== 0)
            test = this.allVersions.find(version=>version.id == id);
          else test = this._.cloneDeep(this.parentTest);
          if(test != undefined){
            this.test = test;
            this.$store.commit('setSelectedTest', test);
            this.getUserMinimalStats();
          }
        },
        getVersions(version){
          let versions_array = [];
          if(version.versions && version.versions.length>0){
            version.versions.forEach(item => {
              if (item.versions.length>0) {
                versions_array.push(item);
                versions_array.push(...this.getVersions(item));
              } else {
                versions_array.push(item);
              }
            });
          }
          versions_array.push(version);
          return versions_array;
        },
        activateTestVersion(id){
          this.parentTest.options.active_version_id = id;
          this.saveVersionActivation(this.parentTest, this);
          this.$emit('activeParentVersion', this.parentTest.id);
        },
        saveVersionActivation: _.debounce(function(data, self){
            axios.post(`${self.$store.state.api_link}/test-question-groups/edit`,
              {data: data},
              self.$store.getters.axiosConfig()
              ).then(()=>{
              })
              .catch((err)=>{
                console.log(err);
              });
        }, 1000),
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_trainingEdit.scss";
.open-task-container{
  margin: 1rem 0 2rem 0;
}
</style>
